import * as React from "react";

type PdfViewerContextValue = {
  numPages: number;
  activePageNumber: number;
  turnPage: (page: number) => void;
  setNumPages: (n: number) => void;
  value?: Nullable<string | File | Blob>;
  onDelete: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPrint: () => void;
  fileUrl: string;
  scale: number;
  setScale: (scale: number) => void;
};

const PdfViewerContext = React.createContext<PdfViewerContextValue>({
  numPages: 0,
  activePageNumber: 1,
  turnPage: () => {},
  setNumPages: () => {},
  onChange: () => {},
  onDelete: () => {},
  onPrint: () => {},
  fileUrl: "",
  scale: 1.0,
  setScale: () => {},
});

function usePdfViewerContext() {
  const context = React.useContext(PdfViewerContext);

  if (!context) {
    throw new Error(
      "Consumers of this context must be children of the PdfViewer component."
    );
  }

  return context;
}

const PdfViewerContextProvider = PdfViewerContext.Provider;

export type { PdfViewerContextValue };
export { usePdfViewerContext, PdfViewerContextProvider };
