import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

function useUpdateTriageMedicalHistory(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateTriageMedicalHistory", { encounterId }],
    (input: ChartFormSubsectionPayload[]) =>
      axiosChartInstance.post(
        `v1/triage/${encounterId}/medical-history`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "triageMedicalHistory",
          { encounterId },
        ]),
    }
  );
}

export { useUpdateTriageMedicalHistory };
