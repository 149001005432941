import * as React from "react";

import {
  ChartForm,
  ChartRouteBaseParams,
  mapFormToPayloadProcedures,
  mapPayloadToFormObjectProcedure,
  nextRoutesNurse,
  nextRoutesProvider,
} from "modules";
import { ProviderChartCode, TriageChartCode } from "enums";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useChartApplicationProcedure,
  useUpdateChartApplicationProcedure,
} from "../api";

import { FormPromptWithSaveProps } from "shared/prompt";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "hooks";

type UseChartProcedureFormProps = {
  chartCode: TriageChartCode | ProviderChartCode;
  sectionLabel: string;
  onProceduresLoaded?: boolean;
};

function useChartProcedureForm(props: UseChartProcedureFormProps) {
  const { chartCode, sectionLabel } = props;
  const { encounterId = "", "*": chartName } =
    useParams<ChartRouteBaseParams>();
  const navigate = useNavigate();
  const useFormContext = useForm<ChartForm>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplicationProcedure({
    chartCode,
    encounterId,
  });
  const {
    mutateAsync: updateChartApplicationProcedure,
    isLoading: isUpdateIsLoading,
  } = useUpdateChartApplicationProcedure({
    chartCode,
    encounterId,
  });

  const toast = useToast();
  const updateIsLoading = isUpdateIsLoading;

  const onSubmit: SubmitHandler<ChartForm> = React.useCallback(
    async (formObject) => {
      try {
        const payload = mapFormToPayloadProcedures(formObject);

        await updateChartApplicationProcedure(payload);
        // Reset form with latest values to set isDirty to false
        reset(formObject);
        toast({ description: formMessages.updateSuccess(sectionLabel) });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [updateChartApplicationProcedure, reset, toast, sectionLabel]
  );

  const onSaveAndNext = React.useCallback(() => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const nextRoute = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      navigate(`./../${nextRoute}`);
    });
  }, [chartName, navigate, onSubmit, handleSubmit, chartCode]);

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] =
    React.useCallback(
      async (onComplete) => {
        try {
          const formObjectFromContext = getValues();
          const payload = mapFormToPayloadProcedures(formObjectFromContext);
          await updateChartApplicationProcedure(payload);
          toast({ description: formMessages.updateSuccess(sectionLabel) });
          onComplete();
        } catch (error) {
          toast({ description: extractApiErrorMessage(error) });
        }
      },
      [updateChartApplicationProcedure, toast, sectionLabel, getValues]
    );

  const handleReloadProcedures = React.useCallback(() => {
    if (data) {
      const form = mapPayloadToFormObjectProcedure(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (data) {
      handleReloadProcedures();
    }
  }, [data, handleReloadProcedures]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const value = React.useMemo(
    () => ({
      procedureInfo: data?.data,
      isDirty,
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSubmit: handleSubmit(onSubmit),
      onRouteChangeSave,
      onSaveAndNext,
      handleReloadProcedures,
    }),
    [
      data,
      isDirty,
      onRouteChangeSave,
      handleSubmit,
      onSubmit,
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSaveAndNext,
      handleReloadProcedures,
    ]
  );

  return value;
}

export type { UseChartProcedureFormProps };
export { useChartProcedureForm };
