import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetScheduleReport(
  date: Date | null,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["scheduleReport", { date }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/Reception/schedule-report`, {
        responseType: "blob",
        params: { date },
      }),
    { ...options }
  );
}

export { useGetScheduleReport };
