import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { AuditCommentsIndicator } from "modules/charts-shared/types/audit-comments";

type UseAuditCommentsSectionIndicator = {
  chartCode: string;
  sectionCode: string;
  encounterId: string;
};

type UseAuditCommentsSubsectionIndicator = {
  chartCode: string;
  sectionCode: string;
  encounterId: string;
  subsectionCode: string;
};

type UseAuditCommentsSharedSectionIndicator = {
  sectionCode: string;
  encounterId: string;
};

type UseAuditCommentsSharedSubsectionIndicator = {
  sectionCode: string;
  encounterId: string;
  subsectionCode: string;
};

function useAuditCommentsSectionIndicator(
  props: UseAuditCommentsSectionIndicator
) {
  const { chartCode, sectionCode, encounterId } = props;
  return useAxiosQuery(
    ["AuditCommentsSectionIndicator", { chartCode, sectionCode, encounterId }],
    () =>
      axiosChartInstance.get<AuditCommentsIndicator>(
        `/v1/audits/section/comments/indicator/${encounterId}/${chartCode}/${sectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

function useAuditCommentsSubsectionIndicator(
  props: UseAuditCommentsSubsectionIndicator
) {
  const { chartCode, sectionCode, encounterId, subsectionCode } = props;
  return useAxiosQuery(
    [
      "AuditCommentsSubsectionIndicator",
      { chartCode, sectionCode, encounterId, subsectionCode },
    ],
    () =>
      axiosChartInstance.get<AuditCommentsIndicator>(
        `/v1/audits/subsection/comments/indicator/${encounterId}/${chartCode}/${sectionCode}/${subsectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

function useAuditCommentsSharedSectionIndicator(
  props: UseAuditCommentsSharedSectionIndicator
) {
  const { sectionCode, encounterId } = props;
  return useAxiosQuery(
    ["AuditCommentsSharedSectionIndicator", { sectionCode, encounterId }],
    () =>
      axiosChartInstance.get<AuditCommentsIndicator>(
        `/v1/audits/section/comments/indicator/${encounterId}/shared/${sectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

function useAuditCommentsSharedSubsectionIndicator(
  props: UseAuditCommentsSharedSubsectionIndicator
) {
  const { sectionCode, encounterId, subsectionCode } = props;
  return useAxiosQuery(
    [
      "AuditCommentsSharedSubsectionIndicator",
      { sectionCode, encounterId, subsectionCode },
    ],
    () =>
      axiosChartInstance.get<AuditCommentsIndicator>(
        `/v1/audits/subsection/comments/indicator/${encounterId}/shared/${sectionCode}/${subsectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

export {
  useAuditCommentsSectionIndicator,
  useAuditCommentsSubsectionIndicator,
  useAuditCommentsSharedSectionIndicator,
  useAuditCommentsSharedSubsectionIndicator,
};
