import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserDetails } from "api";
import { UserType } from "modules";
import { USERID_CLAIMS } from "system-constants";

type UserRolesContextValue = {
  isRolesLoading: boolean;
  roles: UserType[];
};

const UserRolesContext = React.createContext<UserRolesContextValue | null>(
  null
);

function UserRolesProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const { isLoading, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [userId, setUserId] = React.useState<string>(
    user ? user[USERID_CLAIMS] : ""
  );
  const [roles, setRoles] = React.useState<UserType[]>([]);
  const [isGeneratingRoles, setIsGeneratingRoles] =
    React.useState<boolean>(true);

  const { data: userDetails, isLoading: isUserDatailsLoading } =
    useGetUserDetails(userId, {
      enabled: !!userId && isAuthenticated && !isLoading,
      currentUser: true,
    });

  const isRolesLoading = isLoading || isGeneratingRoles || isUserDatailsLoading;

  const generateRoles = React.useCallback(async () => {
    try {
      const teamTypes = userDetails?.data?.teamTypes ?? "";
      const teamTypesList = teamTypes.split(", ") as UserType[];
      setRoles(teamTypesList);

      setIsGeneratingRoles(false);
    } catch (_error) {
      setIsGeneratingRoles(false);
    }
  }, [userDetails]);

  const verifyAuthentication = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (isAuthenticated && user && token) {
      setUserId(user[USERID_CLAIMS]);
      generateRoles();
    }
  }, [generateRoles, user, isAuthenticated, getAccessTokenSilently]);

  React.useEffect(() => {
    verifyAuthentication();
  }, [verifyAuthentication]);

  const value: UserRolesContextValue = React.useMemo(
    () => ({
      isRolesLoading,
      roles,
    }),
    [isRolesLoading, roles]
  );

  return (
    <UserRolesContext.Provider value={value}>
      {children}
    </UserRolesContext.Provider>
  );
}

function useUserRoles() {
  const context = React.useContext(UserRolesContext);

  if (context === null) {
    throw new Error("useUserRoles must be used within an UserRolesProvider");
  }

  return context;
}

export { UserRolesProvider, useUserRoles };
