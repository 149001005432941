import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonRaisedHand = createIcon({
  path: (
    <path
      d="M31.3865 43C32.3382 43 33.0303 42.3944 33.0303 41.2553V30.1956C33.0303 29.7053 33.261 29.4169 33.6504 29.4169C34.0397 29.4169 34.2848 29.7053 34.2848 30.1956V41.2553C34.2848 42.3944 34.977 43 35.9286 43C36.8659 43 37.558 42.3944 37.558 41.2553V22.5677C37.558 22.1784 37.7455 21.9477 38.0627 21.9477C38.3655 21.9477 38.5674 22.1784 38.5674 22.5677V30.2388C38.5674 31.2626 39.173 31.8394 40.0382 31.8394C40.9033 31.8394 41.4945 31.2626 41.4945 30.2388V22.7408C41.4945 18.1698 38.9134 16.9874 33.7946 16.9874H31.574C30.579 16.9874 30.0744 16.8432 29.2813 16.2664L27.3779 14.9254L27.0319 9.53259C26.9598 8.49439 26.2965 7.94645 25.4313 8.00413C24.5517 8.04739 23.9461 8.68184 24.0038 9.70562L24.3499 15.0552C24.422 16.2232 24.8834 16.9009 25.6043 17.4344L29.065 19.9578C29.5697 20.3183 29.7427 20.7653 29.7427 21.3421V41.2553C29.7427 42.3944 30.4493 43 31.3865 43ZM33.7946 15.5455C35.7123 15.5455 37.2552 13.9593 37.2552 12.0992C37.2552 10.1815 35.7123 8.62417 33.7946 8.62417C31.8624 8.62417 30.3483 10.1815 30.3483 12.0992C30.3483 13.9882 31.8624 15.5455 33.7946 15.5455Z"
      fill="currentColor"
    />
  ),
  viewBox: "20 3 27 45",
});

export { PersonRaisedHand };
