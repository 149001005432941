import {
  chakra,
  Icon,
  useMultiStyleConfig,
  Input,
  Button,
  Center,
  useFormControl,
  useMergeRefs,
} from "@chakra-ui/react";
import { SiExpandClose, SiExpandOpen } from "@medstonetech/slate-icons";
import { useCombobox, UseComboboxProps } from "downshift";
import * as React from "react";
import { DropdownMenu, DropdownMenuItem } from "shared/dropdown-menu";
import { AccessorType, applyAccessor } from "shared/utils";

type SelectFilterProps<T> = UseComboboxProps<T | null> & {
  labelAccessor: AccessorType<T>;
  valueAccessor: AccessorType<T>;
  placeholder?: string;
  isDisabled?: boolean;
  backgroundColor?: string;
};

function SelectFilterInner<T>(
  props: SelectFilterProps<T>,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const {
    labelAccessor,
    valueAccessor,
    selectedItem: selectedItemProp,
    initialSelectedItem,
    items,
    placeholder,
    isDisabled,
    backgroundColor,
    ...checkboxProps
  } = props;

  const [selectedItemValue, setSelectedItemValue] = React.useState<
    T | null | undefined
  >(initialSelectedItem);

  React.useEffect(() => {
    setSelectedItemValue(selectedItemProp);
  }, [selectedItemProp]);

  const [filter, setFilter] = React.useState<string | undefined>("");

  const filterStarted = React.useRef<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const mergedRef = useMergeRefs(ref, containerRef);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const formControl = useFormControl({
    id: checkboxProps.id,
    isDisabled,
  });

  const internalItems = React.useMemo(() => {
    if (filter && filterStarted.current) {
      const lowercaseFilter = (filter || "").toLowerCase();
      return items.filter((item) =>
        (item ? applyAccessor(item, labelAccessor) : "")
          .toLowerCase()
          .includes(lowercaseFilter)
      );
    }
    return [null, ...items];
  }, [items, filter, labelAccessor]);

  const getSelectedItem = () =>
    selectedItemValue &&
    items.find(
      (x) =>
        x &&
        applyAccessor(x, valueAccessor) ===
          applyAccessor(selectedItemValue, valueAccessor)
    );

  React.useEffect(() => {
    if (selectedItemValue) {
      setFilter(
        selectedItemValue ? applyAccessor(selectedItemValue, labelAccessor) : ""
      );
    } else {
      setFilter("");
    }
  }, [selectedItemValue, labelAccessor]);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    setHighlightedIndex,
    highlightedIndex,
  } = useCombobox<T | null>({
    ...checkboxProps,
    items: internalItems,
    itemToString(item) {
      return item ? applyAccessor(item, labelAccessor) : "";
    },
    selectedItem: getSelectedItem(),
    id: formControl.id,
    toggleButtonId: formControl.id,
    onIsOpenChange: ({
      isOpen: isCheckboxOpen,
      selectedItem: currentSelectedItem,
    }) => {
      if (!isCheckboxOpen) {
        if (inputRef.current) inputRef.current.blur();
        filterStarted.current = false;
        setFilter(
          currentSelectedItem
            ? applyAccessor(currentSelectedItem, labelAccessor)
            : selectedItemValue
            ? applyAccessor(selectedItemValue, labelAccessor)
            : ""
        );
      } else {
        setFilter("");
      }
    },
  });

  const selectedItemLabel = selectedItem
    ? applyAccessor(selectedItem, labelAccessor)
    : "";

  const styles = useMultiStyleConfig("CustomCheckbox", {
    hasSelectedItem: !!selectedItem,
  });

  const isFormDisabled = formControl.disabled;

  const { value, onChange, defaultValue, ...restInputProps } = getInputProps({
    ref: inputRef,
    autoComplete: "nope",
    ...formControl,
  });

  return (
    <>
      <chakra.div
        ref={mergedRef}
        __css={styles.togglebtn}
        bg={backgroundColor}
        _hover={{ bg: backgroundColor }}
        _active={{ bg: backgroundColor }}
      >
        <Input
          __css={styles.input}
          bg={backgroundColor}
          _hover={{ bg: backgroundColor }}
          _active={{ bg: backgroundColor }}
          _focus={{ bg: backgroundColor }}
          title={selectedItemLabel}
          isDisabled={isFormDisabled}
          disabled={isFormDisabled}
          placeholder={placeholder}
          value={filter}
          onChange={(e) => {
            filterStarted.current = true;
            if (e.currentTarget.value !== "") {
              if (highlightedIndex !== 0) setHighlightedIndex(0);
            } else {
              const currentItemIndex = selectedItemValue
                ? items.findIndex(
                    (x) =>
                      x &&
                      applyAccessor(x, valueAccessor) ===
                        applyAccessor(selectedItemValue, valueAccessor)
                  ) + 1
                : 0;
              setHighlightedIndex(currentItemIndex);
            }
            setFilter(e.currentTarget.value);
          }}
          border="none"
          {...restInputProps}
        />
        <Button
          __css={styles.btncontainer}
          type="button"
          disabled={isFormDisabled}
          {...getToggleButtonProps()}
        >
          {!formControl.disabled && (
            <chakra.span
              __css={styles.action}
              role="img"
              display="flex"
              aria-label="dropdown"
            >
              {isOpen ? (
                <Icon fontSize="24px" as={SiExpandClose} color="gray.700" />
              ) : (
                <Icon fontSize="24px" as={SiExpandOpen} color="gray.700" />
              )}
            </chakra.span>
          )}
        </Button>
      </chakra.div>
      <DropdownMenu
        outline="unset"
        respectiveToRef={containerRef}
        isOpen={isOpen}
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            {internalItems.length === 0 && <Center h="40px">-</Center>}
            {internalItems.map((item, index) => (
              <DropdownMenuItem
                key={item ? applyAccessor(item, valueAccessor) : "empty"}
                {...getItemProps({ item: item as T, index })}
              >
                {item ? applyAccessor(item, labelAccessor) : ""}
              </DropdownMenuItem>
            ))}
          </>
        )}
      </DropdownMenu>
    </>
  );
}

const SelectFilter = React.forwardRef(SelectFilterInner) as <T>(
  props: SelectFilterProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectFilterInner>;

export type { SelectFilterProps };
export { SelectFilter };
