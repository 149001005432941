import { Procedures } from "modules/provider/shared/procedure-charts";

const sectionCode = "SPRO2";
const chartCode = "C001x";

function T01Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T01Procedures };
