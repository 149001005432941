import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useCreateEncounterConsentFile(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createEncounterConsentFile", { encounterId }],
    (input: FormData) =>
      axiosPatientInstance.post<string>(
        `v1/encounter/${encounterId}/form/files/consent`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterConsentFile",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["encounterOverview", { encounterId }]);
      },
    }
  );
}

export { useCreateEncounterConsentFile };
