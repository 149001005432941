import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Cart = createIcon({
  path: (
    <path
      d="M0 0.779896C0 0.363951 0.353553 0 0.769497 0H4.41941C5.48007 0 5.77123 0.405546 5.87522 1.20624L6.08319 2.52686H23.0537C23.6257 2.52686 24 2.87002 24 3.36915C24 3.46274 23.9792 3.62912 23.9584 3.7643L23.1265 9.26516C22.9081 10.7522 22.1283 11.688 20.6101 11.688H7.41421L7.58059 12.7799C7.67418 13.4142 8.03813 13.8302 8.63085 13.8302H20.5893C20.9948 13.8302 21.3484 14.1629 21.3484 14.6101C21.3484 15.0572 20.9948 15.3899 20.5893 15.3899H8.45407C6.94627 15.3899 6.16638 14.4645 5.94801 12.9879L4.28423 1.57019H0.769497C0.353553 1.57019 0 1.20624 0 0.779896ZM7.58059 18.7591C7.58059 17.8232 8.32929 17.0745 9.27556 17.0745C10.2114 17.0745 10.9601 17.8232 10.9601 18.7591C10.9601 19.7054 10.2114 20.4437 9.27556 20.4437C8.32929 20.4437 7.58059 19.7054 7.58059 18.7591ZM17.3033 18.7591C17.3033 17.8232 18.0624 17.0745 18.9983 17.0745C19.9445 17.0745 20.6932 17.8232 20.6932 18.7591C20.6932 19.7054 19.9445 20.4437 18.9983 20.4437C18.0624 20.4437 17.3033 19.7054 17.3033 18.7591Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 21",
});

export { Cart };
