import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Close = createIcon({
  path: (
    <path
      d="M0.326361 19.6705C0.767791 20.0993 1.49931 20.0993 1.92812 19.6705L10 11.5986L18.0719 19.6705C18.5007 20.0993 19.2448 20.1119 19.6736 19.6705C20.1025 19.2291 20.1025 18.5102 19.6736 18.0813L11.6018 9.99685L19.6736 1.92497C20.1025 1.49615 20.1151 0.764638 19.6736 0.335819C19.2322 -0.105611 18.5007 -0.105611 18.0719 0.335819L10 8.40769L1.92812 0.335819C1.49931 -0.105611 0.755179 -0.118224 0.326361 0.335819C-0.102458 0.77725 -0.102458 1.49615 0.326361 1.92497L8.39824 9.99685L0.326361 18.0813C-0.102458 18.5102 -0.11507 19.2417 0.326361 19.6705Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Close };
