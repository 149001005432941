import * as React from "react";
import { ButtonProps, chakra, useMergeRefs } from "@chakra-ui/react";
import { useSegment } from "./Segment";

type SegmentOptionProps = ButtonProps;

const SegmentOption = React.forwardRef<HTMLButtonElement, SegmentOptionProps>(
  (props, ref) => {
    const { children, ...buttonProps } = props;
    const [index, setIndex] = React.useState(0);
    const internalRef = React.useRef<HTMLButtonElement>();
    const mergedRefs = useMergeRefs(ref, internalRef);
    const { onChange, selectedIndex, isFitted } = useSegment();

    const isSelected = index === selectedIndex;

    React.useLayoutEffect(() => {
      const segmentNode = internalRef.current;
      if (segmentNode) {
        const { parentNode } = segmentNode;
        const nodeIndex = parentNode
          ? Array.from(parentNode.children).indexOf(segmentNode)
          : 0;
        setIndex(nodeIndex);
      }
    }, []);

    return (
      <chakra.button
        aria-selected={isSelected}
        onClick={() => onChange(index)}
        ref={mergedRefs}
        height="calc(100% - 0.25rem)"
        margin="0.125rem"
        padding="0 0.5rem"
        _selected={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          border: "1px solid #007AFF",
          color: "white",
          bg: "#007AFF",
        }}
        width={isFitted ? "50%" : undefined}
        {...buttonProps}
      >
        {children}
      </chakra.button>
    );
  }
);

SegmentOption.displayName = "SegmentOption";

export type { SegmentOptionProps };
export { SegmentOption };
