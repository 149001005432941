import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  chakra,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { OrderStatus } from "enums";
import {
  ChartRowElement,
  TriageIVMedicationSection,
} from "modules/charts-shared";
import {
  getOrderDetailsDateShortFormat,
  ORDER_STATUS_COLOR,
} from "modules/orders";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import {
  FormChartOption,
  FormRadioChartOptionGroup,
  Textarea,
  UserInfo,
} from "shared";
import { USERID_CLAIMS } from "system-constants";
import { enumMapper } from "utils";

type MedsAndIvTypes = "ivStarts" | "ivMedications" | "medications";

type MedsAndIvModalProps = {
  isOpen: boolean;
  onClose(): void;
  type: MedsAndIvTypes;
  item: TriageIVMedicationSection & { index: number };
  title: string;
  onSave: () => void;
};

type MedicationTypeContentProps = {
  item: TriageIVMedicationSection;
  type: MedsAndIvTypes;
};

type ItemInfoProps = {
  label: string;
  value: ReactNode;
};

function ItemInfo({ label, value }: ItemInfoProps) {
  return (
    <Box display="flex" justifyContent="space-between" py={3} px={4}>
      <Text color="#9A9EA7">{label}</Text>
      <Text fontWeight="bold">{value}</Text>
    </Box>
  );
}

function ItemDetails({ item, type }: MedicationTypeContentProps) {
  const { register } = useFormContext();

  return (
    <Box>
      <Box
        height="310px"
        w="500px"
        border="1px solid #FFFFFF"
        borderRadius="10px"
        bgColor="white"
        overflow="hidden"
      >
        <ItemInfo
          label="Order Status"
          value={
            <Badge
              bgColor={
                ORDER_STATUS_COLOR[(item.status as OrderStatus) || "InProcess"]
              }
              fontWeight="700"
              fontSize="15px"
              color="white"
              borderRadius="20px"
              padding="0.5rem 1rem"
              textTransform="capitalize"
              width="100%"
              textAlign="center"
            >
              {enumMapper.toDisplay(
                "orderStatus",
                (item.status as OrderStatus) || "InProcess"
              )}
            </Badge>
          }
        />
        <Divider ml="16px" />
        <ItemInfo
          label="Expected Administration Time"
          value={getOrderDetailsDateShortFormat(item.order?.administrationTime)}
        />
        <Divider ml="16px" />
        <ItemInfo label="Medication" value={item.medication as string} />
        <Divider ml="16px" />
        <ItemInfo label="Dose" value={item.solution as string} />
        <Divider ml="16px" />
        <ItemInfo
          label="Time Ordered"
          value={getOrderDetailsDateShortFormat(item.order?.timeOrdered)}
        />
        <Divider ml="16px" />
        <Box p={4}>
          <Text color="#9A9EA7">Notes</Text>
          <Text>{item.order?.notes as string}</Text>
        </Box>
      </Box>
      <Box py={4}>
        <Text color="#9A9EA7" pl={4} pb={1}>
          Comments
        </Text>
        <Textarea
          backgroundColor="white"
          borderRadius="10px"
          resize="none"
          height="70px"
          minH="unset"
          border="0"
          {...register(`${type}[${item.index}].comments`)}
        />
      </Box>
    </Box>
  );
}

type StepProps = {
  label: string;
  isActive: boolean;
  color: string;
};

function Step({ label, isActive, color }: StepProps) {
  return (
    <Text
      bgColor={isActive ? color : "#D1D1D6"}
      color="white"
      py={1}
      px={2}
      borderRadius="5px"
      fontSize="17px"
      w="100%"
    >
      {label}
    </Text>
  );
}

function MedicationTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch } = useFormContext();
  const status = watch(`medications[${item.index}].status`);

  return (
    <Box display="flex" justifyContent="space-between">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step label="Step 1 - Start Medication" isActive color="green" />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`medications[${item.index}].startTime`}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo userId={watch(`medications[${item.index}].startedBy`)} />
          </Box>
        </Box>
        <Step
          label="Step 2 - Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`medications[${item.index}].assessmentTime`}
                w="240px"
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo
                userId={watch(`medications[${item.index}].assessedBy`)}
              />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`medications[${item.index}].noChange`}
                bgColor="white"
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`medications[${item.index}].improved`}
                bgColor="white"
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`medications[${item.index}].nar`}
                bgColor="white"
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`medications[${item.index}].continued`}
                bgColor="white"
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`medications[${item.index}].pain`}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function IvStartsTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch } = useFormContext();
  const status = watch(`ivStarts[${item.index}].status`);

  return (
    <Box display="flex" justifyContent="space-between">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step label="Step 1 - Start Medication" isActive color="green" />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`ivStarts[${item.index}].startTime`}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo userId={watch(`ivStarts[${item.index}].startedBy`)} />
          </Box>
        </Box>
        <Step
          label="Step 2 - Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`ivStarts[${item.index}].assessmentTime`}
                w="240px"
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo userId={watch(`ivStarts[${item.index}].assessedBy`)} />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={2}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`ivStarts[${item.index}].noChange`}
                bgColor="white"
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`ivStarts[${item.index}].improved`}
                bgColor="white"
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`ivStarts[${item.index}].nar`}
                bgColor="white"
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`ivStarts[${item.index}].continued`}
                bgColor="white"
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`ivStarts[${item.index}].pain`}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function IvMedicationsTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch } = useFormContext();
  const status = watch(`ivMedications[${item.index}].status`);

  return (
    <Box display="flex" justifyContent="start" gap="40px">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step label="Step 1 - Start Medication" isActive color="blue" />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`ivMedications[${item.index}].startTime`}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo
              userId={watch(`ivMedications[${item.index}].startedBy`)}
            />
          </Box>
        </Box>
        <Step
          label="Step 2 - Complete Medication"
          isActive={
            status === "InProcess" ||
            status === "Completed" ||
            status === "Administered"
          }
          color="green"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Stop Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`ivMedications[${item.index}].stopTime`}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Stopped by</Text>
            <UserInfo
              userId={watch(`ivMedications[${item.index}].stoppedBy`)}
            />
          </Box>
        </Box>
        <Box py={4} display="flex" gap={4}>
          <Box>
            <Text color="#808080" ml={4}>
              Amount Infused*
            </Text>
            <ChartRowElement
              type="input"
              name={`ivMedications[${item.index}].amountInfused`}
              w="240px"
            />
          </Box>
          <Box>
            <Text color="#808080" ml={4}>
              Amount Wasted*
            </Text>
            <ChartRowElement
              type="input"
              name={`ivMedications[${item.index}].amountWasted`}
              w="240px"
            />
          </Box>
        </Box>
        <Step
          label="Step 3 - Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`ivMedications[${item.index}].assessmentTime`}
                w="240px"
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo
                userId={watch(`ivMedications[${item.index}].assessedBy`)}
              />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`ivMedications[${item.index}].noChange`}
                bgColor="white"
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`ivMedications[${item.index}].improved`}
                isDisabled={status !== "Completed"}
                bgColor="white"
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`ivMedications[${item.index}].nar`}
                bgColor="white"
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`ivMedications[${item.index}].continued`}
                bgColor="white"
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`ivMedications[${item.index}].pain`}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function MedsAndIvModal({
  isOpen,
  onClose,
  type,
  item,
  title,
}: MedsAndIvModalProps) {
  const { getValues, setValue } = useFormContext();

  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalContent background="gray.200" minW="1080px">
        <ModalHeader
          display="flex"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <chakra.span
            fontSize="16px"
            cursor="pointer"
            onClick={onClose}
            color="blue"
          >
            Cancel
          </chakra.span>
          <chakra.span fontSize="16px">{title}</chakra.span>
          <chakra.span
            fontSize="16px"
            cursor="pointer"
            color="blue"
            onClick={async () => {
              if (type === "medications") {
                if (getValues(`medications[${item.index}].startTime`)) {
                  setValue(`medications[${item.index}].status`, "Completed");
                  if (!getValues(`medications[${item.index}].startedBy`)) {
                    setValue(`medications[${item.index}].startedBy`, userId);
                  }
                }

                if (
                  getValues(`medications[${item.index}].assessmentTime`) &&
                  (getValues(`medications[${item.index}].noChange`) ||
                    getValues(`medications[${item.index}].improved`) ||
                    getValues(`medications[${item.index}].nar`) ||
                    getValues(`medications[${item.index}].continued`)) &&
                  getValues(`medications[${item.index}].pain`)
                ) {
                  setValue(`medications[${item.index}].status`, "Administered");
                  if (!getValues(`medications[${item.index}].assessedBy`)) {
                    setValue(`medications[${item.index}].assessedBy`, userId);
                  }
                }
              }

              if (type === "ivStarts") {
                if (getValues(`ivStarts[${item.index}].startTime`)) {
                  setValue(`ivStarts[${item.index}].status`, "Completed");
                  if (!getValues(`ivStarts[${item.index}].startedBy`)) {
                    setValue(`ivStarts[${item.index}].startedBy`, userId);
                  }
                }

                if (
                  getValues(`ivStarts[${item.index}].assessmentTime`) &&
                  getValues(`ivStarts[${item.index}].pain`) &&
                  (getValues(`ivStarts[${item.index}].noChange`) ||
                    getValues(`ivStarts[${item.index}].improved`) ||
                    getValues(`ivStarts[${item.index}].nar`) ||
                    getValues(`ivStarts[${item.index}].continued`))
                ) {
                  setValue(`ivStarts[${item.index}].status`, "Administered");
                  if (!getValues(`ivStarts[${item.index}].assessedBy`)) {
                    setValue(`ivStarts[${item.index}].assessedBy`, userId);
                  }
                }
              }

              if (type === "ivMedications") {
                if (getValues(`ivMedications[${item.index}].startTime`)) {
                  setValue(`ivMedications[${item.index}].status`, "InProcess");
                  if (!getValues(`ivMedications[${item.index}].startedBy`)) {
                    setValue(`ivMedications[${item.index}].startedBy`, userId);
                  }
                }

                if (
                  getValues(`ivMedications[${item.index}].stopTime`) &&
                  getValues(`ivMedications[${item.index}].amountInfused`) &&
                  getValues(`ivMedications[${item.index}].amountWasted`)
                ) {
                  setValue(`ivMedications[${item.index}].status`, "Completed");
                  if (!getValues(`ivMedications[${item.index}].stoppedBy`)) {
                    setValue(`ivMedications[${item.index}].stoppedBy`, userId);
                  }
                }

                if (
                  getValues(`ivMedications[${item.index}].assessmentTime`) &&
                  getValues(`ivMedications[${item.index}].pain`) &&
                  (getValues(`ivMedications[${item.index}].noChange`) ||
                    getValues(`ivMedications[${item.index}].improved`) ||
                    getValues(`ivMedications[${item.index}].nar`) ||
                    getValues(`ivMedications[${item.index}].continued`))
                ) {
                  setValue(
                    `ivMedications[${item.index}].status`,
                    "Administered"
                  );
                  if (!getValues(`ivMedications[${item.index}].assessedBy`)) {
                    setValue(`ivMedications[${item.index}].assessedBy`, userId);
                  }
                }
              }
              onClose();
            }}
          >
            Done
          </chakra.span>
        </ModalHeader>
        <ModalBody display="flex" flexDir="column" gap="1rem">
          {type === "medications" && (
            <MedicationTypeContent item={item} type={type} />
          )}
          {type === "ivStarts" && (
            <IvStartsTypeContent item={item} type={type} />
          )}
          {type === "ivMedications" && (
            <IvMedicationsTypeContent item={item} type={type} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { MedsAndIvModal };
