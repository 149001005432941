import { HTMLChakraProps } from "@chakra-ui/react";

type GetFocusableRowProps = {
  onKeyPress?: () => void;
};

function getFocusableRowProps(
  props: GetFocusableRowProps
): HTMLChakraProps<"tr"> {
  const { onKeyPress: onKeyPressProp } = props;

  const onKeyPress = (event: React.KeyboardEvent) => {
    const { key } = event;

    if (key === "Enter" || key === " ") {
      onKeyPressProp?.();
    }
  };

  return {
    onKeyPress,
    tabIndex: 0,
    _focus: { bg: "gray.50" },
    _hover: { cursor: "pointer" },
  };
}

export type { GetFocusableRowProps };
export { getFocusableRowProps };
