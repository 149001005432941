import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../theme";

type ChakraProps = { children: React.ReactNode };

function Chakra({ children }: ChakraProps) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default Chakra;

/**
 * IMPORTANT NOTE
 *
 * The original chakra version in this project was 1.6.5, but was bumped to
 * 1.7.1 due to an issue with the chakra color mode. Here's the link to
 * the issue for reference:
 * https://github.com/chakra-ui/chakra-ui/issues/4987
 */
