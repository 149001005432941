import { useQuery } from "react-query";
import { axiosSLPatientInstance } from "config";
import { Facility } from "modules/stp-vip/types";

type ExistingStpVipElement = {
  isStp: boolean;
  isVip: boolean;
  fullName: string;
  dob: string;
  facility: Facility;
  lastEncounter: string;
  stpHomeLocation?: Facility;
};

type ExistingStpVipResponse = ExistingStpVipElement[];

type StpVipPatientFilter = Partial<{
  firstName: string;
  lastName: string;
  dob: string;
}>;

type UseExistingStpVipProps = StpVipPatientFilter;

function useExistingStpVip(props: UseExistingStpVipProps, enabled = true) {
  const { ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useQuery(
    ["searchEncounters", props],
    () =>
      axiosSLPatientInstance.get<ExistingStpVipResponse>(
        `v1/patient/existing`,
        {
          params: validFilters,
        }
      ),
    { keepPreviousData: true, staleTime: 0, enabled }
  );
}
export { useExistingStpVip };
export type {
  UseExistingStpVipProps,
  StpVipPatientFilter,
  ExistingStpVipElement,
};
