import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T001XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="skull"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="facial"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="spine:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="C"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="T"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no fracture"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}nml alignment`}
            type="option"
          />
          <ChartRowElement
            label="no FB"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A017`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A026`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A027`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T014XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infitates"
            fontStyle="italic"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T016XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="skull"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="facial"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A004`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A022`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A026`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A027`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A028`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A029`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A030`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A031`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A026`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A027`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A028`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A029`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A030`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A031`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A032`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="CUB"
            name={`${sectionDelimiter}A033`}
            type="option"
          />
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A034`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A035`}
            type="option"
          />
          <ChartRowElement
            label="spine:"
            name={`${sectionDelimiter}A036`}
            type="option"
          />
          <ChartRowElement
            label="C"
            name={`${sectionDelimiter}A037`}
            type="option"
          />
          <ChartRowElement
            label="T"
            name={`${sectionDelimiter}A038`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A039`}
            type="option"
          />
          <ChartRowElement
            label="S"
            name={`${sectionDelimiter}A040`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A041`}
            type="input"
            w="100px"
          />
          <ChartRowElement name={`${sectionDelimiter}A042`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A043`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A044`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A045`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A046`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A047`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A048`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            fontStyle="italic"
            name={`${sectionDelimiter}A049`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A050`}
            type="option"
          />
          <ChartRowElement
            label="no mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A051`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A052`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A053`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A054`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            fontStyle="italic"
            name={`${sectionDelimiter}A055`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            fontStyle="italic"
            name={`${sectionDelimiter}A056`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            fontStyle="italic"
            name={`${sectionDelimiter}A057`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            fontStyle="italic"
            name={`${sectionDelimiter}A058`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            fontStyle="italic"
            name={`${sectionDelimiter}A059`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A060`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A061`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A062`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T017XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="skull"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="facial"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A004`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A022`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A026`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A027`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A028`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A029`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A030`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A031`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A032`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A033`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A034`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A035`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A036`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A037`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A038`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A039`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A040`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="CUB"
            name={`${sectionDelimiter}A041`}
            type="option"
          />
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A042`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A043`}
            type="option"
          />
          <ChartRowElement
            label="spine:"
            name={`${sectionDelimiter}A044`}
            type="option"
          />
          <ChartRowElement
            label="C"
            name={`${sectionDelimiter}A045`}
            type="option"
          />
          <ChartRowElement
            label="T"
            name={`${sectionDelimiter}A046`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A047`}
            type="option"
          />
          <ChartRowElement
            label="S"
            name={`${sectionDelimiter}A048`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A049`}
            type="input"
            w="100px"
          />
          <ChartRowElement name={`${sectionDelimiter}A050`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A051`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A052`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A053`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A054`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A055`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A056`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            fontStyle="italic"
            name={`${sectionDelimiter}A057`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A058`}
            type="option"
          />
          <ChartRowElement
            label="no mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A059`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A060`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A061`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A062`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            fontStyle="italic"
            name={`${sectionDelimiter}A063`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            fontStyle="italic"
            name={`${sectionDelimiter}A064`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            fontStyle="italic"
            name={`${sectionDelimiter}A065`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            fontStyle="italic"
            name={`${sectionDelimiter}A066`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            fontStyle="italic"
            name={`${sectionDelimiter}A067`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A068`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A069`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A070`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T021XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml alignment"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="no FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="no pneumo"
            fontStyle="italic"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            fontStyle="italic"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            fontStyle="italic"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            fontStyle="italic"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            fontStyle="italic"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            fontStyle="italic"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A025`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T022XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            fontStyle="italic"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            fontStyle="italic"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            fontStyle="italic"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            fontStyle="italic"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            fontStyle="italic"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            fontStyle="italic"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A021`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A022`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T024XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T030XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="sinus"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="facial"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A004`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no sinus opacification"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="no air-fluid level"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A018`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A019`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T031XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="nml bowel gas"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="no free air"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="no mass"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="no mediastinum"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="no pneumo"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A021`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A023`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T035XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="spine:"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="C"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="T"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="S"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A007`}
            type="input"
            w="100px"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="skull"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="scapula"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="rib series"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A023`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A026`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A027`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A028`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A029`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A030`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A031`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A032`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A033`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A034`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A035`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A036`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A037`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A038`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no fracture"
            name={`${sectionDelimiter}A039`}
            type="option"
          />
          <ChartRowElement
            label="nml alignment"
            name={`${sectionDelimiter}A040`}
            type="option"
          />
          <ChartRowElement
            label="no FB"
            name={`${sectionDelimiter}A041`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A042`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A043`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            name={`${sectionDelimiter}A044`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            name={`${sectionDelimiter}A045`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            name={`${sectionDelimiter}A046`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            name={`${sectionDelimiter}A047`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            name={`${sectionDelimiter}A048`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A049`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A050`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T036XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="upright abd"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="3-view"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="chest:"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="PA"
            fontStyle="italic"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="lat"
            fontStyle="italic"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="AP"
            fontStyle="italic"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="nml bowel gas"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="no free air"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="no mass"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="no mediastinum"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A022`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A025`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T039XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="upright abd"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="3-view"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="chest:"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="PA"
            fontStyle="italic"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="lat"
            fontStyle="italic"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="AP"
            fontStyle="italic"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="nml bowel gas"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="no free air"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="no mass"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="no mediastinum"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A022`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A025`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T041XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="upright abdomen"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="3-view"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="nml bowel gas"
            fontStyle="italic"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="no mass"
            fontStyle="italic"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            label="no organomegaly"
            fontStyle="italic"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A017`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A018`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T044XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T046XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T052XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no pneumo"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T073XRAYChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Xrays done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="skull"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="facial"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="CUB"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="KUB"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="abdomen"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="spine:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement
            label="C"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="T"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement
            label="S"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A014`}
            type="input"
            w="100px"
          />
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A024`}
            type="input"
            w="200px"
          />
          <ChartRowElement
            label="finger"
            name={`${sectionDelimiter}A025`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A026`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A027`}
            type="option"
          />
          <ChartRowElement
            label="shoulder"
            name={`${sectionDelimiter}A028`}
            type="option"
          />
          <ChartRowElement
            label="clavicle"
            name={`${sectionDelimiter}A029`}
            type="option"
          />
          <ChartRowElement
            label="humerus"
            name={`${sectionDelimiter}A030`}
            type="option"
          />
          <ChartRowElement
            label="elbow"
            name={`${sectionDelimiter}A031`}
            type="option"
          />
          <ChartRowElement
            label="f-arm"
            name={`${sectionDelimiter}A032`}
            type="option"
          />
          <ChartRowElement
            label="wrist"
            name={`${sectionDelimiter}A033`}
            type="option"
          />
          <ChartRowElement
            label="hand"
            name={`${sectionDelimiter}A034`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A035`}
            type="input"
            w="200px"
          />
          <ChartRowElement
            label="finger"
            name={`${sectionDelimiter}A036`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A037`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A038`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A039`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A040`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A041`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A042`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A043`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A044`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A045`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A046`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A047`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A048`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A049`}
            type="option"
          />
          <ChartRowElement
            label="hip"
            name={`${sectionDelimiter}A050`}
            type="option"
          />
          <ChartRowElement
            label="femur"
            name={`${sectionDelimiter}A051`}
            type="option"
          />
          <ChartRowElement
            label="knee"
            name={`${sectionDelimiter}A052`}
            type="option"
          />
          <ChartRowElement
            label="lower leg"
            name={`${sectionDelimiter}A053`}
            type="option"
          />
          <ChartRowElement
            label="ankle"
            name={`${sectionDelimiter}A054`}
            type="option"
          />
          <ChartRowElement
            label="foot"
            name={`${sectionDelimiter}A055`}
            type="option"
          />
          <ChartRowElement
            label="toes"
            name={`${sectionDelimiter}A056`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A057`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A058`}
            type="option"
          />
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A059`}
            type="option"
          />
          <ChartRowElement
            label="scapula"
            name={`${sectionDelimiter}A060`}
            type="option"
          />
          <ChartRowElement
            label="rib series"
            name={`${sectionDelimiter}A061`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A062`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A063`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A064`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A065`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A066`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A067`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD:"
            fontStyle="italic"
            name={`${sectionDelimiter}A068`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A069`}
            type="option"
          />
          <ChartRowElement
            label="nml alignment"
            fontStyle="italic"
            name={`${sectionDelimiter}A070`}
            type="option"
          />
          <ChartRowElement
            label="no FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A071`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A072`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="no infiltrates"
            fontStyle="italic"
            name={`${sectionDelimiter}A073`}
            type="option"
          />
          <ChartRowElement
            label="nml heart size"
            fontStyle="italic"
            name={`${sectionDelimiter}A074`}
            type="option"
          />
          <ChartRowElement
            label="nml mediastinum"
            fontStyle="italic"
            name={`${sectionDelimiter}A075`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A076`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A077`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="fracture"
            fontStyle="italic"
            name={`${sectionDelimiter}A078`}
            type="option"
          />
          <ChartRowElement
            label="dislocation"
            fontStyle="italic"
            name={`${sectionDelimiter}A079`}
            type="option"
          />
          <ChartRowElement
            label="STS"
            fontStyle="italic"
            name={`${sectionDelimiter}A080`}
            type="option"
          />
          <ChartRowElement
            label="effusion"
            fontStyle="italic"
            name={`${sectionDelimiter}A081`}
            type="option"
          />
          <ChartRowElement
            label="fat pad sign"
            fontStyle="italic"
            name={`${sectionDelimiter}A082`}
            type="option"
          />
          <ChartRowElement
            label="DJD"
            fontStyle="italic"
            name={`${sectionDelimiter}A083`}
            type="option"
          />
          <ChartRowElement
            label="FB"
            fontStyle="italic"
            name={`${sectionDelimiter}A084`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A085`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A086`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function XRAYChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C001x: <T001XRAYChart sectionDelimiter={sectionDelimiter} />,
    C014x: <T014XRAYChart sectionDelimiter={sectionDelimiter} />,
    C016x: <T016XRAYChart sectionDelimiter={sectionDelimiter} />,
    C017x: <T017XRAYChart sectionDelimiter={sectionDelimiter} />,
    C021x: <T021XRAYChart sectionDelimiter={sectionDelimiter} />,
    C022x: <T022XRAYChart sectionDelimiter={sectionDelimiter} />,
    C024x: <T024XRAYChart sectionDelimiter={sectionDelimiter} />,
    C030x: <T030XRAYChart sectionDelimiter={sectionDelimiter} />,
    C031x: <T031XRAYChart sectionDelimiter={sectionDelimiter} />,
    C035x: <T035XRAYChart sectionDelimiter={sectionDelimiter} />,
    C036x: <T036XRAYChart sectionDelimiter={sectionDelimiter} />,
    C039x: <T039XRAYChart sectionDelimiter={sectionDelimiter} />,
    C041x: <T041XRAYChart sectionDelimiter={sectionDelimiter} />,
    C044x: <T044XRAYChart sectionDelimiter={sectionDelimiter} />,
    C046x: <T046XRAYChart sectionDelimiter={sectionDelimiter} />,
    C052x: <T052XRAYChart sectionDelimiter={sectionDelimiter} />,
    C073x: <T073XRAYChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { XRAYChart };
