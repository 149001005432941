import { Gender, PatientSearchEncounterStatus } from "enums";
import { PaginationPayload, PaginationResponse } from "types";

import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";

type SearchEncountersElement = {
  encounterId: string;
  fullName: string;
  mrn: string;
  ssn: string;
  dob: string;
  cellphone: string;
  mainEncounter: string;
  encounterStatus: PatientSearchEncounterStatus;
  gender: string;
  encounterNumber: string;
  isVip: boolean;
  painProtocol: boolean;
};

type SearchEncountersResponse = PaginationResponse<SearchEncountersElement>;

type EncounterFilter = Partial<{
  firstName: string;
  lastName: string;
  dob: string | Date;
  gender: Gender | null;
  cellphone: string;
  ssn: string;
  mrn: string;
  wristband: string;
  encounterNumber?: string;
}>;

function trimEncounterFilter(filter: EncounterFilter): EncounterFilter {
  const trimmedFilter: EncounterFilter = {};

  for (const key in filter) {
    if (filter.hasOwnProperty(key)) {
      const value = filter[key as keyof EncounterFilter];
      if (typeof value === "string") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trimmedFilter[key as keyof EncounterFilter] = value.trim();
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trimmedFilter[key as keyof EncounterFilter] = value;
      }
    }
  }

  return trimmedFilter;
}

type UseSearchPatientsProps = EncounterFilter & PaginationPayload;

function useSearchEncounters(props: UseSearchPatientsProps, enabled = true) {
  const { sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useQuery(
    ["searchEncounters", props],
    () =>
      axiosPatientInstance.get<SearchEncountersResponse>("v1/search", {
        params: {
          page,
          pageSize: size,
          sortDesc: descending || false,
          orderBy: sort,
          ...validFilters,
        },
      }),
    { keepPreviousData: true, staleTime: 0, enabled }
  );
}
export { useSearchEncounters, trimEncounterFilter };
export type {
  UseSearchPatientsProps,
  EncounterFilter,
  SearchEncountersElement,
};
