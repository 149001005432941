import * as React from "react";
import { chakra, Box, Center } from "@chakra-ui/react";
import backgroundImg from "assets/background-welcome.jpg";
import logoIcon from "assets/emr-logo.svg";

type AuthLayoutProps = {
  children?: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Box
      bgImage={backgroundImg}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      w="100%"
      minH="100vh"
    >
      <Center flexDirection="column">
        <chakra.img
          src={logoIcon}
          width="240px"
          height="100px"
          marginBottom="3rem"
          marginTop="5rem"
          alt="EMR Logo"
        />
        {children}
      </Center>
    </Box>
  );
};

export { AuthLayout };
