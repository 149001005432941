import { useAuth0 } from "@auth0/auth0-react";
import { axiosIdentityInstance } from "config";
import { useAxiosMutation, useToast } from "hooks";
import { useQueryClient } from "react-query";

function useUpdateProfilePicture() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const updateToken = async () => {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      queryClient.setQueryData<string>(["auth0Token"], () => token);
      queryClient.invalidateQueries([
        "UserProfilePicture",
        { currentUserPicture: true },
      ]);
    } catch (error) {
      if (error instanceof Error) {
        toast({ description: error.message });
      }
    }
  };

  return useAxiosMutation(
    ["identity"],
    (input: FormData) =>
      axiosIdentityInstance.post<string>("v1/user/picture", input),
    {
      onSuccess: () => {
        updateToken();
      },
    }
  );
}

export { useUpdateProfilePicture };
