import * as React from "react";
import {
  FormErrorMessage as CKFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
} from "@chakra-ui/react";

const omittedProps = ["variant", "size", "colorScheme"] as const;
type CkFormErrorMessageProps = Omit<
  ChakraFormErrorMessageProps,
  typeof omittedProps[number]
>;

type FormErrorMessageProps = CkFormErrorMessageProps;

const FormErrorMessage = React.forwardRef<
  HTMLDivElement,
  FormErrorMessageProps
>((props, ref) => {
  return (
    <CKFormErrorMessage
      ref={ref}
      ml="10px"
      fontSize="0.75rem"
      color="red"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      display="block"
      {...props}
    />
  );
});

FormErrorMessage.displayName = "FormErrorMessage";

export type { FormErrorMessageProps };
export { FormErrorMessage };
