import * as yup from "yup";
import { formMessages } from "messages";
import { isAfter, isBefore, isValid } from "date-fns";

type dateInputSchemaProps = {
  errMsg?: string;
  requiredMsg?: string;
  maxDate?: Date;
  minDate?: Date;
  maxDateMsg?: string;
  minDateMsg?: string;
};

function dateInputSchema(props: dateInputSchemaProps) {
  const { errMsg, requiredMsg, maxDate, minDate, maxDateMsg, minDateMsg } =
    props;

  const defaultErrorMessage = formMessages.valid("Date");
  const defaultRequiredMessage = formMessages.required("Date");

  let validation = yup.string().nullable();

  validation = validation.test(
    "valid-date",
    errMsg || defaultErrorMessage,
    (value) => {
      if (!value || value === "") return true;

      if (value.length < 10) return false;

      const date = new Date(value.replace(/-/g, "/"));
      if (!isValid(date)) {
        return false;
      }

      if (Number.isNaN(date.getDate())) {
        return false;
      }

      if (Number.isNaN(date.getMonth())) {
        return false;
      }

      if (Number.isNaN(date.getFullYear())) {
        return false;
      }

      if (isBefore(date, new Date("01/01/1900"))) {
        return false;
      }

      return true;
    }
  );

  if (requiredMsg) {
    validation = validation.required(requiredMsg || defaultRequiredMessage);
  }

  if (maxDate) {
    validation = validation.test(
      "max-date",
      maxDateMsg || defaultErrorMessage,
      (value) => {
        if (!value) return true;

        const date = new Date(value);
        if (!isValid(date)) {
          return true;
        }

        if (maxDate) {
          if (isAfter(date, maxDate)) {
            return false;
          }
        }
        return true;
      }
    );
  }

  if (minDate) {
    validation = validation.test(
      "min-date",
      minDateMsg || defaultErrorMessage,
      (value) => {
        if (!value) return true;

        const date = new Date(value);
        if (!isValid(date)) {
          return true;
        }

        if (minDate) {
          if (isBefore(date, minDate)) {
            return false;
          }
        }
        return true;
      }
    );
  }

  return validation;
}

export { dateInputSchema };
