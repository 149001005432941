import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UpdateSelectedPrimaryCarePhysicianPayload = {
  primaryCarePhysicianId: string;
};

function useUpdateSelectedPrimaryCarePhysician(patientId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updatePrimaryCarePyhisician", { patientId }],
    (input: UpdateSelectedPrimaryCarePhysicianPayload) => {
      return axiosPatientInstance.put<null>(
        `/v1/encounter/${patientId}/form/primary-care-physician/assign`,
        input
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("primaryCarePhysicians");
        queryClient.invalidateQueries("patientInfo");
      },
    }
  );
}

export type { UpdateSelectedPrimaryCarePhysicianPayload };
export { useUpdateSelectedPrimaryCarePhysician };
