import { Button, ButtonProps, HStack, StackProps } from "@chakra-ui/react";
import { contextErrors } from "messages";
import * as React from "react";
import { Link, useMatch } from "react-router-dom";

type NavigationTabProps = {
  to: string;
} & ButtonProps;

type NavigationTabsContextValue = {
  isFitted?: boolean;
};

const NavigationTabsContext = React.createContext<NavigationTabsContextValue>({
  isFitted: false,
});

function useNavigationTabsContext() {
  const context = React.useContext(NavigationTabsContext);

  if (!context) {
    throw new Error(
      contextErrors.missingParent("NavigationTab", "NavigationTabs")
    );
  }

  return context;
}

function NavigationTab(props: NavigationTabProps) {
  const { to, children, ...btnProps } = props;
  const match = useMatch({ path: to, end: false });
  const { isFitted } = useNavigationTabsContext();

  return (
    <Button
      as={Link}
      variant="navigation"
      isActive={!!match}
      to={to}
      flex={isFitted ? 1 : undefined}
      {...btnProps}
    >
      {children}
    </Button>
  );
}

type NavigationTabsProps = {
  isFitted?: boolean;
} & StackProps;

function NavigationTabs(props: NavigationTabsProps) {
  const { isFitted = false, children, ...stackProps } = props;
  const value = React.useMemo(() => ({ isFitted }), [isFitted]);

  return (
    <HStack
      spacing="20px"
      width={isFitted ? "100%" : undefined}
      {...stackProps}
    >
      <NavigationTabsContext.Provider value={value}>
        {children}
      </NavigationTabsContext.Provider>
    </HStack>
  );
}

export type { NavigationTabProps, NavigationTabsProps };
export { NavigationTab, NavigationTabs };
