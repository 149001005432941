import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C001x";

function T01ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });

      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );

      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Laceration:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abrasion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contusion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Neck"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Spine:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fracture:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                />
                <ChartRowElement
                  label="closed"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="open"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dislocation"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Concussion:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                />
                <ChartRowElement
                  label="lost consciousness:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="no"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A037`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="seconds"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="minutes"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Post-concussion syndrome"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cerebral:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A038`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic hemorrhage"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epidural"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Subdural"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Traumatic SAH"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cord Injury:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A039`}
                />
                <ChartRowElement
                  label="incomplete"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="level"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="C: #"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="T: #"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A041`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="L: #"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A042`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="sacral"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Seizure"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Eyes - Nontrauma"
            >
              Eyes - Nontrauma
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Blepharitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A099`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper lid"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower lid"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ulcerative"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="seborrheic"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="staph"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Central retinal vein occlusion:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A100`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Conjunctivitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A101`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A102`}
                />
                <ChartRowElement
                  label="simple"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="atopic"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral epidemic"
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="follicular"
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mucopurulent"
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A103`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Chalazion:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A104`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper lid"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower lid"
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Corneal ulcer:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A105`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="central"
                  name={`Q007.${chartCode}${sectionId}Q007A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="peripheral"
                  name={`Q007.${chartCode}${sectionId}Q007A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hypopyon"
                  name={`Q007.${chartCode}${sectionId}Q007A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cotton wool spots:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A106`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dacrocystitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A107`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Glaucoma:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A108`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A109`}
                />
                <ChartRowElement
                  label="open-angle"
                  name={`Q007.${chartCode}${sectionId}Q007A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="angle-closure"
                  name={`Q007.${chartCode}${sectionId}Q007A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q007.${chartCode}${sectionId}Q007A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q007.${chartCode}${sectionId}Q007A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q007.${chartCode}${sectionId}Q007A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A046`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypopyon:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A110`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A047`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Iritis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A111`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="primary"
                  name={`Q007.${chartCode}${sectionId}Q007A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="secondary"
                  name={`Q007.${chartCode}${sectionId}Q007A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="infectious"
                  name={`Q007.${chartCode}${sectionId}Q007A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="noninfectious"
                  name={`Q007.${chartCode}${sectionId}Q007A056`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A057`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Keratitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A112`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A059`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="UV"
                  name={`Q007.${chartCode}${sectionId}Q007A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="exposure"
                  name={`Q007.${chartCode}${sectionId}Q007A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="punctate"
                  name={`Q007.${chartCode}${sectionId}Q007A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="herpetic"
                  name={`Q007.${chartCode}${sectionId}Q007A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A064`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ocular hypertension:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A113`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A066`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A067`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Orbital cellulitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A114`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A068`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A069`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A070`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Periorbital cellulitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A115`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A071`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A072`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A073`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Retinal artery occlusion:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A116`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A074`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A075`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="central"
                  name={`Q007.${chartCode}${sectionId}Q007A076`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="branch"
                  name={`Q007.${chartCode}${sectionId}Q007A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="transient"
                  name={`Q007.${chartCode}${sectionId}Q007A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A079`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Retinal detachment:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A117`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A080`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A081`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A082`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Retinal hemorrhage:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A118`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A083`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A084`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A085`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sty:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A119`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A086`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A087`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper lid"
                  name={`Q007.${chartCode}${sectionId}Q007A088`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower lid"
                  name={`Q007.${chartCode}${sectionId}Q007A089`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="internal"
                  name={`Q007.${chartCode}${sectionId}Q007A090`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="external"
                  name={`Q007.${chartCode}${sectionId}Q007A091`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A092`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vision Loss:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A120`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A093`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A094`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sudden"
                  name={`Q007.${chartCode}${sectionId}Q007A095`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="transient"
                  name={`Q007.${chartCode}${sectionId}Q007A096`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A097`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A098`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Eyes - Trauma"
            >
              Eyes - Trauma
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Chemical exposure:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A046`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A047`}
                />
                <ChartRowElement
                  label="conjunctivitis"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="corneal burn"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="substance:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A048`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  w="573px"
                  type="input"
                />
                <ChartRowElement
                  label="by accident"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="assault"
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  type="cross-option"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Conjunctiva FB:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A049`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Conjunctival hemorrhage:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A050`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Corneal abrasion:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A051`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ FB"
                  name={`Q008.${chartCode}${sectionId}Q008A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Corneal FB:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A052`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Corneal laceration:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A053`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ loss of intraocular contents"
                  name={`Q008.${chartCode}${sectionId}Q008A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hyphema:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A054`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Orbit fracture:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A055`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="floor"
                  name={`Q008.${chartCode}${sectionId}Q008A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="roof"
                  name={`Q008.${chartCode}${sectionId}Q008A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ entrapment"
                  name={`Q008.${chartCode}${sectionId}Q008A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Penetrating wnd to:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A056`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="eyeball"
                  name={`Q008.${chartCode}${sectionId}Q008A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="orbit"
                  name={`Q008.${chartCode}${sectionId}Q008A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ FB"
                  name={`Q008.${chartCode}${sectionId}Q008A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Scleral laceration:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A057`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ loss of intraocular contents"
                  name={`Q008.${chartCode}${sectionId}Q008A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A044`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A045`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Diabetes:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A045`}
                />
                <ChartRowElement
                  label="Type 1"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Type 2"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="with complications"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  w="231px"
                  type="input"
                />
                <ChartRowElement
                  label="with retinpathy"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NPDR:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A046`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mod"
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sv"
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PDR"
                  name={`Q009.${chartCode}${sectionId}Q009A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="with macular edema"
                  name={`Q009.${chartCode}${sectionId}Q009A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Eye pain:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A047`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A048`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q009.${chartCode}${sectionId}Q009A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q009.${chartCode}${sectionId}Q009A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q009.${chartCode}${sectionId}Q009A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="GSW"
                  name={`Q009.${chartCode}${sectionId}Q009A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="stab"
                  name={`Q009.${chartCode}${sectionId}Q009A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="penetrating injury"
                  name={`Q009.${chartCode}${sectionId}Q009A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A049`}
                />
                <ChartRowElement
                  label="head"
                  name={`Q009.${chartCode}${sectionId}Q009A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q009.${chartCode}${sectionId}Q009A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant"
                  name={`Q009.${chartCode}${sectionId}Q009A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q009.${chartCode}${sectionId}Q009A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thorax"
                  name={`Q009.${chartCode}${sectionId}Q009A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pain:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A050`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q009.${chartCode}${sectionId}Q009A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q009.${chartCode}${sectionId}Q009A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q009.${chartCode}${sectionId}Q009A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
                <ChartRowElement
                  label="T-spine"
                  name={`Q009.${chartCode}${sectionId}Q009A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shock"
                  name={`Q009.${chartCode}${sectionId}Q009A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q009.${chartCode}${sectionId}Q009A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypovolemic"
                  name={`Q009.${chartCode}${sectionId}Q009A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tooth #:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A051`}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A034`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="fx"
                  name={`Q009.${chartCode}${sectionId}Q009A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="avulsion"
                  name={`Q009.${chartCode}${sectionId}Q009A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sublux"
                  name={`Q009.${chartCode}${sectionId}Q009A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q009.${chartCode}${sectionId}Q009A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q009.${chartCode}${sectionId}Q009A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q009.${chartCode}${sectionId}Q009A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A044`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T01ClinicalImpression };
