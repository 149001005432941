import * as React from "react";
import * as yup from "yup";

/**
 * This custom yup validation resolver hook was introduced because the default
 * `yupResolver` function from "@hookform/resolvers" has some type errors when
 * dealing with select options (SelectOption<T>). Since no fix has been found,
 * this hook can provide the same functionality without type errors.
 *
 * Code mostly taken from https://react-hook-form.com/advanced-usage#CustomHookwithResolver
 *
 * @param validationSchema
 * @returns An object with either the form values or validation errors
 */
const useYupValidationResolver = <T extends yup.AnyObjectSchema>(
  validationSchema: T
) =>
  React.useCallback(
    async (data: unknown) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        const yupErrors = errors as yup.ValidationError;

        return {
          values: {},
          errors:
            yupErrors.inner?.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [String(currentError.path)]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ) || {},
        };
      }
    },
    [validationSchema]
  );

export { useYupValidationResolver };
