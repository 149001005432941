import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

export type UseChartSectionNotesCountParams = {
  encounterId: string;
  chartCode?: string;
  sectionCode: string;
};

/**
 * get the count section notes by encounterId
 * @param params
 * @returns
 */
export function useChartSectionNotesCount(
  params: UseChartSectionNotesCountParams
) {
  const { encounterId, chartCode, sectionCode } = params;
  return useAxiosQuery(
    ["sectionNotesCount", { encounterId, chartCode, sectionCode }],
    () => {
      // if the chart code is not specified, use only the section code
      if (chartCode) {
        return axiosChartInstance.get<number>(
          `v1/chart-application/${encounterId}/section-notes-count/${chartCode}/${sectionCode}`
        );
      } else {
        return axiosChartInstance.get<number>(
          `v1/chart-application/${encounterId}/section-notes-count/${sectionCode}`
        );
      }
    }
  );
}
