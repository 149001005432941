const parts = ["root", "dropdownmenuitem"];

const baseStyle = {
  root: {
    width: "100%",
    backgroundColor: "white",
    overflow: "auto",
    boxShadow:
      "0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06)",
    borderRadius: "10px",
  },
  dropdownmenuitem: {
    height: "48px",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    _selected: {
      backgroundColor: "blue",
      color: "white",
    },
    _hover: { cursor: "pointer" },
  },
};

const dropdownMenuTheme = { baseStyle, parts };
export { dropdownMenuTheme };
