import {
  UseCheckboxGroupProps,
  useCheckboxGroup,
  Box,
  CheckboxProps,
  Divider,
  BoxProps,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";
import { isLastIndex } from "utils";

type CheckboxListGroupProps = {
  divider?: React.ReactElement;
} & UseCheckboxGroupProps &
  Omit<BoxProps, keyof UseCheckboxGroupProps>;

const CheckboxListGroup = React.forwardRef<
  HTMLDivElement,
  CheckboxListGroupProps
>((props, ref) => {
  // This component expects children of type CheckboxListItem,
  // otherwise it won't work as expected
  const {
    value: propValue,
    defaultValue,
    onChange,
    isDisabled,
    isNative,
    children,
    divider,
    ...boxProps
  } = props;
  const childrenArray = React.Children.toArray(children);
  const { getCheckboxProps } = useCheckboxGroup({
    value: propValue,
    defaultValue,
    onChange,
    isDisabled,
    isNative,
  });

  return (
    <Box
      bg="white"
      borderRadius="10px"
      display="flex"
      flexDirection="column"
      ref={ref}
      {...boxProps}
    >
      {childrenArray.map((child, index) => {
        if (React.isValidElement<CheckboxProps>(child)) {
          const {
            props: { value },
          } = child;
          const checkbox = getCheckboxProps({ value });
          return (
            <React.Fragment key={value}>
              {React.cloneElement(child, { ...checkbox })}
              {!isLastIndex(index, childrenArray) &&
                (divider ? (
                  React.cloneElement(divider)
                ) : (
                  <Divider
                    color="gray.450"
                    opacity={1}
                    width="calc(100% - 16px)"
                    alignSelf="flex-end"
                  />
                ))}
            </React.Fragment>
          );
        }
        return child;
      })}
    </Box>
  );
});

if (config.isDev) {
  CheckboxListGroup.displayName = "CheckboxListGroup";
}

export { CheckboxListGroup };
