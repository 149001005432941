import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const QuestionMarkCircle = createIcon({
  path: (
    <path
      d="M12 24C18.5647 24 24 18.5529 24 12C24 5.43529 18.5529 0 11.9882 0C5.43529 0 0 5.43529 0 12C0 18.5529 5.44706 24 12 24ZM12 22C6.44706 22 2.01176 17.5529 2.01176 12C2.01176 6.44706 6.43529 2 11.9882 2C17.5412 2 22 6.44706 22 12C22 17.5529 17.5529 22 12 22ZM11.7529 14.4353C12.3412 14.4353 12.7059 14.0588 12.7059 13.6V13.4588C12.7059 12.8 13.0824 12.3765 13.9059 11.8353C15.0471 11.0824 15.8588 10.4 15.8588 8.98824C15.8588 7.03529 14.1176 5.97647 12.1176 5.97647C10.0941 5.97647 8.76471 6.94118 8.44706 8.02353C8.38824 8.21177 8.35294 8.4 8.35294 8.6C8.35294 9.12941 8.76471 9.41177 9.15294 9.41177C9.55294 9.41177 9.81176 9.22353 10.0235 8.94118L10.2353 8.65882C10.6471 7.97647 11.2588 7.57647 12.0471 7.57647C13.1176 7.57647 13.8118 8.18824 13.8118 9.08235C13.8118 9.88235 13.3176 10.2706 12.2941 10.9882C11.4471 11.5765 10.8118 12.2 10.8118 13.3529V13.5059C10.8118 14.1176 11.1529 14.4353 11.7529 14.4353ZM11.7294 17.9412C12.4118 17.9412 13 17.4 13 16.7176C13 16.0353 12.4235 15.4941 11.7294 15.4941C11.0353 15.4941 10.4588 16.0471 10.4588 16.7176C10.4588 17.3882 11.0471 17.9412 11.7294 17.9412Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
});

export { QuestionMarkCircle };
