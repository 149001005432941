import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { Pharmacy } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

function useRemovePreferredPharmacy(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["removePreferredPharmacy"],
    () =>
      axiosPatientInstance.delete<Pharmacy>(
        `/v1/encounter/${encounterId}/form/preferred-pharmacy/remove`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patientInfo", { encounterId }]);
      },
    }
  );
}

export { useRemovePreferredPharmacy };
