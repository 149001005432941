import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const chartCode = "T015x";

function N15Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { watch } = useFormContext;

  const q001AllNormal = Boolean(watch(`Q001.${chartCode}${sectionId}Q001A001`));
  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal = Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`));
  const q009AllNormal = Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`));

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Date / Time:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="date"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nml"
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q001.${chartCode}${sectionId}Q001A005`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q001.${chartCode}${sectionId}Q001A006`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q001.${chartCode}${sectionId}Q001A007`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A008`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway patent"
                    name={`Q001.${chartCode}${sectionId}Q001A009`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway compromised"
                    name={`Q001.${chartCode}${sectionId}Q001A010`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A011`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breathing unlabored"
                    name={`Q001.${chartCode}${sectionId}Q001A012`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labored breathing"
                    name={`Q001.${chartCode}${sectionId}Q001A013`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A030`}
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q001.${chartCode}${sectionId}Q001A014`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q001.${chartCode}${sectionId}Q001A015`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q001.${chartCode}${sectionId}Q001A016`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A017`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="circulation nml"
                    name={`Q001.${chartCode}${sectionId}Q001A018`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pale"
                    name={`Q001.${chartCode}${sectionId}Q001A019`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q001.${chartCode}${sectionId}Q001A020`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A021`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neuro awake & alert"
                    name={`Q001.${chartCode}${sectionId}Q001A022`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lethargic"
                    name={`Q001.${chartCode}${sectionId}Q001A023`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="obtunded"
                    name={`Q001.${chartCode}${sectionId}Q001A024`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="anxious"
                    name={`Q001.${chartCode}${sectionId}Q001A025`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A026`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q001.${chartCode}${sectionId}Q001A027`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A028`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q002.${chartCode}${sectionId}Q002A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x4"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="speech abnormalities"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="cognition abnormalities"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pupils unequal"
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    w="50px"
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A026`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    w="50px"
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A027`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="moves all extremities"
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="weakness"
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="sensory loss"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="facial droop"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q002.${chartCode}${sectionId}Q002A023`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A024`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="HEENT"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q003.${chartCode}${sectionId}Q003A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              HEENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml inspection"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="foreign body visible"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mouth ulcerations"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="trouble handing secretions"
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="changed voice:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="hoarse"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="muffled"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml mucous membranes"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Abdomen / GU"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q004.${chartCode}${sectionId}Q004A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft / non-tender"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no distension"
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="rigid"
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="distended"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="bruit"
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="pulsatile mass"
                    name={`Q004.${chartCode}${sectionId}Q004A011`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A012`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hepatomegaly"
                    name={`Q004.${chartCode}${sectionId}Q004A013`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="splenomegaly"
                    name={`Q004.${chartCode}${sectionId}Q004A014`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="mass"
                    name={`Q004.${chartCode}${sectionId}Q004A015`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A016`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="emesis"
                    name={`Q004.${chartCode}${sectionId}Q004A017`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A018`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds nml"
                    name={`Q004.${chartCode}${sectionId}Q004A019`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds"
                    name={`Q004.${chartCode}${sectionId}Q004A020`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="hyper"
                    name={`Q004.${chartCode}${sectionId}Q004A021`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="hypo"
                    name={`Q004.${chartCode}${sectionId}Q004A022`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="absent"
                    name={`Q004.${chartCode}${sectionId}Q004A023`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A024`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no discharge"
                    name={`Q004.${chartCode}${sectionId}Q004A025`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="penile discharge"
                    name={`Q004.${chartCode}${sectionId}Q004A026`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="vaginal discharge"
                    name={`Q004.${chartCode}${sectionId}Q004A027`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A028`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no bleeding"
                    name={`Q004.${chartCode}${sectionId}Q004A029`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="vaginal bleeding"
                    name={`Q004.${chartCode}${sectionId}Q004A030`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A031`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml perineal exam"
                    name={`Q004.${chartCode}${sectionId}Q004A032`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="perineal:"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A046`}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q004.${chartCode}${sectionId}Q004A033`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="redness"
                    name={`Q004.${chartCode}${sectionId}Q004A034`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="lesions"
                    name={`Q004.${chartCode}${sectionId}Q004A035`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A036`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml scrotal exam"
                    name={`Q004.${chartCode}${sectionId}Q004A037`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scrotal:"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A047`}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q004.${chartCode}${sectionId}Q004A038`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="redness"
                    name={`Q004.${chartCode}${sectionId}Q004A039`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="lesions"
                    name={`Q004.${chartCode}${sectionId}Q004A040`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A041`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q004.${chartCode}${sectionId}Q004A042`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A043`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q004.${chartCode}${sectionId}Q004A044`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A045`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Chest"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q005.${chartCode}${sectionId}Q005A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no respiratory distress"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A028`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml breath sounds"
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breath sounds"
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="tachypnea"
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="wheezing"
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="stridor"
                    name={`Q005.${chartCode}${sectionId}Q005A013`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="crackles"
                    name={`Q005.${chartCode}${sectionId}Q005A014`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="grunting"
                    name={`Q005.${chartCode}${sectionId}Q005A015`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A016`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="accessory muscle use"
                    name={`Q005.${chartCode}${sectionId}Q005A017`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="retractions"
                    name={`Q005.${chartCode}${sectionId}Q005A018`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="splinting"
                    name={`Q005.${chartCode}${sectionId}Q005A019`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="nasal flaring"
                    name={`Q005.${chartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="orthopnea"
                    name={`Q005.${chartCode}${sectionId}Q005A021`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="exertional SOB"
                    name={`Q005.${chartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="tripod"
                    name={`Q005.${chartCode}${sectionId}Q005A023`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="choking"
                    name={`Q005.${chartCode}${sectionId}Q005A024`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A025`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q005.${chartCode}${sectionId}Q005A026`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A027`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="CVS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q006.${chartCode}${sectionId}Q006A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="regular rate & rythm"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="murmur"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="grade"
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    w="50px"
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="/ 6"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q006.${chartCode}${sectionId}Q006A020`}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="sys / dias"
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulses strong / equal"
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cap refill less than 2 sec"
                    name={`Q006.${chartCode}${sectionId}Q006A015`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cap refill greater than 2 sec"
                    name={`Q006.${chartCode}${sectionId}Q006A016`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A017`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q006.${chartCode}${sectionId}Q006A018`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A019`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Extremities"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q007.${chartCode}${sectionId}Q007A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="calf tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="edema"
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="strong periph pulses"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="peripheral pulses"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="weak"
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="thready"
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml capillary refill"
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="slow capillary refill"
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    w="50px"
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="sec"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Skin"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q008.${chartCode}${sectionId}Q008A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="warm / dry"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cool"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="cyanotic"
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="flushed"
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="mottled"
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="increased warmth"
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="color nml, no rash"
                    name={`Q008.${chartCode}${sectionId}Q008A010`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q008.${chartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="diaper rash"
                    name={`Q008.${chartCode}${sectionId}Q008A012`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q008.${chartCode}${sectionId}Q008A013`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A014`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q008.${chartCode}${sectionId}Q008A015`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ulcers"
                    name={`Q008.${chartCode}${sectionId}Q008A016`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="sacrum"
                    name={`Q008.${chartCode}${sectionId}Q008A017`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="hip"
                    name={`Q008.${chartCode}${sectionId}Q008A018`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="heel"
                    name={`Q008.${chartCode}${sectionId}Q008A019`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A020`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="trugor good"
                    name={`Q008.${chartCode}${sectionId}Q008A021`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="poor turgor"
                    name={`Q008.${chartCode}${sectionId}Q008A022`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A023`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q008.${chartCode}${sectionId}Q008A024`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A025`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q009.${chartCode}${sectionId}Q009A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="development age appropriate"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="obese"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="malnourished"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="dehydrated"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nourished / hydrated"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="recent weight loss"
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="recent weight gain"
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A012`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N15Assessments };
