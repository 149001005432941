import * as React from "react";
import { EmrSearch } from "@medstonetech/slate-icons";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  useControllableState,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { InputProps } from "shared";
import { CloseOutline } from "icons";

const SearchBar = React.forwardRef<HTMLInputElement, Omit<InputProps, "size">>(
  (props, ref) => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none" height="100%">
          <Icon
            as={EmrSearch}
            color="#8E8E93"
            alignSelf="center"
            display="flex"
            fontSize="1rem"
          />
        </InputLeftElement>
        <Input variant="search" ref={ref} {...props} />
      </InputGroup>
    );
  }
);

SearchBar.displayName = "SearchBar";

type SearchBarClearProps = {
  defaultValue?: string | undefined;
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
} & Omit<InputProps, "value" | "onChange" | "size">;

const SearchBarClear = React.forwardRef<HTMLInputElement, SearchBarClearProps>(
  (props, ref) => {
    const { defaultValue = "", value, onChange, ...rest } = props;

    const [internalValue, setInternalValue] = useControllableState({
      defaultValue,
      value,
      onChange,
    });

    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none" height="100%">
          <Icon
            as={EmrSearch}
            color="gray.700"
            alignSelf="center"
            display="flex"
            fontSize="1rem"
          />
        </InputLeftElement>
        <Input
          variant="search"
          bg="gray.350"
          placeholder="Search"
          _placeholder={{ opacity: 1, color: "gray.700" }}
          ref={ref}
          value={internalValue}
          onChange={(e) => setInternalValue(e.target.value)}
          {...rest}
        />
        {!!internalValue && (
          <InputRightElement
            children={
              <IconButton
                variant="icon"
                icon={
                  <Icon as={CloseOutline} h="17px" w="17px" color="gray.650" />
                }
                onClick={() => setInternalValue("")}
                aria-label="clear"
                h="40px"
                w="40px"
              />
            }
          />
        )}
      </InputGroup>
    );
  }
);

SearchBar.displayName = "SearchBarClear";

export { SearchBar, SearchBarClear };
