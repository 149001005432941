import { axiosChartInstance } from "config";
import { EncounterStatus, UserSignatureType } from "enums";
import { useAxiosQuery } from "hooks";
import {
  ChartApplicationStatus,
  ChartFormSubsection,
  DispositionSignatureForm,
} from "modules";
import { CreatedBy } from "types";

type UseChartDispositionParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
};

type SignatureInfo = {
  id: string;
  signatureUserType: string;
  userId: string;
  userName: string;
  createdAt: Date;
  signature: string;
};

type InPresenceOf = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  pictureUrl: string;
};

type DispositionResponseSignatureInfo = SignatureInfo & {
  inPresenceOf: InPresenceOf;
};

type UseChartDispositionResponse = {
  subsections: ChartFormSubsection[];
  createdBy: CreatedBy;
  userSignatureType: UserSignatureType;
  nurseSignature: DispositionResponseSignatureInfo;
  scribeSignature: DispositionResponseSignatureInfo;
  supervisorSignature: DispositionResponseSignatureInfo;
  actingProviderSignature: DispositionResponseSignatureInfo;
  signatures: DispositionSignatureForm[];
  encounterStatus?: EncounterStatus;
  chartStatus?: ChartApplicationStatus;
};

function useChartDisposition(params: UseChartDispositionParams) {
  const { encounterId, chartCode, sectionId } = params;
  return useAxiosQuery(
    ["chartDisposition", { encounterId, chartCode, sectionId }],
    () =>
      axiosChartInstance.get<UseChartDispositionResponse>(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionId}/disposition`
      )
  );
}

export type {
  UseChartDispositionParams,
  SignatureInfo,
  DispositionResponseSignatureInfo,
};
export { useChartDisposition };
