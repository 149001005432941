import { Box } from "@chakra-ui/react";
import {
  F001Interpretation,
  F002Interpretation,
  F003Interpretation,
  F004Interpretation,
  F005Interpretation,
  F006Interpretation,
  F007Interpretation,
  F008Interpretation,
  F009Interpretation,
  F010Interpretation,
  F011Interpretation,
  F012Interpretation,
  F013Interpretation,
  F014Interpretation,
  F015Interpretation,
  F016Interpretation,
  F017Interpretation,
  F018Interpretation,
  F019Interpretation,
  F020Interpretation,
  F021Interpretation,
  F022Interpretation,
  D001Interpretation,
  D002Interpretation,
  D003Interpretation,
  D004Interpretation,
  D005Interpretation,
  D006Interpretation,
  D007Interpretation,
  D008Interpretation,
  D009Interpretation,
  D010Interpretation,
  D011Interpretation,
  D012Interpretation,
  D013Interpretation,
  D014Interpretation,
  D015Interpretation,
} from "../radiology-charts";
import { UseOrderChartInterpretation } from "modules/provider/api";
import { RadiologyInterpretationsType } from "./constants";

type InterpretationElementsProps = {
  index: number;
  type: RadiologyInterpretationsType;
  isReadOnly?: boolean;
  encounterId: string;
  sectionCode: string;
  chartCode: string;
  interpretations: UseOrderChartInterpretation[];
  onRemoveElement: (id: string, type: RadiologyInterpretationsType) => void;
};

function InterpretationElements(props: InterpretationElementsProps) {
  const {
    index,
    type,
    interpretations,
    encounterId,
    chartCode,
    sectionCode,
    isReadOnly,
    onRemoveElement,
  } = props;

  const onRemoveCallback = (id: string) => {
    onRemoveElement(id, type);
  };

  const getInterpretationComponent = (
    interpretation: UseOrderChartInterpretation
  ) => {
    switch (interpretation.code) {
      case "F001":
        return (
          <F001Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F002":
        return (
          <F002Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F003":
        return (
          <F003Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F004":
        return (
          <F004Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F005":
        return (
          <F005Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F006":
        return (
          <F006Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F007":
        return (
          <F007Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F008":
        return (
          <F008Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F009":
        return (
          <F009Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F010":
        return (
          <F010Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F011":
        return (
          <F011Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F012":
        return (
          <F012Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F013":
        return (
          <F013Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F014":
        return (
          <F014Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F015":
        return (
          <F015Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F016":
        return (
          <F016Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F017":
        return (
          <F017Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F018":
        return (
          <F018Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F019":
        return (
          <F019Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F020":
        return (
          <F020Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F021":
        return (
          <F021Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "F022":
        return (
          <F022Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );

      case "D001":
        return (
          <D001Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D002":
        return (
          <D002Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D003":
        return (
          <D003Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D004":
        return (
          <D004Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D005":
        return (
          <D005Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D006":
        return (
          <D006Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D007":
        return (
          <D007Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D008":
        return (
          <D008Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D009":
        return (
          <D009Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D010":
        return (
          <D010Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D011":
        return (
          <D011Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D012":
        return (
          <D012Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D013":
        return (
          <D013Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D014":
        return (
          <D014Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
      case "D015":
        return (
          <D015Interpretation
            index={index}
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={interpretation.sectionId}
            sectionCode={sectionCode}
            sectionSubCode={interpretation.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveCallback}
          />
        );
    }
  };

  return (
    <Box display="flex" width="100%" columnGap="35px" flexDirection="column">
      {interpretations.map((interpretation, idx) => (
        <Box key={idx} marginTop="1rem" marginBottom="1rem" width="100%">
          {getInterpretationComponent(interpretation)}
        </Box>
      ))}
    </Box>
  );
}

export { InterpretationElements };
