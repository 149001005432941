import React, { forwardRef } from "react";
import {
  Input,
  InputProps,
  useControllableState,
  useMergeRefs,
} from "@chakra-ui/react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { useDivideControlProps } from "shared/form/hooks";

type NumberFormatInputProps = {
  value?: string | null;
  defaultValue?: string | null;
  onChange?: (value: string | null) => void;
} & Omit<InputProps, "value" | "onChange">;

const NumberFormatInput = forwardRef<HTMLInputElement, NumberFormatInputProps>(
  (props, ref) => {
    const { onChange, value, defaultValue, type, ...rest } = props;

    const maskedInput = React.useRef<HTMLInputElement>(null);
    const refs = useMergeRefs(ref, maskedInput);
    const [cursorPosition, setCursorPosition] = React.useState<{
      pos: number;
    } | null>(null);

    const maskValueInternal = (text: string | null) => {
      let parts =
        text?.replace(/[^\d\.]/g, "").match(/^\d*(\.\d{0,6})?$/) || [];
      parts = parts.filter((p) => !!p);

      return parts[0] ?? "";
    };

    const valueInterceptor = React.useMemo(
      () => maskValueInternal(value || ""),
      [value]
    );

    const [internalValue, setInternalValue] = useControllableState({
      value: valueInterceptor,
      onChange,
    });

    const handleChange = (inputEvent?: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = maskedInput?.current?.value || "";
      const validValue = /^\d*(\.\d{0,6})?$/.test(currentValue);
      if (inputEvent) {
        const currentStartCursorPosition =
          (inputEvent?.currentTarget?.selectionStart || 0) -
          (validValue ? 0 : 1);
        const currentCursorPosition =
          inputEvent?.currentTarget?.selectionStart !== null
            ? Math.max(0, currentStartCursorPosition)
            : Math.max(0, currentValue.length - 1);
        setCursorPosition({ pos: currentCursorPosition });
      }
      if (validValue) {
        const componentParts = maskValueInternal(currentValue);
        setInternalValue(componentParts);
      }
    };

    React.useEffect(() => {
      const componentParts = maskValueInternal(internalValue || "");
      setInternalValue(componentParts);
    }, [internalValue, setInternalValue]);

    React.useEffect(() => {
      if (cursorPosition && maskedInput)
        maskedInput?.current?.setSelectionRange(
          cursorPosition.pos,
          cursorPosition.pos
        );
    }, [maskedInput, cursorPosition]);

    return (
      <Input
        ref={refs}
        value={internalValue || ""}
        onChange={handleChange}
        autoComplete="nope"
        onKeyDown={(e) => {
          // Prevent propagation if the character typed is invalid
          if (e.key.length === 1 && !/^[\d\.]$/.test(e.key)) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        {...rest}
      />
    );
  }
);

NumberFormatInput.displayName = "NumberInput";

const omittedProps = ["selected", "name", "onChange"] as const;

type FormInputProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<NumberFormatInputProps, typeof omittedProps[number]> & {
      onChange?: (value: string | null) => void;
    };

function FormInputInner<TFieldValues extends FieldValues>(
  props: FormInputProps<TFieldValues>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const { onChange: onChangeProp, ...restProps } = props;
  const [inputProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormInputProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, value, onChange, ...restField },
  } = useController(controllerProps);

  const inputRef = useMergeRefs(ref, fieldRef);

  const handleChange: (value: string | null) => void = (data) => {
    onChangeProp?.(data);
    onChange(data);
  };

  return (
    <NumberFormatInput
      ref={inputRef}
      {...inputProps}
      {...restField}
      value={value}
      onChange={handleChange}
    />
  );
}

const FormNumberFormatInput = React.forwardRef(FormInputInner) as <
  TFieldValues extends FieldValues
>(
  props: FormInputProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof FormInputInner>;

export type { NumberFormatInputProps };
export { NumberFormatInput, FormNumberFormatInput };
