const SCROLLBARSTYLE = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#C4C4C4",
    borderRadius: "24px",
  },
};

export { SCROLLBARSTYLE };
