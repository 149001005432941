import { Box } from "@chakra-ui/react";
import { ChartFormProcedureSection } from "modules/charts-shared";
import {
  S001Procedure,
  S002Procedure,
  S003Procedure,
  S004Procedure,
  S005Procedure,
  S006Procedure,
  S007Procedure,
  S008Procedure,
  S009Procedure,
  S010Procedure,
  S011Procedure,
  S012Procedure,
  S013Procedure,
  S014Procedure,
  S015Procedure,
  S016Procedure,
  S017Procedure,
  S018Procedure,
  S019Procedure,
  S020Procedure,
  S021Procedure,
  S022Procedure,
  S023Procedure,
  S024Procedure,
  S025Procedure,
} from "../procedure-charts";

type ProcedureElementsProps = {
  isReadOnly: boolean;
  encounterId: string;
  sectionCode: string;
  chartCode: string;
  procedures: ChartFormProcedureSection[];
  onRemoveElement: (id: string) => void;
};

function ProcedureElements(props: ProcedureElementsProps) {
  const {
    procedures,
    encounterId,
    chartCode,
    sectionCode,
    isReadOnly,
    onRemoveElement,
  } = props;

  const getProcedureComponent = (procedure: ChartFormProcedureSection) => {
    switch (procedure.code) {
      case "S001":
        return (
          <S001Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S002":
        return (
          <S002Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S003":
        return (
          <S003Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S004":
        return (
          <S004Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S005":
        return (
          <S005Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S006":
        return (
          <S006Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S007":
        return (
          <S007Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S008":
        return (
          <S008Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S009":
        return (
          <S009Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S010":
        return (
          <S010Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S011":
        return (
          <S011Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S012":
        return (
          <S012Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S013":
        return (
          <S013Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S014":
        return (
          <S014Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S015":
        return (
          <S015Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S016":
        return (
          <S016Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S017":
        return (
          <S017Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S018":
        return (
          <S018Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S019":
        return (
          <S019Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S020":
        return (
          <S020Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S021":
        return (
          <S021Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S022":
        return (
          <S022Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S023":
        return (
          <S023Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S024":
        return (
          <S024Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
      case "S025":
        return (
          <S025Procedure
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={procedure.id}
            sectionCode={sectionCode}
            sectionSubCode={procedure.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        );
    }
  };

  return (
    <Box display="flex" width="100%" columnGap="35px" flexDirection="column">
      {procedures.map((procedure, index) => (
        <Box key={index} marginTop="5px" marginBottom="5px" width="100%">
          {getProcedureComponent(procedure)}
        </Box>
      ))}
    </Box>
  );
}

export { ProcedureElements };
