import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { BackButton, Loading } from "shared";
import { BaseModalProps } from "types";
import { DiagramCanvasPreview } from "./DiagramCanvasPreview";
import { DiagramsPreview } from "./DiagramsPreview";
import { useDiagramCategories, useDiagrams } from "./api/queries";

type DiagramsPickerModalProps = BaseModalProps & {
  encounterId: string;
  chartCode: string;
};

type DiagramsPickerModalContentProps = Omit<DiagramsPickerModalProps, "isOpen">;

const DIVIDER_MARGIN = "15px";

function DiagramsPickerModalContent(props: DiagramsPickerModalContentProps) {
  const { onClose, encounterId, chartCode } = props;
  const { data, isLoading } = useDiagramCategories();
  const { data: diagrams, isLoading: isLoadingDiagrams } = useDiagrams(
    encounterId,
    chartCode
  );

  return (
    <>
      <ModalHeader
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="gray.200"
      >
        <BackButton position="absolute" left="20px" onClick={onClose}>
          Diagrams
        </BackButton>
        <Box fontSize="1.0625rem" fontWeight="600">
          Add Diagrams
        </Box>
      </ModalHeader>
      <ModalBody padding="15px 0 15px 20px">
        <DiagramsPreview title="Head">
          {data &&
            data.data?.heads.map((diagramInfo) => (
              <DiagramCanvasPreview
                encounterId={encounterId}
                chartCode={chartCode}
                key={diagramInfo.code}
                diagram={{
                  ...diagramInfo,
                  imageCode: diagramInfo.code,
                  shapes: [],
                  id: null,
                }}
                onAddSuccess={() => onClose()}
                addMode
              />
            ))}
          {isLoading && <Loading height="152px" />}
        </DiagramsPreview>
        <Divider marginY={DIVIDER_MARGIN} />
        <DiagramsPreview title="Body and Extremities">
          {data &&
            data.data?.body.map((diagramInfo) => (
              <DiagramCanvasPreview
                encounterId={encounterId}
                chartCode={chartCode}
                key={diagramInfo.code}
                diagram={{
                  ...diagramInfo,
                  imageCode: diagramInfo.code,
                  shapes: [],
                  id: null,
                }}
                onAddSuccess={() => onClose()}
                addMode
              />
            ))}
          {isLoading && <Loading height="152px" />}
        </DiagramsPreview>
        <Divider marginY={DIVIDER_MARGIN} />
        <DiagramsPreview title="Miscellaneous">
          {data &&
            data.data?.miscellaneous.map((diagramInfo) => (
              <DiagramCanvasPreview
                encounterId={encounterId}
                chartCode={chartCode}
                key={diagramInfo.code}
                diagram={{
                  ...diagramInfo,
                  imageCode: diagramInfo.code,
                  shapes: [],
                  id: null,
                }}
                onAddSuccess={() => onClose()}
                addMode
              />
            ))}
          {isLoading && <Loading height="152px" />}
        </DiagramsPreview>
        <Divider marginY={DIVIDER_MARGIN} />
        <DiagramsPreview title="Saved Images">
          {diagrams &&
            diagrams?.data.map(({ data: shapes, ...rest }) => (
              <DiagramCanvasPreview
                encounterId={encounterId}
                chartCode={chartCode}
                key={rest.id}
                diagram={{ ...rest, shapes }}
                deleteMode
              />
            ))}
          {isLoadingDiagrams && <Loading height="152px" />}
        </DiagramsPreview>
      </ModalBody>
    </>
  );
}

function DiagramsPickerModal(props: DiagramsPickerModalProps) {
  const { isOpen, onClose, ...rest } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        width="1355px"
        height="980px"
        maxWidth="unset"
        bg="gray.50"
        marginY="10px"
        overflow="auto"
      >
        {isOpen && <DiagramsPickerModalContent onClose={onClose} {...rest} />}
      </ModalContent>
    </Modal>
  );
}

export { DiagramsPickerModal };
export type { DiagramsPickerModalProps };
