import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type RoomCounterResponse = {
  openBeds: number;
  assignedBeds: number;
  cleaningBeds: number;
  openRooms: number;
  closedRooms: number;
  maintenanceRooms: number;
};

function useRoomCounters() {
  return useAxiosQuery(["roomCounters"], () => {
    return axiosIdentityInstance.get<RoomCounterResponse>("v1/Room/counters");
  });
}

export { useRoomCounters };
export type { RoomCounterResponse };
