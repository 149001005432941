import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateStpVipNotePayload = {
  note: string;
};

function useCreateStpVipNotes(patientId: string, type: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createStpVipNote", { patientId, type }],
    (input: UseCreateStpVipNotePayload) =>
      axiosPatientInstance.post(`v1/notes/${patientId}/`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stpVipNotes", { patientId, type }]);
      },
    }
  );
}

export type { UseCreateStpVipNotePayload };
export { useCreateStpVipNotes };
