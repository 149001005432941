import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosQuery } from "hooks";
import { Order } from "modules/charts-shared";
import { OrderHistoryItem } from "modules/charts-shared";
import { ChartAnswer } from "types";

type UseOrderChartParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
};

type UseOrderChartInterpretation = {
  id?: string;
  code: string;
  type: string;
  sectionId: string;
  deleted: boolean;
  answers: ChartAnswer[];
  order?: Partial<OrderHistoryItem>;
  orderId?: string;
  orderNumber?: number;
};

type UseOrderChartResponseItem = {
  orderId: string;
  orderNumber: number;
  order: Order;
  answers: {
    qCode: string;
    value: unknown;
  }[];
};

type UseOrderChartPayloadItem = {
  order?: Partial<OrderHistoryItem>;
  orderId: string;
  orderNumber: number;
  interpretations: UseOrderChartInterpretation[];
  answers: {
    qCode: string;
    value: unknown;
  }[];
};

function useOrderChart(params: UseOrderChartParams) {
  const { encounterId, orderType } = params;
  return useAxiosQuery(["orderChart", params], () =>
    axiosChartInstance.get<UseOrderChartResponseItem[]>(
      `v1/order/${encounterId}`,
      { params: { orderType } }
    )
  );
}

function useOrderChartRadiology(params: UseOrderChartParams) {
  const { encounterId, orderType } = params;
  return useAxiosQuery(["orderChart", params], () =>
    axiosChartInstance.get<UseOrderChartPayloadItem[]>(
      `v1/order/${encounterId}/radiology`,
      { params: { orderType } }
    )
  );
}

export type {
  UseOrderChartInterpretation,
  UseOrderChartParams,
  UseOrderChartPayloadItem,
  UseOrderChartResponseItem,
};
export { useOrderChart, useOrderChartRadiology };
