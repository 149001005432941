import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseImportTriageMedicalHistoryProps = {
  encounterId: string;
};

function useImportTriageMedicalHistory(
  params: UseImportTriageMedicalHistoryProps
) {
  const { encounterId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["importTriageMedicalHistory", { encounterId }],
    () =>
      axiosChartInstance.patch<boolean>(
        `v1/triage/${encounterId}/medical-history/import-previous`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["medicalHistory", params]);
      },
    }
  );
}

export { useImportTriageMedicalHistory };
