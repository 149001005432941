import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteChartApplication(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteChartApplication", { encounterId }],
    (chartCode: string) =>
      axiosChartInstance.delete(
        `v1/chart-application/${encounterId}/${chartCode}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activeCharts", { encounterId }]);
        queryClient.invalidateQueries(["deletedChartsList", { encounterId }]);
      },
    }
  );
}

export { useDeleteChartApplication };
