import { InsuranceType } from "enums";

const INSURANCE_TYPE_COLOR: Record<InsuranceType, string> = {
  Commercial: "green",
  Auto: "blue",
  PromptPay: "yellow",
  WorkersCompensation: "orange",
  Government: "red",
};

export { INSURANCE_TYPE_COLOR };
