import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DocumentOutline = createIcon({
  path: (
    <path
      d="M2.60261 18H11.5187C13.2565 18 14.1213 17.1185 14.1213 15.3722V7.74907C14.1213 6.66604 13.9953 6.1959 13.3237 5.50746L8.69776 0.797575C8.0597 0.142724 7.53078 0 6.59048 0H2.60261C0.873134 0 0 0.889925 0 2.63619V15.3722C0 17.1269 0.864739 18 2.60261 18ZM2.66138 16.6483C1.79664 16.6483 1.35168 16.1866 1.35168 15.347V2.66138C1.35168 1.83022 1.79664 1.35168 2.66978 1.35168H6.40578V6.22948C6.40578 7.28731 6.9347 7.80784 7.98414 7.80784H12.7696V15.347C12.7696 16.1866 12.3246 16.6483 11.4515 16.6483H2.66138ZM8.13526 6.54011C7.79944 6.54011 7.67351 6.40578 7.67351 6.06996V1.61194L12.5093 6.54011H8.13526Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { DocumentOutline };
