import { axiosFacilityInstance } from "config";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type FaqDto = {
  id: string;
  question: string;
  answer: string;
};

type UseFaqsProps = PaginationPayload & {
  search?: string;
};

type UseFaqsResponse = PaginationResponse<FaqDto>;

function useFaqs(props: UseFaqsProps) {
  const { size = 10, page = 1, search } = props;

  return useInfiniteQuery(
    ["faqs", { search }],
    ({ pageParam = page }) =>
      axiosFacilityInstance.get<UseFaqsResponse>("v1/faq", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export { useFaqs };
export type { FaqDto };
