import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseEncounterPaymentFileProps = {
  encounterId: string;
  paymentId: string;
};

function useEncounterPaymentFile(props: UseEncounterPaymentFileProps) {
  const { paymentId } = props;

  return useAxiosQuery(["encounterPaymentFile", props], () =>
    axiosPatientInstance.get<Blob>(
      `v1/encounter/form/files/payment/${paymentId}`,
      { responseType: "blob" }
    )
  );
}

export { useEncounterPaymentFile };
