import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { useChartForm } from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared/loading";

const triageChartCode = "T013x";
const sectionId = "SCHC";

function N13ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A046" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A045"
                  label="started:"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A047"
                  label="just PTA"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T013xSCHCQ001A001"
                  defaultValue={0}
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A002"
                  label="minutes ago"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A003"
                  label="hours ago"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A004"
                  label="days ago"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A048"
                  label="sudden onset"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="initiated by:"
                  name="Q001.T013xSCHCQ001A052"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="current illness"
                  name="Q001.T013xSCHCQ001A053"
                  type="cross-option"
                />
                <ChartRowElement
                  label="out of meds"
                  name="Q001.T013xSCHCQ001A054"
                  type="cross-option"
                />
                <ChartRowElement
                  label="allergies"
                  name="Q001.T013xSCHCQ001A055"
                  type="cross-option"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A056" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="sick contacts:"
                  name="Q001.T013xSCHCQ001A057"
                  type="cross-option"
                />
                <ChartRowElement
                  label="home"
                  name="Q001.T013xSCHCQ001A058"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="daycare"
                  name="Q001.T013xSCHCQ001A059"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="other"
                  name="Q001.T013xSCHCQ001A060"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A061" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent travel:"
                  name="Q001.T013xSCHCQ001A062"
                  type="cross-option"
                />
                <ChartRowElement
                  label="outside US"
                  name="Q001.T013xSCHCQ001A063"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A064" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="acting differently:"
                  name="Q001.T013xSCHCQ001A065"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sleeping more"
                  name="Q001.T013xSCHCQ001A066"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="fussy"
                  name="Q001.T013xSCHCQ001A067"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="crying"
                  name="Q001.T013xSCHCQ001A068"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A069" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="current therapy:"
                  name="Q001.T013xSCHCQ001A070"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inhaler"
                  name="Q001.T013xSCHCQ001A071"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nebulizer"
                  name="Q001.T013xSCHCQ001A072"
                  type="cross-option"
                />
                <ChartRowElement
                  label="albuterol"
                  name="Q001.T013xSCHCQ001A073"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="Xopenex"
                  name="Q001.T013xSCHCQ001A074"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="long acting bronchodialator"
                  name="Q001.T013xSCHCQ001A075"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A076" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="oral medication:"
                  name="Q001.T013xSCHCQ001A077"
                  type="cross-option"
                />
                <ChartRowElement
                  label="prednisone"
                  name="Q001.T013xSCHCQ001A078"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement
                  label="prednisolone"
                  name="Q001.T013xSCHCQ001A079"
                  type="cross-option"
                  fontStyle="italic"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A080" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A026"
                  label="ingestion"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A027" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A081"
                  label="trouble breathing"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A082"
                  label="hurts to breathe"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A083"
                  label="short of breath"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A084"
                  label="wheezing"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A085" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A006"
                  label="fever"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A086"
                  label="chills"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A008"
                  label="earache"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A009"
                  label="pulling at ears"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A010"
                  label="L"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A011"
                  label="R"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A013"
                  label="runny nose"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A087"
                  label="color"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A015"
                  label="sore throat"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A088"
                  label="hoarse"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A089"
                  type="cross-option"
                  label="chest congestion"
                />
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A090"
                  type="cross-option"
                  label="nasal congestion"
                />
                <ChartRowElement name="Q001.T013xSCHCQ001A091" type="input" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A016"
                  label="cough"
                />
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A091"
                  type="cross-option"
                  label="barking"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A092"
                  type="cross-option"
                  label="bloody"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A093"
                  type="cross-option"
                  label="productive"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A018"
                  label="red eyes"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A019"
                  label="discharge"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A020"
                  label="L"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A021"
                  label="R"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A022" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A023"
                  label="chemical exposure"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A024"
                  label="CO exposure"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A025" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A030"
                  label="nausea"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A031"
                  label="vomiting x"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T013xSCHCQ001A049"
                  w="50px"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A032" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A033"
                  label="diarrhea"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A035"
                  label="# of wet diapers"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A034" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name="Q001.T013xSCHCQ001A094"
                  type="cross-option"
                  label="decreased intake"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A037"
                  label="change in appetite"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A039"
                  label="abdominal pain"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A038" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A041"
                  label="rash"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A042" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSCHCQ001A043"
                  label="seizure"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A044" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q001.T013xSCHCQ001A050"
                />
                <ChartRowElement type="input" name="Q001.T013xSCHCQ001A051" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N13ChiefComplaint };
