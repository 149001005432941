import * as React from "react";
import {
  Box,
  Button,
  ButtonProps,
  useControllableState,
} from "@chakra-ui/react";
import { config } from "config";

type CrossChartOptionValue = "Positive" | "Negative";

type CrossChartOptionProps = {
  label: string;
  value?: CrossChartOptionValue | null;
  defaultValue?: CrossChartOptionValue | null;
  onChange?: (value?: CrossChartOptionValue | null) => void;
} & Omit<ButtonProps, "onChange" | "type" | "value">;

const NEXT: Record<CrossChartOptionValue, CrossChartOptionValue | null> = {
  Negative: null,
  Positive: "Negative",
};

const getNextValue = (
  current: CrossChartOptionValue | null
): CrossChartOptionValue | null => (!current ? "Positive" : NEXT[current]);

const CrossChartOption = React.forwardRef<
  HTMLButtonElement,
  CrossChartOptionProps
>((props, ref) => {
  const {
    label,
    value: valueProp,
    onChange,
    defaultValue,
    ...btnProps
  } = props;
  const [value, setValue] = useControllableState({
    value: valueProp,
    onChange,
    defaultValue,
  });

  return (
    <Button
      ref={ref}
      variant="label"
      aria-selected={value === "Positive"}
      onClick={() => setValue(getNextValue)}
      type="button"
      position="relative"
      borderRadius="20px"
      padding="7px 10px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="all 200ms ease-in-out"
      color={value ? "blue" : "gray.700"}
      height="36px"
      border="1px solid transparent"
      _selected={{
        borderColor: "blue",
      }}
      {...btnProps}
    >
      {label}
      {value === "Negative" ? (
        <Box
          width="3px"
          height="56px"
          bg="blue"
          position="absolute"
          transform="rotate(-55deg)"
        />
      ) : null}
    </Button>
  );
});

if (config.isDev) {
  CrossChartOption.displayName = "CrossChartOption";
}

export type { CrossChartOptionProps, CrossChartOptionValue };
export { CrossChartOption };
