import { axiosIdentityInstance } from "config";
import { BedStatus } from "enums";
import { UseEncounterSideInfoResponse } from "modules/reception/api";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type UseBedsProps = PaginationPayload & {
  search?: string;
  bedStatus?: BedStatus;
  assigned?: boolean;
};

type BedDto = {
  id: string;
  roomId: string;
  name: string;
  bedCode: string;
  status: BedStatus;
  encounterId?: string;
  encounter?: UseEncounterSideInfoResponse;
};

type UseBedsResponse = PaginationResponse<BedDto>;

function useBeds(props: UseBedsProps) {
  const { size = 10, page = 1, search, bedStatus, assigned } = props;

  return useInfiniteQuery(
    ["beds", { search, bedStatus, assigned }],
    ({ pageParam = page }) =>
      axiosIdentityInstance.get<UseBedsResponse>("v1/bed", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
          bedStatus,
          assigned,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export type { UseBedsResponse, BedDto };
export { useBeds };
