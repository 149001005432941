import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteAuditComments(
  encounterId: string,
  chartCode: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "deleteAuditComments",
      { encounterId, chartCode, sectionCode, subsectionCode },
    ],
    (commentId: string) =>
      axiosChartInstance.delete(
        `/v1/audits/subsection/comments/${encounterId}/${chartCode}/${sectionCode}/${subsectionCode}/${commentId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditComments",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSectionIndicator",
          { encounterId, chartCode, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSubsectionIndicator",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

function useDeleteAuditCommentsShared(
  encounterId: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteSharedAuditComments", { encounterId, sectionCode, subsectionCode }],
    (commentId: string) =>
      axiosChartInstance.delete(
        `/v1/audits/subsection/comments/${encounterId}/shared/${sectionCode}/${subsectionCode}/${commentId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsShared",
          { encounterId, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSharedSectionIndicator",
          { encounterId, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSharedSubsectionIndicator",
          { encounterId, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

export { useDeleteAuditComments, useDeleteAuditCommentsShared };
