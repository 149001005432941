import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const SquareInfo = createIcon({
  path: (
    <path
      d="M3.06641 17.998L14.9121 17.998C16.9629 17.998 17.9785 16.9824 17.9785 14.9707L17.9785 3.04688C17.9785 1.03516 16.9629 0.0195312 14.9121 0.0195312L3.06641 0.0195312C1.02539 0.0195312 0 1.02539 0 3.04688L0 14.9707C0 16.9922 1.02539 17.998 3.06641 17.998ZM7.23633 14.6484C6.81641 14.6484 6.49414 14.3457 6.49414 13.9258C6.49414 13.5352 6.81641 13.2129 7.23633 13.2129L8.47656 13.2129L8.47656 8.62305L7.40234 8.62305C6.99219 8.62305 6.66992 8.32031 6.66992 7.90039C6.66992 7.50977 6.99219 7.1875 7.40234 7.1875L9.29688 7.1875C9.81445 7.1875 10.0879 7.55859 10.0879 8.10547L10.0879 13.2129L11.3281 13.2129C11.748 13.2129 12.0703 13.5352 12.0703 13.9258C12.0703 14.3457 11.748 14.6484 11.3281 14.6484ZM8.90625 5.52734C8.17383 5.52734 7.58789 4.94141 7.58789 4.19922C7.58789 3.45703 8.17383 2.87109 8.90625 2.87109C9.63867 2.87109 10.2148 3.45703 10.2148 4.19922C10.2148 4.94141 9.63867 5.52734 8.90625 5.52734Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { SquareInfo };
