import * as React from "react";

import { Divider, Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { useChartTrackingContext, usePermissions } from "contexts";
import {
  ChartFormProcedureSection,
  ChartSectionHeader,
  useChartProcedureForm,
  useSaveAndNext,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  PROCEDURE_SELECT_ITEM,
  ProcedureElements,
  ProceduresHeader,
} from "modules/provider/shared";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ProviderChartCode, TriageChartCode } from "enums";
import { SedationNoteElements } from "../procedures/SedationNoteElements";

type ProceduresProps = {
  chartCode: TriageChartCode | ProviderChartCode;
  sectionCode: string;
};

import faker from "faker";

function Procedures(props: ProceduresProps) {
  const { chartCode, sectionCode } = props;

  const {
    procedureInfo,
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
  } = useChartProcedureForm({
    sectionLabel: "Procedures",
    chartCode,
  });

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:proc").isEditable;

  const { isPastEncounter } = useChartTrackingContext();

  const isReadOnlyOrPastEncounter = isReadOnly || isPastEncounter;

  const handleSaveAndNext = useSaveAndNext(
    () => onSubmit(),
    "procedures",
    "provider"
  );

  const [procedures, setProcedures] = React.useState<
    ChartFormProcedureSection[]
  >([]);

  const [sedationNotes, setSedationNotes] = React.useState<
    ChartFormProcedureSection[]
  >([]);

  React.useEffect(() => {
    if (procedureInfo) {
      setProcedures(procedureInfo?.sections ?? []);
      setSedationNotes(procedureInfo?.sedationNotes ?? []);
    }
  }, [procedureInfo]);

  const [isDirtyProcedureSedationNote, setIsDirtyProcedureSedationNote] =
    React.useState(false);

  if (getIsLoading) {
    return <Loading />;
  }

  const DEFAULT_PROCEDURE_SECTION: ChartFormProcedureSection = {
    id: "",
    code: "",
    subSections: [],
  };

  const onAddProcedure = (list: PROCEDURE_SELECT_ITEM[]) => {
    setProcedures((prev) => [
      ...prev,
      ...list.map((item) => ({
        ...DEFAULT_PROCEDURE_SECTION,
        code: item.value,
        id: item.id,
      })),
    ]);

    setIsDirtyProcedureSedationNote(true);
  };

  const onRemoveProcedure = (id: string) => {
    setProcedures((prev) => [
      ...prev.filter((procedure) => procedure.id !== id),
    ]);

    setIsDirtyProcedureSedationNote(true);
  };

  const onAddSedationNote = () => {
    setSedationNotes((prev) => [
      ...prev,
      {
        ...DEFAULT_PROCEDURE_SECTION,
        code: "S001",
        id: faker.datatype.uuid(),
      },
    ]);

    setIsDirtyProcedureSedationNote(true);
  };

  const onRemoveSedationNote = (id: string) => {
    setSedationNotes((prev) => [...prev.filter((note) => note.id !== id)]);

    setIsDirtyProcedureSedationNote(true);
  };

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty || isDirtyProcedureSedationNote}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnlyOrPastEncounter}
        sectionId={sectionCode}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ProceduresHeader
          onAddProcedure={onAddProcedure}
          onAddSedationNote={onAddSedationNote}
          isReadOnly={isReadOnlyOrPastEncounter}
        />

        <ProcedureElements
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={sectionCode}
          isReadOnly={isReadOnlyOrPastEncounter}
          procedures={procedures}
          onRemoveElement={onRemoveProcedure}
        />

        {sedationNotes.length > 0 && (
          <Divider marginTop="25px" marginBottom="45px !important" />
        )}

        <SedationNoteElements
          chartCode={chartCode}
          encounterId={encounterId}
          isReadOnly={isReadOnlyOrPastEncounter}
          elements={sedationNotes}
          onRemoveElement={onRemoveSedationNote}
        />
      </ChartForm>
    </FormProvider>
  );
}

export { Procedures };
