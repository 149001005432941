import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChargeNurse } from "../queries";

type AssignChargeNurseRequest = { userId: string; active: boolean };

function useAssignChargeNurse() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["assignChargeNurse"],
    (payload: AssignChargeNurseRequest) =>
      axiosIdentityInstance.put<ChargeNurse>(
        `v1/user/charge-nurse/assign`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("activeChargeNurse");
      },
    }
  );
}

export { useAssignChargeNurse };
