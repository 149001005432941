import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C022x";

function T22ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Laceration:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abrasion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contusion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Burn:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                />
                <ChartRowElement
                  label="thermal"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="electrical"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="accident"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="self-harm"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="assault"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unkown"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="1st"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="2nd"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="3rd"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Total BSA="
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="CV / Resp"
            >
              CV / Resp
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Smoke inhalation"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchospasm:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ARDS"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulmonary edema:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Respiratory failure:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                />
                <ChartRowElement
                  label="w/ hypoxia"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cardiac arrest"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="CO Poisoning:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                />
                <ChartRowElement
                  label="accident"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="self-harm"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="source:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                />
                <ChartRowElement
                  label="building fire"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="house fire"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fuel burning"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Compartment syndrome:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper ext"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower ext"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rhabdomyolysis"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shock:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                />
                <ChartRowElement
                  label="hypovolemic"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Hypoxia"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Loss of Consciousness - transient"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T22ClinicalImpression };
