import { UseOrderChartInterpretation } from "modules/provider/api";

type RADIOLOGY_INTERPRETATIONS_SELECT_ITEM = {
  id: string;
  type: string;
  label: string;
  value: string;
};

type RADIOLOGY_INTERPRETATIONS_SELECT_ITEM_NO_ID = Omit<
  RADIOLOGY_INTERPRETATIONS_SELECT_ITEM,
  "id"
>;

type RadiologyInterpretationsType = "FRACTURE" | "DISLOCATION";

const DEFAULT_RADIOLOGY_INTERPRETATION: Omit<
  UseOrderChartInterpretation,
  "orderId" | "orderNumber"
> = {
  code: "DEFAULT",
  type: "Default",
  sectionId: "",
  deleted: false,
  answers: [],
};

const RADIOLOGY_GRID_COLUMNS = "300px 1fr";

const RADIOLOGY_INTERPRETATIONS_SELECT_OPTIONS: RADIOLOGY_INTERPRETATIONS_SELECT_ITEM_NO_ID[] =
  [
    {
      label: "Ankle",
      value: "F001",
      type: "FRACTURE",
    },
    {
      label: "C1",
      value: "F002",
      type: "FRACTURE",
    },
    {
      label: "C2",
      value: "F003",
      type: "FRACTURE",
    },
    {
      label: "CTLS",
      value: "F004",
      type: "FRACTURE",
    },
    {
      label: "Carpal",
      value: "F005",
      type: "FRACTURE",
    },
    {
      label: "Clavicle",
      value: "F006",
      type: "FRACTURE",
    },
    {
      label: "Facial",
      value: "F007",
      type: "FRACTURE",
    },
    {
      label: "Femur",
      value: "F008",
      type: "FRACTURE",
    },
    {
      label: "Fibula",
      value: "F009",
      type: "FRACTURE",
    },
    {
      label: "Foot",
      value: "F010",
      type: "FRACTURE",
    },
    {
      label: "Metacarpal",
      value: "F011",
      type: "FRACTURE",
    },
    {
      label: "Patella",
      value: "F012",
      type: "FRACTURE",
    },
    {
      label: "Pelvic",
      value: "F013",
      type: "FRACTURE",
    },
    {
      label: "Phalanx",
      value: "F014",
      type: "FRACTURE",
    },
    {
      label: "Radius",
      value: "F015",
      type: "FRACTURE",
    },
    {
      label: "Sacrum",
      value: "F016",
      type: "FRACTURE",
    },
    {
      label: "Scapula",
      value: "F017",
      type: "FRACTURE",
    },
    {
      label: "Skull",
      value: "F018",
      type: "FRACTURE",
    },
    {
      label: "Tibia",
      value: "F019",
      type: "FRACTURE",
    },
    {
      label: "Rib",
      value: "F020",
      type: "FRACTURE",
    },
    {
      label: "Sternum",
      value: "F021",
      type: "FRACTURE",
    },
    {
      label: "Ulna",
      value: "F022",
      type: "FRACTURE",
    },
    {
      label: "AC Joint",
      value: "D001",
      type: "DISLOCATION",
    },
    {
      label: "Ankle",
      value: "D002",
      type: "DISLOCATION",
    },
    {
      label: "DIP",
      value: "D003",
      type: "DISLOCATION",
    },
    {
      label: "Elbow",
      value: "D004",
      type: "DISLOCATION",
    },
    {
      label: "Foot",
      value: "D005",
      type: "DISLOCATION",
    },
    {
      label: "Hip",
      value: "D006",
      type: "DISLOCATION",
    },
    {
      label: "IP",
      value: "D007",
      type: "DISLOCATION",
    },
    {
      label: "Knee",
      value: "D008",
      type: "DISLOCATION",
    },
    {
      label: "MCP",
      value: "D009",
      type: "DISLOCATION",
    },
    {
      label: "Patella",
      value: "D010",
      type: "DISLOCATION",
    },
    {
      label: "PIP",
      value: "D011",
      type: "DISLOCATION",
    },
    {
      label: "SC Joint",
      value: "D012",
      type: "DISLOCATION",
    },
    {
      label: "Shoulder",
      value: "D013",
      type: "DISLOCATION",
    },
    {
      label: "Toe",
      value: "D014",
      type: "DISLOCATION",
    },
    {
      label: "Wrist",
      value: "D015",
      type: "DISLOCATION",
    },
  ];

const CONST_CHART_TYPE_CONFIG = {
  XRAY: {
    subsectionId: "Q001",
    subsectionLabel: "Xray",
  },
  MRI: {
    subsectionId: "Q002",
    subsectionLabel: "MRI",
  },
  ULTRASOUND: {
    subsectionId: "Q003",
    subsectionLabel: "Ultrasound",
  },
  CTSCAN: {
    subsectionId: "Q004",
    subsectionLabel: "CT Scan",
  },
  OTHER: {
    subsectionId: "Q005",
    subsectionLabel: "Other",
  },
  VENOGRAM: {
    subsectionId: "Q006",
    subsectionLabel: "Venogram",
  },
  FETALHEARTS: {
    subsectionId: "Q007",
    subsectionLabel: "Fetal Hearts Tones",
  },
  POSTURALVITALS: {
    subsectionId: "Q008",
    subsectionLabel: "Postural Vitals",
  },
  IVP: {
    subsectionId: "Q009",
    subsectionLabel: "IVP",
  },
  TESTICULARSCAN: {
    subsectionId: "Q010",
    subsectionLabel: "Testicular Scan",
  },
};

const CONST_CHART_WRAPPERS_CONFIG = {
  C001x: ["XRAY", "CTSCAN", "MRI"],
  C014x: ["XRAY", "CTSCAN", "ULTRASOUND", "OTHER"],
  C016x: ["XRAY", "CTSCAN", "ULTRASOUND"],
  C017x: ["XRAY", "CTSCAN", "ULTRASOUND"],
  C021x: ["XRAY", "ULTRASOUND"],
  C022x: ["XRAY", "OTHER"],
  C024x: ["CTSCAN", "MRI", "XRAY", "ULTRASOUND", "OTHER"],
  C030x: ["XRAY", "CTSCAN", "OTHER"],
  C031x: ["CTSCAN", "XRAY", "ULTRASOUND"],
  C035x: ["XRAY", "CTSCAN", "ULTRASOUND", "OTHER"],
  C036x: ["XRAY", "CTSCAN", "ULTRASOUND"],
  C039x: ["XRAY", "CTSCAN", "ULTRASOUND", "FETALHEARTS", "POSTURALVITALS"],
  C040x: ["ULTRASOUND", "FETALHEARTS", "POSTURALVITALS"],
  C041x: ["XRAY", "CTSCAN", "ULTRASOUND", "OTHER"],
  C043x: ["ULTRASOUND"],
  C044x: ["XRAY", "CTSCAN", "MRI"],
  C046x: ["CTSCAN", "MRI", "XRAY", "ULTRASOUND"],
  C052x: ["XRAY", "CTSCAN"],
  C070x: ["XRAY", "CTSCAN", "MRI", "ULTRASOUND"],
  C073x: ["XRAY", "CTSCAN", "MRI", "ULTRASOUND"],
};

type ChartType = keyof typeof CONST_CHART_TYPE_CONFIG;
type ChartCode = keyof typeof CONST_CHART_WRAPPERS_CONFIG;

export {
  RADIOLOGY_GRID_COLUMNS,
  CONST_CHART_TYPE_CONFIG,
  CONST_CHART_WRAPPERS_CONFIG,
  RADIOLOGY_INTERPRETATIONS_SELECT_OPTIONS,
  DEFAULT_RADIOLOGY_INTERPRETATION,
};
export type {
  ChartType,
  ChartCode,
  RadiologyInterpretationsType,
  RADIOLOGY_INTERPRETATIONS_SELECT_ITEM,
  RADIOLOGY_INTERPRETATIONS_SELECT_ITEM_NO_ID,
};
