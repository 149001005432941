import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { HowDidYouHearAboutUsOption } from "enums";
import {
  ContactInfo,
  DriverLicense,
  EmergencyContact,
  Employment,
  PrimaryCarePhysician,
} from "types";

type UseEncounterPatientInfoProps = {
  encounterId: string;
};

type EncounterPatientInfoResponse = {
  contactInfo: ContactInfo | null;
  identification?: DriverLicense | null;
  emergencyContact: EmergencyContact | null;
  employment: Employment | null;
  primaryCarePhysician: PrimaryCarePhysician | null;
  howDidTheyHearAboutUs: HowDidYouHearAboutUsOption | null;
  appointmentNotes: string | null;
  painProtocol: boolean;
  isVip: boolean;
};

function useEncounterPatientInfo(props: UseEncounterPatientInfoProps) {
  const { encounterId } = props;

  return useAxiosQuery(
    ["encounterPatientInfo", props],
    () =>
      axiosPatientInstance.get<EncounterPatientInfoResponse>(
        `v1/encounter/${encounterId}/form/patient-info`
      ),
    { staleTime: 0, cacheTime: 0 }
  );
}

export type { UseEncounterPatientInfoProps };
export { useEncounterPatientInfo };
