import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Car = createIcon({
  path: (
    <path
      d="M1.00788 14.1626C0.440481 14.1626 0 13.7221 0 13.1547V8.73497C0 7.42845 0.246371 6.69681 0.940689 5.79345L1.59768 4.95728C1.87391 3.61344 2.37412 2.20241 2.62796 1.64994C3.04604 0.776441 3.84488 0.238905 4.88262 0.111987C5.41269 0.0447947 7.01784 0 9.00373 0C10.9822 0 12.5873 0.0373289 13.1248 0.111987C14.1551 0.246371 14.954 0.776441 15.372 1.64994C15.6259 2.20241 16.1261 3.61344 16.4023 4.95728L17.0593 5.79345C17.7611 6.69681 18 7.42845 18 8.73497V13.1547C18 13.7221 17.5595 14.1626 16.9921 14.1626H16.0962C15.5214 14.1626 15.0883 13.7221 15.0883 13.1547V11.8631C13.4235 11.9751 10.9747 12.0498 9.00373 12.0498C7.0253 12.0498 4.57652 11.9751 2.91912 11.8631V13.1547C2.91912 13.7221 2.47864 14.1626 1.90377 14.1626H1.00788ZM3.26255 4.27789C3.18789 4.59892 3.32227 4.74077 3.65823 4.71837C4.80796 4.65118 6.26379 4.57652 9.00373 4.57652C11.7362 4.57652 13.192 4.65118 14.3418 4.71837C14.6777 4.74077 14.8196 4.59892 14.7375 4.27789C14.5583 3.53132 14.2447 2.5309 14.0133 2.13521C13.7893 1.74699 13.5504 1.59021 13.095 1.52302C12.5649 1.43343 11.2883 1.40357 9.00373 1.40357C6.71174 1.40357 5.43509 1.44836 4.90502 1.52302C4.44961 1.58275 4.20324 1.74699 3.98673 2.13521C3.76275 2.53837 3.42679 3.53132 3.26255 4.27789ZM3.53878 9.96682C4.24803 9.96682 4.78557 9.42928 4.78557 8.72003C4.78557 8.01078 4.24803 7.47325 3.53878 7.47325C2.82953 7.47325 2.292 8.01078 2.292 8.72003C2.292 9.42928 2.82953 9.96682 3.53878 9.96682ZM14.4612 9.96682C15.1705 9.96682 15.708 9.42928 15.708 8.72003C15.708 8.01078 15.1705 7.47325 14.4612 7.47325C13.752 7.47325 13.2144 8.01078 13.2144 8.72003C13.2144 9.42928 13.752 9.96682 14.4612 9.96682ZM6.98797 9.66072H11.012C11.5421 9.66072 11.9154 9.28743 11.9154 8.75736C11.9154 8.22729 11.5421 7.854 11.012 7.854H6.98797C6.4579 7.854 6.08461 8.22729 6.08461 8.75736C6.08461 9.28743 6.4579 9.66072 6.98797 9.66072Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 -2 24 20",
});

export { Car };
