import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartRow,
  ChartRowElement,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { AssessmentsHeader, ASSESSMENTS_GRID_COLUMNS } from "../../shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const triageChartCode = "T003x";

function N03Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <AssessmentsHeader title="Primary Assessment" />
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSASSQ001A017"
                  label="Date/Time"
                />
                <ChartRowElement
                  type="date"
                  name="Q001.T003xSASSQ001A001"
                  width="10rem"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Airway patent"
                    name="Q001.T001xSASSQ001A002"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="compromised"
                    name="Q001.T001xSASSQ001A003"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Breathing unlabored"
                    name="Q001.T001xSASSQ001A005"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="labored"
                    name="Q001.T001xSASSQ001A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="respiratory distress"
                    name="Q001.T001xSASSQ001A007"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="circulation nml"
                    name="Q001.T001xSASSQ001A009"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q001.T001xSASSQ001A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q001.T001xSASSQ001A011"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A012" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Neuro awake alert"
                    name="Q001.T001xSASSQ001A013"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lethargic"
                    name="Q001.T003xSASSQ001A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="obtunded"
                    name="Q001.T003xSASSQ001A015"
                  />
                  <ChartRowElement type="input" name="Q001.T003xSASSQ001A016" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <AssessmentsHeader title="Secondary Assessment" />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Upper Extremities"
            >
              Upper Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml inspection"
                    name="Q002.T003xSASSQ002A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="see diagram"
                    name="Q002.T003xSASSQ002A002"
                  />
                  <ChartRowElement type="input" name="Q002.T003xSASSQ002A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q002.T003xSASSQ002A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q002.T003xSASSQ002A005"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q002.T003xSASSQ002A006"
                  />
                  <ChartRowElement type="input" name="Q002.T003xSASSQ002A007" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no edema"
                    name="Q002.T003xSASSQ002A008"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q002.T003xSASSQ002A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="active"
                    name="Q002.T003xSASSQ002A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="passive"
                    name="Q002.T003xSASSQ002A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="functional"
                    name="Q002.T003xSASSQ002A012"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="due to pain"
                    name="Q002.T003xSASSQ002A013"
                  />
                  <ChartRowElement type="input" name="Q002.T003xSASSQ002A014" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q002.T003xSASSQ002A015"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="joint swelling"
                    name="Q002.T003xSASSQ002A016"
                  />
                  <ChartRowElement type="input" name="Q002.T003xSASSQ002A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no deformity"
                    name="Q002.T003xSASSQ002A018"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="deformity"
                    name="Q002.T003xSASSQ002A019"
                  />
                  <ChartRowElement type="input" name="Q002.T003xSASSQ002A020" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="SKIN"
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="warm"
                    name="Q003.T003xSASSQ003A001"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dry"
                    name="Q003.T003xSASSQ003A002"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q003.T003xSASSQ003A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q003.T003xSASSQ003A004"
                  />
                  <ChartRowElement type="input" name="Q003.T003xSASSQ003A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact"
                    name="Q003.T003xSASSQ003A006"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcers"
                    name="Q003.T003xSASSQ003A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sacrum"
                    name="Q003.T003xSASSQ003A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hip"
                    name="Q003.T003xSASSQ003A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="heel"
                    name="Q003.T003xSASSQ003A010"
                  />
                  <ChartRowElement type="input" name="Q003.T003xSASSQ003A011" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="NEURO"
            >
              NEURO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x 4"
                    name="Q004.T003xSASSQ004A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.T003xSASSQ004A007"
                    label="disoriented to"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q004.T003xSASSQ004A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q004.T003xSASSQ004A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    name="Q004.T003xSASSQ004A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q004.T003xSASSQ004A005"
                  />
                  <ChartRowElement type="input" name="Q004.T003xSASSQ004A006" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="regular rate"
                    name="Q005.T003xSASSQ005A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tachycardia"
                    name="Q005.T003xSASSQ005A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bradycardia"
                    name="Q005.T003xSASSQ005A003"
                  />
                  <ChartRowElement type="input" name="Q005.T003xSASSQ005A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulses strong"
                    name="Q005.T003xSASSQ005A005"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="equal"
                    name="Q005.T003xSASSQ005A006"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulse deficit"
                    name="Q005.T003xSASSQ005A007"
                  />
                  <ChartRowElement type="input" name="Q005.T003xSASSQ005A008" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Chest"
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml breath sounds"
                    name="Q006.T003xSASSQ006A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="wheezing"
                    name="Q006.T003xSASSQ006A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rales"
                    name="Q006.T003xSASSQ006A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rhonchi"
                    name="Q006.T003xSASSQ006A004"
                  />
                  <ChartRowElement type="input" name="Q006.T003xSASSQ006A005" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="soft / non-tender"
                    name="Q007.T003xSASSQ007A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q007.T003xSASSQ007A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rigid"
                    name="Q007.T003xSASSQ007A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="distended"
                    name="Q007.T003xSASSQ007A004"
                  />
                  <ChartRowElement type="input" name="Q007.T003xSASSQ007A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowElement type="empty" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="catheter present"
                    name="Q007.T003xSASSQ007A006"
                  />
                  <ChartRowElement type="input" name="Q007.T003xSASSQ007A007" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="FUNCTIONAL / NUTRITIONAL ASSESSMENT"
            >
              FUNCTIONAL / NUTRITIONAL ASSESSMENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="independent ADL"
                    name="Q008.T003xSASSQ008A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="assisted"
                    name="Q008.T003xSASSQ008A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="total care"
                    name="Q008.T003xSASSQ008A003"
                  />
                  <ChartRowElement type="input" name="Q008.T003xSASSQ008A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appear well"
                    name="Q008.T003xSASSQ008A005"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="obese"
                    name="Q008.T003xSASSQ008A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malnourished"
                    name="Q008.T003xSASSQ008A007"
                  />
                  <ChartRowElement type="input" name="Q008.T003xSASSQ008A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nourished"
                    name="Q008.T003xSASSQ008A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hydrated"
                    name="Q008.T003xSASSQ008A010"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight loss"
                    name="Q008.T003xSASSQ008A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight gain"
                    name="Q008.T003xSASSQ008A012"
                  />
                  <ChartRowElement type="input" name="Q008.T003xSASSQ008A013" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Aditional findings"
            >
              Aditional findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q009.T003xSASSQ009A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N03Assessments };
