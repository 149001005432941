import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateOrderChartNoteAudioProps = {
  chartCode: string;
  encounterId: string;
  orderType: OrderGroup;
};

function useCreateOrderChartNoteAudio(params: CreateOrderChartNoteAudioProps) {
  const { encounterId, orderType } = params;
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createOrderChartNoteAudio", params],
    (audio: FormData) =>
      axiosChartInstance.post(`v1/order/${encounterId}/audio-note`, audio, {
        params: { orderType },
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["orderChartNotes", params]),
    }
  );
}

export { useCreateOrderChartNoteAudio };
