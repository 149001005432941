import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const CrossmarkShield = createIcon({
  path: (
    <path
      d="M13.2734 32C13.0015 32 12.5857 31.904 12.1699 31.6802C3.15042 26.7386 0 24.4838 0 18.7586V6.84458C0 4.98951 0.71964 4.30185 2.28686 3.66217C4.02998 2.94253 9.91504 0.815592 11.6422 0.271864C12.1539 0.127936 12.7456 0 13.2734 0C13.8171 0 14.4088 0.111944 14.9205 0.271864C16.6317 0.847576 22.5327 2.94253 24.2759 3.66217C25.8431 4.31784 26.5627 4.98951 26.5627 6.84458V18.7586C26.5627 24.4838 23.5402 26.9625 14.3928 31.6802C13.977 31.8881 13.5612 32 13.2734 32ZM8.02798 22.085C8.45977 22.085 8.84358 21.925 9.13143 21.6372L13.2894 17.4633L17.4473 21.6372C17.7351 21.925 18.1029 22.085 18.5347 22.085C19.3823 22.085 20.054 21.4133 20.054 20.5657C20.054 20.1499 19.91 19.7821 19.6062 19.4943L15.4323 15.3363L19.6222 11.1304C19.942 10.8266 20.07 10.4748 20.07 10.075C20.07 9.22739 19.3983 8.57171 18.5667 8.57171C18.1509 8.57171 17.8151 8.71564 17.4952 9.01949L13.2894 13.2094L9.09945 9.03548C8.7956 8.74763 8.45977 8.6037 8.02798 8.6037C7.1964 8.6037 6.52474 9.24338 6.52474 10.091C6.52474 10.4908 6.68466 10.8586 6.97251 11.1464L11.1464 15.3363L6.97251 19.5102C6.68466 19.7981 6.52474 20.1659 6.52474 20.5657C6.52474 21.4133 7.1964 22.085 8.02798 22.085Z"
      fill="currentColor"
    />
  ),
  viewBox: "9 0 9 38",
});

export { CrossmarkShield };
