import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { useGetUserDetails } from "api";
import { Loading, StaffAvatar } from "shared";

type UserInfoProps = {
  userId: string;
};

function UserInfo({ userId }: UserInfoProps) {
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails(
    userId,
    { enabled: !!userId }
  );

  return (
    <Flex justifyContent="center" flex={1}>
      {isUserLoading && <Loading />}
      {userData && (
        <HStack>
          <StaffAvatar
            w="32px"
            h="32px"
            size="xs"
            fontSize=".75rem"
            userName={userData?.data?.fullName}
            profileUrl={userData?.data.pictureUrl}
          />
          <VStack spacing="0" alignItems="flex-start">
            <Box
              fontSize="17px"
              fontWeight="600"
              lineHeight="1.5rem"
              color="black"
            >
              {userData?.data.fullName}
            </Box>
            <Box
              fontSize="15px"
              fontWeight="400"
              lineHeight="1rem"
              color="gray.700"
            >
              {userData?.data.teams}
            </Box>
          </VStack>
        </HStack>
      )}
    </Flex>
  );
}

export { UserInfo };
