import * as React from "react";
import { chakra } from "@chakra-ui/react";
import { Card } from "shared";

function AssessmentsHeader({ title }: { title: string }) {
  return (
    <Card
      height="3.5rem"
      borderRadius="11px"
      padding="15px 10px"
      boxShadow="none"
      border="none"
    >
      <chakra.span fontWeight="600" fontSize="1.125rem">
        {title}
      </chakra.span>
    </Card>
  );
}

export { AssessmentsHeader };
