import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C017x";

function T17ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  textDecoration="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Laceration:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abrasion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contusion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Burn:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                />
                <ChartRowElement
                  label="thermal"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                />
                <ChartRowElement
                  label="electrical"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                />
                <ChartRowElement
                  label="chemical:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                  width="300px"
                />
                <ChartRowElement
                  label="accident:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                />
                <ChartRowElement
                  label="self-harm"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                />
                <ChartRowElement
                  label="assault"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                />
                <ChartRowElement
                  label="unknown"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="1st"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                />
                <ChartRowElement
                  label="area:"
                  textDecoration="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="input"
                  width="800px"
                />
                <ChartRowElement
                  label=":"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="2nd"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                />
                <ChartRowElement
                  label="area:"
                  textDecoration="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="input"
                  width="800px"
                />
                <ChartRowElement
                  label=":"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="3rd"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                />
                <ChartRowElement
                  label="area:"
                  textDecoration="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="input"
                  width="800px"
                />
                <ChartRowElement
                  label=":"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  label="%"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Total BSA="
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="input"
                  width="150px"
                />
                <ChartRowElement
                  label="%"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Spine:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fracture:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                />
                <ChartRowElement
                  label="closed"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="open"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dislocation"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="CV / Resp"
            >
              CV / Resp
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Aorta transection"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cardiac arrest"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cardiac contusion"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hemothorax"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumothorax"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tension"
                  fontStyle="italic"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulmonary"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  fontStyle="italic"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac"
                  fontStyle="italic"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Respiratory arrest"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Adbomen / GI / GU"
            >
              Adbomen / GI / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Hemoperitoneum"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Liver:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                />
                <ChartRowElement
                  label="contusion"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  label="cm"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                />
                <ChartRowElement
                  label="stellate"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                />
                <ChartRowElement
                  label="multiple"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Spleen:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                />
                <ChartRowElement
                  label="contusion"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                />
                <ChartRowElement
                  label="<2cm>"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                />
                <ChartRowElement
                  label=">2cm"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                />
                <ChartRowElement
                  label="<1cm"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                />
                <ChartRowElement
                  label="1-3cm"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                />
                <ChartRowElement
                  label=">3cm"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pancreas:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                />
                <ChartRowElement
                  label="head:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                />
                <ChartRowElement
                  label="body:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                />
                <ChartRowElement
                  label="tall:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                />
                <ChartRowElement
                  label="contusion"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                />
                <ChartRowElement
                  label="sm"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                />
                <ChartRowElement
                  label="med"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                />
                <ChartRowElement
                  label="lg"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A028`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q007.${chartCode}${sectionId}Q007A030`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A031`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Concussion:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                />
                <ChartRowElement
                  label="lost consciousness:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="no"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="seconds"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="minutes"
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours"
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cerebral:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A012`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q008.${chartCode}${sectionId}Q008A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac"
                  name={`Q008.${chartCode}${sectionId}Q008A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic hemorrhage"
                  name={`Q008.${chartCode}${sectionId}Q008A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epidural"
                  name={`Q008.${chartCode}${sectionId}Q008A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Subdural"
                  name={`Q008.${chartCode}${sectionId}Q008A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Traumatic SAH"
                  name={`Q008.${chartCode}${sectionId}Q008A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cord Injury:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A025`}
                />
                <ChartRowElement
                  label="incomplete"
                  name={`Q008.${chartCode}${sectionId}Q008A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q008.${chartCode}${sectionId}Q008A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="level"
                  name={`Q008.${chartCode}${sectionId}Q008A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="C: #"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A029`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A030`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="T: #"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A031`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A032`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="L: #"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A033`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A034`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="sacral"
                  name={`Q008.${chartCode}${sectionId}Q008A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Seizure"
                  name={`Q008.${chartCode}${sectionId}Q008A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q008.${chartCode}${sectionId}Q008A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A039`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Diaphragm rupture"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shock"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypovolemic"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Crush injury:"
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="to:"
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Amputation:"
                  name={`Q009.${chartCode}${sectionId}Q009A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="partial"
                  name={`Q009.${chartCode}${sectionId}Q009A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q009.${chartCode}${sectionId}Q009A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  name={`Q009.${chartCode}${sectionId}Q009A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tooth #:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A015`}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A016`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="fx"
                  name={`Q009.${chartCode}${sectionId}Q009A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="avulsion"
                  name={`Q009.${chartCode}${sectionId}Q009A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sublux"
                  name={`Q009.${chartCode}${sectionId}Q009A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Acute pain:"
                  name={`Q009.${chartCode}${sectionId}Q009A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  fontStyle="italic"
                  name={`Q009.${chartCode}${sectionId}Q009A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  name={`Q009.${chartCode}${sectionId}Q009A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q009.${chartCode}${sectionId}Q009A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A026`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q009.${chartCode}${sectionId}Q009A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q009.${chartCode}${sectionId}Q009A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A030`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T17ClinicalImpression };
