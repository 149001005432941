import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const RFilled = createIcon({
  path: (
    <path
      d="M3.59491 20H16.4051C18.7593 20 20 18.7699 20 16.4475V3.5631C20 1.24072 18.7593 0 16.4051 0H3.59491C1.25133 0 0 1.24072 0 3.5631V16.4475C0 18.7699 1.25133 20 3.59491 20ZM7.15801 15.0689C6.4369 15.0689 6.03393 14.5917 6.03393 13.8176V6.29905C6.03393 5.45069 6.46872 4.93107 7.29586 4.93107H10.3499C12.7359 4.93107 14.1782 6.16119 14.1782 8.19724C14.1782 9.56522 13.3722 10.6893 12.1103 11.0923L13.5843 13.3298C13.7646 13.5843 13.8388 13.7964 13.8388 14.0933C13.8388 14.666 13.404 15.0689 12.789 15.0689C12.2906 15.0689 12.0042 14.8674 11.6331 14.2842L9.92577 11.4846H8.29268V13.8176C8.29268 14.6023 7.87911 15.0689 7.15801 15.0689ZM8.29268 9.81972H10.0424C11.1347 9.81972 11.8346 9.24708 11.8346 8.25027C11.8346 7.23224 11.1347 6.63839 10.0742 6.63839H8.29268V9.81972Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { RFilled };
