import {
  Box,
  Checkbox,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MedicationRxType } from "enums";
import { AddRounded, MinusRoundFilled } from "icons";
import { MedicationRx } from "modules/charts-shared";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, Label, ToolbarHeader } from "shared";

type AddMedicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type: MedicationRxType;
  onAddItem: (item: MedicationRx) => void;
  onDeleteItem: (item: MedicationRx) => void;
  editingMedication: MedicationRx | null;
};

type AddMedicationForm = {
  medication: string;
  dosage: string;
  frequency: string;
  otherFrequency: string | null;
};

const FREQS = [
  "1x daily",
  "2x daily",
  "3x daily",
  "4x daily",
  "5x daily",
  "As Needed",
];

function AddMedicationModal({
  isOpen,
  onClose,
  type,
  onAddItem,
  onDeleteItem,
  editingMedication,
}: AddMedicationModalProps) {
  const { handleSubmit, register, watch, setValue, reset } =
    useForm<AddMedicationForm>({
      defaultValues: {
        medication: "",
        dosage: "",
        frequency: "",
        otherFrequency: null,
      },
    });

  useEffect(() => {
    if (editingMedication) {
      const freqs = editingMedication.medFrequency.split(",");

      const otherFeqs = freqs.filter((freq) => !FREQS.includes(freq));

      reset({
        medication: editingMedication.medName,
        dosage: editingMedication.medDosage,
        frequency: freqs.filter((freq) => FREQS.includes(freq)).join(","),
        otherFrequency: otherFeqs.length > 0 ? otherFeqs.join(",") : null,
      });
    }
  }, [editingMedication, reset]);

  const frequency = watch("frequency");
  const otherFrequency = watch("otherFrequency");

  const onSubmit = (data: AddMedicationForm) => {
    const freqs = [
      ...data.frequency.split(","),
      ...(data.otherFrequency ?? "").split(","),
    ];
    onAddItem({
      medDosage: data.dosage,
      medName: data.medication,
      medFrequency: freqs.join(","),
      type: type,
      id: editingMedication?.id,
    });
    onClose();
  };

  const onDelete = (data: AddMedicationForm) => {
    const freqs = [
      ...data.frequency.split(","),
      ...(data.otherFrequency ?? "").split(","),
    ];

    onDeleteItem({
      medDosage: data.dosage,
      medName: data.medication,
      medFrequency: freqs.join(","),
      type: type,
      id: editingMedication?.id,
    });

    onClose();
  };

  const handleFreqChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, checked },
    } = e;
    const selectedFreqs = frequency == "" ? [] : [...frequency.split(",")];
    if (checked) {
      selectedFreqs.push(value);
    } else {
      selectedFreqs.splice(selectedFreqs.indexOf(value), 1);
    }
    setValue("frequency", selectedFreqs.join(","));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        w="540px"
        height="98%"
        overflow="hidden"
        m="auto"
        p={0}
      >
        <ModalHeader>
          <ToolbarHeader
            titleText={`${
              type === "OTC_RX" ? "OTC" : "Prescription"
            } Medication`}
            leftButtons={[
              <Button key="cancelBtn" onClick={onClose}>
                Cancel
              </Button>,
            ]}
            rightButtons={[
              <Button key="doneBtn" onClick={() => handleSubmit(onSubmit)()}>
                Done
              </Button>,
            ]}
          />
        </ModalHeader>
        <ModalBody>
          <form
            style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            <FormControl>
              <Label>Medication</Label>
              <Input {...register("medication")} />
            </FormControl>
            <FormControl>
              <Label>Dosage</Label>
              <Input {...register("dosage")} />
            </FormControl>
            <Label>Frequency</Label>
            <Card
              variant="basic"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {FREQS.map((freq) => (
                <Box
                  key={freq}
                  sx={{
                    p: "0.5rem 0.625rem",
                  }}
                >
                  <Checkbox
                    value={freq}
                    isChecked={frequency.includes(freq)}
                    onChange={handleFreqChange}
                  >
                    {freq}
                  </Checkbox>
                </Box>
              ))}
            </Card>
            {otherFrequency !== null && (
              <FormControl>
                <Label>Other Frequency (maximum 30 characters)</Label>
                <InputGroup>
                  <Input {...register("otherFrequency")} />
                  <InputRightElement>
                    <IconButton
                      aria-label="remove other frequency"
                      variant="icon"
                      color="red"
                      onClick={() => setValue("otherFrequency", null)}
                    >
                      <Icon as={MinusRoundFilled} />
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            )}
            <Button
              variant="label"
              sx={{
                p: "1rem",
                color: "blue",
                columnGap: "0.75rem",
              }}
              onClick={() => setValue("otherFrequency", "")}
            >
              <Icon as={AddRounded} />
              Add other frequency
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          {editingMedication ? (
            <Button
              colorScheme="red"
              width="100%"
              onClick={() => handleSubmit(onDelete)()}
            >
              Delete
            </Button>
          ) : (
            <Button colorScheme="red" width="100%" onClick={onClose}>
              Close
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { AddMedicationModal };
