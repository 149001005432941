import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseOrderFormFavoritesResponse = {
  id: string;
  userId: string;
  favorite: boolean;
  orderFormElementId: string;
};

function useOrderFormFavorites() {
  return useAxiosQuery(["orderFormFavorites"], () =>
    axiosChartInstance.get<UseOrderFormFavoritesResponse[]>(
      `v1/order-form/favorites/all`,
      {}
    )
  );
}
export { useOrderFormFavorites };
export type { UseOrderFormFavoritesResponse };
