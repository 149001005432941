import { Icon } from "@chakra-ui/react";
import { EmrActions } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { TriageChartCode } from "enums";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartActionsForm } from "../../hooks";
import { ActionsHeader } from "../../shared";
import { actionsConstants } from "../../shared/actions";

const chartId: TriageChartCode = "T012x";
const sectionId = "SACT";

function N12Actions() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    encounterId,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartActionsForm({ chartCode: chartId });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:actions").isEditable;

  const { getValues } = useFormReturn;
  const initialActionsSections = getValues("initialActionsSections");
  const actionsSections = getValues("actionsSections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrActions} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Actions {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              encounterId={encounterId}
              chartId={chartId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Initial Actions"
            >
              Initial Actions
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ActionsHeader />
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[0] &&
                          initialActionsSections[0].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[0] &&
                          initialActionsSections[0].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name={`initialActionsSections[0].${chartId}${sectionId}Q001A000`}
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ID"
                      name={`initialActionsSections[0].${chartId}${sectionId}Q001A001`}
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement
                      pl="20px"
                      type="option"
                      label="allergy band"
                      name="initialActionsSections[0].T012xSACTQ001A002"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="applied"
                      name={`initialActionsSections[0].${chartId}${sectionId}Q001A003`}
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement
                      pl="20px"
                      type="option"
                      label="verified"
                      name="initialActionsSections[0].T012xSACTQ001A004"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[1] &&
                          initialActionsSections[1].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[1] &&
                          initialActionsSections[1].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[1].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="c-collar"
                      name="initialActionsSections[1].T012xSACTQ001A005"
                    />
                    <ChartRowElement
                      type="option"
                      label="backboard"
                      name="initialActionsSections[1].T012xSACTQ001A006"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="applied"
                      fontStyle="italic"
                      name="initialActionsSections[1].T012xSACTQ001A007"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[2] &&
                          initialActionsSections[2].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[2] &&
                          initialActionsSections[2].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[2].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="disrobed"
                      name="initialActionsSections[2].T012xSACTQ001A008"
                    />
                    <ChartRowElement
                      type="option"
                      label="gowned"
                      name="initialActionsSections[2].T012xSACTQ001A009"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="blanket provided"
                      name="initialActionsSections[2].T012xSACTQ001A010"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[3] &&
                          initialActionsSections[3].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[3] &&
                          initialActionsSections[3].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[3].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ice pack"
                      name="initialActionsSections[3].T012xSACTQ001A011"
                    />

                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="elevation"
                      name="initialActionsSections[3].T012xSACTQ001A012"
                    />
                    <ChartRowElement
                      type="option"
                      label="immobilization"
                      name="initialActionsSections[3].T012xSACTQ001A013"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[4] &&
                          initialActionsSections[4].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[4] &&
                          initialActionsSections[4].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[4].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bandage"
                      name="initialActionsSections[4].T012xSACTQ001A014"
                    />

                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="dressing"
                      name="initialActionsSections[4].T012xSACTQ001A015"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[5] &&
                          initialActionsSections[5].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[5] &&
                          initialActionsSections[5].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[5].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bed low position"
                      name="initialActionsSections[5].T012xSACTQ001A016"
                    />

                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="side rails up"
                      name="initialActionsSections[5].T012xSACTQ001A017"
                    />
                    <ChartRowElement
                      type="option"
                      label="x 1"
                      name="initialActionsSections[5].T012xSACTQ001A018"
                    />
                    <ChartRowElement
                      type="option"
                      label="x 2"
                      name="initialActionsSections[5].T012xSACTQ001A019"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[6] &&
                          initialActionsSections[6].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[6] &&
                          initialActionsSections[6].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[6].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="call light in reach"
                      name="initialActionsSections[6].T012xSACTQ001A020"
                    />

                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="head of bed elevated"
                      name="initialActionsSections[6].T012xSACTQ001A021"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_3
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[7] &&
                          initialActionsSections[7].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[7] &&
                          initialActionsSections[7].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="initialActionsSections[7].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="held by parent"
                      name="initialActionsSections[7].T012xSACTQ001A022"
                    />

                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="caregiver"
                      name="initialActionsSections[7].T012xSACTQ001A023"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              encounterId={encounterId}
              chartId={chartId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Actions"
            >
              Actions
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ActionsHeader />
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[0] &&
                          actionsSections[0].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[0] &&
                          actionsSections[0].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[0].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="agency notified"
                      name="actionsSections[0].T012xSACTQ002A001"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[0].T012xSACTQ002A029"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[1] &&
                          actionsSections[1].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[1] &&
                          actionsSections[1].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[1].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="setup"
                      name="actionsSections[1].T012xSACTQ002A030"
                    />
                    <ChartRowElement
                      type="option"
                      label="suture tray"
                      name="actionsSections[1].T012xSACTQ002A002"
                    />
                    <ChartRowElement
                      type="option"
                      label="eye tray"
                      name="actionsSections[1].T012xSACTQ002A003"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[1].T012xSACTQ002A031"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[2] &&
                          actionsSections[2].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[2] &&
                          actionsSections[2].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[2].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="cardiac monitor"
                      name="actionsSections[2].T012xSACTQ002A004"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[2].T012xSACTQ002A032"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[3] &&
                          actionsSections[3].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[3] &&
                          actionsSections[3].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[3].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="pulse oximeter"
                      name="actionsSections[3].T012xSACTQ002A005"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[3].T012xSACTQ002A006"
                      size="sm"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[3].T012xSACTQ002A023"
                      label="%"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[3].T012xSACTQ002A024"
                      label="RA/"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[3].T012xSACTQ002A007"
                      size="md"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[3].T012xSACTQ002A025"
                      label="L"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[3].T012xSACTQ002A033"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[4] &&
                          actionsSections[4].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[4] &&
                          actionsSections[4].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[4].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="actionsSections[4].T012xSACTQ002A008"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[4].T012xSACTQ002A009"
                      size="sm"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[4].T012xSACTQ002A026"
                      label="L"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[4].T012xSACTQ002A027"
                      label="via"
                    />
                    <ChartRowElement
                      type="option"
                      label="NC"
                      name="actionsSections[4].T012xSACTQ002A010"
                    />
                    <ChartRowElement
                      type="option"
                      label="mask"
                      name="actionsSections[4].T012xSACTQ002A011"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[4].T012xSACTQ002A034"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[5] &&
                          actionsSections[5].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[5] &&
                          actionsSections[5].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[5].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bedside glucose"
                      name="actionsSections[5].T012xSACTQ002A012"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[5].T012xSACTQ002A013"
                      size="md"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[5].T012xSACTQ002A028"
                      label="mg/dL"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[5].T012xSACTQ002A035"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[6] &&
                          actionsSections[6].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[6] &&
                          actionsSections[6].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[6].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ready for provider eval"
                      name="actionsSections[6].T012xSACTQ002A014"
                    />
                    <ChartRowElement
                      type="option"
                      label="notified provider"
                      name="actionsSections[6].T012xSACTQ002A015"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[6].T012xSACTQ002A036"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[9] &&
                          actionsSections[9].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[9] &&
                          actionsSections[9].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[9].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="extremity elevated"
                      name="actionsSections[9].T012xSACTQ002A037"
                    />
                    <ChartRowElement
                      type="option"
                      label="cold pack"
                      name="actionsSections[9].T012xSACTQ002A038"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[9].T012xSACTQ002A039"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[8] &&
                          actionsSections[8].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[8] &&
                          actionsSections[8].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement type="date" name="actionsSections[8].date" />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="restraints"
                      name="actionsSections[8].T012xSACTQ002A021"
                    />
                    <ChartRowElement
                      type="option"
                      label="see documentation"
                      name="actionsSections[8].T012xSACTQ002A022"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[8].T012xSACTQ002A040"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[10] &&
                          actionsSections[10].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[10] &&
                          actionsSections[10].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="actionsSections[10].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[10].T012xSACTQ002A041"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[11] &&
                          actionsSections[11].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[11] &&
                          actionsSections[11].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="actionsSections[11].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[11].T012xSACTQ002A042"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[12] &&
                          actionsSections[12].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[12] &&
                          actionsSections[12].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="actionsSections[12].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[12].T012xSACTQ002A043"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={actionsConstants.CONTAINER_PADDING}
              >
                <ChartRow
                  templateColumns={
                    actionsConstants.ACTIONS_ROW_TEMPLATE_COLUMNS_4
                  }
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[13] &&
                          actionsSections[13].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[13] &&
                          actionsSections[13].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowElement
                    type="date"
                    name="actionsSections[13].date"
                  />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[13].T012xSACTQ002A044"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N12Actions };
