import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCHC";
const triageChartCode = "T003x";

function N03ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onSaveAndNext,
    onRouteChangeSave,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A020"
                  label="ocurred"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A001"
                  label="just PTA"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T003xSCHCQ001A002"
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A003"
                  label="min"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A004"
                  label="hrs"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A005"
                  label="days ago"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T003xSCHCQ001A021"
                  label="location:"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="tingling"
                  name="Q001.T003xSCHCQ001A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="numbness"
                  name="Q001.T003xSCHCQ001A009"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="weakness"
                  name="Q001.T003xSCHCQ001A011"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="pain"
                  name="Q001.T003xSCHCQ001A013"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="redness"
                  name="Q001.T003xSCHCQ001A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="swelling"
                  name="Q001.T003xSCHCQ001A016"
                />
                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="tenderness"
                  name="Q001.T003xSCHCQ001A018"
                />

                <ChartRowElement type="input" name="Q001.T003xSCHCQ001A019" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fall"
                  name="Q002.T003xSCHCQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="direct blow"
                  name="Q002.T003xSCHCQ002A002"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A003" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="twisting"
                  name="Q002.T003xSCHCQ002A004"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="crush"
                  name="Q002.T003xSCHCQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="jammed"
                  name="Q002.T003xSCHCQ002A007"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="bite"
                  name="Q002.T003xSCHCQ002A009"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="cut with"
                  name="Q002.T003xSCHCQ002A011"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="burn"
                  name="Q002.T003xSCHCQ002A013"
                />
                <ChartRowElement type="input" name="Q002.T003xSCHCQ002A014" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N03ChiefComplaint };
