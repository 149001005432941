const parts = ["togglebtn", "label", "btncontainer", "action"];

function baseStyle() {
  return {
    togglebtn: {
      transition: "all 200ms ease-in-out",
      width: "100%",
      height: "40px",
      bg: "gray.50",
      color: "black",
      border: "1px solid",
      borderColor: "gray.450",
      borderRadius: "10px",
      fontWeight: "unset",
      paddingLeft: "var(--chakra-space-3)",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      outline: "none",
      _hover: { bg: "gray.50" },
      _active: { bg: "gray.50" },
      _focus: { boxShadow: "var(--chakra-shadows-outline)" },
      _invalid: {
        borderColor: "red",
      },
      _disabled: { cursor: "not-allowed", opacity: 0.4 },
    },
    label: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    btncontainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: "6px",
    },
    action: {
      transition: "all 200ms ease-in-out",
      borderRadius: "50%",
      outline: "none",
      _hover: { cursor: "pointer" },
      _focus: {
        boxShadow: "var(--chakra-shadows-outline)",
      },
    },
  };
}

const customSelectTheme = {
  parts,
  baseStyle,
};

export { customSelectTheme };
