import { axiosIdentityInstance } from "config";
import { InsuranceProviderTag } from "modules/reception/types";
import { PaginationPayload, PaginationResponse } from "types";
import { useInfiniteQuery } from "react-query";

type InsuranceProvidersItemResponse = {
  id: string;
  name: string;
  payerId: string;
  claimStatusId: string;
  eligibilityId: string;
  providerId: string;
  professionalId: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  displayName: string;
  tag: InsuranceProviderTag;
};

type InsuranceProvidersResponse =
  PaginationResponse<InsuranceProvidersItemResponse>;

function useInsuranceProviders(props: PaginationPayload) {
  const { size = 10, page = 1 } = props;

  return useInfiniteQuery(
    "providers",
    ({ pageParam = page }) =>
      axiosIdentityInstance.get<InsuranceProvidersResponse>(
        "v1/InsuranceProvider",
        { params: { pageSize: size, page: pageParam } }
      ),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
      staleTime: 0,
    }
  );
}

export { useInsuranceProviders };
export type { InsuranceProvidersResponse, InsuranceProvidersItemResponse };
