import { axiosPatientInstance } from "config";
import { EncounterType } from "enums";
import { useAxiosQuery } from "hooks";
import { EncounterIntegration } from "modules/reception/types/encounter-integration";

type PatientEncounter = {
  encounterId: string;
  date: string;
  type: EncounterType;
  integrations: Partial<EncounterIntegration>;
};

type UsePatientEncountersResponse = PatientEncounter[];

function usePatientEncounters(encounterId: string) {
  return useAxiosQuery(["patientEncounters", { encounterId }], () =>
    axiosPatientInstance.get<UsePatientEncountersResponse>(
      `v1/Encounter/${encounterId}/encounters`
    )
  );
}

export type { PatientEncounter, UsePatientEncountersResponse };
export { usePatientEncounters };
