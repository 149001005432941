import * as React from "react";
import { GridProps, Grid } from "@chakra-ui/react";

type ChartRowProps = {
  isEvenlySpaced?: boolean;
} & GridProps;

function ChartRow(props: ChartRowProps) {
  const { isEvenlySpaced, children, ...gridProps } = props;
  const childrenArray = React.Children.toArray(children);

  return (
    <Grid
      columnGap="1rem"
      width="100%"
      alignItems="center"
      templateColumns={
        isEvenlySpaced
          ? `repeat(${childrenArray.length}, 1fr)`
          : `repeat(${childrenArray.length - 1}, max-content) 1fr`
      }
      {...gridProps}
    >
      {children}
    </Grid>
  );
}

export type { ChartRowProps };
export { ChartRow };
