import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUpdateEncounterPayment(encounterId: string, paymentId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterPayment", { encounterId, paymentId }],
    (input: FormData) =>
      axiosPatientInstance.put(
        `v1/encounter/${encounterId}/form/payment/${paymentId}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterPayments", { encounterId }]);
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
        queryClient.invalidateQueries(["encounterOverview", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterPayment",
          { encounterId, paymentId },
        ]);
        queryClient.invalidateQueries([
          "encounterPaymentFile",
          { encounterId, paymentId },
        ]);
      },
    }
  );
}

export { useUpdateEncounterPayment };
