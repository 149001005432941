import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const MoreHoriz = createIcon({
  path: (
    <path
      d="M11 22C17.0176 22 22 17.0069 22 11C22 4.98235 17.0069 0 10.9892 0C4.98235 0 0 4.98235 0 11C0 17.0069 4.99314 22 11 22ZM11 20.1667C5.9098 20.1667 1.84412 16.0902 1.84412 11C1.84412 5.9098 5.89902 1.83333 10.9892 1.83333C16.0794 1.83333 20.1559 5.9098 20.1667 11C20.1775 16.0902 16.0902 20.1667 11 20.1667ZM5.96373 12.5745C6.82647 12.5745 7.52745 11.8627 7.52745 11C7.52745 10.1373 6.81569 9.42549 5.96373 9.42549C5.0902 9.42549 4.38922 10.1373 4.38922 11C4.38922 11.8627 5.0902 12.5745 5.96373 12.5745ZM10.9892 12.5745C11.852 12.5745 12.5637 11.8627 12.5637 11C12.5637 10.1373 11.852 9.42549 10.9892 9.42549C10.1265 9.42549 9.41471 10.1373 9.41471 11C9.41471 11.8627 10.1265 12.5745 10.9892 12.5745ZM16.0255 12.5745C16.8882 12.5745 17.5892 11.8627 17.5892 11C17.5892 10.1373 16.8882 9.42549 16.0255 9.42549C15.152 9.42549 14.451 10.1373 14.451 11C14.451 11.8627 15.152 12.5745 16.0255 12.5745Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 22",
});

export { MoreHoriz };
