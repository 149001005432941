import React from "react";
import { HashRouter } from "react-router-dom";

type ReactRouterProps = { children: React.ReactNode };

function ReactRouter({ children }: ReactRouterProps) {
  return <HashRouter>{children}</HashRouter>;
}

export default ReactRouter;
