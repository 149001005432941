import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { EmrChevronRight } from "@medstonetech/slate-icons";
import * as React from "react";

function BackButton(props: ButtonProps) {
  const { children, ...rest } = props;

  return (
    <Button
      variant="label"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      color="blue"
      {...rest}
    >
      <Icon
        as={EmrChevronRight}
        width="10px"
        height="16px"
        transform="rotate(180deg)"
        marginRight="9px"
      />
      {children || "Back"}
    </Button>
  );
}

export { BackButton };
