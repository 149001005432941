import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type DeleteTicketsRequest = {
  ids: string[];
};

function useDeleteTickets(roomId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteTickets", { roomId }],
    (input: DeleteTicketsRequest) =>
      axiosIdentityInstance.delete("/v1/ticket", {
        params: { roomId },
        data: input.ids,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
        queryClient.invalidateQueries(["ticketsByRoom", { roomId }]);
      },
    }
  );
}

export { useDeleteTickets };
export type { DeleteTicketsRequest };
