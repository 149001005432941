import { axiosChartInstance } from "config";
import { ChartProgressStatus } from "enums";
import { ProgressNoteBy } from "enums/progress-noteBy";
import { useAxiosQuery } from "hooks";
import { PaginationResponse } from "types";

type UseProgressNotesProps = {
  noteBy: ProgressNoteBy;
  encounterId: string;
};

type UserEntity = {
  id: string;
  fullName: string;
  teams: string;
  pictureUrl: string;
};

type UseProgressNotesResponseItem = {
  id: string;
  encounterId: string;
  date: Date | string;
  createdAt: Date | string;
  createdByFullName: string | null;
  createdByProfilePictureUrl: string | null;
  updatedByFullName: string | null;
  updatedByProfilePictureUrl: string | null;
  status: ChartProgressStatus;
  notes: string | null;
  trasnferredTo?: string | null;
  transferredToEntity?: UserEntity | null;
  signedBy?: string | null;
  signedByEntity?: UserEntity | null;
};

type UseProgressNotesResponse =
  PaginationResponse<UseProgressNotesResponseItem>;

function useProgressNotes(props: UseProgressNotesProps) {
  const { encounterId, noteBy } = props;
  return useAxiosQuery(["progressNotes", props], () =>
    axiosChartInstance.get<UseProgressNotesResponse>(
      `v1/ProgressNote/${encounterId}/${noteBy}`,
      // @todo - Implement pagination in progress correctly
      { params: { pageSize: 1000 } }
    )
  );
}

export type { UseProgressNotesProps, UseProgressNotesResponseItem };
export { useProgressNotes };
