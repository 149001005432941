import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const ArrowUpCircle = createIcon({
  path: (
    <path
      d="M10 20C15.4695 20 20 15.4792 20 10C20 4.53049 15.4695 0 9.99032 0C4.52081 0 0 4.53049 0 10C0 15.4792 4.53049 20 10 20ZM10.0097 15.0823C9.48693 15.0823 9.10939 14.7241 9.10939 14.211V9.47725L9.20619 7.40561L8.29622 8.52856L7.17328 9.6999C7.02807 9.86447 6.80542 9.96128 6.56341 9.96128C6.08906 9.96128 5.74056 9.62246 5.74056 9.14811C5.74056 8.9061 5.80833 8.71249 5.97289 8.53824L9.303 5.17909C9.52565 4.94676 9.73863 4.84027 10.0097 4.84027C10.2904 4.84027 10.5034 4.94676 10.726 5.17909L14.0465 8.53824C14.211 8.71249 14.2885 8.9061 14.2885 9.14811C14.2885 9.62246 13.9303 9.96128 13.456 9.96128C13.2139 9.96128 13.001 9.87415 12.8461 9.6999L11.7328 8.53824L10.8132 7.39593L10.91 9.47725V14.211C10.91 14.7241 10.5324 15.0823 10.0097 15.0823Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { ArrowUpCircle };
