import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C040x";

function T40HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();

  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="uterine contractions"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vaginal bleeding"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vaginal fluid leakage"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="pelvic pain"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="abdominal pain"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="still present"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="better"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lasted:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="mild"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="6"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="7"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="8"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="9"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="10"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="contractions"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="pelvic pain"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="strength:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A032`}
                />
                <ChartRowElement
                  label="weak"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="strong"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pattern:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A033`}
                />
                <ChartRowElement
                  label="regular"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="irregular"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="frequency:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A034`}
                />
                <ChartRowElement
                  label="every"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A035`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A036`}
                />
                <ChartRowElement
                  label="uncertain"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A037`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A038`}
                />
                <ChartRowElement
                  label="brief"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="vaginal bleeding:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A039`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="(bloody show)"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="heavy"
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="pads / day"
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="leakage of fluid:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A040`}
                />
                <ChartRowElement
                  label="uncertain"
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="heavy gush"
                  name={`Q005.${chartCode}${sectionId}Q005A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q005.${chartCode}${sectionId}Q005A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q005.${chartCode}${sectionId}Q005A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A031`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Pregnancy History"
            >
              Pregnancy History
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Rh"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="prenatal care:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A052`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="clinic"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Dr."
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="prior sonogram:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A053`}
                />
                <ChartRowElement
                  label="date:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A054`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="IUP"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fetal demise"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pregnant"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="preg test:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A055`}
                />
                <ChartRowElement
                  label="home"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="in clinic"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="blood"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="urine"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="EDC:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A056`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="by:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A057`}
                />
                <ChartRowElement
                  label="ultrasound"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="dates"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="LMNP:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A058`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="G"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="P"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="Ab"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="c-section"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vaginal"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Problems w/ Current Pregnancy:"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A059`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gestational HTN"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="false labor"
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pre-eclampsia"
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gestional diabetes"
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Problems w/ Prior Pregnancy:"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A060`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q006.${chartCode}${sectionId}Q006A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fetal distress"
                  name={`Q006.${chartCode}${sectionId}Q006A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="precipitous delivery"
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="premature deliv."
                  name={`Q006.${chartCode}${sectionId}Q006A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="at"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A061`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A042`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="weeks"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A062`}
                />
                <ChartRowElement
                  label="pregnancy-induced HTN"
                  name={`Q006.${chartCode}${sectionId}Q006A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A044`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="gestional diabetes"
                  name={`Q006.${chartCode}${sectionId}Q006A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="failure to progress"
                  name={`Q006.${chartCode}${sectionId}Q006A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cranio-pelvic disproportion"
                  name={`Q006.${chartCode}${sectionId}Q006A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pre-eclampsia"
                  name={`Q006.${chartCode}${sectionId}Q006A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A051`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T40HPI };
