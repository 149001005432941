import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Import = createIcon({
  path: (
    <path
      d="M11.9932 20.2952C12.2772 20.2952 12.507 20.2141 12.7775 19.9437L17.3476 15.5223C17.5504 15.3194 17.6721 15.1031 17.6721 14.8192C17.6721 14.2648 17.2394 13.8727 16.6851 13.8727C16.4146 13.8727 16.1442 13.9808 15.9549 14.1972L13.8997 16.3741L12.9938 17.3341L13.0749 15.3059V1.05465C13.0749 0.486761 12.5746 0 11.9932 0C11.4118 0 10.9251 0.486761 10.9251 1.05465V15.3059L11.0062 17.3341L10.0868 16.3741L8.04507 14.1972C7.85577 13.9808 7.55831 13.8727 7.30141 13.8727C6.73352 13.8727 6.32789 14.2648 6.32789 14.8192C6.32789 15.1031 6.43606 15.3194 6.63887 15.5223L11.209 19.9437C11.493 20.2141 11.7228 20.2952 11.9932 20.2952ZM4.24563 29.1921H19.7544C22.5803 29.1921 24 27.7859 24 25.0006V11.5065C24 8.72113 22.5803 7.31493 19.7544 7.31493H15.982V9.49183H19.7138C21.0524 9.49183 21.8231 10.222 21.8231 11.6282V24.8789C21.8231 26.2851 21.0524 27.0152 19.7138 27.0152H4.27268C2.92056 27.0152 2.1769 26.2851 2.1769 24.8789V11.6282C2.1769 10.222 2.92056 9.49183 4.27268 9.49183H8.01803V7.31493H4.24563C1.41972 7.31493 0 8.72113 0 11.5065V25.0006C0 27.7859 1.41972 29.1921 4.24563 29.1921Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 30",
});

export { Import };
