import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const AuditCommentAlt = createIcon({
  path: (
    <path
      d="M7.13388 24C6.32189 24 5.8695 23.42 5.8695 22.5268V19.5109H5.31271C1.85597 19.5109 0 17.6085 0 14.1982V5.31271C0 1.90237 1.85597 0 5.31271 0H20.2533C23.71 0 25.566 1.91397 25.566 5.31271V14.1982C25.566 17.5969 23.71 19.5109 20.2533 19.5109H12.8062L8.80425 23.072C8.09666 23.6984 7.70227 24 7.13388 24ZM7.60947 21.8772L11.3214 18.1885C11.7622 17.7361 12.087 17.6433 12.7366 17.6433H20.2533C22.5848 17.6433 23.6984 16.4485 23.6984 14.1866V5.31271C23.6984 3.05075 22.5848 1.86757 20.2533 1.86757H5.31271C2.96955 1.86757 1.86757 3.05075 1.86757 5.31271V14.1866C1.86757 16.4485 2.96955 17.6433 5.31271 17.6433H6.73949C7.36588 17.6433 7.60947 17.8869 7.60947 18.5133V21.8772ZM7.41228 9.75544C7.41228 9.17545 7.81827 8.76945 8.40986 8.76945H11.8202V5.35911C11.8202 4.76752 12.2146 4.36153 12.7714 4.36153C13.363 4.36153 13.7574 4.76752 13.7574 5.35911V8.76945H17.1793C17.7709 8.76945 18.1653 9.17545 18.1653 9.75544C18.1653 10.3238 17.7593 10.7066 17.1793 10.7066H13.7574V14.1286C13.7574 14.7202 13.363 15.1145 12.7714 15.1145C12.2146 15.1145 11.8202 14.7086 11.8202 14.1286V10.7066H8.40986C7.81827 10.7066 7.41228 10.3238 7.41228 9.75544Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 24",
});

export { AuditCommentAlt };
