import {
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrCheckFilled, EmrPlus } from "@medstonetech/slate-icons";
import { IntegrationStatus } from "enums";
import { usePagination, useToast } from "hooks";
import { CloseCircle, Edit } from "icons";
import { formMessages, genericErrors } from "messages";
import {
  useAssignPreferredPharmacy,
  usePharmacyList,
  useRemovePreferredPharmacy,
} from "modules/charts-shared/api";
import { useUpdateEncounterIntegrationAYVAPharmacy } from "modules/reception/api";
import * as React from "react";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  usePagination as usePaginationReactTable,
  useSortBy,
  useTable,
} from "react-table";
import {
  ActionButton,
  Loading,
  SearchBarWidgetCard,
  SingleActionDialog,
  TableContainer,
  TableHeader,
  TableTopSection,
} from "shared";
import { PaginationControl } from "shared/pagination-control";
import { enumMapper, extractApiErrorMessage } from "utils";
import { maskString } from "utils/formatter";
import { Pharmacy } from "../../types";
import { AddPharmacyModal, EditPharmacyModal } from "./AddPharmacyModal";

type PharmacyListModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  preferredPharmacyId: string;
  encounterId: string;
}>;

function AddNewPharmacy() {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <ActionButton icon={<Icon as={EmrPlus} />} onClick={onOpen}>
        Add New
      </ActionButton>
      <AddPharmacyModal isModalOpen={isOpen} onModalClose={onClose} />
    </>
  );
}

function PharmacyListContent(props: PharmacyListModalProps) {
  const { nextPage, previousPage, setTotalPages, setSort, state } =
    usePagination({
      initialState: {
        page: 0,
        totalPages: 0,
        size: 8,
      },
    });

  const { onClose, preferredPharmacyId, encounterId } = props;
  const { page, size, sort } = state;
  const toast = useToast();
  const [preferredPharmacy, setPreferredPharmacy] = React.useState<
    string | undefined
  >(undefined);

  const [filters, setFilters] = React.useState<Record<string, string>>({
    name: "",
    address: "",
    city: "",
    NCPDPId: "",
  });

  const {
    onOpen: onEditOpen,
    isOpen: isEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [editPharmacyId, setEditPharmacyId] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    if (preferredPharmacyId) setPreferredPharmacy(preferredPharmacyId);
  }, [preferredPharmacyId]);

  const {
    mutateAsync: assignPreferredPharmacy,
    isLoading: isAssigningPharmacy,
  } = useAssignPreferredPharmacy(encounterId);
  const {
    mutateAsync: removePreferredPharmacy,
    isLoading: isRemovingPharmacy,
  } = useRemovePreferredPharmacy(encounterId);

  const {
    mutateAsync: updateIntegrationAYVAPharmacy,
    isLoading: isLoadingUpdateIntegrationAYVAPharmacy,
  } = useUpdateEncounterIntegrationAYVAPharmacy(encounterId, "PH");

  const {
    isOpen: isOpenAyvaModal,
    onClose: onCloseAyvaModal,
    onOpen: onOpenAyvaModal,
  } = useDisclosure();

  const [ayvaModalLabel, setAyvaModalLabel] = React.useState("");
  const [ayvaModalTitle, setAyvaModalTitle] = React.useState("");
  const [ayvaModalContent, setAyvaModalContent] = React.useState<string[]>([]);

  const onUpdateIntegrationAYVAPharmacy = React.useCallback(
    async (successStatus: IntegrationStatus) => {
      try {
        const ayvaRequest = await updateIntegrationAYVAPharmacy(successStatus);

        setAyvaModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
        setAyvaModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
        setAyvaModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
        onOpenAyvaModal();
      } catch (error) {
        toast({
          description:
            extractApiErrorMessage(error) || genericErrors.unknownError,
        });
      }
    },
    [onOpenAyvaModal, updateIntegrationAYVAPharmacy, toast]
  );

  const onAssignPreferredPharmacy = React.useCallback(
    async (pharmacyId: string) => {
      try {
        await assignPreferredPharmacy({ pharmacyId });
        setPreferredPharmacy(pharmacyId);
        toast({
          status: "success",
          description: formMessages.updateSuccess("Preferred Pharmacy"),
        });

        onUpdateIntegrationAYVAPharmacy("Success");
      } catch (error) {
        if (error instanceof Error) {
          toast({
            status: "error",
            description: error.message || genericErrors.unknownError,
          });
        }
      }
    },
    [assignPreferredPharmacy, onUpdateIntegrationAYVAPharmacy, toast]
  );

  const onRemovePreferredPharmacy = React.useCallback(async () => {
    try {
      await removePreferredPharmacy({});
      setPreferredPharmacy(undefined);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Preferred Pharmacy"),
      });

      onUpdateIntegrationAYVAPharmacy("Warning");
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  }, [removePreferredPharmacy, onUpdateIntegrationAYVAPharmacy, toast]);

  const { data, isLoading, isFetching, error } = usePharmacyList({
    page: page + 1,
    size,
    descending: sort?.desc,
    sort: sort?.id,
    ...filters,
  });

  const isUpdatingPreferredPharmacy =
    isAssigningPharmacy ||
    isRemovingPharmacy ||
    isLoadingUpdateIntegrationAYVAPharmacy;

  const columns: Array<Column<Pharmacy>> = React.useMemo(
    () => [
      { Header: "NCPDPID", accessor: "ncpdpId" },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone #",
        accessor: "phone",
      },
      {
        Header: "Fax #",
        accessor: "fax",
        Cell: ({ value }) => <>{maskString(value, "(###) ###-####")}</>,
      },
      {
        Header: "Street",
        accessor: "address",
      },
      {
        Header: "Suite #",
        accessor: "suite",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "ST",
        accessor: "state",
        Cell: ({ value }) => (
          <>{value ? enumMapper.toDisplay("usState", value) : null}</>
        ),
      },
      {
        Header: "ZIP",
        accessor: "zip",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <HStack spacing="12">
            <IconButton
              aria-label="check"
              icon={
                <Icon
                  as={EmrCheckFilled}
                  fontSize="1.25rem"
                  color={
                    preferredPharmacy === original.id ? "green" : "gray.450"
                  }
                />
              }
              variant="ghost"
              minWidth="unset"
              height="unset"
              onClick={() => {
                if (preferredPharmacy !== original.id)
                  onAssignPreferredPharmacy(original.id);
                else onRemovePreferredPharmacy();
              }}
              disabled={isUpdatingPreferredPharmacy}
              isLoading={
                isUpdatingPreferredPharmacy && preferredPharmacy === original.id
              }
            />
            <IconButton
              aria-label="edit"
              icon={<Icon as={Edit} fontSize="1.25rem" color="blue" />}
              onClick={() => {
                setEditPharmacyId(original.id);
                onEditOpen();
              }}
              variant="ghost"
              minWidth="unset"
              height="unset"
            />
          </HStack>
        ),
      },
    ],
    [
      preferredPharmacy,
      isUpdatingPreferredPharmacy,
      onEditOpen,
      onAssignPreferredPharmacy,
      onRemovePreferredPharmacy,
    ]
  );

  const options: TableOptions<Pharmacy> = React.useMemo(
    () => ({
      columns,
      data: data?.data.content || [],
      pageCount: data?.data.totalPages,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetPage: true,
      useControlledState: (tableState) =>
        React.useMemo(
          () => ({ ...tableState, pageIndex: page, pageSize: size }),
          // Eslint will think the outer variables page and size are
          // not necessary, but they are, so it is important to add
          // them in the deps array and override the eslint rule.
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [tableState, page, size]
        ),
    }),
    [columns, data, page, size]
  );
  const {
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(options, useGlobalFilter, useSortBy, usePaginationReactTable);

  React.useEffect(() => {
    setSort(sortBy[0]);
  }, [sortBy, setSort]);

  React.useEffect(() => {
    setTotalPages(options?.pageCount || 0);
  }, [options, setTotalPages]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <ModalContent
      maxWidth="calc(100% - 40px)"
      width="1600px"
      maxHeight="950px"
      height="calc(100% - 40px)"
      bg="gray.50"
      padding="20px"
      containerProps={{
        overflow: "visible",
      }}
    >
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="unset"
      >
        <HStack spacing="2.375rem" marginLeft="1.25rem" alignItems="center">
          <Heading fontSize="1.5rem" fontWeight="600">
            Pharmacy List
          </Heading>
        </HStack>
        <IconButton
          aria-label="close modal"
          variant="icon"
          icon={<Icon as={CloseCircle} />}
          size="lg"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody padding="unset" marginTop="26px" overflow="auto">
        <SearchBarWidgetCard
          fields={[
            { label: "NCPDPID", value: "NCPDPId" },
            { label: "Name", value: "name" },
            { label: "Address", value: "address" },
            { label: "City", value: "city" },
          ]}
          onChange={(values) => {
            setFilters(values);
          }}
          mb="1rem"
        />
        <TableContainer borderRadius="10px">
          <TableTopSection
            leftItems={<AddNewPharmacy />}
            rightItems={
              <PaginationControl
                currentPage={page + 1}
                onClickNext={nextPage}
                onClickPrevious={previousPage}
                pagesCount={data?.data.totalPages || 0}
              />
            }
          />
          <Table>
            <Thead>
              {headerGroups.map((headerGroup) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();

                return (
                  <Tr key={headerGroupKey} {...headerGroupProps}>
                    {headerGroup.headers.map((header) => {
                      const { key: headerKey } = header.getHeaderProps(
                        header.getSortByToggleProps()
                      );
                      return <TableHeader key={headerKey} header={header} />;
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody>
              {!isFetching &&
                rows.map((row) => {
                  prepareRow(row);
                  const { key: rowKey, ...rowProps } = row.getRowProps();
                  return (
                    <Tr key={rowKey} {...rowProps}>
                      {row.cells.map((cell) => {
                        const { key: cellKey, ...cellProps } =
                          cell.getCellProps();
                        return (
                          <Td
                            key={cellKey}
                            {...cellProps}
                            color={
                              row.original.id === preferredPharmacy
                                ? "black"
                                : "gray.700"
                            }
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              {(isLoading || isFetching) && (
                <Tr>
                  <Td colSpan={columns.length}>
                    <Loading />
                  </Td>
                </Tr>
              )}
              {!isLoading && !isFetching && rows.length === 0 && (
                <Tr>
                  <Td colSpan={columns.length}>
                    <Box
                      textAlign="center"
                      fontSize="1.25rem"
                      fontWeight="700"
                      color="gray.650"
                    >
                      There is no registered pharmacies at the moment.
                    </Box>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <EditPharmacyModal
          encounterId={encounterId}
          pharmacyId={editPharmacyId}
          isModalOpen={isEditOpen && !!editPharmacyId}
          onModalClose={onEditClose}
        />
        {isOpenAyvaModal && (
          <SingleActionDialog
            title={ayvaModalTitle}
            actionLabel={ayvaModalLabel}
            actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
            content={ayvaModalContent}
            onClose={onCloseAyvaModal}
            isOpen={isOpenAyvaModal}
          />
        )}
      </ModalBody>
    </ModalContent>
  );
}

function PharmacyListModal(props: Omit<PharmacyListModalProps, "children">) {
  const { isOpen, onClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
    >
      {isOpen && <PharmacyListContent {...props} />}
    </Modal>
  );
}

export { PharmacyListModal };
