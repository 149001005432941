import {
  mapFormObjectToAnswersList,
  mapAnswersListToFormObject,
} from "modules";
import {
  ProceduresForm,
  ProceduresPayload,
  ProceduresResponse,
  ProceduresRow,
  ProceduresSectionResponse,
} from "../types";
import { ChartAnswer } from "types";

const filterSection: (setcions: {
  index: number;
  date: Date | undefined;
  answers: ChartAnswer[];
}) => boolean = ({ date, answers }) => !!date || !!answers.length;

const sectionToPayload = (
  {
    date,
    id: _id,
    createdById: _createdById,
    createdByFullName: _createdByFullName,
    createdByPictureUrl: _createdByPictureUrl,
    createdDate: _createdDate,
    ...answersForm
  }: ProceduresRow,
  index: number
) => ({
  index,
  date,
  answers: mapFormObjectToAnswersList(answersForm),
});

function formToPayload(form: ProceduresForm): ProceduresPayload {
  const { sections } = form;

  return {
    sections: sections.map(sectionToPayload).filter(filterSection),
  };
}

function fillSections(sections: ProceduresSectionResponse[]) {
  const maxIndex = Math.max(...sections.map(({ index }) => index));

  const filledSections = Array.from({ length: maxIndex + 1 }, (_, i) => {
    const found = sections.find(({ index }) => index === i);
    return found || { date: undefined, answers: [], createdBy: undefined };
  });

  return filledSections.map(({ date, answers, createdBy }) => ({
    date: date && new Date(date),
    createdByFullName:
      createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
    createdByPictureUrl: createdBy && createdBy.pictureUrl,
    ...mapAnswersListToFormObject(answers),
  }));
}

function responseToForm(response: ProceduresResponse): ProceduresForm {
  const { sections } = response;

  const form = {
    sections: fillSections(sections || []),
  };

  return form;
}

export { formToPayload, responseToForm };
