import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";
import { RoomDto } from "modules/room-list/api";

function useRoomById(roomId: string) {
  return useAxiosQuery(["room", { roomId }], () => {
    return axiosIdentityInstance.get<RoomDto>(`v1/Room/${roomId}`);
  });
}

export { useRoomById };
