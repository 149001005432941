import { Box, BoxProps, Icon, Th } from "@chakra-ui/react";
import { DownArrowFilled, UpArrowFilled } from "icons";
import * as React from "react";
import { HeaderGroup } from "react-table";
import { AnyObject } from "types";

type TableHeaderProps<T extends AnyObject> = BoxProps & {
  header: HeaderGroup<T>;
  centeredHeader?: boolean;
};

function TableHeader<T extends AnyObject>(props: TableHeaderProps<T>) {
  const { header, centeredHeader = false, ...thProps } = props;
  const headerProps = header.getHeaderProps(header.getSortByToggleProps());

  const centeredAttributes: BoxProps = React.useMemo<BoxProps>(
    () => (centeredHeader ? { justifyContent: "center" } : {}),
    [centeredHeader]
  );

  return (
    <Th {...headerProps} {...thProps}>
      <Box display="flex" alignItems="center" {...centeredAttributes}>
        {header.render("Header")}
        {header.isSorted ? (
          <>
            {header.isSortedDesc ? (
              <Icon
                as={DownArrowFilled}
                fontSize="0.625rem"
                color="gray.450"
                marginLeft="14px"
              />
            ) : (
              <Icon
                as={UpArrowFilled}
                fontSize="0.625rem"
                color="gray.450"
                marginLeft="14px"
              />
            )}
          </>
        ) : null}
      </Box>
    </Th>
  );
}

export type { TableHeaderProps };
export { TableHeader };
