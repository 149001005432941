import axios from "axios";
import { useAxiosQuery } from "hooks";

function useStaffProfilePicture(
  userId: string,
  pictureUrl: string,
  options: { enabled: boolean }
) {
  const { enabled } = options;
  return useAxiosQuery(
    ["staffProfilePicture", { userId }],
    () =>
      axios({
        method: "get",
        url: pictureUrl,
        responseType: "blob",
      }),
    { staleTime: 1000 * 60 * 10, enabled }
  );
}

export { useStaffProfilePicture };
