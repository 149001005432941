import React from "react";
import { Tabs as CkTabs, TabsProps as CkTabsProps } from "@chakra-ui/react";
import { TabListProps } from "./TabList";
import { TabsVariant } from "./types";

type TabsProps = Omit<CkTabsProps, "variant"> & {
  variant?: TabsVariant;
};

function Tabs(props: TabsProps) {
  const { variant = "solid-rounded", children, ...rest } = props;
  const clonedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return <>{child}</>;
    }

    return React.cloneElement<TabListProps>(child, { variant });
  });

  return (
    <CkTabs variant={variant} {...rest}>
      {clonedChildren}
    </CkTabs>
  );
}

export type { TabsProps };
export { Tabs };
