import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type ChartSectionInfoProps = {
  encounterId: string;
  chartCode: string;
  sectionCode: string;
};

type ChartSectionInfoResponse = {
  id: string;
  chartFavoriteId?: string | null;
};

function useChartSectionInfo({
  encounterId,
  chartCode,
  sectionCode,
}: ChartSectionInfoProps) {
  return useAxiosQuery(
    ["chartSectionInfo", { encounterId, chartCode, sectionCode }],
    () =>
      axiosChartInstance.get<ChartSectionInfoResponse>(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionCode}/section-info`
      )
  );
}

export { useChartSectionInfo };
