import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const UpArrowFilled = createIcon({
  path: (
    <path
      d="M0,9.9c0,0.5,0.4,0.8,0.9,0.8l8.2,0c0.5,0,0.9-0.3,0.9-0.8c0-0.3-0.1-0.5-0.2-0.7L5.8,1.1
    C5.6,0.5,5.3,0.4,5,0.4c-0.3,0-0.6,0.2-0.8,0.7L0.2,9.2C0.1,9.4,0,9.6,0,9.9z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 10 11",
});

export { UpArrowFilled };
