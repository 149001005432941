import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";

function useUpdateProviderMedicalHistory(encounterId: string) {
  return useAxiosMutation(
    ["chartApplication", { encounterId }],
    (input: ChartFormSubsectionPayload[]) =>
      axiosChartInstance.post(`v1/chart/${encounterId}/medical-history`, input)
  );
}

export { useUpdateProviderMedicalHistory };
