import * as React from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";

import { debounce } from "throttle-debounce";

import { AllergyRxType, MedicationRxType } from "enums";
import { Edit } from "icons";
import { AllergyRx, MedicationRx } from "modules/charts-shared/types";

type AllergyRxInfoProps = {
  item: AllergyRx;
  type: AllergyRxType;
  onEditItem?: () => void;
};

function AllergyRxInfo(props: AllergyRxInfoProps) {
  const { item, onEditItem } = props;

  return (
    <Box width="100%">
      <Flex justifyContent="space-between" width="100%" p="1rem 1.5rem">
        <Heading fontSize="1rem" fontWeight="500">
          {item.allergy}
        </Heading>
        <Button
          position="relative"
          right="6px"
          fontSize="0.9375rem"
          color="blue"
          display="flex"
          variant="label"
          alignItems="center"
          onClick={() => onEditItem && onEditItem()}
        >
          <Icon as={Edit} fontSize="1.2125rem" />
        </Button>
      </Flex>
      <Box width="100%" flex={10} display="flex" flexDirection="column">
        <List>
          {item.reactions
            .filter((r) => r.scope === "PRESET")
            .map((reaction) => (
              <ListItem
                key={reaction.id}
                display="flex"
                alignItems="center"
                p="0.5rem 1.5rem"
              >
                &#8226;
                <Text color="black" fontSize="1rem" pl={2} fontWeight="500">
                  {reaction.reaction}
                </Text>
              </ListItem>
            ))}
        </List>
      </Box>

      <Box
        width="100%"
        flex={10}
        display="flex"
        flexDirection="column"
        gap="20px"
        paddingLeft="25px"
      >
        <List spacing={1}>
          {item.reactions
            .filter((r) => r.scope === "CUSTOM")
            .map((reaction) => (
              <ListItem
                key={reaction.id}
                display="flex"
                alignItems="center"
                height="32px"
              >
                &#8226;
                <Text color="black" fontSize="md" pl={2}>
                  {reaction.reaction}
                </Text>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
}

type MedicationRxFormProps = {
  item: MedicationRx;
  type: MedicationRxType;
  isReadOnly: boolean;
  onUpdateItem: (type: MedicationRxType, item: MedicationRx) => void;
};

function MedicationRxForm(props: MedicationRxFormProps) {
  const { item, type, onUpdateItem, isReadOnly = false } = props;

  const [updatedItem, setUpdatedItem] = React.useState(item);

  const [medName, setMedName] = React.useState(item.medName ?? "");
  const [medDosage, setMedDosage] = React.useState(item.medDosage ?? "");
  const [medFrequency, setMedFrequency] = React.useState(
    item.medFrequency ?? ""
  );

  const debouncedMedName = debounce(500, (value: string) => {
    setUpdatedItem({ ...updatedItem, medName: value });
  });

  const debouncedMedDosage = debounce(500, (value: string) => {
    setUpdatedItem({ ...updatedItem, medDosage: value });
  });

  const debouncedMedFrequency = debounce(500, (value: string) => {
    setUpdatedItem({ ...updatedItem, medFrequency: value });
  });

  React.useEffect(() => {
    if (
      item.medName !== updatedItem.medName ||
      item.medDosage !== updatedItem.medDosage ||
      item.medFrequency !== updatedItem.medFrequency
    ) {
      onUpdateItem(type, {
        ...item,
        medName: updatedItem.medName ?? "",
        medDosage: updatedItem.medDosage ?? "",
        medFrequency: updatedItem.medFrequency ?? "",
      });
    }
  }, [updatedItem, item, onUpdateItem, type]);

  return (
    <Box as="form" flex={10} display="flex" flexDirection="row" gap="20px">
      <Box flex={"250px"}>
        <Flex columnGap="10px">
          <Input
            type="text"
            value={medName}
            disabled={isReadOnly}
            onChange={(e) => {
              setMedName(e.target.value || "");
              debouncedMedName(e.target.value || "");
            }}
          />
        </Flex>
      </Box>

      <Box flex={"90px"}>
        <Flex columnGap="10px">
          <Input
            type="text"
            value={medDosage}
            disabled={isReadOnly}
            onChange={(e) => {
              setMedDosage(e.target.value || "");
              debouncedMedDosage(e.target.value || "");
            }}
          />
        </Flex>
      </Box>

      <Box flex={"300px"}>
        <Flex columnGap="10px">
          <Input
            type="text"
            value={medFrequency}
            disabled={isReadOnly}
            onChange={(e) => {
              setMedFrequency(e.target.value || "");
              debouncedMedFrequency(e.target.value || "");
            }}
          />
        </Flex>
      </Box>
    </Box>
  );
}

export { AllergyRxInfo, MedicationRxForm };
