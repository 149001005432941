const sectionCode = "SPRO2";
const chartCode = "C073x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T73Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T73Procedures };
