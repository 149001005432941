import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Edit = createIcon({
  path: (
    <path
      d="M16.4057 3.57006L17.5469 2.4514C18.1232 1.87512 18.1458 1.25364 17.6373 0.733855L17.2531 0.349668C16.7446 -0.158814 16.1232 -0.102316 15.5469 0.451365L14.4056 1.58133L16.4057 3.57006ZM3.08341 16.8584L15.4113 4.54182L13.4339 2.55309L1.09468 14.881L0.0212156 17.3895C-0.0804809 17.6607 0.202009 17.9771 0.4732 17.8641L3.08341 16.8584Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { Edit };
