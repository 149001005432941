import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { Chart } from "types";

type UseGetChart = {
  chartCode: string;
};

function useGetChart(props: UseGetChart) {
  const { chartCode } = props;
  return useAxiosQuery(
    ["getChart", { chartCode }],
    () => axiosChartInstance.get<Chart>(`/v1/Chart/${chartCode}`, {}),
    { staleTime: 1 * 60 * 1000 }
  );
}

export { useGetChart };
