import {
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrClose } from "@medstonetech/slate-icons";
import * as React from "react";
import {
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageIndicator,
  PdfViewerPreview,
  PdfViewerProps,
} from "./PdfViewer";

type PdfViewerModalProps = Pick<UseDisclosureReturn, "onClose" | "isOpen"> &
  Pick<PdfViewerProps, "value"> & { title: string };

function PdfViewerModal(props: PdfViewerModalProps) {
  const { isOpen, onClose, value, title } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="100%"
        height="100%"
        padding="0"
        maxWidth="unset"
        marginTop="0"
        marginBottom="0"
      >
        <PdfViewer value={value}>
          <PdfViewerContainer
            height="100%"
            activePage={<PdfViewerActivePage />}
            header={
              <PdfViewerHeader
                title={title}
                leftElements={[<PdfViewerPageIndicator key="page-indicator" />]}
                rightElements={[
                  <IconButton
                    key="close-button"
                    aria-label="close"
                    onClick={onClose}
                    icon={<Icon as={EmrClose} />}
                    variant="icon"
                  />,
                ]}
              />
            }
            preview={<PdfViewerPreview />}
          />
        </PdfViewer>
      </ModalContent>
    </Modal>
  );
}

export { PdfViewerModal };
