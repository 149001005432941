import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateAuditCommentsReplyPayload = { reply: string };

function useCreateAuditCommentsReply(
  commentId: string,
  encounterId: string,
  chartCode: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "createAuditCommentsReply",
      { encounterId, chartCode, sectionCode, subsectionCode },
    ],
    (input: UseCreateAuditCommentsReplyPayload) =>
      axiosChartInstance.put(
        `/v1/audits/subsection/comments/reply/${commentId}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditComments",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsReplySubsectionIndicator",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

function useCreateAuditCommentsSharedReply(
  commentId: string,
  encounterId: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "createAuditCommentsReplyShared",
      { encounterId, sectionCode, subsectionCode },
    ],
    (input: UseCreateAuditCommentsReplyPayload) =>
      axiosChartInstance.put(
        `/v1/audits/subsection/comments/reply/${commentId}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsShared",
          { encounterId, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

export type { UseCreateAuditCommentsReplyPayload };
export { useCreateAuditCommentsReply, useCreateAuditCommentsSharedReply };
