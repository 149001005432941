import { AddRounded } from "icons";
import { EmrRemove } from "@medstonetech/slate-icons";
import { Box, Button, Flex, Heading, Icon, VStack } from "@chakra-ui/react";

type RxSectionGrouperProps = {
  label: string;
  onAddItem: () => void;
  grouperElements: JSX.Element;
  headerActionButton?: JSX.Element;
  isAddDisabled?: boolean;
};

function RxSectionGrouper(props: RxSectionGrouperProps) {
  const {
    label,
    onAddItem,
    grouperElements,
    headerActionButton,
    isAddDisabled,
  } = props;

  return (
    <Box>
      <VStack>
        <Flex
          justifyContent="space-between"
          width="100%"
          paddingLeft="12px"
          marginBottom="9px"
        >
          <Heading
            fontSize="1.125rem"
            display="flex"
            alignItems="center"
            gap={4}
          >
            {label}
            {headerActionButton}
          </Heading>
          <Button
            position="relative"
            right="18px"
            fontSize="0.9375rem"
            color="blue"
            display="flex"
            variant="label"
            alignItems="center"
            onClick={onAddItem}
            disabled={isAddDisabled}
          >
            <Icon as={AddRounded} fontSize="1.525rem" />
          </Button>
        </Flex>

        <Box w="100%" mt="15px" mb="10px">
          {grouperElements}
        </Box>
      </VStack>
    </Box>
  );
}

type RxSectionProps = {
  onRemoveItem?: () => void;
  sectionContent: JSX.Element;
};

function RxSection(props: RxSectionProps) {
  const { sectionContent, onRemoveItem } = props;

  return (
    <Flex justifyContent="space-between" w="100%">
      {sectionContent}

      {onRemoveItem && (
        <Box flex={0.5}>
          <Button
            w="100%"
            top="18px"
            height="100%"
            fontSize="0.9375rem"
            color="red"
            display="flex"
            variant="label"
            alignItems="center"
            marginLeft="8px"
            position="static"
            onClick={() => onRemoveItem && onRemoveItem()}
          >
            <Icon as={EmrRemove} fontSize="1.525rem" />
          </Button>
        </Box>
      )}
    </Flex>
  );
}

export { RxSectionGrouper, RxSection };
