import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartProceduresForm } from "../../hooks";
import { ProceduresHeader, proceduresConstants } from "../../shared";

const triageChartCode = "T012x";

const { PROCEDURES_CONTAINER_PADDING, PROCEDURES_ROW_TEMPLATE_COLUMNS_2 } =
  proceduresConstants;

function N12Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ProceduresHeader />
        <ChartSubsection
          content={
            <>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[0] && sections[0].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[0] && sections[0].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[0].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="portable"
                      name="sections[0].T012xSPROQ001A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="to Xray"
                      name="sections[0].T012xSPROQ001A002"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[0].T012xSPROQ001A053"
                      label="w/:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="monitor"
                      name="sections[0].T012xSPROQ001A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[0].T012xSPROQ001A004"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[0].T012xSPROQ001A005"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[0].T012xSPROQ001A006"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[0].T012xSPROQ001A063"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[1] && sections[1].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[1] && sections[1].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[1].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="return to room"
                      name="sections[1].T012xSPROQ001A007"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[1].T012xSPROQ001A008"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[2] && sections[2].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[2] && sections[2].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[2].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="to CT"
                      name="sections[2].T012xSPROQ001A009"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[2].T012xSPROQ001A054"
                      label="w/:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="contrast"
                      name="sections[2].T012xSPROQ001A010"
                    />
                    <ChartRowElement
                      type="option"
                      label="parental meds"
                      name="sections[2].T012xSPROQ001A011"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[2].T012xSPROQ001A064"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[2].T012xSPROQ001A065"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[2].T012xSPROQ001A055"
                      label="w/:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="monitor"
                      name="sections[2].T012xSPROQ001A012"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[2].T012xSPROQ001A013"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[2].T012xSPROQ001A014"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[2].T012xSPROQ001A015"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[4] && sections[4].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[4] && sections[4].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[4].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="return to room"
                      name="sections[4].T012xSPROQ001A017"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[4].T012xSPROQ001A018"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[5] && sections[5].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[5] && sections[5].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[5].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="labs drawn"
                      name="sections[5].T012xSPROQ001A019"
                    />
                    <ChartRowElement
                      type="option"
                      label="labs sent"
                      name="sections[5].T012xSPROQ001A020"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[5].T012xSPROQ001A056"
                      label="by:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[5].T012xSPROQ001A021"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[5].T012xSPROQ001A022"
                    />
                    <ChartRowElement
                      type="option"
                      label="lab"
                      name="sections[5].T012xSPROQ001A023"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[5].T012xSPROQ001A066"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[6] && sections[6].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[6] && sections[6].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[6].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="laceration repair"
                      name="sections[6].T012xSPROQ001A024"
                    />
                    <ChartRowElement
                      type="option"
                      label="to:"
                      fontStyle="italic"
                      name="sections[6].T012xSPROQ001A067"
                    />
                    <ChartRowElement
                      type="option"
                      label="scalp"
                      name="sections[6].T012xSPROQ001A068"
                    />
                    <ChartRowElement
                      type="option"
                      label="face"
                      name="sections[6].T012xSPROQ001A069"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[6].T012xSPROQ001A070"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[7] && sections[7].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[7] && sections[7].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[7].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      name="sections[7].T012xSPROQ001A057"
                      label="assisted by:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[7].T012xSPROQ001A026"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[7].T012xSPROQ001A027"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[7].T012xSPROQ001A058"
                      label="site:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[7].T012xSPROQ001A028"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[8] && sections[8].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[8] && sections[8].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[8].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="dislocation"
                      name="sections[8].T012xSPROQ001A029"
                    />
                    <ChartRowElement
                      type="option"
                      label="fx reduction"
                      name="sections[8].T012xSPROQ001A030"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[8].T012xSPROQ001A071"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      name="sections[8].T012xSPROQ001A059"
                      label="assisted by"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[8].T012xSPROQ001A032"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[8].T012xSPROQ001A033"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[8].T012xSPROQ001A060"
                      label="site:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[8].T012xSPROQ001A034"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[10] && sections[10].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[10] && sections[10].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[10].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="splint applied"
                      name="sections[10].T012xSPROQ001A035"
                    />
                    <ChartRowElement
                      type="option"
                      label="sling applied"
                      name="sections[10].T012xSPROQ001A036"
                    />
                    <ChartRowElement
                      type="option"
                      label="arm"
                      name="sections[10].T012xSPROQ001A037"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="leg"
                      name="sections[10].T012xSPROQ001A072"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="short"
                      name="sections[10].T012xSPROQ001A038"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="long"
                      name="sections[10].T012xSPROQ001A039"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[10].T012xSPROQ001A061"
                      label="type:"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[10].T012xSPROQ001A040"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[16] && sections[16].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[16] && sections[16].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[16].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="Foley"
                      name="sections[16].T012xSPROQ001A073"
                    />
                    <ChartRowElement
                      type="option"
                      label="straight cath"
                      name="sections[16].T012xSPROQ001A074"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[16].T012xSPROQ001A075"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[12] && sections[12].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[12] && sections[12].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[12].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="post-splint assessment:"
                      name="sections[12].T012xSPROQ001A041"
                    />
                    <ChartRowElement
                      type="option"
                      label="nml"
                      name="sections[12].T012xSPROQ001A076"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="color"
                      name="sections[12].T012xSPROQ001A042"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="sensation"
                      name="sections[12].T012xSPROQ001A043"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="movement"
                      name="sections[12].T012xSPROQ001A044"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[12].T012xSPROQ001A077"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[17] && sections[17].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[17] && sections[17].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[17].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="urine collection bag placed"
                      name="sections[17].T012xSPROQ001A078"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[17].T012xSPROQ001A079"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[13] && sections[13].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[13] && sections[13].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[13].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="cleaned wound"
                      name="sections[13].T012xSPROQ001A045"
                    />
                    <ChartRowElement
                      type="option"
                      label="applied abx ointment"
                      name="sections[13].T012xSPROQ001A046"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[13].T012xSPROQ001A080"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[14] && sections[14].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[14] && sections[14].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[14].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      name="sections[14].T012xSPROQ001A062"
                      label="applied:"
                      fontStyle="italic "
                    />
                    <ChartRowElement
                      type="option"
                      label="dressing"
                      name="sections[14].T012xSPROQ001A047"
                    />
                    <ChartRowElement
                      type="option"
                      label="Band-Aid"
                      name="sections[14].T012xSPROQ001A048"
                    />
                    <ChartRowElement
                      type="option"
                      label="elastic wrap"
                      name="sections[14].T012xSPROQ001A049"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[14].T012xSPROQ001A081"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[15] && sections[15].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[15] && sections[15].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[15].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="crutch training"
                      name="sections[15].T012xSPROQ001A051"
                    />
                    <ChartRowElement
                      type="option"
                      label="w/ proper return demonstration"
                      name="sections[15].T012xSPROQ001A052"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[15].T012xSPROQ001A082"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[18] && sections[18].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[18] && sections[18].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[18].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[18].T012xSPROQ001A083"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[19] && sections[19].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[19] && sections[19].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[19].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[19].T012xSPROQ001A084"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[20] && sections[20].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[20] && sections[20].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[20].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[20].T012xSPROQ001A085"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[21] && sections[21].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[21] && sections[21].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[21].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[21].T012xSPROQ001A086"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[22] && sections[22].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[22] && sections[22].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[22].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[22].T012xSPROQ001A087"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N12Procedures };
