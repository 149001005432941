import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const HeartDocument = createIcon({
  path: (
    <path
      d="M3.07007 18H14.9299C16.9832 18 18 16.9832 18 14.969V3.03096C18 1.01684 16.9832 0 14.9299 0H3.07007C1.02662 0 0 1.00706 0 3.03096V14.969C0 16.9929 1.02662 18 3.07007 18ZM4.39978 2.63009C4.92776 2.63009 5.32863 2.93319 5.57306 3.38294C5.81749 2.93319 6.23791 2.63009 6.72678 2.63009C7.55785 2.63009 8.15426 3.25584 8.15426 4.11624C8.15426 5.38729 6.81477 6.57034 5.82727 7.18631C5.73927 7.2352 5.65128 7.29386 5.58284 7.29386C5.52417 7.29386 5.4264 7.24498 5.3384 7.18631C4.37045 6.5019 3.00163 5.38729 3.00163 4.11624C3.00163 3.25584 3.59804 2.63009 4.39978 2.63009ZM3.6176 11.6643C3.25584 11.6643 3.00163 11.4003 3.00163 11.0581C3.00163 10.7257 3.25584 10.4617 3.6176 10.4617H13.4438C13.786 10.4617 14.0402 10.7257 14.0402 11.0581C14.0402 11.4003 13.786 11.6643 13.4438 11.6643H3.6176ZM3.6176 15.0081C3.25584 15.0081 3.00163 14.7442 3.00163 14.4117C3.00163 14.0695 3.25584 13.8055 3.6176 13.8055H10.8626C11.2146 13.8055 11.4688 14.0695 11.4688 14.4117C11.4688 14.7442 11.2146 15.0081 10.8626 15.0081H3.6176Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { HeartDocument };
