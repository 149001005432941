import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C014x";

function T14HPI() {
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });

      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );

      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A001"
                  label="fever"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A002"
                  label="cough"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A003"
                  label="congested"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A004"
                  label="fussy"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A005"
                  label="pulling ears"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A006"
                  label="not eating"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A007"
                  label="less active"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A008"
                  label="vomiting"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A009"
                  label="diarrhea"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A011"
                  label="rash"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.C014xSHPIQ001A012"
                  label="injestion"
                />
                <ChartRowElement type="input" name="Q001.C014xSHPIQ001A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q001.C014xSHPIQ001A013" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset / Duration"
            >
              Onset / Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="just PTA"
                  name="Q002.C014xSHPIQ002A001"
                />
                <ChartRowElement
                  type="input"
                  name="Q002.C014xSHPIQ002A002"
                  defaultValue=""
                  width="50px"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  label="minutes ago"
                  name="Q002.C014xSHPIQ002A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hours ago"
                  name="Q002.C014xSHPIQ002A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="days ago"
                  name="Q002.C014xSHPIQ002A005"
                />
                <ChartRowElement type="input" name="Q002.C014xSHPIQ002A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="gradual onset"
                  name="Q002.C014xSHPIQ002A007"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sudden-onset"
                  name="Q002.C014xSHPIQ002A008"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="intermittent episodes lasting:"
                  name="Q002.C014xSHPIQ002A009"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="input"
                  name="Q002.C014xSHPIQ002A0010"
                  width="400px"
                />
                <ChartRowElement
                  type="cross-option"
                  label="worse"
                  name="Q002.C014xSHPIQ002A011"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="persistent"
                  name="Q002.C014xSHPIQ002A012"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="since"
                  name="Q002.C014xSHPIQ002A013"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q002.C014xSHPIQ002A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q002.C014xSHPIQ002A015" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="sick contacts"
                  name="Q003.C014xSHPIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="home"
                  name="Q003.C014xSHPIQ003A002"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="school"
                  name="Q003.C014xSHPIQ003A003"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q003.C014xSHPIQ003A004"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q003.C014xSHPIQ003A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C014xSHPIQ003A008"
                  label="recent travel:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="outside US"
                  name="Q003.C014xSHPIQ003A006"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q003.C014xSHPIQ003A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C014xSHPIQ003A008"
                  label="other:"
                />
                <ChartRowElement type="input" name="Q003.C014xSHPIQ003A009" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fever to "
                  name="Q004.C014xSHPIQ004A001"
                />
                <ChartRowElement
                  type="input"
                  name="Q004.C014xSHPIQ004A002"
                  defaultValue=""
                  width="100px"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C014xSHPIQ004A011"
                  label="°F"
                />
                <ChartRowElement
                  type="cross-option"
                  label="oral "
                  name="Q004.C014xSHPIQ004A003"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="rectal"
                  name="Q004.C014xSHPIQ004A004"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="axillary"
                  name="Q004.C014xSHPIQ004A005"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="TM"
                  name="Q004.C014xSHPIQ004A006"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="not measured - subjective"
                  name="Q004.C014xSHPIQ004A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="none noted"
                  name="Q004.C014xSHPIQ004A008"
                />
                <ChartRowElement type="input" name="Q004.C014xSHPIQ004A009" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Associated symptoms"
            >
              Associated symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="acting differently:"
                  name="Q005.C014xSHPIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fussy"
                  name="Q005.C014xSHPIQ005A002"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="crying more"
                  name="Q005.C014xSHPIQ005A003"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="not sleeping"
                  name="Q005.C014xSHPIQ005A004"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="less active"
                  name="Q005.C014xSHPIQ005A005"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="inconsolable"
                  name="Q005.C014xSHPIQ005A006"
                  fontStyle="italic"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="drinking less"
                  name="Q005.C014xSHPIQ005A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="eating less"
                  name="Q005.C014xSHPIQ005A009"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="not drinking"
                  name="Q005.C014xSHPIQ005A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="decreased urination"
                  name="Q005.C014xSHPIQ005A014"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A015" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q005.C014xSHPIQ005A016"
                  label="last feeding:"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q005.C014xSHPIQ005A017"
                  label="last liquids:"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A018" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="last urinated:"
                  name="Q005.C014xSHPIQ005A019"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A020" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="sleeping more"
                  name="Q005.C014xSHPIQ005A021"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A022" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other:"
                  name="Q005.C014xSHPIQ005A023"
                />
                <ChartRowElement type="input" name="Q005.C014xSHPIQ005A024" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="similar symptoms previously"
                  name="Q006.C014xSHPIQ006A001"
                />
                <ChartRowElement type="input" name="Q006.C014xSHPIQ006A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="recenly:"
                  name="Q006.C014xSHPIQ006A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="seen by doctor"
                  name="Q006.C014xSHPIQ006A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="treated by doctor"
                  name="Q006.C014xSHPIQ006A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hospitalized"
                  name="Q006.C014xSHPIQ006A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="PCP sent to ER"
                  name="Q006.C014xSHPIQ006A007"
                />
                <ChartRowElement type="input" name="Q006.C014xSHPIQ006A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T14HPI };
