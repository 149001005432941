import * as React from "react";
import * as Yup from "yup";

import {
  Box,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { Input, InputProps } from "shared";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useCreateStpVipNotes,
  usePatchEncounterField,
} from "modules/reception/api";
import { useToast, useYupValidationResolver } from "hooks";

import { EmrSendouts } from "@medstonetech/slate-icons";
import { extractApiErrorMessage } from "utils";
import { genericErrors } from "messages";
import { usePatientBasicInfoContext } from "modules/reception/contexts";

type StpVipNoteForm = {
  note: string;
  type: "Stp" | "Vip" | "Other";
};

type STPVipNotesModalFooterProps = {
  patientId: string;
  type: "Stp" | "Vip" | "Other";
  onClose(): void;
  disabled: boolean;
};

const STP_VIP_NOTE_FORM_SCHEMA = Yup.object().shape({
  note: Yup.string().required(),
  type: Yup.string().required(),
});

function STPVipNotesModalFooter(props: STPVipNotesModalFooterProps) {
  const { patientId, type, onClose, disabled } = props;
  const { mutateAsync: createNote, isLoading: isCreateNoteLoading } =
    useCreateStpVipNotes(patientId, type);
  const isLoading = isCreateNoteLoading;

  const resolver = useYupValidationResolver(STP_VIP_NOTE_FORM_SCHEMA);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<StpVipNoteForm>({
    resolver,
  });

  React.useEffect(() => {
    setValue("type", type);
  }, [setValue, type]);

  const { painProtocolPatientFormContext, vipPatientFormContext } =
    usePatientBasicInfoContext();

  const { mutateAsync, isLoading: isSaving } = usePatchEncounterField({
    encounterId: patientId,
  });

  const toast = useToast();

  const onSubmit: SubmitHandler<StpVipNoteForm> = async (values) => {
    try {
      await createNote(values);

      if (type === "Stp") {
        await mutateAsync([{ path: "painProtocol", value: true }]);
        painProtocolPatientFormContext.setValue("painProtocol", true);
        toast({ description: "Patient marked as STP successfully" });
      }

      if (type === "Vip") {
        await mutateAsync([{ path: "isVip", value: true }]);
        vipPatientFormContext.setValue("isVip", true);
        toast({ description: "Patient marked as VIP successfully" });
      }

      onClose();
      reset({ note: "" });
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const { onChange: registerOnChange, ...restRegister } = register("note");
  const onChange: InputProps["onChange"] = (e) => {
    registerOnChange(e);
  };
  const onKeyPress: InputProps["onKeyPress"] = (e) => {
    if (e.key === "Enter" && !disabled) {
      handleSubmit(onSubmit)(e);
    }
  };

  return (
    <ModalFooter height="60px" bg="gray.50" padding="0 60px">
      <Box width="100%" position="relative" display="flex" alignItems="center">
        <InputGroup>
          <Input
            placeholder="Type here"
            backgroundColor="white"
            borderRadius="25px"
            isDisabled={isSubmitting || isLoading || disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...restRegister}
          />

          <InputRightElement width="unset" right="1rem">
            {/* If note is being uploaded show a loading */}
            {isLoading && <Spinner width="20px" height="20px" color="blue" />}
            {/* If the user is typing show the submit button */}
            {!isLoading && (
              <IconButton
                aria-label="submit note"
                icon={<Icon as={EmrSendouts} color="white" w="20px" h="20px" />}
                width="24px"
                height="24px"
                variant="ghost"
                minWidth="unset"
                bg="blue"
                isLoading={isSaving}
                onClick={handleSubmit(onSubmit)}
                disabled={disabled}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </Box>
    </ModalFooter>
  );
}

export type { STPVipNotesModalFooterProps };
export { STPVipNotesModalFooter };
