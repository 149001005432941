import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S005Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q005.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q005`}
          subsectionLabel="Central Line"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Central Line
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q005A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q005A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Estimated blood loss"
                name={`${sectionDelimiter}Q005A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q005A004`}
                w="100px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                type="option"
                name={`${sectionDelimiter}Q005A005`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A006`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="central line placed"
                name={`${sectionDelimiter}Q005A007`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q005A008`}
                w="50px"
                type="input"
              />
              <ChartRowElement
                label="lumen"
                name={`${sectionDelimiter}Q005A009`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A010`}
                w="50px"
                type="input"
              />
              <ChartRowElement
                label="gauge"
                name={`${sectionDelimiter}Q005A011`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A012`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                name={`${sectionDelimiter}Q005A013`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="chlorhexidine"
                name={`${sectionDelimiter}Q005A014`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A015`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="anesthesia:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q005A017`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q005A018`}
                w="100px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q005A019`}
              />
              <ChartRowElement
                label="local"
                name={`${sectionDelimiter}Q005A020`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q005A021`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A022`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="position:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q005A023`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q005A024`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q005A025`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="subclavian"
                name={`${sectionDelimiter}Q005A026`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="internal jugular"
                name={`${sectionDelimiter}Q005A027`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="post"
                name={`${sectionDelimiter}Q005A028`}
                type="option"
              />
              <ChartRowElement
                label="ant"
                name={`${sectionDelimiter}Q005A029`}
                type="option"
              />
              <ChartRowElement
                label="in"
                name={`${sectionDelimiter}Q005A030`}
                type="option"
              />
              <ChartRowElement
                label="approach"
                name={`${sectionDelimiter}Q005A031`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="femoral"
                name={`${sectionDelimiter}Q005A032`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="secured"
                name={`${sectionDelimiter}Q005A033`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="good blood return"
                name={`${sectionDelimiter}Q005A034`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="position confirmed on CXR"
                name={`${sectionDelimiter}Q005A035`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q005A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowElement
              name={`${sectionDelimiter}Q005A037`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S005Procedure };
