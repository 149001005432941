import { AxiosError, AxiosResponse } from "axios";
import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UseQueryOptions } from "react-query";
import { TriageIvMedicationCountersResponse } from "./useIvAndMedicationCounters";

function useMedsAndIvCounters(
  encounterId: string,
  options: UseQueryOptions<
    AxiosResponse<TriageIvMedicationCountersResponse>,
    AxiosError
  >
) {
  return useAxiosQuery(
    ["medsAndIvCounters", { encounterId }],
    () =>
      axiosChartInstance.get<TriageIvMedicationCountersResponse>(
        `v1/triage/iv-medication/${encounterId}/meds-iv/counters`
      ),
    options
  );
}

export { useMedsAndIvCounters };
