import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const RemovePerson = createIcon({
  path: (
    <path
      d="M6.93191 7.19442C5.14356 7.19442 3.62592 5.60295 3.62592 3.5603C3.61772 1.55865 5.15176 0 6.93191 0C8.71206 0 10.2379 1.53404 10.2379 3.54389C10.2379 5.60295 8.72847 7.19442 6.93191 7.19442ZM13.8556 7.03856C13.4454 7.03856 13.1173 6.71042 13.1173 6.29204C13.1173 6.09516 13.1911 5.91468 13.3388 5.78343L15.5127 3.60131L13.3388 1.4274C13.1911 1.28794 13.1173 1.10747 13.1173 0.910582C13.1173 0.50041 13.4454 0.180476 13.8556 0.180476C14.0689 0.180476 14.233 0.254307 14.3724 0.393765L16.5546 2.56768L18.7449 0.393765C18.9007 0.2379 19.0648 0.164069 19.2617 0.164069C19.6801 0.164069 20 0.492207 20 0.902379C20 1.09926 19.9344 1.27153 19.7785 1.4192L17.6046 3.60131L19.7703 5.77523C19.918 5.91468 20 6.09516 20 6.29204C20 6.71042 19.6719 7.03856 19.2535 7.03856C19.0402 7.03856 18.8597 6.95652 18.7203 6.81706L16.5546 4.65135L14.3888 6.81706C14.2494 6.96473 14.0689 7.03856 13.8556 7.03856ZM1.46842 14.8236C0.549631 14.8236 0 14.3888 0 13.6669C0 11.5505 2.67432 8.64643 6.93191 8.64643C11.1813 8.64643 13.8638 11.5505 13.8638 13.6669C13.8638 14.3888 13.306 14.8236 12.3872 14.8236H1.46842Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 15",
});

export { RemovePerson };
