import * as React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type SegmentProps = {
  index?: number;
  defaultIndex?: number;
  onChange?: (value: number) => void;
  isFitted?: boolean;
} & Omit<BoxProps, "onChange">;

type SegmentContextProps = {
  selectedIndex: number;
  onChange: (value: number) => void;
  isFitted: boolean;
};

const SegmentContext = React.createContext<SegmentContextProps | undefined>(
  undefined
);

function useSegment() {
  const value = React.useContext(SegmentContext);

  if (!value) {
    throw new Error(
      "The Segment context must have a defined value to call this hook."
    );
  }

  return value;
}

function Segment(props: SegmentProps) {
  const {
    index,
    defaultIndex = 0,
    children,
    onChange: onChangeProp,
    isFitted = false,
    ...boxProps
  } = props;
  const [selected, setSelected] = React.useState(defaultIndex);

  const contextValue = React.useMemo<SegmentContextProps>(
    () => ({
      onChange: (x) => {
        onChangeProp?.(x);
        setSelected(x);
      },
      selectedIndex: index || selected,
      isFitted,
    }),
    [selected, onChangeProp, index, isFitted]
  );

  return (
    <Box
      display="flex"
      bg="#F2F2F7"
      borderRadius="5px"
      height="2.25rem"
      alignItems="center"
      fontSize="1rem"
      width={isFitted ? "100%" : undefined}
      {...boxProps}
    >
      <SegmentContext.Provider value={contextValue}>
        {children}
      </SegmentContext.Provider>
    </Box>
  );
}

export type { SegmentProps };
export { Segment, useSegment };
