import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonTalking = createIcon({
  path: (
    <path
      d="M17.2374 12.2511C17.564 12.4717 17.97 12.3923 18.2083 12.0569C19.338 10.5035 20 8.36757 20 6.18752C20 4.00747 19.3469 1.85389 18.2083 0.318145C17.97 -0.0172473 17.564 -0.0966824 17.2374 0.123971C16.9109 0.344624 16.8579 0.759452 17.1139 1.12132C18.0847 2.48055 18.632 4.30755 18.632 6.18752C18.632 8.06748 18.0671 9.88566 17.1139 11.2537C16.8667 11.6156 16.9109 12.0304 17.2374 12.2511ZM13.9718 9.94745C14.263 10.1504 14.6778 10.0887 14.9162 9.74445C15.5958 8.85301 16.0018 7.53792 16.0018 6.18752C16.0018 4.83712 15.5958 3.53085 14.9162 2.63059C14.6778 2.28637 14.263 2.22459 13.9718 2.42759C13.6099 2.67472 13.5569 3.11603 13.8305 3.4779C14.3425 4.18399 14.6337 5.16369 14.6337 6.18752C14.6337 7.21135 14.3336 8.18222 13.8305 8.89714C13.5658 9.26784 13.6099 9.69149 13.9718 9.94745ZM7.4316 10.2122C9.32921 10.2122 10.962 8.51762 10.962 6.31108C10.962 4.13103 9.32039 2.51585 7.4316 2.51585C5.54281 2.51585 3.90115 4.16634 3.90115 6.32874C3.90115 8.51762 5.54281 10.2122 7.4316 10.2122ZM1.88879 18.3852H12.9744C14.3601 18.3852 14.8544 17.988 14.8544 17.2113C14.8544 14.9342 12.0035 11.7921 7.4316 11.7921C2.85084 11.7921 0 14.9342 0 17.2113C0 17.988 0.494263 18.3852 1.88879 18.3852Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18.39",
});

export { PersonTalking };
