import { axiosChartInstance } from "config";
import { EncounterStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type ChartApplicationResponse = {
  status?: EncounterStatus;
  chartApplicationId: string;
  chartCode: string;
  chartTitle: string;
  chartType: string;
};

function useCreateChartApplication(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createChartApplication", { encounterId }],
    (chartCode: string) =>
      axiosChartInstance.post<ChartApplicationResponse>(
        `v1/chart-application/${encounterId}/${chartCode}`
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["activeCharts", { encounterId }]),
    }
  );
}

export type { ChartApplicationResponse };
export { useCreateChartApplication };
