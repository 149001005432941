import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { CancellationReason } from "modules/orders/enums";
import { useQueryClient } from "react-query";

type UseRequestOrderCancellationPayload = {
  reason: CancellationReason;
  other: string | null;
};

function useRequestOrderCancellation(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["requestOrderCancellation", { orderId }],
    (reason: UseRequestOrderCancellationPayload) =>
      axiosOrderInstance.put(`v1/${orderId}/request-cancellation`, reason),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "chartSidebarIndicators",
          { encounterId },
        ]);
      },
    }
  );
}

export { useRequestOrderCancellation };
