import * as React from "react";
import {
  Box,
  Spinner,
  Flex,
  AvatarProps,
  useMultiStyleConfig,
  BoxProps,
} from "@chakra-ui/react";
import { Avatar } from "shared";
import { useFileUrl } from "hooks";
import { useUserProfilePicture } from "api/queries/useUserProfilePicture";
import { useAuth0 } from "@auth0/auth0-react";

type UserAvatarProps = {
  picture?: string;
  currentUserPicture?: boolean;
  boxProps?: BoxProps;
} & AvatarProps;

function UserAvatar(props: UserAvatarProps) {
  const { user } = useAuth0();
  const { currentUserPicture = false, boxProps, ...avatarProps } = props;
  const { picture = "", w, h, width, height, size, ...rest } = avatarProps;

  // Queries
  const {
    data: file,
    isLoading: isFileLoading,
    isError,
  } = useUserProfilePicture(picture, currentUserPicture);

  const fileUrl = useFileUrl(file?.data);

  const name = user?.given_name ? user.given_name : "";
  const lastName = user?.family_name ? user.family_name : "";

  const styles = useMultiStyleConfig("Avatar", { size });
  const widthTemplate =
    w || width || (styles.container.width as string) || "140px";
  const heightTemplate =
    h || height || (styles.container.height as string) || "140px";

  return (
    <Box {...boxProps}>
      {isFileLoading ? (
        <Flex
          width={widthTemplate}
          height={heightTemplate}
          align="center"
          justify="center"
          {...rest}
        >
          <Spinner color="blue" width="40px" height="40px" />
        </Flex>
      ) : isError ? (
        <Avatar loading="eager" {...avatarProps} />
      ) : (
        <Avatar
          bg="#9e2700"
          loading="eager"
          src={fileUrl || ""}
          name={`${name} ${lastName}`}
          {...avatarProps}
        />
      )}
    </Box>
  );
}

export { UserAvatar };
