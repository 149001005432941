import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Audits = createIcon({
  path: (
    <path
      d="M1.07474 14.2732C1.25258 14.2732 1.45361 14.2191 1.65464 14.1108V6.04639C1.65464 5.51289 1.73969 5.35825 2.20361 5.09536L9.1933 1.05928C9.1701 0.386598 8.73711 0 8.16495 0C7.89433 0 7.60052 0.0773196 7.29897 0.255155L0.997423 3.88144C0.131443 4.38402 0 4.57732 0 5.6134V12.8119C0 13.6778 0.43299 14.2732 1.07474 14.2732ZM4.23711 16.0979C4.41495 16.0979 4.60825 16.0438 4.80928 15.9278V7.8634C4.80928 7.32217 4.8866 7.18299 5.35825 6.9201L12.3479 2.88402C12.3325 2.21134 11.8995 1.81701 11.3196 1.81701C11.049 1.81701 10.7552 1.90206 10.4536 2.07217L4.15206 5.69845C3.27835 6.20103 3.15464 6.40979 3.15464 7.43041L3.14691 14.6366C3.14691 15.5103 3.59536 16.0979 4.23711 16.0979ZM7.56186 18C7.87887 18 8.25773 17.884 8.67526 17.6443L14.6675 14.1959C15.5103 13.7088 15.7577 13.299 15.7577 12.2938V5.38144C15.7577 4.29897 15.3247 3.7268 14.6211 3.7268C14.3119 3.7268 13.9407 3.84278 13.5309 4.07474L7.53093 7.53866C6.66495 8.04124 6.43299 8.43557 6.43299 9.44072V16.3531C6.43299 17.4124 6.85052 18 7.56186 18Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 18",
});

export { Audits };
