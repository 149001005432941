import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuProps,
  StylesProvider,
  useDisclosure,
  useMultiStyleConfig,
  useStyles,
  VStack,
} from "@chakra-ui/react";
import { useGetUser } from "api/queries";
import { config } from "config";
import * as React from "react";
import { UserAvatar } from "shared/user-avatar";
import { AnyObject } from "types";
import { SidebarUserModal } from "./SidebarUserModal";

const detailTextStyleProps = {
  fontSize: "0.75rem",
  fontWeight: "600",
  color: "gray.700",
};

function SidebarUserView(props: Omit<MenuProps, "children">) {
  const { user, getIdTokenClaims } = useAuth0();
  const [userId, setUserId] = React.useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const generatePermissions = React.useCallback(async () => {
    try {
      const tokenIdClaims = await getIdTokenClaims();
      if (tokenIdClaims)
        setUserId(
          tokenIdClaims[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
          ] || ""
        );
    } catch (_) {}
  }, [getIdTokenClaims]);

  React.useEffect(() => {
    generatePermissions();
  }, [generatePermissions]);

  // TODO: Probably will be best to add the user title to the token claims in the backend/auth0
  const { data: userData } = useGetUser(userId, {
    enabled: !!userId,
    currentUser: true,
  });
  const userTitle = userData?.data.title || "";

  return (
    <Menu {...props}>
      <MenuButton
        as={chakra.button}
        type="button"
        outline="none"
        border="none"
        _focus={{
          boxShadow: "var(--chakra-shadows-outline)",
          transition: "all 200ms ease-in-out",
        }}
        onClick={onOpen}
        bg="rgba(255, 255, 255, 0.38)"
        padding="8px 13px"
        width="100%"
      >
        <HStack spacing={0}>
          <UserAvatar
            marginRight="0.875rem"
            size="sm"
            fontSize="1.5rem"
            picture={user?.picture && user.picture}
            currentUserPicture
          />
          <VStack spacing="0px" alignItems="start">
            <chakra.h3 fontSize="1.0625rem" fontWeight="600" textAlign="left">
              {user?.name}
            </chakra.h3>
            <Box {...detailTextStyleProps}>{userTitle}</Box>
          </VStack>
        </HStack>
      </MenuButton>
      <SidebarUserModal isOpen={isOpen} onClose={onClose} />
    </Menu>
  );
}

type HeadingSize = "sm" | "md" | "lg";

type SidebarHeadingProps = {
  size?: HeadingSize;
  as?: "h1" | "h2" | "h3";
} & Omit<BoxProps, "as">;

const HEADING_SIZES: Record<HeadingSize, AnyObject> = {
  sm: { fontSize: "1rem", fontWeight: "300" },
  md: { fontSize: "1.5rem", fontWeight: "600", lineHeight: "1.8rem" },
  lg: { fontSize: "1.875rem", fontWeight: "700", lineHeight: "2.25rem" },
};

function SidebarHeading(props: SidebarHeadingProps) {
  const { size = "lg", ...restProps } = props;

  return <Box {...HEADING_SIZES[size]} {...restProps} />;
}

function SidebarBottom(props: BoxProps) {
  const styles = useStyles();

  return <Box __css={styles.bottom} {...props} />;
}

function SidebarActions(props: BoxProps) {
  const styles = useStyles();

  return <Flex __css={styles.actions} {...props} />;
}

const DEFAULT_SIDEBAR_CONTENT_STYLES: BoxProps = {
  justifyContent: "space-between",
};

const SidebarContent = React.forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    const styles = useStyles();

    return <Box ref={ref} __css={styles.content} {...props} />;
  }
);

function SidebarHeader(props: BoxProps) {
  const styles = useStyles();

  return <Box __css={styles.header} {...props} />;
}

function SidebarNavigation(props: BoxProps) {
  const styles = useStyles();

  return <chakra.nav __css={styles.navigation} {...props} />;
}

function Sidebar(props: BoxProps) {
  const styles = useMultiStyleConfig("Sidebar", {});

  return (
    <StylesProvider value={styles}>
      <Flex __css={styles.root} {...props} />
    </StylesProvider>
  );
}

if (config.isDev) {
  SidebarContent.displayName = "SidebarContent";
}

export {
  Sidebar,
  SidebarHeader,
  SidebarActions,
  SidebarHeading,
  SidebarContent,
  SidebarBottom,
  SidebarUserView,
  SidebarNavigation,
  DEFAULT_SIDEBAR_CONTENT_STYLES,
};
