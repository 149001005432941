import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ExclamationCircle = createIcon({
  path: (
    <>
      <circle cx="9" cy="9" r="9" fill="#F8F8F8" />
      <path
        d="M9 18C13.9235 18 18 13.9147 18 9C18 4.07647 13.9147 0 8.99118 0C4.07647 0 0 4.07647 0 9C0 13.9147 4.08529 18 9 18ZM9 10.6412C8.54118 10.6412 8.28529 10.3853 8.27647 9.91765L8.16176 5.10882C8.15294 4.64118 8.49706 4.30588 8.99118 4.30588C9.47647 4.30588 9.83823 4.65 9.82941 5.11765L9.70588 9.91765C9.69706 10.3941 9.44118 10.6412 9 10.6412ZM9 13.5971C8.47059 13.5971 8.01176 13.1735 8.01176 12.6529C8.01176 12.1324 8.46176 11.7 9 11.7C9.53823 11.7 9.98824 12.1235 9.98824 12.6529C9.98824 13.1824 9.52941 13.5971 9 13.5971Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: "0 0 18 18",
});

export { ExclamationCircle };
