import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { AYVAResponse } from "./useUpdateEncounterIntegrationAYVA";
import { HL7Response } from "./useUpdateEncounterIntegrationHL7";

type IntegrationResponse = {
  lisResponse?: HL7Response;
  ayvaResponse?: AYVAResponse;
  ramsoftResponse?: HL7Response;
};

function useUpdateEncounterIntegrations(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrations", { encounterId }],
    () =>
      axiosPatientInstance.post<IntegrationResponse>(`v1/integration/`, {
        encounterId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

export type { IntegrationResponse };
export { useUpdateEncounterIntegrations };
