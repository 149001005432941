import { BaseSchema } from "yup";

function safeValidationSync(validation: BaseSchema, value: unknown) {
  try {
    validation.validateSync(value);
    return { success: true };
  } catch (err) {
    return { success: false, error: (err as Error).message };
  }
}

export { safeValidationSync };
