import { theme } from "@chakra-ui/react";

const baseAvatarTheme = theme.components.Avatar;

const baseStyleBadge = {
  transform: "translate(0%, 0%)",
  border: "unset",
  borderRadius: "unset",
  borderColor: "unset",
};

const baseStyleContainer = {
  bg: "gray.700",
};

const baseStyle = {
  badge: baseStyleBadge,
  container: baseStyleContainer,
};

function getSize(size: string, font: string) {
  return {
    container: {
      width: `${size}px`,
      height: `${size}px`,
    },
    label: {
      fontSize: `calc(${font}rem / 2.5)`,
    },
  };
}

const sizes = {
  xs: getSize("32", "2"),
  sm: getSize("63", "4"),
  md: getSize("100", "7"),
  lg: getSize("140", "10"),
};

const defaultProps = {
  size: "sm",
};

export const avatarTheme = {
  ...baseAvatarTheme,
  sizes: {
    xs: baseAvatarTheme.sizes.xs,
    sm: baseAvatarTheme.sizes.sm,
    md: baseAvatarTheme.sizes.md,
    lg: baseAvatarTheme.sizes.lg,
  },
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
