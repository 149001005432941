import * as React from "react";
import {
  Textarea as CkTextarea,
  TextareaProps as ChakraTexareaProps,
} from "@chakra-ui/react";

const ckTextareaOmittedProps = ["colorScheme"] as const;
type CkTextareaProps = Omit<
  ChakraTexareaProps,
  typeof ckTextareaOmittedProps[number]
>;

type TextareaProps = CkTextareaProps;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    return <CkTextarea ref={ref} {...props} />;
  }
);

Textarea.displayName = "Textarea";

export type { TextareaProps };
export { Textarea };
