import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { InsuranceType } from "modules/reception/types";

function useInsuranceEligibilityReportFile(
  encounterId: string,
  insuranceType: InsuranceType
) {
  return useAxiosQuery(
    ["insuranceEligibilityReportFile", { encounterId, insuranceType }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/integration/eligibility/report`, {
        responseType: "blob",
        params: { encounterId, insuranceType },
      })
  );
}

export { useInsuranceEligibilityReportFile };
