import { axiosSLPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseMergeStpVipPatientsProps = {
  master: string;
  patients: string[];
};

function useMergeStpVipPatients() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["mergeStpVipPatients"],
    (payload: UseMergeStpVipPatientsProps) =>
      axiosSLPatientInstance.post("v1/patient/merge", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("stpVipPatients");
        queryClient.invalidateQueries("stpVipCounters");
      },
    }
  );
}

export type { UseMergeStpVipPatientsProps };
export { useMergeStpVipPatients };
