import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Clock = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM4.87255 11.0588C4.48039 11.0588 4.18627 10.7647 4.18627 10.3725C4.18627 9.9902 4.48039 9.69608 4.87255 9.69608H9.31373V3.76471C9.31373 3.38235 9.60784 3.08824 9.9902 3.08824C10.3725 3.08824 10.6765 3.38235 10.6765 3.76471V10.3725C10.6765 10.7647 10.3725 11.0588 9.9902 11.0588H4.87255Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Clock };
