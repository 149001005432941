import { Box, HStack, StackProps, VStack } from "@chakra-ui/react";
import * as React from "react";
import { EncounterAvatar } from "shared";
import { maskString } from "utils/formatter";

type PatientInfoProps = {
  pictureUrl?: string | null;
  name: string;
  age: string;
  gender: string;
  mrn: string;
  ssn: string;
  encounterId: string;
} & StackProps;

function PatientInfo(props: PatientInfoProps) {
  const { age, gender, mrn, name, ssn, pictureUrl, encounterId, ...restProps } =
    props;

  return (
    <HStack spacing="15px" {...restProps}>
      <EncounterAvatar encounterId={encounterId} size="xs" />
      <VStack spacing="8px" alignItems="flex-start">
        <HStack lineHeight="1.25rem">
          <Box
            fontWeight="600"
            fontSize="1.0625rem"
            width="124px"
            maxWidth="124px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            title={name}
          >
            {name}
          </Box>
          <Box fontWeight="600" color="gray.600">
            {`${age} ${(gender && gender[0]) || ""}`}
          </Box>
        </HStack>
        <HStack spacing="23px" lineHeight="1rem">
          <Box fontWeight="700" color="gray.600" fontSize="0.8125rem">
            {`MRN# ${mrn}`}
          </Box>
          <Box fontWeight="700" color="gray.600" fontSize="0.8125rem">
            {`SSN *${maskString(ssn, "###-##-####")}`}
          </Box>
        </HStack>
      </VStack>
    </HStack>
  );
}

export type { PatientInfoProps };
export { PatientInfo };
