import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S011Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q011.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q011`}
          subsectionLabel="Incision & Drainage of Abscesss"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Incision & Drainage of Abscesss
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q011A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q011A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                name={`${sectionDelimiter}Q011A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="local:"
                name={`${sectionDelimiter}Q011A004`}
                type="option"
              />
              <ChartRowElement
                label="lidocaine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A005`}
                type="option"
              />
              <ChartRowElement
                label="1%"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A006`}
                type="option"
              />
              <ChartRowElement
                label="2%"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A007`}
                type="option"
              />
              <ChartRowElement
                label="epi"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A008`}
                type="option"
              />
              <ChartRowElement
                label="bicarb"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A009`}
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A010`}
                type="option"
              />
              <ChartRowElement
                label="0.25%"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A011`}
                type="option"
              />
              <ChartRowElement
                label="0.5%"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A012`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A013`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="procedural sedation"
                name={`${sectionDelimiter}Q011A014`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A015`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q011A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep / Procedure:"
                name={`${sectionDelimiter}Q011A017`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="skin prep:"
                name={`${sectionDelimiter}Q011A018`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="inscised w/"
                name={`${sectionDelimiter}Q011A020`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A021`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="blade"
                name={`${sectionDelimiter}Q011A022`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A023`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="purulent drainage:"
                name={`${sectionDelimiter}Q011A024`}
                type="option"
              />
              <ChartRowElement
                label="large"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A025`}
                type="option"
              />
              <ChartRowElement
                label="small"
                fontStyle="italic"
                name={`${sectionDelimiter}Q011A026`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A027`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="probed to break up loculations"
                name={`${sectionDelimiter}Q011A028`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A029`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="obtained"
                name={`${sectionDelimiter}Q011A030`}
                type="option"
              />
              <ChartRowElement
                label="cultures"
                name={`${sectionDelimiter}Q011A031`}
                type="option"
              />
              <ChartRowElement
                label="gram stain"
                name={`${sectionDelimiter}Q011A032`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A033`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="estimated blodd loss"
                name={`${sectionDelimiter}Q011A034`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A035`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="mL"
                name={`${sectionDelimiter}Q011A036`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q011A037`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>

          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q011A038`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S011Procedure };
