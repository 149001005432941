import { useAuth0 } from "@auth0/auth0-react";
import { Box, chakra, Icon, Switch, VStack } from "@chakra-ui/react";
import { EmrPlus } from "@medstonetech/slate-icons";
import { format } from "date-fns";
import { useToast } from "hooks";
import { formMessages } from "messages";
import { Control, useFieldArray, UseFormWatch } from "react-hook-form";
import { Button, DividerList, Select, StaffAvatar, Textarea } from "shared";
import { USERID_CLAIMS } from "system-constants";
import { extractApiErrorMessage } from "utils";
import { getOptions, valueToOption } from "utils/enum-mapper";
import { TicketRequestDto, useUpdateTickets } from "../api";
import { RoomDetailsForm, TicketForm } from "./RoomManagementDetails";

type TicketItemProps = {
  index: number;
  ticket: TicketForm;
  control: Control<RoomDetailsForm>;
  name: "newTickets" | "updatedTickets";
};

const TicketItem = ({ index, ticket, control, name }: TicketItemProps) => {
  const { update, remove } = useFieldArray({ control, name });
  const { append } = useFieldArray({ control, name: "deletedTickets" });

  const handleRemove = () => {
    append(ticket);
    remove(index);
  };

  return (
    <Box w="510px" h="440px">
      <Box w="100%" h="380px" bgColor="white" borderRadius="10px" p={4}>
        <DividerList dividerLeftMargin="4px">
          <Box display="flex" justifyContent="space-between" py={3} px={2}>
            <chakra.span fontSize="17px" fontWeight="400" color="gray.700">
              Ticket #
            </chakra.span>
            <chakra.span fontSize="17px" fontWeight="500">
              {ticket.number}
            </chakra.span>
          </Box>
          <Box display="flex" justifyContent="space-between" py={3} px={2}>
            <chakra.span fontSize="17px" fontWeight="400" color="gray.700">
              Time Reported
            </chakra.span>
            <chakra.span fontSize="17px" fontWeight="500">
              {ticket.reportedAt &&
                format(new Date(ticket.reportedAt), "MM/dd/yyyy hh:mm aa")}
            </chakra.span>
          </Box>
          <Box display="flex" justifyContent="space-between" py={3} px={2}>
            <chakra.span fontSize="17px" fontWeight="400" color="gray.700">
              Reported By
            </chakra.span>
            <Box display="flex" justifyContent="space-between" gap={2}>
              <StaffAvatar
                w="32px"
                h="32px"
                size="xs"
                fontSize=".75rem"
                userName={ticket.user?.fullName}
                profileUrl={ticket.user?.pictureUrl}
              />
              <VStack spacing="0" alignItems="flex-start" minW="80px">
                <Box
                  fontSize="1.0625rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="black"
                >
                  {ticket.user?.fullName}
                </Box>
                <Box
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1rem"
                  color="gray.700"
                >
                  {ticket.user?.teams}
                </Box>
              </VStack>
            </Box>
          </Box>
          <VStack w="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              py={3}
              px={2}
              w="100%"
            >
              <chakra.span fontSize="17px" fontWeight="400" color="gray.700">
                Description
              </chakra.span>
              <Select
                initialSelectedItem={
                  ticket.type
                    ? valueToOption("roomTicketType", ticket.type)
                    : undefined
                }
                labelAccessor="label"
                valueAccessor="value"
                placeholder="Select"
                items={getOptions("roomTicketType")}
                showBlank={false}
                iconProps={{
                  fontSize: "1.5rem",
                  sx: { color: "gray.450 !important" },
                  marginLeft: 2,
                }}
                btnProps={{
                  variant: "label",
                  maxW: "300px",
                  width: "200px",
                  textAlign: "end",
                  justifyContent: "end",
                  fontSize: "17px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeHolderProps={{
                  color: "blue",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
                onSelectedItemChange={(item) => {
                  if (item.selectedItem) {
                    update(index, { type: item.selectedItem.value });
                  }
                }}
              />
            </Box>
            <Box
              maxH="70px"
              height="70px"
              w="100%"
              overflow="hidden"
              borderRadius="10px"
              mt={3}
            >
              <Textarea
                value={ticket.description}
                bg="gray.250"
                variant="filled"
                border="none"
                placeholder="Type details here"
                _placeholder={{ opacity: 1, color: "gray.550" }}
                maxH="70px"
                height="70px"
                resize="none"
                onChange={(e) => {
                  update(index, { description: e.target.value });
                }}
                overflow="auto"
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              py={3}
              px={2}
              w="100%"
              h="60px"
              alignItems="center"
            >
              <chakra.span fontSize="17px" fontWeight="400" color="gray.700">
                Completed
              </chakra.span>
              <chakra.span fontSize="17px" fontWeight="500">
                {ticket.status === "Complete" ? "Yes" : "No"}
                <Switch
                  colorScheme="green"
                  size="lg"
                  isChecked={ticket.status === "Complete"}
                  onChange={() => {
                    if (ticket.status === "Complete") {
                      update(index, { status: "Incomplete" });
                    } else {
                      update(index, { status: "Complete" });
                    }
                  }}
                  ml={2}
                />
              </chakra.span>
            </Box>
          </VStack>
        </DividerList>
      </Box>
      <Button
        variant="label"
        color="red"
        p={4}
        fontSize="17px"
        fontWeight="500"
        onClick={handleRemove}
      >
        Remove
      </Button>
    </Box>
  );
};

type TicketListProps = {
  roomId: string;
  control: Control<RoomDetailsForm>;
  watch: UseFormWatch<RoomDetailsForm>;
};

const TicketList = ({ roomId, watch, control }: TicketListProps) => {
  const toast = useToast();
  const { user } = useAuth0();
  const userId = user ? user[USERID_CLAIMS] : "";

  const updatedTickets = watch("updatedTickets");

  const { mutateAsync: updateTickets, isLoading: isUpdateTicketsLoading } =
    useUpdateTickets(roomId);

  const handleAddTicket = async () => {
    try {
      const ticket: TicketRequestDto = {
        roomId,
        userId,
        reportedAt: new Date(),
        description: "",
        status: "Incomplete",
      };

      await updateTickets({
        newTickets: [ticket],
        updatedTickets: [],
      });

      toast({
        status: "success",
        description: formMessages.createSuccess("Ticket"),
      });
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Box display="flex" justifyContent="start" flexDirection="column">
      <Box w="100%" display="flex" justifyContent="center">
        <Button
          variant="outlineSquared"
          key="doneBtn"
          fontSize="16px"
          fontWeight="400"
          onClick={handleAddTicket}
          isLoading={isUpdateTicketsLoading}
          isDisabled={isUpdateTicketsLoading}
        >
          <Icon
            as={EmrPlus}
            fontSize="1rem"
            color="blue"
            mr={3}
            borderRadius="10px"
          />
          Add New Ticket
        </Button>
      </Box>

      <VStack p={1} mt={4}>
        {updatedTickets.map((ticket, index) => (
          <TicketItem
            key={index}
            ticket={ticket}
            index={index}
            control={control}
            name="updatedTickets"
          />
        ))}
      </VStack>
    </Box>
  );
};

export { TicketList };
