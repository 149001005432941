import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S002Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q002.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q002`}
          subsectionLabel="Apgar Score (Five Minute)"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Apgar Score (Five Minute)
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="HR="
              fontStyle="italic"
              textDecorationLine="underline"
              type="option"
              name={`${sectionDelimiter}Q002A030`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q002A001`}
              w="695px"
              type="input"
            />
            <ChartRowElement
              label="RR="
              fontStyle="italic"
              textDecorationLine="underline"
              type="option"
              name={`${sectionDelimiter}Q002A031`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q002A002`}
              type="input"
            />
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="HR"
                name={`${sectionDelimiter}Q002A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="absent (0)"
                name={`${sectionDelimiter}Q002A004`}
                type="option"
              />
              <ChartRowElement
                label="less than 100 (1)"
                name={`${sectionDelimiter}Q002A005`}
                type="option"
              />
              <ChartRowElement
                label="100 or greater (2)"
                name={`${sectionDelimiter}Q002A006`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q002A007`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="RR"
                name={`${sectionDelimiter}Q002A008`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="absent (0)"
                name={`${sectionDelimiter}Q002A009`}
                type="option"
              />
              <ChartRowElement
                label="weak cry (1)"
                name={`${sectionDelimiter}Q002A010`}
                type="option"
              />
              <ChartRowElement
                label="strong cry (2)"
                name={`${sectionDelimiter}Q002A011`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q002A012`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Color"
                name={`${sectionDelimiter}Q002A013`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="blue or pale (0)"
                name={`${sectionDelimiter}Q002A014`}
                type="option"
              />
              <ChartRowElement
                label="pink blue extrem. (1)"
                name={`${sectionDelimiter}Q002A015`}
                type="option"
              />
              <ChartRowElement
                label="pink (2)"
                name={`${sectionDelimiter}Q002A016`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q002A017`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Muscle tone"
                name={`${sectionDelimiter}Q002A018`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="limp (0)"
                name={`${sectionDelimiter}Q002A019`}
                type="option"
              />
              <ChartRowElement
                label="some flexion (1)"
                name={`${sectionDelimiter}Q002A020`}
                type="option"
              />
              <ChartRowElement
                label="active motion (2)"
                name={`${sectionDelimiter}Q002A021`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q002A022`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Reflex Irrit"
                name={`${sectionDelimiter}Q002A023`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="no response (0)"
                name={`${sectionDelimiter}Q002A024`}
                type="option"
              />
              <ChartRowElement
                label="grimace (1)"
                name={`${sectionDelimiter}Q002A025`}
                type="option"
              />
              <ChartRowElement
                label="cough or sneeze (2)"
                name={`${sectionDelimiter}Q002A026`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q002A027`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Score="
              type="option"
              name={`${sectionDelimiter}Q002A032`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q002A028`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q002A029`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S002Procedure };
