import * as React from "react";
import { Box, Flex, useCheckbox } from "@chakra-ui/react";
import { CheckboxProps } from "./Checkbox";

type CheckboxButtonProps = { activeColor?: string } & CheckboxProps;

const CheckboxButton = React.forwardRef<HTMLInputElement, CheckboxButtonProps>(
  (props, ref) => {
    const { children, activeColor = "blue", ...rest } = props;
    const { getCheckboxProps, getInputProps } = useCheckbox(rest);

    const input = getInputProps();
    const checkboxProps = getCheckboxProps();

    const { isChecked, isReadOnly, ...styleProps } = rest;

    return (
      <Box as="label">
        <input ref={ref} {...input} />
        <Flex
          {...checkboxProps}
          color="gray.450"
          bg="white"
          alignItems="center"
          justifyContent="center"
          transition="all 200ms ease-in-out"
          h="36px"
          fontWeight="600"
          borderWidth="1px"
          borderRadius="5px"
          borderColor="gray.450"
          textAlign="center"
          cursor="pointer"
          _checked={{
            color: activeColor,
            borderColor: activeColor,
          }}
          _disabled={{ opacity: 0.6, cursor: "not-allowed" }}
          {...styleProps}
        >
          {children}
        </Flex>
      </Box>
    );
  }
);

export type { CheckboxButtonProps };
export { CheckboxButton };
