import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteOrderFile(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteOrderFile", { orderId }],
    (pageNumber: number) =>
      axiosOrderInstance.delete(`v1/file/${orderId}/remove/${pageNumber}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrderFile", { orderId }]);
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
      },
    }
  );
}

export { useDeleteOrderFile };
