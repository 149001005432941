import { Box, IconButton, Spinner } from "@chakra-ui/react";
import { WarningCircleFilled } from "icons";
import { genericErrors } from "messages";
import * as React from "react";
import { extractApiErrorMessage } from "utils";

type AutoSaveControlProps<Err extends Error> = React.PropsWithChildren<{
  isLoading: boolean;
  retry: () => void;
  error?: Err | null;
}>;

function AutoSaveControl<Err extends Error>(props: AutoSaveControlProps<Err>) {
  const { isLoading, retry, error, children } = props;

  return (
    <Box display="flex" alignItems="center" position="relative">
      {children}
      {isLoading && (
        <Spinner
          position="absolute"
          size="sm"
          right="-0.5rem"
          top="-0.5rem"
          color="blue"
        />
      )}
      {error && (
        <IconButton
          aria-label="retry"
          size="sm"
          variant="icon"
          onClick={retry}
          position="absolute"
          top="-10px"
          right="-10px"
          icon={<WarningCircleFilled color="red" />}
          title={
            extractApiErrorMessage(error) || genericErrors.fieldSavingError
          }
        />
      )}
    </Box>
  );
}

export type { AutoSaveControlProps };
export { AutoSaveControl };
