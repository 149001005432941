import { Box } from "@chakra-ui/react";
import { InsuranceType } from "enums";
import { INSURANCE_TYPE_COLOR } from "modules/in-process/constants";
import * as React from "react";

type InsuranceAvatarContainerProps = {
  avatar: React.ReactElement;
  icon?: React.ReactElement;
  entitledToObservation: boolean;
  insuranceType?: InsuranceType | null;
};

function InsuranceAvatarContainer(props: InsuranceAvatarContainerProps) {
  const { icon, avatar, insuranceType } = props;

  return (
    <Box position="relative">
      {React.cloneElement(avatar, {
        borderWidth: "2px",
        borderColor: insuranceType
          ? INSURANCE_TYPE_COLOR[insuranceType]
          : "gray.200",
      })}
      {icon && (
        <Box
          position="absolute"
          bgColor="red"
          borderRadius="5px"
          width="1.25rem"
          height="1.25rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bottom={0}
          right={0}
        >
          {React.cloneElement(icon, {
            fontSize: "0.75rem",
            color: "white",
          })}
        </Box>
      )}
    </Box>
  );
}

export type { InsuranceAvatarContainerProps };
export { InsuranceAvatarContainer };
