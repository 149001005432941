import {
  chakra,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Flex,
} from "@chakra-ui/react";
import * as React from "react";
import { DividerList, Loading, ToolbarHeader, WarningDialog } from "shared";
import { useCancelOrder, useEncounterOrder } from "modules/orders/api";
import {
  OrderDetailsEntry,
  OrderDetailsEntryDateBox,
  OrderDetailsEntryTextBox,
} from "../OrderDetailsEntry";
import { OrderStatusPill } from "../OrderStatusPill";
import { enumMapper } from "utils";
import { getOrderDetailsDateShortFormat } from "modules/orders/helpers";
import { MedicalStaffInfo } from "../MedicalStaffInfo";
import { formMessages, genericErrors } from "messages";
import { usePermissions } from "contexts";

type CancellationDetailModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  orderId: string;
  encounterId: string;
};

type ButtonCancelOrderModalProps = {
  orderId: string;
  encounterId: string;
};

function ButtonCancellationDetailModal(props: ButtonCancelOrderModalProps) {
  const { orderId, encounterId } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button variant="link" color="blue" minW="50px" p="0" onClick={onOpen}>
        See Reason
      </Button>
      <CancellationDetailModal
        isModalOpen={isOpen}
        onModalClose={onClose}
        orderId={orderId}
        encounterId={encounterId}
      />
    </>
  );
}

function CancellationDetailModalContent(props: CancellationDetailModalProps) {
  const { orderId, encounterId, onModalClose } = props;

  const toast = useToast();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isLoading: isLoadingOrder } = useEncounterOrder(orderId);

  const { scope } = usePermissions();

  const { mutateAsync: cancelOrder, isLoading } = useCancelOrder(
    orderId,
    encounterId
  );

  const handleCancelOrder = async () => {
    try {
      onClose();
      await cancelOrder({});
      toast({
        status: "success",
        description: formMessages.cancelSuccess("Order"),
      });
      onClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  const canCancelOrder = scope("provider:odh").isEditable;

  const isCancellationRequested =
    data?.data.status === "CancellationRequested" &&
    !!data?.data.cancellationRequestedBy;

  const isCancelled =
    data?.data.status === "Cancelled" && !!data?.data.cancelledBy;

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <Flex alignItems="center" wordBreak="break-word">
              <chakra.span fontSize="1.0625rem" fontWeight="600">
                Reason Cancelled
              </chakra.span>
            </Flex>
          }
          rightButtons={[
            <Button
              key="cancelBtn"
              fontSize="16px"
              fontWeight="500"
              onClick={onModalClose}
            >
              Close
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="0 15px 19px">
        {isLoadingOrder || !data ? (
          <Loading padding="16px" height="180px" />
        ) : (
          <>
            <Box
              background="white"
              borderRadius="10px"
              p="0 25px"
              border="1px solid"
              borderColor="gray.450"
              mb="15px"
            >
              <DividerList dividerLeftMargin="0px">
                <OrderDetailsEntry
                  label="EMR Order ID"
                  value={
                    <OrderDetailsEntryTextBox>
                      {data.data.orderId}
                    </OrderDetailsEntryTextBox>
                  }
                />
                <OrderDetailsEntry
                  label="Order Status"
                  value={<OrderStatusPill status={data.data.status} />}
                />
                {data.data.elementType === "Radiology" ? (
                  <OrderDetailsEntry
                    label="Study Type ID/CPT Code"
                    value={
                      data.data.studyTypeId && data.data.cptCode ? (
                        <OrderDetailsEntryTextBox>
                          {data.data.studyTypeId} / {data.data.cptCode}
                        </OrderDetailsEntryTextBox>
                      ) : (
                        ""
                      )
                    }
                  />
                ) : (
                  <OrderDetailsEntry
                    label="Type/Study Type ID"
                    value={
                      <OrderDetailsEntryTextBox>
                        {data.data.elementCategory &&
                          enumMapper.toDisplay(
                            "orderFormElementCategory",
                            data.data.elementCategory
                          )}{" "}
                        / {data.data.type}
                      </OrderDetailsEntryTextBox>
                    }
                  />
                )}
                <OrderDetailsEntry
                  label="Description"
                  value={
                    <OrderDetailsEntryTextBox textAlign="end">
                      {data.data.description}
                    </OrderDetailsEntryTextBox>
                  }
                />
                <OrderDetailsEntry
                  label="Time Ordered"
                  value={
                    <OrderDetailsEntryDateBox>
                      {data.data.orderedAt &&
                        getOrderDetailsDateShortFormat(
                          new Date(data.data.orderedAt)
                        )}
                    </OrderDetailsEntryDateBox>
                  }
                />
                {data.data.orderedBy && (
                  <OrderDetailsEntry
                    label="Ordered By"
                    value={
                      <MedicalStaffInfo
                        name={data.data.orderedBy.fullName}
                        role={data.data.orderedBy.teams}
                        pictureUrl={data.data.orderedBy.pictureUrl}
                      />
                    }
                  />
                )}
                {data.data.notes && (
                  <OrderDetailsEntry
                    label="Order Notes"
                    value={
                      <OrderDetailsEntryTextBox textAlign="end">
                        {data.data.notes}
                      </OrderDetailsEntryTextBox>
                    }
                  />
                )}
              </DividerList>
            </Box>
            <Box
              background="white"
              borderRadius="10px"
              p="0 25px"
              border="1px solid"
              borderColor="gray.450"
              mb="15px"
            >
              <DividerList dividerLeftMargin="0px">
                <OrderDetailsEntry
                  label="Requested Cancel"
                  value={
                    <MedicalStaffInfo
                      name={
                        data.data.cancellationRequestedBy?.fullName || "N/A"
                      }
                      role={data.data.cancellationRequestedBy?.teams || ""}
                      pictureUrl={
                        data.data.cancellationRequestedBy?.pictureUrl || ""
                      }
                    />
                  }
                />
                <OrderDetailsEntry
                  label="Time Requested"
                  value={
                    <OrderDetailsEntryDateBox>
                      {data.data.cancellationRequestedAt &&
                        getOrderDetailsDateShortFormat(
                          new Date(data.data.cancellationRequestedAt)
                        )}
                    </OrderDetailsEntryDateBox>
                  }
                />
              </DividerList>
            </Box>

            <chakra.span color="gray.700" fontSize="15px" fontWeight="500">
              Reason:
            </chakra.span>

            <Box
              background="white"
              borderRadius="10px"
              p="15px 25px"
              border="1px solid"
              borderColor="gray.450"
              m="15px 0"
            >
              <chakra.span color="gray.700" fontSize="15px" fontWeight="500">
                {data.data.cancellation?.other ||
                  data.data.cancellation?.reason}
              </chakra.span>
            </Box>

            {canCancelOrder && isCancellationRequested && (
              <Button
                onClick={onOpen}
                isLoading={isLoading}
                variant="solid"
                w="100%"
                mb="25px"
              >
                Acknowledge
              </Button>
            )}

            {isCancelled && (
              <Box
                background="white"
                borderRadius="10px"
                p="0 25px"
                border="1px solid"
                borderColor="gray.450"
                mb="15px"
              >
                <DividerList dividerLeftMargin="0px">
                  <OrderDetailsEntry
                    label="Approved By"
                    value={
                      <MedicalStaffInfo
                        name={data.data.cancelledBy?.fullName || "N/A"}
                        role={data.data.cancelledBy?.teams || ""}
                        pictureUrl={data.data.cancelledBy?.pictureUrl || ""}
                      />
                    }
                  />
                  <OrderDetailsEntry
                    label="Time Approved"
                    value={
                      <OrderDetailsEntryDateBox>
                        {data.data.cancellationTime &&
                          getOrderDetailsDateShortFormat(
                            new Date(data.data.cancellationTime)
                          )}
                      </OrderDetailsEntryDateBox>
                    }
                  />
                </DividerList>
              </Box>
            )}
          </>
        )}

        <WarningDialog
          isOpen={isOpen}
          onCancel={onClose}
          title="Warning!"
          mainText="Are you sure you want to cancel this order? This action cannot be undone."
          onClose={onClose}
          onAction={handleCancelOrder}
          cancelLabel="Go Back"
          actionLabel="Cancel Order"
          actionsDirection="vertical"
          blockScrollOnMount={false}
        />
      </ModalBody>
    </>
  );
}

function CancellationDetailModal(props: CancellationDetailModalProps) {
  const { isModalOpen, onModalClose, orderId, encounterId } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <CancellationDetailModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            orderId={orderId}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { CancellationDetailModal, ButtonCancellationDetailModal };
