import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const AuditCommentCheck = createIcon({
  path: (
    <path
      d="M7.20337 24C6.49649 24 6.08135 23.5063 6.08135 22.7321V19.5119H5.23983C2.0533 19.5119 0 17.5259 0 14.1599V5.37447C0 2.00842 1.97475 0 5.36325 0H20.0729C23.4727 0 25.4474 2.00842 25.4474 5.37447V14.1599C25.4474 17.5147 23.4727 19.5119 20.0729 19.5119H12.8022L8.65077 23.2482C8.06732 23.7756 7.69705 24 7.20337 24ZM11.4783 15.338C11.9383 15.338 12.3198 15.1136 12.5891 14.7097L17.8289 6.61992C18.0084 6.35063 18.1318 6.05891 18.1318 5.78962C18.1318 5.16129 17.5708 4.7237 16.9649 4.7237C16.5722 4.7237 16.2356 4.93689 15.9776 5.36325L11.4446 12.5778L9.36886 9.98598C9.08836 9.61571 8.79663 9.48107 8.42637 9.48107C7.79804 9.48107 7.29313 9.98598 7.29313 10.6031C7.29313 10.906 7.40533 11.1865 7.62973 11.4558L10.3226 14.7209C10.6592 15.136 11.0295 15.338 11.4783 15.338Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 24",
});

export { AuditCommentCheck };
