import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { InsuranceCardType } from "modules/reception/types";
import { useQueryClient } from "react-query";

type UseDeleteEncounterInsuranceBenefitFileProps = {
  encounterId: string;
  type: InsuranceCardType;
};

function useDeleteEncounterInsuranceBenefitFile(
  params: UseDeleteEncounterInsuranceBenefitFileProps
) {
  const { encounterId, type: insuranceType } = params;
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterInsuranceBenefitFile", { encounterId, insuranceType }],
    () =>
      axiosPatientInstance.delete<string>(
        `v1/encounter/${encounterId}/form/files/insurance/${insuranceType}/benefit`
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "encounterInsuranceBenefitsFile",
          { encounterId, insuranceType },
        ]),
    }
  );
}

export { useDeleteEncounterInsuranceBenefitFile };
