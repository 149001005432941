import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T039FetalHeartsChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="date" name={`${sectionDelimiter}A001`} />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="date" name={`${sectionDelimiter}A002`} />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T040FetalHeartsChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="date" name={`${sectionDelimiter}A001`} />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="date" name={`${sectionDelimiter}A002`} />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function FetalHeartsChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C039x: <T039FetalHeartsChart sectionDelimiter={sectionDelimiter} />,
    C040x: <T040FetalHeartsChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { FetalHeartsChart };
