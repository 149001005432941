import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { VitalsColumn, VitalsResponse } from "modules";
import { useQueryClient } from "react-query";

function useCreateVitalCheck(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createVitalCheck"],
    (payload: VitalsColumn) => {
      return axiosChartInstance.post<VitalsResponse>(
        `v1/Vitals/${encounterId}`,
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitals", { encounterId }]);
        queryClient.invalidateQueries(["vitalsTimer", { encounterId }]);
        queryClient.invalidateQueries(["chartPatientCard", { encounterId }]);
      },
    }
  );
}

export { useCreateVitalCheck };
