import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import {
  InsuranceCardPosition,
  InsuranceCardType,
} from "modules/reception/types";

type UseDeleteInsuranceCardParams = {
  encounterId: string;
  position: InsuranceCardPosition;
  type: InsuranceCardType;
};

function useDeleteInsuranceCard(params: UseDeleteInsuranceCardParams) {
  const { encounterId, position, type } = params;
  return useAxiosMutation(["deleteInsuranceCard", { encounterId }], () =>
    axiosPatientInstance.delete(
      `v1/encounter/${encounterId}/form/files/insurance-card/${type}/${position}`,
      {
        params: { position },
      }
    )
  );
}

export { useDeleteInsuranceCard };
