import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S017Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q017.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
          subsectionLabel="Recheck Post-Reduction"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Recheck Post-Reduction
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q017A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q017A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Exam:"
                name={`${sectionDelimiter}Q017A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro"
                name={`${sectionDelimiter}Q017A004`}
                type="option"
              />
              <ChartRowElement
                label="vasc"
                name={`${sectionDelimiter}Q017A005`}
                type="option"
              />
              <ChartRowElement
                label="intact"
                name={`${sectionDelimiter}Q017A006`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q017A007`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Post-Reduction Xray:"
                name={`${sectionDelimiter}Q017A008`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="good alignment"
                fontStyle="italic"
                name={`${sectionDelimiter}Q017A009`}
                type="option"
              />
              <ChartRowElement
                label="Hill-Sachs deformity"
                fontStyle="italic"
                name={`${sectionDelimiter}Q017A010`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q017A011`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Post-Reduction Xray:"
                name={`${sectionDelimiter}Q017A012`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="good alignment"
                fontStyle="italic"
                name={`${sectionDelimiter}Q017A012`}
                type="option"
              />
              <ChartRowElement
                label="Hill-Sachs deformity"
                fontStyle="italic"
                name={`${sectionDelimiter}Q017A013`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q017A014`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="sling"
                name={`${sectionDelimiter}Q017A015`}
                type="option"
              />
              <ChartRowElement
                label="shoulder immobilizer"
                name={`${sectionDelimiter}Q017A016`}
                type="option"
              />
              <ChartRowElement
                label="clavicle strap"
                name={`${sectionDelimiter}Q017A017`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q017A018`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q017A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S017Procedure };
