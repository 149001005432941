import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C022x";

function T22HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });

      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );

      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="thermal burn"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical burn"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="electrical burn"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="smoke inhalation"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="home"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="school"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neighbor's"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="park"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="work"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="street"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="exact location:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  w="627.5px"
                  type="input"
                />
                <ChartRowElement
                  label="exact activity:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="flame"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hot liquid"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="steam"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="radiator"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical burn"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="electrical injury"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="smoke inhalation:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                />
                <ChartRowElement
                  label="brief"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="definite"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="sec"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="min"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hrs"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="closed space entrapment"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="sec"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="min"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hrs"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="mild"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="6"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="7"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="8"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="9"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="10"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="headache"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dizziness"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="cough"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="lost consciousness:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="no"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="seconds"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="minutes"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="remembers:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                />
                <ChartRowElement
                  label="event"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="coming to hospital"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T22HPI };
