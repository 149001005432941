import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type ChartSummaryParams = {
  encounterId?: string;
  chartType: "Triage" | "Chart" | "Observation";
};

type ChartSummaryResponse = {
  charts: {
    [key: string]: string[];
  };
};

function useChartSummary(params: ChartSummaryParams) {
  const { encounterId, chartType } = params;
  return useAxiosQuery(["chartSummary", { encounterId, chartType }], () =>
    axiosChartInstance.get<ChartSummaryResponse>(
      `v1/chart-application/${encounterId}/${chartType}/summary`
    )
  );
}
export type { ChartSummaryParams, ChartSummaryResponse };
export { useChartSummary };
