import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { DuplicatePatientsCounters } from "modules/duplicate-patients/types";

function useDuplicatePatientsCounters() {
  return useAxiosQuery(["duplicatePatientsCounters"], () =>
    axiosPatientInstance.get<DuplicatePatientsCounters>(
      `v1/duplicate-patients/counters`,
      {}
    )
  );
}

export { useDuplicatePatientsCounters };
