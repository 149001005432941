import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const TreatmentList = createIcon({
  path: (
    <g fill="none">
      <path
        d="M1.78092 12.103C0.636042 12.103 0 11.4669 0 10.3039V5.88794C0 2.01716 2.03534 0 5.96063 0H10.3766C11.5396 0 12.1757 0.636042 12.1757 1.78092C12.1757 2.9258 11.5396 3.56184 10.3766 3.56184H6.1787C4.48864 3.56184 3.56184 4.43412 3.56184 6.19687V10.3039C3.56184 11.4669 2.94397 12.103 1.78092 12.103ZM34.2191 12.103C33.0742 12.103 32.4382 11.4669 32.4382 10.3039V6.19687C32.4382 4.43412 31.4932 3.56184 29.8213 3.56184H25.6234C24.4604 3.56184 23.8243 2.9258 23.8243 1.78092C23.8243 0.636042 24.4604 0 25.6234 0H30.0394C33.9647 0 36 2.01716 36 5.88794V10.3039C36 11.4669 35.3821 12.103 34.2191 12.103ZM5.96063 36C2.03534 36 0 33.9828 0 30.0939V25.6779C0 24.5149 0.61787 23.8788 1.78092 23.8788C2.9258 23.8788 3.56184 24.5149 3.56184 25.6779V29.785C3.56184 31.5659 4.48864 32.4382 6.1787 32.4382H10.3766C11.5396 32.4382 12.1757 33.0742 12.1757 34.2191C12.1757 35.364 11.5396 36 10.3766 36H5.96063ZM25.6234 36C24.4604 36 23.8243 35.364 23.8243 34.2191C23.8243 33.0742 24.4604 32.4382 25.6234 32.4382H29.8213C31.4932 32.4382 32.4382 31.5659 32.4382 29.785V25.6779C32.4382 24.5331 33.056 23.8788 34.2191 23.8788C35.364 23.8788 36 24.5149 36 25.6779V30.0939C36 33.9828 33.9647 36 30.0394 36H25.6234Z"
        fill="currentColor"
      />
      <path
        d="M11.0617 29C8.43828 29 7 27.5732 7 24.9613V11.0387C7 8.42678 8.43828 7 11.0617 7H24.9383C27.5732 7 29 8.42678 29 11.0387V24.9613C29 27.5732 27.5732 29 24.9383 29H11.0617ZM18.0575 23.569C19.0816 23.569 19.6684 22.8902 19.6684 21.7856V14.8818H21.7626C22.568 14.8818 23.0743 14.4215 23.0743 13.6621C23.0743 12.8912 22.568 12.4425 21.7626 12.4425H14.387C13.5701 12.4425 13.0638 12.8912 13.0638 13.6621C13.0638 14.4215 13.5701 14.8818 14.387 14.8818H16.4582V21.7856C16.4582 22.8902 17.0335 23.569 18.0575 23.569Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "-2 0 40 36",
});

export { TreatmentList };
