import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { useUsers, UseUsersResponseItem } from "modules/identity";
import { PersonalInfo } from "modules/in-process/components/in-process-patients/PersonalInfo";
import { useState, useMemo, useEffect } from "react";
import { DividerList, SearchBar } from "shared";
import { debounce } from "throttle-debounce";
import { ChartType } from "types";

type AddTransferCareProviderModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  progressNoteId: string;
  chartType: ChartType;
  onSelectedProvider: (provider: UseUsersResponseItem) => void;
};

function AddTransferCareProviderModal(
  props: AddTransferCareProviderModalProps
) {
  const { onClose, isOpen, chartType, onSelectedProvider } = props;

  const [search, setSearch] = useState("");
  const [selectedProvider, setSelectedProvider] =
    useState<UseUsersResponseItem | null>(null);

  const { data, refetch } = useUsers({
    userType: chartType === "Triage" ? "Nurse" : "Provider",
    search,
  });

  const onSearch = useMemo(
    () =>
      debounce<React.ChangeEventHandler<HTMLInputElement>>(1000, (e) => {
        setSearch(e.target.value);
      }),
    []
  );

  const handleClose = () => {
    setSelectedProvider(null);
    onClose();
  };

  const handleSelectProvider = () => {
    if (selectedProvider) {
      onSelectedProvider(selectedProvider);
    }
    onClose();
  };

  useEffect(() => {
    refetch();
  }, [search, refetch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end", alignItems: "center" }}
        height="98%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
        mr="10px"
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          fontSize="1rem"
          padding="20px 16px 30px"
        >
          <Button variant="label" onClick={handleClose} color="blue">
            Cancel
          </Button>
          <Heading fontWeight="600" fontSize="1.0625rem">
            {chartType === "Chart" ? "Providers" : "Nurses"}
          </Heading>
          <Button
            variant="label"
            onClick={handleSelectProvider}
            color="blue"
            disabled={!selectedProvider}
          >
            Done
          </Button>
        </ModalHeader>
        <ModalBody
          padding="34px 15px 15px"
          display="flex"
          flexDirection="column"
          h="100%"
        >
          <SearchBar onChange={onSearch} />
          <DividerList dividerLeftMargin="62px">
            {data?.data.map((item) => (
              <Box
                padding="15px 21px"
                key={item.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                _selected={{ bg: "blue", color: "white" }}
                onClick={() => setSelectedProvider(item)}
              >
                <PersonalInfo
                  name={item.fullName}
                  pictureUrl={item.pictureUrl}
                  description={item.teams}
                />
                {item.id === selectedProvider?.id && (
                  <Icon as={EmrCheck} fontSize="1rem" color="blue" />
                )}
              </Box>
            ))}
          </DividerList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { AddTransferCareProviderModal };
