function baseStyle() {
  return {
    borderRadius: "20px",
    padding: "7px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 200ms ease-in-out",
    color: "gray.700",
    height: "36px",
    border: "1px solid transparent",
    _selected: {
      borderColor: "blue",
      color: "blue",
    },
  };
}

const chartOptionTheme = { baseStyle };

export { chartOptionTheme };
