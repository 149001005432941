import * as React from "react";
import {
  useAsyncDebounce,
  UseGlobalFiltersInstanceProps,
  TableState,
} from "react-table";
import { InputGroup, Icon, InputLeftElement } from "@chakra-ui/react";
import { EmrSearch } from "@medstonetech/slate-icons";
import { Input } from "../input";
import { AnyObject } from "../../types/utils";

export type TableSearchProps<D extends AnyObject> = {
  globalFilter: TableState<D>["globalFilter"];
  setGlobalFilter: UseGlobalFiltersInstanceProps<D>["setGlobalFilter"];
};

export function TableSearch<D extends AnyObject>(props: TableSearchProps<D>) {
  const { globalFilter, setGlobalFilter } = props;

  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((newValue) => {
    setGlobalFilter(newValue || undefined);
  }, 200);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon as={EmrSearch} color="gray.550" />
      </InputLeftElement>
      <Input
        paddingStart="10"
        variant="search"
        value={value || ""}
        placeholder="Search"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </InputGroup>
  );
}
