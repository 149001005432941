import { createIcon } from "@chakra-ui/react";

const Chain = createIcon({
  path: (
    <path
      d="M11.2371 16.7349C10.0561 16.5031 9.19522 16.0175 8.58818 15.3994C5.80685 12.6291 5.81789 8.71097 8.57715 5.96275L12.4622 2.06668C15.2104 -0.681536 19.1175 -0.70361 21.8988 2.08876C24.6802 4.85905 24.6802 8.78823 21.9099 11.5254L18.8085 14.6268C19.0513 13.6666 18.9078 12.5739 18.5215 11.691L20.2102 10.0023C22.0313 8.18119 22.0423 5.6206 20.1991 3.77742C18.378 1.95631 15.8174 1.95631 13.9853 3.78846L10.2768 7.48586C8.45574 9.30697 8.45574 11.8786 10.2879 13.7107C10.928 14.3509 11.8551 14.7703 13.0803 14.8917L11.2371 16.7349ZM12.7491 7.27616C13.9301 7.4969 14.791 7.98252 15.398 8.6006C18.1794 11.3709 18.1683 15.289 15.4201 18.0372L11.5351 21.9333C8.77581 24.6815 4.86871 24.7036 2.08738 21.9112C-0.693951 19.141 -0.693951 15.2118 2.07634 12.4746L5.17774 9.37319C4.93493 10.3334 5.07841 11.4261 5.46471 12.309L3.77604 13.9977C1.95493 15.8188 1.95493 18.3794 3.78708 20.2226C5.61922 22.0437 8.17981 22.0437 10.0009 20.2226L13.7094 16.5141C15.5305 14.693 15.5305 12.1214 13.6983 10.2893C13.0582 9.64911 12.1311 9.24075 10.917 9.1083L12.7491 7.27616Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
});

export { Chain };
