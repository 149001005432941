import { Icon } from "@chakra-ui/react";
import { EmrActions } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules";
import {
  ACTIONS_COLUMNS_GAP,
  ACTIONS_ROW_TEMPLATE_COLUMNS_3,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  CONTAINER_PADDING_2,
} from "modules/triage/shared/actions/constants";
import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartActionsForm } from "../../hooks";
import { ActionsHeaderDescription } from "../../shared";
import { usePermissions } from "contexts";

const sectionId = "SACT";
const chartCode = "T002x";

function N02Actions() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartActionsForm({
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:actions").isEditable;

  const { getValues } = useFormReturn;
  const initialActionsSections = getValues("initialActionsSections");
  const actionsSections = getValues("actionsSections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrActions} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Actions {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Initial Actions"
            >
              Initial Actions
            </ChartSubsectionHeader>
          }
          content={<ActionsHeaderDescription />}
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (initialActionsSections[0] &&
                        initialActionsSections[0].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[0] &&
                        initialActionsSections[0].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[0].${chartCode}${sectionId}Q001A000`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ID"
                    name={`initialActionsSections[0].${chartCode}${sectionId}Q001A001`}
                    type="option"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="unset"
                    ml="8px"
                    marginInline="unset"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    pl="20px"
                    label="allergy band"
                    name={`initialActionsSections[0].${chartCode}${sectionId}Q001A002`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="applied"
                    name={`initialActionsSections[0].${chartCode}${sectionId}Q001A003`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="unset"
                    ml="8px"
                    marginInline="unset"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    pl="20px"
                    label="verified"
                    name={`initialActionsSections[0].${chartCode}${sectionId}Q001A004`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="unset"
                    ml="8px"
                    marginInline="unset"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (initialActionsSections[1] &&
                        initialActionsSections[1].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[1] &&
                        initialActionsSections[1].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[1].${chartCode}${sectionId}Q001A006`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`initialActionsSections[1].${chartCode}${sectionId}Q001A007`}
                    type="option"
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`initialActionsSections[1].${chartCode}${sectionId}Q001A008`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="applied"
                    name={`initialActionsSections[1].${chartCode}${sectionId}Q001A009`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    w="32px"
                    h="32px"
                    fontSize=".25rem"
                    userName={
                      (initialActionsSections[2] &&
                        initialActionsSections[2].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[2] &&
                        initialActionsSections[2].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[2].${chartCode}${sectionId}Q001A010`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disrobed"
                    name={`initialActionsSections[2].${chartCode}${sectionId}Q001A011`}
                    type="option"
                  />
                  <ChartRowElement
                    label="gowned"
                    name={`initialActionsSections[2].${chartCode}${sectionId}Q001A012`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="blanket provided"
                    name={`initialActionsSections[2].${chartCode}${sectionId}Q001A013`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (initialActionsSections[3] &&
                        initialActionsSections[3].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[3] &&
                        initialActionsSections[3].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[3].${chartCode}${sectionId}Q001A014`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="icepack"
                    name={`initialActionsSections[3].${chartCode}${sectionId}Q001A015`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bandage"
                    name={`initialActionsSections[3].${chartCode}${sectionId}Q001A016`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (initialActionsSections[4] &&
                        initialActionsSections[4].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[4] &&
                        initialActionsSections[4].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[4].${chartCode}${sectionId}Q001A017`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bed low position"
                    name={`initialActionsSections[4].${chartCode}${sectionId}Q001A018`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="side rails up"
                    name={`initialActionsSections[4].${chartCode}${sectionId}Q001A019`}
                    type="option"
                  />
                  <ChartRowElement
                    label="x 1"
                    name={`initialActionsSections[4].${chartCode}${sectionId}Q001A020`}
                    type="option"
                  />
                  <ChartRowElement
                    label="x 2"
                    name={`initialActionsSections[4].${chartCode}${sectionId}Q001A021`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (initialActionsSections[5] &&
                        initialActionsSections[5].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (initialActionsSections[5] &&
                        initialActionsSections[5].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`initialActionsSections[5].${chartCode}${sectionId}Q001A022`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="call light in reach"
                    name={`initialActionsSections[5].${chartCode}${sectionId}Q001A023`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="head of bed elevated"
                    name={`initialActionsSections[5].${chartCode}${sectionId}Q001A024`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Actions"
            >
              Actions
            </ChartSubsectionHeader>
          }
          content={<ActionsHeaderDescription />}
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[0] &&
                        actionsSections[0].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[0] &&
                        actionsSections[0].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A001`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="visual acuity:"
                    type="option"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A052`}
                  />
                  <ChartRowElement
                    label="L 20 /"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A002`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A003`}
                    w="50px"
                    minW="50px"
                    type="input"
                  />
                  <ChartRowElement
                    label="R 20 /"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A004`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A005`}
                    w="50px"
                    minW="50px"
                    type="input"
                  />
                  <ChartRowElement
                    label="both 20 /"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A006`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A007`}
                    w="50px"
                    minW="50px"
                    type="input"
                  />
                  <ChartRowElement
                    label="corrected"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A008`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="uncorrected"
                    name={`actionsSections[0].${chartCode}${sectionId}Q002A009`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[1] &&
                        actionsSections[1].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[1] &&
                        actionsSections[1].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[1].${chartCode}${sectionId}Q002A010`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="eye tray"
                    name={`actionsSections[1].${chartCode}${sectionId}Q002A011`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[1].${chartCode}${sectionId}Q002A012`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[2] &&
                        actionsSections[2].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[2] &&
                        actionsSections[2].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[2].${chartCode}${sectionId}Q002A013`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cardiac monitor"
                    name={`actionsSections[2].${chartCode}${sectionId}Q002A014`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[2].${chartCode}${sectionId}Q002A015`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[3] &&
                        actionsSections[3].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[3] &&
                        actionsSections[3].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A016`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse oximeter"
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A017`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A018`}
                    w="120px"
                    minW="120px"
                    type="input"
                  />
                  <ChartRowElement
                    label="%"
                    type="option"
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A052`}
                  />
                  <ChartRowElement
                    label="RA /"
                    type="option"
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A053`}
                  />
                  <ChartRowElement
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A019`}
                    w="120px"
                    minW="120px"
                    type="input"
                  />
                  <ChartRowElement
                    label="L"
                    type="option"
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A054`}
                  />
                  <ChartRowElement
                    name={`actionsSections[3].${chartCode}${sectionId}Q002A020`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[4] &&
                        actionsSections[4].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[4] &&
                        actionsSections[4].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A021`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="O2"
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A022`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A023`}
                    w="120px"
                    minW="120px"
                    type="input"
                  />
                  <ChartRowElement
                    label="L"
                    type="option"
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A052`}
                  />
                  <ChartRowElement
                    label="via:"
                    type="option"
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A053`}
                  />
                  <ChartRowElement
                    label="NC"
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A024`}
                    type="option"
                  />
                  <ChartRowElement
                    label="mask"
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A025`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[4].${chartCode}${sectionId}Q002A026`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[5] &&
                        actionsSections[5].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[5] &&
                        actionsSections[5].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[5].${chartCode}${sectionId}Q002A027`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bedside glucose"
                    name={`actionsSections[5].${chartCode}${sectionId}Q002A028`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[5].${chartCode}${sectionId}Q002A029`}
                    w="120px"
                    minW="120px"
                    type="input"
                  />
                  <ChartRowElement
                    label="mg / dL"
                    type="option"
                    name={`actionsSections[5].${chartCode}${sectionId}Q002A052`}
                  />
                  <ChartRowElement
                    name={`actionsSections[5].${chartCode}${sectionId}Q002A030`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[6] &&
                        actionsSections[6].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[6] &&
                        actionsSections[6].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[6].${chartCode}${sectionId}Q002A031`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ready for provider eval"
                    name={`actionsSections[6].${chartCode}${sectionId}Q002A032`}
                    type="option"
                  />
                  <ChartRowElement
                    label="notified provider"
                    name={`actionsSections[6].${chartCode}${sectionId}Q002A033`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[6].${chartCode}${sectionId}Q002A034`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[7] &&
                        actionsSections[7].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[7] &&
                        actionsSections[7].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A035`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A036`}
                    type="option"
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A037`}
                    type="option"
                  />
                  <ChartRowElement
                    label="removed"
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A038`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="cleared by:"
                    fontStyle="italic"
                    type="option"
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A052`}
                  />
                  <ChartRowElement
                    name={`actionsSections[7].${chartCode}${sectionId}Q002A039`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[8] &&
                        actionsSections[8].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[8] &&
                        actionsSections[8].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[8].${chartCode}${sectionId}Q002A040`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="restraints"
                    name={`actionsSections[8].${chartCode}${sectionId}Q002A041`}
                    type="option"
                  />
                  <ChartRowElement
                    label="see documentation"
                    name={`actionsSections[8].${chartCode}${sectionId}Q002A042`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`actionsSections[8].${chartCode}${sectionId}Q002A043`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[9] &&
                        actionsSections[9].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[9] &&
                        actionsSections[9].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[9].${chartCode}${sectionId}Q002A044`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[9].${chartCode}${sectionId}Q002A045`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[10] &&
                        actionsSections[10].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[10] &&
                        actionsSections[10].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[10].${chartCode}${sectionId}Q002A046`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[10].${chartCode}${sectionId}Q002A047`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[11] &&
                        actionsSections[11].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[11] &&
                        actionsSections[11].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[11].${chartCode}${sectionId}Q002A048`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[11].${chartCode}${sectionId}Q002A049`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (actionsSections[12] &&
                        actionsSections[12].createdByFullName) ||
                      ""
                    }
                    profileUrl={
                      (actionsSections[12] &&
                        actionsSections[12].createdByPictureUrl) ||
                      ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[12].${chartCode}${sectionId}Q002A050`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`actionsSections[12].${chartCode}${sectionId}Q002A051`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N02Actions };
