import {
  UseRadioGroupProps,
  useRadioGroup,
  Box,
  RadioProps,
  Divider,
  BoxProps,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";
import { isLastIndex } from "utils";

type RadioListGroupProps = UseRadioGroupProps &
  Omit<BoxProps, keyof UseRadioGroupProps>;

const RadioListGroup = React.forwardRef<HTMLDivElement, RadioListGroupProps>(
  (props, ref) => {
    // This component expects children of type RadioListItem,
    // otherwise it won't work as expected
    const {
      value: propValue,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
      children,
      ...boxProps
    } = props;
    const childrenArray = React.Children.toArray(children);
    const { getRootProps, getRadioProps } = useRadioGroup({
      value: propValue,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
    });
    const group = getRootProps();

    return (
      <Box
        bg="white"
        borderRadius="10px"
        display="flex"
        flexDirection="column"
        ref={ref}
        {...boxProps}
        {...group}
      >
        {childrenArray.map((child, index) => {
          if (React.isValidElement<RadioProps>(child)) {
            const {
              props: { value },
            } = child;
            const radio = getRadioProps({ value });
            return (
              <React.Fragment key={value}>
                {React.cloneElement(child, { ...radio })}
                {!isLastIndex(index, childrenArray) && (
                  <Divider
                    color="gray.450"
                    opacity={1}
                    width="calc(100% - 16px)"
                    alignSelf="flex-end"
                  />
                )}
              </React.Fragment>
            );
          }
          return child;
        })}
      </Box>
    );
  }
);

if (config.isDev) {
  RadioListGroup.displayName = "RadioListGroup";
}

export { RadioListGroup };
