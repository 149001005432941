import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteEncounterConsentFilePage(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterConsentFilePage", { encounterId }],
    (pageNumber: number) =>
      axiosPatientInstance.delete<string>(
        `v1/encounter/${encounterId}/form/files/consent/${pageNumber}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterConsentFile",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["encounterOverview", { encounterId }]);
      },
    }
  );
}

export { useDeleteEncounterConsentFilePage };
