import {
  Box,
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import { format } from "date-fns";
import {
  AmbulatoryNote as AmbulatoryNoteType,
  useDeleteAmbulatoryNote,
} from "modules/charts-shared/api";
import { useState } from "react";
import { Card, DeleteDialog, StaffAvatar } from "shared";

type AmbulatoryNoteProps = {
  note: AmbulatoryNoteType;
  encounterId: string;
};

function AmbulatoryNote({ note, encounterId }: AmbulatoryNoteProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);

  const { mutate, isLoading: isDeleting } = useDeleteAmbulatoryNote(
    note.id,
    encounterId
  );

  return (
    <>
      <VStack width="100%">
        <Flex w="100%">
          <Box w="30px" />
          <HStack
            spacing="5px"
            color="gray.750"
            fontSize="0.75rem"
            marginLeft="10px"
            justify="flex-start"
            flex={1}
          >
            <chakra.span>{`${note.userNote.firstName} ${note.userNote.lastName}`}</chakra.span>
            <chakra.span>&#183;</chakra.span>
            <chakra.span>
              {note.createdAt &&
                format(new Date(note.createdAt), "hh:mm a L/d/yyyy")}
            </chakra.span>
          </HStack>
        </Flex>
        <Flex
          align="center"
          onMouseEnter={() => setShowDeleteBtn(true)}
          onMouseLeave={() => setShowDeleteBtn(false)}
          width="100%"
          columnGap="10px"
        >
          <StaffAvatar
            size="xs"
            width="30px"
            height="30px"
            fontSize=".5rem"
            userName={`${note.userNote?.firstName} ${note.userNote?.lastName}`}
            profileUrl={note.userNote?.pictureUrl || ""}
          />
          <Card
            padding="10px"
            borderRadius="0.625rem"
            bg="gray.50"
            position="relative"
            boxShadow="none"
            flex={1}
          >
            {note.note}
            {showDeleteBtn && !isDeleting && (
              <IconButton
                aria-label="delete note"
                icon={<Icon as={EmrRemove} color="red" />}
                position="absolute"
                bottom="-10px"
                right="-10px"
                width="20px"
                height="20px"
                variant="ghost"
                minWidth="unset"
                onClick={onOpen}
              />
            )}
            {isDeleting && (
              <Spinner
                position="absolute"
                bottom="-10px"
                right="-10px"
                width="20px"
                height="20px"
                color="blue"
              />
            )}
          </Card>
        </Flex>
      </VStack>
      <DeleteDialog
        isOpen={isOpen}
        onClose={onClose}
        onDelete={() => mutate({})}
      />
    </>
  );
}

export { AmbulatoryNote };
