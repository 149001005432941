import { axiosPatientInstance } from "config";
import { DietType } from "enums";
import { useAxiosQuery } from "hooks";
import { RoomBedDto } from "modules/in-process/api";

type ChartPatientCardResponse = {
  encounterId: string;
  entitledToObservation: true;
  fullName: string;
  age: 0;
  gender: string;
  overallTime: string;
  acuity: number;
  painLevel: number;
  hasFoodAllergies: true;
  hasDrugAllergies: true;
  hasAllergiesOrMedicationsRx: boolean;
  hasAllergiesRx: boolean;
  assignedSupervisor: string;
  diet: DietType;
  hasAppointmentNotes: true;
  pictureUrl?: string;
  roomBed?: RoomBedDto;
  isVip?: boolean;
  painProtocol?: boolean;
  chiefComplaint?: string;
};

function useChartPatientCardInfo(encounterId: string) {
  return useAxiosQuery(["chartPatientCard", { encounterId }], () =>
    axiosPatientInstance.get<ChartPatientCardResponse>(
      `v1/InProcess/${encounterId}`
    )
  );
}

export type { ChartPatientCardResponse };
export { useChartPatientCardInfo };
