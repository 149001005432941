import { ModalBody, ModalBodyProps, VStack } from "@chakra-ui/react";
import * as React from "react";
import { Loading } from "shared";

type ChartNotesModalBodyProps = {
  isLoading?: boolean;
} & ModalBodyProps;

const ChartNotesModalBody = React.forwardRef<
  HTMLDivElement,
  ChartNotesModalBodyProps
>((props, ref) => {
  const { isLoading, children, ...modalBodyProps } = props;

  return (
    <ModalBody
      flex="1"
      overflow="auto"
      padding="0 20px"
      margin="22px 0"
      ref={ref}
      {...modalBodyProps}
    >
      {isLoading && <Loading />}
      <VStack spacing="1.25rem" paddingBottom="1rem">
        {children}
      </VStack>
    </ModalBody>
  );
});

ChartNotesModalBody.displayName = "ChartNotesModalBody";

export type { ChartNotesModalBodyProps };
export { ChartNotesModalBody };
