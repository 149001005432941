import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGenerateUserInteractionReportPayload = {
  userId?: string | null;
  encounterNumber: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

function useGenerateUserInteractionReport(
  payload: UseGenerateUserInteractionReportPayload,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["userInteractions"],
    () =>
      axiosPatientInstance.get<Blob>("v1/user-interactions", {
        responseType: "blob",
        params: payload,
      }),
    { ...options }
  );
}

export type { UseGenerateUserInteractionReportPayload };
export { useGenerateUserInteractionReport };
