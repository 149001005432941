import { Box, BoxProps, Text } from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";
import { StaffAvatar } from "shared";
import { zip } from "utils";
import {
  VALUE_KEYS_LABELS,
  VITALS_COLUMN_VALUE_KEYS,
} from "../constants/constants";
import { useVitalsContext } from "../contexts";
import { getCellDisplay } from "../utils/utils";
import { EdgeVitalsCell, LabelVitalsCell, VitalsCell } from "./VitalsCell";

const columnTuples = zip(VITALS_COLUMN_VALUE_KEYS, VALUE_KEYS_LABELS);

type VitalsFixedSectionProps = BoxProps;

const VitalsFixedSection = React.forwardRef<
  HTMLDivElement,
  VitalsFixedSectionProps
>((props, ref) => {
  const { ...boxProps } = props;
  const { vitalsData } = useVitalsContext();
  const latestRecord = vitalsData?.currentVitalCheck;
  const takenByName = latestRecord?.takenBy
    ? `${latestRecord.takenBy.fullName}`
    : "";
  const takenByPictureUrl =
    latestRecord?.takenBy && latestRecord.takenBy.pictureUrl
      ? latestRecord.takenBy.pictureUrl
      : "";

  return (
    <Box
      ref={ref}
      height="100%"
      overflow="overlay"
      {...boxProps}
      borderRadius="10px"
    >
      <Box
        border="1px solid #D1D1D6"
        borderRadius="10px"
        display="grid"
        gridTemplateColumns="120px 240px"
        gridTemplateRows="repeat(12, 45px)"
        gridGap="1px"
        bg="#D3D3D6"
      >
        <EdgeVitalsCell
          cellPosition="top"
          justifyContent="flex-start"
          paddingLeft="15px"
          borderTopStartRadius="10px"
        >
          Time (#)
        </EdgeVitalsCell>
        <EdgeVitalsCell cellPosition="top" borderTopEndRadius="10px">
          Most Current
        </EdgeVitalsCell>
        {columnTuples.map(([key, label]) => (
          <React.Fragment key={key}>
            <LabelVitalsCell>{label}</LabelVitalsCell>
            <VitalsCell
              color={
                latestRecord &&
                latestRecord[key] !== undefined &&
                latestRecord[key] !== null &&
                latestRecord[key] !== ""
                  ? "blue"
                  : "gray.450"
              }
              title={
                latestRecord && latestRecord[key]
                  ? getCellDisplay(key, latestRecord[key])
                  : undefined
              }
            >
              {getCellDisplay(
                key,
                latestRecord ? latestRecord[key] : undefined
              )}
            </VitalsCell>
          </React.Fragment>
        ))}
        <EdgeVitalsCell
          cellPosition="bottom"
          justifyContent="flex-start"
          paddingLeft="15px"
        >
          Taken by
        </EdgeVitalsCell>
        <EdgeVitalsCell cellPosition="bottom">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flex={1}
            paddingLeft="4px"
          >
            <StaffAvatar
              size="xs"
              fontSize="1rem"
              title={takenByName}
              marginRight="1.25rem"
              userName={takenByName || ""}
              profileUrl={takenByPictureUrl || ""}
            />
            <Box display="flex" flexDirection="column" marginLeft="10px">
              <Text fontSize="15px">{takenByName}</Text>
              <Text fontSize="12px">
                {latestRecord?.takenBy ? latestRecord.takenBy.teams : ""}
              </Text>
            </Box>
          </Box>
        </EdgeVitalsCell>
      </Box>
    </Box>
  );
});

if (config.isDev) {
  VitalsFixedSection.displayName = "VitalsFixedSection";
}

export { VitalsFixedSection };
