import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseOpenChartsProviderCountResponse = {
  count: number;
};

function useOpenChartsProviderCount() {
  return useAxiosQuery(["openChartProviderCounter"], () =>
    axiosPatientInstance.get<UseOpenChartsProviderCountResponse>(
      `v1/open-charts/provider-count`
    )
  );
}

export { useOpenChartsProviderCount };
