import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DiagonalArrow = createIcon({
  path: (
    <path
      d="M0.31934 17.663C-0.100899 17.2538 -0.111958 16.5571 0.31934 16.1369L11.0244 5.465L9.01167 3.45228C8.48084 2.92145 8.79049 2.20262 9.55355 1.9925L16.8525 0.0350632C17.527 -0.152939 18.1463 0.444244 17.9694 1.1299L16.0009 8.45091C15.8019 9.20292 15.0609 9.51257 14.5301 8.98174L12.5505 6.99114L1.84547 17.663C1.42523 18.0833 0.728521 18.0833 0.31934 17.663Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { DiagonalArrow };
