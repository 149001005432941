import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S024Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q024.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q024`}
          subsectionLabel="Vaginal Delivery"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Vaginal Delivery
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                type="option"
                name={`${sectionDelimiter}Q024A001`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q024A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Position:"
                type="option"
                name={`${sectionDelimiter}Q024A003`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="lithotomy position:"
                type="option"
                name={`${sectionDelimiter}Q024A004`}
              />
              <ChartRowElement
                label="gurney"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q024A005`}
              />
              <ChartRowElement
                label="pelvic table"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q024A006`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A007`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                name={`${sectionDelimiter}Q024A008`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="vulvar"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A009`}
                type="option"
              />
              <ChartRowElement
                label="local"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A010`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A011`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep / Episiotomy:"
                name={`${sectionDelimiter}Q024A012`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="chlorhexidine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A013`}
                type="option"
              />
              <ChartRowElement
                label="betadine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A014`}
                type="option"
              />
              <ChartRowElement
                label="episiotomy"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A015`}
                type="option"
              />
              <ChartRowElement
                label="chlorhexidine"
                name={`${sectionDelimiter}Q005A016`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="L"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A017`}
                type="option"
              />
              <ChartRowElement
                label="R"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A018`}
                type="option"
              />
              <ChartRowElement
                label="midline"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A019`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A020`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Delivery Stabilization:"
                type="option"
                name={`${sectionDelimiter}Q024A021`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="precipitous delivery"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A022`}
                type="option"
              />
              <ChartRowElement
                label="nuchal cord removed"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A023`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A024`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Estimated blood loss:"
                type="option"
                name={`${sectionDelimiter}Q024A025`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q024A026`}
                w="200px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                name={`${sectionDelimiter}Q024A027`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A028`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Newborn:"
                type="option"
                name={`${sectionDelimiter}Q024A029`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="suctioned"
                name={`${sectionDelimiter}Q024A030`}
                type="option"
              />
              <ChartRowElement
                label="bulb syringe"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A031`}
                type="option"
              />
              <ChartRowElement
                label="suction catheter"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A032`}
                type="option"
              />
              <ChartRowElement
                label="simulated"
                name={`${sectionDelimiter}Q024A033`}
                type="option"
              />
              <ChartRowElement
                label="intubated"
                name={`${sectionDelimiter}Q024A034`}
                type="option"
              />
              <ChartRowElement
                label="suctioned"
                name={`${sectionDelimiter}Q024A035`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="warmed with:"
                name={`${sectionDelimiter}Q024A037`}
                type="option"
              />
              <ChartRowElement
                label="blanket"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A038`}
                type="option"
              />
              <ChartRowElement
                label="infant warmer"
                fontStyle="italic"
                name={`${sectionDelimiter}Q024A039`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A040`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="see newborn/spontaneous delivery template"
                name={`${sectionDelimiter}Q024A041`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q024A042`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q024A043`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S024Procedure };
