import {
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { useFileUrl, useToast } from "hooks";
import { Import } from "icons";
import React from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import { MdCrop, MdZoomIn, MdZoomOut } from "react-icons/md";
import { Button, Input } from "shared";
import { constants } from "system-constants";
import { getCroppedImg } from "utils";

type ImportSignatureContentProps = {
  image: ImportSignatureModalProps["image"];
  croppedImage: string | null;
  setCroppedImage: React.Dispatch<
    React.SetStateAction<ImportSignatureContentProps["croppedImage"]>
  >;
};

const ImportSignatureContent = ({
  image: defaultImage,
  croppedImage,
  setCroppedImage,
}: ImportSignatureContentProps) => {
  const toast = useToast();

  const [image, setImage] = React.useState(defaultImage);
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(
    null
  );
  const [zoom, setZoom] = React.useState(1);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const imageUrl = useFileUrl(image);

  const onClickInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleImportNewImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files && files.length > 0) {
      const newImage = files[0];

      if (newImage.size > constants.MAX_FILE_SIZE) {
        toast({
          description: `Files must be smaller than ${constants.MAX_FILE_SIZE_IN_MB}MB`,
        });
        return;
      }

      setImage(newImage);
      e.currentTarget.value = "";
    }
  };

  const handleCrop = async () => {
    if (croppedAreaPixels) {
      const newCroppedImage = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(newCroppedImage);
    }
  };

  return (
    <VStack width="100%" pb={4}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        p={1}
        py={3}
      >
        <Button
          variant="label"
          color="blue"
          disabled={Boolean(croppedImage)}
          onClick={() => setZoom((prev) => prev + 0.1)}
        >
          <Icon as={MdZoomIn} w="24px" h="24px" color="blue" mr={2} />
          Zoom In
        </Button>
        <Button
          variant="label"
          color="blue"
          disabled={Boolean(croppedImage)}
          onClick={() => setZoom((prev) => prev - 0.1)}
        >
          <Icon as={MdZoomOut} w="24px" h="24px" color="blue" mr={2} />
          Zoom Out
        </Button>
        <Button variant="label" color="blue" onClick={handleCrop}>
          <Icon as={MdCrop} w="24px" h="24px" color="blue" mr={2} />
          Crop
        </Button>
        <Button
          variant="label"
          color="blue"
          disabled={Boolean(croppedImage)}
          onClick={onClickInput}
        >
          <Input
            ref={inputRef}
            type="file"
            display="none"
            accept="image/*"
            onChange={handleImportNewImage}
          />
          <Icon as={Import} w="24px" h="24px" color="blue" mr={2} />
          Import New File
        </Button>
      </Box>
      <Box
        p={4}
        bgColor="gray.450"
        h="100%"
        w="100%"
        borderRadius="5px"
        overflow="auto"
      >
        {croppedImage ? (
          <chakra.img
            src={croppedImage}
            style={{ position: "relative", top: "40%", margin: "auto" }}
          />
        ) : (
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={(_, currentCroppedAreaPixels) =>
              setCroppedAreaPixels(currentCroppedAreaPixels)
            }
            onZoomChange={setZoom}
            cropSize={{
              width: 400,
              height: 63,
            }}
            style={{
              containerStyle: {
                position: "relative",
                height: "100%",
                width: "100%",
              },
            }}
          />
        )}
      </Box>
    </VStack>
  );
};

type ImportSignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSign: (signature: string) => void;
  isLoading?: boolean;
  image: File;
};

const ImportSignatureModal = (props: ImportSignatureModalProps) => {
  const { onClose, isLoading, image, onSign } = props;

  const [croppedImage, setCroppedImage] =
    React.useState<ImportSignatureContentProps["croppedImage"]>(null);

  const handleCancel = () => {
    setCroppedImage(null);
    onClose();
  };

  const handleDone = () => {
    if (croppedImage) {
      onSign(croppedImage);
    }
  };

  return (
    <Modal {...props} isCentered>
      <ModalContent borderRadius="5px" minWidth="660px" minHeight="925px">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bgColor="gray.200"
          borderTopRadius="5px"
        >
          <Button variant="label" color="blue" onClick={handleCancel}>
            Cancel
          </Button>
          <Box display="flex" alignContent="center" gap="0.5rem">
            <Icon as={EmrSignature} fontSize="1.25rem" color="black" />
            <Text fontSize="15px">Add Signature</Text>
          </Box>

          <Button
            variant="label"
            color="blue"
            isLoading={isLoading}
            disabled={isLoading || !Boolean(croppedImage)}
            onClick={handleDone}
          >
            Done
          </Button>
        </ModalHeader>
        <ModalBody display="flex">
          <ImportSignatureContent
            image={image}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { ImportSignatureModal };
