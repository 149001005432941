import { Icon } from "@chakra-ui/react";
import { EmrLabs } from "@medstonetech/slate-icons";
import { OrderFormElementCategory } from "enums";
import { Ultrasound } from "icons";
import { OrderTypeIcon } from "modules/radiology/components";

function OrderTypeIconElement(
  colorIcon: string,
  elementCategory?: OrderFormElementCategory
) {
  switch (elementCategory) {
    case "CT_SCAN":
    case "XRAY":
      return (
        <OrderTypeIcon orderType={elementCategory} colorIcon={colorIcon} />
      );
    case "Ultrasound":
      return (
        <Icon as={Ultrasound} color={colorIcon} w="1.375rem" h="1.375rem" />
      );
    case "MRI":
    default:
      return <Icon as={EmrLabs} color={colorIcon} w="1.375rem" h="1.375rem" />;
  }
}

export { OrderTypeIconElement };
