import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseAmbulatoryNotesProps = {
  encounterId: string;
};

type UserNote = {
  firstName: string;
  lastName: string;
  pictureUrl: string;
  id: string;
  email: string;
  phoneNumber: string;
};

type AmbulatoryNote = {
  id: string;
  note: string;
  userNote: UserNote;
  createdAt: string;
};

function useAmbulatoryNotes({ encounterId }: UseAmbulatoryNotesProps) {
  return useAxiosQuery(["ambulatoryNotes", encounterId], () =>
    axiosChartInstance.get<AmbulatoryNote[]>(
      `v1/ambulatory-note/${encounterId}`
    )
  );
}

export { useAmbulatoryNotes };
export type { AmbulatoryNote };
