import * as React from "react";
import { Segment, SegmentOption } from "shared";
import {
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalValueBox,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { WeightUnit } from "../../types/types";
import { Button } from "@chakra-ui/react";
import { Close } from "icons";

type WeightVitalsInputModalProps = Omit<
  BaseVitalsInputModalProps,
  "title" | "onSave"
> & {
  onSave: (value: number, unit: WeightUnit) => void;
};

function getUnit(index: number): WeightUnit {
  const units: Record<number, WeightUnit> = {
    0: "kg",
    1: "lbs",
  };

  return units[index];
}

function WeightVitalsInputModal(props: WeightVitalsInputModalProps) {
  const { onSave, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");
  const [unit, setUnit] = React.useState(0);

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setUnit(0);
    }
  }, [initialValue]);

  return (
    <VitalsInputModal title="Weight" {...vitalsInputModalProps}>
      <VitalsInputModalValueBox
        selector={
          <Segment isFitted onChange={setUnit} index={unit}>
            <SegmentOption>kg</SegmentOption>
            <SegmentOption>lbs</SegmentOption>
          </Segment>
        }
      >
        {value}
      </VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        customKeyLabel="."
        onClickCustomKey={() => setValue((prev) => `${prev}.`)}
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={() => {
          const num = Number(value);
          onSave(Number.isNaN(num) ? 0 : num, getUnit(unit));
          onClose();
        }}
        onDelete={() => setValue((prev) => prev.slice(0, prev.length - 1))}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export { WeightVitalsInputModal };
