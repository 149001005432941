import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import {
  OpenChartProviderCounters,
  ProviderTypes,
} from "modules/open-charts/types";

type useOpenChartsProviderCountersProps = {
  providerType: ProviderTypes;
};

function useOpenChartsProviderCounters(
  props: useOpenChartsProviderCountersProps
) {
  const { providerType = "Provider" } = props;

  return useAxiosQuery(["openChartProviderCounters", { providerType }], () =>
    axiosChartInstance.get<OpenChartProviderCounters>(
      `v1/open-charts/filter/providers`,
      {
        params: {
          providerType: providerType,
        },
      }
    )
  );
}

export { useOpenChartsProviderCounters };
