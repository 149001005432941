import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { Order } from "modules/charts-shared";

type IvMedicationSection = {
  code: string;
  answers: { qCode: string; value: string }[];
  createdDate: string;
  createdById: string;
  orderId: string;
  order?: Order;
};

type TriageIvMedicationResponse = {
  ivStarts: IvMedicationSection[];
  ivMedications: IvMedicationSection[];
  medications: IvMedicationSection[];
  treatments: IvMedicationSection[];
};

function useIvAndMedication(encounterId: string) {
  return useAxiosQuery(["ivAndMedication", { encounterId }], () =>
    axiosChartInstance.get<TriageIvMedicationResponse>(
      `v1/triage/iv-medication/${encounterId}`
    )
  );
}

export { useIvAndMedication };
export type { TriageIvMedicationResponse };
