import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const EditSquared = createIcon({
  path: (
    <path
      d="M22.8121 3.02845L20.9742 1.20252L21.8271 0.361629C22.2595 -0.0708281 22.9443 -0.130892 23.3527 0.265527L23.665 0.58987C24.1215 1.03434 24.1095 1.69504 23.641 2.17555L22.8121 3.02845ZM8.48096 15.9421C8.20467 16.0502 7.90436 15.7379 8.02448 15.4616L9.10562 13.0951L20.0612 2.1275L21.8871 3.94141L10.9316 14.909L8.48096 15.9421ZM3.94016 23.9546C1.35743 23.9546 0 22.6332 0 20.0625V6.28389C0 3.71317 1.35743 2.37976 3.94016 2.37976H17.3223L14.9678 4.73425H4.08432C2.96714 4.73425 2.35449 5.32287 2.35449 6.48811V19.8582C2.35449 21.0235 2.96714 21.6121 4.08432 21.6121H17.8028C18.6197 21.6121 19.2323 21.0235 19.2323 19.8582V9.08285L21.5868 6.72836V20.0625C21.5868 22.6332 20.2414 23.9546 17.947 23.9546H3.94016Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 3 22 20",
});

export { EditSquared };
