import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { SupervisingProvider } from "modules/reception/types";
import { useQueryClient } from "react-query";

function useAssignSupervisingProvider(supervisingProviderId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["assignSupervisingProvider", { supervisingProviderId }],
    (active: boolean) =>
      axiosPatientInstance.post<SupervisingProvider>(
        `v1/supervising-provider/${supervisingProviderId}/assign`,
        { active }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("supervisingProviderList");
        queryClient.invalidateQueries("activeSupervisingProvider");
      },
    }
  );
}

export { useAssignSupervisingProvider };
