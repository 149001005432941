import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const StpVipList = createIcon({
  path: (
    <path
      d="M5.93821 6.18851C5.42606 6.56098 4.85183 6.17299 5.05359 5.58324L5.55022 4.01574L4.26208 3.06903C3.79649 2.71208 3.98272 2.04473 4.57247 2.04473H6.18653L6.7142 0.384114C6.8694 -0.128038 7.58331 -0.128038 7.75403 0.384114L8.2817 2.04473H9.86471C10.4545 2.04473 10.6562 2.71208 10.1751 3.06903L8.90249 4.01574L9.41464 5.58324C9.6164 6.17299 9.01113 6.5765 8.49897 6.18851L7.22635 5.2418L5.93821 6.18851ZM15.0328 4.96245C14.2102 4.96245 13.5739 4.31062 13.5739 3.48807C13.5739 2.66552 14.2102 2.01369 15.0328 2.01369H34.5256C35.3637 2.01369 36 2.66552 36 3.48807C36 4.31062 35.3482 4.96245 34.5256 4.96245H15.0328ZM5.93821 15.7021C5.42606 16.0746 4.85183 15.6711 5.05359 15.0813L5.55022 13.5294L4.26208 12.5827C3.79649 12.2257 3.98272 11.5583 4.57247 11.5583H6.18653L6.7142 9.89773C6.8694 9.38558 7.58331 9.38558 7.75403 9.89773L8.2817 11.5583H9.86471C10.4545 11.5583 10.6562 12.2257 10.1751 12.5827L8.90249 13.5294L9.41464 15.0813C9.6164 15.6711 9.01113 16.0901 8.49897 15.7021L7.22635 14.7554L5.93821 15.7021ZM15.0328 14.4916C14.2102 14.4916 13.5739 13.8398 13.5739 13.0172C13.5739 12.1947 14.2102 11.5428 15.0328 11.5428H34.5256C35.3637 11.5428 36 12.1947 36 13.0172C36 13.8398 35.3482 14.4916 34.5256 14.4916H15.0328ZM5.93821 25.2468C5.42606 25.6193 4.85183 25.2157 5.05359 24.626L5.55022 23.074L4.26208 22.1273C3.79649 21.7704 3.98272 21.103 4.57247 21.103H6.18653L6.7142 19.4424C6.8694 18.9302 7.58331 18.9302 7.75403 19.4424L8.2817 21.103H9.86471C10.4545 21.103 10.6562 21.7704 10.1751 22.1273L8.90249 23.074L9.41464 24.626C9.6164 25.2157 9.01113 25.6348 8.49897 25.2468L7.22635 24.3001L5.93821 25.2468ZM15.0328 24.0207C14.2102 24.0207 13.5739 23.3689 13.5739 22.5463C13.5739 21.7238 14.2102 21.072 15.0328 21.072H34.5256C35.3637 21.072 36 21.7238 36 22.5463C36 23.3689 35.3482 24.0207 34.5256 24.0207H15.0328Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 40 26",
});

export { StpVipList };
