import * as React from "react";
import { BoxProps, HStack } from "@chakra-ui/react";
import { isLastIndex } from "utils";

type ChartRowGroupProps = BoxProps;

function ChartRowGroup(props: ChartRowGroupProps) {
  const { children, ...boxProps } = props;
  const childrenArray = React.Children.toArray(children);

  return (
    <HStack spacing="0.75rem" alignItems="center" {...boxProps}>
      {childrenArray.map(
        (child, index) =>
          React.isValidElement(child) &&
          // Last element of a row group always fills the remaining space
          React.cloneElement(child, {
            flex: isLastIndex(index, childrenArray) ? 1 : "unset",
          })
      )}
    </HStack>
  );
}

export type { ChartRowGroupProps };
export { ChartRowGroup };
