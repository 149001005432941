import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useApproveOrder(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["approveOrder", { orderId }],
    () => axiosOrderInstance.put(`v1/${orderId}/approve`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrderFile", { orderId }]);
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderContrast", { orderId }]);
      },
    }
  );
}

export { useApproveOrder };
