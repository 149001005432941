import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteCommonDoseFavorite() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteOrderFormUserFavoriteDose"],
    (doseId: string) =>
      axiosChartInstance.delete(`v1/order-form/doses/favorites/${doseId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["doseFavorites"]);
      },
    }
  );
}
export { useDeleteCommonDoseFavorite };
