import { AnyObject, ApiError, RecursiveKeyOf } from "types";
import { AxiosError } from "axios";
import { matchPath } from "react-router-dom";
import { formMessages } from "messages";

/**
 * This can receive any argument and will perform
 * no operation.
 * @example
 *
 * noop(2)
 * // => void
 *
 * @param {any} _val
 */
const noop = (_val: unknown = null) => {};

/**
 * This function is helpful when validating a case
 * that should never occur by asserting that the provided
 * argument is of type `never`. The function will return call
 * to the `noop` function.
 *
 * @param {never} val
 * @returns {noop} noop
 * @example
 *
 * const cond = 'msg'
 * switch (cond) {
 *  case 'msg':
 *    operationWithMsg()
 *    break;
 *  default:
 *    assertNever(cond)
 * }
 *
 */
const assertNever = <T>(val: never) => {
  return noop(val) as unknown as T;
};

/**
 * Given a start and end numbers, returns an array with numbers in
 * from start up to, but not including, end.
 *
 * @param {number} start Start of the range
 * @param {number} end End of the range
 * @returns {Array} range of numbers
 */
const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, i) => start + i);

function removeNullValues<T extends AnyObject>(obj: T): NonNullable<T> {
  const nonNullKeys = Object.keys(obj).filter((k) => obj[k] != null);

  const newObj = {} as AnyObject;
  nonNullKeys.forEach((k) => {
    newObj[k] = obj[k];
  });

  return newObj as NonNullable<T>;
}

/**
 * Given an AxiosError object, return the api response
 * error message or the default one.
 * @param error Error thrown by axios
 * @returns ApiError data
 */
function extractApiErrorMessage(error: unknown | Error) {
  const err = error as AxiosError<ApiError | string>;
  const errData = err.response?.data;

  // Handle blob response type error
  if (
    err.request.responseType === "blob" &&
    err.response?.data instanceof Blob &&
    err.response?.data.type &&
    err.response?.data.type.toLowerCase().indexOf("json") !== -1
  ) {
    return "Something went wrong.";
  }

  if (!errData) {
    return (error as Error).message;
  }

  if (typeof errData === "string") {
    return errData;
  }

  return errData.ErrorMessage || errData.Message;
}

/**
 * Given an AxiosError object, return the api data
 * and status code
 * @param error Error thrown by axios
 * @returns ApiError data
 */
function extractApiErrorData<T>(error: unknown | Error) {
  const err = error as AxiosError<ApiError | string>;

  return {
    // eslint-disable-next-line
    data: ((err.response?.data as any)?.Data || null) as T,
    statusCode: err.response?.status || null,
  };
}

/**
 * Checks if the given index is the last in the given
 * array
 * @param index Array index
 * @param arr Array
 * @returns {boolean}
 */
function isLastIndex<T>(index: number, arr: T[]) {
  return arr.length - 1 === index;
}

/**
 * Given a date returns the same date as an UTC date
 * @param date Date
 * @returns date as UTC date
 */
function getUTCDate(date: Date) {
  const datetime = new Date();
  datetime.setUTCFullYear(date.getFullYear());
  datetime.setUTCMonth(date.getMonth());
  datetime.setUTCDate(date.getDate());
  datetime.setUTCHours(date.getHours());
  datetime.setUTCMinutes(date.getMinutes());

  return datetime;
}

function zip<T, K>(a: T[], b: K[]): [T, K][] {
  if (a.length < b.length) {
    return a.map((x, index) => [x, b[index]]);
  }

  return b.map((x, index) => [a[index], x]);
}

function appendHashToURL(url: string) {
  return `${url}?${Date.now()}`;
}

function isValidImageBlob(blob: Blob) {
  return /image\/{1}.+/.test(blob.type);
}

function getFileUrl(file?: Nullable<string | File | Blob>) {
  if (!file) {
    return "";
  }

  if (typeof file === "string") {
    return file;
  }

  return window.URL.createObjectURL(file);
}

function isImage(mimeType: string) {
  const mime = mimeType.split("/");
  return mime && mime[0] && mime[0] === "image";
}

function onFormRouteChange(
  isDirty: boolean,
  location: Location,
  basePath: string
) {
  // if form is not dirty allow navigation
  if (!isDirty) {
    return true;
  }
  // Check if new location is subroute of patient registration
  const isSubroute = matchPath(location.pathname, basePath);
  // If navigating to subroute, allow navigation
  if (isSubroute) {
    return !!isSubroute;
  }

  // Otherwise, show prompt
  return formMessages.unsavedChanges;
}

function getObjectFieldPath<T extends AnyObject>(path: RecursiveKeyOf<T>) {
  return `/${path.split(".").join("/")}`;
}

/**
 * Function to convert a timer string with
 * hh:mm format to milliseconds
 * @param timer Timer string in hh:mm format
 * @returns timer in ms
 */
function timerToMs(timer: string) {
  const xs = timer.split(":");

  // Timer must have exactly two elements
  if (xs.length !== 2) {
    return 0;
  }

  const [hours, minutes] = xs;

  // hours and minutes must be valid numbers
  if (Number.isNaN(Number(hours)) || Number.isNaN(Number(minutes))) {
    return 0;
  }

  const hoursInMs = Number(hours) * 60 * 60 * 1000;
  const minutesInMs = Number(minutes) * 60 * 1000;

  return hoursInMs + minutesInMs;
}

function msToTimer(millisec: number) {
  const seconds = Math.floor(millisec / 1000);
  const minutes = Math.floor(seconds / 60);

  if (minutes > 59) {
    const hours = Math.floor(minutes / 60);
    const hrStr = hours >= 10 ? String(hours) : `0${hours}`;
    const newMinutes = minutes - hours * 60;
    const minStr = newMinutes < 10 ? `0${newMinutes}` : String(newMinutes);

    return `${hrStr}:${minStr}`;
  }

  const minStr = minutes < 10 ? `0${minutes}` : String(minutes);
  return `0:${minStr}`;
}

function msToTimeComponents(millisec: number) {
  const seconds = Math.floor(millisec / 1000);
  const secondsLeft = seconds % 60;
  const minutes = Math.floor(seconds / 60);
  const minutesLeft = minutes % 60;
  const hours = Math.floor(minutes / 60);

  return { hours, minutes: minutesLeft, seconds: secondsLeft };
}

function formatTimer(value: string) {
  const [hrs, min] = value.split(":");

  if (!hrs || !min) {
    return "0:00";
  }

  if (hrs.length > 1) {
    return `${hrs.slice(1)}:${min}`;
  }

  return `${hrs}:${min}`;
}

export {
  noop,
  assertNever,
  range,
  removeNullValues,
  extractApiErrorData,
  extractApiErrorMessage,
  isLastIndex,
  getUTCDate,
  zip,
  appendHashToURL,
  getFileUrl,
  onFormRouteChange,
  getObjectFieldPath,
  timerToMs,
  msToTimer,
  msToTimeComponents,
  formatTimer,
  isValidImageBlob,
  isImage,
};
