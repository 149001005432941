import {
  Box,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useToast, useYupValidationResolver } from "hooks";
import { useForm } from "react-hook-form";
import { Button, Input, Textarea, ToolbarHeader } from "shared";
import { CreateFaqPayload, useCreateFaq, useUpdateFaq } from "../api";
import * as Yup from "yup";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { FaqDto } from "../api/queries";

type FaqFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  faq?: FaqDto;
};

const FAQ_FORM_SCHEMA = Yup.object().shape({
  question: Yup.string().required(),
  answer: Yup.string().required(),
});

const FormModalContent = ({ faq, onClose }: FaqFormModalProps) => {
  const toast = useToast();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateFaqPayload>({
    resolver: useYupValidationResolver(FAQ_FORM_SCHEMA),
    defaultValues: {
      question: faq?.question ?? "",
      answer: faq?.answer ?? "",
    },
    mode: "onChange",
  });

  const { mutateAsync: createFaq, isLoading: isCreateFaqLoading } =
    useCreateFaq();

  const { mutateAsync: updateFaq, isLoading: isUpdateFaqLoading } =
    useUpdateFaq(faq?.id ?? "");

  const onSubmit = async () => {
    try {
      if (faq?.id) {
        await updateFaq(getValues());
      } else {
        await createFaq(getValues());
      }

      toast({
        status: "success",
        description: formMessages.createSuccess("FAQ"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="17px" fontWeight="600">
              Frequently Asked Question
            </chakra.span>
          }
          leftButtons={[
            <Button
              key="addBtn"
              fontSize="16px"
              fontWeight="500"
              onClick={onClose}
            >
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="cancelBtn"
              fontSize="16px"
              fontWeight="500"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isCreateFaqLoading || isUpdateFaqLoading}
              isLoading={isCreateFaqLoading || isUpdateFaqLoading}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="20px" h="calc(100% - 60px)">
        <VStack gap={2} h="100%">
          <Box width="100%">
            <chakra.span
              fontSize="15px"
              fontWeight="500"
              color="gray.600"
              pl={2}
            >
              Question *
            </chakra.span>
            <Input
              autoComplete="off"
              placeholder="Type question here"
              backgroundColor="white"
              borderRadius="10px"
              _placeholder={{ color: "gray.500" }}
              {...register("question")}
            />
          </Box>
          <Box width="100%" h="calc(100% - 100px)">
            <chakra.span
              fontSize="15px"
              fontWeight="500"
              color="gray.600"
              pl={2}
            >
              Answer *
            </chakra.span>
            <Textarea
              placeholder="Type answer here"
              backgroundColor="white"
              borderRadius="10px"
              _placeholder={{ color: "gray.500" }}
              resize="none"
              height="100%"
              maxH="unset"
              {...register("answer")}
            />
          </Box>
        </VStack>
      </ModalBody>
    </>
  );
};

const FaqFormModal = (props: FaqFormModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="1020px"
        height="880px"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <FormModalContent {...props} />
      </ModalContent>
    </Modal>
  );
};

export { FaqFormModal };
