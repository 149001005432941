import { Icon } from "@chakra-ui/react";
import { EmrRadiology } from "@medstonetech/slate-icons";
import { useChartTrackingContext } from "contexts";
import { OrderStatus } from "enums";
import { useToast } from "hooks";
import { formMessages } from "messages";
import {
  ChartRouteBaseParams,
  OrderChartSectionHeader,
  useSaveAndNext,
} from "modules/charts-shared";
import { ChartForm } from "modules/charts-shared-v2";
import {
  useOrderChartRadiology,
  useUpdateOrderChartRadiology,
} from "modules/provider/api";
import {
  RadiologyChartForm,
  RadiologyChartTable,
  RadiologyCounters,
} from "modules/provider/shared";
import {
  formToPayload,
  responseToForm,
} from "modules/provider/shared/radiology-chart/utils";
import { BaseProviderChartProps } from "modules/provider/types";
import React, { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Loading } from "shared";
import { FormPromptWithSaveProps } from "shared/prompt";
import { extractApiErrorMessage } from "utils";

const sectionCode = "SRAD";

type RadiologyChartProps = {
  HideInterpretations?: boolean;
} & BaseProviderChartProps;

function RadiologyChart(props: RadiologyChartProps) {
  const { chartCode, isReadOnly } = props;

  const { isPastEncounter } = useChartTrackingContext();
  const isReadOnlyOrPastEncounter = isReadOnly || isPastEncounter;

  const toast = useToast();

  const [filter, setFilter] = useState<OrderStatus | null>(null);
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<RadiologyChartForm>();
  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isDirty },
  } = useFormContext;

  const { data, isLoading } = useOrderChartRadiology({
    encounterId,
    chartCode,
    orderType: "Radiology",
  });

  React.useEffect(() => {
    if (data) {
      reset(responseToForm(data.data));
    }
  }, [data, reset]);

  const { mutateAsync: updateChart, isLoading: isUpdating } =
    useUpdateOrderChartRadiology({
      encounterId,
      chartCode,
      orderType: "Radiology",
    });

  const saveData = React.useCallback(
    async (values: RadiologyChartForm) => {
      const payload = formToPayload(values);
      await updateChart(payload);
    },
    [updateChart]
  );

  const onSubmit: SubmitHandler<RadiologyChartForm> = React.useCallback(
    async (values) => {
      try {
        if (values.rad) {
          await saveData(values);
        }
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Radiology") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [saveData, reset, toast]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "radiology",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await saveData(getValues());
      toast({ description: formMessages.updateSuccess("Radiology") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const items = watch("rad");
  const filteredItems = React.useMemo(
    () =>
      items
        ? items
            .map((item, index) => ({ ...item, index }))
            .filter((item) => !filter || item.status === filter)
        : [],
    [items, filter]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={isUpdating}
        disabled={isReadOnly}
      >
        <OrderChartSectionHeader
          icon={<Icon as={EmrRadiology} />}
          chartId={chartCode}
          chartCode={chartCode}
          orderType="Radiology"
          encounterId={encounterId}
          showNotesBtn
          showActionsBtns
          isLoading={isUpdating}
          onSaveAndNext={handleSaveAndNext}
        >
          Radiology {isReadOnly ? "(Read Only)" : ""}
        </OrderChartSectionHeader>

        <RadiologyCounters filter={filter} setFilter={setFilter} />

        <RadiologyChartTable
          chartCode={chartCode}
          sectionCode={sectionCode}
          items={items}
          filteredItems={filteredItems}
          isReadOnly={isReadOnlyOrPastEncounter}
          hideInterpretations
        />
      </ChartForm>
    </FormProvider>
  );
}

export { RadiologyChart };
