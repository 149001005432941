import * as React from "react";

export function useSyncScroll<T extends HTMLElement, K extends HTMLElement>(
  leftRef: React.MutableRefObject<T | null>,
  rightRef: React.MutableRefObject<K | null>
) {
  const isSyncingLeftScroll = React.useRef(false);
  const isSyncingRightScroll = React.useRef(false);

  const onScrollLeft: React.DOMAttributes<T>["onScroll"] =
    React.useCallback(() => {
      if (!leftRef.current || !rightRef.current) {
        return;
      }

      if (!isSyncingLeftScroll.current) {
        isSyncingRightScroll.current = true;
        rightRef.current.scrollTo({ top: leftRef.current.scrollTop });
        rightRef.current.scrollTo({ left: leftRef.current.scrollLeft });
      }

      isSyncingLeftScroll.current = false;
    }, [leftRef, rightRef]);

  const onScrollRight: React.DOMAttributes<K>["onScroll"] =
    React.useCallback(() => {
      if (!leftRef.current || !rightRef.current) {
        return;
      }

      if (!isSyncingRightScroll.current) {
        isSyncingLeftScroll.current = true;
        leftRef.current.scrollTo({ top: rightRef.current.scrollTop });
        leftRef.current.scrollTo({ left: rightRef.current.scrollLeft });
      }

      isSyncingRightScroll.current = false;
    }, [leftRef, rightRef]);

  const value = React.useMemo(
    () => ({ onScrollLeft, onScrollRight }),
    [onScrollLeft, onScrollRight]
  );

  return value;
}
