import Konva from "konva";
import * as React from "react";
import { Arrow, KonvaNodeEvents } from "react-konva";
import { ArrowConfig } from "./types";
import { WithTransformer, WithTransformerProps } from "./WithTransformer";

type TransformerArrowProps = ArrowConfig &
  KonvaNodeEvents & { isSelected: boolean } & Pick<
    WithTransformerProps,
    "dispatch"
  >;

function TransformerArrow(props: TransformerArrowProps) {
  const { isSelected, dispatch, ...arrowProps } = props;
  const arrowRef = React.useRef<Konva.Arrow>(null);

  const onTransformEnd = React.useCallback(() => {
    const node = arrowRef.current;

    if (!node) {
      return;
    }

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    const rotation = node.rotation();
    const points = node.points();
    const id = node.id();

    dispatch({
      type: "EDIT_SHAPE",
      payload: {
        rotation,
        points: points.map((element, index) =>
          index % 2 === 0 ? element * scaleX : element * scaleY
        ),
        id,
      },
    });
  }, [dispatch]);

  return (
    <WithTransformer
      isSelected={isSelected}
      shapeRef={arrowRef}
      dispatch={dispatch}
      enabledAnchors={["middle-left", "middle-right"]}
      flipEnabled={false}
      onTransformEnd={onTransformEnd}
    >
      <Arrow ref={arrowRef} {...arrowProps} points={arrowProps.points || []} />
    </WithTransformer>
  );
}

export type { TransformerArrowProps };
export { TransformerArrow };
