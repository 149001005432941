import { axiosPatientInstance } from "config";
import { Gender } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateEncounterPayload = {
  dob: Date;
  firstName: string;
  lastName?: string;
  cellphone: string;
  gender: Gender;
  ssn?: string;
  wristband?: string;
};

type CreatePatientResponse = {
  encounterId: string;
};

function useCreateEncounter() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createEncounter"],
    (payload: CreateEncounterPayload) =>
      axiosPatientInstance.post<CreatePatientResponse>("v1", payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries("searchPatient");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("patients", { refetchInactive: true });
        queryClient.invalidateQueries("receptionCounters");
        queryClient.invalidateQueries(["inProcessCounters"]);
      },
    }
  );
}

export { useCreateEncounter };
export type { CreateEncounterPayload };
