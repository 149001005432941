import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonSquared = createIcon({
  path: (
    <path
      d="M4.43096 24C1.56904 24 0 22.4435 0 19.5941V4.40586C0 1.55649 1.56904 0 4.43096 0H19.569C22.4435 0 24 1.55649 24 4.40586V19.5941C24 22.4435 22.4435 24 19.569 24H4.43096ZM12.0126 14.3849C14.3975 14.3975 16.2301 12.364 16.2301 9.77824C16.2301 7.33054 14.3724 5.24686 12.0126 5.24686C9.64017 5.24686 7.78243 7.33054 7.78243 9.77824C7.79498 12.364 9.62761 14.3724 12.0126 14.3849ZM4.35565 20.8494H19.6695C18.5649 18.1757 15.5649 16.3305 12.0126 16.3305C8.4477 16.3305 5.4477 18.1757 4.35565 20.8494Z"
      fill="currentColor"
    />
  ),
  viewBox: "-2 2 26 20",
});

export { PersonSquared };
