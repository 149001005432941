import { differenceInMonths, lastDayOfMonth } from "date-fns";

function dateWithoutTimezone(dateString: string) {
  return new Date(dateString.replace("Z", ""));
}

function calculateAge(date: string | Date) {
  const dob = new Date(date);
  let currentDate = new Date();

  if (dob.getMonth() !== currentDate.getMonth())
    currentDate = lastDayOfMonth(currentDate);

  const diffMonths = differenceInMonths(dob, currentDate);
  const yearsBirthday = Math.abs(Math.trunc(diffMonths / 12));
  const monthsBirthday = Math.abs(diffMonths % 12);
  return { yearsBirth: yearsBirthday, monthsBirth: monthsBirthday };
}

export { dateWithoutTimezone, calculateAge };
