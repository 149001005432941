import { differenceInMilliseconds } from "date-fns";
import * as React from "react";

/**
 * Hook for handling a countdown given a start date
 * @param start Countdown initial date
 * @param secondsInterval The interval time in seconds of the countdown
 * @returns Remaining time in ms
 */
function useCountdown(start: Date, secondsInterval = 5) {
  const intervalIdRef = React.useRef<number | null>(0);
  const [countdown, setCountdown] = React.useState(0);

  // If countdown reaches 0 or less clear the interval
  React.useEffect(() => {
    if (countdown <= 0 && intervalIdRef.current) {
      window.clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [countdown]);

  React.useEffect(() => {
    const ms = Math.round(differenceInMilliseconds(start, Date.now()));
    setCountdown(ms);

    // Set interval
    intervalIdRef.current = window.setInterval(() => {
      setCountdown(() => {
        const diff = start.getTime() - Date.now();

        if (diff < 0) {
          return 0;
        }

        return diff;
      });
    }, 1000 * secondsInterval);

    return () => {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [start, secondsInterval]);

  if (countdown < 0) {
    return 0;
  }

  return countdown;
}

export { useCountdown };
