import * as React from "react";
import { EmrEyeVisible, EmrEyeHidden } from "@medstonetech/slate-icons";
import { InputGroup, InputRightElement, Icon } from "@chakra-ui/react";
import { Input, InputProps } from "./Input";

const InputPassword = React.forwardRef<
  HTMLInputElement,
  Omit<InputProps, "size">
>((props, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <InputGroup>
      <Input
        type={showPassword ? "text" : "password"}
        variant="outline"
        ref={ref}
        paddingRight="44px"
        {...props}
      />
      <InputRightElement>
        <Icon
          as={showPassword ? EmrEyeVisible : EmrEyeHidden}
          color="#A2A2A2"
          w="26.2px"
          h="25px"
          marginTop="3"
          marginRight="3"
          onClick={() => setShowPassword(!showPassword)}
          role="button"
        />
      </InputRightElement>
    </InputGroup>
  );
});

InputPassword.displayName = "InputPassword";

export { InputPassword };
