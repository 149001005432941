import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S018Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q018.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
          subsectionLabel="Reduction of Shoulder Dislocation"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Reduction of Shoulder Dislocation"
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q018A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q018A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Procedural Sedation:"
                name={`${sectionDelimiter}Q018A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q018A004`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="traction"
                name={`${sectionDelimiter}Q018A005`}
                type="option"
              />
              <ChartRowElement
                label="counter traction"
                name={`${sectionDelimiter}Q018A006`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q018A007`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q018A008`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q018A009`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="kocher maneuver"
                name={`${sectionDelimiter}Q018A010`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q018A011`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q018A012`}
                type="option"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="scapular manipulation"
                name={`${sectionDelimiter}Q018A013`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q018A014`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q018A015`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q018A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="weight"
                name={`${sectionDelimiter}Q018A017`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q018A018`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="lbs"
                name={`${sectionDelimiter}Q018A019`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q018A020`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q018A021`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S018Procedure };
