import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateAmbulatoryNotePayload = {
  note: string;
  patientVitalCheckId: string;
};

type UseCreateAmbulatoryNoteParams = {
  encounterId: string;
};

function useCreateAmbulatoryNote({
  encounterId,
}: UseCreateAmbulatoryNoteParams) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createAmbulatoryNote", encounterId],
    (input: UseCreateAmbulatoryNotePayload) =>
      axiosChartInstance.post(`v1/ambulatory-note/${encounterId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ambulatoryNotes", encounterId]);
      },
    }
  );
}

export { useCreateAmbulatoryNote };
