import {
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { EmrBed, EmrRoomCard } from "@medstonetech/slate-icons";
import {
  Cleaning,
  ClosedRoom,
  Edit,
  Maintenance,
  PersonFront,
  PersonSquared,
} from "icons";
import RoomList from "modules/room-list";
import { Button, InfiniteList, ToolbarHeader } from "shared";
import * as CSS from "csstype";
import { useToast } from "hooks";
import { RoomFilter, useRoomCounters, useRoomsDetails } from "../api";
import React, { useState } from "react";
import { BedDto, RoomDto } from "modules/room-list/api";
import { extractApiErrorMessage } from "utils";
import { RoomManagementDetailsModal } from "./RoomManagementDetails";

type RoomManagementModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const RoomManagementHeader = ({ onClose }: RoomManagementModalProps) => {
  const {
    isOpen: isRoomListOpen,
    onClose: onRoomListClose,
    onOpen: onRoomListOpen,
  } = useDisclosure();

  return (
    <ModalHeader
      minH="80px"
      bgColor="gray.200"
      alignContent="center"
      borderBottom="1px solid"
      borderColor="gray.450"
    >
      <ToolbarHeader
        titleText={
          <Box display="flex" alignContent="center">
            <Icon as={EmrRoomCard} mr={2} h="24px" w="24px" color="gray.650" />
            <chakra.span fontSize="17px" fontWeight="600">
              Room Management
            </chakra.span>
          </Box>
        }
        leftButtons={[
          <Button
            key="editRooms"
            fontSize="16px"
            fontWeight="500"
            onClick={onRoomListOpen}
          >
            <Icon as={Edit} fontSize="1rem" color="blue" mr={2} />
            Edit Rooms
          </Button>,
        ]}
        rightButtons={[
          <Button
            key="cancelBtn"
            fontSize="16px"
            fontWeight="500"
            onClick={onClose}
          >
            Done
          </Button>,
        ]}
      />
      <RoomList isOpen={isRoomListOpen} onClose={onRoomListClose} />
    </ModalHeader>
  );
};

type FilterButtonProps = {
  icon: React.ReactNode;
  label: string;
  count: number;
  action: () => void;
  color: CSS.Property.Color;
  active: boolean;
};

const FilterButton = ({
  icon,
  label,
  count,
  color,
  action,
  active,
}: FilterButtonProps) => {
  return (
    <Box
      w="308px"
      h="65px"
      border="1px solid"
      borderColor={active ? color : "white"}
      display="flex"
      justifyContent="space-between"
      borderRadius="10px"
      alignItems="center"
      bgColor={active ? color : "white"}
      onClick={action}
      sx={{ cursor: "pointer" }}
      boxShadow="0px 4px 4px 2px rgba(0,0,0,0.1);"
    >
      <Box
        p={3}
        display="flex"
        justifyContent="start"
        gap={4}
        alignContent="center"
      >
        <Box
          w="30px"
          h="30px"
          sx={{ ">svg": { color: !active ? color : "white" } }}
        >
          {icon}
        </Box>
        <chakra.span
          fontSize="21px"
          fontWeight="600"
          alignContent="center"
          color={!active ? color : "white"}
        >
          {label}
        </chakra.span>
      </Box>

      <chakra.span
        fontSize="24px"
        fontWeight="700"
        alignContent="center"
        px={3}
        color={!active ? color : "white"}
      >
        {count}
      </chakra.span>
    </Box>
  );
};

type BedItemProps = {
  bed: BedDto;
  isClosedRoom: boolean;
};

const BedItem = ({ bed, isClosedRoom }: BedItemProps) => {
  return (
    <Box>
      <VStack>
        <chakra.span fontSize="17px" fontWeight="700">
          {bed.name}
        </chakra.span>
        <Box
          w="40px"
          h="40px"
          borderRadius="5px"
          bgColor={
            bed.status === "Cleaning"
              ? "purple"
              : !!bed.encounterId
              ? "blue"
              : isClosedRoom
              ? "gray.700"
              : "green"
          }
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icon
            as={
              bed.status === "Cleaning"
                ? Cleaning
                : !!bed.encounterId
                ? PersonFront
                : EmrBed
            }
            h="60%"
            w="60%"
            color="white"
          />
        </Box>
        <chakra.span fontSize="17px" fontWeight="700">
          {bed.encounter ? bed.encounter.lastName : ""}
        </chakra.span>
      </VStack>
    </Box>
  );
};

type RoomItemProps = {
  room: RoomDto;
};

const RoomItem = ({ room }: RoomItemProps) => {
  const RoomManagementDetailsDisclosure = useDisclosure();
  return (
    <Box
      bgColor={room.status === "Closed" ? "gray.450" : "white"}
      borderRadius="10px"
      mb={5}
      w="390px"
      h="180px"
      p={4}
      onClick={RoomManagementDetailsDisclosure.onOpen}
      cursor="pointer"
      boxShadow="0px 4px 4px 2px rgba(0,0,0,0.1);"
    >
      <Box display="flex" justifyContent="space-between">
        <Box w="40px" h="40px" />
        <chakra.span fontSize="32px" fontWeight="600">
          {room.name}
        </chakra.span>
        <Box w="40px" h="40px">
          {room.isMaintenance && (
            <Box
              w="40px"
              h="40px"
              bgColor="red"
              borderRadius="25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={Maintenance} h="60%" w="60%" color="white" />
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        {room.beds.map((bed) => (
          <BedItem
            key={bed.id}
            bed={bed}
            isClosedRoom={room.status === "Closed"}
          />
        ))}
      </Box>
      <RoomManagementDetailsModal
        roomId={room.id}
        {...RoomManagementDetailsDisclosure}
      />
    </Box>
  );
};

const RoomManagementContent = () => {
  const toast = useToast();
  const [activeFilter, setActiveFilter] = useState<RoomFilter | undefined>(
    undefined
  );

  const { data: counterData } = useRoomCounters();

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetching,
    hasNextPage,
    error,
    refetch,
  } = useRoomsDetails({
    size: 20,
    filter: activeFilter,
    bedStatus: "Active",
  });

  const rows = React.useMemo(
    () =>
      data?.pages.reduce<RoomDto[]>(
        (accum, curr) => [...accum, ...curr.data.content],
        []
      ) || [],
    [data]
  );

  const fetchMore = React.useCallback(async () => {
    try {
      if (!isLoading && !isFetching) {
        await fetchNextPage();
      }
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast, isLoading, isFetching]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <FilterButton
          icon={<Icon as={EmrBed} h="100%" w="100%" />}
          label="Open Beds"
          count={counterData?.data.openBeds ?? 0}
          action={() => {
            if (activeFilter === "Open") {
              setActiveFilter(undefined);
              refetch();
            } else {
              setActiveFilter("Open");
              refetch();
            }
          }}
          color="green"
          active={!activeFilter || activeFilter === "Open"}
        />
        <FilterButton
          icon={<Icon as={PersonSquared} h="100%" w="100%" />}
          label="Occupied Beds"
          count={counterData?.data.assignedBeds ?? 0}
          action={() => {
            if (activeFilter === "Occupied") {
              setActiveFilter(undefined);
              refetch();
            } else {
              setActiveFilter("Occupied");
              refetch();
            }
          }}
          color="blue"
          active={!activeFilter || activeFilter === "Occupied"}
        />
        <FilterButton
          icon={<Icon as={Cleaning} h="100%" w="100%" />}
          label="Cleaning"
          count={counterData?.data.cleaningBeds ?? 0}
          action={() => {
            if (activeFilter === "Cleaning") {
              setActiveFilter(undefined);
              refetch();
            } else {
              setActiveFilter("Cleaning");
              refetch();
            }
          }}
          color="purple"
          active={!activeFilter || activeFilter === "Cleaning"}
        />
        <FilterButton
          icon={<Icon as={Maintenance} h="100%" w="100%" />}
          label="Maintenance"
          count={counterData?.data.maintenanceRooms ?? 0}
          action={() => {
            if (activeFilter === "Maintenance") {
              setActiveFilter(undefined);
              refetch();
            } else {
              setActiveFilter("Maintenance");
              refetch();
            }
          }}
          color="red"
          active={!activeFilter || activeFilter === "Maintenance"}
        />
        <FilterButton
          icon={<Icon as={ClosedRoom} h="100%" w="100%" />}
          label="Closed Rooms"
          count={counterData?.data.closedRooms ?? 0}
          action={() => {
            if (activeFilter === "Closed") {
              setActiveFilter(undefined);
              refetch();
            } else {
              setActiveFilter("Closed");
              refetch();
            }
          }}
          color="gray.450"
          active={!activeFilter || activeFilter === "Closed"}
        />
      </Box>
      <Box py={4} w="1620px" maxW="1620px" h="inherit">
        <InfiniteList
          fetchMore={fetchMore}
          hasMore={!!hasNextPage}
          isLoading={isLoading || isFetching}
          renderRow={(room) => <RoomItem room={room} />}
          rows={rows}
          isGrid
        />
      </Box>
    </>
  );
};

const RoomManagementModal = (props: RoomManagementModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        bg="gray.50"
        maxW="unset"
        width="1660px"
        height="95%"
        margin="auto"
        overflow="hidden"
        borderRadius="20px"
      >
        <RoomManagementHeader {...props} />
        <ModalBody p="20px" height="inherit">
          <RoomManagementContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { RoomManagementModal };
