import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Clip = createIcon({
  path: (
    <path
      d="M15.2118 10.4327L8.35161 17.2929C6.56889 19.0849 4.17016 18.9076 2.63944 17.3582C1.0994 15.8275 0.922059 13.4474 2.70478 11.6554L12.0664 2.30311C13.1398 1.22974 14.7171 1.08041 15.7438 2.09777C16.7612 3.1338 16.6119 4.70185 15.5478 5.77521L6.40089 14.9221C5.94354 15.3982 5.38352 15.2582 5.06618 14.9408C4.74884 14.6141 4.61817 14.0728 5.07552 13.5968L11.469 7.22192C11.749 6.93258 11.7677 6.5219 11.497 6.25123C11.2264 5.98988 10.8157 6.00855 10.5357 6.28856L4.12349 12.7007C3.17146 13.6528 3.20879 15.1088 4.04882 15.9488C4.96351 16.8635 6.34489 16.8449 7.30625 15.8835L16.4905 6.69924C18.2359 4.95385 18.1612 2.65779 16.6399 1.13641C15.1558 -0.347636 12.8224 -0.45964 11.077 1.28575L1.66875 10.7034C-0.636654 13.0088 -0.459316 16.3035 1.62208 18.3849C3.70347 20.457 6.99824 20.6343 9.30364 18.3382L16.2105 11.4314C16.4812 11.1607 16.4812 10.666 16.2012 10.414C15.9305 10.1247 15.4918 10.162 15.2118 10.4327Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 20",
});

export { Clip };
