import { useToast } from "hooks";
import * as React from "react";

type RecorderState = {
  minutes: number;
  seconds: number;
  initRecording: boolean;
  mediaStream?: MediaStream;
  mediaRecorder?: MediaRecorder;
  audio?: Blob;
};

type UseRecorderProps = Partial<{
  maxMinutesRecord: number;
}>;

const INITIAL_STATE: RecorderState = {
  minutes: 0,
  seconds: 0,
  initRecording: false,
};

const MAX_MINUTES_RECORD = 5;

function useRecorder(props: UseRecorderProps = {}) {
  const { maxMinutesRecord = MAX_MINUTES_RECORD } = props;
  const [state, setState] = React.useState(INITIAL_STATE);
  const toast = useToast();

  /**
   * This function initializes the stream object if the user gives the browser
   * permission to record, otherwise shows an error toast.
   */
  const startRecording = React.useCallback(async () => {
    try {
      if (navigator.mediaDevices) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setState((prevState) => ({
          ...prevState,
          mediaStream: stream,
          initRecording: true,
        }));
      }
    } catch (error) {
      toast({ description: (error as Error).message });
    }
  }, [toast]);

  const saveRecording = React.useCallback(
    () => state.mediaRecorder?.stop(),
    [state]
  );

  const cancelRecording = React.useCallback(() => setState(INITIAL_STATE), []);

  /**
   * Initiliaze the mediaRecorder object
   */
  React.useEffect(() => {
    if (state.mediaStream) {
      setState((prevState) => ({
        ...prevState,
        mediaRecorder:
          state.mediaStream && new MediaRecorder(state.mediaStream),
      }));
    }
  }, [state.mediaStream]);

  /**
   * Once mediaRecorder is instantiated, start recording and assign
   * the ondataavailable and onstop events to the recorder object. The cleanup
   * function stops the previous recorder instance if the instance changes.
   */
  React.useEffect(() => {
    const recorder = state.mediaRecorder;
    let chunks: Blob[] = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();

      recorder.ondataavailable = (e) => chunks.push(e.data);

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: recorder.mimeType });
        chunks = [];

        setState((prevState) => {
          if (prevState.mediaRecorder) {
            return {
              ...INITIAL_STATE,
              audio: blob,
            };
          }

          return INITIAL_STATE;
        });
      };
    }

    return () => {
      if (recorder) {
        recorder.stream.getAudioTracks().forEach((track) => track.stop());
      }
    };
  }, [state.mediaRecorder]);

  /**
   * Create an interval for updating the minutes and seconds recorded so far.
   * The cleanup function clears the interval if the recording stops.
   */
  React.useEffect(() => {
    let interval: number;

    if (state.initRecording) {
      interval = window.setInterval(() => {
        setState((prevState) => {
          const { minutes, seconds } = prevState;

          if (seconds === 59) {
            return {
              ...prevState,
              minutes: minutes + 1,
              seconds: 0,
            };
          }
          return {
            ...prevState,
            seconds: seconds + 1,
          };
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [state.initRecording]);

  /**
   * Stop and save recording if the user reaches the time limit
   */
  React.useEffect(() => {
    if (state.minutes >= maxMinutesRecord && state.initRecording) {
      saveRecording();
    }
  }, [state.minutes, state.initRecording, saveRecording, maxMinutesRecord]);

  return { state, startRecording, saveRecording, cancelRecording };
}

export { useRecorder };
