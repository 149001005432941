import { Box, Button, ButtonProps, HStack, Icon } from "@chakra-ui/react";
import { config } from "config";
import { RightArrow } from "icons";
import * as React from "react";

type ModalInputButtonProps = {
  icon: React.ReactNode;
  label: string;
  value: string;
} & Omit<ButtonProps, "children">;

const ModalInputButton = React.forwardRef<
  HTMLButtonElement,
  ModalInputButtonProps
>((props, ref) => {
  const { icon, label, value, ...btnProps } = props;

  return (
    <Button
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      variant="label"
      width="510px"
      padding="1rem"
      ref={ref}
      {...btnProps}
    >
      <HStack spacing="1rem">
        <Box w="1.25rem" h="1.25rem">
          {icon}
        </Box>
        <Box fontSize="1.0625rem" color="gray.650">
          {label}
        </Box>
      </HStack>
      <HStack spacing="1rem">
        <Box color="blue">{value}</Box>
        <Icon as={RightArrow} fontSize="0.875rem" color="gray.450" />
      </HStack>
    </Button>
  );
});

if (config.isDev) {
  ModalInputButton.displayName = "ModalInputButton";
}

export type { ModalInputButtonProps };
export { ModalInputButton };
