import { axiosPatientInstance } from "config";
import { SectionStatus } from "enums/encounter";
import { useAxiosQuery } from "hooks";

type EncounterFormStatusResponse = {
  patientInfo: SectionStatus;
  insurance: SectionStatus;
  consent: SectionStatus;
  payments: SectionStatus;
};

function useEncounterFormStatus(encounterId: string) {
  return useAxiosQuery(
    ["encounterFormStatus", { encounterId }],
    () =>
      axiosPatientInstance.get<EncounterFormStatusResponse>(
        `v1/encounter/${encounterId}/form/status`
      ),
    { staleTime: 0, cacheTime: 0 }
  );
}

export type { EncounterFormStatusResponse };
export { useEncounterFormStatus };
