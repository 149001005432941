import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T041IVPChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="IVP done:"
            fontStyle="italic"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="heart"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="pericardium"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="abdomen"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="obstruction"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement
            label="L"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement
            label="R"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="proximal"
            fontStyle="italic"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="mid"
            fontStyle="italic"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement
            label="distal ureter"
            fontStyle="italic"
            name={`${sectionDelimiter}A019`}
            type="option"
          />
          <ChartRowElement
            label="UVJ"
            fontStyle="italic"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement
            label="mild"
            fontStyle="italic"
            name={`${sectionDelimiter}A021`}
            type="option"
          />
          <ChartRowElement
            label="mod"
            fontStyle="italic"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement
            label="marked"
            fontStyle="italic"
            name={`${sectionDelimiter}A023`}
            type="option"
          />
          <ChartRowElement
            label="stone"
            fontStyle="italic"
            name={`${sectionDelimiter}A024`}
            type="option"
          />
          <ChartRowElement
            name={`${sectionDelimiter}A025`}
            type="input"
            w="100px"
          />
          <ChartRowElement
            label="mm"
            fontStyle="italic"
            name={`${sectionDelimiter}A026`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A027`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A028`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function IVPChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C041x: <T041IVPChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { IVPChart };
