import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateOrderChartNoteParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
};

type OrderChartNotePayload = {
  note: string;
};

function useCreateOrderChartNote(params: CreateOrderChartNoteParams) {
  const { encounterId, orderType } = params;
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createOrderChartNote", params],
    (payload: OrderChartNotePayload) =>
      axiosChartInstance.put(`v1/order/${encounterId}/note`, payload, {
        params: { orderType },
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["orderChartNotes", params]),
    }
  );
}

export { useCreateOrderChartNote };
