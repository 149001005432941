import React, { forwardRef } from "react";
import {
  Box,
  List,
  ListProps,
  StylesProvider,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { useFixedPosition } from "hooks";

type DropdownMenuProps = ListProps & {
  respectiveToRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
};

const DropdownMenu = forwardRef<HTMLUListElement, DropdownMenuProps>(
  (props, ref) => {
    const { respectiveToRef, isOpen, children, ...rest } = props;
    const styles = useMultiStyleConfig("DropdownMenu", {});
    const { left, width, bottom, top, maxHeight } = useFixedPosition({
      respectiveToRef,
      isOpen,
    });

    return (
      <>
        {/* Backdrop */}
        {isOpen && (
          <Box
            width="100vw"
            height="100vh"
            position="fixed"
            zIndex="modal"
            top={0}
            left={0}
          />
        )}
        <List
          ref={ref}
          __css={styles.root}
          position="fixed"
          left={`${left}px`}
          width={`${width}px`}
          bottom={bottom ? `${bottom}px` : undefined}
          top={top ? `${top}px` : undefined}
          zIndex="modal"
          maxHeight={`${maxHeight}px`}
          {...rest}
        >
          <StylesProvider value={styles}>{children}</StylesProvider>
        </List>
      </>
    );
  }
);
DropdownMenu.displayName = "DropdownMenu";

export { DropdownMenu };
