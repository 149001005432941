import { axiosChartInstance } from "config";
import { ProgressNoteBy } from "enums/progress-noteBy";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartProgressItem } from "types";

type CreateChartProgressPayloadItem = Partial<Omit<ChartProgressItem, "id">>;

type UseCreateProgressNotesProps = {
  noteBy: ProgressNoteBy;
};

function useCreateProgressNote(props: UseCreateProgressNotesProps) {
  const { noteBy } = props;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createProgressNote"],
    (input: CreateChartProgressPayloadItem) =>
      axiosChartInstance.post<ChartProgressItem>(
        `v1/ProgressNote/${noteBy}`,
        input
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(["progressNotes"]),
    }
  );
}

export { useCreateProgressNote };
