import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { CreateFaqPayload } from "./useCreateFaq";

type UpdateFaqPayload = CreateFaqPayload;

function useUpdateFaq(id: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateFaq", { id }],
    (input: UpdateFaqPayload) =>
      axiosFacilityInstance.put(`/v1/faq/${id}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("faqs");
      },
    }
  );
}

export { useUpdateFaq };
