import {
  Box,
  Button,
  chakra,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CloseCircle } from "icons";
import { formMessages } from "messages";
import { ButtonCancelOrderModal } from "modules/orders";
import * as React from "react";
import { Card, StaffAvatar, ToolbarHeader } from "shared";
import { extractApiErrorMessage } from "utils";
import { OrderHistoryItem, usePutApproveOrder } from "../api";

type ScribeOrderActionsModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  order: OrderHistoryItem;
  encounterId: string;
};

function ScribeOrderActionsModalBody(props: ScribeOrderActionsModalProps) {
  const { order, onModalClose, encounterId } = props;
  const toast = useToast();

  const { mutateAsync: approveOrder, isLoading: isApproveLoading } =
    usePutApproveOrder({
      encounterId,
    });

  const onApproveOrder = async () => {
    try {
      await approveOrder(order.id);
      toast({ description: formMessages.updateSuccess("Order") });
      onModalClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <>
      <ModalHeader p="10px">
        <ToolbarHeader
          alignItems="center"
          h="40px"
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Order {order.orderId}
            </chakra.span>
          }
          rightButtons={[
            <IconButton
              key="closeBtn"
              size="iconSm"
              aria-label="Cancel"
              variant="ghost"
              h="40px"
              w="40px"
              onClick={onModalClose}
              icon={
                <Icon as={CloseCircle} color="gray.700" h="20px" w="20px" />
              }
            />,
          ]}
        />
      </ModalHeader>
      <ModalBody padding="15px 15px 35px 15px">
        <Card mb="60px" variant="default" border="none" boxShadow="none">
          <HStack spacing="30px" minH="60px">
            <Box flex="1" p="0 20px">
              Ordered by
            </Box>
            <HStack flex="1" p="6px 15px" spacing="15px">
              <StaffAvatar
                fontSize="1rem"
                userName={order?.orderedBy?.fullName || ""}
                profileUrl={order?.orderedBy?.pictureUrl || ""}
                width="2rem"
                height="2rem"
                size="md"
              />
              <VStack alignItems="start" spacing="0">
                <Box fontSize="1.0625rem" fontWeight="600" color="black">
                  {order.orderedBy?.fullName}
                </Box>
                <Box fontSize="0.875rem" fontWeight="400" color="gray.700">
                  {order.orderedBy?.teams}
                </Box>
              </VStack>
            </HStack>
          </HStack>
        </Card>
        <HStack spacing="30px">
          <ButtonCancelOrderModal
            orderId={order.id || ""}
            encounterId={encounterId}
            flex="1"
            onCancelSuccess={onModalClose}
            isDisabled={isApproveLoading}
          />
          <Button
            variant="outlineSquared"
            onClick={onApproveOrder}
            isLoading={isApproveLoading}
            flex="1"
          >
            Approve
          </Button>
        </HStack>
      </ModalBody>
    </>
  );
}

function ScribeOrderActionsModal(props: ScribeOrderActionsModalProps) {
  const { isModalOpen, onModalClose, order, encounterId } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <ScribeOrderActionsModalBody
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            order={order}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { ScribeOrderActionsModal };
