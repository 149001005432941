import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type ExportOrderChartNoteParams = {
  encounterId: string;
  chartCode: string;
  chartOrderSectionNote: string;
  isExportable: boolean;
  orderType: OrderGroup;
};

function useExportableOrderChartNote(params: ExportOrderChartNoteParams) {
  const { encounterId, chartOrderSectionNote, isExportable, orderType } =
    params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["exportOrderChartNote", params],
    () =>
      axiosChartInstance.put(
        `v1/order/${encounterId}/note/update-exportable/${chartOrderSectionNote}/${isExportable}`,
        null,
        {
          params: { orderType },
        }
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "orderChartNotes",
          { encounterId, orderType },
        ]),
    }
  );
}

export { useExportableOrderChartNote };
