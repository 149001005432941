import { Box, Button, Icon, useDisclosure } from "@chakra-ui/react";
import { SiPlus } from "@medstonetech/slate-icons";
import { ProcedureListModal } from "./ProcedureListModal";
import { PROCEDURE_SELECT_ITEM } from "./constants";

type ProceduresHeaderProps = {
  isReadOnly: boolean;
  onAddProcedure: (list: PROCEDURE_SELECT_ITEM[]) => void;
  onAddSedationNote: () => void;
};

function ProceduresHeader(props: ProceduresHeaderProps) {
  const { onAddProcedure, onAddSedationNote, isReadOnly } = props;

  const procedureListDisclose = useDisclosure();

  return (
    <Box display="flex" justifyContent="flex-end" columnGap="25px">
      <Button
        variant="ghost"
        color="blue"
        fontSize="1.0625rem"
        disabled={isReadOnly}
        leftIcon={
          <Icon
            borderRadius="1rem"
            color="white"
            backgroundColor="blue"
            as={SiPlus}
          />
        }
        onClick={procedureListDisclose.onOpen}
      >
        Procedures
      </Button>
      <Button
        variant="ghost"
        color="blue"
        fontSize="1.0625rem"
        disabled={isReadOnly}
        leftIcon={
          <Icon
            borderRadius="1rem"
            color="white"
            backgroundColor="blue"
            as={SiPlus}
          />
        }
        onClick={onAddSedationNote}
      >
        Sedation Notes
      </Button>

      <ProcedureListModal
        isOpen={procedureListDisclose.isOpen}
        onClose={procedureListDisclose.onClose}
        onDone={onAddProcedure}
      />
    </Box>
  );
}

export { ProceduresHeader };
