import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { SelectFilter, SelectFilterProps } from "shared";
import { useMergeRefs } from "@chakra-ui/react";
import { useDivideControlProps } from "./hooks";

const omittedSelectProps = ["selectedItem"] as const;
type OmittedSelectProps<T> = Omit<
  SelectFilterProps<T>,
  typeof omittedSelectProps[number]
>;

type FormSelectFilterProps<
  T,
  TFieldValues extends FieldValues
> = UseControllerProps<TFieldValues> & OmittedSelectProps<T>;

function FormSelectFilterInner<T, TFieldValues extends FieldValues>(
  props: FormSelectFilterProps<T, TFieldValues>,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const { onSelectedItemChange: onSelectedItemChangeProp, ...restProps } =
    props;
  const [selectProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormSelectFilterProps<T, TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, value, onChange, ...restField },
  } = useController(controllerProps);

  const selectRef = useMergeRefs(ref, fieldRef);

  const handleOnSelectedItemChange: SelectFilterProps<T>["onSelectedItemChange"] =
    (e) => {
      onChange(e.selectedItem);
      onSelectedItemChangeProp?.(e);
    };

  return (
    <SelectFilter
      ref={selectRef}
      selectedItem={value || null}
      onSelectedItemChange={handleOnSelectedItemChange}
      {...restField}
      {...selectProps}
    />
  );
}

const FormSelectFilter = React.forwardRef(FormSelectFilterInner) as <
  T,
  TFieldValues extends FieldValues
>(
  props: FormSelectFilterProps<T, TFieldValues> & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  }
) => ReturnType<typeof FormSelectFilterInner>;

export { FormSelectFilter };
export type { FormSelectFilterProps };
