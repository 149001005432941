export default {
  chartApiBaseUrl: process.env.REACT_APP_CHART_API_BASE_URL,
  identityApiBaseUrl: process.env.REACT_APP_IDENTITY_API_BASE_URL,
  patientApiBaseUrl: process.env.REACT_APP_PATIENT_API_BASE_URL,
  facilityApiBaseUrl: process.env.REACT_APP_FACILITY_API_BASE_URL,
  timerApiBaseUrl: process.env.REACT_APP_TIMER_API_BASE_URL,
  orderApiBaseUrl: process.env.REACT_APP_ORDER_API_BASE_URL,
  nodeEnv: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV === "development",
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  auth0BackendApiAudience: process.env.REACT_APP_AUTH0_BACKEND_API_AUDIENCE,
  auth0LogoutUrl: process.env.REACT_APP_AUTH0_LOGOUT_URL,
  SLPatientApiBaseUrl: process.env.REACT_APP_STP_VIP_API_BASE_URL,
  ipifyUrl: process.env.REACT_APP_IPIFY_URL,
};
