import {
  Box,
  BoxProps,
  HStack,
  StackProps,
  useMergeRefs,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";

type CustomRadioProps = UseRadioGroupProps &
  Omit<StackProps, keyof UseRadioGroupProps>;

type CustomRadioContextValue = {
  getRadioProps: ReturnType<typeof useRadioGroup>["getRadioProps"];
  isDisabled?: boolean;
};

const CustomRadioContext = React.createContext<CustomRadioContextValue | null>(
  null
);

function useCustomRadioContext() {
  const context = React.useContext(CustomRadioContext);

  if (!context) {
    throw new Error(
      "Consumers of this context must be children of a Custom Radio component."
    );
  }

  return context;
}

type CustomRadioItemProps = {
  value: string | number;
} & BoxProps;

function CustomRadioItem(props: CustomRadioItemProps) {
  const { value, ...boxProps } = props;
  const { getRadioProps, isDisabled } = useCustomRadioContext();
  const radioProps = getRadioProps({ value });
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} disabled={isDisabled} />
      <Box
        {...checkbox}
        aria-disabled={isDisabled}
        width="240px"
        height="50px"
        border="1px"
        borderColor="gray.650"
        borderRadius="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="gray.650"
        fontSize="1.0625rem"
        fontWeight="500"
        transition="all 200ms"
        _checked={{ bg: "blue", borderColor: "blue", color: "white" }}
        _focus={{ boxShadow: "var(--chakra-shadows-outline)" }}
        _disabled={{ opacity: 0.6 }}
        {...boxProps}
      />
    </Box>
  );
}

const CustomRadio = React.forwardRef<HTMLDivElement, CustomRadioProps>(
  (props, ref) => {
    const {
      value,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
      ...stackProps
    } = props;
    const { getRadioProps, getRootProps } = useRadioGroup({
      value,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
    });

    const { ref: groupRef, ...rest } = getRootProps();
    const mergedRefs = useMergeRefs(ref, groupRef);

    const contextValue: CustomRadioContextValue = React.useMemo(
      () => ({ getRadioProps, isDisabled }),
      [getRadioProps, isDisabled]
    );

    return (
      <CustomRadioContext.Provider value={contextValue}>
        <HStack ref={mergedRefs} spacing="20px" {...stackProps} {...rest} />
      </CustomRadioContext.Provider>
    );
  }
);

if (config.isDev) {
  CustomRadio.displayName = "CustomRadio";
}

export type { CustomRadioProps };
export { CustomRadio, CustomRadioItem };
