import {
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useToast, useYupValidationResolver } from "hooks";
import { formMessages, genericErrors } from "messages";
import * as React from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FormControl,
  FormSelect,
  NumberInputMask,
  WarningDialog,
} from "shared";
import { enumMapper, extractApiErrorMessage } from "utils";
import * as yup from "yup";
import {
  CreatePrimaryCarePhysicianPayload,
  useCreatePrimaryCarePhysician,
  useDeleteEncounterPrimaryCarePhysician,
  useDeletePrimaryCarePhysician,
  useUpdatePrimaryCarePhysician,
} from "../../../api";
import { PCP, PCPForm, PCPPayload } from "../../../types";
import { useParams } from "react-router-dom";

type PCPModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

type PCPModalHeaderProps = {
  onSubmit: () => void;
  isSubmitting: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
} & Pick<PCPModalProps, "onClose">;

type PCPEditProps = {
  initialPCP: PCP;
  isEncounter?: boolean;
};

type PCPEditModalProps = Omit<PCPModalProps, "children"> & PCPEditProps;
type DeleteWarningDialogProps = { onDeleteSuccess?: () => void } & PCPEditProps;

const usStateOptions = enumMapper.getOptions("usState");

const schema = yup.object().shape({
  name: yup.string().required(formMessages.required("Name")),
  phone: yup.string(),
  fax: yup.string(),
  address: yup.string(),
  suite: yup.string(),
  city: yup.string(),
  state: yup.mixed(),
  zip: yup.string(),
});

function PCPModalBody(props: Partial<PCPEditModalProps>) {
  const { initialPCP, onClose, isEncounter } = props;
  const { encounterId = "" } = useParams<{ encounterId: string }>();

  const toast = useToast();

  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<PCPForm>();

  const handleOpenPcp = () => {
    onClose?.();
    const btn = document.getElementById("open-pcp-btn");
    btn?.click();
  };

  const {
    mutateAsync: deleteSelectedPrimaryCarePhysician,
    isLoading: unselectIsLoading,
  } = useDeleteEncounterPrimaryCarePhysician(encounterId);

  const handleDeletePCP = async () => {
    try {
      await deleteSelectedPrimaryCarePhysician({});
      onClose?.();
      toast({
        description: formMessages.deleteSuccess("Primary Care Physician"),
      });
    } catch (error) {
      const err = error as Error;
      toast({
        description: extractApiErrorMessage(err),
      });
    }
  };

  return (
    <ModalBody marginTop="10px" marginBottom="10px">
      <form>
        <VStack alignItems="stretch" spacing="28px" marginBottom="32px">
          <FormControl
            label="Name"
            error={errors.name?.message}
            flex="2"
            isRequired
          >
            <Input placeholder="Name" {...register("name")} />
          </FormControl>
          <HStack alignItems="stretch" spacing="18px">
            <FormControl label="Phone #" error={errors.phone?.message} flex="2">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <NumberInputMask
                    type="text"
                    mask="(###) ###-####"
                    inputMode="tel"
                    placeholder="Phone #"
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl label="Fax #" error={errors.fax?.message} flex="2">
              <Controller
                name="fax"
                control={control}
                render={({ field }) => (
                  <NumberInputMask
                    type="text"
                    mask="(###) ###-####"
                    inputMode="tel"
                    placeholder="Fax #"
                    {...field}
                  />
                )}
              />
            </FormControl>
          </HStack>
          <Flex direction="row" gridGap="4">
            <FormControl
              label="Address"
              error={errors.address?.message}
              flex="2"
            >
              <Input placeholder="Address" {...register("address")} />
            </FormControl>
            <FormControl
              label="Suite #"
              error={errors.suite?.message}
              minW="120px"
              width="120px"
            >
              <Input placeholder="Suite" {...register("suite")} />
            </FormControl>
          </Flex>
          <Flex direction="row" gridGap="4">
            <FormControl
              label="City"
              error={errors.city?.message}
              minW="240px"
              w="240px"
            >
              <Input placeholder="City" {...register("city")} />
            </FormControl>
            <FormControl
              label="State"
              error={errors.state?.message}
              w="80px"
              minW="80px"
            >
              <FormSelect
                name="state"
                items={usStateOptions}
                labelAccessor="label"
                valueAccessor="value"
                control={control}
              />
            </FormControl>
            <FormControl
              label="ZIP"
              error={errors.zip?.message}
              w="140px"
              minW="140px"
            >
              <Input placeholder="ZIP" type="number" {...register("zip")} />
            </FormControl>
          </Flex>
        </VStack>
      </form>
      {isEncounter && (
        <Flex direction="column" rowGap="1.5rem" mt="0.5rem">
          <Button variant="outline" borderWidth="thin" onClick={handleOpenPcp}>
            Go to PCP List
          </Button>
          <Button
            variant="outline"
            colorScheme="red"
            borderWidth="thin"
            isLoading={unselectIsLoading}
            onClick={handleDeletePCP}
          >
            Delete PCPC for this Patient
          </Button>
        </Flex>
      )}
      {initialPCP && !isEncounter && (
        <DeleteWarningDialog
          initialPCP={initialPCP}
          onDeleteSuccess={onClose}
        />
      )}
    </ModalBody>
  );
}

function DeleteWarningDialog(props: DeleteWarningDialogProps) {
  const { initialPCP, onDeleteSuccess } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();

  const toast = useToast();

  const { mutateAsync: deletePrimaryCarePhysician, isLoading } =
    useDeletePrimaryCarePhysician(initialPCP.id || "");

  const onDelete = async () => {
    try {
      onClose();
      await deletePrimaryCarePhysician(null);
      toast({
        description: formMessages.deleteSuccess("Primary Care Physician"),
      });
      onDeleteSuccess?.();
    } catch (error) {
      const err = error as Error;
      toast({
        description: extractApiErrorMessage(err),
      });
    }
  };

  return (
    <>
      <Button
        variant="label"
        color="red"
        marginTop="56px"
        onClick={onOpen}
        isLoading={isLoading}
      >
        Remove from List
      </Button>
      <WarningDialog
        isOpen={isOpen}
        onClose={onClose}
        onAction={onDelete}
        onCancel={onClose}
        title="Warning!"
        mainText="Are you sure you want to remove this PCP from this list?"
        secondaryText="Changes cannot be undone!"
      />
    </>
  );
}

function PCPModalHeader(props: PCPModalHeaderProps) {
  const { onSubmit, isSubmitting, onClose, isEditing, isLoading } = props;

  let title = "Add New PCP";
  if (isEditing) {
    title = "Edit PCP";
  }

  return (
    <ModalHeader
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="15px 25px 0px 25px"
    >
      <HStack alignItems="center" maxWidth="540px" w="100%">
        <Button variant="label" color="blue" onClick={onClose} fontSize="lg">
          Cancel
        </Button>
        <Spacer />
        <Heading fontSize="1.0rem" fontWeight="600">
          {title}
        </Heading>
        <Spacer />
        <Button
          variant="label"
          color="blue"
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isSubmitting}
          fontSize="lg"
        >
          Done
        </Button>
      </HStack>
    </ModalHeader>
  );
}

function PCPModal(props: PCPModalProps) {
  const { isOpen, onClose, children } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius="10px"
        paddingBottom="10px"
        width="540px"
        maxWidth="540px"
        height="620px"
      >
        {isOpen && <>{children}</>}
      </ModalContent>
    </Modal>
  );
}

function PCPCreateModalContent(props: PCPModalProps) {
  const { onClose } = props;
  const { mutateAsync: createPrimaryCarePhysician, isLoading } =
    useCreatePrimaryCarePhysician();
  const toast = useToast();
  const resolver = useYupValidationResolver(schema);
  const formContext = useForm<PCPForm>({
    resolver,
    mode: "onChange",
    defaultValues: {},
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formContext;

  const onSubmit: SubmitHandler<PCPForm> = async (data) => {
    try {
      const payload: CreatePrimaryCarePhysicianPayload = {
        ...data,
        state:
          data.state != null
            ? enumMapper.toDisplay("usState", data.state.value)
            : "",
      };
      await createPrimaryCarePhysician(payload);
      toast({
        status: "success",
        description: formMessages.createSuccess("Primary Care Physician"),
      });

      onClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <FormProvider {...formContext}>
      <PCPModalHeader
        isSubmitting={isSubmitting}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      />
      <PCPModalBody />
    </FormProvider>
  );
}

function PCPEditModalContent(props: PCPEditModalProps) {
  const { initialPCP, isEncounter, ...restProps } = props;
  const { onClose } = restProps;
  const resolver = useYupValidationResolver(schema);
  const formContext = useForm<PCPForm>({
    resolver,
    mode: "onChange",
    defaultValues: {},
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = formContext;
  const { mutateAsync: updatePrimaryCarePhysician, isLoading } =
    useUpdatePrimaryCarePhysician(initialPCP.id);
  const toast = useToast();
  const { id } = initialPCP; // useParams<{ id: string }>();

  const onSubmit: SubmitHandler<PCPForm> = async (data) => {
    try {
      const payload: PCPPayload = {
        ...data,
        id,
        state:
          data.state != null
            ? enumMapper.toDisplay("usState", data.state.value)
            : "",
      };
      await updatePrimaryCarePhysician(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Primary Care Physician"),
      });
      onClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  React.useEffect(() => {
    if (initialPCP) {
      const { state, ...restInitialPCP } = initialPCP;
      reset({
        ...restInitialPCP,
        state: enumMapper.valueToOption("usState", state),
      });
    }
  }, [reset, initialPCP]);

  return (
    <FormProvider {...formContext}>
      <PCPModalHeader
        isSubmitting={isSubmitting}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        isEditing
      />
      <PCPModalBody
        initialPCP={initialPCP}
        isEncounter={isEncounter}
        {...restProps}
      />
    </FormProvider>
  );
}

function PCPCreateModal(props: Omit<PCPModalProps, "children">) {
  return (
    <PCPModal {...props}>
      <PCPCreateModalContent {...props} />
    </PCPModal>
  );
}

function PCPEditModal(props: PCPEditModalProps) {
  const { initialPCP, ...restProps } = props;

  return (
    <PCPModal {...restProps}>
      <PCPEditModalContent initialPCP={initialPCP} {...restProps} />
    </PCPModal>
  );
}

export { PCPCreateModal, PCPEditModal };
