import { axiosChartInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartType } from "types";

type UseDeleteChartApplicationSignaturesParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
  chartType?: ChartType;
};

function useDeleteChartApplicationSignatures(
  params: UseDeleteChartApplicationSignaturesParams
) {
  const queryClient = useQueryClient();
  const { encounterId, chartCode, chartType } = params;

  return useAxiosMutation(
    ["deleteChartApplicationSignatures", params],
    (ids: string[]) =>
      axiosChartInstance.delete(`v1/signature/${encounterId}/${chartCode}`, {
        data: ids,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartApplication", params]);
        queryClient.invalidateQueries([
          "chartApplicationHardStops",
          { encounterId, chartCode, chartType: chartType ?? "" },
        ]);
      },
    }
  );
}

export { useDeleteChartApplicationSignatures };
