import { chakra, Box, LinkProps as CkLinkProps } from "@chakra-ui/react";
import { OrderStatus } from "enums";
import * as React from "react";
import { Link, LinkProps, useMatch } from "react-router-dom";
import { ORDER_STATUS_COLOR } from "../constants";

type OrderNavigationCardProps = {
  icon: React.ReactNode;
  to: string;
  status: OrderStatus;
} & Omit<LinkProps, "to"> &
  CkLinkProps;

function OrderNavigationCard(props: OrderNavigationCardProps) {
  const { to, icon, status, children, ...restProps } = props;
  const match = useMatch(to);

  return (
    <chakra.a
      as={Link}
      aria-selected={!!match}
      to={to}
      display="flex"
      alignItems="center"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      border="1px"
      borderColor="gray.450"
      borderRadius="10px"
      padding="24px 18px"
      width="390px"
      _selected={{ borderColor: "blue" }}
      bg="white"
      flexDirection="row"
      {...restProps}
    >
      <Box w="1.375rem" h="1.375rem" marginRight="17px">
        {React.isValidElement(icon) &&
          React.cloneElement(icon, {
            fontSize: "1.375rem",
            color: ORDER_STATUS_COLOR[status],
          })}
      </Box>
      <Box fontSize="0.875rem" fontWeight="600" flex="1" overflow="hidden">
        {children}
      </Box>
    </chakra.a>
  );
}

export type { OrderNavigationCardProps };
export { OrderNavigationCard };
