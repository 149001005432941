import * as React from "react";
import { Card, CardProps } from "shared/card";

function TableContainer(props: CardProps) {
  return (
    <Card
      display="flex"
      flexDirection="column"
      overflow="hidden"
      boxShadow="unset"
      {...props}
    />
  );
}

export { TableContainer };
