import React from "react";
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { InterpretationsPlusMinus } from "icons";
import { RadiologyInterpretationsModal } from "./RadiologyInterpretationsModal";
import {
  RADIOLOGY_INTERPRETATIONS_SELECT_ITEM,
  RadiologyInterpretationsType,
} from "./constants";

type RadiologyInterpretationsProps = {
  chartId: string;
  sectionId: string;
  type: RadiologyInterpretationsType;
  isReadOnly?: boolean;
  onAddInterpretation: (list: RADIOLOGY_INTERPRETATIONS_SELECT_ITEM[]) => void;
} & BoxProps;

function RadiologyInterpretations(props: RadiologyInterpretationsProps) {
  const {
    chartId,
    sectionId,
    type,
    children,
    isReadOnly = false,
    onAddInterpretation,
    ...boxProps
  } = props;

  const radiologyInterpretationDisclose = useDisclosure();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding=".25rem 0 .25rem 1.25rem"
      marginTop="1.25rem"
      {...boxProps}
    >
      <Box
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <HStack spacing="20px">
          <chakra.h2
            fontSize="1.125rem"
            fontWeight="600"
            textTransform="capitalize"
          >
            {type.toString().toLowerCase()}
          </chakra.h2>
        </HStack>
      </Box>

      <Button
        variant="ghost"
        color="blue"
        fontSize="1.0625rem"
        disabled={isReadOnly}
        leftIcon={<Icon color="blue" as={InterpretationsPlusMinus} />}
        onClick={radiologyInterpretationDisclose.onOpen}
        textTransform="capitalize"
        justifyContent="flex-end"
      >
        {type.toString().toLowerCase()}
      </Button>

      <RadiologyInterpretationsModal
        type={type}
        isOpen={radiologyInterpretationDisclose.isOpen}
        onClose={radiologyInterpretationDisclose.onClose}
        onDone={onAddInterpretation}
      />
    </Box>
  );
}

export { RadiologyInterpretations };
