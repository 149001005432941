import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type DeleteOrderQueueParams = {
  encounterId: string;
};

function useDeleteOrderQueue({ encounterId }: DeleteOrderQueueParams) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteOrderQueue", { encounterId }],
    (orderIds: string[]) =>
      axiosOrderInstance.delete(`v1/queue/${encounterId}`, { data: orderIds }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["orderQueue", { encounterId }]),
    }
  );
}

export { useDeleteOrderQueue };
