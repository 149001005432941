import * as React from "react";
import { useToast } from "hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { TriageChartCode } from "enums";
import { useTriageChartActions, useUpdateTriageChartActions } from "../api";
import { ActionsForm, TriageRouteBaseParams } from "../types";
import { actionsUtils } from "../utils";
import { nextRoutesNurse, nextRoutesProvider } from "modules/charts-shared";

type UseTriageChartActionsFormProps = {
  chartCode: TriageChartCode;
};

function useTriageChartActionsForm(props: UseTriageChartActionsFormProps) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<TriageRouteBaseParams>();
  const navigate = useNavigate();
  const { chartCode } = props;
  const useFormReturn = useForm<ActionsForm>({
    defaultValues: { initialActionsSections: [], actionsSections: [] },
  });
  const { handleSubmit, reset } = useFormReturn;
  const toast = useToast();
  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useTriageChartActions({
    encounterId,
    chartCode,
  });
  const { mutateAsync: updateActions, isLoading: updateIsLoading } =
    useUpdateTriageChartActions({ encounterId, chartCode });
  const onSubmit: SubmitHandler<ActionsForm> = React.useCallback(
    async (input) => {
      try {
        if (!input.initialActionsSections) {
          input.initialActionsSections = [];
        }

        await updateActions(actionsUtils.formToPayload(input));
        toast({ description: formMessages.updateSuccess("Actions") });
        // Reset form with latest values to set isDirty to false
        reset(input);
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [updateActions, toast, reset]
  );

  const onSaveAndNext = React.useCallback(() => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const nextRoute = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];

    handleSubmit(onSubmit)().then(() => {
      navigate(`./../${nextRoute}`);
    });
  }, [chartName, navigate, onSubmit, handleSubmit, chartCode]);

  React.useEffect(() => {
    if (data) {
      reset(actionsUtils.responseToForm(data.data));
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const value = React.useMemo(
    () => ({
      encounterId,
      onSubmit: handleSubmit(onSubmit),
      updateIsLoading,
      getIsLoading,
      useFormReturn,
      onSaveAndNext,
    }),
    [
      handleSubmit,
      onSubmit,
      updateIsLoading,
      encounterId,
      getIsLoading,
      useFormReturn,
      onSaveAndNext,
    ]
  );
  return value;
}

export type { UseTriageChartActionsFormProps };
export { useTriageChartActionsForm };
