import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Expand = createIcon({
  path: (
    <path
      d="M0.868835 7.64008C1.36936 7.64008 1.72823 7.26233 1.72823 6.76181V5.96852L1.53935 2.67261L4.02309 5.28856L6.93179 8.21616C7.09234 8.38615 7.30011 8.4617 7.52676 8.4617C8.06506 8.4617 8.4617 8.11228 8.4617 7.58342C8.4617 7.32844 8.37671 7.10178 8.20672 6.93179L5.28856 4.02309L2.67261 1.53935L5.97796 1.72823H6.76181C7.26233 1.72823 7.64953 1.3788 7.64953 0.868835C7.64953 0.358867 7.27177 0 6.76181 0H1.52046C0.557188 0 0 0.557188 0 1.52046V6.76181C0 7.25289 0.368311 7.64008 0.868835 7.64008ZM11.2288 18.0189H16.4701C17.4334 18.0189 18 17.4617 18 16.4984V11.2571C18 10.766 17.6317 10.3788 17.1217 10.3788C16.6306 10.3788 16.2623 10.7566 16.2623 11.2571V12.0504L16.4607 15.3463L13.9675 12.7303L11.0682 9.80273C10.9077 9.63274 10.6905 9.55719 10.4638 9.55719C9.93494 9.55719 9.52886 9.90661 9.52886 10.4355C9.52886 10.6905 9.62329 10.9171 9.79328 11.0871L12.702 13.9958L15.3274 16.4795L12.022 16.2907H11.2288C10.7282 16.2907 10.341 16.6401 10.341 17.1501C10.341 17.66 10.7282 18.0189 11.2288 18.0189Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 19",
});

export { Expand };
