import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useAddCommonDoseFavorite() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateOrderFormFavorites"],
    (payload: {
      orderFormElementId: string;
      route: string;
      orderFormElementCommonDoseId: string;
    }) =>
      axiosChartInstance.post(
        `v1/order-form/doses/favorites/${payload.orderFormElementId}`,
        {
          route: payload.route,
          OrderFormElementCommonDoseId: payload.orderFormElementCommonDoseId,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["doseFavorites"]);
        queryClient.invalidateQueries(["orderListElements"]);
      },
    }
  );
}
export { useAddCommonDoseFavorite };
