import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseChartSubsectionFileProps = {
  encounterId: string;
  subsectionId: string;
};

function useUploadChartSubsectionFile({
  encounterId,
  subsectionId,
}: UseChartSubsectionFileProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["uploadChartSubsectionFile", { encounterId, subsectionId }],
    (file: FormData) =>
      axiosChartInstance.post(
        `v1/chart/${encounterId}/ekg/${subsectionId}/file`,
        file
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartSubsectionFile",
          { encounterId, subsectionId },
        ]);
      },
    }
  );
}

export { useUploadChartSubsectionFile };
