import {
  anatomy,
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";

const parts = [
  "imageContainer",
  "img",
  "deletebtn",
  "actionsContainer",
  "iconsContainer",
  "scannerContainer",
];
const imagePickerAnatomy = anatomy("image-picker").parts(...parts);

const baseStyle: PartsStyleFunction<typeof imagePickerAnatomy> = () => {
  return {
    imageContainer: {
      borderRadius: "10px",
      bg: "gray.50",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid",
      borderColor: "gray.450",
      position: "relative",
      height: "240px",
      width: "100%",
      outline: "none",
      _hover: { cursor: "pointer" },
      _focus: {
        boxShadow: "var(--chakra-shadows-outline)",
      },
      _disabled: { cursor: "not-allowed", opacity: 0.4, boxShadow: "none" },
    },

    scannerContainer: {
      borderRadius: "10px",
      bg: "gray.50",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid",
      borderColor: "gray.450",
      position: "relative",
      height: "240px",
      width: "100%",
      outline: "none",
      _disabled: { cursor: "not-allowed", opacity: 0.4, boxShadow: "none" },
      userSelect: "none",
    },

    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      position: "absolute",
      borderRadius: "9px",
    },

    actionsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    iconsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "15%",
    },

    expandbtn: {
      color: "blue",
    },

    deletebtn: {
      color: "red",
      fontWeight: "400",
      fontSize: "1.0625rem",
      lineHeight: "1.25rem",
      outline: "none",
      _focus: {
        boxShadow: "var(--chakra-shadows-outline)",
      },
      _disabled: {
        opacity: "0.5",
      },
    },
  };
};

const variantRound: SystemStyleFunction = () => {
  return {
    imageContainer: {
      width: "100px",
      height: "100px",
      borderStyle: "none",
      borderWidth: "0px",
      bg: "none",
      borderRadius: "50%",
    },
    actionsContainer: {
      top: "-5px",
      right: "-5px",
      position: "absolute",
    },
    deletebtn: {
      h: "40px",
      w: "40px",
    },
    img: {
      borderRadius: "50%",
      objectFit: "cover",
    },
  };
};

const variants = {
  round: variantRound,
};

const imagePickerTheme = { baseStyle, parts, variants };

export { imagePickerTheme };
