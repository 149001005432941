import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Meal = createIcon({
  path: (
    <path
      d="M16 32C7.22531 32 0 24.7747 0 16C0 7.24065 7.22531 0 15.9847 0C24.7593 0 32 7.24065 32 16C32 24.7747 24.7747 32 16 32ZM12.6865 26.0019C13.4382 26.0019 13.9137 25.5724 13.8984 24.8667L13.745 15.2637C13.745 14.9262 13.8523 14.7421 14.1285 14.604C15.2943 14.0825 15.8313 13.5302 15.7392 11.9348L15.4938 6.79578C15.4631 6.38159 15.2483 6.13615 14.8802 6.13615C14.4966 6.13615 14.2819 6.39693 14.2819 6.81112L14.3432 11.8121C14.3432 12.1035 14.1592 12.3183 13.883 12.3183C13.5762 12.3183 13.3768 12.1035 13.3768 11.8274L13.3154 6.67306C13.3154 6.25887 13.0547 6.02876 12.6865 6.02876C12.3183 6.02876 12.0729 6.25887 12.0729 6.67306L11.9808 11.8274C11.9808 12.1035 11.7967 12.3183 11.4899 12.3183C11.2291 12.3183 11.0297 12.1035 11.0297 11.8121L11.1064 6.81112C11.1064 6.39693 10.8917 6.13615 10.5082 6.13615C10.14 6.13615 9.89454 6.38159 9.8792 6.79578L9.63375 11.9348C9.57239 13.5302 10.094 14.0825 11.2598 14.604C11.536 14.7421 11.6587 14.9262 11.6587 15.2637L11.4899 24.8667C11.4593 25.5877 11.9501 26.0019 12.6865 26.0019ZM18.9147 18.7152L18.7459 24.8207C18.7306 25.5877 19.2215 26.0019 19.9425 26.0019C20.7248 26.0019 21.185 25.6031 21.185 24.8821V6.6884C21.185 6.24353 20.8782 6.02876 20.5561 6.02876C20.2339 6.02876 19.9885 6.19751 19.7124 6.59636C18.3317 8.5139 17.3653 12.303 17.3653 15.6472V16.2148C17.3653 16.8284 17.5801 17.2579 18.0709 17.5954L18.4851 17.8715C18.8073 18.0863 18.93 18.3164 18.9147 18.7152Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 32 32",
});

export { Meal };
