import { SystemStyleObject, theme } from "@chakra-ui/react";

const {
  baseStyle: bStyle,
  defaultProps: dp,
  ...rest
} = theme.components.Divider;

const baseStyle: SystemStyleObject = {
  ...bStyle,
  borderColor: "gray.450",
  bg: "gray.450",
  opacity: 1,
};

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    height: "1px",
  },
  lg: {
    height: "3px",
  },
  xl: {
    height: "4px",
  },
};

const defaultProps = {
  ...dp,
  size: "sm",
};

export default {
  baseStyle,
  defaultProps,
  sizes,
  ...rest,
};
