import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormProcedurePayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseUpdateChartApplicationProcedureParams = {
  encounterId: string;
  chartCode: string;
};

function useUpdateChartApplicationProcedure(
  params: UseUpdateChartApplicationProcedureParams
) {
  const { encounterId, chartCode } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["chartApplicationProcedure", { chartCode }],
    (input: ChartFormProcedurePayload) =>
      axiosChartInstance.put(
        `v1/chart-application/${encounterId}/${chartCode}/procedure-group`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartApplicationProcedure", params]);
        queryClient.invalidateQueries(["chartDisposition", params]);
      },
    }
  );
}

export type { UseUpdateChartApplicationProcedureParams };
export { useUpdateChartApplicationProcedure };
