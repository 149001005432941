import { useMergeRefs } from "@chakra-ui/react";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import * as React from "react";
import { KonvaNodeEvents, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { TextConfig } from "./types";
import { WithTransformer, WithTransformerProps } from "./WithTransformer";

type CanvasTextProps = TextConfig &
  KonvaNodeEvents & {
    isSelected: boolean;
  } & Pick<WithTransformerProps, "dispatch">;

const MIN_TEXT_AREA_WIDTH = 300;
const MIN_TEXT_AREA_HEIGHT = 100;

const CanvasEditableText = React.forwardRef<Konva.Text, CanvasTextProps>(
  (props, ref) => {
    const { isSelected, dispatch, text, ...textProps } = props;
    const { x, y, width = 0, height = 0, id } = textProps;
    const [value, setValue] = React.useState(text);
    const [isEditing, setIsEditing] = React.useState(isSelected);
    const shapeRef = React.useRef<Konva.Text>(null);
    const mergedRef = useMergeRefs(ref, shapeRef);
    const mounted = React.useRef(false);
    const onTransform = (e: KonvaEventObject<Event>) => {
      const scaleX = e.target.scaleX();
      const scaleY = e.target.scaleY();

      dispatch({
        type: "EDIT_SHAPE",
        payload: {
          width: e.target.width() * scaleX,
          height: e.target.height() * scaleY,
          id,
        },
      });
    };

    React.useEffect(() => {
      if (!isEditing && mounted.current) {
        dispatch({
          type: "EDIT_SHAPE",
          payload: { text: value || "Type here", id },
        });
      }
    }, [value, isEditing, id, dispatch]);

    React.useEffect(() => {
      mounted.current = true;
    }, []);

    if (isEditing) {
      return (
        <Html groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
          <textarea
            ref={(node) => node?.focus()}
            placeholder="Type here"
            value={value}
            onChange={(e) => {
              const newValue = e.currentTarget.value;
              setValue(newValue);
            }}
            style={{
              width: `${
                width < MIN_TEXT_AREA_WIDTH ? MIN_TEXT_AREA_WIDTH : width
              }px`,
              height: `${
                height < MIN_TEXT_AREA_HEIGHT ? MIN_TEXT_AREA_HEIGHT : height
              }px`,
              border: "none",
              padding: "20px",
              margin: "0px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              outline: "none",
              resize: "none",
              color: "#FF3B30",
              fontSize: "17px",
            }}
            onBlur={() => {
              setIsEditing(false);
            }}
          />
        </Html>
      );
    }

    return (
      <>
        <WithTransformer
          isSelected={isSelected}
          dispatch={dispatch}
          shapeRef={shapeRef}
        >
          <Text
            ref={mergedRef}
            onDblClick={() => {
              setIsEditing(true);
            }}
            onTransform={onTransform}
            text={text || "Type here"}
            {...textProps}
          />
        </WithTransformer>
      </>
    );
  }
);

function CanvasText(props: CanvasTextProps) {
  const { isSelected, dispatch, ...textProps } = props;
  const textRef = React.useRef<Konva.Text>(null);

  return (
    <WithTransformer
      isSelected={isSelected}
      shapeRef={textRef}
      dispatch={dispatch}
      enabledAnchors={[]}
      flipEnabled={false}
      rotateEnabled={false}
    >
      <Text ref={textRef} {...textProps} />
    </WithTransformer>
  );
}

export { CanvasEditableText, CanvasText };
