import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C070a";

function T70ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="ENT / Resp"
            >
              ENT / Resp
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Asthma:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A070`}
                />
                <ChartRowElement
                  label="Reactive airway dz:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A071`}
                />
                <ChartRowElement
                  label="acute exacerbation"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="status asthmaticus"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hx of:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A072`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mod"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe dz"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ARDS"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchospasm, acute"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchitis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A073`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="simple"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mucopurulent"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cold (Nasopharyngitis)"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="COPD:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A074`}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A075`}
                />
                <ChartRowElement
                  label="exacerbation"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute bronchitis"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="COVID-19:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A076`}
                />
                <ChartRowElement
                  label="disease"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="exposure"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epiglottis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A077`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ obstruction"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Influenza:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A078`}
                />
                <ChartRowElement
                  label="H1N1"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="A"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pharyngitis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A079`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Strep"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumonia:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A080`}
                />
                <ChartRowElement
                  label="interstitial"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bronchopneumonia"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lobar"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A081`}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Influenza:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A082`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="A & B"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  fontStyle="italic"
                  type="cross-option"
                />

                <ChartRowElement
                  label="COVID-19"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumothorax:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A083`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tension"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm edema:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A084`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ CHF"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm embolisym"
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ cor pulmonale"
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Respiratory failure:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A085`}
                />
                <ChartRowElement
                  label="w/ hypoxia"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sinusitis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A086`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="maxillary"
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="frontal"
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ethmoid"
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Smoke inhalation"
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A066`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="URI, acute"
                  name={`Q002.${chartCode}${sectionId}Q002A067`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A068`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A069`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Acute MI:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A060`}
                />
                <ChartRowElement
                  label="STEMI"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NSTEMI"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anterior"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inferior"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lateral"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Allergic reaction"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Anaphylaxis"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A061`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Anxiety reaction"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Panic attack"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Arrythmia:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A062`}
                />
                <ChartRowElement
                  label="A-fib:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A063`}
                />
                <ChartRowElement
                  label="paroxysmal"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="A-flutter:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A064`}
                />
                <ChartRowElement
                  label="typical"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PSVT"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="AV block:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A065`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="V tach"
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="V flutter"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="torsades"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="CHF:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A066`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A067`}
                />
                <ChartRowElement
                  label="LVF"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RVF"
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Costochondritis"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="DKA:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A068`}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A069`}
                />
                <ChartRowElement
                  label="type 1 diabetes"
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="coma"
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="GERD"
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes zoster (shingles)"
                  name={`Q003.${chartCode}${sectionId}Q003A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hyperventilation syndrome"
                  name={`Q003.${chartCode}${sectionId}Q003A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Mastitis"
                  name={`Q003.${chartCode}${sectionId}Q003A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/lactation"
                  name={`Q003.${chartCode}${sectionId}Q003A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ preg:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A070`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q003.${chartCode}${sectionId}Q003A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Muscle strain, ant chest wall"
                  name={`Q003.${chartCode}${sectionId}Q003A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A047`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q003.${chartCode}${sectionId}Q003A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Sepsis, severe"
                  name={`Q003.${chartCode}${sectionId}Q003A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A071`}
                />
                <ChartRowElement
                  label="hypotension"
                  name={`Q003.${chartCode}${sectionId}Q003A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q003.${chartCode}${sectionId}Q003A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q003.${chartCode}${sectionId}Q003A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alt. mental"
                  name={`Q003.${chartCode}${sectionId}Q003A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypoxia"
                  name={`Q003.${chartCode}${sectionId}Q003A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="resp. distress"
                  name={`Q003.${chartCode}${sectionId}Q003A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A056`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Viral syndrome"
                  name={`Q003.${chartCode}${sectionId}Q003A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A058`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A059`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Arm pain:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Chest pain:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                />
                <ChartRowElement
                  label="precordial"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Chest wall pain"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cough"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fever"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypoxema"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shortness of breath"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Wheezing"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Pulmonary"
            >
              Pulmonary
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Bronchospasm, acute"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchitis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A040`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Influenza:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A041`}
                />
                <ChartRowElement
                  label="H1N1"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="A"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pleurisy"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ effusion"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumonia:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A042`}
                />
                <ChartRowElement
                  label="interstitial"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bronchopneumonia"
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lobar"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A043`}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Influenza:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A044`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bacterial:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A045`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumomediastinum"
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumothorax:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A046`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q005.${chartCode}${sectionId}Q005A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tension"
                  name={`Q005.${chartCode}${sectionId}Q005A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm edema:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A047`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q005.${chartCode}${sectionId}Q005A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="with CHF"
                  name={`Q005.${chartCode}${sectionId}Q005A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm embolism w/ cor pulmonale"
                  name={`Q005.${chartCode}${sectionId}Q005A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q005.${chartCode}${sectionId}Q005A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Sepsis"
                  name={`Q005.${chartCode}${sectionId}Q005A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="URI, acute"
                  name={`Q005.${chartCode}${sectionId}Q005A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A039`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="CV"
            >
              CV
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Acute MI:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                />
                <ChartRowElement
                  label="STEMI"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NSTEMI"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anterior"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inferior"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lateral"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Angina:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                />
                <ChartRowElement
                  label="stable"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unstable"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic dissection:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic aneurysm:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ rupture"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="CHF:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                />
                <ChartRowElement
                  label="LVF"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RVF"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="systolic"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="diastolic"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ischemic chest pain"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pericarditis, acute:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                />
                <ChartRowElement
                  label="viral"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="GI"
            >
              GI
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Biliary colic:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A031`}
                />
                <ChartRowElement
                  label="w/ gallstones"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cholecystitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A032`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A033`}
                />
                <ChartRowElement
                  label="gallstones"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="obstruction"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Esophageal obstruction"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ food"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Esophageal perforation"
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Esophageal spasm"
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gastritis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A034`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  bleeding"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="GERD"
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  esophagitis"
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pancreatitis:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A035`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="biliary"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Peptic ulcer dz:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A036`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  perforation"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  hemorrhage"
                  name={`Q007.${chartCode}${sectionId}Q007A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A030`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T70ClinicalImpression };
