/* eslint-disable react/no-array-index-key */
import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Button,
  chakra,
  BoxProps,
  Icon,
  Spinner,
  ButtonProps,
} from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import * as React from "react";

type VitalsInputModalProps = React.PropsWithChildren<
  Pick<UseDisclosureReturn, "isOpen" | "onClose">
> & { title: string };

type BaseVitalsInputModalProps = {
  onSave: (value: string) => void;
  onValidate?: (value: string) => string | boolean;
  initialValue?: string;
} & VitalsInputModalProps;

type VitalsInputModalValueBoxProps = {
  selector?: React.ReactNode;
  error?: string;
} & BoxProps;

const NON_ZERO_KEYPAD_INTS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function VitalsInputModalValueBox(props: VitalsInputModalValueBoxProps) {
  const { children, selector, error, ...restProps } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      padding="0 15px"
      position="relative"
      {...restProps}
    >
      {selector}
      <Box display="flex" alignItems="center" height="92px" maxWidth="100%">
        <chakra.span
          fontWeight="400"
          fontSize="2.5rem"
          overflow="hidden"
          maxWidth="100%"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {children}
        </chakra.span>
      </Box>
      {error && (
        <Box position="absolute" bottom="0" fontSize="0.75rem" color="red">
          {error}
        </Box>
      )}
    </Box>
  );
}

type VitalsInputModalKeypadExtraKey = {
  onClick: () => void;
  label: string;
};

type VitalsInputModalKeypadExtraKeys = [
  VitalsInputModalKeypadExtraKey,
  VitalsInputModalKeypadExtraKey,
  VitalsInputModalKeypadExtraKey
];

type VitalsInputModalKeypadProps = {
  onClickKey: (value: string) => void;
  onSave: () => void;
  onDelete: () => void;
  customKeyLabel?: string;
  onClickCustomKey?: () => void;
  extraKeys?: VitalsInputModalKeypadExtraKeys;
  isLoading?: boolean;
};

const SHARED_BTN_PROPS: ButtonProps = {
  outline: "none",
  borderRadius: 0,
  minWidth: "unset",
  height: "75px",
  _hover: { color: "white", bg: "blue" },
  _focus: { outline: "none" },
};

function VitalsInputModalKeypad(props: VitalsInputModalKeypadProps) {
  const {
    onClickKey,
    onSave,
    onDelete,
    onClickCustomKey,
    customKeyLabel,
    extraKeys,
    isLoading = false,
  } = props;

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(3, 100px)"
      gridGap="1px"
      bg="#D3D3D6"
      borderTop="1px solid #D3D3D6"
      borderBottomRadius="10px"
    >
      {NON_ZERO_KEYPAD_INTS.map((num) => (
        <Button
          disabled={isLoading}
          key={num}
          onClick={() => onClickKey(String(num))}
          bg="white"
          color="black"
          {...SHARED_BTN_PROPS}
        >
          {num}
        </Button>
      ))}
      {!onClickCustomKey || !customKeyLabel ? (
        <Box bg="#F7F7F7" />
      ) : (
        <Button
          disabled={isLoading}
          onClick={onClickCustomKey}
          bg="#F7F7F7"
          color="black"
          {...SHARED_BTN_PROPS}
        >
          {customKeyLabel}
        </Button>
      )}
      <Button
        disabled={isLoading}
        onClick={() => onClickKey(String(0))}
        bg="white"
        color="black"
        {...SHARED_BTN_PROPS}
      >
        0
      </Button>
      <Button
        disabled={isLoading}
        onClick={onDelete}
        bg="#F7F7F7"
        {...SHARED_BTN_PROPS}
      >
        <Icon color="red" as={EmrRemove} />
      </Button>
      {extraKeys &&
        extraKeys.map(({ onClick, label }, index) => (
          <Button
            disabled={isLoading}
            key={index}
            onClick={onClick}
            bg="#F7F7F7"
            color="black"
            {...SHARED_BTN_PROPS}
          >
            {label}
          </Button>
        ))}
      <Button
        disabled={isLoading}
        onClick={onSave}
        gridColumn="span 3"
        height="75px"
        bg="#007AFF"
        color="white"
        borderTopRadius="0"
        borderBottomRadius="md"
        minWidth="unset"
      >
        {isLoading ? <Spinner /> : "Enter"}
      </Button>
    </Box>
  );
}

function VitalsInputModal(props: VitalsInputModalProps) {
  const { isOpen, onClose, title, children } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="302px">
        <ModalHeader
          padding="24px"
          height="50px"
          textAlign="center"
          fontSize="1.375rem"
          color="#A2A2A2"
        >
          {title}
        </ModalHeader>
        <ModalBody padding="0">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export type {
  VitalsInputModalProps,
  BaseVitalsInputModalProps,
  VitalsInputModalKeypadExtraKey,
  VitalsInputModalKeypadExtraKeys,
};
export { VitalsInputModal, VitalsInputModalValueBox, VitalsInputModalKeypad };
