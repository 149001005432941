import { axiosPatientInstance } from "config";
import { Ethnicity, Gender, MaritalStatus, Race, Title } from "enums";
import { useAxiosQuery } from "hooks";

type EncounterInfoResponse = {
  firstName: string;
  mi: string;
  lastName: string;
  title: Title;
  maritalStatus: MaritalStatus;
  dob: string;
  ssn: string;
  gender: Gender;
  race: Race;
  ethnicity: Ethnicity;
  requiredValidation: boolean;
  isValidated: boolean;
  isVip: boolean;
  painProtocol: boolean;
  createdAt: string;
  mrn: string;
};

function useEncounterInfo(encounterId: string) {
  return useAxiosQuery(
    ["encounterInfo", { encounterId }],
    () =>
      axiosPatientInstance.get<EncounterInfoResponse>(
        `v1/encounter/${encounterId}/form/info`
      ),
    { staleTime: 0, cacheTime: 0 }
  );
}

export type { EncounterInfoResponse };
export { useEncounterInfo };
