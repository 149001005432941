import React, { forwardRef } from "react";
import {
  Input as CkInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

const ckInputOmittedProps = ["colorScheme", "size"] as const;
type CkInputProps = Omit<ChakraInputProps, typeof ckInputOmittedProps[number]>;

const inputSizes = ["lg", "md", "sm", "single-char"] as const;

type BaseProps = CkInputProps & {
  variant?: CkInputProps["variant"];
  size?: never;
};

type InputFilledProps = CkInputProps & {
  variant?: "filled";
  size?: typeof inputSizes[number];
};

type InputProps = BaseProps | InputFilledProps;

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { size, ...restProps } = props;

  return (
    <CkInput
      ref={ref}
      size={size}
      maxLength={size === "single-char" ? 1 : undefined}
      autoComplete="off"
      {...restProps}
    />
  );
});
Input.displayName = "Input";

export type { InputProps };
export { Input };
