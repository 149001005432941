import { mapAnswersListToFormObject } from "modules";
import {
  TriageIVMedicationForm,
  TriageIVMedicationSection,
  mapFormObjectToAnswersList,
} from "modules/charts-shared";
import {
  IvMedicationPayload,
  IvMedicationPayloadSection,
  TriageIvMedicationResponse,
} from "../api";

function mapSectionToPayload({
  code,
  createdById,
  orderId,
  ...answers
}: TriageIVMedicationSection): IvMedicationPayloadSection {
  return {
    code,
    orderId,
    answers: mapFormObjectToAnswersList(answers),
  };
}

function formToPayload(form: TriageIVMedicationForm): IvMedicationPayload {
  const { ivMedications, ivStarts, medications, treatments } = form;
  return {
    IVMedications: ivMedications?.map(mapSectionToPayload) || [],
    IVStarts: ivStarts?.map(mapSectionToPayload) || [],
    Medications: medications?.map(mapSectionToPayload) || [],
    Treatments: treatments?.map(mapSectionToPayload) || [],
  };
}

function responseToForm(payload: TriageIvMedicationResponse) {
  const { ivMedications, ivStarts, medications, treatments } = payload;

  return {
    ivMedications: ivMedications?.map(
      ({ code, createdById, orderId, answers, order }) => ({
        code,
        createdById,
        orderId,
        order,
        ...mapAnswersListToFormObject(answers),
      })
    ),
    ivStarts: ivStarts?.map(
      ({ code, createdById, orderId, answers, order }) => ({
        code,
        createdById,
        orderId,
        order,
        ...mapAnswersListToFormObject(answers),
      })
    ),
    medications: medications?.map(
      ({ code, createdById, orderId, answers, order }) => ({
        code,
        createdById,
        orderId,
        order,
        ...mapAnswersListToFormObject(answers),
      })
    ),
    treatments: treatments?.map(
      ({ code, createdById, orderId, answers, order }) => ({
        code,
        createdById,
        orderId,
        order,
        ...mapAnswersListToFormObject(answers),
      })
    ),
  };
}

export { formToPayload, responseToForm };
