import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { FavoriteDose } from "modules/order-list/types";

function useDoseFavorites(medicationId: string) {
  return useAxiosQuery(["doseFavorites"], () =>
    axiosChartInstance.get<FavoriteDose[]>(
      `v1/order-form/doses/favorites/${medicationId}`,
      {}
    )
  );
}
export { useDoseFavorites };
