import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const SquaredDollarSign = createIcon({
  path: (
    <path
      d="M3.41119 20H16.5888C18.8702 20 20 18.8702 20 16.6323V3.36773C20 1.12982 18.8702 0 16.5888 0H3.41119C1.14068 0 0 1.11896 0 3.36773V16.6323C0 18.881 1.14068 20 3.41119 20ZM3.43292 18.251C2.34655 18.251 1.74905 17.6752 1.74905 16.5454V3.45464C1.74905 2.32482 2.34655 1.74905 3.43292 1.74905H16.5671C17.6426 1.74905 18.251 2.32482 18.251 3.45464V16.5454C18.251 17.6752 17.6426 18.251 16.5671 18.251H3.43292ZM10.0054 16.1869C10.2988 16.1869 10.5269 15.9804 10.5269 15.6763V14.9158C12.2108 14.7529 13.5144 13.7751 13.5144 12.1347C13.5144 10.6573 12.5584 9.76643 10.6681 9.42966L10.5269 9.39707V6.36611C11.2982 6.50733 11.8414 6.97447 12.0587 7.70234C12.1673 8.02825 12.3737 8.23466 12.7213 8.23466C13.0907 8.23466 13.3623 7.97393 13.3623 7.5937C13.3623 7.48506 13.3297 7.37643 13.308 7.25693C13.0255 6.08365 11.9174 5.27974 10.5269 5.11678V4.36719C10.5269 4.06301 10.2988 3.8566 10.0054 3.8566C9.71211 3.8566 9.49484 4.06301 9.49484 4.36719V5.11678C7.84356 5.30147 6.68115 6.33351 6.68115 7.81097C6.68115 9.19066 7.60456 10.0163 9.41879 10.44L9.49484 10.4617V13.6773C8.51711 13.5361 7.94134 12.9929 7.76752 12.2651C7.64802 11.8957 7.41988 11.7219 7.09397 11.7219C6.72461 11.7219 6.46388 11.9826 6.46388 12.3628C6.46388 12.4823 6.48561 12.591 6.5182 12.6996C6.82238 13.9489 8.03911 14.7529 9.49484 14.8941V15.6763C9.49484 15.9804 9.71211 16.1869 10.0054 16.1869ZM9.49484 9.12548L9.41879 9.10375C8.44106 8.82129 8.01738 8.38675 8.01738 7.74579C8.01738 7.08311 8.57143 6.52906 9.49484 6.36611V9.12548ZM10.5269 10.7224L10.755 10.7768C11.8088 11.0701 12.1782 11.5155 12.1782 12.2108C12.1782 12.9712 11.635 13.547 10.5269 13.6773V10.7224Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { SquaredDollarSign };
