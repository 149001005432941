import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";

function useEncounterMOTFile(encounterId: string) {
  return useQuery(
    ["MOTFile", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(
        `v1/encounter/${encounterId}/form/files/mot`,
        { responseType: "blob" }
      ),
    { staleTime: 60000 }
  );
}

export { useEncounterMOTFile };
