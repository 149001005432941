import { Box, chakra, Flex, Icon } from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { useUsers } from "modules";
import { PersonalInfo } from "modules/in-process/components/in-process-patients/PersonalInfo";
import { useSupervisingProviderList } from "modules/reception/api";
import React from "react";
import { DividerList, Loading } from "shared";
import { Input, InputProps } from "shared/input";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";

type SearchInputProps = InputProps & { label: string };

const SearchInput = ({ label, ...rest }: SearchInputProps) => {
  return (
    <Box>
      <chakra.p p={1} pl={3} fontSize="15px" color="gray.650">
        {label}
      </chakra.p>
      <Input w="240px" {...rest} />
    </Box>
  );
};

type ProviderInfoProps = {
  fullName: string;
  pictureUrl?: string;
  description: string;
  selected?: boolean;
  handleSelect: () => void;
};

const ProviderInfo = ({
  fullName,
  description,
  pictureUrl,
  selected,
  handleSelect,
}: ProviderInfoProps) => {
  return (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleSelect}
    >
      <PersonalInfo
        name={fullName}
        pictureUrl={pictureUrl}
        description={description}
      />
      {selected && <Icon as={EmrCheck} fontSize="1rem" color={"blue"} mr={2} />}
    </Box>
  );
};

type SearchProviderInputProps = {
  onSelect: (provider: string) => void;
};

const SearchProviderInput = ({ onSelect }: SearchProviderInputProps) => {
  const toast = useToast();

  const [search, setSearch] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");

  const [userSelected, setUserSelected] = React.useState<
    { id: string; fullName: string } | undefined
  >(undefined);

  const debouncedLastName = debounce(1000, setLastName);
  const debouncedFirstName = debounce(1000, setFirstName);

  const {
    data: usersData,
    isLoading: isUsersLoading,
    error: usersError,
  } = useUsers({
    userType: "Provider",
    search: search.trim(),
    options: { enabled: !!search.trim() },
  });

  const {
    data: supervisingProviderData,
    isLoading: isSupervisingProviderLoading,
    error: supervisingProviderError,
  } = useSupervisingProviderList(
    {
      page: 1,
      size: 50,
      search: search.trim(),
    },
    { enabled: !!search.trim() }
  );

  React.useEffect(() => {
    setSearch(`${firstName} ${lastName}`);
    if (firstName && lastName) {
      onSelect(`${lastName}, ${firstName}`);
      setUserSelected(undefined);
    } else {
      onSelect("");
    }
  }, [lastName, firstName, onSelect]);

  React.useEffect(() => {
    if (userSelected) {
      onSelect(userSelected?.fullName ?? "");
    }
  }, [userSelected, onSelect]);

  React.useEffect(() => {
    if (usersError) {
      toast({
        status: "error",
        description: extractApiErrorMessage(usersError),
      });
    }
  }, [usersError, toast]);

  React.useEffect(() => {
    if (supervisingProviderError) {
      toast({
        status: "error",
        description: extractApiErrorMessage(supervisingProviderError),
      });
    }
  }, [supervisingProviderError, toast]);

  return (
    <Box>
      <chakra.p
        p={4}
        color="gray.550"
        fontWeight="400"
        fontSize="14px"
        textAlign="center"
      >
        Search or manually enter a Provider below.
      </chakra.p>
      <Flex justifyContent="space-between">
        <SearchInput
          label="Last Name"
          onChange={(e) => debouncedLastName(e.target.value)}
        />
        <SearchInput
          label="First Name"
          onChange={(e) => debouncedFirstName(e.target.value)}
        />
      </Flex>

      {isUsersLoading && isSupervisingProviderLoading && <Loading p={4} />}

      <Box
        my={4}
        borderRadius="10px"
        bg="white"
        _focus={{
          boxShadow: "var(--chakra-shadows-outline)",
          outline: "none",
        }}
        outline="none"
        overflow="auto"
        maxH="100%"
      >
        <DividerList dividerLeftMargin="44px">
          {usersData?.data.map((item) => {
            const nameSplited = item.fullName.split(" ");
            const fullName = `${nameSplited[1]}, ${nameSplited[0]}`;
            return (
              <ProviderInfo
                key={item.id}
                fullName={item.fullName}
                description={item.teams}
                pictureUrl={item.pictureUrl}
                handleSelect={() => {
                  setUserSelected({ id: item.id, fullName });
                }}
                selected={item.id === userSelected?.id}
              />
            );
          })}
          {supervisingProviderData?.data.content.map((item) => {
            return (
              <ProviderInfo
                key={item.id}
                fullName={`${item.firstName} ${item.lastName}`}
                description={"Supervising Provider"}
                handleSelect={() => {
                  setUserSelected({
                    id: item.id,
                    fullName: `${item.lastName}, ${item.firstName}`,
                  });
                }}
                selected={item.id === userSelected?.id}
              />
            );
          })}
        </DividerList>
      </Box>
    </Box>
  );
};

export { SearchProviderInput };
