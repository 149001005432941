import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const AYVALogs = createIcon({
  path: (
    <path
      d="M6.6693 1.08129C6.72455 0.378848 7.13496 0 7.89266 0H15.4459C16.1957 0 16.614 0.378848 16.6693 1.08129H6.6693ZM5.0592 3.35438C5.16969 2.60458 5.54854 2.17837 6.38516 2.17837H16.9455C17.7901 2.17837 18.161 2.60458 18.2715 3.35438H5.0592ZM8.29519 17.8848C8.63457 17.427 8.89503 16.8982 9.04499 16.3378H17.3165C18.0426 16.3378 18.453 15.9511 18.453 15.1855V7.33228C18.453 6.56669 18.0426 6.18784 17.3165 6.18784H6.00631C5.2723 6.18784 4.86977 6.56669 4.86977 7.33228V9.76322C4.61721 9.72376 4.35675 9.69219 4.09629 9.69219C3.83583 9.69219 3.57538 9.72376 3.32281 9.76322V7.206C3.32281 5.51697 4.20679 4.64088 5.9116 4.64088H17.4112C19.116 4.64088 20 5.51697 20 7.206V15.3197C20 17.0008 19.116 17.8848 17.4112 17.8848H8.29519ZM4.06472 18.9424C1.83899 18.9424 0 17.1113 0 14.8777C0 12.6519 1.83899 10.8208 4.06472 10.8208C6.29045 10.8208 8.12944 12.6519 8.12944 14.8777C8.12944 17.0955 6.27466 18.9424 4.06472 18.9424ZM4.06472 15.0592C4.77506 15.0592 5.32755 14.4751 5.32755 13.6701C5.32755 12.944 4.77506 12.3362 4.06472 12.3362C3.35438 12.3362 2.80189 12.944 2.80189 13.6701C2.80189 14.4751 3.35438 15.0592 4.06472 15.0592ZM4.06472 17.9163C5.21705 17.9163 6.10103 17.3165 6.3457 16.7798V16.7482C6.09313 16.251 5.2644 15.7064 4.06472 15.7064C2.87293 15.7064 2.03631 16.251 1.78374 16.7403V16.7798C2.02052 17.3165 2.92028 17.9163 4.06472 17.9163Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 19",
});

export { AYVALogs };
