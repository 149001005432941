import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetFacesheetPDF(
  encounterId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["patientFacesheet", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/patient-facesheet`, {
        params: { encounterId },
        responseType: "blob",
      }),
    { ...options, staleTime: 5 * 60 * 1000 }
  );
}

export { useGetFacesheetPDF };
