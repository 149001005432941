import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ClosedRoom = createIcon({
  path: (
    <path
      d="M3.4228 19.0425C1.19142 19.0425 0 17.8713 0 15.65V3.39251C0 1.17122 1.19142 0 3.4228 0H20.5772C22.8086 0 24 1.18132 24 3.39251V15.65C24 17.8612 22.8086 19.0425 20.5772 19.0425H3.4228ZM3.61464 16.7909H20.3854C21.2638 16.7909 21.7484 16.3467 21.7484 15.4178V3.62474C21.7484 2.69584 21.2638 2.25158 20.3854 2.25158H3.61464C2.72613 2.25158 2.25158 2.69584 2.25158 3.62474V15.4178C2.25158 16.3467 2.72613 16.7909 3.61464 16.7909ZM8.78418 13.7417C8.22886 13.7417 7.7947 13.2974 7.7947 12.7421C7.7947 12.4796 7.89567 12.2373 8.08751 12.0555L10.6117 9.53134L8.08751 7.00715C7.89567 6.81531 7.7947 6.57299 7.7947 6.32057C7.7947 5.76525 8.22886 5.34119 8.78418 5.34119C9.06689 5.34119 9.29912 5.43206 9.48086 5.6239L12.005 8.13799L14.5393 5.6239C14.7514 5.41186 14.9735 5.32099 15.2461 5.32099C15.8014 5.32099 16.2356 5.75515 16.2356 6.31048C16.2356 6.57299 16.1346 6.79512 15.9327 6.99706L13.4186 9.53134L15.9327 12.0555C16.1245 12.2373 16.2255 12.4796 16.2255 12.7421C16.2255 13.2974 15.7913 13.7417 15.236 13.7417C14.9432 13.7417 14.711 13.6306 14.5191 13.4388L12.005 10.9348L9.50105 13.4388C9.30921 13.6407 9.06689 13.7417 8.78418 13.7417Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 26",
});

export { ClosedRoom };
