import { switchAnatomy } from "@chakra-ui/anatomy";
import { theme } from "@chakra-ui/react";
import { PartsStyleFunction } from "@chakra-ui/theme-tools";

const baseSwitchTheme = theme.components.Switch;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const baseStyle: PartsStyleFunction<typeof switchAnatomy> = (props) => {
  const { colorScheme } = props;
  const { track = {}, ...rest } = baseSwitchTheme.baseStyle(props);

  return {
    track: {
      ...track,
      _checked: {
        bg: colorScheme,
      },
    },
    ...rest,
  };
};

export default {
  ...baseSwitchTheme,
  baseStyle,
};
