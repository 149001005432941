import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";

function useCheckWhitelistAccess() {
  return useAxiosMutation(["whitelist-check"], (ip: string) =>
    axiosIdentityInstance.post<boolean>(`/v1/whitelist/check/${ip}`)
  );
}

export { useCheckWhitelistAccess };
