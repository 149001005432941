import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { updateInProcessListItemCache } from "modules/in-process/utils";
import { useQueryClient } from "react-query";

type UseUpdateAllergiesPayload = {
  allergies: "Yes" | "No";
};

function useUpdateAllergies(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateAllergies", { encounterId }],
    (payload: UseUpdateAllergiesPayload) =>
      axiosPatientInstance.post(
        `v1/InProcess/${encounterId}/allergies`,
        payload
      ),
    {
      onSuccess: (_, { allergies }) => {
        queryClient.invalidateQueries(["patientDetails", { encounterId }]);
        updateInProcessListItemCache(queryClient, encounterId, {
          hasFoodAllergies: allergies === "Yes",
        });
      },
    }
  );
}

export type { UseUpdateAllergiesPayload };
export { useUpdateAllergies };
