const nextRoutesNurse = [
  "chief-complaint",
  "arrival-info",
  "vitals",
  "medical-hx",
  "assessments",
  "order-form",
  "iv-and-medications",
  "ekg",
  "procedures",
  "progress",
  "actions",
  "order-history",
  "supplies",
  "disposition",
];

const nextRoutesProvider = [
  "arrival-info",
  "medical-hx",
  "vitals",
  "order-history",
  "hpi",
  "ros",
  "physical-exam",
  "order-form",
  "iv-and-medications",
  "radiology",
  "labs",
  "ekg",
  "progress",
  "procedures",
  "mdm",
  "clinical-impression",
  "disposition",
  "supplies",
  "procedural-sedation-note",
];

export { nextRoutesNurse, nextRoutesProvider };
