import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFavorite } from "modules/charts-shared/types";

type ChartFavoriteProps = {
  sectionCode: string;
  chartCode: string;
};

function useChartFavorites({ sectionCode, chartCode }: ChartFavoriteProps) {
  return useAxiosQuery(["chartFavorites", { sectionCode }], () =>
    axiosChartInstance.get<ChartFavorite[]>(
      `v1/favorites/${chartCode}/${sectionCode}`
    )
  );
}

export { useChartFavorites };
