import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { CloseCircle } from "icons";
import { usePatchEncounterField } from "modules/reception/api";
import { EncounterEmergencyContactForm } from "modules/reception/types";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormSelectFilter, Label, NumberInputMask } from "shared";
import { enumMapper } from "utils";

type EmergencyContactEditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  emergencyContact?: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    relationship?: string;
    leaveMessage?: boolean;
  };
};

const relationToPatientOptions = enumMapper.getOptions("relationToPatient");

function EmergencyContactEditModal({
  isOpen,
  onClose,
  emergencyContact,
}: EmergencyContactEditModalProps) {
  const { encounterId = "" } = useParams();
  const { register, reset, watch, setValue, handleSubmit, control } =
    useForm<EncounterEmergencyContactForm>({
      defaultValues: {
        firstName: "",
        lastName: "",
        relationship: "",
        phone: "",
        leaveMessage: false,
      },
    });

  const { mutateAsync: updateEncounter, isLoading } = usePatchEncounterField({
    encounterId,
    reloadInfo: true,
    reloadSections: ["inProcess"],
  });

  useEffect(() => {
    if (!emergencyContact) return;
    reset(emergencyContact);
  }, [emergencyContact, reset]);
  const leaveMessage = watch("leaveMessage");

  const onSubmit = async (values: EncounterEmergencyContactForm) => {
    const paths = [
      {
        path: "emergencyContact/firstName",
        value: values.firstName,
      },
      {
        path: "emergencyContact/lastName",
        value: values.lastName,
      },
      {
        path: "emergencyContact/relationship",
        value: values.relationship,
      },
      {
        path: "emergencyContact/phone",
        value: values.phone,
      },
      {
        path: "emergencyContact/leaveMessage",
        value: values.leaveMessage,
      },
    ];
    await updateEncounter(paths);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="520px"
        margin="auto"
        maxW="unset"
        borderRadius="10px"
      >
        <ModalHeader>
          <Flex alignItems="center" justify="space-between">
            <Text fontSize="1.25rem" color="gray.650">
              Emergency Contact
            </Text>
            <Button
              disabled={isLoading}
              variant="icon"
              onClick={() => handleSubmit(onSubmit)()}
              isLoading={isLoading}
            >
              <CloseCircle color="gray.650" />
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          rowGap="1rem"
          display="flex"
          flexDirection="column"
          pb="2rem"
        >
          <Flex columnGap="1.25rem">
            <FormControl>
              <Label>First Name</Label>
              <Input {...register("firstName")} />
            </FormControl>
            <FormControl>
              <Label>Last Name</Label>
              <Input {...register("lastName")} />
            </FormControl>
          </Flex>
          <FormControl label="Relation to Patient">
            <FormSelectFilter
              name="relationship"
              items={relationToPatientOptions}
              labelAccessor="label"
              valueAccessor="value"
              control={control}
            />
          </FormControl>
          <Flex columnGap="1.25rem">
            <FormControl flex={1}>
              <Label>Phone Number</Label>

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <NumberInputMask
                    type="text"
                    mask="(###) ###-####"
                    inputMode="tel"
                    {...field}
                  />
                )}
              />
            </FormControl>
            <Flex alignItems="flex-end" pb="0.5rem" flex={1}>
              <Checkbox
                isChecked={leaveMessage}
                onChange={(e) => setValue("leaveMessage", e.target.checked)}
              >
                Leave a message
              </Checkbox>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { EmergencyContactEditModal };
