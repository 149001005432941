const PROCEDURES_CONTAINER_PADDING = "0.5rem 0.6875rem 0.5rem 0.3125rem";
const PROCEDURES_CONTAINER_PADDING_2 = "0.5rem 0.6875rem 0.5rem 0.6875rem";
const PROCEDURES_ROW_TEMPLATE_COLUMNS = "200px 1fr 30px";
const PROCEDURES_ROW_TEMPLATE_COLUMNS_2 = "30px 200px 1fr";

export {
  PROCEDURES_CONTAINER_PADDING,
  PROCEDURES_CONTAINER_PADDING_2,
  PROCEDURES_ROW_TEMPLATE_COLUMNS,
  PROCEDURES_ROW_TEMPLATE_COLUMNS_2,
};
