const sectionCode = "SPRO2";
const chartCode = "C044x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T44Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T44Procedures };
