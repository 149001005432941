import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCHC";
const triageChartCode = "T012x";

function N12ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A016"
                  label="ocurred"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A007"
                  label="just PTA"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T012xSCHCQ001A001"
                  defaultValue={0}
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A002"
                  label="min ago"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A003"
                  label="hours ago"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A004"
                  label="days ago"
                />
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A008"
                  label="lost consciousness"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A009"
                  label="dazed"
                />
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSCHCQ001A018"
                  label="post injury decreased LOC"
                />
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A019" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="nausea"
                  name="Q001.T012xSCHCQ001A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="vomiting x"
                  name="Q001.T012xSCHCQ001A009"
                />
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q001.T012xSCHCQ001A014"
                />
                <ChartRowElement type="input" name="Q001.T012xSCHCQ001A015" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Quality"
            >
              Quality
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="pain"
                  name="Q002.T012xSCHCQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="swelling"
                  name="Q002.T012xSCHCQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="tenderness"
                  name="Q002.T012xSCHCQ002A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="numbness"
                  name="Q002.T012xSCHCQ002A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="tingling"
                  name="Q002.T012xSCHCQ002A005"
                />
                <ChartRowElement type="input" name="Q002.T012xSCHCQ002A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  type="option"
                  name="Q002.T012xSCHCQ002A007"
                />
                <ChartRowElement type="input" name="Q002.T012xSCHCQ002A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="head"
                  name="Q004.T012xSCHCQ004A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="face"
                  name="Q004.T012xSCHCQ004A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="nose"
                  name="Q004.T012xSCHCQ004A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mouth"
                  name="Q004.T012xSCHCQ004A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lip"
                  name="Q004.T012xSCHCQ004A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chin"
                  name="Q004.T012xSCHCQ004A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q004.T012xSCHCQ004A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="back"
                  name="Q004.T012xSCHCQ004A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chest"
                  name="Q004.T012xSCHCQ004A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="abdomen"
                  name="Q004.T012xSCHCQ004A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="coccyx"
                  name="Q004.T012xSCHCQ004A011"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A012"
                  label="L"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="eye"
                  name="Q004.T012xSCHCQ004A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ear"
                  name="Q004.T012xSCHCQ004A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q004.T012xSCHCQ004A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q004.T012xSCHCQ004A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q004.T012xSCHCQ004A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q004.T012xSCHCQ004A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q004.T012xSCHCQ004A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q004.T012xSCHCQ004A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q004.T012xSCHCQ004A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q004.T012xSCHCQ004A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q004.T012xSCHCQ004A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q004.T012xSCHCQ004A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q004.T012xSCHCQ004A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q004.T012xSCHCQ004A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q004.T012xSCHCQ004A026"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q004.T012xSCHCQ004A027"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A028" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A029"
                  label="R"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="eye"
                  name="Q004.T012xSCHCQ004A030"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ear"
                  name="Q004.T012xSCHCQ004A032"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q004.T012xSCHCQ004A032"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q004.T012xSCHCQ004A033"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q004.T012xSCHCQ004A034"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q004.T012xSCHCQ004A035"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q004.T012xSCHCQ004A036"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q004.T012xSCHCQ004A037"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q004.T012xSCHCQ004A038"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q004.T012xSCHCQ004A039"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q004.T012xSCHCQ004A040"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q004.T012xSCHCQ004A041"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q004.T012xSCHCQ004A042"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q004.T012xSCHCQ004A043"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q004.T012xSCHCQ004A044"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q004.T012xSCHCQ004A045"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A046" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="back:"
                  name="Q004.T012xSCHCQ004A047"
                />
                <ChartRowElement
                  type="cross-option"
                  label="upper"
                  name="Q004.T012xSCHCQ004A048"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mid"
                  name="Q004.T012xSCHCQ004A049"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lower"
                  name="Q004.T012xSCHCQ004A050"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A051" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A052"
                  label="radiating to:"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A053"
                  label="L"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A054"
                  label="R"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A055"
                  label="thigh"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A056"
                  label="leg"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A057" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.T012xSCHCQ004A058"
                  label="other"
                />
                <ChartRowElement type="input" name="Q004.T012xSCHCQ004A059" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fall"
                  name="Q003.T012xSCHCQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="direct blow"
                  name="Q003.T012xSCHCQ003A002"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A003" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="twisting"
                  name="Q003.T012xSCHCQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="crush"
                  name="Q003.T012xSCHCQ003A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="jammed"
                  name="Q003.T012xSCHCQ003A006"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="bite:"
                  fontStyle="italic"
                  name="Q003.T012xSCHCQ003A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="human"
                  name="Q003.T012xSCHCQ003A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="animal"
                  name="Q003.T012xSCHCQ003A010"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A011" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="burn"
                  name="Q003.T012xSCHCQ003A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chemical"
                  name="Q003.T012xSCHCQ003A013"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="GSW"
                  name="Q003.T012xSCHCQ003A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="stab wound"
                  name="Q003.T012xSCHCQ003A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="cut with"
                  name="Q003.T012xSCHCQ003A017"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A018" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="hit by:"
                  name="Q003.T012xSCHCQ003A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="car"
                  name="Q003.T012xSCHCQ003A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="motorcycle"
                  name="Q003.T012xSCHCQ003A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="bycicle"
                  name="Q003.T012xSCHCQ003A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ATV"
                  name="Q003.T012xSCHCQ003A023"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A024" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="safety:"
                  fontStyle="italic"
                  name="Q003.T012xSCHCQ003A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="none"
                  name="Q003.T012xSCHCQ003A026"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hellmet"
                  name="Q003.T012xSCHCQ003A027"
                />
                <ChartRowElement
                  type="cross-option"
                  label="safety glasses"
                  name="Q003.T012xSCHCQ003A028"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A029" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q003.T012xSCHCQ003A030"
                />
                <ChartRowElement type="input" name="Q003.T012xSCHCQ003A031" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N12ChiefComplaint };
