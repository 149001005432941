import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useAddCustomDoseFavorite() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateOrderFormFavorites"],
    (payload: {
      orderFormElementId: string;
      dose: {
        doseAmount: number;
        doseUnit: string;
      };
      concentration?: Partial<{
        doseAmount: number;
        doseUnit: string;
        volumeAmount: number;
        volumeUnit: string;
      }>;
      route: string;
    }) =>
      axiosChartInstance.post(
        `v1/order-form/doses/custom-favorite/${payload.orderFormElementId}`,
        {
          route: payload.route,
          dose: payload.dose,
          concentration: payload.concentration,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["doseFavorites"]);
        queryClient.invalidateQueries(["orderListElements"]);
      },
    }
  );
}
export { useAddCustomDoseFavorite };
