import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartNoteApiUrlParams, getChartNoteApiURL } from "../../utils";

type UseCreateChartNotePayload = {
  note: string;
};

function useCreateChartNote(params: ChartNoteApiUrlParams) {
  const queryClient = useQueryClient();
  const { section, ...rest } = params;
  const restFilter = Object.fromEntries(
    Object.entries(rest).filter(([_, v]) => v != null)
  );

  return useAxiosMutation(
    ["createChartNote", params],
    (input: UseCreateChartNotePayload) =>
      axiosChartInstance.post(getChartNoteApiURL(params), input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartNotes", params]);
        queryClient.invalidateQueries([
          "sectionNotesCount",
          { sectionCode: section, ...restFilter },
        ]);
        queryClient.invalidateQueries(["subSectionNotesCount", params]);
      },
    }
  );
}

export { useCreateChartNote };
