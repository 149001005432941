import { theme } from "@chakra-ui/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Anatomy, PartsStyleFunction } from "@chakra-ui/theme-tools";

const { baseStyle: bs, parts } = theme.components.Menu;

const baseStyle: PartsStyleFunction<
  Omit<
    Anatomy<"button" | "list" | "item" | "command" | "divider" | "groupTitle">,
    "parts"
  >
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
> = (props) => {
  const { list, item, button, ...rest } = bs(props);

  return {
    ...rest,
    list: {
      ...list,
      width: "250px",
      bg: "gray.250",
      padding: "0",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      borderWidth: "0",
      border: "none",
    },
    item: {
      ...item,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 10px 12px 20px",
      lineHeight: "1.25rem",
    },
  };
};

export default {
  baseStyle,
  parts,
};
