import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const MedicationList = createIcon({
  path: (
    <path
      d="M5.47089 11.6936C4.5332 11.6936 4 11.142 4 10.1859V5.68131C4 1.89377 5.93054 0 9.77324 0H14.2594C15.2339 0 15.7671 0.514811 15.7671 1.47089C15.7671 2.42697 15.2339 2.96016 14.2594 2.96016H9.81001C7.9714 2.96016 6.96016 3.93463 6.96016 5.84678V10.1859C6.96016 11.142 6.44535 11.6936 5.47089 11.6936ZM38.5107 11.6936C37.573 11.6936 37.0398 11.142 37.0398 10.1859V5.84678C37.0398 3.93463 35.9918 2.96016 34.1716 2.96016H29.7222C28.7661 2.96016 28.2329 2.42697 28.2329 1.47089C28.2329 0.514811 28.7661 0 29.7222 0H34.2268C38.0695 0 40 1.89377 40 5.68131V10.1859C40 11.142 39.4852 11.6936 38.5107 11.6936ZM18.3412 30.0981C16.3555 30.0981 15.0684 28.811 15.0684 26.7886V15.2972C15.0684 13.2932 16.3555 12.0061 18.3412 12.0061H18.7457V10.5352H18.5986C17.5138 10.5352 16.8703 9.87334 16.8703 8.75179V7.68539C16.8703 6.56384 17.5138 5.90194 18.5986 5.90194H25.4198C26.5046 5.90194 27.1481 6.56384 27.1481 7.68539V8.75179C27.1481 9.87334 26.5046 10.5352 25.4198 10.5352H25.2727V12.0061H25.6956C27.6629 12.0061 28.9499 13.2932 28.9499 15.2972V26.7886C28.9499 28.811 27.6629 30.0981 25.6956 30.0981H18.3412ZM9.77324 35.9816C5.93054 35.9816 4 34.0878 4 30.2819V25.7957C4 24.8212 4.51481 24.288 5.47089 24.288C6.42697 24.288 6.96016 24.8212 6.96016 25.7957V30.1348C6.96016 32.047 7.9714 33.0215 9.81001 33.0215H14.2594C15.2339 33.0215 15.7671 33.5546 15.7671 34.5107C15.7671 35.4484 15.2339 35.9816 14.2594 35.9816H9.77324ZM29.7222 35.9816C28.7661 35.9816 28.2329 35.4484 28.2329 34.5107C28.2329 33.5546 28.7661 33.0215 29.7222 33.0215H34.1716C35.9918 33.0215 37.0398 32.047 37.0398 30.1348V25.7957C37.0398 24.8212 37.5546 24.288 38.5107 24.288C39.4668 24.288 40 24.8212 40 25.7957V30.2819C40 34.0695 38.0695 35.9816 34.2268 35.9816H29.7222Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 44 36",
});

export { MedicationList };
