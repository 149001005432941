import { Concentration } from "modules/order-list/types";

function calculateMedicationAmount(
  concentration: Partial<Concentration>,
  doseAmount: number,
  doseUnit: string,
  weight: number
) {
  let dosage = doseAmount;
  let concentrationAmount = concentration?.doseAmount || 1;
  let concentrationVolume = concentration?.volumeAmount || 1;

  dosage = getAmountInMg(dosage, doseUnit);
  concentrationAmount = getAmountInMg(
    concentrationAmount,
    concentration.doseUnit ?? ""
  );
  concentrationVolume = getVolumeInMl(
    concentrationVolume,
    concentration.volumeUnit ?? ""
  );

  const concentrationByMl = concentrationAmount / concentrationVolume;
  return (dosage / concentrationByMl) * weight;
}

function getAmountInMg(amount: number, unit: string) {
  if (/\bmcg\b/.test(unit)) {
    amount *= 0.001;
  } else if (/\bg\b/.test(unit)) {
    amount *= 1000;
  }

  return amount;
}

function getVolumeInMl(volume: number, unit: string) {
  if (unit === "L") {
    volume *= 1000;
  }

  return volume;
}

export { calculateMedicationAmount, getAmountInMg, getVolumeInMl };
