import * as React from "react";
import { useVirtual } from "react-virtual";
import { Box, BoxProps, Spinner } from "@chakra-ui/react";

type InfiniteListProps<T, K> = {
  rows: T[];
  isLoading: boolean;
  hasMore: boolean;
  fetchMore?: () => K;
  renderRow: (item: T, index: number) => JSX.Element;
  isHorizontal?: boolean;
  spacing?: string;
  scrollToBottomOnRowsChange?: boolean;
  header?: React.ReactElement;
  isGrid?: boolean;
  doNotUseContainerStyle?: boolean;
} & BoxProps;

function getInnerBoxStyleProps(
  isHorizontal: boolean,
  totalBoxSize: number,
  isGrid: boolean
): BoxProps {
  const baseStyle: BoxProps = {
    position: "relative",
  };

  if (isGrid) {
    return {
      ...baseStyle,
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill,minmax(390px, 1fr))",
      gap: "0px 20px",
    };
  }

  if (isHorizontal) {
    return { ...baseStyle, width: `${totalBoxSize}px`, height: "100%" };
  }

  return {
    ...baseStyle,
    width: "100%",
    height: `${totalBoxSize}px`,
  };
}

function getListItemStyleProps(
  isHorizontal: boolean,
  itemStart: number,
  isGrid: boolean
): BoxProps {
  const baseStyle: BoxProps = {
    position: "absolute",
    top: 0,
    left: 0,
    width: !isHorizontal ? "100%" : undefined,
    height: isHorizontal ? "100%" : undefined,
  };

  if (isGrid) {
    return {};
  }

  if (isHorizontal) {
    return { ...baseStyle, transform: `translateX(${itemStart}px)` };
  }

  return { ...baseStyle, transform: `translateY(${itemStart}px)` };
}

function InfiniteList<T, K>(props: InfiniteListProps<T, K>) {
  const {
    rows,
    isLoading,
    hasMore,
    fetchMore,
    renderRow,
    isHorizontal = false,
    scrollToBottomOnRowsChange = false,
    spacing = "16px",
    header,
    isGrid = false,
    doNotUseContainerStyle = false,
    ...boxProps
  } = props;
  const parentRef = React.useRef<HTMLDivElement>(null);
  const { totalSize, virtualItems, scrollToIndex } = useVirtual({
    size: rows.length,
    parentRef,
    horizontal: isHorizontal,
    overscan: isGrid ? rows.length : undefined,
  });

  const observerRef = React.useRef<IntersectionObserver>();

  const loadingRowRef = (ref: HTMLDivElement) => {
    if (ref && observerRef.current) {
      observerRef.current.observe(ref);
    }
  };

  React.useEffect(() => {
    if (scrollToBottomOnRowsChange && rows.length > 0) {
      scrollToIndex(rows.length - 1);
    }
  }, [scrollToIndex, rows, scrollToBottomOnRowsChange]);

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (!isLoading && hasMore && entry.isIntersecting) {
          fetchMore?.();
        }
      },
      { root: parentRef.current, rootMargin: "0px", threshold: 0.9 }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isLoading, fetchMore, hasMore]);

  return (
    <Box
      ref={parentRef}
      height="100%"
      width="100%"
      overflow="overlay"
      {...boxProps}
    >
      {!!header && React.cloneElement(header)}
      <Box {...getInnerBoxStyleProps(isHorizontal, totalSize, isGrid)}>
        {virtualItems.map(({ index, start, measureRef, key }) => {
          return (
            <Box
              ref={measureRef}
              key={key}
              {...getListItemStyleProps(
                isHorizontal,
                start,
                isGrid || doNotUseContainerStyle
              )}
            >
              {React.cloneElement(renderRow(rows[index], index), {
                marginBottom: !isHorizontal ? spacing : undefined,
                marginRight: isHorizontal ? spacing : undefined,
              })}
            </Box>
          );
        })}
        {(hasMore || isLoading) && (
          <Box
            ref={loadingRowRef}
            display="flex"
            justifyContent="center"
            padding="1rem"
            {...getListItemStyleProps(isHorizontal, totalSize, isGrid)}
          >
            <Spinner size="lg" color="blue" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default InfiniteList;
