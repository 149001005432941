import {
  Box,
  Button,
  chakra,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Spinner,
  useMergeRefs,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useScanner,
  useYupValidationResolver,
  useGenerateImagesPdf,
} from "hooks";
import { Clip, DocumentOutline } from "icons";
import { formMessages } from "messages/form";
import { useCreateSummaryMiscellaneous } from "modules/charts-shared/api/mutations/useCreateSummaryMiscellaneousFile";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToolbarHeader, FormControl, DownloadScannerAppModal } from "shared";
import { extractApiErrorMessage } from "utils";
import { Import, Scanner } from "icons";
import * as yup from "yup";
import { EmrClose } from "@medstonetech/slate-icons";

const IMPORT_DOCUMENT_SCHEMA = yup.object().shape({
  fileName: yup.string().required("Document name is required"),
  fileList: yup
    .mixed()
    .test(
      "fileRequired",
      "File is required",
      (files: FileList | null | undefined) => !!files?.item(0)
    ),
});

type ImportDocumentForm = {
  fileName: string;
  fileList: FileList | null | undefined;
};

type AdditionalDocumentModalProps = {
  directScan?: boolean;
  directImport?: boolean;
  encounterId?: string;
  isOpen: boolean;
  onClose: () => void;
  goBack: () => void;
  onChangeFile?: (newFile: Nullable<string | File | Blob>) => Promise<void>;
};

type AdditionalDocumentModes = "menu" | "import" | "imageCapture";

type MenuContentProps = {
  directScan?: boolean;
  directImport?: boolean;
  setMode(mode: AdditionalDocumentModes): void;
  onClose(): void;
  onChangeFile?: (newFile: Nullable<string | File | Blob>) => Promise<void>;
};

function getImgSrc(img?: Nullable<File> | string) {
  if (!img) {
    return "";
  }

  if (typeof img === "string") {
    return img;
  }

  return URL.createObjectURL(img);
}

function ImportDocumentContent(
  props: Omit<AdditionalDocumentModalProps, "isOpen">
) {
  const { onClose, encounterId, goBack, onChangeFile = null } = props;
  const yupResolver = useYupValidationResolver(IMPORT_DOCUMENT_SCHEMA);
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    watch,
    setValue,
    reset,
  } = useForm<ImportDocumentForm>({
    mode: "onChange",
    defaultValues: { fileName: "", fileList: undefined },
    resolver: yupResolver,
  });

  const internalInputRef = React.useRef<HTMLInputElement>(null);
  const { ref, ...restRegister } = register("fileList");
  const fileInputRef = useMergeRefs(ref, internalInputRef);
  const toast = useToast();

  const handleSubmitClick = () => {
    internalInputRef.current?.click();
  };

  const handleRemoveClick = () => {
    setValue("fileList", null, { shouldValidate: true });
  };

  const fileData = watch("fileList");
  const hasFileData = !!fileData?.item(0);
  const fileName = fileData?.item(0)?.name || "file.pdf";

  const { mutateAsync: createMiscellaneousFile, isLoading } =
    useCreateSummaryMiscellaneous(encounterId || "");

  const onSubmit: SubmitHandler<ImportDocumentForm> = async (
    data: ImportDocumentForm
  ) => {
    try {
      const newFile = data.fileList?.item(0);

      if (newFile instanceof File) {
        const formData = new FormData();
        formData.append("file", newFile as Blob, newFile.name);
        formData.append("fileName", data.fileName);

        if (onChangeFile) {
          onChangeFile(newFile);
          return;
        }

        await createMiscellaneousFile(formData);

        toast({
          status: "success",
          description: formMessages.createSuccess("Miscellaneous File"),
        });
        onClose();
      } else {
        reset();
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <ModalContent bg="gray.100" maxWidth="400px">
      <ModalHeader bg="gray.100" p="15px 17px 15px 25px" borderRadius="10px">
        <ToolbarHeader
          bg="gray.100"
          titleText={
            <chakra.span fontSize="0.9375rem" fontWeight="600">
              Upload Document
            </chakra.span>
          }
          leftButtons={[
            <Button
              key="cancelBtn"
              onClick={goBack}
              fontSize="1.0625rem"
              fontWeight="400"
            >
              Go Back
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              isDisabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              fontSize="1rem"
              fontWeight="500"
              isLoading={isLoading}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="20px 25px 30px 25px">
        <Box>
          <Box>
            <FormControl error={errors.fileName?.message}>
              <Box
                p="0 4px 0 12px"
                mb="8px"
                fontSize="0.9375rem"
                fontWeight="500"
              >
                Document Name
              </Box>
              <Input
                variant="unstyled"
                fontSize="1.0625rem"
                h="44px"
                w="100%"
                borderRadius="12px"
                borderColor="gray.450"
                borderStyle="solid"
                borderWidth="1px"
                bg="white"
                {...register("fileName")}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl error={errors.fileList?.message}>
              <HStack mt="21px" mb="10px" p="0 4px 0 12px">
                <Box
                  flexGrow="1"
                  flexBasis="0"
                  fontSize="0.9375rem"
                  fontWeight="500"
                >
                  Attached File
                </Box>
                <Button
                  variant="label"
                  fontSize="0.9375rem"
                  key="remove"
                  minHeight="20px"
                  p="0 10px"
                  color={hasFileData ? "red" : "blue"}
                  rightIcon={
                    <Icon
                      as={Clip}
                      h="15px"
                      w="15px"
                      fontSize="1.125rem"
                      color={hasFileData ? "red" : "blue"}
                    />
                  }
                  onClick={hasFileData ? handleRemoveClick : handleSubmitClick}
                >
                  {hasFileData ? "remove" : "add"}
                </Button>
              </HStack>
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                ref={fileInputRef}
                {...restRegister}
              />
              <InputGroup>
                <InputLeftElement pointerEvents="none" h="44px" w="44px">
                  <Icon
                    as={DocumentOutline}
                    h="18px"
                    w="18px"
                    color={hasFileData ? "blue" : "gray.450"}
                  />
                </InputLeftElement>
                <Input
                  variant="unstyled"
                  value={hasFileData ? `${fileName}` : "No file attached"}
                  h="44px"
                  w="100%"
                  fontSize="1.0625rem"
                  fontWeight="400"
                  borderWidth="1px"
                  borderRadius="12px"
                  borderColor="gray.450"
                  borderStyle="solid"
                  bg="white"
                  color={hasFileData ? "blue" : "gray.450"}
                  readOnly
                />
              </InputGroup>
            </FormControl>
          </Box>
        </Box>
      </ModalBody>
    </ModalContent>
  );
}

function ImageCaptureContent(
  props: Omit<AdditionalDocumentModalProps, "isOpen">
) {
  const { goBack, encounterId, onChangeFile = null } = props;
  const {
    isLoading: isScannerLoading,
    data: scannedFiles,
    scan,
    isScannerDetected,
    refresh,
  } = useScanner({
    isMultiple: true,
  });

  const yupResolver = useYupValidationResolver(
    yup.object().shape({
      fileName: yup.string().required("Document name is required"),
    })
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ImportDocumentForm>({
    mode: "onChange",
    defaultValues: { fileName: "" },
    resolver: yupResolver,
  });

  const toast = useToast();

  const {
    mutateAsync: createMiscellaneousFile,
    isLoading: isUploadingDocument,
  } = useCreateSummaryMiscellaneous(encounterId || "");

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const { generatePdf, isCreatingPdf } = useGenerateImagesPdf();

  const onSubmit = async (data: { fileName: string }) => {
    try {
      const pdfBlob = await generatePdf(scannedFiles);

      if (pdfBlob instanceof File) {
        const formData = new FormData();
        formData.append("file", pdfBlob as Blob, pdfBlob.name);
        formData.append("fileName", data.fileName);

        if (onChangeFile) {
          onChangeFile(pdfBlob);
          return;
        }

        await createMiscellaneousFile(formData);

        toast({
          status: "success",
          description: formMessages.createSuccess("Miscellaneous File"),
        });
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <ModalContent bg="gray.100" minW="32.5rem">
      <ModalHeader
        bg="gray.100"
        borderRadius="10px"
        display="flex"
        flexDir="column"
        gap="0.5rem"
      >
        <ToolbarHeader
          borderTopRadius="10px"
          bg="gray.100"
          titleText={
            <chakra.span fontSize="0.9375rem" fontWeight="600">
              Image Capture
            </chakra.span>
          }
          leftButtons={[
            <Button
              key="cancelBtn"
              onClick={goBack}
              fontSize="1.0625rem"
              fontWeight="400"
            >
              Go Back
            </Button>,
          ]}
        />
        <FormControl error={errors.fileName?.message} mb="0.25rem">
          <Input
            variant="unstyled"
            fontSize="1.0625rem"
            h="44px"
            w="100%"
            borderRadius="12px"
            borderColor="gray.450"
            borderStyle="solid"
            borderWidth="1px"
            bg="white"
            placeholder="Document Name"
            {...register("fileName")}
          />
        </FormControl>
      </ModalHeader>
      <ModalBody
        display="flex"
        flexDir="row"
        borderTop="1px solid"
        borderTopColor="gray.450"
        minH="28.5rem"
        p="0"
      >
        <Box
          bgColor="gray.200"
          display="flex"
          flexDir="column"
          gap="1rem"
          maxH="inherit"
          overflowY="auto"
          justifyContent="flex-start"
          alignItems="center"
          p="1rem 0"
          flex={1}
        >
          {scannedFiles.map((file, index) => {
            const isSelected = file === selectedFile;

            return (
              <Box
                display="flex"
                flexDirection="row"
                minH="110px"
                cursor="pointer"
                gap="0.15rem"
                onClick={() => setSelectedFile(file)}
              >
                <Box display="flex" alignItems="flex-end">
                  <chakra.span fontSize="12px" color="gray.550">
                    {index + 1}
                  </chakra.span>
                </Box>
                <chakra.img
                  src={getImgSrc(file)}
                  objectFit="contain"
                  width="84px"
                  height="110px"
                  border={isSelected ? "2px solid" : "unset"}
                  borderRadius="5px"
                  borderColor="blue"
                />
              </Box>
            );
          })}
        </Box>
        <Box
          flex={4}
          bgColor="gray.450"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box maxW="80%" p="0.5rem 0">
            {selectedFile && (
              <chakra.img
                src={getImgSrc(selectedFile)}
                objectFit="contain"
                h="50%"
              />
            )}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter
        justifyContent="center"
        bgColor="gray.250"
        borderBottomRadius="inherit"
        borderTop="1px solid"
        borderTopColor="gray.450"
        flexDir="column"
      >
        <Box display="flex" gap="1rem">
          <Button
            borderRadius="5px"
            minW="220px"
            minH="40px"
            background="white"
            border="1px solid"
            borderColor="blue"
            color="blue"
            fontWeight={500}
            onClick={isScannerDetected ? scan : refresh}
            _hover={{
              backgroundColor: "white",
              color: "blue",
            }}
            disabled={isScannerLoading || !isScannerDetected}
          >
            {isScannerLoading ? <Spinner color="blue" /> : "Scan"}
          </Button>

          <Button
            borderRadius="5px"
            minW="220px"
            minH="40px"
            background="white"
            border="1px solid"
            borderColor="gray.650"
            color="gray.650"
            fontWeight={500}
            _hover={{
              backgroundColor: "white",
              color: "gray.650",
            }}
            onClick={handleSubmit(onSubmit)}
            isLoading={isCreatingPdf || isUploadingDocument}
            disabled={
              isScannerLoading || !isScannerDetected || scannedFiles.length < 1
            }
          >
            Accept
          </Button>
        </Box>

        {!isScannerDetected && !isScannerLoading && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="1rem"
          >
            <chakra.span fontSize="20px" lineHeight="1rem">
              Scanner not detected
            </chakra.span>
            <chakra.span
              color="blue"
              fontSize="17px"
              onClick={refresh}
              cursor="pointer"
            >
              Click to refresh the connection
            </chakra.span>
          </Box>
        )}
      </ModalFooter>
    </ModalContent>
  );
}

function MenuContent(props: MenuContentProps) {
  const {
    onClose,
    setMode,
    onChangeFile,
    directScan = false,
    directImport = false,
  } = props;
  const {
    scan,
    isLoading: isScannerLoading,
    refresh,
    isScannerDetected,
    data: scannedData,
  } = useScanner();
  const downloadScannerAppModalDisclosure = useDisclosure();
  const toast = useToast();

  const { generatePdf, isCreatingPdf } = useGenerateImagesPdf();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const submitData = React.useCallback(async () => {
    try {
      if (scannedData) {
        const pdfBlob = await generatePdf(scannedData);

        if (pdfBlob instanceof File) {
          onChangeFile?.(pdfBlob);
        }
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [generatePdf, scannedData, toast, onChangeFile]);

  React.useEffect(() => {
    if (scannedData && onChangeFile && scannedData.length > 0) {
      submitData();
    }
  }, [scannedData, submitData, onChangeFile]);

  const onClickScannerProps = !directScan
    ? { onClick: () => setMode("imageCapture") }
    : {
        onClick: () => {
          (isScannerDetected
            ? scan
            : downloadScannerAppModalDisclosure.onOpen)();
        },
      };

  const onClickImportProps = !directImport
    ? { onClick: () => setMode("import") }
    : {
        onClick: () => {
          fileInputRef.current?.click();
        },
      };

  const onFileImportChange = (
    fileEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFile = fileEvent.currentTarget.files?.item(0);
    if (newFile instanceof File) {
      onChangeFile?.(newFile);
    }
  };

  return (
    <>
      <ModalContent bg="gray.100" maxWidth="400px">
        <ModalHeader borderRadius="10px">
          <ToolbarHeader
            borderTopRadius="10px"
            alignItems="center"
            bg="gray.100"
            titleText={
              <chakra.span
                fontSize="0.9375rem"
                fontWeight="600"
                color="gray.650"
              >
                Add Document
              </chakra.span>
            }
            rightButtons={[
              <Icon
                as={EmrClose}
                key="closeBtn"
                onClick={onClose}
                fontSize="1.0625rem"
                fontWeight="400"
                color="gray.650"
                cursor="pointer"
              />,
            ]}
          />
        </ModalHeader>
        <ModalBody borderTop="1px solid" borderColor="gray.450">
          <Box
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            p="1rem 2rem"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="gray.700"
                borderRadius="50%"
                width="3.75rem"
                height="3.75rem"
                _hover={{
                  cursor: "pointer",
                }}
                {...onClickScannerProps}
              >
                {isScannerLoading || isCreatingPdf ? (
                  <Spinner color="white" />
                ) : (
                  <Icon as={Scanner} w="24px" h="19.12px;" color="white" />
                )}
              </Box>

              <chakra.span color="gray.700">Scan</chakra.span>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="gray.700"
                borderRadius="50%"
                width="3.75rem"
                height="3.75rem"
                _hover={{
                  cursor: "pointer",
                }}
                {...onClickImportProps}
              >
                <Icon as={Import} w="38px" color="white" />
              </Box>

              <chakra.span color="gray.700">Import</chakra.span>
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={onFileImportChange}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
      <DownloadScannerAppModal
        isOpen={downloadScannerAppModalDisclosure.isOpen}
        onClose={downloadScannerAppModalDisclosure.onClose}
        refresh={refresh}
      />
    </>
  );
}

function AdditionalDocumentModal(
  props: Omit<AdditionalDocumentModalProps, "goBack">
) {
  const {
    isOpen,
    onClose,
    encounterId,
    onChangeFile,
    directScan = false,
    directImport = false,
  } = props;
  const [mode, setMode] = React.useState<AdditionalDocumentModes>("menu");
  const handleGoBack = () => setMode("menu");

  const getContent = () => {
    if (mode === "menu")
      return (
        <MenuContent
          directScan={directScan}
          directImport={directImport}
          onClose={onClose}
          setMode={setMode}
          onChangeFile={onChangeFile}
        />
      );

    if (mode === "import")
      return (
        <ImportDocumentContent
          onClose={onClose}
          goBack={handleGoBack}
          encounterId={encounterId}
          onChangeFile={onChangeFile}
        />
      );

    if (mode === "imageCapture")
      return (
        <ImageCaptureContent
          onClose={onClose}
          goBack={handleGoBack}
          encounterId={encounterId}
          onChangeFile={onChangeFile}
        />
      );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      {getContent()}
    </Modal>
  );
}

export { AdditionalDocumentModal };
