import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const Lock = createIcon({
  path: (
    <path
      d="M47.801 69.6898C48.1734 68.9548 48.3968 68.0447 48.3968 67.1697C48.3968 65.5246 47.8755 63.8094 46.9818 62.3393L30.8956 35.2124C28.9593 31.9222 25.459 30.2071 21.8098 30.2071C21.2885 30.2071 20.8045 30.2421 20.3204 30.3471C21.4375 28.982 23.0759 28.142 25.2729 27.9319V19.7064C25.2729 6.40544 34.6565 0 44.5242 0C54.3919 0 63.7755 6.40544 63.7755 19.7064V27.9319C68.2439 28.457 70.5526 31.2922 70.5526 36.3325V61.1142C70.5526 66.8546 67.6109 69.6898 61.9509 69.6898H47.801ZM32.2733 27.7919H56.8123V19.0063C56.8123 10.7807 51.1895 6.26544 44.5242 6.26544C37.8588 6.26544 32.2733 10.7807 32.2733 19.0063V27.7919ZM5.72363 72C2.55851 72 0.510498 69.8298 0.510498 67.0997C0.510498 66.2596 0.733918 65.4545 1.21799 64.6845L17.3042 37.5576C18.3096 35.9125 20.0597 35.0724 21.8471 35.0724C23.5972 35.0724 25.3101 35.8775 26.3527 37.5576L42.4762 64.6845C42.9603 65.4545 43.1837 66.2596 43.1837 67.0997C43.1837 69.8298 41.1357 72 37.9333 72H5.72363ZM21.8471 58.139C23.2621 58.139 24.3047 57.229 24.3419 55.8989L24.6398 46.1682C24.7143 44.6281 23.56 43.543 21.8471 43.543C20.1342 43.543 18.9799 44.6281 19.0543 46.1682L19.3522 55.8989C19.3895 57.229 20.3949 58.139 21.8471 58.139ZM21.8471 66.6796C23.7462 66.6796 25.1984 65.3145 25.1984 63.5644C25.1984 61.8843 23.7089 60.4842 21.8471 60.4842C19.9853 60.4842 18.4958 61.8493 18.4958 63.5644C18.4958 65.3145 19.9853 66.6796 21.8471 66.6796Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 71 72",
});

export { Lock };
