import { Icon } from "@chakra-ui/react";
import { EmrCrossCase } from "@medstonetech/slate-icons";
import { useChartForm } from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { SharedChartBaseProps } from "../types";
import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "./chart";

const SECTION_CODE = "SSUP";
const GRID_COLUMNS = "1fr 1fr 1fr";

function Supplies(props: SharedChartBaseProps) {
  const { chartType, chartCode, isReadOnly = false } = props;

  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId: SECTION_CODE,
    sectionLabel: "Supplies",
    chartCode: chartCode,
  });

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={SECTION_CODE}
      >
        <SharedChartSectionHeader
          icon={<Icon as={EmrCrossCase} />}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showNotesBtn={chartType === "Chart" || chartType === "Triage"}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Supplies {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="IV"
              subsectionId="Q001"
              subsectionLabel="IV"
            >
              IV
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A001`}
                  label="IV start kit"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A002`}
                  label="Buretrol"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A003`}
                  label="Insulin syringe"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A004`}
                  label="cathalon: 18 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A005`}
                  label="secondary set"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A006`}
                  label="Blunt fill needle"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A007`}
                  label="cathalon: 20 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A008`}
                  label="butterfly: 23 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A009`}
                  label="Filter needle"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A010`}
                  label="cathalon: 22 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A011`}
                  label="blood collection assembly"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A012`}
                  label="Nasal Atomizer"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A013`}
                  label="cathalon: 24 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A014`}
                  label="saline flush: 10 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A015`}
                  inputName={`Q001.T001x${SECTION_CODE}Q001A016`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A017`}
                  label="loop"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A018`}
                  label="syringe: 3 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A019`}
                  inputName={`Q001.T001x${SECTION_CODE}Q001A020`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A021`}
                  label="arm board"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A022`}
                  label="syringe: 5 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A023`}
                  inputName={`Q001.T001x${SECTION_CODE}Q001A024`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A025`}
                  label="IV tubing"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A026`}
                  label="syringe: 10 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A027`}
                  inputName={`Q001.T001x${SECTION_CODE}Q001A028`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A029`}
                  label="Dial-a-flow"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A030`}
                  label="IM needle: 22 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q001.T001x${SECTION_CODE}Q001A031`}
                  inputName={`Q001.T001x${SECTION_CODE}Q001A032`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Port-a-Cath Access"
              subsectionId="Q002"
              subsectionLabel="Port-a-Cath Access"
            >
              Port-a-Cath Access
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A001`}
                  label="Huber needle: 20 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A002`}
                  label="Port-a-Cath access kit"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A003`}
                  inputName={`Q002.T001x${SECTION_CODE}Q002A004`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A005`}
                  label="Huber needle: 22 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A006`}
                  label="sterile 4 x4"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q002.T001x${SECTION_CODE}Q002A007`}
                  inputName={`Q002.T001x${SECTION_CODE}Q002A008`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Suture Setup"
              subsectionId="Q003"
              subsectionLabel="Suture Setup"
            >
              Suture Setup
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A001`}
                  label="laceration tray"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A002`}
                  label="Betadine"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A003`}
                  label="dermabond adhesive"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A004`}
                  label="syringe: 10 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A005`}
                  label="NS 100 mL bottle"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A006`}
                  label="Band-Aid"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A007`}
                  label="syringe: 5 cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A008`}
                  label="2 x 2 gauze non-sterile (bulk)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A009`}
                  label="staple remover"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A010`}
                  label="needle: 25 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A011`}
                  label="4 x 4 gauze sterile pack"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A012`}
                  label="staple gun"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A013`}
                  label="needle: 27 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A014`}
                  label="kerlix"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A015`}
                  label="suture removal kit"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A016`}
                  label="needle: 30 g"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A017`}
                  label="Kendall sterile 4 x 4"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A018`}
                  label="steri strips"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A019`}
                  label="Xylocaine / administration"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A020`}
                  label="covaderm 4 x6 adhesive dressing"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A021`}
                  label="surgical absorbable hemostat"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A022`}
                  label="topical 4%"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A023`}
                  label="tube gauze"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A024`}
                  label="Neosporin ointment"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A025`}
                  label="topical LET"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A026`}
                  label="#11 scalpel"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A027`}
                  label="2 x 3 non-adherent dressing"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A028`}
                  label="sterile gloves: 6.5"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A029`}
                  label="iodoform packing: 1/4”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A030`}
                  label="I&D kit"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A031`}
                  label="sterile gloves: 7"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A032`}
                  label="iodoform packing: 1/2”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A033`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A034`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A035`}
                  label="sterile gloves: 7.5"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A036`}
                  label="cautery"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A037`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A038`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A039`}
                  label="sterile gloves: 8"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A040`}
                  label="coban 1”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A041`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A042`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A043`}
                  label="sterile gloves: 8.5"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A044`}
                  label="coban: 3”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A045`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A046`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A047`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A048`}
                  label="suture"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A049`}
                  label="paper tape"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q003.T001x${SECTION_CODE}Q003A050`}
                  inputName={`Q003.T001x${SECTION_CODE}Q003A051`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Pelvic Exam"
              subsectionId="Q004"
              subsectionLabel="Pelvic Exam"
            >
              Pelvic Exam
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A001`}
                  label="speculum: small"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A002`}
                  label="Ring forceps"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A003`}
                  inputName={`Q004.T001x${SECTION_CODE}Q004A004`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A005`}
                  label="speculum: medium"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A006`}
                  label="Pelvic exam"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A007`}
                  inputName={`Q004.T001x${SECTION_CODE}Q004A008`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A009`}
                  label="16” cotton tip swab"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A010`}
                  label="peri pad"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q004.T001x${SECTION_CODE}Q004A011`}
                  inputName={`Q004.T001x${SECTION_CODE}Q004A012`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Ortho"
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A001`}
                  label="ortho glass splint: 2”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A002`}
                  label="forearm splint colles: Right: Small"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A003`}
                  label="clavicle strap: S"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A004`}
                  label="ortho glass splint: 3”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A005`}
                  label="forearm splint colles: Right: Large"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A006`}
                  label="clavicle strap: M"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A007`}
                  label="ortho glass splint: 4”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A008`}
                  label="ankle splint -Pneumatic"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A009`}
                  label="clavicle strap: L"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A010`}
                  label="ortho glass splint: 5”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A011`}
                  label="aircast universal ankle sprain care kit"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A012`}
                  label="clavicle strap: XL"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A013`}
                  label="ACE: 2”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A014`}
                  label="boot splint: S"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A015`}
                  label="cervical collar"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A016`}
                  label="ACE: 3”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A017`}
                  label="boot splint: M"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A018`}
                  label="shoulder immobilizer: S"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A019`}
                  label="ACE: 4”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A020`}
                  label="boot splint: L"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A021`}
                  label="shoulder immobilizer: M"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A022`}
                  label="16” cotton tip swab"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A023`}
                  label="ortho shoe"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A024`}
                  label="shoulder immobilizer: L"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A025`}
                  label="coban: 1”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A026`}
                  label="knee immobilizer: 16”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A027`}
                  label="crutches: S"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A028`}
                  label="coban: 3”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A029`}
                  label="knee immobilizer: 20”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A030`}
                  label="crutches: M"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A031`}
                  label="sling: XS"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A032`}
                  label="knee immobilizer: 24”"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A033`}
                  label="crutches: Tall"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A034`}
                  label="sling: S"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A035`}
                  label="finger splint padded: S"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A036`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A037`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A038`}
                  label="sling: M"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A039`}
                  label="finger splint padded: M"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A040`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A041`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A042`}
                  label="sling: L"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A043`}
                  label="finger splint padded: L"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A044`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A045`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A046`}
                  label="sling: XL"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A047`}
                  label="bivalve padded finger splint: S"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A048`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A049`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A050`}
                  label="cotton wrap"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A051`}
                  label="bivalve padded finger splint: L"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A052`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A053`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A054`}
                  label="forearm splint colles: Left: Small"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A055`}
                  label="aluminum foam custom splint"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A056`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A057`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A058`}
                  label="forearm splint colles: Left: Large"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A059`}
                  label="ice pack"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q005.T001x${SECTION_CODE}Q005A060`}
                  inputName={`Q005.T001x${SECTION_CODE}Q005A061`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="GU"
              subsectionId="Q006"
              subsectionLabel="GU"
            >
              GU
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A001`}
                  label="urinal"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A002`}
                  label="Coude catheter: 14 F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A003`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A004`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A005`}
                  label="fracture bed pan"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A006`}
                  label="Coude catheter: 16 F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A007`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A008`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A009`}
                  label="pedi U-bag"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A010`}
                  label="Coude catheter: 20 F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A011`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A012`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A013`}
                  label="Foley cath kit: 8F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A014`}
                  label="leg bag"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A015`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A016`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A017`}
                  label="Foley cath kit: 16F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A018`}
                  label="urine strainer"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A019`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A020`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A021`}
                  label="Foley cath kit: 20F"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A022`}
                  label="urine collection hat"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q006.T001x${SECTION_CODE}Q006A023`}
                  inputName={`Q006.T001x${SECTION_CODE}Q006A024`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="O2 Supplies"
              subsectionId="Q007"
              subsectionLabel="O2 Supplies"
            >
              O2 Supplies
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A001`}
                  label="nasal cannula"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A002`}
                  label="nebulizer: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A003`}
                  label="incentive spirometer"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A004`}
                  label="non-rebreather"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A005`}
                  label="pulse ox: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A006`}
                  inputName={`Q007.T001x${SECTION_CODE}Q007A007`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A008`}
                  label="nebulizer: Adult"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A009`}
                  label="pulse ox: Neo"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q007.T001x${SECTION_CODE}Q007A010`}
                  inputName={`Q007.T001x${SECTION_CODE}Q007A011`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Cardiac Monitoring"
              subsectionId="Q008"
              subsectionLabel="Cardiac Monitoring"
            >
              Cardiac Monitoring
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A001`}
                  label="cardiac monitor"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A002`}
                  label="O2 sat / Pulse ox: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A003`}
                  label="O2 administration"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A004`}
                  label="electrodes"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A005`}
                  label="O2 sat / Pulse ox: Neo"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A006`}
                  label="12 Lead EKG"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A007`}
                  label="O2 sat / Pulse ox: Adult"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A008`}
                  label="nasal cannula"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q008.T001x${SECTION_CODE}Q008A009`}
                  inputName={`Q008.T001x${SECTION_CODE}Q008A010`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Radiology"
              subsectionId="Q009"
              subsectionLabel="Radiology"
            >
              Radiology
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A001`}
                  label="CT"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A002`}
                  label="X-ray"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A003`}
                  label="US"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A004`}
                  label="Omnipaque IV Contrast: 350 / 100 mL"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A005`}
                  label="Omnipaque IV Contrast: 350 / 100 mL"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q009.T001x${SECTION_CODE}Q009A006`}
                  inputName={`Q009.T001x${SECTION_CODE}Q009A007`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Nitrous"
              subsectionId="Q010"
              subsectionLabel="Nitrous"
            >
              Nitrous
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q010.T001x${SECTION_CODE}Q010A001`}
                  label="Oxygen"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q010.T001x${SECTION_CODE}Q010A002`}
                  label="Tubing"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q010.T001x${SECTION_CODE}Q010A003`}
                  label="Mask"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="ENT"
              subsectionId="Q011"
              subsectionLabel="ENT"
            >
              ENT
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A001`}
                  label="ENT Tray"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A002`}
                  inputName={`Q011.T001x${SECTION_CODE}Q011A003`}
                  inputProps={{ width: "50px" }}
                  label="Hydrogen peroxide"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A004`}
                  label="ear loop"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A005`}
                  label="EYE Tray"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A006`}
                  label="eye patch"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A007`}
                  label="alligator forceps"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A008`}
                  label="Flourescein"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A009`}
                  label="ear wick"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A010`}
                  label="nasal speculum"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A011`}
                  label="Morgan lens"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A012`}
                  label="rhino-rocket (epistat balloon)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A013`}
                  inputName={`Q011.T001x${SECTION_CODE}Q011A014`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A015`}
                  label="irrigation tubing"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A016`}
                  label="tetracaine application"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q011.T001x${SECTION_CODE}Q011A017`}
                  inputName={`Q011.T001x${SECTION_CODE}Q011A018`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Resusitation"
              subsectionId="Q012"
              subsectionLabel="Resusitation"
            >
              Resusitation
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A001`}
                  label="cardiac monitor"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A002`}
                  label="end tidal CO2 detector: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A003`}
                  label="60 cc needle tip syringe"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A004`}
                  label="ambu bag: Adult"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A005`}
                  label="suction canister"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A006`}
                  label="clipper / blade"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A007`}
                  label="ambu bag: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A008`}
                  label="oral airway"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A009`}
                  label="defibrillator pads"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A010`}
                  label="ET tube size:"
                  inputName={`Q012.T001x${SECTION_CODE}Q012A011`}
                  inputProps={{ width: "50px" }}
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A012`}
                  label="suction tubing"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A013`}
                  label="Arrow multi-lumen central line kit"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A014`}
                  label="stylet"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A015`}
                  label="yankhauer tip"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A016`}
                  inputName={`Q012.T001x${SECTION_CODE}Q012A017`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A018`}
                  label="end tidal CO2 detector: Adult"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A019`}
                  label="16F NG tube"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q012.T001x${SECTION_CODE}Q012A020`}
                  inputName={`Q012.T001x${SECTION_CODE}Q012A021`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="General Medical"
              subsectionId="Q013"
              subsectionLabel="General Medical"
            >
              General Medical
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A001`}
                  label="LP tray: Adult"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A002`}
                  label="PPE equipment"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A003`}
                  label="large mauve basin"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A004`}
                  label="LP tray: Pedi"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A005`}
                  label="emesin basin"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A006`}
                  inputName={`Q013.T001x${SECTION_CODE}Q013A007`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A008`}
                  label="cervical collar"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A009`}
                  label="emesin bag"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q013.T001x${SECTION_CODE}Q013A010`}
                  inputName={`Q013.T001x${SECTION_CODE}Q013A011`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Order Sets"
              subsectionId="Q014"
              subsectionLabel="Order Sets"
            >
              Order Sets
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A001`}
                  label="Venipuncture: MYCO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A002`}
                  label="Venipuncture: BNP"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A003`}
                  label="Strep Screen (throat swab)"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A004`}
                  label="Venipuncture: CBC"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A005`}
                  label="Venipuncture: BHCG"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A006`}
                  label="FIA / CoV-2 (nasal swab)"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A007`}
                  label="Venipuncture: COMPMET"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A008`}
                  label="Venipuncture: PT"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A009`}
                  label="Respiratory Pathogen Panel (nasal swab)"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A010`}
                  label="Venipuncture: METLYTE 8"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A011`}
                  label="Venipuncture: INR"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A012`}
                  label="CoV-2 Antibody IGM / IGG"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A013`}
                  label="Venipuncture: LIVER FUNCTIONS"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A014`}
                  label="Accucheck (FSGB)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A015`}
                  label="Guaiac Stool"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A016`}
                  label="Venipuncture: BLOOD GAS"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A017`}
                  label="Serum HCG"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A018`}
                  label="HYPLORI"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A019`}
                  label="Venipuncture: MONO SPOT"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A020`}
                  label="UA (urine catch kit)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A021`}
                  inputName={`Q014.T001x${SECTION_CODE}Q014A022`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A023`}
                  label="Venipuncture: CKMB"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A024`}
                  label="UDS (urine catch kit)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A025`}
                  inputName={`Q014.T001x${SECTION_CODE}Q014A026`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A027`}
                  label="Venipuncture: TROP MYO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A028`}
                  label="Urine HCG (urine catch kit)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A029`}
                  inputName={`Q014.T001x${SECTION_CODE}Q014A030`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A031`}
                  label="Venipuncture: LACTIC ACID"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A032`}
                  label="Influenza (nasal swab)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A033`}
                  inputName={`Q014.T001x${SECTION_CODE}Q014A034`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A035`}
                  label="Venipuncture: D-DIMER"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A036`}
                  label="RSV (nasal swab)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q014.T001x${SECTION_CODE}Q014A037`}
                  inputName={`Q014.T001x${SECTION_CODE}Q014A038`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Meds"
              subsectionId="Q015"
              subsectionLabel="Meds"
            >
              Meds
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A001`}
                  label="Decadron 10 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A002`}
                  label="Rocephin 1 gm: IV"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A003`}
                  label="Acetaminophen 15 mg / kg: IV"
                  inputName={`Q015.T001x${SECTION_CODE}Q015A004`}
                  inputProps={{ width: "50px" }}
                  inputLabel="mg"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A005`}
                  label="Dilaudid 2 mg: IM"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A006`}
                  label="Demerol 50 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A007`}
                  label="Ibuprofen 10 mg / kg: PO"
                  inputName={`Q015.T001x${SECTION_CODE}Q015A008`}
                  inputProps={{ width: "50px" }}
                  inputLabel="mg"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A009`}
                  label="Dilaudid 2 mg: IV"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A010`}
                  label="NS 0.9% 1000 mL"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A011`}
                  label="GI Cocktail"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A012`}
                  label="Morphine 4 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A013`}
                  label="NS 0.9% 500 mL"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A014`}
                  label="Fenesin 600 mg"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A015`}
                  label="Zofran 4 mg: IM"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A016`}
                  label="Lido 1% 2cc"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A017`}
                  label="Sudafed 30 mg: PO"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A018`}
                  label="Zofran 4 mg: IV"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A019`}
                  label="Albuterol 2.5 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A020`}
                  label="Afrin: 2 sprays"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A021`}
                  label="Toradol 30 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A022`}
                  label="Atrovent 0.5 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A023`}
                  label="Afrin: 1 bottle"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A024`}
                  label="Toradol 60 mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A025`}
                  label="NS 0.9% 100 mL (for IVPB)"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A026`}
                  label="Claritin 10 mg: PO"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A027`}
                  label="Fentynal 50 mcg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A028`}
                  label="tDap 0.5 mL IM"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A029`}
                  label="Norflex 60 mg: IM"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A030`}
                  label="Rocephin 1 gm: IM"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A031`}
                  label="Acetaminophen 15 mg / kg: PO"
                  inputName={`Q015.T001x${SECTION_CODE}Q015A032`}
                  inputProps={{ width: "50px" }}
                  inputLabel="mg"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q015.T001x${SECTION_CODE}Q015A033`}
                  label="Norflex 60 mg: IV"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Additional Meds"
              subsectionId="Q016"
              subsectionLabel="Additional Meds"
            >
              Additional Meds
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A001`}
                  label="Vit C"
                  inputName={`Q016.T001x${SECTION_CODE}Q016A002`}
                  inputLabel="g: PO"
                  inputProps={{ width: "50px" }}
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A003`}
                  label="Vit B12 1000 iu: IM"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A004`}
                  label="IM Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A005"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A006`}
                  label="Vit D 1200 iu: PO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A007`}
                  label="PO Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A008"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A009`}
                  label="IM Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A010"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A011`}
                  label="NAC 600 mg: PO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A012`}
                  label="PO Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A013"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A014`}
                  label="IM Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A015"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A016`}
                  label="Zinc 50 mg: PO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A017`}
                  label="PO Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A018"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A019`}
                  label="IV Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A020"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A021`}
                  label="Quercetin 1000 mg: PO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A022`}
                  label="PO Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A023"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A024`}
                  label="IV Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A025"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A026`}
                  label="Azithromycin 250 mg: PO"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A027`}
                  label="PO Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A028"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q016.T001x${SECTION_CODE}Q016A029`}
                  label="IV Meds"
                  inputName="Q016.T001x${SECTION_CODE}Q016A030"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Labs"
              subsectionId="Q017"
              subsectionLabel="Labs"
            >
              Labs
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A001`}
                  label="Blood Culture"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A002`}
                  label="ESR / CRP"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A003`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A004`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A005`}
                  label="Strep Culture"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A006`}
                  label="Lactic Acid"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A007`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A008`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A009`}
                  label="Urine Culture"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A010`}
                  label="G6PD Level"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A012`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A013`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A014`}
                  label="PCR / CoV-2 Nasal"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A015`}
                  label="Ferritin"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A016`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A017`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A018`}
                  label="MG Level"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A019`}
                  label="25(OH)D Level"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A020`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A021`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A022`}
                  label="PO4 Level"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A023`}
                  label="Procalcitonin"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A024`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A025`}
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A026`}
                  label="LDH"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A027`}
                  label="Firbrinogen"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q017.T001x${SECTION_CODE}Q017A028`}
                  inputName={`Q017.T001x${SECTION_CODE}Q017A029`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              showAuditsBtn={false}
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={SECTION_CODE}
              subsectionCode="Treatments"
              subsectionId="Q018"
              subsectionLabel="Treatments"
            >
              Treatments
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A001`}
                  label="Aloe Vera"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A002`}
                  label="Massage Therapy"
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A003`}
                  label="P-Wave"
                />
              </ChartRow>
              <ChartRow gridTemplateColumns={GRID_COLUMNS}>
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A004`}
                  inputName={`Q018.T001x${SECTION_CODE}Q018A004`}
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A005`}
                  inputName={`Q018.T001x${SECTION_CODE}Q018A005`}
                />
                <ChartRowElement
                  type="counter-option"
                  name={`Q018.T001x${SECTION_CODE}Q018A006`}
                  inputName={`Q018.T001x${SECTION_CODE}Q018A006`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { Supplies };
