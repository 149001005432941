import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Loading } from "shared";
import { enumMapper } from "utils";
import * as Yup from "yup";
import { formMessages } from "messages/form";
import { useYupValidationResolver } from "hooks";
import { phoneSchema } from "validators";
import { useEncounterPatientInfo } from "../api";
import {
  EncounterAppointmentNotesForm,
  EncounterContactInformationForm,
  EncounterEmergencyContactForm,
  EncounterEmploymentForm,
  EncounterHowDidTheyHearAboutUsForm,
  EncounterIdentificationForm,
  EncounterPainProtocolPatientForm,
  EncounterPrimaryCarePhysicianForm,
  EncounterVIPPatientForm,
} from "../types";

type PatientBasicInfoContextValue = {
  contactInfoFormContext: UseFormReturn<EncounterContactInformationForm>;
  identificationFormContext: UseFormReturn<EncounterIdentificationForm>;
  emergencyContactFormContext: UseFormReturn<EncounterEmergencyContactForm>;
  employmentFormContext: UseFormReturn<EncounterEmploymentForm>;
  howDidTheyHearAboutUsFormContext: UseFormReturn<EncounterHowDidTheyHearAboutUsForm>;
  painProtocolPatientFormContext: UseFormReturn<EncounterPainProtocolPatientForm>;
  vipPatientFormContext: UseFormReturn<EncounterVIPPatientForm>;
  patientAppointmentNotesFormContext: UseFormReturn<EncounterAppointmentNotesForm>;
  primaryCarePhysician: Partial<EncounterPrimaryCarePhysicianForm>;
  updatePrimaryCarePhysician: (pcp: EncounterPrimaryCarePhysicianForm) => void;
};

const PatientBasicInfoContext =
  React.createContext<PatientBasicInfoContextValue | null>(null);

// Default values
const PATIENT_CONTACT_INFO_INITAL: EncounterContactInformationForm = {
  cellphone: "",
  email: "",
  address: "",
  aptSiteNumber: "",
  city: "",
  state: null,
  zipCode: "",
  requiredValidation: false,
  isValidated: false,
};

const PATIENT_IDENTIFICATION_INITIAL: EncounterIdentificationForm = {
  frontFile: null,
  requiredValidation: false,
  isValidated: false,
  isProfilePicture: false,
};

const PATIENT_EMERGENCY_CONTACT_INITIAL: EncounterEmergencyContactForm = {
  name: "",
  firstName: "",
  lastName: "",
  relationship: "",
  phone: "",
  leaveMessage: false,
};

const PATIENT_EMPLOYMENT_INITIAL: EncounterEmploymentForm = {
  name: "",
  occupation: "",
  employerPhone: "",
  employerPhoneExt: "",
};

const PATIENT_HOW_DID_THEY_HEAR_ABOUT_US_INITIAL: EncounterHowDidTheyHearAboutUsForm =
  {
    howDidTheyHearAboutUs: null,
  };

const PAIN_PROTOCOL_PATIENT_INITIAL: EncounterPainProtocolPatientForm = {
  painProtocol: false,
};

const VIP_PATIENT_INITIAL: EncounterVIPPatientForm = {
  isVip: false,
};

type PatientBasicInfoProviderProps = React.PropsWithChildren<{
  encounterId: string;
}>;

const contactInfoValidations = {
  cellphone: phoneSchema(formMessages.validPhone("Phone")).nullable(),
  email: Yup.string().email(formMessages.valid("Email")).nullable(),
  address: Yup.string().required().nullable(),
  aptSiteNumber: Yup.string().nullable(),
  city: Yup.string().required().nullable(),
  state: Yup.mixed().required().nullable(),
  zipCode: Yup.string().required().nullable(),
  isValidated: Yup.boolean().nullable(),
};

const contactInfoSavingValidations = {
  cellphone: phoneSchema(formMessages.validPhone("Phone")).nullable(),
  email: Yup.string().email(formMessages.valid("Email")).nullable(),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.mixed().nullable(),
  zipCode: Yup.string().nullable(),
};

const contactInfoSchema = Yup.object().shape({ ...contactInfoValidations });

const identificationValidations = {
  frontFile: Yup.mixed().required().nullable(),
  isValidated: Yup.boolean().nullable(),
};

const identificationSchema = Yup.object().shape({
  ...identificationValidations,
});

function PatientBasicInfoProvider(props: PatientBasicInfoProviderProps) {
  const { encounterId, children } = props;
  const loadedRef = React.useRef(false);
  const contactInfoSchemaResolver = useYupValidationResolver(contactInfoSchema);
  const contactInfoFormContext = useForm<EncounterContactInformationForm>({
    defaultValues: PATIENT_CONTACT_INFO_INITAL,
    mode: "onChange",
    resolver: contactInfoSchemaResolver,
  });
  const identificationSchemaResolver =
    useYupValidationResolver(identificationSchema);
  const identificationFormContext = useForm<EncounterIdentificationForm>({
    defaultValues: PATIENT_IDENTIFICATION_INITIAL,
    mode: "onChange",
    resolver: identificationSchemaResolver,
  });
  const emergencyContactFormContext = useForm<EncounterEmergencyContactForm>({
    defaultValues: PATIENT_EMERGENCY_CONTACT_INITIAL,
  });
  const employmentFormContext = useForm<EncounterEmploymentForm>({
    defaultValues: PATIENT_EMPLOYMENT_INITIAL,
  });
  const howDidTheyHearAboutUsFormContext =
    useForm<EncounterHowDidTheyHearAboutUsForm>({
      defaultValues: PATIENT_HOW_DID_THEY_HEAR_ABOUT_US_INITIAL,
    });
  const painProtocolPatientFormContext =
    useForm<EncounterPainProtocolPatientForm>({
      defaultValues: PAIN_PROTOCOL_PATIENT_INITIAL,
    });
  const vipPatientFormContext = useForm<EncounterVIPPatientForm>({
    defaultValues: VIP_PATIENT_INITIAL,
  });
  const patientAppointmentNotesFormContext =
    useForm<EncounterAppointmentNotesForm>({
      defaultValues: { appointmentNotes: "" },
    });
  const [primaryCarePhysician, setPrimaryCarePhysician] =
    React.useState<EncounterPrimaryCarePhysicianForm>();

  const { data, isLoading, error } = useEncounterPatientInfo({ encounterId });
  const updatePrimaryCarePhysician = React.useCallback(
    (pcp: EncounterPrimaryCarePhysicianForm) => setPrimaryCarePhysician(pcp),
    []
  );

  const value: PatientBasicInfoContextValue = React.useMemo(
    () => ({
      contactInfoFormContext,
      identificationFormContext,
      emergencyContactFormContext,
      employmentFormContext,
      howDidTheyHearAboutUsFormContext,
      patientAppointmentNotesFormContext,
      primaryCarePhysician: primaryCarePhysician || {},
      updatePrimaryCarePhysician,
      painProtocolPatientFormContext,
      vipPatientFormContext,
    }),
    [
      contactInfoFormContext,
      identificationFormContext,
      emergencyContactFormContext,
      employmentFormContext,
      howDidTheyHearAboutUsFormContext,
      patientAppointmentNotesFormContext,
      primaryCarePhysician,
      updatePrimaryCarePhysician,
      painProtocolPatientFormContext,
      vipPatientFormContext,
    ]
  );

  React.useEffect(() => {
    if (data && !loadedRef.current) {
      const {
        appointmentNotes,
        contactInfo,
        emergencyContact,
        employment,
        howDidTheyHearAboutUs,
        primaryCarePhysician: newPrimaryCarePhysician,
        painProtocol,
        identification,
        isVip,
      } = data.data;

      if (contactInfo) {
        const { state, ...restContactInfo } = contactInfo;
        contactInfoFormContext.reset({
          state: state && enumMapper.valueToOption("usState", state),
          ...restContactInfo,
        });
      }
      identificationFormContext.reset({
        frontFile: identification?.frontUrl || null,
        requiredValidation: identification?.requiredValidation || false,
        isProfilePicture: identification?.isProfilePicture,
        isValidated: identification?.isValidated,
      });
      emergencyContactFormContext.reset(emergencyContact || {});
      employmentFormContext.reset(employment || {});
      howDidTheyHearAboutUsFormContext.reset({
        howDidTheyHearAboutUs:
          howDidTheyHearAboutUs &&
          enumMapper.valueToOption(
            "howDidTheyHearAboutUs",
            howDidTheyHearAboutUs
          ),
      });
      painProtocolPatientFormContext.reset({ painProtocol: !!painProtocol });
      vipPatientFormContext.reset({ isVip: !!isVip });
      patientAppointmentNotesFormContext.reset({
        appointmentNotes: appointmentNotes || "",
      });
      if (newPrimaryCarePhysician) {
        setPrimaryCarePhysician(newPrimaryCarePhysician);
      }

      loadedRef.current = true;
    }
  }, [
    data,
    contactInfoFormContext,
    identificationFormContext,
    emergencyContactFormContext,
    employmentFormContext,
    howDidTheyHearAboutUsFormContext,
    patientAppointmentNotesFormContext,
    painProtocolPatientFormContext,
    vipPatientFormContext,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <>Something went wrong</>;
  }

  return (
    <PatientBasicInfoContext.Provider value={value}>
      {children}
    </PatientBasicInfoContext.Provider>
  );
}

function usePatientBasicInfoContext() {
  const context = React.useContext(PatientBasicInfoContext);

  if (!context) {
    throw new Error(
      "PatientBasicInfoContext value must be defined before using it"
    );
  }

  return context;
}

export type { PatientBasicInfoContextValue, PatientBasicInfoProviderProps };
export {
  PatientBasicInfoProvider,
  usePatientBasicInfoContext,
  contactInfoValidations,
  contactInfoSavingValidations,
};
