import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const TextBox = createIcon({
  path: (
    <path
      d="M3.20171 0C1.35259 0 0.385233 0.950241 0.385233 2.7908V4.62279C0.385233 5.16212 0.71054 5.48743 1.24131 5.48743C1.76351 5.48743 2.08882 5.16212 2.08882 4.62279V2.94489C2.08882 2.1145 2.52541 1.70358 3.313 1.70358H17.7378C18.5254 1.70358 18.962 2.1145 18.962 2.94489V4.62279C18.962 5.16212 19.2959 5.48743 19.8181 5.48743C20.3489 5.48743 20.6742 5.16212 20.6742 4.62279V2.7908C20.6742 0.958802 19.7068 0 17.8491 0H3.20171ZM10.5126 11.9337C11.069 11.9337 11.3772 11.5655 11.3772 10.9749V5.41038H13.252C13.6715 5.41038 13.9625 5.145 13.9625 4.73408C13.9625 4.30605 13.6715 4.05779 13.252 4.05779H7.80738C7.38791 4.05779 7.09684 4.30605 7.09684 4.73408C7.09684 5.145 7.38791 5.41038 7.80738 5.41038H9.66506V10.9749C9.66506 11.557 9.96469 11.9337 10.5126 11.9337ZM1.24131 9.05725C1.9176 9.05725 2.47405 8.5008 2.47405 7.81594C2.47405 7.13965 1.9176 6.57464 1.24131 6.57464C0.556447 6.57464 0 7.13965 0 7.81594C0 8.5008 0.556447 9.05725 1.24131 9.05725ZM19.8181 9.05725C20.4944 9.05725 21.0508 8.5008 21.0508 7.81594C21.0508 7.13965 20.4944 6.57464 19.8181 6.57464C19.1332 6.57464 18.5768 7.13109 18.5768 7.81594C18.5768 8.5008 19.1332 9.05725 19.8181 9.05725ZM3.20171 16H17.8491C19.7068 16 20.6742 15.0412 20.6742 13.2092V11.0348C20.6742 10.4955 20.3403 10.1701 19.8181 10.1701C19.2873 10.1701 18.962 10.4955 18.962 11.0348V13.0551C18.962 13.8855 18.5254 14.2964 17.7378 14.2964H3.313C2.52541 14.2964 2.08882 13.8855 2.08882 13.0551V11.0348C2.08882 10.4955 1.76351 10.1701 1.24131 10.1701C0.71054 10.1701 0.385233 10.4955 0.385233 11.0348V13.2092C0.385233 15.0412 1.35259 16 3.20171 16Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 16",
});

export { TextBox };
