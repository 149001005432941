import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const CheckList = createIcon({
  path: (
    <path
      d="M27.7672 19.5343C30.9117 19.5343 33.5343 16.9251 33.5343 13.7806C33.5343 10.6227 30.9117 8 27.7672 8C24.6093 8 22 10.6227 22 13.7806C22 16.9251 24.6227 19.5343 27.7672 19.5343ZM27.0847 16.8715C26.8037 16.8849 26.6164 16.7244 26.4157 16.4969L24.917 14.6905C24.7832 14.5433 24.7431 14.3827 24.7431 14.1686C24.7431 13.7806 25.0375 13.4728 25.3988 13.4728C25.653 13.4728 25.8403 13.5798 26.0143 13.8073L27.058 15.132L29.4398 11.3051C29.6004 11.0642 29.8011 10.9304 30.0419 10.9304C30.4166 10.9304 30.7377 11.2248 30.7377 11.5593C30.7377 11.7467 30.6842 11.9072 30.5638 12.1079L27.7672 16.4969C27.62 16.7377 27.3791 16.8849 27.0847 16.8715ZM37.5352 14.7975H50.9697C51.5317 14.7975 52 14.3559 52 13.7806C52 13.2186 51.5317 12.777 50.9697 12.777H37.5352C36.9732 12.777 36.5183 13.2186 36.5183 13.7806C36.5183 14.3559 36.9732 14.7975 37.5352 14.7975ZM27.7672 35.0294C30.9117 35.0294 33.5343 32.4068 33.5343 29.2623C33.5343 26.091 30.9117 23.4817 27.7672 23.4817C24.6093 23.4817 22 26.1044 22 29.2623C22 32.4068 24.6227 35.0294 27.7672 35.0294ZM27.7672 33.0758C25.6931 33.0758 23.9536 31.3229 23.9536 29.2623C23.9536 27.1882 25.7065 25.4353 27.7672 25.4353C29.8278 25.4353 31.5807 27.1882 31.5807 29.2623C31.5807 31.3229 29.8278 33.0758 27.7672 33.0758ZM37.5352 30.2658H50.9697C51.5317 30.2658 52 29.8243 52 29.2623C52 28.6869 51.5317 28.2453 50.9697 28.2453H37.5352C36.9732 28.2453 36.5183 28.6869 36.5183 29.2623C36.5183 29.8243 36.9732 30.2658 37.5352 30.2658Z"
      fill="currentColor"
    />
  ),
  viewBox: "15 0 45 45",
});

export { CheckList };
