import { axiosPatientInstance } from "config";
import { EncounterIntegrationAYVAProtocol, IntegrationStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type AYVAResponse = {
  sentRequestInd: boolean;
  sentAYVAPatientInd: boolean;
  sentAYVAEncounterInd: boolean;

  messageInfoTitle?: string;
  messageInfoAction?: string;
  messageInfoMessages?: string[];
};

function useUpdateEncounterIntegrationAYVA(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationAYVA", { encounterId }],
    () =>
      axiosPatientInstance.post<AYVAResponse>(`v1/integration/ayva/`, {
        encounterId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

function useUpdateEncounterIntegrationAYVABMI(
  encounterId: string,
  protocol: EncounterIntegrationAYVAProtocol
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationAYVABMI", { encounterId, protocol }],
    () =>
      axiosPatientInstance.post<AYVAResponse>(`v1/integration/ayva/bmi/`, {
        encounterId,
        protocol,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

function useUpdateEncounterIntegrationAYVAPharmacy(
  encounterId: string,
  protocol: EncounterIntegrationAYVAProtocol
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationAYVAPharmacy", { encounterId, protocol }],
    (successStatus: IntegrationStatus) =>
      axiosPatientInstance.post<AYVAResponse>(`v1/integration/ayva/pharmacy/`, {
        encounterId,
        protocol,
        successStatus,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

type AYVAAllergiesResponse = {
  sentRequestInd: boolean;
  sentAllergiesMedicationsRxInd: boolean;
  erroredAllergiesMedicationsRxcount: number;
  ignoredAllergiesMedicationsRxCount: number;
  completeAllergiesMedicationsRxCount: number;

  messageInfoTitle?: string;
  messageInfoAction?: string;
  messageInfoMessages?: string[];
};

function useUpdateEncounterIntegrationAYVAAllergies(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationAYVAAllergies", { encounterId }],
    () =>
      axiosPatientInstance.post<AYVAAllergiesResponse>(
        `v1/integration/ayva/allergies-medications-rx/`,
        {
          encounterId,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "encounterAYVAAllergyIntegration",
          { encounterId },
        ]);
      },
    }
  );
}

export type { AYVAResponse, AYVAAllergiesResponse };
export {
  useUpdateEncounterIntegrationAYVA,
  useUpdateEncounterIntegrationAYVABMI,
  useUpdateEncounterIntegrationAYVAPharmacy,
  useUpdateEncounterIntegrationAYVAAllergies,
};
