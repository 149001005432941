import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type IvMedicationPayloadSection = {
  code: string;
  answers: { qCode: string; value: unknown }[];
  orderId?: string;
};
type IvMedicationPayload = {
  IVStarts: IvMedicationPayloadSection[];
  IVMedications: IvMedicationPayloadSection[];
  Medications: IvMedicationPayloadSection[];
  Treatments: IvMedicationPayloadSection[];
};

function useUpdateIvAndMedication(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateIvAndMedication", { encounterId }],
    (input: IvMedicationPayload) =>
      axiosChartInstance.post(`v1/triage/iv-medication/${encounterId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "ivAndMedicationCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["medsAndIvCounters", { encounterId }]);
        queryClient.invalidateQueries(["treatmentsCounters", { encounterId }]);
      },
    }
  );
}

export { useUpdateIvAndMedication };
export type { IvMedicationPayload, IvMedicationPayloadSection };
