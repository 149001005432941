import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type AverageWaitTimeResponse = {
  averageHours: number;
};

function useAuditsIncompleteAverageWaitTime() {
  return useAxiosQuery(
    ["audits-incomplete-average-hours"],
    () =>
      axiosPatientInstance.get<AverageWaitTimeResponse>(
        "/v1/audits/incomplete-average-hours"
      ),
    {
      refetchInterval: 300000,
    }
  );
}

export { useAuditsIncompleteAverageWaitTime };
