import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteChartNote(noteId: string, isSubsection?: boolean) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteChartNote", { noteId }],
    () => {
      if (isSubsection) {
        return axiosChartInstance.delete(`v1/ChartNoteSection/${noteId}`);
      }
      return axiosChartInstance.delete(`v1/ChartNoteSection/${noteId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartNotes"]);
        queryClient.invalidateQueries(["sectionNotesCount"]);
        queryClient.invalidateQueries(["subSectionNotesCount"]);
      },
    }
  );
}

export { useDeleteChartNote };
