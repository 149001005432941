import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeletePrimaryCarePhysician(physicianId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deletePRimaryCarePhysician", { physicianId }],
    () => axiosFacilityInstance.delete(`/v1/physician/${physicianId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("primaryCarePhysicians");
      },
    }
  );
}

export { useDeletePrimaryCarePhysician };
