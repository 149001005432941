import { Box, BoxProps } from "@chakra-ui/react";
import * as React from "react";

type VitalsCellProps = BoxProps;
type EdgeVitalsCellProps = VitalsCellProps & { cellPosition: "top" | "bottom" };

function VitalsCell(props: VitalsCellProps) {
  return (
    <Box
      padding="8px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      bg="white"
      color="gray.650"
      fontWeight="600"
      {...props}
    />
  );
}

function LabelVitalsCell(props: VitalsCellProps) {
  return (
    <VitalsCell
      bg="gray.50"
      justifyContent="flex-start"
      paddingLeft="15px"
      {...props}
    />
  );
}

function EdgeVitalsCell(props: EdgeVitalsCellProps) {
  const { cellPosition, ...restProps } = props;

  return (
    <VitalsCell
      position="sticky"
      bg="#F2F2F7"
      top={cellPosition === "top" ? 0 : undefined}
      bottom={cellPosition === "bottom" ? 0 : undefined}
      {...restProps}
    />
  );
}

export type { VitalsCellProps, EdgeVitalsCellProps };
export { VitalsCell, LabelVitalsCell, EdgeVitalsCell };
