import { useMergeRefs } from "@chakra-ui/react";
import * as React from "react";

function useScrollbarWidth<T extends HTMLElement>() {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0);

  const initialRef = React.useRef<T>(null);
  const fnRef = (element: T | null) => {
    if (element) {
      setScrollbarWidth(element.offsetWidth - element.clientWidth);
    }
  };
  const ref = useMergeRefs(initialRef, fnRef);

  React.useEffect(() => {
    const listenerCallback = () => {
      const element = initialRef.current;

      if (element) {
        setScrollbarWidth(element.offsetWidth - element.clientWidth);
      }
    };
    window.addEventListener("resize", listenerCallback);

    return () => window.removeEventListener("resize", listenerCallback);
  }, []);

  return { scrollbarWidth, ref };
}

export { useScrollbarWidth };
