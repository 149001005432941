import { Flex, Icon } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { OrderStatus } from "enums";
import { SquaredChecked } from "icons";
import { TriageIVMedicationSection } from "modules/charts-shared";
import { OrderHeaderCard } from "modules/orders";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

type TreatmentsCountersProps = {
  filter: OrderStatus | null;
  setFilter: (filter: OrderStatus | null) => void;
};

function TreatmentsCounters({ filter, setFilter }: TreatmentsCountersProps) {
  const { watch } = useFormContext();

  const treatments = watch("treatments");

  const handleSetFilter = (newFilter: OrderStatus) => {
    if (filter === newFilter) {
      setFilter(null);
    } else {
      setFilter(newFilter);
    }
  };

  const getCounter = useCallback(
    (status: string) => {
      let counter = 0;
      if (treatments && treatments.length > 0) {
        counter += treatments.filter(
          (treatment: TriageIVMedicationSection) => treatment?.status === status
        ).length;
      }
      return counter;
    },
    [treatments]
  );

  return (
    <Flex columnGap="30px">
      <OrderHeaderCard
        title="Ordered"
        icon={<Icon as={EmrRegistration} />}
        bg={filter === "Ordered" || filter === null ? "orange" : "white"}
        color={filter === "Ordered" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Ordered")}
        counter={getCounter("Ordered")}
        flex={1}
      />
      <OrderHeaderCard
        title="In Process"
        icon={<Icon as={EmrInProcess} />}
        bg={filter === "InProcess" || filter === null ? "blue" : "white"}
        color={filter === "InProcess" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("InProcess")}
        counter={getCounter("InProcess")}
        flex={1}
      />
      <OrderHeaderCard
        title="Completed"
        icon={<Icon as={SquaredChecked} />}
        bg={filter === "Completed" || filter === null ? "green" : "white"}
        color={filter === "Completed" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Completed")}
        counter={getCounter("Completed")}
        flex={1}
      />
    </Flex>
  );
}

export { TreatmentsCounters };
