import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetFrontDeskReport(
  startDate: Date | null,
  endDate?: Date | null,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["scheduleReport", { startDate, endDate }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/front-desk-log`, {
        responseType: "blob",
        params: { startDate, endDate },
      }),
    { ...options }
  );
}

export { useGetFrontDeskReport };
