import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const OpenCharts = createIcon({
  path: (
    <path
      d="M0 30.4772C0 33.4603 1.53967 35 4.48154 35H17.3488C20.2906 35 21.8303 33.4466 21.8303 30.4772V27.934H24.1673C27.1092 27.934 28.6489 26.3806 28.6489 23.4112V4.53653C28.6489 1.55342 27.1092 0 24.1673 0H11.3001C8.35821 0 6.81854 1.55342 6.81854 4.53653V7.06599H4.48154C1.53967 7.06599 0 8.63315 0 11.6025V30.4772ZM14.4894 4.93519C13.8845 4.93519 13.5546 4.52278 13.5546 4.06913V3.75295C13.5546 3.34053 13.857 2.87313 14.4894 2.87313H20.9642C21.6104 2.87313 21.899 3.34053 21.899 3.75295V4.06913C21.899 4.52278 21.5829 4.93519 20.9642 4.93519H14.4894ZM2.73566 30.2985V11.7812C2.73566 10.5165 3.39552 9.8154 4.72898 9.8154H8.92183V17.2938C8.92183 19.2871 9.89787 20.2494 11.8774 20.2494H19.0947V30.2985C19.0947 31.5632 18.4348 32.2643 17.1013 32.2643H4.71524C3.39552 32.2643 2.73566 31.5632 2.73566 30.2985ZM12.1386 17.8437C11.575 17.8437 11.3276 17.61 11.3276 17.0326V10.3378L18.7235 17.8437H12.1386Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 29 35",
});

export { OpenCharts };
