const baseStyle = {
  table: {
    borderCollapse: "collapse",
    borderTop: "1px solid",
    borderColor: "gray.450",
    td: {
      border: "none",
      padding: "25px 30px",
      fontWeight: "500",
      fontSize: "1.0625rem",
    },
    th: {
      border: "none",
      bg: "gray.50",
      padding: "25px 30px",
      textTransform: "unset",
      fontWeight: "500",
      fontSize: "1rem",
      fontFamily: "unset",
      color: "gray.700",
    },
    thead: {
      tr: {
        borderBottom: "1px",
        borderColor: "gray.450",
      },
    },
    tbody: {
      tr: {
        borderBottom: "1px",
        borderColor: "gray.450",
      },
    },
  },
};

export default {
  baseStyle,
};
