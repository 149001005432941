import Konva from "konva";
import * as React from "react";
import { Ellipse, KonvaNodeEvents } from "react-konva";
import { EllipseConfig } from "./types";
import { WithTransformer, WithTransformerProps } from "./WithTransformer";

type TransformerEllipseProps = EllipseConfig &
  KonvaNodeEvents & { isSelected: boolean } & Pick<
    WithTransformerProps,
    "dispatch"
  >;

function TransformerEllipse(props: TransformerEllipseProps) {
  const { isSelected, dispatch, ...ellipseProps } = props;
  const ellipseRef = React.useRef<Konva.Ellipse>(null);

  const onTransformEnd = React.useCallback(() => {
    const node = ellipseRef.current;

    if (!node) {
      return;
    }

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    const rotation = node.rotation();
    const radiusX = node.radiusX();
    const radiusY = node.radiusY();
    const id = node.id();

    dispatch({
      type: "EDIT_SHAPE",
      payload: {
        rotation,
        radiusX: radiusX * scaleX,
        radiusY: radiusY * scaleY,
        id,
      },
    });
  }, [dispatch]);

  return (
    <WithTransformer
      isSelected={isSelected}
      shapeRef={ellipseRef}
      dispatch={dispatch}
      flipEnabled={false}
      rotateEnabled={false}
      onTransformEnd={onTransformEnd}
    >
      <Ellipse
        ref={ellipseRef}
        {...ellipseProps}
        radiusX={ellipseProps.radiusX || 100}
        radiusY={ellipseProps.radiusY || 40}
      />
    </WithTransformer>
  );
}

export type { TransformerEllipseProps };
export { TransformerEllipse };
