import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const FramePerson = createIcon({
  path: (
    <path
      d="M0 19.2631C0 19.7108 0.298507 20 0.746269 20H14.944C15.3918 20 15.6903 19.7108 15.6903 19.2631V0.746269C15.6903 0.298507 15.3918 0 14.944 0H0.746269C0.298507 0 0 0.298507 0 0.746269V19.2631ZM1.50187 18.2276V1.78172C1.50187 1.57649 1.57649 1.50187 1.77239 1.50187H13.9179C14.1138 1.50187 14.1884 1.57649 14.1884 1.78172V18.2276C14.1884 18.4328 14.1138 18.4981 13.9179 18.4981H1.77239C1.57649 18.4981 1.50187 18.4328 1.50187 18.2276ZM2.50933 16.9496C2.50933 17.2948 2.70522 17.5 3.05037 17.5H12.6399C12.9851 17.5 13.181 17.2948 13.181 16.9496V3.0597C13.181 2.71455 12.9851 2.50933 12.6399 2.50933H3.05037C2.70522 2.50933 2.50933 2.71455 2.50933 3.0597V16.9496ZM7.83582 12.5746C5.63433 12.5746 4.18843 13.7313 3.45149 14.8321V3.66604C3.45149 3.52612 3.51679 3.45149 3.65672 3.45149H12.0336C12.1735 3.45149 12.2388 3.52612 12.2388 3.66604V14.8601C11.5019 13.75 10.056 12.5746 7.83582 12.5746ZM7.83582 11.7817C9.21642 11.791 10.3172 10.625 10.3172 9.07649C10.3172 7.62127 9.21642 6.41791 7.83582 6.41791C6.46455 6.41791 5.35448 7.62127 5.35448 9.07649C5.35448 10.625 6.46455 11.7724 7.83582 11.7817Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 20",
});

export { FramePerson };
