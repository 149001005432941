import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  Icon,
  LinkProps as CkLinkProps,
  VStack,
} from "@chakra-ui/react";
import { Audits, DuplicatePatients, OpenCharts } from "icons";
import { useIncompleteAuditsCount } from "modules/audits/api";
import { useDuplicatePatientsCounters } from "modules/duplicate-patients/api";
import { useOpenChartsProviderCount } from "modules/open-charts/api";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Card, Loading } from "shared";

type NavigationButtonProps = Omit<LinkProps, "to"> & CkLinkProps & BoxProps;

function OpenChartsNavigationCard(props: NavigationButtonProps) {
  const { data, isLoading } = useOpenChartsProviderCount();

  return (
    <chakra.a
      as={Link}
      role="link"
      borderRadius="10px"
      transition="all 200ms ease-in-out"
      outline="none"
      border="none"
      display="block"
      width="50%"
      disabled={isLoading}
      _focus={{
        boxShadow: "var(--chakra-shadows-outline)",
      }}
      _hover={{
        cursor: "pointer",
        textDecoration: "none",
      }}
      to="/in-process/provider-open-charts"
      {...props}
    >
      <Card variant="rounded" p="15px 15px 13px 15px">
        <Box
          backgroundColor="transparent"
          width="100%"
          height="200px"
          alignItems="center"
          fontWeight="500"
          fontSize="17px"
        >
          {isLoading ? (
            <Loading />
          ) : (
            <VStack alignItems="start" gap="1rem">
              <HStack w="100%" alignItems="start">
                <Box flex="1">
                  <Flex
                    borderRadius="50%"
                    w="64px"
                    h="64px"
                    bgColor="red"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    left="8px"
                    top="8px"
                  >
                    <Icon
                      as={OpenCharts}
                      color="white"
                      w="32px"
                      h="32px"
                      position="relative"
                    />
                  </Flex>
                </Box>
              </HStack>
              <Box paddingTop="18px">
                <Box padding="10px 10px 0px 10px">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <chakra.span fontSize="2.0125rem" fontWeight="500">
                      {data?.data?.count ?? 0}
                    </chakra.span>
                  )}
                </Box>
                <Box padding="0px 10px 10px 10px">
                  <chakra.span
                    fontSize="1.2125rem"
                    fontWeight="500"
                    color="gray.700"
                  >
                    My Open Charts
                  </chakra.span>
                </Box>
              </Box>
            </VStack>
          )}
        </Box>
      </Card>
    </chakra.a>
  );
}

function IncompleteAuditsNavigationCard(props: NavigationButtonProps) {
  const { data, isLoading } = useIncompleteAuditsCount({
    assignFilter: "AssignedToMe",
  });

  return (
    <chakra.a
      as={Link}
      role="link"
      borderRadius="10px"
      transition="all 200ms ease-in-out"
      outline="none"
      border="none"
      display="block"
      width="50%"
      disabled={isLoading}
      _focus={{
        boxShadow: "var(--chakra-shadows-outline)",
      }}
      _hover={{
        cursor: "pointer",
        textDecoration: "none",
      }}
      to="/audits/incomplete/assigned-to-me"
      {...props}
    >
      <Card variant="rounded" p="15px 15px 13px 15px">
        <Box
          backgroundColor="transparent"
          width="100%"
          height="200px"
          alignItems="center"
          fontWeight="500"
          fontSize="17px"
        >
          <VStack alignItems="start" gap="1rem">
            <HStack w="100%" alignItems="start" gap="4rem">
              <Box flex="1">
                <Flex
                  borderRadius="50%"
                  w="64px"
                  h="64px"
                  bgColor="#FF9500"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  left="8px"
                  top="8px"
                >
                  <Icon
                    as={Audits}
                    color="white"
                    w="32px"
                    h="32px"
                    position="relative"
                  />
                </Flex>
              </Box>
            </HStack>
            <Box paddingTop="18px">
              <Box padding="10px 10px 0px 10px">
                {isLoading ? (
                  <Loading />
                ) : (
                  <chakra.span fontSize="2.0125rem" fontWeight="500">
                    {data?.data?.count ?? 0}
                  </chakra.span>
                )}
              </Box>
              <Box padding="0px 10px 10px 10px">
                <chakra.span
                  fontSize="1.2125rem"
                  fontWeight="500"
                  color="gray.700"
                >
                  My Audit Reviews
                </chakra.span>
              </Box>
            </Box>
          </VStack>
        </Box>
      </Card>
    </chakra.a>
  );
}

function DuplicatePatientsNavigationCard(props: NavigationButtonProps) {
  const { data, isLoading } = useDuplicatePatientsCounters();

  return (
    <chakra.a
      as={Link}
      role="link"
      borderRadius="10px"
      transition="all 200ms ease-in-out"
      outline="none"
      border="none"
      display="block"
      width="48%"
      disabled={isLoading}
      _focus={{
        boxShadow: "var(--chakra-shadows-outline)",
      }}
      _hover={{
        cursor: "pointer",
        textDecoration: "none",
      }}
      to="/duplicate-patients/patients"
      {...props}
    >
      <Card variant="rounded" p="15px 15px 13px 15px">
        <Box
          backgroundColor="transparent"
          width="100%"
          height="200px"
          alignItems="center"
          fontWeight="500"
          fontSize="17px"
        >
          <VStack alignItems="start" gap="1rem">
            <HStack w="100%" alignItems="start" gap="4rem">
              <Box flex="1">
                <Flex
                  borderRadius="50%"
                  w="64px"
                  h="64px"
                  bgColor="yellow"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  left="8px"
                  top="8px"
                >
                  <Icon
                    as={DuplicatePatients}
                    color="white"
                    w="32px"
                    h="32px"
                    position="relative"
                  />
                </Flex>
              </Box>
            </HStack>
            <Box paddingTop="18px">
              <Box padding="10px 10px 0px 10px">
                {isLoading ? (
                  <Loading />
                ) : (
                  <chakra.span fontSize="2.0125rem" fontWeight="500">
                    {data?.data?.duplicatePatientsCounter ?? 0}
                  </chakra.span>
                )}
              </Box>
              <Box padding="0px 10px 10px 10px">
                <chakra.span
                  fontSize="1.2125rem"
                  fontWeight="500"
                  color="gray.700"
                >
                  Duplicate Patients
                </chakra.span>
              </Box>
            </Box>
          </VStack>
        </Box>
      </Card>
    </chakra.a>
  );
}

export {
  OpenChartsNavigationCard,
  IncompleteAuditsNavigationCard,
  DuplicatePatientsNavigationCard,
};
