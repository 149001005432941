import * as React from "react";
import { Box, BoxProps, Spinner, SpinnerProps } from "@chakra-ui/react";

type LoadingProps = BoxProps & { spinnerProps?: SpinnerProps };

function Loading(props: LoadingProps) {
  const { spinnerProps = { color: "blue" }, ...boxProps } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      {...boxProps}
    >
      <Spinner size="lg" {...spinnerProps} />
    </Box>
  );
}

export type { LoadingProps };
export { Loading };
