import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Home = createIcon({
  path: (
    <path
      d="M0 8.26451C0 8.6897 0.327065 9.05764 0.850368 9.05764C1.10384 9.05764 1.33279 8.91864 1.5372 8.75511L9.64841 1.94399C9.87735 1.74775 10.139 1.74775 10.3679 1.94399L18.471 8.75511C18.6672 8.91864 18.8962 9.05764 19.1496 9.05764C19.6321 9.05764 20 8.75511 20 8.28087C20 8.00286 19.8937 7.78209 19.6811 7.60221L17.3835 5.67253V2.27105C17.3835 1.90311 17.1464 1.67416 16.7784 1.67416H15.65C15.2903 1.67416 15.045 1.90311 15.045 2.27105V3.70196L11.1693 0.44767C10.4579 -0.149223 9.55846 -0.149223 8.8471 0.44767L0.327065 7.60221C0.106296 7.78209 0 8.02739 0 8.26451ZM2.6574 15.6153C2.6574 16.7927 3.3933 17.5041 4.61161 17.5041H15.3966C16.6067 17.5041 17.3508 16.7927 17.3508 15.6153V9.40106L10.507 3.66926C10.1881 3.39125 9.80376 3.39943 9.49305 3.66926L2.6574 9.40106V15.6153ZM12.1504 15.9669H7.85773V10.7339C7.85773 10.3495 8.1112 10.1043 8.4955 10.1043H11.5209C11.9052 10.1043 12.1504 10.3495 12.1504 10.7339V15.9669Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 21 18",
});

export { Home };
