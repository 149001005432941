import {
  Icon,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  HStack,
  IconButton,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Heading,
} from "@chakra-ui/react";
import { EmrPlus, EmrCheckFilled } from "@medstonetech/slate-icons";
import { usePagination, useToast } from "hooks";
import { Edit, CloseCircle } from "icons";
import * as React from "react";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  usePagination as usePaginationReactTable,
  useSortBy,
  useTable,
} from "react-table";
import {
  ActionButton,
  Loading,
  TableContainer,
  TableHeader,
  TableTopSection,
} from "shared";
import { PaginationControl } from "shared/pagination-control";
import { TableSearch } from "shared/table/TableSearch";
import { debounce } from "throttle-debounce";
import { enumMapper, extractApiErrorMessage } from "utils";
import {
  useDeleteEncounterPrimaryCarePhysician,
  usePrimaryCarePhysicians,
  useUpdateSelectedPrimaryCarePhysician,
} from "modules/reception/api";
import { formMessages } from "messages/form";
import { useParams } from "react-router-dom";
import { usePatientBasicInfoContext } from "modules/reception/contexts/PatientBasicInfoContext";
import { EncounterPrimaryCarePhysicianForm } from "modules/reception/types";
import { PCPCreateModal, PCPEditModal } from "./PCPModal";
import { PCP } from "../../../types";
import { maskString } from "utils/formatter";

type PCPListModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

type PCPSelectedProps = React.PropsWithChildren<{
  selectedPCP: PCP;
}>;

const columns: Array<Column<PCP>> = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone #",
    accessor: "phone",
  },
  {
    Header: "Fax #",
    accessor: "fax",
    Cell: ({ value }) => <>{maskString(value, "(###) ###-####")}</>,
  },
  {
    Header: "Street",
    accessor: "address",
  },
  {
    Header: "Suite #",
    accessor: "suite",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "ST",
    accessor: "state",
    Cell: ({ value }) => (
      <>{value ? enumMapper.toDisplay("usState", value) : null}</>
    ),
  },
  {
    Header: "ZIP",
    accessor: "zip",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableSortBy: true,
    Cell: ({ row: { original } }) => (
      <HStack spacing="12">
        <SelectPrimaryCarePhysician selectedPCP={original} />
        <EditPCP selectedPCP={original} />
      </HStack>
    ),
  },
];

function AddNewPCP() {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <ActionButton icon={<Icon as={EmrPlus} />} onClick={onOpen}>
        Add New
      </ActionButton>
      <PCPCreateModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

function EditPCP(props: PCPSelectedProps) {
  const { selectedPCP } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        aria-label="edit"
        icon={<Icon as={Edit} fontSize="1.25rem" color="blue" />}
        onClick={onOpen}
        variant="ghost"
        minWidth="unset"
        height="unset"
      />
      <PCPEditModal
        isOpen={isOpen}
        onClose={onClose}
        initialPCP={selectedPCP}
      />
    </>
  );
}

function PCPList(props: PCPListModalProps) {
  const {
    nextPage,
    previousPage,
    setTotalPages,
    setGlobalFilter,
    setSort,
    state,
  } = usePagination();

  const { onClose } = props;
  const {
    primaryCarePhysician: { id: selectedPCPId },
  } = usePatientBasicInfoContext();
  const { globalSearch, page, size, sort } = state;
  const toast = useToast();

  const { data, isLoading, error } = usePrimaryCarePhysicians({
    page: page + 1,
    size,
    globalSearch,
    descending: sort?.desc,
    sort: sort?.id,
  });

  const options: TableOptions<PCP> = React.useMemo(
    () => ({
      columns,
      data: data?.data.content || [],
      pageCount: data?.data.totalPages,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetPage: true,
      useControlledState: (tableState) =>
        React.useMemo(
          () => ({ ...tableState, pageIndex: page, pageSize: size }),
          // Eslint will think the outer variables page and size are
          // not necessary, but they are, so it is important to add
          // them in the deps array and override the eslint rule.
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [tableState, page, size]
        ),
    }),
    [data, page, size]
  );
  const {
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(options, useGlobalFilter, useSortBy, usePaginationReactTable);
  const debouncedSearch = React.useMemo(
    () => debounce(1000, setGlobalFilter),
    [setGlobalFilter]
  );

  React.useEffect(() => {
    setSort(sortBy[0]);
  }, [sortBy, setSort]);

  React.useEffect(() => {
    setTotalPages(options?.pageCount || 0);
  }, [options, setTotalPages]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <ModalContent maxWidth="1600px" width="1600px" bg="gray.50" padding="20px">
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="unset"
      >
        <HStack spacing="2.375rem" marginLeft="1.25rem" alignItems="center">
          <Heading fontSize="1.5rem" fontWeight="600">
            Primary Care Physician (PCP) List
          </Heading>
        </HStack>
        <IconButton
          aria-label="close modal"
          variant="icon"
          icon={<Icon as={CloseCircle} />}
          size="lg"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody padding="unset" marginTop="26px">
        <TableContainer>
          <TableTopSection
            leftItems={<AddNewPCP />}
            rightItems={
              <>
                <PaginationControl
                  currentPage={page + 1}
                  onClickNext={nextPage}
                  onClickPrevious={previousPage}
                  pagesCount={data?.data.totalPages || 0}
                />
                <TableSearch
                  globalFilter={globalSearch}
                  setGlobalFilter={debouncedSearch}
                />
              </>
            }
          />
          <Table>
            <Thead>
              {headerGroups.map((headerGroup) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();

                return (
                  <Tr key={headerGroupKey} {...headerGroupProps}>
                    {headerGroup.headers.map((header) => {
                      const { key: headerKey } = header.getHeaderProps(
                        header.getSortByToggleProps()
                      );
                      return <TableHeader key={headerKey} header={header} />;
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody>
              {rows.map((row) => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <Tr key={rowKey} {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key: cellKey, ...cellProps } =
                        cell.getCellProps();
                      return (
                        <Td
                          key={cellKey}
                          {...cellProps}
                          color={
                            row.original.id === selectedPCPId
                              ? "black"
                              : "gray.700"
                          }
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
              {isLoading && (
                <Tr>
                  <Td colSpan={columns.length}>
                    <Loading />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>
    </ModalContent>
  );
}

function PrimaryCarePhysicianModal(props: Omit<PCPListModalProps, "children">) {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {isOpen && <PCPList {...props} />}
    </Modal>
  );
}

function SelectPrimaryCarePhysician(props: PCPSelectedProps) {
  const { selectedPCP } = props;
  const {
    address,
    fax,
    name,
    phone,
    id: pcpId,
    state,
    suite,
    zip,
    city,
  } = selectedPCP;
  const {
    updatePrimaryCarePhysician,
    primaryCarePhysician: { id: currentPCPId },
  } = usePatientBasicInfoContext();
  const isPcpSelected = selectedPCP.id === currentPCPId;
  const toast = useToast();
  const { id = "", encounterId = "" } = useParams<{
    id: string;
    encounterId: string;
  }>();

  const { mutateAsync: updateSelectedPrimaryCarePhysician, isLoading } =
    useUpdateSelectedPrimaryCarePhysician(id || encounterId);

  const {
    mutateAsync: deleteSelectedPrimaryCarePhysician,
    isLoading: unselectIsLoading,
  } = useDeleteEncounterPrimaryCarePhysician(id || encounterId);

  const onUpdateSelected = async () => {
    let newPcp: EncounterPrimaryCarePhysicianForm = {
      id: "",
      address: "",
      fax: "",
      name: "",
      phone: "",
      state: "",
      suite: "",
      city: "",
      zip: "",
    };
    try {
      if (isPcpSelected) {
        await deleteSelectedPrimaryCarePhysician({});
      } else {
        const payload = {
          primaryCarePhysicianId: pcpId,
        };
        await updateSelectedPrimaryCarePhysician(payload);
        newPcp = {
          id: pcpId,
          address,
          fax,
          name,
          phone,
          state,
          suite,
          zip,
          city,
        };
      }
      updatePrimaryCarePhysician(newPcp);
      toast({
        description: formMessages.updateSuccess("Primary Care Physician"),
      });
    } catch (error) {
      const err = error as Error;
      toast({ description: extractApiErrorMessage(err) });
    }
  };
  return (
    <>
      <IconButton
        aria-label="check"
        icon={
          <Icon
            as={EmrCheckFilled}
            fontSize="1.25rem"
            color={isPcpSelected ? "green" : "gray.450"}
          />
        }
        variant="ghost"
        minWidth="unset"
        height="unset"
        onClick={onUpdateSelected}
        disabled={isLoading || unselectIsLoading}
        isLoading={isLoading || unselectIsLoading}
      />
    </>
  );
}

export { PrimaryCarePhysicianModal };
