import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const SquaredChecked = createIcon({
  path: (
    <path
      d="M4.09343 24H19.9066C22.6442 24 24 22.6442 24 19.9587V4.04128C24 1.35578 22.6442 0 19.9066 0H4.09343C1.36882 0 0 1.34275 0 4.04128V19.9587C0 22.6573 1.36882 24 4.09343 24ZM10.5464 18.3552C10.1032 18.3552 9.73819 18.1727 9.39924 17.7165L6.1271 13.7012C5.93156 13.4405 5.81423 13.1537 5.81423 12.8539C5.81423 12.2672 6.27051 11.7849 6.87018 11.7849C7.2352 11.7849 7.522 11.9022 7.84791 12.3324L10.4943 15.748L16.0608 6.805C16.3085 6.41391 16.6475 6.20532 16.9995 6.20532C17.56 6.20532 18.0945 6.59642 18.0945 7.20913C18.0945 7.49593 17.9381 7.79576 17.7686 8.06953L11.6415 17.7165C11.3677 18.1467 10.9897 18.3552 10.5464 18.3552Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
});

export { SquaredChecked };
