import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteMOTFile(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteMOTFile", { encounterId }],
    () =>
      axiosPatientInstance.delete(`v1/encounter/${encounterId}/form/files/mot`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["MOTFile", { encounterId }]);
      },
    }
  );
}

export { useDeleteMOTFile };
