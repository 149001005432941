import { PaginationPayload, PaginationResponse } from "types";
import { useAxiosQuery } from "hooks";
import { axiosFacilityInstance } from "config";
import { PCP } from "../../types";

type PrimaryCarePhysiciansResponse = PaginationResponse<PCP>;

type UsePrimaryCarePhysiciansProps = PaginationPayload;

function usePrimaryCarePhysicians(props: UsePrimaryCarePhysiciansProps) {
  const { sort, descending, page = 1, size = 10, globalSearch } = props;

  return useAxiosQuery(
    ["primaryCarePhysicians", props],
    () =>
      axiosFacilityInstance.get<PrimaryCarePhysiciansResponse>("v1/physician", {
        params: {
          page,
          pageSize: size,
          sortDesc: descending || false,
          orderBy: sort,
          search: globalSearch,
        },
      }),
    { keepPreviousData: true }
  );
}

export { usePrimaryCarePhysicians };
export type { PrimaryCarePhysiciansResponse, UsePrimaryCarePhysiciansProps };
