import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetCensusReportByRange(
  startDate: Date | null,
  endDate: Date | null,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["censusReportByRange", { startDate, endDate }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/Reception/census-report/range`, {
        responseType: "blob",
        params: { startDate, endDate },
      }),
    { ...options }
  );
}

export { useGetCensusReportByRange };
