const sectionCode = "SPRO2";
const chartCode = "C043x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T43Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T43Procedures };
