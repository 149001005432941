import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const WarningTriangleFilled = createIcon({
  path: (
    <path
      d="M2.44584 18.4208H17.5542C19.0467 18.4208 20 17.3231 20 15.975C20 15.5705 19.8941 15.1565 19.6822 14.7713L12.1136 1.25181C11.6514 0.414059 10.8329 0 10.0048 0C9.1767 0 8.34858 0.423688 7.88637 1.25181L0.327395 14.7809C0.115551 15.1661 0 15.5705 0 15.975C0 17.3231 0.962927 18.4208 2.44584 18.4208ZM10.0144 11.8247C9.44632 11.8247 9.12855 11.507 9.10929 10.9389L8.97448 6.0857C8.95522 5.49832 9.37891 5.08426 10.0048 5.08426C10.6115 5.08426 11.0544 5.49832 11.0351 6.0857L10.8907 10.9292C10.8714 11.5166 10.5537 11.8247 10.0144 11.8247ZM10.0144 15.1372C9.36928 15.1372 8.83004 14.675 8.83004 14.0395C8.83004 13.4136 9.35965 12.9417 10.0144 12.9417C10.6596 12.9417 11.1892 13.4039 11.1892 14.0395C11.1892 14.675 10.65 15.1372 10.0144 15.1372Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18.42",
});

export { WarningTriangleFilled };
