import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCHC";
const triageChartCode = "T007x";

function N07ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSave={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSCHCQ001A013"
                  label="ocurred:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="just PTA"
                  name="Q001.T001xSCHCQ001A001"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T001xSCHCQ001A002"
                  size="sm"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  label="minutes ago"
                  fontStyle="italic"
                  name="Q001.T001xSCHCQ001A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hours ago"
                  fontStyle="italic"
                  name="Q001.T001xSCHCQ001A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="days ago"
                  fontStyle="italic"
                  name="Q001.T001xSCHCQ001A005"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="location:"
                  name="Q001.T001xSCHCQ001A015"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A016" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="lost consciousness"
                  name="Q001.T001xSCHCQ001A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ambulatory at scene"
                  name="Q001.T001xSCHCQ001A008"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A009" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="tingling"
                  name="Q001.T001xSCHCQ001A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="numbness"
                  name="Q001.T001xSCHCQ001A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="weakness"
                  name="Q001.T001xSCHCQ001A019"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A020" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="pain"
                  name="Q001.T001xSCHCQ001A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="tenderness"
                  name="Q001.T001xSCHCQ001A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="redness"
                  name="Q001.T001xSCHCQ001A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="swelling"
                  name="Q001.T001xSCHCQ001A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="unable to bear weight"
                  name="Q001.T001xSCHCQ001A025"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A026" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="snapping"
                  name="Q001.T001xSCHCQ001A027"
                />
                <ChartRowElement
                  type="cross-option"
                  label="popping sensation"
                  name="Q001.T001xSCHCQ001A028"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A029" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q001.T001xSCHCQ001A030"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A031" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="head"
                  name="Q002.T001xSCHCQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="face"
                  name="Q002.T001xSCHCQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="nose"
                  name="Q002.T001xSCHCQ002A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mouth"
                  name="Q002.T001xSCHCQ002A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lip"
                  name="Q002.T001xSCHCQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chin"
                  name="Q002.T001xSCHCQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q002.T001xSCHCQ002A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chest"
                  name="Q002.T001xSCHCQ002A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="abdomen"
                  name="Q002.T001xSCHCQ002A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="coccyx"
                  name="Q002.T001xSCHCQ002A010"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A011" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q002.T001xSCHCQ002A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="eye"
                  name="Q002.T001xSCHCQ002A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ear"
                  name="Q002.T001xSCHCQ002A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q002.T001xSCHCQ002A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q002.T001xSCHCQ002A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q002.T001xSCHCQ002A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q002.T001xSCHCQ002A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q002.T001xSCHCQ002A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q002.T001xSCHCQ002A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q002.T001xSCHCQ002A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q002.T001xSCHCQ002A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q002.T001xSCHCQ002A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q002.T001xSCHCQ002A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q002.T001xSCHCQ002A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q002.T001xSCHCQ002A026"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q002.T001xSCHCQ002A027"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q002.T001xSCHCQ002A028"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A029" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q002.T001xSCHCQ002A030"
                />
                <ChartRowElement
                  type="cross-option"
                  label="eye"
                  name="Q002.T001xSCHCQ002A031"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ear"
                  name="Q002.T001xSCHCQ002A032"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q002.T001xSCHCQ002A033"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q002.T001xSCHCQ002A034"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q002.T001xSCHCQ002A035"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q002.T001xSCHCQ002A036"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q002.T001xSCHCQ002A037"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q002.T001xSCHCQ002A038"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q002.T001xSCHCQ002A039"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q002.T001xSCHCQ002A040"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q002.T001xSCHCQ002A041"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q002.T001xSCHCQ002A042"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q002.T001xSCHCQ002A043"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q002.T001xSCHCQ002A044"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q002.T001xSCHCQ002A045"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q002.T001xSCHCQ002A046"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A047" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="back:"
                  name="Q002.T001xSCHCQ002A048"
                />
                <ChartRowElement
                  type="cross-option"
                  label="upper"
                  name="Q002.T001xSCHCQ002A049"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mid"
                  name="Q002.T001xSCHCQ002A050"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lower"
                  name="Q002.T001xSCHCQ002A051"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A052" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="radiating to:"
                  name="Q002.T001xSCHCQ002A053"
                />
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q002.T001xSCHCQ002A054"
                />
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q002.T001xSCHCQ002A055"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q002.T001xSCHCQ002A056"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q002.T001xSCHCQ002A057"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A058" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q002.T001xSCHCQ002A059"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A060" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fall"
                  name="Q003.T001xSCHCQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="direct blow"
                  name="Q003.T001xSCHCQ003A003"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="twisting"
                  name="Q003.T001xSCHCQ003A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="crush"
                  name="Q003.T001xSCHCQ003A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="jammed"
                  name="Q003.T001xSCHCQ003A015"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A016" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="bite:"
                  name="Q003.T001xSCHCQ003A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="human"
                  fontStyle="italic"
                  name="Q003.T001xSCHCQ003A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="animal"
                  fontStyle="italic"
                  name="Q003.T001xSCHCQ003A007"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A019" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="burn"
                  name="Q003.T001xSCHCQ003A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chemical"
                  name="Q003.T001xSCHCQ003A009"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="GSW"
                  name="Q003.T001xSCHCQ003A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="stab wound"
                  name="Q003.T001xSCHCQ003A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="cut with"
                  name="Q003.T001xSCHCQ003A005"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="hit by:"
                  name="Q003.T001xSCHCQ003A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="car"
                  name="Q003.T001xSCHCQ003A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="motorcycle"
                  name="Q003.T001xSCHCQ003A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="bicycle"
                  name="Q003.T001xSCHCQ003A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ATV"
                  name="Q003.T001xSCHCQ003A026"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A027" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="safety:"
                  name="Q003.T001xSCHCQ003A028"
                />
                <ChartRowElement
                  type="cross-option"
                  label="none"
                  name="Q003.T001xSCHCQ003A029"
                />
                <ChartRowElement
                  type="cross-option"
                  label="helmet"
                  name="Q003.T001xSCHCQ003A030"
                />
                <ChartRowElement
                  type="cross-option"
                  label="safety glasses"
                  name="Q003.T001xSCHCQ003A031"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A032" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q003.T001xSCHCQ003A033"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A034" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N07ChiefComplaint };
