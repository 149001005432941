import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseSummaryMiscellaneous = {
  id: string;
  name: string;
}[];

function useSummaryMiscellaneous(encounterId: string) {
  return useAxiosQuery(
    [
      "summaryMiscellaneous",
      {
        encounterId,
      },
    ],
    () =>
      axiosChartInstance.get<UseSummaryMiscellaneous>(
        `v1/summary-miscellaneous/${encounterId}`
      )
  );
}

export { useSummaryMiscellaneous };
