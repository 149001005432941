import * as React from "react";
import { Box, Center, keyframes } from "@chakra-ui/react";
import Background from "assets/background-welcome.jpg";
import { AnimatedLogo } from "shared/animated-logo";
import { motion } from "framer-motion";

const scale = keyframes`
  0% {
    background-size: 100%
  }
  100% {
    background-size: 120%
  }`;

const backgroundAnimation = `${scale} 5.5s`;

function WelcomePage() {
  return (
    <Box
      bgImage={Background}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      w="100%"
      minH="100vh"
      animation={backgroundAnimation}
    >
      <Center flexDirection="column" minH="inherit">
        <AnimatedLogo size="md" />
        <motion.p
          style={{
            color: "#32C2E8",
            textShadow:
              "0px 12px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08)",
            fontSize: "40px",
            fontWeight: 600,
          }}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ default: { duration: 1, delay: 1 } }}
        >
          Welcome
        </motion.p>
      </Center>
    </Box>
  );
}

export { WelcomePage };
