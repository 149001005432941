import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C039x";

function T39ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="GU"
            >
              GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abortion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A124`}
                />
                <ChartRowElement
                  label="threatened, <20wks"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="missed, <20wk, fetal demise"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="spontaneous / induced"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="incomplete"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A125`}
                />
                <ChartRowElement
                  label="UTI"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PID"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sepsis-severe"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="delayed hemorrhage"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bartholin's abscess"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  I&D"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Blighted ovum"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cerevictis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A126`}
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="herpes"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trichomonas"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Discomforts of pregnancy:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A127`}
                />
                <ChartRowElement
                  label="trimester:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A128`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nausea"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vomiting"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fatigue"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="low back pain"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pedal edema"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hemorrhoids"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dysfunctional uterine bleeding"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dysmenorrhea:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A129`}
                />
                <ChartRowElement
                  label="primary"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="secondary"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dyspareunia"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ectopic preg:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A130`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tubal"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Endometritis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A131`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post-partum"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gonorrhea:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A132`}
                />
                <ChartRowElement
                  label="vaginitis"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cervicitis"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes genitalis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A133`}
                />
                <ChartRowElement
                  label="involving:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A134`}
                />
                <ChartRowElement
                  label="vulva"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vagina"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cervix"
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nephrolithiasis"
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ovarian cyst:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A135`}
                />
                <ChartRowElement
                  label="follicular"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="simple"
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ovarian Torsion"
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PID:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A136`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A066`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A067`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q002.${chartCode}${sectionId}Q002A068`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A069`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pregnant:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A137`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A070`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A071`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A072`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="+preg test"
                  name={`Q002.${chartCode}${sectionId}Q002A074`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="IUP"
                  name={`Q002.${chartCode}${sectionId}Q002A075`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A076`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pyelonephritis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A138`}
                />
                <ChartRowElement
                  label="w/ obstruction"
                  name={`Q002.${chartCode}${sectionId}Q002A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A078`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Renal colic"
                  name={`Q002.${chartCode}${sectionId}Q002A079`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A080`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A081`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A082`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="TOA:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A139`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A083`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A084`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ureterolithiasis"
                  name={`Q002.${chartCode}${sectionId}Q002A085`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A086`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A087`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="location:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A140`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A088`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Urethritis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A141`}
                />
                <ChartRowElement
                  label="nonspecific"
                  name={`Q002.${chartCode}${sectionId}Q002A089`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A090`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q002.${chartCode}${sectionId}Q002A091`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trichomonas"
                  name={`Q002.${chartCode}${sectionId}Q002A092`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A093`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Uterine fibroid:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A142`}
                />
                <ChartRowElement
                  label="intramural"
                  name={`Q002.${chartCode}${sectionId}Q002A094`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="submucosal"
                  name={`Q002.${chartCode}${sectionId}Q002A095`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="subserosal"
                  name={`Q002.${chartCode}${sectionId}Q002A096`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A097`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Urinary incontinence:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A143`}
                />
                <ChartRowElement
                  label="stress"
                  name={`Q002.${chartCode}${sectionId}Q002A098`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="urge"
                  name={`Q002.${chartCode}${sectionId}Q002A099`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post-void"
                  name={`Q002.${chartCode}${sectionId}Q002A100`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="continual"
                  name={`Q002.${chartCode}${sectionId}Q002A101`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A102`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="UTI:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A144`}
                />
                <ChartRowElement
                  label="cystitis"
                  name={`Q002.${chartCode}${sectionId}Q002A103`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A104`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hematuria"
                  name={`Q002.${chartCode}${sectionId}Q002A105`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="catheter"
                  name={`Q002.${chartCode}${sectionId}Q002A106`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A107`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vaginal bleeding:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A145`}
                />
                <ChartRowElement
                  label="heavy period"
                  name={`Q002.${chartCode}${sectionId}Q002A108`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="menorrhagia"
                  name={`Q002.${chartCode}${sectionId}Q002A109`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="metrorrhagia"
                  name={`Q002.${chartCode}${sectionId}Q002A110`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="dysfunctional uterine bleed"
                  name={`Q002.${chartCode}${sectionId}Q002A111`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ ovulation"
                  name={`Q002.${chartCode}${sectionId}Q002A112`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post-coital"
                  name={`Q002.${chartCode}${sectionId}Q002A113`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A114`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vaginal discharge"
                  name={`Q002.${chartCode}${sectionId}Q002A115`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A116`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vaginitis:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A146`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A117`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="atrophic"
                  name={`Q002.${chartCode}${sectionId}Q002A118`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="candida"
                  name={`Q002.${chartCode}${sectionId}Q002A119`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A120`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q002.${chartCode}${sectionId}Q002A121`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A122`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A123`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Appendictis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                />
                <ChartRowElement
                  label="acute:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                />
                <ChartRowElement
                  label="w/ peritonitis:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                />
                <ChartRowElement
                  label="local"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="general"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hernia:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="inguinal"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="femoral"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="obstruction"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gangrene"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abd pain:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute abd"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="rebound"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tenderness"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dysuria"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Fever"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hematuria:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                />
                <ChartRowElement
                  label="gross"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="microscopic"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nausea"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Vomiting"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pain:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="flank"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pelvic"
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ tenderness"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dehydration"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T39ClinicalImpression };
