import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type EncounterProfilePictureResponse = {
  response: string;
};

function useCreateEncounterProfilePicture() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createEncounterProfilePicture"],
    (encounterId: string) => {
      return axiosPatientInstance.post<EncounterProfilePictureResponse>(
        `v1/encounter/${encounterId}/form/files/profile-picture`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("profilePicture");
      },
    }
  );
}

export { useCreateEncounterProfilePicture };
