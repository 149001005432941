import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import {
  UseUpdateOrderFormFavoritesDto,
  useUpdateOrderFormFavorites,
} from "modules/charts-shared/api";
import { UseOrderFormFavoritesResponse } from "modules/charts-shared/api/queries/useOrderFormFavorites";
import {
  OrderFormElementTypeGroup,
  useOrderListElements,
} from "modules/order-list/api";
import { OrderElementResponse } from "modules/order-list/types";
import { useMemo, useState } from "react";
import { OrderFormItemCard } from "./OrderFormItemCard";
import { OrderFormSearchBar } from "./OrderFormSearchBar";

type OrderFormListProps = {
  onAddItem: (item: OrderElementResponse) => void;
  type: OrderFormElementTypeGroup;
  favorites: UseOrderFormFavoritesResponse[];
  isDisabled?: boolean;
} & BoxProps;

function OrderFormList({
  onAddItem,
  type,
  favorites,
  isDisabled,
  ...rest
}: OrderFormListProps) {
  const { data } = useOrderListElements(type);
  const { mutateAsync } = useUpdateOrderFormFavorites();

  const [search, setSearch] = useState("");
  const [myFavorites, setMyFavorites] = useState(false);
  const [facilityFavorites, setFacilityFavorites] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [medType, setMedType] = useState(0);
  const toggleFacilityFavorites = () => {
    setFacilityFavorites((prev) => !prev);
  };

  const toggleMyFavorites = () => {
    setMyFavorites((prev) => !prev);
  };

  const filteredItems = useMemo(() => {
    if (!data || !favorites) return [];

    const searchTerms = search.split(" ");
    const isSearching = Boolean(searchTerms.length);

    const filterFavorites = (item: OrderElementResponse) => {
      if (!facilityFavorites && !myFavorites) return true;
      if (facilityFavorites && item.favorite) return true;
      if (myFavorites) {
        return favorites.find((fav) => fav.orderFormElementId === item.id);
      }
      return false;
    };

    const filterSearch = (item: OrderElementResponse) => {
      return searchTerms.every((term) =>
        item.description.toLowerCase().includes(term.toLowerCase())
      );
    };

    const filterType = (item: OrderElementResponse) => {
      if (type === "Medication") {
        return medType === 0 && item.orderFormElementType === "Medication";
      }

      if (type === "Treatment") {
        return medType === 0 && item.orderFormElementType === "Treatment";
      }

      if (type === "Radiology") {
        return (
          (medType === 0 &&
            (item.orderFormElementCategory === "CT_SCAN" ||
              item.orderFormElementCategory === "CT_SCAN_CONTRAST")) ||
          (medType === 1 && item.orderFormElementCategory === "MRI") ||
          (medType === 2 && item.orderFormElementCategory === "XRAY") ||
          (medType === 3 && item.orderFormElementCategory === "Ultrasound")
        );
      }

      return true;
    };

    return data.data.filter(
      (item) =>
        filterFavorites(item) &&
        (isSearching ? filterSearch(item) : true) &&
        filterType(item)
    );
  }, [search, data, facilityFavorites, myFavorites, favorites, type, medType]);

  const addToFavorites = async (ids: string[]) => {
    const updatingFavorites: UseUpdateOrderFormFavoritesDto[] = ids.map(
      (id) => {
        const existingFavorite = favorites.find(
          (fav) => fav.orderFormElementId === id
        );
        if (existingFavorite) {
          return { ...existingFavorite, favorite: !existingFavorite.favorite };
        }
        return {
          favorite: true,
          orderFormElementId: id,
        };
      }
    );
    await mutateAsync(updatingFavorites);
    setSelectedIds([]);
  };

  const handleAddFavorite = (id: string) => {
    const newIds = [...selectedIds, id];
    setSelectedIds(newIds);

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        addToFavorites(newIds);
      }, 2000)
    );
  };

  return (
    <Box>
      <OrderFormSearchBar
        search={search}
        onChangeSearch={(newSearch) => setSearch(newSearch)}
        toggleFacilityFavorites={toggleFacilityFavorites}
        toggleMyFavorites={toggleMyFavorites}
        facilityFavorites={facilityFavorites}
        myFavorites={myFavorites}
      />
      {type === "Radiology" && (
        <Flex justifyContent="center" mb="1.25rem">
          <Tabs
            isFitted
            index={medType}
            onChange={(i) => setMedType(i)}
            w="100%"
          >
            <TabList>
              <Tab height="2.25rem">CT Scans</Tab>
              <Tab height="2.25rem">MRI</Tab>
              <Tab height="2.25rem">Xrays</Tab>
              <Tab height="2.25rem">Ultrasounds</Tab>
            </TabList>
          </Tabs>
        </Flex>
      )}
      <Grid
        gridTemplateColumns={
          type === "Radiology" ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
        }
        justifyItems="flex-start"
        rowGap="10px"
        columnGap="20px"
        {...rest}
      >
        {filteredItems.map((item) => (
          <OrderFormItemCard
            key={item.id}
            item={item}
            onClick={() => onAddItem(item)}
            isMyFavorite={
              !!favorites.find((fav) => fav.orderFormElementId === item.id)
                ?.favorite
            }
            onFavorite={() => handleAddFavorite(item.id)}
            isLoading={selectedIds.includes(item.id)}
            isDisabled={isDisabled}
          />
        ))}
      </Grid>
    </Box>
  );
}

export { OrderFormList };
