import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";
import { Contrast } from "modules/orders/types";

function useOrderContrast(orderId: string) {
  return useAxiosQuery(["orderContrast", { orderId }], () =>
    axiosOrderInstance.get<Contrast>(`v1/contrast/${orderId}`)
  );
}

export { useOrderContrast };
