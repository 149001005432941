import { SystemStyleObject } from "@chakra-ui/react";

const BASE_STYLE: SystemStyleObject = {
  display: "flex",
  fontWeight: 600,
  borderRadius: "50%",
  background: "red",
  color: "white",
  justifyContent: "center",
  alignItems: "center",
};

const SIZES: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: "0.375rem",
    minWidth: "10px",
    height: "10px",
  },
  lg: {
    fontSize: "1rem",
    minWidth: "24px",
    height: "24px",
  },
};

const counterTheme = {
  baseStyle: BASE_STYLE,
  sizes: SIZES,
  defaultProps: {
    size: "lg",
  },
};

export { counterTheme };
