import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

function useUpdateArrivalInfo(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateArrivalInfo", { encounterId }],
    (input: ChartFormSubsectionPayload[]) =>
      axiosChartInstance.post(`v1/triage/${encounterId}/arrival-info`, input),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["arrivalInfo", { encounterId }]),
    }
  );
}

export { useUpdateArrivalInfo };
