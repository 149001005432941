import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const chartCode = "T033x";

function N33Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { watch } = useFormContext;

  const q001AllNormal = Boolean(watch(`Q001.${chartCode}${sectionId}Q001A001`));
  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Date / Time:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="date"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nml"
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q001.${chartCode}${sectionId}Q001A005`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q001.${chartCode}${sectionId}Q001A006`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q001.${chartCode}${sectionId}Q001A007`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A008`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway patent"
                    name={`Q001.${chartCode}${sectionId}Q001A009`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway compromised"
                    name={`Q001.${chartCode}${sectionId}Q001A010`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A011`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breathing unlabored"
                    name={`Q001.${chartCode}${sectionId}Q001A012`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labored breathing"
                    name={`Q001.${chartCode}${sectionId}Q001A013`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A029`}
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q001.${chartCode}${sectionId}Q001A014`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q001.${chartCode}${sectionId}Q001A015`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q001.${chartCode}${sectionId}Q001A016`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A017`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="circulation nml"
                    name={`Q001.${chartCode}${sectionId}Q001A018`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pale"
                    name={`Q001.${chartCode}${sectionId}Q001A019`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q001.${chartCode}${sectionId}Q001A020`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A021`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neuro awake & alert"
                    name={`Q001.${chartCode}${sectionId}Q001A022`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lethargic"
                    name={`Q001.${chartCode}${sectionId}Q001A023`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="obtunded"
                    name={`Q001.${chartCode}${sectionId}Q001A024`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A025`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q001.${chartCode}${sectionId}Q001A026`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${chartCode}${sectionId}Q001A027`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q002.${chartCode}${sectionId}Q002A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x 4"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="moves all extremities"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="weakness"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="sensory loss"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Chest"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q003.${chartCode}${sectionId}Q003A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no respiratory distress"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A029`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml breath sounds"
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breath sounds"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="tachypnea"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="wheezing"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="stridor"
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="crackles"
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="accessory muscle use"
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="retractions"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="splinting"
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="nasal flaring"
                    name={`Q003.${chartCode}${sectionId}Q003A019`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="orthopnea"
                    name={`Q003.${chartCode}${sectionId}Q003A020`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="exertional SOB"
                    name={`Q003.${chartCode}${sectionId}Q003A021`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="tripod"
                    name={`Q003.${chartCode}${sectionId}Q003A022`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A023`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest non-tender"
                    name={`Q003.${chartCode}${sectionId}Q003A024`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest wall tenderness"
                    name={`Q003.${chartCode}${sectionId}Q003A025`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A026`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q003.${chartCode}${sectionId}Q003A027`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A028`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="CVS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q004.${chartCode}${sectionId}Q004A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="regular rate"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulses strong"
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Abdomen / GU"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q005.${chartCode}${sectionId}Q005A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft, non-tender"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="rigid"
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="distended"
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds normal"
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A032`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="hyper"
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="hypo"
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="absent"
                    name={`Q005.${chartCode}${sectionId}Q005A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A014`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no discharge"
                    name={`Q005.${chartCode}${sectionId}Q005A015`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="penile discharge"
                    name={`Q005.${chartCode}${sectionId}Q005A016`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A017`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml scrotal exam"
                    name={`Q005.${chartCode}${sectionId}Q005A018`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scrotal:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A033`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q005.${chartCode}${sectionId}Q005A019`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="pain"
                    name={`Q005.${chartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="redness"
                    name={`Q005.${chartCode}${sectionId}Q005A021`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="lesions"
                    name={`Q005.${chartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A023`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="urine incontinence"
                    name={`Q005.${chartCode}${sectionId}Q005A024`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A025`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="genital lesion"
                    name={`Q005.${chartCode}${sectionId}Q005A026`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A027`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q005.${chartCode}${sectionId}Q005A028`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A029`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q005.${chartCode}${sectionId}Q005A030`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A031`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Skin"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q006.${chartCode}${sectionId}Q006A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="warm / dry"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cool"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="cyanotic"
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="flushed"
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="mottled"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="increased warmth"
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ulcers"
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="sacrum"
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="hip"
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="heel"
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A015`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q006.${chartCode}${sectionId}Q006A016`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A017`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q007.${chartCode}${sectionId}Q007A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="independent ADL"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="assisted"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="total care"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nourished / hydrated"
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="obese"
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="malnourished"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="dehydrated"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="recent weight loss"
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="recent weight gain"
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N33Assessments };
