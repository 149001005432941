import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCHC";
const triageChartCode = "T001x";

function N01ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    onSaveAndNext,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSCHCQ001A013"
                  label="ocurred"
                />
                <ChartRowElement
                  type="cross-option"
                  label="just PTA"
                  name="Q001.T001xSCHCQ001A001"
                />
                <ChartRowElement
                  type="input"
                  name="Q001.T001xSCHCQ001A002"
                  size="sm"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  label="min"
                  name="Q001.T001xSCHCQ001A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hrs"
                  name="Q001.T001xSCHCQ001A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="days ago"
                  name="Q001.T001xSCHCQ001A005"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="lost consciousness"
                  name="Q001.T001xSCHCQ001A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="dazed"
                  name="Q001.T001xSCHCQ001A008"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A009" />
                <ChartRowElement
                  type="cross-option"
                  label="nausea"
                  name="Q001.T001xSCHCQ001A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="vomiting x"
                  name="Q001.T001xSCHCQ001A011"
                />
                <ChartRowElement type="input" name="Q001.T001xSCHCQ001A012" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Injuries / Pain"
            >
              Injuries / Pain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="head"
                  name="Q002.T001xSCHCQ002A001"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="face"
                  name="Q002.T001xSCHCQ002A003"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A004" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="ear"
                  name="Q002.T001xSCHCQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q002.T001xSCHCQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q002.T001xSCHCQ002A007"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q002.T001xSCHCQ002A009"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="eye"
                  name="Q002.T001xSCHCQ002A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q002.T001xSCHCQ002A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q002.T001xSCHCQ002A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="both"
                  name="Q002.T001xSCHCQ002A014"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A015" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="nose"
                  name="Q002.T001xSCHCQ002A016"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="mouth"
                  name="Q002.T001xSCHCQ002A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lip"
                  name="Q002.T001xSCHCQ002A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chin"
                  name="Q002.T001xSCHCQ002A020"
                />
                <ChartRowElement type="input" name="Q002.T001xSCHCQ002A021" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fall"
                  name="Q003.T001xSCHCQ003A001"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="direct blow"
                  name="Q003.T001xSCHCQ003A003"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A004" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="cut with"
                  name="Q003.T001xSCHCQ003A005"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="animal bite"
                  name="Q003.T001xSCHCQ003A007"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="chemical"
                  name="Q003.T001xSCHCQ003A009"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="burn"
                  name="Q003.T001xSCHCQ003A011"
                />
                <ChartRowElement type="input" name="Q003.T001xSCHCQ003A012" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N01ChiefComplaint };
