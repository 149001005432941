import * as React from "react";
import {
  UseDisclosureReturn,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Spacer,
  Icon,
  Box,
  Divider,
  Spinner,
  Center,
  Input,
  chakra,
} from "@chakra-ui/react";
import {
  SiChevronRight,
  EmrCheckCircle,
  EmrExclamationCircle,
} from "@medstonetech/slate-icons";
import { useReactToPrint } from "react-to-print";

import { Button, Loading } from "shared";
import {
  useEncounterSideInfo,
  useEncounterOverview,
} from "modules/reception/api";
import {
  differenceInMonths,
  differenceInYears,
  format,
  isValid,
  lastDayOfMonth,
} from "date-fns";
import { calculateAge, dateWithoutTimezone } from "utils";

type PrintLabelsModalProps = {
  encounterId: string;
  customProvider?: string;
} & UseDisclosureReturn;

type EncounterInfoRowProps = {
  title: string;
  value?: string;
  hideDivider?: boolean;
};

const EncounterInfoRow = React.forwardRef<
  HTMLDivElement,
  EncounterInfoRowProps
>((props, ref) => {
  const { title, value, hideDivider } = props;
  return (
    <React.Fragment key={title}>
      <Flex
        direction="row"
        w="100%"
        padding="20px 25px"
        alignItems="center"
        ref={ref}
      >
        <Icon
          as={value ? EmrCheckCircle : EmrExclamationCircle}
          alignSelf="center"
          mr="0.5rem"
          fontSize={value ? "17px" : "25px"}
          color={value ? "green" : "red"}
        />
        <chakra.span color="gray.700">{title}</chakra.span>
        <Spacer />
        <chakra.span color="gray.700">{value}</chakra.span>
      </Flex>
      {!hideDivider && (
        <Divider
          w="calc(100% - 50px)"
          marginLeft="50px"
          height="0"
          color="gray.450"
        />
      )}
    </React.Fragment>
  );
});

function PrintLabelsModal(props: PrintLabelsModalProps) {
  const { encounterId } = props;
  const printRef = React.useRef(null);

  const { data: encounterSideInfo, isLoading: isLoadingEncounterSideInfo } =
    useEncounterSideInfo(encounterId);
  const { data: encounterOverview, isLoading: isLoadingEncounterOverview } =
    useEncounterOverview(encounterId);

  const [providerInput, setProviderInput] = React.useState<string | null>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      "@page { size: 6.375in 8.25in !important; margin: 0; padding: 0; }",
    documentTitle: `Labels for ${encounterSideInfo?.data.firstName}`,
  });

  const nameDesc = React.useMemo(
    () =>
      [encounterSideInfo?.data?.lastName, encounterSideInfo?.data?.firstName]
        .filter((x) => !!x)
        .join(", ") || "N/A",
    [encounterSideInfo]
  );

  const providernameDesc = React.useMemo(
    () =>
      [
        encounterOverview?.data?.supervisingProviderFirstName,
        encounterOverview?.data?.supervisingProviderLastName,
      ]
        .filter((x) => !!x)
        .join(", ")
        .toUpperCase() || "N/A",
    [encounterOverview]
  );

  const { yearsBirth, monthsBirth } = React.useMemo(() => {
    return calculateAge(encounterSideInfo?.data.dob || new Date());
  }, [encounterSideInfo]);

  if (isLoadingEncounterOverview || isLoadingEncounterSideInfo)
    return (
      <Modal isCentered {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Center w="100%">
              <Spinner width="20px" height="20px" color="blue" />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent bgColor="gray.200" padding="0.5rem">
        <ModalHeader
          p="10px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          fontSize="1.063rem"
          fontWeight="normal"
          userSelect="none"
        >
          <chakra.span
            onClick={props.onClose}
            _hover={{ cursor: "pointer" }}
            color="blue"
          >
            Cancel
          </chakra.span>
          <chakra.span fontWeight="600">Labels</chakra.span>
          <Button
            border="none"
            bg="transparent"
            h="unset"
            minW="unset"
            p="0"
            color="blue"
            fontWeight="normal"
            disabled={!providerInput}
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
            _disabled={{ color: "gray.600", cursor: "not-allowed" }}
            type="submit"
            onClick={handlePrint}
          >
            Continue <Icon as={SiChevronRight} fontSize="16px" />
          </Button>
        </ModalHeader>

        <ModalBody p="1rem 0.5rem">
          <Box bgColor="white" borderRadius="0.5rem" fontSize="17px">
            <EncounterInfoRow
              title="First Name & MI"
              value={encounterSideInfo?.data.firstName}
            />
            <EncounterInfoRow
              title="Last name"
              value={encounterSideInfo?.data.lastName}
            />
            <EncounterInfoRow
              title="DOB"
              value={format(
                dateWithoutTimezone(encounterSideInfo?.data.dob || ""),
                "MM/dd/yyyy"
              )}
            />
            <EncounterInfoRow
              title="Age"
              value={Math.abs(
                differenceInYears(
                  new Date(encounterSideInfo?.data.dob || new Date()),
                  new Date()
                )
              ).toString()}
            />
            <EncounterInfoRow
              title="Gender"
              value={encounterSideInfo?.data.gender}
            />
            <EncounterInfoRow
              title="Encounter Date"
              value={format(
                new Date(encounterOverview?.data.date || new Date()),
                "MM/dd/yyyy"
              )}
            />
            <EncounterInfoRow
              title="MRN"
              value={encounterSideInfo?.data.mrn}
              hideDivider
            />
          </Box>
          <Box p="0.5rem 1rem">
            <chakra.span fontWeight="400" fontSize="15px" color="gray.600">
              Enter the name of the Provider (Last, First) in the space below
              without exceeding 22 characters.
            </chakra.span>
          </Box>
          <Box bgColor="white" borderRadius="0.5rem" fontSize="17px">
            <Flex
              direction="row"
              w="100%"
              padding="20px 25px"
              alignItems="center"
            >
              <Icon
                as={providerInput ? EmrCheckCircle : EmrExclamationCircle}
                alignSelf="center"
                mr="0.5rem"
                fontSize={providerInput ? "17px" : "25px"}
                color={providerInput ? "green" : "red"}
              />
              <chakra.span color="gray.700">Provider</chakra.span>
              <Spacer />
              <Input
                bg="white"
                textAlign="right"
                border="none"
                width="50%"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white" }}
                onChange={(e) => setProviderInput(e.target.value)}
              />
            </Flex>
          </Box>
        </ModalBody>

        <Box hidden>
          <Box
            ref={printRef}
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gridRowGap="0px"
            gridColumnGap="8.64pxpx"
            m="0"
            p="36.5px 14px 0 14px"
          >
            {[...Array(30)].map((index) => (
              <Box
                display="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
                w="189.36px"
                h="72px"
                fontSize="8px"
                textTransform="uppercase"
                p="0 1rem"
                key={index}
              >
                <Box display="flex" flexDir="column">
                  <chakra.span>{nameDesc}</chakra.span>
                  <chakra.span>
                    DOB:{" "}
                    {format(
                      dateWithoutTimezone(encounterSideInfo?.data.dob || ""),
                      "MM/dd/yyyy"
                    )}
                  </chakra.span>
                  <chakra.span>{providernameDesc}</chakra.span>
                  <chakra.span>MRN: {encounterSideInfo?.data.mrn}</chakra.span>
                </Box>
                <Box display="flex" flexDir="column">
                  &nbsp;
                  <chakra.span>
                    {format(
                      new Date(encounterOverview?.data.date || new Date()),
                      "MM/dd/yyyy"
                    )}
                  </chakra.span>
                  <chakra.span>
                    {yearsBirth}y{yearsBirth < 1 ? ` ${monthsBirth}m` : ""}
                  </chakra.span>
                  <chakra.span>
                    SEX: {encounterSideInfo?.data.gender}
                  </chakra.span>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

function PrintLabelsActionModalContent(props: PrintLabelsModalProps) {
  const { encounterId, customProvider, ...rest } = props;
  const { isOpen, onClose } = rest;
  const printRef = React.useRef(null);

  const { data: encounterSideInfo, isLoading: isLoadingEncounterSideInfo } =
    useEncounterSideInfo(encounterId, { enabled: isOpen });
  const { data: encounterOverview, isLoading: isLoadingEncounterOverview } =
    useEncounterOverview(encounterId, { enabled: isOpen });

  const isLoading = isLoadingEncounterSideInfo || isLoadingEncounterOverview;
  const hasData = !!encounterSideInfo?.data && !!encounterOverview?.data;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      onClose();
    },
    pageStyle:
      "@page { size: 6.375in 8.25in !important; margin: 0; padding: 0; }",
    documentTitle: `Labels for ${encounterSideInfo?.data.firstName}`,
  });

  const nameDesc = React.useMemo(
    () =>
      [encounterSideInfo?.data?.lastName, encounterSideInfo?.data?.firstName]
        .filter((x) => !!x)
        .join(", ") || "N/A",
    [encounterSideInfo]
  );

  const providernameDesc = React.useMemo(
    () =>
      [
        encounterOverview?.data?.supervisingProviderLastName,
        encounterOverview?.data?.supervisingProviderFirstName,
      ]
        .filter((x) => !!x)
        .join(", ")
        .toUpperCase() || "N/A",
    [encounterOverview]
  );

  const { yearsBirth, monthsBirth } = React.useMemo(() => {
    const dob = new Date(encounterSideInfo?.data.dob || new Date());
    let currentDate = new Date();

    if (dob.getMonth() !== currentDate.getMonth())
      currentDate = lastDayOfMonth(currentDate);

    const diffMonths = differenceInMonths(dob, currentDate);
    const yearsBirthday = Math.abs(Math.trunc(diffMonths / 12));
    const monthsBirthday = Math.abs(diffMonths % 12);
    return { yearsBirth: yearsBirthday, monthsBirth: monthsBirthday };
  }, [encounterSideInfo]);

  React.useEffect(() => {
    if (!isLoading && hasData) handlePrint();
  }, [isLoading, hasData, handlePrint]);

  const dobDescription = React.useMemo(() => {
    try {
      if (
        !encounterSideInfo?.data.dob ||
        !isValid(dateWithoutTimezone(encounterSideInfo?.data.dob))
      )
        return "N/A";
      return format(
        dateWithoutTimezone(encounterSideInfo?.data.dob),
        "MM/dd/yyyy"
      );
    } catch (error) {
      return "N/A";
    }
  }, [encounterSideInfo]);

  return (
    <>
      <ModalBody p="50px">
        <Loading />
      </ModalBody>

      <Box hidden>
        <Box
          ref={printRef}
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gridRowGap="0px"
          gridColumnGap="8.64pxpx"
          m="0"
          p="36.5px 14px 0 14px"
        >
          {[...Array(30)].map((_, index) => (
            <Box
              display="flex"
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              w="189.36px"
              h="72px"
              fontSize="8px"
              textTransform="uppercase"
              p="0 1rem"
              key={`keys${index}`}
            >
              <Box display="flex" flexDir="column">
                <chakra.span>{nameDesc}</chakra.span>
                <chakra.span>DOB: {dobDescription}</chakra.span>
                <chakra.span>
                  {!customProvider ? providernameDesc : customProvider}
                </chakra.span>
                <chakra.span>MRN: {encounterSideInfo?.data.mrn}</chakra.span>
              </Box>
              <Box display="flex" flexDir="column">
                &nbsp;
                <chakra.span>
                  {format(
                    new Date(encounterOverview?.data.date || new Date()),
                    "MM/dd/yyyy"
                  )}
                </chakra.span>
                <chakra.span>
                  {yearsBirth}y{yearsBirth < 1 ? ` ${monthsBirth}m` : ""}
                </chakra.span>
                <chakra.span>SEX: {encounterSideInfo?.data.gender}</chakra.span>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

function PrintLabelsActionModal(props: PrintLabelsModalProps) {
  const { encounterId, ...rest } = props;
  const { isOpen } = rest;
  return (
    <Modal isCentered {...rest}>
      <ModalOverlay />
      <ModalContent bgColor="gray.200" padding="0.5rem">
        {isOpen && <PrintLabelsActionModalContent {...props} />}
      </ModalContent>
    </Modal>
  );
}

export { PrintLabelsModal, PrintLabelsActionModal };
