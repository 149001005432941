import axios from "axios";
import { useAxiosQuery } from "hooks";

function useUserProfilePicture(
  resourceUrl: string,
  currentUserPicture = false
) {
  return useAxiosQuery(
    ["UserProfilePicture", { resourceUrl, currentUserPicture }],
    () => axios.get<Blob>(resourceUrl, { responseType: "blob" }),
    { staleTime: 1 * 60 * 60 * 1000 }
  );
}

export { useUserProfilePicture };
