import { EncounterDischargedSubstatus, EncounterStatus } from "enums";

import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UpdateEncounterStatusParams = {
  status: EncounterStatus;
  subStatus?: EncounterDischargedSubstatus;
};

function useUpdateEncounterStatus(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterStatus", { encounterId }],
    (payload: UpdateEncounterStatusParams) =>
      axiosPatientInstance.put(
        `v1/Encounter/${encounterId}/change-status`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOverview", { encounterId }], {
          refetchInactive: true,
        });
        queryClient.invalidateQueries("encounters");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("receptionCounters");
        queryClient.invalidateQueries(["inProcessCounters"]);
        queryClient.invalidateQueries([
          "patientInfoByEncounter",
          { encounterId },
        ]);
      },
    }
  );
}

export { useUpdateEncounterStatus };
