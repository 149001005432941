import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type PatchAmbulatoryParams = {
  encounterId: string;
};

function useUpdateAmbulatory({ encounterId }: PatchAmbulatoryParams) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateVitalCheck"],
    (payload: { ambulatory: boolean }) =>
      axiosChartInstance.put(`v1/vitals/${encounterId}/ambulatory`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitals"]);
        queryClient.invalidateQueries(["chartPatientCard", { encounterId }]);
      },
    }
  );
}

export { useUpdateAmbulatory };
