import { Divider, DividerProps } from "@chakra-ui/react";
import * as React from "react";
import { AnyObject } from "types";
import { isLastIndex } from "utils";

type DividerListProps = React.PropsWithChildren<AnyObject> & {
  dividerLeftMargin?: string;
  dividerProps?: DividerProps;
};

function DividerList(props: DividerListProps) {
  const { children, dividerProps, dividerLeftMargin = "16px" } = props;
  const childrenArr = React.Children.toArray(children).filter((x) => !!x);

  return (
    <>
      {childrenArr.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {child}
          {!isLastIndex(index, childrenArr) && (
            <Divider
              width={`calc(100% - ${dividerLeftMargin})`}
              marginLeft={dividerLeftMargin}
              {...dividerProps}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export type { DividerListProps };
export { DividerList };
