import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { EncounterDiet } from "modules/in-process/types";

type UseEncounterDiet = { encounterId: string };

function useEncounterDiet(props: UseEncounterDiet) {
  const { encounterId } = props;
  return useAxiosQuery(["inProcessEncounterDiet", { encounterId }], () =>
    axiosPatientInstance.get<EncounterDiet>(
      `v1/InProcess/${encounterId}/diet`,
      {}
    )
  );
}

export { useEncounterDiet };
