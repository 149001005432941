import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { SupervisingProvider } from "modules/reception/types";

type SupervisingProviderResponse = {
  supervisingProvider: SupervisingProvider;
};

function useActiveSupervisingProvider() {
  return useAxiosQuery(["activeSupervisingProvider"], () =>
    axiosPatientInstance.get<SupervisingProviderResponse>(
      `v1/supervising-provider/active`
    )
  );
}

export { useActiveSupervisingProvider };
