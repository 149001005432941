import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  ModalHeader,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { EmrTrashcan } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import {
  Circle as CircleIcon,
  CurveLine as CurveLineIcon,
  DiagonalArrow,
  EllipseOutlined,
  Line as LineIcon,
  TextBox,
} from "icons";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import * as React from "react";
import { MdCircle } from "react-icons/md";
import { Image, Layer, Stage } from "react-konva";
import { WarningDialog } from "shared";
import useImage from "use-image";
import { extractApiErrorMessage } from "utils";
import { useSaveDiagrams } from "./api";
import { TransformerArrow } from "./CanvasArrow";
import { TransformerCircle } from "./CanvasCircle";
import { TransformerLine } from "./CanvasLine";
import { CanvasEditableText, CanvasText } from "./CanvasText";
import { DiagramAction, DiagramsState, DiagramState } from "./diagram-reducer";
import { DiagramCanvasPreview } from "./DiagramCanvasPreview";
import { DiagramsPreview } from "./DiagramsPreview";
import { Diagram } from "./types";
import {
  isShapeArrow,
  isShapeCircle,
  isShapeCurveLine,
  isShapeEllipse,
  isShapeLine,
  isShapeText,
} from "./utils";
import { TransformerEllipse } from "./CanvasEllipse";
import { TransformerCurveLine } from "./CanvasCurveLine";

type BaseDiagramCanvasProps = {
  state?: DiagramState | null;
  currentColor: string;
  dispatch: React.Dispatch<DiagramAction>;
};

type DiagramCanvasStageProps = {
  diagramImgUrl?: string;
} & BaseDiagramCanvasProps;

type DiagramCanvasModalProps = {
  diagrams: Diagram[];
  onClose: () => void;
  encounterId: string;
  chartCode: string;
};

type DiagramCanvasProps = DiagramCanvasModalProps & {
  state: DiagramsState;
  dispatch: React.Dispatch<DiagramAction>;
};

type DiagramCanvasLabelProps = BaseDiagramCanvasProps & {
  label: string;
  short: string;
  isLabelItalic?: boolean;
};

const DiagramCanvasStage = React.forwardRef<
  Konva.Stage,
  DiagramCanvasStageProps
>((props, ref) => {
  const { diagramImgUrl, state, dispatch } = props;
  const [image] = useImage(diagramImgUrl ?? "");

  const handleDragEnd = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const id = e.target.id();

      dispatch({
        type: "EDIT_SHAPE",
        payload: {
          id,
          x: e.target.x(),
          y: e.target.y(),
        },
      });
    },
    [dispatch]
  );

  const deselectShape = React.useCallback(
    function <T>(e: KonvaEventObject<T>) {
      if (!state?.selectedShape) {
        return;
      }

      const clickedOnEmpty = e.target === e.target.getStage();

      if (clickedOnEmpty) {
        dispatch({ type: "DESELECT_SHAPE" });
      }
    },
    [dispatch, state?.selectedShape]
  );

  return (
    <Stage
      ref={ref}
      width={1000}
      height={630}
      onMouseDown={deselectShape}
      onTouchStart={deselectShape}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Layer>
        <Image image={image} x={1000 / 2 - (image?.width ?? 0) / 2} y={0} />
        {state?.shapes.map((shape) => {
          const { id = "" } = shape.config;
          const shapeProps = {
            key: id,
            onClick: () => {
              dispatch({ type: "SELECT_SHAPE", payload: id });
            },
            isSelected: state?.selectedShape === id,
            dispatch,
            onDragEnd: handleDragEnd,
          };

          if (isShapeCircle(shape)) {
            return <TransformerCircle {...shape.config} {...shapeProps} />;
          }

          if (isShapeLine(shape)) {
            return <TransformerLine {...shape.config} {...shapeProps} />;
          }

          if (isShapeText(shape)) {
            return (
              <CanvasText fontSize={16} {...shape.config} {...shapeProps} />
            );
          }

          if (isShapeArrow(shape)) {
            return <TransformerArrow {...shape.config} {...shapeProps} />;
          }

          if (isShapeEllipse(shape)) {
            return <TransformerEllipse {...shape.config} {...shapeProps} />;
          }

          if (isShapeCurveLine(shape)) {
            return <TransformerCurveLine {...shape.config} {...shapeProps} />;
          }

          return (
            <CanvasEditableText
              {...shape.config}
              {...shapeProps}
              fontSize={16}
            />
          );
        })}
      </Layer>
    </Stage>
  );
});

function DiagramCanvasActions(props: BaseDiagramCanvasProps) {
  const { state, dispatch, currentColor } = props;
  const onClickTextBox = () => {
    dispatch({
      type: "CREATE_EDITABLE_TEXT",
    });
  };

  const onClickCircle = () => dispatch({ type: "CREATE_CIRCLE" });

  const onClickLine = () => dispatch({ type: "CREATE_LINE" });

  const onClickCurveLine = () => dispatch({ type: "CREATE_CURVE_LINE" });

  const onClickArrow = () => dispatch({ type: "CREATE_ARROW" });

  const onClickEllipse = () => dispatch({ type: "CREATE_ELLIPSE" });

  const onClickDelete = () => {
    if (state?.selectedShape) {
      dispatch({ type: "DELETE_SHAPE", payload: state.selectedShape });
    }
  };

  const onChangeColor = (color: string) =>
    dispatch({ type: "CHANGE_COLOR", payload: color });

  return (
    <VStack rowGap="16px">
      <VStack
        width="50px"
        bg="gray.250"
        border="1px solid #D1D1D6"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
        borderRadius="10px"
        height="fit-content"
        spacing="0px"
        divider={<Divider size="xs" width="32px" />}
      >
        {["black", "blue", "green", "orange", "red"].map((color, index) => (
          <Box
            sx={currentColor === color ? { bgColor: "gray.450" } : {}}
            w="100%"
            textAlign="center"
            borderTopRadius={index === 0 ? "10px" : "0px"}
            borderBottomRadius={index === 4 ? "10px" : "0px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            h="32px"
          >
            <IconButton
              aria-label="create text box"
              variant="icon"
              icon={<Icon as={MdCircle} color={color} />}
              w="1.3125rem"
              onClick={() => onChangeColor(color)}
            />
          </Box>
        ))}
      </VStack>
      <VStack
        width="50px"
        bg="gray.250"
        spacing="15px"
        padding="6px 6px 11px"
        border="1px solid #D1D1D6"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
        borderRadius="10px"
        height="fit-content"
      >
        <Divider size="lg" width="32px" />
        <IconButton
          aria-label="create text box"
          variant="icon"
          icon={<Icon as={TextBox} color="gray.650" />}
          w="1.3125rem"
          h="1rem"
          onClick={onClickTextBox}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="create circle"
          variant="icon"
          icon={<Icon as={CircleIcon} color="gray.650" />}
          size="sm"
          onClick={onClickCircle}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="create ellipse"
          variant="icon"
          icon={<Icon as={EllipseOutlined} color="gray.650" />}
          size="sm"
          onClick={onClickEllipse}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="create line"
          variant="icon"
          icon={<Icon as={LineIcon} color="gray.650" />}
          size="sm"
          onClick={onClickLine}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="create curve line"
          variant="icon"
          icon={<Icon as={CurveLineIcon} color="gray.650" />}
          size="sm"
          onClick={onClickCurveLine}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="create arrow"
          variant="icon"
          icon={<Icon as={DiagonalArrow} color="gray.650" />}
          size="sm"
          onClick={onClickArrow}
        />
        <Divider size="lg" />
        <IconButton
          aria-label="delete selected element"
          variant="icon"
          icon={<Icon as={EmrTrashcan} color="gray.650" />}
          size="sm"
          onClick={onClickDelete}
          isDisabled={!state?.selectedShape}
        />
      </VStack>
    </VStack>
  );
}

function DiagramCanvasLabel(props: DiagramCanvasLabelProps) {
  const { label, short, isLabelItalic = false, dispatch } = props;
  const onCreateLabel = () => {
    dispatch({ type: "CREATE_LABEL", payload: { text: short } });
  };

  return (
    <Button
      variant="label"
      width="225px"
      height="42px"
      border="1px"
      borderColor="gray.450"
      bg="gray.250"
      padding="10px 17px"
      color="gray.650"
      fontStyle={isLabelItalic ? "italic" : "normal"}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      lineHeight="1.1875rem"
      onClick={onCreateLabel}
    >
      <Box
        fontStyle="normal"
        color="black"
        fontWeight="500"
        fontSize="1.0625rem"
        marginRight="27px"
        width="26px"
        textAlign="center"
        lineHeight="1.25rem"
      >
        {short}
      </Box>
      {label}
    </Button>
  );
}

type CanvasLabel = {
  label: string;
  short: string;
  isLabelItalic?: boolean;
};

const CANVAS_LABELS: CanvasLabel[] = [
  { label: "Abrasion", short: "A" },
  { label: "Burn", short: "B" },
  { label: "Contusion", short: "C" },
  { label: "Ecchymosis", short: "E" },
  { label: "Foreign Body", short: "fb" },
  { label: "Laceration", short: "L" },
  { label: "Muscle Spasm", short: "M" },
  { label: "Point Tenderness", short: "PtT" },
  { label: "Puncture Wound", short: "PW" },
  { label: "Swelling", short: "S" },
  { label: "Tenderness", short: "T" },
  { label: "without", short: "ø", isLabelItalic: true },
  { label: "mild", short: "m", isLabelItalic: true },
  { label: "moderate", short: "mod", isLabelItalic: true },
  { label: "severe", short: "sev", isLabelItalic: true },
];

function DiagramCanvasLabels(props: BaseDiagramCanvasProps) {
  return (
    <VStack
      spacing={0}
      height="630px"
      borderRadius="10px"
      overflow="hidden"
      border="1px"
      borderColor="gray.450"
    >
      {CANVAS_LABELS.map((label) => (
        <DiagramCanvasLabel key={label.short} {...props} {...label} />
      ))}
    </VStack>
  );
}

type DiagramActionButtonsProps = {
  onDiscard: () => void;
  onSave: () => void;
  onUndo: () => void;
  hasHistory: boolean;
  isLoading?: boolean;
  isDirty?: boolean;
};

const DiagramActionButtons = ({
  onDiscard,
  onSave,
  onUndo,
  isLoading,
  isDirty,
  hasHistory,
}: DiagramActionButtonsProps) => {
  const buttonStyle = {
    borderColor: "gray.700",
    bgColor: "gray.250",
    color: "gray.700",
  };

  return (
    <Flex justifyContent="center" width="100%" gap={6} height="40px">
      <Button
        variant="outlineSquared"
        borderRadius="10px"
        width="240px"
        height="inherit"
        sx={buttonStyle}
        onClick={onDiscard}
      >
        Discard Changes
      </Button>

      <Button
        variant="outlineSquared"
        borderRadius="10px"
        width="240px"
        height="inherit"
        _disabled={{ ...buttonStyle, cursor: "not-allowed" }}
        onClick={onSave}
        isLoading={isLoading}
        isDisabled={!isDirty || isLoading}
        sx={{ bgColor: "blue", color: "white" }}
      >
        Save Changes
      </Button>

      <Button
        variant="outlineSquared"
        borderRadius="10px"
        width="240px"
        height="inherit"
        _disabled={{ ...buttonStyle, cursor: "not-allowed" }}
        onClick={onUndo}
        isLoading={isLoading}
        isDisabled={!hasHistory}
        sx={{ bgColor: "blue", color: "white" }}
      >
        Undo Last Change
      </Button>
    </Flex>
  );
};

function DiagramCanvas(props: DiagramCanvasProps) {
  const toast = useToast();

  const [unsavedWarningNextAction, setUnsavedWarningNextAction] =
    React.useState(() => () => {});

  const [previousSelected, setPreviousSelected] = React.useState(0);

  const {
    isOpen: isConfirmationOpen,
    onClose: onConfirmationClose,
    onOpen: onConfirmationOpen,
  } = useDisclosure();

  const {
    isOpen: isUnsavedWarningOpen,
    onClose: onUnsavedWarningClose,
    onOpen: onUnsavedWarningOpen,
  } = useDisclosure();

  const {
    diagrams: diagramsProp,
    chartCode,
    encounterId,
    state,
    dispatch,
    onClose,
  } = props;
  const { diagrams, activeDiagramIndex, currentColor } = state;

  const internalDiagramsRef = React.useRef(diagramsProp);
  const stageRef = React.useRef<Konva.Stage>(null);

  const selectedDiagram =
    activeDiagramIndex !== null ? diagrams[activeDiagramIndex] : null;

  const { mutateAsync: saveDiagrams, isLoading } = useSaveDiagrams(
    encounterId,
    chartCode
  );

  const onSave = async () => {
    try {
      if (selectedDiagram) {
        const selectedDiagramPayload = {
          data: selectedDiagram.shapes,
          imageCode: selectedDiagram.imageCode,
          id: selectedDiagram.id,
        };

        await saveDiagrams(selectedDiagramPayload);
        onConfirmationOpen();
      }
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onDiscard = () => {
    if (selectedDiagram) {
      const index = diagramsProp.findIndex(
        (diagram) => diagram.id === selectedDiagram.id
      );
      dispatch({
        type: "SET_STATE",
        payload: {
          diagrams: diagramsProp.map((diagram) => ({
            ...diagram,
            selectedShape: null,
            history: [],
          })),
          activeDiagramIndex: index,
          currentColor,
        },
      });
    }
  };

  const onUndo = () => {
    if (selectedDiagram) {
      dispatch({ type: "UNDO" });
    }
  };

  // If diagrams is not empty, select first one
  React.useEffect(() => {
    const internalDiagrams = internalDiagramsRef.current;

    if (internalDiagrams.length > 0) {
      dispatch({
        type: "SET_STATE",
        payload: {
          diagrams: internalDiagrams.map((diagram) => ({
            ...diagram,
            selectedShape: null,
            history: [],
          })),
          activeDiagramIndex: previousSelected,
          currentColor: "black",
        },
      });
    }
  }, [dispatch, previousSelected]);

  return (
    <>
      <ModalHeader
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="gray.200"
        borderBottom="1px"
        borderColor="gray.400"
      >
        <Box fontSize="1.0625rem" fontWeight="600">
          Diagrams
        </Box>
        <Button
          position="absolute"
          right="20px"
          variant="label"
          color="blue"
          onClick={() => {
            if (selectedDiagram?.isDirty) {
              setUnsavedWarningNextAction(() => onClose);
              onUnsavedWarningOpen();
            } else {
              onClose();
            }
          }}
        >
          Done
        </Button>
      </ModalHeader>

      <Box padding="20px">
        <HStack spacing="20px" alignItems="flex-start">
          <VStack spacing="20px">
            <DiagramCanvasStage
              ref={stageRef}
              diagramImgUrl={selectedDiagram?.imageUrl}
              dispatch={dispatch}
              state={selectedDiagram}
              currentColor={currentColor}
            />
            <DiagramActionButtons
              onSave={onSave}
              onDiscard={onDiscard}
              onUndo={onUndo}
              isLoading={isLoading}
              isDirty={selectedDiagram?.isDirty}
              hasHistory={Boolean(selectedDiagram?.history?.length)}
            />
          </VStack>

          <DiagramCanvasActions
            dispatch={dispatch}
            state={selectedDiagram}
            currentColor={currentColor}
          />
          <DiagramCanvasLabels
            state={selectedDiagram}
            dispatch={dispatch}
            currentColor={currentColor}
          />
        </HStack>
        <HStack p={3} pt={4} pb={0}>
          <DiagramsPreview
            title="Saved Images"
            encounterId={encounterId}
            chartCode={chartCode}
          >
            {diagrams.map((diagram, index) => (
              <DiagramCanvasPreview
                encounterId={encounterId}
                chartCode={chartCode}
                key={diagram.id ? diagram.id : diagram.imageCode}
                diagram={diagram}
                isActive={
                  !!selectedDiagram?.id
                    ? selectedDiagram.id === diagram.id
                    : !diagram.id &&
                      selectedDiagram?.imageCode === diagram.imageCode
                }
                onClick={() => {
                  if (selectedDiagram?.isDirty) {
                    onUnsavedWarningOpen();
                    setPreviousSelected(index);
                    setUnsavedWarningNextAction(
                      () => () =>
                        dispatch({
                          type: "SELECT_DIAGRAM",
                          payload: index,
                        })
                    );
                  } else {
                    dispatch({
                      type: "SELECT_DIAGRAM",
                      payload: index,
                    });
                  }
                }}
                dispatch={dispatch}
                deleteMode
              />
            ))}
          </DiagramsPreview>
        </HStack>
        <WarningDialog
          isOpen={isConfirmationOpen}
          onCancel={onConfirmationClose}
          title="Diagrams Saved"
          mainText="The diagrams have been successfully updated."
          onClose={onConfirmationClose}
          onAction={onConfirmationClose}
          actionLabel="Ok"
          blockScrollOnMount={false}
          actionButtonProps={{ color: "blue" }}
          actionsDirection="vertical"
          onlyActionButton
        />
        <WarningDialog
          isOpen={isUnsavedWarningOpen}
          onClose={onUnsavedWarningClose}
          title="Warning!"
          mainText={
            <chakra.span>
              You have unsaved changes <br /> <br />
              Would you like to save these changes before continuing?
            </chakra.span>
          }
          actionsDirection="vertical"
          blockScrollOnMount={false}
          cancelButtonProps={{ color: "gray.800" }}
          cancelLabel="Cancel"
          onCancel={onUnsavedWarningClose}
          actionButtonProps={{ color: "red" }}
          actionLabel="Discard Changes"
          onAction={() => {
            onDiscard();
            onUnsavedWarningClose();
            unsavedWarningNextAction();
          }}
          showExtraActionButton
          extraActionButtonProps={{ color: "blue" }}
          extraActionLabel="Save Changes"
          onExtraAction={async () => {
            await onSave();
            onUnsavedWarningClose();
            unsavedWarningNextAction();
          }}
          isExtraActionLoading={isLoading}
        />
      </Box>
    </>
  );
}

export { DiagramCanvas };
export type { DiagramCanvasModalProps };
