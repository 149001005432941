import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Permissions = createIcon({
  path: (
    <path
      d="M7.44035 7.70545C9.34021 7.70545 10.975 6.00884 10.975 3.79971C10.975 1.61708 9.33137 0 7.44035 0C5.54934 0 3.90574 1.65243 3.90574 3.81738C3.90574 6.00884 5.54934 7.70545 7.44035 7.70545ZM15.3137 5.81443C13.8027 5.81443 12.6097 7.0162 12.6097 8.50074C12.6097 9.64065 13.2901 10.6215 14.324 11.028V15.1988C14.324 15.3049 14.377 15.3756 14.4477 15.4639L15.1546 16.1797C15.2342 16.2592 15.3579 16.268 15.4551 16.1797L16.7894 14.8365C16.8866 14.7482 16.8866 14.6333 16.7894 14.5449L15.9588 13.7054L17.1163 12.5832C17.1959 12.4948 17.187 12.38 17.0987 12.2739L15.9676 11.1429C17.2666 10.6215 18 9.66716 18 8.50074C18 7.0162 16.7982 5.81443 15.3137 5.81443ZM15.3049 7.07806C15.7555 7.07806 16.1267 7.45803 16.1267 7.90869C16.1267 8.37702 15.7555 8.74816 15.3049 8.74816C14.8454 8.74816 14.4654 8.37702 14.4654 7.90869C14.4654 7.45803 14.8365 7.07806 15.3049 7.07806ZM1.51105 15.8881H13.2813C13.1753 15.676 13.1222 15.4462 13.1222 15.1988V11.8675C12.7069 11.5405 12.38 11.1252 12.1502 10.6568C10.9485 9.84389 9.34904 9.28719 7.44035 9.28719C2.8542 9.28719 0 12.433 0 14.7128C0 15.4462 0.565538 15.8881 1.51105 15.8881Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 17",
});

export { Permissions };
