import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Collapse = createIcon({
  path: (
    <path
      d="M1.80361 8.98798H7.36473C8.38677 8.98798 8.97796 8.38677 8.97796 7.36473V1.81363C8.97796 1.29259 8.58717 0.881764 8.05611 0.881764C7.52505 0.881764 7.14429 1.28257 7.14429 1.81363V2.65531L7.35471 6.1523L4.70942 3.37675L1.63327 0.270541C1.46293 0.0901804 1.23247 0 0.991984 0C0.420842 0 0 0.380762 0 0.941884C0 1.2024 0.1002 1.45291 0.280561 1.63327L3.36673 4.71944L6.14228 7.35471L2.64529 7.15431H1.80361C1.27255 7.15431 0.861723 7.52505 0.861723 8.06613C0.861723 8.59719 1.26253 8.98798 1.80361 8.98798ZM11.4128 19.1583C11.9439 19.1583 12.3246 18.7675 12.3246 18.2265V17.2745L12.1142 13.7876L14.7595 16.5631L17.9058 19.7295C18.0762 19.9098 18.2966 20 18.5471 20C19.1082 20 19.5291 19.6192 19.5291 19.0581C19.5291 18.7976 19.4389 18.5471 19.2585 18.3667L16.1022 15.2104L13.3166 12.5752L16.8236 12.7756H17.7756C18.3066 12.7756 18.7174 12.4048 18.7174 11.8737C18.7174 11.3327 18.3166 10.9519 17.7756 10.9519H12.1042C11.0822 10.9519 10.491 11.5431 10.491 12.5651V18.2265C10.491 18.7575 10.8717 19.1583 11.4128 19.1583Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Collapse };
