import { useMatch, useNavigate } from "react-router-dom";
import { Icon, IconButton, useDisclosure } from "@chakra-ui/react";
import { useToast } from "hooks";
import { useDeleteChartApplication } from "modules/charts-shared/api";
import {
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonProps,
  WarningDialog,
} from "shared";
import { ChartType } from "types";
import { extractApiErrorMessage } from "utils";
import { EmrBed, EmrRemove, EmrVitals } from "@medstonetech/slate-icons";
import { NurseHat } from "icons";
import { usePermissions } from "contexts";

type ChartPickerNavigationElementProps = {
  isEditMode?: boolean;
  chartName: string;
  chartType: ChartType;
  chartCode: string;
  encounterId: string;
} & NavigationButtonProps;

export function ChartPickerNavigationElement(
  props: ChartPickerNavigationElementProps
) {
  const {
    chartName,
    chartType,
    isEditMode = false,
    chartCode,
    encounterId,
    ...restProps
  } = props;

  const match = useMatch({ path: restProps.to, end: false });
  const selected = !!match;

  const { mutateAsync: deleteChartApplication, isLoading } =
    useDeleteChartApplication(encounterId);
  const toast = useToast();
  const navigate = useNavigate();
  const { scope } = usePermissions();
  const mainRoute = window.location.hash.split("/")[1];

  const addDeleteNursePermission =
    chartType === "Triage" && scope("nurse:adddelete").isAccessible;

  const addDeleteProviderPermission =
    (chartType === "Chart" || chartType === "Observation") &&
    scope("provider:adddelete").isAccessible;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onDelete = async () => {
    try {
      await deleteChartApplication(chartCode);
      navigate(`/${mainRoute}/charts/${encounterId}/general/patient-info`);
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const getLabelIcon = () => {
    if (chartType === "Observation")
      return <Icon color="gray.450" as={EmrBed} fontSize="1.25rem" />;

    if (chartType === "Chart")
      return <Icon color="gray.450" as={EmrVitals} fontSize="1.25rem" />;

    return <Icon color="gray.450" as={NurseHat} fontSize="1.25rem" />;
  };

  return (
    <NavigationButton variant="chart" {...restProps}>
      <NavigationButtonContent label={chartName} labelIcon={getLabelIcon()} />
      {isEditMode && (addDeleteNursePermission || addDeleteProviderPermission) && (
        <IconButton
          aria-label="remove chart"
          icon={<Icon as={EmrRemove} color={selected ? "white" : "red"} />}
          variant="icon"
          isLoading={isLoading}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpen();
          }}
        />
      )}
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning!"
        mainText="Are you sure you want to delete this chart? This action cannot be undone."
        onClose={onClose}
        onAction={() => {
          onClose();
          onDelete();
        }}
        actionsDirection="horizantal"
      />
    </NavigationButton>
  );
}
