import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const BarGraph = createIcon({
  path: (
    <path
      d="M46.0647 29.7436H49.3326C51.0647 29.7436 52 28.843 52 27.1917V10.5635C52 8.90069 51.0647 8 49.3326 8H46.0647C44.3326 8 43.3972 8.90069 43.3972 10.5635V27.1917C43.3972 28.843 44.3326 29.7436 46.0647 29.7436ZM35.3603 29.7436H38.6282C40.3603 29.7436 41.2956 28.843 41.2956 27.1917V13.9238C41.2956 12.2725 40.3603 11.3603 38.6282 11.3603H35.3603C33.6282 11.3603 32.7044 12.2725 32.7044 13.9353V27.1917C32.7044 28.843 33.6282 29.7436 35.3603 29.7436ZM24.6559 29.7436H27.9238C29.6674 29.7436 30.6028 28.843 30.6028 27.1917V17.2956C30.6028 15.6443 29.6674 14.7321 27.9238 14.7321H24.6559C22.9353 14.7321 22 15.6443 22 17.3072V27.1917C22 28.843 22.9353 29.7436 24.6559 29.7436Z"
      fill="currentColor"
    />
  ),
  viewBox: "15 0 45 45",
});

export { BarGraph };
