import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrPerson } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  ChartRowGroup,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SPEX";
const chartCode = "C006x";

function T06PhysicalExam() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Physical Exam",
    chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSPEXQ001A001"
                    label="Nursing Assessment reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSPEXQ001A002"
                    label="Vitals Reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="input" name="Q001.C003xSPEXQ001A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A001"
                    label="no acute distress"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A002"
                    label="c-collar"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A003"
                    label="backboard (PTA / in ED)"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSPEXQ002A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A005"
                    label="alert"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A006"
                    label="mild"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A007"
                    label="moderate"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A008"
                    label="severe distress"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSPEXQ002A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A010"
                    label="anxious"
                  />
                  <ChartRowElement type="input" name="Q002.C006xSPEXQ002A012" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Extremities hand"
            >
              Extremities hand
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q003.C006xSPEXQ003A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A004"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A005"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A006"
                    label="soft-tissue"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A007"
                    label="bony"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q003.C006xSPEXQ003A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q003.C006xSPEXQ003A009"
                  />
                  <ChartRowElement type="input" name="Q003.C006xSPEXQ003A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A011"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A012"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q003.C006xSPEXQ003A013"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q003.C006xSPEXQ003A014"
                  />
                  <ChartRowElement type="input" name="Q003.C006xSPEXQ003A015" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C006xSPEXQ003A016"
                    label="deformity"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q003.C006xSPEXQ003A017"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q003.C006xSPEXQ003A018"
                  />
                  <ChartRowElement type="input" name="Q003.C006xSPEXQ003A019" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Wrist"
            >
              Wrist
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A004"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A005"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A006"
                    label="soft-tissue"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A007"
                    label="bony"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A009"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A011"
                    label="nml ROM"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A012"
                    label="tenderness in anatomical snuff box "
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A013"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A014"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A015" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A016"
                    label="wrist pain on axial thumb load "
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A017"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A018"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A019" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A020"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A021"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A022"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A023"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A025"
                    label="limited ROM"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A026"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A027"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A028" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C006xSPEXQ004A029"
                    label="deformity"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C006xSPEXQ004A030"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C006xSPEXQ004A031"
                  />
                  <ChartRowElement type="input" name="Q004.C006xSPEXQ004A032" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Forearm / Elbow"
            >
              Forearm / Elbow
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A004"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A005"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A006"
                    label="soft-tissue"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A007"
                    label="bony"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q005.C006xSPEXQ005A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q005.C006xSPEXQ005A009"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A011"
                    label="nml ROM"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A012"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A013"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q005.C006xSPEXQ005A014"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q005.C006xSPEXQ005A015"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A016" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A017"
                    label="limited ROM"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q005.C006xSPEXQ005A018"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q005.C006xSPEXQ005A019"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C006xSPEXQ005A021"
                    label="deformity"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q005.C006xSPEXQ005A022"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q005.C006xSPEXQ005A023"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A024" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Arm / Shoulder"
            >
              Arm / Shoulder
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q005.C006xSPEXQ005A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A004"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A005"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A006"
                    label="soft-tissue"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A007"
                    label="bony"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C006xSPEXQ006A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C006xSPEXQ006A009"
                  />
                  <ChartRowElement type="input" name="Q006.C006xSPEXQ006A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A011"
                    label="nml ROM"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A012"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A013"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C006xSPEXQ006A014"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C006xSPEXQ006A015"
                  />
                  <ChartRowElement type="input" name="Q006.C006xSPEXQ006A016" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A017"
                    label="limited ROM"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C006xSPEXQ006A018"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C006xSPEXQ006A019"
                  />
                  <ChartRowElement type="input" name="Q006.C006xSPEXQ006A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C006xSPEXQ006A021"
                    label="deformity"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C006xSPEXQ006A022"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C006xSPEXQ006A023"
                  />
                  <ChartRowElement type="input" name="Q006.C006xSPEXQ006A024" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Neuro / Vasc / Tendon"
            >
              Neuro / Vasc / Tendon
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A001"
                    label="sensation nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A002"
                    label="sensory deficit"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A003"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A004"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A006"
                    label="motor nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A007"
                    label="motor deficit"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A009"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A011"
                    label="no vascular compromise"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A012"
                    label="pulse deficit"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A013"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A015"
                    label="radial"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A016"
                    label="ulnar"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A018"
                    label="pallor"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A019"
                    label="abnml cap prefill"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A020"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A021"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A022" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A023"
                    label="tendon function nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A024"
                    label="deficit in tendon functions"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A025"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A026"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A027" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A028"
                    label="limited extension"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A029"
                    label="limited flexion"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A030" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A031"
                    label="tendon injury:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A032"
                    label="abrasion"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A033"
                    label="lac:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A034"
                    label="complete"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A035"
                    label="partial"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A036" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A075"
                    label="location:"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A037"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A038"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A039"
                    label="f-arm"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A040"
                    label="wrist"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A041"
                    label="hand"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A042"
                    label="digit"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A043" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A076"
                    label="involving:"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C006xSPEXQ007A044"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C006xSPEXQ007A045"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A046" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="empty" width="1.0rem" />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A047"
                    label="brachioradialis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A048"
                    label="FCR"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A049"
                    label="FCU"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="empty" width="1.0rem" />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A077"
                    label="FDS:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A050"
                    label="2nd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A051"
                    label="3rd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A052"
                    label="4th"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A053"
                    label="5th"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="empty" width="1.0rem" />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A078"
                    label="FDP:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A054"
                    label="2nd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A055"
                    label="3rd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A056"
                    label="4th"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A057"
                    label="5th"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A058"
                    label="ECR longus"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A059"
                    label="ECR brevis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A060"
                    label="ECU"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A061"
                    label="common ext"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A062" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A063"
                    label="EPL"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A064"
                    label="EPB"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A079"
                    label="ext digitorum:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A065"
                    label="2nd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A066"
                    label="3rd"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A067"
                    label="4th"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A068"
                    label="5th"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A069" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A070"
                    label="APL"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A071"
                    label="APB"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A072"
                    label="ext indicis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C006xSPEXQ007A073"
                    label="ext digiti minimi"
                  />
                  <ChartRowElement type="input" name="Q007.C006xSPEXQ007A074" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="SKIN"
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A001"
                    label="color nml, no rash"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A002"
                    label="diaphoretic"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A003"
                    label="cool"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A004"
                    label="cyanotic"
                  />
                  <ChartRowElement type="input" name="Q008.C006xSPEXQ008A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A006"
                    label="pressure ulcer"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A013"
                    label="location:"
                  />
                  <ChartRowElement type="input" name="Q008.C006xSPEXQ008A007" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A014"
                    label="depth / stage:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A008"
                    label="1"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A009"
                    label="2"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A010"
                    label="3"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C006xSPEXQ008A011"
                    label="4"
                    width="2.5rem"
                  />
                  <ChartRowElement type="input" name="Q008.C006xSPEXQ008A012" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Head / ENT"
            >
              Head / ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A002"
                    label="tenderness"
                  />
                  <ChartRowElement type="input" name="Q009.C006xSPEXQ009A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A004"
                    label="pharynx nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A005"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A006"
                    label="ecchymosis"
                  />
                  <ChartRowElement type="input" name="Q009.C006xSPEXQ009A007" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C006xSPEXQ009A008"
                    label="dry mucous membranes"
                  />
                  <ChartRowElement type="input" name="Q009.C006xSPEXQ009A009" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Neck / Back"
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C006xSPEXQ010A001"
                    label="nml inspection"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C006xSPEXQ010A002"
                    label="tenderness"
                  />
                  <ChartRowElement type="input" name="Q010.C006xSPEXQ010A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C006xSPEXQ010A004"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C006xSPEXQ010A005"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C006xSPEXQ010A006"
                    label="ecchymosis"
                  />
                  <ChartRowElement type="input" name="Q010.C006xSPEXQ010A007" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A001"
                    label="chest non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A002"
                    label="tenderness"
                  />
                  <ChartRowElement type="input" name="Q011.C006xSPEXQ011A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A004"
                    label="breath sounds nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A005"
                    label="swelling"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A006"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A007"
                    label="abrasions"
                  />
                  <ChartRowElement type="input" name="Q011.C006xSPEXQ011A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A009"
                    label="crepitus"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A010"
                    label="subcutaneous emphysema"
                  />
                  <ChartRowElement type="input" name="Q011.C006xSPEXQ011A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A012"
                    label="decreased breath sounds"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q011.C006xSPEXQ011A013"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q011.C006xSPEXQ011A014"
                  />
                  <ChartRowElement type="input" name="Q011.C006xSPEXQ011A015" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A016"
                    label="wheezes"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A017"
                    label="rales"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C006xSPEXQ011A018"
                    label="rhonchi"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q011.C006xSPEXQ011A019"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q011.C006xSPEXQ011A020"
                  />
                  <ChartRowElement type="input" name="Q011.C006xSPEXQ011A021" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A001"
                    label="heart sounds nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A002"
                    label="tachycardia"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A003"
                    label="bradycardia"
                  />
                  <ChartRowElement type="input" name="Q012.C003xSPEXQ012A004" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A001"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A002"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A002"
                    label="guarding"
                  />
                  <ChartRowElement type="input" name="Q013.C003xSPEXQ013A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A004"
                    label="no organomegaly"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="empty" width="1.0rem" />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A005"
                    label="generalized:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A006"
                    label="RUQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A007"
                    label="LUQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A008"
                    label="RLQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A010"
                    label="LLQ"
                  />
                  <ChartRowElement type="input" name="Q013.C003xSPEXQ013A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q013.C003xSPEXQ013A014"
                    label="catheter present"
                  />
                  <ChartRowElement type="input" name="Q013.C003xSPEXQ013A015" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T06PhysicalExam };
