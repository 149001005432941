import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const PersonKey = createIcon({
  path: (
    <path
      d="M8.39343 8.94663C6.08524 8.94663 4.21579 6.92458 4.21579 4.43516C4.21579 1.9839 6.09477 0 8.39343 0C10.7016 0 12.5615 1.95529 12.5615 4.41609C12.5615 6.91504 10.6921 8.94663 8.39343 8.94663ZM8.39343 7.29656C9.72875 7.29656 10.8352 6.04708 10.8352 4.41609C10.8352 2.83278 9.72875 1.65007 8.39343 1.65007C7.05811 1.65007 5.9517 2.85186 5.9517 4.43516C5.9517 6.05662 7.06765 7.29656 8.39343 7.29656ZM16.9585 8.09775C18.6849 8.09775 20.0679 9.4903 20.0679 11.1976C20.0679 12.5138 19.2667 13.5916 17.8456 14.2116L19.0283 15.3848C19.1809 15.5374 19.1714 15.7377 19.0474 15.8617L17.817 17.0635L18.6945 17.941C18.8184 18.0745 18.828 18.2652 18.6945 18.3988L17.1779 19.9058C17.0444 20.0393 16.8632 20.0202 16.7487 19.9153L15.9284 19.1046C15.8235 18.9997 15.7663 18.8852 15.7663 18.7517V14.1067C14.5931 13.6202 13.8396 12.5043 13.8396 11.1976C13.8396 9.4903 15.2131 8.09775 16.9585 8.09775ZM16.949 9.51891C16.4244 9.51891 15.9952 9.94812 15.9952 10.4632C15.9952 10.9973 16.434 11.4265 16.949 11.4265C17.4641 11.4265 17.8933 10.9973 17.8933 10.4632C17.8933 9.94812 17.4641 9.51891 16.949 9.51891ZM2.44172 17.817C0.791653 17.817 0 17.2924 0 16.1764C0 13.5726 3.27153 10.0626 8.38389 10.0626C9.92904 10.0626 11.3025 10.3869 12.4661 10.9019C12.428 11.684 12.552 12.3612 12.8095 12.9621C11.7317 12.2468 10.2343 11.7126 8.38389 11.7126C4.17764 11.7126 1.81222 14.4596 1.81222 15.9189C1.81222 16.1001 1.89806 16.1669 2.12697 16.1669H14.3737V17.817H2.44172Z"
      fill="#007AFF"
    />
  ),
  viewBox: "0 0 20 20",
});

export { PersonKey };
