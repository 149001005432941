import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T039PosturalVitalsChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="lying:"
            fontStyle="italic"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="sitting:"
            fontStyle="italic"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="standing:"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T040PosturalVitalsChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="lying:"
            fontStyle="italic"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="sitting:"
            fontStyle="italic"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="standing:"
            fontStyle="italic"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="BP"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
          <ChartRowElement
            label="HR"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A016`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function PosturalVitalsChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C039x: <T039PosturalVitalsChart sectionDelimiter={sectionDelimiter} />,
    C040x: <T040PosturalVitalsChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { PosturalVitalsChart };
