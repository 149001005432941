const HARD_STOPS_PROVIDER_SECTIONS = {
  orderHistory: "Order History",
  hpi: "HPI",
  ros: "ROS",
  physicalExam: "Physical Exam",
  medsAndIV: "Meds & IV",
  treatments: "Treatments",
  radiology: "Radiology",
  labs: "Labs",
  ekg: "EKG",
  disposition: "Disposition",
};

const HARD_STOPS_TRIAGE_SECTIONS = {
  chiefComplaint: "Chief Complaint",
  assessments: "Assessments",
  medsAndIV: "Meds & IV",
  treatments: "Treatments",
  ekg: "EKG",
  orderHistory: "Order History",
  disposition: "Disposition",
};

const HARD_STOPS_SECTIONS_ROUTES = {
  orderHistory: "order-history",
  hpi: "hpi",
  ros: "ros",
  physicalExam: "physical-exam",
  medsAndIV: "iv-and-medications",
  treatments: "treatments",
  radiology: "radiology",
  labs: "labs",
  assessments: "assessments",
  chiefComplaint: "chief-complaint",
  ekg: "ekg",
  disposition: "disposition",
};

const HARD_STOPS_SUBSECTIONS = {
  providerSignature: "Provider Signature",
  subsections: "Subsections",
  vitalsReviewed: "Vitals Reviewed",
  nursingAssesmentReviewed: "Nursing Assessment Reviewed",
  complete: "Complete",
  acknowledgements: "Acknowledgments",
  interpretations: "Interpretations",
  ekgStrip: "EKG Strip",
  hasOrders: "Has Orders",
  scribeSignature: "Scribe Signature",
  supervisorSignature: "Supervisor Signature",
  chargeNurseSignature: "Charge Nurse Signature",
  treatingNurseSignature: "Treating Nurse Signature",
};

export {
  HARD_STOPS_PROVIDER_SECTIONS,
  HARD_STOPS_TRIAGE_SECTIONS,
  HARD_STOPS_SUBSECTIONS,
  HARD_STOPS_SECTIONS_ROUTES,
};
