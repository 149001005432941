import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { Pharmacy } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type AssignPreferredPharmacyPayload = { pharmacyId: string };

function useAssignPreferredPharmacy(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["assignPreferredPharmacy"],
    (input: AssignPreferredPharmacyPayload) =>
      axiosPatientInstance.put<Pharmacy>(
        `/v1/encounter/${encounterId}/form/preferred-pharmacy/assign`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patientInfo", { encounterId }]);
      },
    }
  );
}

export { useAssignPreferredPharmacy };
export type { AssignPreferredPharmacyPayload };
