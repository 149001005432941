import * as React from "react";
import { Box, chakra, LinkProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type ModuleLinkProps = {
  to: string;
  moduleIcon: JSX.Element;
  label: string;
  isEnabled?: boolean;
  isExternal?: boolean;
  bg: string;
  hasAccess?: boolean;
} & LinkProps;

function ModuleLink(props: ModuleLinkProps) {
  const {
    to,
    moduleIcon,
    label,
    isEnabled = true,
    bg,
    isExternal = false,
    hasAccess: _hasAccess,
    ...linkProps
  } = props;

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <chakra.a
        href={isExternal ? to : undefined}
        aria-disabled={!isEnabled}
        tabIndex={!isEnabled ? -1 : undefined}
        bg={bg}
        as={!isExternal ? Link : undefined}
        to={to}
        display="flex"
        flexDirection="column"
        outline="none"
        _focus={{ transform: "scale(0.75)" }}
        _hover={{ transform: "scale(0.75)" }}
        _disabled={{ pointerEvents: "none" }}
        transition="all 200ms ease-in-out"
        marginBottom="1rem"
        width="10vh"
        height="10vh"
        borderRadius="0.9rem"
        justifyContent="center"
        alignItems="center"
        boxShadow="0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)"
        {...linkProps}
      >
        {React.cloneElement(moduleIcon, {
          width: "57%",
          height: "57%",
          color: "white",
        })}
      </chakra.a>
      <Box
        textAlign="center"
        fontWeight="500"
        fontSize="1.25em"
        color={isEnabled ? "black" : "gray.550"}
        sx={{ textSizeAdjust: "none", fontSizeAdjust: "none" }}
      >
        {label}
      </Box>
    </Box>
  );
}

export type { ModuleLinkProps };
export { ModuleLink };
