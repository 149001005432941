import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateAuditCommentsPayload = { comment: string };

function useCreateAuditComments(
  encounterId: string,
  chartCode: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "createAuditComments",
      { encounterId, chartCode, sectionCode, subsectionCode },
    ],
    (input: UseCreateAuditCommentsPayload) =>
      axiosChartInstance.post(
        `/v1/audits/subsection/comments/${encounterId}/${chartCode}/${sectionCode}/${subsectionCode}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditComments",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSectionIndicator",
          { encounterId, chartCode, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSubsectionIndicator",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

function useCreateAuditCommentsShared(
  encounterId: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createAuditCommentsShared", { encounterId, sectionCode, subsectionCode }],
    (input: UseCreateAuditCommentsPayload) =>
      axiosChartInstance.post(
        `/v1/audits/subsection/comments/${encounterId}/shared/${sectionCode}/${subsectionCode}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsShared",
          { encounterId, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSharedSectionIndicator",
          { encounterId, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSharedSubsectionIndicator",
          { encounterId, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

export type { UseCreateAuditCommentsPayload };
export { useCreateAuditComments, useCreateAuditCommentsShared };
