import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import { RadiologyInterpretationProps } from "../radiology-chart/types";

function F010Interpretation(props: RadiologyInterpretationProps) {
  const {
    index,
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
          subsectionLabel="Foot"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        />
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Foot"
                name={`${sectionDelimiter}Q001A001`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q001A002`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q001A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="calcaneus:"
                name={`${sectionDelimiter}Q001A004`}
                type="option"
              />
              <ChartRowElement
                label="body"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A005`}
                type="option"
              />
              <ChartRowElement
                label="ant process"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A006`}
                type="option"
              />
              <ChartRowElement
                label="tuberosity"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A007`}
                type="option"
              />
              <ChartRowElement
                label="extra-articular"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A008`}
                type="option"
              />
              <ChartRowElement
                label="intra-articular"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A009`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A010`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="talus:"
                name={`${sectionDelimiter}Q001A011`}
                type="option"
              />
              <ChartRowElement
                label="head"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A012`}
                type="option"
              />
              <ChartRowElement
                label="neck"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A013`}
                type="option"
              />
              <ChartRowElement
                label="body"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A014`}
                type="option"
              />
              <ChartRowElement
                label="dome"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A015`}
                type="option"
              />
              <ChartRowElement
                label="post process"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A016`}
                type="option"
              />
              <ChartRowElement
                label="lat process"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A017`}
                type="option"
              />
              <ChartRowElement
                label="extra-articular"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A018`}
                type="option"
              />
              <ChartRowElement
                label="intra-articular"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A019`}
                type="option"
              />
              <ChartRowElement
                label="avulsion"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A020`}
                type="option"
              />
              <ChartRowElement
                label="chip fx"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A021`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A022`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="navicular"
                name={`${sectionDelimiter}Q001A023`}
                type="option"
              />
              <ChartRowElement
                label="cuboid"
                name={`${sectionDelimiter}Q001A024`}
                type="option"
              />
              <ChartRowElement
                label="cuniform:"
                name={`${sectionDelimiter}Q001A025`}
                type="option"
              />
              <ChartRowElement
                label="medial"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A026`}
                type="option"
              />
              <ChartRowElement
                label="middle"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A027`}
                type="option"
              />
              <ChartRowElement
                label="lateral"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A028`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A029`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="metatarsal:"
                name={`${sectionDelimiter}Q001A030`}
                type="option"
              />
              <ChartRowElement
                label="1st"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A031`}
                type="option"
              />
              <ChartRowElement
                label="2nd"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A032`}
                type="option"
              />
              <ChartRowElement
                label="3rd"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A033`}
                type="option"
              />
              <ChartRowElement
                label="4th"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A034`}
                type="option"
              />
              <ChartRowElement
                label="5th"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A035`}
                type="option"
              />
              <ChartRowElement
                label="SH:"
                name={`${sectionDelimiter}Q001A036`}
                type="option"
              />
              <ChartRowElement
                label="1"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A037`}
                type="option"
              />
              <ChartRowElement
                label="2"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A038`}
                type="option"
              />
              <ChartRowElement
                label="3"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A028`}
                type="option"
              />
              <ChartRowElement
                label="4"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A039`}
                type="option"
              />
              <ChartRowElement
                label="5"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A040`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A041`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="toe:"
                name={`${sectionDelimiter}Q001A042`}
                type="option"
              />
              <ChartRowElement
                label="1st"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A043`}
                type="option"
              />
              <ChartRowElement
                label="2nd"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A044`}
                type="option"
              />
              <ChartRowElement
                label="3rd"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A045`}
                type="option"
              />
              <ChartRowElement
                label="4th"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A034`}
                type="option"
              />
              <ChartRowElement
                label="5th"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A046`}
                type="option"
              />
              <ChartRowElement
                label="prox phalanx"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A047`}
                type="option"
              />
              <ChartRowElement
                label="middle"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A048`}
                type="option"
              />
              <ChartRowElement
                label="distal"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A049`}
                type="option"
              />
              <ChartRowElement
                label="SH:"
                name={`${sectionDelimiter}Q001A050`}
                type="option"
              />
              <ChartRowElement
                label="1"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A051`}
                type="option"
              />
              <ChartRowElement
                label="2"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A052`}
                type="option"
              />
              <ChartRowElement
                label="3"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A053`}
                type="option"
              />
              <ChartRowElement
                label="4"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A054`}
                type="option"
              />
              <ChartRowElement
                label="5"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A055`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A056`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="nondisplaced"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A057`}
                type="option"
              />
              <ChartRowElement
                label="displaced"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A058`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A059`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q001A060`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { F010Interpretation };
