import { axiosChartInstance } from "config";
import { ProgressNoteBy } from "enums/progress-noteBy";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartProgressItem } from "types";

type UseUpdateProgressNotes = {
  noteBy: ProgressNoteBy;
  encounterId: string;
};

function useUpdateProgressNotes(props: UseUpdateProgressNotes) {
  const { encounterId, noteBy } = props;

  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateProgressNotes"],
    (input: ChartProgressItem[]) =>
      axiosChartInstance.put(
        `v1/ProgressNote/${encounterId}/${noteBy}/bulk`,
        input
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(["progressNotes"]),
    }
  );
}

export { useUpdateProgressNotes };
