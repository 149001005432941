import { useAxiosMutation } from "hooks";
import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";

function useDeletePatientByEncounterId(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deletePatientByEncounterId", { encounterId }],
    () => axiosPatientInstance.delete(`v1/${encounterId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("searchPatient");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("patients", { refetchInactive: true });
        queryClient.invalidateQueries("receptionCounters");
        queryClient.invalidateQueries(["inProcessCounters"]);
      },
    }
  );
}

export { useDeletePatientByEncounterId };
