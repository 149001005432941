import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ExpandAlt = createIcon({
  path: (
    <path
      d="M1.26739 8.8408C0.535806 8.8408 0 8.3153 0 7.59402V2.00927C0 0.741886 0.741886 0 2.00927 0H7.71767C8.43895 0 8.96445 0.535806 8.96445 1.25708C8.96445 1.98867 8.42864 2.51417 7.71767 2.51417H6.97579L3.97733 2.28748L6.27512 4.46162L9.10871 7.2849C9.3457 7.5219 9.48995 7.83102 9.48995 8.18135C9.48995 8.95415 8.94384 9.48995 8.17105 9.48995C7.83101 9.48995 7.5219 9.36631 7.2849 9.11901L4.47192 6.28542L2.28748 3.98764L2.51417 6.95518V7.59402C2.51417 8.3153 1.97836 8.8408 1.26739 8.8408ZM12.272 20C11.5507 20 11.0252 19.4745 11.0252 18.7429C11.0252 18.0216 11.5507 17.4961 12.272 17.4961H13.0139L16.0021 17.7125L13.7043 15.5487L10.8707 12.7254C10.6337 12.4781 10.4997 12.169 10.4997 11.829C10.4997 11.0562 11.0459 10.5204 11.8186 10.5204C12.1484 10.5204 12.4678 10.644 12.7048 10.8913L15.5178 13.7249L17.6919 16.0124L17.4755 13.0551V12.4163C17.4755 11.6847 18.001 11.1695 18.7223 11.1695C19.4436 11.1695 19.9794 11.695 19.9794 12.4163V17.9907C19.9794 19.2684 19.2478 20 17.9701 20H12.272Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { ExpandAlt };
