import { chakra, Input, InputProps } from "@chakra-ui/react";
import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { NumberSelector, NumberSelectorProps } from "shared";
import { useDivideControlProps } from "./hooks";

type FormCounterInputProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> &
    NumberSelectorProps & {
      inputProps?: Omit<InputProps, "name">;
      inputName?: string;
      inputLabel?: string;
    };

function FormCounterInputOption<TFieldValues extends FieldValues>(
  props: FormCounterInputProps<TFieldValues>
) {
  const {
    onChange: onChangeProp,
    defaultValue,
    inputName,
    inputProps,
    inputLabel,
    ...restProps
  } = props;
  const [counterOptionProps, { name }] = useDivideControlProps<
    TFieldValues,
    FormCounterInputProps<TFieldValues>
  >(restProps);

  const { control, register } = useFormContext();
  const {
    field: { ref: fieldRef, onChange, onBlur, value, ...restField },
  } = useController({
    control: control,
    name: name,
  });

  const handleChange: NumberSelectorProps["onChange"] = (newValue) => {
    onChangeProp?.(newValue);
    onChange(newValue === "0" ? "" : newValue);
  };

  const active = React.useMemo(() => {
    return parseInt(value || "") > 0;
  }, [value]);

  const widthProps = inputProps?.width
    ? { width: inputProps?.width }
    : { flex: "1" };

  const textColor = active ? "blue" : "gray.700";

  return (
    <>
      <NumberSelector
        value={value}
        onChange={handleChange}
        color={textColor}
        focusBorderColor={textColor}
        defaultValue="0"
        {...counterOptionProps}
        {...restField}
      />
      {!!inputName && (
        <Input
          bg="white"
          borderColor="gray.700"
          border="1px solid"
          autoComplete="off"
          ml="10px"
          color={active ? textColor : "black"}
          fontWeight="600"
          {...inputProps}
          {...widthProps}
          {...register(inputName)}
        />
      )}
      {!!inputLabel && (
        <chakra.span fontWeight="600" color={textColor} marginX="10px">
          {inputLabel}
        </chakra.span>
      )}
    </>
  );
}

export { FormCounterInputOption };
