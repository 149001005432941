import * as yup from "yup";
import { formMessages } from "messages";
import { regex } from "utils";

// eslint-disable-next-line no-template-curly-in-string
const defaultErrorMessage = formMessages.validPhone("${path}");

function validate(value: string | undefined) {
  /**
   * An empty string is a valid phone number value.
   * If it needs a non-empty value, it should be
   * required in the schema.
   */
  if (!value) return true;

  return regex.phoneNumber.test(value);
}

function phoneSchema(errorMessage = "") {
  const errMsg = errorMessage || defaultErrorMessage;

  return yup.string().test("phone", errMsg, validate);
}

export { validate as phone, phoneSchema };
