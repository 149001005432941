import * as React from "react";
import { useToast } from "hooks";
import { genericErrors } from "messages";
import { ChartNote } from "modules";
import { ChartNote as ChartNoteType } from "types";
import { extractApiErrorMessage } from "utils";
import {
  useDeleteChartNote,
  useExportableChartNote,
} from "modules/charts-shared/api";

type AsyncChartNoteProps = {
  note: ChartNoteType;
};

function AsyncChartNote(props: AsyncChartNoteProps) {
  const { note } = props;
  const { id, subsection } = note;
  const toast = useToast();
  const { mutateAsync: deleteNote, isLoading } = useDeleteChartNote(
    id,
    !!subsection
  );
  const { mutateAsync: exportNote, isLoading: isExporting } =
    useExportableChartNote(id, !!subsection);

  const onDeleteChartNote = async () => {
    try {
      await deleteNote({});
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const onExportChartNote = async () => {
    try {
      await exportNote(!note.isExportable);
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <ChartNote
      isDeleting={isLoading}
      note={note}
      onDeleteChartNote={onDeleteChartNote}
      onExportChartNote={onExportChartNote}
      isExporting={isExporting}
    />
  );
}

export type { AsyncChartNoteProps };
export { AsyncChartNote };
