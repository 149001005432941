import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const ContrastTriangle = createIcon({
  path: (
    <path
      d="M3.02434 22.1442H20.9757C22.7971 22.1442 24 20.7695 24 19.1198C24 18.6158 23.8625 18.1002 23.599 17.6305L14.6062 1.54654C14.0334 0.504057 13.0253 0 12.0057 0C10.9862 0 9.96659 0.515513 9.3938 1.54654L0.400955 17.642C0.13747 18.1117 0 18.6158 0 19.1198C0 20.7695 1.20286 22.1442 3.02434 22.1442ZM12.063 17.3327C10.3332 17.3327 8.90119 15.9695 8.90119 14.2396C8.90119 13.243 9.38234 12.3036 9.86348 11.41L11.7652 7.89308C11.8453 7.75561 11.9255 7.67542 12.063 7.67542C12.2005 7.67542 12.3036 7.75561 12.3723 7.89308L14.2625 11.41C14.7437 12.2921 15.2363 13.243 15.2363 14.2396C15.2363 15.9695 13.8043 17.3327 12.063 17.3327Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 23",
});

export { ContrastTriangle };
