import {
  Box,
  BoxProps,
  HStack,
  StackProps,
  useMergeRefs,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";

type InProcessOptionsRadioProps = UseRadioGroupProps &
  Omit<StackProps, keyof UseRadioGroupProps>;

type InProcessOptionsRadioContextValue = {
  getRadioProps: ReturnType<typeof useRadioGroup>["getRadioProps"];
  isDisabled?: boolean;
};

const InProcessOptionsRadioContext =
  React.createContext<InProcessOptionsRadioContextValue | null>(null);

function useInProcessOptionsRadioContext() {
  const context = React.useContext(InProcessOptionsRadioContext);

  if (!context) {
    throw new Error(
      "Consumers of this context must be children of a Custom Radio component."
    );
  }

  return context;
}

type InProcessOptionsRadioItemProps = {
  value: string | number;
} & BoxProps;

function InProcessOptionsRadioItem(props: InProcessOptionsRadioItemProps) {
  const { value, ...boxProps } = props;
  const { getRadioProps, isDisabled } = useInProcessOptionsRadioContext();
  const radioProps = getRadioProps({ value });
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex="1" height="100%">
      <input {...input} disabled={isDisabled} />
      <Box
        {...checkbox}
        aria-disabled={isDisabled}
        height="100%"
        borderRadius="5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="gray.650"
        fontSize="0.8125rem"
        fontWeight="600"
        transition="all 200ms"
        cursor="pointer"
        _checked={{ bg: "white", borderColor: "white", color: "blue" }}
        _focus={{ boxShadow: "var(--chakra-shadows-outline)" }}
        _disabled={{ opacity: 0.6 }}
        {...boxProps}
      />
    </Box>
  );
}

const InProcessOptionsRadio = React.forwardRef<
  HTMLDivElement,
  InProcessOptionsRadioProps
>((props, ref) => {
  const {
    value,
    defaultValue,
    onChange,
    isDisabled,
    isFocusable,
    name,
    isNative,
    ...stackProps
  } = props;
  const { getRadioProps, getRootProps } = useRadioGroup({
    value,
    defaultValue,
    onChange,
    isDisabled,
    isFocusable,
    name,
    isNative,
  });

  const { ref: groupRef, ...rest } = getRootProps();
  const mergedRefs = useMergeRefs(ref, groupRef);

  const contextValue: InProcessOptionsRadioContextValue = React.useMemo(
    () => ({ getRadioProps, isDisabled }),
    [getRadioProps, isDisabled]
  );

  return (
    <InProcessOptionsRadioContext.Provider value={contextValue}>
      <HStack
        ref={mergedRefs}
        spacing="1px"
        bg="gray.300"
        borderRadius="5px"
        padding="2px"
        {...stackProps}
        {...rest}
      />
    </InProcessOptionsRadioContext.Provider>
  );
});

if (config.isDev) {
  InProcessOptionsRadio.displayName = "InProcessOptionsRadio";
}

export type { InProcessOptionsRadioProps };
export { InProcessOptionsRadio, InProcessOptionsRadioItem };
