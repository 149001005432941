import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const WarningCircleFilled = createIcon({
  path: (
    <path
      d="M8.36719 16.3281C12.7266 16.3281 16.3359 12.7109 16.3359 8.35938C16.3359 4 12.7188 0.390625 8.35938 0.390625C4.00781 0.390625 0.398438 4 0.398438 8.35938C0.398438 12.7109 4.01562 16.3281 8.36719 16.3281ZM8.36719 9.8125C7.96094 9.8125 7.73438 9.58594 7.72656 9.17188L7.625 4.91406C7.61719 4.5 7.92188 4.20312 8.35938 4.20312C8.78906 4.20312 9.10938 4.50781 9.10156 4.92188L8.99219 9.17188C8.98438 9.59375 8.75781 9.8125 8.36719 9.8125ZM8.36719 12.4297C7.89844 12.4297 7.49219 12.0547 7.49219 11.5938C7.49219 11.1328 7.89062 10.75 8.36719 10.75C8.84375 10.75 9.24219 11.125 9.24219 11.5938C9.24219 12.0625 8.83594 12.4297 8.36719 12.4297Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 17",
});

export { WarningCircleFilled };
