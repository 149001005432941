import { Box, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { format, isValid } from "date-fns";

import { Card } from "shared";
import { EmrClose } from "@medstonetech/slate-icons";
import { ORDER_STATUS_COLOR } from "modules/orders";
import { OrderItem } from "modules/charts-shared/types";
import { OrderStatus } from "enums";
import { toDisplay } from "utils/enum-mapper";

type OrderFormCartItemProps = {
  item: OrderItem;
  onDelete?: () => void;
  orderStatus?: OrderStatus;
};

function OrderFormCartItem({
  item: {
    orderElementType,
    description,
    notes,
    date,
    location,
    orderElementCategory,
    administrationTime,
  },
  onDelete,
  orderStatus,
}: OrderFormCartItemProps) {
  const borderColor = orderStatus
    ? ORDER_STATUS_COLOR[orderStatus]
    : "gray.700";

  return (
    <Card
      w="100%"
      p="10px 20px"
      variant="basic"
      borderLeft="8px solid"
      borderColor={borderColor}
    >
      <Flex justifyContent="space-between">
        <Box flex={1}>
          <Flex>
            <Text flex={1} color="gray">
              {orderElementType}
            </Text>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              flex={2}
              columnGap="10px"
            >
              {orderStatus && (
                <>
                  <Text color="gray">{format(date, "MM-dd-yyyy")}</Text>
                  <Text color="gray">{format(date, "HH:mm a")}</Text>
                </>
              )}
            </Box>
          </Flex>
          <Box display="flex" flexDir="column" flex={1}>
            <Text fontWeight="500">{description}</Text>
            {orderElementCategory && (
              <Text color="gray">
                {toDisplay("orderFormElementCategory", orderElementCategory)}
              </Text>
            )}
          </Box>
          <Flex>
            <Text fontWeight="500">{!!location?.length ? location : ""}</Text>
          </Flex>
          <Flex>
            <Text fontWeight="500">{notes}</Text>
          </Flex>
          <Flex>
            {administrationTime && isValid(new Date(administrationTime))
              ? format(new Date(administrationTime), "MM/dd/yyyy HH:mm")
              : ""}
          </Flex>
        </Box>
        {onDelete && (
          <Flex alignItems="flex-start" justifyContent="center">
            <IconButton
              aria-label="Delete Item"
              variant="icon"
              color="red"
              onClick={onDelete}
            >
              <Icon as={EmrClose} />
            </IconButton>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export { OrderFormCartItem };
