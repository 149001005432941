import { useAuth0 } from "@auth0/auth0-react";
import { Button, Icon, useDisclosure, Image } from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { formMessages, genericErrors } from "messages";
import {
  useAddUserSignature,
  useGetUserSignature,
} from "modules/onboarding/api";
import { USERID_CLAIMS } from "system-constants";
import { AddSignatureModal } from "./AddSignatureModal";

export const SignatureManager = () => {
  const toast = useToast();

  const { user } = useAuth0();
  const userId = user ? user[USERID_CLAIMS] : "";

  const {
    isOpen: isAddSignatureOpen,
    onClose: onAddSignatureClose,
    onOpen: onAddSignatureOpen,
  } = useDisclosure();

  const { data, isLoading: isGetSignatureLoading } =
    useGetUserSignature(userId);

  const { mutateAsync: addSignature, isLoading } = useAddUserSignature(userId);

  const handleOnSign = async (signature: string) => {
    try {
      await addSignature({ signature });
      toast({
        status: "success",
        description: formMessages.createSuccess("Signature"),
      });
      onAddSignatureClose();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <>
      {data?.data.signature ? (
        <Image src={data?.data.signature} height="100%" width="100%" />
      ) : (
        <Button
          variant="label"
          color="blue"
          onClick={onAddSignatureOpen}
          isLoading={isGetSignatureLoading}
        >
          <Icon as={EmrSignature} fontSize="1.5rem" color="blue" m={2} />
          Add Signature
        </Button>
      )}

      <AddSignatureModal
        isOpen={isAddSignatureOpen}
        onClose={onAddSignatureClose}
        onSign={handleOnSign}
        isLoading={isLoading}
      />
    </>
  );
};
