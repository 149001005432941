import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useExportableChartNote(noteId: string, isSubsection: boolean) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["exportableChartNote", { noteId }],
    (isExportable: boolean) => {
      if (isSubsection) {
        return axiosChartInstance.put(
          `v1/ChartNoteSection/update-exportable/${noteId}/${isExportable}`
        );
      }
      return axiosChartInstance.put(
        `v1/ChartNoteSection/update-exportable/${noteId}/${isExportable}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartNotes"]);
        queryClient.invalidateQueries(["sectionNotesCount"]);
        queryClient.invalidateQueries(["subSectionNotesCount"]);
      },
    }
  );
}

export { useExportableChartNote };
