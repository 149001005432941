import React, { forwardRef } from "react";
import { chakra, ListItemProps, useStyles } from "@chakra-ui/react";

const DropdownMenuItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ children, ...props }, ref) => {
    const styles = useStyles();

    return (
      <chakra.li ref={ref} __css={styles.dropdownmenuitem} {...props}>
        {children}
      </chakra.li>
    );
  }
);

DropdownMenuItem.displayName = "DropdownMenuItem";

export { DropdownMenuItem };
