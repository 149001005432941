import * as React from "react";
import { useToast } from "hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { TriageChartCode } from "enums";
import {
  useTriageChartProcedures,
  useUpdateTriageChartProcedures,
} from "../api";
import { ProceduresForm, TriageRouteBaseParams } from "../types";
import { proceduresUtils } from "../utils";
import { nextRoutesNurse, nextRoutesProvider } from "modules/charts-shared";

type UseTriageChartProceduresFormProps = {
  chartCode: TriageChartCode;
};

function useTriageChartProceduresForm(
  props: UseTriageChartProceduresFormProps
) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<TriageRouteBaseParams>();

  const [nextRoute, setNextRoute] = React.useState<string | null>(null);

  const { chartCode } = props;
  const navigate = useNavigate();
  const useFormReturn = useForm<ProceduresForm>({
    defaultValues: { sections: [] },
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormReturn;
  const toast = useToast();
  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useTriageChartProcedures({
    encounterId,
    chartCode,
  });
  const { mutateAsync: updateProcedures, isLoading: updateIsLoading } =
    useUpdateTriageChartProcedures({ encounterId, chartCode });
  const onSubmit: SubmitHandler<ProceduresForm> = React.useCallback(
    async (input) => {
      try {
        const payload = proceduresUtils.formToPayload(input);
        const response = await updateProcedures(payload);
        // Reset form with latest values to set isDirty to false
        reset(proceduresUtils.responseToForm(response.data));
        toast({ description: formMessages.updateSuccess("Procedures") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [toast, reset, updateProcedures]
  );

  const onSaveAndNext = React.useCallback(() => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  }, [chartName, setNextRoute, onSubmit, handleSubmit, chartCode]);

  React.useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [isDirty, nextRoute, navigate]);

  React.useEffect(() => {
    if (data) {
      const form = proceduresUtils.responseToForm(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  return {
    encounterId,
    onSubmit: handleSubmit(onSubmit),
    updateIsLoading,
    getIsLoading,
    useFormReturn,
    onSaveAndNext,
  };
}

export type { UseTriageChartProceduresFormProps };
export { useTriageChartProceduresForm };
