import { RadioProps, useRadio, Box, UseRadioProps } from "@chakra-ui/react";
import * as React from "react";

type RadioListItemProps = {
  preventDefault?: boolean;
  passIsCheckedToChildren?: boolean;
} & RadioProps &
  UseRadioProps;

function RadioListItem(props: RadioListItemProps) {
  const {
    children,
    preventDefault = true,
    passIsCheckedToChildren = false,
    id,
    name,
    value,
    isChecked,
    defaultChecked,
    isDisabled,
    isFocusable,
    isReadOnly,
    isInvalid,
    isRequired,
    onChange,
    "data-radiogroup": dataRadioGroup,
    ...rest
  } = props;
  const {
    getInputProps,
    getCheckboxProps,
    state: { isChecked: isSelected },
  } = useRadio({
    id,
    name,
    value,
    isChecked,
    defaultChecked,
    isDisabled,
    isFocusable,
    isReadOnly,
    isInvalid,
    isRequired,
    onChange,
    "data-radiogroup": dataRadioGroup,
  });

  const input = getInputProps();
  const { onMouseDown, ...restCheckbox } = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...restCheckbox}
        {...(preventDefault && { onMouseDown })}
        {...(!passIsCheckedToChildren
          ? {
              color: "gray.600",
              _checked: {
                color: "blue",
              },
            }
          : {})}
        height="48px"
        display="flex"
        alignItems="center"
        padding="14px 12px 14px 16px"
        _disabled={{ opacity: 0.6 }}
        {...rest}
      >
        {passIsCheckedToChildren
          ? React.isValidElement(children) &&
            React.cloneElement(children, { isChecked: isSelected })
          : children}
      </Box>
    </Box>
  );
}

export { RadioListItem };
