import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { EncounterListItemMin } from "modules/reception/types";

function usePatientMinInfoByEncounter(
  encounterId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["patientInfoByEncounter", { encounterId }],
    () =>
      axiosPatientInstance.get<EncounterListItemMin>(
        `v1/encounter/${encounterId}/min-patient-info`
      ),
    options
  );
}

export { usePatientMinInfoByEncounter };
