import { theme } from "@chakra-ui/react";
import Input from "./input";

const baseTextareaTheme = theme.components.Textarea;

type Dict = Record<string, unknown>;

const baseStyle = {
  bg: "gray.50",
  border: "1px solid",
  borderColor: "gray.450",
  borderRadius: "10px",
};

const variants = {
  filled: (props: Dict) => Input.variants.filled(props).field,
};

const sizes = {
  md: {
    minH: "130px",
  },
};

const defaultProps = {
  size: "md",
  variant: "filled",
};

export const textareaTheme = {
  ...baseTextareaTheme,
  sizes: {
    md: baseTextareaTheme.sizes.md,
  },
  variants: {
    filled: baseTextareaTheme.variants.filled,
  },
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
