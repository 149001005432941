import * as React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  UseDisclosureReturn,
  Box,
} from "@chakra-ui/react";
import { Button } from "shared";

type DeleteDialogProps = {
  onDelete: () => void;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

function DeleteDialog(props: DeleteDialogProps) {
  const { onDelete, onClose, isOpen } = props;
  const cancelRef = React.useRef(null);

  const internalOnDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter justifyContent="center">
            <Box>
              <Button ref={cancelRef} onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={internalOnDelete} ml={3}>
                Delete
              </Button>
            </Box>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export type { DeleteDialogProps };
export { DeleteDialog };
