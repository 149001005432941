import * as React from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

type AudioWaveProps = {
  wavesQuantity?: number;
};

function AudioWave(props: AudioWaveProps) {
  const { wavesQuantity = 10 } = props;

  return (
    <Box display="flex" sx={{ "> :not(:last-child)": { marginRight: "2px" } }}>
      {Array(wavesQuantity)
        .fill(0)
        .map((_, i) => (
          <motion.div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              height: "20px",
              width: "3px",
              backgroundColor: "gray",
            }}
            animate={{
              scaleY: ["10%", "75%", "100%", "75%", "10%"],
            }}
            transition={{ repeat: Infinity, delay: i * 0.2 }}
          />
        ))}
    </Box>
  );
}

export type { AudioWaveProps };
export { AudioWave };
