import { AxiosError, AxiosResponse } from "axios";
import { axiosOrderInstance } from "config";
import { OrderFormElementType } from "enums";
import { useAxiosQuery } from "hooks";
import { UseQueryOptions } from "react-query";

type EncounterOrderCountersResponse = {
  ordered: number;
  inProcess: number;
  completed: number;
};

function useEncounterOrderCounters(
  encounterId: string,
  type: OrderFormElementType,
  options: UseQueryOptions<
    AxiosResponse<EncounterOrderCountersResponse>,
    AxiosError
  >
) {
  return useAxiosQuery(
    ["encounterOrderCounters", { encounterId, type }],
    () =>
      axiosOrderInstance.get<EncounterOrderCountersResponse>(
        `v1/counters/by-encounter/${encounterId}`,
        { params: { type } }
      ),
    options
  );
}

export { useEncounterOrderCounters };
export type { EncounterOrderCountersResponse };
