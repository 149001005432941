import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";

type UseUpdateLastLoginPayload = {
  userIpAddress: string;
};

function useUpdateLastLogin() {
  return useAxiosMutation(
    ["updateLastLogin"],
    (payload?: UseUpdateLastLoginPayload) =>
      axiosIdentityInstance.put(`v1/user/update-login`, payload ?? {}),
    {
      onSuccess: () => {},
    }
  );
}

export { useUpdateLastLogin };
