import { OrderGroup } from "enums/order";
import { ChartNotesModalFooter } from "modules/charts-shared";
import {
  useCreateOrderChartNote,
  useCreateOrderChartNoteAudio,
} from "modules/charts-shared/api";

type OrderChartNoteModalFooterProps = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
  onCreateNoteSuccess?: () => void;
};

function OrderChartNoteModalFooter(props: OrderChartNoteModalFooterProps) {
  const { encounterId, chartCode, orderType, onCreateNoteSuccess } = props;

  const { mutateAsync: createNote, isLoading: isCreating } =
    useCreateOrderChartNote({
      encounterId,
      chartCode,
      orderType,
    });

  const { mutateAsync: createNoteAudio, isLoading: isCreatingAudioNote } =
    useCreateOrderChartNoteAudio({
      encounterId,
      chartCode,
      orderType,
    });

  return (
    <ChartNotesModalFooter
      isLoading={isCreating || isCreatingAudioNote}
      onCreateNote={createNote}
      onCreateAudioNote={createNoteAudio}
      onCreateNoteSuccess={onCreateNoteSuccess}
    />
  );
}

export { OrderChartNoteModalFooter };
