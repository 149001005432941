import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteProgressNote() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteProgressNote"],
    (progressNoteId: string) =>
      axiosChartInstance.delete(`v1/ProgressNote/${progressNoteId}`),
    {
      onSuccess: () => queryClient.invalidateQueries(["progressNotes"]),
    }
  );
}

export { useDeleteProgressNote };
