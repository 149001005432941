import { Box, InputGroup } from "@chakra-ui/react";
import * as React from "react";
import { UseFormRegister } from "react-hook-form";
import { PatientDetailsForm } from "../PatientDetailsModal";
import { Input } from "shared";

type ChiefComplaintInputProps = {
  encounterId: string;
  register: UseFormRegister<PatientDetailsForm>;
};

export const ChiefComplaintInput = ({ register }: ChiefComplaintInputProps) => {
  return (
    <Box p={0} pb={4}>
      <Box p={2} pb={1} fontSize="1rem" color="gray.650">
        Chief Complaint
      </Box>

      <InputGroup display="flex" alignItems="center">
        <Input
          autoComplete="off"
          placeholder="Type here..."
          backgroundColor="white"
          borderColor="white"
          borderRadius="10px"
          color="blue"
          _placeholder={{ color: "gray.500" }}
          {...register("chiefComplaint")}
        />
      </InputGroup>
    </Box>
  );
};
