import {
  Flex,
  HStack,
  Icon,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, Card, CardProps, Loading } from "shared";

import { ExclamationTriangle } from "icons";
import { useChartPatientCardInfo } from "modules/charts-shared/api";
import { PatientDietModal } from "modules/in-process/components";
import { PatientDetailsModal } from "modules/in-process/components/in-process-patients/PatientDetailsModal";
import { DIET_TYPE_ICON } from "modules/in-process/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ChartPatientCard } from "./ChartPatientCard";
import { usePermissions } from "contexts";

type SidebarChartPatientCardProps = {
  patientId: string;
} & CardProps;

function SidebarChartPatientCard(props: SidebarChartPatientCardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { scope } = usePermissions();

  const { patientId, ...cardProps } = props;
  const { data, error, isLoading } = useChartPatientCardInfo(patientId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDietOpen,
    onOpen: onDietOpen,
    onClose: onDietCLose,
  } = useDisclosure();
  const patient = data?.data;
  const showError = !!error && !isLoading;
  const showData = !!patient && !isLoading;

  const fromAllergiesMedicationsRx = location.pathname.includes(
    "allergies-medications-rx"
  );

  return (
    <VStack spacing=".25rem" w="100%" height="160px">
      <Card
        boxShadow="none"
        border="none"
        width="100%"
        height="80px"
        padding="8px 14px"
        {...cardProps}
      >
        {showData && (
          <Button variant="label" onClick={onOpen}>
            <PatientDetailsModal
              isOpen={isOpen}
              onClose={onClose}
              encounterId={patientId}
            />
            <ChartPatientCard
              name={patient.fullName}
              age={patient.age}
              gender={patient.gender}
              pictureUrl={patient.pictureUrl || ""}
              overallTimer={patient.overallTime || "0:00"}
              timerStatus="AGREEABLE"
              roomNumber={patient.roomBed?.bedCode}
              acuity={patient.acuity}
              painLevel={patient.painLevel}
              dietType={patient.diet || ""}
              hasFoodAllergies={patient.hasFoodAllergies}
              entitledToObservation={patient.entitledToObservation}
              encounterId={patientId}
              isVip={patient.isVip}
              isStp={patient.painProtocol}
              chiefComplaint={patient.chiefComplaint}
              maxWidth="179px"
            />
          </Button>
        )}
        {isLoading && <Loading />}
        {showError && "Something went wrong"}
      </Card>

      <Flex columnGap="10px" w="100%">
        {scope("inprocess:allergies").isAccessible && (
          <Card
            cursor="pointer"
            boxShadow="none"
            padding="2px 14px"
            height="44px"
            width="50%"
            display="flex"
            flexDirection="row"
            alignSelf="flex-start"
            color={patient?.hasAllergiesRx ? "red" : " blue"}
            borderColor={
              fromAllergiesMedicationsRx
                ? patient?.hasAllergiesRx
                  ? "red"
                  : "blue"
                : "none"
            }
            borderRadius="10px"
            outline="2px solid transparent"
            outlineOffset="2px"
            {...cardProps}
          >
            <HStack
              spacing="0.5rem"
              padding="10px 0px"
              gap="4px"
              onClick={() => navigate("./allergies-medications-rx")}
            >
              <Icon as={ExclamationTriangle} width="1.15rem" height="1.15rem" />
              <chakra.span
                fontSize=".75rem"
                maxWidth="170px"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight="500"
              >
                Allergies & <br /> Medications
              </chakra.span>
            </HStack>
          </Card>
        )}

        {scope("inprocess:diet").isAccessible && (
          <Card
            cursor="pointer"
            boxShadow="none"
            padding="2px 14px"
            height="44px"
            width="50%"
            display="flex"
            flexDirection="row"
            alignSelf="flex-start"
            borderRadius="10px"
            outline="2px solid transparent"
            outlineOffset="2px"
            {...cardProps}
          >
            <HStack spacing="0.5rem" padding="10px 0px" onClick={onDietOpen}>
              {DIET_TYPE_ICON[patient?.diet ?? "Healthy"]}
              <chakra.span
                fontSize=".75rem"
                maxWidth="170px"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight="500"
                color="blue"
              >
                Food & Diet
              </chakra.span>
            </HStack>
          </Card>
        )}

        {isDietOpen && patient && (
          <PatientDietModal
            isModalOpen={isDietOpen}
            onModalClose={onDietCLose}
            patient={{
              encounterId: patient?.encounterId,
              fullName: patient?.fullName,
              hasDrugAllergies: patient?.hasDrugAllergies,
              hasFoodAllergies: patient?.hasFoodAllergies,
            }}
          />
        )}
      </Flex>
    </VStack>
  );
}

export { SidebarChartPatientCard };
export type { SidebarChartPatientCardProps };
