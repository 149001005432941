import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseIncompleteAuditsCountResponse = {
  count: number;
};

type UseIncompleteAuditsCountProps = {
  assignFilter: "All" | "AssignedToMe" | "Unassigned";
};

function useIncompleteAuditsCount(props: UseIncompleteAuditsCountProps) {
  const { assignFilter = "All" } = props;

  return useAxiosQuery(["incomplete-audits-count"], () =>
    axiosPatientInstance.get<UseIncompleteAuditsCountResponse>(
      "/v1/audits/incomplete/count",
      {
        params: {
          assignFilter: assignFilter,
        },
      }
    )
  );
}

export { useIncompleteAuditsCount };
