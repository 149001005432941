import { axiosPatientInstance } from "config";
import {
  EncounterIntegrationMethod,
  EncounterIntegrationProtocol,
  EncounterIntegrationProvider,
} from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type HL7Response = {
  sentHL7ADTMessageInd?: boolean;
  sentHL7ORMMessageInd?: boolean;
  sentHL7ORUMessageInd?: boolean;
};

type updateEncounterIntegrationHL7Payload = {
  encounterId: string;
  provider: EncounterIntegrationProvider;
  method: EncounterIntegrationMethod;
  protocol: EncounterIntegrationProtocol;
};

function useUpdateEncounterIntegrationHL7(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationHL7", { encounterId }],
    (input: updateEncounterIntegrationHL7Payload) => {
      return axiosPatientInstance.post<HL7Response>(`v1/integration/hl7`, {
        ...input,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

function useDeleteEncounterIntegrationHL7(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateDeleteEncounterIntegrationHL7", { encounterId }],
    (input: updateEncounterIntegrationHL7Payload) => {
      return axiosPatientInstance.post<HL7Response>(
        `v1/integration/hl7/delete-encounter`,
        {
          ...input,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

function useUpdateEncounterIntegrationAllergiesHL7(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterIntegrationHL7", { encounterId }],
    (input: updateEncounterIntegrationHL7Payload) => {
      return axiosPatientInstance.post<HL7Response>(
        `v1/integration/hl7/allergies-medications-rx`,
        {
          ...input,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterIntegrations",
          { encounterId },
        ]);
      },
    }
  );
}

export type { HL7Response };
export {
  useUpdateEncounterIntegrationHL7,
  useDeleteEncounterIntegrationHL7,
  useUpdateEncounterIntegrationAllergiesHL7,
};
