import * as React from "react";
import {
  Box,
  Button,
  chakra,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { formMessages, genericErrors } from "messages";
import { useToast, useYupValidationResolver } from "hooks";
import { extractApiErrorMessage } from "utils";
import {
  AuditCommentThread,
  AuditCommentSharedThread,
} from "./AuditCommentThread";
import {
  useAuditComments,
  useAuditCommentsShared,
} from "modules/charts-shared/api/queries/useAuditComments";
import {
  useCreateAuditComments,
  useCreateAuditCommentsShared,
} from "modules/charts-shared/api/mutations/useCreateAuditComments";
import { Loading, StaffAvatar, ToolbarHeader, WarningDialog } from "shared";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePermissions } from "contexts";
import { UseMutateFunction } from "react-query";
import {
  useUpdateAuditStatus,
  UseUpdateAuditStatusPayload,
} from "modules/audits/api/mutations/useUpdateAuditStatus";
import { ChartType } from "types";
import { useGetChart } from "modules/charts-shared/api/queries/useGetChart";
import { ArrowUpCircle, Audits } from "icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  useUpdateAuditCommentsCheck,
  useUpdateAuditCommentsSharedCheck,
} from "modules/charts-shared/api";
import { AuditCommentsIndicator } from "modules/charts-shared/types/audit-comments";

type AuditCommentsModalProps = {
  title: React.ReactNode;
  encounterId: string;
  chartCode: string;
  sectionCode: string;
  subsectionCode: string;
  commentsIndicator?: AuditCommentsIndicator;
  commentsIndicatorLoading: boolean;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type AuditCommentsSharedModalProps = {
  title: React.ReactNode;
  chartCode: string;
  encounterId: string;
  sectionCode: string;
  subsectionCode: string;
  commentsIndicator?: AuditCommentsIndicator;
  commentsIndicatorLoading: boolean;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type AuditCommentsModalHeaderProps = {
  isAuditor: boolean;
  isCommenter: boolean;
  chartType?: ChartType;
  updateAuditStatus: UseMutateFunction<
    unknown,
    unknown,
    UseUpdateAuditStatusPayload
  >;
  isUpdateAuditStatusLoading: boolean;
  commentsIndicator?: AuditCommentsIndicator;
  commentsIndicatorLoading: boolean;
};

type AuditCommentsModalToolBarProps = {
  title: React.ReactNode;
  onClose: () => void;
  isCommenter: boolean;
  commentsIndicator?: AuditCommentsIndicator;
  updateCheck?: UseMutateFunction<unknown, unknown, unknown>;
  updateCheckLoading?: boolean;
};

type AuditCommentsModalBodyProps = {
  isLoading?: boolean;
} & ModalBodyProps;

type AuditCommentsModalFooterProps = {
  isLoading?: boolean;
  chartType?: ChartType;
  isAuditor: boolean;
  isCommenter: boolean;
  createComment: UseMutateFunction<unknown, unknown, AuditCommentForm>;
  isCreateCommentLoading: boolean;
  updateAuditStatus: UseMutateFunction<
    unknown,
    unknown,
    UseUpdateAuditStatusPayload
  >;
  isUpdateAuditStatusLoading: boolean;
};

type AuditCommentForm = {
  comment: string;
};

const AuditCommentsModalBody = React.forwardRef<
  HTMLDivElement,
  AuditCommentsModalBodyProps
>((props, ref) => {
  const { isLoading, children, ...modalBodyProps } = props;

  return (
    <ModalBody
      flex="1"
      overflow="auto"
      padding="0px 12px 10px 12px"
      bg="gray.200"
      ref={ref}
      maxHeight="60vh"
      {...modalBodyProps}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <VStack spacing="1.25rem" paddingBottom=".5rem">
          {children}
        </VStack>
      )}
    </ModalBody>
  );
});

AuditCommentsModalBody.displayName = "AuditCommentsModalBody";

function AuditCommentsModalFooter({
  isLoading,
  isAuditor,
  createComment,
  isCreateCommentLoading,
}: AuditCommentsModalFooterProps) {
  const { user } = useAuth0();

  const CHART_COMMENT_FORM_SCHEMA = Yup.object().shape({
    comment: Yup.string().required(),
  });

  const resolver = useYupValidationResolver(CHART_COMMENT_FORM_SCHEMA);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<AuditCommentForm>({
    resolver,
  });

  const watchComment = watch("comment");

  const toast = useToast();

  const onSubmit: SubmitHandler<AuditCommentForm> = async (values) => {
    try {
      await createComment(values);
      reset({ comment: "" });
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const { onChange: registerOnChange, ...restRegister } = register("comment");
  const onChange: InputProps["onChange"] = (e) => {
    registerOnChange(e);
  };
  const onKeyPress: InputProps["onKeyPress"] = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)(e);
    }
  };

  return (
    <ModalFooter height="90px" bg="gray.50" px="32px" borderBottomRadius="10px">
      {isLoading && (
        <Box width="100%">
          <Loading />
        </Box>
      )}

      {!isLoading && isAuditor && (
        <Box
          width="100%"
          position="relative"
          display="flex"
          alignItems="center"
        >
          <VStack width="100%">
            <Box width="100%">
              <InputGroup display="flex" alignItems="center">
                <StaffAvatar
                  width="32px"
                  height="32px"
                  position="relative"
                  size="xs"
                  userName={user?.name || ""}
                  profileUrl={user?.pictureUrl || ""}
                  mr={5}
                />
                <Input
                  autoComplete="off"
                  placeholder="Add new comment here"
                  backgroundColor="white"
                  borderColor="white"
                  borderRadius="25px"
                  isDisabled={isSubmitting || isCreateCommentLoading}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  _placeholder={{ color: "gray.500" }}
                  {...restRegister}
                />
                <InputRightElement width="unset" right="1rem">
                  <IconButton
                    aria-label="submit comment"
                    icon={
                      <Icon
                        as={ArrowUpCircle}
                        color={Boolean(watchComment) ? "blue" : "gray.500"}
                        w="20px"
                        h="20px"
                      />
                    }
                    width="24px"
                    height="24px"
                    variant="ghost"
                    minWidth="unset"
                    bgColor="white"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isCreateCommentLoading}
                    isDisabled={isCreateCommentLoading}
                  />
                </InputRightElement>
              </InputGroup>
            </Box>
          </VStack>
        </Box>
      )}
    </ModalFooter>
  );
}

function AuditCommentsModalHeader({
  updateAuditStatus,
  isUpdateAuditStatusLoading,
  chartType,
  isAuditor,
  isCommenter,
}: AuditCommentsModalHeaderProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = React.useState("");

  const commenterType = chartType == "Triage" ? "Nurse" : "Provider";

  const {
    isOpen: isProviderReviewWarningOpen,
    onClose: onProviderReviewWarningClose,
    onOpen: onProviderReviewWarningOpen,
  } = useDisclosure();

  const {
    isOpen: isAuditorReviewWarningOpen,
    onClose: onAuditorReviewWarningClose,
    onOpen: onAuditorReviewWarningOpen,
  } = useDisclosure();

  const {
    isOpen: isBillingWarningOpen,
    onClose: onBillingWarningClose,
    onOpen: onBillingWarningOpen,
  } = useDisclosure();

  const {
    isOpen: isConfirmationOpen,
    onClose: onConfirmationClose,
    onOpen: onConfirmationOpen,
  } = useDisclosure();

  const onUpdateAudit = async (status: string) => {
    try {
      await updateAuditStatus({
        status: status,
        type: commenterType,
      });
      onConfirmationOpen();
      onProviderReviewWarningClose();
      onAuditorReviewWarningClose();
      onBillingWarningClose();
      toast({
        description: formMessages.updateSuccess("Audit status updated"),
      });
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <ModalHeader fontWeight="400" p={0} borderRadius="1rem">
      <Box
        bg="gray.200"
        height="140px"
        width="100%"
        my={4}
        borderRadius="10px"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        boxShadow="md"
      >
        <VStack gap="15px">
          <HStack gap="5px">
            <Icon as={Audits} color="gray.650" fontSize="1.75rem" />
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="gray.650">
              {chartType} Chart Audit
            </chakra.span>
          </HStack>
          <Box>
            {isCommenter && (
              <HStack>
                <WarningDialog
                  isOpen={isAuditorReviewWarningOpen}
                  onCancel={onAuditorReviewWarningClose}
                  title="Warning!"
                  mainText="Are you sure you want to move this to the Auditor for Approval?"
                  onClose={onAuditorReviewWarningClose}
                  onAction={() => {
                    setConfirmMessage(
                      "Your Audit Review has been sent back to the Auditor for Approval."
                    );
                    onUpdateAudit("AuditorReview");
                  }}
                  cancelLabel="Cancel"
                  actionLabel="Move to Auditor"
                  blockScrollOnMount={false}
                  cancelButtonProps={{ color: "red" }}
                  actionButtonProps={{ color: "blue" }}
                  actionsDirection="vertical"
                />
                <Button
                  variant="outlineSquared"
                  borderRadius="10px"
                  w="240px"
                  onClick={onAuditorReviewWarningOpen}
                  isLoading={isUpdateAuditStatusLoading}
                  isDisabled={isUpdateAuditStatusLoading}
                >
                  Move for Auditor Review
                </Button>
              </HStack>
            )}
            {isAuditor && (
              <HStack gap="15px">
                <WarningDialog
                  isOpen={isProviderReviewWarningOpen}
                  onCancel={onProviderReviewWarningClose}
                  title="Warning!"
                  mainText={`Are you sure you want to move this to the ${commenterType} for Review?`}
                  onClose={onProviderReviewWarningClose}
                  onAction={() => {
                    setConfirmMessage(
                      `Your Audit Review has been sent to the ${commenterType} for Review.`
                    );
                    onUpdateAudit("InReview");
                  }}
                  cancelLabel="Cancel"
                  actionLabel={`Move to ${commenterType}`}
                  blockScrollOnMount={false}
                  cancelButtonProps={{ color: "red" }}
                  actionButtonProps={{ color: "blue" }}
                  actionsDirection="vertical"
                />
                <Button
                  variant="outlineSquared"
                  borderRadius="10px"
                  w="240px"
                  onClick={onProviderReviewWarningOpen}
                  isLoading={isUpdateAuditStatusLoading}
                  isDisabled={isUpdateAuditStatusLoading}
                >
                  Move for {commenterType} Review
                </Button>

                <WarningDialog
                  isOpen={isBillingWarningOpen}
                  onCancel={onBillingWarningClose}
                  title="Warning!"
                  mainText={
                    <chakra.span>
                      Are you sure you want to move this to billing?
                      <br />
                      Moving this to Billing means you will complete this Audit.
                      You will not be able to edit this chart again.
                    </chakra.span>
                  }
                  onClose={onBillingWarningClose}
                  onAction={() => {
                    setConfirmMessage(
                      `Your Audit Review has been marked as complete.`
                    );
                    onUpdateAudit("Completed");
                  }}
                  cancelLabel="Cancel"
                  actionLabel="Complete Audit"
                  blockScrollOnMount={false}
                  cancelButtonProps={{ color: "red" }}
                  actionButtonProps={{ color: "blue" }}
                  actionsDirection="vertical"
                />
                <Button
                  variant="outlineSquared"
                  borderRadius="10px"
                  color="green"
                  colorScheme="green"
                  sx={{ borderColor: "green" }}
                  w="240px"
                  onClick={onBillingWarningOpen}
                  isLoading={isUpdateAuditStatusLoading}
                  isDisabled={isUpdateAuditStatusLoading}
                >
                  Move to Billing
                </Button>
              </HStack>
            )}
          </Box>
          <WarningDialog
            isOpen={isConfirmationOpen}
            onCancel={onConfirmationClose}
            title="Sent"
            mainText={confirmMessage}
            onClose={onConfirmationClose}
            onAction={() => navigate("/audits/incomplete")}
            actionLabel="Ok"
            blockScrollOnMount={false}
            actionButtonProps={{ color: "blue" }}
            actionsDirection="vertical"
            onlyActionButton
          />
        </VStack>
      </Box>
    </ModalHeader>
  );
}

function AuditCommentsModalToolBar({
  title,
  onClose,
  isCommenter,
  commentsIndicator,
  updateCheck,
  updateCheckLoading,
}: AuditCommentsModalToolBarProps) {
  const toast = useToast();

  const {
    isOpen: isProviderReviewWarningOpen,
    onClose: onProviderReviewWarningClose,
    onOpen: onProviderReviewWarningOpen,
  } = useDisclosure();

  const onCheck = async () => {
    if (updateCheck && !commentsIndicator?.hasCheck) {
      try {
        await updateCheck(null);
        toast({
          description: formMessages.updateSuccess("Audit subsection status"),
        });
      } catch (error) {
        toast({
          description:
            extractApiErrorMessage(error) || genericErrors.unknownError,
        });
      }

      onClose();
    }
  };

  return (
    <VStack gap="5px" py={4} bg="gray.200">
      <ToolbarHeader
        width="100%"
        px={6}
        mt={2}
        borderTopRadius="10px"
        display="flex"
        alignItems="center"
        titleText={
          <chakra.span
            fontSize="1.0625rem"
            fontWeight="600"
            color="black"
            alignSelf="center"
          >
            {title}
          </chakra.span>
        }
        rightButtons={[
          <Button key="doneBtn" onClick={onClose}>
            Hide
          </Button>,
        ]}
      />

      {isCommenter && (
        <Box
          margin="0px 2px"
          textAlign="center"
          wordBreak="break-word"
          fontSize="1.0625rem"
          fontWeight="600"
        >
          <WarningDialog
            isOpen={isProviderReviewWarningOpen}
            onCancel={onProviderReviewWarningClose}
            title="Warning!"
            mainText="Are you sure you want to mark this subsection as reviewed?"
            onClose={onProviderReviewWarningClose}
            onAction={() => {
              onCheck();
            }}
            cancelLabel="Cancel"
            actionLabel="Move to Auditor"
            blockScrollOnMount={false}
            cancelButtonProps={{ color: "red" }}
            actionButtonProps={{ color: "blue" }}
            actionsDirection="vertical"
          />

          {commentsIndicator?.hasCheck ? (
            <Button
              variant="outlineSquared"
              borderRadius="10px"
              w="240px"
              bg="green"
              isLoading={updateCheckLoading}
              isDisabled={true}
            >
              Reviewed
            </Button>
          ) : (
            <Button
              variant="outlineSquared"
              borderRadius="10px"
              w="240px"
              onClick={onProviderReviewWarningOpen}
              isLoading={updateCheckLoading}
              isDisabled={commentsIndicator?.hasCheck || updateCheckLoading}
            >
              Mark Reviewed
            </Button>
          )}
        </Box>
      )}
    </VStack>
  );
}

function AuditCommentsModal(props: AuditCommentsModalProps) {
  const {
    isOpen,
    onClose,
    title,
    encounterId,
    chartCode,
    sectionCode,
    subsectionCode,
    commentsIndicator,
    commentsIndicatorLoading,
  } = props;

  const modalBodyRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight });
    }
  }, []);

  const { data: chartData, isLoading: isLoadingChart } = useGetChart({
    chartCode,
  });

  const { scope } = usePermissions();

  const { data, isLoading: isLoadingComments } = useAuditComments({
    encounterId,
    chartCode,
    sectionCode,
    subsectionCode,
  });

  const { mutateAsync: createComment, isLoading: isCreateCommentLoading } =
    useCreateAuditComments(encounterId, chartCode, sectionCode, subsectionCode);

  const {
    mutateAsync: updateAuditStatus,
    isLoading: isUpdateAuditStatusLoading,
  } = useUpdateAuditStatus(encounterId);

  React.useEffect(() => {
    if (modalBodyRef.current) {
      scrollToBottom();
    }
  }, [data, scrollToBottom]);

  const { mutateAsync: updateCheck, isLoading: isUpdateCheckLoading } =
    useUpdateAuditCommentsCheck(
      encounterId,
      chartCode,
      sectionCode,
      subsectionCode
    );

  const isLoadingModal =
    isLoadingChart ||
    isLoadingComments ||
    isCreateCommentLoading ||
    isUpdateAuditStatusLoading ||
    isUpdateCheckLoading ||
    isLoadingChart;

  const isAuditor = scope("audits").isEditable;
  const isCommenter =
    !scope("audits").isEditable && scope("audits").isAccessible;

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInRight">
      <ModalContent
        display="flex"
        flexDirection="column"
        width="28vw"
        maxWidth="92vh"
        bg="transparent"
        p={0}
        m={0}
        boxShadow="none"
        containerProps={{
          justifyContent: "flex-end",
          paddingRight: "2rem",
        }}
      >
        <AuditCommentsModalHeader
          chartType={chartData?.data?.type}
          isAuditor={isAuditor}
          isCommenter={isCommenter}
          updateAuditStatus={updateAuditStatus}
          commentsIndicator={commentsIndicator}
          commentsIndicatorLoading={commentsIndicatorLoading}
          isUpdateAuditStatusLoading={isUpdateAuditStatusLoading}
        />

        <Box boxShadow="md">
          <AuditCommentsModalToolBar
            title={title}
            onClose={onClose}
            isCommenter={isCommenter}
            updateCheck={updateCheck}
            commentsIndicator={commentsIndicator}
            updateCheckLoading={isUpdateCheckLoading}
          />
          <Divider height="0px" />

          {data && data.data.length > 0 && (
            <>
              <AuditCommentsModalBody
                isLoading={isLoadingModal}
                ref={modalBodyRef}
              >
                {data?.data.map((comment) => (
                  <AuditCommentThread
                    key={comment.id}
                    comment={comment}
                    encounterId={encounterId}
                    chartCode={chartCode}
                    sectionCode={sectionCode}
                    subsectionCode={subsectionCode}
                    isAuditor={isAuditor}
                    isCommenter={
                      !scope("audits").isEditable &&
                      scope("audits").isAccessible
                    }
                  />
                ))}
              </AuditCommentsModalBody>
              <Divider height="0px" />
            </>
          )}

          <AuditCommentsModalFooter
            isLoading={isLoadingModal}
            chartType={chartData?.data?.type}
            isAuditor={isAuditor}
            isCommenter={isCommenter}
            createComment={createComment}
            isCreateCommentLoading={isCreateCommentLoading}
            updateAuditStatus={updateAuditStatus}
            isUpdateAuditStatusLoading={isUpdateAuditStatusLoading}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
}

function AuditCommentsSharedModal(props: AuditCommentsSharedModalProps) {
  const {
    isOpen,
    onClose,
    title,
    encounterId,
    chartCode,
    sectionCode,
    subsectionCode,
    commentsIndicator,
    commentsIndicatorLoading,
  } = props;

  const modalBodyRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight });
    }
  }, []);

  const { scope } = usePermissions();

  const { data: chartData, isLoading: isLoadingChart } = useGetChart({
    chartCode,
  });

  const { data, isLoading: isLoadingComments } = useAuditCommentsShared({
    encounterId,
    sectionCode,
    subsectionCode,
  });

  const { mutateAsync: createComment, isLoading: isCreateCommentLoading } =
    useCreateAuditCommentsShared(encounterId, sectionCode, subsectionCode);

  const {
    mutateAsync: updateAuditStatus,
    isLoading: isUpdateAuditStatusLoading,
  } = useUpdateAuditStatus(encounterId);

  React.useEffect(() => {
    if (modalBodyRef.current) {
      scrollToBottom();
    }
  }, [data, scrollToBottom]);

  const { mutateAsync: updateCheck, isLoading: isUpdateCheckLoading } =
    useUpdateAuditCommentsSharedCheck(encounterId, sectionCode, subsectionCode);

  const isLoadingModal =
    isLoadingChart ||
    isLoadingComments ||
    isCreateCommentLoading ||
    isUpdateAuditStatusLoading ||
    isUpdateCheckLoading ||
    isLoadingChart;

  const isAuditor = scope("audits").isEditable;
  const isCommenter =
    !scope("audits").isEditable && scope("audits").isAccessible;

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInRight">
      <ModalContent
        display="flex"
        flexDirection="column"
        width="28vw"
        maxWidth="92vh"
        bg="transparent"
        p={0}
        m={0}
        boxShadow="none"
        containerProps={{
          justifyContent: "flex-end",
          paddingRight: "2rem",
        }}
      >
        <AuditCommentsModalHeader
          chartType={chartData?.data?.type}
          isAuditor={isAuditor}
          isCommenter={isCommenter}
          updateAuditStatus={updateAuditStatus}
          commentsIndicator={commentsIndicator}
          commentsIndicatorLoading={commentsIndicatorLoading}
          isUpdateAuditStatusLoading={isUpdateAuditStatusLoading}
        />

        <Box boxShadow="md">
          <AuditCommentsModalToolBar
            title={title}
            onClose={onClose}
            isCommenter={isCommenter}
            updateCheck={updateCheck}
            commentsIndicator={commentsIndicator}
            updateCheckLoading={isUpdateCheckLoading}
          />
          <Divider height="0px" />

          {data && data.data.length > 0 && (
            <>
              <AuditCommentsModalBody
                isLoading={isLoadingModal}
                ref={modalBodyRef}
              >
                {data?.data.map((comment) => (
                  <AuditCommentSharedThread
                    key={comment.id}
                    comment={comment}
                    encounterId={encounterId}
                    sectionCode={sectionCode}
                    subsectionCode={subsectionCode}
                    isAuditor={isAuditor}
                    isCommenter={isCommenter}
                  />
                ))}
              </AuditCommentsModalBody>
              <Divider height="0px" />
            </>
          )}

          {isAuditor && (
            <AuditCommentsModalFooter
              isLoading={isLoadingModal}
              chartType={chartData?.data?.type}
              isAuditor={isAuditor}
              isCommenter={isCommenter}
              createComment={createComment}
              isCreateCommentLoading={isCreateCommentLoading}
              updateAuditStatus={updateAuditStatus}
              isUpdateAuditStatusLoading={isUpdateAuditStatusLoading}
            />
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
}

export { AuditCommentsModal, AuditCommentsSharedModal };
