import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { DispositionSignatureForm } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";
import { ChartType } from "types";

type UseAddChartApplicationSignaturesParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
  chartType?: ChartType;
};

function useAddChartApplicationSignatures(
  params: UseAddChartApplicationSignaturesParams
) {
  const queryClient = useQueryClient();
  const { encounterId, chartCode, chartType } = params;

  return useAxiosMutation(
    ["addChartApplicationSignatures", params],
    (signatures: DispositionSignatureForm[]) =>
      axiosChartInstance.put(
        `v1/signature/${encounterId}/${chartCode}`,
        signatures
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartApplication", params]);
        queryClient.invalidateQueries([
          "chartApplicationHardStops",
          { encounterId, chartCode, chartType: chartType ?? "" },
        ]);
      },
    }
  );
}

export { useAddChartApplicationSignatures };
export type { UseAddChartApplicationSignaturesParams };
