import { Box } from "@chakra-ui/react";
import * as React from "react";
import { Loading } from "shared/loading";

type AsyncContentProps<E extends Error> = React.PropsWithChildren<{
  isLoading: boolean;
  error: Nullable<E>;
  loading?: React.ReactNode;
}>;

function AsyncContent<E extends Error>(props: AsyncContentProps<E>) {
  const { error, isLoading, children, loading } = props;

  if (isLoading) {
    return <>{loading}</> || <Loading />;
  }

  if (error) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Something went wrong.
      </Box>
    );
  }

  return <>{children}</>;
}

export type { AsyncContentProps };
export { AsyncContent };
