import axios from "axios";
import { useAxiosQuery } from "hooks";

function useChartNoteAudio(audioUrl: string) {
  return useAxiosQuery(["chartNoteAudio", { audioUrl }], () =>
    axios.get<Blob>(audioUrl, { responseType: "blob" })
  );
}

export { useChartNoteAudio };
