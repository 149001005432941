import { useAxiosMutation } from "hooks";
import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";

function useDeleteEncounterNote(noteId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterNote", { noteId, encounterId }],
    () =>
      axiosPatientInstance.delete(
        `v1/Encounter/${encounterId}/notes/${noteId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterNotes", { encounterId }]);
        queryClient.invalidateQueries(["encounterNotesCount", { encounterId }]);
      },
    }
  );
}

export { useDeleteEncounterNote };
