import { Box } from "@chakra-ui/react";
import { ChartSubsection, ChartSubsectionHeader } from "modules/charts-shared";
import {
  ChartCode,
  ChartType,
  CONST_CHART_TYPE_CONFIG,
  CONST_CHART_WRAPPERS_CONFIG,
  DEFAULT_RADIOLOGY_INTERPRETATION,
} from "../radiology-chart/constants";
import {
  InterpretationChartWrapperProps,
  InterpretationChartWrappersProps,
  RadSection,
} from "../radiology-chart/types";

import faker from "faker";
import { XRAYChart } from "./XRAYChart";
import { MRIChart } from "./MRIChart";
import { UltrasoundChart } from "./UltrasoundChart";
import { CTChart } from "./CTChart";
import { OtherChart } from "./OtherChart";
import { VenogramChart } from "./VenogramChart";
import { FetalHeartsChart } from "./FetalHeartsChart";
import { PosturalVitalsChart } from "./PosturalVitalsChart";
import { IVPChart } from "./IVPChart";
import { TesticularScanChart } from "./TesticularScanChart";
import { useFormContext } from "react-hook-form";

function ToggableChartWrapper(props: InterpretationChartWrapperProps) {
  const {
    index,
    chartType,
    chartCode,
    encounterId,
    sectionCode,
    onToggleInterpretation,
  } = props;

  const { watch, setValue } = useFormContext();
  const radChart: RadSection = watch(`rad.${index}`);

  const { subsectionId, subsectionLabel } =
    CONST_CHART_TYPE_CONFIG[chartType as ChartType] || {};

  const interpretation = radChart?.interpretations?.find(
    (item) => item.type === chartType
  ) ?? {
    ...DEFAULT_RADIOLOGY_INTERPRETATION,
    sectionId: faker.datatype.uuid(),
  };

  const sectionId = interpretation.sectionId;
  const isInterpretationVisible = watch(
    `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}A000`
  );

  const renderChart = () => {
    const chartProps = {
      index,
      chartCode,
      sectionId,
      sectionCode,
      subsectionId,
    };

    switch (chartType) {
      case "XRAY":
        return <XRAYChart {...chartProps} />;
      case "MRI":
        return <MRIChart {...chartProps} />;
      case "ULTRASOUND":
        return <UltrasoundChart {...chartProps} />;
      case "CTSCAN":
        return <CTChart {...chartProps} />;
      case "OTHER":
        return <OtherChart {...chartProps} />;
      case "VENOGRAM":
        return <VenogramChart {...chartProps} />;
      case "FETALHEARTS":
        return <FetalHeartsChart {...chartProps} />;
      case "POSTURALVITALS":
        return <PosturalVitalsChart {...chartProps} />;
      case "IVP":
        return <IVPChart {...chartProps} />;
      case "TESTICULARSCAN":
        return <TesticularScanChart {...chartProps} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: "15px 5px 15px 10px" }}>
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            chartId={chartCode}
            encounterId={encounterId}
            sectionId={sectionCode}
            subsectionId={subsectionId}
            subsectionLabel={subsectionLabel}
            showInteractions={false}
            showToggleInterpretation
            isInterpretationVisible={Boolean(isInterpretationVisible)}
            onToggleInterpretation={() => {
              setValue(
                `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}A000`,
                !isInterpretationVisible
              );

              if (isInterpretationVisible) {
                Object.keys(radChart).forEach((key) => {
                  if (
                    !key.includes(
                      `${chartCode}${sectionId}${sectionCode}${subsectionId}A000`
                    ) &&
                    key.includes(
                      `${chartCode}${sectionId}${sectionCode}${subsectionId}`
                    )
                  ) {
                    setValue(`rad.${index}.${key}`, undefined);
                  }
                });
              }

              onToggleInterpretation({
                ...interpretation,
                type: chartType,
                code: subsectionId,
              });
            }}
            sx={{
              padding: "0 .75rem",
            }}
          >
            {subsectionLabel}
          </ChartSubsectionHeader>
        }
        content={<>{isInterpretationVisible && renderChart()}</>}
      />
    </Box>
  );
}

function ToggableChartWrappers(props: InterpretationChartWrappersProps) {
  const { chartCode } = props;
  const renderChartWrapper = (chartType: string) => {
    return (
      <ToggableChartWrapper {...props} chartType={chartType} key={chartType} />
    );
  };

  const chartWrappers =
    CONST_CHART_WRAPPERS_CONFIG[chartCode as ChartCode] || [];
  return (
    <Box sx={{ gap: "15px" }}>
      {chartWrappers.map((chartType) => renderChartWrapper(chartType))}
    </Box>
  );
}

export { ToggableChartWrappers };
