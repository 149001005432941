import {
  extendTheme,
  theme as defaultTheme,
  ThemeConfig,
} from "@chakra-ui/react";
import foundations from "./foundations";
import components, { componentsTheme } from "./components";

const baseTheme = {
  ...defaultTheme,
  components: componentsTheme,
};

const colorModeConfig: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const overrides = {
  ...foundations,
  components,
  config: colorModeConfig,
};

/**
 * There appears to be some errors in the internal chakra
 * `extendTheme` function. It has conflicts with the button styles
 * overrides. Will look into it, but for now we can disable type
 * checking here.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default extendTheme(overrides as any, baseTheme);
