function toCurrency(value: string | number, decimalDigits = 2) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  });
  const val = Number(value);

  if (Number.isNaN(val)) {
    return 0;
  }

  return currencyFormatter.format(val);
}

/**
 * Options used by the text mask
 */
type MaskStringOptions = {
  /** @type {boolean} - format if the text only consist of numbers */
  onlyNumbers?: boolean;
};

/**
 * Takes a text and a mask, then formats the text into the mask format
 * @param text The string to be formmated
 * @param mask The mask that will be applied to the text, example of a correct mask:
 * ###-###-### where the "#" character are the ones to be modified
 * @param options - the options for the mask process
 * @returns the masked string
 */
function maskString(
  text: string,
  mask: string,
  options: MaskStringOptions = { onlyNumbers: false }
) {
  const { onlyNumbers } = options;
  const partsMask = mask.match(/([#]{1,})/g) || [];
  if (partsMask.length === 0) {
    throw Error("Invalid input mask");
  }

  if (onlyNumbers) {
    if (!/(^[0-9]+$)/.test(text)) return text;
  }

  const regex = RegExp(
    partsMask.reduce((regxResult, part) => {
      return `${regxResult}(.{0,${part.length}})`;
    }, "")
  );

  const maskParts = mask.match(/(#{1,})|([^#]{1,})/g) || [];
  let parts = text?.match(regex) || [];
  // The first result alway returns the full string, so its neccesary to ignore it
  parts.splice(0, 1);
  parts = parts.filter((p) => !!p);

  // Replace the value parts in the mask with the new values
  const maskedText = maskParts.reduce((res, part) => {
    if (parts.length > 0) {
      if (/^#+/.test(part)) {
        const maskPart = parts.splice(0, 1);
        return `${res}${maskPart[0]}`;
      }
      return `${res}${part}`;
    }
    return res;
  }, "");

  return maskedText;
}

function msToTimer(millisec: number) {
  const seconds = Math.round(millisec / 1000);
  const minutes = Math.round(seconds / 60);

  if (minutes > 59) {
    const hours = Math.floor(minutes / 60);
    const hrStr = hours >= 10 ? String(hours) : `0${hours}`;
    const newMinutes = minutes - hours * 60;
    const minStr = newMinutes < 10 ? `0${newMinutes}` : String(newMinutes);

    return `${hrStr}:${minStr}`;
  }

  const minStr = minutes < 10 ? `0${minutes}` : String(minutes);
  return `0:${minStr}`;
}

function secToMinutes(seconds: number) {
  const minutes = Math.trunc(seconds / 60);
  const secondsLeft = Math.trunc(seconds % 60);

  const minStr = minutes < 10 ? `0${minutes}` : String(minutes);
  const secStr = secondsLeft < 10 ? `0${secondsLeft}` : String(secondsLeft);
  return `${minStr}:${secStr}`;
}

function msToMinutes(millisec: number) {
  const seconds = Math.round(millisec / 1000);
  return secToMinutes(seconds);
}

export { toCurrency, maskString, msToTimer, msToMinutes, secToMinutes };
