import * as React from "react";

import {
  ChartRow,
  ChartRowElement,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";

import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Icon } from "@chakra-ui/react";
import { Loading } from "shared";
import { usePermissions } from "contexts";

const sectionId = "SCLI";
const chartCode = "C003x";

function T03ClinicalImpression() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
  } = useChartForm({
    sectionId,
    sectionLabel: "CLI",
    chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection onSubmit={onSubmit} disabled={isReadOnly}>
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="subsequent"
                  name="Q001.C003xSCLIQ001A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="squelae"
                  name="Q001.C003xSCLIQ001A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C003xSCLIQ002A007"
                  label="(**R / L; FB; specify anatomy)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="laceration:"
                  name="Q002.C003xSCLIQ002A001"
                />
                <ChartRowElement type="input" name="Q002.C003xSCLIQ002A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="abrasion:"
                  name="Q002.C003xSCLIQ002A003"
                />
                <ChartRowElement type="input" name="Q002.C003xSCLIQ002A004" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="contusion:"
                  name="Q002.C003xSCLIQ002A005"
                />
                <ChartRowElement type="input" name="Q002.C003xSCLIQ002A006" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C003xSCLIQ003A003"
                  label="(**R / L; FB; specify anatomy)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q003.C003xSCLIQ003A001"
                />
                <ChartRowElement type="input" name="Q003.C003xSCLIQ003A002" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C003xSCLIQ004A005"
                  label="(**R / L; FB; specify anatomy)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C003xSCLIQ004A006"
                  label="Spine"
                />
                <ChartRowElement
                  type="cross-option"
                  label="C"
                  name="Q004.C003xSCLIQ004A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="T"
                  name="Q004.C003xSCLIQ004A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q004.C003xSCLIQ004A003"
                />
                <ChartRowElement type="input" name="Q004.C003xSCLIQ004A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q005.C003xSCLIQ005A006"
                  label="(See Xrays / CT)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Fracture:"
                  name="Q005.C003xSCLIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="closed"
                  name="Q005.C003xSCLIQ005A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="open"
                  name="Q005.C003xSCLIQ005A003"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Dislocation"
                  name="Q005.C003xSCLIQ005A004"
                />
                <ChartRowElement type="input" name="Q005.C003xSCLIQ005A005" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Concussion"
                  name="Q006.C003xSCLIQ006A001"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Post-Concussion syndrome"
                  name="Q006.C003xSCLIQ006A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q006.C003xSCLIQ006A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q006.C003xSCLIQ006A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="Cerebral:"
                  name="Q006.C003xSCLIQ006A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="contusion"
                  name="Q006.C003xSCLIQ006A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lac"
                  name="Q006.C003xSCLIQ006A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="traumatic"
                  name="Q006.C003xSCLIQ006A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hemorrhage"
                  name="Q006.C003xSCLIQ006A009"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q006.C003xSCLIQ006A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q006.C003xSCLIQ006A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="epidural"
                  name="Q006.C003xSCLIQ006A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="subdural"
                  name="Q006.C003xSCLIQ006A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="traumatic"
                  name="Q006.C003xSCLIQ006A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="SAH"
                  name="Q006.C003xSCLIQ006A015"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="LOC"
            >
              LOC
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="unknown"
                  name="Q007.C003xSCLIQ007A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="few sec"
                  name="Q007.C003xSCLIQ007A002"
                />
                <ChartRowElement
                  type="input"
                  size="sm"
                  name="Q007.C003xSCLIQ007A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sec"
                  name="Q007.C003xSCLIQ007A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="min"
                  name="Q007.C003xSCLIQ007A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hrs"
                  name="Q007.C003xSCLIQ007A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="unk time"
                  name="Q007.C003xSCLIQ007A007"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Seizure"
                  name="Q007.C003xSCLIQ007A008"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="GSW"
                  name="Q008.C003xSCLIQ008A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="Stab"
                  name="Q008.C003xSCLIQ008A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="Penetrating injury"
                  name="Q008.C003xSCLIQ008A003"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q008.C003xSCLIQ008A019"
                  label="to:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="head"
                  name="Q008.C003xSCLIQ008A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q008.C003xSCLIQ008A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ant"
                  name="Q008.C003xSCLIQ008A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="post"
                  name="Q008.C003xSCLIQ008A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thorax"
                  name="Q008.C003xSCLIQ008A008"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Pain:"
                  name="Q008.C003xSCLIQ008A009"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q008.C003xSCLIQ008A020"
                  label="acute:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="traumatic"
                  name="Q008.C003xSCLIQ008A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q008.C003xSCLIQ008A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="T-spine"
                  name="Q008.C003xSCLIQ008A012"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q008.C003xSCLIQ008A021"
                  label="Tooth"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q008.C003xSCLIQ008A022"
                  label="#"
                />
                <ChartRowElement
                  type="input"
                  size="sm"
                  name="Q008.C003xSCLIQ008A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fx"
                  name="Q008.C003xSCLIQ008A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="avulsion"
                  name="Q008.C003xSCLIQ008A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sublux"
                  name="Q008.C003xSCLIQ008A016"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Hypokalemia"
                  name="Q008.C003xSCLIQ008A017"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Leukocytosis"
                  name="Q008.C003xSCLIQ008A018"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { T03ClinicalImpression };
