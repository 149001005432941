import { AuthenticatedRoutes, UnauthenticatedRoutes } from "routes";
import { FullPageLoading, MaintenancePage } from "pages";
import { useSetupAuth0, useToast } from "hooks";

import { Box } from "@chakra-ui/react";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { usePermissions } from "contexts";

type Settings = {
  allowed: boolean;
  mode: "maintenance" | "normal";
} | null;

function UnauthenticatedApp() {
  return (
    <Box height="100vh">
      <UnauthenticatedRoutes />
    </Box>
  );
}

function AuthenticatedApp() {
  const authReady = useSetupAuth0();
  const { isLoadingPermissions, claims } = usePermissions();

  const settings = claims?.settings as Settings;

  if (!authReady || isLoadingPermissions) {
    return <FullPageLoading />;
  }

  return (
    <Box height="100vh">
      <AuthenticatedRoutes />
      {settings?.mode === "maintenance" && !settings?.allowed && (
        <MaintenancePage />
      )}
    </Box>
  );
}

export const App = () => {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const toast = useToast();

  React.useEffect(() => {
    if (error) {
      toast({ description: error?.message });
    }
  }, [error, toast]);

  if (isLoading) {
    return <FullPageLoading />;
  }

  if (isAuthenticated) {
    return <AuthenticatedApp />;
  }

  return <UnauthenticatedApp />;
};
