import * as React from "react";
import { ModalHeader, CloseButton } from "@chakra-ui/react";

type STPVipNotesModalHeaderProps = {
  title: React.ReactNode;
  onClose: () => void;
};

function STPVipNotesModalHeader(props: STPVipNotesModalHeaderProps) {
  const { title, onClose } = props;

  return (
    <ModalHeader
      minHeight="60px"
      fontWeight="400"
      padding="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
      position="relative"
    >
      {title}
      <CloseButton
        position="absolute"
        top="20px"
        right="20px"
        width="20px"
        height="20px"
        bg="gray.700"
        color="white"
        borderRadius="50%"
        sx={{ svg: { width: "7px", height: "7px" } }}
        onClick={onClose}
      />
    </ModalHeader>
  );
}

export type { STPVipNotesModalHeaderProps };
export { STPVipNotesModalHeader };
