import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { Select, SelectProps } from "shared";
import { useMergeRefs } from "@chakra-ui/react";
import { useDivideControlProps } from "./hooks";

const omittedSelectProps = ["selectedItem"] as const;
type OmittedSelectProps<T> = Omit<
  SelectProps<T>,
  typeof omittedSelectProps[number]
>;

type FormSelectProps<
  T,
  TFieldValues extends FieldValues
> = UseControllerProps<TFieldValues> & OmittedSelectProps<T>;

function FormSelectInner<T, TFieldValues extends FieldValues>(
  props: FormSelectProps<T, TFieldValues>,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  const { onSelectedItemChange: onSelectedItemChangeProp, ...restProps } =
    props;
  const [selectProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormSelectProps<T, TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, value, onChange, ...restField },
  } = useController(controllerProps);

  const selectRef = useMergeRefs(ref, fieldRef);

  const handleOnSelectedItemChange: SelectProps<T>["onSelectedItemChange"] = (
    e
  ) => {
    onChange(e.selectedItem);
    onSelectedItemChangeProp?.(e);
  };

  let selectedItem = value || null;
  if (restProps.defaultValue && !value) {
    selectedItem = restProps.defaultValue;
  }

  return (
    <Select
      ref={selectRef}
      selectedItem={selectedItem}
      onSelectedItemChange={handleOnSelectedItemChange}
      {...restField}
      {...selectProps}
    />
  );
}

const FormSelect = React.forwardRef(FormSelectInner) as <
  T,
  TFieldValues extends FieldValues
>(
  props: FormSelectProps<T, TFieldValues> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof FormSelectInner>;

export { FormSelect };
export type { FormSelectProps };
