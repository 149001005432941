import * as React from "react";

import { Box, Icon, IconButton, useDisclosure } from "@chakra-ui/react";
import { EmrMedicalHX, EmrRemove } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { ProviderChartCode, TriageChartCode } from "enums";
import { useToast } from "hooks";
import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import {
  ChartRowElementImportedProps,
  ChartSection,
  ChartSubsectionHeader,
  CustomRow,
  mapPayloadToFormObject,
  useSharedChartForm,
} from "modules/charts-shared";
import { FormProvider, useFieldArray } from "react-hook-form";
import { Loading, SingleActionDialog } from "shared";
import { FormPromptWithSaveProps } from "shared/prompt";
import { extractApiErrorMessage } from "utils";
import {
  useImportProviderMedicalHistory,
  useProviderMedicalHistory,
  useUpdateProviderMedicalHistory,
} from "../api";
import { ROWS_OPTIONS } from "./custom-rows-modal/constants";
import { useTriageMedicalHistoryFirstSnapshot } from "modules/triage/api";
// import { replaceT003WithC003 } from "../utils/replace-chart-keys";
import { extractSMHXKeys, extractSMHXPart } from "../utils/extract-smhx-keys";

const sectionId = "SMHX";
const DEFAULT_CHART_CODE: ProviderChartCode = "C003x";

function ProviderMedicalHistory(props: {
  chartCode: TriageChartCode | ProviderChartCode;
  isReadOnly?: boolean;
}) {
  const { isReadOnly: isMedicalHistoryReadonly } = props;

  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    onSaveAndNext,
    data: providerData,
  } = useSharedChartForm({
    sectionLabel: "Medical History",
    useGetSharedChartForm: useProviderMedicalHistory,
    useUpdateSharedChartForm: useUpdateProviderMedicalHistory,
    sectionCode: sectionId,
  });

  const {
    mutateAsync: importProviderMedicalHistory,
    isLoading: isImportingProviderMedicalHistoryLoading,
  } = useImportProviderMedicalHistory({ encounterId });

  const { watch, control, reset } = useFormContext;

  const [chartCode, setChartCode] = React.useState<
    ProviderChartCode | TriageChartCode
  >(DEFAULT_CHART_CODE);

  const [nursingFormChanges, setNursingFormChanges] = React.useState<string[]>(
    []
  );
  const customRows = watch("Q001.customRows") as CustomRow[] | undefined;
  const { remove } = useFieldArray({
    control,
    name: `Q001.customRows` as never,
  });

  const { data: triageData, isLoading: isLoadingTriageMedicalHistory } =
    useTriageMedicalHistoryFirstSnapshot(encounterId);

  const toast = useToast();

  React.useEffect(() => {
    if (triageData && providerData) {
      const triageForm = mapPayloadToFormObject(triageData.data);
      const providerForm = mapPayloadToFormObject(providerData.data);

      /** If the provider form retrieves data from the triage form instead,
       * it means the provider form is empty and the nurse has already
       * filled on her side, hence the backend is returning the triage form */
      const providerHasDataFromTriage = providerForm?.Q001
        ? Object.keys(providerForm?.Q001).some((x) => x?.includes("T003x"))
        : false;

      if (providerHasDataFromTriage) {
        setChartCode("T003x");
        reset(providerForm);
      }

      const formChanges = extractSMHXKeys(triageForm);
      setNursingFormChanges(formChanges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triageData, providerData]);

  const getImportedProps = (
    code: string
  ): Record<"imported", ChartRowElementImportedProps> | null => {
    const part = extractSMHXPart(code);

    if (nursingFormChanges.includes(part)) {
      return {
        imported: {
          status: "accepted",
          type: "nursing",
        },
      };
    }

    return null;
  };

  const { scope } = usePermissions();
  const isReadOnly =
    isMedicalHistoryReadonly || !scope("provider:mh").isEditable;

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await onSubmit();
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const [modalLabel, setModalLabel] = React.useState("");
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState<string[]>([]);

  const onImportProviderMedicalHx = async () => {
    try {
      const importRequest = await importProviderMedicalHistory({ encounterId });

      setModalLabel("Ok");
      setModalTitle("Warning!");
      if (importRequest.data) {
        setModalContent([
          "This patient’s Medical History has been imported from their last encounter.",
          "Please review to make sure this information is up to date.",
        ]);
      } else {
        setModalContent([
          "This patient has no previous encounters.  There is no Medical History to be imported.",
        ]);
      }

      onOpenModal();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  if (getIsLoading || isLoadingTriageMedicalHistory) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={onRouteChangeSave}
        isPromptSaveLoading={
          updateIsLoading || isImportingProviderMedicalHistoryLoading
        }
      >
        <SharedChartSectionHeader
          icon={<Icon as={EmrMedicalHX} />}
          encounterId={encounterId}
          sectionId={sectionId}
          showImportBtn
          showActionsBtns
          onImport={() => {
            if (!isReadOnly) onImportProviderMedicalHx();
          }}
          isLoading={
            updateIsLoading || isImportingProviderMedicalHistoryLoading
          }
          onSaveAndNext={onSaveAndNext}
        >
          Medical History {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              encounterId={encounterId}
              chartId={chartCode}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Past HX"
              showAddCustomRows
              customRowsOptions={ROWS_OPTIONS.filter(
                (option) =>
                  customRows &&
                  !customRows
                    .flatMap((customRow) =>
                      customRow.customAnswers.map(
                        (customAnswer) => customAnswer.label
                      )
                    )
                    .includes(option)
              )}
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                    w="168px"
                  >
                    no chronic diseases
                  </ChartRowElement>
                </>
              }
            >
              Past HX
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  label="cardiac disease"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A002`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  label="Afib"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A003`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  label="CAD"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A004`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  label="CHF"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A005`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  label="MI"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A006`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A007`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  label="cancer"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A008`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  label="chemo"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A009`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  label="rad tx"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A010`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A011`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  label="diabetes:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A012`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  label="Type 1"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A013`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  label="Type 2"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A014`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  label="diet"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A015`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  label="oral"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A016`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  label="insulin"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A017`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A018`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  label="asthma"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A019`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  label="COPD"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A020`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A021`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  label="hypertension"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A022`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A023`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  label="LMNP:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A024`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  width="20rem"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A025`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  label="pregnant"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A026`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  label="postmenopausal"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A027`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  label="hysterectomy"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A028`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A029`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  label="birth weight:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A030`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  width="10rem"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A031`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  label="premature birth:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A032`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                  width="10rem"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A033`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                  label="weeks"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A034`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A035`}
                  label="complications at birth:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A035`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A036`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A036`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A037`}
                  label="old records reviewed"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A037`)}
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A038`}
                  label="summary:"
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A038`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q001.${chartCode}${sectionId}Q001A039`}
                  {...getImportedProps(`Q001.${chartCode}${sectionId}Q001A039`)}
                />
              </ChartRow>
              {customRows?.map((customRow, index) => (
                <ChartRow key={customRow.order}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={4}
                    alignItems="center"
                  >
                    {customRow.customAnswers.map((customAnswer) => (
                      <ChartRowElement
                        key={customAnswer.qCode}
                        type={
                          customAnswer.type === "Textfield"
                            ? "input"
                            : "cross-option"
                        }
                        name={customAnswer.qCode}
                        label={customAnswer.label}
                        {...getImportedProps(customAnswer.qCode)}
                      />
                    ))}
                    <IconButton
                      aria-label="delete-row-btn"
                      variant="icon"
                      size="lg"
                      mr={2}
                      icon={
                        <Icon
                          as={EmrRemove}
                          color="red"
                          sx={{ h: "20px", w: "20px" }}
                        />
                      }
                      onClick={() => remove(index)}
                    />
                  </Box>
                </ChartRow>
              ))}
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionId}
              subsectionCode="Q002"
              subsectionLabel="Immunizations"
            >
              Immunizations
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Covid"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A001`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="referred to PCP"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A002`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="UTD"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A003`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="give in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A004`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A005`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="influenza"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A006`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="referred to PCP"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A007`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="UTD"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A008`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="give in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A009`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A010`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="pneumovax"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A011`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="referred to PCP"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A012`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="UTD"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A013`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="give in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A014`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A015`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="tetanus"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A016`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="pneumovax"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                />
                <ChartRowElement
                  type="cross-option"
                  label="referred to PCP"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A018`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="UTD"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A019`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="give in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  {...getImportedProps(`Q002.${chartCode}${sectionId}Q002A020`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Surgeries / Procedures"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q003.${chartCode}${sectionId}Q003A001`}
                    w="60px"
                    {...getImportedProps(
                      `Q003.${chartCode}${sectionId}Q003A001`
                    )}
                  >
                    none
                  </ChartRowElement>
                </>
              }
            >
              Surgeries / Procedures
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="appendectomy"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A002`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="cholecystectomy"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A003`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="hysterectomy"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A004`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="tonsillectomy"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A005`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="BTL"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A006`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="C-section"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A007`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A008`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A009`)}
                  label="CT"
                />
                <ChartRowElement
                  type="cross-option"
                  label="MRI"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A010`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="ECHO"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A011`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A012`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="input"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A013`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="input"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A014`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="input"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  {...getImportedProps(`Q003.${chartCode}${sectionId}Q003A015`)}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionId}
              subsectionCode="Q004"
              subsectionLabel="Social HX"
            >
              Social HX
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="attends:"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A001`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="daycare"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A002`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="school"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A003`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A004`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A005`)}
                  label="caretaker"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foster care"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A006`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A007`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A008`)}
                  label="2nd hand smoke exposure"
                />
                <ChartRowElement
                  type="cross-option"
                  label="smoker"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A009`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A010`)}
                  width="10rem"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ppd"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A011`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="never"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A012`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="past"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A013`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="quit"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A014`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A015`)}
                  width="10rem"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ago"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A016`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A017`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A029`)}
                  label="tobacco:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="use"
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A030`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="dependence"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A031`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A032`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A033`)}
                  label="nicotine:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="use"
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A034`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="dependence"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A035`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A036`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A037`)}
                  label="vaporizer:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="use"
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A038`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="dependence"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A039`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A040`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="drugs"
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A041`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A042`)}
                  width="5.5rem"
                />
                <ChartRowElement
                  type="cross-option"
                  label="alcohol"
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A043`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="recent"
                  name={`Q004.${chartCode}${sectionId}Q004A044`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A044`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="heavy"
                  name={`Q004.${chartCode}${sectionId}Q004A045`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A045`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="occasional"
                  name={`Q004.${chartCode}${sectionId}Q004A046`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A046`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="screening"
                  name={`Q004.${chartCode}${sectionId}Q004A047`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A047`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A048`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A048`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A049`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A049`)}
                  label="smoking cessation counseling"
                />
                <ChartRowElement
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A050`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A050`)}
                  label="alcohol cessation counseling"
                />
                <ChartRowElement
                  type="input"
                  name={`Q004.${chartCode}${sectionId}Q004A051`}
                  {...getImportedProps(`Q004.${chartCode}${sectionId}Q004A051`)}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              encounterId={encounterId}
              chartId={chartCode}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Family HX"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q005.${chartCode}${sectionId}Q005A001`}
                    {...getImportedProps(
                      `Q005.${chartCode}${sectionId}Q005A001`
                    )}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
            >
              Family HX
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="adopted"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A002`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A003`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="cancer"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A004`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="diabetes"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A005`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A006`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="cardiac disease"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A007`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="aortic disease"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A008`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  {...getImportedProps(`Q005.${chartCode}${sectionId}Q005A009`)}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              encounterId={encounterId}
              chartId={chartCode}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Screening"
            >
              Screening
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="infectious disease risk:"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A001`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="hepatitis"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A002`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="HIV"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A003`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="TB exposure"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A004`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="symptoms"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A005`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A006`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="recent travel:"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A007`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="outside US"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A008`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A009`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="sick contacts:"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A010`)}
                />
                <ChartRowElement
                  type="cross-option"
                  label="home"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A011`)}
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="work"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A012`)}
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="school"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A013`)}
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="cross-option"
                  label="public"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A014`)}
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A015`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="physically hurt or threatened by someone close"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A016`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A017`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="fall risk"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A018`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A019`)}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="suicide risk"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A020`)}
                />
                <ChartRowElement
                  type="input"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  {...getImportedProps(`Q006.${chartCode}${sectionId}Q006A021`)}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>

      {isOpenModal && (
        <SingleActionDialog
          title={modalTitle}
          actionLabel={modalLabel}
          actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
          content={modalContent}
          onClose={onCloseModal}
          isOpen={isOpenModal}
        />
      )}
    </FormProvider>
  );
}

export { ProviderMedicalHistory };
