import { axiosIdentityInstance } from "config";
import { RoomTicketStatus, RoomTicketType } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type TicketRequestDto = {
  ticketId?: string;
  roomId: string;
  userId: string;
  description: string;
  reportedAt: Date;
  type?: RoomTicketType;
  status: RoomTicketStatus;
};

type UpdateTicketsRequest = {
  newTickets: TicketRequestDto[];
  updatedTickets: TicketRequestDto[];
};

function useUpdateTickets(roomId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateTickets", { roomId }],
    (input: UpdateTicketsRequest) =>
      axiosIdentityInstance.post("/v1/ticket", input, { params: { roomId } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
        queryClient.invalidateQueries(["ticketsByRoom", { roomId }]);
      },
    }
  );
}

export { useUpdateTickets };
export type { TicketRequestDto };
