import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseMergeEncountersPayload = {
  masterId: string;
  encounterIds: string[];
};

function useMergeEncounters() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["mergeEncounters"],
    (payload: UseMergeEncountersPayload) =>
      axiosPatientInstance.post("v1/merge", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inProcessCounters"]);
        queryClient.invalidateQueries("receptionCounters");
        queryClient.invalidateQueries("duplicatePatientsCounters");
        queryClient.invalidateQueries("searchDuplicateEncounters");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("encounters");
      },
    }
  );
}

export type { UseMergeEncountersPayload };
export { useMergeEncounters };
