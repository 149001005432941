import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type ChargeNurse = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  pictureUrl?: string;
};

type ActiveChargeNurseResponse = {
  chargeNurse?: ChargeNurse;
};

function useGetActiveChargeNurse() {
  return useAxiosQuery(["activeChargeNurse"], () =>
    axiosIdentityInstance.get<ActiveChargeNurseResponse>(
      `v1/user/charge-nurse/active`
    )
  );
}

export { useGetActiveChargeNurse };
export type { ChargeNurse };
