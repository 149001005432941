import { useMergeRefs } from "@chakra-ui/react";
import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { RadioChartOptionGroup, RadioChartOptionGroupProps } from "shared";
import { useDivideControlProps } from "./hooks";

type FormRadioChartOptionGroupProps<T extends FieldValues> = Omit<
  RadioChartOptionGroupProps,
  "name"
> &
  UseControllerProps<T>;

function FormRadioChartOptionGroupInner<T extends FieldValues>(
  props: FormRadioChartOptionGroupProps<T>,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const { onChange: onChangeProp, isDisabled, ...restProps } = props;
  const [imagePickerProps, controllerProps] = useDivideControlProps<
    T,
    FormRadioChartOptionGroupProps<T>
  >(restProps);

  const {
    field: { ref: fieldRef, onChange, ...fieldProps },
  } = useController(controllerProps);

  const mergedRef = useMergeRefs(ref, fieldRef);

  const handleChange: RadioChartOptionGroupProps["onChange"] = (nextValue) => {
    onChangeProp?.(nextValue);
    onChange(nextValue);
  };

  return (
    <RadioChartOptionGroup
      {...fieldProps}
      {...imagePickerProps}
      ref={mergedRef}
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}

const FormRadioChartOptionGroup = React.forwardRef(
  FormRadioChartOptionGroupInner
) as <TFieldValues extends FieldValues>(
  props: FormRadioChartOptionGroupProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof FormRadioChartOptionGroupInner>;

export type { FormRadioChartOptionGroupProps };
export { FormRadioChartOptionGroup };
