import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const FolderPerson = createIcon({
  path: (
    <path
      d="M16.1609 8.63281C14.0568 8.63281 12.3219 6.68213 12.3219 4.31641C12.3219 1.95068 14.0568 0 16.1609 0C18.265 0 20 1.95068 20 4.31641C20 6.66553 18.2503 8.63281 16.1609 8.63281ZM2.50277 15.9458C0.87117 15.9458 0 14.9746 0 13.1567V3.03809C0 1.23682 0.87117 0.273926 2.31082 0.273926H4.38538C5.19011 0.273926 5.5814 0.439941 6.12034 0.921387L6.51901 1.28662C6.9546 1.67676 7.28682 1.81787 7.83315 1.81787H11.7608C11.4876 2.43213 11.3621 3.01318 11.3178 3.66895H7.39018C6.58546 3.66895 6.18678 3.50293 5.65522 3.02148L5.24917 2.65625C4.81358 2.25781 4.48874 2.125 3.9498 2.125H2.63566C2.00074 2.125 1.64636 2.50684 1.64636 3.2207V5.2959H11.34C11.436 5.86035 11.6205 6.3916 11.8789 6.86475H1.64636V12.9658C1.64636 13.7295 1.99336 14.0947 2.64304 14.0947H14.3965C15.0314 14.0947 15.3931 13.7295 15.3931 12.9658V9.74512C15.9173 9.87793 16.567 9.86963 17.0321 9.74512V13.1567C17.0321 14.9746 16.1683 15.9458 14.6918 15.9458H2.50277ZM16.1609 4.45752C16.8106 4.45752 17.32 3.85156 17.32 3.02148C17.32 2.26611 16.8106 1.63525 16.1609 1.63525C15.5113 1.63525 15.0018 2.26611 15.0018 3.02148C15.0018 3.85156 15.5039 4.45752 16.1609 4.45752ZM16.1609 7.4707C17.2315 7.4707 18.0509 6.83154 18.265 6.27539V6.24219C18.0362 5.72754 17.2388 5.15479 16.1609 5.15479C15.0904 5.15479 14.2931 5.71924 14.0568 6.24219V6.27539C14.2636 6.83154 15.0904 7.4707 16.1609 7.4707Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18",
});

export { FolderPerson };
