import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const FootSteps = createIcon({
  path: (
    <path
      d="M3.62419 0C5.53482 0 7.24838 2.82738 7.24838 5.08072C7.24838 6.13456 6.88853 8.18227 6.08316 9.84443H1.16522C0.351281 8.18227 0 6.13456 0 5.08072C0 2.82738 1.71357 0 3.62419 0ZM14.1712 5.71474C15.9704 6.37446 16.6044 9.6131 15.8333 11.7294C15.4735 12.7232 14.4368 14.5225 13.1088 15.8076L8.49072 14.1283C8.29366 12.2863 8.67064 10.2386 9.02192 9.25325C9.80159 7.137 12.3719 5.06358 14.1712 5.71474ZM3.63276 15.0794C2.24477 15.0794 1.4908 13.7171 1.4908 11.7893C1.4908 11.6094 1.50794 11.3438 1.54221 11.0953H5.71474C5.74901 11.3438 5.75758 11.6094 5.75758 11.7893C5.75758 13.7171 5.00361 15.0794 3.63276 15.0794ZM9.01335 19.8859C7.71961 19.4061 7.47971 17.8725 8.13943 16.0647C8.19941 15.8933 8.30222 15.6534 8.4136 15.4307L12.3377 16.8529C12.2777 17.1014 12.2092 17.3584 12.1492 17.5298C11.4895 19.329 10.3157 20.3572 9.01335 19.8859Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 20",
});

export { FootSteps };
