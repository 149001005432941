import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const CheckmarkShield = createIcon({
  path: (
    <path
      d="M18.961 36C19.2847 36 19.7522 35.8741 20.2198 35.6404C30.5235 30.3357 33.9401 27.5485 33.9401 21.0929V7.73227C33.9401 5.61039 33.1129 4.83716 31.3327 4.0999C29.3546 3.27273 22.7732 0.953047 20.8492 0.305694C20.2557 0.125874 19.5904 0 18.961 0C18.3497 0 17.6843 0.125874 17.0909 0.305694C15.1489 0.917083 8.56743 3.29071 6.60739 4.0999C4.82717 4.81918 4 5.61039 4 7.73227V21.0929C4 27.5485 7.56044 30.0839 17.7203 35.6404C18.1878 35.8921 18.6553 36 18.961 36ZM16.965 26.0739C16.2817 26.0739 15.7243 25.7862 15.2208 25.1389L10.8152 19.7982C10.4915 19.3846 10.3297 18.953 10.3297 18.5035C10.3297 17.5684 11.0669 16.8132 11.984 16.8132C12.5594 16.8132 12.991 17.029 13.4585 17.6044L16.9111 21.974L24.3197 10.1239C24.7153 9.49451 25.2188 9.17083 25.7942 9.17083C26.6933 9.17083 27.5385 9.8002 27.5385 10.7532C27.5385 11.1668 27.3227 11.6164 27.0709 12.03L18.6374 25.1209C18.2238 25.7502 17.6484 26.0739 16.965 26.0739Z"
      fill="currentColor"
    />
  ),
  viewBox: "9 0 20 38",
});

export { CheckmarkShield };
