import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Restricted = createIcon({
  path: (
    <path
      d="M10 20C15.4833 20 20 15.4758 20 9.99522C20 4.51459 15.4737 0 10 0C4.51675 0 0 4.51459 0 9.99522C0 15.4758 4.51675 20 10 20ZM10 17.7523C5.70335 17.7523 2.25837 14.2898 2.25837 9.99522C2.25837 5.70062 5.69378 2.24773 10 2.24773C14.2967 2.24773 17.7512 5.70062 17.7608 9.99522C17.7608 14.2898 14.2967 17.7523 10 17.7523ZM6.77512 14.2133C7.08134 14.2133 7.32057 14.0985 7.55024 13.869L13.8278 7.5275C14.0574 7.29794 14.1722 7.03969 14.1722 6.77188C14.1722 6.21712 13.7321 5.79627 13.1962 5.79627C12.9187 5.79627 12.6699 5.91105 12.4402 6.1406L6.16268 12.4821C5.93301 12.7116 5.81818 12.9699 5.81818 13.2377C5.81818 13.7829 6.2201 14.2133 6.77512 14.2133Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Restricted };
