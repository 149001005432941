import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { PCP } from "../../types";

type CreatePrimaryCarePhysicianPayload = {
  name: string;
  phone: string;
  fax: string;
  address: string;
  suite: string;
  city: string;
  state: string;
  zip: string;
};

function useCreatePrimaryCarePhysician() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createPrimaryCarePhysician"],
    (payload: CreatePrimaryCarePhysicianPayload) => {
      return axiosFacilityInstance.post<PCP>("/v1/physician", payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("primaryCarePhysicians");
      },
    }
  );
}

export { useCreatePrimaryCarePhysician };
export type { CreatePrimaryCarePhysicianPayload };
