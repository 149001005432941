import * as React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Flex,
  Spinner,
  Tooltip,
  useMultiStyleConfig,
} from "@chakra-ui/react";

import { useFileUrl } from "hooks";
import { useUserProfilePicture } from "api/queries";

type StaffAvatarProps = {
  userId?: string;
  userName?: string;
  profileUrl?: string;
} & AvatarProps;

type BlobStaffAvatarProps = {
  userId?: string;
  userName?: string;
  blobUrl?: Nullable<File> | string;
} & AvatarProps;

function StaffAvatar(props: StaffAvatarProps) {
  const { userName, profileUrl, ...avatarProps } = props;

  const { w, h, width, height, size, fontSize, ...rest } = avatarProps;

  // Queries
  const {
    data: file,
    isLoading: isFileLoading,
    isError,
  } = useUserProfilePicture(profileUrl || "");

  const fileUrl = useFileUrl(file?.data);

  const styles = useMultiStyleConfig("Avatar", { size });
  const widthTemplate =
    w || width || (styles.container.width as string) || "140px";
  const heightTemplate =
    h || height || (styles.container.height as string) || "140px";

  return (
    <Tooltip label={userName}>
      <Box>
        {isFileLoading ? (
          <Flex
            width={widthTemplate}
            height={heightTemplate}
            align="center"
            justify="center"
            {...rest}
          >
            <Spinner color="blue" />
          </Flex>
        ) : isError || !userName ? (
          <Avatar loading="eager" {...avatarProps} />
        ) : (
          <Avatar
            bg="#9e2700"
            loading="eager"
            src={fileUrl || ""}
            name={userName}
            {...avatarProps}
          />
        )}
      </Box>
    </Tooltip>
  );
}

function getImgSrc(img?: Nullable<File> | string) {
  if (!img) {
    return "";
  }

  if (typeof img === "string") {
    return img;
  }

  return URL.createObjectURL(img);
}

function BlobStaffAvatar(props: BlobStaffAvatarProps) {
  const { userName, blobUrl, ...avatarProps } = props;

  const fileUrl = getImgSrc(blobUrl);

  return (
    <Box>
      <Avatar
        bg="#9e2700"
        loading="eager"
        src={fileUrl || ""}
        name={userName}
        {...avatarProps}
      />
    </Box>
  );
}

export { StaffAvatar, BlobStaffAvatar };
