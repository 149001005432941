import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";

function useEncounterOrderFile(
  orderId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["encounterOrderFile", { orderId }],
    () =>
      axiosOrderInstance.get<Blob>(`v1/file/${orderId}`, {
        responseType: "blob",
      }),
    { ...options, staleTime: 5 * 60 * 1000 }
  );
}

function useEncounterOrderFileRamsoft(
  orderId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["encounterRamsoftOrderFile", { orderId }],
    () =>
      axiosOrderInstance.get<Blob>(`v1/file/${orderId}/ramsoft`, {
        responseType: "blob",
      }),
    { ...options, staleTime: 5 * 60 * 1000 }
  );
}

export { useEncounterOrderFile, useEncounterOrderFileRamsoft };
