import * as React from "react";
import { Box, chakra, HStack, SelectProps } from "@chakra-ui/react";
import { Segment, SegmentOption } from "shared";
import { range } from "utils";

type CalendarTimeInputProps = {
  onChange?: (value: string) => void;
  value?: string;
};

const SELECT_STYLE_PROPS: Omit<SelectProps, "size"> = {
  backgroundColor: "gray.250",
  borderRadius: "5px",
  border: "none",
  height: "2.25rem",
  width: "30px",
  appearance: "none",
};

function getOptions(start: number, end: number) {
  return range(start, end).map((x) => {
    const opt = x < 10 ? `0${x}` : `${x}`;
    return (
      <option key={opt} value={opt}>
        {opt}
      </option>
    );
  });
}

function to12Format(value?: string) {
  if (!value) {
    return "12:00";
  }

  const [hours, minutes] = value.split(":");

  const asNumber = Number(hours);

  if (asNumber === 0) {
    return `12:${minutes}`;
  }

  if (asNumber > 12) {
    const in12Format = asNumber - 12;
    return `${in12Format < 10 ? "0" : ""}${in12Format}:${minutes}`;
  }

  return value;
}

function to24Format(x: "am" | "pm", value?: string) {
  if (!value) {
    return "00:00";
  }

  const [hours, minutes] = value.split(":");

  const asNumber = Number(hours);

  /**
   * If time is AM and hours 12, then the 24h format
   * is substracting 12 to the hours
   */
  if (x === "am" && asNumber === 12) {
    return `${asNumber - 12}:${minutes}`;
  }

  /**
   * If time is PM and hours 12, then time is already in 24h
   * format
   */
  if (x === "pm" && asNumber === 12) {
    return `${asNumber}:${minutes}`;
  }

  /**
   * If time is PM and hours different to 12, then the 24h format
   * is calculated by adding 12 to hours
   */
  if (x === "pm") {
    return `${asNumber + 12}:${minutes}`;
  }

  /**
   * If time is AM and hours different to 12, then time is already
   * in 24h format
   */
  return value;
}

function isAmPeriod(time?: string) {
  if (!time) {
    return true;
  }

  const [hours] = time.split(":");

  return Number(hours) < 12;
}

const HOURS_OPTIONS = getOptions(1, 13);
const MINUTES_OPTIONS = getOptions(0, 60);

function CalendarTimeInput(props: CalendarTimeInputProps) {
  const { onChange, value } = props;
  const [hours, minutes] = to12Format(value).split(":");
  const [period, setPeriod] = React.useState(isAmPeriod(value) ? 0 : 1);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="0 1rem 1rem"
    >
      <chakra.span fontWeight="bold" fontSize="1.25rem">
        Time
      </chakra.span>
      <HStack>
        <Box
          display="flex"
          bg="gray.250"
          borderRadius="5px"
          width="100%"
          height="2.25rem"
          alignItems="center"
          fontSize="1.375rem"
        >
          <chakra.select
            value={hours}
            onChange={(e) =>
              onChange?.(
                to24Format(
                  period === 0 ? "am" : "pm",
                  `${e.currentTarget.value}:${minutes}`
                )
              )
            }
            textAlign="right"
            {...SELECT_STYLE_PROPS}
          >
            {HOURS_OPTIONS}
          </chakra.select>
          <chakra.span margin="0 0.25rem">:</chakra.span>
          <chakra.select
            value={minutes}
            onChange={(e) => {
              onChange?.(`${hours || "00"}:${e.currentTarget.value}`);
            }}
            {...SELECT_STYLE_PROPS}
          >
            {MINUTES_OPTIONS}
          </chakra.select>
        </Box>
        <Segment
          index={period}
          onChange={(x) => {
            onChange?.(
              to24Format(x === 0 ? "am" : "pm", `${hours}:${minutes}`)
            );
            setPeriod(x);
          }}
        >
          <SegmentOption>AM</SegmentOption>
          <SegmentOption>PM</SegmentOption>
        </Segment>
      </HStack>
    </Box>
  );
}

export type { CalendarTimeInputProps };
export { CalendarTimeInput };
