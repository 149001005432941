import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrRos } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  ChartRowGroup,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ROS_GRID_COLUMNS } from "modules/provider/shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SROS";
const chartCode = "C003x";

function T03ROS() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "ROS",
    chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ros").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrRos} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          ROS {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="CONST"
            >
              CONST
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSROSQ001A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSROSQ001A002"
                    label="recent illness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSROSQ001A003"
                    label="fever"
                  />
                  <ChartRowElement type="input" name="Q001.C003xSROSQ001A004" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="EYE"
            >
              EYE
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSROSQ002A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSROSQ002A002"
                    label="problems with vision"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSROSQ002A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="ENT"
            >
              ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSROSQ003A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSROSQ003A002"
                    label="nasal drainage"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSROSQ003A003"
                    label="congestion"
                  />
                  <ChartRowElement type="input" name="Q003.C003xSROSQ003A004" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="MS"
            >
              MS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSROSQ004A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSROSQ004A002"
                    label="back pain"
                  />

                  <ChartRowElement type="input" name="Q004.C003xSROSQ004A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="NEURO"
            >
              NEURO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C003xSROSQ005A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C003xSROSQ005A002"
                    label="numbness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q005.C003xSROSQ005A003"
                    label="weakness"
                  />
                  <ChartRowElement type="input" name="Q005.C003xSROSQ005A004" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="GU"
            >
              GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSROSQ006A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSROSQ006A002"
                    label="problems urinating"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSROSQ006A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="LNMP"
            >
              LNMP
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSROSQ007A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSROSQ007A002"
                    label="preg"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSROSQ007A003"
                    label="post-menop"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSROSQ007A004" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSROSQ008A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSROSQ008A002"
                    label="chest pain"
                  />
                  <ChartRowElement type="input" name="Q008.C003xSROSQ008A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="RESP"
            >
              RESP
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSROSQ009A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSROSQ009A002"
                    label="shortness of breath"
                  />
                  <ChartRowElement type="input" name="Q009.C003xSROSQ009A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="LYMPH"
            >
              LYMPH
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSROSQ010A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSROSQ010A002"
                    label="ankle swelling"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q010.C003xSROSQ010A003"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q010.C003xSROSQ010A004"
                  />
                  <ChartRowElement type="input" name="Q010.C003xSROSQ010A005" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="SKIN"
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSROSQ011A001"
                    label="normal"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSROSQ011A002"
                    label="rash"
                  />
                  <ChartRowElement type="input" name="Q011.C003xSROSQ011A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="GI"
            >
              GI
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow gridTemplateColumns={ROS_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q012.C003xSROSQ012A001"
                      label="normal"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q012.C003xSROSQ012A002"
                      label="nausea"
                    />
                    <ChartRowElement
                      type="cross-option"
                      name="Q012.C003xSROSQ012A003"
                      label="vomiting"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q012.C003xSROSQ012A004"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSROSQ012A005"
                    label="except as marked positive, all systems above reviewed and found negative"
                  />
                  <ChartRowElement type="empty" />
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSROSQ012A006"
                    label="CONST / NEURO / MS components also addressed in HPI"
                  />
                  <ChartRowElement type="empty" />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T03ROS };
