import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartType } from "types";

type UseRestoreDeletedChart = {
  chartType: ChartType;
  id: string;
  name: string;
};

function useRestoreDeletedChart(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["restoreCharts", { encounterId }],
    (chartApplicationId: string) =>
      axiosChartInstance.get<UseRestoreDeletedChart>(
        `v1/chart-application/${encounterId}/recover-chart-application/${chartApplicationId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activeCharts", { encounterId }]);
        queryClient.invalidateQueries(["deletedChartsList", { encounterId }]);
      },
    }
  );
}

export { useRestoreDeletedChart };
