import * as React from "react";
import jsPDF from "jspdf";
import { ImageDimension, CustomImage } from "types";
import { A4_PAPER_DIMENSIONS, A4_PAPER_RATIO } from "system-constants";

type PDFGeneratorReturnValues = {
  generatePdf(files: File[]): Promise<Blob>;
  isCreatingPdf: boolean;
};

const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

const fileToImageURL = (file: File): Promise<CustomImage> => {
  return new Promise((resolve, reject) => {
    const image = new CustomImage(file.type);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject(new Error("Failed to convert File to Image"));
    };

    image.src = URL.createObjectURL(file);
  });
};

const useGenerateImagesPdf = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const generatePdf = (files: File[]) =>
    new Promise<Blob>(async (resolve, reject) => {
      try {
        setIsLoading(true);

        const images: CustomImage[] = [];

        for (const img of files) {
          const _img = await fileToImageURL(img);
          images.push(_img);
        }

        const doc = new jsPDF();
        doc.deletePage(1);

        doc.setProperties({
          title: "emr-pdf",
        });

        images.forEach((image) => {
          const imageDimensions = imageDimensionsOnA4({
            width: image.width,
            height: image.height,
          });

          doc.addPage();
          doc.addImage(
            image.src,
            image.imageType,
            (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
            (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
            imageDimensions.width,
            imageDimensions.height
          );
        });

        setIsLoading(false);

        resolve(
          new File([doc.output("blob")], "emr-pdf.pdf", {
            type: "application/pdf",
          })
        );
      } catch (error) {
        setIsLoading(false);
        reject(error);
      }
    });

  return { generatePdf, isCreatingPdf: isLoading } as PDFGeneratorReturnValues;
};

export { useGenerateImagesPdf };
