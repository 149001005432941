import { Box, BoxProps } from "@chakra-ui/react";
import * as React from "react";

type OrderDetailsEntryProps = Omit<BoxProps, "children"> & {
  label: string;
  value: React.ReactNode;
};

function OrderDetailsEntryTextBox(props: BoxProps) {
  return <Box fontSize="1.0625rem" lineHeight="1.25rem" {...props} />;
}

function OrderDetailsEntryDateBox(props: BoxProps) {
  return (
    <OrderDetailsEntryTextBox
      whiteSpace="pre-line"
      textAlign="right"
      {...props}
    />
  );
}

function OrderDetailsEntry(props: OrderDetailsEntryProps) {
  const { label, value, ...restProps } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="60px"
      minWidth="445px"
      padding="0 20px 0 0"
      {...restProps}
    >
      <Box fontSize="1.0625rem" color="gray.700">
        {label}
      </Box>
      {value}
    </Box>
  );
}

function OrderDetailsNotes(props: OrderDetailsEntryProps) {
  const { label, value, ...restProps } = props;

  return (
    <Box height="144px" minWidth="445px" padding="8px 20px 0 0" {...restProps}>
      <Box fontSize="1.0625rem" color="gray.700">
        {label}
      </Box>
      <Box
        p="4px"
        borderRadius="10px"
        minW="425px"
        bgColor="gray.200"
        border="1px solid"
        borderColor="gray.450"
        h="100px"
      >
        {value}
      </Box>
    </Box>
  );
}

export type { OrderDetailsEntryProps };
export {
  OrderDetailsEntry,
  OrderDetailsEntryTextBox,
  OrderDetailsEntryDateBox,
  OrderDetailsNotes,
};
