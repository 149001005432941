function variantSolid() {
  return {
    container: {
      backgroundColor: "#F2F2F2",
      borderTopLeftRadius: "14px !important",
      borderTopRightRadius: "14px !important",
      borderBottomLeftRadius: "14px !important",
      borderBottomRightRadius: "14px !important",
      px: 4,
      py: 3,
      maxWidth: "350px",
    },
    title: {
      fontWeight: "600",
      color: "black",
      textAlign: "center",
      fontSize: "17px",
    },
    description: {
      color: "black",
      textAlign: "center",
      fontSize: "13px",
    },
    icon: {
      visibility: "hidden",
      display: "none",
    },
  };
}

const variants = {
  solid: variantSolid,
};

export default {
  variants,
};
