import {
  AvatarBadge,
  Box,
  BoxProps,
  chakra,
  Flex,
  Icon,
  IconButton,
  Input,
  InputProps,
  Spinner,
  useControllableState,
  useFormControl,
  useMultiStyleConfig,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  CloseButton,
} from "@chakra-ui/react";
import { EmrPlus } from "@medstonetech/slate-icons";
import { config } from "config";
import { useToast } from "hooks";
import { MinusRoundFilled, Scanner, Import, ExpandAlt } from "icons";
import * as React from "react";
import { Avatar } from "shared/avatar";
import { constants } from "system-constants";
import { useScanner } from "hooks";
import { EMR_SCANNER_APP_LINK } from "system-constants/constants";

type ImagePickerProps = Omit<
  InputProps,
  "value" | "defaultValue" | "onChange"
> & {
  value?: Nullable<File> | string;
  variant?: "default" | "round" | "scanner";
  setValue?: (value: Nullable<File> | string) => void;
  defaultValue?: Nullable<File> | string;
  onDelete?: (e: React.MouseEvent) => void;
  expandLabel?: string;
  showExpand?: boolean;
  isLoading?: boolean;
};

type ImagePickerContextValue = {
  setValue: (value: Nullable<File> | string) => void;
  formControl: {
    disabled: boolean;
    readOnly: boolean;
    required: boolean;
    "aria-invalid": boolean | undefined;
    "aria-required": boolean | undefined;
    "aria-readonly": boolean | undefined;
    "aria-describedby": string | undefined;
    id: string;
    onFocus: (
      event: import("react").FocusEvent<HTMLInputElement, Element>
    ) => void;
    onBlur: (
      event: import("react").FocusEvent<HTMLInputElement, Element>
    ) => void;
  };
  value?: Nullable<File> | string;
  variant: "default" | "round" | "scanner";
  imgUrl?: string;
  onDelete?: (e: React.MouseEvent) => void;
  isLoading?: boolean;
  expandLabel?: string;
  showExpand?: boolean;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
};

const ImagePickerContext =
  React.createContext<Nullable<ImagePickerContextValue>>(null);

function useImagePickerContext() {
  const context = React.useContext(ImagePickerContext);

  if (!context) {
    throw new Error(
      "This component must be used as children of the ImagePicker component."
    );
  }

  return context;
}

type ImagePickerContentProps = {
  label?: string;
};

type ImagePickerActionsProps = Exclude<BoxProps, "variant">;

function getImgSrc(img?: Nullable<File> | string) {
  if (!img) {
    return "";
  }

  if (typeof img === "string") {
    return img;
  }

  return URL.createObjectURL(img);
}

function ImagePickerActions(props: ImagePickerActionsProps) {
  const { children, ...boxProps } = props;
  const {
    value,
    expandLabel = "",
    showExpand,
    onDelete: onDeleteProp,
    setValue,
    formControl: { disabled },
    variant,
    imgUrl,
  } = useImagePickerContext();
  const styles = useMultiStyleConfig("ImagePicker", { variant });

  const imagePickerExpandedModalDisclosure = useDisclosure();

  const onExpand = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!imagePickerExpandedModalDisclosure.isOpen) {
      imagePickerExpandedModalDisclosure.onOpen();
    }
  };

  const onDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    onDeleteProp?.(e);
    setValue(null);
  };

  if (variant === "round") {
    return (
      <Box __css={styles.actionsContainer} {...boxProps}>
        <Box>{children}</Box>
        {!!imgUrl && (
          <IconButton
            aria-label="Remove"
            variant="label"
            icon={
              <Icon
                as={MinusRoundFilled}
                bg="white"
                color="red"
                borderRadius="50%"
                h="22px"
                w="22px"
              />
            }
            disabled={!value || disabled}
            onClick={onDelete}
            __css={styles.deletebtn}
          />
        )}
      </Box>
    );
  }

  return (
    <Box __css={styles.actionsContainer} {...boxProps}>
      <Box>{children}</Box>

      {!!imgUrl && (
        <Box __css={styles.iconsContainer}>
          {showExpand && (
            <IconButton
              aria-label="Remove"
              variant="label"
              icon={<Icon as={ExpandAlt} color="blue" h="18px" w="20px" />}
              disabled={!value || disabled}
              onClick={onExpand}
              __css={styles.expandbtn}
            />
          )}

          <IconButton
            aria-label="Remove"
            variant="label"
            icon={
              <Icon
                as={MinusRoundFilled}
                bg="white"
                color="red"
                borderRadius="50%"
                h="19px"
                w="20px"
              />
            }
            disabled={!value || disabled}
            onClick={onDelete}
            __css={styles.deletebtn}
          />
        </Box>
      )}

      <ImagePickerExpandedModal
        variant={variant}
        imgUrl={imgUrl}
        imgTitle={expandLabel}
        isOpen={imagePickerExpandedModalDisclosure.isOpen}
        onClose={imagePickerExpandedModalDisclosure.onClose}
      />
    </Box>
  );
}

type ImagePickerExpandedModalProps = {
  variant: string;
  imgTitle?: string;
  imgUrl?: string;
  isOpen: boolean;
  onClose(): void;
};

function ImagePickerExpandedModal(props: ImagePickerExpandedModalProps) {
  const { variant, imgTitle, imgUrl, isOpen, onClose } = props;
  const styles = useMultiStyleConfig("ImagePicker", { variant });

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="gray.200" height="lg" width="3xl" maxWidth="4xl">
        <ModalHeader
          display="flex"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            width="100%"
            display="flex"
            flexDir="row"
            justifyContent="center"
          >
            <chakra.span fontSize="16px">{imgTitle}</chakra.span>
          </Box>
          <CloseButton
            position="absolute"
            top="18px"
            right="20px"
            width="20px"
            height="20px"
            bg="gray.700"
            color="gray.200"
            borderRadius="50%"
            sx={{ svg: { width: "9px", height: "9px" } }}
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody padding="0" margin="0" maxHeight="90%">
          <Box
            w="100%"
            display="flex"
            flexDir="row"
            justifyContent="space-around"
            textAlign="center"
            fontSize="17px"
            maxHeight="95%"
          >
            <chakra.img
              src={imgUrl}
              __css={styles.img}
              border="1px solid"
              borderRadius="10px"
              borderColor="gray.450"
              sx={{
                width: "46rem",
                height: "auto",
                maxHeight: "100%",
                position: "relative",
              }}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

type DownloadScannerAppModalProps = {
  isOpen: boolean;
  onClose(): void;
  refresh(): void;
};

function DownloadScannerAppModal(props: DownloadScannerAppModalProps) {
  const { isOpen, onClose, refresh } = props;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="295px">
        <ModalBody padding="0" margin="0">
          <Box
            padding="1rem"
            display="flex"
            flexDir="column"
            justifyContent="center"
            textAlign="center"
          >
            <chakra.span fontWeight="600" fontSize="17px">
              Scanner not Detected
            </chakra.span>
            <chakra.span fontSize="13px">
              You must download the app to connect to the scanner
            </chakra.span>

            <Box marginTop="1rem" display="flex" flexDir="column">
              <chakra.span fontSize="14px">
                Already got the scanner app?
              </chakra.span>
              <chakra.a
                onClick={refresh}
                fontSize="14px"
                color="blue"
                textDecor="underline"
                cursor="pointer"
                userSelect="none"
              >
                Click here to refresh the connection
              </chakra.a>
            </Box>
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDir="row"
            borderTop="1px solid"
            borderColor="gray.450"
            justifyContent="space-around"
            textAlign="center"
            fontSize="17px"
          >
            <chakra.span
              color="gray.700"
              cursor="pointer"
              borderRight="1px solid"
              borderColor="gray.450"
              p="0.45rem"
              w="50%"
              onClick={onClose}
            >
              Cancel
            </chakra.span>
            <chakra.a
              color="blue"
              cursor="pointer"
              p="0.45rem"
              w="50%"
              href={EMR_SCANNER_APP_LINK}
              target="_blank"
            >
              Download
            </chakra.a>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

type ImageStatus = {
  error: boolean;
  loaded: boolean;
  loading: boolean;
};

const INITIAL_IMAGE_STATUS: ImageStatus = {
  error: false,
  loaded: false,
  loading: false,
};

function ImagePickerContent(props: ImagePickerContentProps) {
  const { label = "Click here to upload image" } = props;
  const toast = useToast();
  const { imgUrl, isLoading, setValue, formControl, inputRef, variant } =
    useImagePickerContext();
  const styles = useMultiStyleConfig("ImagePicker", { variant });
  const {
    scan,
    isLoading: isScannerLoading,
    refresh,
    isScannerDetected,
    data: scanned,
  } = useScanner();

  const downloadScannerAppModalDisclosure = useDisclosure();

  const [status, setStatus] = React.useState(INITIAL_IMAGE_STATUS);

  /**
   * Trigger file selector when pressing enter or space
   * keys.
   */
  const onLabelKeypress: React.KeyboardEventHandler<HTMLLabelElement> = (e) => {
    const { key } = e;
    if (key === " " || key === "Enter") {
      e.currentTarget.click();
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files && files.length > 0) {
      const image = files[0];

      if (image.size > constants.MAX_FILE_SIZE) {
        toast({
          description: `Files must be smaller than ${constants.MAX_FILE_SIZE_IN_MB}MB`,
        });
        return;
      }
      setValue(image);
    }
  };

  React.useEffect(() => {
    if (imgUrl) setStatus(INITIAL_IMAGE_STATUS);
  }, [imgUrl]);

  React.useEffect(() => {
    if (scanned.length > 0 && !formControl.disabled && !isScannerLoading) {
      setValue(scanned[0]);
    }
    /** Tracks down only the loading state to avoid undesired infinite loops due to the scanner/picker approach */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScannerLoading]);

  const getContent = () => {
    if (isLoading) {
      return <Spinner color="blue" />;
    }

    if (imgUrl) {
      // the image display inside of container
      return <chakra.img src={imgUrl} __css={styles.img} />;
    }

    if (variant === "round")
      return (
        <chakra.span textAlign="center" color="gray.550">
          <Avatar bg="gray.450" w="100%" h="100%">
            <AvatarBadge top="-5px" right="-5px" bottom="auto">
              <Flex
                h="40px"
                w="40px"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  position="relative"
                  borderRadius="50%"
                  h="22px"
                  w="22px"
                  bg="green"
                >
                  <Icon
                    as={EmrPlus}
                    color="white"
                    position="absolute"
                    top="calc(50% - 5.5px)"
                    left="calc(50% - 5.5px)"
                    h="11px"
                    w="11px"
                  />
                </Box>
              </Flex>
            </AvatarBadge>
          </Avatar>
          {formControl.required && <chakra.span color="red">*</chakra.span>}
        </chakra.span>
      );

    return (
      <chakra.span textAlign="center" color="gray.550">
        {label}
      </chakra.span>
    );
  };

  if (variant === "scanner") {
    if (isLoading || status.loading) {
      return <Spinner color="blue" />;
    }

    if (!!imgUrl)
      return (
        <chakra.label
          htmlFor={formControl.id}
          __css={styles.imageContainer}
          tabIndex={0}
          onKeyPress={onLabelKeypress}
          aria-disabled={formControl.disabled}
          aria-label="image picker"
        >
          <chakra.img
            src={imgUrl}
            display={status.loaded ? "block" : "none"}
            __css={styles.img}
            onLoad={() =>
              setStatus({
                ...INITIAL_IMAGE_STATUS,
                loaded: true,
                loading: false,
              })
            }
            onLoadStart={() =>
              setStatus({
                ...INITIAL_IMAGE_STATUS,
                loading: true,
              })
            }
            onError={() =>
              setStatus({
                ...INITIAL_IMAGE_STATUS,
                loaded: true,
                error: true,
              })
            }
          />
        </chakra.label>
      );

    return (
      <Box __css={styles.scannerContainer} tabIndex={0} gap="4.375rem">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray.700"
            borderRadius="50%"
            width="3.75rem"
            height="3.75rem"
            _hover={{
              cursor: "pointer",
            }}
            onClick={
              isScannerDetected
                ? scan
                : downloadScannerAppModalDisclosure.onOpen
            }
          >
            {isScannerLoading ? (
              <Spinner color="white" />
            ) : (
              <Icon as={Scanner} w="24px" h="19.12px;" color="white" />
            )}
          </Box>

          <chakra.span color="gray.700">Scan</chakra.span>
        </Box>

        <chakra.label
          display="flex"
          flexDirection="column"
          alignItems="center"
          htmlFor={formControl.id}
          tabIndex={0}
          onKeyPress={onLabelKeypress}
          aria-disabled={formControl.disabled}
          aria-label="image picker"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray.700"
            borderRadius="50%"
            width="3.75rem"
            height="3.75rem"
            _hover={{
              cursor: "pointer",
            }}
          >
            <Icon as={Import} w="24px" h="29.19px" color="white" />
          </Box>
          <chakra.span color="gray.700">Import</chakra.span>
          <Input
            key={imgUrl}
            type="file"
            display="none"
            accept="image/*"
            onChange={handleChange}
            ref={inputRef}
            {...formControl}
          />
        </chakra.label>

        <DownloadScannerAppModal
          isOpen={downloadScannerAppModalDisclosure.isOpen}
          onClose={downloadScannerAppModalDisclosure.onClose}
          refresh={refresh}
        />
      </Box>
    );
  }
  return (
    <chakra.label
      htmlFor={formControl.id}
      __css={styles.imageContainer}
      tabIndex={0}
      onKeyPress={onLabelKeypress}
      aria-disabled={formControl.disabled}
      aria-label="image picker"
    >
      {getContent()}
      <Input
        key={imgUrl}
        type="file"
        display="none"
        accept="image/*"
        onChange={handleChange}
        ref={inputRef}
        {...formControl}
      />
    </chakra.label>
  );
}

if (config.isDev) {
  ImagePickerContent.displayName = "ImagePickerContent";
}

const ImagePicker = React.forwardRef<HTMLInputElement, ImagePickerProps>(
  (props, ref) => {
    const {
      value: valueProp,
      variant = "default",
      defaultValue,
      setValue: setValueProp,
      expandLabel = "",
      showExpand = false,
      onDelete,
      isLoading,
      children,
      ...inputProps
    } = props;
    const [value, setValue] = useControllableState({
      defaultValue,
      value: valueProp,
      onChange: setValueProp,
    });

    const formControl = useFormControl(inputProps);
    const imgUrl = React.useMemo(() => getImgSrc(value), [value]);

    const contextValue = React.useMemo<ImagePickerContextValue>(
      () => ({
        imgUrl,
        isLoading,
        expandLabel,
        showExpand,
        onDelete,
        setValue,
        value,
        variant,
        formControl,
        inputRef: ref,
      }),
      [
        imgUrl,
        isLoading,
        expandLabel,
        showExpand,
        onDelete,
        setValue,
        value,
        variant,
        formControl,
        ref,
      ]
    );

    /**
     * Revoke the previous object url every time the img
     * changes.
     */
    React.useEffect(() => {
      return () => {
        if (imgUrl) {
          URL.revokeObjectURL(imgUrl);
        }
      };
    }, [imgUrl]);

    return (
      <ImagePickerContext.Provider value={contextValue}>
        {variant === "round" ? (
          <Box position="relative">{children}</Box>
        ) : (
          <VStack spacing="1rem">{children}</VStack>
        )}
      </ImagePickerContext.Provider>
    );
  }
);

if (config.isDev) {
  ImagePicker.displayName = "ImagePicker";
}

export type { ImagePickerProps };
export {
  ImagePicker,
  ImagePickerActions,
  ImagePickerContent,
  DownloadScannerAppModal,
};
