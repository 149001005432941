import * as React from "react";

import {
  ActionScopes,
  Auth0Permissions,
  ChartScopes,
  ModulesScopes,
} from "types";

import { Buffer } from "buffer";
import { useAuth0 } from "@auth0/auth0-react";

type PermissionsContextValue = {
  isLoadingPermissions: boolean;
  scope(s: ModulesScopes | ActionScopes | ChartScopes): {
    isAccessible: boolean;
    isEditable: boolean;
  };
  claims: Record<string, unknown>;
};

const PermissionsContext = React.createContext<PermissionsContextValue | null>(
  null
);

function PermissionsProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const { getAccessTokenSilently, isLoading } = useAuth0();

  const [permissions, setPermissions] = React.useState<Auth0Permissions[]>([]);
  const [claims, setClaims] = React.useState<Record<string, unknown>>({});
  const [isGeneratingPermissions, setIsGeneratingPermissions] =
    React.useState<boolean>(true);

  const isLoadingPermissions = isLoading || isGeneratingPermissions;

  const generatePermissions = React.useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const tokenParts = token.split(".");
      const decrypted = JSON.parse(
        Buffer.from(
          tokenParts.length > 1 ? tokenParts[1] : "" || "",
          "base64"
        ).toString()
      );

      setPermissions(decrypted?.permissions);

      const decryptedClaims: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(decrypted)) {
        if (key.includes("claims"))
          decryptedClaims[key.split("/").pop() || ""] = value;
      }

      setClaims(decryptedClaims);
      setIsGeneratingPermissions(false);
    } catch (_error) {
      setIsGeneratingPermissions(false);
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    generatePermissions();
  }, [generatePermissions]);

  const scope: PermissionsContextValue["scope"] = React.useCallback(
    (s) => {
      return {
        isAccessible:
          permissions.includes(`${s}:view` as Auth0Permissions) ||
          permissions.includes(`${s}:edit` as Auth0Permissions) ||
          permissions.includes(`${s}:commenter` as Auth0Permissions) ||
          permissions.includes(`${s}:auditor` as Auth0Permissions) ||
          permissions.includes(s as Auth0Permissions) ||
          permissions.includes(`${s}:adddelete` as Auth0Permissions),
        isEditable:
          permissions.includes(`${s}:edit` as Auth0Permissions) ||
          permissions.includes(`${s}:auditor` as Auth0Permissions),
      };
    },
    [permissions]
  );

  const value: PermissionsContextValue = React.useMemo(
    () => ({
      isLoadingPermissions,
      scope,
      claims,
    }),
    [isLoadingPermissions, scope, claims]
  );

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
}

function usePermissions() {
  const context = React.useContext(PermissionsContext);

  if (context === null) {
    throw new Error(
      "usePermissions must be used within an PermissionsProvider"
    );
  }

  return context;
}

export { PermissionsProvider, usePermissions };
