import { useAxiosMutation } from "hooks";
import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";

function useDeleteEncounter(encounterId: string, encounters: string[]) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounter", { encounterId }],
    () =>
      axiosPatientInstance.delete(
        `v1/Encounter/${encounterId}/delete-encounter`
      ),
    {
      onSuccess: () => {
        encounters.forEach((eId) => {
          queryClient.invalidateQueries([
            "patientEncounters",
            { encounterId: eId },
          ]);
        });
      },
    }
  );
}

export { useDeleteEncounter };
