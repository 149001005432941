import { mapAnswersListToFormObject } from "modules";
import {
  mapFormObjectToAnswersList,
  EKGForm,
  EKGSection,
} from "modules/charts-shared";
import { ChartEkgFormSubsectionResponse, EKGPayloadSection } from "../api";

function mapSectionToPayload({
  code,
  createdById,
  order,
  orderId,
  signature,
  ...answers
}: EKGSection): EKGPayloadSection {
  return {
    code,
    order,
    orderId,
    answers: mapFormObjectToAnswersList(answers),
  };
}

function formToPayload(form: EKGForm): EKGPayloadSection[] {
  const { ekg } = form;
  return ekg?.map(mapSectionToPayload) || [];
}

function responseToForm(payload: ChartEkgFormSubsectionResponse[]) {
  return {
    ekg: payload.map(({ id, code, order, orderId, answers, signature }) => ({
      id,
      code,
      order,
      orderId,
      signature,
      ...mapAnswersListToFormObject(answers),
    })),
  };
}

export { formToPayload, responseToForm };
