import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { ProviderChartCode } from "enums";
import {
  ChartRow,
  ChartRowElement,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { usePermissions } from "contexts";

const CHART_CODE: ProviderChartCode = "C006x";
const SECTION_CODE = "SCLI";

function T06ClinicalImpression() {
  const {
    getIsLoading,
    encounterId,
    onSubmit,
    updateIsLoading,
    useFormContext,
  } = useChartForm({
    chartCode: CHART_CODE,
    sectionId: SECTION_CODE,
    sectionLabel: "Clinical Impression",
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection onSubmit={onSubmit} disabled={isReadOnly}>
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={CHART_CODE}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={updateIsLoading}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="subsequent"
                  name="Q001.C006xSCLIQ001A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sequelae"
                  name="Q001.C006xSCLIQ001A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A023"
                  label="(**R / L; FB; specify anatomy)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Laceration:"
                  name="Q002.C006xSCLIQ002A001"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Abrasion:"
                  name="Q002.C006xSCLIQ002A003"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A004" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Contusion:"
                  name="Q002.C006xSCLIQ002A005"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A024"
                  label="Burn:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thermal"
                  name="Q002.C006xSCLIQ002A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="electrical"
                  name="Q002.C006xSCLIQ002A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chemical"
                  name="Q002.C006xSCLIQ002A009"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="accident"
                  name="Q002.C006xSCLIQ002A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="self-harm"
                  name="Q002.C006xSCLIQ002A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="assault"
                  name="Q002.C006xSCLIQ002A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="unk"
                  name="Q002.C006xSCLIQ002A014"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A015" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A025"
                  label="1 st, area:"
                  width="80px"
                />
                <ChartRowElement
                  type="input"
                  width="150px"
                  name="Q002.C006xSCLIQ002A016"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A026"
                  label=";"
                />
                <ChartRowElement
                  type="input"
                  width="120px"
                  name="Q002.C006xSCLIQ002A017"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A027"
                  label="%"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A028"
                  label="2 nd, area:"
                  width="80px"
                />
                <ChartRowElement
                  type="input"
                  width="150px"
                  name="Q002.C006xSCLIQ002A018"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A029"
                  label=";"
                />
                <ChartRowElement
                  type="input"
                  width="120px"
                  name="Q002.C006xSCLIQ002A019"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A030"
                  label="%"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A031"
                  label="3 rd, area:"
                  width="80px"
                />
                <ChartRowElement
                  type="input"
                  width="150px"
                  name="Q002.C006xSCLIQ002A020"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A032"
                  label=";"
                />
                <ChartRowElement
                  type="input"
                  width="120px"
                  name="Q002.C006xSCLIQ002A021"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A033"
                  label="%"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q002.C006xSCLIQ002A034"
                  label="Total BSA="
                  marginLeft="120px"
                />
                <ChartRowElement type="input" name="Q002.C006xSCLIQ002A022" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q003.C006xSCLIQ003A001"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q003.C006xSCLIQ003A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A032"
                  label="Shoulder:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="trapezius"
                  name="Q003.C006xSCLIQ003A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="deltoid"
                  name="Q003.C006xSCLIQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="pec major"
                  name="Q003.C006xSCLIQ003A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="rotator cuff"
                  name="Q003.C006xSCLIQ003A06"
                />
                <ChartRowElement type="input" name="Q003.C006xSCLIQ003A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A033"
                  label="Up-arm:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="triceps"
                  name="Q003.C006xSCLIQ003A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="biceps"
                  name="Q003.C006xSCLIQ003A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="long"
                  name="Q003.C006xSCLIQ003A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="short"
                  name="Q003.C006xSCLIQ003A011"
                />
                <ChartRowElement type="input" name="Q003.C006xSCLIQ003A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A034"
                  label="Forearm:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="FCR"
                  name="Q003.C006xSCLIQ003A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="FCU"
                  name="Q003.C006xSCLIQ003A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="brachioradialis"
                  name="Q003.C006xSCLIQ003A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ext digitorum"
                  name="Q003.C006xSCLIQ003A016"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A035"
                  label="flexor:"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A036"
                  label="to"
                />
                <ChartRowElement
                  type="cross-option"
                  label="1st"
                  name="Q003.C006xSCLIQ003A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="2nd"
                  name="Q003.C006xSCLIQ003A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="3rd"
                  name="Q003.C006xSCLIQ003A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="4th"
                  name="Q003.C006xSCLIQ003A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="5th"
                  name="Q003.C006xSCLIQ003A021"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A037"
                  label="extensor:"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A038"
                  label="to"
                />
                <ChartRowElement
                  type="cross-option"
                  label="1st"
                  name="Q003.C006xSCLIQ003A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="2nd"
                  name="Q003.C006xSCLIQ003A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="3rd"
                  name="Q003.C006xSCLIQ003A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="4th"
                  name="Q003.C006xSCLIQ003A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="5th"
                  name="Q003.C006xSCLIQ003A026"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q003.C006xSCLIQ003A039"
                  label="hand intrinsic:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="1st"
                  name="Q003.C006xSCLIQ003A027"
                />
                <ChartRowElement
                  type="cross-option"
                  label="2nd"
                  name="Q003.C006xSCLIQ003A028"
                />
                <ChartRowElement
                  type="cross-option"
                  label="3rd"
                  name="Q003.C006xSCLIQ003A029"
                />
                <ChartRowElement
                  type="cross-option"
                  label="4th"
                  name="Q003.C006xSCLIQ003A030"
                />
                <ChartRowElement
                  type="cross-option"
                  label="5th"
                  name="Q003.C006xSCLIQ003A031"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q004.C006xSCLIQ004A001"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q004.C006xSCLIQ004A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C006xSCLIQ004A024"
                  label="Shoulder:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="AC Joint"
                  name="Q004.C006xSCLIQ004A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="rotator cuff"
                  name="Q004.C006xSCLIQ004A004"
                />
                <ChartRowElement type="input" name="Q004.C006xSCLIQ004A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C006xSCLIQ004A025"
                  label="Elbow:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="radial collat"
                  name="Q004.C006xSCLIQ004A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ulnar collat"
                  name="Q004.C006xSCLIQ004A007"
                />
                <ChartRowElement type="input" name="Q004.C006xSCLIQ004A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C006xSCLIQ004A026"
                  label="Wrist:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="radiocarpal joint"
                  name="Q004.C006xSCLIQ004A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="carpal"
                  name="Q004.C006xSCLIQ004A010"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C006xSCLIQ004A027"
                  label="MCP:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="1st"
                  name="Q004.C006xSCLIQ004A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="2nd"
                  name="Q004.C006xSCLIQ004A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="3rd"
                  name="Q004.C006xSCLIQ004A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="4th"
                  name="Q004.C006xSCLIQ004A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="5th"
                  name="Q004.C006xSCLIQ004A015"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="IP"
                  name="Q004.C006xSCLIQ004A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="PIP"
                  name="Q004.C006xSCLIQ004A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="DIP"
                  name="Q004.C006xSCLIQ004A018"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C006xSCLIQ004A028"
                  label=":"
                />
                <ChartRowElement
                  type="cross-option"
                  label="1st"
                  name="Q004.C006xSCLIQ004A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="2nd"
                  name="Q004.C006xSCLIQ004A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="3rd"
                  name="Q004.C006xSCLIQ004A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="4th"
                  name="Q004.C006xSCLIQ004A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="5th"
                  name="Q004.C006xSCLIQ004A023"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q005.C006xSCLIQ005A006"
                  label="(See Xrays)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Fracture:"
                  name="Q005.C006xSCLIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="closed"
                  name="Q005.C006xSCLIQ005A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="open"
                  name="Q005.C006xSCLIQ005A003"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Dislocation"
                  name="Q005.C006xSCLIQ005A004"
                />
                <ChartRowElement type="input" name="Q005.C006xSCLIQ005A005" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q006"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q006.C006xSCLIQ006A010"
                  label="Nerve Injury:"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q006.C006xSCLIQ006A001"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q006.C006xSCLIQ006A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="radial"
                  name="Q006.C006xSCLIQ006A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ulnar"
                  name="Q006.C006xSCLIQ006A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="median"
                  name="Q006.C006xSCLIQ006A005"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q006.C006xSCLIQ006A011"
                  label="at:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="up-arm"
                  name="Q006.C006xSCLIQ006A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="f-arm"
                  name="Q006.C006xSCLIQ006A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q006.C006xSCLIQ006A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q006.C006xSCLIQ006A009"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q007"
              subsectionLabel="Vascular"
            >
              Vascular
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q007.C006xSCLIQ007A014"
                  label="Lacerated:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="artery"
                  name="Q007.C006xSCLIQ007A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="vein"
                  name="Q007.C006xSCLIQ007A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q007.C006xSCLIQ007A003"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q007.C006xSCLIQ007A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="brachial"
                  name="Q007.C006xSCLIQ007A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="radial"
                  name="Q007.C006xSCLIQ007A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ulnar"
                  name="Q007.C006xSCLIQ007A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="cephalic"
                  name="Q007.C006xSCLIQ007A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="basilic"
                  name="Q007.C006xSCLIQ007A009"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q007.C006xSCLIQ007A015"
                  label="at:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="up-arm"
                  name="Q007.C006xSCLIQ007A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="f-arm"
                  name="Q007.C006xSCLIQ007A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q007.C006xSCLIQ007A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q007.C006xSCLIQ007A013"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q008"
              subsectionLabel="Tendon"
            >
              Tendon
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q008.C006xSCLIQ008A002"
                  label="(See P.E)"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Tendon injury"
                  name="Q008.C006xSCLIQ008A001"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q009"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Hypokalemia"
                  name="Q009.C006xSCLIQ009A001"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Leukocytosis"
                  name="Q009.C006xSCLIQ009A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { T06ClinicalImpression };
