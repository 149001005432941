import { Box, Divider, Icon, useToast } from "@chakra-ui/react";
import { PillsBottle, SquaredChecked } from "icons";
import { formMessages } from "messages";
import { ChartSection, SharedChartSectionHeader } from "modules";
import {
  ChartRouteBaseParams,
  TriageIVMedicationForm,
  nextRoutesNurse,
  nextRoutesProvider,
  TriageIVMedicationSection,
} from "modules/charts-shared";
import { OrderHeaderCard } from "modules/orders";
import {
  useIvAndMedication,
  useUpdateIvAndMedication,
} from "modules/triage/api";
import {
  formToPayload,
  responseToForm,
} from "modules/triage/utils/iv-medications";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { IvAndMedicationsColumn } from "./IvAndMedicationColumn";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";

// const SECTION_CODE = "SIVM";

type MedsAndIvTypes = "ivStarts" | "ivMedications" | "medications";

type IvAndMedicationsFiltersProps = {
  filter: MedsAndIvTypes | null;
  setFilter: (filter: MedsAndIvTypes | null) => void;
};

function IvAndMedicationsFilters({
  filter,
  setFilter,
}: IvAndMedicationsFiltersProps) {
  const baseFilterStyle = {
    bgColor: "#EEEEEF",
    borderRadius: "10px",
    color: "#595959",
    minW: "320px",
    ":hover": {
      color: "white",
    },
  };

  const handleClick = (
    currentFilter: "ivStarts" | "ivMedications" | "medications"
  ) => {
    setFilter(currentFilter === filter ? null : currentFilter);
  };

  return (
    <Box w="100%" display="flex" justifyContent="center" gap={4}>
      <Button
        isActive={filter === "ivStarts"}
        _active={{ color: "white", bgColor: "blue" }}
        sx={{
          ...baseFilterStyle,
        }}
        onClick={() => handleClick("ivStarts")}
      >
        IV Pushes
      </Button>
      <Button
        isActive={filter === "ivMedications"}
        _active={{ color: "white", bgColor: "blue" }}
        onClick={() => handleClick("ivMedications")}
        sx={{
          ...baseFilterStyle,
        }}
      >
        IV / Medication Infusions
      </Button>
      <Button
        isActive={filter === "medications"}
        _active={{ color: "white", bgColor: "blue" }}
        onClick={() => handleClick("medications")}
        sx={{
          ...baseFilterStyle,
        }}
      >
        Medications
      </Button>
    </Box>
  );
}

function IvAndMedications(props: { chartCode: string; isReadOnly?: boolean }) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();
  const useFormContext = useForm<TriageIVMedicationForm>({
    defaultValues: {
      ivMedications: [],
      ivStarts: [],
      medications: [],
      treatments: [],
    },
  });
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const { data: ivMedicationData, isLoading: getIsLoading } =
    useIvAndMedication(encounterId);
  const { mutateAsync: updateIvMedication, isLoading: updateIsLoading } =
    useUpdateIvAndMedication(encounterId);
  const { chartCode, isReadOnly } = props;

  const [filter, setFilter] = useState<MedsAndIvTypes | null>(null);
  const [nextRoute, setNextRoute] = useState<string | null>(null);

  useEffect(() => {
    if (ivMedicationData) {
      reset(responseToForm(ivMedicationData.data));
    }
  }, [ivMedicationData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  if (getIsLoading) {
    return <Loading />;
  }

  const ivStarts =
    filter === null || filter === "ivStarts" ? watch("ivStarts") : [];
  const ivMedications =
    filter === null || filter === "ivMedications" ? watch("ivMedications") : [];
  const medications =
    filter === null || filter === "medications" ? watch("medications") : [];

  const onSubmit = async (formData: TriageIVMedicationForm) => {
    const payload = formToPayload(formData);
    try {
      await updateIvMedication(payload);
      toast({ description: formMessages.updateSuccess("IV and Medications") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleSaveAndNext = () => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  };

  const filteredItems: (TriageIVMedicationSection & { index: number })[] = [
    ...ivStarts.map((x, index) => ({
      ...x,
      index,
    })),
    ...ivMedications.map((x, index) => ({
      ...x,
      index,
    })),
    ...medications.map((x, index) => ({
      ...x,
      index,
    })),
  ];

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        minWidth="100%"
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<PillsBottle />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Meds & IV {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>

        <IvAndMedicationsFilters filter={filter} setFilter={setFilter} />

        <Box w="100%" h="90vh" display="flex" justifyContent="space-between">
          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Ordered"
                icon={<Icon as={EmrRegistration} />}
                bg="orange"
                counter={
                  filteredItems.filter((x) => x.status === "Ordered").length
                }
              />
            }
            status="Ordered"
            items={filteredItems.filter((x) => x.status === "Ordered")}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" mt="80px" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="In Process"
                icon={<Icon as={EmrInProcess} />}
                bg="blue"
                counter={
                  filteredItems.filter((x) => x.status === "InProcess").length
                }
              />
            }
            status="InProcess"
            items={filteredItems.filter((x) => x.status === "InProcess")}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" mt="80px" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Completed"
                icon={<Icon as={EmrInProcess} />}
                bg="green"
                counter={
                  filteredItems.filter((x) => x.status === "Completed").length
                }
              />
            }
            status="Completed"
            items={filteredItems.filter((x) => x.status === "Completed")}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" mt="80px" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Administered"
                icon={<Icon as={SquaredChecked} />}
                bg="purple"
                counter={
                  filteredItems.filter((x) => x.status === "Administered")
                    .length
                }
              />
            }
            status="Administered"
            items={filteredItems.filter((x) => x.status === "Administered")}
            onSave={handleSubmit(onSubmit)}
          />
        </Box>
      </ChartSection>
    </FormProvider>
  );
}

export { IvAndMedications };
