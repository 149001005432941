import * as React from "react";
import { Loading } from "shared";
import { ModalBody, ModalBodyProps, VStack } from "@chakra-ui/react";

type STPVipNotesModalBodyProps = {
  isLoading?: boolean;
} & ModalBodyProps;

const STPVipNotesModalBody = React.forwardRef<
  HTMLDivElement,
  STPVipNotesModalBodyProps
>((props, ref) => {
  const { isLoading, children, ...modalBodyProps } = props;

  return (
    <ModalBody
      flex="1"
      overflow="auto"
      padding="0 20px"
      margin="22px 0"
      ref={ref}
      {...modalBodyProps}
    >
      {isLoading && <Loading />}
      <VStack spacing="1.25rem" paddingBottom="1rem">
        {children}
      </VStack>
    </ModalBody>
  );
});

STPVipNotesModalBody.displayName = "STPVipNotesModalBody";

export type { STPVipNotesModalBodyProps };
export { STPVipNotesModalBody };
