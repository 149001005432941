import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { useChartForm } from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared/loading";

const sectionId = "SCHC";
const chartCode = "T032x";

function N32ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="started:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A041`}
                />
                <ChartRowElement
                  label="just PTA"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pelvic pain"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="contractions"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="every"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="min"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="vaginal bleeding"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="leakage of fluid"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gest. age:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A042`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="weeks"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="months"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="LMNP:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A043`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="EDC:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A044`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="G:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A045`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="P:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A046`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="Ab:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A047`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="prenatal care:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A048`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="clinic"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="MD"
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NP"
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="problems:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A049`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gest. HTN"
                  name={`Q001.${chartCode}${sectionId}Q001A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gest. diabetes"
                  name={`Q001.${chartCode}${sectionId}Q001A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pre-eclampsia"
                  name={`Q001.${chartCode}${sectionId}Q001A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q001.${chartCode}${sectionId}Q001A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A040`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N32ChiefComplaint };
