import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C031x";

function T31HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions } = useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficulty breathing"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wheezing"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chest pain"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chest discomfort"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="sudden onset"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="gradual onset"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="still present"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="constant"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="‘waxing & waning'"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="better"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="worse"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lasted:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="intermittent episodes lasting:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="worse"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="since:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Initiating Event"
            >
              Initiating Event
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="upper respiratory illness"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="out of meds"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="excercise"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="choking"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="allergy to:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="exposure:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                />
                <ChartRowElement
                  label="smoke"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mold"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="emotionally upset"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="onset during:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                />
                <ChartRowElement
                  label="sleep"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="rest"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="emotional upset"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="activity / exertion"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent flight"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="maximum:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="6"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="7"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="8"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="9"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="10"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="currently:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="6"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="7"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="8"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="9"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="10"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Quality"
            >
              Quality
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="pressure"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tightness"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="indigestion"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="burning"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dull"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aching"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sharp"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="stabbing"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="llike prior MI"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="radiating to:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                />
                <ChartRowElement
                  label="arm"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="back"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="jaw"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Worsened by"
            >
              Worsened by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="nothing"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="deep breaths"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="coughing"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="exertion"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="movement"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="change in position"
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="laying flat"
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Relieved by"
            >
              Relieved by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="nothing"
                  name={`Q010.${chartCode}${sectionId}Q010A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sitting up"
                  name={`Q010.${chartCode}${sectionId}Q010A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="rest"
                  name={`Q010.${chartCode}${sectionId}Q010A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="antacids"
                  name={`Q010.${chartCode}${sectionId}Q010A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nitroglycerin"
                  name={`Q010.${chartCode}${sectionId}Q010A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="O2"
                  name={`Q010.${chartCode}${sectionId}Q010A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspirin"
                  name={`Q010.${chartCode}${sectionId}Q010A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="fever"
                  name={`Q011.${chartCode}${sectionId}Q011A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chills"
                  name={`Q011.${chartCode}${sectionId}Q011A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sweating"
                  name={`Q011.${chartCode}${sectionId}Q011A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="cough:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A068`}
                />
                <ChartRowElement
                  label="bloody"
                  name={`Q011.${chartCode}${sectionId}Q011A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="productive"
                  name={`Q011.${chartCode}${sectionId}Q011A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="loose"
                  name={`Q011.${chartCode}${sectionId}Q011A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="barking"
                  name={`Q011.${chartCode}${sectionId}Q011A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hacking"
                  name={`Q011.${chartCode}${sectionId}Q011A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="paroxysmal"
                  name={`Q011.${chartCode}${sectionId}Q011A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="runny nose:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A069`}
                />
                <ChartRowElement
                  label="yellow"
                  name={`Q011.${chartCode}${sectionId}Q011A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="green"
                  name={`Q011.${chartCode}${sectionId}Q011A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="clear"
                  name={`Q011.${chartCode}${sectionId}Q011A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="drainage"
                  name={`Q011.${chartCode}${sectionId}Q011A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chest pain"
                  name={`Q011.${chartCode}${sectionId}Q011A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chest discomfort"
                  name={`Q011.${chartCode}${sectionId}Q011A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="central"
                  name={`Q011.${chartCode}${sectionId}Q011A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pain"
                  name={`Q011.${chartCode}${sectionId}Q011A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pressure"
                  name={`Q011.${chartCode}${sectionId}Q011A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tightness"
                  name={`Q011.${chartCode}${sectionId}Q011A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="constant"
                  name={`Q011.${chartCode}${sectionId}Q011A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q011.${chartCode}${sectionId}Q011A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A070`}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A027`}
                  w="278px"
                  type="input"
                />
                <ChartRowElement
                  label="radiation:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A071`}
                />
                <ChartRowElement
                  label="back"
                  name={`Q011.${chartCode}${sectionId}Q011A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="jaw"
                  name={`Q011.${chartCode}${sectionId}Q011A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q011.${chartCode}${sectionId}Q011A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q011.${chartCode}${sectionId}Q011A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hurts to breath"
                  name={`Q011.${chartCode}${sectionId}Q011A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="heart racing"
                  name={`Q011.${chartCode}${sectionId}Q011A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="palpitations"
                  name={`Q011.${chartCode}${sectionId}Q011A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="leg pain"
                  name={`Q011.${chartCode}${sectionId}Q011A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="calf pain"
                  name={`Q011.${chartCode}${sectionId}Q011A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ankle swelling"
                  name={`Q011.${chartCode}${sectionId}Q011A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg swelling"
                  name={`Q011.${chartCode}${sectionId}Q011A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A045`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A046`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="dizziness"
                  name={`Q011.${chartCode}${sectionId}Q011A047`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="light-headedness"
                  name={`Q011.${chartCode}${sectionId}Q011A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="anxiety"
                  name={`Q011.${chartCode}${sectionId}Q011A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="tingling"
                  name={`Q011.${chartCode}${sectionId}Q011A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hands"
                  name={`Q011.${chartCode}${sectionId}Q011A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="feet"
                  name={`Q011.${chartCode}${sectionId}Q011A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A056`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="weakness"
                  name={`Q011.${chartCode}${sectionId}Q011A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A059`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A060`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="muscle spasms"
                  name={`Q011.${chartCode}${sectionId}Q011A061`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A062`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A063`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hands"
                  name={`Q011.${chartCode}${sectionId}Q011A064`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="feet"
                  name={`Q011.${chartCode}${sectionId}Q011A065`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A066`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A072`}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A067`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Current Therapy"
            >
              Current Therapy
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="none"
                  name={`Q012.${chartCode}${sectionId}Q012A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="inhaled:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A031`}
                />
                <ChartRowElement
                  label="neb"
                  name={`Q012.${chartCode}${sectionId}Q012A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="MDI"
                  name={`Q012.${chartCode}${sectionId}Q012A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="albuterol"
                  name={`Q012.${chartCode}${sectionId}Q012A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="inhaler"
                  name={`Q012.${chartCode}${sectionId}Q012A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="uses spacer"
                  name={`Q012.${chartCode}${sectionId}Q012A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="nebulizer"
                  name={`Q012.${chartCode}${sectionId}Q012A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="steroid"
                  name={`Q012.${chartCode}${sectionId}Q012A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ipratropium"
                  name={`Q012.${chartCode}${sectionId}Q012A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="xopenex"
                  name={`Q012.${chartCode}${sectionId}Q012A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="primatene (OTC)"
                  name={`Q012.${chartCode}${sectionId}Q012A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Home Peak Flows:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A032`}
                />
                <ChartRowElement
                  label="measured"
                  name={`Q012.${chartCode}${sectionId}Q012A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="usual:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A033`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A022`}
                  w="550.5px"
                  type="input"
                />
                <ChartRowElement
                  label="latest:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A034`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="oral:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A035`}
                />
                <ChartRowElement
                  label="prednisone"
                  name={`Q012.${chartCode}${sectionId}Q012A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="prednisolone"
                  name={`Q012.${chartCode}${sectionId}Q012A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PRN"
                  name={`Q012.${chartCode}${sectionId}Q012A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="taking brief course"
                  name={`Q012.${chartCode}${sectionId}Q012A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tapering"
                  name={`Q012.${chartCode}${sectionId}Q012A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="maintenance"
                  name={`Q012.${chartCode}${sectionId}Q012A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="current mg / day:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A036`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A030`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  name={`Q013.${chartCode}${sectionId}Q013A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q013.${chartCode}${sectionId}Q013A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  name={`Q013.${chartCode}${sectionId}Q013A008`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q013.${chartCode}${sectionId}Q013A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q013.${chartCode}${sectionId}Q013A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q013.${chartCode}${sectionId}Q013A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q013.${chartCode}${sectionId}Q013A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q013.${chartCode}${sectionId}Q013A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T31HPI };
