import React, { forwardRef } from "react";
import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

type CardProps = {
  variant?: "smooth" | "rounded" | "validator" | "basic" | "default";
} & BoxProps;

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, variant, ...props }, ref) => {
    const styles = useStyleConfig("Card", { variant });

    return (
      <Box ref={ref} __css={styles} {...props}>
        {children}
      </Box>
    );
  }
);
Card.displayName = "Card";

export type { CardProps };
export { Card };
