import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const OrderQueue = createIcon({
  path: (
    <path
      d="M3.19593 17.374C1.0687 17.374 0 16.3257 0 14.2188V8.60051C0 7.68448 0.152672 7.257 0.580153 6.66667L4.10178 1.91349C5.2112 0.396947 5.8117 0 7.62341 0H16.3766C18.1883 0 18.799 0.396947 19.9084 1.91349L23.4198 6.66667C23.8575 7.257 24 7.68448 24 8.60051V14.2188C24 16.3155 22.9415 17.374 20.8041 17.374H3.19593ZM12 11.4809C13.5369 11.4809 14.514 10.1578 14.514 8.90585V8.87532C14.514 8.4173 14.7786 7.98982 15.3486 7.98982H21.8321C22.1679 7.98982 22.229 7.70483 22.0763 7.48092L18.3104 2.31043C17.8422 1.65903 17.2417 1.42494 16.4987 1.42494H7.50127C6.75827 1.42494 6.15776 1.65903 5.68957 2.31043L1.92366 7.48092C1.76081 7.70483 1.83206 7.98982 2.16794 7.98982H8.6514C9.22137 7.98982 9.49618 8.4173 9.49618 8.87532V8.90585C9.49618 10.1578 10.4733 11.4809 12 11.4809Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 18",
});

export { OrderQueue };
