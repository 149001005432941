import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { useChartForm } from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared/loading";

const sectionId = "SCHC";
const chartCode = "T025x";

function N25ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="started / occurred:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A046`}
                />
                <ChartRowElement
                  label="just PTA"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chronic"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent injury"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ambulatory at scene"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="high blood pressure"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="cough"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dry"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="productive"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chest pain"
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="nausea"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vomiting x"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="abdominal pain"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="high blood sugar"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="low blood sugar"
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="fever"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chills"
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="problems urinating"
                  name={`Q001.${chartCode}${sectionId}Q001A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="back pain"
                  name={`Q001.${chartCode}${sectionId}Q001A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A039`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="diarrhea"
                  name={`Q001.${chartCode}${sectionId}Q001A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="headache"
                  name={`Q001.${chartCode}${sectionId}Q001A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chemical exposure"
                  name={`Q001.${chartCode}${sectionId}Q001A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A045`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Quality"
            >
              Quality
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="stiffness"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="spasm"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="stabbing"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sharp"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dull"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="burning"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tightness"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="numbness"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="head"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="back:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                />
                <ChartRowElement
                  label="upper"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="radiating to:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="no known recent injury"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="lifting"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="twisting"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="injury on duty"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="fall"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pulling"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="pushing"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="safety:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                />
                <ChartRowElement
                  label="none"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wearing back brace"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N25ChiefComplaint };
