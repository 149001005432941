import * as React from "react";

import {
  Box,
  chakra,
  ComponentWithAs,
  HStack,
  Icon,
  IconButton,
  IconProps,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IntegrationStatus } from "enums";
import { SquareInfo } from "icons";
import { genericErrors } from "messages";
import {
  useEncounterIntegrations,
  useUpdateEncounterIntegrationAYVA,
  useUpdateEncounterIntegrationAYVAPharmacy,
} from "modules/reception/api";
import { Card, CardProps, Loading, SingleActionDialog } from "shared";
import { extractApiErrorMessage } from "utils";
import { usePatientInfo } from "modules/charts-shared";

type EncounterIntegrationButtonProps = {
  type: string;
  label: string;
  status: IntegrationStatus;
  isLoading: boolean;
  icon: ComponentWithAs<"svg", IconProps>;
  onUpdateEncounterIntegration?: (type: string) => void;
  isDisabled?: boolean;
} & CardProps;

function EncounterIntegrationButton(props: EncounterIntegrationButtonProps) {
  const {
    type,
    label,
    status,
    icon,
    isLoading,
    onUpdateEncounterIntegration = () => null,
    isDisabled,
    ...cardProps
  } = props;

  const INTEGRATION_COLOR: Record<IntegrationStatus, string> = {
    Success: "green",
    Failure: "red",
    Disconnected: "lightgray",
    Warning: "yellow",
  };

  const INTEGRATION_TEXT: Record<IntegrationStatus, string> = {
    Success: "Information Sent",
    Failure: "Information Failed",
    Disconnected: "No Information Sent",
    Warning: "Partial Information Sent",
  };

  return (
    <Card
      margin="3px"
      borderRadius="10px"
      backgroundColor={INTEGRATION_COLOR[status]}
      {...cardProps}
    >
      {isLoading ? (
        <Loading spinnerProps={{ color: "white" }} />
      ) : (
        <HStack
          spacing="10px"
          cursor={`${
            status !== "Success" && !isDisabled ? "pointer" : "not-allowed"
          }`}
          onClick={() =>
            status !== "Success" && !isDisabled
              ? onUpdateEncounterIntegration(type)
              : null
          }
        >
          <Box display="flex" justifyContent="space-between">
            <IconButton
              aria-label="integration"
              icon={<Icon as={icon} color="white" fontSize="1.5rem" />}
              variant="ghost"
              height="100%"
              width="2rem"
              minWidth="unset"
              cursor={`${
                status !== "Success" && !isDisabled ? "pointer" : "not-allowed"
              }`}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom="5px"
          >
            <VStack spacing="0px">
              <chakra.span
                width="100%"
                color="white"
                fontSize="17px"
                fontWeight="600"
                textAlign="start"
                lineHeight="1.3em"
              >
                {label}
              </chakra.span>
              <chakra.span
                width="100%"
                color="white"
                fontSize="13px"
                fontWeight="400"
                textAlign="start"
                lineHeight=".85em"
              >
                {INTEGRATION_TEXT[status]}
              </chakra.span>
            </VStack>
          </Box>
        </HStack>
      )}
    </Card>
  );
}

type EncounterIntegrationCardsProps = {
  encounterId: string;
  showAYVAPharmacyIntegration?: boolean;
  isDisabled?: boolean;
};

function EncounterIntegrationCards(props: EncounterIntegrationCardsProps) {
  const {
    encounterId,
    showAYVAPharmacyIntegration = false,
    isDisabled,
  } = props;

  const { data: encounterData, isLoading: isLoadingEncounterInfo } =
    usePatientInfo(encounterId);

  const { data: integrationData, isLoading: isLoadingEncounterIntegrations } =
    useEncounterIntegrations(encounterId);

  const INTEGRATION_ITEMS = React.useMemo(() => {
    let integrationItems = [
      {
        type: "ayva",
        label: "Ayva",
        status: integrationData?.data?.ayvaConnectionStatus ?? "Disconnected",
      },
      {
        type: "lis",
        label: "LIS",
        status: integrationData?.data?.lisADTConnectionStatus ?? "Disconnected",
      },
      {
        type: "ramsoft",
        label: "Ramsoft",
        status:
          integrationData?.data?.ramsoftADTConnectionStatus ?? "Disconnected",
      },
    ];

    if (showAYVAPharmacyIntegration) {
      integrationItems = [
        ...integrationItems.slice(0, 1),
        {
          type: "ayva-pharmacy",
          label: "Preferred Pharmacy",
          status:
            integrationData?.data?.ayvaPharmacyConnectionStatus ??
            "Disconnected",
        },
        ...integrationItems.slice(1),
      ];
    }

    return integrationItems;
  }, [integrationData, showAYVAPharmacyIntegration]);

  const {
    mutateAsync: updateIntegrationAYVA,
    isLoading: isLoadingUpdateIntegrationAYVA,
  } = useUpdateEncounterIntegrationAYVA(encounterId);

  const {
    mutateAsync: updateIntegrationAYVAPharmacy,
    isLoading: isLoadingUpdateIntegrationAYVAPharmacy,
  } = useUpdateEncounterIntegrationAYVAPharmacy(encounterId, "PH");

  const toast = useToast();

  const {
    isOpen: isOpenAyvaModal,
    onClose: onCloseAyvaModal,
    onOpen: onOpenAyvaModal,
  } = useDisclosure();

  const [ayvaModalLabel, setAyvaModalLabel] = React.useState("");
  const [ayvaModalTitle, setAyvaModalTitle] = React.useState("");
  const [ayvaModalContent, setAyvaModalContent] = React.useState<string[]>([]);

  const onUpdateIntegrationAYVA = async () => {
    try {
      const ayvaRequest = await updateIntegrationAYVA({
        encounterId: encounterId,
      });

      setAyvaModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
      setAyvaModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
      setAyvaModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
      onOpenAyvaModal();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const onUpdateIntegrationAYVAPharmacy = async () => {
    try {
      const ayvaRequest = await updateIntegrationAYVAPharmacy("Success");

      setAyvaModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
      setAyvaModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
      setAyvaModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
      onOpenAyvaModal();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <HStack spacing="15px">
      {INTEGRATION_ITEMS.map((item) => (
        <Box key={item.type} display="flex" justifyContent="space-between">
          <EncounterIntegrationButton
            type={item.type}
            label={item.label}
            status={item.status}
            icon={SquareInfo}
            sx={{
              width: "240px",
              padding: "4px 16px 4px 16px",
            }}
            isLoading={
              isLoadingEncounterInfo ||
              isLoadingEncounterIntegrations ||
              (item.type === "ayva" && isLoadingUpdateIntegrationAYVA) ||
              (item.type === "ayva-pharmacy" &&
                isLoadingUpdateIntegrationAYVAPharmacy)
            }
            onUpdateEncounterIntegration={(integration) => {
              switch (integration) {
                case "lis":
                  // onUpdateIntegrationLIS();
                  break;
                case "ayva":
                  onUpdateIntegrationAYVA();
                  break;
                case "ayva-pharmacy":
                  if (
                    encounterData?.data?.preferredPharmacy?.id &&
                    integrationData?.data?.ayvaPharmacyConnectionStatus ===
                      "Failure"
                  ) {
                    onUpdateIntegrationAYVAPharmacy();
                  }
                  break;
              }
            }}
            isDisabled={isDisabled}
          />
        </Box>
      ))}

      {isOpenAyvaModal && (
        <SingleActionDialog
          title={ayvaModalTitle}
          actionLabel={ayvaModalLabel}
          actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
          content={ayvaModalContent}
          onClose={onCloseAyvaModal}
          isOpen={isOpenAyvaModal}
        />
      )}
    </HStack>
  );
}

export { EncounterIntegrationCards, EncounterIntegrationButton };
