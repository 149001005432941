import {
  ArrowConfig,
  CircleConfig,
  Diagram,
  EllipseConfig,
  LineConfig,
  ShapeConfig,
  ShapeState,
  TextConfig,
} from "./types";

type CreateCircleAction = {
  type: "CREATE_CIRCLE";
  payload?: Omit<CircleConfig, "id">;
};

type CreateEditableTextAction = {
  type: "CREATE_EDITABLE_TEXT";
  payload?: Omit<TextConfig, "id">;
};

type CreateLabelAction = {
  type: "CREATE_LABEL";
  payload: Omit<TextConfig, "id" | "text"> & { text: string };
};

type CreateLineAction = {
  type: "CREATE_LINE";
  payload?: Omit<LineConfig, "id">;
};

type CreateCurveLineAction = {
  type: "CREATE_CURVE_LINE";
  payload?: Omit<LineConfig, "id">;
};

type CreateArrowAction = {
  type: "CREATE_ARROW";
  payload?: Omit<ArrowConfig, "id">;
};

type CreateEllipseAction = {
  type: "CREATE_ELLIPSE";
  payload?: Omit<EllipseConfig, "id">;
};

type ChangeColorAction = {
  type: "CHANGE_COLOR";
  payload: string;
};

type EditShapeAction = {
  type: "EDIT_SHAPE";
  payload: ShapeConfig;
};

type DeleteShapeAction = {
  type: "DELETE_SHAPE";
  payload: string;
};

type SelectShapeAction = {
  type: "SELECT_SHAPE";
  payload: string;
};

type DeselectShapeAction = {
  type: "DESELECT_SHAPE";
};

type SetStateAction = {
  type: "SET_STATE";
  payload: DiagramsState;
};

type SelectDiagramAction = {
  type: "SELECT_DIAGRAM";
  payload: number;
};

type DeleteDiagramAction = {
  type: "DELETE_DIAGRAM";
  payload: string;
};

type UndoAction = {
  type: "UNDO";
};

type RecordDragAction = {
  type: "RECORD_DRAG";
  payload: {
    id: string;
    x: number;
    y: number;
  };
};

type DiagramAction =
  | CreateCircleAction
  | CreateEditableTextAction
  | CreateLabelAction
  | CreateLineAction
  | EditShapeAction
  | DeleteShapeAction
  | SelectShapeAction
  | DeselectShapeAction
  | SetStateAction
  | SelectDiagramAction
  | DeleteDiagramAction
  | ChangeColorAction
  | CreateArrowAction
  | CreateEllipseAction
  | CreateCurveLineAction
  | UndoAction
  | RecordDragAction;

type HistoryMoveObject = {
  action: "move";
  id: string;
  x?: number;
  y?: number;
};

type HistoryAddObject = {
  action: "add";
  id: string;
};

type HistoryDeleteObject = {
  action: "delete";
  shape: ShapeState;
};

type HistoryEditObject = {
  action: "edit";
  shape: ShapeState;
};

type HistoryObject =
  | HistoryAddObject
  | HistoryMoveObject
  | HistoryDeleteObject
  | HistoryEditObject;

type DiagramState = Diagram & {
  selectedShape: string | null;
  isDirty?: boolean;
  history: HistoryObject[];
};

type DiagramsState = {
  diagrams: DiagramState[];
  activeDiagramIndex: number | null;
  currentColor: string;
};

const DIAGRAM_INITIAL_STATE: DiagramsState = {
  diagrams: [],
  activeDiagramIndex: null,
  currentColor: "black",
};

const DEFAULT_SHAPE_CONFIG: Partial<ShapeConfig> = {
  draggable: true,
};

const DEFAULT_EDITABLE_TEXT_CONFIG: Partial<TextConfig> = {
  draggable: true,
  padding: 20,
  fontSize: 17,
  fontStyle: "bold",
  x: 850,
  y: 630 / 2,
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_LABEL_CONFIG: Partial<TextConfig> = {
  draggable: true,
  padding: 20,
  fontSize: 16,
  fontStyle: "bold",
  x: 900,
  y: 630 / 2,
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_CIRCLE_CONFIG: Partial<CircleConfig> = {
  width: 140,
  height: 140,
  x: 850,
  y: 630 / 2,
  strokeWidth: 3,
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_LINE_CONFIG: Partial<LineConfig> = {
  points: [10, 10, 296, 10],
  strokeWidth: 3,
  lineCap: "round",
  lineJoin: "round",
  x: 700,
  y: 630 / 2,
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_CURVE_LINE_CONFIG: Partial<LineConfig> = {
  points: [50, 50, 150, 150, 250, 50],
  tension: 1,
  strokeWidth: 3,
  lineCap: "round",
  lineJoin: "round",
  x: 700,
  y: 630 / 2,
  hitFunc: (context, shape) => {
    context.beginPath();
    context.rect(0, 0, 250, 150);
    context.closePath();
    context.fillStrokeShape(shape);
  },
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_ARROW_CONFIG: Partial<ArrowConfig> = {
  points: [10, 10, 296, 10],
  strokeWidth: 3,
  lineCap: "round",
  lineJoin: "round",
  x: 700,
  y: 630 / 2,
  ...DEFAULT_SHAPE_CONFIG,
};

const DEFAULT_ELLIPSE_CONFIG: Partial<EllipseConfig> = {
  strokeWidth: 3,
  lineCap: "round",
  lineJoin: "round",
  radiusX: 100,
  radiusY: 40,
  x: 700,
  y: 630 / 2,
  ...DEFAULT_SHAPE_CONFIG,
};

function isActiveDiagram(
  diagram: DiagramState,
  selectedDiagram: DiagramState | null
) {
  if (!selectedDiagram) {
    return false;
  }

  return diagram.id
    ? diagram.id === selectedDiagram.id
    : diagram.imageCode === selectedDiagram.imageCode;
}

function diagramReducer(
  state: DiagramsState,
  action: DiagramAction
): DiagramsState {
  const { type } = action;
  const { diagrams, activeDiagramIndex, currentColor } = state;
  const selectedDiagram =
    activeDiagramIndex !== null ? diagrams[activeDiagramIndex] : null;

  if (type === "CREATE_EDITABLE_TEXT") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "editable-text",
                config: {
                  ...DEFAULT_EDITABLE_TEXT_CONFIG,
                  ...payload,
                  fill: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_LABEL") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "text",
                config: {
                  ...DEFAULT_LABEL_CONFIG,
                  ...payload,
                  fill: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_CIRCLE") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "circle",
                config: {
                  ...DEFAULT_CIRCLE_CONFIG,
                  ...payload,
                  stroke: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_LINE") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "line",
                config: {
                  ...DEFAULT_LINE_CONFIG,
                  ...payload,
                  stroke: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_CURVE_LINE") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "curve_line",
                config: {
                  ...DEFAULT_CURVE_LINE_CONFIG,
                  ...payload,
                  stroke: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_ARROW") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "arrow",
                config: {
                  ...DEFAULT_ARROW_CONFIG,
                  ...payload,
                  stroke: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CREATE_ELLIPSE") {
    const { payload = {} } = action;
    const newTextId = new Date().valueOf().toString();

    return {
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            isDirty: true,
            selectedShape: newTextId,
            shapes: [
              ...shapes,
              {
                type: "ellipse",
                config: {
                  ...DEFAULT_ELLIPSE_CONFIG,
                  ...payload,
                  stroke: currentColor,
                  id: newTextId,
                },
              },
            ],
            history: [...diagram.history, { action: "add", id: newTextId }],
          };
        }

        return diagram;
      }),
      activeDiagramIndex,
      currentColor,
    };
  }

  if (type === "CHANGE_COLOR") {
    const { payload = "black" } = action;

    return {
      ...state,
      currentColor: payload,
    };
  }

  if (type === "EDIT_SHAPE") {
    const { payload } = action;
    const { id } = payload;

    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          const editedShape = shapes.find((shape) => shape.config.id === id);
          return {
            ...diagram,
            isDirty: true,
            shapes: shapes.map((shape) => {
              const { config, ...rest } = shape;

              if (config.id !== id) {
                return shape;
              }

              return {
                ...rest,
                config: {
                  ...config,
                  ...payload,
                },
              };
            }),
            history: editedShape
              ? [...diagram.history, { action: "edit", shape: editedShape }]
              : diagram.history,
          };
        }

        return diagram;
      }),
    };
  }

  if (type === "DELETE_SHAPE") {
    const { payload } = action;

    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        const { shapes } = diagram;

        if (isActiveDiagram(diagram, selectedDiagram)) {
          const { selectedShape } = diagram;
          const deletedShape = shapes.find(
            (shape) => shape.config.id === payload
          );

          return {
            ...diagram,
            isDirty: true,
            selectedShape: payload === selectedShape ? null : selectedShape,
            shapes: shapes.filter(({ config: { id } }) => id !== payload),
            history: deletedShape
              ? [
                  ...diagram.history,
                  {
                    action: "delete",
                    shape: deletedShape,
                  },
                ]
              : diagram.history,
          };
        }

        return diagram;
      }),
    };
  }

  if (type === "SELECT_SHAPE") {
    const { payload } = action;

    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        if (isActiveDiagram(diagram, selectedDiagram)) {
          const { selectedShape } = diagram;

          return {
            ...diagram,
            isDirty: true,
            selectedShape: payload === selectedShape ? null : payload,
          };
        }

        return diagram;
      }),
    };
  }

  if (type === "DESELECT_SHAPE") {
    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            selectedShape: null,
          };
        }

        return diagram;
      }),
    };
  }

  if (type === "SET_STATE") {
    const { payload } = action;

    return payload;
  }

  if (type === "SELECT_DIAGRAM") {
    const { payload } = action;

    return {
      ...state,
      activeDiagramIndex: payload,
    };
  }

  if (type === "DELETE_DIAGRAM") {
    const { payload } = action;

    return {
      activeDiagramIndex,
      diagrams: diagrams.filter((diagram) => {
        const isDiagramId = diagram.id && diagram.id === payload;
        const isDiagramCode = !diagram.id && diagram.imageCode === payload;

        if (isDiagramId || isDiagramCode) {
          return false;
        }

        return true;
      }),
      currentColor,
    };
  }

  if (type === "RECORD_DRAG") {
    const { payload } = action;
    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        if (isActiveDiagram(diagram, selectedDiagram)) {
          return {
            ...diagram,
            history: [
              ...diagram.history,
              { action: "move", id: payload.id, x: payload.x, y: payload.y },
            ],
          };
        }
        return diagram;
      }),
    };
  }

  if (type === "UNDO") {
    return {
      ...state,
      diagrams: diagrams.map((diagram) => {
        if (isActiveDiagram(diagram, selectedDiagram)) {
          const { history, shapes, selectedShape } = diagram;
          const newHistory = [...history];
          const lastAction = newHistory.pop();
          if (!lastAction) return diagram;

          if (lastAction.action === "add") {
            return {
              ...diagram,
              isDirty: newHistory.length > 0,
              selectedShape:
                lastAction.id === selectedShape ? null : selectedShape,
              shapes: shapes.filter(
                ({ config: { id } }) => id !== lastAction.id
              ),
              history: newHistory,
            };
          }
          if (lastAction.action === "delete") {
            return {
              ...diagram,
              isDirty: newHistory.length > 0,
              selectedShape: lastAction.shape.config.id,
              shapes: [...shapes, lastAction.shape],
              history: newHistory,
            };
          }
          if (lastAction.action === "move") {
            return {
              ...diagram,
              isDirty: newHistory.length > 0,
              selectedShape: lastAction.id,
              shapes: shapes.map((shape) => {
                if (shape.config.id === lastAction.id) {
                  return {
                    ...shape,
                    config: {
                      ...shape.config,
                      x: lastAction.x,
                      y: lastAction.y,
                    },
                  };
                }
                return shape;
              }),
              history: newHistory,
            };
          }
          if (lastAction.action === "edit") {
            return {
              ...diagram,
              isDirty: newHistory.length > 0,
              selectedShape: lastAction.shape.config.id,
              shapes: shapes.map((shape) => {
                if (shape.config.id === lastAction.shape.config.id) {
                  return lastAction.shape;
                }
                return shape;
              }),
              history: newHistory,
            };
          }
        }
        return diagram;
      }),
    };
  }

  return state;
}

export { DIAGRAM_INITIAL_STATE, diagramReducer };
export type { DiagramAction, DiagramState, DiagramsState };
