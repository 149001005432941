import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import { RadiologyInterpretationProps } from "../radiology-chart/types";

function F017Interpretation(props: RadiologyInterpretationProps) {
  const {
    index,
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionSubCode}_Q001`}
          subsectionLabel="Scapula"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        />
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Scapula:"
                name={`${sectionDelimiter}Q001A001`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q001A002`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q001A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="glenoid"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A004`}
                type="option"
              />
              <ChartRowElement
                label="neck"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A005`}
                type="option"
              />
              <ChartRowElement
                label="acrominon"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A006`}
                type="option"
              />
              <ChartRowElement
                label="carocoid process"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A007`}
                type="option"
              />
              <ChartRowElement
                label="body"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A008`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A009`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="nondisplaced"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A010`}
                type="option"
              />
              <ChartRowElement
                label="displaced"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A011`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A012`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { F017Interpretation };
