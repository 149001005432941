import { chakra } from "@chakra-ui/react";
import { addMilliseconds } from "date-fns";
import { useCountdown } from "hooks";
import * as React from "react";
import { msToTimer } from "utils";

type ActiveTimerProps = {
  latestRecordTakenAt: Date | string | undefined;
  timer: number;
  vitalsTimerMode?: boolean;
};

function ActiveTimer(props: ActiveTimerProps) {
  const { timer, vitalsTimerMode = false } = props;
  const start = React.useMemo(
    () => addMilliseconds(Date.now(), timer),
    [timer]
  );
  const milliseconds = useCountdown(start);

  if (!vitalsTimerMode) {
    return <>{msToTimer(milliseconds)}</>;
  }

  const [hrs, min] = msToTimer(milliseconds).split(":");
  return (
    <>
      <chakra.span paddingRight="0.825rem">{hrs}</chakra.span>
      <chakra.span paddingRight="2rem" fontSize="1.125rem">
        hours
      </chakra.span>
      <chakra.span paddingRight="0.825rem">{min}</chakra.span>
      <chakra.span fontSize="1.125rem">minutes</chakra.span>
    </>
  );
}

export type { ActiveTimerProps };
export { ActiveTimer };
