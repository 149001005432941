import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S023Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q023.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
          subsectionLabel="Wound Description / Repair (Head Injury)"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Wound Description / Repair (Head Injury)
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                type="option"
                name={`${sectionDelimiter}Q023A001`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q023A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Wound Description / Repair:"
                type="option"
                name={`${sectionDelimiter}Q023A003`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="length:"
                type="option"
                name={`${sectionDelimiter}Q023A004`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A005`}
                w="100px"
                type="input"
              />
              <ChartRowElement
                label="cm"
                type="option"
                name={`${sectionDelimiter}Q023A006`}
              />
              <ChartRowElement
                label="location:"
                type="option"
                name={`${sectionDelimiter}Q023A007`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A008`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="linear"
                name={`${sectionDelimiter}Q023A009`}
                type="option"
              />
              <ChartRowElement
                label="stellate"
                name={`${sectionDelimiter}Q023A010`}
                type="option"
              />
              <ChartRowElement
                label="irregular"
                name={`${sectionDelimiter}Q023A011`}
                type="option"
              />
              <ChartRowElement
                label="flap"
                name={`${sectionDelimiter}Q023A012`}
                type="option"
              />
              <ChartRowElement
                label="into:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q023A013`}
              />
              <ChartRowElement
                label="subcut"
                name={`${sectionDelimiter}Q023A014`}
                type="option"
              />
              <ChartRowElement
                label="muscle"
                name={`${sectionDelimiter}Q023A015`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="clean"
                name={`${sectionDelimiter}Q023A017`}
                type="option"
              />
              <ChartRowElement
                label="contaminated :"
                type="option"
                name={`${sectionDelimiter}Q023A018`}
              />
              <ChartRowElement
                label="moderately"
                name={`${sectionDelimiter}Q023A019`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="heavily"
                name={`${sectionDelimiter}Q023A020`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A021`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Distal NVT:"
                type="option"
                name={`${sectionDelimiter}Q023A022`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro intact"
                name={`${sectionDelimiter}Q023A023`}
                type="option"
              />
              <ChartRowElement
                label="vasc intact"
                name={`${sectionDelimiter}Q023A024`}
                type="option"
              />
              <ChartRowElement
                label="galea intact"
                name={`${sectionDelimiter}Q023A025`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A026`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                type="option"
                name={`${sectionDelimiter}Q023A027`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="local"
                name={`${sectionDelimiter}Q023A028`}
                type="option"
              />
              <ChartRowElement
                label="topical"
                name={`${sectionDelimiter}Q023A029`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A030`}
                w="200px"
                type="input"
              />
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q023A031`}
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                name={`${sectionDelimiter}Q023A032`}
                type="option"
              />
              <ChartRowElement
                label="epi"
                name={`${sectionDelimiter}Q023A033`}
                type="option"
              />
              <ChartRowElement
                label="bicarb"
                name={`${sectionDelimiter}Q023A034`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A035`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                type="option"
                name={`${sectionDelimiter}Q023A036`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="Shur-Clens"
                name={`${sectionDelimiter}Q023A037`}
                type="option"
              />
              <ChartRowElement
                label="Hibiclens"
                name={`${sectionDelimiter}Q023A038`}
                type="option"
              />
              <ChartRowElement
                label="Betadine"
                name={`${sectionDelimiter}Q023A039`}
                type="option"
              />
              <ChartRowElement
                label="Chlorhexidine"
                name={`${sectionDelimiter}Q023A040`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A041`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="irrigated w/ saline"
                name={`${sectionDelimiter}Q023A042`}
                type="option"
              />
              <ChartRowElement
                label="debrided:"
                type="option"
                name={`${sectionDelimiter}Q023A043`}
              />
              <ChartRowElement
                label="moderate"
                name={`${sectionDelimiter}Q023A044`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="extensive"
                name={`${sectionDelimiter}Q023A045`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A046`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="wound explored:"
                type="option"
                name={`${sectionDelimiter}Q023A047`}
              />
              <ChartRowElement
                label="to base"
                name={`${sectionDelimiter}Q023A048`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="in bloodless field"
                name={`${sectionDelimiter}Q023A049`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="wound margins revised"
                name={`${sectionDelimiter}Q023A050`}
                type="option"
              />
              <ChartRowElement
                label="multiple flaps aligned"
                name={`${sectionDelimiter}Q023A051`}
                type="option"
              />
              <ChartRowElement
                label="vermillion border aligned"
                name={`${sectionDelimiter}Q023A052`}
                type="option"
              />
              <ChartRowElement
                label="galea repaired"
                name={`${sectionDelimiter}Q023A053`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A054`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="foreign body identified:"
                type="option"
                name={`${sectionDelimiter}Q023A055`}
              />
              <ChartRowElement
                label="wood"
                name={`${sectionDelimiter}Q023A056`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="glass"
                name={`${sectionDelimiter}Q023A057`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="metal"
                name={`${sectionDelimiter}Q023A058`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="other"
                name={`${sectionDelimiter}Q023A059`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="foreign material removed"
                name={`${sectionDelimiter}Q023A060`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A061`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q023A062`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Repair:"
                type="option"
                name={`${sectionDelimiter}Q023A063`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="superficial"
                name={`${sectionDelimiter}Q023A064`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="deep"
                name={`${sectionDelimiter}Q023A065`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="complicated"
                name={`${sectionDelimiter}Q023A066`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A067`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="wound closed w/:"
                type="option"
                name={`${sectionDelimiter}Q023A068`}
              />
              <ChartRowElement
                label="adhesive"
                name={`${sectionDelimiter}Q023A069`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="steri-strips"
                name={`${sectionDelimiter}Q023A070`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="no closure required"
                name={`${sectionDelimiter}Q023A071`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A072`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="SKIN:"
                w="300px"
                type="option"
                name={`${sectionDelimiter}Q023A073`}
              />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="#"
                type="option"
                name={`${sectionDelimiter}Q023A074`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A075`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="-0"
                type="option"
                name={`${sectionDelimiter}Q023A076`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A077`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="nylon"
                name={`${sectionDelimiter}Q023A078`}
                type="option"
              />
              <ChartRowElement
                label="prolene"
                name={`${sectionDelimiter}Q023A079`}
                type="option"
              />
              <ChartRowElement
                label="vicryl"
                name={`${sectionDelimiter}Q023A080`}
                type="option"
              />
              <ChartRowElement
                label="staples"
                name={`${sectionDelimiter}Q023A081`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A082`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="interrupted"
                name={`${sectionDelimiter}Q023A083`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="running"
                name={`${sectionDelimiter}Q023A084`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="simple"
                name={`${sectionDelimiter}Q023A085`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="mattress:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q023A086`}
              />
              <ChartRowElement
                label="h"
                name={`${sectionDelimiter}Q023A087`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="v"
                name={`${sectionDelimiter}Q023A088`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A089`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="SUBCUTANEOUS / MUCOSA:"
                w="300px"
                type="option"
                name={`${sectionDelimiter}Q023A090`}
              />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="#"
                type="option"
                name={`${sectionDelimiter}Q023A091`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A092`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="-0"
                type="option"
                name={`${sectionDelimiter}Q023A093`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A094`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="vicryl"
                name={`${sectionDelimiter}Q023A095`}
                type="option"
              />
              <ChartRowElement
                label="silk"
                name={`${sectionDelimiter}Q023A096`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A097`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="interrupted"
                name={`${sectionDelimiter}Q023A098`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="running"
                name={`${sectionDelimiter}Q023A099`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="simple"
                name={`${sectionDelimiter}Q023A100`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="mattress:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q023A101`}
              />
              <ChartRowElement
                label="h"
                name={`${sectionDelimiter}Q023A102`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="v"
                name={`${sectionDelimiter}Q023A103`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A104`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="FASCIA / MUSCLE / TENDON:"
                w="300px"
                type="option"
                name={`${sectionDelimiter}Q023A105`}
              />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="#"
                type="option"
                name={`${sectionDelimiter}Q023A106`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A107`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="-0"
                type="option"
                name={`${sectionDelimiter}Q023A108`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A109`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="vicryl"
                name={`${sectionDelimiter}Q023A110`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A111`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="interrupted"
                name={`${sectionDelimiter}Q023A112`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="running"
                name={`${sectionDelimiter}Q023A113`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="simple"
                name={`${sectionDelimiter}Q023A114`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="mattress:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q023A115`}
              />
              <ChartRowElement
                label="h"
                name={`${sectionDelimiter}Q023A116`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="v"
                name={`${sectionDelimiter}Q023A117`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A118`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="NAIL / NAIL MATRIX:"
                w="300px"
                type="option"
                name={`${sectionDelimiter}Q023A119`}
              />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="#"
                type="option"
                name={`${sectionDelimiter}Q023A120`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A121`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="-0"
                type="option"
                name={`${sectionDelimiter}Q023A122`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A123`}
                w="120px"
                type="input"
              />
              <ChartRowElement
                label="vicryl"
                name={`${sectionDelimiter}Q023A124`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A125`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="nail excised"
                name={`${sectionDelimiter}Q023A126`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="nail reattached"
                name={`${sectionDelimiter}Q023A127`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A128`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="OTHER:"
                w="300px"
                type="option"
                name={`${sectionDelimiter}Q023A129`}
              />
              <ChartRowElement type="empty" />

              <ChartRowElement
                label="retention sutures placed"
                name={`${sectionDelimiter}Q023A130`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q023A131`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q023A132`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S023Procedure };
