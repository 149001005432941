import { axiosSLPatientInstance } from "config";
import { StpVipPatientFilter } from "enums";
import { useAxiosQuery } from "hooks";
import { StpVipNoteDto } from "modules/stp-vip/types";

type UseStpVipNotesProps = {
  patientId: string;
  filter: StpVipPatientFilter;
  facility: "galveston" | "tyler" | "longview";
};

function useStpVipSharedNotes(props: UseStpVipNotesProps) {
  const { patientId, filter, facility } = props;
  return useAxiosQuery(["stpVipNotes", { patientId, filter, facility }], () =>
    axiosSLPatientInstance.get<StpVipNoteDto[]>(
      `v1/patient-note/${patientId}/${filter}/${facility}`
    )
  );
}

export { useStpVipSharedNotes };
