import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import {
  ChartNoteApiUrlParams,
  getChartNoteApiURL,
} from "modules/charts-shared/utils";
import { ChartNote } from "types";

function useChartNotes(params: ChartNoteApiUrlParams) {
  return useAxiosQuery(["chartNotes", params], () =>
    axiosChartInstance.get<ChartNote[]>(getChartNoteApiURL(params))
  );
}

export { useChartNotes };
