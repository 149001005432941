import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
// eslint-disable-next-line
import { genericErrors } from "messages";
import { useAuth0 } from "@auth0/auth0-react";
// eslint-disable-next-line
import { config } from "config";

function useHandleUnauthorizedError(status?: number) {
  const { logout } = useAuth0();
  const toast = useToast();

  useEffect(() => {
    /**
     * If api return an unauthorized error, logout from
     * the application
     */
    if (status === 401 || status === 403) {
      toast({ description: genericErrors.unauthorizedError });
      logout({ returnTo: config.auth0LogoutUrl });
    }
  }, [status, toast, logout]);
}

export { useHandleUnauthorizedError };
