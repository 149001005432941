import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { InsuranceType } from "modules/reception/types";
import { useQueryClient } from "react-query";

type EligibilityResponse = {
  sentRequestInd: boolean;
  sentInsuranceInd: boolean;
  sentEligibilityInd: boolean;
  incompleteEligibilityInd: boolean;
  unableVerifyEligibilityInd: boolean;

  eligibilityInfoTitle?: string;
  eligibilityInfoAction?: string;
  eligibilityInfoMessages?: string[];
};

function useUpdateInsuranceEligibility(
  encounterId: string,
  insuranceType: InsuranceType
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterEligibility", { encounterId }],
    () =>
      axiosPatientInstance.post<EligibilityResponse>(
        `v1/integration/eligibility`,
        {
          encounterId,
          insuranceType,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterInsuranceEligibility",
          { encounterId, insuranceType },
        ]);
        queryClient.invalidateQueries([
          "insuranceEligibilityReportFile",
          { encounterId, insuranceType },
        ]);
      },
    }
  );
}

export type { EligibilityResponse };
export { useUpdateInsuranceEligibility };
