import { theme } from "@chakra-ui/react";

const baseInputTheme = theme.components.Input;

const sizes = {
  lg: {
    field: {},
  },
  md: {
    field: {
      maxW: "210px",
    },
  },
  sm: {
    field: {
      maxW: "120px",
    },
  },
  "single-char": {
    field: {
      maxW: "50px",
      maxLength: 1,
    },
  },
};

function getDefaults(props: Record<string, unknown>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || "gray.450",
    errorBorderColor: ec || "red",
  };
}

function variantFilled(props: Record<string, unknown>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: "1px solid",
      borderColor: "gray.450",
      bg: "gray.50",
      borderRadius: "10px",
      fontSize: "17px",
      h: "40px",
      px: 3,
      _hover: {
        bg: "gray.50",
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {
        borderColor: ec,
      },
      _focus: {
        bg: "gray.50",
        borderColor: fc,
      },
      _placeholder: {
        color: "gray.450",
      },
    },
  };
}

function variantOutline(props: Record<string, unknown>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      width: "288px",
      border: "2px solid",
      borderColor: "#8E8E93",
      borderRadius: "50px",
      fontSize: "17px",
      h: "52px",
      px: 5,
      _hover: {
        borderColor: "#8E8E93",
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {
        borderColor: ec,
      },
      _focus: {
        bg: "transparent",
        borderColor: fc,
      },
      _placeholder: {
        color: "#8E8E93",
      },
    },
  };
}

function variantSearch() {
  return {
    field: {
      borderRadius: "10px",
      minW: "343px",
      h: "43px",
      bg: "gray.300",
      fontSize: "17px",
      _hover: {},
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {},
      _focus: {
        bg: "gray.250",
      },
      _placeholder: {
        color: "gray.750",
      },
    },
  };
}

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  search: variantSearch,
};

const defaultProps = {
  size: "lg",
  variant: "filled",
};

export const inputTheme = {
  ...baseInputTheme,
  sizes: {
    lg: baseInputTheme.sizes.lg,
    md: baseInputTheme.sizes.md,
    sm: baseInputTheme.sizes.sm,
  },
  variants: {
    filled: baseInputTheme.variants.filled,
    outline: baseInputTheme.variants.outline,
  },
};

export default {
  sizes,
  variants,
  defaultProps,
};
