import { Icon, useControllableState, useDisclosure } from "@chakra-ui/react";
import { EmrVitals } from "@medstonetech/slate-icons";
import { config } from "config";
import * as React from "react";
import {
  MedicalStaffInputProps,
  MedicalStaffModal,
  MedicalStaffModalBaseProps,
  MedicalStaffModalContent,
} from "../MedicalStaffInput";
import { ModalInputButton } from "../ModalInputButton";

function AdmittingProviderModal(props: MedicalStaffModalBaseProps) {
  const { isOpen, onClose, onChange, initialValue, ...rest } = props;

  return (
    <MedicalStaffModal isOpen={isOpen} onClose={onClose}>
      {isOpen && (
        <MedicalStaffModalContent
          onClose={onClose}
          title="Assign Admitting Provider"
          initialValue={initialValue}
          onChange={onChange}
          {...rest}
        />
      )}
    </MedicalStaffModal>
  );
}

const AdmittingProviderInput = React.forwardRef<
  HTMLButtonElement,
  MedicalStaffInputProps
>((props, ref) => {
  const {
    defaultValue = null,
    onChange,
    value: valueProp,
    encounterId,
    encounterUserType,
    userType,
    onCloseDetailsModal,
    disabled = false,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [value, setValue] = useControllableState({
    defaultValue,
    onChange,
    value: valueProp,
  });

  return (
    <>
      <ModalInputButton
        ref={ref}
        icon={<Icon as={EmrVitals} fontSize="1.25rem" color="gray.600" />}
        label="Admitting Provider"
        value={value?.fullName || ""}
        onClick={onOpen}
        disabled={disabled}
      />
      <AdmittingProviderModal
        isOpen={isOpen}
        onClose={onClose}
        onChange={setValue}
        initialValue={value}
        encounterId={encounterId}
        encounterUserType={encounterUserType}
        userType={userType}
        onCloseDetailsModal={onCloseDetailsModal}
      />
    </>
  );
});

if (config.isDev) {
  AdmittingProviderInput.displayName = "AdmittingProviderInput";
}

export { AdmittingProviderInput };
