import * as React from "react";

import {
  Card,
  DividerList,
  Loading,
  SearchBar,
  TabList,
  Tabs,
  WarningDialog,
} from "shared";
import { BaseModalProps, ChartType } from "types";
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabPanel,
  TabPanels,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Chart,
  ChartApplicationResponse,
  useChartsList,
  useCreateChartApplication,
  useDeleteFavoriteChart,
  usePutFavoriteChart,
  useRestoreDeletedChart,
} from "modules/charts-shared/api";
import { EmrCheck, EmrHeart } from "@medstonetech/slate-icons";
import { extractApiErrorData, extractApiErrorMessage } from "utils";

import { AxiosResponse } from "axios";
import { EncounterStatus } from "enums";
import { debounce } from "throttle-debounce";
import { formMessages } from "messages";
import { getChartTypeIcon } from "modules/charts-shared/constants";
import { usePermissions } from "contexts";
import { useToast } from "hooks";
import { useEncounterIntegrations } from "modules/reception/api";
import { useNavigate } from "react-router-dom";
import { useUpdateIntegrationsStatus } from "modules/in-process/hooks";

type AddPatientChartModalProps = BaseModalProps & {
  encounterId: string;
  onChartCreated?: (newStatus: EncounterStatus) => void;
  defaultChartType?: string;
};

type AddPatientChartModalContentProps = Omit<
  AddPatientChartModalProps,
  "isOpen"
>;

type CreateChartApplicationError = Partial<{
  chartApplicationDeleted: boolean;
  chartApplicationId: string;
}>;

type ChartItemProps = {
  title: string;
  isFavorite: boolean;
  chartType: ChartType;
  code: string;
  onClickChart?: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
};

function ChartItem(props: ChartItemProps) {
  const {
    title,
    isFavorite,
    chartType,
    code,
    onClickChart,
    isSelected,
    isDisabled = false,
  } = props;
  const { mutateAsync: addFavoriteChart, isLoading: isAddLoading } =
    usePutFavoriteChart(chartType);
  const { mutateAsync: deleteFavoriteChart, isLoading: isDeleteLoading } =
    useDeleteFavoriteChart(chartType);
  const toast = useToast();

  const onClickFavoriteBtn = async () => {
    try {
      if (!isFavorite) {
        await addFavoriteChart(code);
      } else {
        await deleteFavoriteChart(code);
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="16px"
      width="100%"
      justifyContent="left"
    >
      <IconButton
        aria-label="set chart as favorite"
        icon={<Icon as={EmrHeart} color={isFavorite ? "blue" : "gray.450"} />}
        marginRight="16px"
        variant="icon"
        size="md"
        isLoading={isAddLoading || isDeleteLoading}
        onClick={onClickFavoriteBtn}
      />
      <Button
        variant="label"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={onClickChart}
        isDisabled={isDisabled}
      >
        <HStack spacing="16px">
          {getChartTypeIcon(chartType, { color: "gray.700" })}
          <Box fontWeight="600" fontSize="1.0625rem">
            {title}
          </Box>
        </HStack>
        {isSelected && (
          <Icon
            as={EmrCheck}
            color="blue"
            fontSize="1.25rem"
            alignSelf="flex-end"
          />
        )}
      </Button>
    </Box>
  );
}

type ChartsListProps = {
  chartType: ChartType;
  selectedChart: Chart | null;
  onChartClick: (chart: Chart) => void;
  search?: string;
};

function ChartsList(props: ChartsListProps) {
  const { chartType, selectedChart, onChartClick, search } = props;
  const { data, isLoading, error } = useChartsList({ type: chartType, search });
  const showData = data && !isLoading && !error;
  const showError = !isLoading && !!error;

  return (
    <Card
      bg="white"
      border="none"
      boxShadow="none"
      margin="15px 0"
      overflow="auto"
    >
      <DividerList dividerLeftMargin="52px">
        {isLoading && <Loading height="200px" />}
        {showData &&
          data?.data.map((chart) => (
            <ChartItem
              isSelected={selectedChart?.code === chart.code}
              key={chart.code}
              onClickChart={() => onChartClick(chart)}
              chartType={chart.code === "C070a" ? "Observation" : chartType}
              {...chart}
            />
          ))}
        {showError && "Something went wrong fetching charts list."}
      </DividerList>
    </Card>
  );
}

function AddPatientChartModalContent(props: AddPatientChartModalContentProps) {
  const { onClose, encounterId, onChartCreated, defaultChartType } = props;
  const { scope } = usePermissions();
  const navigate = useNavigate();
  const addDeleteNursePermission = scope("nurse:adddelete").isAccessible;
  const addDeleteProviderPermission = scope("provider:adddelete").isAccessible;

  const initialTab =
    addDeleteNursePermission && !addDeleteProviderPermission ? 1 : 0;

  const [selectedChart, setSelectedChart] = React.useState<Chart | null>(null);
  const [search, setSearch] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(initialTab);
  const debouncedSearch = React.useMemo(() => debounce(1000, setSearch), []);
  const {
    mutateAsync: createChartApplication,
    isLoading,
    error: createError,
  } = useCreateChartApplication(encounterId);
  const toast = useToast();
  const {
    isOpen: isDialogOpen,
    onClose: onDialogClose,
    onOpen: onDialogOpen,
  } = useDisclosure();

  React.useEffect(() => {
    if (defaultChartType === "triage") setActiveTab(1);
    if (defaultChartType === "provider") setActiveTab(0);
  }, [defaultChartType]);

  const onDone = async () => {
    try {
      if (!selectedChart) return;
      const chartApplicationRequest: AxiosResponse<ChartApplicationResponse> =
        await createChartApplication(selectedChart?.code);

      onChartCreated?.(chartApplicationRequest.data?.status ?? "Lobby");
      onClose();

      const chartType =
        chartApplicationRequest.data.chartType === "Observation"
          ? "Chart"
          : chartApplicationRequest.data.chartType;
      navigate(
        `/in-process/charts/${encounterId}/${chartType}/${chartApplicationRequest.data.chartCode}`
      );
    } catch (error) {
      const { data, statusCode } =
        extractApiErrorData<CreateChartApplicationError>(error);
      if (
        statusCode === 409 &&
        data.chartApplicationDeleted &&
        data.chartApplicationId
      ) {
        onDialogOpen();
      } else toast({ description: extractApiErrorMessage(error) });
    }
  };

  const { mutateAsync: restoreChart, isLoading: restoreChartLoading } =
    useRestoreDeletedChart(encounterId);

  const onRestoreChartClick = async () => {
    try {
      const { data } =
        extractApiErrorData<CreateChartApplicationError>(createError);
      if (!data.chartApplicationDeleted || !data.chartApplicationId) return;
      await restoreChart(data.chartApplicationId);
      toast({ description: formMessages.updateSuccess("Chart") });
      onDialogClose();
      onClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <>
      <WarningDialog
        isOpen={isDialogOpen}
        onCancel={onDialogClose}
        title="Warning"
        mainText="The chart already exists but is deleted at the moment, do you want to restore it?"
        onClose={onDialogClose}
        onAction={() => onRestoreChartClick()}
        cancelLabel="Cancel"
        actionLabel="Restore chart"
        blockScrollOnMount={false}
        isActionLoading={restoreChartLoading}
        cancelButtonProps={{ color: "red" }}
        actionButtonProps={{ color: "blue" }}
      />
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        padding="20px 0 35px"
      >
        <Button variant="label" color="blue" onClick={onClose}>
          Cancel
        </Button>
        <Heading fontSize="1.0625rem">Add New Chart</Heading>
        <Button
          variant="label"
          color="blue"
          isDisabled={!selectedChart}
          onClick={onDone}
          isLoading={isLoading}
        >
          Save
        </Button>
      </ModalHeader>

      <ModalBody padding="0" overflow="auto">
        <Tabs
          isFitted
          size="sm"
          index={activeTab}
          variant="solid-rounded"
          onChange={(index) => {
            setActiveTab(index);
            setSearch("");
            setSearchInput("");
          }}
        >
          {addDeleteNursePermission && addDeleteProviderPermission && (
            <TabList index={activeTab} mb={6}>
              <>
                <Tab maxH="30">Providers</Tab>
                <Tab maxH="30">Nursing</Tab>
              </>
            </TabList>
          )}

          <SearchBar
            value={searchInput}
            placeholder="Search"
            onChange={(e) => {
              debouncedSearch(e.target.value);
              setSearchInput(e.target.value);
            }}
          />

          <TabPanels>
            <TabPanel>
              {addDeleteProviderPermission && (
                <ChartsList
                  chartType="Chart"
                  onChartClick={setSelectedChart}
                  selectedChart={selectedChart}
                  search={search}
                />
              )}
            </TabPanel>
            <TabPanel>
              {addDeleteNursePermission && (
                <ChartsList
                  chartType="Triage"
                  onChartClick={setSelectedChart}
                  selectedChart={selectedChart}
                  search={search}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>
    </>
  );
}

function AddPatientChartModal(props: AddPatientChartModalProps) {
  const { isOpen, onClose, encounterId, defaultChartType } = props;

  const toast = useToast();

  const { data: encounterIntegrations } = useEncounterIntegrations(encounterId);

  const { isLoading: isLoadingIntegrationStatus, updateIntegrationStatus } =
    useUpdateIntegrationsStatus(encounterId, "ayva");

  const onStatusChange = async () => {
    try {
      if (
        !isLoadingIntegrationStatus &&
        encounterIntegrations?.data.ayvaConnectionStatus !== "Success"
      ) {
        updateIntegrationStatus("InProcess");
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        width="540px"
        padding="0 15px 15px"
        maxWidth="540px"
        bg="gray.200"
        h="90%"
      >
        {isOpen && (
          <AddPatientChartModalContent
            onClose={onClose}
            encounterId={encounterId}
            onChartCreated={(newStatus: EncounterStatus) => {
              if (newStatus === "InProcess") onStatusChange();
            }}
            defaultChartType={defaultChartType}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { AddPatientChartModal };
