import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const BubbleChatIndicator = createIcon({
  path: (
    <path
      d="M20.4145 7.15026C18.456 7.15026 16.8394 5.54404 16.8394 3.57513C16.8394 1.61658 18.456 0 20.4145 0C22.3731 0 24 1.61658 24 3.57513C24 5.54404 22.3731 7.15026 20.4145 7.15026ZM4.15544 22.1762C3.32642 22.1762 3.06736 21.5233 3.52332 20.943C3.93782 20.4145 4.94301 19.285 5.54404 18.2902C5.64767 18.1036 5.59585 17.9482 5.39896 17.8446C2.01036 16.0104 0 13.0363 0 9.69948C0 4.35233 5.33679 0.0518135 12 0.0518135C13.513 0.0518135 14.9534 0.279793 16.2902 0.694301C15.7098 1.51295 15.3679 2.50777 15.3679 3.57513C15.3679 6.35233 17.6477 8.62176 20.4145 8.62176C21.6373 8.62176 22.7668 8.17617 23.6477 7.43005C23.8756 8.1658 24 8.92228 24 9.69948C24 15.2435 18.6943 19.3472 11.2124 19.3472C11.0984 19.3472 10.9741 19.3368 10.8497 19.3368C10.6839 19.3264 10.5181 19.3782 10.3212 19.5233C8.45596 20.8394 5.41969 22.1762 4.15544 22.1762Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 23",
});

export { BubbleChatIndicator };
