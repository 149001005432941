import { axiosPatientInstance } from "config";
import { PaymentForm, PaymentType } from "enums";
import { useAxiosQuery } from "hooks";
import { PaginationPayload, PaginationResponse } from "types";

type EncounterPayment = {
  id: string;
  date: string;
  type: PaymentType;
  form: PaymentForm;
  transactionNumber: string;
  amountDue: string;
  amountPaid: string;
  balance: string;
  hasFile: boolean;
};

type EncounterPaymentResponse = PaginationResponse<EncounterPayment>;
type UseEncounterPaymentsProps = PaginationPayload & { encounterId: string };

function useEncounterPayments(props: UseEncounterPaymentsProps) {
  const { size = 10, page = 1, encounterId, ...rest } = props;

  return useAxiosQuery(["encounterPayments", props], () =>
    axiosPatientInstance.get<EncounterPaymentResponse>(
      `v1/encounter/${encounterId}/form/payment`,
      {
        params: { pageSize: size, page, ...rest },
      }
    )
  );
}

export type {
  EncounterPaymentResponse,
  UseEncounterPaymentsProps,
  EncounterPayment,
};
export { useEncounterPayments };
