import React from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "shared";

type ToggleMedicationStatusProps = {
  name: string;
  isReadOnly?: boolean;
};

const ALL_MEDICATION_STATUSES = [
  { value: "Ordered", label: "Ordered", color: "orange" },
  { value: "InProcess", label: "In Process", color: "blue" },
  { value: "Completed", label: "Completed", color: "green" },
];

const valueToOption = (value?: string) => {
  if (!value) {
    return null;
  }

  return ALL_MEDICATION_STATUSES.find((x) => x.value === value);
};

function ToggleMedicationStatus({
  name,
  isReadOnly,
}: ToggleMedicationStatusProps) {
  const { watch, setValue } = useFormContext();

  const status = watch(name);

  const handleStatusChange = (value: string) => {
    if (value === "Ordered" || value === "InProcess") {
      setValue(name, value);
    }
    if (value === "Completed") {
      setValue(name, value);
    }
    if (value === "Ordered" || value === "InProcess") {
      return;
    }
    setValue(name, "Completed");
  };

  const color = React.useMemo(
    () => ALL_MEDICATION_STATUSES.find((x) => x.value === status)?.color,
    [status]
  );

  const medicationStatus = React.useMemo(() => {
    if (status === "InProcess")
      return ALL_MEDICATION_STATUSES.filter((x) => x.value !== "Ordered");

    if (status === "Completed")
      return ALL_MEDICATION_STATUSES.filter(
        (x) => x.value !== "InProcess" && x.value !== "Ordered"
      );

    return ALL_MEDICATION_STATUSES;
  }, [status]);

  return (
    <Select
      btnProps={{
        borderRadius: "20px",
        bgColor: color,
        color: "white",
        _hover: { bgColor: color },
      }}
      iconProps={{ color: "white" }}
      isDisabled={isReadOnly}
      items={medicationStatus}
      defaultSelectedItem={valueToOption(status)}
      labelAccessor="label"
      valueAccessor="value"
      showBlank={false}
      onSelectedItemChange={(x) =>
        x.selectedItem && handleStatusChange(x.selectedItem.value)
      }
    />
  );
}

export { ToggleMedicationStatus };
