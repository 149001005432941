import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Scribe = createIcon({
  path: (
    <path
      d="M15.2143 3.5382L13.1926 1.52626L14.306 0.432398C14.853 -0.104768 15.4683 -0.153601 15.9761 0.354265L16.3863 0.774231C16.8942 1.2821 16.8649 1.88763 16.318 2.43456L15.2143 3.5382ZM2.99625 15.727L0.554583 16.6646C0.24205 16.7818 -0.0997829 16.44 0.0271836 16.1079L0.994083 13.7248L12.216 2.50293L14.2181 4.51486L2.99625 15.727ZM4.17801 16.6548L6.36575 14.4769H18.9159C19.5117 14.4769 20 14.9652 20 15.561C20 16.1567 19.5117 16.6548 18.9159 16.6548H4.17801Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18",
});

export { Scribe };
