import {
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from "@chakra-ui/react";
import { HeartDocument, MoreHoriz } from "icons";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { isLastIndex } from "utils";

type OrderSectionHeaderMenuProps = {
  encounterId: string;
  redirect: { from: string; backLabel: string };
};

function OrderSectionHeaderMenu(props: OrderSectionHeaderMenuProps) {
  const { encounterId, redirect } = props;
  const navigate = useNavigate();
  const mainRoute = window.location.hash.split("/")[1];

  const MENU_OPTIONS: MenuItemProps[] = React.useMemo(
    () => [
      {
        key: "1",
        children: "Open Patient’s Chart",
        icon: <Icon as={HeartDocument} color="gray.700" h="18px" w="18px" />,
        onClick: () => {
          navigate(`/${mainRoute}/charts/${encounterId}/general/patient-info`, {
            state: {
              from: redirect.from,
              backLabel: redirect.backLabel,
            },
          });
        },
      },
    ],
    [encounterId, navigate, redirect, mainRoute]
  );

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="icon"
        icon={<Icon as={MoreHoriz} color="blue" />}
      >
        Actions
      </MenuButton>
      <MenuList zIndex="popover">
        {MENU_OPTIONS.map(
          ({ key, children, icon, isDisabled, onClick }, index) => (
            <React.Fragment key={key}>
              <MenuItem
                isDisabled={isDisabled}
                onClick={onClick}
                bg="white"
                fontSize="1.0625rem"
                fontWeight="400"
              >
                {children}
                {icon}
              </MenuItem>
              {!isLastIndex(index, MENU_OPTIONS) && <Divider />}
            </React.Fragment>
          )
        )}
      </MenuList>
    </Menu>
  );
}

export { OrderSectionHeaderMenu };
