import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection } from "modules/charts-shared";

function useMdm(encounterId: string) {
  return useAxiosQuery(["mdm", { encounterId }], () =>
    axiosChartInstance.get<ChartFormSubsection[]>(`v1/chart/${encounterId}/mdm`)
  );
}

export { useMdm };
