import { axiosIdentityInstance } from "config";
import { BedStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { UseEncounterSideInfoResponse } from "modules/reception/api";
import { useQueryClient } from "react-query";

type BedRequestDto = {
  bedId?: string;
  roomId?: string;
  encounterId?: string;
  name: string;
  status: BedStatus;
  encounter?: UseEncounterSideInfoResponse;
};

type UpdateBedPayload = BedRequestDto[];

function useUpdateBeds(roomId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateBed", { roomId }],
    (input: UpdateBedPayload) =>
      axiosIdentityInstance.put(`/v1/bed`, input, { params: { roomId } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
      },
    }
  );
}

export { useUpdateBeds };
export type { BedRequestDto, UpdateBedPayload };
