import cloneDeep from "lodash/cloneDeep";
import { genericErrors } from "messages";
import * as React from "react";

import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import faker from "faker";

import { useParams } from "react-router-dom";
import { Card, CheckboxButton, Loading, SingleActionDialog } from "shared";

import { SharedChartSectionHeader } from "modules/charts-shared/components";

import {
  AllergyRx,
  ChartRouteBaseParams,
  MedicationRx,
  SharedChartBaseProps,
} from "modules/charts-shared/types";

import {
  ALLERGY_RX_TYPE_LABEL,
  ALLERGY_RX_TYPE_OPTIONS,
  MEDICATION_RX_TYPE_LABEL,
  MEDICATION_RX_TYPE_OPTIONS,
} from "modules/charts-shared/constants";

import {
  EncounterAllergiesMedicationsRxPayload,
  useEncounterAllergiesMedicationsRx,
  useImportEncounterAllergiesMedicationsRx,
  useUpdateEncounterAllergiesMedicationsRx,
} from "modules/charts-shared/api";

import { AllergyRxType, MedicationRxType } from "enums";
import { useToast } from "hooks";
import { Edit, ExclamationTriangle } from "icons";
import { extractApiErrorMessage } from "utils";

import { useUpdateEncounterIntegrationAYVAAllergies } from "modules/reception/api";

import { AllergyItemModal } from "./AllergyItemModal";
import { MedicationsFile } from "./MedicationFile";

import { FormPrompt } from "shared/prompt";
import { AddMedicationModal } from "./AddMedicationModal";
import {
  AYVAAllergiesMedicationsRX,
  HL7AllergiesMedicationsRX,
} from "./AllergiesMedicationsRxIntegration";
import { RxSection, RxSectionGrouper } from "./AllergiesMedicationsSection";
import { AllergyRxInfo } from "./AllergyMedicationCard";

const sectionCode = "SAMRX";

type AllergyGroup = Record<AllergyRxType, AllergyRx[]>;
type MedicationGroup = Record<MedicationRxType, MedicationRx[]>;

const defaultAllergy: AllergyRx = {
  type: "DRUG_AL",
  allergy: "",
  scope: "PRESET",
  reactions: [],
};

type AllergiesMedicationsRxProps = Partial<SharedChartBaseProps> & {
  onBack?: () => void;
};

function AllergiesMedicationsRx(props: AllergiesMedicationsRxProps) {
  const { isReadOnly = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const [allergyModalMode, setAllergyModalMode] = React.useState<
    "ADD" | "EDIT"
  >("ADD");
  const [allergyItem, setAllergyItem] = React.useState<AllergyRx | null>(null);

  const toast = useToast();

  const { data, isLoading: isLoadingAllergiesMedicationsRx } =
    useEncounterAllergiesMedicationsRx({
      encounterId,
    });

  const {
    mutateAsync: updateAllergiesMedicationsRx,
    isLoading: isUpdatingAllergiesMedicationsRxLoading,
  } = useUpdateEncounterAllergiesMedicationsRx({ encounterId });

  const {
    mutateAsync: importAllergiesMedicationsRx,
    isLoading: isImportingAllergiesMedicationsRxLoading,
  } = useImportEncounterAllergiesMedicationsRx({ encounterId });

  const {
    mutateAsync: updateIntegrationAYVA,
    isLoading: isLoadingUpdateIntegrationAYVA,
  } = useUpdateEncounterIntegrationAYVAAllergies(encounterId);

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const [modalLabel, setModalLabel] = React.useState("");
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState<string[]>([]);
  const [isDirty, setIsDirty] = React.useState(false);

  const [allergiesGroups, setAllergiesGroups] = React.useState<AllergyGroup>({
    DRUG_AL: [],
    OTHER_AL: [],
  });

  const [nkda, setNKDA] = React.useState(false);
  const [none, setNone] = React.useState(false);
  const [addingType, setAddingType] = React.useState<MedicationRxType | null>(
    null
  );
  const [editingMedication, setEditingMedication] =
    React.useState<MedicationRx | null>(null);

  const [medicationsGroups, setMedicationsGroups] =
    React.useState<MedicationGroup>({
      PRESCRIPTION_RX: [],
      OTC_RX: [],
    });

  React.useEffect(() => {
    const allergyGroup: AllergyGroup = {
      DRUG_AL: [],
      OTHER_AL: [],
    };
    const medicationGroup: MedicationGroup = {
      PRESCRIPTION_RX: [],
      OTC_RX: [],
    };

    let allergyTypeElements = [];
    ALLERGY_RX_TYPE_OPTIONS.forEach((type: AllergyRxType) => {
      if (data && data?.data && data?.data?.allergiesRx) {
        if (data?.data?.allergiesRx.length > 0) {
          allergyTypeElements = data?.data?.allergiesRx
            .filter(
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              (el: AllergyRx) => el.type === type
            )
            .map((el) => ({ ...el, id: faker.datatype.uuid() }));

          allergyGroup[type] =
            allergyTypeElements.length > 0 ? allergyTypeElements : [];
        } else {
          allergyGroup[type] = [];
        }
      }
    });

    let medicationTypeElements = [];
    MEDICATION_RX_TYPE_OPTIONS.forEach((type: MedicationRxType) => {
      if (data && data?.data && data?.data?.medicationsRx) {
        if (data?.data?.medicationsRx.length > 0) {
          medicationTypeElements = data?.data?.medicationsRx
            .filter(
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              (el: MedicationRx) => el.type === type
            )
            .map((el) => ({ ...el, id: faker.datatype.uuid() }));

          medicationGroup[type] =
            medicationTypeElements.length > 0 ? medicationTypeElements : [];
        } else {
          if (!isReadOnly) medicationGroup[type] = [];
        }
      }
    });

    setAllergiesGroups(allergyGroup);
    setMedicationsGroups(medicationGroup);
    setNKDA(data?.data.nkda ?? false);
    setNone(data?.data.none ?? false);
  }, [data, isReadOnly]);

  const handleSaveAYVAAllergiesMedicationsRx = async () => {
    try {
      const ayvaRequest = await updateIntegrationAYVA({
        encounterId: encounterId,
      });

      setModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
      setModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
      setModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
      onOpenModal();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const mapAllergiesMedicationsToPayload = () => {
    const allergyRxList: AllergyRx[] = [];
    const medicationRxList: MedicationRx[] = [];

    ALLERGY_RX_TYPE_OPTIONS.forEach((type: AllergyRxType) => {
      allergyRxList.push(
        ...allergiesGroups[type].filter((el) => el.allergy !== "")
      );
    });

    MEDICATION_RX_TYPE_OPTIONS.forEach((type: MedicationRxType) => {
      medicationRxList.push(
        ...medicationsGroups[type].filter(
          (el) =>
            el.medName !== "" && el.medDosage !== "" && el.medFrequency !== ""
        )
      );
    });

    return {
      allergyRxRequest: { allergyRxList },
      medicationRxRequest: { medicationRxList },
      nkda,
      none,
    };
  };

  const onImportAllergiesMedicationsRx = async () => {
    try {
      const importRequest = await importAllergiesMedicationsRx({ encounterId });

      setModalLabel("Ok");
      setModalTitle("Warning!");
      if (importRequest.data) {
        setModalContent([
          "This patient’s Allergies & Medications have been imported from their last encounter.",
          "Please review to make sure this information is up to date.",
        ]);
      } else {
        setModalContent([
          "This patient has no previous encounters.  There is no Allergies & Medications to be imported.",
        ]);
      }

      onOpenModal();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const onSaveAllergiesMedicationsRx = async (onComplete?: () => void) => {
    try {
      const payload: EncounterAllergiesMedicationsRxPayload =
        mapAllergiesMedicationsToPayload();

      const allergyPayloadInvalid = payload.allergyRxRequest.allergyRxList.find(
        (el) => el.allergy === ""
      );

      const medicationPayloadInvalid =
        payload.medicationRxRequest.medicationRxList.find(
          (el) =>
            el.medName === "" || el.medDosage === "" || el.medFrequency === ""
        );
      setIsDirty(false);

      if (
        !payload.nkda &&
        !payload.none &&
        payload.allergyRxRequest.allergyRxList.length === 0 &&
        payload.medicationRxRequest.medicationRxList.length === 0
      ) {
        toast({
          description:
            "You need to add at least one element for one of the allergies/medications categories.",
        });

        return;
      }

      if (typeof allergyPayloadInvalid !== "undefined") {
        toast({ description: "One of the allergies has incomplete info." });

        return;
      }

      if (typeof medicationPayloadInvalid !== "undefined") {
        toast({ description: "One of the medications has incomplete info." });

        return;
      }

      await updateAllergiesMedicationsRx(payload);
      toast({
        description:
          "Updated Allergies/Medications Rx. sending info to AYVA&Ramsoft...",
      });

      await handleSaveAYVAAllergiesMedicationsRx();

      onComplete?.();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleAddAllergyItem = (type: AllergyRxType) => {
    setAllergyItem({
      ...defaultAllergy,
      type,
      id: faker.datatype.uuid(),
    });

    setAllergyModalMode("ADD");
  };

  const handleEditAllergyItem = (item: AllergyRx) => {
    setAllergyItem(item);
    setAllergyModalMode("EDIT");
  };

  const onAddAllergyItem = (type: AllergyRxType, item: AllergyRx) => {
    const updatedAllergiesGroup = cloneDeep(allergiesGroups);
    updatedAllergiesGroup[type].push({
      ...item,
      type,
      id: faker.datatype.uuid(),
    });

    setAllergiesGroups(updatedAllergiesGroup);
    setIsDirty(true);
  };

  const onUpdateAllergyItem = (type: AllergyRxType, item: AllergyRx) => {
    const updatedAllergiesGroup = cloneDeep(allergiesGroups);

    const updatedIndex = updatedAllergiesGroup[type].findIndex(
      (el) => el.id === item.id
    );

    updatedAllergiesGroup[type][updatedIndex] = { ...item };

    setAllergiesGroups(updatedAllergiesGroup);
    setIsDirty(true);
  };

  const onRemoveAllergyItem = (type: AllergyRxType, item: AllergyRx) => {
    const updatedAllergiesGroup = cloneDeep(allergiesGroups);
    updatedAllergiesGroup[type] = updatedAllergiesGroup[type].filter(
      (el) => el.id !== item.id
    );

    setAllergiesGroups(updatedAllergiesGroup);
    setIsDirty(true);
  };

  const handleAddMedicationItem = (item: MedicationRx) => {
    const updatedMedicationsGroup = cloneDeep(medicationsGroups);

    if (item.id) {
      const updatedIndex = updatedMedicationsGroup[item.type].findIndex(
        (el) => el.id === item.id
      );
      updatedMedicationsGroup[item.type][updatedIndex] = { ...item };
    } else {
      updatedMedicationsGroup[item.type].push({
        ...item,
        id: faker.datatype.uuid(),
      });
    }
    setMedicationsGroups(updatedMedicationsGroup);
    setAddingType(null);
    setIsDirty(true);
  };

  const handleDeleteMedicationItem = (item: MedicationRx) => {
    const updatedMedicationsGroup = cloneDeep(medicationsGroups);
    updatedMedicationsGroup[item.type] = updatedMedicationsGroup[
      item.type
    ].filter((el) => el.id !== item.id);

    setMedicationsGroups(updatedMedicationsGroup);
    setAddingType(null);
    setEditingMedication(null);
    setIsDirty(true);
  };

  return (
    <Box
      height="100%"
      w="100%"
      minHeight="600px"
      backgroundColor="gray.50"
      px="20px"
      pt="15px"
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="15px"
      >
        <HL7AllergiesMedicationsRX encounterId={encounterId} />

        <AYVAAllergiesMedicationsRX
          encounterId={encounterId}
          isLoadingUpdateIntegrationAYVA={isLoadingUpdateIntegrationAYVA}
        />
      </Box>

      <Box display="flex" flexDirection="column" width="100%">
        <SharedChartSectionHeader
          icon={<Icon as={ExclamationTriangle} />}
          encounterId={encounterId}
          sectionId={sectionCode}
          showNotesBtn
          showImportBtn
          showActionsBtns={!isReadOnly}
          onImport={() => {
            if (!isReadOnly) onImportAllergiesMedicationsRx();
          }}
          onSave={() => {
            if (!isReadOnly) onSaveAllergiesMedicationsRx();
          }}
          wrapperStyles={{
            width: "100%",
            padding: "0rem",
            margin: "0rem",
            display: "flex",
            alignSelf: "center",
            marginBottom: "20px !important",
          }}
          sx={{
            width: "100%",
            height: "65px",
            sectionBgColor: "gray.200",
            sectionPadding: "0",
            bg: "white",
          }}
        >
          Allergies & Medications {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>

        {isLoadingAllergiesMedicationsRx ||
        isImportingAllergiesMedicationsRxLoading ||
        isUpdatingAllergiesMedicationsRxLoading ? (
          <Box h="100%" alignItems="center">
            <Loading />
          </Box>
        ) : (
          <Flex>
            <Box
              display="flex"
              flex={5}
              overflow="auto"
              overflowX="hidden"
              p="0px 20px 20px 0px"
              columnGap="0.875rem"
              maxHeight="80vh"
            >
              <Flex direction="column" rowGap="1rem" flex={1}>
                <Card
                  variant="smooth"
                  padding="1rem 1.25rem"
                  bgColor="gray.200"
                >
                  <Text fontSize="1.125rem" fontWeight="600">
                    Allergies
                  </Text>
                </Card>
                {ALLERGY_RX_TYPE_OPTIONS.map(
                  (el: AllergyRxType, idx: number) => (
                    <RxSectionGrouper
                      key={idx}
                      label={ALLERGY_RX_TYPE_LABEL[el]}
                      onAddItem={() => {
                        if (!isReadOnly) handleAddAllergyItem(el);
                      }}
                      isAddDisabled={
                        (el === "DRUG_AL" && nkda) ||
                        (el === "OTHER_AL" && none) ||
                        isReadOnly
                      }
                      headerActionButton={
                        el === "DRUG_AL" ? (
                          <CheckboxButton
                            px="10px"
                            fontSize="1rem"
                            fontWeight="600"
                            isChecked={nkda}
                            isDisabled={
                              Boolean(allergiesGroups[el].length) || isReadOnly
                            }
                            onChange={() => {
                              setNKDA((prev) => !prev);
                            }}
                          >
                            NKDA
                          </CheckboxButton>
                        ) : (
                          <CheckboxButton
                            px="10px"
                            fontSize="1rem"
                            fontWeight="600"
                            isChecked={none}
                            isDisabled={
                              Boolean(allergiesGroups[el].length) || isReadOnly
                            }
                            onChange={() => {
                              setNone((prev) => !prev);
                            }}
                          >
                            none
                          </CheckboxButton>
                        )
                      }
                      grouperElements={
                        allergiesGroups[el].length > 0 ? (
                          <Card
                            w="100%"
                            minHeight="50px"
                            boxShadow="none"
                            border="none"
                          >
                            {allergiesGroups[el].map((item, idx2, arr) => (
                              <Box>
                                <RxSection
                                  key={idx2}
                                  sectionContent={
                                    <AllergyRxInfo
                                      type={el}
                                      item={item}
                                      onEditItem={() => {
                                        if (!isReadOnly)
                                          handleEditAllergyItem(item);
                                      }}
                                    />
                                  }
                                />

                                {idx2 < arr.length - 1 && (
                                  <Box p="0.5rem 1.5rem 0 1.5rem">
                                    <Divider height="0px" />
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Card>
                        ) : (
                          <></>
                        )
                      }
                    />
                  )
                )}
              </Flex>
              <Flex direction="column" flex={1} rowGap="1rem">
                <Card
                  variant="smooth"
                  padding="1rem 1.25rem"
                  bgColor="gray.200"
                >
                  <Text fontSize="1.125rem" fontWeight="600">
                    Medications
                  </Text>
                </Card>
                {MEDICATION_RX_TYPE_OPTIONS.map(
                  (el: MedicationRxType, idx: number) => (
                    <RxSectionGrouper
                      key={idx}
                      label={MEDICATION_RX_TYPE_LABEL[el]}
                      onAddItem={() => {
                        if (!isReadOnly) setAddingType(el);
                      }}
                      isAddDisabled={isReadOnly}
                      grouperElements={
                        medicationsGroups[el].length > 0 ? (
                          <Card
                            w="100%"
                            minHeight="50px"
                            p="1rem 1.5rem"
                            boxShadow="none"
                            border="none"
                          >
                            {medicationsGroups[el].map((item) => (
                              <Box key={item.id}>
                                <Flex>
                                  <Text
                                    flex={1}
                                    fontWeight="500"
                                    fontSize="1rem"
                                  >
                                    {item.medName}
                                  </Text>
                                  <IconButton
                                    variant="icon"
                                    aria-label="edit"
                                    color="blue"
                                    onClick={() => {
                                      setEditingMedication(item);
                                    }}
                                    isDisabled={isReadOnly}
                                  >
                                    <Icon as={Edit} />
                                  </IconButton>
                                </Flex>
                                <Box pl="2rem">
                                  <ul>
                                    <li
                                      style={{
                                        padding: "0.5rem 0.75rem",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                      }}
                                    >{`${item.medDosage}, ${item.medFrequency}`}</li>
                                  </ul>
                                </Box>
                              </Box>
                            ))}
                          </Card>
                        ) : (
                          <></>
                        )
                      }
                    />
                  )
                )}
              </Flex>
            </Box>
            <Box flex={4} display="flex" flexDirection="column" overflow="auto">
              <Card
                variant="default"
                overflow="hidden"
                height="calc(100% - 50px)"
              >
                <MedicationsFile encounterId={encounterId} />
              </Card>
            </Box>
          </Flex>
        )}
      </Box>

      <AllergyItemModal
        isOpen={Boolean(allergyItem)}
        onClose={() => setAllergyItem(null)}
        onAddItem={onAddAllergyItem}
        onUpdateItem={onUpdateAllergyItem}
        onRemoveItem={onRemoveAllergyItem}
        item={allergyItem}
        mode={allergyModalMode}
      />

      {isOpenModal && (
        <SingleActionDialog
          title={modalTitle}
          actionLabel={modalLabel}
          actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
          content={modalContent}
          onClose={onCloseModal}
          isOpen={isOpenModal}
        />
      )}
      <FormPrompt
        mainText="You have unsaved changes in this section!"
        onSave={onSaveAllergiesMedicationsRx}
        title="Warning"
        when={isDirty && !isReadOnly}
        isSaveLoading={isUpdatingAllergiesMedicationsRxLoading}
        secondaryText=""
      />
      {(addingType || editingMedication) && (
        <AddMedicationModal
          isOpen={Boolean(addingType) || Boolean(editingMedication)}
          onClose={() => {
            setAddingType(null);
            setEditingMedication(null);
          }}
          type={addingType ?? editingMedication?.type ?? "PRESCRIPTION_RX"}
          onAddItem={handleAddMedicationItem}
          onDeleteItem={handleDeleteMedicationItem}
          editingMedication={editingMedication}
        />
      )}
    </Box>
  );
}

export { AllergiesMedicationsRx };
