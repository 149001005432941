import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { nextRoutesNurse, nextRoutesProvider } from "../constants";

const useSaveAndNext = (
  onSubmit: () => Promise<void>,
  currentRoute: string,
  type: "nurse" | "provider",
  isDirty = false
) => {
  const [nextRoute, setNextRoute] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [isDirty, nextRoute, navigate]);

  const handleSaveAndNext = useCallback(() => {
    const nextRoutes = type === "nurse" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(currentRoute) + 1];
    onSubmit().then(() => {
      setNextRoute(`./../${route}`);
    });
  }, [onSubmit, currentRoute, type, setNextRoute]);

  return handleSaveAndNext;
};

export { useSaveAndNext };
