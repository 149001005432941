import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { ContrastType, OralOptions } from "enums";
import { useQueryClient } from "react-query";

type UsePuOrderContrastPayload = {
  type: ContrastType;
  options: OralOptions;
  seconds: number;
};

function usePuOrderContrast(orderId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["putContrast", { orderId }],
    (payload: UsePuOrderContrastPayload) =>
      axiosOrderInstance.put(`v1/contrast/${orderId}`, payload),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["orderContrast", { orderId }]),
    }
  );
}

export type { UsePuOrderContrastPayload };
export { usePuOrderContrast };
