import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Circle = createIcon({
  path: (
    <path
      d="M8.00406 16C12.384 16 16 12.384 16 7.99594C16 3.61605 12.3758 0 7.99594 0C3.61605 0 0 3.61605 0 7.99594C0 12.384 3.61605 16 8.00406 16ZM8.00406 14.2854C4.51803 14.2854 1.7227 11.482 1.7227 7.99594C1.7227 4.5099 4.5099 1.71458 7.99594 1.71458C11.482 1.71458 14.2773 4.5099 14.2854 7.99594C14.2936 11.482 11.482 14.2854 8.00406 14.2854Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 16",
});

export { Circle };
