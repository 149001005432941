import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const CloudCheck = createIcon({
  path: (
    <path
      d="M22.3307 20H6.95893C3.01887 20 0 17.1254 0 13.6737C0 10.8546 1.57603 8.54606 4.13984 8.05771C4.27303 4.91676 7.22531 2.80799 10.0999 3.52941C11.5316 1.47614 13.7847 0 16.7148 0C21.4317 0 25.0832 3.6515 25.0832 8.71254C27.303 9.64484 28.657 11.7758 28.657 14.162C28.657 17.4029 25.8602 20 22.3307 20ZM13.5072 15.3274C13.9179 15.3274 14.2841 15.1276 14.5061 14.7614L18.8679 7.82464C19.0011 7.61376 19.1343 7.34739 19.1343 7.08102C19.1343 6.52608 18.646 6.09323 18.0577 6.09323C17.6915 6.09323 17.3585 6.30411 17.1365 6.68147L13.4739 12.7414L11.6093 10.3885C11.3762 10.0888 11.0988 9.93341 10.7325 9.93341C10.1554 9.93341 9.68923 10.3774 9.68923 10.9767C9.68923 11.232 9.77802 11.4872 9.9889 11.7314L12.4639 14.7836C12.7636 15.1498 13.0966 15.3274 13.5072 15.3274Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 29 20",
});

export { CloudCheck };
