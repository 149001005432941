const cardTheme = {
  baseStyle: {
    background: "#FFFFFF",
    boxShadow:
      "0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)",
  },

  variants: {
    smooth: {
      borderRadius: "1rem",
      border: "1px solid #D1D1D6",
    },
    rounded: {
      borderRadius: "1.75rem",
    },
    validator: {
      borderRadius: "10px",
      border: "1px solid #D1D1D6",
      borderLeft: "10px solid #D1D1D6",
    },
    basic: {
      borderRadius: "10px",
      border: "none",
      boxShadow: "none",
    },
    default: {
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderColor: "gray.450",
      borderWidth: "1px",
    },
  },
  // The default variant value
  defaultProps: {
    variant: "smooth",
  },
};

export { cardTheme };
