import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { Clear } from "icons";
import React, { useEffect, useCallback } from "react";
import { Button } from "shared";
import SignaturePad from "signature_pad";

type SignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSign: (signature: string) => void;
  isLoading?: boolean;
};

const SignatureModal = (props: SignatureModalProps) => {
  const toast = useToast();
  const { isOpen, onClose, onSign, isLoading } = props;
  const signaturePad = React.useRef<SignaturePad>();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const initializeSignaturePad = useCallback(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    canvas.width = rect.width * ratio;
    canvas.height = rect.height * ratio;

    const context = canvas.getContext("2d");
    if (context) {
      context.scale(ratio, ratio);
      context.clearRect(0, 0, canvas.width, canvas.height);
    }

    if (signaturePad.current) {
      signaturePad.current.off();
    }

    signaturePad.current = new SignaturePad(canvas, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "black",
      velocityFilterWeight: 0.7,
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        initializeSignaturePad();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isOpen, initializeSignaturePad]);

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        initializeSignaturePad();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen, initializeSignaturePad]);

  const onSignatureDone = () => {
    if (!signaturePad.current?.isEmpty()) {
      const data = signaturePad.current?.toDataURL() || "";
      onSign(data);
      onClose();
    } else {
      toast({
        status: "error",
        description:
          "Please sign your name with your finger or the stylus pen.",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent borderRadius="5px" minWidth="860px" minHeight="360px">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bgColor="gray.200"
          borderTopRadius="5px"
        >
          <Button variant="label" color="blue" onClick={onClose}>
            Cancel
          </Button>
          <Box display="flex" alignContent="center" gap="0.5rem">
            <Icon as={EmrSignature} fontSize="1.25rem" color="black" />
            <Text fontSize="15px">Add Signature</Text>
          </Box>

          <Button
            variant="label"
            color="blue"
            onClick={onSignatureDone}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Done
          </Button>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column">
          <Box flex="1" pt={8}>
            <canvas
              ref={canvasRef}
              style={{
                width: "100%",
                height: "125px",
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23DFDFDFFF' stroke-width='3' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                borderRadius: "10px",
                backgroundColor: "#F8F8F8",
                touchAction: "none",
              }}
              id="signature-canvas"
            />
          </Box>
          <Text textAlign="center" color="gray.750" fontSize="15px">
            Sign your name here with your finger or the stylus pen.
          </Text>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Button
            leftIcon={<Icon as={Clear} color="blue" />}
            variant="ghost"
            color="blue"
            fontSize="17px"
            onClick={() => signaturePad.current?.clear()}
          >
            Clear
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { SignatureModal as default };
