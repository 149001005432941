import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { InsuranceCardType } from "modules/reception/types";

type UseEncounterInsuranceBenefitsFileProps = {
  encounterId: string;
  insuranceType: InsuranceCardType;
};

function useEncounterInsuranceBenefitsFile(
  props: UseEncounterInsuranceBenefitsFileProps
) {
  const { encounterId, insuranceType } = props;

  return useAxiosQuery(["encounterInsuranceBenefitsFile", props], () =>
    axiosPatientInstance.get<Blob>(
      `v1/encounter/${encounterId}/form/files/insurance/${insuranceType}/benefit`,
      { responseType: "blob" }
    )
  );
}

export type { UseEncounterInsuranceBenefitsFileProps };
export { useEncounterInsuranceBenefitsFile };
