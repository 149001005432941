import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseImportProviderMedicalHistoryProps = {
  encounterId: string;
};

function useImportProviderMedicalHistory(
  params: UseImportProviderMedicalHistoryProps
) {
  const { encounterId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["importProviderMedicalHistory", { encounterId }],
    () =>
      axiosChartInstance.patch<boolean>(
        `v1/chart/${encounterId}/medical-history/import-previous`
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "providerMedicalHistory",
          { encounterId },
        ]),
    }
  );
}

export { useImportProviderMedicalHistory };
