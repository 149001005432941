import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const OpenChartsAlt = createIcon({
  path: (
    <path
      d="M0 20.8987C0 22.9442 1.05577 24 3.07305 24H11.8963C13.9136 24 14.9694 22.9348 14.9694 20.8987V19.1548H16.5719C18.5891 19.1548 19.6449 18.0896 19.6449 16.0534V3.11076C19.6449 1.0652 18.5891 0 16.5719 0H7.74862C5.73134 0 4.67557 1.0652 4.67557 3.11076V4.84525H3.07305C1.05577 4.84525 0 5.91987 0 7.95601V20.8987ZM9.93558 3.38413C9.52081 3.38413 9.29458 3.10134 9.29458 2.79026V2.57345C9.29458 2.29065 9.50196 1.97015 9.93558 1.97015H14.3755C14.8185 1.97015 15.0165 2.29065 15.0165 2.57345V2.79026C15.0165 3.10134 14.7997 3.38413 14.3755 3.38413H9.93558ZM1.87588 20.7761V8.07856C1.87588 7.21131 2.32836 6.73056 3.24273 6.73056H6.11783V11.8586C6.11783 13.2255 6.78711 13.8853 8.14454 13.8853H13.0935V20.7761C13.0935 21.6434 12.641 22.1241 11.7266 22.1241H3.23331C2.32836 22.1241 1.87588 21.6434 1.87588 20.7761ZM8.32364 12.2357C7.93715 12.2357 7.76747 12.0754 7.76747 11.6795V7.08877L12.839 12.2357H8.32364Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 24",
});

export { OpenChartsAlt };
