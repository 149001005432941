import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const MinusRoundFilled = createIcon({
  path: (
    <path
      d="M9 18C13.9226 18 18 13.9313 18 9C18 4.07744 13.9226 0 8.99129 0C4.06873 0 0 4.07744 0 9C0 13.9313 4.07744 18 9 18ZM5.57599 9.86254C5.02711 9.86254 4.64376 9.54889 4.64376 9.01743C4.64376 8.48596 5.00968 8.15489 5.57599 8.15489H12.4327C12.999 8.15489 13.3562 8.48596 13.3562 9.01743C13.3562 9.54889 12.9816 9.86254 12.4327 9.86254H5.57599Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { MinusRoundFilled };
