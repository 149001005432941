import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { DiagramInfo } from "../../types";

type UseDiagramCategoriesResponse = {
  heads: DiagramInfo[];
  body: DiagramInfo[];
  miscellaneous: DiagramInfo[];
};

function useDiagramCategories() {
  return useAxiosQuery(["diagramsCategories"], () =>
    axiosChartInstance.get<UseDiagramCategoriesResponse>("v1/diagram")
  );
}

export type { UseDiagramCategoriesResponse };
export { useDiagramCategories };
