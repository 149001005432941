import { Box, BoxProps, Button } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Card, FormControl, Input } from "shared";

type SearchBarWidgetCardProps = {
  fields: { label: string; value: string }[];
  onChange: (values: Record<string, string>) => void;
} & Omit<BoxProps, "onChange">;

function SearchBarWidgetCard({
  fields,
  onChange,
  ...containerProps
}: SearchBarWidgetCardProps) {
  const [filters, setFilters] = useState<Record<string, string>>({});
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleOnChange = (value: string, key: string) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [key]: value };
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = setTimeout(() => {
        onChange(newFilters);
      }, 500);

      return newFilters;
    });
  };

  const handleReset = () => {
    setFilters({});
    onChange({});
  };

  return (
    <Card {...containerProps}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        marginTop="0.625rem"
        padding="30px"
        alignItems="flex-end"
        columnGap="0.5rem"
      >
        {fields.map(({ label, value }) => (
          <FormControl label={label}>
            <Input
              type="text"
              placeholder={label}
              value={filters?.[value] ?? ""}
              onChange={(e) => handleOnChange(e.target.value, value)}
            />
          </FormControl>
        ))}
        <Button
          variant="action"
          colorScheme="dark-gray"
          onClick={handleReset}
          width="280px"
          borderRadius="10px"
        >
          Clear Search
        </Button>
      </Box>
    </Card>
  );
}

export { SearchBarWidgetCard };
