import { TriageChartCode } from "enums";
import {
  N01Actions,
  N01Assessments,
  N01ChiefComplaint,
  N01Procedures,
  N02Actions,
  N02Assessments,
  N02ChiefComplaint,
  N02Procedures,
  N03Actions,
  N03Assessments,
  N03ChiefComplaint,
  N03Procedures,
  N06Actions,
  N06Assessments,
  N06ChiefComplaint,
  N06Procedures,
  N07Actions,
  N07Assessments,
  N07ChiefComplaint,
  N07Procedures,
  N08Actions,
  N08Assessments,
  N08ChiefComplaint,
  N08Procedures,
  N10Actions,
  N10Assessments,
  N10ChiefComplaint,
  N10Procedures,
  N11Actions,
  N11Assessments,
  N11ChiefComplaint,
  N11Procedures,
  N12Actions,
  N12Assessments,
  N12ChiefComplaint,
  N12Procedures,
  N13Actions,
  N13Assessments,
  N13ChiefComplaint,
  N13Procedures,
  N15Actions,
  N15Assessments,
  N15ChiefComplaint,
  N15Procedures,
  N24Actions,
  N24Assessments,
  N24ChiefComplaint,
  N24Procedures,
  N25Actions,
  N25Assessments,
  N25ChiefComplaint,
  N25Procedures,
  N28Actions,
  N28Assessments,
  N28ChiefComplaint,
  N28Procedures,
  N29Actions,
  N29Assessments,
  N29ChiefComplaint,
  N29Procedures,
  N30Actions,
  N30Assessments,
  N30ChiefComplaint,
  N30Procedures,
  N31Actions,
  N31Assessments,
  N31ChiefComplaint,
  N31Procedures,
  N32Actions,
  N32Assessments,
  N32ChiefComplaint,
  N32Procedures,
  N33Actions,
  N33Assessments,
  N33ChiefComplaint,
  N33Procedures,
  N35Actions,
  N35Assessments,
  N35ChiefComplaint,
  N35Procedures,
  N36Actions,
  N36Assessments,
  N36ChiefComplaint,
  N36Procedures,
  N39Actions,
  N39Assessments,
  N39ChiefComplaint,
  N39Procedures,
  N40Actions,
  N40Assessments,
  N40ChiefComplaint,
  N40Procedures,
  N42Actions,
  N42Assessments,
  N42ChiefComplaint,
  N42Procedures,
  N51Actions,
  N51Assessments,
  N51ChiefComplaint,
  N51Procedures,
  N57Actions,
  N57Assessments,
  N57ChiefComplaint,
  N57Procedures,
  N58Actions,
  N58Assessments,
  N58ChiefComplaint,
  N58Procedures,
} from "modules/triage/components";
import * as React from "react";
import {
  AllergiesMedicationsRx,
  ArrivalInfo,
  IvAndMedications,
  Treatments,
} from "modules/triage/shared";
import { ChartType } from "types";
import {
  EKG,
  LabsChart,
  ProviderMedicalHistory,
  RadiologyChart,
} from "modules/provider/shared";
import { Disposition, OrderHistory, Progress, Supplies } from "../components";
import {
  N09Assessments,
  N09ChiefComplaint,
  N09Actions,
  N09Procedures,
} from "modules/triage/components/n09";
import {
  N70Assessments,
  N70ChiefComplaint,
  N70Actions,
  N70Procedures,
} from "modules/triage/components/n70";

const PROVIDER_CHART_TYPE: ChartType = "Triage";

type TriageSharedChartSection =
  | "SAMRX"
  | "SARI"
  | "SDIS"
  | "SMHX"
  | "ORHI"
  | "SSUP"
  | "SIVM"
  | "STRE"
  | "SLAB"
  | "SRAD"
  | "SEKG"
  | "SPGR";

type TriageChartSection =
  | TriageSharedChartSection
  | "SASS"
  | "SCHC"
  | "SACT"
  | "SPRO";

const getSharedCharts = (chartCode: TriageChartCode) => ({
  SAMRX: () => (
    <AllergiesMedicationsRx
      chartCode={chartCode}
      chartType={PROVIDER_CHART_TYPE}
      isReadOnly
    />
  ),
  SARI: () => <ArrivalInfo chartCode={chartCode} isReadOnly />,
  SDIS: () => (
    <Disposition
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SMHX: () => <ProviderMedicalHistory chartCode={chartCode} isReadOnly />,
  ORHI: () => (
    <OrderHistory
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SSUP: () => (
    <Supplies
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SIVM: () => <IvAndMedications chartCode={chartCode} isReadOnly />,
  STRE: () => <Treatments chartCode={chartCode} isReadOnly />,
  SLAB: () => <LabsChart chartCode={"C001x"} isReadOnly />,
  SRAD: () => <RadiologyChart chartCode={"C001x"} isReadOnly />,
  SEKG: () => <EKG chartCode={chartCode} isReadOnly />,
  SPGR: () => <Progress chartType="Triage" isReadOnly />,
});

const NursingCharts: Partial<
  Record<TriageChartCode, Record<TriageChartSection, () => React.ReactElement>>
> = {
  T001x: {
    SASS: N01Assessments,
    SCHC: N01ChiefComplaint,
    SACT: N01Actions,
    SPRO: N01Procedures,
    ...getSharedCharts("T001x"),
  },
  T002x: {
    SASS: N02Assessments,
    SCHC: N02ChiefComplaint,
    SACT: N02Actions,
    SPRO: N02Procedures,
    ...getSharedCharts("T002x"),
  },
  T003x: {
    SASS: N03Assessments,
    SCHC: N03ChiefComplaint,
    SACT: N03Actions,
    SPRO: N03Procedures,
    ...getSharedCharts("T003x"),
  },
  T006x: {
    SASS: N06Assessments,
    SCHC: N06ChiefComplaint,
    SACT: N06Actions,
    SPRO: N06Procedures,
    ...getSharedCharts("T006x"),
  },
  T007x: {
    SASS: N07Assessments,
    SCHC: N07ChiefComplaint,
    SACT: N07Actions,
    SPRO: N07Procedures,
    ...getSharedCharts("T007x"),
  },
  T008x: {
    SASS: N08Assessments,
    SCHC: N08ChiefComplaint,
    SACT: N08Actions,
    SPRO: N08Procedures,
    ...getSharedCharts("T008x"),
  },
  T009x: {
    SASS: N09Assessments,
    SCHC: N09ChiefComplaint,
    SACT: N09Actions,
    SPRO: N09Procedures,
    ...getSharedCharts("T009x"),
  },
  T010x: {
    SASS: N10Assessments,
    SCHC: N10ChiefComplaint,
    SACT: N10Actions,
    SPRO: N10Procedures,
    ...getSharedCharts("T010x"),
  },
  T011x: {
    SASS: N11Assessments,
    SCHC: N11ChiefComplaint,
    SACT: N11Actions,
    SPRO: N11Procedures,
    ...getSharedCharts("T011x"),
  },
  T012x: {
    SASS: N12Assessments,
    SCHC: N12ChiefComplaint,
    SACT: N12Actions,
    SPRO: N12Procedures,
    ...getSharedCharts("T012x"),
  },
  T013x: {
    SASS: N13Assessments,
    SCHC: N13ChiefComplaint,
    SACT: N13Actions,
    SPRO: N13Procedures,
    ...getSharedCharts("T013x"),
  },
  T015x: {
    SASS: N15Assessments,
    SCHC: N15ChiefComplaint,
    SACT: N15Actions,
    SPRO: N15Procedures,
    ...getSharedCharts("T015x"),
  },
  T024x: {
    SASS: N24Assessments,
    SCHC: N24ChiefComplaint,
    SACT: N24Actions,
    SPRO: N24Procedures,
    ...getSharedCharts("T024x"),
  },
  T025x: {
    SASS: N25Assessments,
    SCHC: N25ChiefComplaint,
    SACT: N25Actions,
    SPRO: N25Procedures,
    ...getSharedCharts("T025x"),
  },
  T028x: {
    SASS: N28Assessments,
    SCHC: N28ChiefComplaint,
    SACT: N28Actions,
    SPRO: N28Procedures,
    ...getSharedCharts("T028x"),
  },
  T029x: {
    SASS: N29Assessments,
    SCHC: N29ChiefComplaint,
    SACT: N29Actions,
    SPRO: N29Procedures,
    ...getSharedCharts("T029x"),
  },
  T030x: {
    SASS: N30Assessments,
    SCHC: N30ChiefComplaint,
    SACT: N30Actions,
    SPRO: N30Procedures,
    ...getSharedCharts("T030x"),
  },
  T031x: {
    SASS: N31Assessments,
    SCHC: N31ChiefComplaint,
    SACT: N31Actions,
    SPRO: N31Procedures,
    ...getSharedCharts("T031x"),
  },
  T032x: {
    SASS: N32Assessments,
    SCHC: N32ChiefComplaint,
    SACT: N32Actions,
    SPRO: N32Procedures,
    ...getSharedCharts("T032x"),
  },
  T033x: {
    SASS: N33Assessments,
    SCHC: N33ChiefComplaint,
    SACT: N33Actions,
    SPRO: N33Procedures,
    ...getSharedCharts("T033x"),
  },
  T035x: {
    SASS: N35Assessments,
    SCHC: N35ChiefComplaint,
    SACT: N35Actions,
    SPRO: N35Procedures,
    ...getSharedCharts("T035x"),
  },
  T036x: {
    SASS: N36Assessments,
    SCHC: N36ChiefComplaint,
    SACT: N36Actions,
    SPRO: N36Procedures,
    ...getSharedCharts("T036x"),
  },
  T039x: {
    SASS: N39Assessments,
    SCHC: N39ChiefComplaint,
    SACT: N39Actions,
    SPRO: N39Procedures,
    ...getSharedCharts("T039x"),
  },
  T040x: {
    SASS: N40Assessments,
    SCHC: N40ChiefComplaint,
    SACT: N40Actions,
    SPRO: N40Procedures,
    ...getSharedCharts("T040x"),
  },
  T042x: {
    SASS: N42Assessments,
    SCHC: N42ChiefComplaint,
    SACT: N42Actions,
    SPRO: N42Procedures,
    ...getSharedCharts("T042x"),
  },
  T051x: {
    SASS: N51Assessments,
    SCHC: N51ChiefComplaint,
    SACT: N51Actions,
    SPRO: N51Procedures,
    ...getSharedCharts("T051x"),
  },
  T057x: {
    SASS: N57Assessments,
    SCHC: N57ChiefComplaint,
    SACT: N57Actions,
    SPRO: N57Procedures,
    ...getSharedCharts("T057x"),
  },
  T058x: {
    SASS: N58Assessments,
    SCHC: N58ChiefComplaint,
    SACT: N58Actions,
    SPRO: N58Procedures,
    ...getSharedCharts("T058x"),
  },
  T070a: {
    SASS: N70Assessments,
    SCHC: N70ChiefComplaint,
    SACT: N70Actions,
    SPRO: N70Procedures,
    ...getSharedCharts("T070a"),
  },
};

export { NursingCharts };
export type { TriageChartSection, TriageSharedChartSection };
