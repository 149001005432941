import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Interactions = createIcon({
  path: (
    <path
      d="M0 3.83608V2.69607C0 0.915334 0.931976 0 2.72936 0H3.92761V1.62264H2.82921C2.05534 1.62264 1.63096 2.03038 1.63096 2.83753V3.83608H0ZM5.07594 1.62264V0H8.82049V1.62264H5.07594ZM6.87332 17.8324C5.07594 17.8324 4.14396 16.9087 4.14396 15.1363V13.8798H2.72936C0.931976 13.8798 0 12.9561 0 11.1754V10.0354H1.63096V11.0423C1.63096 11.8494 2.05534 12.2488 2.82921 12.2488H4.14396V6.64865C4.14396 4.87623 5.07594 3.95258 6.87332 3.95258H12.2571V2.83753C12.2571 2.03038 11.8244 1.62264 11.0589 1.62264H9.96049V0H11.1587C12.9561 0 13.8881 0.923655 13.8881 2.69607V3.95258H15.2944C17.0918 3.95258 18.0238 4.87623 18.0238 6.64865V10.8925L16.8089 9.66925C16.684 9.53611 16.5426 9.42794 16.3928 9.35304V6.79011C16.3928 5.98295 15.9684 5.58353 15.2029 5.58353H6.97318C6.1993 5.58353 5.77492 5.98295 5.77492 6.79011V14.9948C5.77492 15.802 6.1993 16.2014 6.97318 16.2014H13.3722L13.3389 17.8324H6.87332ZM0 8.82049V5.05098H1.63096V8.82049H0ZM14.5372 16.6757L14.6287 9.96049C14.637 9.611 15.0031 9.46954 15.2445 9.71086L19.8794 14.4456C20.1373 14.7202 19.9543 15.0864 19.5881 15.0864L17.849 15.1363L19.3135 18.523C19.3967 18.6978 19.3551 18.9308 19.1471 19.014L18.2983 19.3468C18.082 19.43 17.8906 19.3219 17.8074 19.1222L16.4261 15.6938L15.2112 16.9337C14.9699 17.1833 14.5288 17.0585 14.5372 16.6757Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Interactions };
