import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "config";
import React from "react";
import Chakra from "./Chakra";
import ReactQuery from "./ReactQuery";
import ReactRouter from "./ReactRouter";
import { PermissionsProvider } from "./Permissions";
import { UserRolesProvider } from "./UserRoles";

type ContextsProviderProps = { children: React.ReactNode };

function ContextsProvider(props: ContextsProviderProps) {
  const { children } = props;

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      audience={config.auth0BackendApiAudience}
      redirectUri={window.location.origin}
    >
      <PermissionsProvider>
        <Chakra>
          <ReactRouter>
            <ReactQuery>
              <UserRolesProvider>{children}</UserRolesProvider>
            </ReactQuery>
          </ReactRouter>
        </Chakra>
      </PermissionsProvider>
    </Auth0Provider>
  );
}

export default ContextsProvider;
