import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import * as React from "react";
import { BaseModalProps } from "types";
import { UseDiagramsResponseItem, useDiagrams } from "./api";
import { diagramReducer, DIAGRAM_INITIAL_STATE } from "./diagram-reducer";
import {
  DiagramCanvas,
  DiagramCanvasModalProps as ModalProps,
} from "./DiagramCanvas";
import { Diagram } from "./types";

type DiagramCanvasModalProps = BaseModalProps & ModalProps;

function DiagramCanvasModal(props: DiagramCanvasModalProps) {
  const { isOpen, onClose, ...diagramCanvasProps } = props;
  const [selectedDiagrams, setSelectedDiagrams] = React.useState<Diagram[]>([]);

  const [state, dispatch] = React.useReducer(
    diagramReducer,
    DIAGRAM_INITIAL_STATE
  );

  const { data } = useDiagrams(
    diagramCanvasProps.encounterId,
    diagramCanvasProps.chartCode
  );

  const auxDiagrams = React.useRef<UseDiagramsResponseItem[] | null>(null);

  const getActiveDiagramIndex = React.useCallback(
    (newData: UseDiagramsResponseItem[]) => {
      if (!newData) return state.activeDiagramIndex;
      if (
        auxDiagrams.current &&
        newData.length !== auxDiagrams.current.length
      ) {
        const newDiagramIndex = newData.findIndex(
          (d) => auxDiagrams.current?.findIndex((ad) => ad.id === d.id) === -1
        );

        auxDiagrams.current = newData;
        if (newDiagramIndex > -1) {
          return newDiagramIndex;
        }
        return state.activeDiagramIndex;
      } else {
        auxDiagrams.current = newData;
        return state.activeDiagramIndex;
      }
    },
    [state.activeDiagramIndex]
  );

  React.useEffect(() => {
    if (data) {
      const selectedDiagramsObj: Diagram[] =
        data.data.map<Diagram>(({ data: shapes, ...rest }) => ({
          ...rest,
          shapes,
        })) ?? [];
      setSelectedDiagrams(selectedDiagramsObj);
      dispatch({
        type: "SET_STATE",
        payload: {
          diagrams: selectedDiagramsObj.map((diagram) => ({
            ...diagram,
            selectedShape: null,
            history: [],
          })),
          activeDiagramIndex: getActiveDiagramIndex(data.data),
          currentColor: "black",
        },
      });
    }
  }, [data, getActiveDiagramIndex]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent
        width="1355px"
        height="980px"
        maxWidth="unset"
        bg="gray.200"
        marginY="10px"
        overflow="auto"
      >
        {isOpen && (
          <DiagramCanvas
            onClose={onClose}
            {...diagramCanvasProps}
            diagrams={selectedDiagrams}
            state={state}
            dispatch={dispatch}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { DiagramCanvasModal };
