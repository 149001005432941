import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UnassignBedRequest = {
  bedCode: string;
  encounterId: string;
};

function useUnassignBed(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["unassignBed"],
    (input: UnassignBedRequest) =>
      axiosIdentityInstance.put(`/v1/bed/unassign-encounter`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
        queryClient.invalidateQueries(["patientDetails", { encounterId }]);
        queryClient.invalidateQueries("beds");
      },
    }
  );
}

export { useUnassignBed };
export type { UnassignBedRequest };
