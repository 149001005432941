import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type ChartSidebarIndicatorsDto = {
  lastModified: string;
  lastDateTimeModified: Date;
  isPhysicalExamSaved: boolean;
  isRadiologySaved: boolean;
  isLabsSaved: boolean;
  isHPISaved: boolean;
  isROSSaved: boolean;
  isProceduralSedationNotesSaved: boolean;
  isClinicalImpressionSaved: boolean;
  isMDMSaved: boolean;
  isChiefComplainSaved: boolean;
  isAssessmentsSaved: boolean;
  isActionsSaved: boolean;
  isArrivalInfoSaved: boolean;
  isVitalsSaved: boolean;
  isMedicalHistorySaved: boolean;
  isOrderFormSaved: boolean;
  isIvMedicationSaved: boolean;
  isEKGSaved: boolean;
  isProceduresSaved: boolean;
  isProgressSaved: boolean;
  isOrderHistorySaved: boolean;
  isSuppliesSaved: boolean;
  isDispositionSaved: boolean;
  hasPendingOrdersCancellation: boolean;
};

type ChartSidebarIndicatorsProps = {
  encounterId: string;
  chartCode: string;
};

function useChartSidebarIndicators({
  encounterId,
  chartCode,
}: ChartSidebarIndicatorsProps) {
  return useAxiosQuery(
    ["chartSidebarIndicators", { encounterId, chartCode }],
    () =>
      axiosChartInstance.get<ChartSidebarIndicatorsDto>(
        `v1/chart-application/${encounterId}/${chartCode}/indicators`
      )
  );
}

export { useChartSidebarIndicators };
export type { ChartSidebarIndicatorsDto };
