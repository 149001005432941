import { theme } from "@chakra-ui/react";

const baseCheckboxTheme = theme.components.Checkbox;

const baseStyleControl = {
  borderColor: "gray.700",
  borderRadius: "base",

  _checked: {
    bg: "blue",
    borderColor: "blue",
  },

  _indeterminate: {
    bg: "blue",
    borderColor: "blue",
  },
};

const sizes = {
  md: {
    control: {
      w: "20px",
      h: "20px",
    },
    label: {
      fontWeight: "medium",
    },
  },
};

const baseStyle = {
  control: baseStyleControl,
};

export const checkboxTheme = {
  ...baseCheckboxTheme,
  sizes: {
    md: baseCheckboxTheme.sizes.md,
  },
};

const variants = {
  chart: {
    control: {
      color: "black",
      width: "unset",
      height: "unset",
      border: "2px solid",
      borderColor: "gray.500",
      borderRadius: "24px",
      padding: "7px 10px",
      outline: "none",
      transition: "all 200ms ease-in-out",
    },
  },
};

export default {
  baseStyle,
  sizes,
  variants,
};
