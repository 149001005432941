import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { useInputMenu } from "hooks";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import { useUsers, UseUsersResponseItem } from "modules/identity";
import { PersonalInfo } from "modules/in-process/components/in-process-patients/PersonalInfo";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import { BackButton, DividerList, Loading, SearchBar } from "shared";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";

type SignatureScribedInPresenseOfModalContentProps = Pick<
  SignatureScribedInPresenseOfModalProps,
  "onClose" | "userId" | "control" | "signatures"
>;

const idAccessor = (value: UseUsersResponseItem | null) => value?.id || "";

const SignatureScribedInPresenseOfModalContent = (
  props: SignatureScribedInPresenseOfModalContentProps
) => {
  const { onClose, control, signatures, userId } = props;
  const toast = useToast();

  const { update } = useFieldArray({
    control,
    name: "signatures",
  });

  const [search, setSearch] = React.useState("");
  const debouncedSearch = debounce(1000, setSearch);

  const [userSelected, setUserSelected] =
    React.useState<UseUsersResponseItem | null>(null);

  const {
    data,
    isLoading: isUsersLoading,
    error: usersError,
  } = useUsers({
    userType: "Provider",
    search,
  });

  React.useEffect(() => {
    if (usersError) {
      toast({ description: extractApiErrorMessage(usersError) });
    }
  }, [usersError, toast]);

  const handleContinue = () => {
    const index = signatures.findIndex(
      (signature) => signature.userId === userId
    );

    if (userSelected && index >= 0) {
      update(index, {
        ...signatures[index],
        scribedInPresenseOf: userSelected.id,
        scribedInPresenseOfUser: {
          id: userSelected.id,
          fullName: userSelected.fullName,
          teams: userSelected.teams,
          pictureUrl: userSelected.pictureUrl,
        },
      });
      onClose();
    }
  };

  const handleSelectItem = (user: UseUsersResponseItem | null) => {
    setUserSelected(user);
  };

  const { getMenuItemProps, getMenuProps, selectedItem } = useInputMenu({
    items: data?.data || [],
    defaultSelectedItem: null,
    idAccessor,
    onSelectedItemChange: handleSelectItem,
  });

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 0"
      >
        <BackButton onClick={onClose}>Cancel</BackButton>
        <Heading fontWeight="600" fontSize="1.0625rem">
          Providers
        </Heading>
        <Button variant="label" color="blue" onClick={handleContinue}>
          Done
        </Button>
      </ModalHeader>
      <ModalBody padding="34px 16px 16px">
        {isUsersLoading ? (
          <Loading />
        ) : (
          <>
            <Box margin="20px 0">
              <SearchBar
                placeholder="Search"
                height="36px"
                bg="gray.350"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </Box>
            <Box
              borderRadius="10px"
              bg="white"
              overflow="hidden"
              outline="none"
              _focus={{
                boxShadow: "var(--chakra-shadows-outline)",
              }}
              {...getMenuProps()}
            >
              <DividerList dividerLeftMargin="62px">
                {data?.data.map((item, index) => (
                  <Box
                    key={item.id}
                    width="510px"
                    padding="8px 12px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    _selected={{ color: "blue" }}
                    _checked={{ color: "blue" }}
                    {...getMenuItemProps({ item, index })}
                  >
                    <PersonalInfo
                      name={item.fullName}
                      pictureUrl={item.pictureUrl}
                      description={item.teams}
                    />
                    {idAccessor(item) === selectedItem?.id && (
                      <Icon as={EmrCheck} fontSize="1rem" />
                    )}
                  </Box>
                ))}
              </DividerList>
            </Box>
          </>
        )}
      </ModalBody>
    </>
  );
};

type SignatureScribedInPresenseOfModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  userId: string;
  control: Control<DispositionForm>;
  signatures: DispositionSignatureForm[];
};

export const SignatureScribedInPresenseOfModal = (
  props: SignatureScribedInPresenseOfModalProps
) => {
  const { isOpen, onClose, ...rest } = props;
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        width="540px"
        maxW="unset"
        bg="gray.200"
        height="90%"
        margin="0"
        alignSelf="center"
      >
        {isOpen && (
          <SignatureScribedInPresenseOfModalContent
            onClose={onClose}
            {...rest}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
