import {
  chakra,
  Icon,
  Box,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { EncounterDrugAllergy } from "modules/in-process/types";
import React, { useEffect, useMemo } from "react";
import {
  Card,
  CheckboxListGroup,
  CheckboxListItem,
  Loading,
  PatientHeaderModal,
  Textarea,
  ToolbarHeader,
} from "shared";
import { formMessages, genericErrors } from "messages";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useYupValidationResolver, useToast } from "hooks";
import * as yup from "yup";
import { DrugAllergy } from "icons";
import {
  useEncounterDrugAllergy,
  usePutEncounterDrugAllergy,
} from "modules/in-process/api";

import { usePermissions } from "contexts";

type PatientDrugAllergyModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  patientName?: string;
  patientEncounterId?: string;
};

type PatientDrugAllergyModalContentProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  patientName?: string;
  patientEncounterId?: string;
};

const schema = yup.object({
  notes: yup.string(),
  drugAllergies: yup.array(),
});

function PatientDrugAllergyModalContent(
  props: PatientDrugAllergyModalContentProps
) {
  const { patientName, patientEncounterId, onModalClose } = props;
  const toast = useToast();
  const { register, control, setValue, watch, handleSubmit } = useForm({
    resolver: useYupValidationResolver(schema),
    defaultValues: {
      notes: "",
      drugAllergies: [],
    },
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("inprocess").isEditable;

  const { data, isLoading: isDietLoading } = useEncounterDrugAllergy({
    encounterId: patientEncounterId || "",
  });
  const { onOpen, onClose } = useDisclosure();

  const watchDrugAllergies = watch("drugAllergies");

  const drugAllergies = useMemo(() => {
    const drugAllergiesDefault = [
      { value: "PCN", label: "PCN", selected: false },
      { value: "ASA", label: "ASA", selected: false },
      { value: "Sulfa", label: "sulfa", selected: false },
      { value: "Codeine", label: "codeine", selected: false },
      { value: "Iodine", label: "iodine", selected: false },
      { value: "Latex", label: "latex", selected: false },
      { value: "Other", label: "Other", selected: false },
    ].map(({ selected, ...rest }) => {
      return {
        ...rest,
        selected:
          (watchDrugAllergies || []).find((drugAllergyselected: string) => {
            return drugAllergyselected === rest.value;
          }) !== undefined,
      };
    });
    const drugAllergySelected = !!drugAllergiesDefault.find(
      (fa) => fa.selected
    );
    if (drugAllergySelected) onOpen();
    else onClose();
    return drugAllergiesDefault;
  }, [watchDrugAllergies, onOpen, onClose]);

  useEffect(() => {
    if (data?.data.drugAllergies)
      setValue("drugAllergies", data?.data.drugAllergies);
    if (data?.data.notes) {
      setValue("notes", data?.data.notes);
    }
  }, [data, setValue]);

  const { mutateAsync: putInProcessDrugAllergies, isLoading } =
    usePutEncounterDrugAllergy(patientEncounterId || "");

  const onSubmit: SubmitHandler<EncounterDrugAllergy> = async (dataForm) => {
    try {
      const payload: EncounterDrugAllergy = {
        ...dataForm,
      };
      await putInProcessDrugAllergies(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Patient Drug Allergies"),
      });
      onModalClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              Drug Allergies
            </chakra.span>
          }
          leftButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Done
            </Button>,
          ]}
          isLoading={isLoading}
        />
        <PatientHeaderModal
          fullName={patientName || ""}
          encounterId={patientEncounterId}
        />
      </ModalHeader>
      <ModalBody padding="16px" fontSize="1.0625rem">
        <form onSubmit={(e) => e.preventDefault()}>
          {isDietLoading && <Loading padding="120px 0px" />}
          {!isDietLoading && (
            <>
              <Box>
                <Flex direction="column" gridGap="15px">
                  <Card boxShadow="none" border="none" borderRadius="0.625rem">
                    <Flex direction="row" alignItems="center" padding="14px">
                      <Flex
                        p="6px"
                        backgroundColor="red"
                        borderRadius="5px"
                        marginRight="15px"
                      >
                        <Icon
                          fontSize="1.50rem"
                          as={DrugAllergy}
                          color="white"
                        />
                      </Flex>
                      <chakra.span flex="1">Drug Allergies</chakra.span>
                    </Flex>
                    <Box paddingLeft="40px" overflow="auto" maxHeight="50vh">
                      <Controller
                        name="drugAllergies"
                        control={control}
                        render={({ field: { onChange, ...rest } }) => (
                          <CheckboxListGroup
                            divider={
                              <Divider
                                color="gray.450"
                                opacity={1}
                                width="calc(100% - 60px)"
                                alignSelf="flex-end"
                                height="0px"
                              />
                            }
                            onChange={(value) => {
                              onChange(value);
                            }}
                            {...rest}
                          >
                            {drugAllergies.map(({ value, label, selected }) => (
                              <CheckboxListItem
                                isReadOnly={isReadOnly}
                                key={value}
                                value={value}
                                color="gray.700"
                              >
                                <Flex
                                  direction="row"
                                  alignItems="center"
                                  flexGrow={1}
                                >
                                  <Icon
                                    fontSize="1.35rem"
                                    marginRight="22px"
                                    as={DrugAllergy}
                                    color={selected ? "blue" : "gray.450"}
                                  />
                                  <chakra.span flexGrow={1}>
                                    {label}
                                  </chakra.span>
                                  {selected && (
                                    <Icon marginRight="8px" as={EmrCheck} />
                                  )}
                                </Flex>
                              </CheckboxListItem>
                            ))}
                          </CheckboxListGroup>
                        )}
                      />
                    </Box>
                  </Card>
                </Flex>
              </Box>

              <Box marginTop="20px">
                <chakra.span color="gray.650" paddingStart="16px">
                  Notes
                </chakra.span>
                <Box
                  bg="white"
                  padding="20px"
                  marginTop="5px"
                  borderRadius="0.375rem"
                >
                  <Textarea
                    bg="gray.50"
                    isDisabled={isReadOnly}
                    {...register("notes")}
                    placeholder="Type notes here"
                  />
                </Box>
              </Box>
            </>
          )}
        </form>
      </ModalBody>
    </>
  );
}

function PatientDrugAllergyModal(props: PatientDrugAllergyModalProps) {
  const { patientName, patientEncounterId, isModalOpen, onModalClose } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxWidth="542px"
        minHeight="950px"
        maxHeight="1000px"
      >
        {patientEncounterId && isModalOpen && (
          <PatientDrugAllergyModalContent
            patientName={patientName}
            patientEncounterId={patientEncounterId}
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { PatientDrugAllergyModal };
