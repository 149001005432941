// The max file size is 2MB
const MAX_FILE_SIZE_IN_MB = 2;
const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const EMR_SCANNER_APP_LINK =
  "https://drive.google.com/drive/folders/1QpAxuwiEX4P0AZpa-9oYe4j86p1ty2CN";

const FACILITY_ADDRESS: Record<string, string> = {
  galveston: "4222 Seawall Blvd, Galveston, TX 77550",
  tyler: "4200 Seawall Blvd, Tyler, TX 75701",
  longview: "4200 Seawall Blvd, Longview, TX 75607",
  localhost: "Development environment",
  staging: "Staging environment",
  demo: "4222 Seawall Blvd, Galveston, TX 77550",
};

export {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_IN_MB,
  EMR_SCANNER_APP_LINK,
  FACILITY_ADDRESS,
};
