import { theme } from "@chakra-ui/react";

/**
 * Everytime this file is updated you might need to run
 * `yarn gen:theme-types` and reload your editor window
 */

/* --------------------------- EXTENDED COMPONENTS -------------------------- */
import Input, { inputTheme } from "./input";
import Textarea, { textareaTheme } from "./textarea";
import Button, { buttonTheme } from "./button";
import Tabs, { tabsTheme } from "./tabs";
import Checkbox, { checkboxTheme } from "./checkbox";
import { autocompleteTheme } from "./autocomplete";
import Avatar, { avatarTheme } from "./avatar";
import Alert from "./alert";
import Table from "./table";
import Switch from "./switch";
import Divider from "./divider";
import Menu from "./menu";

/* ---------------------------- CUSTOM COMPONENTS --------------------------- */
import { navigationButtonTheme } from "./navigation-button";
import { cardTheme } from "./card";
import { customSelectTheme } from "./custom-select";
import { customCheckboxTheme } from "./custom-checkbox";
import { dropdownMenuTheme } from "./dropdown-menu";
import { imagePickerTheme } from "./image-picker";
import { counterTheme } from "./counter";
import { chartOptionTheme } from "./chart-option";
import { segmentTheme } from "./segment";
import { sidebarTheme } from "./sidebar";

const baseComponentsTheme = theme.components;

/**
 * Override base chakra theme. Use cases:
 * - Remove base chakra configurations, such as variants or sizes
 * - Include custom components styles that don't exist in the chakra theme
 */
export const componentsTheme = {
  ...baseComponentsTheme,
  Input: inputTheme,
  Textarea: textareaTheme,
  Card: cardTheme,
  Counter: counterTheme,
  Button: buttonTheme,
  DropdownMenu: dropdownMenuTheme,
  Tabs: tabsTheme,
  CustomSelect: customSelectTheme,
  CustomCheckbox: customCheckboxTheme,
  Autocomplete: autocompleteTheme,
  Avatar: avatarTheme,
  NavigationButton: navigationButtonTheme,
  ImagePicker: imagePickerTheme,
  Checkbox: checkboxTheme,
  ChartOption: chartOptionTheme,
  Segment: segmentTheme,
  Sidebar: sidebarTheme,
};

/**
 * Extend base chakra theme, usefull for including custom styles to
 * already existing chakra components. Can also contain styles for
 * custom components.
 */
export default {
  Input,
  Textarea,
  Button,
  Tabs,
  Checkbox,
  Avatar,
  Alert,
  Table,
  Switch,
  Divider,
  Menu,
};
