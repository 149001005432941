import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PillsBottle = createIcon({
  path: (
    <path
      d="M3.33005 24C1.33005 24 0 22.6601 0 20.6207V9.50739C0 7.46798 1.33005 6.12808 3.33005 6.12808H3.5665V4.85714H3.49754C2.35468 4.85714 1.67488 4.15764 1.67488 2.99507V1.86207C1.67488 0.699507 2.35468 0 3.49754 0H10.3448C11.4877 0 12.1675 0.699507 12.1675 1.86207V2.99507C12.1675 4.15764 11.4877 4.85714 10.3448 4.85714H10.2759V6.12808H10.5123C12.5123 6.12808 13.8424 7.46798 13.8424 9.50739V20.6207C13.8424 22.6601 12.5123 24 10.5123 24H3.33005ZM3.133 15.7241C3.133 16.197 3.44828 16.4926 3.89163 16.4926H5.48768V18.0788C5.48768 18.532 5.78325 18.8473 6.25616 18.8473H7.57635C8.04926 18.8473 8.34483 18.532 8.34483 18.0788V16.4926H9.94089C10.3842 16.4926 10.7094 16.197 10.7094 15.7241V14.4039C10.7094 13.931 10.3842 13.6453 9.94089 13.6453H8.34483V12.0493C8.34483 11.5961 8.04926 11.2808 7.57635 11.2808H6.25616C5.78325 11.2808 5.48768 11.5961 5.48768 12.0493V13.6453H3.89163C3.44828 13.6453 3.133 13.931 3.133 14.4039V15.7241Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 14 24",
});

export { PillsBottle };
