import { axiosIdentityInstance } from "config";
import { RoomStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UpdateRoomStatusRequest = {
  roomStatus: RoomStatus;
};

function useUpdateRoomStatus(id: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateRoomStatus", { id }],
    (input: UpdateRoomStatusRequest) =>
      axiosIdentityInstance.put(`/v1/room/${id}/status`, {}, { params: input }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
        queryClient.invalidateQueries(["room", { roomId: id }]);
      },
    }
  );
}

export { useUpdateRoomStatus };
export type { UpdateRoomStatusRequest };
