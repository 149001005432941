import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartType } from "types";

type UseDeletedChartsListResponse = {
  id: string;
  name: string;
  chartType?: ChartType;
};

function useDeletedChartsList(encounterId: string) {
  return useAxiosQuery(["deletedChartsList", { encounterId }], () =>
    axiosChartInstance.get<UseDeletedChartsListResponse[]>(
      `v1/chart-application/${encounterId}/get-charts-application-deleted`
    )
  );
}

export { useDeletedChartsList };
