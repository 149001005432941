import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S020Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q020.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q020`}
          subsectionLabel="Suture Removal/Wound or Burn Recheck"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Suture Removal/Wound or Burn Recheck
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="sutures"
              name={`${sectionDelimiter}Q020A001`}
              type="option"
            />
            <ChartRowElement
              label="staples removed by"
              name={`${sectionDelimiter}Q020A002`}
              type="option"
            />
            <ChartRowElement
              label="nurse"
              name={`${sectionDelimiter}Q020A003`}
              type="option"
            />
            <ChartRowElement
              label="physician"
              name={`${sectionDelimiter}Q020A004`}
              type="option"
            />
            <ChartRowElement
              label="MLP"
              name={`${sectionDelimiter}Q020A005`}
              type="option"
            />
            <ChartRowElement
              label="Tech"
              name={`${sectionDelimiter}Q020A006`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q020A007`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="drain removed from abscess cavity"
              name={`${sectionDelimiter}Q020A008`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q020A009`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="dry sterile dressing applied by"
              name={`${sectionDelimiter}Q020A010`}
              type="option"
            />
            <ChartRowElement
              label="nurse"
              name={`${sectionDelimiter}Q020A011`}
              type="option"
            />
            <ChartRowElement
              label="physician"
              name={`${sectionDelimiter}Q020A012`}
              type="option"
            />
            <ChartRowElement
              label="MLP"
              name={`${sectionDelimiter}Q020A013`}
              type="option"
            />
            <ChartRowElement
              label="tech"
              name={`${sectionDelimiter}Q020A014`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q020A015`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="antibiotic administered"
              name={`${sectionDelimiter}Q020A016`}
              type="option"
            />
            <ChartRowElement
              label="IV"
              name={`${sectionDelimiter}Q020A017`}
              type="option"
            />
            <ChartRowElement
              label="IM"
              name={`${sectionDelimiter}Q020A018`}
              type="option"
            />
            <ChartRowElement
              label="topical"
              name={`${sectionDelimiter}Q020A019`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q020A020`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q020A021`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S020Procedure };
