import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import { RadiologyInterpretationProps } from "../radiology-chart/types";

function F022Interpretation(props: RadiologyInterpretationProps) {
  const {
    index,
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionSubCode}_Q001`}
          subsectionLabel="Ulna"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        />
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Ulna:"
                name={`${sectionDelimiter}Q001A001`}
                type="option"
              />
              <ChartRowElement
                label="L"
                name={`${sectionDelimiter}Q001A002`}
                type="option"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q001A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="proximal:"
                name={`${sectionDelimiter}Q001A004`}
                type="option"
              />
              <ChartRowElement
                label="olecranon"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A005`}
                type="option"
              />
              <ChartRowElement
                label="coronoid process:"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A006`}
                type="option"
              />
              <ChartRowElement
                label="intra-articular"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A007`}
                type="option"
              />
              <ChartRowElement
                label="torus"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A008`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A009`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="shaft:"
                name={`${sectionDelimiter}Q001A010`}
                type="option"
              />
              <ChartRowElement
                label="transverse"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A011`}
                type="option"
              />
              <ChartRowElement
                label="oblique"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A012`}
                type="option"
              />
              <ChartRowElement
                label="spiral"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A013`}
                type="option"
              />
              <ChartRowElement
                label="segmental"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A014`}
                type="option"
              />
              <ChartRowElement
                label="comminuted"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A015`}
                type="option"
              />
              <ChartRowElement
                label="Monteggia’s"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A016`}
                type="option"
              />
              <ChartRowElement
                label="greenstick"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A017`}
                type="option"
              />
              <ChartRowElement
                label="‘bent bone’"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A018`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="distal:"
                name={`${sectionDelimiter}Q001A020`}
                type="option"
              />
              <ChartRowElement
                label="styloid"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A021`}
                type="option"
              />
              <ChartRowElement
                label="transverse"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A022`}
                type="option"
              />
              <ChartRowElement
                label="oblique"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A023`}
                type="option"
              />
              <ChartRowElement
                label="comminuted"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A024`}
                type="option"
              />
              <ChartRowElement
                label="torus"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A025`}
                type="option"
              />
              <ChartRowElement
                label="SH:"
                name={`${sectionDelimiter}Q001A026`}
                type="option"
              />
              <ChartRowElement
                label="1"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A027`}
                type="option"
              />
              <ChartRowElement
                label="2"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A028`}
                type="option"
              />
              <ChartRowElement
                label="3"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A029`}
                type="option"
              />
              <ChartRowElement
                label="4"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A030`}
                type="option"
              />
              <ChartRowElement
                label="5"
                fontStyle="italic"
                name={`${sectionDelimiter}Q001A031`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A032`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { F022Interpretation };
