import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";

type ChangeUserPasswordPayload = FormData;

function useChangeUserPassword(userId: string) {
  return useAxiosMutation(
    ["changeUserPassword", { userId }],
    (input: ChangeUserPasswordPayload) =>
      axiosIdentityInstance.put(`/v1/user/${userId}/reset-password`, input)
  );
}

export { useChangeUserPassword };
