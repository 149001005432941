import {
  Box,
  chakra,
  HStack,
  Icon,
  IconButton,
  StackProps,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EmrCheck, EmrPlus, SiDelete } from "@medstonetech/slate-icons";
import { formMessages } from "messages";
import {
  SignedSection,
  useCreateSignedSection,
  useDeleteSignedSection,
  useDeleteSummaryMiscellaneous,
} from "modules/charts-shared/api";
import * as React from "react";
import { extractApiErrorMessage } from "utils";

type SectionItemSelectionProps = {
  title: string;
  subtitle?: string;
  icon?: React.ReactElement;
  leftElement?: React.ReactElement;
  isChecked?: boolean;
} & StackProps;

function SectionItemBase(props: SectionItemSelectionProps) {
  const { icon, leftElement, title, subtitle, isChecked, ...rest } = props;
  return (
    <HStack h="52px" spacing="12px" pr="5px" pl="10px" w="100%" {...rest}>
      {leftElement
        ? React.cloneElement(leftElement)
        : icon && (
            <>
              <Box h="40px" w="40px" p="11px">
                {React.cloneElement(icon, {
                  h: "18px",
                  w: "18px",
                  color: "gray.700",
                  verticalAlign: "top",
                })}
              </Box>
            </>
          )}

      <VStack flex="1" spacing="0px" alignItems="start">
        <chakra.span fontSize="0.9375rem" fontWeight="600" color="black">
          {title}
        </chakra.span>
        {subtitle && (
          <chakra.span fontSize="0.8125rem" fontWeight="400" color="gray.700">
            {subtitle}
          </chakra.span>
        )}
      </VStack>
      {isChecked !== undefined && (
        <Box h="40px" w="40px" p="13px">
          <Icon
            h="14px"
            w="14px"
            color={isChecked ? "blue" : "gray.450"}
            as={EmrCheck}
            verticalAlign="top"
          />
        </Box>
      )}
    </HStack>
  );
}

type SectionItemProps = {
  icon: React.ReactElement;
  description: string;
  isChecked?: boolean;
  value?: string;
} & StackProps;

function SectionItem(props: SectionItemProps) {
  const { icon, description, isChecked, value, ...rest } = props;
  return (
    <SectionItemBase
      title={description}
      icon={icon}
      isChecked={isChecked}
      {...rest}
    />
  );
}

type SignedItemProps = {
  icon: React.ReactElement;
  description: string;
  isChecked?: boolean;
  uploadedFile?: boolean;
  onAddFile?: (addedFile: boolean) => void;
  encounterId: string;
  type: SignedSection;
} & StackProps;

function SignedItem(props: SignedItemProps) {
  const {
    icon,
    encounterId,
    description,
    type,
    isChecked,
    uploadedFile = false,
    onAddFile,
    ...rest
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [uploadedInternalFile, setUploadedInternalFile] = React.useState(false);
  const toast = useToast();

  const selectFileClick = () => {
    inputRef?.current?.click();
  };

  const { mutateAsync: createSignedFileSection, isLoading } =
    useCreateSignedSection(encounterId);

  const handleFileSelection = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const newFile = event.currentTarget?.files?.item(0);
      if (newFile instanceof File) {
        const formData = new FormData();
        formData.append("file", newFile as Blob, newFile.name);
        await createSignedFileSection({ type, input: formData });
        let documentMsg = "";
        switch (type) {
          case "Discharge":
            documentMsg = "Discharge Form";
            break;
          case "Observation":
            documentMsg = "Observation Consent";
            break;
          case "Ama":
            documentMsg = "AMA Forms";
            break;
          default:
            documentMsg = "Transfer Documents";
            break;
        }
        toast({
          status: "success",
          description: formMessages.createSuccess(documentMsg),
        });
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => setUploadedInternalFile(uploadedFile), [uploadedFile]);

  const fileModeElements = uploadedInternalFile
    ? { icon }
    : {
        leftElement: (
          <Box>
            <input
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFileSelection}
            />
            <IconButton
              variant="ghost"
              size="iconSm"
              aria-label="Add file"
              height="40px"
              width="40px"
              padding="11px"
              ms="0"
              icon={
                <Icon as={EmrPlus} color="blue" height="18px" width="18px" />
              }
              onClick={(e) => {
                e.preventDefault();
                selectFileClick();
              }}
              isLoading={isLoading}
            />
          </Box>
        ),
        subtitle: "Not Downloaded",
      };

  return (
    <SectionItemBase
      title={description}
      isChecked={isChecked}
      {...fileModeElements}
      {...rest}
    />
  );
}

type SectionItemRemoveProps = {
  description: string;
  value: string;
  uploadedFile?: boolean;
  onDeleteClick?: (value: string) => void;
  encounterId: string;
  type?: SignedSection;
} & StackProps;

function SectionItemRemove(props: SectionItemRemoveProps) {
  const {
    description,
    value,
    uploadedFile,
    onDeleteClick,
    encounterId,
    type,
    ...rest
  } = props;

  const { mutateAsync: deleteSignedFileSection, isLoading } =
    useDeleteSignedSection(encounterId);
  const toast = useToast();

  const deleteSignedFile = async () => {
    try {
      if (type) {
        await deleteSignedFileSection({ type });
        onDeleteClick?.(value);
        let documentMsg = "";
        switch (type) {
          case "Discharge":
            documentMsg = "Discharge Form";
            break;
          case "Observation":
            documentMsg = "Observation Consent";
            break;
          case "Ama":
            documentMsg = "AMA Forms";
            break;
          default:
            documentMsg = "Transfer Documents";
            break;
        }
        toast({
          status: "success",
          description: formMessages.deleteSuccess(documentMsg),
        });
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <SectionItemBase
      title={description}
      leftElement={
        <IconButton
          variant="ghost"
          size="iconSm"
          aria-label="Add file"
          height="40px"
          width="40px"
          padding="11px"
          isDisabled={!uploadedFile}
          color="red"
          _disabled={{
            color: "gray.450",
          }}
          icon={<Icon as={SiDelete} height="18px" width="18px" />}
          onClick={() => {
            deleteSignedFile();
          }}
          isLoading={isLoading}
        />
      }
      {...rest}
    />
  );
}

type DocumentItemProps = {
  description: string;
  value: string;
  onDeleteClick?: (value: string) => void;
  encounterId: string;
  fileId: string;
} & StackProps;

function DocumentItem(props: DocumentItemProps) {
  const { description, value, fileId, onDeleteClick, encounterId, ...rest } =
    props;

  const { mutateAsync: deleteAdditionalFile, isLoading } =
    useDeleteSummaryMiscellaneous(encounterId);
  const toast = useToast();

  const deleteSignedFile = async () => {
    try {
      await deleteAdditionalFile({ miscellaneousId: fileId });
      onDeleteClick?.(value);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("File"),
      });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <SectionItemBase
      title={description}
      leftElement={
        <IconButton
          variant="ghost"
          size="iconSm"
          aria-label="Add file"
          height="40px"
          width="40px"
          padding="11px"
          color="red"
          _disabled={{
            color: "gray.450",
          }}
          icon={<Icon as={SiDelete} height="18px" width="18px" />}
          onClick={() => {
            deleteSignedFile();
            onDeleteClick?.(value);
          }}
          isLoading={isLoading}
        />
      }
      {...rest}
    />
  );
}

export { SectionItem, SignedItem, SectionItemRemove, DocumentItem };
