import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";

function useIdentificationFile(encounterId: string) {
  return useQuery(["identificationFile", { encounterId }], () =>
    axiosPatientInstance.get<Blob>(
      `v1/encounter/${encounterId}/form/files/identification`,
      { responseType: "blob" }
    )
  );
}

export { useIdentificationFile };
