import {
  Box,
  BoxProps,
  Button,
  chakra,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrCheck, EmrVitals } from "@medstonetech/slate-icons";
import { useInputMenu, useToast } from "hooks";
import { NurseHat } from "icons";
import {
  SharedChartBaseProps,
  useActiveCharts,
  UseActiveChartsResponseItem,
} from "modules/charts-shared";
import { useEncounterInfo } from "modules/reception/api";
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  BackButton,
  Card,
  DividerList,
  EncounterAvatar,
  Loading,
} from "shared";
import { ChartType } from "types/chart";
import { extractApiErrorMessage } from "utils";

type SelectChartModalProps = {
  onNext: (params: SharedChartBaseProps) => void;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type SelectChartModalContentProps = Pick<
  SelectChartModalProps,
  "onClose" | "onNext"
>;

type ChartItemProps = {
  title: string;
  chartType: ChartType;
  isSelected?: boolean;
  onClickChart?: () => void;
} & BoxProps;

const idAccessor = (value: UseActiveChartsResponseItem | null) =>
  value?.chartCode || "";

function ChartItem(props: ChartItemProps) {
  const { title, chartType, isSelected, ...boxProps } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      padding="16px"
      width="100%"
      justifyContent="left"
      {...boxProps}
    >
      <Button
        variant="label"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        <HStack spacing="16px">
          <Icon
            as={chartType === "Chart" ? EmrVitals : NurseHat}
            color="gray.700"
          />
          <Box fontWeight="600" fontSize="1.0625rem">
            {title}
          </Box>
        </HStack>
        {isSelected && (
          <Icon
            as={EmrCheck}
            color="blue"
            fontSize="1.25rem"
            alignSelf="flex-end"
          />
        )}
      </Button>
    </Box>
  );
}

function SelectChartModalContent(props: SelectChartModalContentProps) {
  const { onClose, onNext } = props;
  const toast = useToast();
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const {
    data: encounter,
    error: encounterError,
    isLoading: isEncounterLoading,
  } = useEncounterInfo(encounterId);
  const {
    data: activeChartsData,
    isLoading: isActiveChartsLoading,
    error: activeChartsError,
  } = useActiveCharts(encounterId);

  const {
    getMenuItemProps,
    getMenuProps,
    selectedItem: selectedChart,
  } = useInputMenu({
    items: activeChartsData?.data || [],
    defaultSelectedItem: null,
    idAccessor,
  });

  React.useEffect(() => {
    if (activeChartsError) {
      toast({ description: extractApiErrorMessage(activeChartsError) });
    }
  }, [activeChartsError, toast]);

  React.useEffect(() => {
    if (encounterError) {
      toast({ description: extractApiErrorMessage(encounterError) });
    }
  }, [encounterError, toast]);

  return (
    <>
      <ModalHeader display="flex">
        <Box display="flex" flex="1">
          <BackButton marginRight="auto" onClick={onClose}>
            Back
          </BackButton>
        </Box>
        <Box display="flex" flex="1" justifyContent="center">
          <Heading fontSize="1.0625rem">Select Chart</Heading>
        </Box>
        <Box display="flex" flex="1">
          <Button
            marginLeft="auto"
            flex="1"
            variant="label"
            color="blue"
            isDisabled={!selectedChart}
            onClick={() => {
              if (selectedChart) {
                onNext({
                  chartCode: selectedChart.chartCode,
                  chartType: selectedChart.chartType,
                } as SharedChartBaseProps);
              }
              onClose();
            }}
            justifyContent="end"
          >
            Next
          </Button>
        </Box>
      </ModalHeader>
      <ModalBody display="flex" flexDirection="column" alignItems="center">
        <EncounterAvatar
          encounterId={encounterId}
          size="md"
          marginBottom="14px"
        />
        <Skeleton
          isLoaded={!isEncounterLoading}
          height="2.5rem"
          width="70%"
          marginBottom="55px"
          startColor="gray.400"
          endColor="gray.450"
          textAlign="center"
        >
          <Box fontSize="1.625rem" fontWeight="500" color="initial">
            {encounter?.data.firstName} {encounter?.data.lastName}
          </Box>
        </Skeleton>
        <chakra.p
          fontSize="1.0625rem"
          letterSpacing="0.5px"
          fontWeight="600"
          color="gray.650"
          textAlign="center"
          marginBottom="50px"
        >
          This patient currently has more than one open chart.
          <br />
          <br />
          Select a chart below to add this 'New Order'
        </chakra.p>
        <Card bg="white" width="100%" variant="default" {...getMenuProps()}>
          <DividerList dividerLeftMargin="52px">
            {isActiveChartsLoading && <Loading height="200px" />}
            {activeChartsData?.data.map((chart, index) => (
              <ChartItem
                key={chart.chartCode}
                title={chart.chartTitle}
                chartType={chart.chartType}
                isSelected={idAccessor(selectedChart) === idAccessor(chart)}
                {...getMenuItemProps({ item: chart, index })}
              />
            ))}
          </DividerList>
        </Card>
      </ModalBody>
    </>
  );
}

function SelectChartModal(props: SelectChartModalProps) {
  const { isOpen, onClose, onNext } = props;
  return (
    <Modal {...props} motionPreset="slideInRight">
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end" }}
        height="100%"
        margin="0"
      >
        {isOpen && (
          <SelectChartModalContent onClose={onClose} onNext={onNext} />
        )}
      </ModalContent>
    </Modal>
  );
}

export { SelectChartModal };
