import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Sparkles = createIcon({
  path: (
    <path
      d="M36.3537 15.4848C36.5518 15.4848 36.6738 15.3628 36.7043 15.1646C37.1616 12.6494 37.1311 12.6189 39.7683 12.1006C39.9665 12.0701 40.1037 11.9482 40.1037 11.7348C40.1037 11.5366 39.9665 11.4146 39.7683 11.3841C37.1463 10.8811 37.2073 10.8354 36.7043 8.32012C36.6738 8.12195 36.5518 8 36.3537 8C36.1555 8 36.0335 8.12195 35.9878 8.32012C35.5 10.8049 35.5762 10.8506 32.9238 11.3841C32.7256 11.4146 32.6037 11.5366 32.6037 11.7348C32.6037 11.9482 32.7256 12.0701 32.939 12.1006C35.561 12.6189 35.5457 12.6494 35.9878 15.1646C36.0335 15.3628 36.1555 15.4848 36.3537 15.4848ZM28.9604 26.1555C29.2652 26.1555 29.4939 25.9421 29.5396 25.6372C30.0732 21.5976 30.1951 21.5823 34.4024 20.7896C34.6921 20.7439 34.9207 20.5305 34.9207 20.2104C34.9207 19.9055 34.6921 19.6768 34.3872 19.6311C30.2104 19.0518 30.0579 18.9299 29.5396 14.814C29.4939 14.4939 29.2652 14.2805 28.9604 14.2805C28.6402 14.2805 28.4116 14.4939 28.3811 14.8293C27.878 18.8537 27.6951 18.8537 23.5183 19.6311C23.2134 19.6921 23 19.9055 23 20.2104C23 20.5457 23.2134 20.7439 23.5793 20.7896C27.7104 21.4451 27.878 21.5671 28.3811 25.6067C28.4116 25.9421 28.6402 26.1555 28.9604 26.1555ZM39.5244 43C39.9665 43 40.3018 42.6799 40.3628 42.2226C41.4604 33.9604 42.6341 32.6646 50.8049 31.7652C51.2774 31.7043 51.6128 31.3537 51.6128 30.9116C51.6128 30.4695 51.2774 30.1189 50.8049 30.0579C42.6646 29.1128 41.5061 27.8933 40.3628 19.6006C40.2866 19.1433 39.9665 18.8232 39.5244 18.8232C39.0823 18.8232 38.747 19.1433 38.686 19.6006C37.5884 27.8628 36.4146 29.1585 28.2439 30.0579C27.7561 30.1189 27.436 30.4695 27.436 30.9116C27.436 31.3537 27.7561 31.7043 28.2439 31.7652C36.3537 32.8476 37.4817 33.9299 38.686 42.2226C38.7622 42.6799 39.0823 43 39.5244 43Z"
      fill="currentColor"
    />
  ),
  viewBox: "15 5 45 45",
});

export { Sparkles };
