import * as React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useCurrentDateTime } from "../hooks";

function CurrentDateTime(props: BoxProps) {
  const date = useCurrentDateTime("EEEE, LLLL dd");
  const clock = useCurrentDateTime("hh:mm");

  return (
    <Box {...props}>
      <Box fontWeight="500" fontSize="5rem">
        {clock}
      </Box>
      <Box fontWeight="500" fontSize="1.75rem">
        {date}
      </Box>
    </Box>
  );
}

export { CurrentDateTime };
