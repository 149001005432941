import { Icon, useDisclosure } from "@chakra-ui/react";
import { usePermissions } from "contexts";
import { QuestionMarkCircle } from "icons";
import { Button } from "shared";
import Faq from "..";

type FaqButtonProps = {
  abbreviated?: boolean;
};

const FaqButton = ({ abbreviated }: FaqButtonProps) => {
  const { scope } = usePermissions();

  const faqDisclosure = useDisclosure();

  return (
    <>
      {scope("faq").isAccessible && (
        <Button
          color="blue"
          bgColor="white"
          borderRadius="40px"
          display="flex"
          justifyContent="center"
          gap={2}
          m="auto"
          my={4}
          onClick={faqDisclosure.onOpen}
          _hover={{ bgColor: "white" }}
        >
          <Icon as={QuestionMarkCircle} w="20px" h="20px" />
          {abbreviated ? "FAQ" : "Frequently Asked Questions"}
        </Button>
      )}
      <Faq {...faqDisclosure} />
    </>
  );
};

export { FaqButton };
