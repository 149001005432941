import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";
import { useAxiosMutation } from "hooks";

function useDeleteEncounterPrimaryCarePhysician(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterPrimaryCarePhysician", { encounterId }],
    () =>
      axiosPatientInstance.delete(
        `v1/encounter/${encounterId}/form/primary-care-physician/remove`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("primaryCarePhysicians");
        queryClient.invalidateQueries("patientInfo");
      },
    }
  );
}

export { useDeleteEncounterPrimaryCarePhysician };
