import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type VitalsTimerPayload = {
  encounterId: string;
};

function useResumeVitalTimer(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["resumeVitalsTimer"],
    (payload: VitalsTimerPayload) => {
      return axiosChartInstance.post<{ encounterId: string }>(
        `v1/Vitals/${encounterId}/timer-control/resume`,
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitalsTimer", { encounterId }]);
      },
    }
  );
}

export { useResumeVitalTimer };
