import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { AllergyRx, MedicationRx } from "modules/charts-shared/types";

type UseEncounterAllergiesMedicationsRxResponse = {
  allergiesRx: AllergyRx[];
  medicationsRx: MedicationRx[];
  nkda: boolean;
  none: boolean;
};

type UseEncounterAllergiesMedicationsRx = { encounterId: string };

function useEncounterAllergiesMedicationsRx(
  props: UseEncounterAllergiesMedicationsRx
) {
  const { encounterId } = props;

  return useAxiosQuery(
    ["getEncounterAllergiesMedicationsRx", { encounterId }],
    () =>
      axiosPatientInstance.get<UseEncounterAllergiesMedicationsRxResponse>(
        `v1/InProcess/${encounterId}/allergies-medications-rx`,
        {}
      )
  );
}
export { useEncounterAllergiesMedicationsRx };
export type { UseEncounterAllergiesMedicationsRxResponse };
