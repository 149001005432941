import { useQueryClient } from "react-query";
import { useAxiosMutation } from "hooks";
import { axiosChartInstance } from "config";

function useDeleteVitalCheck(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteVitalCheck"],
    (vitalCheckId: string) =>
      axiosChartInstance.delete(
        `v1/Vitals/vitals/${encounterId}/vital-check/${vitalCheckId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitals"]);
        queryClient.invalidateQueries(["chartPatientCard", { encounterId }]);
      },
    }
  );
}

export { useDeleteVitalCheck };
