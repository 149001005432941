import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Star = createIcon({
  path: (
    <path
      d="M4.81838 23.7925C5.28202 24.1543 5.87004 24.0299 6.57115 23.5211L12.5532 19.1222L18.5465 23.5211C19.2476 24.0299 19.8244 24.1543 20.2993 23.7925C20.7629 23.4419 20.8647 22.8652 20.582 22.0397L18.2186 15.006L24.2572 10.6636C24.9583 10.1661 25.241 9.6459 25.0601 9.08049C24.8791 8.53769 24.3476 8.2663 23.4769 8.2776L16.07 8.32284L13.8197 1.25521C13.5483 0.418404 13.1412 0 12.5532 0C11.9765 0 11.5694 0.418404 11.298 1.25521L9.04765 8.32284L1.64078 8.2776C0.770045 8.2663 0.238559 8.53769 0.0576279 9.08049C-0.134612 9.6459 0.159402 10.1661 0.86051 10.6636L6.89909 15.006L4.53568 22.0397C4.25297 22.8652 4.35474 23.4419 4.81838 23.7925ZM6.44676 21.5534C6.42415 21.5308 6.43545 21.5195 6.44676 21.4517L8.69709 14.9834C8.85541 14.5423 8.76494 14.1918 8.36916 13.9204L2.73767 10.0304C2.68113 9.99645 2.66982 9.97383 2.68113 9.93991C2.69244 9.90598 2.71506 9.90598 2.7829 9.90598L9.62437 10.0304C10.088 10.0417 10.382 9.84944 10.529 9.38581L12.4966 2.83836C12.508 2.77051 12.5306 2.74789 12.5532 2.74789C12.5871 2.74789 12.6097 2.77051 12.621 2.83836L14.5887 9.38581C14.7357 9.84944 15.0297 10.0417 15.4933 10.0304L22.3348 9.90598C22.4026 9.90598 22.4253 9.90598 22.4366 9.93991C22.4479 9.97383 22.4252 9.99645 22.38 10.0304L16.7485 13.9204C16.3527 14.1918 16.2623 14.5423 16.4206 14.9834L18.6709 21.4517C18.6822 21.5195 18.6935 21.5308 18.6709 21.5534C18.6483 21.5874 18.6144 21.5647 18.5692 21.5308L13.1299 17.3807C12.768 17.098 12.3496 17.098 11.9878 17.3807L6.54854 21.5308C6.5033 21.5647 6.46938 21.5874 6.44676 21.5534Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 26 24",
});

export { Star };
