import React from "react";

import {
  useEncounterOrderFile,
  useEncounterOrderFileRamsoft,
} from "modules/orders/api";

import {
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageIndicator,
  PdfViewerPreview,
  TabList,
  Tabs,
} from "shared";

import {
  Box,
  Icon,
  IconButton,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { Collapse, Expand } from "icons";

type RadiologyChartFileProps = {
  orderId: string;
  isFileMode: boolean;
  toggleFileMode: () => void;
};

function RadiologyChartFileWrapper(props: RadiologyChartFileProps) {
  const { orderId, isFileMode, toggleFileMode } = props;
  const [activeTab, setActiveTab] = React.useState(0);

  if (isFileMode) {
    return (
      <>
        {activeTab === 0 && (
          <RadiologyChartRamsoftFile
            orderId={orderId}
            isFileMode={isFileMode}
            toggleFileMode={toggleFileMode}
          />
        )}
        {activeTab === 1 && (
          <RadiologyChartManualFile
            orderId={orderId}
            isFileMode={isFileMode}
            toggleFileMode={toggleFileMode}
          />
        )}
      </>
    );
  }

  return (
    <Tabs
      isFitted
      size="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      index={activeTab}
      onChange={(index) => {
        setActiveTab(index);
      }}
    >
      <Box
        sx={{
          paddingTop: "5px",
          marginBottom: "20px",
        }}
      >
        <TabList
          index={activeTab}
          sx={{
            maxWidth: "70%",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Tab
            maxH="48"
            fontSize={"1.175rem"}
            _selected={{
              margin: "3px",
              height: "42px",
              bgColor: "white",
              color: "blue",
              borderRadius: "5px",
            }}
          >
            Ramsoft Report
          </Tab>
          <Tab
            maxH="48"
            fontSize={"1.175rem"}
            _selected={{
              margin: "3px",
              height: "42px",
              bgColor: "white",
              color: "blue",
              borderRadius: "5px",
            }}
          >
            Manual Report
          </Tab>
        </TabList>
      </Box>
      <TabPanels
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <TabPanel>
          {activeTab === 0 && (
            <RadiologyChartRamsoftFile
              orderId={orderId}
              isFileMode={isFileMode}
              toggleFileMode={toggleFileMode}
            />
          )}
        </TabPanel>
        <TabPanel>
          {activeTab === 1 && (
            <RadiologyChartManualFile
              orderId={orderId}
              isFileMode={isFileMode}
              toggleFileMode={toggleFileMode}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function RadiologyChartRamsoftFile(props: RadiologyChartFileProps) {
  const { orderId } = props;
  const { data: file } = useEncounterOrderFileRamsoft(orderId);
  return (
    <PdfViewer value={file?.data}>
      <PdfViewerContainer
        height="40vh"
        marginBottom="20px"
        activePage={<PdfViewerActivePage />}
        header={
          <PdfViewerHeader
            title="Ramsoft Report"
            leftElements={[<PdfViewerPageIndicator key="page-indicator" />]}
            rightElements={[
              <PdfViewerActions
                key="viewer-actions"
                showSubmit={false}
                showDelete={false}
              />,
            ]}
          />
        }
        preview={<PdfViewerPreview />}
      />
    </PdfViewer>
  );
}

function RadiologyChartManualFile(props: RadiologyChartFileProps) {
  const { orderId, isFileMode, toggleFileMode } = props;
  const { data: file } = useEncounterOrderFile(orderId);
  return (
    <PdfViewer value={file?.data}>
      <PdfViewerContainer
        height={isFileMode ? "80vh" : "40vh"}
        marginBottom="20px"
        activePage={<PdfViewerActivePage pageWidth={isFileMode ? 1200 : 800} />}
        header={
          <PdfViewerHeader
            title="Radiology Report"
            leftElements={[
              <IconButton
                key="expand-collapse-button"
                aria-label="submit document"
                icon={<Icon as={isFileMode ? Collapse : Expand} />}
                color="blue"
                variant="icon"
                onClick={() => toggleFileMode()}
              />,
              <PdfViewerPageIndicator key="page-indicator" />,
            ]}
            rightElements={[
              <PdfViewerActions
                key="viewer-actions"
                showSubmit={false}
                showDelete={false}
              />,
            ]}
          />
        }
        preview={<PdfViewerPreview />}
      />
    </PdfViewer>
  );
}

export {
  RadiologyChartFileWrapper,
  RadiologyChartRamsoftFile,
  RadiologyChartManualFile,
};
