type ChartInputSize = "xs" | "sm" | "md" | "lg" | "xl";

const CHART_INPUT_WIDTH: Record<ChartInputSize, string> = {
  xs: "80px",
  sm: "100px",
  md: "150px",
  lg: "200px",
  xl: "300px",
};

const chartMeasures = {
  CHART_INPUT_WIDTH,
};

export type { ChartInputSize };
export { chartMeasures };
