import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import {
  InsuranceCardPosition,
  InsuranceCardType,
} from "modules/reception/types";

type CreateInsuranceCardParams = {
  encounterId: string;
  position: InsuranceCardPosition;
  type: InsuranceCardType;
};

function useCreateInsuranceCard(params: CreateInsuranceCardParams) {
  const { encounterId, position, type } = params;
  return useAxiosMutation(
    ["createInsuranceCard", { encounterId }],
    (input: FormData) =>
      axiosPatientInstance.post<string>(
        `v1/encounter/${encounterId}/form/files/insurance-card/${type}/${position}`,
        input
      )
  );
}

export { useCreateInsuranceCard };
