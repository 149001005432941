import { chakra, ButtonProps, Divider } from "@chakra-ui/react";
import * as React from "react";
import { Counter } from "shared";

type OptionCardProps = {
  label: string;
  icon?: React.ReactElement;
  counter?: number;
  divider?: boolean;
} & ButtonProps;

function MenuOption(props: OptionCardProps) {
  const { label, icon, counter, divider, ...btnProps } = props;

  return (
    <>
      <chakra.button
        padding="0.875rem 0.75rem 0.875rem 1rem"
        height="3.4rem"
        color="gray.600"
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        borderRadius="10px"
        _disabled={{ opacity: "0.7", cursor: "not-allowed" }}
        {...btnProps}
      >
        <chakra.span fontSize="1.0625rem" fontWeight="500">
          {label}
        </chakra.span>
        <chakra.span w="1.5rem" h="1.5rem" textAlign="center">
          {icon &&
            React.cloneElement(icon, { fontSize: "1.25rem", ...icon.props })}
        </chakra.span>
        {counter !== undefined && counter !== null && (
          <Counter count={counter} />
        )}
      </chakra.button>
      {divider && <Divider orientation="horizontal" color="gray.450" />}
    </>
  );
}

export { MenuOption };
export type { OptionCardProps };
