import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ExclamationSquare = createIcon({
  path: (
    <path
      d="M4.73479 24.1262H20.107C22.932 24.1262 24.4209 22.65 24.4209 19.8632V4.40188C24.4209 1.61503 22.932 0.12616 20.107 0.12616H4.73479C1.92249 0.12616 0.420898 1.61503 0.420898 4.40188V19.8632C0.420898 22.65 1.92249 24.1262 4.73479 24.1262ZM12.4845 14.1877C11.6701 14.1877 11.212 13.7423 11.1738 12.9151L10.9829 6.89604C10.9448 6.04345 11.5683 5.44535 12.4718 5.44535C13.3626 5.44535 13.9988 6.05617 13.9607 6.90877L13.7698 12.9151C13.7316 13.7423 13.2735 14.1877 12.4845 14.1877ZM12.4845 18.667C11.5683 18.667 10.8175 18.0307 10.8175 17.1399C10.8175 16.2492 11.5556 15.6129 12.4845 15.6129C13.4135 15.6129 14.1388 16.2492 14.1388 17.1399C14.1388 18.0434 13.4008 18.667 12.4845 18.667Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 25 25",
});

export { ExclamationSquare };
