import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ActionsPayload } from "../../types";

type UseUpdateTriageChartActionsProps = {
  encounterId: string;
  chartCode: string;
};

function useUpdateTriageChartActions(props: UseUpdateTriageChartActionsProps) {
  const { encounterId, chartCode } = props;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateTriageChartActions", props],
    (input: ActionsPayload) =>
      axiosChartInstance.put(
        `v1/triage/${encounterId}/${chartCode}/actions`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "triageChartActions",
          { encounterId, chartCode },
        ]),
    }
  );
}

export { useUpdateTriageChartActions };
