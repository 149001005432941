import * as React from "react";
import { ChartNotesModalFooter } from "modules";
import {
  useCreateChartNote,
  useCreateAudioChartNote,
} from "modules/charts-shared/api";

type AsyncChartNotesModalFooterProps = {
  encounterId: string;
  chartId: string;
  sectionId: string;
  subsectionId?: string;
  onCreateNoteSuccess?: () => void;
  isDisabled?: boolean;
};

function AsyncChartNotesModalFooter(props: AsyncChartNotesModalFooterProps) {
  const {
    encounterId,
    chartId,
    sectionId,
    subsectionId,
    onCreateNoteSuccess,
    isDisabled,
  } = props;
  const { mutateAsync: createNote, isLoading: isCreateNoteLoading } =
    useCreateChartNote({
      encounterId,
      chartCode: chartId,
      section: sectionId,
      subsection: subsectionId,
    });
  const { mutateAsync: createAudioNote, isLoading: isCreateAudioNoteLoading } =
    useCreateAudioChartNote({
      encounterId,
      chartCode: chartId,
      section: sectionId,
      subsection: subsectionId,
    });
  const isLoading = isCreateNoteLoading || isCreateAudioNoteLoading;

  return (
    <ChartNotesModalFooter
      isLoading={isLoading}
      onCreateAudioNote={createAudioNote}
      onCreateNote={createNote}
      onCreateNoteSuccess={onCreateNoteSuccess}
      isDisabled={isDisabled}
    />
  );
}

export { AsyncChartNotesModalFooter };
