import { FieldValues, UseControllerProps } from "react-hook-form";

function useDivideControlProps<
  TFieldValues extends FieldValues,
  T extends UseControllerProps<TFieldValues>
>(
  props: T
): [
  Omit<T, keyof UseControllerProps<TFieldValues>>,
  UseControllerProps<TFieldValues>
] {
  const { name, control, defaultValue, rules, shouldUnregister, ...restProps } =
    props;
  const controlProps: UseControllerProps<TFieldValues> = {
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  };

  return [restProps, controlProps];
}

export { useDivideControlProps };
