import * as React from "react";
import { Interaction } from "types";
import {
  Box,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Button,
  Flex,
  chakra,
} from "@chakra-ui/react";
import { Avatar, Card } from "shared";
import { format } from "date-fns";
import { SCROLLBARSTYLE } from "modules/labs/constants";

type ChartSubsectionInteractionsModalProps = {
  interactions: Interaction[];
  isOpen: boolean;
  onClose(): void;
  label: React.ReactNode;
};

function buildInteractionPhrase(interaction: Interaction) {
  const { action, previousValue, newValue } = interaction;

  if (action === "added") return `${action} "${newValue}"`;

  if (action === "removed") return `${action} value "${previousValue}"`;

  if (action === "updated")
    return `${action} from "${previousValue}" to "${newValue}"`;
}

function ChartSubsectionInteractionsModal(
  props: ChartSubsectionInteractionsModalProps
) {
  const { interactions, isOpen, onClose, label } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInRight">
      <ModalContent
        containerProps={{ justifyContent: "flex-end", paddingRight: "2rem" }}
        background="gray.200"
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          minH="120px"
          backgroundColor="gray.200"
          alignItems="center"
        >
          <Box w={30} />
          <Heading fontWeight={600} fontSize="1.0625rem" color="gray.650">
            Chart Interactions Log
          </Heading>
          <Button variant="label" color="blue" onClick={onClose}>
            Close
          </Button>
        </ModalHeader>

        <ModalBody
          backgroundColor="gray.50"
          display="flex"
          flexDir="column"
          gap="1.25rem"
          h="480px"
          maxH="480px"
          overflowY="auto"
          css={SCROLLBARSTYLE}
          pt={5}
          pb={5}
        >
          <Heading
            fontWeight={600}
            fontSize="1.0625rem"
            color="gray.650"
            alignSelf="center"
          >
            {label}
          </Heading>

          <Flex direction="column-reverse" gap="1.25rem">
            {interactions.map((interaction) => (
              <Card
                key={interaction.createdAt}
                h="120px"
                p="1rem"
                display="flex"
                flexDir="column"
                gap="0.5rem"
                variant="basic"
              >
                <Flex direction="row" justifyContent="space-between">
                  <Flex direction="row" alignItems="center" gap="1rem">
                    <Avatar src={interaction.user.pictureUrl} size="xs" />
                    <Flex direction="column">
                      <chakra.span fontSize="16px" fontWeight={600}>
                        {interaction.user.fullName}
                      </chakra.span>
                      <chakra.span
                        lineHeight={0.85}
                        fontSize="14px"
                        color="gray.750"
                      >
                        {interaction.user.teams}
                      </chakra.span>
                    </Flex>
                  </Flex>

                  <chakra.span color="gray.750">
                    {format(
                      new Date(interaction.createdAt),
                      "MM/dd/yyyy hh:mm a"
                    )}
                  </chakra.span>
                </Flex>

                <chakra.span>
                  <chakra.span fontWeight={600}>
                    {interaction.label} -{" "}
                  </chakra.span>
                  {buildInteractionPhrase(interaction)}
                </chakra.span>
              </Card>
            ))}
          </Flex>

          {interactions.length < 1 && (
            <chakra.span color="gray.650" alignSelf="center">
              No interactions found
            </chakra.span>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ChartSubsectionInteractionsModal };
