import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChangePasswordForm } from "types";

function useChangePassword() {
  return useAxiosMutation("changePassword", (input: ChangePasswordForm) =>
    axiosIdentityInstance.put("v1/user/change-password", input)
  );
}

export { useChangePassword };
