import * as React from "react";
import { chakra, Icon } from "@chakra-ui/react";
import { EmrProfileComplete } from "@medstonetech/slate-icons";
import { Card, CardProps } from "shared";
import {
  PROCEDURES_ROW_TEMPLATE_COLUMNS,
  PROCEDURES_CONTAINER_PADDING,
} from "./constants";

function ProceduresHeader(props: CardProps) {
  return (
    <Card
      padding={PROCEDURES_CONTAINER_PADDING}
      bg="gray.250"
      boxShadow="none"
      color="gray.650"
      display="grid"
      gridTemplateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS}
      gridGap="1rem"
      alignItems="center"
      {...props}
    >
      <chakra.span textAlign="center">Date / Time</chakra.span>
      <chakra.span>Description</chakra.span>
      <Icon
        as={EmrProfileComplete}
        w="1.25rem"
        h="1.25rem"
        color="gray.650"
        justifySelf="center"
      />
    </Card>
  );
}

export { ProceduresHeader };
