import React, { forwardRef } from "react";
import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

type CounterProps = {
  count: number;
  size?: "sm" | "lg";
} & BoxProps;

const Counter = forwardRef<HTMLDivElement, CounterProps>(
  ({ count, size, ...props }, ref) => {
    const styles = useStyleConfig("Counter", { size });

    return (
      <Box ref={ref} __css={styles} {...props}>
        {count}
      </Box>
    );
  }
);
Counter.displayName = "Counter";

export { Counter };
