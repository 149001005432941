import * as React from "react";
import {
  Checkbox as CkCheckbox,
  CheckboxProps as CkCheckboxProps,
} from "@chakra-ui/react";

const omittedProps = ["size", "variant", "colorScheme"] as const;
type CheckboxProps = Omit<CkCheckboxProps, typeof omittedProps[number]>;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    return <CkCheckbox ref={ref} {...props} />;
  }
);

Checkbox.displayName = "Checkbox";

export type { CheckboxProps };
export { Checkbox };
