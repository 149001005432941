import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosQuery } from "hooks";
import { ChartNote } from "types";

type UseOrderChartNotesParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
};

function useOrderChartNotes(params: UseOrderChartNotesParams) {
  const { encounterId, orderType } = params;
  return useAxiosQuery(["orderChartNotes", params], () =>
    axiosChartInstance.get<ChartNote[]>(`v1/order/${encounterId}/note`, {
      params: { orderType },
    })
  );
}

export type { UseOrderChartNotesParams };
export { useOrderChartNotes };
