import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import {
  ChartSection,
  ChartSectionHeader,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
} from "modules";
import { useTriageChartProceduresForm } from "../../hooks";
import { ProceduresHeader, proceduresConstants } from "../../shared";
import { usePermissions } from "contexts";

const triageChartCode = "T013x";

const { PROCEDURES_CONTAINER_PADDING_2, PROCEDURES_ROW_TEMPLATE_COLUMNS_2 } =
  proceduresConstants;

function N13Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ProceduresHeader />
        <ChartSubsection
          content={
            <>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[0] && sections[0].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[0] && sections[0].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[0].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="portable"
                      name="sections[0].T013xSPROQ001A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="to Xray"
                      name="sections[0].T013xSPROQ001A002"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[0].T013xSPROQ001A042"
                      label="w/"
                    />
                    <ChartRowElement
                      type="option"
                      label="monitor"
                      name="sections[0].T013xSPROQ001A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[0].T013xSPROQ001A004"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[0].T013xSPROQ001A005"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[0].T013xSPROQ001A006"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[0].T013xSPROQ001A046"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[1] && sections[1].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[1] && sections[1].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[1].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="return to room"
                      name="sections[1].T013xSPROQ001A007"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[1].T013xSPROQ001A008"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[2] && sections[2].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[2] && sections[2].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[2].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="to CT"
                      name="sections[2].T013xSPROQ001A009"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[2].T013xSPROQ001A043"
                      label="w/"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="contrast"
                      name="sections[2].T013xSPROQ001A010"
                    />
                    <ChartRowElement
                      type="option"
                      label="parental meds"
                      name="sections[2].T013xSPROQ001A011"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[3].T013xSPROQ001A047"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[3].T013xSPROQ001A048"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[3].T013xSPROQ001A044"
                      label="w/"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="monitor"
                      name="sections[3].T013xSPROQ001A012"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[3].T013xSPROQ001A013"
                    />
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="sections[3].T013xSPROQ001A014"
                    />
                    <ChartRowElement
                      type="option"
                      label="tech"
                      name="sections[3].T013xSPROQ001A015"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[4] && sections[4].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[4] && sections[4].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[4].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="return to room"
                      name="sections[4].T013xSPROQ001A017"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[4].T013xSPROQ001A018"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[5] && sections[5].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[5] && sections[5].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[5].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="lab drawn"
                      name="sections[5].T013xSPROQ001A019"
                    />
                    <ChartRowElement
                      type="option"
                      label="sent"
                      name="sections[5].T013xSPROQ001A020"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[5].T013xSPROQ001A045"
                      label="by"
                    />
                    <ChartRowElement
                      type="option"
                      label="ED tech"
                      name="sections[5].T013xSPROQ001A021"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[5].T013xSPROQ001A022"
                    />
                    <ChartRowElement
                      type="option"
                      label="lab"
                      name="sections[5].T013xSPROQ001A023"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[5].T013xSPROQ001A049"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[16] && sections[16].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[16] && sections[16].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[16].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ABG drawn"
                      name="sections[16].T013xSPROQ001A050"
                    />
                    <ChartRowElement
                      type="option"
                      label="by"
                      fontStyle="italic"
                      name="sections[16].T013xSPROQ001A051"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[16].T013xSPROQ001A052"
                      label="by"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[16].T013xSPROQ001A053"
                    />
                    <ChartRowElement
                      type="option"
                      label="MD"
                      name="sections[16].T013xSPROQ001A054"
                    />
                    <ChartRowElement
                      type="option"
                      label="RT"
                      name="sections[16].T013xSPROQ001A055"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[16].T013xSPROQ001A056"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[17] && sections[17].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[17] && sections[17].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[17].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="blood CX drawn"
                      name="sections[17].T013xSPROQ001A057"
                    />
                    <ChartRowElement
                      type="option"
                      label="by"
                      fontStyle="italic"
                      name="sections[17].T013xSPROQ001A058"
                    />
                    <ChartRowElement
                      type="option"
                      name="sections[17].T013xSPROQ001A059"
                      label="by"
                    />
                    <ChartRowElement
                      type="option"
                      label="nurse"
                      name="sections[17].T013xSPROQ001A060"
                    />
                    <ChartRowElement
                      type="option"
                      label="MD"
                      name="sections[17].T013xSPROQ001A061"
                    />
                    <ChartRowElement
                      type="option"
                      label="RT"
                      name="sections[17].T013xSPROQ001A062"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[17].T013xSPROQ001A063"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[6] && sections[6].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[6] && sections[6].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[6].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="LP tray set up"
                      name="sections[6].T013xSPROQ001A024"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[6].T013xSPROQ001A064"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[7] && sections[7].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[7] && sections[7].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[7].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="consent signed"
                      name="sections[7].T013xSPROQ001A025"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[7].T013xSPROQ001A065"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[8] && sections[8].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[8] && sections[8].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[8].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="assisted with LP"
                      name="sections[8].T013xSPROQ001A026"
                    />
                    <ChartRowElement
                      type="option"
                      label="tolerated well"
                      name="sections[8].T013xSPROQ001A027"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[8].T013xSPROQ001A066"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[9] && sections[9].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[9] && sections[9].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[9].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="spinal fluid to lab"
                      name="sections[9].T013xSPROQ001A028"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[9].T013xSPROQ001A067"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[10] && sections[10].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[10] && sections[10].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[10].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bronchodilator treatment"
                      name="sections[10].T013xSPROQ001A029"
                    />
                    <ChartRowElement
                      type="option"
                      label="nebulizer"
                      fontStyle="italic"
                      name="sections[10].T013xSPROQ001A030"
                    />
                    <ChartRowElement
                      type="option"
                      label="inhaler"
                      fontStyle="italic"
                      name="sections[10].T013xSPROQ001A031"
                    />
                    <ChartRowElement
                      type="option"
                      label="x1"
                      name="sections[11].T013xSPROQ001A032"
                    />
                    <ChartRowElement
                      type="option"
                      label="x2"
                      name="sections[12].T013xSPROQ001A034"
                    />
                    <ChartRowElement
                      type="option"
                      label="x3"
                      name="sections[13].T013xSPROQ001A036"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[13].T013xSPROQ001A037"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[18] && sections[18].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[18] && sections[18].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[18].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="peak flow"
                      name="sections[18].T013xSPROQ001A068"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[18].T013xSPROQ001A069"
                      w="100px"
                    />
                    <ChartRowElement
                      type="label"
                      label="mL"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="in ED"
                      name="sections[18].T013xSPROQ001A070"
                    />
                    <ChartRowElement
                      type="option"
                      label="pre treatment"
                      name="sections[18].T013xSPROQ001A071"
                    />
                    <ChartRowElement
                      type="option"
                      label="post treatment"
                      name="sections[18].T013xSPROQ001A072"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[18].T013xSPROQ001A073"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="usual"
                      name="sections[18].T013xSPROQ001A074"
                    />
                    <ChartRowElement
                      type="input"
                      w="100px"
                      name="sections[18].T013xSPROQ001A075"
                    />
                    <ChartRowElement
                      type="label"
                      label="mL"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="option"
                      label="current at home"
                      name="sections[18].T013xSPROQ001A076"
                    />
                    <ChartRowElement
                      type="input"
                      w="100px"
                      name="sections[18].T013xSPROQ001A077"
                    />
                    <ChartRowElement
                      type="label"
                      label="mL"
                      fontStyle="italic"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[18].T013xSPROQ001A078"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[19] && sections[19].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[19] && sections[19].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[19].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="Foley"
                      name="sections[19].T013xSPROQ001A038"
                    />
                    <ChartRowElement
                      type="option"
                      label="straight cath"
                      name="sections[19].T013xSPROQ001A039"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[19].T013xSPROQ001A079"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[15] && sections[15].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[15] && sections[15].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[15].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="urine collection bag placed"
                      name="sections[15].T013xSPROQ001A041"
                    />
                    <ChartRowElement
                      type="input"
                      name="sections[15].T013xSPROQ001A080"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[20] && sections[20].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[20] && sections[20].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[20].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[20].T013xSPROQ001A081"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[21] && sections[21].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[21] && sections[21].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[21].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[21].T013xSPROQ001A082"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[22] && sections[22].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[22] && sections[22].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[22].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[22].T013xSPROQ001A083"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[23] && sections[23].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[23] && sections[23].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[23].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[23].T013xSPROQ001A084"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={PROCEDURES_CONTAINER_PADDING_2}>
                <ChartRow templateColumns={PROCEDURES_ROW_TEMPLATE_COLUMNS_2}>
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      fontSize=".25rem"
                      w="32px"
                      h="32px"
                      userName={
                        (sections[24] && sections[24].createdByFullName) || ""
                      }
                      profileUrl={
                        (sections[24] && sections[24].createdByPictureUrl) || ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement type="date" name="sections[24].date" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="sections[24].T013xSPROQ001A085"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N13Procedures };
