import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection } from "modules/charts-shared";

function useTriageMedicalHistoryFirstSnapshot(encounterId: string) {
  return useAxiosQuery(["medicalHistoryFirstSnapshot", { encounterId }], () =>
    axiosChartInstance.get<ChartFormSubsection[]>(
      `v1/triage/${encounterId}/medical-history/first-snapshot`
    )
  );
}

export { useTriageMedicalHistoryFirstSnapshot };
