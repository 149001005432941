import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";

type EncounterFormSection =
  | "Info"
  | "ContactInfo"
  | "Identification"
  | "PrimaryInsuranceVerification"
  | "PrimaryInsuranceBenefit"
  | "PrimaryInsuranceInformation"
  | "PrimaryInsuranceGuarantor"
  | "SecondaryInsuranceVerification"
  | "SecondaryInsuranceBenefit"
  | "SecondaryInsuranceInformation"
  | "SecondaryInsuranceGuarantor"
  | "TertiaryInsuranceVerification"
  | "TertiaryInsuranceBenefit"
  | "TertiaryInsuranceInformation"
  | "TertiaryInsuranceGuarantor";

type UseValidateEncounterFormProps = {
  encounterId: string;
  section: EncounterFormSection;
};

type UseValidateEncounterResponse = {
  isValidated: boolean;
  section: EncounterFormSection;
};

function useValidateEncounterForm(props: UseValidateEncounterFormProps) {
  const { encounterId, section } = props;

  return useAxiosMutation(["validateEncounterForm", props], () =>
    axiosPatientInstance.put<UseValidateEncounterResponse>(
      `v1/encounter/${encounterId}/form/validate/${section}`
    )
  );
}

export type {
  EncounterFormSection,
  UseValidateEncounterFormProps,
  UseValidateEncounterResponse,
};
export { useValidateEncounterForm };
