import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Countdown = createIcon({
  path: (
    <path
      d="M6.1875 12.3809C9.58594 12.3809 12.375 9.58594 12.375 6.1875C12.375 2.79492 9.58008 0 6.1875 0C2.78906 0 0 2.79492 0 6.1875C0 9.58594 2.79492 12.3809 6.1875 12.3809ZM6.1875 10.8398C3.61523 10.8398 1.54688 8.76562 1.54688 6.1875C1.54688 3.61523 3.60938 1.54102 6.1875 1.54102C8.75977 1.54102 10.834 3.61523 10.834 6.1875C10.8398 8.76562 8.76562 10.8398 6.1875 10.8398ZM4.26562 5.12109L5.83594 6.69141C6.06445 6.91992 6.41016 6.91992 6.63867 6.69141L9.07617 4.24805C9.30469 4.02539 9.30469 3.67383 9.07617 3.45117C8.84766 3.22852 8.50781 3.22852 8.2793 3.45117L6.23438 5.49023L5.06836 4.32422C4.83984 4.0957 4.49414 4.0957 4.27148 4.32422C4.04883 4.55273 4.04297 4.89844 4.26562 5.12109ZM0.0410156 9.69141V11.2734C0.0410156 12.1348 0.474609 12.5625 1.3418 12.5625H3.07031C1.77539 11.9238 0.755859 10.957 0.0410156 9.69141ZM12.4219 9.69141C11.7012 10.957 10.6816 11.9238 9.38672 12.5625H11.1152C11.9824 12.5625 12.4219 12.1348 12.4219 11.2734V9.69141Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 13 13",
});

export { Countdown };
