import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T041TesticularScanChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Testicular Scan done:"
            fontStyle="italic"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function TesticularScanChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C035x: <T041TesticularScanChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { TesticularScanChart };
