import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonAdd = createIcon({
  path: (
    <path
      d="M10.0122 6.87012C8.23975 6.87012 6.81152 5.31738 6.8042 3.40576C6.8042 1.52344 8.24707 0 10.0122 0C11.7847 0 13.2129 1.50879 13.2129 3.39111C13.2129 5.31006 11.7773 6.87012 10.0122 6.87012ZM10.0122 5.60303C11.0376 5.60303 11.8872 4.62891 11.8872 3.39111C11.8872 2.18994 11.0376 1.26709 10.0122 1.26709C8.98682 1.26709 8.12988 2.19727 8.13721 3.40576C8.13721 4.64355 8.99414 5.60303 10.0122 5.60303ZM8.37158 13.6816C8.51074 13.2568 8.56201 12.8687 8.57666 12.4146H14.8169C14.9927 12.4146 15.0586 12.3633 15.0586 12.2241C15.0586 11.1035 13.2422 8.99414 10.0049 8.99414C9.08203 8.99414 8.26904 9.17725 7.59521 9.47021C7.33887 9.09668 7.02393 8.76709 6.66504 8.48145C7.60254 8.02002 8.73047 7.72705 10.0049 7.72705C13.938 7.72705 16.4429 10.4224 16.4429 12.4292C16.4429 13.2861 15.835 13.6816 14.5752 13.6816H8.37158ZM3.77197 16.0767C1.70654 16.0767 0 14.3774 0 12.312C0 10.2466 1.70654 8.54004 3.77197 8.54004C5.8374 8.54004 7.54395 10.2466 7.54395 12.312C7.54395 14.3701 5.81543 16.0767 3.77197 16.0767ZM3.77197 14.6997C4.07227 14.6997 4.27002 14.502 4.27002 14.2017V12.8101H5.66162C5.95459 12.8101 6.15967 12.6123 6.15967 12.312C6.15967 12.0117 5.95459 11.814 5.66162 11.814H4.27002V10.4224C4.27002 10.1294 4.07227 9.92432 3.77197 9.92432C3.47168 9.92432 3.27393 10.1294 3.27393 10.4224V11.814H1.88232C1.58203 11.814 1.38428 12.0117 1.38428 12.312C1.38428 12.6123 1.58936 12.8101 1.88232 12.8101H3.27393V14.2017C3.27393 14.502 3.47168 14.6997 3.77197 14.6997Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 17",
});

export { PersonAdd };
