import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseChartSubsectionFileProps = {
  encounterId: string;
  subsectionId: string;
};

function useChartSubsectionFile({
  encounterId,
  subsectionId,
}: UseChartSubsectionFileProps) {
  return useAxiosQuery(
    ["chartSubsectionFile", { encounterId, subsectionId }],
    () =>
      axiosChartInstance.get<Blob>(
        `v1/chart/${encounterId}/ekg/${subsectionId}/file`,
        { responseType: "blob" }
      )
  );
}

export { useChartSubsectionFile };
