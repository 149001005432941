import { useAuth0 } from "@auth0/auth0-react";
import { AuthLayout } from "modules/auth/components/auth-layout";
import * as React from "react";
import { Button } from "shared";

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <AuthLayout>
      <Button
        type="submit"
        width="275px"
        onClick={() => loginWithRedirect()}
        margin="auto"
      >
        Sign In
      </Button>
    </AuthLayout>
  );
}

export { Login };
