import { BaseVitalsColumn, Spo2Type, TemperatureType } from "../types/types";

const SPO2_LABEL: Record<Spo2Type, string> = {
  hf: "High Flow",
  m: "Mask",
  nc: "Nasal Canula",
  ra: "Room air",
};

const VITALS_COLUMN_VALUE_KEYS: (keyof BaseVitalsColumn)[] = [
  "acuity",
  "painLevel",
  "bp",
  "pulse",
  "rr",
  "temp",
  "spO2",
  "spO2Type",
  "gcs",
  "pupils",
  "fetalHt",
];

const VALUE_KEYS_LABELS = [
  "Acuity (1-5)",
  "Pain (1-10)",
  "BP",
  "Pulse",
  "RR",
  "Temp (type)",
  "SpO2",
  "SpO2 type",
  "GCS",
  "Pupils (L/R)",
  "Fetal HT",
];

const COLORS = {
  GRAY_1: "#F2F2F7",
} as const;

const TEMPERATURE_TYPE_LABEL: Readonly<Record<TemperatureType, string>> = {
  oral: "Oral",
  rectal: "Rectal",
  tm: "TM",
};

export {
  VITALS_COLUMN_VALUE_KEYS,
  VALUE_KEYS_LABELS,
  TEMPERATURE_TYPE_LABEL,
  COLORS,
  SPO2_LABEL,
};
