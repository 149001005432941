import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteAuditCommentsReply(
  encounterId: string,
  chartCode: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "deleteAuditCommentsReply",
      { encounterId, chartCode, sectionCode, subsectionCode },
    ],
    (replyId: string) =>
      axiosChartInstance.delete(
        `/v1/audits/subsection/comments/reply/${replyId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditComments",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsReplySubsectionIndicator",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

function useDeleteAuditCommentsSharedReply(
  encounterId: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "deleteSharedAuditCommentsReply",
      { encounterId, sectionCode, subsectionCode },
    ],
    (replyId: string) =>
      axiosChartInstance.delete(
        `/v1/audits/subsection/comments/reply/${replyId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsShared",
          { encounterId, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

export { useDeleteAuditCommentsReply, useDeleteAuditCommentsSharedReply };
