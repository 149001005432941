import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UseUsersResponseItem } from "modules/identity";

type OrderProviderAuthorizationResponseDto = {
  isAuthorized: boolean;
  user: UseUsersResponseItem;
};

function useGetOrderProviderAuthorization(encounterId: string) {
  return useAxiosQuery(["orderProviderAuthorization", { encounterId }], () =>
    axiosPatientInstance.get<OrderProviderAuthorizationResponseDto>(
      `/v1/Encounter/${encounterId}/order-signature`
    )
  );
}

export { useGetOrderProviderAuthorization };
