import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import { useArrivalInfo, useUpdateArrivalInfo } from "../api";

import { Icon } from "@chakra-ui/react";
import { EmrCar } from "@medstonetech/slate-icons";
import { useSharedChartForm } from "modules/charts-shared";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

type ArrivalInfoSectionProps = {
  chartCode: string;
  hasParent?: boolean;
  showLastDoseOptions?: boolean;
  isReadOnly?: boolean;
};

const sectionCode = "SARI";

function ArrivalInfo(props: ArrivalInfoSectionProps) {
  const { hasParent, showLastDoseOptions, chartCode, isReadOnly } = props;
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    onSaveAndNext,
    updateIsLoading,
    useFormContext,
  } = useSharedChartForm({
    sectionLabel: "Arrival Info",
    useGetSharedChartForm: useArrivalInfo,
    useUpdateSharedChartForm: useUpdateArrivalInfo,
    sectionCode,
  });

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<Icon as={EmrCar} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Arrival Info {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q001"
              subsectionLabel="Historian"
              showNotesBtn={false}
            >
              Historian
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                {hasParent && (
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T001xSARIQ001A005"
                    label="parent"
                  />
                )}
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSARIQ001A001"
                  label="patient"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSARIQ001A002"
                  label="paramedics"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSARIQ001A003"
                  label="family"
                />
                <ChartRowElement type="input" name="Q001.T001xSARIQ001A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q002"
              subsectionLabel="Barriers"
              showNotesBtn={false}
            >
              Barriers
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="learning"
                  name="Q002.T001xSARIQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="communication"
                  name="Q002.T001xSARIQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="interpreter"
                  name="Q002.T001xSARIQ002A003"
                />
                <ChartRowElement type="input" name="Q002.T001xSARIQ002A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q003"
              subsectionLabel="Arrival Mode"
              showNotesBtn={false}
            >
              Arrival Mode
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="car"
                  name="Q003.T001xSARIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="EMS"
                  name="Q003.T001xSARIQ003A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="police"
                  name="Q003.T001xSARIQ003A003"
                />
                <ChartRowElement type="input" name="Q003.T001xSARIQ003A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q006"
              subsectionLabel="Treatment PTA"
              showNotesBtn={false}
            >
              Treatment PTA
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="see EMS report"
                  name="Q006.T001xSARIQ006A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="IV"
                  name="Q006.T001xSARIQ006A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="02"
                  name="Q006.T001xSARIQ006A003"
                />
                {!showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="c-collar"
                    name="Q006.T001xSARIQ006A004"
                  />
                )}
                {!showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="backboard"
                    name="Q006.T001xSARIQ006A005"
                  />
                )}
                {showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="tylenol"
                    name="Q006.T013xSARIQ006A004"
                  />
                )}
                {showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="ibuprofen"
                    name="Q006.T013xSARIQ006A005"
                  />
                )}
                <ChartRowElement type="empty" />
              </ChartRow>
              {showLastDoseOptions && (
                <ChartRow isEvenlySpaced>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Time of last dose"
                      name="Q006.T013xSARIQ006A006"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q006.T013xSARIQ006A007"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Amount given"
                      name="Q006.T013xSARIQ006A008"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q006.T013xSARIQ006A009"
                    />
                  </ChartRowGroup>
                </ChartRow>
              )}
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="last blood glucose"
                  name="Q006.T001xSARIQ006A006"
                />
                <ChartRowElement
                  type="input"
                  name="Q006.T001xSARIQ006A007"
                  size="lg"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q006.T001xSARIQ006A010"
                  label="mg/dL"
                />
                <ChartRowElement type="input" name="Q006.T001xSARIQ006A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { ArrivalInfo };
