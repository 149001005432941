import {
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { EmrXmark } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { Import, Write } from "icons";
import React from "react";
import { Input } from "shared";
import { constants } from "system-constants";
import { ImportSignatureModal } from "./ImportSignatureModal";

const SignatureModal = React.lazy(
  () => import(/* webpackChunkName: "SignatureModal" */ "./SignatureModal")
);

type SignaturesOptionsProps = {
  onSign: (signature: string) => void;
  isLoading?: boolean;
};

const SignaturesOptions = ({ onSign, isLoading }: SignaturesOptionsProps) => {
  const toast = useToast();
  const [imageSelected, setImageSelected] = React.useState<File | null>(null);

  const {
    isOpen: isSignatureOpen,
    onClose: onSignatureClose,
    onOpen: onSignatureOpen,
  } = useDisclosure();

  const {
    isOpen: isImportSignatureOpen,
    onClose: onImportSignatureClose,
    onOpen: onImportSignatureOpen,
  } = useDisclosure();

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files && files.length > 0) {
      const image = files[0];

      if (image.size > constants.MAX_FILE_SIZE) {
        toast({
          description: `Files must be smaller than ${constants.MAX_FILE_SIZE_IN_MB}MB`,
        });
        return;
      }

      setImageSelected(image);
      onImportSignatureOpen();
      e.currentTarget.value = "";
    }
  };

  return (
    <Box
      gap="4.375rem"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <chakra.label
        display="flex"
        flexDirection="column"
        alignItems="center"
        tabIndex={0}
        aria-label="image picker"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="gray.700"
          borderRadius="50%"
          width="3.75rem"
          height="3.75rem"
          _hover={{
            cursor: "pointer",
          }}
        >
          <Icon as={Import} w="24px" h="29.19px" color="white" />
        </Box>
        <chakra.span color="gray.700">Import</chakra.span>
        <Input
          type="file"
          display="none"
          accept="image/*"
          onChange={handleSelectFile}
        />
      </chakra.label>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="gray.700"
          borderRadius="50%"
          width="3.75rem"
          height="3.75rem"
          _hover={{
            cursor: "pointer",
          }}
          onClick={onSignatureOpen}
        >
          <Icon as={Write} w="24px" h="19.12px;" color="white" />
        </Box>

        <chakra.span color="gray.700">Write</chakra.span>
      </Box>

      <React.Suspense fallback={null}>
        <SignatureModal
          isOpen={isSignatureOpen}
          onClose={onSignatureClose}
          onSign={onSign}
          isLoading={isLoading}
        />
      </React.Suspense>
      {imageSelected && (
        <ImportSignatureModal
          isOpen={isImportSignatureOpen}
          onClose={onImportSignatureClose}
          onSign={onSign}
          isLoading={isLoading}
          image={imageSelected}
        />
      )}
    </Box>
  );
};

type AddSignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & SignaturesOptionsProps;

const AddSignatureModal = (props: AddSignatureModalProps) => {
  const { onClose } = props;
  return (
    <Modal {...props} isCentered>
      <ModalContent
        borderRadius="5px"
        minWidth="400px"
        minHeight="240px"
        sx={{ backgroundColor: "gray.50", border: "1px solid #D1D1D6" }}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderTopRadius="5px"
          borderBottom="1px solid #D1D1D6"
        >
          <Box />
          <Box display="flex" alignContent="center" gap="0.5rem">
            <Text fontSize="15px">Add Signature</Text>
          </Box>

          <chakra.button
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="24px"
            h="24px"
            bg="gray.650"
            borderRadius="50%"
            onClick={onClose}
          >
            <Icon as={EmrXmark} w="12px" h="12px" color="gray.50" />
          </chakra.button>
        </ModalHeader>
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <SignaturesOptions {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { AddSignatureModal };
