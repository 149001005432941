import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";

function useEncounterChiefComplaintFile(encounterId: string) {
  return useQuery(
    ["chiefComplaintFile", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(
        `v1/encounter/${encounterId}/form/files/chief-complaint-thumbnail`,
        { responseType: "blob" }
      ),
    { staleTime: 60000 }
  );
}

export { useEncounterChiefComplaintFile };
