import { axiosSLPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { SearchPatientsElement } from "modules/stp-vip/types";

type UseStpVipPatientById = { patientId: string };

function useStpVipPatientById({ patientId }: UseStpVipPatientById) {
  return useAxiosQuery(["stpVipPatientById", { patientId }], () =>
    axiosSLPatientInstance.get<SearchPatientsElement>(
      `v1/patient/by-id/${patientId}`
    )
  );
}

export { useStpVipPatientById };
