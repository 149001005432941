import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C046x";

function T46HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="dizziness"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="weakness"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="near-fainting"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vertigo"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="person"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="room"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="weakness"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="paresthesia"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="facial droop"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="falling"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficulty standing"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficulty walking"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="impaired speech"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="visual loss"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="decreased mental status"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="confusion"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="low blood sugar"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="diabetic"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fever"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="character of altered mental status:"
                  textDecorationLine="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                />
                <ChartRowElement
                  label="trouble concentrating"
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="disoriented"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="confused"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="combative"
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="agitated"
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="seizure activity"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="decreased responsiveness"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unresponsive"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  w="415px"
                  type="input"
                />
                <ChartRowElement
                  label="last known well date & time:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="upon waking"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot confirm onset"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="better"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="continues in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="more than 3 hours"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="sudden onset"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gradual onset"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="still present"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="constant"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="‘waxing & waning'"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="better"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="worse"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lasted:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                />
                <ChartRowElement
                  label="greater than 3 hours"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="intermittent episodes lasting:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="worse"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="since:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="mild"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="6"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="7"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="8"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="9"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="10"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="insect bite"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tick bite"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fell"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="injury:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A044`}
                />
                <ChartRowElement
                  label="head"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="found unresponsive"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown duration"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="by:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A045`}
                />
                <ChartRowElement
                  label="nursing home staff"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bystander"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="family:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A046`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="given:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A047`}
                />
                <ChartRowElement
                  label="dextrostick"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  w="369.5px"
                  type="input"
                />
                <ChartRowElement
                  label="D50"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Narcan"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PTA"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="rersponse:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A048`}
                />
                <ChartRowElement
                  label="good"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="marginal"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="no response"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="heavy"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcohol intake"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="beer"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wine"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="liquor"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="last drink:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A049`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="drug abuse"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="overdose"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent trauma"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="head injury"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recent illness"
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="other family members sick"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="new medications"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="nursing home resident"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chronic dementia"
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="depression"
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A050`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Character of Deficit(s)"
            >
              Character of Deficit(s)
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="new weakness"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLE"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLE"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LUE"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUE"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="facial"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="general (diffuse)"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ascending"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="altered sensation"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLE"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLE"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LUE"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUE"
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="facial"
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="vision problems"
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="impaired speech"
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="impaired swallowing"
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficult"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable"
                  name={`Q005.${chartCode}${sectionId}Q005A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="decreased ability to stand / walk / falling"
                  name={`Q005.${chartCode}${sectionId}Q005A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="weak"
                  name={`Q005.${chartCode}${sectionId}Q005A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficult"
                  name={`Q005.${chartCode}${sectionId}Q005A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="off balance"
                  name={`Q005.${chartCode}${sectionId}Q005A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot walk"
                  name={`Q005.${chartCode}${sectionId}Q005A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot stand"
                  name={`Q005.${chartCode}${sectionId}Q005A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="falling"
                  name={`Q005.${chartCode}${sectionId}Q005A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="unable:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A041`}
                />
                <ChartRowElement
                  label="to stand"
                  name={`Q005.${chartCode}${sectionId}Q005A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="to sit"
                  name={`Q005.${chartCode}${sectionId}Q005A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bed-ridden"
                  name={`Q005.${chartCode}${sectionId}Q005A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A039`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A042`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A040`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Baseline"
            >
              Baseline
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Cognition:"
                  textDecorationLine="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                />
                <ChartRowElement
                  label="alert, oriented x4"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alert but disoriented to time"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alert but confused"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="poor alertness"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gait:"
                  textDecorationLine="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                />
                <ChartRowElement
                  label="walks w/o assistance"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="uses a cane / walker"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="walks only w/ assistance"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable to walk"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="fever"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chills"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="headache"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nausea"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vomiting"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sweating"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="trouble breathing"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="weakness"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="new weakness"
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="numbness"
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="decreased ability to:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A089`}
                />
                <ChartRowElement
                  label="stand"
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="walk"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="weak"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficult"
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="off balance"
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot walk"
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot stand"
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="falling"
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="involuntary movements"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="seizure"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="dizziness"
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="light headedness"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fainted"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nearly fainted"
                  name={`Q007.${chartCode}${sectionId}Q007A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="while:"
                  textDecorationLine="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A090`}
                />
                <ChartRowElement
                  label="standing"
                  name={`Q007.${chartCode}${sectionId}Q007A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sitting"
                  name={`Q007.${chartCode}${sectionId}Q007A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="supine"
                  name={`Q007.${chartCode}${sectionId}Q007A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lasted:"
                  textDecorationLine="underline"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A091`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chest pain"
                  name={`Q007.${chartCode}${sectionId}Q007A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck pain"
                  name={`Q007.${chartCode}${sectionId}Q007A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="back pain"
                  name={`Q007.${chartCode}${sectionId}Q007A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="abdominal pain:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A092`}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q007.${chartCode}${sectionId}Q007A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q007.${chartCode}${sectionId}Q007A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q007.${chartCode}${sectionId}Q007A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q007.${chartCode}${sectionId}Q007A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q007.${chartCode}${sectionId}Q007A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="altered mental status"
                  name={`Q007.${chartCode}${sectionId}Q007A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="disoriented"
                  name={`Q007.${chartCode}${sectionId}Q007A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="confused"
                  name={`Q007.${chartCode}${sectionId}Q007A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="agitated"
                  name={`Q007.${chartCode}${sectionId}Q007A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trouble concentrating"
                  name={`Q007.${chartCode}${sectionId}Q007A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trouble thinking"
                  name={`Q007.${chartCode}${sectionId}Q007A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="decreased responsiveness"
                  name={`Q007.${chartCode}${sectionId}Q007A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unresponsive"
                  name={`Q007.${chartCode}${sectionId}Q007A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="hearing loss"
                  name={`Q007.${chartCode}${sectionId}Q007A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ringing in ear"
                  name={`Q007.${chartCode}${sectionId}Q007A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="roaring in ear"
                  name={`Q007.${chartCode}${sectionId}Q007A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ear pain"
                  name={`Q007.${chartCode}${sectionId}Q007A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A058`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="sense of confusion"
                  name={`Q007.${chartCode}${sectionId}Q007A059`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A060`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="sense of movement"
                  name={`Q007.${chartCode}${sectionId}Q007A061`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="spinning"
                  name={`Q007.${chartCode}${sectionId}Q007A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="falling"
                  name={`Q007.${chartCode}${sectionId}Q007A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vague"
                  name={`Q007.${chartCode}${sectionId}Q007A064`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="distintict"
                  name={`Q007.${chartCode}${sectionId}Q007A065`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A066`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="decreased ability to stand/walk"
                  name={`Q007.${chartCode}${sectionId}Q007A067`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="weak"
                  name={`Q007.${chartCode}${sectionId}Q007A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficult"
                  name={`Q007.${chartCode}${sectionId}Q007A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="off balance"
                  name={`Q007.${chartCode}${sectionId}Q007A070`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot walk"
                  name={`Q007.${chartCode}${sectionId}Q007A071`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannot stand"
                  name={`Q007.${chartCode}${sectionId}Q007A072`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="falling"
                  name={`Q007.${chartCode}${sectionId}Q007A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="walks w/o assistance"
                  name={`Q007.${chartCode}${sectionId}Q007A074`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cane"
                  name={`Q007.${chartCode}${sectionId}Q007A075`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="walker"
                  name={`Q007.${chartCode}${sectionId}Q007A076`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable to walk"
                  name={`Q007.${chartCode}${sectionId}Q007A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bed-ridden"
                  name={`Q007.${chartCode}${sectionId}Q007A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable to sit"
                  name={`Q007.${chartCode}${sectionId}Q007A079`}
                  fontStyle="italic"
                  type="cross-option"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="usually:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A093`}
                />
                <ChartRowElement
                  label="walks w/o assistance"
                  name={`Q007.${chartCode}${sectionId}Q007A080`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="uses a cane / walker"
                  name={`Q007.${chartCode}${sectionId}Q007A081`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="walks only w/ assistance"
                  name={`Q007.${chartCode}${sectionId}Q007A082`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable to walk"
                  name={`Q007.${chartCode}${sectionId}Q007A083`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="stands for transfers"
                  name={`Q007.${chartCode}${sectionId}Q007A084`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bed-ridden"
                  name={`Q007.${chartCode}${sectionId}Q007A085`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unable to sit up"
                  name={`Q007.${chartCode}${sectionId}Q007A086`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A087`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A094`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A088`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Worsened by"
            >
              Worsened by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="none"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="changing position"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="movement of head"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="standing position"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T46HPI };
