import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteDiagram(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteDiagram"],
    (diagramId: string) =>
      axiosChartInstance.delete(
        `v1/diagram-chart/${encounterId}/${chartCode}/${diagramId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["diagrams", { encounterId, chartCode }]);
        queryClient.invalidateQueries(["diagramsCategories"]);
        queryClient.invalidateQueries([
          "diagramsCounter",
          { encounterId, chartCode },
        ]);
      },
    }
  );
}

export { useDeleteDiagram };
