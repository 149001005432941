import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const InterpretationsPlusMinus = createIcon({
  path: (
    <path
      d="M0 6.97057C0 6.24351 0.565493 5.67802 1.29256 5.67802H5.88575V1.26947C5.88575 0.542412 6.4397 0 7.16676 0C7.90537 0 8.45932 0.542412 8.45932 1.26947V5.67802H13.0641C13.7911 5.67802 14.3566 6.24351 14.3566 6.97057C14.3566 7.69763 13.7911 8.26313 13.0641 8.26313H8.45932V12.7063C8.45932 13.4334 7.90537 13.9758 7.16676 13.9758C6.4397 13.9758 5.88575 13.4334 5.88575 12.7063V8.26313H1.29256C0.565493 8.26313 0 7.69763 0 6.97057ZM0 18.719C0 17.9804 0.565493 17.4264 1.29256 17.4264H13.0641C13.7796 17.4264 14.3566 17.9804 14.3566 18.719C14.3566 19.446 13.7796 20 13.0641 20H1.29256C0.565493 20 0 19.446 0 18.719Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 15 20",
});

export { InterpretationsPlusMinus };
