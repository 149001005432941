import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { CreateRoomPayload } from "./useCreateRoom";

type UpdateRoomPayload = CreateRoomPayload;

function useUpdateRoom(id: string, updateInfo = true) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateRoom", { id }],
    (input: UpdateRoomPayload) =>
      axiosIdentityInstance.put(`/v1/room/${id}`, input),
    {
      onSuccess: () => {
        if (updateInfo) {
          queryClient.invalidateQueries("rooms");
          queryClient.invalidateQueries("roomsDetails");
          queryClient.invalidateQueries("roomCounters");
          queryClient.invalidateQueries(["room", { roomId: id }]);
        }
      },
    }
  );
}

export { useUpdateRoom };
export type { UpdateRoomPayload };
