import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { PatchVitalParams, PatchVitalPayload } from "modules/vitals/types";

function usePatchVitalCheck(params: PatchVitalParams) {
  const queryClient = useQueryClient();
  const { encounterId } = params;
  return useAxiosMutation(
    ["patchVitalCheck"],
    ({ vitalCheckId, payload }: PatchVitalPayload) =>
      axiosChartInstance.patch(
        `v1/vitals/${encounterId}/vital-check/${vitalCheckId}`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitals"]);
        queryClient.invalidateQueries(["chartPatientCard", { encounterId }]);
      },
    }
  );
}

export { usePatchVitalCheck };
