import {
  Input,
  InputProps,
  useControllableState,
  useMergeRefs,
} from "@chakra-ui/react";
import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { useDivideControlProps } from "shared/form/hooks";
import InputMask from "react-input-mask";

type NumberInputMaskProps = {
  mask: string;
  value?: string | null;
  defaultValue?: string | null;
  onChange?: (value: string | null) => void;
  isDateMask?: boolean;
} & Omit<InputProps, "value" | "onChange">;

const NumberInputMask = React.forwardRef<
  HTMLInputElement,
  NumberInputMaskProps
>((props, ref) => {
  const { onChange, value, defaultValue, mask, ...rest } = props;

  // generate the regexp used to get the value without mask
  const unmaskValue = React.useMemo(() => {
    const partsMask = mask.match(/([#]{1,})/g) || [];
    if (partsMask.length === 0) {
      throw Error("Invalid input mask");
    }
    const regexString = partsMask.reduce((regxResult, part) => {
      return `${regxResult}(\\d{0,${part.length}})`;
    }, "");
    return RegExp(regexString);
  }, [mask]);

  const maskParts = React.useMemo(() => {
    return mask.match(/(#{1,})|([^#]{1,})/g) || [];
  }, [mask]);

  // used to locate all the parts where not editable characters are
  const invalidEditZones = React.useMemo(() => {
    let stringIndex = 0;
    return maskParts.reduce<{ start: number; end: number }[]>((res, part) => {
      if (/[^#]+$/.test(part)) {
        res.push({ start: stringIndex, end: stringIndex + part.length });
      }
      stringIndex += part.length;
      return res;
    }, []);
  }, [maskParts]);

  const nextValidCursorPosition = React.useCallback(
    (currentCursorPosition: number) => {
      const invalidZone = invalidEditZones.find(
        (editZone) =>
          currentCursorPosition > editZone.start &&
          currentCursorPosition <= editZone.end
      );
      if (invalidZone) return invalidZone.end - currentCursorPosition + 1;
      return 0;
    },
    [invalidEditZones]
  );

  const prevValidCursorPosition = React.useCallback(
    (currentCursorPosition: number) => {
      const invalidZone = invalidEditZones.find(
        (editZone) =>
          currentCursorPosition > editZone.start &&
          currentCursorPosition < editZone.end
      );
      if (invalidZone) return invalidZone.start - currentCursorPosition;
      return 0;
    },
    [invalidEditZones]
  );

  const [maskedValue, setMaskedValue] = React.useState<string>("");
  const maskedInput = React.useRef<HTMLInputElement>(null);
  const refs = useMergeRefs(ref, maskedInput);
  const [cursorPosition, setCursorPosition] = React.useState<{
    pos: number;
  } | null>(null);

  const maskValueInternal = React.useCallback(
    (text: string | null) => {
      let parts = text?.replace(/\D/g, "").match(unmaskValue) || [];
      // The first result alway returns the full string, so its neccesary to ignore it
      parts.splice(0, 1);
      parts = parts.filter((p) => !!p);

      // Replace the value parts in the mask with the new values
      const maskedText = maskParts.reduce((res, part) => {
        if (parts.length > 0) {
          if (/^#+/.test(part)) {
            const maskPart = parts.splice(0, 1);
            return `${res}${maskPart[0]}`;
          }
          return `${res}${part}`;
        }
        return res;
      }, "");
      const numberValue = maskedText.replace(/(\D)/g, "") || "";

      return { maskedText, value: numberValue };
    },
    [unmaskValue, maskParts]
  );

  const valueInterceptor = React.useMemo(
    () => maskValueInternal(value || "").value,
    [value, maskValueInternal]
  );

  const [internalValue, setInternalValue] = useControllableState({
    value: valueInterceptor,
    onChange,
  });

  const handleChange = (inputEvent?: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = maskedInput?.current?.value || "";
    const componentParts = maskValueInternal(currentValue);
    if (inputEvent) {
      let currentCursorPosition =
        inputEvent?.currentTarget?.selectionStart !== null
          ? inputEvent?.currentTarget?.selectionStart
          : Math.max(0, currentValue.length - 1);

      if (
        componentParts.maskedText.length < maskedValue.length ||
        (currentValue.length < maskedValue.length &&
          componentParts.maskedText.length === maskedValue.length)
      ) {
        // The user probably deleted a character
        currentCursorPosition += prevValidCursorPosition(currentCursorPosition);
      } else {
        // The user added a new character to the string
        currentCursorPosition += nextValidCursorPosition(currentCursorPosition);
      }
      setCursorPosition({ pos: currentCursorPosition });
    }
    setMaskedValue(componentParts.maskedText);
    if (props.isDateMask) {
      setInternalValue(`${componentParts.maskedText}/${componentParts.value}`);
      return;
    }
    setInternalValue(componentParts.value);
  };

  React.useEffect(() => {
    const componentParts = maskValueInternal(internalValue || "");
    setMaskedValue(componentParts.maskedText);
    setInternalValue(componentParts.value);
  }, [value, internalValue, setInternalValue, maskValueInternal]);

  React.useEffect(() => {
    if (cursorPosition && maskedInput)
      maskedInput?.current?.setSelectionRange(
        cursorPosition.pos,
        cursorPosition.pos
      );
  }, [maskedInput, cursorPosition]);

  return (
    <Input
      ref={refs}
      value={maskedValue}
      onChange={handleChange}
      onKeyDown={(e) => {
        // Return the cursor to his last position if the character typed was invalid
        if (e.key.length === 1 && !/^\d$/.test(e.key)) {
          const currentCursorPosition = Math.max(
            0,
            (e?.currentTarget?.selectionStart || 0) - 1
          );
          maskedInput?.current?.setSelectionRange(
            currentCursorPosition,
            currentCursorPosition
          );
        }
      }}
      {...rest}
    />
  );
});

NumberInputMask.displayName = "NumberInputMask";

const omittedProps = ["selected", "name", "onChange"] as const;

type FormInputProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<NumberInputMaskProps, typeof omittedProps[number]> & {
      onChange?: (value: string | null) => void;
    };

function FormInputInner<TFieldValues extends FieldValues>(
  props: FormInputProps<TFieldValues>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const { onChange: onChangeProp, ...restProps } = props;
  const [inputProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormInputProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, value, onChange, ...restField },
  } = useController(controllerProps);

  const inputRef = useMergeRefs(ref, fieldRef);

  const handleChange: (value: string | null) => void = (data) => {
    if (props.isDateMask) {
      const strDateArr = data?.split("/") || [];
      onChangeProp?.(strDateArr[0]);
      onChange(strDateArr[1]);
      return;
    }

    onChangeProp?.(data);
    onChange(data);
  };

  return (
    <NumberInputMask
      ref={inputRef}
      {...inputProps}
      {...restField}
      value={value}
      onChange={handleChange}
    />
  );
}

const FormNumberInputMask = React.forwardRef(FormInputInner) as <
  TFieldValues extends FieldValues
>(
  props: FormInputProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof FormInputInner>;

type MaskedNumberInputProps = {
  mask: string;
  value?: string;
  defaultValue?: string | null;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  isDateMask?: boolean;
} & Omit<InputProps, "value" | "onChange">;

function MaskedNumberInput(props: MaskedNumberInputProps) {
  const { onChange: onChangeProp, ...restProps } = props;
  // eslint-disable-next-line
  const mask = (restProps as any).mask || "9999999999";
  // eslint-disable-next-line
  const defaultValue = (restProps as any)?.control?.watchInternal(
    restProps.name
  );
  const [controlledValue, setControlledValue] = React.useState(
    defaultValue as string
  );

  React.useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined)
      setControlledValue(defaultValue);
  }, [defaultValue, setControlledValue]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    setControlledValue(inputValue);
    event.target.value = inputValue;
    onChangeProp?.(event);
  };

  return (
    <Input
      as={InputMask}
      maskChar={null}
      value={controlledValue}
      onChange={onChange}
      {...restProps}
      mask={mask}
    />
  );
}

MaskedNumberInput.displayName = "MaskedNumberInput";

export { MaskedNumberInput, NumberInputMask, FormNumberInputMask };
