const parts = ["root", "option"];

function baseStyle() {
  return {
    root: {
      display: "flex",
      bg: "gray.250",
      borderRadius: "5px",
      height: "2.25rem",
      alignItems: "center",
      fontSize: "1rem",
    },
    option: {
      height: "calc(100% - 0.25rem)",
      margin: "0.125rem",
      padding: "0 0.5rem",
      _selected: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        border: "1px solid gray.450",
        bg: "white",
      },
    },
  };
}

const segmentTheme = {
  parts,
  baseStyle,
};

export { segmentTheme };
