import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type DeleteSignedSectionPayload = {
  miscellaneousId: string;
};

function useDeleteSummaryMiscellaneous(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteSummaryMiscellaneous", { encounterId }],
    ({ miscellaneousId }: DeleteSignedSectionPayload) =>
      axiosChartInstance.delete(
        `v1/summary-miscellaneous/${encounterId}/remove/${miscellaneousId}`
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "summaryMiscellaneous",
          { encounterId },
        ]),
    }
  );
}

export { useDeleteSummaryMiscellaneous };
