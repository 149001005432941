import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { useEffectOnce } from "utils";

type ExtendedWindow = {
  ws: WebSocket;
} & Window;

type ScannerReturnValues = {
  scan(): void;
  data: File[];
  base64Data: string[];
  isLoading: boolean;
  isScannerDetected: boolean;
  refresh(): void;
};

type UseScannerProps = {
  isMultiple?: boolean;
};

const getBase64 = (file: File) => {
  return new Promise<string>((resolve) => {
    let baseURL;

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

function useScanner(props?: UseScannerProps) {
  const [data, setData] = React.useState<File[]>([]);
  const [base64Data, setBase64Data] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isScannerDetected, setIsScannerDetected] =
    React.useState<boolean>(false);

  useEffectOnce(() => createSocketConfig());

  const toast = useToast();

  const createSocketConfig = () => {
    setIsLoading(true);

    (window as unknown as ExtendedWindow).ws = new WebSocket(
      "ws://localhost:8181"
    );

    (window as unknown as ExtendedWindow).ws.onmessage = async (
      wsevent: MessageEvent
    ) => {
      setIsLoading(true);

      if (wsevent.data?.toLowerCase?.().includes("error")) {
        toast({ description: wsevent.data });
        setIsLoading(false);
      }

      if (wsevent.data instanceof Blob) {
        const scannedFile = new File([wsevent.data], `document.png`, {
          type: "image/png",
        });
        const _base64str = await getBase64(scannedFile);

        if (props?.isMultiple) {
          setData((prevState) => [...prevState, scannedFile]);
          setBase64Data((prevState) => [...prevState, _base64str]);
          setIsLoading(false);
          return;
        }

        setData([scannedFile]);
        setBase64Data([_base64str]);
        setIsLoading(false);
      }
    };

    (window as unknown as ExtendedWindow).ws.onopen = () => {
      setIsScannerDetected(true);
      setIsLoading(false);
    };

    (window as unknown as ExtendedWindow).ws.onerror = () => {
      setIsScannerDetected(false);
      setIsLoading(false);
    };
  };

  const scan = () => {
    (window as unknown as ExtendedWindow).ws.send("1100");
  };

  return {
    scan,
    data,
    base64Data,
    isLoading,
    isScannerDetected,
    refresh: createSocketConfig,
  } as ScannerReturnValues;
}

export { useScanner };
export type { ScannerReturnValues };
