import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { Loading } from "shared/loading";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { useChartForm } from "modules";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SCHC";
const chartCode = "T051x";

function N51ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="started:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                />
                <ChartRowElement
                  label="just PTA"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="altered mental status"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="seizure"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="runny nose"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="cough"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wheezing"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="trouble breathing"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="vomiting"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pool"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fresh water"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="salt water"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="water temp:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                />
                <ChartRowElement
                  label="freezing"
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cold"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="warm"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="found:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                />
                <ChartRowElement
                  label="floating"
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="on bottom"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N51ChiefComplaint };
