import { createIcon } from "@chakra-ui/react";

const RoomList = createIcon({
  path: (
    <g fill="none">
      <path
        d="M1.78092 12.103C0.636042 12.103 0 11.4669 0 10.3039V5.88794C0 2.01716 2.03534 0 5.96063 0H10.3766C11.5396 0 12.1757 0.636042 12.1757 1.78092C12.1757 2.9258 11.5396 3.56184 10.3766 3.56184H6.1787C4.48864 3.56184 3.56184 4.43412 3.56184 6.19687V10.3039C3.56184 11.4669 2.94397 12.103 1.78092 12.103ZM34.2191 12.103C33.0742 12.103 32.4382 11.4669 32.4382 10.3039V6.19687C32.4382 4.43412 31.4932 3.56184 29.8213 3.56184H25.6234C24.4604 3.56184 23.8243 2.9258 23.8243 1.78092C23.8243 0.636042 24.4604 0 25.6234 0H30.0394C33.9647 0 36 2.01716 36 5.88794V10.3039C36 11.4669 35.3821 12.103 34.2191 12.103ZM5.96063 36C2.03534 36 0 33.9828 0 30.0939V25.6779C0 24.5149 0.61787 23.8788 1.78092 23.8788C2.9258 23.8788 3.56184 24.5149 3.56184 25.6779V29.785C3.56184 31.5659 4.48864 32.4382 6.1787 32.4382H10.3766C11.5396 32.4382 12.1757 33.0742 12.1757 34.2191C12.1757 35.364 11.5396 36 10.3766 36H5.96063ZM25.6234 36C24.4604 36 23.8243 35.364 23.8243 34.2191C23.8243 33.0742 24.4604 32.4382 25.6234 32.4382H29.8213C31.4932 32.4382 32.4382 31.5659 32.4382 29.785V25.6779C32.4382 24.5331 33.056 23.8788 34.2191 23.8788C35.364 23.8788 36 24.5149 36 25.6779V30.0939C36 33.9828 33.9647 36 30.0394 36H25.6234Z"
        fill="currentColor"
      />
      <path
        d="M10.7764 25.7722H25.2152C27.0464 25.7722 28 24.827 28 23.0211V12.7511C28 10.9451 27.0464 10 25.2152 10H10.7764C8.95359 10 8 10.9367 8 12.7511V23.0211C8 24.827 8.95359 25.7722 10.7764 25.7722ZM10.8861 24.0928C10.1097 24.0928 9.67932 23.6878 9.67932 22.8692V12.903C9.67932 12.0844 10.1097 11.6793 10.8861 11.6793H25.1055C25.8819 11.6793 26.3122 12.0844 26.3122 12.903V22.8692C26.3122 23.6878 25.8819 24.0928 25.1055 24.0928H10.8861ZM17.9916 21.9325C17.9916 22.5063 18.3713 22.8861 18.9367 22.8861H24.1772C24.7426 22.8861 25.1224 22.5063 25.1224 21.9325V13.8312C25.1224 13.2574 24.7426 12.8776 24.1772 12.8776H18.9367C18.3713 12.8776 17.9916 13.2574 17.9916 13.8312V21.9325ZM11.1561 17.8734C11.1646 18.211 11.4177 18.481 11.7468 18.481H13.8903L14.9873 18.4388L14.4135 18.9873L13.5612 19.8059C13.443 19.9072 13.384 20.0759 13.384 20.2194C13.384 20.5401 13.6203 20.7764 13.9241 20.7764C14.0928 20.7764 14.2278 20.7089 14.346 20.5738L16.4641 18.3207C16.616 18.1603 16.6667 18.0338 16.6667 17.8734C16.6667 17.7215 16.616 17.5949 16.4641 17.4346L14.346 15.173C14.2278 15.0464 14.0928 14.9789 13.9241 14.9789C13.6118 14.9789 13.384 15.2152 13.384 15.5443C13.384 15.6878 13.443 15.8481 13.5612 15.9494L14.4135 16.7679L14.9873 17.3165L13.8903 17.2743H11.7468C11.4177 17.2743 11.1477 17.5443 11.1561 17.8734Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 35 38",
});

export { RoomList };
