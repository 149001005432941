import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Chart = createIcon({
  path: (
    <path
      d="M0 16.5356C0 17.011 0.310786 17.3218 0.786106 17.3218H19.0951C19.5887 17.3218 20 16.9378 20 16.426C20 15.9141 19.5887 15.5302 19.0951 15.5302H2.04753C1.85558 15.5302 1.79159 15.4662 1.79159 15.2742V10.3016L5.66728 8.05302C5.97806 8.34552 6.3894 8.52834 6.85558 8.52834C7.24863 8.52834 7.6234 8.39122 7.91591 8.16271L9.63437 9.62523C9.57038 9.80804 9.53382 10.0091 9.53382 10.2102C9.53382 11.1792 10.3108 11.9561 11.2706 11.9561C12.2303 11.9561 13.0165 11.1792 13.0165 10.2102C13.0165 10.1371 13.0073 10.0548 12.9982 9.98172L17.1846 7.47715C17.4589 7.6691 17.7971 7.77879 18.1536 7.77879C19.1133 7.77879 19.8995 6.99269 19.8995 6.03291C19.8995 5.07313 19.1133 4.28702 18.1536 4.28702C17.1938 4.28702 16.4077 5.07313 16.4077 6.03291C16.4077 6.15174 16.426 6.27057 16.4442 6.38026L12.34 8.83912C12.0475 8.6106 11.6728 8.47349 11.2706 8.47349C10.9872 8.47349 10.713 8.53748 10.4662 8.66545L8.57404 7.05667C8.58318 6.96526 8.59232 6.87386 8.59232 6.78245C8.59232 5.82267 7.81536 5.03656 6.85558 5.03656C5.88665 5.03656 5.10969 5.82267 5.10969 6.78245C5.10969 6.80987 5.10969 6.83729 5.10969 6.86472L1.79159 8.78428V0.904936C1.79159 0.402194 1.40768 0 0.895795 0C0.383912 0 0 0.402194 0 0.904936V16.5356Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18",
});

export { Chart };
