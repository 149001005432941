import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Ultrasound = createIcon({
  path: (
    <path
      d="M10.9395 8.99659C10.9395 5.79209 9.93955 2.80728 8.15927 0.337617C7.61382 -0.427524 6.5002 0.239133 7.0911 1.03458C8.75017 3.31485 9.63652 6.06481 9.63652 8.99659C9.63652 11.9284 8.74259 14.6708 7.0911 16.951C6.51535 17.7389 7.59109 18.4434 8.15927 17.6556C9.93955 15.1783 10.9395 12.1935 10.9395 8.99659ZM6.8108 8.99659C6.8108 6.61783 6.06081 4.38302 4.71234 2.57243C4.10629 1.76184 3.05328 2.54213 3.62145 3.28455C4.85628 4.90574 5.51536 6.88298 5.51536 8.99659C5.51536 11.1102 4.85628 13.0874 3.62145 14.7086C3.05328 15.451 4.10629 16.2313 4.71234 15.4132C6.06081 13.6026 6.8108 11.3753 6.8108 8.99659ZM2.69722 8.99659C2.69722 7.436 2.15935 5.98148 1.20482 4.84513C0.576037 4.10272 -0.340617 4.94361 0.129074 5.54967C1.04573 6.70117 1.39421 7.66327 1.39421 8.99659C1.39421 10.3299 1.04573 11.292 0.129074 12.4435C-0.333041 13.042 0.583613 13.8753 1.20482 13.1405C2.15935 12.0117 2.69722 10.5572 2.69722 8.99659Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 11 18",
});

export { Ultrasound };
