import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { TriageChartCode } from "enums";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
} from "modules";
import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartProceduresForm } from "../../hooks";
import { ProceduresHeader, proceduresConstants } from "../../shared";
import { usePermissions } from "contexts";

const CHART_CODE: TriageChartCode = "T003x";

function N03Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({ chartCode: CHART_CODE });
  const { getValues } = useFormReturn;
  const sections = getValues("sections");
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ProceduresHeader />
        {/* Section One */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[0].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A001"
                    label="portable"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A002"
                    label="to Xray"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A053"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A003"
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A004"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A005"
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T003xSPROQ001A006"
                    label="tech"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[1].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[1].T003xSPROQ001A007"
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[1].T003xSPROQ001A008"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Two */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[2].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[2].T003xSPROQ001A009"
                    label="to CT"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T003xSPROQ001A053"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T003xSPROQ001A010"
                    label="contrast"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T003xSPROQ001A011"
                    label="parenteral meds"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[3].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[3].T003xSPROQ001A054"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T003xSPROQ001A012"
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T003xSPROQ001A013"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T003xSPROQ001A014"
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T003xSPROQ001A015"
                    label="tech"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    src={(sections[3] && sections[3].createdByPictureUrl) || ""}
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[4].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[4].T003xSPROQ001A016"
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[4].T003xSPROQ001A017"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[4] && sections[4].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[4] && sections[4].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Three */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[5].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A018"
                    label="lab drawn"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A019"
                    label="sent"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A053"
                    label="by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A020"
                    label="ED tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A021"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T003xSPROQ001A022"
                    label="lab"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[5] && sections[5].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[5] && sections[5].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Four */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[6].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[6].T003xSPROQ001A023"
                    label="lasceration repair"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[6].T003xSPROQ001A024"
                    label="see additional notes"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[7].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[7].T003xSPROQ001A053"
                    label="assisted by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[7].T003xSPROQ001A025"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[7].T003xSPROQ001A026"
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[7].T003xSPROQ001A054"
                    label="site"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[7].T003xSPROQ001A027"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[7] && sections[7].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[7] && sections[7].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Five */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[8].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[8].T003xSPROQ001A028"
                    label="foreign body removed"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[8] && sections[8].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[8] && sections[8].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[9].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[9].T003xSPROQ001A053"
                    label="assisted by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[9].T003xSPROQ001A029"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[9].T003xSPROQ001A030"
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[9].T003xSPROQ001A054"
                    label="site"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[9].T003xSPROQ001A031"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[9] && sections[9].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[9] && sections[9].createdByPictureUrl) || ""
                    }
                    src={(sections[9] && sections[9].createdByPictureUrl) || ""}
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[10].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[10].T003xSPROQ001A032"
                    label="dislocation"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[10].T003xSPROQ001A033"
                    label="fx reduction"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[10].T003xSPROQ001A034"
                    label="see aditional notes"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[10] && sections[10].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[10] && sections[10].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[11].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[11].T003xSPROQ001A055"
                    label="assisted by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[11].T003xSPROQ001A035"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[11].T003xSPROQ001A036"
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[11].T003xSPROQ001A056"
                    label="site"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[11].T003xSPROQ001A037"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[11] && sections[11].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[11] && sections[11].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Six */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[12].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[12].T003xSPROQ001A038"
                    label="splint applied"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T003xSPROQ001A039"
                    label="slign applied"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T003xSPROQ001A040"
                    label="arm"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T003xSPROQ001A041"
                    label="short"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T003xSPROQ001A042"
                    label="long"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[12] && sections[12].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[12] && sections[12].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[13].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[13].T003xSPROQ001A053"
                    label="type:"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[13].T003xSPROQ001A043"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[13] && sections[13].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[13] && sections[13].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Seven */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[14].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[14].T003xSPROQ001A044"
                    label="post-splint assessment nml"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[14].T003xSPROQ001A045"
                    label="color"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[14].T003xSPROQ001A046"
                    label="sensation"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[14].T003xSPROQ001A047"
                    label="movement"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[14] && sections[14].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[14] && sections[14].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Eight */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[15].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[15].T003xSPROQ001A048"
                    label="cleaned wound"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[15].T003xSPROQ001A049"
                    label="applied abx ointment"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[15] && sections[15].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[15] && sections[15].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        {/* Section Nine */}
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[16].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[16].T003xSPROQ001A053"
                    label="applied:"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[16].T003xSPROQ001A050"
                    label="dressing"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[16].T003xSPROQ001A051"
                    label="Band-Aid"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[16].T003xSPROQ001A052"
                    label="elastic wrap"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[16] && sections[16].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[16] && sections[16].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N03Procedures };
