import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { Pharmacy } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type EditPharmacyPayload = Partial<Omit<Pharmacy, "actions" | "state">> & {
  state: string;
};

function useEditPharmacy(pharmacyId: string, encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["editPharmacy", { pharmacyId }],
    (input: EditPharmacyPayload) =>
      axiosFacilityInstance.put<Pharmacy>(`v1/pharmacy/${pharmacyId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pharmacyList");
        queryClient.invalidateQueries(["pharmacy", { pharmacyId }]);
        if (encounterId)
          queryClient.invalidateQueries(["patientInfo", { encounterId }]);
      },
    }
  );
}

export { useEditPharmacy };
export type { EditPharmacyPayload };
