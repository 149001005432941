import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { useChartForm } from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared/loading";

const sectionId = "SCHC";
const chartCode = "T010x";

function N10ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="occurred:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                />
                <ChartRowElement
                  label="just PTA"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="thermal burn"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical burn"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sunburn"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="electrical burn"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lightning"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="head"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="face"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nose"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mouth"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lip"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chin"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chest"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="abdomen"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ear"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="eye"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="both"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fingers"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="toes"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fingers"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="toes"
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="back:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                />
                <ChartRowElement
                  label="upper"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Mechanism"
            >
              Mechanism
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="closed space"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="entrapment"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="smoke inhalation"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical exposure"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N10ChiefComplaint };
