import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";
import { useAxiosMutation } from "hooks";

function useDeleteEncounterIdentification(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterIdentification", { encounterId }],
    () =>
      axiosPatientInstance.delete(
        `v1/encounter/${encounterId}/form/files/identification`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
      },
    }
  );
}

export { useDeleteEncounterIdentification };
