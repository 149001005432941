import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EmrCheck, SiChevronLeft } from "@medstonetech/slate-icons";
import { useGetUser } from "api/queries";
import { useFileUrl, useYupValidationResolver } from "hooks";
import { PersonKey, ResetPassword } from "icons";
import { formMessages, genericErrors } from "messages";
import {
  useChangeUserPassword,
  useUsersNeedToResetPassword,
} from "modules/onboarding/api";
import {
  useStaffProfilePicture,
  useGetEnabledUsersMin,
  UseGetEnabledUsersMinResponse,
} from "modules/permissions-shared/api";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Card,
  DividerList,
  LeftInfoInput,
  Loading,
  PasswordValidationInput,
  SearchBarClear,
  StaffAvatar,
  ToolbarHeader,
  WarningDialog,
} from "shared";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";
import * as Yup from "yup";

type PasswordsManagerModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
};

type ChangePasswordFormData = {
  password: string | undefined;
};

const schema = Yup.object().shape({
  password: Yup.string()
    .required(formMessages.required("Password"))
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
});

const initialValues: Partial<ChangePasswordFormData> = {
  password: undefined,
};

type UserItemProps = {
  userId: string;
  name: string;
  imageUrl: string | null;
  title: string;
  readOnly?: boolean;
  selected?: boolean;
} & Omit<FlexProps, "name" | "title">;

function UserItem(props: UserItemProps) {
  const {
    userId,
    imageUrl,
    name,
    title,
    selected = false,
    readOnly = false,
    ...rest
  } = props;
  return (
    <Flex
      direction="row"
      alignItems="center"
      p="5px 14px"
      cursor={readOnly ? "unset" : "pointer"}
      {...rest}
    >
      <HStack spacing="15px" w="100%">
        <StaffAvatar
          h="32px"
          w="32px"
          size="xs"
          fontSize=".5rem"
          userName={name}
          profileUrl={imageUrl || ""}
        />
        <VStack spacing="0px" alignItems="start">
          <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
            {name}
          </chakra.span>
          <chakra.span fontSize="0.875rem" fontWeight="400" color="gray.700">
            {title}
          </chakra.span>
        </VStack>
        {selected && (
          <>
            <Spacer />
            <Icon h="14px" w="14px" as={EmrCheck} color="blue" />
          </>
        )}
      </HStack>
    </Flex>
  );
}

type ResetPasswordButtonProps = {
  userId: string;
  resettingPassword: boolean;
  setResettingPassword: React.Dispatch<React.SetStateAction<boolean>>;
  onModalClose: () => void;
};

function ResetPasswordButton({
  userId,
  resettingPassword,
  setResettingPassword,
  onModalClose,
}: ResetPasswordButtonProps) {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    mutateAsync: changeUserPassword,
    isLoading: isChangingPassword,
    error: changePasswordError,
  } = useChangeUserPassword(userId);

  const {
    register,
    formState: { isValid, errors, dirtyFields },
    handleSubmit,
  } = useForm<ChangePasswordFormData>({
    resolver: useYupValidationResolver(schema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  React.useEffect(() => {
    if (changePasswordError) {
      toast({ description: extractApiErrorMessage(changePasswordError) });
    }
  }, [changePasswordError, toast]);

  const onSubmit: SubmitHandler<ChangePasswordFormData> = async (dataForm) => {
    try {
      const { password } = dataForm;

      if (password) {
        const formData = new FormData();
        formData.append("password", password);

        await changeUserPassword(formData);
        toast({
          status: "success",
          description: formMessages.updateSuccess("User"),
        });
        onModalClose();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  };

  if (resettingPassword) {
    return (
      <>
        <Box padding="0 15px" margin="9px 0 24px">
          <chakra.span fontSize="0.9375rem" fontWeight="400" color="gray.650">
            Passwords must have upper and lower case letters, at least 1 number,
            at least 1 special character, not match any part of your email, and
            be at least 8 characters long.
          </chakra.span>
        </Box>
        <PasswordValidationInput
          isValid={!errors.password?.message}
          showValidator={!!dirtyFields.password}
          leftTitle="Password"
          inputElement={<Input {...register("password")} />}
        />
        <Button
          variant="outlineSquared"
          borderRadius="25px"
          w="100%"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isChangingPassword}
        >
          <Icon as={ResetPassword} mr="8px" w="1.25rem" h="1.25rem" />
          Reset Password
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        variant="outlineSquared"
        borderRadius="25px"
        w="100%"
        onClick={onOpen}
      >
        <Icon as={ResetPassword} mr="8px" w="1.25rem" h="1.25rem" />
        Reset Password
      </Button>
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning"
        mainText="Are you sure you want to Reset this User's Password?"
        onClose={onClose}
        onAction={() => setResettingPassword(true)}
        cancelLabel="Cancel"
        actionLabel="Continue"
        blockScrollOnMount={false}
        cancelButtonProps={{ color: "gray.700" }}
        actionButtonProps={{ color: "blue" }}
      />
    </>
  );
}

type SetUserNeedToChangePasswordButtonProps = {
  userId: string;
  resettingPassword: boolean;
  onModalClose: () => void;
};

const SetUserNeedToChangePasswordButton = ({
  userId,
  resettingPassword,
  onModalClose,
}: SetUserNeedToChangePasswordButtonProps) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutateAsync: usersNeedToResetPassword, isLoading } =
    useUsersNeedToResetPassword();

  const handleOnClick = async () => {
    try {
      await usersNeedToResetPassword([userId]);
      toast({
        status: "success",
        description: formMessages.updateSuccess("User"),
      });
      onModalClose();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  if (resettingPassword) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="outlineSquared"
        borderRadius="25px"
        w="100%"
        onClick={onOpen}
        isDisabled={isLoading}
        isLoading={isLoading}
        mt={12}
      >
        <Icon as={PersonKey} mr="8px" w="1.25rem" h="1.25rem" />
        Force User to Change Password Next Log-In
      </Button>
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning"
        mainText="Are you sure you want to force this User to change their Password?"
        onClose={onClose}
        onAction={() => handleOnClick()}
        cancelLabel="Cancel"
        actionLabel="Continue"
        blockScrollOnMount={false}
        cancelButtonProps={{ color: "gray.700" }}
        actionButtonProps={{ color: "blue" }}
      />
    </>
  );
};

function PasswordsManagerModalContent(props: PasswordsManagerModalProps) {
  const toast = useToast();
  const { onModalClose } = props;
  const [searchString, setSearchString] = React.useState<string>("");
  const [selectedUser, setSelectedUser] = React.useState<
    UseGetEnabledUsersMinResponse | undefined
  >(undefined);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [resettingPassword, setResettingPassword] = React.useState(false);

  const onSearch = React.useMemo(
    () =>
      debounce(1000, (value) => {
        setSearchString(value || "");
      }),
    [setSearchString]
  );

  const {
    data,
    isLoading,
    error: searchError,
  } = useGetEnabledUsersMin(searchString);

  const selectableUsers = React.useMemo(
    () =>
      (data?.data || []).map((tempUser) => ({
        selected: selectedUser?.id === tempUser.id,
        ...tempUser,
      })),
    [data, selectedUser]
  );

  const {
    data: userData,
    isLoading: isUserLoading,
    error: userDataError,
  } = useGetUser(selectedUser?.id || "", {
    enabled: tabIndex === 1 && !!selectedUser,
  });
  const pictureUrlData = userData?.data?.pictureUrl;

  const { data: fileData, isLoading: isPictureLoading } =
    useStaffProfilePicture(selectedUser?.id || "", pictureUrlData || "", {
      enabled: !!pictureUrlData,
    });
  const imageURL = useFileUrl(fileData?.data);
  const isUserDataLoading = isPictureLoading || isUserLoading;

  React.useEffect(() => {
    if (searchError) {
      toast({ description: extractApiErrorMessage(searchError) });
    }
  }, [searchError, toast]);

  React.useEffect(() => {
    if (userDataError) {
      toast({ description: extractApiErrorMessage(userDataError) });
    }
  }, [userDataError, toast]);

  const leftButtonsHeader =
    tabIndex === 0
      ? [
          <Button key="cancelBtn" onClick={onModalClose}>
            Cancel
          </Button>,
        ]
      : [
          <Button
            key="backButton"
            size="iconSm"
            variant="ghost"
            color="blue"
            onClick={() => {
              setSelectedUser(undefined);
              setTabIndex(0);
            }}
            leftIcon={<Icon as={SiChevronLeft} />}
          >
            Back
          </Button>,
        ];

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              Reset Password
            </chakra.span>
          }
          leftButtons={leftButtonsHeader}
        />
      </ModalHeader>
      <ModalBody padding="16px" fontSize="1.0625rem" overflow="auto">
        <Tabs index={tabIndex} onChange={setTabIndex}>
          <TabPanels>
            <TabPanel padding="0px">
              <Flex direction="column" mt="3px">
                <SearchBarClear onChange={onSearch} />
                <Flex direction="column" marginTop="20px">
                  {searchString !== "" && isLoading ? (
                    <Loading />
                  ) : (
                    searchString !== "" &&
                    selectableUsers.length > 0 && (
                      <Card variant="basic" textAlign="center">
                        <DividerList dividerLeftMargin="102px">
                          {selectableUsers.map(({ id, ...userItem }) => (
                            <UserItem
                              key={id}
                              userId={id}
                              {...userItem}
                              onClick={() => {
                                if (selectedUser?.id !== id) {
                                  setSelectedUser({ id, ...userItem });
                                  setTabIndex(1);
                                }
                              }}
                            />
                          ))}
                        </DividerList>
                      </Card>
                    )
                  )}
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel padding="0px">
              {isUserDataLoading ? (
                <Loading />
              ) : (
                !!userData && (
                  <Flex direction="column" alignItems="center">
                    <StaffAvatar
                      h="88px"
                      w="88px"
                      mt="12px"
                      mb="40px"
                      size="md"
                      userName={`${userData.data.firstName} ${userData.data.lastName}`}
                      profileUrl={imageURL || ""}
                    />
                    <Card
                      border="none"
                      boxShadow="none"
                      overflow="hidden"
                      mb="15px"
                      w="100%"
                    >
                      <LeftInfoInput
                        leftTitle="Name"
                        inputElement={
                          <Input
                            borderRadius="0px"
                            value={userData?.data.firstName}
                            readOnly
                          />
                        }
                      />
                      <Divider
                        borderWidth="1"
                        width="calc(100% - 25px)"
                        ml="25px"
                      />
                      <LeftInfoInput
                        leftTitle="Last Name"
                        inputElement={
                          <Input
                            borderRadius="0px"
                            value={userData?.data.lastName}
                            readOnly
                          />
                        }
                      />
                    </Card>
                    <LeftInfoInput
                      leftTitle="Username"
                      inputElement={
                        <Input value={userData?.data.username} readOnly />
                      }
                      mb="15px"
                    />
                    <LeftInfoInput
                      leftTitle="Title"
                      inputElement={
                        <Input value={userData?.data.title} readOnly />
                      }
                      mb="15px"
                    />
                    <LeftInfoInput
                      leftTitle="Email"
                      inputElement={
                        <Input value={userData?.data.email} readOnly />
                      }
                      mb="15px"
                    />
                    <LeftInfoInput
                      leftTitle="Phone #"
                      inputElement={
                        <Input value={userData?.data.phoneNumber} readOnly />
                      }
                      mb="15px"
                    />
                    {selectedUser && (
                      <Box display="flex" justifyContent="center" width="500px">
                        <VStack spacing={3} width="100%">
                          <SetUserNeedToChangePasswordButton
                            userId={selectedUser.id}
                            resettingPassword={resettingPassword}
                            onModalClose={onModalClose}
                          />
                          <ResetPasswordButton
                            userId={selectedUser.id}
                            resettingPassword={resettingPassword}
                            setResettingPassword={setResettingPassword}
                            onModalClose={onModalClose}
                          />
                        </VStack>
                      </Box>
                    )}
                  </Flex>
                )
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>
    </>
  );
}

function PasswordsManagerModal(props: PasswordsManagerModalProps) {
  const { isModalOpen, onModalClose, ...rest } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxWidth="540px"
        minHeight="924px"
        maxHeight="calc(100% - 100px)"
      >
        {isModalOpen && (
          <PasswordsManagerModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            {...rest}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { PasswordsManagerModal };
