import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ShapeState } from "../../types";

type UseSaveDiagramsPayloadItem = {
  id?: string | null;
  imageCode: string;
  data: ShapeState[];
};

function useSaveDiagrams(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["saveDiagrams", { encounterId, chartCode }],
    (payload: UseSaveDiagramsPayloadItem) =>
      axiosChartInstance.put(
        `v1/diagram-chart/${encounterId}/${chartCode}`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["diagrams", { encounterId, chartCode }]);
        queryClient.invalidateQueries(["diagramsCategories"]);
        queryClient.invalidateQueries([
          "diagramsCounter",
          { encounterId, chartCode },
        ]);
      },
    }
  );
}

export type { UseSaveDiagramsPayloadItem };
export { useSaveDiagrams };
