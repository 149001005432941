import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { EncounterUserType } from "modules/identity";
import { EncounterAssignResponse } from "modules/in-process/types";
import { useQueryClient } from "react-query";

type UseAssignUserPayload = {
  type: EncounterUserType;
  userId: string | null;
};

function useAssignUser(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["assignUser", { encounterId }],
    (payload: UseAssignUserPayload) =>
      axiosPatientInstance.put<EncounterAssignResponse>(
        `v1/InProcess/${encounterId}/assign-user`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patientDetails", { encounterId }]);
        queryClient.invalidateQueries("inProcessEncounterList");
        queryClient.invalidateQueries("auditsList");
      },
    }
  );
}

export type { UseAssignUserPayload };
export { useAssignUser };
