import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFavoriteRequest } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type UpdateChartFavoriteProps = {
  id: string;
  sectionCode: string;
};

function useUpdateChartFavorite({ id, sectionCode }: UpdateChartFavoriteProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateChartFavorite", { id, sectionCode }],
    (payload: ChartFavoriteRequest) =>
      axiosChartInstance.put(`v1/favorites/${id}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartFavorites"]);
      },
    }
  );
}

export { useUpdateChartFavorite };
