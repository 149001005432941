import { Icon } from "@chakra-ui/react";
import { EmrPerson } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import {
  PHYSICAL_EXAM_GRID_COLUMNS,
  PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP,
} from "modules/provider/shared";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SPEX";
const chartCode = "C073x";

function T73PhysicalExam() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Physical Exam",
    chartCode,
  });

  const { watch } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal = Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`));
  const q009AllNormal = Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`));
  const q010AllNormal = Boolean(watch(`Q010.${chartCode}${sectionId}Q010A001`));
  const q011AllNormal = Boolean(watch(`Q011.${chartCode}${sectionId}Q011A001`));
  const q012AllNormal = Boolean(watch(`Q012.${chartCode}${sectionId}Q012A001`));
  const q013AllNormal = Boolean(watch(`Q013.${chartCode}${sectionId}Q013A001`));
  const q014AllNormal = Boolean(watch(`Q014.${chartCode}${sectionId}Q014A001`));
  const q015AllNormal = Boolean(watch(`Q015.${chartCode}${sectionId}Q015A001`));
  const q016AllNormal = Boolean(watch(`Q016.${chartCode}${sectionId}Q016A001`));
  const q017AllNormal = Boolean(watch(`Q017.${chartCode}${sectionId}Q017A001`));
  const q018AllNormal = Boolean(watch(`Q018.${chartCode}${sectionId}Q018A001`));
  const q019AllNormal = Boolean(watch(`Q019.${chartCode}${sectionId}Q019A001`));
  const q020AllNormal = Boolean(watch(`Q020.${chartCode}${sectionId}Q020A001`));
  const q021AllNormal = Boolean(watch(`Q021.${chartCode}${sectionId}Q021A001`));
  const q022AllNormal = Boolean(watch(`Q022.${chartCode}${sectionId}Q022A001`));
  const q023AllNormal = Boolean(watch(`Q023.${chartCode}${sectionId}Q023A001`));
  const q024AllNormal = Boolean(watch(`Q024.${chartCode}${sectionId}Q024A001`));
  const q025AllNormal = Boolean(watch(`Q025.${chartCode}${sectionId}Q025A001`));
  const q026AllNormal = Boolean(watch(`Q026.${chartCode}${sectionId}Q026A001`));
  const q027AllNormal = Boolean(watch(`Q027.${chartCode}${sectionId}Q027A001`));
  const q028AllNormal = Boolean(watch(`Q028.${chartCode}${sectionId}Q028A001`));
  const q030AllNormal = Boolean(watch(`Q030.${chartCode}${sectionId}Q030A001`));
  const q031AllNormal = Boolean(watch(`Q031.${chartCode}${sectionId}Q031A001`));

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    label="Nursing Assessment reviewed"
                    pr="0px"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    label="Vitals Reviewed"
                    pr="0px"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="PTA"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="in ED"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no acute distress"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mild distress"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="moderate distress"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="severe distress"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears normal"
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="alert"
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="anxious"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="lethargic"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unconscious"
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unresponsive"
                    name={`Q002.${chartCode}${sectionId}Q002A023`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A024`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A026`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head"
            >
              Head
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="ear"
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="ear"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no obvious trauma"
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="raccoon eyes"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="Battle's sign"
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A019`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q003.${chartCode}${sectionId}Q003A020`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q003.${chartCode}${sectionId}Q003A021`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A022`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A024`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A023`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="EENT"
            >
              EENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharynx nml"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharyngeal erythema"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial petechia"
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharynx nml"
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hemotympanum"
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A011`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="TM obscured by wax"
                    name={`Q004.${chartCode}${sectionId}Q004A012`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A013`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A014`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A015`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no injury to teeth, lips or gums"
                    name={`Q004.${chartCode}${sectionId}Q004A016`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="clotted nasal blood"
                    name={`Q004.${chartCode}${sectionId}Q004A017`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A018`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A019`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A020`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nasal septal hematoma"
                    name={`Q004.${chartCode}${sectionId}Q004A021`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A022`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A023`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A024`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial abrasions"
                    name={`Q004.${chartCode}${sectionId}Q004A025`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A026`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A027`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A028`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dental injury"
                    name={`Q004.${chartCode}${sectionId}Q004A029`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="malocclusion"
                    name={`Q004.${chartCode}${sectionId}Q004A030`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A031`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q004.${chartCode}${sectionId}Q004A032`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="crosses vermillion border"
                    name={`Q004.${chartCode}${sectionId}Q004A033`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A034`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q004.${chartCode}${sectionId}Q004A035`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A036`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A038`}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A037`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Neck / Back"
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no external sign of trauma"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abrasion"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="muscle spasm"
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="decreased ROM"
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="rib tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q005.${chartCode}${sectionId}Q005A013`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A014`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no vertebral tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A015`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="vertebral point-tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A016`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A017`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CVA tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A018`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q005.${chartCode}${sectionId}Q005A019`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q005.${chartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A021`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="painless ROM"
                    name={`Q005.${chartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q005.${chartCode}${sectionId}Q005A023`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="pain on neck movement"
                    name={`Q005.${chartCode}${sectionId}Q005A024`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="pain with axial compression"
                    name={`Q005.${chartCode}${sectionId}Q005A025`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="vertebral point-tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A026`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A027`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thyroid nml"
                    name={`Q005.${chartCode}${sectionId}Q005A028`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lymphadenopathy"
                    name={`Q005.${chartCode}${sectionId}Q005A029`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="thyromegaly"
                    name={`Q005.${chartCode}${sectionId}Q005A030`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q005.${chartCode}${sectionId}Q005A031`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A032`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="subcontaneous emphysema"
                    name={`Q005.${chartCode}${sectionId}Q005A033`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A034`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A036`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A035`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Nexus Criteria"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q006.${chartCode}${sectionId}Q006A001`}
                    w="81px"
                  >
                    negative
                  </ChartRowElement>
                </>
              }
            >
              Nexus Criteria
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="midline tenderness"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="distracting injury"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="altered mental status"
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="recent ETOH"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="focal neuro deficit"
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest non-tender"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="abrasions"
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no respiratory distress"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="crepitus"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="subcontaneous emphysema"
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="splinting"
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="paradoxical movements"
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="flail chest"
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="seat belt bruising"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breathing sounds nml"
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breathing sounds"
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A020`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A021`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A022`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp. distress"
                    name={`Q007.${chartCode}${sectionId}Q007A023`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wheezes"
                    name={`Q007.${chartCode}${sectionId}Q007A024`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q007.${chartCode}${sectionId}Q007A025`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q007.${chartCode}${sectionId}Q007A026`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A027`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A028`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A029`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q007.${chartCode}${sectionId}Q007A031`}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A030`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="reg. rate & rhythm"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="irregularly irregular rhythm"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="JVD"
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="extrasystoles"
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="occasional"
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="frequent"
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A010`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bilateral pulses nml"
                    name={`Q008.${chartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="asymmetrical pulses"
                    name={`Q008.${chartCode}${sectionId}Q008A012`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A013`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q008.${chartCode}${sectionId}Q008A014`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="murmur"
                    name={`Q008.${chartCode}${sectionId}Q008A015`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="grade"
                    name={`Q008.${chartCode}${sectionId}Q008A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A017`}
                    w="100px"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="/6"
                    name={`Q008.${chartCode}${sectionId}Q008A018`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="sys"
                    name={`Q008.${chartCode}${sectionId}Q008A019`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="dias"
                    name={`Q008.${chartCode}${sectionId}Q008A020`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A021`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="gallop"
                    name={`Q008.${chartCode}${sectionId}Q008A022`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="S3"
                    name={`Q008.${chartCode}${sectionId}Q008A023`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="S4"
                    name={`Q008.${chartCode}${sectionId}Q008A024`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A025`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased pulse"
                    name={`Q008.${chartCode}${sectionId}Q008A026`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q008.${chartCode}${sectionId}Q008A027`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q008.${chartCode}${sectionId}Q008A028`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="radial"
                    name={`Q008.${chartCode}${sectionId}Q008A029`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A030`}
                    w="249.6666717529297px"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="fem"
                    name={`Q008.${chartCode}${sectionId}Q008A031`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A032`}
                    w="249.6666717529297px"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="dors ped"
                    name={`Q008.${chartCode}${sectionId}Q008A033`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A034`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q008.${chartCode}${sectionId}Q008A036`}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A035`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="generalized"
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="LUQ"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="RUQ"
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="LLQ"
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="RLQ"
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no organomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A012`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="organomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A013`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q009.${chartCode}${sectionId}Q009A014`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="hepatomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A015`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="splenomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A016`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A017`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pulsatile mass"
                    name={`Q009.${chartCode}${sectionId}Q009A018`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mass"
                    name={`Q009.${chartCode}${sectionId}Q009A019`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="prominent  aortic pulsation"
                    name={`Q009.${chartCode}${sectionId}Q009A020`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A021`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abdominal bruit"
                    name={`Q009.${chartCode}${sectionId}Q009A022`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A023`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q009.${chartCode}${sectionId}Q009A024`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A025`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q009.${chartCode}${sectionId}Q009A027`}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A026`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color, warm, dry"
                    name={`Q010.${chartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cyanosis"
                    name={`Q010.${chartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoresis"
                    name={`Q010.${chartCode}${sectionId}Q010A004`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="pallor"
                    name={`Q010.${chartCode}${sectionId}Q010A005`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="warmth"
                    name={`Q010.${chartCode}${sectionId}Q010A006`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q010.${chartCode}${sectionId}Q010A007`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q010.${chartCode}${sectionId}Q010A008`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q010.${chartCode}${sectionId}Q010A009`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A010`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no rash"
                    name={`Q010.${chartCode}${sectionId}Q010A011`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q010.${chartCode}${sectionId}Q010A012`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="zoster-like"
                    name={`Q010.${chartCode}${sectionId}Q010A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="petechiae"
                    name={`Q010.${chartCode}${sectionId}Q010A014`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q010.${chartCode}${sectionId}Q010A015`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="lymphangitis"
                    name={`Q010.${chartCode}${sectionId}Q010A016`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A017`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q010.${chartCode}${sectionId}Q010A018`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ucler"
                    name={`Q010.${chartCode}${sectionId}Q010A019`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="location:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q010.${chartCode}${sectionId}Q010A029`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A020`}
                    w="673px"
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="depth / stage:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q010.${chartCode}${sectionId}Q010A030`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="1"
                    width="2.5rem"
                    name={`Q010.${chartCode}${sectionId}Q010A021`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="2"
                    width="2.5rem"
                    name={`Q010.${chartCode}${sectionId}Q010A022`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="3"
                    width="2.5rem"
                    name={`Q010.${chartCode}${sectionId}Q010A023`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="4"
                    width="2.5rem"
                    name={`Q010.${chartCode}${sectionId}Q010A024`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml palp."
                    name={`Q010.${chartCode}${sectionId}Q010A025`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="crepitus"
                    name={`Q010.${chartCode}${sectionId}Q010A026`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A027`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q010.${chartCode}${sectionId}Q010A031`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A028`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Eyes"
            >
              Eyes
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q011.${chartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="unequal pupils"
                    name={`Q011.${chartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A004`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A005`}
                    w="50px"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    name={`Q011.${chartCode}${sectionId}Q011A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A007`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A008`}
                    w="50px"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    name={`Q011.${chartCode}${sectionId}Q011A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A010`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOMI"
                    name={`Q011.${chartCode}${sectionId}Q011A011`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM entrapment"
                    name={`Q011.${chartCode}${sectionId}Q011A012`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="EOM palsy"
                    name={`Q011.${chartCode}${sectionId}Q011A013`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A014`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A015`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A016`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hyphema"
                    name={`Q011.${chartCode}${sectionId}Q011A017`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="retinal detachment"
                    name={`Q011.${chartCode}${sectionId}Q011A018`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A019`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A020`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A021`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="periorbital hematoma"
                    name={`Q011.${chartCode}${sectionId}Q011A022`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A023`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A024`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A025`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lids & conjunctivae nml"
                    name={`Q011.${chartCode}${sectionId}Q011A026`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="subconjunctival hemorrhage"
                    name={`Q011.${chartCode}${sectionId}Q011A027`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A028`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A029`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A030`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scleral icterus"
                    name={`Q011.${chartCode}${sectionId}Q011A031`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="pale conjunctivae"
                    name={`Q011.${chartCode}${sectionId}Q011A032`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A033`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="foreign body"
                    name={`Q011.${chartCode}${sectionId}Q011A034`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A035`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A036`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A037`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="fluorescein uptake"
                    name={`Q011.${chartCode}${sectionId}Q011A038`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A039`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q011.${chartCode}${sectionId}Q011A041`}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A040`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Neuro / Psych"
            >
              Neuro / Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x4"
                    name={`Q012.${chartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    name={`Q012.${chartCode}${sectionId}Q012A089`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q012.${chartCode}${sectionId}Q012A003`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q012.${chartCode}${sectionId}Q012A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q012.${chartCode}${sectionId}Q012A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q012.${chartCode}${sectionId}Q012A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A007`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="confused"
                    name={`Q012.${chartCode}${sectionId}Q012A008`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="obtunded"
                    name={`Q012.${chartCode}${sectionId}Q012A009`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A010`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor normal"
                    name={`Q012.${chartCode}${sectionId}Q012A011`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A012`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A013`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="weak arm abduction (deltoid)"
                    name={`Q012.${chartCode}${sectionId}Q012A014`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A015`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A016`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A017`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="digital nerve deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A018`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A019`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A020`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="decreased fine touch"
                    name={`Q012.${chartCode}${sectionId}Q012A021`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="abnml 2-point discrim"
                    name={`Q012.${chartCode}${sectionId}Q012A022`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A023`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="median nerve deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A024`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A025`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A026`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A027`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensory deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A028`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="lat. 3 ½ fingers"
                    name={`Q012.${chartCode}${sectionId}Q012A029`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="last palm"
                    name={`Q012.${chartCode}${sectionId}Q012A030`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A031`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A032`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="pronation"
                    name={`Q012.${chartCode}${sectionId}Q012A033`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="thumb flexion"
                    name={`Q012.${chartCode}${sectionId}Q012A034`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="index & middle finger flexion"
                    name={`Q012.${chartCode}${sectionId}Q012A035`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A036`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ulnar nerve deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A037`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A038`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A039`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A040`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensory deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A041`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="med. palm"
                    name={`Q012.${chartCode}${sectionId}Q012A042`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="med. 1½ fingers"
                    name={`Q012.${chartCode}${sectionId}Q012A043`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A044`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A045`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="thumb adduction"
                    name={`Q012.${chartCode}${sectionId}Q012A046`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="fingers adduct"
                    name={`Q012.${chartCode}${sectionId}Q012A047`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A048`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="radial nerve deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A049`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A050`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A051`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A052`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensory deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A053`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="dorsolateral hand"
                    name={`Q012.${chartCode}${sectionId}Q012A054`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A055`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor deficit-"
                    name={`Q012.${chartCode}${sectionId}Q012A056`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="wrist drop"
                    name={`Q012.${chartCode}${sectionId}Q012A057`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="thumb extension"
                    name={`Q012.${chartCode}${sectionId}Q012A058`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A059`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensation normal"
                    name={`Q012.${chartCode}${sectionId}Q012A060`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensory deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A061`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A062`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CN's nml (2-12)"
                    name={`Q012.${chartCode}${sectionId}Q012A063`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CN deficit"
                    name={`Q012.${chartCode}${sectionId}Q012A064`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="facial droop"
                    name={`Q012.${chartCode}${sectionId}Q012A065`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A066`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A067`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A068`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="grips nml / symmetrical"
                    name={`Q012.${chartCode}${sectionId}Q012A069`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="grips abnormal"
                    name={`Q012.${chartCode}${sectionId}Q012A070`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="grips asymmetrical"
                    name={`Q012.${chartCode}${sectionId}Q012A071`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A072`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="reflexes nml"
                    name={`Q012.${chartCode}${sectionId}Q012A073`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal reflexes"
                    name={`Q012.${chartCode}${sectionId}Q012A074`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A075`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="clonus"
                    name={`Q012.${chartCode}${sectionId}Q012A076`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A077`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A078`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A079`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dorsiflex. of great toes nml bilat."
                    name={`Q012.${chartCode}${sectionId}Q012A080`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="difficulty walking"
                    name={`Q012.${chartCode}${sectionId}Q012A081`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="antalgic gait"
                    name={`Q012.${chartCode}${sectionId}Q012A082`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="abnormal gait"
                    name={`Q012.${chartCode}${sectionId}Q012A083`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A084`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mood / affect nml"
                    name={`Q012.${chartCode}${sectionId}Q012A085`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="depressed mood / affect"
                    name={`Q012.${chartCode}${sectionId}Q012A086`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A087`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q012.${chartCode}${sectionId}Q012A090`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A088`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Vascular"
            >
              Vascular
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no vascular compromise"
                    name={`Q013.${chartCode}${sectionId}Q013A002`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal color"
                    name={`Q013.${chartCode}${sectionId}Q013A003`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="abnormal warmth"
                    name={`Q013.${chartCode}${sectionId}Q013A004`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="abnormal cap refill"
                    name={`Q013.${chartCode}${sectionId}Q013A005`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A006`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A007`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A008`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="brachial pulse deficit"
                    name={`Q013.${chartCode}${sectionId}Q013A009`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A010`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A011`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A012`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="radial pulse deficit"
                    name={`Q013.${chartCode}${sectionId}Q013A013`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A014`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A015`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A016`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ulnar pulse deficit"
                    name={`Q013.${chartCode}${sectionId}Q013A017`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A018`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A019`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A020`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lower extremity deficit"
                    name={`Q013.${chartCode}${sectionId}Q013A023`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A024`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A025`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A026`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q013.${chartCode}${sectionId}Q013A027`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A028`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A029`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="dorsalis pedis"
                    name={`Q013.${chartCode}${sectionId}Q013A030`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="post tibial"
                    name={`Q013.${chartCode}${sectionId}Q013A031`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A032`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q013.${chartCode}${sectionId}Q013A021`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A022`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q014"
              subsectionLabel="Tendons"
            >
              Upper Tendons
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendon function nml"
                    name={`Q014.${chartCode}${sectionId}Q014A002`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deficit in tendon functions"
                    name={`Q014.${chartCode}${sectionId}Q014A003`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A004`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A005`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="limited extension"
                    name={`Q014.${chartCode}${sectionId}Q014A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="limited flexion"
                    name={`Q014.${chartCode}${sectionId}Q014A007`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A008`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendon injury:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A058`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q014.${chartCode}${sectionId}Q014A009`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="lac:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A059`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="complete"
                    name={`Q014.${chartCode}${sectionId}Q014A010`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="partial"
                    name={`Q014.${chartCode}${sectionId}Q014A011`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A012`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="location:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A060`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A013`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A014`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="shoulder"
                    name={`Q014.${chartCode}${sectionId}Q014A066`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="elbow"
                    name={`Q014.${chartCode}${sectionId}Q014A067`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="forearm"
                    name={`Q014.${chartCode}${sectionId}Q014A015`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="wrist"
                    name={`Q014.${chartCode}${sectionId}Q014A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="hand"
                    name={`Q014.${chartCode}${sectionId}Q014A017`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="digit"
                    name={`Q014.${chartCode}${sectionId}Q014A018`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A019`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="involving:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A061`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A020`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A021`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="brachioradialis"
                    name={`Q014.${chartCode}${sectionId}Q014A022`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="FCR"
                    name={`Q014.${chartCode}${sectionId}Q014A023`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="FCU"
                    name={`Q014.${chartCode}${sectionId}Q014A024`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A025`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="palmaris longus"
                    name={`Q014.${chartCode}${sectionId}Q014A026`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="FPL"
                    name={`Q014.${chartCode}${sectionId}Q014A027`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="FPB"
                    name={`Q014.${chartCode}${sectionId}Q014A028`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A029`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="FDS:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A062`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="2nd"
                    name={`Q014.${chartCode}${sectionId}Q014A030`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="3rd"
                    name={`Q014.${chartCode}${sectionId}Q014A031`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="4th"
                    name={`Q014.${chartCode}${sectionId}Q014A032`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="5th"
                    name={`Q014.${chartCode}${sectionId}Q014A033`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A034`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="FDP:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A063`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="2nd"
                    name={`Q014.${chartCode}${sectionId}Q014A035`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="3rd"
                    name={`Q014.${chartCode}${sectionId}Q014A036`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="4th"
                    name={`Q014.${chartCode}${sectionId}Q014A037`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="5th"
                    name={`Q014.${chartCode}${sectionId}Q014A038`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A039`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ECR longus"
                    name={`Q014.${chartCode}${sectionId}Q014A040`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="ECR brevis"
                    name={`Q014.${chartCode}${sectionId}Q014A041`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="ECU"
                    name={`Q014.${chartCode}${sectionId}Q014A042`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="common ext"
                    name={`Q014.${chartCode}${sectionId}Q014A043`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A044`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EPL"
                    name={`Q014.${chartCode}${sectionId}Q014A045`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="EPB"
                    name={`Q014.${chartCode}${sectionId}Q014A046`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="ext digitorum:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A064`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="2nd"
                    name={`Q014.${chartCode}${sectionId}Q014A047`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="3rd"
                    name={`Q014.${chartCode}${sectionId}Q014A048`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="4th"
                    name={`Q014.${chartCode}${sectionId}Q014A049`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="5th"
                    name={`Q014.${chartCode}${sectionId}Q014A050`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A051`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="APL"
                    name={`Q014.${chartCode}${sectionId}Q014A052`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="APB"
                    name={`Q014.${chartCode}${sectionId}Q014A053`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="ext indicis"
                    name={`Q014.${chartCode}${sectionId}Q014A054`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="ext digiti minimi"
                    name={`Q014.${chartCode}${sectionId}Q014A055`}
                    type="cross-option"
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A056`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q014.${chartCode}${sectionId}Q014A065`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A057`}
                    type="input"
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q030"
              subsectionLabel="Tendons"
            >
              Lower Tendons
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendon function nml"
                    name={`Q030.${chartCode}${sectionId}Q030A002`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendond deficit"
                    name={`Q030.${chartCode}${sectionId}Q030A003`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q030.${chartCode}${sectionId}Q030A004`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q030.${chartCode}${sectionId}Q030A005`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="limited extension"
                    name={`Q030.${chartCode}${sectionId}Q030A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="limited flexion"
                    name={`Q030.${chartCode}${sectionId}Q030A007`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A008`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendon visualized"
                    type="cross-option"
                    name={`Q030.${chartCode}${sectionId}Q030A009`}
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="tendon injury seen"
                    name={`Q030.${chartCode}${sectionId}Q030A010`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A011`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tendon injury:"
                    type="cross-option"
                    name={`Q030.${chartCode}${sectionId}Q030A012`}
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q030.${chartCode}${sectionId}Q030A013`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="lac:"
                    name={`Q030.${chartCode}${sectionId}Q030A014`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="complete"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A015`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="partial"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A016`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A017`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="location:"
                    type="cross-option"
                    name={`Q030.${chartCode}${sectionId}Q030A018`}
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q030.${chartCode}${sectionId}Q030A019`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q030.${chartCode}${sectionId}Q030A020`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="lower leg"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A021`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="ankle"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A022`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="foot"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A023`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="toe"
                    fontStyle="italic"
                    name={`Q030.${chartCode}${sectionId}Q030A024`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A025`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="extensor"
                    type="cross-option"
                    name={`Q030.${chartCode}${sectionId}Q030A026`}
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    label="flexor"
                    name={`Q030.${chartCode}${sectionId}Q030A027`}
                    type="cross-option"
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A028`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q030.${chartCode}${sectionId}Q030A029`}
                    isDisabled={q030AllNormal}
                  />
                  <ChartRowElement
                    name={`Q030.${chartCode}${sectionId}Q030A030`}
                    type="input"
                    isDisabled={q030AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q015"
              subsectionLabel="Shoulder"
            >
              Shoulder
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q015.${chartCode}${sectionId}Q015A002`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q015.${chartCode}${sectionId}Q015A003`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="soft tissue"
                    name={`Q015.${chartCode}${sectionId}Q015A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q015.${chartCode}${sectionId}Q015A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A006`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A007`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A008`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q015.${chartCode}${sectionId}Q015A009`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q015.${chartCode}${sectionId}Q015A010`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A011`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A012`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A013`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no dislocation"
                    name={`Q015.${chartCode}${sectionId}Q015A014`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity:"
                    type="cross-option"
                    name={`Q015.${chartCode}${sectionId}Q015A040`}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="clavicular deformity"
                    name={`Q015.${chartCode}${sectionId}Q015A015`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="AC drop-off"
                    name={`Q015.${chartCode}${sectionId}Q015A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="anterior fullness"
                    name={`Q015.${chartCode}${sectionId}Q015A017`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A018`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A019`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A020`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="full ROM"
                    name={`Q015.${chartCode}${sectionId}Q015A021`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q015.${chartCode}${sectionId}Q015A022`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A023`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A024`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A025`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="held in:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q015.${chartCode}${sectionId}Q015A041`}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="adduction"
                    name={`Q015.${chartCode}${sectionId}Q015A026`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="abduction"
                    name={`Q015.${chartCode}${sectionId}Q015A027`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="internal rotation"
                    name={`Q015.${chartCode}${sectionId}Q015A028`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="external rotation"
                    name={`Q015.${chartCode}${sectionId}Q015A029`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A030`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS_SUB_GROUP}
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="held in:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q015.${chartCode}${sectionId}Q015A042`}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="adduction"
                    name={`Q015.${chartCode}${sectionId}Q015A031`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="abduction"
                    name={`Q015.${chartCode}${sectionId}Q015A032`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="internal rotation"
                    name={`Q015.${chartCode}${sectionId}Q015A033`}
                    fontStyle="italic"
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="external rotation"
                    name={`Q015.${chartCode}${sectionId}Q015A034`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="flexion"
                    name={`Q015.${chartCode}${sectionId}Q015A035`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="extension"
                    name={`Q015.${chartCode}${sectionId}Q015A036`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A037`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q015.${chartCode}${sectionId}Q015A038`}
                    type="cross-option"
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A039`}
                    type="input"
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q016"
              subsectionLabel="Hand"
            >
              Hand
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q016.${chartCode}${sectionId}Q016A002`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q016.${chartCode}${sectionId}Q016A003`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q016.${chartCode}${sectionId}Q016A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q016.${chartCode}${sectionId}Q016A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q016.${chartCode}${sectionId}Q016A006`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q016.${chartCode}${sectionId}Q016A007`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A008`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q016.${chartCode}${sectionId}Q016A009`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q016.${chartCode}${sectionId}Q016A010`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q016.${chartCode}${sectionId}Q016A011`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q016.${chartCode}${sectionId}Q016A012`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A013`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of FB"
                    name={`Q016.${chartCode}${sectionId}Q016A014`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q016.${chartCode}${sectionId}Q016A015`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q016.${chartCode}${sectionId}Q016A016`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q016.${chartCode}${sectionId}Q016A017`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="due to:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q016.${chartCode}${sectionId}Q016A034`}
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="pain"
                    name={`Q016.${chartCode}${sectionId}Q016A018`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="functional deficit"
                    name={`Q016.${chartCode}${sectionId}Q016A019`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A020`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q016.${chartCode}${sectionId}Q016A021`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q016.${chartCode}${sectionId}Q016A022`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q016.${chartCode}${sectionId}Q016A023`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A024`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nail injury"
                    name={`Q016.${chartCode}${sectionId}Q016A025`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q016.${chartCode}${sectionId}Q016A026`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q016.${chartCode}${sectionId}Q016A027`}
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="finger #:"
                    type="cross-option"
                    name={`Q016.${chartCode}${sectionId}Q016A035`}
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A028`}
                    w="50px"
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="complete"
                    name={`Q016.${chartCode}${sectionId}Q016A029`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="partial avulsion"
                    name={`Q016.${chartCode}${sectionId}Q016A030`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    label="subungual hematoma"
                    name={`Q016.${chartCode}${sectionId}Q016A031`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A032`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q016.${chartCode}${sectionId}Q016A036`}
                    isDisabled={q016AllNormal}
                  />
                  <ChartRowElement
                    name={`Q016.${chartCode}${sectionId}Q016A033`}
                    type="input"
                    isDisabled={q016AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q017"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q017.${chartCode}${sectionId}Q017A002`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q017.${chartCode}${sectionId}Q017A003`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q017.${chartCode}${sectionId}Q017A004`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A005`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A006`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A007`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="calf tenderness"
                    name={`Q017.${chartCode}${sectionId}Q017A008`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A009`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A010`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A011`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pedal edema"
                    name={`Q017.${chartCode}${sectionId}Q017A012`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pedal edema"
                    name={`Q017.${chartCode}${sectionId}Q017A013`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A014`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A015`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A016`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q017.${chartCode}${sectionId}Q017A017`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bony point-tenderness"
                    name={`Q017.${chartCode}${sectionId}Q017A018`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A019`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A020`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A021`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no obvious injury"
                    name={`Q017.${chartCode}${sectionId}Q017A022`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="shortening of leg"
                    name={`Q017.${chartCode}${sectionId}Q017A023`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A024`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A025`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A026`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml ROM"
                    name={`Q017.${chartCode}${sectionId}Q017A027`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="external rotation of leg"
                    name={`Q017.${chartCode}${sectionId}Q017A028`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A029`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A030`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A031`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hip pain on leg movement"
                    name={`Q017.${chartCode}${sectionId}Q017A032`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A033`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A034`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A035`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hip tenderness"
                    name={`Q017.${chartCode}${sectionId}Q017A036`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="knee tenderness"
                    name={`Q017.${chartCode}${sectionId}Q017A037`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A038`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A039`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A040`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml tendon exam"
                    name={`Q017.${chartCode}${sectionId}Q017A041`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="erythema"
                    name={`Q017.${chartCode}${sectionId}Q017A042`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="soft tissue injury"
                    name={`Q017.${chartCode}${sectionId}Q017A043`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A044`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A045`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A046`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="positive Homan's sign"
                    name={`Q017.${chartCode}${sectionId}Q017A047`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A048`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A049`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A050`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="painful"
                    name={`Q017.${chartCode}${sectionId}Q017A051`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="unable to bear weight"
                    name={`Q017.${chartCode}${sectionId}Q017A052`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A053`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A054`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A055`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Joint exam:"
                    type="cross-option"
                    name={`Q017.${chartCode}${sectionId}Q017A067`}
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A056`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q017.${chartCode}${sectionId}Q017A057`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="ligaments laxity"
                    name={`Q017.${chartCode}${sectionId}Q017A058`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A059`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A060`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A061`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="joint effusion"
                    name={`Q017.${chartCode}${sectionId}Q017A062`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q017.${chartCode}${sectionId}Q017A063`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q017.${chartCode}${sectionId}Q017A064`}
                    type="cross-option"
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A065`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q017.${chartCode}${sectionId}Q017A068`}
                    isDisabled={q017AllNormal}
                  />
                  <ChartRowElement
                    name={`Q017.${chartCode}${sectionId}Q017A066`}
                    type="input"
                    isDisabled={q017AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q018"
              subsectionLabel="Wrist"
            >
              Wrist
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q018.${chartCode}${sectionId}Q018A002`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q018.${chartCode}${sectionId}Q018A003`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q018.${chartCode}${sectionId}Q018A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q018.${chartCode}${sectionId}Q018A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A006`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A007`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A008`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness in anatomical snuff box"
                    name={`Q018.${chartCode}${sectionId}Q018A009`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A010`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A011`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A012`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wrist pain on axial thumb load"
                    name={`Q018.${chartCode}${sectionId}Q018A013`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A014`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A015`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A016`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q018.${chartCode}${sectionId}Q018A017`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q018.${chartCode}${sectionId}Q018A018`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A019`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A020`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A021`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="normal ROM"
                    name={`Q018.${chartCode}${sectionId}Q018A022`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q018.${chartCode}${sectionId}Q018A023`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A024`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A025`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A026`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q018.${chartCode}${sectionId}Q018A027`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q018.${chartCode}${sectionId}Q018A028`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q018.${chartCode}${sectionId}Q018A029`}
                    type="cross-option"
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A030`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q018.${chartCode}${sectionId}Q018A032`}
                    isDisabled={q018AllNormal}
                  />
                  <ChartRowElement
                    name={`Q018.${chartCode}${sectionId}Q018A031`}
                    type="input"
                    isDisabled={q018AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q019"
              subsectionLabel="Forearm / Elbow"
            >
              Forearm / Elbow
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q019.${chartCode}${sectionId}Q019A002`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q019.${chartCode}${sectionId}Q019A003`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q019.${chartCode}${sectionId}Q019A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q019.${chartCode}${sectionId}Q019A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q019.${chartCode}${sectionId}Q019A006`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q019.${chartCode}${sectionId}Q019A007`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    name={`Q019.${chartCode}${sectionId}Q019A008`}
                    type="input"
                    isDisabled={q019AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q019.${chartCode}${sectionId}Q019A009`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q019.${chartCode}${sectionId}Q019A010`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q019.${chartCode}${sectionId}Q019A011`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q019.${chartCode}${sectionId}Q019A012`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    name={`Q019.${chartCode}${sectionId}Q019A013`}
                    type="input"
                    isDisabled={q019AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="normal ROM"
                    name={`Q019.${chartCode}${sectionId}Q019A014`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q019.${chartCode}${sectionId}Q019A015`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q019.${chartCode}${sectionId}Q019A016`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q019.${chartCode}${sectionId}Q019A017`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    name={`Q019.${chartCode}${sectionId}Q019A018`}
                    type="input"
                    isDisabled={q019AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q019.${chartCode}${sectionId}Q019A019`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q019.${chartCode}${sectionId}Q019A020`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q019.${chartCode}${sectionId}Q019A021`}
                    type="cross-option"
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    name={`Q019.${chartCode}${sectionId}Q019A022`}
                    type="input"
                    isDisabled={q019AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q019.${chartCode}${sectionId}Q019A024`}
                    isDisabled={q019AllNormal}
                  />
                  <ChartRowElement
                    name={`Q019.${chartCode}${sectionId}Q019A023`}
                    type="input"
                    isDisabled={q019AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q020"
              subsectionLabel="Arm / Shoulder"
            >
              Arm / Shoulder
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q020.${chartCode}${sectionId}Q020A002`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q020.${chartCode}${sectionId}Q020A003`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q020.${chartCode}${sectionId}Q020A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q020.${chartCode}${sectionId}Q020A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q020.${chartCode}${sectionId}Q020A006`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q020.${chartCode}${sectionId}Q020A007`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    name={`Q020.${chartCode}${sectionId}Q020A008`}
                    type="input"
                    isDisabled={q020AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q020.${chartCode}${sectionId}Q020A009`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q020.${chartCode}${sectionId}Q020A010`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q020.${chartCode}${sectionId}Q020A011`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q020.${chartCode}${sectionId}Q020A012`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    name={`Q020.${chartCode}${sectionId}Q020A013`}
                    type="input"
                    isDisabled={q020AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="normal ROM"
                    name={`Q020.${chartCode}${sectionId}Q020A014`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q020.${chartCode}${sectionId}Q020A015`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q020.${chartCode}${sectionId}Q020A016`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q020.${chartCode}${sectionId}Q020A017`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    name={`Q020.${chartCode}${sectionId}Q020A018`}
                    type="input"
                    isDisabled={q020AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q020.${chartCode}${sectionId}Q020A019`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q020.${chartCode}${sectionId}Q020A020`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q020.${chartCode}${sectionId}Q020A021`}
                    type="cross-option"
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    name={`Q020.${chartCode}${sectionId}Q020A022`}
                    type="input"
                    isDisabled={q020AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q020.${chartCode}${sectionId}Q020A024`}
                    isDisabled={q020AllNormal}
                  />
                  <ChartRowElement
                    name={`Q020.${chartCode}${sectionId}Q020A023`}
                    type="input"
                    isDisabled={q020AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q021"
              subsectionLabel="Genital / Rectal"
            >
              Genital / Rectal
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml genital exam"
                    name={`Q021.${chartCode}${sectionId}Q021A002`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="perineal hematoma"
                    name={`Q021.${chartCode}${sectionId}Q021A003`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A004`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml vaginal exam"
                    name={`Q021.${chartCode}${sectionId}Q021A005`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="blood at urethral meatus"
                    name={`Q021.${chartCode}${sectionId}Q021A006`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A007`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml rectal exam"
                    name={`Q021.${chartCode}${sectionId}Q021A008`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased rectal tone"
                    name={`Q021.${chartCode}${sectionId}Q021A009`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A010`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="discharge"
                    name={`Q021.${chartCode}${sectionId}Q021A011`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    label="lesions"
                    name={`Q021.${chartCode}${sectionId}Q021A012`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A013`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heme neg stool"
                    name={`Q021.${chartCode}${sectionId}Q021A014`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="black stool"
                    name={`Q021.${chartCode}${sectionId}Q021A015`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    label="bloody stool"
                    name={`Q021.${chartCode}${sectionId}Q021A016`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    label="heme pos. stool"
                    name={`Q021.${chartCode}${sectionId}Q021A017`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    label="trace"
                    name={`Q021.${chartCode}${sectionId}Q021A018`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A019`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q021.${chartCode}${sectionId}Q021A020`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A021`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q021.${chartCode}${sectionId}Q021A022`}
                    type="cross-option"
                    isDisabled={q021AllNormal}
                  />
                  <ChartRowElement
                    name={`Q021.${chartCode}${sectionId}Q021A023`}
                    type="input"
                    isDisabled={q021AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q022"
              subsectionLabel="Foot"
            >
              Foot
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q022.${chartCode}${sectionId}Q022A002`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q022.${chartCode}${sectionId}Q022A003`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q022.${chartCode}${sectionId}Q022A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q022.${chartCode}${sectionId}Q022A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q022.${chartCode}${sectionId}Q022A006`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q022.${chartCode}${sectionId}Q022A007`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    name={`Q022.${chartCode}${sectionId}Q022A008`}
                    type="input"
                    isDisabled={q022AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color/temp"
                    name={`Q022.${chartCode}${sectionId}Q022A009`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q022.${chartCode}${sectionId}Q022A010`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q022.${chartCode}${sectionId}Q022A011`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q022.${chartCode}${sectionId}Q022A012`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q022.${chartCode}${sectionId}Q022A013`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q022.${chartCode}${sectionId}Q022A014`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    name={`Q022.${chartCode}${sectionId}Q022A015`}
                    type="input"
                    isDisabled={q022AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin intact"
                    name={`Q022.${chartCode}${sectionId}Q022A016`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q022.${chartCode}${sectionId}Q022A017`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q022.${chartCode}${sectionId}Q022A018`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q022.${chartCode}${sectionId}Q022A019`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    name={`Q022.${chartCode}${sectionId}Q022A020`}
                    type="input"
                    isDisabled={q022AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q022.${chartCode}${sectionId}Q022A021`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q022.${chartCode}${sectionId}Q022A022`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q022.${chartCode}${sectionId}Q022A023`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    name={`Q022.${chartCode}${sectionId}Q022A024`}
                    type="input"
                    isDisabled={q022AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q022.${chartCode}${sectionId}Q022A025`}
                    type="cross-option"
                    isDisabled={q022AllNormal}
                  />
                  <ChartRowElement
                    name={`Q022.${chartCode}${sectionId}Q022A026`}
                    type="input"
                    isDisabled={q022AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q023"
              subsectionLabel="Ankle"
            >
              Ankle
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q023.${chartCode}${sectionId}Q023A002`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q023.${chartCode}${sectionId}Q023A003`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q023.${chartCode}${sectionId}Q023A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q023.${chartCode}${sectionId}Q023A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q023.${chartCode}${sectionId}Q023A006`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q023.${chartCode}${sectionId}Q023A007`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A008`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color"
                    name={`Q023.${chartCode}${sectionId}Q023A009`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q023.${chartCode}${sectionId}Q023A010`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q023.${chartCode}${sectionId}Q023A011`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q023.${chartCode}${sectionId}Q023A012`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q023.${chartCode}${sectionId}Q023A013`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q023.${chartCode}${sectionId}Q023A014`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A015`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no joint swelling"
                    name={`Q023.${chartCode}${sectionId}Q023A016`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q023.${chartCode}${sectionId}Q023A017`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q023.${chartCode}${sectionId}Q023A018`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q023.${chartCode}${sectionId}Q023A019`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A020`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin intact"
                    name={`Q023.${chartCode}${sectionId}Q023A021`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laxity of ligaments"
                    name={`Q023.${chartCode}${sectionId}Q023A022`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q023.${chartCode}${sectionId}Q023A023`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q023.${chartCode}${sectionId}Q023A024`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A025`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q023.${chartCode}${sectionId}Q023A026`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q023.${chartCode}${sectionId}Q023A027`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q023.${chartCode}${sectionId}Q023A028`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A029`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q023.${chartCode}${sectionId}Q023A030`}
                    type="cross-option"
                    isDisabled={q023AllNormal}
                  />
                  <ChartRowElement
                    name={`Q023.${chartCode}${sectionId}Q023A031`}
                    type="input"
                    isDisabled={q023AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              isRequired={false}
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q031"
              subsectionLabel="Leg"
            >
              Ottawa Ankle/Foot Rules:
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="label"
                  label="Any pain in the malleolar or midfoot area, and any one of the following:"
                  fontStyle="italic"
                  color="black"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Bone tenderness along the distal 6 cm of the posterior edge of the fibula or tibia, or the tip of the lateral or medial malleolus."
                  name={`Q031.${chartCode}${sectionId}Q031A002`}
                  isDisabled={q031AllNormal}
                />
                <ChartRowElement
                  name={`Q031.${chartCode}${sectionId}Q031A003`}
                  type="input"
                  isDisabled={q031AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Bone tenderness at the base of the 5th metatarsal (for foot injuries)."
                  name={`Q031.${chartCode}${sectionId}Q031A004`}
                  isDisabled={q031AllNormal}
                />
                <ChartRowElement
                  name={`Q031.${chartCode}${sectionId}Q031A005`}
                  type="input"
                  isDisabled={q031AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="Bone tenderness at the navicular bone (for foot injuries)."
                  name={`Q031.${chartCode}${sectionId}Q031A006`}
                  isDisabled={q031AllNormal}
                />
                <ChartRowElement
                  name={`Q031.${chartCode}${sectionId}Q031A007`}
                  type="input"
                  isDisabled={q031AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="An inability to bear weight four steps both immediately and in the ED."
                  name={`Q031.${chartCode}${sectionId}Q031A008`}
                  isDisabled={q031AllNormal}
                />
                <ChartRowElement
                  name={`Q031.${chartCode}${sectionId}Q031A009`}
                  type="input"
                  isDisabled={q031AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q024"
              subsectionLabel="Leg"
            >
              Leg
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q024.${chartCode}${sectionId}Q024A002`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q024.${chartCode}${sectionId}Q024A003`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q024.${chartCode}${sectionId}Q024A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q024.${chartCode}${sectionId}Q024A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q024.${chartCode}${sectionId}Q024A006`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q024.${chartCode}${sectionId}Q024A007`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    name={`Q024.${chartCode}${sectionId}Q024A008`}
                    type="input"
                    isDisabled={q024AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of ischemia"
                    name={`Q024.${chartCode}${sectionId}Q024A009`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q024.${chartCode}${sectionId}Q024A010`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="eccymosis"
                    name={`Q024.${chartCode}${sectionId}Q024A011`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q024.${chartCode}${sectionId}Q024A012`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q024.${chartCode}${sectionId}Q024A013`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q024.${chartCode}${sectionId}Q024A014`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    name={`Q024.${chartCode}${sectionId}Q024A015`}
                    type="input"
                    isDisabled={q024AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q024.${chartCode}${sectionId}Q024A016`}
                    type="cross-option"
                    isDisabled={q024AllNormal}
                  />
                  <ChartRowElement
                    name={`Q024.${chartCode}${sectionId}Q024A017`}
                    type="input"
                    isDisabled={q024AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q025"
              subsectionLabel="Knee"
            >
              Knee
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q025.${chartCode}${sectionId}Q025A002`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q025.${chartCode}${sectionId}Q025A003`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q025.${chartCode}${sectionId}Q025A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q025.${chartCode}${sectionId}Q025A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A006`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A007`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A008`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q025.${chartCode}${sectionId}Q025A009`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q025.${chartCode}${sectionId}Q025A010`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q025.${chartCode}${sectionId}Q025A011`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A012`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A013`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A014`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no joint swelling"
                    name={`Q025.${chartCode}${sectionId}Q025A015`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="joint effusion"
                    name={`Q025.${chartCode}${sectionId}Q025A016`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A017`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A018`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A019`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml ROM"
                    name={`Q025.${chartCode}${sectionId}Q025A020`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q025.${chartCode}${sectionId}Q025A021`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A022`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A023`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A024`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q025.${chartCode}${sectionId}Q025A025`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A026`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A027`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A028`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="stable"
                    name={`Q025.${chartCode}${sectionId}Q025A029`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ligaments"
                    name={`Q025.${chartCode}${sectionId}Q025A030`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A031`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain"
                    name={`Q025.${chartCode}${sectionId}Q025A032`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="laxity"
                    name={`Q025.${chartCode}${sectionId}Q025A033`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="on anterior drawer"
                    name={`Q025.${chartCode}${sectionId}Q025A034`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A035`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A036`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A037`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain"
                    name={`Q025.${chartCode}${sectionId}Q025A038`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="laxity"
                    name={`Q025.${chartCode}${sectionId}Q025A039`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="on posterior drawer"
                    name={`Q025.${chartCode}${sectionId}Q025A040`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A041`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A042`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A043`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain"
                    name={`Q025.${chartCode}${sectionId}Q025A044`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="laxity"
                    name={`Q025.${chartCode}${sectionId}Q025A045`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="on medial drawer"
                    name={`Q025.${chartCode}${sectionId}Q025A046`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A047`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A048`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A049`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain"
                    name={`Q025.${chartCode}${sectionId}Q025A050`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="laxity"
                    name={`Q025.${chartCode}${sectionId}Q025A051`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="on lateral drawer"
                    name={`Q025.${chartCode}${sectionId}Q025A052`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q025.${chartCode}${sectionId}Q025A053`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q025.${chartCode}${sectionId}Q025A054`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A055`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q025.${chartCode}${sectionId}Q025A056`}
                    type="cross-option"
                    isDisabled={q025AllNormal}
                  />
                  <ChartRowElement
                    name={`Q025.${chartCode}${sectionId}Q025A057`}
                    type="input"
                    isDisabled={q025AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q026"
              subsectionLabel="Thigh / Hip"
            >
              Thigh / Hip
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q026.${chartCode}${sectionId}Q026A002`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q026.${chartCode}${sectionId}Q026A003`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="soft-tissue"
                    name={`Q026.${chartCode}${sectionId}Q026A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="bony"
                    name={`Q026.${chartCode}${sectionId}Q026A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q026.${chartCode}${sectionId}Q026A006`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q026.${chartCode}${sectionId}Q026A007`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    name={`Q026.${chartCode}${sectionId}Q026A008`}
                    type="input"
                    isDisabled={q026AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no swelling"
                    name={`Q026.${chartCode}${sectionId}Q026A009`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="swelling"
                    name={`Q026.${chartCode}${sectionId}Q026A010`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q026.${chartCode}${sectionId}Q026A011`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q026.${chartCode}${sectionId}Q026A012`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q026.${chartCode}${sectionId}Q026A013`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    name={`Q026.${chartCode}${sectionId}Q026A014`}
                    type="input"
                    isDisabled={q026AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited hip ROM"
                    name={`Q026.${chartCode}${sectionId}Q026A015`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q026.${chartCode}${sectionId}Q026A016`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q026.${chartCode}${sectionId}Q026A017`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    name={`Q026.${chartCode}${sectionId}Q026A018`}
                    type="input"
                    isDisabled={q026AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hip pain on leg movement"
                    name={`Q026.${chartCode}${sectionId}Q026A019`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q026.${chartCode}${sectionId}Q026A020`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q026.${chartCode}${sectionId}Q026A021`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    name={`Q026.${chartCode}${sectionId}Q026A022`}
                    type="input"
                    isDisabled={q026AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q026.${chartCode}${sectionId}Q026A023`}
                    type="cross-option"
                    isDisabled={q026AllNormal}
                  />
                  <ChartRowElement
                    name={`Q026.${chartCode}${sectionId}Q026A024`}
                    type="input"
                    isDisabled={q026AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q027"
              subsectionLabel="Straight Leg Raising"
            >
              Straight Leg Raising
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="L negative"
                    name={`Q027.${chartCode}${sectionId}Q027A002`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain at"
                    name={`Q027.${chartCode}${sectionId}Q027A003`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    name={`Q027.${chartCode}${sectionId}Q027A004`}
                    w="70px"
                    type="input"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    label="degrees"
                    name={`Q027.${chartCode}${sectionId}Q027A005`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    name={`Q027.${chartCode}${sectionId}Q027A006`}
                    type="input"
                    isDisabled={q027AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="R negative"
                    name={`Q027.${chartCode}${sectionId}Q027A007`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain at"
                    name={`Q027.${chartCode}${sectionId}Q027A008`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    name={`Q027.${chartCode}${sectionId}Q027A009`}
                    w="70px"
                    type="input"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    label="degrees"
                    name={`Q027.${chartCode}${sectionId}Q027A010`}
                    type="cross-option"
                    isDisabled={q027AllNormal}
                  />
                  <ChartRowElement
                    name={`Q027.${chartCode}${sectionId}Q027A011`}
                    type="input"
                    isDisabled={q027AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q028"
              subsectionLabel="Hips / Pelvis"
            >
              Hips / Pelvis
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hips non-tender"
                    name={`Q028.${chartCode}${sectionId}Q028A002`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bony point-tenderness"
                    name={`Q028.${chartCode}${sectionId}Q028A003`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q028.${chartCode}${sectionId}Q028A004`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q028.${chartCode}${sectionId}Q028A005`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    name={`Q028.${chartCode}${sectionId}Q028A006`}
                    type="input"
                    isDisabled={q028AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis stable"
                    name={`Q028.${chartCode}${sectionId}Q028A007`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain on hip movement"
                    name={`Q028.${chartCode}${sectionId}Q028A008`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q028.${chartCode}${sectionId}Q028A009`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q028.${chartCode}${sectionId}Q028A010`}
                    type="cross-option"
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    name={`Q028.${chartCode}${sectionId}Q028A011`}
                    type="input"
                    isDisabled={q028AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q028.${chartCode}${sectionId}Q028A013`}
                    isDisabled={q028AllNormal}
                  />
                  <ChartRowElement
                    name={`Q028.${chartCode}${sectionId}Q028A012`}
                    type="input"
                    isDisabled={q028AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q029"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q029.${chartCode}${sectionId}Q029A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q029.${chartCode}${sectionId}Q029A002`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={<ChartSubsectionContent></ChartSubsectionContent>}
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T73PhysicalExam };
