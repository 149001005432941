import * as React from "react";
import { getFileUrl } from "utils";

function useFileUrl(file?: Nullable<string | File | Blob>, fileType?: string) {
  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    const newUrl = getFileUrl(file);

    if (fileType === "application/json") {
      setUrl("");
      return;
    }

    setUrl(newUrl);

    return () => {
      window.URL.revokeObjectURL(newUrl);
      setUrl("");
    };
  }, [file, fileType]);

  return url;
}

export { useFileUrl };
