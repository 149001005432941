import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Line = createIcon({
  path: (
    <path
      d="M0.307187 14.1627C-0.102396 14.5827 -0.102396 15.2651 0.307187 15.685C0.727273 16.105 1.40991 16.105 1.83 15.685L15.6928 1.83727C16.1024 1.41732 16.1024 0.734908 15.6928 0.314961C15.2727 -0.104987 14.5901 -0.104987 14.17 0.314961L0.307187 14.1627Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 16",
});

export { Line };
