import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Food = createIcon({
  path: (
    <g fill="none">
      <path
        d="M6.33864 12.4349C6.25366 12.442 6.16888 12.4457 6.0839 12.4457C5.85141 12.4457 5.62529 12.412 5.40387 12.3607L0.280406 17.4844C0.0289272 17.7355 0.0289272 18.1434 0.280406 18.3945L1.60641 19.7208C1.85789 19.972 2.26532 19.972 2.51611 19.7208L8.67382 13.5643L7.53265 12.333L6.33864 12.4349Z"
        fill="currentColor"
      />
      <path
        d="M19.8195 4.22855C19.579 3.98822 19.1899 3.98822 18.9495 4.22855L15.6292 7.54869C15.3151 7.86253 14.8062 7.86253 14.492 7.54869C14.1793 7.2351 14.1793 6.72663 14.492 6.41312L17.7958 3.11C18.0454 2.86016 18.0454 2.45513 17.7958 2.20471C17.5451 1.9552 17.1402 1.9552 16.8906 2.20471L13.5881 5.5075C13.274 5.82167 12.7657 5.82167 12.4515 5.5075C12.138 5.19403 12.138 4.6852 12.4515 4.37197L15.771 1.05215C16.0121 0.811455 16.0121 0.421578 15.771 0.180554C15.5306 -0.0601846 15.1407 -0.0601846 14.8996 0.180554L11.1647 3.91569C10.2667 4.81424 9.98306 6.07552 10.2738 7.22318L12.7775 9.7271C13.9251 10.0168 15.187 9.73298 16.084 8.83516L19.8194 5.10023C20.0606 4.85953 20.0606 4.46925 19.8195 4.22855Z"
        fill="currentColor"
      />
      <path
        d="M1.67813 0.520492C1.55014 0.392466 1.37707 0.322266 1.19934 0.322266C1.16153 0.322266 1.12351 0.325492 1.08639 0.331699C0.870152 0.36829 0.684626 0.507097 0.589311 0.70467C-0.504731 2.96598 -0.0475119 5.6718 1.72844 7.44783L4.90565 10.6246C5.21912 10.9385 5.64297 11.1122 6.08255 11.1122C6.12956 11.1122 6.17668 11.1102 6.22369 11.1064L8.06837 10.9489L16.1122 19.6265C16.2371 19.7614 16.412 19.8397 16.5963 19.8433C16.6001 19.8433 16.6041 19.8433 16.6081 19.8433C16.7883 19.8433 16.9608 19.7722 17.0888 19.6451L18.4664 18.2672C18.5937 18.1402 18.6648 17.9678 18.6648 17.7881C18.6648 17.6085 18.5937 17.436 18.4669 17.309L1.67813 0.520492Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 20 20",
});

export { Food };
