import * as React from "react";
import { Button, ButtonProps } from "../button";

const TableAction = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        border="2px solid"
        borderColor="gray.450"
        borderRadius="40px"
        bg="gray.250"
        color="gray.650"
        h="40px"
        _hover={{
          bg: "gray.250",
        }}
        _active={{
          bg: "gray.250",
        }}
        {...props}
      />
    );
  }
);

TableAction.displayName = "TableAction";

export { TableAction };
