import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const EligibilityFailInfo = createIcon({
  path: (
    <path
      d="M17.0368 30C16.81 30 16.4878 29.9165 16.1775 29.7494C9.39881 26.1094 7 24.355 7 20.0825V11.2869C7 9.77126 7.54898 9.18647 8.84983 8.64943C10.091 8.13625 14.4232 6.60865 15.6285 6.22675C16.0701 6.09547 16.5833 6 17.0368 6C17.4784 6 18.0035 6.09547 18.4451 6.22675C19.6385 6.62059 23.9826 8.13625 25.2238 8.64943C26.5127 9.19841 27.0617 9.77126 27.0617 11.2869V20.0825C27.0617 24.355 24.7583 26.2645 17.8841 29.7494C17.5738 29.9045 17.2636 30 17.0368 30ZM13.182 22.5052C13.552 22.5052 13.8503 22.3859 14.089 22.1472L17.0368 19.1875L19.9965 22.1472C20.2233 22.3859 20.5336 22.5052 20.8916 22.5052C21.5838 22.5052 22.1208 21.9682 22.1208 21.264C22.1208 20.9418 22.0015 20.6315 21.7628 20.4048L18.7911 17.4451L21.7628 14.4615C22.0254 14.2108 22.1328 13.9244 22.1328 13.6022C22.1328 12.91 21.5957 12.3729 20.9035 12.3729C20.5574 12.3729 20.2829 12.4804 20.0323 12.731L17.0368 15.7026L14.0651 12.7429C13.8265 12.5162 13.552 12.3849 13.182 12.3849C12.5017 12.3849 11.9647 12.9219 11.9647 13.6141C11.9647 13.9244 12.096 14.2347 12.3227 14.4615L15.2944 17.4451L12.3227 20.4167C12.096 20.6435 11.9647 20.9538 11.9647 21.264C11.9647 21.9682 12.5017 22.5052 13.182 22.5052Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 35 35",
});

export { EligibilityFailInfo };
