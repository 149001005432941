import { BaseVitalsColumn, Spo2Type, WeightUnit } from "../types/types";

import { SPO2_LABEL } from "../constants/constants";

/**
 * Validates if the given height in ft/inch is valid
 * Input examples: [`5'10"`, `5'`, `5"`, `5"0`, `5'10`, `5`, "`", `"`, `e`]
 * @param heightStr string representation of ft/inch height
 * @returns is height valid
 */
function isValidHeightStr(heightStr: string) {
  const xs = heightStr.split("'");

  if (xs.length !== 2) {
    return false;
  }

  const [ft, inch] = xs;
  const validFeet = !Number.isNaN(Number(ft));

  if (!validFeet) {
    return false;
  }

  if (validFeet && !inch) {
    return true;
  }

  const lastCharInInches = inch[inch.length - 1];
  const i = inch.substring(0, inch.length - 1);
  const invalidInches =
    !i || lastCharInInches !== `"` || Number.isNaN(Number(i));

  return !invalidInches;
}

function parseHeight(height: string): [number, number] | [] {
  if (!isValidHeightStr(height)) {
    return [];
  }

  const [ft, inch] = height.split("'");

  return [Number(ft), Number(inch.substring(0, inch.length - 1))];
}

function calculateBmi(weight: number | undefined, height: string | undefined) {
  if (!weight || !height) {
    return 0;
  }

  const [feet, inches] = parseHeight(height);

  if (!feet || inches === undefined || inches === null) {
    return 0;
  }

  const h = feet * 0.3048 + inches * 0.0254;

  return weight / h ** 2;
}

function weightConversion(weight: number, weightUnit: WeightUnit) {
  if (weightUnit === "kg") {
    return weight;
  }

  return weight * 0.453592;
}

function getMapperFn<
  Key extends keyof BaseVitalsColumn,
  Value extends BaseVitalsColumn[Key]
>(key: Key): (x: Value) => string {
  if (key === "spO2Type") {
    return (n) => SPO2_LABEL[n as Spo2Type];
  }

  if (key === "bmi" || key === "weight") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (n) => {
      if (typeof n === "number") {
        const numberFormatted = n.toString().match(/^-?\d+(?:\.\d{0,1})?/);
        return numberFormatted ? numberFormatted[0] : "";
      }

      return n;
    };
  }

  return (n) => String(n);
}

function getCellDisplay<
  Key extends keyof BaseVitalsColumn,
  Value extends BaseVitalsColumn[Key]
>(key: Key, value?: Value) {
  if (value !== undefined && value !== null && value !== "") {
    return getMapperFn(key)(value);
  }
  return "NA";
}

function getEditCellDisplay<
  Key extends keyof BaseVitalsColumn,
  Value extends BaseVitalsColumn[Key]
>(key: Key, value?: Value) {
  if (value !== undefined && value !== null && value !== "") {
    return getCellDisplay(key, value);
  }
  return undefined;
}

export {
  calculateBmi,
  getMapperFn,
  weightConversion,
  getCellDisplay,
  getEditCellDisplay,
  isValidHeightStr,
};
