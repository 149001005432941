import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteRoom(id: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteRoom", { id }],
    () => axiosIdentityInstance.delete(`/v1/room/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
      },
    }
  );
}

export { useDeleteRoom };
