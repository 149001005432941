import { Badge } from "@chakra-ui/react";
import * as React from "react";
import { Loading } from "shared";
import { formatTimer } from "utils";
import { useVitalsTimer } from "../api";
import { ActiveTimer } from "./ActiveTimer";

type VitalsTimerBadgeProps = {
  encounterId: string;
};

function VitalsTimerBadge(props: VitalsTimerBadgeProps) {
  const { encounterId } = props;
  const { data, isLoading, error } = useVitalsTimer(encounterId);
  const timerMs = data?.data.millisecondRemaining || 0;
  const timer = data?.data.timer ? formatTimer(data.data.timer) : "0:00";

  const showActiveTimer = data?.data && data.data.timer && !isLoading && !error;

  const showDefault = (error || !showActiveTimer) && !isLoading;

  const latestRecordTakenAt = data?.data.lastCheck
    ? new Date(data.data.lastCheck)
    : undefined;

  const isPaused =
    data?.data.status === "Paused" || data?.data.status === "NotStarted";

  return (
    <Badge
      bg="orange"
      color="white"
      borderRadius="20px"
      padding="0.125rem 0.5rem"
    >
      {isLoading && <Loading spinnerProps={{ size: "xs" }} />}

      {showActiveTimer && !isPaused && (
        <ActiveTimer
          latestRecordTakenAt={latestRecordTakenAt}
          timer={timerMs}
        />
      )}

      {(showDefault || isPaused) && timer}
    </Badge>
  );
}

export type { VitalsTimerBadgeProps };
export { VitalsTimerBadge };
