import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection } from "modules/charts-shared";

function useTriageMedicalHistory(encounterId: string) {
  return useAxiosQuery(["triageMedicalHistory", { encounterId }], () =>
    axiosChartInstance.get<ChartFormSubsection[]>(
      `v1/triage/${encounterId}/medical-history`
    )
  );
}

export { useTriageMedicalHistory };
