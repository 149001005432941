import {
  Box,
  Button,
  ButtonProps,
  chakra,
  CSSObject,
  Icon,
  IconProps,
  useFormControl,
  useMergeRefs,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { SiExpandClose } from "@medstonetech/slate-icons/build/icons/SiExpandClose";
import { SiExpandOpen } from "@medstonetech/slate-icons/build/icons/SiExpandOpen";
import { useSelect, UseSelectProps } from "downshift";
import * as React from "react";
import { MdCheck } from "react-icons/md";
import { DropdownMenu, DropdownMenuItem } from "shared/dropdown-menu";
import { AccessorType, applyAccessor } from "shared/utils";

type SelectProps<T> = UseSelectProps<T | null> & {
  labelAccessor: AccessorType<T>;
  valueAccessor: AccessorType<T>;
  btnProps?: ButtonProps;
  placeholder?: string;
  isDisabled?: boolean;
  showBlank?: boolean;
  customOpenIcon?: React.FC;
  customCloseIcon?: React.FC;
  iconProps?: IconProps;
  useCheckHighlighted?: boolean;
  placeHolderProps?: CSSObject;
};

function SelectInner<T>(
  props: SelectProps<T>,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    labelAccessor,
    valueAccessor,
    selectedItem: selectedItemProp,
    items,
    btnProps = {},
    placeholder,
    isDisabled,
    showBlank = true,
    customOpenIcon,
    customCloseIcon,
    iconProps,
    useCheckHighlighted,
    placeHolderProps,
    ...selectProps
  } = props;

  const internalItems = React.useMemo(() => {
    if (showBlank) {
      return [null, ...items];
    }

    return items;
  }, [items, showBlank]);

  const formControl = useFormControl({
    id: selectProps.id,
    isDisabled,
  });

  const getSelectedItem = () =>
    selectedItemProp &&
    items.find(
      (x) =>
        x &&
        applyAccessor(x, valueAccessor) ===
          applyAccessor(selectedItemProp, valueAccessor)
    );

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect<T | null>({
    ...selectProps,
    items: internalItems,
    selectedItem: getSelectedItem(),
    id: formControl.id,
    toggleButtonId: formControl.id,
  });

  const selectedItemLabel = selectedItem
    ? applyAccessor(selectedItem, labelAccessor)
    : "";

  const styles = useMultiStyleConfig("CustomSelect", {
    hasSelectedItem: !!selectedItem,
  });
  const {
    ref: toggleBtnRef,
    onClick: onToggleBtnOnClick,
    ...restToggleBtnProps
  } = getToggleButtonProps();
  const internalRef = React.useRef<HTMLButtonElement>(null);
  const mergedRef = useMergeRefs(ref, toggleBtnRef, internalRef);
  const internalOnClick: ButtonProps["onClick"] = (event) => {
    if (onToggleBtnOnClick) {
      onToggleBtnOnClick(event);
    }

    if (btnProps && btnProps.onClick) {
      btnProps.onClick(event);
    }
  };

  return (
    <>
      <Button
        type="button"
        ref={mergedRef}
        __css={!!btnProps.variant ? undefined : styles.togglebtn}
        {...btnProps}
        onClick={internalOnClick}
        {...formControl}
        {...restToggleBtnProps}
        {...btnProps}
      >
        <chakra.span
          title={selectedItemLabel}
          color={!selectedItemLabel ? "gray.450" : undefined}
          __css={styles.label}
          sx={placeHolderProps}
        >
          {selectedItemLabel || placeholder}
        </chakra.span>
        <Box __css={styles.btncontainer}>
          {!formControl.disabled && (
            <chakra.span
              __css={styles.action}
              role="img"
              display="flex"
              aria-label="dropdown"
            >
              {isOpen ? (
                <Icon
                  fontSize="24px"
                  as={customOpenIcon ? customOpenIcon : SiExpandClose}
                  color="gray.700"
                  {...iconProps}
                />
              ) : (
                <Icon
                  fontSize="24px"
                  as={customCloseIcon ? customCloseIcon : SiExpandOpen}
                  color="gray.700"
                  {...iconProps}
                />
              )}
            </chakra.span>
          )}
        </Box>
      </Button>
      <DropdownMenu
        outline="unset"
        respectiveToRef={internalRef}
        isOpen={isOpen}
        {...getMenuProps()}
      >
        {isOpen &&
          internalItems.map((item, index) => (
            <DropdownMenuItem
              key={item ? applyAccessor(item, valueAccessor) : "empty"}
              {...getItemProps({
                item: item as T,
                index,
                "aria-selected":
                  !useCheckHighlighted && index === highlightedIndex,
              })}
              color={
                useCheckHighlighted && index === highlightedIndex
                  ? "blue"
                  : undefined
              }
            >
              {useCheckHighlighted && (
                <Box height="24px" width="24px" mr={2}>
                  {item &&
                    selectedItem &&
                    applyAccessor(item, valueAccessor) ===
                      applyAccessor(selectedItem, valueAccessor) && (
                      <Icon
                        as={MdCheck}
                        height="24px"
                        width="24px"
                        color="blue"
                      />
                    )}
                </Box>
              )}

              {item ? applyAccessor(item, labelAccessor) : ""}
            </DropdownMenuItem>
          ))}
      </DropdownMenu>
    </>
  );
}

const Select = React.forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof SelectInner>;

export type { SelectProps };
export { Select };
