import { Box, HStack } from "@chakra-ui/react";
import { ExclamationTriangle } from "icons";
import {
  useEncounterIntegrations,
  useEncounterAYVAAllergiesIntegration,
} from "modules/reception/api";
import { EncounterIntegrationButton } from "modules/reception/components/encounter-integration";

type HL7AllergiesMedicationsRXProps = {
  encounterId: string;
  isLoadingUpdateIntegrationHL7?: boolean;
};

function HL7AllergiesMedicationsRX(props: HL7AllergiesMedicationsRXProps) {
  const { encounterId, isLoadingUpdateIntegrationHL7 = false } = props;

  const { data, isLoading: isLoadingEncounterIntegrations } =
    useEncounterIntegrations(encounterId);

  return (
    <HStack spacing="15px">
      <Box display="flex" justifyContent="space-between">
        <EncounterIntegrationButton
          type={"hl7-allergies"}
          label={"Ramsoft"}
          sx={{
            width: "220px",
            padding: "8px 16px 8px 16px",
            position: "relative",
            bottom: "15px",
          }}
          icon={ExclamationTriangle}
          status={data?.data?.ramsoftADTConnectionStatus ?? "Disconnected"}
          isLoading={
            isLoadingEncounterIntegrations || isLoadingUpdateIntegrationHL7
          }
        />
      </Box>
    </HStack>
  );
}

type AYVAAllergiesMedicationsRXProps = {
  encounterId: string;
  isLoadingUpdateIntegrationAYVA: boolean;
};

function AYVAAllergiesMedicationsRX(props: AYVAAllergiesMedicationsRXProps) {
  const { encounterId, isLoadingUpdateIntegrationAYVA } = props;

  const { data, isLoading: isLoadingEncounterIntegrations } =
    useEncounterAYVAAllergiesIntegration(encounterId);

  return (
    <HStack spacing="15px">
      <Box display="flex" justifyContent="space-between">
        <EncounterIntegrationButton
          type={"ayva-allergies"}
          label={"Allergies"}
          sx={{
            width: "220px",
            padding: "8px 16px 8px 16px",
            position: "relative",
            bottom: "15px",
          }}
          icon={ExclamationTriangle}
          status={data?.data?.ayvaAllergiesConnectionStatus ?? "Disconnected"}
          isLoading={
            isLoadingEncounterIntegrations || isLoadingUpdateIntegrationAYVA
          }
        />
      </Box>
    </HStack>
  );
}

export { HL7AllergiesMedicationsRX, AYVAAllergiesMedicationsRX };
