import * as React from "react";
import {
  HStack,
  Box,
  chakra,
  Spinner,
  IconButton,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  VStack,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { EmrPatientChart, EmrRemove } from "@medstonetech/slate-icons";
import { format } from "date-fns";
import { Avatar, Card, Button, DeleteDialog, StaffAvatar } from "shared";
import { ChartNote as ChartNoteType } from "types";
import { useChartNoteAudio } from "modules/charts-shared/api";
import { useFileUrl } from "hooks";

type ChartNoteProps = {
  note: ChartNoteType;
  onDeleteChartNote: () => Promise<unknown>;
  isDeleting: boolean;
  onExportChartNote: () => Promise<unknown>;
  isExporting: boolean;
};

type CommonNoteProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  note: string;
  exportable?: boolean;
  onExportChartNote: () => Promise<unknown>;
  isExporting: boolean;
};

type AudioNoteProps = {
  voiceNoteUrl: string;
};

type ChartNoteHeaderProps = {
  createdByName: string;
  createdAt: Date | string;
};

type SeenByPopoverProps = {
  viewedBy: { id: string; picture: string; name: string }[];
};

function SeenByPopover(props: SeenByPopoverProps) {
  const { viewedBy } = props;

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bg="transparent"
          minWidth="unset"
          width="20px"
          height="20px"
          padding="0"
          margin="0"
          _hover={{ bg: "transparent" }}
          disabled={viewedBy.length === 0}
        >
          <Avatar width="20px" height="20px" />
        </Button>
      </PopoverTrigger>
      <PopoverContent borderRadius="10px" padding="0.75rem 1rem" width="unset">
        <PopoverArrow />
        <HStack spacing="1.75rem" alignItems="flex-start" overflow="auto">
          {viewedBy.map((user) => (
            <VStack key={user.id} spacing="5px" maxWidth="60px">
              <StaffAvatar
                size="xs"
                width="60px"
                height="60px"
                fontSize="1rem"
                userName={user?.name}
                profileUrl={user?.picture || ""}
              />
              <chakra.span textAlign="center" fontSize="0.75rem">
                {user.name}
              </chakra.span>
            </VStack>
          ))}
        </HStack>
      </PopoverContent>
    </Popover>
  );
}

function ChartNoteHeader(props: ChartNoteHeaderProps) {
  const { createdByName, createdAt } = props;

  return (
    <HStack
      spacing="5px"
      alignItems="center"
      color="gray.750"
      fontSize="0.75rem"
      marginLeft="10px"
    >
      <chakra.span>{createdByName}</chakra.span>
      <chakra.span>&#183;</chakra.span>
      <chakra.span>
        {createdAt && format(new Date(createdAt), "hh:mm a L/d/yyyy")}
      </chakra.span>
    </HStack>
  );
}

function CommonNote(props: CommonNoteProps) {
  const { note, exportable, onExportChartNote, isExporting } = props;

  return (
    <Card
      padding="10px"
      borderRadius="0.625rem"
      bg="gray.50"
      position="relative"
      boxShadow="none"
    >
      {note}
      {isExporting ? (
        <Spinner
          position="absolute"
          top="-10px"
          right="-10px"
          width="20px"
          height="20px"
          color="blue"
        />
      ) : (
        <IconButton
          aria-label="add to chart"
          icon={
            <Icon
              as={EmrPatientChart}
              color={exportable ? "green" : "gray.700"}
            />
          }
          position="absolute"
          top="-10px"
          right="-10px"
          width="20px"
          height="20px"
          variant="ghost"
          minWidth="unset"
          onClick={onExportChartNote}
        />
      )}
    </Card>
  );
}

function AudioNote(props: AudioNoteProps) {
  const { voiceNoteUrl } = props;
  const { data, isLoading } = useChartNoteAudio(voiceNoteUrl);
  const audioUrl = useFileUrl(data?.data);

  return (
    <Skeleton isLoaded={!isLoading} height="30px">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src={audioUrl}
        style={{ width: "100%", height: "30px" }}
        controls
      />
    </Skeleton>
  );
}

function ChartNote(props: ChartNoteProps) {
  const {
    note,
    onDeleteChartNote,
    isDeleting,
    onExportChartNote,
    isExporting,
  } = props;
  const { createdAt, createdByEntity } = note;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showDeleteBtn, setShowDeleteBtn] = React.useState(false);

  return (
    <>
      <HStack alignItems="flex-end" width="100%">
        <StaffAvatar
          size="xs"
          width="30px"
          height="30px"
          fontSize=".5rem"
          userName={`${createdByEntity?.firstName} ${createdByEntity?.lastName}`}
          profileUrl={createdByEntity?.pictureUrl || ""}
        />
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          position="relative"
          onMouseEnter={() => setShowDeleteBtn(true)}
          onMouseLeave={() => setShowDeleteBtn(false)}
        >
          <ChartNoteHeader
            createdByName={
              createdByEntity
                ? `${createdByEntity.firstName} ${createdByEntity.lastName}`
                : ""
            }
            createdAt={createdAt}
          />
          {note.voiceNoteUrl ? (
            <AudioNote voiceNoteUrl={note.voiceNoteUrl} />
          ) : (
            <CommonNote
              note={note.note || ""}
              exportable={note.isExportable}
              onExportChartNote={onExportChartNote}
              isExporting={isExporting}
            />
          )}
          {/* Delete note button */}
          {showDeleteBtn && !isDeleting && (
            <IconButton
              aria-label="delete note"
              icon={<Icon as={EmrRemove} color="red" />}
              position="absolute"
              bottom="-10px"
              right="-10px"
              width="20px"
              height="20px"
              variant="ghost"
              minWidth="unset"
              onClick={onOpen}
            />
          )}
          {/* Is deleting note spinner */}
          {isDeleting && (
            <Spinner
              position="absolute"
              bottom="-10px"
              right="-10px"
              width="20px"
              height="20px"
              color="blue"
            />
          )}
        </Box>
        <Box>{note.readById && <SeenByPopover viewedBy={[]} />}</Box>
      </HStack>
      <DeleteDialog
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDeleteChartNote}
      />
    </>
  );
}

export type { ChartNoteProps };
export { ChartNote };
