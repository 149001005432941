import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { InsuranceCardType } from "modules/reception/types";
import { useQueryClient } from "react-query";

type UseCreateEncounterInsuranceBenefitFileProps = {
  encounterId: string;
  type: InsuranceCardType;
};

function useCreateEncounterInsuranceBenefitFile(
  params: UseCreateEncounterInsuranceBenefitFileProps
) {
  const { encounterId, type: insuranceType } = params;
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createEncounterInsuranceBenefitFile", { encounterId }],
    (input: FormData) =>
      axiosPatientInstance.post<string>(
        `v1/encounter/${encounterId}/form/files/insurance/${insuranceType}/benefit`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "encounterInsuranceBenefitsFile",
          { encounterId, insuranceType },
        ]),
    }
  );
}

export { useCreateEncounterInsuranceBenefitFile };
