import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrPerson } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  ChartRowGroup,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SPEX";
const chartCode = "C003x";

function T03PhysicalExam() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Physical Exam",
    chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSPEXQ001A001"
                    label="Nursing Assessment reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C003xSPEXQ001A002"
                    label="Vitals Reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="input" name="Q001.C003xSPEXQ001A003" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A001"
                    label="no acute distress"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A002"
                    label="c-collar"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A003"
                    label="backboard (PTA / in ED)"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSPEXQ002A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A005"
                    label="alert"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A006"
                    label="mild"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A007"
                    label="moderate"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A008"
                    label="severe distress"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSPEXQ002A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A010"
                    label="anxious"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.C003xSPEXQ002A011"
                    label="lethargic"
                  />
                  <ChartRowElement type="input" name="Q002.C003xSPEXQ002A012" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head"
            >
              Head
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A001"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q003.C003xSPEXQ003A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A004"
                    label="no swelling"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A005"
                    label="raccoon eyes"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A006"
                    label="Battle’s sign"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q003.C003xSPEXQ003A007"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q003.C003xSPEXQ003A008"
                  />
                  <ChartRowElement type="input" name="Q003.C003xSPEXQ003A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q003.C003xSPEXQ003A010"
                    label="no obvious trauma"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="input" name="Q003.C003xSPEXQ003A011" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="EYES"
            >
              EYES
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A001"
                    label="lids & conjunctivae nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A004"
                    label="PERRL"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A005"
                    label="unequal pupils"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A006"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.C003xSPEXQ004A007"
                    width="7.5rem"
                    textAlign="center"
                    inputType="number"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A036"
                    label="mm"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A008"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.C003xSPEXQ004A009"
                    width="7.5rem"
                    textAlign="center"
                    inputType="number"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A037"
                    label="mm"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A011"
                    label="EOMI"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A012"
                    label="EOM entrapment"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A013"
                    label="palsy"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A014"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A015"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A016" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A017"
                    label="hyphema"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A018"
                    label="retinal detachment"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A019"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A020"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A021" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A022"
                    label="periorbital hematoma"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A023"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A024"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A025" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A026"
                    label="subconjunctival hemorrhage"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A027"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A028"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A029" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A030"
                    label="foreign body"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q004.C003xSPEXQ004A031"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q004.C003xSPEXQ004A032"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A033" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C003xSPEXQ004A034"
                    label="fluorescein uptake"
                  />
                  <ChartRowElement type="input" name="Q004.C003xSPEXQ004A035" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Neck"
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A001"
                      label="non-tender"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A002"
                      label="see diagram"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q005.C003xSPEXQ005A003"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A004"
                      label="painless ROM"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A005"
                      label="pain on neck movement"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q005.C003xSPEXQ005A006"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A007"
                      label="nexus criteria neg"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A008"
                      label="midline tenderness"
                    />
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A009"
                      label="distracting injury"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q005.C003xSPEXQ005A010"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A011"
                      label="altered mental status"
                    />
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A012"
                      label="recent ETOH"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q005.C003xSPEXQ005A013"
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                  <ChartRowGroup>
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      name="Q005.C003xSPEXQ005A014"
                      label="focal neuro deficit"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q005.C003xSPEXQ005A015"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="ENT"
            >
              ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A001"
                    label="nml external exam"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A004"
                    label="pharynx nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A005"
                    label="hemotympanum"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C003xSPEXQ006A006"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C003xSPEXQ006A007"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A009"
                    label="no injury to teeth"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A010"
                    label="TM obscured by wax"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C003xSPEXQ006A011"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C003xSPEXQ006A012"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A013" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A014"
                    label="lips or gums"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A015"
                    label="clotted nasal blood"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C003xSPEXQ006A016"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C003xSPEXQ006A017"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A018" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A019"
                    label="nasal septal hematoma"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q006.C003xSPEXQ006A020"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q006.C003xSPEXQ006A021"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A022" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A023"
                    label="dental injury"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A024"
                    label="malocclusion"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A025" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A026"
                    label="laceration"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A027"
                    label="crosses vermillion border"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A028" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q006.C003xSPEXQ006A029"
                    label="dry mucous membranes"
                  />
                  <ChartRowElement type="input" name="Q006.C003xSPEXQ006A030" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Neuro / Psych"
            >
              Neuro / Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A001"
                    label="oriented x4"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A027"
                    label="disoriented to:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A002"
                    label="person"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A003"
                    label="place"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A004"
                    label="time"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A005"
                    label="situation"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A007"
                    label="sensation nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A008"
                    label="confused"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A009"
                    label="obtunded"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A011"
                    label="motor nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A012"
                    label="weakness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A013"
                    label="sensory loss"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A014" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A015"
                    label="CN’s nml (2-12)"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A016"
                    label="facial droop"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q007.C003xSPEXQ007A017"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q007.C003xSPEXQ007A018"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A019" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A020"
                    label="mood"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A021"
                    label="affect nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A022"
                    label="abnormal gait"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A023" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A024"
                    label="depressed mood"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C003xSPEXQ007A025"
                    label="affect"
                  />
                  <ChartRowElement type="input" name="Q007.C003xSPEXQ007A026" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A001"
                    label="chest non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A002"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A003"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A004"
                    label="abrasions"
                  />
                  <ChartRowElement type="input" name="Q008.C003xSPEXQ008A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A006"
                    label="no resp. distress"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A007"
                    label="decreased breath sounds"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q008.C003xSPEXQ008A008"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q008.C003xSPEXQ008A009"
                  />
                  <ChartRowElement type="input" name="Q008.C003xSPEXQ008A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A011"
                    label="breath sounds nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A012"
                    label="wheezes"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A013"
                    label="rales"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q008.C003xSPEXQ008A014"
                    label="rhonchi"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q008.C003xSPEXQ008A015"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q008.C003xSPEXQ008A016"
                  />
                  <ChartRowElement type="input" name="Q008.C003xSPEXQ008A017" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSPEXQ009A001"
                    label="heart sounds nml"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSPEXQ009A002"
                    label="tachycardia"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSPEXQ009A003"
                    label="bradycardia"
                  />
                  <ChartRowElement type="input" name="Q009.C003xSPEXQ009A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q009.C003xSPEXQ009A005"
                    label="reg. rate & rhythm"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="input" name="Q009.C003xSPEXQ009A006" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A001"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q010.C003xSPEXQ010A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A004"
                    label="no organomegaly"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A005"
                    label="tenderness:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A006"
                    label="generalized"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A007"
                    label="RUQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A008"
                    label="LUQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A009"
                    label="RLQ"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A010"
                    label="LLQ"
                  />
                  <ChartRowElement type="input" name="Q010.C003xSPEXQ010A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A012"
                    label="ecchymosis"
                  />
                  <ChartRowElement type="input" name="Q010.C003xSPEXQ010A013" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C003xSPEXQ010A014"
                    label="catheter present"
                  />
                  <ChartRowElement type="input" name="Q010.C003xSPEXQ010A015" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="SKIN"
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A001"
                    label="intact, nml palp."
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A002"
                    label="crepitus"
                  />
                  <ChartRowElement type="input" name="Q011.C003xSPEXQ011A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A004"
                    label="pressure ulcer"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A011"
                    label="location:"
                  />
                  <ChartRowElement type="input" name="Q011.C003xSPEXQ011A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A012"
                    label="depth / stage:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A006"
                    label="1"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A007"
                    label="2"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A008"
                    label="3"
                    width="2.5rem"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C003xSPEXQ011A009"
                    label="4"
                    width="2.5rem"
                  />
                  <ChartRowElement type="input" name="Q011.C003xSPEXQ011A010" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A001"
                    label="non-tender"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A002"
                    label="see diagram"
                  />
                  <ChartRowElement type="input" name="Q012.C003xSPEXQ012A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A004"
                    label="tenderness"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q012.C003xSPEXQ012A005"
                    label="ecchymosis"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q012.C003xSPEXQ012A006"
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q012.C003xSPEXQ012A007"
                  />
                  <ChartRowElement type="input" name="Q012.C003xSPEXQ012A008" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T03PhysicalExam };
