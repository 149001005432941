import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { EncounterDiet } from "modules/in-process/types";
import { useQueryClient } from "react-query";
import { updateInProcessListItemCache } from "modules/in-process/utils";

function usePutEncounterDiet(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["putInProcessEncounterDiet"],
    (payload: EncounterDiet) =>
      axiosPatientInstance.put<string>(
        `v1/InProcess/${encounterId}/diet`,
        payload
      ),
    {
      onSuccess: (_, { typeOfDiet, foodAllergies }) => {
        queryClient.invalidateQueries([
          "inProcessEncounterDiet",
          { encounterId },
        ]);
        updateInProcessListItemCache(queryClient, encounterId, {
          diet: typeOfDiet,
          hasFoodAllergies: foodAllergies.length > 0,
        });
      },
    }
  );
}

export { usePutEncounterDiet };
