import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";

type UsersNeedToResetPasswordPayload = string[];

function useUsersNeedToResetPassword() {
  return useAxiosMutation(
    ["usersNeedToResetPasswordPayload"],
    (userIds: UsersNeedToResetPasswordPayload) =>
      axiosIdentityInstance.put(`/v1/user/reset-password`, userIds)
  );
}

export { useUsersNeedToResetPassword };
