import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type AverageWaitTimeResponse = {
  time: string;
};

function useAverageWaitTime() {
  return useAxiosQuery(
    ["averageTime"],
    () =>
      axiosPatientInstance.get<AverageWaitTimeResponse>(
        "/v1/Reception/wait-time"
      ),
    {
      refetchInterval: 300000,
    }
  );
}

export { useAverageWaitTime };
