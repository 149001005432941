import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import faker from "faker";
import { OrderElementResponse } from "modules/order-list/types";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Label, Select } from "shared";
import { getOptions } from "utils/enum-mapper";
import { UseOrderFormFavoritesResponse } from "../api/queries/useOrderFormFavorites";
import { OrderItem } from "../types";
import { OrderFormElementRoute } from "enums";

type AddOrderItemModalProps = {
  item: OrderElementResponse | null;
  onAddItem: (item: OrderItem) => void;
  favorite?: UseOrderFormFavoritesResponse;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type AddOrderItemModalBodyProps = Omit<
  AddOrderItemModalProps,
  "isOpen" | "item"
> & {
  item: OrderElementResponse;
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function AddOrderItemModalBody({
  item,
  onClose,
  onAddItem,
}: AddOrderItemModalBodyProps) {
  const [notes, setNotes] = useState("");
  const [locations, setLocations] = useState<string[]>([]);
  const [route, setRoute] = useState<OrderFormElementRoute>();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAddItem = () => {
    onAddItem({
      id: faker.datatype.uuid(),
      description: item.description ?? "",
      orderElementType: item.orderFormElementType || "Lab",
      orderElementCategory: item.orderFormElementCategory,
      orderElementRoute: route,
      location: locations.join(", "),
      date: new Date(),
      notes,
      cptCode: item.cptCode,
      studyTypeId: item.examID,
      instructions: item.instructions,
    });
    onClose();
  };

  useEffect(() => {
    if (textareaRef.current && textareaRef.current.scrollHeight > 40) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight * 1.05
      }px`;
    }
  }, [item]);

  return (
    <>
      <ModalBody
        display="flex"
        flexDirection="column"
        px="20px"
        py="20px"
        maxW="inherit"
      >
        <Box flex={1}>
          {item.orderFormElementType === "Radiology" && (
            <>
              <Label {...fieldLabelStyle}>Type *</Label>
              <InputGroup>
                <Input readOnly value={item?.orderFormElementCategory ?? ""} />
                <InputRightElement>
                  <Icon
                    as={EmrLock}
                    w="32px"
                    h="32px"
                    size="lg"
                    color="gray.450"
                  />
                </InputRightElement>
              </InputGroup>
            </>
          )}

          <Label {...fieldLabelStyle}>
            {item.orderFormElementType === "Medication"
              ? "Medication Name"
              : "Description"}{" "}
            *
          </Label>
          <InputGroup>
            <Textarea
              readOnly
              value={item?.description}
              resize="none"
              height="auto"
              rows={1}
              ref={textareaRef}
            />
            <InputRightElement>
              <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
            </InputRightElement>
          </InputGroup>
          {item?.orderFormElementType !== "Lab" &&
            !!item?.orderFormSubElements?.length && (
              <>
                <Label {...fieldLabelStyle}>
                  {item.orderFormElementType === "Medication"
                    ? "Dosages"
                    : "Locations"}{" "}
                  (Choose at least one) *
                </Label>
                <Card variant="basic" py="8px">
                  <CheckboxGroup
                    value={locations}
                    onChange={(value: string[]) => setLocations(value)}
                  >
                    <Stack>
                      {item.orderFormSubElements.map((sub, i, arr) => (
                        <Fragment key={sub.id}>
                          <Checkbox value={sub.description} px="16px" py="4px">
                            {sub.description}
                          </Checkbox>
                          {i < arr.length - 1 && (
                            <Divider width="100%" h="0.5px" />
                          )}
                        </Fragment>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </Card>
              </>
            )}
          {item?.orderFormElementType === "Medication" && (
            <>
              <Label {...fieldLabelStyle}>Route *</Label>
              <Select
                items={getOptions("orderFormElementRoute")}
                labelAccessor="label"
                valueAccessor="value"
                showBlank={false}
                onSelectedItemChange={(changes) =>
                  setRoute(changes.selectedItem?.value)
                }
              />
            </>
          )}
          <Label {...fieldLabelStyle}>Notes</Label>
          <Textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            resize="none"
            maxH="70px"
            minH="70px"
          />
        </Box>
      </ModalBody>
      <ModalFooter
        justifyContent="space-around"
        bgColor="white"
        borderBottomRadius="md"
        px={2}
      >
        <Button
          variant="outlineSquared"
          colorScheme="gray"
          onClick={onClose}
          width="240px"
        >
          Cancel
        </Button>
        <Button
          variant="outlineSquared"
          bgColor="blue"
          color="white"
          onClick={handleAddItem}
          width="240px"
        >
          Add to Queue
        </Button>
      </ModalFooter>
    </>
  );
}

function AddOrderItemModal({
  isOpen,
  onClose,
  item,
  onAddItem,
  favorite,
}: AddOrderItemModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        w="540px"
        maxWidth="540px"
        height={item?.orderFormElementType === "Radiology" ? "510px" : "380px"}
        bg="gray.200"
      >
        <ModalHeader textAlign="center" fontSize="17pt">
          {item?.orderFormElementType} Order
        </ModalHeader>
        {isOpen && item && (
          <AddOrderItemModalBody
            onAddItem={onAddItem}
            onClose={onClose}
            item={item}
            favorite={favorite}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { AddOrderItemModal };
