import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Write = createIcon({
  path: (
    <path
      d="M18.3078 4.1424L15.9582 1.80496L17.2783 0.509065C17.9202 -0.120713 18.6469 -0.181268 19.2403 0.412176L19.7126 0.88451C20.3061 1.49007 20.2698 2.20462 19.6158 2.85862L18.3078 4.1424ZM3.53219 18.8937L0.613409 20.008C0.262186 20.1412 -0.113259 19.7536 0.0320748 19.4024L1.20685 16.5563L14.8198 2.95551L17.1451 5.28085L3.53219 18.8937ZM4.88863 20.0201L7.27453 17.6463H22.8131C23.4671 17.6463 24 18.1792 24 18.8332C24 19.4872 23.4671 20.0201 22.8131 20.0201H4.88863Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 20",
});

export { Write };
