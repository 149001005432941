import { TooltipProps, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

function Tooltip({
  children,
  ...tooltipProps
}: PropsWithChildren & TooltipProps) {
  return (
    <ChakraTooltip
      backgroundColor="gray.200"
      color="gray.650"
      hasArrow
      rounded="lg"
      placement="top"
      {...tooltipProps}
    >
      {children}
    </ChakraTooltip>
  );
}

export { Tooltip };
