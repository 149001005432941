import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { AuditStatus } from "modules/audits/types";

type UseAuditStatusResponse = {
  id: string;
  demographicAuditStatus?: AuditStatus;
  providerChartAuditStatus?: AuditStatus;
  nurseChartAuditStatus?: AuditStatus;
};

function useAuditStatus(encounterId: string) {
  return useAxiosQuery(
    ["audits-status", { encounterId }],
    () =>
      axiosPatientInstance.get<UseAuditStatusResponse>(
        `/v1/audits/${encounterId}/status`
      ),
    {}
  );
}

export { useAuditStatus };
