import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateNewEncounterResponse = {
  encounterId: string;
};

function useCreateNewEncounter(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createNewEncounter", { encounterId }],
    () =>
      axiosPatientInstance.post<UseCreateNewEncounterResponse>(
        `v1/Encounter/${encounterId}/new-encounter`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounters", { encounterId }]);
      },
    }
  );
}

export type { UseCreateNewEncounterResponse };
export { useCreateNewEncounter };
