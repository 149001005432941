import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { TriageChartCode } from "enums";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
} from "modules";
import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartProceduresForm } from "../../hooks";
import { proceduresConstants, ProceduresHeader } from "../../shared";

const CHART_CODE: TriageChartCode = "T001x";

function N01Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({ chartCode: CHART_CODE });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ProceduresHeader />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[0].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A001"
                    label="portable"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A002"
                    label="to Xray"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A041"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A003"
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A004"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A005"
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[0].T001xSPROQ001A006"
                    label="tech"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[1].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[1].T001xSPROQ001A007"
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[1].T001xSPROQ001A008"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[2].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[2].T001xSPROQ001A009"
                    label="to CT"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T001xSPROQ001A041"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T001xSPROQ001A010"
                    label="contrast"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[2].T001xSPROQ001A011"
                    label="parenteral meds"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[3].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[3].T001xSPROQ001A042"
                    label="w/"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T001xSPROQ001A012"
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T001xSPROQ001A013"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T001xSPROQ001A014"
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[3].T001xSPROQ001A015"
                    label="tech"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[4].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[4].T001xSPROQ001A016"
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[4].T001xSPROQ001A017"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[4] && sections[4].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[4] && sections[4].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[5].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[5].T001xSPROQ001A018"
                    label="lasceration repair"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T001xSPROQ001A041"
                    label="to:"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T001xSPROQ001A019"
                    label="scalp"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T001xSPROQ001A020"
                    label="face"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[5].T001xSPROQ001A021"
                    label="see additional notes"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[5] && sections[5].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[5] && sections[5].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[6].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[6].T001xSPROQ001A042"
                    label="assisted by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[6].T001xSPROQ001A022"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[6].T001xSPROQ001A023"
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[6].T001xSPROQ001A043"
                    label="site"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[6].T001xSPROQ001A024"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[7].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[7].T001xSPROQ001A025"
                    label="foreign body removed"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[7].T001xSPROQ001A026"
                    label="see additional notes"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[7] && sections[7].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[7] && sections[7].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[8].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[8].T001xSPROQ001A041"
                    label="assisted by"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[8].T001xSPROQ001A027"
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[8].T001xSPROQ001A028"
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[8].T001xSPROQ001A042"
                    label="site"
                  />
                  <ChartRowElement
                    type="input"
                    name="sections[8].T001xSPROQ001A029"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[8] && sections[8].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[8] && sections[8].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[9].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[9].T001xSPROQ001A030"
                    label="eye irrigation"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[9].T001xSPROQ001A031"
                    label="flourescein"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[9] && sections[9].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[9] && sections[9].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[10].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[10].T001xSPROQ001A032"
                    label="Morgan lens"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[10].T001xSPROQ001A033"
                    label="sol"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[10].T001xSPROQ001A034"
                    label="amt"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[10] && sections[10].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[10] && sections[10].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[11].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[11].T001xSPROQ001A035"
                    label="cleaned wound"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[11].T001xSPROQ001A036"
                    label="applied abx ointment"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[11] && sections[11].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[11] && sections[11].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS
                }
              >
                <ChartRowElement type="date" name="sections[12].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name="sections[12].T001xSPROQ001A041"
                    label="applied:"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T001xSPROQ001A037"
                    label="eye patch"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T001xSPROQ001A038"
                    label="dressing"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T001xSPROQ001A039"
                    label="Band-Aid"
                  />
                  <ChartRowElement
                    type="option"
                    name="sections[12].T001xSPROQ001A040"
                    label="elastic wrap"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[12] && sections[12].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[12] && sections[12].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N01Procedures };
