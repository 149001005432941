import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ComputerUnlocked = createIcon({
  path: (
    <path
      d="M2.05192 14.0338C0.774619 14.0338 0 13.2674 0 11.9819V2.05192C0 0.766378 0.774619 0 2.05192 0H17.9398C19.2254 0 20 0.766378 20 2.05192V11.9819C20 13.2674 19.2254 14.0338 17.9398 14.0338H12.6906V15.5253H12.7235C13.1356 15.5253 13.4734 15.8632 13.4734 16.2752C13.4734 16.679 13.1356 17.0169 12.7235 17.0169H7.27647C6.86444 17.0169 6.52658 16.679 6.52658 16.2752C6.52658 15.8632 6.86444 15.5253 7.27647 15.5253H7.30944V14.0338H2.05192ZM2.1508 12.4598H17.8492C18.2035 12.4598 18.4178 12.2373 18.4178 11.8912V2.14256C18.4178 1.79646 18.2035 1.57396 17.8492 1.57396H2.1508C1.79646 1.57396 1.5822 1.79646 1.5822 2.14256V11.8912C1.5822 12.2373 1.79646 12.4598 2.1508 12.4598ZM7.07046 9.11413V6.95509C7.07046 6.47713 7.30119 6.23815 7.75443 6.23815H9.86403V5.64483C9.86403 4.53234 10.5398 3.77421 11.5863 3.77421C12.6329 3.77421 13.3086 4.53234 13.3086 5.5789V6.11454C13.3086 6.35352 13.1108 6.5513 12.8801 6.5513C12.6329 6.5513 12.4433 6.35352 12.4433 6.11454V5.5789C12.4433 4.99382 12.1302 4.61475 11.5863 4.61475C11.0424 4.61475 10.7293 4.99382 10.7293 5.5789V6.24639C11.0836 6.29584 11.2567 6.53482 11.2567 6.95509V9.11413C11.2567 9.59209 11.0342 9.83107 10.581 9.83107H7.75443C7.30119 9.83107 7.07046 9.59209 7.07046 9.11413Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 18",
});

export { ComputerUnlocked };
