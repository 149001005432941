import { AuditsListItem } from "modules/audits/types";
import { useQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";
import { axiosPatientInstance } from "config";

type UseAuditsProps = PaginationPayload &
  Partial<{
    search: string;
    filter: "completed" | "incomplete";
    assignFilter: "All" | "AssignedToMe" | "Unassigned";
    patient: string;
    mrn: string;
    encounterNumber: string;
    encounterDate: string;
    provider: string;
    providerChartTimer?: Date;
    nurse: string;
    nurseChartTimer?: Date;
    demographicAuditor: string;
    demographicAuditorTimer?: Date;
    nurseChartAuditor: string;
    nurseChartAuditorTimer?: Date;
    providerChartAuditor: string;
    providerChartAuditorTimer?: Date;
  }>;

type UseAuditsResponse = PaginationResponse<AuditsListItem>;

function useAudits(props: UseAuditsProps) {
  const {
    sort,
    descending,
    page = 1,
    size = 10,
    search,
    filter,
    assignFilter,
    ...filters
  } = props;

  return useQuery(["auditsList", { search }], () =>
    axiosPatientInstance.get<UseAuditsResponse>(`v1/audits/${filter}`, {
      params: {
        page,
        pageSize: size,
        sortDesc: descending || false,
        orderBy: sort,
        search,
        filter,
        assignFilter,
        ...filters,
      },
    })
  );
}

export type { UseAuditsResponse };
export { useAudits };
