const sectionCode = "SPRO2";
const chartCode = "C016x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T16Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T16Procedures };
