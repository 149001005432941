import {
  Box,
  chakra,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrPlus } from "@medstonetech/slate-icons";
import { useFileUrl } from "hooks";
import { DeleteOutline, Collapse } from "icons";
import * as React from "react";
import { isImage } from "utils";

type ImageModalProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  value: File | Blob | null;
  title: string;
  onChange: (file: File | Blob | null) => void;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

function ImageModal(props: ImageModalProps) {
  const { isOpen, onClose, title, isDisabled, isLoading, value, onChange } =
    props;

  const [file, setFile] = React.useState<File | Blob | null>(value);
  const fileUrl = useFileUrl(file);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const mimeType = file?.type;
  const showImage = mimeType && isImage(mimeType) && !isLoading;
  React.useEffect(() => setFile(value), [value]);

  const onCloseModal = () => {
    onClose();
    if (value !== file) onChange(file);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent
        marginTop={0}
        marginBottom={0}
        padding={0}
        width="765px"
        maxWidth="765px"
        height="1040px"
      >
        <chakra.input
          ref={inputRef}
          onChange={(e) => {
            const currentFile = e.target.files?.item(0);

            if (currentFile && isImage(currentFile?.type)) {
              setFile(currentFile);
            }
          }}
          type="file"
          accept="image/*, application/pdf"
          display="none"
          key={fileUrl}
          disabled={isDisabled}
        />
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          bg="gray.50"
          padding="16px 20px 20px 20px"
          border="1px"
          borderColor="gray.450"
          borderRadius="10px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="16px"
          >
            <IconButton
              aria-label="expand"
              icon={<Collapse />}
              variant="icon"
              color="blue"
              isDisabled={isLoading || !value}
              onClick={onCloseModal}
            />
            <Heading color="gray.650" fontSize="1rem" fontWeight="500">
              {title}
            </Heading>
            <HStack spacing="2rem">
              <IconButton
                aria-label="upload file"
                icon={<Icon as={EmrPlus} />}
                variant="icon"
                color="blue"
                onClick={() => inputRef.current?.click()}
                isDisabled={isLoading || isDisabled}
              />
              <IconButton
                aria-label="delete file"
                icon={<DeleteOutline />}
                variant="icon"
                color="blue"
                onClick={() => setFile(null)}
                isDisabled={isLoading || !value || isDisabled}
              />
            </HStack>
          </Box>
          <Box
            flex={1}
            bg="white"
            border="1px"
            color="gray.450"
            overflow="auto"
          >
            {showImage && (
              <chakra.img
                w="100%"
                h="100%"
                src={fileUrl}
                objectFit="scale-down"
              />
            )}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export type { ImageModalProps };
export { ImageModal };
