import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared";

const triageChartCode = "T013x";
const sectionId = "SASS";

function N13Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  const { watch } = useFormContext;

  if (getIsLoading) {
    return <Loading />;
  }

  const q001AllNormal = Boolean(
    watch(`Q001.${triageChartCode}${sectionId}Q001A023`)
  );
  const q002AllNormal = Boolean(
    watch(`Q002.${triageChartCode}${sectionId}Q002A019`)
  );
  const q003AllNormal = Boolean(
    watch(`Q003.${triageChartCode}${sectionId}Q003A018`)
  );
  const q004AllNormal = Boolean(
    watch(`Q004.${triageChartCode}${sectionId}Q004A001`)
  );
  const q005AllNormal = Boolean(
    watch(`Q005.${triageChartCode}${sectionId}Q005A012`)
  );
  const q006AllNormal = Boolean(
    watch(`Q006.${triageChartCode}${sectionId}Q006A020`)
  );
  const q007AllNormal = Boolean(
    watch(`Q007.${triageChartCode}${sectionId}Q007A007`)
  );
  const q008AllNormal = Boolean(
    watch(`Q008.${triageChartCode}${sectionId}Q008A015`)
  );
  const q009AllNormal = Boolean(
    watch(`Q009.${triageChartCode}${sectionId}Q009A012`)
  );
  const q011AllNormal = Boolean(
    watch(`Q011.${triageChartCode}${sectionId}Q011A001`)
  );

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              showAuditsBtn={false}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q001.T013xSASSQ001A023"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T013xSASSQ001A022"
                  label="Date/Time"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  type="date"
                  name="Q001.T013xSASSQ001A001"
                  width="20rem"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T013xSASSQ001A024"
                    label="appears nml"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T013xSASSQ001A025"
                    label="ill"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T013xSASSQ001A026"
                    label="uncomfortable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T013xSASSQ001A027"
                    label="crying"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T013xSASSQ001A028"
                    label="inconsolable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A029"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway patent"
                    name="Q001.T013xSASSQ001A002"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway compromised"
                    name="Q001.T013xSASSQ001A003"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A004"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="breathing unlabored"
                    name="Q001.T013xSASSQ001A005"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="labored breathing"
                    name="Q001.T013xSASSQ001A006"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="respiratory distress:"
                    name="Q001.T013xSASSQ001A007"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mild"
                    fontStyle="italic"
                    name="Q001.T013xSASSQ001A030"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="moderate"
                    fontStyle="italic"
                    name="Q001.T013xSASSQ001A031"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="severe"
                    fontStyle="italic"
                    name="Q001.T013xSASSQ001A032"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A008"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="circulation nml"
                    name="Q001.T013xSASSQ001A009"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q001.T013xSASSQ001A010"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q001.T013xSASSQ001A011"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A012"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="neuro awake & alert"
                    name="Q001.T013xSASSQ001A013"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="listless"
                    name="Q001.T013xSASSQ001A014"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="fussy"
                    name="Q001.T013xSASSQ001A018"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A016"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q001.T013xSASSQ001A033"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ001A034"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q002.T013xSASSQ002A019"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x 4"
                    name="Q002.T013xSASSQ002A001"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.T013xSASSQ002A018"
                    label="disoriented to"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q002.T013xSASSQ002A002"
                    fontStyle="italic"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q002.T013xSASSQ002A003"
                    fontStyle="italic"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    fontStyle="italic"
                    name="Q002.T013xSASSQ002A004"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q002.T013xSASSQ002A005"
                    fontStyle="italic"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSASSQ002A006"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.T013xSASSQ002A023"
                    label="weakness"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sensory loss"
                    name="Q002.T013xSASSQ002A024"
                    fontStyle="italic "
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSASSQ002A025"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="PERRL"
                    name="Q002.T013xSASSQ002A007"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pupils unequal"
                    name="Q002.T013xSASSQ002A008"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q002.T013xSASSQ002A011"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSCHCQ002A012"
                    defaultValue={0}
                    width="4rem"
                    textAlign="center"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="label" label="mm" fontStyle="italic" />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q002.T013xSASSQ002A009"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSCHCQ002A010"
                    defaultValue={0}
                    width="4rem"
                    textAlign="center"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="label" label="mm" fontStyle="italic" />
                  <ChartRowElement
                    type="input"
                    name="Q001.T013xSASSQ002A020"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="maintains eye contact"
                    name="Q002.T013xSASSQ002A013"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lack of eye contact"
                    name="Q002.T013xSASSQ002A014"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSCHCQ002A015"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q002.T013xSASSQ002A021"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T013xSCHCQ002A022"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="HEENT"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q003.T013xSASSQ003A018"
                >
                  all normal
                </ChartRowElement>
              }
            >
              HEENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="fontanel nml"
                    name="Q003.T013xSASSQ003A001"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="fontanel:"
                    name="Q003.T013xSASSQ003A021"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bulging"
                    fontStyle="italic"
                    name="Q003.T013xSASSQ003A002"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sunken"
                    fontStyle="italic"
                    name="Q003.T013xSASSQ003A003"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A004"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml eye inspection"
                    name="Q003.T013xSASSQ003A005"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale conjunctivae"
                    name="Q003.T013xSASSQ003A022"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="red cojunctivae"
                    name="Q003.T013xSASSQ003A006"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="exudate"
                    name="Q003.T013xSASSQ003A007"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A008"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="drainage:"
                    name="Q003.T013xSASSQ003A023"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="nasal"
                    fontStyle="italic"
                    name="Q003.T013xSASSQ003A011"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="ear"
                    fontStyle="italic"
                    name="Q003.T013xSASSQ003A012"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A013"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="drooling"
                    name="Q003.T013xSASSQ003A014"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A015"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="erythema"
                    name="Q003.T013xSASSQ003A024"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A025"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml mucous membranes"
                    name="Q003.T013xSASSQ003A026"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dry mucous membranes"
                    name="Q003.T013xSASSQ003A016"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A017"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q003.T013xSASSQ003A019"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T013xSASSQ003A020"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Neck"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q011.T013xSASSQ011A001"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="thyromegaly"
                    name="Q011.T013xSASSQ011A002"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="lymphadenopathy"
                    name="Q011.T013xSASSQ011A003"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T013xSASSQ011A004"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q011.T013xSASSQ011A005"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T013xSASSQ011A006"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Chest"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q004.T013xSASSQ004A001"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml breath sounds"
                    name="Q004.T013xSASSQ004A002"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="decreased breath sounds"
                    name="Q004.T013xSASSQ004A003"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="tachypnea"
                    name="Q004.T013xSASSQ004A004"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="wheezing"
                    name="Q004.T013xSASSQ004A005"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rales"
                    name="Q004.T013xSASSQ004A006"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rhonchi"
                    name="Q004.T013xSASSQ004A007"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="stridor"
                    name="Q004.T013xSASSQ004A008"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="crackles"
                    name="Q004.T013xSASSQ004A009"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="grunting"
                    name="Q004.T013xSASSQ004A010"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T013xSASSQ004A011"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="accessory muscle use"
                    name="Q004.T013xSASSQ004A012"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="retractions"
                    name="Q004.T013xSASSQ004A013"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="splinting"
                    name="Q004.T013xSASSQ004A014"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="nasal flaring"
                    name="Q004.T013xSASSQ004A015"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="orthopnea"
                    name="Q004.T013xSASSQ004A016"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="exertional SOB"
                    name="Q004.T013xSASSQ004A017"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="tripod"
                    name="Q004.T013xSASSQ004A018"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="choking"
                    name="Q004.T013xSASSQ004A019"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T013xSASSQ004A020"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="chest non-tender"
                    name="Q004.T013xSASSQ004A021"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="chest wall tenderness"
                    name="Q004.T013xSASSQ004A022"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T013xSASSQ004A023"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q004.T013xSASSQ004A024"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T013xSASSQ004A025"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="CVS"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q005.T013xSASSQ005A012"
                >
                  all normal
                </ChartRowElement>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="regular rate"
                    name="Q005.T013xSASSQ005A001"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tachycardia"
                    name="Q005.T013xSASSQ005A002"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bradycardia"
                    name="Q005.T013xSASSQ005A003"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A004"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cap refill greater than 2 sec"
                    name="Q005.T013xSASSQ005A009"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cap refill greater than 2 sec"
                    name="Q005.T013xSASSQ005A010"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A011"
                    w="66px"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="label"
                    label="sec"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A013"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulses strong / equal"
                    name="Q005.T013xSASSQ005A005"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulse deficit"
                    name="Q005.T013xSASSQ005A007"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A008"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="skin warm & dry"
                    name="Q005.T013xSASSQ005A014"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q005.T013xSASSQ005A015"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q005.T013xSASSQ005A016"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mottled"
                    name="Q005.T013xSASSQ005A017"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q005.T013xSASSQ005A018"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diphoretic"
                    name="Q005.T013xSASSQ005A019"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A020"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q005.T013xSASSQ005A021"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T013xSASSQ005A022"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Abdomen / GU"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q006.T013xSASSQ006A020"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="soft / non-tender"
                    name="Q006.T013xSASSQ006A006"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q006.T013xSASSQ006A002"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="guarding"
                    name="Q006.T013xSASSQ006A003"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rebound"
                    name="Q006.T013xSASSQ006A004"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="firm"
                    name="Q006.T013xSASSQ006A007"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="distended"
                    name="Q006.T013xSASSQ006A008"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A009"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="bowel sounds nml"
                    name="Q006.T013xSASSQ006A010"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="bowel sounds"
                    name="Q006.T013xSASSQ006A011"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hyper"
                    name="Q006.T013xSASSQ006A012"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hypo"
                    name="Q006.T013xSASSQ006A013"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="absent"
                    name="Q006.T013xSASSQ006A014"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A015"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="decreased:"
                    name="Q006.T013xSASSQ006A021"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="intake"
                    name="Q006.T013xSASSQ006A022"
                    isDisabled={q006AllNormal}
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="urine output"
                    name="Q006.T013xSASSQ006A023"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A024"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no organomegaly"
                    name="Q006.T013xSASSQ006A025"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="hepatomegaly"
                    name="Q006.T013xSASSQ006A026"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="splenomegaly"
                    name="Q006.T013xSASSQ006A027"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mass"
                    name="Q006.T013xSASSQ006A028"
                    fontStyle="italic"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A029"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="hernia"
                    name="Q006.T013xSASSQ006A030"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A031"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="emesis"
                    name="Q006.T013xSASSQ006A032"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bruit"
                    name="Q006.T013xSASSQ006A033"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A034"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="catheter present"
                    name="Q006.T013xSASSQ006A016"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A017"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q006.T013xSASSQ006A018"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T013xSASSQ006A019"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Extremities"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q007.T013xSASSQ007A007"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q007.T013xSASSQ007A001"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q007.T013xSASSQ007A002"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="calf tenderness"
                    name="Q007.T013xSASSQ007A010"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="pedal edema"
                    name="Q007.T013xSASSQ007A011"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="edema"
                    name="Q007.T013xSASSQ007A012"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q007.T013xSASSQ007A013"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="joint pain"
                    name="Q007.T013xSASSQ007A014"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T013xSASSQ007A015"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="moves all extremities"
                    name="Q007.T013xSASSQ007A004"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q007.T013xSASSQ007A005"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="contractures"
                    name="Q007.T013xSASSQ007A016"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="limping gait"
                    name="Q007.T013xSASSQ007A017"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="pain with weight bearing"
                    name="Q007.T013xSASSQ007A018"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="unable to bear weight"
                    name="Q007.T013xSASSQ007A019"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="weakness"
                    name="Q007.T013xSASSQ007A020"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="numbness"
                    name="Q007.T013xSASSQ007A021"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T013xSASSQ007A006"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q007.T013xSASSQ007A008"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T013xSASSQ007A009"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Skin"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q008.T013xSASSQ008A015"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="warm /dry"
                    name="Q008.T013xSASSQ008A001"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q008.T013xSASSQ008A003"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q008.T013xSASSQ008A004"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="flushed"
                    name="Q008.T013xSASSQ008A022"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mottled"
                    name="Q008.T013xSASSQ008A023"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q008.T013xSASSQ008A024"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="increased warmth"
                    name="Q008.T013xSASSQ008A025"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A005"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact"
                    name="Q008.T013xSASSQ008A006"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcers"
                    name="Q008.T013xSASSQ008A016"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sacrum"
                    name="Q008.T013xSASSQ008A017"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hip"
                    name="Q008.T013xSASSQ008A018"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="heel"
                    name="Q008.T013xSASSQ008A019"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A009"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="embolic lesions"
                    name="Q008.T013xSASSQ008A026"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A027"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no rash"
                    name="Q008.T013xSASSQ008A010"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="skin rash"
                    name="Q008.T013xSASSQ008A007"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaper rash"
                    name="Q008.T013xSASSQ008A008"
                    isDisabled={q008AllNormal}
                  />

                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A012"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="turgor good"
                    name="Q008.T013xSASSQ008A013"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="poor turgor"
                    name="Q008.T013xSASSQ008A011"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A014"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q008.T013xSASSQ008A020"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T013xSASSQ008A021"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q009.T013xSASSQ009A012"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="development age appropiate"
                    name="Q009.T013xSASSQ009A001"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="breast feed"
                    name="Q009.T013xSASSQ009A014"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bottle feed"
                    name="Q009.T013xSASSQ009A015"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A004"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appears nourished / hydrated"
                    name="Q009.T013xSASSQ009A005"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="obese"
                    name="Q009.T013xSASSQ009A002"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malnourished"
                    name="Q009.T013xSASSQ009A003"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dehydrated"
                    name="Q009.T013xSASSQ009A004"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A008"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight loss"
                    name="Q009.T013xSASSQ009A006"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight gain"
                    name="Q009.T013xSASSQ009A007"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A013"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml feeding / suck"
                    name="Q009.T013xSASSQ009A016"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="poor intake / suck"
                    name="Q009.T013xSASSQ009A017"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A018"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="decreased number of wet diapers"
                    name="Q009.T013xSASSQ009A019"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A020"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q009.T013xSASSQ009A009"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T013xSASSQ009A011"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q010.T013xSASSQ010A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N13Assessments };
