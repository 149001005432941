import { OrderContrastTimerStatus } from "enums";

const ORDER_CONTRAST_STATUS_COLOR: Record<OrderContrastTimerStatus, string> = {
  InProcess: "blue",
  Finished: "green",
  Paused: "yellow",
  NotStarted: "red",
};

export { ORDER_CONTRAST_STATUS_COLOR };
