import { Box, Flex, Text } from "@chakra-ui/react";
import { EmrCalendar } from "@medstonetech/slate-icons";
import { useChartTrackingContext } from "contexts";
import { ProviderChartCode } from "enums";
import { useEncounterOverview } from "modules/reception/api";
import * as React from "react";
import { MdChevronLeft } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "shared";
import { ProviderSidebar } from "../provider-sidebar";
import { format } from "date-fns";

type ProviderContainerProps = {
  providerChartCode: ProviderChartCode;
  children: React.ReactNode;
  hiddentSections?: string[];
};

function ProviderContainer(props: ProviderContainerProps) {
  const { encounterId = "" } = useParams();
  const { providerChartCode, hiddentSections, children } = props;
  const { isPastEncounter, setIsPastEncounter } = useChartTrackingContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [fromRoute, setFromRoute] = React.useState<string>("");

  const { data, isLoading } = useEncounterOverview(encounterId, {
    enabled: isPastEncounter,
  });

  React.useEffect(() => {
    if (location.state) {
      const locationState = location.state as {
        fromRoute?: string;
        isPastEncounter: boolean;
      };
      setFromRoute(locationState.fromRoute ?? "");
      setIsPastEncounter(locationState.isPastEncounter ?? false);
    }
  }, [location, setIsPastEncounter]);

  const handleBack = () => {
    setIsPastEncounter(false);
    setFromRoute("");
    navigate(fromRoute, { replace: true });
  };

  return (
    <Box>
      {isPastEncounter && (
        <Flex height="80px" backgroundColor="red" px="1rem">
          <Flex alignItems="center" justify="flex-start" flex={1}>
            <Button
              variant="label"
              leftIcon={<MdChevronLeft />}
              color="white"
              onClick={handleBack}
            >
              Back
            </Button>
          </Flex>
          <Flex
            alignItems="center"
            justify="space-around"
            flex={2}
            columnGap="3.75rem"
          >
            <Flex alignItems="center" columnGap="0.75rem">
              <EmrCalendar color="white" />
              <Text color="white">
                {!isLoading &&
                  format(
                    data?.data?.date ? new Date(data?.data.date) : new Date(),
                    "LL/dd/yyyy\tp"
                  )}
              </Text>
            </Flex>
            <Text color="white">Enc# {data?.data?.encounterNumber}</Text>
            <Text color="white">
              This chart is from a past encounter and is not editable.
            </Text>
          </Flex>
          <Box flex={1} />
        </Flex>
      )}
      <Box display="flex">
        <ProviderSidebar
          providerChartCode={providerChartCode}
          hiddentSections={hiddentSections}
        />
        <Box height="100vh" width="100%" overflow="overlay" bg="gray.50">
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export { ProviderContainer };
