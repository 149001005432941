import { axiosSLPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { Facility } from "modules/stp-vip/types";
import { useQueryClient } from "react-query";

type UseAssignStpHomePayload = {
  home: Facility;
};

function useAssignStpHome(patientId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["assignHome", { patientId }],
    (input: UseAssignStpHomePayload) =>
      axiosSLPatientInstance.put(`v1/patient/assign-home/${patientId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stpVipPatientById", { patientId }]);
        queryClient.invalidateQueries(["stpVipPatients"]);
      },
    }
  );
}

export type { UseAssignStpHomePayload };
export { useAssignStpHome };
