import { anatomy, PartsStyleFunction } from "@chakra-ui/theme-tools";

const parts = [
  "root",
  "container",
  "content",
  "label",
  "labelicon",
  "indicator",
];

const navigationButtonAnatomy = anatomy("navigation-button").parts(...parts);

const baseStyle: PartsStyleFunction<typeof navigationButtonAnatomy> = () => {
  const root = {
    borderRadius: "10px",
    transition: "all 200ms ease-in-out",
    outline: "none",
    border: "none",
    display: "block",
    _focus: {
      boxShadow: "var(--chakra-shadows-outline)",
    },
    _hover: {
      cursor: "pointer",
      textDecoration: "none",
    },
  };
  const container = {
    borderRadius: "10px",
    height: "44px",
    backgroundColor: "transparent",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    transition: "all 200ms ease-in-out",
    alignItems: "center",
    padding: "11px",
    fontWeight: 500,
    fontSize: "17px",
  };
  const content = {
    display: "flex",
    alignItems: "center",
  };
  const label = {
    color: "black",
    fontSize: "1.0625rem",
    fontWeight: "500",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };
  const labelicon = {
    marginRight: "15px",
    fontSize: "1.5rem",
  };
  const indicator = {
    color: "gray.700",
    _first: {
      color: "gray.700",
    },
  };

  return {
    root,
    container: {
      ...container,
      _selected: {
        bg: "gray.350",
      },
    },
    content,
    label,
    labelicon,
    indicator,
  };
};

const chartVariant: PartsStyleFunction<typeof navigationButtonAnatomy> = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  colorScheme,
}) => {
  return {
    container: {
      _selected: {
        bg: colorScheme || "blue",
      },
    },
    labelicon: {
      _selected: {
        color: "white",
      },
    },
    label: {
      color: "gray.700",
      fontSize: "0.75rem",
      _selected: {
        color: "white",
      },
    },
  };
};

const variants = {
  chart: chartVariant,
};

const navigationButtonTheme = {
  parts,
  baseStyle,
  variants,
};

export { navigationButtonTheme };
