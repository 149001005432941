import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteSharedChartNote(chartNoteId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteSharedChartNote", { chartNoteId }],
    () => axiosChartInstance.delete(`v1/ChartNote/${chartNoteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sharedChartNotes"]);
        queryClient.invalidateQueries(["sectionNotesCount"]);
        queryClient.invalidateQueries(["subSectionNotesCount"]);
      },
    }
  );
}

export { useDeleteSharedChartNote };
