import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartApplicationStatus } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseUpdateChartApplicationStatus = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
};

function useUpdateChartApplicationStatus(
  params: UseUpdateChartApplicationStatus
) {
  const { encounterId, chartCode, sectionId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["UpdateChartApplicationStatus", { encounterId, chartCode }],
    (status: ChartApplicationStatus) =>
      axiosChartInstance.put(
        `v1/chart-application/${encounterId}/${chartCode}/change-status`,
        {
          status,
        }
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "chartDisposition",
          {
            encounterId,
            chartCode,
            sectionId,
          },
        ]),
    }
  );
}

export type { UseUpdateChartApplicationStatus };
export { useUpdateChartApplicationStatus };
