import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { AuditComment } from "modules/charts-shared/types/audit-comments";

type UseAuditComments = {
  encounterId: string;
  chartCode: string;
  sectionCode: string;
  subsectionCode: string;
};

type UseAuditCommentsShared = {
  encounterId: string;
  sectionCode: string;
  subsectionCode: string;
};

function useAuditComments(props: UseAuditComments) {
  const { chartCode, sectionCode, encounterId, subsectionCode } = props;
  return useAxiosQuery(
    ["AuditComments", { chartCode, sectionCode, encounterId, subsectionCode }],
    () =>
      axiosChartInstance.get<AuditComment[]>(
        `/v1/audits/subsection/comments/${encounterId}/${chartCode}/${sectionCode}/${subsectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

function useAuditCommentsShared(props: UseAuditCommentsShared) {
  const { sectionCode, encounterId, subsectionCode } = props;
  return useAxiosQuery(
    ["AuditCommentsShared", { sectionCode, encounterId, subsectionCode }],
    () =>
      axiosChartInstance.get<AuditComment[]>(
        `/v1/audits/subsection/comments/${encounterId}/shared/${sectionCode}/${subsectionCode}`,
        {}
      ),
    { staleTime: 1 * 30 * 1000 }
  );
}

export { useAuditComments, useAuditCommentsShared };
