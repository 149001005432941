import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const CalendarDayTimelineLeft = createIcon({
  path: (
    <path
      d="M1.13998 2.00853C0.589376 2.00853 0.139589 1.55874 0.139589 1.00814C0.139589 0.449787 0.589376 0 1.13998 0C1.69833 0 2.14036 0.449787 2.14036 1.00814C2.14036 1.55874 1.69833 2.00853 1.13998 2.00853ZM3.42769 5.56805V2.64444C3.42769 0.946103 4.33501 0.0387747 6.05661 0.0387747H16.7817C18.4955 0.0387747 19.4029 0.946103 19.4029 2.64444V5.56805H17.6813V2.72974C17.6813 2.07832 17.3478 1.76813 16.7274 1.76813H6.1109C5.49826 1.76813 5.15704 2.07832 5.15704 2.72974V5.56805H3.42769ZM1.13998 5.17255C0.589376 5.17255 0.139589 4.72276 0.139589 4.1644C0.139589 3.6138 0.589376 3.16402 1.13998 3.16402C1.69833 3.16402 2.14036 3.6138 2.14036 4.1644C2.14036 4.72276 1.69833 5.17255 1.13998 5.17255ZM20 7.35169C20 7.68515 19.7441 7.94106 19.4029 7.94106H2.07833C1.88445 8.25902 1.52772 8.4684 1.13222 8.4684C0.511826 8.4684 0 7.96433 0 7.34393C0 6.72354 0.511826 6.21946 1.13222 6.21946C1.52772 6.21946 1.89221 6.42885 2.08608 6.75456H19.4029C19.7441 6.75456 20 7.01047 20 7.35169ZM5.15704 9.12757V11.9736C5.15704 12.6328 5.49826 12.943 6.1109 12.943H16.7274C17.3478 12.943 17.6813 12.6328 17.6813 11.9736V9.12757H19.4029V12.0667C19.4029 13.765 18.4955 14.6646 16.7817 14.6646H6.05661C4.33501 14.6646 3.42769 13.765 3.42769 12.0667V9.12757H5.15704ZM1.13998 11.5083C0.589376 11.5083 0.139589 11.0585 0.139589 10.5002C0.139589 9.94959 0.589376 9.49981 1.13998 9.49981C1.69833 9.49981 2.14036 9.94959 2.14036 10.5002C2.14036 11.0585 1.69833 11.5083 1.13998 11.5083ZM1.13998 14.6646C0.589376 14.6646 0.139589 14.2226 0.139589 13.6642C0.139589 13.1059 0.589376 12.6638 1.13998 12.6638C1.69833 12.6638 2.14036 13.1059 2.14036 13.6642C2.14036 14.2226 1.69833 14.6646 1.13998 14.6646Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 15",
});

export { CalendarDayTimelineLeft };
