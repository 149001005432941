import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type LinkChartToOrderPayload = {
  chartApplicationId: string;
};

type useLinkChartToOrderProps = {
  orderId: string;
  encounterId: string;
};

function useLinkChartToOrder({
  orderId,
  encounterId,
}: useLinkChartToOrderProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["linkChartToOrder", { orderId }],
    (payload: LinkChartToOrderPayload) =>
      axiosOrderInstance.post(
        `v1/order-history/link-chart/${orderId}`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
      },
    }
  );
}

export { useLinkChartToOrder };
