import {
  Box,
  Icon,
  Modal,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { SiChevronLeft } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { Car } from "icons";
import { genericErrors } from "messages";
import {
  useDeleteMOTFile,
  useEncounterMOTFile,
  useUploadMOTFile,
} from "modules";
import { useState, useEffect } from "react";
import {
  Button,
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageController,
  PdfViewerPreview,
} from "shared";
import { extractApiErrorMessage } from "utils";
import { AdditionalDocumentModal } from "../summary";

type FilePreviewProps = {
  file: File | string | Blob;
  isOpen: boolean;
  onClose: () => void;
  encounterId: string;
};

function FilePreview({ file, isOpen, onClose, encounterId }: FilePreviewProps) {
  const toast = useToast();

  const { mutateAsync: deleteMot, isLoading: isDeleteLoading } =
    useDeleteMOTFile(encounterId);
  const { mutateAsync: upload, isLoading: isUploadLoading } =
    useUploadMOTFile(encounterId);

  const handleUpload = async (newFile: Nullable<string | File | Blob>) => {
    try {
      if (newFile instanceof File) {
        const formData = new FormData();
        formData.append("file", newFile);
        await upload(formData);
      }
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMot({});
      onClose();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent minW="1570px" maxH="90%">
        <PdfViewer value={file} onDelete={handleDelete} onChange={handleUpload}>
          <PdfViewerContainer
            contentContainerProps={{ maxH: "92vh" }}
            header={
              <PdfViewerHeader
                title="MOT"
                leftElements={[
                  <Button
                    key="backButton"
                    size="iconSm"
                    variant="ghost"
                    color="blue"
                    onClick={onClose}
                    leftIcon={<Icon as={SiChevronLeft} />}
                  >
                    Back
                  </Button>,
                ]}
                rightElements={[
                  <PdfViewerActions
                    key="viewer-actions"
                    showDelete
                    showSubmit
                    isDeleteLoading={isDeleteLoading}
                    isSubmitLoading={isUploadLoading}
                  />,
                ]}
              />
            }
            activePage={<PdfViewerActivePage />}
            preview={<PdfViewerPreview />}
            pageController={<PdfViewerPageController />}
          />
        </PdfViewer>
      </ModalContent>
    </Modal>
  );
}

type MotButtonProps = {
  encounterId: string;
};

function MotButton({ encounterId }: MotButtonProps) {
  const toast = useToast();
  const disclosure = useDisclosure();
  const [file, setFile] = useState<Nullable<File | string | Blob>>();

  const {
    data,
    isLoading,
    isFetching,
    error: fileError,
  } = useEncounterMOTFile(encounterId);
  const { mutateAsync: upload, isLoading: isUploadLoading } =
    useUploadMOTFile(encounterId);

  const handleUpload = async (newFile: Nullable<string | File | Blob>) => {
    try {
      if (newFile instanceof File) {
        const formData = new FormData();
        formData.append("file", newFile);
        await upload(formData);
        disclosure.onClose();
      }
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  useEffect(() => {
    if (data?.data) {
      setFile(data?.data);
    }

    if (fileError) {
      setFile(null);
    }
  }, [data, fileError]);

  return (
    <Box>
      <Button
        zIndex={1000}
        h="30px"
        minW="80px"
        borderRadius="10px"
        colorScheme={file ? "green" : "red"}
        bgColor={file ? "green" : "red"}
        fontSize="15px"
        fontWeight="600"
        isLoading={isLoading || isFetching || isUploadLoading}
        isDisabled={isLoading || isFetching || isUploadLoading}
        onClick={(e) => {
          disclosure.onOpen();
          e.stopPropagation();
        }}
        display="flex"
        alignItems="center"
      >
        <Icon as={Car} fontSize="24px" />
        MOT
      </Button>
      {!file && (
        <AdditionalDocumentModal {...disclosure} onChangeFile={handleUpload} />
      )}
      {file && (
        <FilePreview {...disclosure} file={file} encounterId={encounterId} />
      )}
    </Box>
  );
}

export { MotButton };
