import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUploadOrderFile(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["uploadOrderFile", { orderId }],
    (file: FormData) =>
      axiosOrderInstance.post(`v1/file/${orderId}/upload`, file),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrderFile", { orderId }]);
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderContrast", { orderId }]);
      },
    }
  );
}

export { useUploadOrderFile };
