import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUploadMOTFile(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["uploadMOTFile", { encounterId }],
    (input: FormData) =>
      axiosPatientInstance.post<string>(
        `v1/encounter/${encounterId}/form/files/mot`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["MOTFile", { encounterId }]);
      },
    }
  );
}

export { useUploadMOTFile };
