import Konva from "konva";
import * as React from "react";
import { KonvaNodeEvents, Line } from "react-konva";
import { LineConfig } from "./types";
import { WithTransformer, WithTransformerProps } from "./WithTransformer";

type TransformerCurveLineProps = LineConfig &
  KonvaNodeEvents & { isSelected: boolean } & Pick<
    WithTransformerProps,
    "dispatch"
  >;

function TransformerCurveLine(props: TransformerCurveLineProps) {
  const { isSelected, dispatch, ...lineProps } = props;
  const lineRef = React.useRef<Konva.Line>(null);

  const onTransformEnd = React.useCallback(() => {
    const node = lineRef.current;

    if (!node) {
      return;
    }

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    const rotation = node.rotation();
    const points = node.points();
    const id = node.id();

    dispatch({
      type: "EDIT_SHAPE",
      payload: {
        rotation,
        points: points.map((element, index) =>
          index % 2 === 0 ? element * scaleX : element * scaleY
        ),
        id,
      },
    });
  }, [dispatch]);

  return (
    <WithTransformer
      isSelected={isSelected}
      shapeRef={lineRef}
      dispatch={dispatch}
      onTransformEnd={onTransformEnd}
    >
      <Line ref={lineRef} {...lineProps} />
    </WithTransformer>
  );
}

export type { TransformerCurveLineProps };
export { TransformerCurveLine };
