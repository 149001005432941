import { UseGetUserDetailsResponse } from "api";
import { axiosOrderInstance } from "config/axios";
import {
  LabOrderType,
  OrderFormElementCategory,
  OrderFormElementType,
  OrderStatus,
} from "enums";
import { useAxiosQuery } from "hooks";
import { Contrast } from "modules/orders/types";
import { Cancellation } from "modules/orders/types/cancellation";

type UseEncounterOrderResponse = {
  orderId: string;
  status: OrderStatus;
  type: LabOrderType;
  description: string;
  orderedAt: Date | string | null;
  orderedBy: UseGetUserDetailsResponse | null;
  approvedBy: UseGetUserDetailsResponse | null;
  cancelledBy: UseGetUserDetailsResponse | null;
  cancellationRequestedBy: UseGetUserDetailsResponse | null;
  cancellationRequestedAt: Date | string | null;
  approvalTime: Date | string | null;
  cancellationTime: Date | string | null;
  contrast?: Contrast;
  cancellation?: Cancellation;
  ramsoftFileId: string | null;
  fileId: string | null;
  elementType: OrderFormElementType;
  elementCategory?: OrderFormElementCategory;
  notes: string | null;
  cptCode: string | null;
  studyTypeId: string | null;
};

function useEncounterOrder(orderId: string) {
  return useAxiosQuery(["encounterOrder", { orderId }], () =>
    axiosOrderInstance.get<UseEncounterOrderResponse>(`v1/${orderId}/detail`)
  );
}

export type { UseEncounterOrderResponse };
export { useEncounterOrder };
