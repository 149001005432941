import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

function useDiagramsCounter(encounterId: string, chartCode: string) {
  return useAxiosQuery(["diagramsCounter", { encounterId, chartCode }], () =>
    axiosChartInstance.get<number>(
      `v1/diagram-chart/${encounterId}/${chartCode}/counter`
    )
  );
}

export { useDiagramsCounter };
