import * as React from "react";
import { useToast } from "hooks";
import { genericErrors } from "messages";
import { ChartNote } from "modules";
import { ChartNote as ChartNoteType } from "types";
import { extractApiErrorMessage } from "utils";
import {
  useDeleteOrderChartNote,
  useExportableOrderChartNote,
} from "modules/charts-shared/api";
import { OrderGroup } from "enums";

type OrderChartNoteProps = {
  note: ChartNoteType;
  encounterId: string;
  chartCode: string;
  orderGroup: OrderGroup;
};

function OrderChartNote(props: OrderChartNoteProps) {
  const { note, orderGroup, encounterId, chartCode } = props;
  const { id } = note;
  const toast = useToast();
  const { mutateAsync: deleteNote, isLoading } = useDeleteOrderChartNote({
    chartCode,
    encounterId,
    noteId: id,
    orderType: orderGroup,
  });
  const { mutateAsync: exportNote, isLoading: isExporting } =
    useExportableOrderChartNote({
      chartCode,
      encounterId,
      isExportable: !note.isExportable,
      orderType: orderGroup,
      chartOrderSectionNote: id,
    });

  const onDeleteChartNote = async () => {
    try {
      await deleteNote({});
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const onExportChartNote = async () => {
    try {
      await exportNote(!note.isExportable);
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <ChartNote
      isDeleting={isLoading}
      note={note}
      onDeleteChartNote={onDeleteChartNote}
      onExportChartNote={onExportChartNote}
      isExporting={isExporting}
    />
  );
}

export { OrderChartNote };
