type AccessorType<T> = keyof T | ((option: T) => string);

function applyAccessor<T>(elem: T, accessor: AccessorType<T>) {
  if (typeof accessor === "function") {
    return accessor(elem);
  }

  if (typeof elem === "string") {
    return elem;
  }

  return `${elem[accessor]}`;
}

export type { AccessorType };
export { applyAccessor };
