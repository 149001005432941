import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { axiosInstances, config } from "config";
import * as React from "react";
import { useQuery } from "react-query";

function useSetupAuth0() {
  const [authReady, setAuthReady] = React.useState(false);
  const { getAccessTokenSilently, logout } = useAuth0();
  const queryResult = useQuery(["auth0Token"], () => getAccessTokenSilently());
  const { data, error } = queryResult;

  // Set Authentication header with auth0 token
  React.useEffect(() => {
    // Setup global audio instance
    const { defaults: globalDefaults } = axios;
    const { headers: globalHeaders } = globalDefaults;
    globalDefaults.headers = {
      ...globalHeaders,
      Authorization: `Bearer ${data}`,
    };

    if (data) {
      Object.values(axiosInstances).forEach((instance) => {
        const { defaults } = instance;
        const { headers } = defaults;
        defaults.headers = { ...headers, Authorization: `Bearer ${data}` };
      });
      setAuthReady(true);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      logout({ returnTo: config.auth0LogoutUrl });
    }
  }, [error, logout]);

  return authReady;
}

export { useSetupAuth0 };
