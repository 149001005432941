const sectionCode = "SPRO2";
const chartCode = "C041x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T41Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T41Procedures };
