import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUpdateAuditCommentsCheck(
  encounterId: string,
  chartCode: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "UpdateAuditCommentsSubsectionCheck",
      { encounterId, chartCode, sectionCode, subsectionCode },
    ],
    () =>
      axiosChartInstance.post(
        `/v1/audits/subsection/check/${encounterId}/${chartCode}/${sectionCode}/${subsectionCode}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsSectionIndicator",
          { encounterId, chartCode, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSubsectionIndicator",
          { encounterId, chartCode, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

function useUpdateAuditCommentsSharedCheck(
  encounterId: string,
  sectionCode: string,
  subsectionCode: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    [
      "UpdateAuditCommentsSharedSubsectionCheck",
      { encounterId, sectionCode, subsectionCode },
    ],
    () =>
      axiosChartInstance.post(
        `/v1/audits/subsection/check/${encounterId}/shared/${sectionCode}/${subsectionCode}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "AuditCommentsSharedSectionIndicator",
          { encounterId, sectionCode },
        ]);
        queryClient.invalidateQueries([
          "AuditCommentsSharedSubsectionIndicator",
          { encounterId, sectionCode, subsectionCode },
        ]);
      },
    }
  );
}

export { useUpdateAuditCommentsCheck, useUpdateAuditCommentsSharedCheck };
