import { axiosOrderInstance } from "config";
import { useAxiosQuery } from "hooks";

type PendingOrderResponse = {
  encounterId: string;
  hasOrder: boolean;
};

function useGetEncounterPendingOrders(encounterId: string) {
  return useAxiosQuery(["encounterPendingOrders", { encounterId }], () =>
    axiosOrderInstance.get<PendingOrderResponse>(
      `v1/order-history/${encounterId}/pending`
    )
  );
}

export { useGetEncounterPendingOrders };
