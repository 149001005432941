import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DiagonalArrowOutline = createIcon({
  path: (
    <path
      d="M3.41119 20H16.5888C18.8702 20 20 18.8702 20 16.6323V3.36773C20 1.12982 18.8702 0 16.5888 0H3.41119C1.14068 0 0 1.11896 0 3.36773V16.6323C0 18.881 1.14068 20 3.41119 20ZM3.43292 18.251C2.34655 18.251 1.74905 17.6752 1.74905 16.5454V3.45464C1.74905 2.32482 2.34655 1.74905 3.43292 1.74905H16.5671C17.6426 1.74905 18.251 2.32482 18.251 3.45464V16.5454C18.251 17.6752 17.6426 18.251 16.5671 18.251H3.43292ZM13.4058 12.6779C13.8838 12.6779 14.2097 12.3085 14.2097 11.8088V6.68115C14.2097 6.0402 13.8512 5.79033 13.2971 5.79033H8.14775C7.63715 5.79033 7.31124 6.10538 7.31124 6.58338C7.31124 7.06138 7.64802 7.37643 8.16947 7.37643H10.1467L11.7545 7.20261L10.0597 8.77784L6.05106 12.7865C5.89897 12.9386 5.80119 13.1559 5.80119 13.3732C5.80119 13.862 6.11624 14.1771 6.59424 14.1771C6.85497 14.1771 7.06138 14.0793 7.21347 13.9272L11.2113 9.92939L12.7757 8.25638L12.6127 9.95111V11.8305C12.6127 12.3411 12.9278 12.6779 13.4058 12.6779Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { DiagonalArrowOutline };
