import { Box, BoxProps, Button, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { EncounterAvatar } from "shared/encounter-avatar";
import { StaffAvatar } from "shared/staff-avatar";

type ToolbarProps = {
  titleText?: React.ReactElement | string;
  onDoneClick?: React.MouseEventHandler | undefined;
  leftButtons?: Array<React.ReactNode | undefined>;
  rightButtons?: Array<React.ReactNode | undefined>;
  isLoading?: boolean;
} & FlexProps;

type PatientModalHeaderProps = {
  encounterId?: string;
  fullName: string;
  pictureUrl?: string;
} & BoxProps;

const toolbarButtonStyle = {
  fontSize: "1rem",
  "aria-label": "Close",
  color: "blue",
  variant: "label",
};

function ToolbarHeader(props: ToolbarProps) {
  const {
    onDoneClick,
    titleText = "",
    leftButtons = [],
    rightButtons = [],
    isLoading,
    ...rest
  } = props;
  return (
    /* Toolbar */
    <Flex justifyContent="space-between" {...rest}>
      <Flex
        flexGrow="1"
        flexBasis="0"
        justifyContent="start"
        textAlign="left"
        minW="60px"
      >
        {leftButtons?.length > 0 &&
          leftButtons?.map(
            (btn) =>
              React.isValidElement(btn) &&
              React.cloneElement(btn, { ...toolbarButtonStyle, ...btn.props })
          )}
      </Flex>
      <Box
        margin="0px 12px"
        textAlign="center"
        wordBreak="break-word"
        fontSize="1.0625rem"
        fontWeight="600"
      >
        {titleText}
      </Box>
      <Flex
        flexGrow="1"
        flexBasis="0"
        justifyContent="End"
        textAlign="right"
        minW="60px"
      >
        {rightButtons?.length > 0 &&
          rightButtons?.map(
            (btn) =>
              React.isValidElement(btn) &&
              React.cloneElement(btn, { ...toolbarButtonStyle, ...btn.props })
          )}
        {leftButtons?.length === 0 && rightButtons?.length === 0 && (
          <Button
            {...toolbarButtonStyle}
            isLoading={isLoading}
            onClick={(e) => {
              onDoneClick?.(e);
            }}
          >
            Done
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

function PatientHeaderModal(props: PatientModalHeaderProps) {
  const { fullName, encounterId, pictureUrl, ...rest } = props;

  return (
    <Box {...rest}>
      <Box marginTop="30px">
        <Box textAlign="center">
          {encounterId ? (
            <EncounterAvatar
              encounterId={encounterId}
              height="100px"
              width="100px"
            />
          ) : (
            <StaffAvatar
              userName={fullName}
              profileUrl={pictureUrl || ""}
              height="100px"
              width="100px"
              fontSize="1rem"
            />
          )}
        </Box>
        <Box
          marginTop="14px"
          textAlign="center"
          fontSize="1.625rem"
          fontWeight="500"
        >{`${fullName}`}</Box>
      </Box>
    </Box>
  );
}

export { ToolbarHeader, PatientHeaderModal };
