import * as React from "react";

import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Switch,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";

import { Action, AddRounded, MinusRoundFilled } from "icons";
import { AuditCommentsSubsectionIndicator } from "../audit-comments";
import { ChartSubsectionInteractionsModal } from "./ChartSubsectionInteractionsModal";
import { Interaction } from "types";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useChartTrackingContext, usePermissions } from "contexts";
import { AddCustomRowsModal } from "modules/provider/shared/custom-rows-modal";
import { CustomRow } from "modules/charts-shared/types";

type ChartSubsectionHeaderProps = {
  chartId: string;
  encounterId: string;
  sectionId: string;
  subsectionId: string;
  subsectionLabel?: string;
  showNotesBtn?: boolean;
  showAuditsBtn?: boolean;
  subsectionNote?: string;
  subsectionOptions?: React.ReactElement;
  subsectionEndOptions?: React.ReactElement;
  showAddCustomRows?: boolean;
  customRowsOptions?: string[];
  isRequired?: boolean;
  showDeleteBtn?: boolean;
  showInteractions?: boolean;
  onDeleteSubSection?: () => void;
  showToggleInterpretation?: boolean;
  isInterpretationVisible?: boolean;
  onToggleInterpretation?: () => void;
} & BoxProps;

const BANNED_CHARTS_FROM_TRACKING = ["actions", "order-form"];

export function ChartSubsectionHeader(props: ChartSubsectionHeaderProps) {
  const {
    chartId,
    encounterId,
    sectionId,
    subsectionId,
    subsectionLabel = "",
    showAuditsBtn = true,
    children,
    subsectionNote,
    subsectionOptions,
    showAddCustomRows,
    customRowsOptions = [],
    showDeleteBtn = false,
    showInteractions = true,
    onDeleteSubSection = () => {},
    isRequired = true,
    subsectionEndOptions,
    showToggleInterpretation = false,
    isInterpretationVisible = false,
    onToggleInterpretation = () => {},
    ...boxProps
  } = props;

  const { isPastEncounter } = useChartTrackingContext();

  const { getValues, control, setValue } = useFormContext();

  const values = getValues();

  const chartInteractions = values?.[subsectionId]
    ?.interactions as Interaction[];

  const chartInteractionsDisclose = useDisclosure();

  const { scope } = usePermissions();

  const isAuditor = scope("audits").isEditable;

  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  const fromSummary = location.pathname.includes("medical-records");

  const customRows = (values?.[subsectionId]?.customRows as CustomRow[]) ?? [];

  const addCustomRowsDisclosure = useDisclosure();

  /**
   * The following variable will determine if the tracking will be available for the chart.
   * Some charts already have the tracking available, so we don't need to track them again.
   */
  const canTrackInteractions = React.useMemo(() => {
    return !BANNED_CHARTS_FROM_TRACKING.some((value) =>
      location.pathname.split("/").includes(value)
    );
  }, [location.pathname]);

  const titleAccesory = React.useMemo(
    () =>
      ["SHPI", "SROS", "SPEX", "SCHC", "SASS"].includes(sectionId) && isRequired
        ? "*"
        : "",
    [sectionId, isRequired]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 1.25rem"
        {...boxProps}
      >
        <Box
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <HStack spacing="20px">
            <chakra.h2 fontSize="1.125rem" fontWeight="600">
              {children} <chakra.span color="red">{titleAccesory}</chakra.span>
            </chakra.h2>

            {!!subsectionOptions && React.cloneElement(subsectionOptions)}
            {showAuditsBtn && fromAudits && isAuditor && (
              <AuditCommentsSubsectionIndicator
                encounterId={encounterId}
                chartCode={chartId}
                sectionCode={sectionId}
                subsectionCode={subsectionId}
                subsectionLabel={subsectionLabel}
                isFromSideBar={false}
              />
            )}
          </HStack>

          {subsectionNote && (
            <Text
              fontSize="15px"
              fontWeight="400"
              color="#9A9EA7"
              padding="0 1.25rem"
            >
              {subsectionNote}
            </Text>
          )}
        </Box>
        <Box
          display="inline-flex"
          justifyContent="space-between"
          alignItems="end"
          position="relative"
          gap={4}
        >
          {!!showAddCustomRows && (
            <>
              <IconButton
                aria-label="add-btn"
                variant="icon"
                size="lg"
                disabled={isPastEncounter}
                icon={
                  <Icon
                    as={AddRounded}
                    color={"blue"}
                    sx={{ h: "20px", w: "20px" }}
                    onClick={addCustomRowsDisclosure.onOpen}
                  />
                }
              />
              <AddCustomRowsModal
                {...addCustomRowsDisclosure}
                control={control}
                chartCode={chartId}
                sectionCode={sectionId}
                subsectionCode={subsectionId}
                subsectionLabel={subsectionLabel}
                customOptions={customRowsOptions}
                setValue={setValue}
                currentOrder={
                  customRows.length
                    ? Math.max(
                        ...customRows.map((customRow) => customRow.order)
                      )
                    : 0
                }
              />
            </>
          )}

          {!!subsectionEndOptions && React.cloneElement(subsectionEndOptions)}
          {showToggleInterpretation && (
            <Flex columnGap="1rem" alignItems="flex-end">
              <Switch
                size="lg"
                isChecked={Boolean(isInterpretationVisible)}
                onChange={() => onToggleInterpretation()}
                colorScheme="green"
              />
            </Flex>
          )}

          {!fromAudits &&
            showInteractions &&
            canTrackInteractions &&
            !fromSummary &&
            scope("chartinteractions").isAccessible && (
              <IconButton
                aria-label="chart-subsection-interaction-btn"
                variant="icon"
                size="lg"
                icon={
                  <Icon
                    as={Action}
                    color={!!chartInteractions ? "blue" : "gray.700"}
                    sx={{ h: "55px", w: "55px" }}
                  />
                }
                onClick={chartInteractionsDisclose.onOpen}
              />
            )}
          {showDeleteBtn && (
            <Button
              variant="ghost"
              color="red"
              fontSize="1.0625rem"
              disabled={isPastEncounter}
              rightIcon={
                <Icon
                  borderRadius="1rem"
                  color="red"
                  backgroundColor="white"
                  as={MinusRoundFilled}
                />
              }
              onClick={onDeleteSubSection}
            >
              Remove
            </Button>
          )}
        </Box>
      </Box>

      {!fromAudits &&
        showInteractions &&
        canTrackInteractions &&
        !fromSummary &&
        scope("chartinteractions").isAccessible && (
          <ChartSubsectionInteractionsModal
            isOpen={chartInteractionsDisclose.isOpen}
            onClose={chartInteractionsDisclose.onClose}
            label={children}
            interactions={chartInteractions || []}
          />
        )}
    </>
  );
}

export type { ChartSubsectionHeaderProps };
