import { axiosChartInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteChartSubsectionFile(
  encounterId: string,
  subsectionId: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteChartSubsectionFile", { encounterId, subsectionId }],
    (page: number) =>
      axiosChartInstance.delete(
        `v1/chart/${encounterId}/ekg/${subsectionId}/file`,
        { params: { pageNumber: page } }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartSubsectionFile",
          { encounterId, subsectionId },
        ]);
      },
    }
  );
}

export { useDeleteChartSubsectionFile };
