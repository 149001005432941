import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFavoriteInfoRequest } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type UpdateChartFavoriteInfoProps = {
  id: string;
  sectionCode: string;
};

function useUpdateChartFavoriteInfo({
  id,
  sectionCode,
}: UpdateChartFavoriteInfoProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateChartFavoriteInfo", { id }],
    (payload: ChartFavoriteInfoRequest) =>
      axiosChartInstance.put(`v1/favorites/info/${id}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartFavorites", { sectionCode }]);
      },
    }
  );
}

export { useUpdateChartFavoriteInfo };
