import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const EmergencyContact = createIcon({
  path: (
    <path
      d="M11.4763 17.0555C16.1664 17.0555 20 13.1892 20 8.52365C20 3.84992 16.1419 0 11.4682 0C7.13703 0 3.52365 3.30342 3.01794 7.50408C3.61338 7.3491 4.34747 7.31647 4.97553 7.43067C5.48124 4.29037 8.18108 1.9168 11.4682 1.9168C15.1305 1.9168 18.0832 4.86134 18.0832 8.52365C18.0832 10.3018 17.3899 11.9168 16.248 13.0914C15.3752 12.1778 13.6786 11.3785 11.4682 11.3785C10.783 11.3785 10.1387 11.46 9.55139 11.5905C9.64111 11.9739 9.69005 12.3817 9.69005 12.7896C9.69005 14.168 9.15171 15.4405 8.27896 16.4111C9.26591 16.8271 10.3507 17.0555 11.4763 17.0555ZM11.4682 10.0816C13.0587 10.0897 14.2904 8.72757 14.2904 6.98205C14.2904 5.34258 13.0424 3.95595 11.4682 3.95595C9.89396 3.95595 8.63785 5.34258 8.65416 6.98205C8.66232 8.72757 9.88581 10.0653 11.4682 10.0816ZM4.24959 17.0473C6.56607 17.0473 8.49918 15.1142 8.49918 12.7896C8.49918 10.4649 6.58238 8.54812 4.24959 8.54812C1.92496 8.54812 0 10.4649 0 12.7896C0 15.1223 1.92496 17.0473 4.24959 17.0473ZM4.24959 13.5073C3.89886 13.5073 3.63785 13.2626 3.62969 12.9119L3.55628 10.6362C3.53997 10.2365 3.83361 9.95106 4.24959 9.95106C4.67374 9.95106 4.96737 10.2284 4.95106 10.6362L4.87765 12.9119C4.86949 13.2626 4.60848 13.5073 4.24959 13.5073ZM4.24959 15.6117C3.79282 15.6117 3.43393 15.2773 3.43393 14.8206C3.43393 14.3801 3.79282 14.0457 4.24959 14.0457C4.70636 14.0457 5.06525 14.3883 5.06525 14.8206C5.06525 15.2773 4.71452 15.6117 4.24959 15.6117Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { EmergencyContact };
