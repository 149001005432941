import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { EncounterApptNotes } from "modules/in-process/types";

type UseEncounterApptNotes = { encounterId: string };

function useEncounterApptNotes(props: UseEncounterApptNotes) {
  const { encounterId } = props;
  return useAxiosQuery(["inProcessEncounterApptNotes", { encounterId }], () =>
    axiosPatientInstance.get<EncounterApptNotes>(
      `v1/InProcess/${encounterId}/appointment-notes`,
      {}
    )
  );
}

export { useEncounterApptNotes };
