import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UsePutApproveOrderProps = {
  encounterId: string;
};

function usePutApproveOrder(props: UsePutApproveOrderProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["putApproveOrder", props],
    (orderId: string) => {
      return axiosOrderInstance.put<string>(`v1/${orderId}/approve-order`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["orderHistory", props]),
    }
  );
}

export { usePutApproveOrder };
