import React from "react";
import {
  Box,
  BoxProps,
  chakra,
  HStack,
  Icon,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Card } from "shared/card";
import { SidebarUserModal } from "shared/sidebar/SidebarUserModal";
import { useAuth0 } from "@auth0/auth0-react";
import { UserAvatar } from "shared/user-avatar";
import { useGetUser } from "api/queries";
import { useUpdateLastLogin } from "api";
import { EmrSignature } from "@medstonetech/slate-icons";
import { ChangePassword, UserType } from "modules";

const detailTextStyleProps = {
  fontSize: "1rem",
  fontWeight: "600",
  color: "gray.700",
};

const teamTypes: UserType[] = [
  "SupervisingProvider",
  "Provider",
  "Nurse",
  "Scribe",
  "HUC",
];

function UserCard(props: BoxProps) {
  const { user, getIdTokenClaims } = useAuth0();
  const [userId, setUserId] = React.useState<string>("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutateAsync: updateLastLogin } = useUpdateLastLogin();

  const generatePermissions = React.useCallback(async () => {
    try {
      const tokenIdClaims = await getIdTokenClaims();
      if (tokenIdClaims) {
        setUserId(
          tokenIdClaims[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
          ] || ""
        );

        (() => {
          fetch("https://geolocation-db.com/json/")
            .then((response) => response.json())
            .then((data: { IPv4: string }) => {
              updateLastLogin(
                data?.IPv4 ? { userIpAddress: data?.IPv4 } : undefined
              );
            });
        })();
      }
    } catch (_) {}
  }, [getIdTokenClaims, updateLastLogin]);

  React.useEffect(() => {
    generatePermissions();
  }, [generatePermissions]);

  // TODO: Probably will be best to add the user title to the token claims in the backend/auth0
  const { data: userData } = useGetUser(userId, {
    enabled: !!userId,
    currentUser: true,
  });
  const userTitle = userData?.data.title || "";
  const signature = Boolean(userData?.data.signature);
  const showMissingSignatureMessage = userData?.data.teams.some((team) =>
    teamTypes.includes(team.type)
  );

  const [isChangePasswordOpen, setIsChangePasswordOpen] = React.useState(false);
  React.useEffect(() => {
    if (userData) {
      setIsChangePasswordOpen(userData.data.needToChangePassword ?? false);
    }
  }, [userData]);

  return (
    <Card
      variant="rounded"
      display="flex"
      padding="32px"
      onClick={onOpen}
      cursor="pointer"
      {...props}
    >
      <HStack spacing={0}>
        <UserAvatar
          size="md"
          fontSize="1.5rem"
          marginRight="1.25rem"
          w="76px"
          h="76px"
          currentUserPicture
          picture={user?.picture && user.picture}
          boxProps={{ alignSelf: "flex-start" }}
        />
        <VStack spacing="0px" alignItems="start">
          <chakra.h3 fontSize="1.4rem" fontWeight="600">
            {user?.name}
          </chakra.h3>
          {user?.department && (
            <Box {...detailTextStyleProps}>{user?.department}</Box>
          )}
          {userTitle && <Box {...detailTextStyleProps}>{userTitle}</Box>}
          {!signature && showMissingSignatureMessage && (
            <Box
              pt={2}
              fontSize="1rem"
              fontWeight="600"
              color="red"
              display="flex"
            >
              <Icon as={EmrSignature} mr="8px" w="1.5rem" h="1.5rem" />
              Missing Signature
            </Box>
          )}
        </VStack>
      </HStack>
      <SidebarUserModal
        key="sidebar-user-modal"
        isOpen={isOpen && !isChangePasswordOpen}
        onClose={onClose}
      />
      <ChangePassword
        key="change-password-user-card"
        isOpen={isChangePasswordOpen}
        onClose={() => {}}
        isCreateNewPassword
      />
    </Card>
  );
}

export { UserCard };
