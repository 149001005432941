import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S007Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q007.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q007`}
          subsectionLabel="Culdocentesis"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Culdocentesis
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q007A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q007A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                name={`${sectionDelimiter}Q007A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="betadine"
                name={`${sectionDelimiter}Q007A004`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="chlorhexidine"
                name={`${sectionDelimiter}Q007A005`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A006`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q007A007`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="none"
                type="option"
                name={`${sectionDelimiter}Q007A008`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A009`}
                w="150px"
                type="input"
              />
              <ChartRowElement
                label="mL 1% lidocaine w/o epi"
                type="option"
                name={`${sectionDelimiter}Q007A010`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A011`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Procedure:"
                type="option"
                name={`${sectionDelimiter}Q007A012`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="18g needle"
                name={`${sectionDelimiter}Q007A013`}
                type="option"
              />
              <ChartRowElement
                label="20g needle"
                name={`${sectionDelimiter}Q007A014`}
                type="option"
              />
              <ChartRowElement
                label="22g needle"
                name={`${sectionDelimiter}Q007A015`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="cervicle traction w/ tenaculum"
                name={`${sectionDelimiter}Q007A017`}
                type="option"
              />
              <ChartRowElement
                label="entry into cul-de-sac area"
                name={`${sectionDelimiter}Q007A018`}
                type="option"
              />
              <ChartRowElement
                label="air sufflated"
                name={`${sectionDelimiter}Q007A019`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A020`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="punctures performed"
                name={`${sectionDelimiter}Q007A021`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A022`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Results:"
                type="option"
                name={`${sectionDelimiter}Q007A023`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="POSITIVE (bloody non-clotting fluid)"
                type="option"
                name={`${sectionDelimiter}Q007A024`}
              />
              <ChartRowElement
                label="INTERMEDIATE (no return)"
                type="option"
                name={`${sectionDelimiter}Q007A025`}
              />
              <ChartRowElement
                label="NEGATIVE (serous fluid returned)"
                type="option"
                name={`${sectionDelimiter}Q007A026`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q007A027`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q007A028`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S007Procedure };
