import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const CheckCircleHardStops = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM10 18.3333C5.37255 18.3333 1.67647 14.6274 1.67647 10C1.67647 5.37255 5.36274 1.66667 9.9902 1.66667C14.6176 1.66667 18.3333 5.37255 18.3333 10C18.3333 14.6274 14.6274 18.3333 10 18.3333ZM8.92157 14.6569C9.2451 14.6569 9.51961 14.5 9.71569 14.1961L14.1961 7.14706C14.3039 6.95098 14.4314 6.73529 14.4314 6.51961C14.4314 6.07843 14.0392 5.79412 13.6275 5.79412C13.3824 5.79412 13.1373 5.95098 12.951 6.23529L8.88235 12.7647L6.95098 10.2647C6.71569 9.95098 6.5 9.87255 6.22549 9.87255C5.80392 9.87255 5.47059 10.2157 5.47059 10.6471C5.47059 10.8627 5.55882 11.0686 5.69608 11.2549L8.08823 14.1961C8.33333 14.5196 8.59804 14.6569 8.92157 14.6569Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { CheckCircleHardStops };
