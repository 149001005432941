import { anatomy, PartsStyleFunction } from "@chakra-ui/theme-tools";

const sidebarAnatomy = anatomy("sidebar").parts(
  "root",
  "header",
  "actions",
  "content",
  "navigation",
  "bottom"
);
const parts = sidebarAnatomy.keys;

const baseStyle: PartsStyleFunction<typeof sidebarAnatomy> = () => {
  return {
    root: {
      padding: "27px 0 0",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      maxHeight: "100vh",
      minWidth: "320px",
      maxWidth: "320px",
      borderRight: "2px",
      borderColor: "gray.350",
      bg: "gray.200",
    },
    header: {
      padding: "0 24px",
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      marginBottom: "29px",
    },
    content: {
      padding: "12px 24px 8px",
      flex: 1,
      overflow: "auto",
    },
    navigation: {
      margin: "0 -8px",
      display: "flex",
      flexDirection: "column",
    },
    bottom: {
      alignSelf: "flex-end",
      width: "100%",
    },
  };
};

const sidebarTheme = { baseStyle, parts };
export { sidebarTheme };
