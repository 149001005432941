import * as React from "react";

import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  FormLabelProps,
  Input,
  InputProps,
  chakra,
  Switch,
  SwitchProps,
  Popover,
  PopoverContent,
  Icon,
  PopoverTrigger,
  PopoverArrow,
  Divider,
} from "@chakra-ui/react";
import { ChartInputSize, chartMeasures } from "modules";
import {
  ChartOptionProps,
  CheckboxButtonProps,
  CrossChartOptionProps,
  FormCalendarInput,
  FormChartOption,
  FormCheckboxButton,
  FormCounterInputOption,
  FormCrossChartOption,
  NumberSelectorProps,
  Textarea,
  TextareaProps,
} from "shared";

import { useChartTrackingContext } from "contexts";
import { useFormContext } from "react-hook-form";
import { EmrCheckFilled, EmrRemove } from "@medstonetech/slate-icons";

type ChartElementType =
  | "label"
  | "container"
  | "option"
  | "cross-option"
  | "input"
  | "text-area"
  | "empty"
  | "circle-option"
  | "date"
  | "checkbox"
  | "checkbox-button"
  | "counter-option"
  | "switch";

type ImportedType = "nursing" | "provider";

type ChartRowElementImportedProps = Partial<{
  date: string;
  status: "accepted" | "rejected" | "pending";
  type: ImportedType;
  value: string;
}>;

type BaseChartRowElementProps = {
  name: string;
};

type ChartRowElementLabelProps = {
  type: "label";
  label: string;
} & FormLabelProps;

type ChartRowElementContainerProps = {
  type: "container";
  content: React.ReactElement;
} & BoxProps;

type ChartRowElementInputProps = {
  type: "input";
  size?: ChartInputSize;
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  Omit<InputProps, "type" | "size"> & { inputType?: InputProps["type"] };

type ChartRowElementTextAreaProps = {
  type: "text-area";
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  Omit<TextareaProps, "type">;

type ChartRowElementOptionProps = {
  type: "option";
  label: string;
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  ChartOptionProps;

type ChartRowElementCrossOptionProps = {
  type: "cross-option";
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  CrossChartOptionProps;

type ChartRowElementCircleOptionProps = Omit<
  ChartRowElementOptionProps,
  "type"
> & { type: "circle-option"; imported?: ChartRowElementImportedProps };

type ChartRowElementDateProps = {
  type: "date";
  dateFormat?: string;
  size?: ChartInputSize;
  showTimeInput?: boolean;
  isDisabled?: boolean;
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  BoxProps;

type ChartRowElementEmptyProps = {
  type: "empty";
} & BoxProps;

type ChartRowElementCheckboxProps = {
  type: "checkbox";
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  CheckboxButtonProps;

type ChartRowElementSwitchProps = {
  type: "switch";
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  SwitchProps;

type ChartRowElementCheckboxButtonProps = {
  type: "checkbox-button";
  imported?: ChartRowElementImportedProps;
} & BaseChartRowElementProps &
  CheckboxButtonProps;

type ChartRowElementCounterOptionProps = {
  type: "counter-option";
} & BaseChartRowElementProps & {
    inputProps?: Omit<InputProps, "name">;
    inputName?: string;
    inputLabel?: string;
    imported?: ChartRowElementImportedProps;
  } & NumberSelectorProps &
  Pick<BoxProps, "children">;

type ChartRowElementProps =
  | ChartRowElementLabelProps
  | ChartRowElementContainerProps
  | ChartRowElementInputProps
  | ChartRowElementOptionProps
  | ChartRowElementCrossOptionProps
  | ChartRowElementTextAreaProps
  | ChartRowElementEmptyProps
  | ChartRowElementCircleOptionProps
  | ChartRowElementDateProps
  | ChartRowElementCheckboxProps
  | ChartRowElementCheckboxButtonProps
  | ChartRowElementCounterOptionProps
  | ChartRowElementSwitchProps;

const IMPORTED_TYPE_COLORS: Record<ImportedType, string> = {
  nursing: "red-sizzling",
  provider: "blue",
};

function ChartRowElementImportPopover(
  props: React.PropsWithChildren<
    ChartRowElementImportedProps & { name: string }
  >
) {
  const { children, name, ...rest } = props;

  if (!rest.date) return <>{children}</>;

  return (
    <Popover isOpen placement="top">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        width="6.5rem"
        p="0.5rem"
        flexDir="row"
        justifyContent="space-around"
        shadow="dark-lg"
      >
        <PopoverArrow />

        <Icon
          as={EmrCheckFilled}
          color="green"
          fontSize="1.85rem"
          cursor="pointer"
        />

        <Divider orientation="vertical" minH="30px" />

        <Icon as={EmrRemove} color="red" fontSize="1.85rem" cursor="pointer" />
      </PopoverContent>
    </Popover>
  );
}

function isChartRowElementT<T extends ChartRowElementProps>(
  props: ChartRowElementProps,
  type: ChartElementType
): props is T {
  return props.type === type;
}

const ChartRowElementLabel: React.FC<ChartRowElementLabelProps> = (props) => {
  const { label, ...styleProps } = props;

  return (
    <chakra.span color="gray.700" padding="7px 10px" {...styleProps}>
      {label}
    </chakra.span>
  );
};

function ChartRowElementContainer(props: ChartRowElementContainerProps) {
  const { content, ...boxProps } = props;
  return (
    <Box p="9px" color="gray.700" {...boxProps}>
      {content}
    </Box>
  );
}

function ChartRowElementEmpty(props: ChartRowElementEmptyProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { type: _type, ...boxProps } = props;

  return <Box {...boxProps} />;
}
function ChartRowElementInput(props: ChartRowElementInputProps) {
  const { name, size, inputType, type: _type, ...styleProps } = props;
  const { register, watch, setValue } = useFormContext();
  const value = watch(name);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (value === undefined || value === null) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  }, [value]);

  const registerWithRef = (e: HTMLInputElement | null) => {
    if (e) {
      inputRef.current = e;
      const registration = register(name);
      registration.ref(e);
    }
  };

  if (props.imported) {
    return (
      <ChartRowElementImportPopover name={name} {...props.imported}>
        <Input
          ref={registerWithRef}
          bg="white"
          borderColor={IMPORTED_TYPE_COLORS[props.imported.type || "nursing"]}
          color={IMPORTED_TYPE_COLORS[props.imported.type || "nursing"]}
          border="1px solid"
          width={size && chartMeasures.CHART_INPUT_WIDTH[size]}
          type={inputType}
          autoComplete="off"
          {...styleProps}
          onChange={(e) => {
            setValue(name, e.target.value, { shouldDirty: true });
          }}
        />
      </ChartRowElementImportPopover>
    );
  }

  return (
    <Input
      ref={registerWithRef}
      bg="white"
      borderColor={value ? "blue" : "gray.700"}
      color="blue"
      border="1px solid"
      width={size && chartMeasures.CHART_INPUT_WIDTH[size]}
      type={inputType}
      autoComplete="off"
      {...styleProps}
      onChange={(e) => {
        setValue(name, e.target.value, { shouldDirty: true });
      }}
    />
  );
}

function ChartRowElementTextArea(props: ChartRowElementTextAreaProps) {
  const { name, imported, ...styleProps } = props;
  const { register } = useFormContext();

  if (imported)
    return (
      <ChartRowElementImportPopover name={name} {...imported}>
        <Textarea bg="white" {...styleProps} {...register(name)} />
      </ChartRowElementImportPopover>
    );

  return <Textarea bg="white" {...styleProps} {...register(name)} />;
}

function ChartRowElementOption(props: ChartRowElementOptionProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, label, type: _type, imported, ...styleProps } = props;
  const { control } = useFormContext();

  if (imported)
    return (
      <ChartRowElementImportPopover name={name} {...imported}>
        <FormChartOption
          name={name}
          label={label}
          control={control}
          {...styleProps}
        />
      </ChartRowElementImportPopover>
    );

  return (
    <FormChartOption
      name={name}
      label={label}
      control={control}
      {...styleProps}
    />
  );
}

function ChartRowElementCrossOption(props: ChartRowElementCrossOptionProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, label, type: _type, ...styleProps } = props;
  const { control } = useFormContext();

  if (props.imported)
    return (
      <ChartRowElementImportPopover name={name} {...props.imported}>
        <FormCrossChartOption
          name={name}
          label={label}
          control={control}
          {...styleProps}
          color={IMPORTED_TYPE_COLORS[props.imported.type || "nursing"]}
        />
      </ChartRowElementImportPopover>
    );

  return (
    <FormCrossChartOption
      name={name}
      label={label}
      control={control}
      {...styleProps}
    />
  );
}

function ChartRowElementCircleOption(props: ChartRowElementCircleOptionProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { type: _type, imported, ...restProps } = props;

  if (imported)
    return (
      <ChartRowElementImportPopover name={restProps.name} {...imported}>
        <ChartRowElementOption
          w="35px"
          h="35px"
          borderRadius="50%"
          type="option"
          {...restProps}
        />
      </ChartRowElementImportPopover>
    );

  return (
    <ChartRowElementOption
      w="35px"
      h="35px"
      borderRadius="50%"
      type="option"
      {...restProps}
    />
  );
}

function ChartRowElementDate(props: ChartRowElementDateProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type: _type,
    dateFormat = "LL/dd/yyyy HH:mm",
    showTimeInput = true,
    name,
    size,
    isDisabled,
    imported,
    ...restProps
  } = props;
  const { control, setValue, getValues } = useFormContext();

  if (imported)
    return (
      <ChartRowElementImportPopover name={name} {...imported}>
        <Box width={size && chartMeasures.CHART_INPUT_WIDTH[size]}>
          <FormCalendarInput
            name={name}
            dateFormat={dateFormat}
            control={control}
            showIcon={false}
            showTimeInput={showTimeInput}
            shouldCloseOnSelect={false}
            disabled={isDisabled}
            onInputClick={() => {
              const date = getValues(name);
              if (!date) {
                setValue(name, new Date());
              }
            }}
          />
        </Box>
      </ChartRowElementImportPopover>
    );

  return (
    <Box width={size && chartMeasures.CHART_INPUT_WIDTH[size]} {...restProps}>
      <FormCalendarInput
        name={name}
        dateFormat={dateFormat}
        control={control}
        showIcon={false}
        showTimeInput={showTimeInput}
        shouldCloseOnSelect={false}
        disabled={isDisabled}
        onInputClick={() => {
          const date = getValues(name);
          if (!date) {
            setValue(name, new Date());
          }
        }}
      />
    </Box>
  );
}

function ChartRowElementCheckbox(props: ChartRowElementCheckboxProps) {
  const { name, imported, ...restProps } = props;
  const { register, watch } = useFormContext();
  const value = watch(name);
  const boolValue = value === true || value === "true";

  if (imported)
    return (
      <ChartRowElementImportPopover name={name} {...imported}>
        <Checkbox {...register(name)} isChecked={boolValue} {...restProps} />
      </ChartRowElementImportPopover>
    );

  return <Checkbox {...register(name)} isChecked={boolValue} {...restProps} />;
}

function ChartRowElementSwitch(props: ChartRowElementSwitchProps) {
  const { name, ...restProps } = props;
  const { register, watch } = useFormContext();
  const value = watch(name);
  const boolValue = value === true || value === "true";

  if (props.imported)
    return (
      <ChartRowElementImportPopover name={name} {...props.imported}>
        <Switch
          {...register(name)}
          isChecked={boolValue}
          colorScheme="green"
          size="lg"
          {...restProps}
        />
      </ChartRowElementImportPopover>
    );

  return (
    <Switch
      {...register(name)}
      isChecked={boolValue}
      colorScheme="green"
      size="lg"
      {...restProps}
    />
  );
}

function ChartRowElementCheckboxButton(
  props: ChartRowElementCheckboxButtonProps
) {
  const { children, name, type: _type, ...styleProps } = props;
  const { control } = useFormContext();

  if (props.imported)
    return (
      <ChartRowElementImportPopover name={name} {...props.imported}>
        <FormCheckboxButton name={name} control={control} {...styleProps}>
          {children}
        </FormCheckboxButton>
      </ChartRowElementImportPopover>
    );

  return (
    <FormCheckboxButton name={name} control={control} {...styleProps}>
      {children}
    </FormCheckboxButton>
  );
}

function ChartRowElementCounterOption(
  props: ChartRowElementCounterOptionProps
) {
  const { children, imported, ...elementProps } = props;

  if (imported)
    return (
      <ChartRowElementImportPopover name={elementProps.name} {...imported}>
        <Flex direction="row" alignItems="center">
          <FormCounterInputOption {...elementProps} />
        </Flex>
      </ChartRowElementImportPopover>
    );

  return (
    <Flex direction="row" alignItems="center">
      <FormCounterInputOption {...elementProps} />
    </Flex>
  );
}

function ChartRowElement(props: ChartRowElementProps) {
  const { isPastEncounter } = useChartTrackingContext();
  if (isChartRowElementT<ChartRowElementLabelProps>(props, "label")) {
    return <ChartRowElementLabel {...props} />;
  }

  if (isChartRowElementT<ChartRowElementContainerProps>(props, "container")) {
    return <ChartRowElementContainer {...props} />;
  }

  if (isChartRowElementT<ChartRowElementOptionProps>(props, "option")) {
    return (
      <ChartRowElementOption
        {...props}
        disabled={props.disabled || isPastEncounter}
      />
    );
  }

  if (
    isChartRowElementT<ChartRowElementCrossOptionProps>(props, "cross-option")
  ) {
    return (
      <ChartRowElementCrossOption
        {...props}
        disabled={props.disabled || props.isDisabled || isPastEncounter}
      />
    );
  }

  if (
    isChartRowElementT<ChartRowElementCircleOptionProps>(props, "circle-option")
  ) {
    return (
      <ChartRowElementCircleOption
        {...props}
        disabled={props.disabled || isPastEncounter}
      />
    );
  }

  if (isChartRowElementT<ChartRowElementTextAreaProps>(props, "text-area")) {
    return (
      <ChartRowElementTextArea
        {...props}
        isDisabled={props.isDisabled || isPastEncounter}
      />
    );
  }

  if (isChartRowElementT<ChartRowElementEmptyProps>(props, "empty")) {
    return <ChartRowElementEmpty {...props} />;
  }

  if (isChartRowElementT<ChartRowElementDateProps>(props, "date")) {
    return <ChartRowElementDate {...props} />;
  }

  if (isChartRowElementT<ChartRowElementCheckboxProps>(props, "checkbox")) {
    return (
      <ChartRowElementCheckbox
        {...props}
        isDisabled={props.isDisabled || isPastEncounter}
      />
    );
  }

  if (isChartRowElementT<ChartRowElementSwitchProps>(props, "switch")) {
    return (
      <ChartRowElementSwitch
        {...props}
        isDisabled={props.isDisabled || isPastEncounter}
      />
    );
  }

  if (
    isChartRowElementT<ChartRowElementCheckboxButtonProps>(
      props,
      "checkbox-button"
    )
  ) {
    return (
      <ChartRowElementCheckboxButton
        {...props}
        isDisabled={props.isDisabled || isPastEncounter}
      />
    );
  }

  if (
    isChartRowElementT<ChartRowElementCounterOptionProps>(
      props,
      "counter-option"
    )
  ) {
    return (
      <ChartRowElementCounterOption
        {...props}
        isDisabled={props.isDisabled || isPastEncounter}
      />
    );
  }

  return (
    <ChartRowElementInput
      {...props}
      isDisabled={props.isDisabled || isPastEncounter}
    />
  );
}

export type { ChartRowElementProps, ChartRowElementImportedProps };
export { ChartRowElement };
