import * as React from "react";
import {
  Box,
  Button,
  chakra,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EmrVitals } from "@medstonetech/slate-icons";
import { NurseHat } from "icons";
import {
  useDeletedChartsList,
  useRestoreDeletedChart,
} from "modules/charts-shared/api";
import { Card, DividerList, Loading, ToolbarHeader } from "shared";
import { ChartType } from "types";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";

type RestoreDeletedChartsModalProps = {
  encounterId: string;
  isModalOpen: boolean;
  onModalClose: () => void;
};

type ChartItemProps = {
  title: string;
  chartType: ChartType;
  code: string;
  onClickChart?: () => void;
  isSelected?: boolean;
};

function ChartItem(props: ChartItemProps) {
  const { title, chartType, onClickChart } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      h="56px"
      justifyContent="left"
    >
      <Button
        variant="label"
        flex={1}
        padding="16px"
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={onClickChart}
      >
        <HStack spacing="16px">
          <Icon
            as={chartType === "Chart" ? EmrVitals : NurseHat}
            color="gray.450"
            h="22px"
            w="22px"
          />
          <Box fontWeight="600" fontSize="1.0625rem" color="black">
            {title}
          </Box>
        </HStack>
      </Button>
    </Box>
  );
}

function RestoreDeletedChartsContent(props: RestoreDeletedChartsModalProps) {
  const { onModalClose, encounterId } = props;
  const toast = useToast();

  const {
    data,
    isLoading,
    isFetching,
    error: fetchError,
  } = useDeletedChartsList(encounterId);
  const { mutateAsync: restoreChart, isLoading: restoreChartLoading } =
    useRestoreDeletedChart(encounterId);

  const loading = isLoading || restoreChartLoading || isFetching;
  const currentError = !loading && fetchError;
  const hasData = data?.data && data.data?.length > 0;
  const showNoResults = !isLoading && !hasData;

  const onChartClick = async (chartId: string) => {
    try {
      await restoreChart(chartId);
      toast({ description: formMessages.updateSuccess("Chart") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <>
      <ModalHeader padding="20px 17px 0 17px">
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Reason Canceled
            </chakra.span>
          }
          rightButtons={[
            <Button key="doneBtn" onClick={onModalClose}>
              Close
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody padding="0 15px 15px">
        <VStack
          justifyContent="center"
          spacing="0px"
          my="34px"
          fontSize="1.0625rem"
          fontWeight="400"
          color="gray.650"
        >
          <chakra.span width="100%" textAlign="center">
            Selecting a chart below will add it back to the
          </chakra.span>
          <chakra.span width="100%" textAlign="center">
            patient’s record. You will have to delete it again if
          </chakra.span>
          <chakra.span width="100%" textAlign="center">
            you do not want it to be part of the patient&#8217;s record.
          </chakra.span>
        </VStack>
        <Card
          variant="default"
          border="none"
          boxShadow="none"
          maxHeight="416px"
          overflow="auto"
        >
          {showNoResults ? (
            <Box width="100%" padding="10px">
              <Box
                textAlign="center"
                fontSize="1.0625rem"
                fontWeight="600"
                color="gray.650"
              >
                There is no charts deleted at the moment.
              </Box>
            </Box>
          ) : (
            <DividerList dividerLeftMargin="52px">
              {loading && <Loading height="200px" />}
              {!loading &&
                data?.data.map(({ id, name, chartType }) => (
                  <ChartItem
                    key={id}
                    title={name}
                    code={id}
                    chartType={chartType || "Triage"}
                    onClickChart={() => onChartClick(id)}
                  />
                ))}
              {currentError && "Something went wrong fetching charts list."}
            </DividerList>
          )}
        </Card>
      </ModalBody>
    </>
  );
}

function RestoreDeletedChartsModal(props: RestoreDeletedChartsModalProps) {
  const { isModalOpen, onModalClose, encounterId } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent width="540px" maxWidth="540px" bg="gray.200">
        {isModalOpen && (
          <RestoreDeletedChartsContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { RestoreDeletedChartsModal };
