import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import {
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules/charts-shared";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const SECTION_CODE = "SHPI";
const CHART_CODE = "C070a";

function T70HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode: CHART_CODE,
    encounterId,
    sectionId: SECTION_CODE,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode: CHART_CODE,
      encounterId,
      sectionId: SECTION_CODE,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode: CHART_CODE,
            sectionId: SECTION_CODE,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode: CHART_CODE,
          sectionId: SECTION_CODE,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={SECTION_CODE}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={CHART_CODE}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        {/* header subsection Q001 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q001.C070aSHPIQ001A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="Onset / Duration"
            >
              Onset / Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="just PTA"
                  name="Q002.C070aSHPIQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="today"
                  name="Q002.C070aSHPIQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="yesterday"
                  name="Q002.C070aSHPIQ002A003"
                />
                <ChartRowElement
                  type="input"
                  name="Q002.C070aSHPIQ002A004"
                  defaultValue=""
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  label="minutes ago"
                  name="Q002.C070aSHPIQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hours ago"
                  name="Q002.C070aSHPIQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="days ago"
                  name="Q002.C070aSHPIQ002A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="gradual onset"
                  name="Q002.C070aSHPIQ002A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sudden onset"
                  name="Q002.C070aSHPIQ002A009"
                />
                <ChartRowElement type="input" name="Q002.C070aSHPIQ002A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="sudden onset"
                  name="Q003.C070aSHPIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="gradual onset"
                  name="Q003.C070aSHPIQ003A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="still present"
                  name="Q003.C070aSHPIQ003A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="better"
                  name="Q003.C070aSHPIQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="worse"
                  name="Q003.C070aSHPIQ003A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="gone now"
                  name="Q003.C070aSHPIQ003A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lasted"
                  name="Q003.C070aSHPIQ003A007"
                />
                <ChartRowElement type="input" name="Q002.C070aSHPIQ002A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="home"
                  name="Q004.C070aSHPIQ004A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="school"
                  name="Q004.C070aSHPIQ004A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neighbor’s"
                  name="Q004.C070aSHPIQ004A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="park"
                  name="Q004.C070aSHPIQ004A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="work"
                  name="Q004.C070aSHPIQ004A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="street"
                  name="Q004.C070aSHPIQ004A006"
                />
                <ChartRowElement type="input" name="Q004.C070aSHPIQ004A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C070aSHPIQ004A010"
                  label="exact location:"
                />
                <ChartRowElement type="input" name="Q004.C070aSHPIQ004A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q004.C070aSHPIQ004A011"
                  label="exact activity:"
                />
                <ChartRowElement type="input" name="Q004.C070aSHPIQ004A009" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q005.C070aSHPIQ005A009"
                  label="onset during:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sleep"
                  name="Q005.C070aSHPIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="rest"
                  name="Q005.C070aSHPIQ005A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="emotional upset"
                  name="Q005.C070aSHPIQ005A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="activity"
                  name="Q005.C070aSHPIQ005A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="exertion"
                  name="Q005.C070aSHPIQ005A005"
                />
                <ChartRowElement type="input" name="Q005.C070aSHPIQ005A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="recent flight"
                  name="Q005.C070aSHPIQ005A007"
                />
                <ChartRowElement type="input" name="Q005.C070aSHPIQ005A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q006"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q006.C070aSHPIQ006A019"
                  label="max:"
                  width="4.5rem"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mild"
                  name="Q006.C070aSHPIQ006A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="moderate"
                  name="Q006.C070aSHPIQ006A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="severe"
                  name="Q006.C070aSHPIQ006A003"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q006.C070aSHPIQ006A020"
                  label="pain 1/10:"
                />
                <ChartRowElement
                  type="circle-option"
                  label="1"
                  name="Q006.C070aSHPIQ006A004"
                />
                <ChartRowElement
                  type="circle-option"
                  label="2"
                  name="Q006.C070aSHPIQ006A005"
                />
                <ChartRowElement
                  type="circle-option"
                  label="3"
                  name="Q006.C070aSHPIQ006A006"
                />
                <ChartRowElement
                  type="circle-option"
                  label="4"
                  name="Q006.C070aSHPIQ006A007"
                />
                <ChartRowElement
                  type="circle-option"
                  label="5"
                  name="Q006.C070aSHPIQ006A008"
                />
                <ChartRowElement
                  type="circle-option"
                  label="6"
                  name="Q006.C070aSHPIQ006A009"
                />
                <ChartRowElement
                  type="circle-option"
                  label="7"
                  name="Q006.C070aSHPIQ006A010"
                />
                <ChartRowElement
                  type="circle-option"
                  label="8"
                  name="Q006.C070aSHPIQ006A011"
                />
                <ChartRowElement
                  type="circle-option"
                  label="9"
                  name="Q006.C070aSHPIQ006A012"
                />
                <ChartRowElement
                  type="circle-option"
                  label="10"
                  name="Q006.C070aSHPIQ006A013"
                />
                <ChartRowElement type="input" name="Q006.C070aSHPIQ006A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q006.C070aSHPIQ006A021"
                  label="max:"
                  width="4.5rem"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mild"
                  name="Q006.C070aSHPIQ006A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="moderate"
                  name="Q006.C070aSHPIQ006A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="severe"
                  name="Q006.C070aSHPIQ006A017"
                />
                <ChartRowElement type="input" name="Q006.C070aSHPIQ006A018" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q007"
              subsectionLabel="Quality"
            >
              Quality
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="pressure"
                  name="Q007.C070aSHPIQ007A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="tightness"
                  name="Q007.C070aSHPIQ007A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="indigestion"
                  name="Q007.C070aSHPIQ007A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="burning"
                  name="Q007.C070aSHPIQ007A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="dull"
                  name="Q007.C070aSHPIQ007A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="aching"
                  name="Q007.C070aSHPIQ007A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sharp"
                  name="Q007.C070aSHPIQ007A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="stabbing"
                  name="Q007.C070aSHPIQ007A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="like prior MI"
                  name="Q007.C070aSHPIQ007A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="cramping"
                  name="Q007.C070aSHPIQ007A010"
                />
                <ChartRowElement type="input" name="Q007.C070aSHPIQ007A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q007.C070aSHPIQ007A011"
                />
                <ChartRowElement type="input" name="Q007.C070aSHPIQ007A012" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q008"
              subsectionLabel="Location of Pain"
            >
              Location of Pain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q008.C070aSHPIQ008A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q009"
              subsectionLabel="Radiation"
            >
              Radiation
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="none"
                  name="Q009.C070aSHPIQ009A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q009.C070aSHPIQ009A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q009.C070aSHPIQ009A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="back"
                  name="Q009.C070aSHPIQ009A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q009.C070aSHPIQ009A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="jaw"
                  name="Q009.C070aSHPIQ009A006"
                />
                <ChartRowElement type="input" name="Q009.C070aSHPIQ009A007" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q009.C070aSHPIQ009A008"
                />
                <ChartRowElement type="input" name="Q009.C070aSHPIQ009A009" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q010"
              subsectionLabel="Associated symptoms"
            >
              Associated symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="palpitations"
                  name="Q010.C070aSHPIQ010A001"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="coughing blood"
                  name="Q010.C070aSHPIQ010A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sputum"
                  name="Q010.C070aSHPIQ010A004"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A005" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="weakness"
                  name="Q010.C070aSHPIQ010A006"
                />
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q010.C070aSHPIQ010A007"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q010.C070aSHPIQ010A008"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A009" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="dizziness"
                  name="Q010.C070aSHPIQ010A010"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A011" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="nausea"
                  name="Q010.C070aSHPIQ010A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="vomiting"
                  name="Q010.C070aSHPIQ010A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="diarrhea"
                  name="Q010.C070aSHPIQ010A014"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A015" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="sweating"
                  name="Q010.C070aSHPIQ010A016"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="shortness of breath"
                  name="Q010.C070aSHPIQ010A018"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A019" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="hurts to breath"
                  name="Q010.C070aSHPIQ010A020"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A021" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q010.C070aSHPIQ010A022"
                />
                <ChartRowElement type="input" name="Q010.C070aSHPIQ010A023" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q011"
              subsectionLabel="Worsened by"
            >
              Worsened by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="nothing"
                  name="Q011.C070aSHPIQ011A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="deep breath"
                  name="Q011.C070aSHPIQ011A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="exertion"
                  name="Q011.C070aSHPIQ011A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="movement"
                  name="Q011.C070aSHPIQ011A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="change in position"
                  name="Q011.C070aSHPIQ011A005"
                />
                <ChartRowElement type="input" name="Q011.C070aSHPIQ011A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q011.C070aSHPIQ011A007"
                />
                <ChartRowElement type="input" name="Q011.C070aSHPIQ011A008" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q012"
              subsectionLabel="Relieved by"
            >
              Relieved by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="nothing"
                  name="Q012.C070aSHPIQ012A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="sitting up"
                  name="Q012.C070aSHPIQ012A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="rest"
                  name="Q012.C070aSHPIQ012A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="antacids"
                  name="Q012.C070aSHPIQ012A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="nitroglycerin"
                  name="Q012.C070aSHPIQ012A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="O2"
                  name="Q012.C070aSHPIQ012A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="aspirin"
                  name="Q012.C070aSHPIQ012A007"
                />
                <ChartRowElement type="input" name="Q012.C070aSHPIQ012A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q012.C070aSHPIQ012A009"
                />
                <ChartRowElement type="input" name="Q012.C070aSHP12A010" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T70HPI };
