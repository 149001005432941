import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { InsuranceType } from "modules/reception/types";
import { useQueryClient } from "react-query";

function useSetInsuranceEligibilityReportFile(
  encounterId: string,
  insuranceType: InsuranceType
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["setInsuranceEligibilityReportFile", { encounterId }],
    () =>
      axiosPatientInstance.post<Blob>(`v1/integration/eligibility/report`, {
        responseType: "blob",
        params: { encounterId, insuranceType },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterInsuranceEligibility",
          { encounterId, insuranceType },
        ]);
        queryClient.invalidateQueries([
          "insuranceEligibilityReportFile",
          { encounterId, insuranceType },
        ]);
      },
    }
  );
}

export { useSetInsuranceEligibilityReportFile };
