import * as React from "react";

type UseFixedPositionProps = {
  respectiveToRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
};

type FixedPositionValue = {
  left: number;
  top?: number;
  bottom?: number;
  width: number;
  maxHeight?: number;
};

/**
 *
 * @param ref ref to extract data from
 * @returns ref's top, bottom, left and width
 */
function getRespectiveToInfo<T extends HTMLElement>(ref?: T | null) {
  if (ref) {
    const { top, bottom, left } = ref.getBoundingClientRect();
    return {
      respectiveToTop: top,
      respectiveToBottom: bottom,
      respectiveToLeft: left,
      respectiveToWidth: ref.clientWidth,
    };
  }
  return {};
}

/**
 * Hook used to calculate the left, top and bottom positions
 * to place in the DOM a position fixed element respective to
 * the respectiveToRef element. It also returns the width and maxHeight
 * of the fixed element
 */
function useFixedPosition(props: UseFixedPositionProps): FixedPositionValue {
  const { respectiveToRef, isOpen } = props;
  const [value, setValue] = React.useState<FixedPositionValue>({
    left: 0,
    top: undefined,
    bottom: undefined,
    width: 0,
    maxHeight: undefined,
  });

  React.useEffect(() => {
    const f = () => {
      const refInfo = getRespectiveToInfo(respectiveToRef.current);
      const {
        respectiveToBottom = 0,
        respectiveToTop = 0,
        respectiveToLeft = 0,
        respectiveToWidth = 0,
      } = refInfo;

      // Show the element on top of its respectiveToRef if there is more
      // space on top of the respectiveToRef or in the bottom otherwise
      const showOnTopOfRespectiveTo =
        respectiveToTop > window.innerHeight - respectiveToBottom;

      setValue({
        left: respectiveToLeft,
        // If element should be positioned below, its top is the respectiveToRef
        // bottom position
        top: !showOnTopOfRespectiveTo ? respectiveToBottom : undefined,
        // If element should be position on top its respectiveTo, its bottom
        // is the diff between the viewport height and the respectiveToRef top
        bottom: showOnTopOfRespectiveTo
          ? window.innerHeight - respectiveToTop
          : undefined,
        width: respectiveToWidth,
        // The max height of the element is the distence between the respectiveToRef
        // position and the top or bottom of the viewport minus 16px
        maxHeight: showOnTopOfRespectiveTo
          ? respectiveToTop - 16
          : window.innerHeight - respectiveToBottom - 16,
      });
    };

    if (isOpen) {
      f();
      window.addEventListener("resize", f);
    }

    return () => {
      if (isOpen) {
        window.removeEventListener("resize", f);
      }
    };
  }, [respectiveToRef, isOpen]);

  return value;
}

export type { UseFixedPositionProps };
export { useFixedPosition };
