import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Medications = createIcon({
  path: (
    <path
      d="M8.32512 60C3.32512 60 0 56.6502 0 51.5517V23.7685C0 18.67 3.32512 15.3202 8.32512 15.3202H8.91626V12.1429H8.74384C5.8867 12.1429 4.18719 10.3941 4.18719 7.48768V4.65517C4.18719 1.74877 5.8867 0 8.74384 0H25.8621C28.7192 0 30.4187 1.74877 30.4187 4.65517V7.48768C30.4187 10.3941 28.7192 12.1429 25.8621 12.1429H25.6897V15.3202H26.2808C31.2808 15.3202 34.6059 18.67 34.6059 23.7685V51.5517C34.6059 56.6502 31.2808 60 26.2808 60H8.32512ZM7.83251 39.3103C7.83251 40.4926 8.62069 41.2315 9.72906 41.2315H13.7192V45.197C13.7192 46.33 14.4581 47.1182 15.6404 47.1182H18.9409C20.1232 47.1182 20.8621 46.33 20.8621 45.197V41.2315H24.8522C25.9606 41.2315 26.7734 40.4926 26.7734 39.3103V36.0099C26.7734 34.8276 25.9606 34.1133 24.8522 34.1133H20.8621V30.1232C20.8621 28.9901 20.1232 28.202 18.9409 28.202H15.6404C14.4581 28.202 13.7192 28.9901 13.7192 30.1232V34.1133H9.72906C8.62069 34.1133 7.83251 34.8276 7.83251 36.0099V39.3103Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 35 60",
});

export { Medications };
