import { theme } from "@chakra-ui/react";
import { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { CSSObject } from "@emotion/react";
import { AnyObject } from "../../types/utils";

const baseButtonTheme = theme.components.Button;

const baseStyle = {
  fontWeight: "500",
};

const sizes = {
  lg: {
    minW: "287px",
    borderRadius: "50px",
  },
  md: {
    minW: "140px",
    borderRadius: "90px",
  },
  iconSm: {
    minW: "24px",
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme } = props;
  const validColorScheme =
    ["gray", "gray.650", "red", "blue", "green"].find(
      (color) => color === colorScheme
    ) || "blue";
  const styles = {
    border: "2px solid",
    color: colorScheme === "gray" ? "gray.650" : validColorScheme,
    borderColor: colorScheme === "gray" ? "gray.650" : validColorScheme,
    backgroundColor: colorScheme === "gray" ? "gray.400" : "white", // defined white because is outline style
  };

  return {
    ...styles,
    h: "52px",
    _hover: {
      ...styles,
    },
    _active: {
      ...styles,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const variantOutlineSquared: SystemStyleFunction = (props) => {
  const { colorScheme } = props;

  const validColorScheme =
    ["gray", "gray.650", "red", "blue"].find(
      (color) => color === colorScheme
    ) || "blue";

  const styles = {
    border: "1px solid",
    color: colorScheme === "gray" ? "gray.650" : validColorScheme,
    borderColor: colorScheme === "gray" ? "gray.650" : validColorScheme,
    backgroundColor: "white",
    borderRadius: "5px",
    width: "200px",
    height: "50px",
    fontWeight: "400",
    fontSize: "1.0625rem",
  };

  return {
    ...styles,
    h: "52px",
  };
};

function variantSolid(props: AnyObject) {
  const { colorScheme: c } = props;

  if (c === "gray") {
    const styles = {
      bg: "gray.400",
      color: "gray.650",
    };

    return {
      ...styles,
      h: "52px",
      _hover: {
        ...styles,
        _disabled: {
          bg: styles.bg,
        },
      },
      _active: { bg: styles.bg },
    };
  }

  const styles = {
    bg: c,
    color: "white",
  };

  return {
    ...styles,
    h: "52px",
    _hover: {
      bg: styles.bg,
      // _disabled: {
      //   bg: background,
      // },
    },
    _active: { bg: styles.bg },
  };
}

function variantAction(props: AnyObject) {
  const { colorScheme: c } = props;

  const styles = {
    height: "50px",
    borderRadius: "5px",
    border: "1px",
    backgroundColor: "white",
    fontWeight: "400",
    minW: "unset",
  };

  const baseIconStyles = {
    marginRight: "0.875rem",
    fontSize: "1.25rem",
  };

  if (c === "blue") {
    return {
      ...styles,
      borderColor: "blue",
      color: "blue",
      "> svg": {
        ...baseIconStyles,
        color: "blue",
      },
    };
  }

  if (c === "gray") {
    return {
      ...styles,
      borderColor: "gray.550",
      color: "gray.550",
      "> svg": {
        ...baseIconStyles,
        color: "gray.550",
      },
    };
  }

  if (c === "dark-gray") {
    return {
      ...styles,
      borderColor: "gray.700",
      color: "gray.700",
      "> svg": {
        ...baseIconStyles,
        color: "gray.700",
      },
    };
  }

  if (c === "red") {
    return {
      ...styles,
      borderColor: "red",
      color: "red",
      "> svg": {
        ...baseIconStyles,
        color: "red",
      },
    };
  }

  return {
    ...styles,
    borderColor: "gray.650",
    color: "gray.650",
  };
}

function variantSquare() {
  return {
    border: "2px solid",
    borderColor: "blue",
    borderRadius: "5px",
    color: "blue",
    bg: "white",
  };
}

const VARIANT_CARD_BASE_STYLES: CSSObject = {
  borderRadius: "10px",
  bg: "white",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

function variantCard() {
  return {
    ...VARIANT_CARD_BASE_STYLES,
    height: "48px",
    padding: "14px 12px 14px 16px",
    color: "gray.600",
  };
}

function variantCardTwo() {
  return {
    ...VARIANT_CARD_BASE_STYLES,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    padding: "18px 14px",
    border: "1px",
    borderColor: "gray.450",
    height: "50px",
    fontSize: "1.0625rem",
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const variantNavigation: SystemStyleFunction = (props) => {
  const { colorScheme = "green" } = props;

  return {
    height: "50px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: colorScheme,
    bg: "white",
    _hover: { bg: "white" },
    _valid: {
      color: "green",
    },
    _active: {
      bg: colorScheme,
      color: "white",
      _hover: { bg: colorScheme },
      _valid: { bg: "green", _hover: { bg: "green" } },
    },
  };
};

function variantIcon(props: AnyObject): CSSObject {
  const { size = "sm" } = props;

  const baseProps = {
    bg: "unset",
    borderRadius: "50%",
    minWidth: "unset",
    _hover: { bg: "unset" },
  };

  if (size === "sm") {
    return {
      w: "1rem",
      h: "1rem",
      "& > svg": {
        fontSize: "1rem",
      },
      ...baseProps,
    };
  }

  if (size === "md") {
    return {
      w: "1.25rem",
      h: "1.25rem",
      "& > svg": {
        fontSize: "1.25rem",
      },
      ...baseProps,
    };
  }

  if (size === "lg") {
    return {
      w: "1.5rem",
      h: "1.5rem",
      "& > svg": {
        fontSize: "1.5rem",
      },
      ...baseProps,
    };
  }

  return {
    w: "1.25rem",
    h: "1.25rem",
    "& > svg": {
      fontSize: "1.25rem",
    },
    ...baseProps,
  };
}

const variantLabel: SystemStyleFunction = () => {
  return {
    width: "unset",
    height: "unset",
    bg: "unset",
    minW: "unset",
    padding: "0",
    borderRadius: "unset",
    outline: "none",
  };
};

const variants = {
  outline: variantOutline,
  outlineSquared: variantOutlineSquared,
  solid: variantSolid,
  action: variantAction,
  square: variantSquare,
  card: variantCard,
  "card-2": variantCardTwo,
  navigation: variantNavigation,
  icon: variantIcon,
  label: variantLabel,
};

const defaultProps = {
  variant: "solid",
  size: "md",
  colorScheme: "blue",
};

export const buttonTheme = {
  ...baseButtonTheme,
  variants: {
    outline: baseButtonTheme.variants.outline,
    solid: baseButtonTheme.variants.solid,
  },
  sizes: {
    lg: baseButtonTheme.sizes.lg,
    md: baseButtonTheme.sizes.md,
  },
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
