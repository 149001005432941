import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { EncounterIntegration } from "modules/reception/types/encounter-integration";

function useEncounterIntegrations(encounterId: string) {
  return useAxiosQuery(["encounterIntegrations", { encounterId }], () =>
    axiosPatientInstance.get<EncounterIntegration>(`v1/integration`, {
      params: {
        encounterId: encounterId,
      },
    })
  );
}

function useEncounterAYVAAllergiesIntegration(encounterId: string) {
  return useAxiosQuery(
    ["encounterAYVAAllergyIntegration", { encounterId }],
    () =>
      axiosPatientInstance.get<EncounterIntegration>(
        `v1/integration/ayva/allergies-medications-rx`,
        {
          params: {
            encounterId: encounterId,
          },
        }
      )
  );
}

export { useEncounterIntegrations, useEncounterAYVAAllergiesIntegration };
