import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonFront = createIcon({
  path: (
    <path
      d="M8.4946 9.00972C10.8078 9.00972 12.6933 6.94924 12.6933 4.44168C12.6933 1.95356 10.8175 0 8.4946 0C6.19114 0 4.2959 1.99244 4.2959 4.46112C4.30562 6.95896 6.18143 9.00972 8.4946 9.00972ZM8.4946 7.54212C7.07559 7.54212 5.86069 6.18143 5.86069 4.46112C5.86069 2.76998 7.05616 1.4676 8.4946 1.4676C9.94276 1.4676 11.1285 2.75054 11.1285 4.44168C11.1285 6.16199 9.92333 7.54212 8.4946 7.54212ZM2.38121 18H14.5983C16.2117 18 16.9795 17.514 16.9795 16.4449C16.9795 13.8985 13.7624 10.2149 8.4946 10.2149C3.21706 10.2149 0 13.8985 0 16.4449C0 17.514 0.767819 18 2.38121 18ZM1.92441 16.5324C1.67171 16.5324 1.56479 16.4644 1.56479 16.2603C1.56479 14.6663 4.03348 11.6825 8.4946 11.6825C12.946 11.6825 15.4147 14.6663 15.4147 16.2603C15.4147 16.4644 15.3175 16.5324 15.0648 16.5324H1.92441Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 18",
});

export { PersonFront };
