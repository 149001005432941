import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateSummaryMiscellaneousPayload = FormData;

function useCreateSummaryMiscellaneous(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createSummaryMiscellaneous", { encounterId }],
    (input: CreateSummaryMiscellaneousPayload) =>
      axiosChartInstance.post(
        `v1/summary-miscellaneous/${encounterId}/upload`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "summaryMiscellaneous",
          { encounterId },
        ]),
    }
  );
}

export { useCreateSummaryMiscellaneous };
