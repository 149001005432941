import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ProceduresPayload, ProceduresResponse } from "../../types";

type UseUpdateTriageChartProceduresProps = {
  encounterId: string;
  chartCode: string;
};

function useUpdateTriageChartProcedures(
  props: UseUpdateTriageChartProceduresProps
) {
  const { encounterId, chartCode } = props;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateTriageChartProcedures", props],
    (input: ProceduresPayload) =>
      axiosChartInstance.put<ProceduresResponse>(
        `v1/triage/${encounterId}/${chartCode}/procedures`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["triageChartProcedures", props]),
    }
  );
}

export { useUpdateTriageChartProcedures };
