import { ChartForm } from "modules/charts-shared/types";

function extractSMHXPart(code: string): string {
  if (!code.includes("T003x")) {
    return code;
  }

  if (code.includes(".")) {
    const [prefix, rest] = code.split(".");
    const formattedRest = rest.replace("T003x", "");
    return `${prefix}.${formattedRest}`;
  }

  return code.replace("T003x", "SMHX");
}

function extractSMHXKeys(chartForm: ChartForm): string[] {
  const keys: Set<string> = new Set();

  function formatKey(key: string, questionCode?: string): string | null {
    const smhxMatch = key.match(/SMHX[^-]*/);
    if (smhxMatch) {
      const smhxPart = smhxMatch[0];
      return questionCode ? `${questionCode}.${smhxPart}` : smhxPart;
    }
    return null;
  }

  Object.entries(chartForm).forEach(
    ([questionCode, question]: [
      string,
      { customRows?: { customAnswers: { qCode: string }[] }[] }
    ]) => {
      Object.keys(question).forEach((key) => {
        if (key.includes("SMHX")) {
          const formattedKey = formatKey(key, questionCode);
          if (formattedKey) keys.add(formattedKey);
        }
      });

      if (question.customRows) {
        question.customRows.forEach(
          (row: { customAnswers: { qCode: string }[] }) => {
            row.customAnswers.forEach((answer) => {
              if (answer.qCode && answer.qCode.includes("SMHX")) {
                const parts = answer.qCode.split(".");
                if (parts.length === 2) {
                  const formattedKey = formatKey(parts[1], parts[0]);
                  if (formattedKey) keys.add(formattedKey);
                }
              }
            });
          }
        );
      }
    }
  );

  return Array.from(keys).sort();
}

export { extractSMHXKeys, extractSMHXPart };
