import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseSectionSummaryFilesResponse = {
  id: string;
  encounterId: string;
  hasDischargeFormSigned: boolean;
  hasObservationConsentSigned: boolean;
  hasAmaFormSigned: boolean;
  hasTransferDocumentsSigned: boolean;
};

function useSignedSectionsFiles(encounterId: string) {
  return useAxiosQuery(
    [
      "signedSectionsFiles",
      {
        encounterId,
      },
    ],
    () =>
      axiosChartInstance.get<UseSectionSummaryFilesResponse>(
        `v1/sectionSummary/${encounterId}`
      )
  );
}

export { useSignedSectionsFiles };
