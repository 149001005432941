import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const InfoRoundOutline = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM10 18.3333C5.37255 18.3333 1.67647 14.6274 1.67647 10C1.67647 5.37255 5.36274 1.66667 9.9902 1.66667C14.6176 1.66667 18.3235 5.37255 18.3333 10C18.3431 14.6274 14.6274 18.3333 10 18.3333ZM9.91176 6.5098C10.6275 6.5098 11.1863 5.94118 11.1863 5.22549C11.1863 4.5098 10.6275 3.94118 9.91176 3.94118C9.20588 3.94118 8.63725 4.5098 8.63725 5.22549C8.63725 5.94118 9.20588 6.5098 9.91176 6.5098ZM8.28431 15.3922H12.2745C12.6765 15.3922 12.9902 15.098 12.9902 14.6961C12.9902 14.3137 12.6765 14.0098 12.2745 14.0098H11.0588V9.01961C11.0588 8.4902 10.7941 8.13725 10.2941 8.13725H8.45098C8.04902 8.13725 7.73529 8.44118 7.73529 8.82353C7.73529 9.22549 8.04902 9.51961 8.45098 9.51961H9.5V14.0098H8.28431C7.88235 14.0098 7.56863 14.3137 7.56863 14.6961C7.56863 15.098 7.88235 15.3922 8.28431 15.3922Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { InfoRoundOutline };
