import { useAxiosMutation } from "hooks";
import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";

function useDeleteEncounterIsProfilePicture(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterIsProfilePicture", { encounterId }],
    () =>
      axiosPatientInstance.delete<null>(
        `v1/encounter/${encounterId}/form/files/profile-picture`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("profilePicture");
      },
    }
  );
}

export { useDeleteEncounterIsProfilePicture };
