import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DeleteOutline = createIcon({
  path: (
    <path
      d="M4.58015 20H12.8154C14.0997 20 14.9618 19.1648 15.0247 17.8806L15.6444 4.82263H16.722C17.0903 4.82263 17.3866 4.50831 17.3866 4.1401C17.3866 3.77189 17.0903 3.46655 16.722 3.46655H12.6179V2.0925C12.6179 0.790301 11.7737 0 10.3907 0H6.978C5.59497 0 4.75079 0.790301 4.75079 2.0925V3.46655H0.673552C0.314324 3.46655 0 3.77189 0 4.1401C0 4.51729 0.314324 4.82263 0.673552 4.82263H1.75123L2.3709 17.8895C2.43377 19.1738 3.27795 20 4.58015 20ZM6.17872 2.18231C6.17872 1.67041 6.53794 1.33812 7.07679 1.33812H10.2919C10.8307 1.33812 11.1899 1.67041 11.1899 2.18231V3.46655H6.17872V2.18231ZM4.70588 18.6439C4.19398 18.6439 3.80781 18.2577 3.78087 17.7279L3.15222 4.82263H14.1985L13.6057 17.7279C13.5878 18.2667 13.2016 18.6439 12.6718 18.6439H4.70588ZM6.03502 17.1082C6.37629 17.1082 6.60081 16.8927 6.59183 16.5784L6.31343 6.96902C6.30445 6.65469 6.07993 6.44814 5.75662 6.44814C5.41536 6.44814 5.19084 6.66367 5.19982 6.978L5.46924 16.5784C5.47822 16.9017 5.70274 17.1082 6.03502 17.1082ZM8.69331 17.1082C9.03458 17.1082 9.27705 16.8927 9.27705 16.5784V6.978C9.27705 6.66367 9.03458 6.44814 8.69331 6.44814C8.35204 6.44814 8.11855 6.66367 8.11855 6.978V16.5784C8.11855 16.8927 8.35204 17.1082 8.69331 17.1082ZM11.3606 17.1082C11.6839 17.1082 11.9084 16.9017 11.9174 16.5784L12.1868 6.978C12.1958 6.66367 11.9713 6.44814 11.63 6.44814C11.3067 6.44814 11.0822 6.65469 11.0732 6.978L10.8038 16.5784C10.7948 16.8927 11.0193 17.1082 11.3606 17.1082Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 20",
});

export { DeleteOutline };
