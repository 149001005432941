import * as React from "react";
import { chakra, Icon } from "@chakra-ui/react";
import { EmrProfileComplete } from "@medstonetech/slate-icons";
import { CardProps, Card } from "shared";
import { ACTIONS_ROW_TEMPLATE_COLUMNS_2, CONTAINER_PADDING } from "./constants";

function ActionsHeader(props: CardProps) {
  return (
    <Card
      display="grid"
      gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_2}
      bg="gray.250"
      boxShadow="none"
      height="2.5rem"
      alignItems="center"
      borderRadius="5px"
      padding={CONTAINER_PADDING}
      {...props}
    >
      <chakra.span color="gray.650" justifySelf="center">
        Date / Time
      </chakra.span>
      <Icon
        as={EmrProfileComplete}
        fontSize="1.25rem"
        color="gray.650"
        justifySelf="center"
        gridColumn={3}
      />
    </Card>
  );
}

function ActionsHeaderDescription(props: CardProps) {
  return (
    <Card
      variant="default"
      display="grid"
      gridTemplateColumns="32px 250px 1fr"
      bg="gray.200"
      alignItems="center"
      h="50px"
      padding="8px 17px"
      {...props}
    >
      <Icon
        as={EmrProfileComplete}
        fontSize="1.25rem"
        color="gray.650"
        justifySelf="center"
      />
      <chakra.span color="gray.650" justifySelf="center" fontWeight="500">
        Date / Time
      </chakra.span>
      <chakra.span color="gray.650" justifySelf="center" fontWeight="500">
        Description
      </chakra.span>
    </Card>
  );
}

export { ActionsHeader, ActionsHeaderDescription };
