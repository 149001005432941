import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type SendOrderQueueParams = {
  encounterId: string;
};

function useSendOrderQueue({ encounterId }: SendOrderQueueParams) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["sendOrderQueue", { encounterId }],
    (orderIds: string[]) =>
      axiosOrderInstance.put(`v1/queue/${encounterId}/send`, orderIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orderQueue", { encounterId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryInfinite",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "ivAndMedicationCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["medsAndIvCounters", { encounterId }]);
        queryClient.invalidateQueries(["treatmentsCounters", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterOrderCounters",
          { encounterId },
        ]);
      },
    }
  );
}

export { useSendOrderQueue };
