import * as React from "react";
import { Box, Spinner } from "@chakra-ui/react";
import background from "assets/background-welcome.jpg";

function FullPageLoading() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgImage={background}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Spinner color="blue" size="xl" />
    </Box>
  );
}

export { FullPageLoading };
