import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PrintOutline = createIcon({
  path: (
    <path
      d="M2.74716 16.5267H3.64829V17.3578C3.64829 18.6439 4.26072 19.2126 5.50306 19.2126H14.4969C15.7305 19.2126 16.3517 18.6439 16.3517 17.3578V16.5267H17.2528C19.0376 16.5267 20 15.5993 20 13.8145V5.76553C20 3.98075 19.0376 3.05337 17.2528 3.05337H16.4479V2.58093C16.4479 0.813648 15.5643 0 13.8583 0H6.14173C4.50569 0 3.54331 0.813648 3.54331 2.58093V3.05337H2.74716C1.03237 3.05337 0 3.98075 0 5.76553V13.8145C0 15.5993 0.96238 16.5267 2.74716 16.5267ZM5.14436 2.46719C5.14436 1.80227 5.48556 1.47857 6.13298 1.47857H13.8583C14.5144 1.47857 14.8469 1.80227 14.8469 2.46719V3.05337H5.14436V2.46719ZM14.4969 8.60892H5.50306C4.30446 8.60892 3.64829 9.1776 3.64829 10.4637V14.9781H2.73841C2.021 14.9781 1.64479 14.6019 1.64479 13.8845V5.70429C1.64479 4.98688 2.021 4.60192 2.73841 4.60192H17.2528C17.9703 4.60192 18.3552 4.98688 18.3552 5.70429V13.8845C18.3552 14.6019 17.9703 14.9781 17.2528 14.9781H16.3517V10.4637C16.3517 9.1776 15.7305 8.60892 14.4969 8.60892ZM14.1295 6.62292C14.1295 7.25284 14.6457 7.75153 15.2668 7.75153C15.8793 7.74278 16.3955 7.25284 16.3955 6.62292C16.3955 6.0105 15.8793 5.49431 15.2668 5.49431C14.6457 5.49431 14.1295 6.0105 14.1295 6.62292ZM5.87052 17.699C5.44182 17.699 5.23185 17.4978 5.23185 17.0691V10.7437C5.23185 10.3237 5.44182 10.1225 5.87052 10.1225H14.1382C14.5582 10.1225 14.7594 10.3237 14.7594 10.7437V17.0691C14.7594 17.4978 14.5582 17.699 14.1382 17.699H5.87052ZM7.11286 13.0184H12.9134C13.2458 13.0184 13.4996 12.7559 13.4996 12.4147C13.4996 12.091 13.2458 11.8373 12.9134 11.8373H7.11286C6.76291 11.8373 6.50919 12.091 6.50919 12.4147C6.50919 12.7559 6.76291 13.0184 7.11286 13.0184ZM7.11286 15.993H12.9134C13.2458 15.993 13.4996 15.7393 13.4996 15.4156C13.4996 15.0744 13.2458 14.8119 12.9134 14.8119H7.11286C6.76291 14.8119 6.50919 15.0744 6.50919 15.4156C6.50919 15.7393 6.76291 15.993 7.11286 15.993Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { PrintOutline };
