import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C021x";

function T21HPI() {
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="animal bite"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="dog"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="cat"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                />
                <ChartRowElement
                  label="other:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="family pet"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                />
                <ChartRowElement
                  label="heighborhood animal"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                />
                <ChartRowElement
                  label="unknown animal"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="appearance of animal:"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                />
                <ChartRowElement
                  label="appeared well"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                />
                <ChartRowElement
                  label="appeared ill"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                />
                <ChartRowElement
                  label="unknown"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                />
                <ChartRowElement
                  label="description:"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="animal's Immunization status:"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                />
                <ChartRowElement
                  label="UTD"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                />
                <ChartRowElement
                  label="unknown"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                />
                <ChartRowElement
                  label="not immunized"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="observation / capture:"
                  type="cross-option"
                  fontStyle="italic"
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                />
                <ChartRowElement
                  label="animal is known; can be observed for 10 days"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                />
                <ChartRowElement
                  label="animal unknown: not captured"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                />
                <ChartRowElement
                  label="animal control notified"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="home"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="school"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neighbor's"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="park"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="work"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="street"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="exact location:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  w="700px"
                  type="input"
                />
                <ChartRowElement
                  label="exact activity:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label={`"unprovoked" attack`}
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label={`"provoked" attack:`}
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="approached animal"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="entered animal's domain"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="animals fighting"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="playing or teasing animal"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="scratched"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bitten"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mucous membrane contact"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="head"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="face"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mouth"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chest"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="abdomen"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="shldr"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="shldr"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="back:"
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q006.${chartCode}${sectionId}Q006A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q006.${chartCode}${sectionId}Q006A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q006.${chartCode}${sectionId}Q006A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="radiating to:"
                  name={`Q006.${chartCode}${sectionId}Q006A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="leg"
                  name={`Q006.${chartCode}${sectionId}Q006A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A046`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="tingling"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="numbness distally"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="pain on movement"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T21HPI };
