import * as React from "react";
import * as Yup from "yup";
import { format } from "date-fns";
import { Card, Loading, StaffAvatar, UserAvatar, WarningDialog } from "shared";
import {
  HStack,
  Box,
  chakra,
  Button,
  useToast,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Icon,
  IconButton,
  CloseButton,
  useControllableState,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { AuditComment as AuditCommentType } from "modules/charts-shared/types/audit-comments";
import { AuditCommentUser as AuditCommentUserType } from "modules/charts-shared/types/audit-comments";
import { useYupValidationResolver } from "hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { extractApiErrorMessage } from "utils";
import { formMessages, genericErrors } from "messages";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useCreateAuditCommentsReply,
  useCreateAuditCommentsSharedReply,
} from "modules/charts-shared/api/mutations/useCreateAuditCommentsReply";
import {
  useDeleteAuditCommentsReply,
  useDeleteAuditCommentsSharedReply,
} from "modules/charts-shared/api/mutations/useDeleteAuditCommentsReply";
import { UseMutateFunction } from "react-query";
import { ArrowUpCircle } from "icons";
import {
  useDeleteAuditComments,
  useDeleteAuditCommentsShared,
} from "modules/charts-shared/api";

type AuditReplyForm = {
  reply: string;
};

type AuditCommentReplyFormProps = {
  avatarImgSrc?: string;
  createReply: UseMutateFunction<unknown, unknown, AuditReplyForm>;
  isCreateReplyLoading: boolean;
  isReplyMode?: boolean;
  defaultIsReplyMode?: boolean;
  setIsReplyMode?: (value: boolean) => void;
};

type AuditCommentProps = {
  user: AuditCommentUserType;
  comment: string;
  createdAt: string;
  isReply?: boolean;
  id?: string;
  deleteCallback?: (id: string) => void;
  isDeleteLoading?: boolean;
  canDelete: boolean;
};

type AuditCommentThreadProps = {
  comment: AuditCommentType;
  encounterId: string;
  chartCode: string;
  sectionCode: string;
  subsectionCode: string;
  isAuditor: boolean;
  isCommenter: boolean;
};

type AuditCommentSharedThreadProps = {
  comment: AuditCommentType;
  encounterId: string;
  sectionCode: string;
  subsectionCode: string;
  isAuditor: boolean;
  isCommenter: boolean;
};

function AuditCommentReplyForm({
  avatarImgSrc,
  createReply,
  isCreateReplyLoading,
  defaultIsReplyMode = false,
  isReplyMode: isReplyModeProp,
  setIsReplyMode: setIsReplyModeProp,
}: AuditCommentReplyFormProps) {
  const toast = useToast();
  const [isReplyMode, setIsReplyMode] = useControllableState({
    value: isReplyModeProp,
    defaultValue: defaultIsReplyMode,
    onChange: setIsReplyModeProp,
  });

  const CHART_REPLY_FORM_SCHEMA = Yup.object().shape({
    reply: Yup.string().required(),
  });

  const resolver = useYupValidationResolver(CHART_REPLY_FORM_SCHEMA);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<AuditReplyForm>({
    resolver,
  });

  const watchReply = watch("reply");

  const onSubmit: SubmitHandler<AuditReplyForm> = async (values) => {
    try {
      await createReply(values);

      toast({
        status: "success",
        description: formMessages.createSuccess("Audit comments reply"),
      });
      reset({ reply: "" });
      setIsReplyMode(false);
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const { onChange: registerOnChange, ...restRegister } = register("reply");
  const onChange: InputProps["onChange"] = (e) => {
    registerOnChange(e);
  };
  const onKeyPress: InputProps["onKeyPress"] = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)(e);
    }
  };

  if (isReplyMode) {
    return (
      <HStack width="100%" pt={4} px={4}>
        <UserAvatar
          fontSize="1.5rem"
          width="36px"
          height="36px"
          size="xs"
          picture={avatarImgSrc || ""}
        />
        <Box width="100%" display="flex" alignItems="center">
          <InputGroup>
            <Input
              autoComplete="off"
              placeholder="Reply..."
              bgColor="gray.200"
              borderColor="gray.200"
              borderRadius="25px"
              isDisabled={isSubmitting || isCreateReplyLoading}
              onChange={onChange}
              onKeyPress={onKeyPress}
              width="95%"
              {...restRegister}
            />
            <InputRightElement width="unset" right="2.5rem">
              <IconButton
                aria-label="submit comment"
                icon={
                  <Icon
                    as={ArrowUpCircle}
                    color={Boolean(watchReply) ? "blue" : "gray.500"}
                    w="20px"
                    h="20px"
                  />
                }
                width="24px"
                height="24px"
                variant="ghost"
                minWidth="unset"
                onClick={handleSubmit(onSubmit)}
                isLoading={isCreateReplyLoading}
                isDisabled={isCreateReplyLoading}
              />
            </InputRightElement>
            <InputRightElement>
              {!isCreateReplyLoading && (
                <CloseButton
                  ml={6}
                  color="white"
                  bg="gray.700"
                  borderRadius="50%"
                  width="24px"
                  height="24px"
                  _hover={{ bg: "gray.500" }}
                  onClick={() => setIsReplyMode(false)}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </Box>
      </HStack>
    );
  }

  return <></>;
}

function AuditComment({
  comment,
  createdAt,
  user,
  id,
  deleteCallback,
  isDeleteLoading,
  isReply,
  canDelete,
}: AuditCommentProps) {
  const toast = useToast();
  const {
    onOpen: onDeleteWarningOpen,
    isOpen: isDeleteWarningOpen,
    onClose: onDeleteWarningClose,
  } = useDisclosure();

  const onDelete = async () => {
    try {
      if (id && deleteCallback) {
        await deleteCallback(id);

        onDeleteWarningClose();
        toast({
          description: formMessages.deleteSuccess(
            `Audit comments ${isReply ? "reply" : "comment"}`
          ),
        });
        onDeleteWarningClose();
      }
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <>
      <VStack>
        <HStack w="100%">
          <StaffAvatar
            fontSize="1.5rem"
            width="32px"
            height="32px"
            size="xs"
            userName={user?.fullName || ""}
            profileUrl={user?.pictureUrl || ""}
          />
          <HStack
            alignItems="top"
            color="gray.750"
            fontSize="1rem"
            marginLeft="10px"
            whiteSpace="nowrap"
            w="calc(100% - 36px)"
          >
            <Box>
              <chakra.span fontWeight="bold">{user?.fullName}</chakra.span>
              <chakra.p fontSize=".8rem" color="gray.500">
                {user?.teams}
              </chakra.p>
            </Box>
            <Box display="flex" justifyContent="space-between" w="100%">
              <Box fontSize=".8rem" pl={1} pt={1} display="flex">
                {createdAt && format(new Date(createdAt), "hh:mm a L/d/yyyy")}
              </Box>
              <Box display="flex">
                {canDelete && (
                  <>
                    <CloseButton
                      color="white"
                      bg="gray.700"
                      borderRadius="50%"
                      width="20px"
                      height="20px"
                      size="sm"
                      _hover={{ bg: "gray.500" }}
                      onClick={onDeleteWarningOpen}
                      isDisabled={isDeleteLoading}
                    />
                    <WarningDialog
                      isOpen={isDeleteWarningOpen}
                      onClose={onDeleteWarningClose}
                      onAction={onDelete}
                      onCancel={onDeleteWarningClose}
                      title="Warning!"
                      mainText={`Are you sure you want to Delete this ${
                        isReply ? "Reply" : "Comment"
                      }?`}
                    />
                  </>
                )}
              </Box>
            </Box>
          </HStack>
        </HStack>
        <HStack width="100%">
          <Card border="none" boxShadow="none" marginLeft="44px">
            {comment}
          </Card>
        </HStack>
      </VStack>
    </>
  );
}

function AuditCommentThread({
  comment,
  encounterId,
  chartCode,
  sectionCode,
  subsectionCode,
  isAuditor,
  isCommenter,
}: AuditCommentThreadProps) {
  const { user } = useAuth0();
  const [isReplyMode, setIsReplyMode] = React.useState(false);

  const { mutateAsync: deleteComment, isLoading: isDeleteCommentLoading } =
    useDeleteAuditComments(encounterId, chartCode, sectionCode, subsectionCode);

  const { mutateAsync: createReply, isLoading: isCreateReplyLoading } =
    useCreateAuditCommentsReply(
      comment.id,
      encounterId,
      chartCode,
      sectionCode,
      subsectionCode
    );

  const { mutateAsync: deleteReply, isLoading: isDeleteReplyLoading } =
    useDeleteAuditCommentsReply(
      encounterId,
      chartCode,
      sectionCode,
      subsectionCode
    );

  const handleDeleteComment = async (id: string) => {
    deleteComment(id);
  };

  const handleDeleteReply = async (id: string) => {
    deleteReply(id);
  };

  return (
    <>
      <Card
        marginTop="1rem !important"
        padding="10px"
        borderRadius="0.625rem"
        bg="white"
        boxShadow="none"
        width="100%"
        borderColor="white"
      >
        {isDeleteCommentLoading ? (
          <Loading />
        ) : (
          <>
            <Box marginTop=".5rem" marginLeft="1rem">
              <AuditComment
                id={comment.id}
                user={comment.user}
                comment={comment.comment}
                createdAt={comment.createdAt}
                deleteCallback={handleDeleteComment}
                isDeleteLoading={isDeleteCommentLoading}
                canDelete={isAuditor}
              />
            </Box>

            {comment.replies.length > 0 && (
              <Box marginLeft="1rem">
                {comment?.replies.map((reply) => (
                  <Box key={reply.id} marginTop=".5rem">
                    <AuditComment
                      isReply
                      id={reply.id}
                      user={reply.createdBy}
                      comment={reply.reply}
                      createdAt={reply.createdAt}
                      deleteCallback={handleDeleteReply}
                      isDeleteLoading={isDeleteReplyLoading}
                      canDelete={isCommenter}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {!isReplyMode ? (
              <HStack color="gray.750" fontSize="0.75rem" ml="3.75rem" pt={4}>
                <Button
                  variant="label"
                  color="blue"
                  onClick={() => setIsReplyMode(true)}
                >
                  Reply
                </Button>
              </HStack>
            ) : (
              <AuditCommentReplyForm
                avatarImgSrc={user?.pictureUrl}
                createReply={createReply}
                isCreateReplyLoading={isCreateReplyLoading}
                isReplyMode={isReplyMode}
                setIsReplyMode={setIsReplyMode}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
}

function AuditCommentSharedThread({
  comment,
  encounterId,
  sectionCode,
  subsectionCode,
  isAuditor,
  isCommenter,
}: AuditCommentSharedThreadProps) {
  const { user } = useAuth0();
  const [isReplyMode, setIsReplyMode] = React.useState(false);

  const { mutateAsync: deleteComment, isLoading: isDeleteCommentLoading } =
    useDeleteAuditCommentsShared(encounterId, sectionCode, subsectionCode);

  const { mutateAsync: createReply, isLoading: isCreateReplyLoading } =
    useCreateAuditCommentsSharedReply(
      comment.id,
      encounterId,
      sectionCode,
      subsectionCode
    );

  const { mutateAsync: deleteReply, isLoading: isDeleteReplyLoading } =
    useDeleteAuditCommentsSharedReply(encounterId, sectionCode, subsectionCode);

  const handleDeleteComment = async (id: string) => {
    deleteComment(id);
  };

  const handleDeleteReply = async (id: string) => {
    deleteReply(id);
  };

  return (
    <>
      <Card
        marginTop="1rem !important"
        padding="10px"
        borderRadius="0.625rem"
        bg="white"
        boxShadow="none"
        width="100%"
        borderColor="white"
      >
        {isDeleteCommentLoading ? (
          <Loading />
        ) : (
          <>
            <Box marginTop=".5rem" marginLeft="1rem">
              <AuditComment
                id={comment.id}
                user={comment.user}
                comment={comment.comment}
                createdAt={comment.createdAt}
                deleteCallback={handleDeleteComment}
                isDeleteLoading={isDeleteCommentLoading}
                canDelete={isAuditor}
              />
            </Box>

            {comment.replies.length > 0 && (
              <Box marginLeft="1rem">
                {comment?.replies.map((reply) => (
                  <Box key={reply.id} marginTop="1rem">
                    <AuditComment
                      isReply
                      user={reply.createdBy}
                      comment={reply.reply}
                      createdAt={reply.createdAt}
                      id={reply.id}
                      deleteCallback={handleDeleteReply}
                      isDeleteLoading={isDeleteReplyLoading}
                      canDelete={isCommenter}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {!isReplyMode ? (
              <HStack color="gray.750" fontSize="0.75rem" ml="3.75rem" pt={4}>
                <Button
                  variant="label"
                  color="blue"
                  onClick={() => setIsReplyMode(true)}
                >
                  Reply
                </Button>
              </HStack>
            ) : (
              <AuditCommentReplyForm
                avatarImgSrc={user?.pictureUrl}
                createReply={createReply}
                isCreateReplyLoading={isCreateReplyLoading}
                isReplyMode={isReplyMode}
                setIsReplyMode={setIsReplyMode}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
}

export type { AuditCommentProps };
export { AuditCommentThread, AuditCommentSharedThread };
