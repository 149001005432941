import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const CircleDownload = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM10 18.3333C5.37255 18.3333 1.67647 14.6275 1.67647 10C1.67647 5.37255 5.36275 1.66667 9.9902 1.66667C14.6176 1.66667 18.3235 5.37255 18.3333 10C18.3431 14.6275 14.6275 18.3333 10 18.3333ZM10 5.0098C9.57843 5.0098 9.26471 5.31373 9.26471 5.7451V10.6471L9.34314 12.7157L8.36275 11.5392L7.19608 10.3627C7.05882 10.2255 6.88235 10.1471 6.67647 10.1471C6.27451 10.1471 5.97059 10.451 5.97059 10.8529C5.97059 11.0588 6.02941 11.2353 6.15686 11.3627L9.43137 14.6176C9.62745 14.8137 9.79412 14.902 10 14.902C10.2157 14.902 10.3922 14.8039 10.5784 14.6176L13.8431 11.3627C13.9706 11.2353 14.049 11.0588 14.049 10.8529C14.049 10.451 13.7353 10.1471 13.3333 10.1471C13.1176 10.1471 12.9412 10.2157 12.8137 10.3627L11.6569 11.5392L10.6569 12.7255L10.7353 10.6471V5.7451C10.7353 5.31373 10.4314 5.0098 10 5.0098Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { CircleDownload };
