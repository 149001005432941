import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const MedicalStar = createIcon({
  path: (
    <path
      d="M10.7972 24C9.17082 24 8.53835 23.5031 8.53835 21.8541V16.128L3.62541 18.9741C3.10588 19.2565 2.67671 19.4372 2.29271 19.4372C1.62635 19.4372 1.152 18.9967 0.655059 18.1384L0.485647 17.856C0.180706 17.3139 0 16.8395 0 16.4442C0 15.7892 0.451765 15.3035 1.25365 14.8518L6.34729 11.9266L1.27624 9.13694C0.451765 8.67388 0.0338823 8.18824 0.0338823 7.53318C0.0338823 7.13788 0.203294 6.67482 0.463059 6.13271L0.632471 5.83906C1.06165 4.94682 1.59247 4.50635 2.23624 4.50635C2.64282 4.50635 3.11718 4.69835 3.63671 4.992L8.53835 7.83812V2.14588C8.53835 0.496941 9.17082 0 10.7972 0H11.1473C12.7736 0 13.4061 0.496941 13.4061 2.13459V7.86071L18.3191 4.992C18.8273 4.70965 19.2678 4.54024 19.6518 4.54024C20.2955 4.54024 20.7699 4.96941 21.2894 5.82776L21.4701 6.144C21.7751 6.67482 21.9445 7.12659 21.9445 7.53318C21.9445 8.19953 21.504 8.68518 20.6908 9.12565L15.5972 11.9266L20.7021 14.8518C21.5153 15.3035 21.9896 15.7892 21.9896 16.4781C21.9896 16.8734 21.8089 17.3365 21.4701 17.8786L21.2894 18.1722C20.7586 18.9967 20.2955 19.4259 19.6518 19.4259C19.2678 19.4259 18.8386 19.2452 18.3304 18.9741L13.4061 16.1167V21.8541C13.4061 23.5031 12.7736 24 11.1473 24H10.7972Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 24",
});

export { MedicalStar };
