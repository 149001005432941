import { TimerStatus } from "enums";

const TIMER_STATUS_COLOR: Record<TimerStatus, string> = {
  AGREEABLE: "green",
  ATTENTION: "yellow",
  CRITICAL: "red",
  UNKNOWN: "gray.450",
};

export { TIMER_STATUS_COLOR };
