import { Icon } from "@chakra-ui/react";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
  useSaveAndNext,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCLI";
const chartCode = "C073x";

function T73ClinicalImpression() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
  } = useChartForm({
    sectionId,
    sectionLabel: "CLI",
    chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    formState: { isDirty },
  } = useFormContext;

  const handleSaveAndNext = useSaveAndNext(
    () => onSubmit(),
    "clinical-impression",
    "provider",
    isDirty
  );

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartSection onSubmit={onSubmit} disabled={isReadOnly}>
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Laceration:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fingertip inj:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="subungual hematoma"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail injury"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail bed injury"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail avulsion"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tip amputation"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abrasion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Puncture wound:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                />
                <ChartRowElement
                  label="w/ FB"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Open bite:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contusion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Burn:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                />
                <ChartRowElement
                  label="thermal"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="electrical"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="chemical:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  w="300px"
                  type="input"
                />
                <ChartRowElement
                  label="accident"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="self-harm"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="assault"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unkown"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="cross-option"
                  w="50px"
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  textDecorationLine="underline"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="cross-option"
                  w="50px"
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  textDecorationLine="underline"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="cross-option"
                  w="50px"
                />
                <ChartRowElement
                  label="area:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  textDecorationLine="underline"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  w="900px"
                  type="input"
                />
                <ChartRowElement
                  label=":"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Total BSA="
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  w="100px"
                  type="input"
                />
                <ChartRowElement
                  label="%"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Forearm:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A096`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Flexor:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A097`}
                />
                <ChartRowElement
                  label="of hand"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="of digit"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Extensor:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A098`}
                />
                <ChartRowElement
                  label="of hand"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="of digit"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hand intrinsic:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A099`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hip"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip muscle"
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="quadriceps"
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hamstring"
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh adductor"
                  name={`Q003.${chartCode}${sectionId}Q003A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hip flexor"
                  name={`Q003.${chartCode}${sectionId}Q003A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="groin"
                  name={`Q003.${chartCode}${sectionId}Q003A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="gluteus"
                  name={`Q003.${chartCode}${sectionId}Q003A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Lower leg muscle:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A100`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant"
                  name={`Q003.${chartCode}${sectionId}Q003A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q003.${chartCode}${sectionId}Q003A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Achilles tendon"
                  name={`Q003.${chartCode}${sectionId}Q003A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A047`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Foot:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A101`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="intrinsic muscle"
                  name={`Q003.${chartCode}${sectionId}Q003A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Thorax:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A102`}
                />
                <ChartRowElement
                  label="ant"
                  name={`Q003.${chartCode}${sectionId}Q003A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q003.${chartCode}${sectionId}Q003A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="low back"
                  name={`Q003.${chartCode}${sectionId}Q003A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A055`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A057`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shoulder:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A103`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A059`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="trapezius"
                  name={`Q003.${chartCode}${sectionId}Q003A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="deltoid"
                  name={`Q003.${chartCode}${sectionId}Q003A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pec major"
                  name={`Q003.${chartCode}${sectionId}Q003A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="rotator cuff"
                  name={`Q003.${chartCode}${sectionId}Q003A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A064`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Up-arm:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A104`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A066`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="triceps"
                  name={`Q003.${chartCode}${sectionId}Q003A067`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="biceps"
                  name={`Q003.${chartCode}${sectionId}Q003A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="long"
                  name={`Q003.${chartCode}${sectionId}Q003A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="short"
                  name={`Q003.${chartCode}${sectionId}Q003A070`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A071`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Forearm:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A105`}
                />
                <ChartRowElement
                  label="FCR"
                  name={`Q003.${chartCode}${sectionId}Q003A072`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="FCU"
                  name={`Q003.${chartCode}${sectionId}Q003A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="brachioradialis"
                  name={`Q003.${chartCode}${sectionId}Q003A074`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ext digitorum"
                  name={`Q003.${chartCode}${sectionId}Q003A075`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A076`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="flexor:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A106`}
                  ml="120px"
                />
                <ChartRowElement
                  label="to:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A107`}
                  textDecorationLine="underline"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A079`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q003.${chartCode}${sectionId}Q003A080`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q003.${chartCode}${sectionId}Q003A081`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A082`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="extensor:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A108`}
                  ml="120px"
                />
                <ChartRowElement
                  label="to:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A109`}
                  textDecorationLine="underline"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q003.${chartCode}${sectionId}Q003A083`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q003.${chartCode}${sectionId}Q003A084`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q003.${chartCode}${sectionId}Q003A085`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q003.${chartCode}${sectionId}Q003A086`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q003.${chartCode}${sectionId}Q003A087`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A088`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Neck"
                  name={`Q003.${chartCode}${sectionId}Q003A089`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thorax:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A110`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A090`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A091`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant"
                  name={`Q003.${chartCode}${sectionId}Q003A092`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q003.${chartCode}${sectionId}Q003A093`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="low back"
                  name={`Q003.${chartCode}${sectionId}Q003A094`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A095`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Wrist:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A076`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="radiocarpal joint"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="carpal"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shoulder:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A077`}
                />
                <ChartRowElement
                  label="AC joint"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="rotator cuff"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Elbow:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A078`}
                />
                <ChartRowElement
                  label="radial collat"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ulnar collat"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="MCP:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A079`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="IP:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A080`}
                />
                <ChartRowElement
                  label="PIP:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A081`}
                />
                <ChartRowElement
                  label="DIP:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A082`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hip:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A083`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hip muscle"
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="quadriceps"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hamstring"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh adductor"
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Knee:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A084`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="collateral:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A085`}
                />
                <ChartRowElement
                  label="med"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lat"
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cruciate:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A086`}
                />
                <ChartRowElement
                  label="ant"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ankle:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A087`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="calcaneofibular"
                  name={`Q004.${chartCode}${sectionId}Q004A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="talofibular:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A088`}
                />
                <ChartRowElement
                  label="ant"
                  name={`Q004.${chartCode}${sectionId}Q004A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q004.${chartCode}${sectionId}Q004A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tibiofibular"
                  name={`Q004.${chartCode}${sectionId}Q004A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="deltoid"
                  name={`Q004.${chartCode}${sectionId}Q004A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Foot:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A089`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tarsal ligament"
                  name={`Q004.${chartCode}${sectionId}Q004A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tarsometatarsal ligament"
                  name={`Q004.${chartCode}${sectionId}Q004A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A054`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Toe:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A090`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="MTP"
                  name={`Q004.${chartCode}${sectionId}Q004A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="IP"
                  name={`Q004.${chartCode}${sectionId}Q004A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q004.${chartCode}${sectionId}Q004A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q004.${chartCode}${sectionId}Q004A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q004.${chartCode}${sectionId}Q004A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q004.${chartCode}${sectionId}Q004A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q004.${chartCode}${sectionId}Q004A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A064`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Spine:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A091`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q004.${chartCode}${sectionId}Q004A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q004.${chartCode}${sectionId}Q004A066`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A067`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A068`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Spine ligaments:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A092`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q004.${chartCode}${sectionId}Q004A069`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q004.${chartCode}${sectionId}Q004A070`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A071`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q004.${chartCode}${sectionId}Q004A072`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A073`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A074`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A075`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Spine"
            >
              Spine
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fracture:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A057`}
                />
                <ChartRowElement
                  label="Dislocation:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A058`}
                />
                <ChartRowElement
                  label="closed"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="open"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Disc disorder:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A059`}
                />
                <ChartRowElement
                  label="degenerative"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="herniated disc"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bulging disc"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="C"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ radiculopathy"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ myelopathy"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="DJD (spondylosis):"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A060`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Osteomyelitis of spine:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A061`}
                />
                <ChartRowElement
                  label="acute hematogenous"
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Scoliosis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A062`}
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="secondary"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="juvenile"
                  name={`Q005.${chartCode}${sectionId}Q005A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="adolescent"
                  name={`Q005.${chartCode}${sectionId}Q005A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="C"
                  name={`Q005.${chartCode}${sectionId}Q005A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q005.${chartCode}${sectionId}Q005A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q005.${chartCode}${sectionId}Q005A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q005.${chartCode}${sectionId}Q005A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q005.${chartCode}${sectionId}Q005A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q005.${chartCode}${sectionId}Q005A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Spinal stenosis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A063`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q005.${chartCode}${sectionId}Q005A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q005.${chartCode}${sectionId}Q005A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q005.${chartCode}${sectionId}Q005A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q005.${chartCode}${sectionId}Q005A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q005.${chartCode}${sectionId}Q005A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q005.${chartCode}${sectionId}Q005A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q005.${chartCode}${sectionId}Q005A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cervical disc:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A064`}
                />
                <ChartRowElement
                  label="upper"
                  name={`Q005.${chartCode}${sectionId}Q005A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q005.${chartCode}${sectionId}Q005A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q005.${chartCode}${sectionId}Q005A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="degenerative"
                  name={`Q005.${chartCode}${sectionId}Q005A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="herniated disc"
                  name={`Q005.${chartCode}${sectionId}Q005A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bulging"
                  name={`Q005.${chartCode}${sectionId}Q005A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ radiculopathy"
                  name={`Q005.${chartCode}${sectionId}Q005A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ myelopathy"
                  name={`Q005.${chartCode}${sectionId}Q005A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A054`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cervical DJD (spondylosis)"
                  name={`Q005.${chartCode}${sectionId}Q005A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A056`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fracture:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                />
                <ChartRowElement
                  label="closed"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="open"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dislocation"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Achilles Tendon Rupture:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Traumatic Joint Effusion:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Nerve injury:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A106`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="radial"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ulnar"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="median"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A107`}
                />
                <ChartRowElement
                  label="up-arm"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nerve injury:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A108`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sciatic"
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="femoral"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tibial"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="peroneal"
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A109`}
                />
                <ChartRowElement
                  label="hip"
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="TIA:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A110`}
                />
                <ChartRowElement
                  label="involving:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A111`}
                />
                <ChartRowElement
                  label="carotid"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vertebro-basilar"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epilepsy:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A112`}
                />
                <ChartRowElement
                  label="focal"
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="complex"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="partial"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q007.${chartCode}${sectionId}Q007A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q007.${chartCode}${sectionId}Q007A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="poorly controlled"
                  name={`Q007.${chartCode}${sectionId}Q007A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Seizure"
                  name={`Q007.${chartCode}${sectionId}Q007A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q007.${chartCode}${sectionId}Q007A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Concussion:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A113`}
                />
                <ChartRowElement
                  label="lost consciousness:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A114`}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q007.${chartCode}${sectionId}Q007A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="no"
                  name={`Q007.${chartCode}${sectionId}Q007A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q007.${chartCode}${sectionId}Q007A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A115`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A039`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="seconds"
                  name={`Q007.${chartCode}${sectionId}Q007A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="minutes"
                  name={`Q007.${chartCode}${sectionId}Q007A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours"
                  name={`Q007.${chartCode}${sectionId}Q007A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cauda equina syndrome"
                  name={`Q007.${chartCode}${sectionId}Q007A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cord compression:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A116`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q007.${chartCode}${sectionId}Q007A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q007.${chartCode}${sectionId}Q007A047`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q007.${chartCode}${sectionId}Q007A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="level"
                  name={`Q007.${chartCode}${sectionId}Q007A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cord Injury:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A117`}
                />
                <ChartRowElement
                  label="incomplete"
                  name={`Q007.${chartCode}${sectionId}Q007A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q007.${chartCode}${sectionId}Q007A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="level"
                  name={`Q007.${chartCode}${sectionId}Q007A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="C: #"
                  name={`Q007.${chartCode}${sectionId}Q007A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A056`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="T: #"
                  name={`Q007.${chartCode}${sectionId}Q007A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A058`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="L: #"
                  name={`Q007.${chartCode}${sectionId}Q007A059`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A060`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="sacral"
                  name={`Q007.${chartCode}${sectionId}Q007A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A062`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Radiculopathy:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A118`}
                />
                <ChartRowElement
                  label="C"
                  name={`Q007.${chartCode}${sectionId}Q007A063`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="T"
                  name={`Q007.${chartCode}${sectionId}Q007A064`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="S"
                  name={`Q007.${chartCode}${sectionId}Q007A066`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q007.${chartCode}${sectionId}Q007A067`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q007.${chartCode}${sectionId}Q007A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q007.${chartCode}${sectionId}Q007A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A070`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sciatica:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A119`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A071`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A072`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="with low back pain"
                  name={`Q007.${chartCode}${sectionId}Q007A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A074`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cervical radiculopathy:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A120`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A075`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A076`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="level"
                  name={`Q007.${chartCode}${sectionId}Q007A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="upper"
                  name={`Q007.${chartCode}${sectionId}Q007A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mid"
                  name={`Q007.${chartCode}${sectionId}Q007A079`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower"
                  name={`Q007.${chartCode}${sectionId}Q007A080`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A081`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nerve injury:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A121`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A082`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A083`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="radial"
                  name={`Q007.${chartCode}${sectionId}Q007A084`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ulnar"
                  name={`Q007.${chartCode}${sectionId}Q007A085`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="median"
                  name={`Q007.${chartCode}${sectionId}Q007A086`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A122`}
                />
                <ChartRowElement
                  label="up-arm"
                  name={`Q007.${chartCode}${sectionId}Q007A087`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q007.${chartCode}${sectionId}Q007A088`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q007.${chartCode}${sectionId}Q007A089`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q007.${chartCode}${sectionId}Q007A090`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A091`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cerebral:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A123`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A092`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A093`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q007.${chartCode}${sectionId}Q007A094`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac"
                  name={`Q007.${chartCode}${sectionId}Q007A095`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic hemorrhage"
                  name={`Q007.${chartCode}${sectionId}Q007A096`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A097`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epidural"
                  name={`Q007.${chartCode}${sectionId}Q007A098`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Subdural"
                  name={`Q007.${chartCode}${sectionId}Q007A099`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Traumatic SAH"
                  name={`Q007.${chartCode}${sectionId}Q007A100`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A101`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A102`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A103`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Post-Concussion syndrome"
                  name={`Q007.${chartCode}${sectionId}Q007A104`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A105`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Vascular"
            >
              Vascular
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Lacerated:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A035`}
                />
                <ChartRowElement
                  label="artery"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vein"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="brachial"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="radial"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ulnar"
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="cephalic"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="basilic"
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A036`}
                />
                <ChartRowElement
                  label="up-arm"
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q008.${chartCode}${sectionId}Q008A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q008.${chartCode}${sectionId}Q008A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="artery"
                  name={`Q008.${chartCode}${sectionId}Q008A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vein"
                  name={`Q008.${chartCode}${sectionId}Q008A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q008.${chartCode}${sectionId}Q008A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q008.${chartCode}${sectionId}Q008A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="femoral"
                  name={`Q008.${chartCode}${sectionId}Q008A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="popliteal"
                  name={`Q008.${chartCode}${sectionId}Q008A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="peroneal"
                  name={`Q008.${chartCode}${sectionId}Q008A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tibial"
                  name={`Q008.${chartCode}${sectionId}Q008A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant"
                  name={`Q008.${chartCode}${sectionId}Q008A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q008.${chartCode}${sectionId}Q008A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="saphenous"
                  name={`Q008.${chartCode}${sectionId}Q008A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="greater"
                  name={`Q008.${chartCode}${sectionId}Q008A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lesser"
                  name={`Q008.${chartCode}${sectionId}Q008A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A037`}
                />
                <ChartRowElement
                  label="hip"
                  name={`Q008.${chartCode}${sectionId}Q008A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q008.${chartCode}${sectionId}Q008A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q008.${chartCode}${sectionId}Q008A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q008.${chartCode}${sectionId}Q008A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q008.${chartCode}${sectionId}Q008A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A034`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Tendon"
            >
              Tendon
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Tendon laceration:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="CV / Resp"
            >
              CV / Resp
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Acute MI:"
                  type="cross-option"
                  name={`Q010.${chartCode}${sectionId}Q010A055`}
                />
                <ChartRowElement
                  label="STEMI"
                  name={`Q010.${chartCode}${sectionId}Q010A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NSTEMI"
                  name={`Q010.${chartCode}${sectionId}Q010A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anterior"
                  name={`Q010.${chartCode}${sectionId}Q010A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inferior"
                  name={`Q010.${chartCode}${sectionId}Q010A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lateral"
                  name={`Q010.${chartCode}${sectionId}Q010A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q010.${chartCode}${sectionId}Q010A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic aneurysm:"
                  type="cross-option"
                  name={`Q010.${chartCode}${sectionId}Q010A056`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q010.${chartCode}${sectionId}Q010A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q010.${chartCode}${sectionId}Q010A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ rupture"
                  name={`Q010.${chartCode}${sectionId}Q010A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic dissection:"
                  type="cross-option"
                  name={`Q010.${chartCode}${sectionId}Q010A057`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q010.${chartCode}${sectionId}Q010A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q010.${chartCode}${sectionId}Q010A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aorta transection"
                  name={`Q010.${chartCode}${sectionId}Q010A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q010.${chartCode}${sectionId}Q010A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q010.${chartCode}${sectionId}Q010A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Arrythmia:"
                  type="cross-option"
                  name={`Q010.${chartCode}${sectionId}Q010A058`}
                />
                <ChartRowElement
                  label="A-fib"
                  name={`Q010.${chartCode}${sectionId}Q010A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="paroxysmal"
                  name={`Q010.${chartCode}${sectionId}Q010A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PSVT"
                  name={`Q010.${chartCode}${sectionId}Q010A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="V-tach"
                  name={`Q010.${chartCode}${sectionId}Q010A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sick sinus"
                  name={`Q010.${chartCode}${sectionId}Q010A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd HB"
                  name={`Q010.${chartCode}${sectionId}Q010A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd HB"
                  name={`Q010.${chartCode}${sectionId}Q010A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A026`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cardiac arrest"
                  name={`Q010.${chartCode}${sectionId}Q010A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cardiac contusion"
                  name={`Q010.${chartCode}${sectionId}Q010A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hemopericardium"
                  name={`Q010.${chartCode}${sectionId}Q010A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hemothorax"
                  name={`Q010.${chartCode}${sectionId}Q010A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q010.${chartCode}${sectionId}Q010A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q010.${chartCode}${sectionId}Q010A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumothorax"
                  name={`Q010.${chartCode}${sectionId}Q010A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q010.${chartCode}${sectionId}Q010A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q010.${chartCode}${sectionId}Q010A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tension"
                  name={`Q010.${chartCode}${sectionId}Q010A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulmonary"
                  name={`Q010.${chartCode}${sectionId}Q010A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q010.${chartCode}${sectionId}Q010A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q010.${chartCode}${sectionId}Q010A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q010.${chartCode}${sectionId}Q010A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac"
                  name={`Q010.${chartCode}${sectionId}Q010A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A047`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm embolism"
                  name={`Q010.${chartCode}${sectionId}Q010A048`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ cor pulmonale"
                  name={`Q010.${chartCode}${sectionId}Q010A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Respiratory arrest"
                  name={`Q010.${chartCode}${sectionId}Q010A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A052`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q010.${chartCode}${sectionId}Q010A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A054`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Abdomen / GI / GU"
            >
              Abdomen / GI / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Biliary colic"
                  name={`Q011.${chartCode}${sectionId}Q011A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ gallstones"
                  name={`Q011.${chartCode}${sectionId}Q011A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cholecystitis:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A054`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q011.${chartCode}${sectionId}Q011A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ gallstones"
                  name={`Q011.${chartCode}${sectionId}Q011A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ obstruction"
                  name={`Q011.${chartCode}${sectionId}Q011A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cystitis:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A055`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q011.${chartCode}${sectionId}Q011A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hematuria"
                  name={`Q011.${chartCode}${sectionId}Q011A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hemoperitoneum"
                  name={`Q011.${chartCode}${sectionId}Q011A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Kidney:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A056`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q011.${chartCode}${sectionId}Q011A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="<2cm"
                  name={`Q011.${chartCode}${sectionId}Q011A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">2cm"
                  name={`Q011.${chartCode}${sectionId}Q011A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A057`}
                />
                <ChartRowElement
                  label="<1cm"
                  name={`Q011.${chartCode}${sectionId}Q011A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1-3cm"
                  name={`Q011.${chartCode}${sectionId}Q011A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">3cm"
                  name={`Q011.${chartCode}${sectionId}Q011A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Liver:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A058`}
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q011.${chartCode}${sectionId}Q011A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A059`}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A023`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="cm"
                  name={`Q011.${chartCode}${sectionId}Q011A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="stellate"
                  name={`Q011.${chartCode}${sectionId}Q011A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="multiple"
                  name={`Q011.${chartCode}${sectionId}Q011A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nephrolithiasis"
                  name={`Q011.${chartCode}${sectionId}Q011A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pancreas:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A060`}
                />
                <ChartRowElement
                  label="head:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A061`}
                />
                <ChartRowElement
                  label="body:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A062`}
                />
                <ChartRowElement
                  label="tall:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A063`}
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q011.${chartCode}${sectionId}Q011A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A064`}
                />
                <ChartRowElement
                  label="sm"
                  name={`Q011.${chartCode}${sectionId}Q011A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="med"
                  name={`Q011.${chartCode}${sectionId}Q011A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lg"
                  name={`Q011.${chartCode}${sectionId}Q011A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pancreatitis:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A065`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A066`}
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q011.${chartCode}${sectionId}Q011A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="biliary"
                  name={`Q011.${chartCode}${sectionId}Q011A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q011.${chartCode}${sectionId}Q011A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pyelonephritis:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A067`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q011.${chartCode}${sectionId}Q011A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ obstructive"
                  name={`Q011.${chartCode}${sectionId}Q011A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Spleen:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A068`}
                />
                <ChartRowElement
                  label="contusion"
                  name={`Q011.${chartCode}${sectionId}Q011A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="<2cm"
                  name={`Q011.${chartCode}${sectionId}Q011A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">2cm"
                  name={`Q011.${chartCode}${sectionId}Q011A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lac:"
                  type="cross-option"
                  name={`Q011.${chartCode}${sectionId}Q011A069`}
                />
                <ChartRowElement
                  label="<1cm"
                  name={`Q011.${chartCode}${sectionId}Q011A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1-3cm"
                  name={`Q011.${chartCode}${sectionId}Q011A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">3cm"
                  name={`Q011.${chartCode}${sectionId}Q011A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A048`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ureterolithiasis"
                  name={`Q011.${chartCode}${sectionId}Q011A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Renal colic"
                  name={`Q011.${chartCode}${sectionId}Q011A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q011.${chartCode}${sectionId}Q011A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A053`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Crush injury:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A099`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q012.${chartCode}${sectionId}Q012A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q012.${chartCode}${sectionId}Q012A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A100`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q012.${chartCode}${sectionId}Q012A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q012.${chartCode}${sectionId}Q012A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q012.${chartCode}${sectionId}Q012A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q012.${chartCode}${sectionId}Q012A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q012.${chartCode}${sectionId}Q012A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A011`}
                  type="cross-option"
                  ml="120px"
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q012.${chartCode}${sectionId}Q012A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q012.${chartCode}${sectionId}Q012A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q012.${chartCode}${sectionId}Q012A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q012.${chartCode}${sectionId}Q012A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A101`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q012.${chartCode}${sectionId}Q012A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q012.${chartCode}${sectionId}Q012A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q012.${chartCode}${sectionId}Q012A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q012.${chartCode}${sectionId}Q012A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q012.${chartCode}${sectionId}Q012A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Amputation:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A102`}
                />
                <ChartRowElement
                  label="partial"
                  name={`Q012.${chartCode}${sectionId}Q012A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q012.${chartCode}${sectionId}Q012A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A103`}
                />
                <ChartRowElement
                  label="hip"
                  name={`Q012.${chartCode}${sectionId}Q012A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q012.${chartCode}${sectionId}Q012A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="knee"
                  name={`Q012.${chartCode}${sectionId}Q012A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q012.${chartCode}${sectionId}Q012A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q012.${chartCode}${sectionId}Q012A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="midfoot"
                  name={`Q012.${chartCode}${sectionId}Q012A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A104`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q012.${chartCode}${sectionId}Q012A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q012.${chartCode}${sectionId}Q012A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q012.${chartCode}${sectionId}Q012A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q012.${chartCode}${sectionId}Q012A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q012.${chartCode}${sectionId}Q012A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="partial"
                  name={`Q012.${chartCode}${sectionId}Q012A039`}
                  type="cross-option"
                  ml="120px"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q012.${chartCode}${sectionId}Q012A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="f-arm"
                  name={`Q012.${chartCode}${sectionId}Q012A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q012.${chartCode}${sectionId}Q012A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A105`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q012.${chartCode}${sectionId}Q012A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q012.${chartCode}${sectionId}Q012A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q012.${chartCode}${sectionId}Q012A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q012.${chartCode}${sectionId}Q012A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q012.${chartCode}${sectionId}Q012A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Diaphragm rupture"
                  name={`Q012.${chartCode}${sectionId}Q012A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A052`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epidural abscess"
                  name={`Q012.${chartCode}${sectionId}Q012A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Epidural mass"
                  name={`Q012.${chartCode}${sectionId}Q012A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A055`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="GSW"
                  name={`Q012.${chartCode}${sectionId}Q012A056`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="stab"
                  name={`Q012.${chartCode}${sectionId}Q012A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="penetrating injury"
                  name={`Q012.${chartCode}${sectionId}Q012A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A106`}
                />
                <ChartRowElement
                  label="head"
                  name={`Q012.${chartCode}${sectionId}Q012A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q012.${chartCode}${sectionId}Q012A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant"
                  name={`Q012.${chartCode}${sectionId}Q012A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q012.${chartCode}${sectionId}Q012A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thorax"
                  name={`Q012.${chartCode}${sectionId}Q012A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A064`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q012.${chartCode}${sectionId}Q012A065`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A066`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q012.${chartCode}${sectionId}Q012A067`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A068`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pain:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A107`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A108`}
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q012.${chartCode}${sectionId}Q012A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cancer related"
                  name={`Q012.${chartCode}${sectionId}Q012A070`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q012.${chartCode}${sectionId}Q012A071`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thoracic spine"
                  name={`Q012.${chartCode}${sectionId}Q012A072`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="low back"
                  name={`Q012.${chartCode}${sectionId}Q012A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A074`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Penetrating injury into abd cavity"
                  name={`Q012.${chartCode}${sectionId}Q012A075`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A076`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shock"
                  name={`Q012.${chartCode}${sectionId}Q012A077`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="traumatic"
                  name={`Q012.${chartCode}${sectionId}Q012A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypovolemic"
                  name={`Q012.${chartCode}${sectionId}Q012A079`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A080`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shoulder pain:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A109`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A081`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A082`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A083`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sickle Cell dz:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A110`}
                />
                <ChartRowElement
                  label="with crisis"
                  name={`Q012.${chartCode}${sectionId}Q012A084`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Hb:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A111`}
                />
                <ChartRowElement
                  label="SS"
                  name={`Q012.${chartCode}${sectionId}Q012A085`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="SC"
                  name={`Q012.${chartCode}${sectionId}Q012A086`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="S trait"
                  name={`Q012.${chartCode}${sectionId}Q012A087`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="S thal"
                  name={`Q012.${chartCode}${sectionId}Q012A088`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A089`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Syncope"
                  name={`Q012.${chartCode}${sectionId}Q012A090`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A091`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tooth #:"
                  type="cross-option"
                  name={`Q012.${chartCode}${sectionId}Q012A112`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A092`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="fx"
                  name={`Q012.${chartCode}${sectionId}Q012A093`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="avulsion"
                  name={`Q012.${chartCode}${sectionId}Q012A094`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sublux"
                  name={`Q012.${chartCode}${sectionId}Q012A095`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A096`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q012.${chartCode}${sectionId}Q012A097`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A098`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={<ChartSubsectionContent></ChartSubsectionContent>}
        />
      </ChartSection>
    </FormProvider>
  );
}

export { T73ClinicalImpression };
