import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseUpdateOrderFormFavoritesDto = {
  id?: string;
  userId?: string;
  favorite: boolean;
  orderFormElementId: string;
};

function useUpdateOrderFormFavorites() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateOrderFormFavorites"],
    (body: UseUpdateOrderFormFavoritesDto[]) =>
      axiosChartInstance.post(`v1/order-form/favorites/add`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orderFormFavorites"]);
      },
    }
  );
}
export { useUpdateOrderFormFavorites };
export type { UseUpdateOrderFormFavoritesDto };
