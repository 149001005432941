import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Phone = createIcon({
  path: (
    <>
      <path
        d="M26.1231 26.9063C24.5642 25.5112 22.3647 23.2049 20.0727 24.3082C17.9515 25.3261 18.2148 27.8104 15.0686 26.7497C12.1217 25.7603 9.18188 22.2439 8.06433 19.4323C6.85424 16.3857 8.91851 15.8661 10.634 13.8801C12.2569 11.9653 10.4845 9.46685 9.38831 7.75849C8.76191 6.76907 7.83655 5.35967 6.5624 5.19595C5.34519 5.05359 4.249 6.40604 3.43752 7.15345C-5.96557 15.7664 6.14243 27.3406 13.2179 32.2521C13.9582 32.7646 14.7127 33.2842 15.5313 33.6686C18.8412 35.199 22.564 34.957 25.3828 32.5866C26.2156 31.8891 27.7532 30.9139 27.803 29.7323C27.8599 28.5791 26.9132 27.6253 26.1231 26.9063Z"
        fill="currentColor"
      />
      <path
        d="M0.277344 14.1643C0.277344 13.9935 0.298698 13.8298 0.305816 13.6661C0.29158 13.5878 0.277344 13.6732 0.277344 14.1643Z"
        fill="currentColor"
      />
      <path
        d="M20.0367 0C16.0577 0 12.2779 1.58735 9.48047 4.38479C10.0357 4.94712 10.4699 5.53793 10.8116 6.04332C13.2317 3.53773 16.5488 2.09986 20.0438 2.09986C27.1406 2.09986 32.9063 7.87269 32.9063 14.9624C32.9063 19.0909 30.9204 22.9347 27.6318 25.3407C28.0304 25.7108 28.55 26.2447 29.0198 26.928C32.7569 24.1235 35.0062 19.7031 35.0062 14.9624C34.9991 6.71243 28.2867 0 20.0367 0Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: "-5 -5 45 45",
});

export { Phone };
