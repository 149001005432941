import { Box, Text } from "@chakra-ui/react";
import { OrderFormElementCategory } from "enums";
import { ORDER_TYPE_BORDER_RADIUS, ORDER_TYPE_TEXT } from "../constants";

type OrderIconProps = {
  colorIcon?: string;
  orderType: OrderFormElementCategory;
};

function OrderTypeIcon(props: OrderIconProps) {
  const { colorIcon, orderType } = props;
  return (
    <Box
      display="flex"
      backgroundColor={colorIcon}
      w="1.6rem"
      h="1.6rem"
      borderRadius={ORDER_TYPE_BORDER_RADIUS[orderType]}
      alignItems="center"
      justifyContent="center"
    >
      <Text
        textAlign="center"
        fontSize="xs"
        color="white"
        lineHeight="1.375rem"
      >
        {ORDER_TYPE_TEXT[orderType]}
      </Text>
    </Box>
  );
}

export { OrderTypeIcon };
