import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartNoteApiUrlParams, getChartAudioNoteApiURL } from "../../utils";

function useCreateAudioChartNote(params: ChartNoteApiUrlParams) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createAudioChartNote", params],
    (input: FormData) =>
      axiosChartInstance.post(getChartAudioNoteApiURL(params), input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartNotes", params]);
        queryClient.invalidateQueries(["sectionNotesCount", params]);
        queryClient.invalidateQueries(["subSectionNotesCount", params]);
      },
    }
  );
}

export { useCreateAudioChartNote };
