import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EmrVitals } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { Chain } from "icons";
import { formMessages } from "messages";
import { useActiveCharts } from "modules/charts-shared";
import { useLinkChartToOrder } from "modules/orders/api";
import React from "react";
import { MdCheck } from "react-icons/md";
import { Button, DividerList, Loading, ToolbarHeader } from "shared";
import { extractApiErrorMessage } from "utils";

type LinkChartToOrdertModalProps = {
  isModalOpen: boolean;
} & LinkChartToOrderModalContentProps;

type LinkChartToOrderModalContentProps = {
  orderId: string;
  linkedId?: string;
  encounterId: string;
  onModalClose: () => void;
};

type ChartProviderItemProps = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
};

const ChartProviderItem = ({
  label,
  isSelected,
  ...rest
}: ChartProviderItemProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ cursor: "pointer" }}
      {...rest}
    >
      <Box display="flex" justifyContent="start" gap={4}>
        <Icon color="gray.450" as={EmrVitals} fontSize="1.5rem" />
        <Text color="black" fontSize="17px">
          {label}
        </Text>
      </Box>
      <Box>
        <Icon
          color={isSelected ? "blue" : "gray.450"}
          as={MdCheck}
          fontSize="1.5rem"
        />
      </Box>
    </Box>
  );
};

const LinkChartToOrderModalContent = ({
  orderId,
  linkedId,
  encounterId,
  onModalClose,
}: LinkChartToOrderModalContentProps) => {
  const toast = useToast();
  const [chartSelected, setChartSelected] = React.useState("");

  const { data, isLoading } = useActiveCharts(encounterId);
  const providerCharts =
    data?.data.filter((chart) => chart.chartType === "Chart") ?? [];

  React.useEffect(() => {
    if (linkedId && data) {
      setChartSelected(
        data.data.find((chart) => chart.chartApplicationId === linkedId)
          ?.chartApplicationId ?? ""
      );
    }
  }, [data, linkedId]);

  const { mutateAsync: LinkChart, isLoading: isLinkLoading } =
    useLinkChartToOrder({ orderId, encounterId });

  const handleDone = async () => {
    try {
      const payload = {
        chartApplicationId: chartSelected,
      };
      await LinkChart(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Order"),
      });
      onModalClose();
    } catch (e: unknown) {
      toast({
        status: "error",
        description: extractApiErrorMessage(e),
      });
    }
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <Text fontSize="1.0625rem" fontWeight="600" color="black">
              <Icon as={Chain} h="20px" w="20px" mr={2} />
              Link Report
            </Text>
          }
          rightButtons={[
            <Button
              key="doneBtn"
              onClick={handleDone}
              isDisabled={!chartSelected || isLinkLoading}
              isLoading={isLinkLoading}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody pb={4}>
        {isLoading && <Loading />}
        <VStack
          justifyContent="center"
          fontSize="1.0625rem"
          fontWeight="500"
          color="gray.600"
          spacing={10}
        >
          <Text width="100%" textAlign="center">
            Select a Provider Chart below to link the Report.
          </Text>
          <Box bgColor="white" borderRadius="25px" p={4} width="100%">
            <DividerList>
              {providerCharts.map((chart) => (
                <ChartProviderItem
                  key={chart.chartApplicationId}
                  label={chart.chartTitle}
                  onClick={() => {
                    setChartSelected(chart.chartApplicationId);
                  }}
                  isSelected={chartSelected === chart.chartApplicationId}
                />
              ))}
            </DividerList>
          </Box>
        </VStack>
      </ModalBody>
    </>
  );
};

const LinkChartToOrdertModal = ({
  isModalOpen,
  onModalClose,
  ...rest
}: LinkChartToOrdertModalProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <LinkChartToOrderModalContent onModalClose={onModalClose} {...rest} />
        )}
      </ModalContent>
    </Modal>
  );
};

export { LinkChartToOrdertModal };
