import * as React from "react";

import faker from "faker";
import cloneDeep from "lodash/cloneDeep";

import {
  chakra,
  Box,
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  UseDisclosureReturn,
  Flex,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import { Fragment, useState } from "react";
import {
  Button,
  Card,
  Label,
  Loading,
  SelectFilter,
  ToolbarHeader,
} from "shared";
import {
  ALLERGIES_OTHER_LABEL,
  ALLERGIES_SELECT_OPTIONS,
  AllergyOption,
  AllergyReaction,
  AllergyRx,
  ALLERGY_RX_TYPE_LABEL,
  REACTIONS_SELECT_OPTIONS,
} from "modules/charts-shared";
import { AllergyRxType } from "enums";
import { AddRounded } from "icons";

type AllergyItemModalProps = {
  item: AllergyRx | null;
  mode: "ADD" | "EDIT";
  onAddItem: (type: AllergyRxType, item: AllergyRx) => void;
  onUpdateItem: (type: AllergyRxType, item: AllergyRx) => void;
  onRemoveItem: (type: AllergyRxType, item: AllergyRx) => void;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type AllergyItemModalContentProps = Omit<
  AllergyItemModalProps,
  "isOpen" | "item"
> & {
  item: AllergyRx;
};

const defaultReaction: AllergyReaction = {
  scope: "CUSTOM",
  reaction: "",
};

function AllergyItemModalContent({
  item,
  mode,
  onClose,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
}: AllergyItemModalContentProps) {
  const toast = useToast();

  const [allergy, setAllergy] = useState("");
  const [isOtherAllergy, setIsOtherAllergy] = useState(false);

  const [reactions, setReactions] = useState<AllergyReaction[]>([]);

  const allergiesOptions = ALLERGIES_SELECT_OPTIONS.filter(
    (el) => el.type === item.type
  );
  const reactionsOptions = REACTIONS_SELECT_OPTIONS.filter(
    (el) => el.type === item.type
  );

  const [initialSelectedAllergy, setInitialSelectedAllergy] =
    React.useState<AllergyOption | null>(null);

  React.useEffect(() => {
    if (item) {
      setAllergy(item.allergy);
      setReactions(item.reactions);

      if (item.scope === "CUSTOM") {
        setInitialSelectedAllergy({
          value: ALLERGIES_OTHER_LABEL,
          type: item.type,
        });

        setIsOtherAllergy(true);
      } else {
        setInitialSelectedAllergy({
          value: item.allergy,
          type: item.type,
        });
      }
    }
  }, [item]);

  const onAllergyChanged = (value: string) => {
    if (value === ALLERGIES_OTHER_LABEL) {
      setAllergy("");
      setIsOtherAllergy(true);
    } else {
      setAllergy(value);
      setIsOtherAllergy(false);
    }
  };

  const onAddCustomReaction = () => {
    const updatedReactions = cloneDeep(reactions);
    updatedReactions.push({
      ...defaultReaction,
      scope: "CUSTOM",
      id: faker.datatype.uuid(),
    });

    setReactions(updatedReactions);
  };

  const onRemoveCustomReaction = (reaction: AllergyReaction) => {
    let updatedReactions = cloneDeep(reactions);
    updatedReactions = updatedReactions.filter((el) => el.id !== reaction.id);

    setReactions(updatedReactions);
  };

  const MAX_REACTION_LENGTH = 30;
  const onUpdateCustomReaction = (id: string, reaction: string) => {
    const updatedReactions = cloneDeep(reactions);

    const updatedIndex = updatedReactions.findIndex((el) => el.id === id);

    updatedReactions[updatedIndex] = {
      ...updatedReactions[updatedIndex],
      reaction: reaction.substring(0, MAX_REACTION_LENGTH),
    };

    setReactions(updatedReactions);
  };

  const onUpdatePresetReactions = (values: string[]) => {
    let updatedReactions = cloneDeep(reactions);
    updatedReactions = updatedReactions.filter((el) => el.scope !== "PRESET");

    values.map((el) => {
      updatedReactions.push({
        scope: "PRESET",
        reaction: el,
        id: faker.datatype.uuid(),
      });
    });

    setReactions(updatedReactions);
  };

  const handleSubmit = () => {
    if (allergy === "") {
      toast({
        description: "You need to set the name for the allergy.",
      });

      return;
    }

    if (reactions.filter((el) => el.reaction === "").length > 0) {
      toast({
        description:
          "You need to add at least one character for the allergies custom reactions.",
      });

      return;
    }

    const allergyPayload: AllergyRx = {
      ...item,
      allergy,
      reactions: cloneDeep(reactions),
      scope: isOtherAllergy ? "CUSTOM" : "PRESET",
    };

    if (mode === "ADD") {
      onAddItem(item.type, allergyPayload);
    } else if (mode === "EDIT") {
      onUpdateItem(item.type, allergyPayload);
    }

    onClose();
  };

  const handleRemove = () => {
    if (mode === "EDIT") {
      onRemoveItem(item.type, item);
    }

    onClose();
  };

  return (
    <ModalContent maxW="30%" minH="85%" bg="gray.200">
      <ModalHeader textAlign="center">
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              {ALLERGY_RX_TYPE_LABEL[item.type]}
            </chakra.span>
          }
          leftButtons={[
            <Button key="cancelBtn" onClick={onClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button key="doneBtn" onClick={handleSubmit}>
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody display="flex" flexDirection="column">
        {item !== null && initialSelectedAllergy !== null ? (
          <Box flex={1}>
            <Flex width="100%" marginBottom="12px" direction="column">
              <Label>Allergy</Label>

              <Box display="flex">
                <SelectFilter
                  items={allergiesOptions}
                  labelAccessor="value"
                  valueAccessor="value"
                  initialSelectedItem={initialSelectedAllergy}
                  onSelectedItemChange={(changes) => {
                    onAllergyChanged(changes.selectedItem?.value || "");
                  }}
                />
              </Box>
            </Flex>

            {isOtherAllergy && (
              <Flex width="100%" marginTop="24px" marginBottom="12px">
                <Input
                  placeholder="Type other allergy here"
                  value={allergy}
                  onChange={(e) => setAllergy(e.target.value ?? "")}
                />
              </Flex>
            )}

            <Flex
              width="100%"
              marginTop="24px"
              marginBottom="12px"
              direction="column"
            >
              <Label mt="10px">Reactions (Choose at least one) *</Label>
              <Card variant="basic" p="15px">
                <CheckboxGroup
                  value={reactions
                    .filter((el) => el.scope === "PRESET")
                    .map((el) => el.reaction)}
                  onChange={(values: string[]) =>
                    onUpdatePresetReactions(values)
                  }
                >
                  <Stack>
                    {reactionsOptions.map((el, i) => (
                      <Fragment key={i}>
                        <Checkbox value={el.value}>{el.value}</Checkbox>
                      </Fragment>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </Card>
            </Flex>

            <Flex
              width="100%"
              marginTop="24px"
              marginBottom="12px"
              direction="column"
            >
              {reactions.filter((el) => el.scope === "CUSTOM").length > 0 && (
                <Label>Other Reactions (maximum 30 characters)</Label>
              )}

              <Box
                display="flex"
                flexDirection="column"
                flex={1.5}
                overflow="auto"
                overflowX="hidden"
                gap="10px"
              >
                {reactions
                  .filter((el) => el.scope === "CUSTOM")
                  .map((el, i) => (
                    <InputGroup key={i}>
                      <Input
                        value={el.reaction}
                        onChange={(e) =>
                          onUpdateCustomReaction(
                            el.id ?? i.toString(),
                            e.target.value ?? ""
                          )
                        }
                      />
                      <InputRightElement>
                        <Icon
                          as={EmrRemove}
                          color="red"
                          cursor="pointer"
                          onClick={() => onRemoveCustomReaction(el)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  ))}
              </Box>
            </Flex>

            <Flex
              width="100%"
              marginLeft="15px"
              marginTop="24px"
              marginBottom="12px"
              direction="row"
              onClick={() => onAddCustomReaction()}
            >
              <Button
                position="relative"
                right="8px"
                fontSize="0.9375rem"
                color="blue"
                display="flex"
                variant="label"
                alignItems="center"
              >
                <Icon as={AddRounded} cursor="pointer" fontSize="1.225rem" />
              </Button>
              <chakra.span
                color="blue"
                fontSize="1.0625rem"
                position="relative"
              >
                add other reaction
              </chakra.span>
            </Flex>
          </Box>
        ) : (
          <Box h="100%" alignItems="center">
            <Loading />
          </Box>
        )}
      </ModalBody>

      <ModalFooter justifyContent="space-around" borderBottomRadius="md">
        <Button
          width="100%"
          variant="solid"
          color="white"
          borderColor="red"
          colorScheme="red"
          onClick={handleRemove}
        >
          Delete
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

function AllergyItemModal({
  isOpen,
  onClose,
  item,
  mode,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
}: AllergyItemModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      {isOpen && item && (
        <AllergyItemModalContent
          onAddItem={onAddItem}
          onUpdateItem={onUpdateItem}
          onRemoveItem={onRemoveItem}
          onClose={onClose}
          item={item}
          mode={mode}
        />
      )}
    </Modal>
  );
}

export { AllergyItemModal };
