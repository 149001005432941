import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartType } from "types";
import { UseChartsListParams } from "../queries";

function usePutFavoriteChart(chartType: ChartType) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["putFavoriteChart", { chartType }],
    (chartCode: string) => {
      return axiosChartInstance.put<string>(`v1/Chart/favorite/${chartCode}`);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === "chartsList" &&
            (query.queryKey[1] as UseChartsListParams)?.type === chartType,
        }),
    }
  );
}

export { usePutFavoriteChart };
