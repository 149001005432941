import {
  Box,
  Button,
  chakra,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useControllableState,
  useDisclosure,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { EmrBed, EmrRemove } from "@medstonetech/slate-icons";
import { config } from "config";
import { useInputMenu, useToast } from "hooks";
import * as React from "react";
import { BackButton, InfiniteList, SearchBar } from "shared";
import { extractApiErrorMessage, isLastIndex } from "utils";
import { ModalInputButton } from "../ModalInputButton";
import {
  AssignBedRequest,
  BedDto,
  UnassignBedRequest,
  UpdateBedPayload,
  useAssignBed,
  useBeds,
  useUnassignBed,
  useUpdateBeds,
} from "modules/room-list/api";
import { debounce } from "throttle-debounce";
import { RoomBedDto } from "modules/in-process/api";
import { formMessages } from "messages";
import { RoomManagementDetailsModal } from "modules/room-management/components";

type RoomInputProps = {
  value?: RoomBedDto | null;
  defaultValue?: RoomBedDto;
  onChange?: (newValue: RoomBedDto | null) => void;
  encounterId: string;
  disabled?: boolean;
};

type RoomModalProps = Pick<UseDisclosureReturn, "isOpen" | "onClose"> & {
  onChange?: (newValue: RoomBedDto | null) => void;
  initialValue?: RoomBedDto | null;
  encounterId: string;
};

type RoomModalContentProps = Pick<
  RoomModalProps,
  "initialValue" | "onChange" | "onClose" | "encounterId"
>;

type PreviousAssignedBedOptionsProps = {
  roomBed: RoomBedDto;
  isOpen: boolean;
  onClose: () => void;
};

const PreviousAssignedBedOptions = ({
  roomBed,
  isOpen,
  onClose,
}: PreviousAssignedBedOptionsProps) => {
  const toast = useToast();
  const RoomManagementDetailsDisclosure = useDisclosure();

  const { mutateAsync: updateBeds, isLoading: isUpdateBedsLoading } =
    useUpdateBeds(roomBed.roomId);

  const handleUpdateBed = async () => {
    try {
      const payload: UpdateBedPayload = [
        {
          bedId: roomBed.bedId,
          roomId: roomBed.roomId,
          name: roomBed.bedName,
          status: "Cleaning",
        },
      ];
      await updateBeds(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Bed"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="gray.100"
        maxW="unset"
        width="400px"
        height="381px"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader display="flex" justifyContent="center">
          <chakra.span fontSize="17px" fontWeight="600" m="auto">
            Assigned Bed Updated
          </chakra.span>
        </ModalHeader>
        <ModalBody>
          <VStack>
            <chakra.span fontSize="15px" fontWeight="500" m="auto">
              Last Room/Bed
            </chakra.span>
            <chakra.span fontSize="21px" fontWeight="600" m="auto">
              {roomBed.bedCode}
            </chakra.span>
            <chakra.span fontSize="13px" fontWeight="400" m="auto" pb={4}>
              Do you need the last room or bed to be cleaned or closed?
            </chakra.span>
            <Button
              variant="outlineSquared"
              w="100%"
              borderRadius="30px"
              onClick={handleUpdateBed}
              isLoading={isUpdateBedsLoading}
            >
              Clean Bed
            </Button>
            <Button
              variant="outlineSquared"
              w="100%"
              borderRadius="30px"
              onClick={RoomManagementDetailsDisclosure.onOpen}
              isDisabled={isUpdateBedsLoading}
            >
              Go to Room Details
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid" borderColor="gray.450">
          <Button
            variant="label"
            w="100%"
            color="gray.650"
            onClick={onClose}
            isDisabled={isUpdateBedsLoading}
          >
            No Thanks
          </Button>
        </ModalFooter>
      </ModalContent>
      <RoomManagementDetailsModal
        roomId={roomBed.roomId}
        {...RoomManagementDetailsDisclosure}
      />
    </Modal>
  );
};

function RoomModalContent(props: RoomModalContentProps) {
  const toast = useToast();

  const previousAssignedBedOptionsDisclosure = useDisclosure();

  const { initialValue, onChange, onClose, encounterId } = props;
  const [search, setSearch] = React.useState("");
  const [oldValue, setOldValue] = React.useState(initialValue);

  const onSearch = React.useMemo(
    () =>
      debounce<React.ChangeEventHandler<HTMLInputElement>>(1000, (e) => {
        setSearch(e.target.value);
      }),
    []
  );

  const {
    data,
    error: bedsError,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useBeds({
    search,
    size: 20,
    assigned: false,
    bedStatus: "Active",
  });

  const rows = React.useMemo(
    () =>
      data?.pages.reduce<BedDto[]>(
        (accum, curr) => [...accum, ...curr.data.content],
        []
      ) || [],
    [data]
  );

  const fetchMore = React.useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  const selectBed = React.useCallback(
    async (newValue: BedDto | null) => {
      try {
        if (newValue) {
          onChange?.({
            bedId: newValue.id,
            roomId: newValue.roomId,
            bedCode: newValue.bedCode,
            bedName: newValue.name,
            roomName: "",
          });
        }
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [onChange, toast]
  );

  const {
    getMenuItemProps,
    getMenuProps,
    selectedItem: selectedItemMenu,
    setSelectedItem,
  } = useInputMenu({
    items: rows || [],
    defaultSelectedItem: initialValue
      ? {
          id: initialValue.bedId,
          name: initialValue.bedName,
          roomId: initialValue.roomId,
          bedCode: initialValue.bedCode,
          status: "Active",
        }
      : null,
    onSelectedItemChange: selectBed,
  });

  React.useEffect(() => {
    if (bedsError) {
      toast({ description: extractApiErrorMessage(bedsError) });
    }
  }, [toast, bedsError]);

  const { mutateAsync: assignBed, isLoading: isAssignBedLoading } =
    useAssignBed(encounterId);

  const { mutateAsync: unassignBed, isLoading: isUnassignBedLoading } =
    useUnassignBed(encounterId);

  const handleSaveAndClose = async (selectedItem: BedDto | null) => {
    try {
      if (selectedItem) {
        const payload: AssignBedRequest = {
          bedId: selectedItem.id,
          bedCode: selectedItem.bedCode,
          encounterId,
        };
        await assignBed(payload);
      } else if (oldValue) {
        const payload: UnassignBedRequest = {
          bedCode: oldValue.bedCode,
          encounterId,
        };
        await unassignBed(payload);
      }

      toast({
        status: "success",
        description: formMessages.updateSuccess("Bed"),
      });

      if (selectedItem && oldValue) {
        previousAssignedBedOptionsDisclosure.onOpen();
      }

      setOldValue(
        selectedItem
          ? {
              bedId: selectedItem.id,
              roomId: selectedItem.roomId,
              roomName: selectedItem.name,
              bedName: selectedItem.bedCode,
              bedCode: selectedItem.bedCode,
            }
          : null
      );
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 0"
      >
        <BackButton onClick={onClose} fontSize="16px" fontWeight="400">
          Details
        </BackButton>
        <Heading fontWeight="600" fontSize="17px">
          Assign Room/Bed
        </Heading>
        <Box w="60px" />
      </ModalHeader>
      <ModalBody padding="34px 16px 16px">
        <Heading fontSize="15px" fontWeight="500" mb="10px" ml={3}>
          Selected
        </Heading>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="18px 21px"
          borderRadius="10px"
          bg="white"
          height="52px"
          minHeight="52px"
        >
          {selectedItemMenu && (
            <>
              <HStack spacing="21px">
                <Icon as={EmrBed} fontSize="1.25rem" color="blue" />
                <Box fontSize="17px" fontWeight="600">
                  {selectedItemMenu.bedCode}
                </Box>
              </HStack>
              <IconButton
                aria-label="remove selected room"
                variant="icon"
                icon={<Icon as={EmrRemove} color="red" fontSize="1.375rem" />}
                onClick={async () => {
                  setSelectedItem(null);
                  await handleSaveAndClose(null);
                }}
                isLoading={isAssignBedLoading || isUnassignBedLoading}
              />
            </>
          )}
        </Box>
        <Box margin="20px 0">
          <SearchBar
            placeholder="Search"
            height="36px"
            bg="gray.350"
            onChange={onSearch}
          />
        </Box>
        <Box
          borderRadius="10px"
          bg="white"
          overflow="hidden"
          outline="none"
          {...getMenuProps()}
          minH="50px"
        >
          <InfiniteList
            fetchMore={fetchMore}
            hasMore={!!hasNextPage}
            isLoading={isLoading}
            renderRow={(item, index) => (
              <>
                <Box
                  width="510px"
                  display="flex"
                  px={4}
                  py={4}
                  justifyContent="space-between"
                  alignItems="center"
                  {...getMenuItemProps({ item, index })}
                  onClick={async () => {
                    setSelectedItem(item);
                    await handleSaveAndClose(item);
                  }}
                  cursor="pointer"
                >
                  <HStack spacing="21px">
                    <Icon as={EmrBed} fontSize="1.25rem" color="blue" />
                    <Box fontWeight="600">{item.bedCode}</Box>
                  </HStack>
                </Box>
                {!isLastIndex(index, rows) && (
                  <Divider
                    width={`calc(100% - 56px)`}
                    ml="56px"
                    mt={1}
                    h="0.5px"
                  />
                )}
              </>
            )}
            rows={rows}
          />
        </Box>
      </ModalBody>
      {oldValue && (
        <PreviousAssignedBedOptions
          roomBed={oldValue}
          {...previousAssignedBedOptionsDisclosure}
        />
      )}
    </>
  );
}

function RoomModal(props: RoomModalProps) {
  const { isOpen, onClose, onChange, initialValue, encounterId } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end", alignItems: "center" }}
        height="98%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
        mr="10px"
      >
        {isOpen && (
          <RoomModalContent
            onClose={onClose}
            onChange={onChange}
            initialValue={initialValue}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

const RoomInput = React.forwardRef<HTMLButtonElement, RoomInputProps>(
  (props, ref) => {
    const {
      defaultValue,
      onChange,
      value: valueProp,
      encounterId,
      disabled = false,
    } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [value, setValue] = useControllableState({
      defaultValue,
      onChange,
      value: valueProp,
    });

    return (
      <>
        <ModalInputButton
          ref={ref}
          icon={<Icon as={EmrBed} fontSize="1.25rem" color="gray.600" />}
          label="Assigned Room/Bed"
          value={value?.bedCode || ""}
          onClick={onOpen}
          disabled={disabled}
        />
        <RoomModal
          isOpen={isOpen}
          onClose={onClose}
          onChange={setValue}
          initialValue={value}
          encounterId={encounterId}
        />
      </>
    );
  }
);

if (config.isDev) {
  RoomInput.displayName = "RoomInput";
}

export { RoomInput };
