import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import {
  ChartFavorite,
  ChartFavoriteRequest,
} from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type AddChartFavoriteProps = {
  sectionCode: string;
  chartCode: string;
};

function useAddChartFavorite({
  sectionCode,
  chartCode,
}: AddChartFavoriteProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["addChartFavorite", { sectionCode, chartCode }],
    (payload: ChartFavoriteRequest) =>
      axiosChartInstance.post<ChartFavorite>(`v1/favorites`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartFavorites",
          { sectionCode, chartCode },
        ]);
      },
    }
  );
}

export { useAddChartFavorite };
