import { Box, BoxProps, HStack } from "@chakra-ui/react";
import * as React from "react";

type OrderHeaderCardProps = {
  counter: number;
  title: string;
  icon: React.ReactNode;
} & Omit<BoxProps, "children">;

type DashboardOrderHeaderCardProps = Omit<
  OrderHeaderCardProps,
  "title" | "icon"
>;

function OrderHeaderCard(props: OrderHeaderCardProps) {
  const { counter, icon, title, color, ...restProps } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="18px 20px 18px 28px"
      height="65px"
      width="100%"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      {...restProps}
    >
      <HStack spacing="23px">
        {React.isValidElement(icon) &&
          React.cloneElement(icon, {
            fontSize: "1.5rem",
            color: color ?? "white",
          })}
        <Box fontSize="1.25rem" fontWeight="600" color={color ?? "white"}>
          {title}
        </Box>
      </HStack>
      <Box
        fontSize="1.875rem"
        fontWeight="600"
        color={color ?? "white"}
        lineHeight="1.375rem"
      >
        {counter}
      </Box>
    </Box>
  );
}

export type { OrderHeaderCardProps, DashboardOrderHeaderCardProps };
export { OrderHeaderCard };
