import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Textarea,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import {
  EmrCheck,
  EmrHeart,
  EmrLiquid,
  EmrPlus,
  SiPlus,
} from "@medstonetech/slate-icons";
import faker from "faker";
import { useToast, useYupValidationResolver } from "hooks";
import { ExclamationTriangle, Meal, RFilled, Restricted } from "icons";
import { formMessages, genericErrors } from "messages";
import { useEncounterDiet, usePutEncounterDiet } from "modules/in-process/api";
import { EncounterDiet, FoodOrder } from "modules/in-process/types";
import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Card,
  CheckboxListGroup,
  CheckboxListItem,
  EncounterAvatar,
  IconCardRadio,
  IconCardRadioItem,
  Loading,
  ToolbarHeader,
  WarningDialog,
} from "shared";
import * as yup from "yup";

import { usePermissions } from "contexts";
import { format } from "date-fns";
import { useEncounterAllergiesMedicationsRx } from "modules/charts-shared";

type DietPatient = {
  fullName: string;
  hasDrugAllergies?: boolean;
  hasFoodAllergies?: boolean;
  encounterId: string;
};

type PatientDietModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  patient?: DietPatient;
};

type PatientDietModalContentProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  patient: DietPatient;
};

type FoodOrderCardProps = {
  foodOrder: FoodOrder;
  index: number;
  mealNumber: number;
  deleteFoodOrder: () => void;
};

const schema = yup.object({
  typeOfDiet: yup.string(),
  notes: yup.string(),
  foodAllergies: yup.array(),
  foodOrders: yup.array(),
});

function FoodOrderCard({
  foodOrder,
  index,
  mealNumber,
  deleteFoodOrder,
}: FoodOrderCardProps) {
  const { watch, setValue } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnMealChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(`foodOrders[${index}]`, { ...foodOrder, meal: e.target.value });
  };

  const handleOnDeliveredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`foodOrders[${index}]`, {
      ...foodOrder,
      delivered: e.target.checked,
      timeDelivered: e.target.checked ? new Date().toISOString() : null,
    });
  };

  const meal = watch(`foodOrders[${index}].meal`);
  const delivered = watch(`foodOrders[${index}].delivered`);
  return (
    <Box>
      <Card
        display="flex"
        flexDirection="column"
        rowGap="10px"
        py="14px"
        variant="basic"
      >
        <Flex
          fontSize="1.063rem"
          justifyContent="center"
          fontWeight="500"
          color="gray.650"
          alignItems="center"
          columnGap="15px"
        >
          <Icon w="32px" h="32px" as={Meal} color="gray.650" /> Meal{" "}
          {mealNumber}
        </Flex>
        <Box px="15px">
          <Textarea
            resize="none"
            value={meal}
            onChange={handleOnMealChange}
            height="90px"
            minH="unset"
          />
        </Box>
        <Flex justifyContent="space-between" px="15px">
          <Text fontSize="1.063rem" fontWeight="400">
            Time Ordered
          </Text>
          <Text fontSize="1.063rem" fontWeight="500" color="gray.700">
            {format(new Date(foodOrder.timeOrdered), "MM/dd/yyyy p")}
          </Text>
        </Flex>
        <Flex pl="15px">
          <Divider height="0px" />
        </Flex>
        <Flex px="15px">
          <Text fontSize="1.063rem" fontWeight="400" flex={1}>
            Delivered
          </Text>
          <Flex columnGap="10px">
            <Text fontSize="1.063rem" fontWeight="500" color="gray.700">
              {delivered ? "Yes" : "No"}
            </Text>
            <Switch
              colorScheme="green"
              size="lg"
              isChecked={delivered}
              onChange={handleOnDeliveredChange}
            />
          </Flex>
        </Flex>
        <Flex pl="15px">
          <Divider height="0px" />
        </Flex>
        <Flex justifyContent="space-between" px="15px">
          <Text fontSize="1.063rem" fontWeight="400">
            Time Delivered
          </Text>
          <Text fontSize="1.063rem" fontWeight="500" color="gray.700">
            {foodOrder.timeDelivered
              ? format(new Date(foodOrder.timeDelivered), "MM/dd/yyyy p")
              : ""}
          </Text>
        </Flex>
      </Card>
      <Button
        variant="text"
        color="red"
        p="0"
        m="0"
        minW="unset"
        my="15px"
        ml="15px"
        onClick={onOpen}
      >
        Remove
      </Button>
      <WarningDialog
        isOpen={isOpen}
        mainText="Are you sure you want to Delete this Meal?"
        title="Warning!"
        actionLabel="Delete"
        onCancel={onClose}
        onAction={deleteFoodOrder}
        onClose={onClose}
      />
    </Box>
  );
}

type DietForm = {
  typeOfDiet: string;
  notes: string;
  foodAllergies: string[];
  foodOrders: FoodOrder[];
};

function PatientDietModalContent(props: PatientDietModalContentProps) {
  const {
    patient,
    patient: { encounterId },
    onModalClose,
  } = props;
  const toast = useToast();
  const formContext = useForm<DietForm>({
    resolver: useYupValidationResolver(schema),
    defaultValues: {
      typeOfDiet: "Clear_liquid",
      notes: "",
      foodAllergies: [],
      foodOrders: [],
    },
  });
  const { register, control, setValue, watch, handleSubmit } = formContext;
  const { remove, prepend } = useFieldArray({
    control,
    name: "foodOrders",
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("inprocess:diet").isEditable;

  const { data: allergies } = useEncounterAllergiesMedicationsRx({
    encounterId,
  });

  const { data, isLoading: isDietLoading } = useEncounterDiet({ encounterId });
  const [showNotes, setShowNotes] = useState(false);
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningCLose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const watchFoodAllergies = watch("foodAllergies");
  const foodOrders = watch("foodOrders");

  const foodAllergies = useMemo(() => {
    const foodAllergiesDefault = [
      { value: "Peanut", label: "Peanut", selected: false },
      { value: "Milk", label: "Milk", selected: false },
      { value: "Egg", label: "Egg", selected: false },
      { value: "Soy", label: "Soy", selected: false },
      { value: "Wheat", label: "Wheat", selected: false },
      { value: "ThreeNut", label: "Tree Nut", selected: false },
      { value: "Shellfish", label: "Shellfish", selected: false },
      { value: "Fish", label: "Fish", selected: false },
      { value: "Sesame", label: "Sesame", selected: false },
      { value: "Other", label: "Other", selected: false },
    ].map(({ selected, ...rest }) => {
      return {
        ...rest,
        selected:
          (watchFoodAllergies || []).find((foodAllergyselected: string) => {
            return foodAllergyselected === rest.value;
          }) !== undefined,
      };
    });
    const foodAllergySelected = !!foodAllergiesDefault.find(
      (fa) => fa.selected
    );
    if (foodAllergySelected) onOpen();
    else onClose();
    return foodAllergiesDefault;
  }, [watchFoodAllergies, onOpen, onClose]);

  useEffect(() => {
    if (data?.data.typeOfDiet) setValue("typeOfDiet", data?.data.typeOfDiet);
    if (data?.data.foodAllergies)
      setValue("foodAllergies", data?.data.foodAllergies);
    if (data?.data.notes) {
      setValue("notes", data?.data.notes);
      setShowNotes(true);
    }
    if (data?.data.foodOrders) {
      setValue("foodOrders", data?.data.foodOrders);
    }
  }, [data, setValue]);

  const { mutateAsync: putInProcessDiet, isLoading } = usePutEncounterDiet(
    patient?.encounterId || ""
  );

  const onSubmit: SubmitHandler<EncounterDiet> = async (dataForm) => {
    try {
      const payload: EncounterDiet = {
        ...dataForm,
      };
      await putInProcessDiet(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Patient Diet"),
      });
      onModalClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  const handleAddNewFoodOrder = () => {
    const currentOrders = foodOrders ?? [];
    const lastOrder = currentOrders[0];
    if (!lastOrder || lastOrder?.delivered) {
      prepend({
        id: faker.datatype.uuid(),
        meal: "",
        timeOrdered: new Date(),
        timeDelivered: undefined,
        delivered: false,
      });
    } else {
      onWarningOpen();
    }
  };

  const handleDeleteFoodOrder = (index: number) => {
    remove(index);
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              Food & Diet
            </chakra.span>
          }
          leftButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              isDisabled={isReadOnly}
            >
              Done
            </Button>,
          ]}
          isLoading={isLoading}
        />
      </ModalHeader>
      <ModalBody padding="16px" fontSize="1.0625rem">
        <FormProvider {...formContext}>
          <form onSubmit={(e) => e.preventDefault()}>
            {isDietLoading && <Loading padding="120px 0px" />}
            {!isDietLoading && (
              <Flex overflow="hidden" columnGap="15px">
                <Box flex={1}>
                  <Flex justify="center">
                    <EncounterAvatar
                      encounterId={encounterId}
                      height="100px"
                      width="100px"
                    />
                  </Flex>
                  <Box
                    marginTop="14px"
                    textAlign="center"
                    fontSize="1.625rem"
                    fontWeight="500"
                  >{`${patient?.fullName}`}</Box>
                  <Box
                    className="typeSelectorContainer"
                    marginBottom="15px"
                    marginTop="50px"
                  >
                    <Controller
                      name="typeOfDiet"
                      control={control}
                      render={({ field }) => (
                        <IconCardRadio isDisabled={isReadOnly} {...field}>
                          <IconCardRadioItem
                            value="Regular"
                            label="Regular"
                            icon={<Icon as={RFilled} />}
                          />
                          <IconCardRadioItem
                            value="Healthy"
                            label="Healthy"
                            icon={<Icon as={EmrHeart} />}
                          />
                          <IconCardRadioItem
                            value="NPO"
                            label="NPO"
                            icon={<Icon as={Restricted} />}
                          />
                          <IconCardRadioItem
                            value="Clear_liquid"
                            label="Clear Liquid"
                            icon={<Icon as={EmrLiquid} />}
                          />
                        </IconCardRadio>
                      )}
                    />
                  </Box>
                  <Flex direction="column" gridGap="15px">
                    <Box>
                      {!showNotes && (
                        <Button
                          variant="ghost"
                          color="blue"
                          fontSize="1.0625rem"
                          disabled={isReadOnly}
                          leftIcon={
                            <Icon
                              borderRadius="1rem"
                              color="white"
                              backgroundColor="blue"
                              as={SiPlus}
                            />
                          }
                          onClick={() => setShowNotes(true)}
                        >
                          add notes
                        </Button>
                      )}
                      {showNotes && (
                        <>
                          <chakra.span color="gray.650" paddingStart="16px">
                            Notes
                          </chakra.span>
                          <Input
                            backgroundColor="white"
                            colorScheme="white"
                            {...register("notes")}
                          />
                        </>
                      )}
                    </Box>
                    <Box overflow="hidden" height="280px" mt="70px">
                      <Card
                        boxShadow="none"
                        border="none"
                        borderRadius="0.625rem"
                        sx={{ overflowY: "auto" }}
                        height="100%"
                      >
                        <Flex
                          direction="row"
                          alignItems="flex-start"
                          p="14px 0 14px 14px"
                        >
                          <Flex
                            p="6px"
                            backgroundColor={
                              patient.hasDrugAllergies ||
                              patient.hasFoodAllergies
                                ? "red"
                                : "gray"
                            }
                            borderRadius="5px"
                            marginRight="15px"
                            mt="5px"
                          >
                            <Icon as={ExclamationTriangle} color="white" />
                          </Flex>
                          <Box flex={1}>
                            <Text fontSize="1.063rem" fontWeight={500}>
                              Allergies
                            </Text>
                            <Text flex="1" fontSize="0.875rem" color="gray.700">
                              Allergies can be added/removed in the Allergies
                              section.
                            </Text>
                            <Divider
                              backgroundColor="gray.450"
                              mt="10px"
                              height="0"
                            />
                            {allergies?.data?.allergiesRx.map(
                              ({ allergy }, index) => (
                                <>
                                  <Flex
                                    alignItems="center"
                                    columnGap="15px"
                                    my="6px"
                                  >
                                    <Icon
                                      w="24px"
                                      h="22px"
                                      as={ExclamationTriangle}
                                      color="red"
                                    />
                                    <Box flex={1}>
                                      <Text
                                        fontSize="1.063rem"
                                        fontWeight={500}
                                      >
                                        {allergy}
                                      </Text>
                                      <Box>
                                        <Text
                                          fontSize="0.875rem"
                                          color="gray.700"
                                          lineHeight="0.875rem"
                                        >
                                          Drug Allergy
                                        </Text>
                                        {index <
                                          allergies.data.allergiesRx.length -
                                            1 && (
                                          <Divider
                                            backgroundColor="gray.450"
                                            height="0"
                                            mt="10px"
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                  </Flex>
                                </>
                              )
                            )}
                          </Box>
                        </Flex>
                        <Collapse in={isOpen}>
                          <Box
                            paddingLeft="40px"
                            overflow="auto"
                            maxHeight="30vh"
                          >
                            <Controller
                              name="foodAllergies"
                              control={control}
                              render={({ field: { onChange, ...rest } }) => (
                                <CheckboxListGroup
                                  onChange={(value) => {
                                    onChange(value);
                                  }}
                                  {...rest}
                                >
                                  {foodAllergies.map(
                                    ({ value, label, selected }) => (
                                      <CheckboxListItem
                                        key={value}
                                        value={value}
                                        color="gray.700"
                                      >
                                        <Flex
                                          direction="row"
                                          alignItems="center"
                                          flexGrow={1}
                                        >
                                          <Icon
                                            marginRight="22px"
                                            as={ExclamationTriangle}
                                          />
                                          <chakra.span flexGrow={1}>
                                            {label}
                                          </chakra.span>
                                          {selected && (
                                            <Icon
                                              marginRight="8px"
                                              as={EmrCheck}
                                            />
                                          )}
                                        </Flex>
                                      </CheckboxListItem>
                                    )
                                  )}
                                </CheckboxListGroup>
                              )}
                            />
                          </Box>
                        </Collapse>
                      </Card>
                    </Box>
                  </Flex>
                </Box>
                <Box flex={1} maxHeight="750px" overflowY="auto">
                  <Flex justifyContent="center" mb="25px">
                    <Button
                      variant="outlineSquared"
                      onClick={handleAddNewFoodOrder}
                      isDisabled={isReadOnly}
                    >
                      <Icon as={EmrPlus} mr="1rem" /> Add New Meal
                    </Button>
                  </Flex>
                  <Box p="15px" display="flex" flexDirection="column">
                    {foodOrders?.map((foodOrder, index) => (
                      <FoodOrderCard
                        key={foodOrder.id}
                        foodOrder={foodOrder}
                        index={index}
                        mealNumber={foodOrders.length - index}
                        deleteFoodOrder={() => handleDeleteFoodOrder(index)}
                      />
                    ))}
                  </Box>
                </Box>
              </Flex>
            )}
          </form>
        </FormProvider>
      </ModalBody>
      <WarningDialog
        isOpen={isWarningOpen}
        mainText="You must deliver the last meal to order a new one."
        title="Warning!"
        actionLabel="OK"
        actionButtonProps={{ color: "blue" }}
        onCancel={onWarningCLose}
        onAction={onWarningCLose}
        onClose={onWarningCLose}
        onlyActionButton
      />
    </>
  );
}

function PatientDietModal(props: PatientDietModalProps) {
  const { patient, isModalOpen, onModalClose } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxWidth="1080px"
        height="1040px"
        maxH="900px"
        overflow="hidden"
      >
        {patient && isModalOpen && (
          <PatientDietModalContent
            patient={patient}
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { PatientDietModal };
