import {
  Box,
  Button,
  HStack,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { EmrEyeHidden, EmrEyeVisible } from "@medstonetech/slate-icons";
import { AddRounded } from "icons";
import * as React from "react";
import { DiagramsPickerModal } from "./DiagramsPickerModal";

type DiagramsPreviewProps = React.PropsWithChildren<{
  title: string;
  enableEditMode?: boolean;
  isLoading?: boolean;
  encounterId?: string;
  chartCode?: string;
  enableHideButton?: boolean;
}>;

const DiagramsPreviewContext = React.createContext<{ isEditMode: boolean }>({
  isEditMode: false,
});

type AddNewDiagramButtonProps = {
  encounterId: string;
  chartCode: string;
};

const AddNewDiagramButton = (props: AddNewDiagramButtonProps) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Button
      variant="label"
      width="160px"
      height="130px"
      bg="transparent"
      padding="10px 17px"
      color="gray.650"
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={onOpen}
    >
      <VStack>
        <Icon as={AddRounded} fontSize="2rem" color="blue" />
        <Box color="blue" textAlign="center" lineHeight="1.25rem">
          Add New Image
        </Box>
      </VStack>
      <React.Suspense fallback={"Loading..."}>
        <DiagramsPickerModal isOpen={isOpen} onClose={onClose} {...props} />
      </React.Suspense>
    </Button>
  );
};

function DiagramsPreview(props: DiagramsPreviewProps) {
  const {
    title,
    enableEditMode = false,
    isLoading,
    encounterId,
    chartCode,
    children,
    enableHideButton,
  } = props;
  const [isEditMode, setIsEditMode] = React.useState(false);
  const contextValue = React.useMemo(() => ({ isEditMode }), [isEditMode]);
  const [isHide, setIsHide] = React.useState(enableHideButton);
  const childrenLength = React.Children.toArray(children).length;

  return (
    <VStack spacing="12px" width="100%" alignItems="flex-start" height="194px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text
          fontSize="1.25rem"
          fontWeight="700"
          display="flex"
          alignItems="middle"
        >
          {title}
          {isLoading && <Spinner ml={2} color="blue" />}
          {enableHideButton && (
            <Button
              variant="label"
              color={isHide ? "blue" : "red"}
              onClick={() => setIsHide((x) => !x)}
            >
              <Icon
                as={isHide ? EmrEyeVisible : EmrEyeHidden}
                color={!isHide ? "red" : undefined}
                filter={
                  isHide
                    ? "invert(35%) sepia(70%) saturate(4133%) hue-rotate(199deg) brightness(100%) contrast(107%)"
                    : undefined
                }
                fontSize="24px"
                ml={4}
                mr={2}
              />
              {isHide ? "Show" : "Hide"}
            </Button>
          )}
        </Text>
        {enableEditMode && (
          <Button
            variant="label"
            color="blue"
            onClick={() => setIsEditMode((x) => !x)}
          >
            {isEditMode ? "Done" : "Edit"}
          </Button>
        )}
      </Box>

      <HStack spacing="15px" width="100%" overflow="auto">
        {!isHide && (
          <DiagramsPreviewContext.Provider value={contextValue}>
            {children}
          </DiagramsPreviewContext.Provider>
        )}
        {childrenLength === 0 && !encounterId && !chartCode && (
          <Box
            fontSize="1.5rem"
            fontWeight="500"
            color="gray.700"
            width="100%"
            textAlign="center"
          >
            You have no saved images.
          </Box>
        )}

        {encounterId && chartCode && (
          <AddNewDiagramButton
            encounterId={encounterId}
            chartCode={chartCode}
          />
        )}
      </HStack>
    </VStack>
  );
}

export type { DiagramsPreviewProps };
export { DiagramsPreview, DiagramsPreviewContext };
