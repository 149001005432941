import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const RightArrow = createIcon({
  path: (
    <path
      d="M11.2673 10C11.2673 9.71198 11.1521 9.447 10.9332 9.23963L1.80876 0.299539C1.60138 0.103687 1.34793 0 1.04839 0C0.46083 0 0 0.449309 0 1.04839C0 1.33641 0.115207 1.60138 0.299539 1.79724L8.68664 10L0.299539 18.2028C0.115207 18.3986 0 18.6521 0 18.9516C0 19.5507 0.46083 20 1.04839 20C1.34793 20 1.60138 19.8963 1.80876 19.6889L10.9332 10.7604C11.1521 10.5415 11.2673 10.288 11.2673 10Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 12 20",
});

export { RightArrow };
