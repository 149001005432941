import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared/assessments";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const triageChartCode = "T007x";

function N07Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { watch } = useFormContext;

  const q001AllNormal = Boolean(
    watch(`Q001.${triageChartCode}${sectionId}Q001A023`)
  );
  const q002AllNormal = Boolean(
    watch(`Q002.${triageChartCode}${sectionId}Q002A023`)
  );
  const q004AllNormal = Boolean(
    watch(`Q004.${triageChartCode}${sectionId}Q004A014`)
  );
  const q005AllNormal = Boolean(
    watch(`Q005.${triageChartCode}${sectionId}Q005A012`)
  );
  const q006AllNormal = Boolean(
    watch(`Q006.${triageChartCode}${sectionId}Q006A014`)
  );
  const q008AllNormal = Boolean(
    watch(`Q008.${triageChartCode}${sectionId}Q008A001`)
  );
  const q009AllNormal = Boolean(
    watch(`Q009.${triageChartCode}${sectionId}Q009A001`)
  );
  const q010AllNormal = Boolean(
    watch(`Q010.${triageChartCode}${sectionId}Q010A001`)
  );
  const q011AllNormal = Boolean(
    watch(`Q011.${triageChartCode}${sectionId}Q011A001`)
  );
  const q012AllNormal = Boolean(
    watch(`Q012.${triageChartCode}${sectionId}Q012A001`)
  );
  const q013AllNormal = Boolean(
    watch(`Q013.${triageChartCode}${sectionId}Q013A001`)
  );

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              showAuditsBtn={false}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q001.T007xSASSQ001A023"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T007xSASSQ001A022"
                  label="Date/Time"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  type="date"
                  name="Q001.T007xSASSQ001A001"
                  width="20rem"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T007xSASSQ001A024"
                    label="appears nml"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T007xSASSQ001A025"
                    label="ill"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T007xSASSQ001A026"
                    label="uncomfortable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T007xSASSQ001A027"
                    label="crying"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T007xSASSQ001A028"
                    label="inconsolable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T007xSASSQ001A029"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway patent"
                    name="Q001.T007xSASSQ001A002"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway compromised"
                    name="Q001.T007xSASSQ001A003"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T007xSASSQ001A004"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="breathing unlabored"
                    name="Q001.T007xSASSQ001A005"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="labored breathing"
                    name="Q001.T007xSASSQ001A006"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="respiratory distress:"
                    name="Q001.T007xSASSQ001A007"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mild"
                    fontStyle="italic"
                    name="Q001.T007xSASSQ001A030"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="moderate"
                    fontStyle="italic"
                    name="Q001.T007xSASSQ001A031"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="severe"
                    fontStyle="italic"
                    name="Q001.T007xSASSQ001A032"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T007xSASSQ001A008"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="circulation nml"
                    name="Q001.T007xSASSQ001A009"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q001.T007xSASSQ001A010"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q001.T007xSASSQ001A011"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T007xSASSQ001A012"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="neuro awake & alert"
                    name="Q001.T007xSASSQ001A013"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lethargic"
                    name="Q001.T007xSASSQ001A014"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="obtunded"
                    name="Q001.T007xSASSQ001A018"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T003xSASSQ001A016"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q001.T007xSASSQ001A033"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name="Q001.T007xSASSQ001A034"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q002.T007xSASSQ002A023"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x 4"
                    name="Q002.T007xSASSQ002A001"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.T007xSASSQ002A018"
                    label="disoriented to:"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q002.T007xSASSQ002A002"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q002.T007xSASSQ002A003"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    name="Q002.T007xSASSQ002A004"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q002.T007xSASSQ002A005"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A006"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="PERRL"
                    name="Q002.T007xSASSQ002A007"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pupils unequal"
                    name="Q002.T007xSASSQ002A010"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q002.T007xSASSQ002A011"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A012"
                    isDisabled={q002AllNormal}
                    size="xs"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mm"
                    name="Q002.T007xSASSQ002A019"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q002.T007xSASSQ002A013"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A014"
                    size="xs"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mm"
                    name="Q002.T007xSASSQ002A020"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A021"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRow>
                    <ChartRowElement
                      type="cross-option"
                      label="memory loss"
                      name="Q002.T007xSASSQ002A008"
                      isDisabled={q002AllNormal}
                    />
                    <ChartRowElement
                      type="input"
                      name="Q002.T007xSASSQ002A009"
                      isDisabled={q002AllNormal}
                    />
                  </ChartRow>
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="weakness"
                    name="Q002.T007xSASSQ002A015"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sensory loss"
                    name="Q002.T007xSASSQ002A016"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A017"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q002.T007xSASSQ002A021"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T007xSASSQ002A021"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head / Face"
            >
              Head / Face
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q003.T007xSASSQ003A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q003.T007xSASSQ003A005"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q003.T007xSASSQ003A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q003.T007xSASSQ003A007"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q003.T007xSASSQ003A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q003.T007xSASSQ003A010"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="skin intact"
                    name="Q003.T007xSASSQ003A009"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="subconjunctival hemorrhage"
                    name="Q003.T007xSASSQ003A016"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q003.T007xSASSQ003A021"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A022" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no visual disturbance"
                    name="Q003.T007xSASSQ003A012"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="periorbital swelling"
                    name="Q003.T007xSASSQ003A030"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="periorbital hematoma"
                    name="Q003.T007xSASSQ003A013"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A014" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="raccoon eyes"
                    name="Q003.T007xSASSQ003A018"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Battle's sign"
                    name="Q003.T007xSASSQ003A019"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nasal passage nml"
                    name="Q003.T007xSASSQ003A031"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nasal injury"
                    name="Q003.T007xSASSQ003A023"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no drainage"
                    name="Q003.T007xSASSQ003A015"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="drainage"
                    name="Q003.T007xSASSQ003A032"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="mouth nml"
                    name="Q003.T007xSASSQ003A033"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dental injury"
                    name="Q003.T007xSASSQ003A025"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malocclusion"
                    name="Q003.T007xSASSQ003A026"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A027" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="drainage"
                    name="Q003.T007xSASSQ003A028"
                  />
                  <ChartRowElement type="input" name="Q003.T007xSASSQ003A029" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Neck / Back"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q004.T007xSASSQ004A014"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q004.T007xSASSQ004A001"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q004.T007xSASSQ004A002"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q004.T007xSASSQ004A003"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q004.T007xSASSQ004A004"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T007xSASSQ004A005"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q004.T007xSASSQ004A006"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q004.T007xSASSQ004A007"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T007xSASSQ004A008"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q004.T007xSASSQ004A009"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q004.T007xSASSQ004A010"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T007xSASSQ004A011"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q004.T007xSASSQ004A012"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T007xSASSQ004A013"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Chest"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name="Q008.T007xSASSQ008A001"
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name="Q008.T007xSASSQ008A002"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name="Q008.T007xSASSQ008A003"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name="Q008.T007xSASSQ008A004"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name="Q008.T007xSASSQ008A005"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="tenderness"
                    name="Q008.T007xSASSQ008A006"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="deformity"
                    name="Q008.T007xSASSQ008A007"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A008"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp distress"
                    name="Q008.T007xSASSQ008A009"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name="Q008.T007xSASSQ008A036"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name="Q008.T007xSASSQ008A010"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name="Q008.T007xSASSQ008A011"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name="Q008.T007xSASSQ008A012"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A013"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml breath sounds"
                    name="Q008.T007xSASSQ008A014"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breath sounds"
                    name="Q008.T007xSASSQ008A015"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="tachypnea"
                    name="Q008.T007xSASSQ008A016"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="wheezing"
                    name="Q008.T007xSASSQ008A017"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name="Q008.T007xSASSQ008A018"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name="Q008.T007xSASSQ008A019"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="stridor"
                    name="Q008.T007xSASSQ008A020"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="crackles"
                    name="Q008.T007xSASSQ008A021"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A022"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="accessory muscle use"
                    name="Q008.T007xSASSQ008A023"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="retractions"
                    name="Q008.T007xSASSQ008A024"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="splinting"
                    name="Q008.T007xSASSQ008A025"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="nasal flaring"
                    name="Q008.T007xSASSQ008A026"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="orthopnea"
                    name="Q008.T007xSASSQ008A027"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="exertional SOB"
                    name="Q008.T007xSASSQ008A028"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="tripod"
                    name="Q008.T007xSASSQ008A029"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A030"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest non-tender"
                    name="Q008.T007xSASSQ008A031"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest wall tenderness"
                    name="Q008.T007xSASSQ008A032"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A033"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name="Q008.T007xSASSQ008A034"
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name="Q008.T007xSASSQ008A035"
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="CVS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name="Q009.T007xSASSQ009A001"
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="regular rate"
                    name="Q009.T007xSASSQ009A002"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name="Q009.T007xSASSQ009A003"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name="Q009.T007xSASSQ009A004"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name="Q009.T007xSASSQ009A005"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulses strong / equal"
                    name="Q009.T007xSASSQ009A006"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name="Q009.T007xSASSQ009A007"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name="Q009.T007xSASSQ009A008"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name="Q009.T007xSASSQ009A012"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name="Q009.T007xSASSQ009A013"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Abdomen"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name="Q010.T007xSASSQ010A001"
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Abdomen
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name="Q010.T007xSASSQ010A019"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name="Q010.T007xSASSQ010A020"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name="Q010.T007xSASSQ010A021"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name="Q010.T007xSASSQ010A022"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name="Q010.T007xSASSQ010A023"
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft / non-tender"
                    name="Q010.T007xSASSQ010A002"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name="Q010.T007xSASSQ010A003"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="rigid"
                    name="Q010.T007xSASSQ010A004"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="distended"
                    name="Q010.T007xSASSQ010A005"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name="Q010.T007xSASSQ010A006"
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds normal"
                    name="Q010.T007xSASSQ010A010"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds"
                    type="cross-option"
                    name="Q010.T007xSASSQ010A019"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="hyper"
                    name="Q010.T007xSASSQ010A011"
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="hypo"
                    name="Q010.T007xSASSQ010A012"
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="absent"
                    name="Q010.T007xSASSQ010A013"
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name="Q010.T007xSASSQ010A014"
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name="Q010.T007xSASSQ010A017"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name="Q010.T007xSASSQ010A018"
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Pelvis / GU"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name="Q011.T007xSASSQ011A001"
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Pelvis / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name="Q011.T007xSASSQ011A002"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name="Q011.T007xSASSQ011A003"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name="Q011.T007xSASSQ011A004"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name="Q011.T007xSASSQ011A005"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name="Q011.T007xSASSQ011A006"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis stable"
                    name="Q011.T007xSASSQ011A007"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis unstable"
                    type="cross-option"
                    name="Q011.T007xSASSQ011A0010"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="tenderness"
                    type="cross-option"
                    name="Q011.T007xSASSQ011A0011"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name="Q011.T007xSASSQ011A012"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="blood at urethral meatus"
                    name="Q011.T007xSASSQ011A013"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name="Q011.T007xSASSQ011A014"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name="Q011.T007xSASSQ011A015"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name="Q011.T007xSASSQ011A016"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name="Q011.T007xSASSQ011A017"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name="Q011.T007xSASSQ011A018"
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Upper Extremities"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  name="Q012.T007xSASSQ012A001"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Upper Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q012.T007xSASSQ012A002"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q012.T007xSASSQ012A003"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q012.T007xSASSQ012A004"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A005"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="sensation / motor intact"
                    name="Q012.T007xSASSQ012A006"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="sensory deficit"
                    name="Q012.T007xSASSQ012A007"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="motor deficit"
                    name="Q012.T007xSASSQ012A008"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A009"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q012.T007xSASSQ012A010"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q012.T007xSASSQ012A011"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q012.T007xSASSQ012A012"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A013"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no edema"
                    name="Q012.T007xSASSQ012A014"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="joint swelling"
                    name="Q012.T007xSASSQ012A015"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A016"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q012.T007xSASSQ012A017"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q012.T007xSASSQ012A018"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="active"
                    name="Q012.T007xSASSQ012A019"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="passive"
                    name="Q012.T007xSASSQ012A020"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="functional"
                    name="Q012.T007xSASSQ012A021"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="due to pain"
                    name="Q012.T007xSASSQ012A022"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A023"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no deformity"
                    name="Q012.T007xSASSQ012A024"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="deformity"
                    name="Q012.T007xSASSQ012A025"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A026"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q012.T007xSASSQ012A027"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q012.T007xSASSQ012A028"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Lower Extremities"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  name="Q013.T007xSASSQ013A001"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Lower Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q013.T007xSASSQ013A002"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q013.T007xSASSQ013A003"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q013.T007xSASSQ013A004"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A005"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="sensation / motor intact"
                    name="Q013.T007xSASSQ013A006"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="sensory deficit"
                    name="Q013.T007xSASSQ013A007"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="motor deficit"
                    name="Q013.T007xSASSQ013A008"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A009"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q013.T007xSASSQ013A010"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q013.T007xSASSQ013A011"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q013.T007xSASSQ013A012"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A013"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no edema"
                    name="Q013.T007xSASSQ013A014"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="joint swelling"
                    name="Q013.T007xSASSQ013A015"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A016"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q013.T007xSASSQ013A017"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q013.T007xSASSQ013A018"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="active"
                    name="Q013.T007xSASSQ013A019"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="passive"
                    name="Q013.T007xSASSQ013A020"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="functional"
                    name="Q013.T007xSASSQ013A021"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="due to pain"
                    name="Q013.T007xSASSQ013A022"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A023"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no deformity"
                    name="Q013.T007xSASSQ013A024"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="deformity"
                    name="Q013.T007xSASSQ013A025"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A026"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q013.T007xSASSQ013A027"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q013.T007xSASSQ013A028"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Skin"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  name="Q005.T007xSASSQ005A012"
                  w="93px"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="warm / dry"
                    name="Q005.T007xSASSQ005A001"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q005.T007xSASSQ005A003"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q005.T007xSASSQ005A004"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="flushed"
                    name="Q005.T007xSASSQ005A013"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mottled"
                    name="Q005.T007xSASSQ005A014"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q005.T007xSASSQ005A015"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="increased warmth"
                    name="Q005.T007xSASSQ005A016"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T007xSASSQ005A005"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact"
                    name="Q005.T007xSASSQ005A006"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcers"
                    name="Q005.T007xSASSQ005A007"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sacrum"
                    name="Q005.T007xSASSQ005A008"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hip"
                    name="Q005.T007xSASSQ005A009"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="heel"
                    name="Q005.T007xSASSQ005A010"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T007xSASSQ005A011"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q005.T007xSASSQ005A017"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T007xSASSQ005A018"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <ChartRowElement
                  name="Q006.T007xSASSQ006A014"
                  type="checkbox-button"
                  w="93px"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="independent ADL"
                    name="Q006.T007xSASSQ006A001"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="assisted"
                    name="Q006.T007xSASSQ006A002"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="total care"
                    name="Q006.T007xSASSQ006A003"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T007xSASSQ006A004"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appears nourished / hydrated"
                    name="Q006.T007xSASSQ006A005"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="obese"
                    name="Q006.T007xSASSQ006A006"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malnourished"
                    name="Q006.T007xSASSQ006A007"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dehydrated"
                    name="Q006.T007xSASSQ006A008"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight loss"
                    name="Q006.T007xSASSQ006A011"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight gain"
                    name="Q006.T007xSASSQ006A012"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T007xSASSQ006A009"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q006.T007xSASSQ006A015"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T007xSASSQ006A013"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q007.T007xSASSQ007A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N07Assessments };
