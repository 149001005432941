import { Box, BoxProps, Flex, Collapse } from "@chakra-ui/react";
import { InfiniteList } from "shared";
import * as React from "react";
import { useOrders, OrdersListItemResponse } from "modules/orders/api";
import { useToast } from "hooks";
import { extractApiErrorMessage } from "utils";
import { useNavigate } from "react-router-dom";
import { SCROLLBARSTYLE } from "modules/labs/constants";
import { OrderCard } from "modules/orders";
import { OrderGroup, OrderStatus } from "enums";

type OrdersListProps = {
  headerElement?: React.ReactNode;
  canToggleCollapse?: boolean;
  colorIcon?: string;
  filter?: OrderStatus;
  didColapse?: (open: boolean) => void;
  expanded: boolean;
  orderGroup?: OrderGroup;
} & BoxProps;

function OrdersList(props: OrdersListProps) {
  const {
    headerElement,
    colorIcon,
    filter,
    didColapse,
    expanded = true,
    orderGroup,
    ...rest
  } = props;

  const { data, isLoading, fetchNextPage, hasNextPage, error } = useOrders({
    orderStatus: filter,
    orderGroup,
  });
  const toast = useToast();
  const navigate = useNavigate();
  const hasData = data && data.pages.length > 0;
  const showNoResults = !isLoading && !hasData;
  const toRoute =
    orderGroup === "Radiology"
      ? "/radiology/radiology-orders/"
      : "/labs/lab-orders/";

  const fetchMore = React.useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <Box {...rest}>
      <Flex direction="column" height="100%">
        {React.isValidElement(headerElement) &&
          React.cloneElement(headerElement, {
            margin: "0 10px 10px",
            mb: "15px",
            counter: data?.pages[0]?.data.totalRecords || 0,
          })}
        <Box
          flex="1"
          height={{ base: "200px", xl: "100%" }}
          overflow="auto"
          css={SCROLLBARSTYLE}
        >
          <Collapse in={expanded} endingHeight="100%">
            {showNoResults ? (
              <Box width="100%">
                <Box
                  textAlign="center"
                  fontSize="1.65rem"
                  fontWeight="bold"
                  color="gray.650"
                >
                  No results
                </Box>
              </Box>
            ) : (
              <InfiniteList
                css={SCROLLBARSTYLE}
                fetchMore={fetchMore}
                hasMore={!!hasNextPage}
                isLoading={isLoading}
                renderRow={(order) => (
                  <OrderCard
                    orderGroup={orderGroup}
                    key={order.encounterId}
                    colorIcon={colorIcon}
                    order={order}
                    onClick={() =>
                      navigate(
                        `${toRoute}${order.encounterId}/order/${order.id}`
                      )
                    }
                  />
                )}
                rows={
                  data?.pages.reduce<OrdersListItemResponse[]>(
                    (accum, curr) => [...accum, ...curr.data.content],
                    []
                  ) || []
                }
                spacing="15px"
                padding="0 10px"
                marginBottom="16px"
              />
            )}
          </Collapse>
        </Box>
      </Flex>
    </Box>
  );
}

export { OrdersList };
