import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button, Card, Label } from "shared";

import {
  HARD_STOPS_TRIAGE_SECTIONS,
  HARD_STOPS_PROVIDER_SECTIONS,
  HARD_STOPS_SUBSECTIONS,
  HARD_STOPS_SECTIONS_ROUTES,
} from "./constants";
import { ProviderHardStopsResponse, TriageHardStopsResponse } from "./types";
import { useNavigate } from "react-router-dom";
import { CheckCircleHardStops, ExclamationCircleHardStops } from "icons";
import { ChartType } from "types";

type HardStops =
  | TriageHardStopsResponse
  | ProviderHardStopsResponse
  | undefined;

type ChartHardStopsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  chartType: ChartType;
  data: HardStops;
};

type ChartHardStopsSectionProps = {
  key: number;
  sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS;
  section:
    | keyof typeof HARD_STOPS_PROVIDER_SECTIONS
    | keyof typeof HARD_STOPS_TRIAGE_SECTIONS;
  data: HardStops;
};

const validateElementRestrictions = (section: string, data: HardStops) => {
  const restrictions = {
    medsAndIV: data?.hasMedAndIvOrders,
    ekg: data?.hasEKGOrders,
    labs: data?.hasLabsOrders,
    treatments: data?.hasTreatmentsOrders,
    radiology: data?.hasRadiologyOrders,
  };

  const prop = section as keyof typeof restrictions;
  return restrictions[prop] ?? true;
};

function ChartHardStopsSection(props: ChartHardStopsSectionProps) {
  const { key, section, sections, data } = props;
  const element = data ? data[section as keyof typeof data] : undefined;

  const navigate = useNavigate();

  if (
    typeof element === "undefined" ||
    !validateElementRestrictions(section, data)
  )
    return null;

  return (
    <Box key={key}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Label>{sections[section as keyof typeof sections]}</Label>
        <Button
          variant="label"
          onClick={() =>
            navigate(
              `./../${
                HARD_STOPS_SECTIONS_ROUTES[
                  section as keyof typeof HARD_STOPS_SECTIONS_ROUTES
                ]
              }`
            )
          }
          color="blue"
        >
          Go to Section
        </Button>
      </Box>

      <Card
        sx={{
          width: "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 1.5,
          px: 5,
          flexDirection: "column",
        }}
      >
        {Object.keys(element).map((prop, index) => {
          const value = element[prop as keyof typeof element];
          if (typeof value === "undefined") return null;

          return (
            <Flex w="100%" columnGap="17px" alignItems="center" key={index}>
              <Icon
                as={value ? CheckCircleHardStops : ExclamationCircleHardStops}
                alignSelf="center"
                fontSize={"26px"}
                color={value ? "green" : "red"}
              />
              <Flex
                borderBottom={
                  index === Object.keys(element).length - 1
                    ? "0px"
                    : "1px solid"
                }
                borderColor="gray.450"
                w="100%"
                justify="start"
                p="1rem 0 1rem 1rem"
                alignItems="center"
              >
                {
                  HARD_STOPS_SUBSECTIONS[
                    prop as keyof typeof HARD_STOPS_SUBSECTIONS
                  ]
                }
              </Flex>
            </Flex>
          );
        })}
      </Card>
    </Box>
  );
}

function ChartHardStopsModal(props: ChartHardStopsModalProps) {
  const { isOpen, onClose, chartType, data } = props;

  const sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS =
    chartType === "Triage"
      ? HARD_STOPS_TRIAGE_SECTIONS
      : HARD_STOPS_PROVIDER_SECTIONS;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="gray.200"
          maxW="unset"
          width="540px"
          containerProps={{ justifyContent: "flex-end" }}
          height="fit-content"
          maxHeight="100vh"
          margin="auto"
          overflow="hidden"
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            fontSize="1rem"
            padding="20px 16px 30px"
            alignItems="center"
          >
            <Box flex={1} />
            <Heading
              fontWeight="600"
              fontSize="1.0625rem"
              textAlign="center"
              textTransform="capitalize"
            >
              {chartType === "Triage" ? "Nurse" : "Provider"} Chart Hard Stops
            </Heading>
            <Flex flex={1} justifyContent="flex-end">
              <Button
                variant="label"
                onClick={() => {
                  onClose();
                }}
                color="blue"
              >
                Close
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody p="15px">
            <Box
              maxH="85vh"
              overflowY="auto"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: 2,
                marginBottom: 2,
              }}
            >
              {Object.keys(sections).map((key, index) => (
                <ChartHardStopsSection
                  key={index}
                  sections={sections}
                  section={key as keyof typeof HARD_STOPS_PROVIDER_SECTIONS}
                  data={data}
                />
              ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { ChartHardStopsModal };
