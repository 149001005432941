import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Cleaning = createIcon({
  path: (
    <path
      d="M9.15679 5.1324C9.29268 5.1324 9.37631 5.04878 9.39721 4.91289C9.7108 3.18815 9.68989 3.16725 11.4983 2.81185C11.6341 2.79094 11.7282 2.70732 11.7282 2.56098C11.7282 2.42509 11.6341 2.34146 11.4983 2.32056C9.70035 1.97561 9.74216 1.94425 9.39721 0.219512C9.37631 0.0836237 9.29268 0 9.15679 0C9.02091 0 8.93728 0.0836237 8.90592 0.219512C8.57143 1.92334 8.62369 1.9547 6.80488 2.32056C6.66899 2.34146 6.58537 2.42509 6.58537 2.56098C6.58537 2.70732 6.66899 2.79094 6.81533 2.81185C8.61324 3.16725 8.60279 3.18815 8.90592 4.91289C8.93728 5.04878 9.02091 5.1324 9.15679 5.1324ZM4.08711 12.4495C4.29617 12.4495 4.45296 12.3031 4.48432 12.0941C4.85017 9.32404 4.9338 9.31359 7.81881 8.77003C8.01742 8.73868 8.17422 8.59233 8.17422 8.37282C8.17422 8.16376 8.01742 8.00697 7.80836 7.97561C4.94425 7.5784 4.83972 7.49477 4.48432 4.67247C4.45296 4.45296 4.29617 4.30662 4.08711 4.30662C3.8676 4.30662 3.7108 4.45296 3.6899 4.68293C3.34495 7.44251 3.21951 7.44251 0.355401 7.97561C0.146341 8.01742 0 8.16376 0 8.37282C0 8.60279 0.146341 8.73868 0.397213 8.77003C3.22996 9.21951 3.34495 9.30313 3.6899 12.0732C3.7108 12.3031 3.8676 12.4495 4.08711 12.4495ZM11.331 24C11.6341 24 11.8641 23.7805 11.9059 23.4669C12.6585 17.8014 13.4634 16.9129 19.0662 16.2962C19.3902 16.2544 19.6202 16.0139 19.6202 15.7108C19.6202 15.4077 19.3902 15.1672 19.0662 15.1254C13.4843 14.4774 12.6899 13.6411 11.9059 7.9547C11.8537 7.64112 11.6341 7.4216 11.331 7.4216C11.0279 7.4216 10.7979 7.64112 10.7561 7.9547C10.0035 13.6202 9.19861 14.5087 3.59582 15.1254C3.26132 15.1672 3.04181 15.4077 3.04181 15.7108C3.04181 16.0139 3.26132 16.2544 3.59582 16.2962C9.15679 17.0383 9.93031 17.7805 10.7561 23.4669C10.8084 23.7805 11.0279 24 11.331 24Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 25",
});

export { Cleaning };
