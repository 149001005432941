import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteAmbulatoryNote(noteId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteAmbulatoryNote", { noteId }],
    () =>
      axiosChartInstance.delete(`v1/ambulatory-note/${encounterId}/${noteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ambulatoryNotes"]);
      },
    }
  );
}

export { useDeleteAmbulatoryNote };
