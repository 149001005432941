import { TriageChartCode } from "enums";

const NURSE_CHARTS: Record<TriageChartCode, string> = {
  T001x: "(N-01) Head / Face Trauma",
  T002x: "(N-02) Eyes",
  T003x: "(N-03) Upper Extremity Injury",
  T006x: "(N-06) MVC",
  T007x: "(N-07) Trauma",
  T008x: "(N-08) Assault",
  T009x: "(N-09) Animal Bite",
  T010x: "(N-10) Burn",
  T011x: "(N-11) Wound Recheck / Suture Removal",
  T012x: "(N-12) Pediatric Injury",
  T013x: "(N-13) Pediatric Illness",
  T015x: "(N-15) PED Abdominal Pain / Ingestion",
  T024x: "(N-24) ENT / Dental",
  T025x: "(N-25) General Med / Back Pain",
  T028x: "(N-28) Chest Pain",
  T029x: "(N-29) Extremity Problems",
  T030x: "(N-30) Abdominal Pain / NVD",
  T031x: "(N-31) Female GU",
  T032x: "(N-32) Pregnancy",
  T033x: "(N-33) Male GU",
  T035x: "(N-35) Skin Rash / Abscess",
  T036x: "(N-36) Allergies",
  T039x: "(N-39) Headache / Dizziness",
  T040x: "(N-40) Neuro / Seizure",
  T042x: "(N-42) Substance Abuse / Psychiatric",
  T051x: "(N-51) PED Near Drowning",
  T057x: "(N-57) Possible Child Abuse",
  T058x: "(N-58) Respiratory",
  T070a: "(N-70) Observation",
} as const;

export { NURSE_CHARTS };
