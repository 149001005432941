import { Box, chakra, Icon, LinkProps, useDisclosure } from "@chakra-ui/react";
import { ResetPassword } from "icons";
import { PasswordsManagerModal } from "./PasswordsManagerModal";

type OnboardingButtonProps = {
  label: string;
  isEnabled?: boolean;
  bg: string;
} & LinkProps;

function OnboardingButton(props: OnboardingButtonProps) {
  const { label, isEnabled = true, bg, ...linkProps } = props;

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <chakra.a
        aria-disabled={!isEnabled}
        tabIndex={!isEnabled ? -1 : undefined}
        bg={bg}
        display="flex"
        flexDirection="column"
        outline="none"
        _focus={{ transform: "scale(0.75)" }}
        _hover={{ transform: "scale(0.75)" }}
        _disabled={{ pointerEvents: "none" }}
        transition="all 200ms ease-in-out"
        marginBottom="1rem"
        width="10vh"
        height="10vh"
        borderRadius="0.9rem"
        justifyContent="center"
        alignItems="center"
        boxShadow="0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)"
        cursor={isEnabled ? "pointer" : "unset"}
        {...linkProps}
      >
        <Icon as={ResetPassword} width="57%" height="57%" color="white" />
      </chakra.a>
      <Box
        textAlign="center"
        fontWeight="500"
        fontSize="1.25rem"
        color={isEnabled ? "black" : "gray.550"}
      >
        {label}
      </Box>
    </Box>
  );
}

function PasswordsManager() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <OnboardingButton label="Reset Passwords" bg="red" onClick={onOpen} />
      <PasswordsManagerModal isModalOpen={isOpen} onModalClose={onClose} />
    </>
  );
}

export { PasswordsManager };
