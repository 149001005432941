import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { Pharmacy } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type CreatePharmacyPayload = Partial<
  Omit<Pharmacy, "id" | "actions" | "state">
> & { state: string };

function useCreatePharmacy() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createPharmacy"],
    (input: CreatePharmacyPayload) =>
      axiosFacilityInstance.post<Pharmacy>(`v1/pharmacy`, input),
    {
      onSuccess: () => queryClient.invalidateQueries("pharmacyList"),
    }
  );
}

export { useCreatePharmacy };
export type { CreatePharmacyPayload };
