import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseUpdateAuditStatusPayload = {
  status: string;
  type: string;
};

function useUpdateAuditStatus(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["UpdateAuditStatus", { encounterId }],
    (input: UseUpdateAuditStatusPayload) => {
      return axiosPatientInstance.post<null>(
        `/v1/audits/${encounterId}/status`,
        input
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("auditsList");
        queryClient.invalidateQueries(["audits-status", { encounterId }]);
      },
    }
  );
}

export type { UseUpdateAuditStatusPayload };
export { useUpdateAuditStatus };
