import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseImportEncounterAllergiesMedicationsRxProps = {
  encounterId: string;
};

function useImportEncounterAllergiesMedicationsRx(
  params: UseImportEncounterAllergiesMedicationsRxProps
) {
  const { encounterId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["importEncounterAllergiesMedicationsRx", { encounterId }],
    () =>
      axiosPatientInstance.patch<boolean>(
        `v1/InProcess/${encounterId}/allergies-medications-rx/import-previous`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patientInfoByEncounter", params]);

        queryClient.invalidateQueries([
          "getEncounterAllergiesMedicationsRx",
          params,
        ]);
      },
    }
  );
}

export type { UseImportEncounterAllergiesMedicationsRxProps };
export { useImportEncounterAllergiesMedicationsRx };
