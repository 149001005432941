import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Bill = createIcon({
  path: (
    <path
      d="M17 32.003C17 32.7763 17.5155 33.307 18.304 33.307H55.696C56.4845 33.307 57 32.7763 57 32.003V9.30402C57 8.51554 56.4845 8 55.696 8H18.304C17.5155 8 17 8.51554 17 9.30402V32.003ZM20.0174 29.8499V11.4572C20.0174 11.1539 20.1539 11.0174 20.4572 11.0174H53.558C53.8613 11.0174 53.9826 11.1539 53.9826 11.4572V29.8499C53.9826 30.1531 53.8613 30.2744 53.558 30.2744H20.4572C20.1539 30.2744 20.0174 30.1531 20.0174 29.8499ZM21.5034 28C21.5034 28.5155 21.7915 28.8036 22.2919 28.8036H51.7233C52.2237 28.8036 52.5118 28.5155 52.5118 28V13.3071C52.5118 12.7915 52.2237 12.5034 51.7233 12.5034H22.2919C21.7915 12.5034 21.5034 12.7915 21.5034 13.3071V28ZM23.1107 26.8779V14.4291C23.1107 14.2168 23.2168 14.1107 23.4291 14.1107H33.467C32.1933 15.5208 31.3745 17.7801 31.3745 20.6308C31.3745 23.4966 32.1933 25.771 33.4822 27.1964H23.4291C23.2168 27.1964 23.1107 27.0751 23.1107 26.8779ZM42.6406 20.6308C42.6406 17.7801 41.8218 15.5208 40.5027 14.1107H50.5861C50.7832 14.1107 50.9045 14.2168 50.9045 14.4291V26.8779C50.9045 27.0751 50.7832 27.1964 50.5861 27.1964H40.4875C41.8067 25.771 42.6406 23.4966 42.6406 20.6308ZM36.9393 26.8931C34.9227 26.8931 33.2092 24.4822 33.2092 20.6308C33.2092 16.8097 34.9227 14.4139 36.9393 14.4139C39.047 14.4139 40.8059 16.8097 40.8059 20.6308C40.8059 24.4822 39.047 26.8931 36.9393 26.8931Z"
      fill="currentColor"
    />
  ),
  viewBox: "15 0 45 45",
});

export { Bill };
