import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const triageChartCode = "T006x";

function N06Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { watch } = useFormContext;

  const q001AllNormal = Boolean(
    watch(`Q001.${triageChartCode}${sectionId}Q001A001`)
  );
  const q002AllNormal = Boolean(
    watch(`Q002.${triageChartCode}${sectionId}Q002A001`)
  );
  const q003AllNormal = Boolean(
    watch(`Q003.${triageChartCode}${sectionId}Q003A001`)
  );
  const q004AllNormal = Boolean(
    watch(`Q004.${triageChartCode}${sectionId}Q004A001`)
  );
  const q005AllNormal = Boolean(
    watch(`Q005.${triageChartCode}${sectionId}Q005A001`)
  );
  const q006AllNormal = Boolean(
    watch(`Q006.${triageChartCode}${sectionId}Q006A001`)
  );
  const q007AllNormal = Boolean(
    watch(`Q007.${triageChartCode}${sectionId}Q007A001`)
  );
  const q008AllNormal = Boolean(
    watch(`Q008.${triageChartCode}${sectionId}Q008A001`)
  );
  const q010AllNormal = Boolean(
    watch(`Q010.${triageChartCode}${sectionId}Q010A001`)
  );
  const q011AllNormal = Boolean(
    watch(`Q011.${triageChartCode}${sectionId}Q011A001`)
  );
  const q012AllNormal = Boolean(
    watch(`Q012.${triageChartCode}${sectionId}Q012A001`)
  );

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q001.${triageChartCode}${sectionId}Q001A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Date / Time:"
                  type="cross-option"
                  name={`Q001.${triageChartCode}${sectionId}Q001A029`}
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  name={`Q001.${triageChartCode}${sectionId}Q001A002`}
                  type="date"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nml"
                    name={`Q001.${triageChartCode}${sectionId}Q001A003`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q001.${triageChartCode}${sectionId}Q001A004`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q001.${triageChartCode}${sectionId}Q001A005`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q001.${triageChartCode}${sectionId}Q001A006`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q001.${triageChartCode}${sectionId}Q001A007`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A008`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway patent"
                    name={`Q001.${triageChartCode}${sectionId}Q001A009`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway compromised"
                    name={`Q001.${triageChartCode}${sectionId}Q001A010`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A011`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breathing unlabored"
                    name={`Q001.${triageChartCode}${sectionId}Q001A012`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labored breathing"
                    name={`Q001.${triageChartCode}${sectionId}Q001A013`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q001.${triageChartCode}${sectionId}Q001A030`}
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q001.${triageChartCode}${sectionId}Q001A014`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q001.${triageChartCode}${sectionId}Q001A015`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q001.${triageChartCode}${sectionId}Q001A016`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A017`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="circulation nml"
                    name={`Q001.${triageChartCode}${sectionId}Q001A018`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pale"
                    name={`Q001.${triageChartCode}${sectionId}Q001A019`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q001.${triageChartCode}${sectionId}Q001A020`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A021`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neuro awake & alert"
                    name={`Q001.${triageChartCode}${sectionId}Q001A022`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lethargic"
                    name={`Q001.${triageChartCode}${sectionId}Q001A023`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="obtunded"
                    name={`Q001.${triageChartCode}${sectionId}Q001A024`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    label="anxious"
                    name={`Q001.${triageChartCode}${sectionId}Q001A025`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A026`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q001.${triageChartCode}${sectionId}Q001A027`}
                    type="cross-option"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    name={`Q001.${triageChartCode}${sectionId}Q001A028`}
                    type="input"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q002.${triageChartCode}${sectionId}Q002A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x 4"
                    name={`Q002.${triageChartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    name={`Q002.${triageChartCode}${sectionId}Q002A013`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q002.${triageChartCode}${sectionId}Q002A003`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q002.${triageChartCode}${sectionId}Q002A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q002.${triageChartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q002.${triageChartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A007`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="memory nml"
                    name={`Q002.${triageChartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="memory loss"
                    name={`Q002.${triageChartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A016`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q002.${triageChartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pupils unequal"
                    name={`Q002.${triageChartCode}${sectionId}Q002A018`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q002.${triageChartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A020`}
                    type="input"
                    w="50px"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement label="mm" fontStyle="italic" type="label" />
                  <ChartRowElement
                    label="R"
                    name={`Q002.${triageChartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A022`}
                    type="input"
                    w="50px"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement label="mm" fontStyle="italic" type="label" />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A023`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="weakness"
                    name={`Q002.${triageChartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="sensory loss"
                    name={`Q002.${triageChartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A010`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q002.${triageChartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${triageChartCode}${sectionId}Q002A012`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Head / Face"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q010.${triageChartCode}${sectionId}Q010A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Head / Face
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q010.${triageChartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q010.${triageChartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q010.${triageChartCode}${sectionId}Q010A004`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q010.${triageChartCode}${sectionId}Q010A005`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q010.${triageChartCode}${sectionId}Q010A006`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${triageChartCode}${sectionId}Q010A007`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no visual disturbance"
                    name={`Q010.${triageChartCode}${sectionId}Q010A008`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="periorbital swelling"
                    type="cross-option"
                    name={`Q010.${triageChartCode}${sectionId}Q010A0009`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="periorbital hematoma"
                    name={`Q010.${triageChartCode}${sectionId}Q010A010`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${triageChartCode}${sectionId}Q010A011`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mouth nml"
                    name={`Q010.${triageChartCode}${sectionId}Q010A012`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dental injury"
                    name={`Q010.${triageChartCode}${sectionId}Q010A013`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="malocclusion"
                    name={`Q010.${triageChartCode}${sectionId}Q010A014`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${triageChartCode}${sectionId}Q010A015`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q010.${triageChartCode}${sectionId}Q010A016`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${triageChartCode}${sectionId}Q010A017`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Neck / Back"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q011.${triageChartCode}${sectionId}Q011A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q011.${triageChartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q011.${triageChartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q011.${triageChartCode}${sectionId}Q011A004`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q011.${triageChartCode}${sectionId}Q011A005`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${triageChartCode}${sectionId}Q011A006`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q011.${triageChartCode}${sectionId}Q011A007`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    type="cross-option"
                    name={`Q011.${triageChartCode}${sectionId}Q011A0010`}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${triageChartCode}${sectionId}Q011A011`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml ROM"
                    name={`Q011.${triageChartCode}${sectionId}Q011A012`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="limited ROM"
                    name={`Q011.${triageChartCode}${sectionId}Q011A013`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${triageChartCode}${sectionId}Q011A014`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q011.${triageChartCode}${sectionId}Q011A015`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${triageChartCode}${sectionId}Q011A016`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Chest"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q003.${triageChartCode}${sectionId}Q003A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q003.${triageChartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q003.${triageChartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q003.${triageChartCode}${sectionId}Q003A004`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q003.${triageChartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="tenderness"
                    name={`Q003.${triageChartCode}${sectionId}Q003A006`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="deformity"
                    name={`Q003.${triageChartCode}${sectionId}Q003A007`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A008`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp distress"
                    name={`Q003.${triageChartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress:"
                    type="cross-option"
                    name={`Q003.${triageChartCode}${sectionId}Q003A036`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="mild"
                    name={`Q003.${triageChartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="moderate"
                    name={`Q003.${triageChartCode}${sectionId}Q003A011`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="severe"
                    name={`Q003.${triageChartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A013`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml breath sounds"
                    name={`Q003.${triageChartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breath sounds"
                    name={`Q003.${triageChartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="tachypnea"
                    name={`Q003.${triageChartCode}${sectionId}Q003A016`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="wheezing"
                    name={`Q003.${triageChartCode}${sectionId}Q003A017`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q003.${triageChartCode}${sectionId}Q003A018`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q003.${triageChartCode}${sectionId}Q003A019`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="stridor"
                    name={`Q003.${triageChartCode}${sectionId}Q003A020`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="crackles"
                    name={`Q003.${triageChartCode}${sectionId}Q003A021`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A022`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="accessory muscle use"
                    name={`Q003.${triageChartCode}${sectionId}Q003A023`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="retractions"
                    name={`Q003.${triageChartCode}${sectionId}Q003A024`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="splinting"
                    name={`Q003.${triageChartCode}${sectionId}Q003A025`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="nasal flaring"
                    name={`Q003.${triageChartCode}${sectionId}Q003A026`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="orthopnea"
                    name={`Q003.${triageChartCode}${sectionId}Q003A027`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="exertional SOB"
                    name={`Q003.${triageChartCode}${sectionId}Q003A028`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="tripod"
                    name={`Q003.${triageChartCode}${sectionId}Q003A029`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A030`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest non-tender"
                    name={`Q003.${triageChartCode}${sectionId}Q003A031`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest wall tenderness"
                    name={`Q003.${triageChartCode}${sectionId}Q003A032`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A033`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q003.${triageChartCode}${sectionId}Q003A034`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${triageChartCode}${sectionId}Q003A035`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="CVS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q004.${triageChartCode}${sectionId}Q004A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="regular rate"
                    name={`Q004.${triageChartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q004.${triageChartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q004.${triageChartCode}${sectionId}Q004A004`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${triageChartCode}${sectionId}Q004A005`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulses strong / equal"
                    name={`Q004.${triageChartCode}${sectionId}Q004A006`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q004.${triageChartCode}${sectionId}Q004A007`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${triageChartCode}${sectionId}Q004A008`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q004.${triageChartCode}${sectionId}Q004A012`}
                    type="cross-option"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${triageChartCode}${sectionId}Q004A013`}
                    type="input"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Abdomen"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q005.${triageChartCode}${sectionId}Q005A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Abdomen
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q005.${triageChartCode}${sectionId}Q005A019`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q005.${triageChartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q005.${triageChartCode}${sectionId}Q005A021`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q005.${triageChartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${triageChartCode}${sectionId}Q005A023`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft / non-tender"
                    name={`Q005.${triageChartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q005.${triageChartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q005.${triageChartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q005.${triageChartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${triageChartCode}${sectionId}Q005A006`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds normal"
                    name={`Q005.${triageChartCode}${sectionId}Q005A010`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bowel sounds:"
                    type="cross-option"
                    name={`Q005.${triageChartCode}${sectionId}Q005A019`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="hyper"
                    name={`Q005.${triageChartCode}${sectionId}Q005A011`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="hypo"
                    name={`Q005.${triageChartCode}${sectionId}Q005A012`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="absent"
                    name={`Q005.${triageChartCode}${sectionId}Q005A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${triageChartCode}${sectionId}Q005A014`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q005.${triageChartCode}${sectionId}Q005A017`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${triageChartCode}${sectionId}Q005A018`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Pelvis / GU"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q012.${triageChartCode}${sectionId}Q012A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Pelvis / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q012.${triageChartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q012.${triageChartCode}${sectionId}Q012A003`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="rigid"
                    name={`Q012.${triageChartCode}${sectionId}Q012A004`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="distended"
                    name={`Q012.${triageChartCode}${sectionId}Q012A005`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${triageChartCode}${sectionId}Q012A006`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis stable"
                    name={`Q012.${triageChartCode}${sectionId}Q012A007`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis unstable"
                    type="cross-option"
                    name={`Q012.${triageChartCode}${sectionId}Q012A0010`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="tenderness"
                    type="cross-option"
                    name={`Q012.${triageChartCode}${sectionId}Q012A0011`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${triageChartCode}${sectionId}Q012A012`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="blood at urethral meatus"
                    name={`Q012.${triageChartCode}${sectionId}Q012A013`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${triageChartCode}${sectionId}Q012A014`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q012.${triageChartCode}${sectionId}Q012A015`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${triageChartCode}${sectionId}Q012A016`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q012.${triageChartCode}${sectionId}Q012A017`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${triageChartCode}${sectionId}Q012A018`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Extremities"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q006.${triageChartCode}${sectionId}Q006A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q006.${triageChartCode}${sectionId}Q006A020`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration"
                    name={`Q006.${triageChartCode}${sectionId}Q006A021`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="abrasion"
                    name={`Q006.${triageChartCode}${sectionId}Q006A022`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="wound"
                    name={`Q006.${triageChartCode}${sectionId}Q006A023`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="drainage"
                    name={`Q006.${triageChartCode}${sectionId}Q006A024`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${triageChartCode}${sectionId}Q006A025`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q006.${triageChartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q006.${triageChartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="calf tenderness"
                    name={`Q006.${triageChartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="pedal edema"
                    name={`Q006.${triageChartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="edema"
                    name={`Q006.${triageChartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q006.${triageChartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="joint pain"
                    name={`Q006.${triageChartCode}${sectionId}Q006A008`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${triageChartCode}${sectionId}Q006A009`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensation / motor intact"
                    name={`Q006.${triageChartCode}${sectionId}Q006A026`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sensory deficit"
                    name={`Q006.${triageChartCode}${sectionId}Q006A027`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="motor deficit"
                    name={`Q006.${triageChartCode}${sectionId}Q006A028`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${triageChartCode}${sectionId}Q006A017`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q006.${triageChartCode}${sectionId}Q006A018`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${triageChartCode}${sectionId}Q006A019`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Skin"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q007.${triageChartCode}${sectionId}Q007A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="warm / dry"
                    name={`Q007.${triageChartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cool"
                    name={`Q007.${triageChartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="cyanotic"
                    name={`Q007.${triageChartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="flushed"
                    name={`Q007.${triageChartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="mottled"
                    name={`Q007.${triageChartCode}${sectionId}Q007A006`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoretic"
                    name={`Q007.${triageChartCode}${sectionId}Q007A007`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="increased warmth"
                    name={`Q007.${triageChartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${triageChartCode}${sectionId}Q007A009`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q007.${triageChartCode}${sectionId}Q007A010`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ulcers"
                    name={`Q007.${triageChartCode}${sectionId}Q007A011`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="sacrum"
                    name={`Q007.${triageChartCode}${sectionId}Q007A012`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="hip"
                    name={`Q007.${triageChartCode}${sectionId}Q007A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="heel"
                    name={`Q007.${triageChartCode}${sectionId}Q007A014`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${triageChartCode}${sectionId}Q007A015`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q007.${triageChartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${triageChartCode}${sectionId}Q007A017`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q008.${triageChartCode}${sectionId}Q008A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="independent ADL"
                    name={`Q008.${triageChartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="assisted"
                    name={`Q008.${triageChartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="total care"
                    name={`Q008.${triageChartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${triageChartCode}${sectionId}Q008A005`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears nourished / hydrated"
                    name={`Q008.${triageChartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="obese"
                    name={`Q008.${triageChartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="malnourished"
                    name={`Q008.${triageChartCode}${sectionId}Q008A008`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="dehydrated"
                    name={`Q008.${triageChartCode}${sectionId}Q008A009`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="recent weight loss"
                    name={`Q008.${triageChartCode}${sectionId}Q008A010`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="recent weight gain"
                    name={`Q008.${triageChartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${triageChartCode}${sectionId}Q008A012`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other"
                    name={`Q008.${triageChartCode}${sectionId}Q008A013`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${triageChartCode}${sectionId}Q008A014`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q009.${triageChartCode}${sectionId}Q009A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N06Assessments };
