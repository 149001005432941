import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseAssignAuditorPayload = {
  type: string;
  userId: string;
};

function useAssignAuditor(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["assign-auditor", { encounterId }],
    (input: UseAssignAuditorPayload) => {
      return axiosPatientInstance.put<null>(
        `/v1/audits/${encounterId}/assign-auditor`,
        input
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("auditsList");
      },
    }
  );
}

export { useAssignAuditor };
