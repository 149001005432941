import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DrugAllergy = createIcon({
  path: (
    <path
      d="M11.6266 10.1734L5.61835 4.17467L8.35113 1.43237C10.2269 -0.443435 12.6836 -0.472001 14.4737 1.31811C16.2638 3.08918 16.2352 5.56486 14.3689 7.44066L11.6266 10.1734ZM10.1888 11.6208L7.43703 14.3631C5.57074 16.2389 3.1141 16.2674 1.31447 14.4773C-0.466116 12.7063 -0.447072 10.2306 1.42873 8.35476L4.17103 5.61247L10.1888 11.6208ZM23.2909 13.7537H12.9407C13.3787 11.3065 15.5306 9.44978 18.111 9.44978C20.701 9.44978 22.8529 11.3065 23.2909 13.7537ZM23.2909 15.6771C22.8529 18.1337 20.7105 20 18.111 20C15.5306 20 13.3882 18.1337 12.9407 15.6771H23.2909Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 20",
});

export { DrugAllergy };
