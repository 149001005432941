import { OrderStatus, OrderType, OrderGroup } from "enums";
import { PaginationPayload, PaginationResponse } from "types";
import { useInfiniteQuery } from "react-query";
import { axiosOrderInstance } from "config";

type SearchOrdersListItem = {
  id: string;
  orderId: string;
  contrast: string;
  status: OrderStatus;
  orderDate: Date;
  patient: {
    fullName: string;
    age: number;
    gender: string;
    mrn: string;
    ssn: string;
  };
  encounterId: string;
  description: string;
  orderedBy?: {
    fullName: string;
    roleName: string;
    pictureUrl: string;
  };
};

type SearchOrdersResponse = PaginationResponse<SearchOrdersListItem>;

type LabOrderFilters = Partial<{
  firstName: string;
  lastName: string;
  ocurrenceNumber: string;
  mrn: string;
  ssn: string;
  dob: Date;
  orderType: OrderType | null;
  orderDescription: string;
  orderDate: Date;
  orderId: string;
  orderedBy: string;
  orderGroup: OrderGroup;
}>;

type UseSearchOrdersProps = PaginationPayload & LabOrderFilters;

function useSearchOrders(props: UseSearchOrdersProps) {
  const { sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useInfiniteQuery(
    ["searchLabsOrders", { sort, descending, ...filters }],
    ({ pageParam = page }) =>
      axiosOrderInstance.get<SearchOrdersResponse>("v1/search", {
        params: {
          page: pageParam,
          sortDesc: descending || false,
          orderBy: sort,
          pageSize: size,
          ...validFilters,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}
export { useSearchOrders };
export type { UseSearchOrdersProps, LabOrderFilters, SearchOrdersListItem };
