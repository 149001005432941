import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const MedicalCross = createIcon({
  path: (
    <path
      d="M0 11.9153C0 13.0221 0.760346 13.7632 1.8768 13.7632H6.22714V18.1232C6.22714 19.2397 6.96824 19.9904 8.07507 19.9904H11.9249C13.0318 19.9904 13.7729 19.2397 13.7729 18.1232V13.7632H18.1328C19.2493 13.7632 20 13.0221 20 11.9153V8.06545C20 6.95861 19.2493 6.21752 18.1328 6.21752H13.7729V1.86718C13.7729 0.750722 13.0318 0 11.9249 0H8.07507C6.96824 0 6.22714 0.750722 6.22714 1.86718V6.21752H1.86718C0.750722 6.21752 0 6.95861 0 8.06545V11.9153Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 19.99",
});

export { MedicalCross };
