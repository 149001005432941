import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Sent = createIcon({
  path: (
    <path
      d="M11.9119 20C10.9841 20 10.6186 19.2877 10.328 18.3224L8.91284 13.6926C8.70665 13.0084 8.75351 12.5398 9.17526 12.0806L18.866 1.52765C18.9878 1.39644 18.9784 1.21837 18.8754 1.12465C18.7723 1.02156 18.6129 1.01218 18.4724 1.13402L7.92877 10.8529C7.43205 11.3027 7.01968 11.3121 6.32615 11.0965L1.612 9.65323C0.674789 9.37207 0 9.00656 0 8.0881C0 7.32896 0.609185 6.80412 1.43393 6.48547L17.7507 0.243674C18.1631 0.0843486 18.5286 0 18.8379 0C19.5501 0 20 0.449859 20 1.16214C20 1.47142 19.9157 1.83693 19.7563 2.2493L13.5426 18.5005C13.1865 19.4096 12.671 20 11.9119 20Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Sent };
