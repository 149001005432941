import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { ModalMedicalRecordsList } from "./ModalMedicalRecordsList";

function MedicalRecordsOption() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MenuItem
        key="8"
        bg="white"
        fontSize="1.0625rem"
        fontWeight="400"
        onClick={onOpen}
      >
        Medical Records
      </MenuItem>
      <ModalMedicalRecordsList isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export { MedicalRecordsOption };
