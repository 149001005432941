import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ExclamationOctagone = createIcon({
  path: (
    <path
      d="M6.28883 17.3122H11.7112C12.8457 17.3122 13.3816 16.9459 13.9712 16.3117L17.2586 12.667C17.8481 12.0149 18 11.5861 18 10.7017V6.61935C18 5.72605 17.8481 5.3062 17.2586 4.65409L13.9712 1.00943C13.3816 0.375186 12.8457 0 11.7112 0H6.28883C5.15434 0 4.60943 0.375186 4.02878 1.00943L0.741439 4.65409C0.151861 5.3062 0 5.72605 0 6.61935V10.7017C0 11.5861 0.151861 12.0149 0.741439 12.667L4.02878 16.3117C4.60943 16.9459 5.15434 17.3122 6.28883 17.3122ZM6.77122 15.8829C5.87792 15.8829 5.61886 15.6864 5.12754 15.1504L2.08139 11.8005C1.733 11.4074 1.65261 11.1841 1.65261 10.532V6.78908C1.65261 6.13697 1.733 5.91365 2.08139 5.5206L5.12754 2.17072C5.61886 1.62581 5.87792 1.43821 6.77122 1.43821H11.2288C12.1221 1.43821 12.3722 1.62581 12.8725 2.17072L15.9097 5.5206C16.267 5.91365 16.3474 6.13697 16.3474 6.78908V10.532C16.3474 11.1841 16.267 11.4074 15.9097 11.8005L12.8725 15.1504C12.3722 15.6864 12.1221 15.8829 11.2288 15.8829H6.77122ZM8.99553 10.264C9.42432 10.264 9.67444 10.0228 9.68337 9.55831L9.80844 4.84169C9.81737 4.3861 9.46005 4.04665 8.9866 4.04665C8.50422 4.04665 8.16476 4.37717 8.1737 4.83275L8.29876 9.55831C8.30769 10.0139 8.55782 10.264 8.99553 10.264ZM8.99553 13.1672C9.51365 13.1672 9.9603 12.7563 9.9603 12.2382C9.9603 11.7201 9.52258 11.3092 8.99553 11.3092C8.47742 11.3092 8.0397 11.729 8.0397 12.2382C8.0397 12.7474 8.48635 13.1672 8.99553 13.1672Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { ExclamationOctagone };
