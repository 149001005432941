import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { Diagram, ShapeState } from "../../types";

type UseDiagramsResponseItem = Omit<Diagram, "shapes" | "id"> & {
  data: ShapeState[];
  id: string;
};

function useDiagrams(encounterId: string, chartCode: string) {
  return useAxiosQuery(["diagrams", { encounterId, chartCode }], () =>
    axiosChartInstance.get<UseDiagramsResponseItem[]>(
      `v1/diagram-chart/${encounterId}/${chartCode}`
    )
  );
}

export type { UseDiagramsResponseItem };
export { useDiagrams };
