import * as React from "react";

import { Box } from "@chakra-ui/react";

import {
  Loading,
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerContainer,
} from "shared";

import { useEncounterMedicationsFile } from "modules/charts-shared";

type ShowFileProps = {
  encounterId: string;
};

function MedicationsFile(props: ShowFileProps) {
  const { encounterId } = props;
  const [file, setFile] = React.useState<Nullable<File | string | Blob>>();

  const { data, isLoading, isFetching } =
    useEncounterMedicationsFile(encounterId);

  React.useEffect(() => {
    if (data) {
      setFile(data.data);
    }
  }, [data]);

  return (
    <Box h="100%" overflow="hidden" background="white">
      {isLoading || isFetching ? (
        <Box h="100%" alignItems="center">
          <Loading />
        </Box>
      ) : (
        data?.data && (
          <PdfViewer value={file}>
            <PdfViewerContainer
              height="100%"
              activePage={
                <PdfViewerActivePage pageWidth={620} pageHeight={850} />
              }
            />
          </PdfViewer>
        )
      )}
    </Box>
  );
}

export { MedicationsFile };
