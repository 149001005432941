const ROWS_OPTIONS = [
  "abdominal aneurysm",
  "abortion",
  "allergy to poison ivy",
  "anaphalxis",
  "aortic aneurysm",
  "arthritis",
  "back injury",
  "back pain",
  "bipolar disorder",
  "bladder infections",
  "bronchiolitis",
  "bronchitis",
  "cardiac problems",
  "carpal tunnel syndrome",
  "cataract",
  "chicken pox",
  "chlamydia",
  "chronic back pain",
  "chronic headaches",
  "chronic pain",
  "cirrhosis",
  "clostridium difficile dz",
  "colon problems",
  "compression fracture(s)",
  "confusion",
  "congenital heart disease",
  "Crohn’s",
  "CVA",
  "dementia",
  "depression",
  "development delay",
  "dialysis",
  "diverticulitis",
  "DVT / PE",
  "ear infection",
  "ectopic pregnancy",
  "endometriosis",
  "enlarged prostate",
  "epididymitis",
  "erectile dysfunction",
  "febrile seizure",
  "fecal impaction",
  "fibroids",
  "gall stones",
  "GC",
  "GERD",
  "GI bleed",
  "GI disease",
  "glaucoma",
  "gout",
  "hay fever",
  "head trauma",
  "headaches",
  "hepatitis",
  "hereditary angioedema",
  "herpes genitalis",
  "hip fracture",
  "HIV",
  "hives",
  "hyperlipidemia",
  "IBS",
  "immunocompromise",
  "inner ear problems",
  "insect bite",
  "intervertebral disc disease",
  "intestinal obstruction",
  "ischemic bowel risk factors",
  "kidney disease",
  "kidney infections",
  "kidney stones",
  "low BP",
  "lupus",
  "macular degeneration",
  "malignancy",
  "migraines",
  "multiple sclerosis",
  "osteoporosis",
  "ovarian cyst(s)",
  "overdose",
  "pancreatitis",
  "paptic ulcer disease",
  "pelvic infection",
  "peptic ulcer",
  "peptic ulcer disease",
  "peripheral vascular disease",
  "pharyngitis",
  "PID",
  "pneumonia",
  "previous eye problem",
  "previous MRI",
  "prior allergic reaction",
  "prior CNS infection",
  "prior dislocation",
  "prior eye injury",
  "prior eye surgeries",
  "prior injury",
  "prior suicide attempt",
  "prostate cancer",
  "prostate infection",
  "prostatitis",
  "psychiatric disorder",
  "psychiatric problems",
  "recent MI",
  "renal disease",
  "schizophrenia",
  "seizure disorder",
  "shingles",
  "sickle cell disease",
  "sinus problems",
  "STD",
  "steroids",
  "strep throat",
  "TIA",
  "transplant",
  "ulceravtive colitis",
  "urinary tract infection",
  "valvular disease",
];

export { ROWS_OPTIONS };
