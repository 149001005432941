import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const LabsList = createIcon({
  path: (
    <g fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.78092 12.103C4.63604 12.103 4 11.4669 4 10.3039V5.88794C4 2.01716 6.03534 0 9.96063 0H14.3766C15.5396 0 16.1757 0.636042 16.1757 1.78092C16.1757 2.9258 15.5396 3.56184 14.3766 3.56184H10.1787C8.48864 3.56184 7.56184 4.43412 7.56184 6.19687V10.3039C7.56184 11.4669 6.94397 12.103 5.78092 12.103ZM38.2191 12.103C37.0742 12.103 36.4382 11.4669 36.4382 10.3039V6.19687C36.4382 4.43412 35.4932 3.56184 33.8213 3.56184H29.6234C28.4604 3.56184 27.8243 2.9258 27.8243 1.78092C27.8243 0.636042 28.4604 0 29.6234 0H34.0394C37.9647 0 40 2.01716 40 5.88794V10.3039C40 11.4669 39.3821 12.103 38.2191 12.103ZM9.96063 36C6.03534 36 4 33.9828 4 30.0939V25.6779C4 24.5149 4.61787 23.8788 5.78092 23.8788C6.9258 23.8788 7.56184 24.5149 7.56184 25.6779V29.785C7.56184 31.5659 8.48864 32.4382 10.1787 32.4382H14.3766C15.5396 32.4382 16.1757 33.0742 16.1757 34.2191C16.1757 35.364 15.5396 36 14.3766 36H9.96063ZM29.6234 36C28.4604 36 27.8243 35.364 27.8243 34.2191C27.8243 33.0742 28.4604 32.4382 29.6234 32.4382H33.8213C35.4932 32.4382 36.4382 31.5659 36.4382 29.785V25.6779C36.4382 24.5331 37.056 23.8788 38.2191 23.8788C39.364 23.8788 40 24.5149 40 25.6779V30.0939C40 33.9828 37.9647 36 34.0394 36H29.6234Z"
        fill="currentColor"
      />
      <path
        d="M30.9857 25.0055L25.4424 16.635V11.7065H25.8495C26.3281 11.7065 26.7161 11.3184 26.7161 10.8398V7.86662C26.7161 7.38804 26.3281 7 25.8495 7H17.5646C17.086 7 16.698 7.38804 16.698 7.86662V10.8398C16.698 11.3184 17.086 11.7065 17.5646 11.7065H17.9717V16.635L12.4284 25.0055C11.9043 25.797 11.8578 26.8081 12.307 27.6443C12.7562 28.4805 13.6249 29 14.5741 29H28.8399C29.7892 29 30.6579 28.4805 31.1071 27.6443C31.5564 26.8081 31.5099 25.797 30.9857 25.0055ZM18.4312 8.73324H24.9829V9.97321C24.3953 9.97321 19.1447 9.97321 18.4312 9.97321V8.73324ZM23.7092 11.7065V16.8959C23.7092 17.0661 23.7593 17.2324 23.8532 17.3743L24.7345 18.7052H20.7595L16.7007 24.834C16.4365 25.2329 15.899 25.3424 15.4997 25.078C15.1006 24.8137 14.9914 24.2761 15.2556 23.877L19.5609 17.3742C19.6549 17.2324 19.7049 17.066 19.7049 16.8958V11.7065H23.7092Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 42 35",
});

export { LabsList };
