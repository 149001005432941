import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useSignOrderProviderAuthorization(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["signOrderProviderAuthorization", { encounterId }],
    () => {
      return axiosPatientInstance.post(
        `v1/Encounter/${encounterId}/order-signature`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "orderProviderAuthorization",
          { encounterId },
        ]);
      },
    }
  );
}

export { useSignOrderProviderAuthorization };
