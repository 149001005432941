import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared";

const sectionId = "SASS";
const triageChartCode = "T012x";

function N12Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { watch } = useFormContext;

  const q001AllNormal = Boolean(
    watch(`Q001.${triageChartCode}${sectionId}Q001A023`)
  );
  const q002AllNormal = Boolean(
    watch(`Q002.${triageChartCode}${sectionId}Q002A019`)
  );
  const q003AllNormal = Boolean(
    watch(`Q003.${triageChartCode}${sectionId}Q003A014`)
  );
  const q004AllNormal = Boolean(
    watch(`Q004.${triageChartCode}${sectionId}Q004A006`)
  );
  const q005AllNormal = Boolean(
    watch(`Q005.${triageChartCode}${sectionId}Q005A014`)
  );
  const q006AllNormal = Boolean(
    watch(`Q006.${triageChartCode}${sectionId}Q006A022`)
  );
  const q007AllNormal = Boolean(
    watch(`Q007.${triageChartCode}${sectionId}Q007A011`)
  );
  const q008AllNormal = Boolean(
    watch(`Q008.${triageChartCode}${sectionId}Q008A021`)
  );
  const q009AllNormal = Boolean(
    watch(`Q009.${triageChartCode}${sectionId}Q009A012`)
  );
  const q011AllNormal = Boolean(
    watch(`Q011.${triageChartCode}${sectionId}Q011A001`)
  );

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              showAuditsBtn={false}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessment"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q001.T012xSASSQ001A023"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Primary Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T012xSASSQ001A022"
                  label="Date/Time"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement
                  type="date"
                  name="Q001.T012xSASSQ001A001"
                  width="20rem"
                  isDisabled={q001AllNormal}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T012xSASSQ001A024"
                    label="appears nml"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T012xSASSQ001A025"
                    label="ill"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T012xSASSQ001A026"
                    label="uncomfortable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T012xSASSQ001A027"
                    label="crying"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.T012xSASSQ001A028"
                    label="inconsolable"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ001A029"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway patent"
                    name="Q001.T012xSASSQ001A002"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="airway compromised"
                    name="Q001.T012xSASSQ001A003"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ001A004"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="breathing unlabored"
                    name="Q001.T012xSASSQ001A005"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="labored breathing"
                    name="Q001.T012xSASSQ001A006"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="respiratory distress:"
                    name="Q001.T012xSASSQ001A007"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mild"
                    fontStyle="italic"
                    name="Q001.T012xSASSQ001A030"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="moderate"
                    fontStyle="italic"
                    name="Q001.T012xSASSQ001A031"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="severe"
                    fontStyle="italic"
                    name="Q001.T012xSASSQ001A032"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ001A008"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="circulation nml"
                    name="Q001.T012xSASSQ001A009"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q001.T012xSASSQ001A010"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q001.T012xSASSQ001A011"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ001A012"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="neuro awake & alert"
                    name="Q001.T012xSASSQ001A013"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="listless"
                    name="Q001.T012xSASSQ001A014"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="fussy"
                    name="Q001.T012xSASSQ001A018"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q001.T003xSASSQ001A016"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q001.T012xSASSQ001A033"
                    isDisabled={q001AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ001A034"
                    isDisabled={q001AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="NEURO"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q002.T012xSASSQ002A019"
                >
                  all normal
                </ChartRowElement>
              }
            >
              NEURO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x 4"
                    name="Q002.T012xSASSQ002A001"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.T012xSASSQ002A018"
                    label="disoriented to"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q002.T012xSASSQ002A002"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q002.T003xSASSQ002A003"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    name="Q002.T012xSASSQ002A004"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q002.T003xSASSQ002A005"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T003xSASSQ002A006"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="confused"
                    name="Q002.013xSASSQ002A016"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="memory loss"
                    name="Q002.T003xSASSQ002A017"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T003xSASSQ002A006"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="PERRL"
                    name="Q002.T012xSASSQ002A007"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pupils unequal"
                    name="Q002.T012xSASSQ002A008"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="circle-option"
                    label="L"
                    name="Q002.T003xSASSQ002A011"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T012xSCHCQ002A012"
                    defaultValue={0}
                    width="4rem"
                    textAlign="center"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="label" label="mm" fontStyle="italic" />
                  <ChartRowElement
                    type="circle-option"
                    label="R"
                    name="Q002.T003xSASSQ002A009"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T012xSCHCQ002A010"
                    defaultValue={0}
                    width="4rem"
                    textAlign="center"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="label" label="mm" fontStyle="italic" />
                  <ChartRowElement
                    type="input"
                    name="Q001.T012xSASSQ002A020"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="maintains eye contact"
                    name="Q002.T012xSASSQ002A013"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lack of eye contact"
                    name="Q002.T012xSASSQ002A014"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T012xSCHCQ002A015"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q002.T012xSASSQ002A021"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T012xSCHCQ002A022"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="HEENT"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q003.T012xSASSQ003A014"
                >
                  all normal
                </ChartRowElement>
              }
            >
              HEENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no signs of trauma"
                    name="Q003.T012xSASSQ003A001"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="scalp injury"
                    name="Q003.T012xSASSQ003A002"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="eye injury"
                    name="Q003.T012xSASSQ003A004"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="facial injury"
                    name="Q003.T012xSASSQ003A005"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dental injury"
                    name="Q003.T012xSASSQ003A006"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T012xSASSQ003A003"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="raccoon eyes"
                    name="Q003.T012xSASSQ003A008"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Battle’s sign"
                    name="Q003.T012xSASSQ003A009"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T012xSASSQ003A010"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="fontanel:"
                    name="Q003.T012xSASSQ003A015"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bulging"
                    fontStyle="italic"
                    name="Q003.T012xSASSQ003A011"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sunken"
                    fontStyle="italic"
                    name="Q003.T012xSASSQ003A012"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T012xSASSQ003A013"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml mucous membranes"
                    name="Q003.T012xSASSQ003A016"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dry mucous membranes"
                    name="Q003.T012xSASSQ003A017"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q003.T012xSASSQ003A018"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q003.T012xSASSQ003A019"
                    isDisabled={q003AllNormal}
                  />

                  <ChartRowElement
                    type="input"
                    name="Q003.T012xSASSQ003A020"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Chest"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q011.T012xSASSQ011A001"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Chest
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml breath sounds"
                    name="Q011.T012xSASSQ011A002"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="decreased breath sounds"
                    name="Q011.T012xSASSQ011A003"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="tachypnea"
                    name="Q011.T012xSASSQ011A004"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="wheezing"
                    name="Q011.T012xSASSQ011A005"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rales"
                    name="Q011.T012xSASSQ011A006"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rhonchi"
                    name="Q011.T012xSASSQ011A007"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="stridor"
                    name="Q011.T012xSASSQ011A008"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="crackles"
                    name="Q011.T012xSASSQ011A009"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="grunting"
                    name="Q011.T012xSASSQ011A010"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T012xSASSQ011A011"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="accessory muscle use"
                    name="Q011.T012xSASSQ011A012"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="retractions"
                    name="Q011.T012xSASSQ011A013"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="splinting"
                    name="Q011.T012xSASSQ011A014"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="nasal flaring"
                    name="Q011.T012xSASSQ011A015"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="orthopnea"
                    name="Q011.T012xSASSQ011A016"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="exertional SOB"
                    name="Q011.T012xSASSQ011A017"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="tripod"
                    name="Q011.T012xSASSQ011A018"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="choking"
                    name="Q011.T012xSASSQ011A019"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T012xSASSQ011A020"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="chest non-tender"
                    name="Q011.T012xSASSQ011A021"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="chest wall tenderness"
                    name="Q011.T012xSASSQ011A022"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T012xSASSQ011A023"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q011.T012xSASSQ011A024"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q011.T012xSASSQ011A025"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Neck / Back"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q004.T012xSASSQ004A006"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q004.T012xSASSQ004A001"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q004.T012xSASSQ004A007"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q004.T012xSASSQ004A008"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q004.T012xSASSQ004A009"
                    isDisabled={q004AllNormal}
                  />

                  <ChartRowElement
                    type="input"
                    name="Q004.T012xSASSQ004A003"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q004.T012xSASSQ004A004"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q004.T012xSASSQ004A002"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T012xSASSQ004A005"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q004.T012xSASSQ004A010"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q004.T012xSASSQ004A011"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T012xSASSQ004A012"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q004.T012xSASSQ004A013"
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q004.T012xSASSQ004A014"
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="CVS"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q005.T012xSASSQ005A014"
                >
                  all normal
                </ChartRowElement>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="regular rate"
                    name="Q005.T012xSASSQ005A001"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tachycardia"
                    name="Q005.T012xSASSQ005A002"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bradycardia"
                    name="Q005.T012xSASSQ005A003"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T012xSASSQ005A004"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulses strong / equal"
                    name="Q005.T012xSASSQ005A005"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pulse deficit"
                    name="Q005.T012xSASSQ005A007"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T012xSASSQ005A008"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cap refill greater than 2 sec"
                    name="Q005.T012xSASSQ005A009"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cap refill greater than 2 sec"
                    name="Q005.T012xSASSQ005A010"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T012xSASSQ005A011"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q005.T012xSASSQ005A012"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.T012xSASSQ005A013"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Abdomen / Pelvis"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q006.T012xSASSQ006A022"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Abdomen / Pelvis
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="soft / non-tender"
                    name="Q006.T012xSASSQ006A006"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q006.T012xSASSQ006A002"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="guarding"
                    name="Q006.T012xSASSQ006A003"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rebound"
                    name="Q006.T012xSASSQ006A004"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="firm"
                    name="Q006.T012xSASSQ006A023"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A005"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="seat belt marks"
                    name="Q006.T012xSASSQ006A019"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A020"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="bowel sounds nml"
                    name="Q006.T012xSASSQ006A010"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="bowel sounds"
                    name="Q006.T012xSASSQ006A011"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hyper"
                    fontStyle="italic"
                    name="Q006.T012xSASSQ006A012"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hypo"
                    fontStyle="italic"
                    name="Q006.T012xSASSQ006A013"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="absent"
                    fontStyle="italic"
                    name="Q006.T012xSASSQ006A014"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A015"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="blood at urethral meatus"
                    name="Q006.T012xSASSQ006A018"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A021"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="catheter present"
                    name="Q006.T012xSASSQ006A016"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A017"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q006.T012xSASSQ006A024"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q006.T012xSASSQ006A025"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Extremities"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  w="93px"
                  name="Q007.T012xSASSQ007A011"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q007.T012xSASSQ007A007"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q007.T012xSASSQ007A012"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q007.T012xSASSQ007A013"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="wound"
                    name="Q007.T012xSASSQ007A014"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="drainage"
                    name="Q007.T012xSASSQ007A015"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T012xSASSQ007A009"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q007.T012xSASSQ007A016"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q007.T012xSASSQ007A017"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="calf tenderness"
                    name="Q007.T012xSASSQ007A018"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="pedal edema"
                    name="Q007.T012xSASSQ007A019"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="edema"
                    name="Q007.T012xSASSQ007A020"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q007.T012xSASSQ007A021"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="joint pain"
                    name="Q007.T012xSASSQ007A022"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T012xSASSQ007A023"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="moves all extremities"
                    name="Q007.T012xSASSQ007A004"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q007.T012xSASSQ007A005"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="contractures"
                    name="Q007.T012xSASSQ007A024"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="limping gait"
                    name="Q007.T012xSASSQ007A025"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="pain with weight bearing"
                    name="Q007.T012xSASSQ007A026"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="unable to bear weight"
                    name="Q007.T012xSASSQ007A027"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="weakness"
                    name="Q007.T012xSASSQ007A028"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="numbness"
                    name="Q007.T012xSASSQ007A029"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T012xSASSQ007A011"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q007.T012xSASSQ007A030"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q007.T012xSASSQ007A006"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Skin"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  name="Q008.T012xSASSQ008A021"
                  w="93px"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q008.T012xSASSQ008A022"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q008.T012xSASSQ008A015"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q008.T012xSASSQ008A016"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abnormal markings"
                    name="Q008.T012xSASSQ008A023"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q008.T012xSASSQ008A017"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q008.T012xSASSQ008A024"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bleeding"
                    name="Q008.T012xSASSQ008A025"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="drainage"
                    name="Q008.T012xSASSQ008A026"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A018"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="burn"
                    name="Q008.T012xSASSQ008A027"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A028"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="warm / dry"
                    name="Q008.T012xSASSQ008A001"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q008.T012xSASSQ008A003"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q008.T012xSASSQ008A029"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="flushed"
                    name="Q008.T012xSASSQ008A030"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="mottled"
                    name="Q008.T012xSASSQ008A031"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q008.T012xSASSQ008A019"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="increased warmth"
                    name="Q008.T012xSASSQ008A032"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A020"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact"
                    name="Q008.T012xSASSQ008A006"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcers"
                    name="Q008.T012xSASSQ008A033"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sacrum"
                    fontStyle="italic"
                    name="Q008.T012xSASSQ008A034"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hip"
                    fontStyle="italic"
                    name="Q008.T012xSASSQ008A035"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="heel"
                    fontStyle="italic"
                    name="Q008.T012xSASSQ008A036"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A018"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="open wounds"
                    name="Q008.T012xSASSQ008A037"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="needle marks"
                    name="Q008.T012xSASSQ008A038"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="lesion(s)"
                    name="Q008.T012xSASSQ008A039"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rash"
                    name="Q008.T012xSASSQ008A040"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="uticaria"
                    name="Q008.T012xSASSQ008A041"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hives"
                    name="Q008.T012xSASSQ008A042"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="blisters"
                    name="Q008.T012xSASSQ008A043"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q008.T012xSASSQ008A044"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="erythema"
                    name="Q008.T012xSASSQ008A045"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A046"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="turgor good"
                    name="Q008.T012xSASSQ008A047"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="poor turgor"
                    name="Q008.T012xSASSQ008A048"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A049"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q008.T012xSASSQ008A050"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q008.T012xSASSQ008A051"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Functional / Nutritional Assessment"
              subsectionOptions={
                <ChartRowElement
                  type="checkbox-button"
                  name="Q009.T012xSASSQ009A012"
                  w="93px"
                >
                  all normal
                </ChartRowElement>
              }
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="development age appropiate"
                    name="Q009.T012xSASSQ009A001"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="obese"
                    name="Q009.T012xSASSQ009A002"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malnourished"
                    name="Q009.T012xSASSQ009A003"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dehydrated"
                    name="Q009.T012xSASSQ009A004"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T012xSASSQ009A005"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appears nourished / hydrated"
                    name="Q009.T012xSASSQ009A006"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight loss"
                    name="Q009.T012xSASSQ009A007"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight gain"
                    name="Q009.T012xSASSQ009A008"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T012xSASSQ009A009"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="other"
                    name="Q009.T012xSASSQ009A013"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    type="input"
                    name="Q009.T012xSASSQ009A011"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Additional findings"
              isRequired={false}
            >
              Additional findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q010.T012xSASSQ010A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N12Assessments };
