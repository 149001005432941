import { AxiosError } from "axios";
import {
  MutationFunction,
  MutationKey,
  useMutation,
  UseMutationOptions,
} from "react-query";
import { useHandleUnauthorizedError } from "./useHandleUnauthorizedError";

/**
 * --- IMPORTANT ---
 * Only use this custom mutation in components inside the
 * auth context, otherwise it won't work due to the useHandleUnauthorizedError
 * hook.
 */
function useAxiosMutation<Response, Input>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<Response, Input>,
  options?: UseMutationOptions<Response, Error, Input>
) {
  const mutationResult = useMutation<Response, AxiosError, Input>(
    mutationKey,
    mutationFn,
    options
  );

  useHandleUnauthorizedError(mutationResult.error?.response?.status);

  return mutationResult;
}

export { useAxiosMutation };
