import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Clear = createIcon({
  path: (
    <path
      d="M0 9.28711C0 12.8833 2.88574 15.769 6.47461 15.769C10.0635 15.769 12.9419 12.8833 12.9419 9.28711C12.9419 5.69824 10.0635 2.81982 6.47461 2.81982C6.02051 2.81982 5.58838 2.87109 5.20752 2.94434L7.15576 1.01807C7.26562 0.900879 7.31689 0.754395 7.31689 0.600586C7.31689 0.263672 7.06787 0 6.74561 0C6.5625 0 6.41602 0.0585938 6.31348 0.175781L3.2959 3.2373C3.17871 3.35449 3.11279 3.51562 3.11279 3.67676C3.11279 3.84521 3.17139 3.9917 3.2959 4.11621L6.31348 7.14844C6.42334 7.2583 6.5625 7.31689 6.73828 7.31689C7.06787 7.31689 7.31689 7.06787 7.31689 6.73096C7.31689 6.57715 7.26562 6.43066 7.14844 6.31348L4.96582 4.14551C5.39062 4.04297 5.91064 3.99902 6.47461 3.99902C9.41162 3.99902 11.7627 6.3501 11.7627 9.28711C11.7627 12.2314 9.41162 14.5898 6.47461 14.5898C3.5376 14.5898 1.1792 12.2314 1.1792 9.28711C1.1792 8.93555 0.959473 8.6792 0.60791 8.6792C0.249023 8.6792 0 8.93555 0 9.28711Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 13 16",
});

export { Clear };
