import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const MedicalRecords = createIcon({
  path: (
    <path
      d="M9.62185 32C5.91966 32 4 30.0975 4 26.4296V5.57043C4 1.90252 5.91966 0 9.62185 0H30.3781C34.0803 0 36 1.90252 36 5.57043V26.4296C36 30.0803 34.0803 32 30.3781 32H9.62185ZM12.0214 4.88484C10.6502 4.88484 9.60471 5.98179 9.60471 7.47295C9.60471 9.80396 12.1414 11.7408 13.6326 12.752C13.7868 12.872 13.9582 12.9405 14.0782 12.9405C14.2153 12.9405 14.3696 12.8548 14.5238 12.752C16.015 11.8436 18.5174 9.80396 18.5174 7.47295C18.5174 5.98179 17.489 4.88484 16.0664 4.88484C15.1751 4.88484 14.4553 5.43332 14.0611 6.17033C13.6668 5.43332 12.9641 4.88484 12.0214 4.88484ZM10.7359 20.602H27.7043C28.3385 20.602 28.8184 20.105 28.8184 19.4708C28.8184 18.8709 28.3385 18.391 27.7043 18.391H10.7359C10.0846 18.391 9.60471 18.8709 9.60471 19.4708C9.60471 20.105 10.0846 20.602 10.7359 20.602ZM10.7359 26.4638H23.368C24.0021 26.4638 24.4821 26.0011 24.4821 25.384C24.4821 24.7499 24.0021 24.2528 23.368 24.2528H10.7359C10.0846 24.2528 9.60471 24.7499 9.60471 25.384C9.60471 26.0011 10.0846 26.4638 10.7359 26.4638Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 40 30",
});

export { MedicalRecords };
