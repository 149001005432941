import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Hand = createIcon({
  path: (
    <path
      d="M0 14.7974V5.14037C0 4.49657 0.513032 3.98354 1.15684 3.98354C1.80064 3.98354 2.30361 4.49657 2.30361 5.14037V11.2465C2.56516 11.0855 2.84682 10.9145 3.15866 10.7737V2.00183C3.15866 1.35802 3.6717 0.844993 4.3155 0.844993C4.94925 0.844993 5.46228 1.35802 5.46228 2.00183V10.0896C5.754 10.0393 6.05578 9.99909 6.34751 9.96891V1.15684C6.34751 0.513032 6.86054 0 7.50434 0C8.14815 0 8.66118 0.513032 8.66118 1.15684V10.0796C8.96296 10.1399 9.24463 10.2204 9.50617 10.3109V2.58528C9.50617 1.94147 10.0192 1.42844 10.6529 1.42844C11.2968 1.42844 11.8098 1.94147 11.8098 2.58528V13.2181C11.8098 13.5501 11.9406 13.6607 12.1317 13.6607C12.2826 13.6607 12.4234 13.5501 12.5642 13.2483L13.9625 10.2305C14.4051 9.3251 14.8377 8.91267 15.5217 8.96296C16.1253 8.99314 16.5176 9.4257 16.4774 10.0192C16.4472 10.5524 16.0448 11.7494 15.743 12.695C15.1696 14.5661 14.4957 16.6786 13.5501 18.2277C12.1719 20.7426 10.1902 22 7.10197 22C3.02789 22 0 19.3141 0 14.7974Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 22",
});

export { Hand };
