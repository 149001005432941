import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const AddRounded = createIcon({
  path: (
    <path
      d="M9 18C13.9235 18 18 13.9147 18 9C18 4.07647 13.9147 0 8.99118 0C4.07647 0 0 4.07647 0 9C0 13.9147 4.08529 18 9 18ZM4.69412 9.00882C4.69412 8.55 5.01176 8.23235 5.47059 8.23235H8.23235V5.47059C8.23235 5.01176 8.54118 4.68529 8.99118 4.68529C9.45 4.68529 9.76765 5.01176 9.76765 5.47059V8.23235H12.5382C12.9882 8.23235 13.3147 8.55 13.3147 9.00882C13.3147 9.45882 12.9882 9.75882 12.5382 9.75882H9.76765V12.5294C9.76765 12.9882 9.45 13.3059 8.99118 13.3059C8.54118 13.3059 8.23235 12.9794 8.23235 12.5294V9.75882H5.47059C5.01176 9.75882 4.69412 9.45882 4.69412 9.00882Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { AddRounded };
