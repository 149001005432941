import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetMergeHistoryReport(
  encounterId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["mergeHistoryReport", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(`v1/merge-history/report/${encounterId}`, {
        responseType: "blob",
      }),
    { ...options }
  );
}

export { useGetMergeHistoryReport };
