import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const LeftArrow = createIcon({
  path: (
    <path
      d="M0 10C0 10.288 0.103687 10.5415 0.322581 10.7604L9.45853 19.6889C9.65438 19.8963 9.90784 20 10.2074 20C10.8065 20 11.2673 19.5507 11.2673 18.9516C11.2673 18.6521 11.1406 18.3986 10.9562 18.2028L2.56912 10L10.9562 1.79724C11.1406 1.60138 11.2673 1.33641 11.2673 1.04839C11.2673 0.449309 10.8065 0 10.2074 0C9.90784 0 9.65438 0.103687 9.45853 0.299539L0.322581 9.23963C0.103687 9.447 0 9.71198 0 10Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 12 20",
});

export { LeftArrow };
