import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { SupervisingProvider } from "../../types";

function useUpdateSupervisingProvider(providerId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateSupervisingProvider"],
    (input: SupervisingProvider) => {
      return axiosPatientInstance.put<SupervisingProvider>(
        `v1/supervising-provider/${providerId}`,
        input
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("supervisingProviderList");
        queryClient.invalidateQueries("activeSupervisingProvider");
      },
    }
  );
}

export { useUpdateSupervisingProvider };
