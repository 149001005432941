import {
  Box,
  Button,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ResponsiveValue,
  UseDisclosureReturn,
  chakra,
  useDisclosure,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  EmrCheckCircle,
  EmrExclamationCircle,
  SiChevronRight,
} from "@medstonetech/slate-icons";
import { differenceInMonths, format, isValid, lastDayOfMonth } from "date-fns";
import {
  useEncounterOverview,
  useEncounterSideInfo,
} from "modules/reception/api";
import { ReactElement, useMemo, useState } from "react";
import { DividerList } from "shared/divider-list";
import { Loading } from "shared/loading";
import { dateWithoutTimezone } from "utils";
import { PrintLabelsActionModal } from "./PrintLabelsModal";
import { SearchProviderInput } from "./SearchProviderInput";

type PrintCustomLabelsModalProps = {
  encounterId: string;
} & UseDisclosureReturn;

type DataItemProps = {
  needVerify?: boolean;
  children: [string, string | ReactElement];
};

function DataItem(props: DataItemProps) {
  const {
    needVerify,
    children: [label, value],
  } = props;
  const styleProps = {
    fontSize: "1.0625rem",
    lineHeight: "1.25rem",
    display: "flex",
    flexDirection: "row" as ResponsiveValue<"row">,
    alignItems: "center",
  };

  return (
    <Box
      width="95%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="52px"
    >
      <chakra.span color="gray.550" title={label} {...styleProps}>
        <Icon
          as={needVerify ? EmrExclamationCircle : EmrCheckCircle}
          color={needVerify ? "red" : "green"}
          size="lg"
          height={needVerify ? "33px" : "20px"}
          width={needVerify ? "33px" : "20px"}
          margin={needVerify ? "0" : "0 8px 0 8px"}
        />
        {label}
      </chakra.span>
      {typeof value === "string" && (
        <chakra.span
          {...styleProps}
          maxWidth="134px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          title={value}
        >
          {value}
        </chakra.span>
      )}
      {typeof value === "object" && value}
    </Box>
  );
}

function PrintLabelsActionModalContent(props: PrintCustomLabelsModalProps) {
  const { encounterId, ...rest } = props;
  const { isOpen, onClose } = rest;
  const printLabelsDisclose = useDisclosure();

  const [customProvider, setCustomProvider] = useState("");
  const onChangeCustomProviderInput = (provider: string) => {
    setCustomProvider(provider);
  };

  const { data: encounterSideInfo, isLoading: isLoadingEncounterSideInfo } =
    useEncounterSideInfo(encounterId, {
      enabled: isOpen,
    });
  const { data: encounterOverview, isLoading: isLoadingEncounterOverview } =
    useEncounterOverview(encounterId, {
      enabled: isOpen,
    });

  const isLoading = isLoadingEncounterSideInfo || isLoadingEncounterOverview;

  const { yearsBirth } = useMemo(() => {
    const dob = new Date(encounterSideInfo?.data.dob || new Date());
    let currentDate = new Date();

    if (dob.getMonth() !== currentDate.getMonth())
      currentDate = lastDayOfMonth(currentDate);

    const diffMonths = differenceInMonths(dob, currentDate);
    const yearsBirthday = Math.abs(Math.trunc(diffMonths / 12));
    return { yearsBirth: yearsBirthday };
  }, [encounterSideInfo]);

  const dobDescription = useMemo(() => {
    try {
      if (
        !encounterSideInfo?.data.dob ||
        !isValid(dateWithoutTimezone(encounterSideInfo?.data.dob))
      )
        return "N/A";
      return format(
        dateWithoutTimezone(encounterSideInfo?.data.dob),
        "MM/dd/yyyy"
      );
    } catch (error) {
      return "N/A";
    }
  }, [encounterSideInfo]);

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 30px"
      >
        <Button
          variant="label"
          onClick={() => {
            onClose();
          }}
          color="blue"
        >
          Close
        </Button>
        <Heading fontWeight="600" fontSize="1.0625rem">
          Custom Labels
        </Heading>
        {!isLoading && (
          <Button
            isDisabled={!customProvider}
            variant="label"
            onClick={() => {
              printLabelsDisclose.onOpen();
              onClose();
            }}
            color="blue"
          >
            Continue
            <Icon
              as={SiChevronRight}
              height="12px"
              width="7px"
              marginLeft="8px"
            />
          </Button>
        )}
        {isLoading && <Loading width="fit-content" />}
      </ModalHeader>
      <ModalBody padding="0 15px 15px" overflow="auto">
        <Box borderRadius="10px" bg="white">
          <DividerList dividerLeftMargin="28px">
            <DataItem>
              First Name & MI {encounterSideInfo?.data.firstName ?? ""}
            </DataItem>
            <DataItem>
              Last Name {encounterSideInfo?.data.lastName ?? ""}
            </DataItem>
            <DataItem>DOB {dobDescription}</DataItem>
            <DataItem>Age {yearsBirth.toString()}</DataItem>
            <DataItem>Gender {encounterSideInfo?.data.gender ?? ""}</DataItem>
            <DataItem>
              Encounter Date
              {format(
                new Date(encounterOverview?.data.date || new Date()),
                "MM/dd/yyyy"
              )}
            </DataItem>
            <DataItem>MRN {encounterSideInfo?.data.mrn ?? ""}</DataItem>
            <DataItem needVerify={!customProvider}>
              Provider
              <Input
                value={customProvider}
                variant="unstyled"
                size="md"
                textAlign="right"
                padding="0 !important"
                onChange={(event) => {
                  onChangeCustomProviderInput(event.target.value);
                }}
                readOnly
              />
            </DataItem>
          </DividerList>
        </Box>

        <SearchProviderInput onSelect={onChangeCustomProviderInput} />

        <PrintLabelsActionModal
          encounterId={encounterId}
          customProvider={customProvider}
          {...printLabelsDisclose}
        />
      </ModalBody>
    </>
  );
}

function PrintCustomLabelsActionModal(props: PrintCustomLabelsModalProps) {
  const { encounterId, ...rest } = props;
  const { isOpen } = rest;
  return (
    <Modal isCentered {...rest}>
      <ModalOverlay />
      <ModalContent
        bgColor="gray.200"
        padding="0.5rem"
        minW="540px"
        height="90%"
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        {isOpen && <PrintLabelsActionModalContent {...props} />}
      </ModalContent>
    </Modal>
  );
}

export { PrintCustomLabelsActionModal };
