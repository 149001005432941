import * as React from "react";
import { yupResolver as Resolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  ListItem,
  ListIcon,
  List,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Button, InputPassword, FormControl } from "shared";
import { formMessages } from "messages/form";
import { ChangePasswordForm } from "types";
import { useChangePassword } from "../../api/mutations";
import { useToast } from "../../../../hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "config";
import { extractApiErrorMessage } from "utils";
import {
  EmrCheckCircle,
  EmrExclamationCircle,
} from "@medstonetech/slate-icons";

type ChangePasswordModalProps = {
  isOpen: boolean;
  onClose(): void;
  isCreateNewPassword?: boolean;
};

type PasswordRequirementItemProps = {
  label: string;
  isValidated?: boolean;
};

const inputProps = {
  variant: "outline",
};

const PasswordRequirementItem = ({
  label,
  isValidated,
}: PasswordRequirementItemProps) => {
  return (
    <ListItem display="flex" alignItems="center" height="32px">
      <ListIcon
        as={isValidated ? EmrCheckCircle : EmrExclamationCircle}
        color={isValidated ? "green" : "red"}
        fontSize={!isValidated ? "34px" : undefined}
        w={isValidated ? "25px" : undefined}
        h={isValidated ? "25px" : undefined}
        ml={isValidated ? 1.5 : undefined}
        mr={isValidated ? "11px" : undefined}
        pr={isValidated ? 1 : undefined}
      />
      &#8226;
      <Text fontSize="md" pl={2}>
        {label}
      </Text>
    </ListItem>
  );
};

function ChangePassword(props: ChangePasswordModalProps) {
  const toast = useToast();
  const { user, logout } = useAuth0();
  const { isOpen, onClose, isCreateNewPassword } = props;
  const [isPreviousPassword, setIsPreviousPassword] = React.useState(false);

  const prefixEmail = user?.email
    ? user?.email.substring(0, user?.email.indexOf("@"))
    : "";

  const ChangePasswordFormSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(formMessages.required("New password"))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "New password isn't secure"
      )
      .test("noEmailMatch", "New password isn't secure", (password) => {
        if (!!password) {
          const matchesWithEmail = password.match(new RegExp(prefixEmail, "g"));
          return !Boolean(matchesWithEmail);
        }
        return false;
      }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!")
      .required(formMessages.required("Confirm password")),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    watch,
  } = useForm<ChangePasswordForm>({
    resolver: Resolver(ChangePasswordFormSchema),
    mode: "onChange",
  });

  const passwordValue = watch("newPassword");

  React.useEffect(() => {
    setIsPreviousPassword(false);
  }, [passwordValue]);

  const { mutateAsync: savePassword, isLoading } = useChangePassword();

  const onSubmit = async (form: ChangePasswordForm) => {
    try {
      const response = await savePassword(form);
      if (response.status === 204) {
        toast({
          title: "Password changed successfully!",
        });
        if (isCreateNewPassword) {
          logout({ returnTo: config.auth0LogoutUrl });
        } else {
          reset();
          onClose();
        }
      }
    } catch (error: unknown) {
      const errorMessage = extractApiErrorMessage(error);
      toast({ status: "error", description: errorMessage });
      if (errorMessage === "Error. Password has previously been used.") {
        setIsPreviousPassword(true);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          if (!isCreateNewPassword) onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="gray.100"
          display="flex"
          flexDirection="column"
          height="648px"
          width="375px"
          maxWidth="375px"
          padding="1rem"
        >
          <ModalHeader
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            fontSize="28px"
            minHeight="60px"
            fontWeight="600"
            pb={isCreateNewPassword ? 0 : undefined}
          >
            {isCreateNewPassword ? "Create New Password" : "Change Password"}
          </ModalHeader>

          <ModalBody>
            {isCreateNewPassword && (
              <Text variant="p" textAlign="center" pb={4} pt={0}>
                You are required to create a new password.
              </Text>
            )}
            <form>
              <VStack spacing="1.5rem">
                <FormControl>
                  <InputPassword
                    placeholder="New Password"
                    {...register("newPassword")}
                    {...inputProps}
                    width="100%"
                  />
                </FormControl>
                <Box w="100%">
                  <chakra.p
                    fontSize="14px"
                    fontWeight="700"
                    color="gray.650"
                    textAlign="center"
                  >
                    Passwords Requirements:
                  </chakra.p>
                  <List spacing={1}>
                    <PasswordRequirementItem
                      label="upper and lower case letters"
                      isValidated={/^(?=.*?[A-Z])(?=.*?[a-z])/.test(
                        passwordValue
                      )}
                    />
                    <PasswordRequirementItem
                      label="at least 1 number"
                      isValidated={/^(?=.*?[0-9])/.test(passwordValue)}
                    />
                    <PasswordRequirementItem
                      label="at least 1 special character"
                      isValidated={/^(?=.*?[#?!@$%^&*-])/.test(passwordValue)}
                    />
                    <PasswordRequirementItem
                      label="not match any part of your email"
                      isValidated={
                        !Boolean(
                          passwordValue &&
                            passwordValue.match(new RegExp(prefixEmail, "g"))
                        )
                      }
                    />
                    <PasswordRequirementItem
                      label="at least 8 characters long"
                      isValidated={
                        !!passwordValue && /^.{8,}/.test(passwordValue)
                      }
                    />
                    <PasswordRequirementItem
                      label="no previous passwords"
                      isValidated={!isPreviousPassword}
                    />
                  </List>
                </Box>
                <FormControl error={errors.confirmPassword?.message}>
                  <InputPassword
                    placeholder="Confirm Password"
                    {...register("confirmPassword")}
                    {...inputProps}
                    width="100%"
                  />
                </FormControl>
              </VStack>
            </form>
          </ModalBody>

          <ModalFooter>
            {!isCreateNewPassword && (
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            )}

            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              margin="auto"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
              width={isCreateNewPassword ? "100%" : undefined}
            >
              {isCreateNewPassword ? "Sign In" : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export { ChangePassword };
