import { Icon, useControllableState, useDisclosure } from "@chakra-ui/react";
import { config } from "config";
import { Scribe } from "icons";
import * as React from "react";
import {
  MedicalStaffInputProps,
  MedicalStaffModal,
  MedicalStaffModalBaseProps,
  MedicalStaffModalContent,
} from "../MedicalStaffInput";
import { ModalInputButton } from "../ModalInputButton";

function ScribeModal(props: MedicalStaffModalBaseProps) {
  const { isOpen, onClose, onChange, initialValue, ...rest } = props;

  return (
    <MedicalStaffModal isOpen={isOpen} onClose={onClose}>
      {isOpen && (
        <MedicalStaffModalContent
          onClose={onClose}
          title="Assign Scribe"
          initialValue={initialValue}
          onChange={onChange}
          {...rest}
        />
      )}
    </MedicalStaffModal>
  );
}

const ScribeInput = React.forwardRef<HTMLButtonElement, MedicalStaffInputProps>(
  (props, ref) => {
    const {
      defaultValue = null,
      onChange,
      value: valueProp,
      encounterId,
      encounterUserType,
      userType,
      onCloseDetailsModal,
      disabled = false,
    } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [value, setValue] = useControllableState({
      defaultValue,
      onChange,
      value: valueProp,
    });

    return (
      <>
        <ModalInputButton
          ref={ref}
          icon={<Icon as={Scribe} fontSize="1.275rem" color="gray.600" />}
          label="Scribe"
          value={value?.fullName || ""}
          onClick={onOpen}
          disabled={disabled}
        />
        <ScribeModal
          isOpen={isOpen}
          onClose={onClose}
          onChange={setValue}
          initialValue={value}
          encounterId={encounterId}
          encounterUserType={encounterUserType}
          userType={userType}
          onCloseDetailsModal={onCloseDetailsModal}
        />
      </>
    );
  }
);

if (config.isDev) {
  ScribeInput.displayName = "ScribeInput";
}

export { ScribeInput };
