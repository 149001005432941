import format from "date-fns/format";
import { useEffect, useState } from "react";

function useCurrentDateTime(dateTimeFormat: string, timeout = 1000) {
  const [timeStr, setTimeStr] = useState("");

  useEffect(() => {
    setTimeStr(format(new Date(), dateTimeFormat));
    const intervalId = window.setInterval(() => {
      setTimeStr(format(new Date(), dateTimeFormat));
    }, timeout);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [dateTimeFormat, timeout]);

  return timeStr;
}

export { useCurrentDateTime };
