const CONTAINER_PADDING = "0.5rem 0.6875rem 0.5rem 0.3125rem";
const CONTAINER_PADDING_2 = "15px 17px";
const ACTIONS_ROW_TEMPLATE_COLUMNS = "200px 1fr 1fr 30px";
const ACTIONS_ROW_TEMPLATE_COLUMNS_2 = "200px 1fr 30px";
const ACTIONS_ROW_TEMPLATE_COLUMNS_3 = "32px 250px 1fr 1fr";
const ACTIONS_ROW_TEMPLATE_COLUMNS_4 = "32px 250px 1fr";
const ACTIONS_COLUMNS_GAP = "17px";

export {
  CONTAINER_PADDING,
  CONTAINER_PADDING_2,
  ACTIONS_ROW_TEMPLATE_COLUMNS,
  ACTIONS_ROW_TEMPLATE_COLUMNS_2,
  ACTIONS_ROW_TEMPLATE_COLUMNS_3,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  ACTIONS_COLUMNS_GAP,
};
