import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C016x";

function T16HPI() {
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });

      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );

      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A001"
                  label="just PTA"
                />
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A002"
                  label="today"
                />
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A003"
                  label="yesterday"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A005"
                  label="minutes ago"
                />
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A006"
                  label="hours ago"
                />
                <ChartRowElement
                  type="option"
                  name="Q002.C014xSPROQ002A007"
                  label="days ago"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A001"
                  label="home"
                />
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A002"
                  label="school"
                />
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A003"
                  label="neighbor's"
                />
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A004"
                  label="park"
                />
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A005"
                  label="daycare"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A007"
                  label="exact location:"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="input"
                />
                <ChartRowElement
                  type="option"
                  name="Q003.C014xSPROQ003A009"
                  label="exact avtivity:"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q004.C014xSPROQ004A001"
                  label="blunt trauma"
                />
                <ChartRowElement
                  type="option"
                  name="Q004.C014xSPROQ004A002"
                  label="penetrating trauma"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q004.C014xSPROQ004A004"
                  label="PCP sent to ER"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A001"
                  label="mild"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A002"
                  label="moderate"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A003"
                  label="(1/10):"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A004"
                  label="1"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A005"
                  label="2"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A006"
                  label="3"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A007"
                  label="4"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A008"
                  label="5"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A009"
                  label="6"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A010"
                  label="7"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A011"
                  label="8"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A012"
                  label="9"
                />
                <ChartRowElement
                  type="option"
                  name="Q005.C014xSPROQ005A013"
                  label="10"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A001"
                  label="head"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A002"
                  label="face"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A003"
                  label="mouth"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A004"
                  label="neck"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A005"
                  label="chest"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A006"
                  label="abdomen"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A008"
                  label="L"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A009"
                  label="shldr"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A010"
                  label="arm"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A011"
                  label="elbow"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A012"
                  label="f-arm"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A013"
                  label="hand"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A014"
                  label="wrist"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A015"
                  label="hip"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A016"
                  label="thigh"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A017"
                  label="knee"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A018"
                  label="leg"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A019"
                  label="ankle"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A020"
                  label="foot"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A022"
                  label="R"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A023"
                  label="shldr"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A024"
                  label="arm"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A025"
                  label="elbow"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A026"
                  label="f-arm"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A027"
                  label="hand"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A028"
                  label="wrist"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A029"
                  label="hip"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A030"
                  label="thigh"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A031"
                  label="knee"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A032"
                  label="leg"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A033"
                  label="ankle"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A034"
                  label="foot"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A036"
                  label="back:"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A037"
                  label="upper"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A038"
                  label="mid"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A039"
                  label="lower"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A041"
                  label="radiating to"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A042"
                  label="L"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A043"
                  label="R"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A044"
                  label="thigh"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q006.C014xSPROQ006A045"
                  label="leg"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A046`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A001"
                  label="lethargic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A002"
                  label="fussy"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A003"
                  label="persistent crying"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A004"
                  label="nausea"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A005"
                  label="vomiting"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A006"
                  label="bruising"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A008"
                  label="lost conciousness:"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A009"
                  label="yes"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A010"
                  label="no"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A011"
                  label="unknown"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A012"
                  label="duration:"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  type="input"
                  width="50px"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A014"
                  label="seconds"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A015"
                  label="minutes"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A016"
                  label="hours"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A018"
                  label="remembers:"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A019"
                  label="event"
                  fontStyle="italic"
                />
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A020"
                  label="coming to hospital"
                  fontStyle="italic"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  name="Q007.C014xSPROQ007A022"
                  label="other:"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T16HPI };
