/* eslint-disable no-console */
import {
  Box,
  BoxProps,
  chakra,
  Heading,
  HStack,
  IconButton,
  useControllableState,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { config } from "config";
import { useFileUrl } from "hooks";
import { EmrPlus } from "@medstonetech/slate-icons";
import { DeleteOutline, Expand } from "icons";
import * as React from "react";
import { Loading } from "shared/loading";
import {
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerModal,
} from "shared/pdf-viewer";
import { extractApiErrorMessage, isImage } from "utils";
import { ImageModal } from "./ImageModal";

type DocumentPickerProps = {
  title: string;
  value?: Nullable<File | Blob>;
  defaultValue?: Nullable<File | Blob>;
  onChange?: (value: Nullable<File | Blob>) => void;
  isLoading?: boolean;
  error?: Error;
  isDisabled?: boolean;
  pdfPageWidth?: number;
} & Omit<BoxProps, "onChange" | "value" | "defaultValue">;

function isPdf(mimeType: string) {
  return mimeType === "application/pdf";
}

const DocumentPicker = React.forwardRef<HTMLDivElement, DocumentPickerProps>(
  (props, ref) => {
    const {
      defaultValue,
      onChange,
      value: valueProp,
      isLoading,
      error,
      title,
      isDisabled,
      pdfPageWidth,
      ...boxProps
    } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [value, setValue] = useControllableState({
      defaultValue,
      value: valueProp,
      onChange,
    });
    const fileUrl = useFileUrl(value);
    const mimeType = value?.type;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const showPdf = mimeType && isPdf(mimeType) && !isLoading;
    const showImage = mimeType && isImage(mimeType) && !isLoading;
    const showError = error && !isLoading;
    const showInvalidFile = mimeType && !isPdf(mimeType) && !isImage(mimeType);

    return (
      <>
        <chakra.input
          ref={inputRef}
          onChange={(e) => {
            const file = e.target.files?.item(0);

            if (file) {
              setValue(file);
            }
          }}
          type="file"
          accept="image/*, application/pdf"
          display="none"
          key={fileUrl}
          disabled={isDisabled}
        />
        <Box
          width="500px"
          height="690px"
          display="flex"
          flexDirection="column"
          bg="gray.50"
          padding="16px 20px 20px 20px"
          border="1px"
          borderColor="gray.450"
          borderRadius="10px"
          ref={ref}
          {...boxProps}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="16px"
          >
            <IconButton
              aria-label="expand"
              icon={<Expand />}
              variant="icon"
              color="blue"
              isDisabled={isLoading || !value}
              onClick={() => onOpen()}
            />
            <Heading color="gray.650" fontSize="1rem" fontWeight="500">
              {title}
            </Heading>
            <HStack spacing="2rem">
              <IconButton
                aria-label="upload file"
                icon={<Icon as={EmrPlus} />}
                variant="icon"
                color="blue"
                onClick={() => inputRef.current?.click()}
                isDisabled={isLoading || isDisabled}
              />
              <IconButton
                aria-label="delete file"
                icon={<DeleteOutline />}
                variant="icon"
                color="blue"
                onClick={() => setValue(null)}
                isDisabled={isLoading || !value || isDisabled}
              />
            </HStack>
          </Box>
          <Box
            flex={1}
            bg="white"
            border="1px"
            color="gray.450"
            overflow="auto"
          >
            {isLoading && <Loading />}
            {showError && extractApiErrorMessage(error)}
            {showPdf && (
              <PdfViewer value={fileUrl}>
                <PdfViewerActivePage pageWidth={pdfPageWidth} />
              </PdfViewer>
            )}
            {showImage && (
              <chakra.img w="100%" h="100%" objectFit="cover" src={fileUrl} />
            )}
            {showInvalidFile && (
              <Box
                w="100%"
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="black"
              >
                Invalid file format.
              </Box>
            )}
          </Box>
        </Box>
        {showPdf && (
          <PdfViewerModal
            isOpen={isOpen}
            onClose={onClose}
            title="Image"
            value={value}
          />
        )}
        {showImage && (
          <ImageModal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            value={value}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onChange={(newFile) => setValue(newFile)}
          />
        )}
      </>
    );
  }
);

if (config.isDev) {
  DocumentPicker.displayName = "DocumentPicker";
}

export type { DocumentPickerProps };
export { DocumentPicker };
