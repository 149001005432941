import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrAssessments } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ASSESSMENTS_GRID_COLUMNS } from "../../shared/assessments";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SASS";
const triageChartCode = "T001x";

function N01Assessments() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Assessments",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();

  const isReadOnly = !scope("nurse:assts").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Assessments {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Primary Assessments"
            >
              Primary Assessments
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T001xSASSQ001A017"
                  label="Date / Time:"
                />
                <ChartRowElement
                  type="date"
                  name="Q001.T001xSASSQ001A001"
                  size="lg"
                />
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Airway patent"
                    name="Q001.T001xSASSQ001A002"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="compromised"
                    name="Q001.T001xSASSQ001A003"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Breathing unlabored"
                    name="Q001.T001xSASSQ001A005"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="labored"
                    name="Q001.T001xSASSQ001A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="respiratory distress"
                    name="Q001.T001xSASSQ001A007"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Circulation nml"
                    name="Q001.T001xSASSQ001A009"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pale"
                    name="Q001.T001xSASSQ001A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="diaphoretic"
                    name="Q001.T001xSASSQ001A011"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A012" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="Neuro awake alert"
                    name="Q001.T001xSASSQ001A013"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lethargic"
                    name="Q001.T001xSASSQ001A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="obtunded"
                    name="Q001.T001xSASSQ001A015"
                  />
                  <ChartRowElement type="input" name="Q001.T001xSASSQ001A016" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x 4"
                    name="Q002.T001xSASSQ002A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.T001xSASSQ002A018"
                    label="disoriented to:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q002.T001xSASSQ002A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q002.T001xSASSQ002A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    name="Q002.T001xSASSQ002A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q002.T001xSASSQ002A005"
                  />
                  <ChartRowElement type="input" name="Q002.T001xSASSQ002A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="PERRL"
                    name="Q002.T001xSASSQ002A007"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRow>
                    <ChartRowElement
                      type="cross-option"
                      label="memory loss"
                      name="Q002.T001xSASSQ002A008"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q002.T001xSASSQ002A009"
                    />
                  </ChartRow>
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pupils unequal"
                    name="Q002.T001xSASSQ002A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q002.T001xSASSQ002A011"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T001xSASSQ002A012"
                    size="md"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q002.T001xSASSQ002A013"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q002.T001xSASSQ002A014"
                    size="md"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="weakness"
                    name="Q002.T001xSASSQ002A015"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sensory loss"
                    name="Q002.T001xSASSQ002A016"
                  />
                  <ChartRowElement type="input" name="Q002.T001xSASSQ002A017" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head / Face"
            >
              Head / Face
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q003.T001xSASSQ003A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="see diagram"
                    name="Q003.T001xSASSQ003A002"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q003.T001xSASSQ003A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q003.T001xSASSQ003A005"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q003.T001xSASSQ003A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q003.T001xSASSQ003A007"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="skin intact"
                    name="Q003.T001xSASSQ003A009"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q003.T001xSASSQ003A010"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no visual disturbance"
                    name="Q003.T001xSASSQ003A012"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="periorbital hematoma"
                    name="Q003.T001xSASSQ003A013"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A014" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no drainage"
                    name="Q003.T001xSASSQ003A015"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="subconjunctival hemorrhage"
                    name="Q003.T001xSASSQ003A016"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="raccoon eyes"
                    name="Q003.T001xSASSQ003A018"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Battle's sign"
                    name="Q003.T001xSASSQ003A019"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q003.T001xSASSQ003A021"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A022" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nasal injury"
                    name="Q003.T001xSASSQ003A023"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dental injury"
                    name="Q003.T001xSASSQ003A025"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malocclusion"
                    name="Q003.T001xSASSQ003A026"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A027" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="drainage"
                    name="Q003.T001xSASSQ003A028"
                  />
                  <ChartRowElement type="input" name="Q003.T001xSASSQ003A029" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Neck / Back"
            >
              Neck / Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no evidence of trauma"
                    name="Q004.T001xSASSQ004A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q004.T001xSASSQ004A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q004.T001xSASSQ004A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q004.T001xSASSQ004A004"
                  />
                  <ChartRowElement type="input" name="Q004.T001xSASSQ004A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q004.T001xSASSQ004A006"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q004.T001xSASSQ004A007"
                  />
                  <ChartRowElement type="input" name="Q004.T001xSASSQ004A008" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q004.T001xSASSQ004A009"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="limited ROM"
                    name="Q004.T001xSASSQ004A010"
                  />
                  <ChartRowElement type="input" name="Q004.T001xSASSQ004A011" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="warm"
                    name="Q005.T001xSASSQ005A001"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dry"
                    name="Q005.T001xSASSQ005A002"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="cool"
                    name="Q005.T001xSASSQ005A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="cyanotic"
                    name="Q005.T001xSASSQ005A004"
                  />
                  <ChartRowElement type="input" name="Q005.T001xSASSQ005A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact"
                    name="Q005.T001xSASSQ005A006"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcers"
                    name="Q005.T001xSASSQ005A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sacrum"
                    name="Q005.T001xSASSQ005A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="hip"
                    name="Q005.T001xSASSQ005A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="heel"
                    name="Q005.T001xSASSQ005A010"
                  />
                  <ChartRowElement type="input" name="Q005.T001xSASSQ005A011" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Functional / Nutritional Assessment"
            >
              Functional / Nutritional Assessment
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="independent ADL"
                    name="Q006.T001xSASSQ006A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="assisted"
                    name="Q006.T001xSASSQ006A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="total care"
                    name="Q006.T001xSASSQ006A003"
                  />
                  <ChartRowElement type="input" name="Q006.T001xSASSQ006A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appears well"
                    name="Q006.T001xSASSQ006A005"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="obese"
                    name="Q006.T001xSASSQ006A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malnourished"
                    name="Q006.T001xSASSQ006A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="dehydrated"
                    name="Q006.T001xSASSQ006A008"
                  />
                  <ChartRowElement type="input" name="Q006.T001xSASSQ006A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow templateColumns={ASSESSMENTS_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="hydrated"
                    name="Q006.T001xSASSQ006A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="nourished"
                    name="Q006.T001xSASSQ006A011"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight loss"
                    name="Q006.T001xSASSQ006A012"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent weight gain"
                    name="Q006.T001xSASSQ006A013"
                  />
                  <ChartRowElement type="input" name="Q006.T001xSASSQ006A014" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Additional Findings"
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q007.T001xSASSQ007A001" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N01Assessments };
