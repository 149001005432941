import { Box, HStack, StackProps, VStack } from "@chakra-ui/react";
import * as React from "react";
import { StaffAvatar } from "shared";

type MedicalStaffInfoProps = StackProps & {
  name: string;
  role: string;
  pictureUrl?: string | null;
};

function MedicalStaffInfo(props: MedicalStaffInfoProps) {
  const { name, role, pictureUrl, ...restProps } = props;

  return (
    <HStack spacing="15px" {...restProps}>
      <StaffAvatar
        fontSize="1rem"
        size="xs"
        userName={name || ""}
        profileUrl={pictureUrl || ""}
        marginRight="1.25rem"
      />
      <VStack spacing="2px" alignItems="flex-start">
        <Box fontSize="1.0625rem" lineHeight="1.25rem">
          {name}
        </Box>
        <Box color="gray.700" fontSize="0.9375rem" lineHeight="1.125rem">
          {role}
        </Box>
      </VStack>
    </HStack>
  );
}

export type { MedicalStaffInfoProps };
export { MedicalStaffInfo };
