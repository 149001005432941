import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C021x";

function T21ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Laceration:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abrasion:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Puncture Wound:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bite:"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dog"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="cat"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                  width="300px"
                />
                <ChartRowElement
                  label="superficial"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="open wound"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fingertip injury:"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="subungual hematoma"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail injury"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail bed injury"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail avulsion"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tip amputation"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Toe tip injury:"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4th"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5th"
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="subungual hematoma"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail injury"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail bed injury"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nail avulsion"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tip amputation"
                  fontStyle="italic"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contusion:"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Strain"
            >
              Strain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Sprain"
            >
              Sprain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Ortho"
            >
              Ortho
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fracture:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                />
                <ChartRowElement
                  label="closed"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="open"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dislocation"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="ID"
            >
              ID
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Lymphangitis, acute:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                />
                <ChartRowElement
                  label="L:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cellulitis:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                />
                <ChartRowElement
                  label="L:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q006.${chartCode}${sectionId}Q006A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A048`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Abscess-cutaneous:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A049`}
                />
                <ChartRowElement
                  label="L:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A050`}
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="arm"
                  name={`Q006.${chartCode}${sectionId}Q006A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q006.${chartCode}${sectionId}Q006A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  name={`Q006.${chartCode}${sectionId}Q006A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A056`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A057`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A058`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A062`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q006.${chartCode}${sectionId}Q006A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lower leg"
                  name={`Q006.${chartCode}${sectionId}Q006A064`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="foot"
                  name={`Q006.${chartCode}${sectionId}Q006A065`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  textDecoration="underline"
                  name={`Q006.${chartCode}${sectionId}Q006A066`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  name={`Q006.${chartCode}${sectionId}Q006A067`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q006.${chartCode}${sectionId}Q006A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q006.${chartCode}${sectionId}Q006A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q006.${chartCode}${sectionId}Q006A070`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  name={`Q006.${chartCode}${sectionId}Q006A071`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A072`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A073`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Tendon"
            >
              Tendon
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Lacerated tendon:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                />
                <ChartRowElement
                  label="L"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                />
                <ChartRowElement
                  label="R"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Amputation:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                />
                <ChartRowElement
                  label="partial"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hand"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="digit"
                  textDecoration="underline"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="midfoot"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="toe:"
                  textDecoration="underline"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="1"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="5"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="amputation:"
                  name={`Q008.${chartCode}${sectionId}Q008A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="partial"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complete"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A026`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rabies:"
                  name={`Q008.${chartCode}${sectionId}Q008A027`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="exposure"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vaccination"
                  fontStyle="italic"
                  name={`Q008.${chartCode}${sectionId}Q008A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia:"
                  name={`Q008.${chartCode}${sectionId}Q008A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q008.${chartCode}${sectionId}Q008A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A035`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T21ClinicalImpression };
