import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrKey, EmrProfileCircle, EmrXmark } from "@medstonetech/slate-icons";
import { config } from "config";
import { useToast } from "hooks";
import { formMessages } from "messages";
import { ChangePassword } from "modules";
import * as React from "react";
import { Card, MenuOption, UserAvatar } from "shared";
import { constants } from "system-constants";
import { useUpdateProfilePicture } from "api";
import { extractApiErrorMessage } from "utils";
import { SignatureManager } from "modules/onboarding/components/signature";

type UserModalProps = {
  isOpen: boolean;
  onClose(): void;
};

const detailTextStyleProps = {
  fontSize: "1.0625rem",
  fontWeight: "600",
  color: "gray.700",
};

function SidebarUserModal(props: UserModalProps) {
  const { isOpen, onClose } = props;
  const { logout, user } = useAuth0();
  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure();
  const { mutateAsync: updateProfilePicture } = useUpdateProfilePicture();
  const toast = useToast();

  const fileInputRef = React.createRef<HTMLInputElement>();

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.length) return;

    try {
      const image = event.target.files[0];

      if (image.size > constants.MAX_FILE_SIZE) {
        toast({
          description: `Files must be smaller than ${constants.MAX_FILE_SIZE_IN_MB}MB`,
        });
        return;
      }

      const body = new FormData();
      body.append("file", image);
      await updateProfilePicture(body);

      // The timestamp is to avoid the browser cache since when the image
      // is updated it returns the same url, but the content has changed
      toast({ description: formMessages.updateSuccess("Profile picture") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <chakra.input
        ref={fileInputRef}
        type="file"
        onChange={onFileUpload}
        display="none"
        accept="image/*"
      />
      <ModalOverlay />
      <ModalContent bg="gray.100" maxWidth="375px">
        <ModalBody>
          <Card
            position="absolute"
            right="1.25rem"
            top="1.25rem"
            boxShadow="none"
            border="none"
            backgroundColor="gray.300"
            _hover={{
              background: "gray.500",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            <chakra.button
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="30px"
              h="30px"
              bg="gray.200"
              borderRadius="50%"
            >
              <Icon
                as={EmrXmark}
                fontSize="0.625rem"
                color="rgba(60, 60, 67, 0.6)"
              />
            </chakra.button>
          </Card>
          <Box
            display="flex"
            flexDirection="column"
            marginTop="2.5rem"
            marginBottom="1rem"
            textAlign="center"
            alignItems="center"
          >
            <UserAvatar
              size="md"
              marginBottom="20px"
              fontSize="3.5rem"
              picture={user?.picture && user.picture}
            />
            <chakra.h3 fontSize="1.5rem" fontWeight="600" textAlign="center">
              {user?.name}
            </chakra.h3>
            <Box {...detailTextStyleProps}>{user?.email}</Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            marginTop="1.75rem"
            marginBottom="1.75rem"
            textAlign="center"
            alignItems="center"
          >
            <Box {...detailTextStyleProps}>
              <SignatureManager />
            </Box>
          </Box>

          <Card boxShadow="none" border="none" marginBottom="1rem">
            <MenuOption label="Task Manager" counter={0} disabled />
          </Card>

          <Card boxShadow="none" border="none" marginBottom="1rem">
            <MenuOption
              label="Add New Profile Picture"
              icon={<Icon as={EmrProfileCircle} />}
              divider
              onClick={() => fileInputRef.current?.click()}
            />
            <MenuOption
              label="Change Password"
              icon={<Icon as={EmrKey} />}
              onClick={onChangePasswordOpen}
            />
          </Card>

          <Card boxShadow="none" border="none" marginBottom="1rem">
            <MenuOption
              label="Sign Out"
              onClick={() => logout({ returnTo: config.auth0LogoutUrl })}
            />
          </Card>
        </ModalBody>
      </ModalContent>
      <ChangePassword
        key="change-password-user-modal"
        isOpen={isChangePasswordOpen}
        onClose={onChangePasswordClose}
      />
    </Modal>
  );
}

export { SidebarUserModal };
