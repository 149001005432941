import { AnyObject } from "types/utils";

const parts = ["input", "btncontainer", "action"];

function baseStyle(props: AnyObject) {
  const { hasSelectedItem } = props;

  return {
    input: {
      paddingRight: hasSelectedItem ? "64px" : "40px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    btncontainer: {
      position: "absolute",
      right: "var(--chakra-space-3)",
      height: "100%",
      top: "0",
      alignItems: "center",
      display: "flex",
    },
    action: {
      type: "button",
      backgroundColor: "transparent",
      minWidth: "0px",
      padding: "0px",
      height: "fit-content",
      outline: "none",
      transition: "all 200ms ease-in-out",
      borderRadius: "50%",
      _hover: { backgroundColor: "transparent" },
      _active: { backgroundColor: "transparent" },
      _focus: {
        boxShadow: "var(--chakra-shadows-outline)",
      },
    },
  };
}

const autocompleteTheme = {
  parts,
  baseStyle,
};

export { autocompleteTheme };
