import { Procedures } from "modules/provider/shared/procedure-charts";

const chartCode = "C003x";
const sectionCode = "SPRO2";

function T03Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T03Procedures };
