import * as React from "react";
import { axiosOrderInstance } from "config";
import {
  OrderContrastTimerStatus,
  OrderFormElementCategory,
  OrderFormElementType,
  OrderGroup,
  OrderStatus,
  OrderType,
} from "enums";
import { Contrast } from "modules/orders/types";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";
import notificationBell from "assets/bell.mp3";

type UseOrdersProps = PaginationPayload & {
  orderStatus?: OrderStatus;
  search?: string;
  orderGroup?: OrderGroup;
};

type ContrastTimer = {
  contrastTimer: string;
  constrastTimerStatus: OrderContrastTimerStatus;
};

type OrdersListItemResponse = {
  id: string;
  pictureUrl: string;
  description: string;
  type: OrderType;
  status: OrderStatus;
  patientFullName: string;
  providerFullName: string;
  timer: string;
  encounterId: string;
  orderedBy: string;
  timerId: string;
  gender?: string;
  age?: number;
  constrastTimer?: ContrastTimer;
  contrast?: Contrast;
  elementType: OrderFormElementType;
  elementCategory: OrderFormElementCategory;
};

type UseOrdersResponse = PaginationResponse<OrdersListItemResponse>;

function useOrders(props: UseOrdersProps) {
  const { size = 16, page = 1, search, orderStatus, orderGroup } = props;

  const query = useInfiniteQuery(
    ["orders", { search, orderStatus, orderGroup }],
    ({ pageParam = page }) =>
      axiosOrderInstance.get<UseOrdersResponse>("v1", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
          orderStatus,
          orderGroup,
        },
      }),
    {
      refetchInterval: 15000,
      refetchOnReconnect: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
    }
  );

  const previousTotalRef = React.useRef<number>();
  const audioRef = React.useRef(new Audio(notificationBell));

  if (orderStatus === "Ordered") {
    const currentTotal = query.data?.pages[0]?.data.totalRecords;
    if (
      currentTotal &&
      previousTotalRef.current &&
      currentTotal !== previousTotalRef.current
    ) {
      audioRef.current.play();
    }

    previousTotalRef.current = currentTotal;
  }

  return query;
}

export type { UseOrdersResponse, OrdersListItemResponse };
export { useOrders };
