import * as React from "react";
import { HStack, Box, Tooltip, BoxProps } from "@chakra-ui/react";
import { SearchOrdersListItem } from "modules/orders/api";
import { ORDER_STATUS_COLOR } from "modules/orders/constants";
import { Card } from "shared";
import { format, isValid } from "date-fns";
import { PatientInfo } from "modules/orders/components/PatientInfo";
import { MedicalStaffInfo } from "modules/orders/components/MedicalStaffInfo";
import { OrderGroup } from "enums";
import { getValueByOrderGroup } from "modules/orders/helpers";

type OrdersSearchItemCardProps = {
  orderItem: SearchOrdersListItem;
  orderGroup: OrderGroup;
} & BoxProps;

function OrdersSearchItemCard(props: OrdersSearchItemCardProps) {
  const { orderItem, orderGroup, ...boxProps } = props;

  return (
    <Card
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      {...boxProps}
    >
      <Box overflowX="auto" overflowY="hidden">
        <HStack
          padding="13px 0"
          alignContent="start"
          spacing="30px"
          fontWeight="400"
        >
          <Tooltip label={orderItem?.orderId} bgColor="black">
            <Box
              width="100px"
              minWidth="100px"
              paddingLeft="30px"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {orderItem?.orderId}
            </Box>
          </Tooltip>
          <Box width="120px" minWidth="120px">
            <Box
              fontSize="1.0625rem"
              color="white"
              borderRadius="20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="6px 17px"
              bg={
                orderItem.status
                  ? ORDER_STATUS_COLOR[orderItem.status]
                  : "gray.450"
              }
              fontWeight="500"
              minWidth="44px"
            >
              {orderItem.status}
            </Box>
          </Box>
          <Box width="180px" minWidth="180px">
            {isValid(new Date(orderItem?.orderDate)) &&
              `${format(
                new Date(orderItem?.orderDate),
                "MMM dd, yyyy h:mm a"
              )}`}
          </Box>
          <PatientInfo
            width="320px"
            minWidth="320px"
            age={`${orderItem.patient?.age || ""}`}
            gender={orderItem.patient?.gender || ""}
            mrn={orderItem.patient?.mrn || ""}
            name={orderItem.patient?.fullName || ""}
            ssn={orderItem.patient?.ssn || ""}
            encounterId={orderItem.encounterId}
            overflow="hidden"
          />
          <Box minWidth="200px" flex="1" fontSize="1.125rem" fontWeight="500">
            {orderItem.description}
          </Box>
          {
            getValueByOrderGroup<React.ReactNode>(
              null,
              <Tooltip label={orderItem.contrast} bgColor="black">
                <Box width="120px" minWidth="120px" fontWeight="400">
                  {orderItem.contrast}
                </Box>
              </Tooltip>
            )[orderGroup]
          }
          <MedicalStaffInfo
            width="250px"
            minWidth="250px"
            name={orderItem.orderedBy?.fullName || ""}
            role={orderItem.orderedBy?.roleName || ""}
            overflow="hidden"
            paddingRight="30px"
            pictureUrl={orderItem.orderedBy?.pictureUrl}
          />
        </HStack>
      </Box>
    </Card>
  );
}

export type { OrdersSearchItemCardProps };
export { OrdersSearchItemCard };
