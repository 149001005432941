import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type VitalsTimerResponse = {
  lastCheck: string;
  timer: string;
  vitalsTimer: {
    hours: string;
    minutes: string;
  };
  millisecondRemaining: number;
  status: "Started" | "NotStarted" | "Paused";
};

function useVitalsTimer(encounterId: string) {
  return useAxiosQuery(["vitalsTimer", { encounterId }], () =>
    axiosChartInstance.get<VitalsTimerResponse>(`v1/Vitals/time/${encounterId}`)
  );
}

export type { VitalsTimerResponse };
export { useVitalsTimer };
