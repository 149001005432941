import { isValidHeightStr } from "modules/vitals/utils";
import * as React from "react";
import {
  VitalsInputModal,
  VitalsInputModalValueBox,
  VitalsInputModalKeypad,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { Close } from "icons";
import { Button } from "@chakra-ui/react";

function HeightVitalsInputModal(
  props: Omit<BaseVitalsInputModalProps, "title">
) {
  const { onSave, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");
  const [isFtSet, setIsFtSet] = React.useState(false);
  const [isInchSet, setIsInchSet] = React.useState(false);
  const [error, setError] = React.useState("");
  const lastChar = value[value.length - 1];
  const onClickCustomKey = () => {
    // Do nothing if both units are already set
    if (isFtSet && isInchSet) {
      return;
    }

    // Do nothing if last item is already a special character
    if (lastChar === `'` || lastChar === `"`) {
      return;
    }

    // Do nothing if value is empty
    if (!lastChar) {
      return;
    }

    if (!isFtSet) {
      setValue((prev) => `${prev}'`);
      setIsFtSet(true);
    } else {
      setValue((prev) => `${prev}"`);
      setIsInchSet(true);
    }
  };

  const internalOnSave = () => {
    if (!isValidHeightStr(value)) {
      setError("Invalid height value");
      return;
    }
    onSave(value);
    setError("");
    onClose();
  };

  const onDelete = () => {
    if (lastChar === `'`) {
      setIsFtSet(false);
    }
    if (lastChar === `"`) {
      setIsInchSet(false);
    }
    setValue((prev) => prev.slice(0, prev.length - 1));
  };

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <VitalsInputModal title="Height" {...vitalsInputModalProps}>
      <VitalsInputModalValueBox error={error}>{value}</VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        customKeyLabel="ft/in"
        onClickCustomKey={onClickCustomKey}
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={internalOnSave}
        onDelete={onDelete}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export { HeightVitalsInputModal };
