import { useToast } from "hooks";
import { formMessages } from "messages";
import { useUpdateVitalsTimer } from "modules/vitals/api";
import * as React from "react";
import { useParams } from "react-router-dom";
import { extractApiErrorMessage, formatTimer } from "utils";
import {
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalValueBox,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { Close } from "icons";
import { Button } from "@chakra-ui/react";

type TimerVitalsInputModalProps = Omit<
  BaseVitalsInputModalProps,
  "title" | "onSave"
>;

function onClickItem(curr: string, newElem: string) {
  const [hours, minutes] = curr.split(":");
  const merged = `${hours}${minutes}`;
  const newStr = `${merged.slice(1)}${newElem}`;

  return `${newStr.substring(0, 1)}:${newStr.substring(1)}`;
}

function onDelete(curr: string) {
  const [hours, minutes] = curr.split(":");
  const merged = `${hours}${minutes}`;
  const newStr = `0${merged.slice(0, merged.length - 1)}`;

  return `${newStr.substring(0, 1)}:${newStr.substring(1)}`;
}

function TimerVitalsInputModal(props: TimerVitalsInputModalProps) {
  const { onValidate, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const toast = useToast();
  const { mutateAsync, isLoading } = useUpdateVitalsTimer(encounterId);
  const [value, setValue] = React.useState(
    !initialValue ? "0:00" : formatTimer(initialValue)
  );
  const [error, setError] = React.useState("");
  const updateTimer = async () => {
    if (onValidate) {
      const validate = onValidate(value);

      if (typeof validate === "string") {
        setError(validate);
        return;
      }
    }

    try {
      const [h, m] = value.split(":");
      const hours = Number(h);
      const minutes = Number(m);

      if (minutes >= 60) {
        const newMinutes = minutes - 60;
        const newMinutesStr = newMinutes > 9 ? newMinutes : `0${newMinutes}`;

        if (hours + 1 > 9) {
          await mutateAsync({ time: "9:59" });
        } else {
          await mutateAsync({ time: `${hours + 1}:${newMinutesStr}` });
        }
      } else {
        await mutateAsync({ time: value });
      }

      toast({ description: formMessages.updateSuccess("Timer") });
      onClose();
    } catch (apiError) {
      toast({ description: extractApiErrorMessage(apiError) });
    }
  };

  return (
    <VitalsInputModal title="Timer" {...vitalsInputModalProps}>
      <VitalsInputModalValueBox error={error}>{value}</VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        onClickKey={(x) => {
          setValue((prev) => onClickItem(prev, x));
        }}
        onSave={updateTimer}
        onDelete={() => setValue(onDelete)}
        isLoading={isLoading}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export type { TimerVitalsInputModalProps };
export { TimerVitalsInputModal };
