import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useMergeRefs } from "@chakra-ui/react";
import { CrossChartOption, CrossChartOptionProps } from "shared";
import { useDivideControlProps } from "./hooks";

type FormCrossChartOptionProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & CrossChartOptionProps;

function FormCrossChartOptionInner<TFieldValues extends FieldValues>(
  props: FormCrossChartOptionProps<TFieldValues>,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  const { onChange: onChangeProp, ...restProps } = props;
  const [CrosschartOptionProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormCrossChartOptionProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, onChange, ...restField },
  } = useController(controllerProps);

  const CrosschartOptionRef = useMergeRefs(ref, fieldRef);

  const handleChange: CrossChartOptionProps["onChange"] = (nextValue) => {
    onChangeProp?.(nextValue);
    onChange(nextValue);
  };

  return (
    <CrossChartOption
      ref={CrosschartOptionRef}
      onChange={handleChange}
      {...CrosschartOptionProps}
      {...restField}
    />
  );
}

const FormCrossChartOption = React.forwardRef(FormCrossChartOptionInner) as <
  TFieldValues extends FieldValues
>(
  props: FormCrossChartOptionProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof FormCrossChartOptionInner>;

export { FormCrossChartOption };
export type { FormCrossChartOptionProps };
