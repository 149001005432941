import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DownArrowFilled = createIcon({
  path: (
    <path
      d="M10 0.760528C10 0.295412 9.64174 0.00628536 9.09491 0.00628536L0.898806 0C0.35198 0 0 0.289126 0 0.754243C0 1.00566 0.113136 1.21307 0.238843 1.47706L4.1609 9.55374C4.42489 10.088 4.66373 10.2577 4.99686 10.2577C5.32998 10.2577 5.57511 10.088 5.83909 9.55374L9.75487 1.47706C9.88686 1.21936 10 1.01194 10 0.760528Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 10 11",
});

export { DownArrowFilled };
