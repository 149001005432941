import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const PillPlus = createIcon({
  path: (
    <path
      d="M2.77504 20H8.76026C10.4269 20 11.5353 18.8834 11.5353 17.1839V7.92282C11.5353 6.22332 10.4269 5.10673 8.76026 5.10673H8.56322V4.04762H8.62069C9.57307 4.04762 10.1396 3.4647 10.1396 2.49589V1.55172C10.1396 0.582923 9.57307 0 8.62069 0H2.91461C1.96223 0 1.39573 0.582923 1.39573 1.55172V2.49589C1.39573 3.4647 1.96223 4.04762 2.91461 4.04762H2.97209V5.10673H2.77504C1.10837 5.10673 0 6.22332 0 7.92282V17.1839C0 18.8834 1.10837 20 2.77504 20ZM2.61084 13.1034V12.0033C2.61084 11.6092 2.87356 11.3711 3.24302 11.3711H4.57307V10.0411C4.57307 9.66338 4.81938 9.40066 5.21346 9.40066H6.31363C6.70772 9.40066 6.95402 9.66338 6.95402 10.0411V11.3711H8.28407C8.65353 11.3711 8.92447 11.6092 8.92447 12.0033V13.1034C8.92447 13.4975 8.65353 13.7438 8.28407 13.7438H6.95402V15.0657C6.95402 15.4433 6.70772 15.7061 6.31363 15.7061H5.21346C4.81938 15.7061 4.57307 15.4433 4.57307 15.0657V13.7438H3.24302C2.87356 13.7438 2.61084 13.4975 2.61084 13.1034Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 12 20",
});

export { PillPlus };
