import * as React from "react";
import { format } from "date-fns";
import { Card, StaffAvatar, WarningDialog } from "shared";
import {
  HStack,
  Box,
  chakra,
  Flex,
  useDisclosure,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { STPVipNote as STPVipNoteType } from "modules/stp-vip/types";
import { useUserRoles } from "contexts/UserRoles";
import { useToast } from "hooks";
import { useDeleteStpVipNote } from "modules/reception/api";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { EmrTrashcan } from "@medstonetech/slate-icons";

type STPVipProps = {
  note: STPVipNoteType;
  encounterId: string;
  type: "Stp" | "Vip" | "Other";
};

type CommonNoteProps = {
  id: string;
  encounterId: string;
  type: string;
  note: string;
};

type STPVipHeaderProps = {
  createdByName: string;
  createdAt: Date | string;
};

function STPVipHeader(props: STPVipHeaderProps) {
  const { createdByName, createdAt } = props;

  return (
    <HStack
      spacing="5px"
      alignItems="center"
      color="gray.750"
      fontSize="0.75rem"
      marginLeft="10px"
    >
      <chakra.span>{createdByName}</chakra.span>
      <chakra.span>&#183;</chakra.span>
      <chakra.span>
        {createdAt && format(new Date(createdAt), "hh:mm a L/d/yyyy")}
      </chakra.span>
    </HStack>
  );
}

function CommonNote(props: CommonNoteProps) {
  const { id, note, encounterId, type } = props;
  const { roles } = useUserRoles();
  const toast = useToast();

  const { mutateAsync: deleteEncounterNote, isLoading: isDeleteLoading } =
    useDeleteStpVipNote(id, encounterId, type);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onDelete = async () => {
    try {
      await deleteEncounterNote(null);
      toast({ description: formMessages.deleteSuccess("Note") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    } finally {
      onClose();
    }
  };

  return (
    <Card
      padding="10px"
      borderRadius="0.625rem"
      bg="gray.50"
      position="relative"
      boxShadow="none"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {note}
        <Box>
          <IconButton
            variant="ghost"
            aria-label="delete"
            onClick={onOpen}
            width="24px"
            height="24px"
            minWidth="unset"
            color="red"
            isDisabled={!roles.includes("Administrator")}
            isLoading={isDeleteLoading}
          >
            <Icon as={EmrTrashcan} />
          </IconButton>
          <WarningDialog
            isOpen={isOpen}
            mainText="Removing this note cannot be undone!"
            onAction={onDelete}
            onCancel={onClose}
            onClose={onClose}
            title="Warning!"
            actionLabel="Delete"
            isActionLoading={isDeleteLoading}
          />
        </Box>
      </Flex>
    </Card>
  );
}

function STPVipNote(props: STPVipProps) {
  const { note, encounterId, type } = props;
  const { createdAt, user } = note;

  return (
    <>
      <HStack alignItems="flex-end" width="100%">
        <StaffAvatar
          width="30px"
          height="30px"
          bottom="10px"
          size="xs"
          position="relative"
          userName={user?.fullName || ""}
          profileUrl={user?.pictureUrl || ""}
        />
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          position="relative"
        >
          <STPVipHeader
            createdByName={user?.fullName || ""}
            createdAt={createdAt}
          />

          <CommonNote
            note={note.note || ""}
            id={note.id}
            type={type}
            encounterId={encounterId}
          />
        </Box>
      </HStack>
    </>
  );
}

export type { STPVipProps };
export { STPVipNote };
