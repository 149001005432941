function onValidatePainLevel(value: string) {
  const x = Number(value);
  if (x < 1 || x > 10) {
    return "Pain must between 1 and 10";
  }
  return true;
}

function onValidateAcuityLevel(value: string) {
  const x = Number(value);
  if (x < 1 || x > 5) {
    return "Acuity must between 1 and 5";
  }
  return true;
}

export { onValidatePainLevel, onValidateAcuityLevel };
