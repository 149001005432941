import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  mapFormToPayload,
  mapPayloadToFormObject,
} from "modules/charts-shared";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";
import { useMdm, useUpdateMdm } from "../../api";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrAssessments } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SMDM";

function T35MDM(props: { chartCode: string }) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const { trackChartInteractions, isLoading: isTrackingInteractions } =
    useChartTrackingContext();

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:mdm").isEditable;

  const { chartCode } = props;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useMdm(encounterId);
  const { mutateAsync: updateMdm, isLoading: updateIsLoading } =
    useUpdateMdm(encounterId);
  const toast = useToast();
  const formObject = getValues();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            sectionId,
            chartType: "application",
          }
        );

        await updateMdm(payload);
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("MDM") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [trackChartInteractions, data?.data, encounterId, updateMdm, reset, toast]
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateMdm(payload);
      toast({ description: formMessages.updateSuccess("MDM") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingInteractions}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <SharedChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading || isTrackingInteractions}
        >
          MDM {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionId}
              subsectionCode="Q001"
              subsectionLabel="Testing"
            >
              Testing based on Chief Complaint/HPI/ROS
            </SharedChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Flu"
                    name={`Q001.${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Strep"
                    name={`Q001.${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RSV"
                    name={`Q001.${sectionId}Q001A003`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Covid"
                    name={`Q001.${sectionId}Q001A004`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mono"
                    name={`Q001.${sectionId}Q001A005`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Respiratory Infection Source:"
                    name={`Q001.${sectionId}Q001A006`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A007`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A008`}
                    label="Troponin BNP to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Myocardial Infarction"
                    name={`Q001.${sectionId}Q001A009`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Ischemia"
                    name={`Q001.${sectionId}Q001A010`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute CHF Exacerbation"
                    name={`Q001.${sectionId}Q001A011`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A012`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Complete Blood Count to evaluate for:"
                    name={`Q001.${sectionId}Q001A013`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A014`}
                    label="Infection"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sepsis"
                    name={`Q001.${sectionId}Q001A015`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Anemia"
                    name={`Q001.${sectionId}Q001A016`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bleeding"
                    name={`Q001.${sectionId}Q001A017`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A018`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Chemistries to evaluate for:"
                    name={`Q001.${sectionId}Q001A019`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A020`}
                    label="Acute Renal Failure"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dehydration"
                    name={`Q001.${sectionId}Q001A021`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hyperkalemia/Hypokalemia"
                    name={`Q001.${sectionId}Q001A022`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Rhabdomyolysis"
                    name={`Q001.${sectionId}Q001A023`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="DKA"
                    name={`Q001.${sectionId}Q001A024`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hyperglycemia"
                    name={`Q001.${sectionId}Q001A025`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A026`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Liver Function Test (LFTs) to evaluate for:"
                    name={`Q001.${sectionId}Q001A027`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A028`}
                    label="Liver Function"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hepatitis"
                    name={`Q001.${sectionId}Q001A029`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cholecystitis"
                    name={`Q001.${sectionId}Q001A030`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A031`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Lipase/Amylase to evaluate for:"
                    name={`Q001.${sectionId}Q001A032`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A033`}
                    label="Pancreatitis"
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A034`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Urinalysis (UA) to evaluate for:"
                    name={`Q001.${sectionId}Q001A035`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A036`}
                    label="Dehydration"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Infection (UTI)"
                    name={`Q001.${sectionId}Q001A037`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Proteinuria"
                    name={`Q001.${sectionId}Q001A038`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hematuria"
                    name={`Q001.${sectionId}Q001A039`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A040`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Urine/Serum"
                    name={`Q001.${sectionId}Q001A041`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pregnancy Test"
                    name={`Q001.${sectionId}Q001A042`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A043`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pregnancy"
                    name={`Q001.${sectionId}Q001A214`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Ectopic Pregnancy"
                    name={`Q001.${sectionId}Q001A044`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A045`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="ESR"
                    name={`Q001.${sectionId}Q001A046`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CRP"
                    name={`Q001.${sectionId}Q001A047`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A048`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Sepsis"
                    name={`Q001.${sectionId}Q001A049`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Shock "
                    name={`Q001.${sectionId}Q001A050`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acidemia "
                    name={`Q001.${sectionId}Q001A051`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A052`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Cultures:"
                    name={`Q001.${sectionId}Q001A053`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Blood"
                    name={`Q001.${sectionId}Q001A054`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A055`}
                    label="Throat"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Urine"
                    name={`Q001.${sectionId}Q001A056`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Wound"
                    name={`Q001.${sectionId}Q001A057`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A058`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sepsis"
                    name={`Q001.${sectionId}Q001A059`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bacteremia"
                    name={`Q001.${sectionId}Q001A060`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sensitivities/Antibiotic resistance"
                    name={`Q001.${sectionId}Q001A061`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A062`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="ProCal"
                    name={`Q001.${sectionId}Q001A063`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Lactate"
                    name={`Q001.${sectionId}Q001A064`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A065`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Sepsis/Shock"
                    name={`Q001.${sectionId}Q001A066`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acidemia"
                    name={`Q001.${sectionId}Q001A067`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A068`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Fibrinogen"
                    name={`Q001.${sectionId}Q001A069`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="D-Dimer"
                    name={`Q001.${sectionId}Q001A070`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A071`}
                    label="PT/INR"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A072`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Pulmonary Embolism"
                    name={`Q001.${sectionId}Q001A073`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Coagulopathy"
                    name={`Q001.${sectionId}Q001A074`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Disseminated Intravascular Coagulation"
                    name={`Q001.${sectionId}Q001A075`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A076`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="EKG(s) to evaluate for:"
                    name={`Q001.${sectionId}Q001A077`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A078`}
                    label="Ischemia"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dysrhythmias"
                    name={`Q001.${sectionId}Q001A079`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Intervals"
                    name={`Q001.${sectionId}Q001A080`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dynamic Changes"
                    name={`Q001.${sectionId}Q001A081`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Medication Safety"
                    name={`Q001.${sectionId}Q001A082`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute PE"
                    name={`Q001.${sectionId}Q001A083`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Electrolyte Abnormalities"
                    name={`Q001.${sectionId}Q001A084`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A085`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${sectionId}Q001A086`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A087`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A088`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A089`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${sectionId}Q001A090`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A091`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A092`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A093`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${sectionId}Q001A094`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A095`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A096`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A097`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${sectionId}Q001A098`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A099`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A100`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A101`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CXR to evaluate for:"
                    name={`Q001.${sectionId}Q001A102`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PNA"
                    name={`Q001.${sectionId}Q001A103`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PTX"
                    name={`Q001.${sectionId}Q001A104`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CM"
                    name={`Q001.${sectionId}Q001A105`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Effusion"
                    name={`Q001.${sectionId}Q001A106`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bronchitis"
                    name={`Q001.${sectionId}Q001A107`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fractures"
                    name={`Q001.${sectionId}Q001A108`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="ARDS"
                    name={`Q001.${sectionId}Q001A109`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Asthma Exacerbation"
                    name={`Q001.${sectionId}Q001A110`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A111`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="X-Ray"
                    name={`Q001.${sectionId}Q001A112`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A113`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A114`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${sectionId}Q001A115`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dislocation"
                    name={`Q001.${sectionId}Q001A116`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Foreign Body"
                    name={`Q001.${sectionId}Q001A117`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Complicated Fractures (Open/Compound)"
                    name={`Q001.${sectionId}Q001A118`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A119`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT Head to evaluate for:"
                    name={`Q001.${sectionId}Q001A120`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Stroke (CVA)"
                    name={`Q001.${sectionId}Q001A121`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A122`}
                    label="Traumatic Brain Injury"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Meningitis/Encephalitis"
                    name={`Q001.${sectionId}Q001A123`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${sectionId}Q001A124`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bleeding"
                    name={`Q001.${sectionId}Q001A125`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A126`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${sectionId}Q001A127`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A128`}
                    label="C"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="T"
                    name={`Q001.${sectionId}Q001A129`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name={`Q001.${sectionId}Q001A130`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Spine"
                    name={`Q001.${sectionId}Q001A131`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A132`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${sectionId}Q001A133`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Spinal Stenosis"
                    name={`Q001.${sectionId}Q001A134`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Herniated Disc"
                    name={`Q001.${sectionId}Q001A135`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${sectionId}Q001A136`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A137`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${sectionId}Q001A138`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Chest"
                    name={`Q001.${sectionId}Q001A139`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A140`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PE"
                    name={`Q001.${sectionId}Q001A141`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="COVID PNA"
                    name={`Q001.${sectionId}Q001A142`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A143`}
                    label="Effusion"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Aortic Pathology"
                    name={`Q001.${sectionId}Q001A144`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A145`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT Abdomen/Pelvis"
                    name={`Q001.${sectionId}Q001A146`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A147`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A148`}
                    label="Appendicitis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${sectionId}Q001A149`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Perforation"
                    name={`Q001.${sectionId}Q001A150`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Obstruction"
                    name={`Q001.${sectionId}Q001A151`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Kidney Stone"
                    name={`Q001.${sectionId}Q001A152`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Gallstones"
                    name={`Q001.${sectionId}Q001A153`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Diverticulitis"
                    name={`Q001.${sectionId}Q001A154`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="GI Bleeding"
                    name={`Q001.${sectionId}Q001A155`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Abdomen"
                    name={`Q001.${sectionId}Q001A156`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A157`}
                    w="158px"
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${sectionId}Q001A158`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A159`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A160`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A161`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="US EXtremity"
                    name={`Q001.${sectionId}Q001A162`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bilateral"
                    name={`Q001.${sectionId}Q001A163`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A164`}
                    label="L"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name={`Q001.${sectionId}Q001A165`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Upper"
                    name={`Q001.${sectionId}Q001A166`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Lower"
                    name={`Q001.${sectionId}Q001A167`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A168`}
                    w="200px"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A169`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="DVT"
                    name={`Q001.${sectionId}Q001A171`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Peripheral Vascular Disease"
                    name={`Q001.${sectionId}Q001A172`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Foreign Body"
                    name={`Q001.${sectionId}Q001A173`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${sectionId}Q001A174`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A175`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="US"
                    name={`Q001.${sectionId}Q001A176`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abdomen"
                    name={`Q001.${sectionId}Q001A177`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pelvic"
                    name={`Q001.${sectionId}Q001A178`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RUQ"
                    name={`Q001.${sectionId}Q001A179`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RLQ"
                    name={`Q001.${sectionId}Q001A180`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A181`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Gallstones"
                    name={`Q001.${sectionId}Q001A182`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Appendicitis"
                    name={`Q001.${sectionId}Q001A183`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="IUP"
                    name={`Q001.${sectionId}Q001A184`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${sectionId}Q001A185`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Intussusception"
                    name={`Q001.${sectionId}Q001A186`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Torsion"
                    name={`Q001.${sectionId}Q001A187`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pyloric Stenosis"
                    name={`Q001.${sectionId}Q001A188`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Kidney Stone"
                    name={`Q001.${sectionId}Q001A189`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A190`}
                    w="208px"
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="MRI"
                    name={`Q001.${sectionId}Q001A191`}
                  />
                  <ChartRowElement
                    type="input"
                    size="md"
                    name={`Q001.${sectionId}Q001A192`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${sectionId}Q001A193`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${sectionId}Q001A194`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dislocation"
                    name={`Q001.${sectionId}Q001A195`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Osteomyelitis"
                    name={`Q001.${sectionId}Q001A196`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Septic Arthritis"
                    name={`Q001.${sectionId}Q001A197`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Avascular Necrosis"
                    name={`Q001.${sectionId}Q001A198`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${sectionId}Q001A199`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A200`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="MRI"
                    name={`Q001.${sectionId}Q001A201`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Brain"
                    name={`Q001.${sectionId}Q001A202`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Spine"
                    name={`Q001.${sectionId}Q001A203`}
                  />
                  <ChartRowElement
                    type="input"
                    w="214px"
                    name={`Q001.${sectionId}Q001A204`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${sectionId}Q001A205`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CVA"
                    name={`Q001.${sectionId}Q001A206`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${sectionId}Q001A207`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cord Compression"
                    name={`Q001.${sectionId}Q001A208`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cauda Equina Syndrome"
                    name={`Q001.${sectionId}Q001A209`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Traumatic Brain Injury"
                    name={`Q001.${sectionId}Q001A210`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${sectionId}Q001A211`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="SCIWORA"
                    name={`Q001.${sectionId}Q001A212`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${sectionId}Q001A213`}
                    w="70px"
                  />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionId}
              subsectionCode="Q002"
              subsectionLabel="Treatments"
            >
              Treatments
            </SharedChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    name="Q002.SMDMQ002A083"
                    label="IV / IVF for:"
                    fontStyle="italic"
                    textDecorationLine="underline"
                  />
                  <ChartRowElement type="input" name="Q002.SMDMQ002A002" />
                </ChartRow>
                <ChartRow gridTemplateColumns="300px 1fr">
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="SIRS / SEPSIS"
                      name="Q002.SMDMQ002A003"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Tachycardia"
                      name="Q002.SMDMQ002A004"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Fever"
                      name="Q002.SMDMQ002A005"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Myalgias"
                      name="Q002.SMDMQ002A006"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Myco"
                      name="Q002.SMDMQ002A080"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Mono"
                      name="Q002.SMDMQ002A081"
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="other"
                      name="Q002.SMDMQ002A082"
                    />
                    <ChartRowElement
                      type="cross-option"
                      name="Q002.SMDMQ002A084"
                      label="to evaluate for source of infection."
                      fontStyle="italic"
                      textDecorationLine="underline"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T35MDM };
