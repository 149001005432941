import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const WarningCircleOutlined = createIcon({
  path: (
    <path
      d="M9 18C13.9235 18 18 13.9147 18 9C18 4.07647 13.9147 0 8.99118 0C4.07647 0 0 4.07647 0 9C0 13.9147 4.08529 18 9 18ZM9 16.5C4.83529 16.5 1.50882 13.1647 1.50882 9C1.50882 4.83529 4.82647 1.5 8.99118 1.5C13.1559 1.5 16.5 4.83529 16.5 9C16.5 13.1647 13.1647 16.5 9 16.5ZM8.99118 10.5882C9.40588 10.5882 9.65294 10.35 9.66176 9.89118L9.79412 5.23235C9.80294 4.78235 9.45 4.44706 8.98235 4.44706C8.50588 4.44706 8.17059 4.77353 8.17941 5.22353L8.29412 9.89118C8.30294 10.3412 8.55 10.5882 8.99118 10.5882ZM8.99118 13.4559C9.49412 13.4559 9.93529 13.05 9.93529 12.5382C9.93529 12.0265 9.50294 11.6206 8.99118 11.6206C8.47059 11.6206 8.03823 12.0353 8.03823 12.5382C8.03823 13.0412 8.47941 13.4559 8.99118 13.4559Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { WarningCircleOutlined };
