import { theme } from "@chakra-ui/react";

const baseTabsTheme = theme.components.Tabs;

const baseStyle = {
  tab: {
    height: "50px",
    color: "white",
    borderRadius: "10px",
  },
  tabpanel: {
    padding: "10px 0",
  },
  tablist: {
    borderRadius: "10px",
  },
};

function variantSolidRounded() {
  return {
    tab: {
      borderRadius: "10px",
      _selected: {
        color: "white",
        bg: "blue",
      },
    },
    tablist: {
      backgroundColor: "gray.300",
    },
  };
}

function variantOutlined() {
  return {
    tab: {
      borderColor: "gray",
      border: "1px solid",
      color: "gray.650",
      width: "166px",
      _selected: {
        color: "blue",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    tablist: {
      backgroundColor: "transparent",
    },
  };
}

const variants = {
  "solid-rounded": variantSolidRounded,
  outlined: variantOutlined,
};

const defaultProps = {
  variant: "solid-rounded",
};

const tabsTheme = {
  ...baseTabsTheme,
  variants: {
    "solid-rounded": baseTabsTheme.variants["solid-rounded"],
  },
};

export default {
  baseStyle,
  variants,
  defaultProps,
};

export { tabsTheme };
