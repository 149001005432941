import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { EmrCalendar } from "@medstonetech/slate-icons";
import { format } from "date-fns";
import { useActiveCharts } from "modules/charts-shared/api";
import { PatientEncounter, usePatientEncounters } from "modules/reception/api";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { Card, ToolbarHeader } from "shared";

type ModalMedicalRecordsListProps = {
  isOpen: boolean;
  onClose: () => void;
};

type EncounterItemProps = {
  encounter: PatientEncounter;
  isLast: boolean;
};

function EncounterItem({ encounter, isLast }: EncounterItemProps) {
  const mainRoute = window.location.hash.split("/")[1];
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useActiveCharts(encounter.encounterId);
  const activeCharts = useMemo(() => data?.data || [], [data]);

  const handlePastEncounter = () => {
    if (activeCharts.length === 0) {
      toast({
        title: "Error",
        description: "No charts found for this encounter",
        status: "error",
      });
    } else if (activeCharts[0]?.chartType && activeCharts[0]?.chartCode) {
      navigate(
        `/${mainRoute}/charts/${encounter.encounterId}/${activeCharts[0]?.chartType}/${activeCharts[0]?.chartCode}/patient-info`,
        {
          state: {
            fromRoute: location.pathname,
            fromLabel: "Back",
            isPastEncounter: true,
          },
        }
      );
    }
  };

  return (
    <Flex
      alignItems="center"
      onClick={handlePastEncounter}
      sx={{ cursor: "pointer" }}
    >
      <Flex alignItems="center" p="1rem 14px">
        <Icon as={EmrCalendar} color="gray.700" />
      </Flex>
      <Flex
        flex={1}
        borderBottom="1px solid"
        borderBottomColor={isLast ? "transparent" : "gray.450"}
        py="1rem"
        alignItems="center"
        columnGap="1rem"
      >
        <chakra.span fontWeight="500">
          {format(new Date(encounter.date), "P")}
        </chakra.span>
        <chakra.span fontWeight="500">
          {format(new Date(encounter.date), "p")}
        </chakra.span>
      </Flex>
    </Flex>
  );
}

function ModalMedicalRecordsList({
  isOpen,
  onClose,
}: ModalMedicalRecordsListProps) {
  const { encounterId = "" } = useParams();
  const { data } = usePatientEncounters(encounterId);
  const encounters =
    data?.data.filter((encounter) => encounter.encounterId !== encounterId) ||
    [];

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="gray.200"
        maxW="unset"
        w="540px"
        h="auto"
        m="auto"
      >
        <ModalHeader>
          <ToolbarHeader
            rightButtons={[
              <Button key="cancelBtn" onClick={onClose}>
                Close
              </Button>,
            ]}
            titleText={<chakra.span>Medical Records</chakra.span>}
          />
        </ModalHeader>
        <ModalBody>
          <Flex justify="center" my="2rem">
            <Text fontWeight="400" maxW="400px" textAlign="center">
              Below are the previous visits for this patient. You will be able
              to view the charts that were used for this visit, but view only.
            </Text>
          </Flex>
          <Card variant="basic">
            {encounters.map((encounter, index) => (
              <EncounterItem
                key={encounter.encounterId}
                encounter={encounter}
                isLast={index === encounters.length - 1}
              />
            ))}
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ModalMedicalRecordsList };
