import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseUserByScopeResponseItem = {
  id: string;
  fullName: string;
  teams: string;
  pictureUrl: string;
};

function useUserByScope(scope: string, search?: string) {
  return useAxiosQuery(["assign-to-me", { scope }], () => {
    return axiosIdentityInstance.get<UseUserByScopeResponseItem[]>(
      `/v1/user/by-scope`,
      {
        params: {
          search,
          scope,
        },
      }
    );
  });
}

export { useUserByScope };
export type { UseUserByScopeResponseItem };
