import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S013Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q013.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q013`}
          subsectionLabel="Intubation (Short)"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Intubation (Short)
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="Intubated with"
              name={`${sectionDelimiter}Q013A001`}
              type="option"
            />
            <ChartRowElement
              label="#"
              name={`${sectionDelimiter}Q013A002`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q013A003`}
              type="input"
              w="350px"
            />
            <ChartRowElement
              label="ETT"
              name={`${sectionDelimiter}Q013A004`}
              type="option"
            />
            <ChartRowElement
              label="curved"
              name={`${sectionDelimiter}Q013A005`}
              type="option"
            />
            <ChartRowElement
              label="straight blade"
              name={`${sectionDelimiter}Q013A006`}
              type="option"
            />
            <ChartRowElement
              label="nasal"
              name={`${sectionDelimiter}Q013A007`}
              type="option"
            />
            <ChartRowElement
              label="preoxygenated"
              name={`${sectionDelimiter}Q013A008`}
              type="option"
            />
            <ChartRowElement
              label="premedicated"
              name={`${sectionDelimiter}Q013A009`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q013A010`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="placement confirmed by:"
              name={`${sectionDelimiter}Q013A011`}
              type="option"
            />
            <ChartRowElement
              label="auscultation"
              fontStyle="italic"
              name={`${sectionDelimiter}Q013A012`}
              type="option"
            />
            <ChartRowElement
              label="CXR"
              fontStyle="italic"
              name={`${sectionDelimiter}Q013A014`}
              type="option"
            />
            <ChartRowElement
              label="placement corrected"
              fontStyle="italic"
              name={`${sectionDelimiter}Q013A015`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q013A016`}
              type="input"
            />
          </ChartRow>

          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q013A017`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S013Procedure };
