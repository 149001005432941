import {
  Box,
  Flex,
  Heading,
  Icon,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { EmrSearch } from "@medstonetech/slate-icons";
import { AddRounded, MinusRoundFilled } from "icons";
import React, { useMemo, useState } from "react";
import { Button, Card, Input, Label, WarningDialog } from "shared";
import {
  PROCEDURE_SELECT_ITEM,
  PROCEDURE_SELECT_ITEM_NO_ID,
  PROCEDURES_SELECT_OPTIONS,
} from "./constants";

import faker from "faker";

type ProcedureListModalProps = {
  isOpen: boolean;
  isReadOnly?: boolean;
  onClose: () => void;
  onDone: (list: PROCEDURE_SELECT_ITEM[]) => void;
};

type ProcedureListItemProps = {
  item: PROCEDURE_SELECT_ITEM_NO_ID;
  onAdd?: () => void;
  onDelete?: () => void;
  isReadOnly?: boolean;
  showAdd?: boolean;
  showDelete?: boolean;
  isLast?: boolean;
};

function ProcedureListItem({
  item,
  onAdd = () => {},
  onDelete = () => {},
  showAdd = false,
  isReadOnly = false,
  showDelete = false,
  isLast = false,
}: ProcedureListItemProps) {
  return (
    <>
      {showAdd && (
        <Button
          width="100%"
          justifyContent="flex-start"
          variant="ghost"
          color="black"
          fontSize="1.0625rem"
          disabled={isReadOnly}
          onClick={onAdd}
          pr="0"
          height="auto"
        >
          <Flex w="100%" columnGap="17px" alignItems="center">
            <Icon
              fontSize="1.2625rem"
              borderRadius="1rem"
              color="green"
              backgroundColor="white"
              as={AddRounded}
            />
            <Flex
              borderBottom={isLast ? "0px" : "1px solid"}
              borderColor="gray.450"
              w="100%"
              justify="start"
              p="1rem 0 1rem 1rem"
              alignItems="center"
            >
              {item.label}
            </Flex>
          </Flex>
        </Button>
      )}
      {showDelete && (
        <Button
          width="100%"
          justifyContent="flex-start"
          variant="ghost"
          color="black"
          fontSize="1.0625rem"
          disabled={isReadOnly}
          onClick={onDelete}
          height="auto"
          pr="0"
        >
          <Flex w="100%" columnGap="17px" alignItems="center">
            <Icon
              fontSize="1.2625rem"
              borderRadius="1rem"
              color="red"
              backgroundColor="white"
              as={MinusRoundFilled}
            />
            <Flex
              borderBottom={isLast ? "0px" : "1px solid"}
              borderColor="gray.450"
              w="100%"
              justify="start"
              p="1rem 0 1rem 1rem"
              alignItems="center"
            >
              {item.label}
            </Flex>
          </Flex>
        </Button>
      )}
    </>
    // <Flex height="52px" p="15px" alignItems="center" columnGap="16px">
    //   {showAdd && (
    //     <IconButton variant="icon" aria-label="Add" onClick={onAdd}>
    //       <Icon as={AddRounded} color="green" />
    //     </IconButton>
    //   )}
    //   {showDelete && (
    //     <IconButton variant="icon" aria-label="Delete" onClick={onDelete}>
    //       <Icon as={MinusRoundFilled} color="red" />
    //     </IconButton>
    //   )}
    //   <Text flex={1}>{item.label}</Text>
    // </Flex>
  );
}

function ProcedureListModal(props: ProcedureListModalProps) {
  const { isOpen, onDone, onClose, isReadOnly = false } = props;

  const [search, setSearch] = useState("");
  const [deletingId, setDeletingId] = useState<string | null>(null);

  const [selectedProcedureList, setSelectedProcedureList] = useState<
    PROCEDURE_SELECT_ITEM[]
  >([]);

  const filteredProcedureList: PROCEDURE_SELECT_ITEM_NO_ID[] = useMemo(() => {
    if (PROCEDURES_SELECT_OPTIONS.length > 0) {
      const filteredElements = PROCEDURES_SELECT_OPTIONS.filter(
        (item) =>
          item.label.toLowerCase().includes(search.toLowerCase()) ||
          search === ""
      );

      return filteredElements;
    }
    return [];
  }, [search]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedProcedureList([]);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent
          bg="gray.200"
          maxW="unset"
          width="540px"
          containerProps={{ justifyContent: "flex-end" }}
          height="90%"
          margin="auto"
          overflow="hidden"
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            fontSize="1rem"
            padding="20px 16px 30px"
            alignItems="center"
          >
            <Box flex={1} />
            <Heading fontWeight="600" fontSize="1.0625rem" textAlign="center">
              Procedures
            </Heading>
            <Flex flex={1} justifyContent="flex-end">
              <Button
                variant="label"
                disabled={selectedProcedureList.length === 0}
                onClick={() => {
                  onDone(selectedProcedureList);
                  setSelectedProcedureList([]);
                  onClose();
                }}
                color="blue"
              >
                Done
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody p="15px">
            <InputGroup maxW="300px" h="36px" mb="10px">
              <InputLeftElement
                h="100%"
                pointerEvents="none"
                children={
                  <Icon as={EmrSearch} color="gray.550" fontSize="0.9375rem" />
                }
              />
              <Input
                paddingLeft="2em"
                bg="gray.350"
                variant="filled"
                border="none"
                placeholder="Search"
                _placeholder={{ opacity: 1, color: "gray.550" }}
                h="100%"
                minWidth="510px"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
            <Box maxH="700px" overflowY="auto">
              <Box>
                <Label>Selected</Label>
                <Card overflowY="auto">
                  {selectedProcedureList.length === 0 && (
                    <React.Fragment>
                      <Flex
                        height="52px"
                        p="15px"
                        alignItems="center"
                        columnGap="16px"
                      >
                        <Text flex={1} textAlign="center" color="gray.600">
                          None Selected
                        </Text>
                      </Flex>
                    </React.Fragment>
                  )}

                  {selectedProcedureList.map((item, i) => (
                    <React.Fragment key={`${item.value}_${i}`}>
                      <ProcedureListItem
                        item={item}
                        showDelete
                        isReadOnly={isReadOnly}
                        onDelete={() =>
                          setSelectedProcedureList((prev) => [
                            ...prev.filter((el) => el.id !== item.id),
                          ])
                        }
                        isLast={i === selectedProcedureList.length - 1}
                      />
                    </React.Fragment>
                  ))}
                </Card>
              </Box>

              <Box marginTop="10px">
                <Label>Add procedures</Label>
                <Card overflowY="auto" marginTop="10px">
                  {filteredProcedureList.map((item, i) => (
                    <React.Fragment key={item.value}>
                      <ProcedureListItem
                        item={item}
                        showAdd
                        isReadOnly={isReadOnly}
                        onAdd={() =>
                          setSelectedProcedureList((prev) => [
                            ...prev,
                            { ...item, id: faker.datatype.uuid() },
                          ])
                        }
                        isLast={i === filteredProcedureList.length - 1}
                      />
                    </React.Fragment>
                  ))}
                </Card>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <WarningDialog
        isOpen={Boolean(deletingId)}
        onCancel={() => setDeletingId(null)}
        onAction={() => {}}
        onClose={() => setDeletingId(null)}
        title="Warning"
        mainText={`Are you sure you want to Delete this \rThis action cannot be undone!`}
      />
    </>
  );
}

export { ProcedureListModal };
