import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  ModalFooter,
  Button,
  VStack,
  ButtonProps,
  Divider,
} from "@chakra-ui/react";
import * as React from "react";
import { UNSAFE_NavigationContext, Navigator } from "react-router-dom";
import { DividerList } from "shared/divider-list";
import { History } from "history";

type FormPromptWithSaveProps = {
  when: boolean;
  onSave: (onComplete: () => void) => void;
  mainText: string;
  title: string;
  secondaryText?: string;
  isSaveLoading?: boolean;
  showSaveBtn?: true;
};

type FormPromptNoSaveProps = {
  when: boolean;
  mainText: string;
  title: string;
  secondaryText?: string;
  showSaveBtn: false;
};

type FormPromptProps = FormPromptWithSaveProps | FormPromptNoSaveProps;

function isPropsNoSave(props: FormPromptProps): props is FormPromptNoSaveProps {
  return props.showSaveBtn === false;
}

const DEFAULT_BTN_STYLE_PROPS: ButtonProps = {
  variant: "label",
  padding: "0.75rem 0",
  fontSize: "1.0625rem",
  fontWeight: "400",
};

function FormPrompt(props: FormPromptProps) {
  const { when, mainText, secondaryText, title } = props;
  const history = React.useContext(UNSAFE_NavigationContext)
    .navigator as Navigator & Partial<Pick<History, "block">>;
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState("");
  const unblockRef = React.useRef<() => void>();
  const isPropsWithSave = !isPropsNoSave(props);

  const continueNavigation = () => {
    unblockRef.current?.();
    setShowPrompt(false);
    history.push(currentPath);
  };

  const onCancel = () => {
    setShowPrompt(false);
  };

  const onSave = () => {
    if (isPropsWithSave) {
      props.onSave(continueNavigation);
    }
  };

  React.useEffect(() => {
    if (when) {
      unblockRef.current = history.block?.((location) => {
        if (when) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setCurrentPath(location.location.pathname);
          setShowPrompt(true);
          return false;
        }
        return "true";
      });
    }

    return () => {
      unblockRef.current?.();
    };
  }, [when, history]);

  return (
    <Modal
      onClose={onCancel}
      isOpen={showPrompt}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="295px"
        width="295px"
        borderRadius="1rem"
        bg="#E5E5E5"
      >
        <ModalHeader
          padding="16px 28px 0"
          textAlign="center"
          fontSize="1.0625rem"
          fontWeight="600"
        >
          {title}
        </ModalHeader>
        <ModalBody
          padding="0 28px 16px"
          fontSize="0.8125rem"
          lineHeight="1.125rem"
        >
          <Box textAlign="center">{mainText}</Box>
          {secondaryText && (
            <Box width="100%" textAlign="center" marginTop="1rem">
              {secondaryText}
            </Box>
          )}
        </ModalBody>
        <ModalFooter padding="0">
          <VStack width="100%" spacing="0">
            <Divider />
            <DividerList dividerLeftMargin="0px">
              <Button
                width="100%"
                onClick={onCancel}
                isDisabled={isPropsWithSave ? props.isSaveLoading : false}
                {...DEFAULT_BTN_STYLE_PROPS}
              >
                Cancel
              </Button>
              <Button
                width="100%"
                onClick={continueNavigation}
                color="red"
                isDisabled={isPropsWithSave ? props.isSaveLoading : false}
                {...DEFAULT_BTN_STYLE_PROPS}
              >
                Discard Changes
              </Button>
              {isPropsWithSave && (
                <Button
                  width="100%"
                  onClick={onSave}
                  color="blue"
                  isLoading={props.isSaveLoading}
                  {...DEFAULT_BTN_STYLE_PROPS}
                >
                  Save Changes
                </Button>
              )}
            </DividerList>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export type { FormPromptProps, FormPromptWithSaveProps, FormPromptNoSaveProps };
export { FormPrompt };
