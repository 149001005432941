/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box } from "@chakra-ui/react";
import { ChartFormProcedureSection } from "modules/charts-shared";
import { ProceduralSedationNote } from "../ProceduralSedationNote";

type SedationNoteElementsProps = {
  isReadOnly: boolean;
  encounterId: string;
  chartCode: string;
  elements: ChartFormProcedureSection[];
  onRemoveElement: (id: string) => void;
};

const sectionCode = "SPSN2";

function SedationNoteElements(props: SedationNoteElementsProps) {
  const { elements, encounterId, chartCode, isReadOnly, onRemoveElement } =
    props;

  return (
    <Box display="flex" width="100%" columnGap="35px" flexDirection="column">
      {elements.map((el, index) => (
        <Box key={index} marginTop="5px" marginBottom="5px" width="100%">
          <ProceduralSedationNote
            chartCode={chartCode}
            encounterId={encounterId}
            sectionId={el.id}
            sectionCode={sectionCode}
            sectionSubCode={el.code}
            isReadOnly={isReadOnly}
            onRemoveElement={onRemoveElement}
          />
        </Box>
      ))}
    </Box>
  );
}

export { SedationNoteElements };
