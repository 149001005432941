import * as React from "react";
import {
  Box,
  BoxProps,
  Button,
  chakra,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { useFileUrl } from "hooks";
import {
  useEncounterChiefComplaintFile,
  useEncounterDNRFile,
  useEncounterMedicationsFile,
} from "modules/charts-shared/api";
import {
  Card,
  FullScreenModal,
  Loading,
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerPageController,
  TabList,
  Tabs,
} from "shared";

type PatientInfoData = {
  encounterId: string;
  gapSize?: string;
};

type PatientInfoFileContainerProps = Omit<PatientInfoData, "gapSize"> &
  BoxProps;

type ShowFileProps = {
  encounterId: string;
};

function PatientInfoFileContainer(props: PatientInfoFileContainerProps) {
  const { encounterId, ...rest } = props;
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Box margin="auto" textAlign="center" {...rest}>
      <Card variant="default" overflow="hidden">
        <Tabs isLazy index={activeTab} onChange={setActiveTab}>
          <Box bg="gray.350" p="7px 5px">
            <TabList
              index={activeTab}
              bg="gray.250"
              alignItems="center"
              h="40px"
              p="0 2px"
            >
              <Tab
                w="calc(100% / 3)"
                h="36px"
                fontSize="0.9375rem"
                fontWeight="500"
                color="gray.650"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                Chief Complaint
              </Tab>
              <Tab
                w="calc(100% / 3)"
                h="36px"
                fontSize="0.9375rem"
                fontWeight="500"
                color="gray.650"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                Medications
              </Tab>
              <Tab
                w="calc(100% / 3)"
                h="36px"
                fontSize="0.9375rem"
                fontWeight="500"
                color="gray.650"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                DNR
              </Tab>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel height="850px" padding="0px">
              <ChiefComplaintFile encounterId={encounterId} />
            </TabPanel>
            <TabPanel height="850px" padding="0px">
              <MedicationsFile encounterId={encounterId} />
            </TabPanel>
            <TabPanel height="850px" padding="0px">
              <DNRFile encounterId={encounterId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
}

function ChiefComplaintFile(props: ShowFileProps) {
  const { encounterId } = props;
  const { data, isLoading, isFetching } =
    useEncounterChiefComplaintFile(encounterId);
  const fileUrl = useFileUrl(data?.data);

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box h="100%">
      {data?.data && (
        <chakra.img src={fileUrl} h="100%" w="100%" objectFit="contain" />
      )}
    </Box>
  );
}

function MedicationListButtonModal(props: { encounterId: string }) {
  const { encounterId } = props;
  const [medicationListIsOpen, setMedicationListIsOpen] = React.useState(false);
  return (
    <>
      <Button
        variant="action"
        height="36px"
        onClick={() => setMedicationListIsOpen(true)}
      >
        View Medication List
      </Button>
      <FullScreenModal
        modalTitle="Medication List"
        isOpen={medicationListIsOpen}
        modalBody={<MedicationsFile encounterId={encounterId} />}
        onClose={() => setMedicationListIsOpen(false)}
      />
    </>
  );
}

function MedicationsFile(props: ShowFileProps) {
  const { encounterId } = props;

  const [file, setFile] = React.useState<Nullable<File | string | Blob>>();
  const { data, isLoading, isFetching } =
    useEncounterMedicationsFile(encounterId);

  React.useEffect(() => {
    if (data) {
      setFile(data.data);
    }
  }, [data]);

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box h="100%" overflow="hidden">
      {data?.data && (
        <PdfViewer value={file}>
          <PdfViewerContainer
            height="calc(100% + 80px)"
            activePage={<PdfViewerActivePage pageWidth={600} />}
            pageController={<PdfViewerPageController />}
          />
        </PdfViewer>
      )}
    </Box>
  );
}

function DNRFile(props: ShowFileProps) {
  const { encounterId } = props;
  const [file, setFile] = React.useState<Nullable<File | string | Blob>>();
  const { data, isLoading, isFetching } = useEncounterDNRFile(encounterId);

  React.useEffect(() => {
    if (data) {
      setFile(data.data);
    }
  }, [data]);

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box h="100%" overflow="hidden">
      {data?.data && (
        <PdfViewer value={file}>
          <PdfViewerContainer
            height="calc(100% + 80px)"
            activePage={<PdfViewerActivePage pageWidth={600} />}
            pageController={<PdfViewerPageController />}
          />
        </PdfViewer>
      )}
    </Box>
  );
}

export { PatientInfoFileContainer, MedicationsFile, MedicationListButtonModal };
