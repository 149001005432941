import Konva from "konva";
import * as React from "react";
import { Circle, KonvaNodeEvents } from "react-konva";
import { CircleConfig } from "./types";
import { WithTransformer, WithTransformerProps } from "./WithTransformer";

type TransformerCircleProps = CircleConfig &
  KonvaNodeEvents & { isSelected: boolean } & Pick<
    WithTransformerProps,
    "dispatch"
  >;

function TransformerCircle(props: TransformerCircleProps) {
  const { isSelected, dispatch, ...circleProps } = props;
  const circleRef = React.useRef<Konva.Circle>(null);

  return (
    <WithTransformer
      isSelected={isSelected}
      shapeRef={circleRef}
      dispatch={dispatch}
      enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
      flipEnabled={false}
      rotateEnabled={false}
    >
      <Circle ref={circleRef} {...circleProps} />
    </WithTransformer>
  );
}

export type { TransformerCircleProps };
export { TransformerCircle };
