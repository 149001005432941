import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type VitalsTimerPayload = {
  time: string;
};

function useUpdateVitalsTimer(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateVitalsTimer"],
    (payload: VitalsTimerPayload) => {
      return axiosChartInstance.post<{ time: string }>(
        `v1/Vitals/timer/${encounterId}`,
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vitalsTimer", { encounterId }]);
      },
    }
  );
}

export type { VitalsTimerPayload as TimerPayload };
export { useUpdateVitalsTimer };
