import {
  HStack,
  VStack,
  Box,
  Icon,
  IconButton,
  BoxProps,
} from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import * as React from "react";
import { StaffAvatar } from "shared";

type PersonalInfoData = {
  name: string;
  description: string;
  pictureUrl?: string;
};

type PersonalInfoProps = PersonalInfoData & {
  isHighlighted?: boolean;
};

function PersonalInfo(props: PersonalInfoProps) {
  const { name, pictureUrl, description, isHighlighted } = props;

  return (
    <HStack>
      <StaffAvatar
        w="32px"
        h="32px"
        size="xs"
        fontSize=".5rem"
        userName={name || ""}
        profileUrl={pictureUrl || ""}
      />
      <VStack spacing="0" alignItems="flex-start">
        <Box fontSize="1.0625rem" fontWeight="600" lineHeight="1.5rem">
          {name}
        </Box>
        <Box
          fontSize="0.875rem"
          fontWeight="400"
          color={isHighlighted ? "white" : "gray.700"}
          lineHeight="1rem"
        >
          {description}
        </Box>
      </VStack>
    </HStack>
  );
}

type PersonalInfoSelectedCardProps = {
  personalInfo?: Nullable<PersonalInfoData>;
  onDelete: (personalInfo?: PersonalInfoData) => void;
} & BoxProps;

function PersonalInfoSelectedCard(props: PersonalInfoSelectedCardProps) {
  const { personalInfo, onDelete, ...boxProps } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="18px 21px"
      borderRadius="10px"
      bg="white"
      height="52px"
      minHeight="52px"
      {...boxProps}
    >
      {personalInfo && (
        <>
          <PersonalInfo
            name={personalInfo.name}
            pictureUrl={personalInfo.pictureUrl}
            description={personalInfo.description}
          />
          <IconButton
            aria-label="remove selected Nurse"
            variant="icon"
            icon={<Icon as={EmrRemove} color="red" fontSize="1.375rem" />}
            onClick={() => onDelete(personalInfo)}
          />
        </>
      )}
    </Box>
  );
}

export type {
  PersonalInfoProps,
  PersonalInfoData,
  PersonalInfoSelectedCardProps,
};
export { PersonalInfo, PersonalInfoSelectedCard };
