import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import {
  SharedChartNoteApiUrlParams,
  getSharedChartNoteApiURL,
} from "../../utils";

type UseCreateSharedChartNotePayload = {
  note: string;
};

function useCreateSharedChartNote(params: SharedChartNoteApiUrlParams) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createSharedChartNote", params],
    (input: UseCreateSharedChartNotePayload) =>
      axiosChartInstance.post(getSharedChartNoteApiURL(params), input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sharedChartNotes", params]);
        queryClient.invalidateQueries(["sectionNotesCount", params]);
        queryClient.invalidateQueries(["subSectionNotesCount", params]);
      },
    }
  );
}

export { useCreateSharedChartNote };
