import React from "react";
import {
  Divider,
  TabList as CkTabList,
  TabListProps as CkTabListProps,
  TabProps,
} from "@chakra-ui/react";
import { TabsVariant } from "./types";

/**
 * interleave inserts Divider elements between tabs except
 * when the tab is active, as active tabs should not have
 * dividers at either side.
 * @param childrenList - React children list
 * @param tabIndex - Current tab index
 * @param activeTab - Active tab index
 * @param dividerIndex - Next divider index
 * @returns React elements list with interleaved dividers
 */
function interleave(
  childrenList: JSX.Element[],
  tabIndex: number,
  activeTab: number,
  dividerIndex = 0
): JSX.Element[] {
  const [x, ...xs] = childrenList;

  if (!x) {
    return [];
  }

  if (x && xs.length === 0) {
    return [x];
  }

  if (tabIndex !== activeTab && tabIndex + 1 !== activeTab) {
    return [
      x,
      <Divider
        key={`tab-divider-${dividerIndex}`}
        height="30px"
        margin="10px 0"
        width="2px"
        backgroundColor="gray.450"
        orientation="vertical"
      />,
      ...interleave(xs, tabIndex + 1, activeTab, dividerIndex + 1),
    ];
  }

  return [x, ...interleave(xs, tabIndex + 1, activeTab, dividerIndex)];
}

type TabListProps = CkTabListProps & {
  index: number;
  variant?: TabsVariant;
};

function TabList(props: TabListProps) {
  const { index, variant = "solid-rounded", children, ...rest } = props;
  const childrenArr = children
    ? React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          throw new Error(
            "Invalid child element for TabList parent, please send Tab children."
          );
        }

        return React.cloneElement<TabProps>(child);
      })
    : [];

  return (
    <CkTabList {...rest}>
      {variant === "solid-rounded" && childrenArr
        ? interleave(childrenArr, 0, index)
        : childrenArr}
    </CkTabList>
  );
}

export type { TabListProps };
export { TabList };
