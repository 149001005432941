import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useGenerateRadiologyReport(orderId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["generateRadiologyReport", { orderId }],
    () => axiosOrderInstance.post(`v1/integrations/hl7-orm-parser/${orderId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "encounterRamsoftOrderFile",
          { orderId },
        ]);
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["orderContrast", { orderId }]);
      },
    }
  );
}

export { useGenerateRadiologyReport };
