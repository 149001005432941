import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UserType } from "modules/identity/types";

type UseUsersProps = {
  userType: UserType;
  search?: string;
  options?: { enabled: boolean };
};

type UseUsersResponseItem = {
  id: string;
  fullName: string;
  teams: string;
  pictureUrl: string;
  signature?: string;
};

function useUsers(props: UseUsersProps) {
  const { userType, search, options } = props;

  return useAxiosQuery(
    ["users", props],
    () =>
      axiosIdentityInstance.get<UseUsersResponseItem[]>(
        "v1/user/by-team-type",
        {
          params: { type: userType, search: search || undefined },
        }
      ),
    { ...options }
  );
}

export type { UseUsersProps, UseUsersResponseItem };
export { useUsers };
