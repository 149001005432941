import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const CloseOutline = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM10 18.3333C5.37255 18.3333 1.67647 14.6275 1.67647 10C1.67647 5.37255 5.36275 1.66667 9.9902 1.66667C14.6176 1.66667 18.3235 5.37255 18.3333 10C18.3431 14.6275 14.6275 18.3333 10 18.3333ZM7.07843 14.4902C7.39216 14.4902 7.55882 14.3824 7.81373 14.0392L9.93137 11.0196H9.97059L12.0882 14.0392C12.3333 14.3824 12.5098 14.4902 12.8137 14.4902C13.2549 14.4902 13.5882 14.1961 13.5882 13.7745C13.5882 13.5784 13.5294 13.4314 13.402 13.2647L10.9412 9.90196L13.4216 6.52941C13.5588 6.35294 13.6078 6.19608 13.6078 6.0098C13.6078 5.62745 13.2843 5.32353 12.8725 5.32353C12.5686 5.32353 12.3725 5.44118 12.1569 5.76471L10.1078 8.78431H10.0588L7.97059 5.7549C7.7451 5.44118 7.55882 5.32353 7.22549 5.32353C6.80392 5.32353 6.46078 5.65686 6.46078 6.04902C6.46078 6.26471 6.5098 6.40196 6.66667 6.61765L9.01961 9.85294L6.51961 13.3039C6.40196 13.4804 6.35294 13.6176 6.35294 13.8137C6.35294 14.1961 6.66667 14.4902 7.07843 14.4902Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { CloseOutline };
