import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Consent = createIcon({
  path: (
    <path
      d="M0 21.0048C0 22.9984 0.982512 24 2.95707 24H11.9237C13.8983 24 14.8808 22.9889 14.8808 21.0048V19.2401H16.5501C18.5151 19.2401 19.5072 18.2289 19.5072 16.2448V2.99523C19.5072 1.01113 18.5151 0 16.5501 0H7.58347C5.61844 0 4.62639 1.00159 4.62639 2.99523V4.75994H2.95707C0.982512 4.75994 0 5.75199 0 7.75517V21.0048ZM9.12878 2.70906V2.50874C9.12878 2.21304 9.31956 1.94595 9.73927 1.94595H14.3943C14.8045 1.94595 15.0048 2.21304 15.0048 2.50874V2.70906C15.0048 2.99523 14.8045 3.27186 14.3943 3.27186H9.73927C9.31956 3.27186 9.12878 2.99523 9.12878 2.70906ZM1.53577 20.9762V7.77424C1.53577 6.82989 2.04134 6.29571 3.02385 6.29571H11.8474C12.8299 6.29571 13.345 6.82989 13.345 7.77424V20.9762C13.345 21.93 12.8299 22.4642 11.8474 22.4642H3.02385C2.04134 22.4642 1.53577 21.93 1.53577 20.9762ZM3.27186 14.3657C3.27186 14.7186 3.51987 14.9762 3.87281 14.9762H8.00318L9.71065 14.9094L8.7186 15.7583L7.78378 16.6741C7.66932 16.7886 7.60254 16.9316 7.60254 17.1033C7.60254 17.4277 7.85056 17.6948 8.18442 17.6948C8.34658 17.6948 8.48967 17.628 8.59459 17.5231L11.2655 14.8426C11.4181 14.69 11.504 14.5564 11.504 14.3657C11.504 14.2035 11.4277 14.07 11.2655 13.9078L8.59459 11.2178C8.48967 11.1129 8.34658 11.0556 8.18442 11.0556C7.85056 11.0556 7.60254 11.3132 7.60254 11.6375C7.60254 11.8092 7.67886 11.9523 7.78378 12.0668L8.7186 12.9921L9.71065 13.8315L8.00318 13.7742H3.87281C3.52941 13.7742 3.27186 14.0127 3.27186 14.3657Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 24",
});

export { Consent };
