import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUpdateEncounterNote(noteId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterNote", { noteId }],
    (note: string) =>
      axiosPatientInstance.put(`v1/Encounter/${encounterId}/notes/${noteId}`, {
        note,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterNotes", { encounterId }]);
      },
    }
  );
}

export { useUpdateEncounterNote };
