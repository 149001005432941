import { useControllableState, usePrevious } from "@chakra-ui/react";
import * as React from "react";

type UseInputMenuProps<T> = {
  defaultSelectedItem?: T | null;
  selectedItem?: T | null;
  onSelectedItemChange?: (item: T | null) => void;
  items: T[];
  idAccessor?: (value: T) => string;
  isDisabled?: boolean;
};

type GetMenuItemPropsParams<T> = {
  item: T;
  index: number;
};

type GetMenuItemPropsReturn = {
  "aria-selected": boolean;
  "aria-checked": boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  cursor?: string;
};

type GetMenuPropsReturn<K extends HTMLElement> = {
  tabIndex?: number;
  ref: (node: Nullable<K>) => void;
};

type UseInputMenuReturn<T, K extends HTMLElement> = {
  selectedItem: T | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<T | null>>;
  getMenuItemProps: (
    params: GetMenuItemPropsParams<T>
  ) => GetMenuItemPropsReturn;
  getMenuProps: () => GetMenuPropsReturn<K>;
  highlightedIndex: number;
};

const defaultIdAccessor = <T>(value: T) => String(value);

/**
 * Hook for providing functionality without UI for menus that work as inputs
 * @param onSelectedItemChange - Function to trigger when selected item changes
 * @param selectedItem - Represents the currently selected item
 * @param items - Array of possible values for the menu
 * @param defaultSelectedItem - Item selected by default
 * @param idAccessor - Function used to determine which attribute uniquely
 * identifies each element. Its important for the case when the value is
 * an object.
 */
function useInputMenu<T, K extends HTMLElement>(
  props: UseInputMenuProps<T>
): UseInputMenuReturn<T, K> {
  const {
    onSelectedItemChange,
    selectedItem: selectedItemProp,
    items,
    defaultSelectedItem = null,
    idAccessor = defaultIdAccessor,
    isDisabled = false,
  } = props;
  const [prevIsDisabled, setPrevIsDisabled] = React.useState(isDisabled);
  const [selectedItem, setSelectedItem] = useControllableState<T | null>({
    value: selectedItemProp,
    onChange: onSelectedItemChange,
    defaultValue: defaultSelectedItem,
  });
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const menuOnKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const hasItems = items.length > 0;

      if (!hasItems) {
        return;
      }

      const { key } = e;
      const highlightedItem = items[highlightedIndex] || null;

      if (key === "ArrowUp") {
        setHighlightedIndex((x) => {
          if (x === -1) {
            return items.length - 1;
          }

          if (x === 0) {
            return x;
          }

          return x - 1;
        });
      } else if (key === "ArrowDown") {
        setHighlightedIndex((x) => {
          if (x === -1) {
            return 0;
          }

          if (x === items.length - 1) {
            return x;
          }

          return x + 1;
        });
      } else if (key === "Enter" && highlightedItem) {
        setSelectedItem(highlightedItem);
      }
    },
    [items, setHighlightedIndex, setSelectedItem, highlightedIndex]
  );
  const prevMenuOnKeyDown = usePrevious(menuOnKeyDown);
  const menuOnFocusOut = React.useCallback(() => {
    setHighlightedIndex(-1);
  }, []);

  const menuRef = React.useCallback(
    (node: K | null) => {
      if (!node) {
        return;
      }
      node.removeEventListener("keydown", prevMenuOnKeyDown);
      node.removeEventListener("keydown", menuOnKeyDown);
      node.removeEventListener("focusout", menuOnFocusOut);

      if (!isDisabled) {
        node.addEventListener("keydown", menuOnKeyDown);
        node.addEventListener("focusout", menuOnFocusOut);
      }
    },
    [menuOnKeyDown, prevMenuOnKeyDown, menuOnFocusOut, isDisabled]
  );

  const getMenuProps = React.useCallback(() => {
    const baseProps = {
      ref: menuRef,
    };

    if (isDisabled) {
      return baseProps;
    }

    return {
      ...baseProps,
      tabIndex: 1,
    };
  }, [menuRef, isDisabled]);

  const getMenuItemProps: UseInputMenuReturn<T, K>["getMenuItemProps"] =
    React.useCallback(
      (params: GetMenuItemPropsParams<T>) => {
        const { index, item } = params;
        const generalProps = {
          "aria-selected": index === highlightedIndex,
          "aria-checked":
            !!selectedItem && idAccessor(selectedItem) === idAccessor(item),
        };
        const interactiveProps = {
          onClick: () => {
            setSelectedItem(item);
          },
          onMouseEnter: () => setHighlightedIndex(index),
          onMouseLeave: () => setHighlightedIndex(-1),
        };

        if (isDisabled) {
          return generalProps;
        }

        return {
          ...generalProps,
          ...interactiveProps,
        };
      },
      [
        selectedItem,
        setSelectedItem,
        highlightedIndex,
        setHighlightedIndex,
        idAccessor,
        isDisabled,
      ]
    );

  // Reset highlighted index when isDisabled prop changes
  if (prevIsDisabled !== isDisabled) {
    setPrevIsDisabled(isDisabled);
    setHighlightedIndex(-1);
  }

  return {
    selectedItem,
    setSelectedItem,
    getMenuItemProps,
    getMenuProps,
    highlightedIndex,
  };
}

export type { UseInputMenuProps };
export { useInputMenu };
