import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const ResetPassword = createIcon({
  path: (
    <path
      d="M23.1429 8C20.8348 8 19 9.8348 19 12.1299C19 13.861 20.0373 15.3911 21.6582 16.007V22.4061C21.6582 22.5682 21.7101 22.6914 21.8268 22.8146L22.9095 23.9038C23.0197 24.014 23.2401 24.0464 23.3763 23.9103L25.4315 21.855C25.5677 21.7124 25.5612 21.5244 25.4315 21.3882L24.1543 20.1305L25.9048 18.3864C26.028 18.2568 26.028 18.0687 25.8919 17.9261L24.1608 16.1821C26.1642 15.3846 27.2793 13.9064 27.2793 12.1299C27.2793 9.84777 25.4315 8 23.1429 8ZM23.1429 11.7993C22.5399 11.7993 22.0602 11.313 22.0602 10.7165C22.0602 10.1136 22.5335 9.63382 23.1429 9.63382C23.7394 9.63382 24.2256 10.1136 24.2256 10.7165C24.2256 11.313 23.7394 11.7993 23.1429 11.7993Z M5.14624 13.326C3.98606 13.326 3.69602 14.1155 4.32445 15.0179L7.9339 20.142C8.46565 20.8833 9.2391 20.8671 9.75474 20.142L13.3642 15.0018C13.9765 14.1155 13.6865 13.326 12.5424 13.326H10.2059C11.6078 7.23502 17.022 2.75543 23.5641 2.75543C31.1697 2.75543 37.2607 8.83026 37.2607 16.4359C37.2607 24.0415 31.1697 30.1647 23.5641 30.1325C18.9717 30.1164 14.9433 27.8927 12.4779 24.4605C11.9784 23.7998 11.2211 23.5903 10.5443 23.9932C9.89976 24.396 9.72251 25.2823 10.2704 25.9913C13.332 30.1002 18.1338 32.8718 23.5641 32.8718C32.5394 32.8718 40 25.4112 40 16.4359C40 7.44449 32.5394 0 23.5641 0C15.6362 0 8.90071 5.78479 7.41826 13.326H5.14624Z"
      fill="currentColor"
    />
  ),
  viewBox: "4 0 36 33",
});

export { ResetPassword };
