import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { updateInProcessListItemCache } from "modules/in-process/utils";
import { EncounterAppointmentNotesForm } from "modules/reception/types";
import { useQueryClient } from "react-query";

function useUpdateApptNotes(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateApptNotes", { encounterId }],
    (payload: EncounterAppointmentNotesForm) =>
      axiosPatientInstance.put(
        `v1/InProcess/${encounterId}/appointment-notes`,
        payload
      ),
    {
      onSuccess: (_, { appointmentNotes }) => {
        queryClient.invalidateQueries([
          "inProcessEncounterApptNotes",
          { encounterId },
        ]);
        updateInProcessListItemCache(queryClient, encounterId, {
          hasAppointmentNotes: !!appointmentNotes,
        });
      },
    }
  );
}

export { useUpdateApptNotes };
