import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Scanner = createIcon({
  path: (
    <path
      d="M0 15.8449C0 18 1.13322 19.1231 3.31872 19.1231H20.6813C22.8668 19.1231 24 18 24 15.8449V12.3642C24 11.6459 23.7673 10.8567 23.2209 10.2698L19.4165 6.21248C19.1535 5.93929 18.9005 5.69646 18.6577 5.48398C18.8499 5.32209 19.0523 5.12985 19.285 4.89713L22.4823 1.66948C23.0691 1.07251 22.7454 0 21.7639 0H2.23609C1.25464 0 0.93086 1.07251 1.51771 1.66948L4.71501 4.89713C4.94772 5.12985 5.15008 5.32209 5.34233 5.48398C5.09949 5.68634 4.85666 5.93929 4.58347 6.21248L0.779089 10.2698C0.232715 10.8567 0 11.6459 0 12.3642V15.8449ZM2.47892 11.2513L6.09106 7.21416C6.60708 6.64755 6.9511 6.36425 7.50759 6.36425H16.4924C17.0489 6.36425 17.3929 6.64755 17.9089 7.21416L21.5211 11.2513C21.7639 11.5143 21.6324 11.9089 21.1872 11.9089H2.81282C2.37774 11.9089 2.24621 11.5143 2.47892 11.2513ZM6.1113 8.73187C6.20236 9.00506 6.48567 9.10624 6.74874 9.01518L9.84486 7.89207C10.118 7.80101 10.2597 7.52782 10.1585 7.26475C10.0675 6.99157 9.78415 6.89039 9.52108 6.98145L6.41484 8.10455C6.14165 8.20573 6.01012 8.4688 6.1113 8.73187ZM5.97976 10.5531C6.08094 10.8162 6.35413 10.9376 6.62732 10.8364L11.1298 9.18718C11.403 9.09612 11.5447 8.83305 11.4435 8.55986C11.3423 8.29679 11.0793 8.17538 10.8061 8.27656L6.29342 9.9258C6.02024 10.027 5.8887 10.2799 5.97976 10.5531ZM16.1585 15.4401C16.1585 15.1062 16.4216 14.8634 16.7757 14.8634H19.6088C19.9629 14.8634 20.2159 15.1062 20.2159 15.4401C20.226 15.7841 19.9528 16.027 19.6088 16.027H16.7757C16.4418 16.027 16.1585 15.7841 16.1585 15.4401Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 20",
});

export { Scanner };
