import * as React from "react";
import {
  Button,
  ButtonProps,
  useControllableState,
  useStyleConfig,
} from "@chakra-ui/react";

type ChartOptionProps = {
  label: string;
  value?: boolean;
  defaultValue?: boolean;
  onChange?: (value?: boolean) => void;
} & Omit<ButtonProps, "onChange" | "type" | "value">;

const ChartOption = React.forwardRef<HTMLButtonElement, ChartOptionProps>(
  (props, ref) => {
    const {
      label,
      value: valueProp,
      onChange,
      defaultValue,
      isDisabled,
      ...btnProps
    } = props;
    const [value, setValue] = useControllableState({
      value: valueProp,
      onChange,
      defaultValue,
    });
    const styles = useStyleConfig("ChartOption");

    return (
      <Button
        ref={ref}
        variant="label"
        aria-selected={!!value}
        onClick={() => setValue((oldValue) => !oldValue)}
        type="button"
        __css={styles}
        sx={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        isDisabled={isDisabled}
        {...btnProps}
      >
        {label}
      </Button>
    );
  }
);

ChartOption.displayName = "ChartOption";

export type { ChartOptionProps };
export { ChartOption };
