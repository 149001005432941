import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartType } from "types";

type FavoriteFilter = "All" | "Non_Favorite" | "Favorite";

type Chart = {
  title: string;
  isFavorite: boolean;
  code: string;
  id: string;
};

type UseChartsListParams = {
  type: ChartType;
  search?: string;
  favorite?: FavoriteFilter;
};

function useChartsList(params: UseChartsListParams) {
  const { type, search, favorite } = params;
  return useAxiosQuery(["chartsList", params], () =>
    axiosChartInstance.get<Chart[]>(`v1/Chart`, {
      params: {
        search: search || undefined,
        favoriteFilter: favorite || "All",
        chartType: type,
      },
    })
  );
}

export type { UseChartsListParams, Chart };
export { useChartsList };
