import { AxiosError, AxiosResponse } from "axios";
import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UseQueryOptions } from "react-query";

type EKGCountersResponse = {
  ordered: number;
  inProcess: number;
  completed: number;
};

function useEKGCounters(
  encounterId: string,
  options: UseQueryOptions<AxiosResponse<EKGCountersResponse>, AxiosError>
) {
  return useAxiosQuery(
    ["ekgCounters", { encounterId }],
    () =>
      axiosChartInstance.get<EKGCountersResponse>(
        `v1/chart/${encounterId}/ekg/counters`
      ),
    options
  );
}

export { useEKGCounters };
export type { EKGCountersResponse };
