import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGetValidNewTeamMembersResponse = {
  id: string;
  name: string;
  imageUrl: string;
  title: string;
};

function useGetValidNewTeamMembers(teamId: string, search: string) {
  return useAxiosQuery(
    ["validNewTeamMembers", { teamId, search }],
    () =>
      axiosIdentityInstance.get<UseGetValidNewTeamMembersResponse[]>(
        `/v1/team/${teamId}/non-members-min`,
        { params: { search } }
      ),
    { staleTime: 1000 * 60 * 4 }
  );
}

export type { UseGetValidNewTeamMembersResponse };
export { useGetValidNewTeamMembers };
