import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Tracking = createIcon({
  path: (
    <>
      <g filter="url(#filter0_d_1_432)">
        <path
          d="M11.8764 15.7375C7.5635 15.7375 4 12.1893 4 7.86112C4 3.54821 7.5635 0 11.8764 0C16.1893 0 19.7375 3.54821 19.7375 7.86112C19.7375 12.1587 16.1434 15.7375 11.8764 15.7375ZM8.23644 9.16111H11.9682C12.5646 9.16111 13.0235 8.70229 13.0235 8.09053V3.44115C13.0235 2.85998 12.5493 2.40116 11.9682 2.40116C11.3717 2.40116 10.8976 2.85998 10.8976 3.44115V7.03524H8.23644C7.63997 7.03524 7.16586 7.50936 7.16586 8.09053C7.16586 8.68699 7.62468 9.16111 8.23644 9.16111ZM20.9305 3.73174C29.0822 4.29762 35.628 11.2105 35.628 19.4693C35.628 20.4787 34.9245 21.0904 34.1445 21.0904C33.3186 21.0904 32.5233 20.494 32.5233 19.4693C32.5233 13.0764 27.8281 7.81524 21.6952 6.94348C21.6799 5.8729 21.374 4.69526 20.9305 3.73174ZM4.03059 19.4693C4.03059 17.8634 4.29059 16.3187 4.74941 14.8505C5.54469 15.6305 6.47763 16.2575 7.50233 16.6705C7.28821 17.5575 7.16586 18.5057 7.16586 19.4693C7.16586 24.241 9.78113 28.3857 13.4823 30.4657C15.4705 31.628 13.9258 34.228 11.9988 33.1421C7.44115 30.6339 4.03059 25.4033 4.03059 19.4693ZM19.294 31.0468L19.4622 18.7199C19.4775 18.0775 20.1505 17.8175 20.594 18.261L29.0975 26.948C29.5716 27.4527 29.2504 28.1257 28.5775 28.1257L25.381 28.2174L28.0575 34.4268C28.2104 34.748 28.1492 35.1762 27.7516 35.3444L26.1916 35.9562C25.8093 36.0939 25.4575 35.895 25.3045 35.528L22.7657 29.2421L20.5328 31.5209C20.0893 31.9798 19.2787 31.7351 19.294 31.0468Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_432"
          x="0"
          y="0"
          width="39.6281"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_432"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_432"
            result="shape"
          />
        </filter>
      </defs>
    </>
  ),
  viewBox: "0 0 40 44",
});

export { Tracking };
