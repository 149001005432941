import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { InsuranceType } from "modules/reception/types";
import { InsuranceEligibility } from "modules/reception/types/insurance-eligibility";

function useInsuranceEligibility(
  encounterId: string,
  insuranceType: InsuranceType
) {
  return useAxiosQuery(
    ["encounterInsuranceEligibility", { encounterId, insuranceType }],
    () =>
      axiosPatientInstance.get<InsuranceEligibility>(
        `v1/integration/eligibility`,
        {
          params: {
            encounterId: encounterId,
            insuranceType: insuranceType,
          },
        }
      )
  );
}

function useAllInsurancesEligibility(encounterId: string) {
  return useAxiosQuery(
    ["encounterInsurancesEligibility", { encounterId }],
    () =>
      axiosPatientInstance.get<[InsuranceEligibility]>(
        `v1/integration/eligibility/all`,
        {
          params: {
            encounterId: encounterId,
          },
        }
      )
  );
}

export { useInsuranceEligibility, useAllInsurancesEligibility };
