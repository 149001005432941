import {
  ChargeStatus,
  ChartProgressStatus,
  ContrastOption,
  EncounterDischargedSubstatus,
  EncounterProfileStatus,
  EncounterStatus,
  Ethnicity,
  Gender,
  HomeLocations,
  HowDidYouHearAboutUsOption,
  InsuranceGuarantorSameAsFrom,
  InsurancePaymentType,
  InsuranceStatus,
  LabOrderType,
  Locations,
  MaritalStatus,
  MedicationType,
  NursesScope,
  OrderFormElementCategory,
  OrderFormElementRoute,
  OrderFormElementType,
  OrderGroup,
  OrderStatus,
  OrderType,
  PatientSearchEncounterStatus,
  PaymentForm,
  PaymentType,
  ProvidersScope,
  Race,
  RadiologyOrderType,
  RelationToPatient,
  RelationToSubscriber,
  RoomTicketType,
  SignatureType,
  Title,
  UsState,
  UserSignatureType,
  VerificationMethod,
} from "enums";
import { EncounterUserType, Spo2Type, UserType } from "modules";

import { SelectOption } from "types";

type Enums = {
  title: Title;
  maritalStatus: MaritalStatus;
  race: Race;
  ethnicity: Ethnicity;
  gender: Gender;
  howDidTheyHearAboutUs: HowDidYouHearAboutUsOption;
  verificationMethod: VerificationMethod;
  usState: UsState;
  paymentForm: PaymentForm;
  paymentType: PaymentType;
  chargeStatus: ChargeStatus;
  encounterStatus: EncounterStatus;
  chartProgressStatus: ChartProgressStatus;
  patientSearchEncounterStatus: PatientSearchEncounterStatus;
  encounterProfileStatus: EncounterProfileStatus;
  insurancePaymentType: InsurancePaymentType;
  insuranceStatus: InsuranceStatus;
  relationToSubscriber: RelationToSubscriber;
  relationToPatient: RelationToPatient;
  insuranceGuarantorSameAsFrom: InsuranceGuarantorSameAsFrom;
  orderStatus: OrderStatus;
  ekgStatus: OrderStatus;
  orderType: OrderType;
  medicationType: MedicationType;
  orderGroup: OrderGroup;
  labOrderType: LabOrderType;
  radiologyOrderType: RadiologyOrderType;
  contrastOption: ContrastOption;
  userSignatureType: UserSignatureType;
  providersScope: ProvidersScope;
  nursesScope: NursesScope;
  locations: Locations;
  homeLocations: HomeLocations;
  userTypes: UserType;
  encounterUserTypes: EncounterUserType;
  orderFormElementType: OrderFormElementType;
  orderFormElementCategory: OrderFormElementCategory;
  signatureType: SignatureType;
  orderFormElementRoute: OrderFormElementRoute;
  roomTicketType: RoomTicketType;
  spo2Type: Spo2Type;
  encounterDischargedSubstatus: EncounterDischargedSubstatus;
};

type EnumsDisplay = { [Enum in keyof Enums]: Record<Enums[Enum], string> };

const ENUMS_DISPLAY: EnumsDisplay = {
  title: {
    Mr: "Mr",
    Mrs: "Mrs",
    Miss: "Miss",
    Ms: "Ms",
  },
  maritalStatus: {
    Married: "Married",
    Single: "Single",
    Divorced: "Divorced",
    Separated: "Separated",
    Widowed: "Widowed",
  },
  race: {
    AfricanAmerican: "African American",
    AlaskanNative: "Alaskan Native",
    AmericanIndian: "American Indian",
    Asian: "Asian",
    Caucasian: "Caucasian",
    PacificIslander: "Pacific Islander",
    Other: "Other",
  },
  ethnicity: {
    HispanicLatino: "Hispanic or Latino",
    NotHispanicLatino: "Not Hispanic or Latino",
    Other: "Other",
  },
  gender: {
    Male: "Male",
    Female: "Female",
    Undefined: "Undefined",
  },
  howDidTheyHearAboutUs: {
    DoctorsOffice: "Doctor's Office",
    Magazine: "Magazine",
    Hospital: "Hospital",
    DriveBy: "Drive By",
    Internet: "Internet",
    Billboard: "Billboard",
    Mailer: "Mailer",
    Newspaper: "Newspaper",
    Pharmacy: "Pharmacy",
    Radio: "Radio",
    TV: "TV",
    FriendOrFamily: "Friend/Family Member",
    Other: "Other",
  },
  verificationMethod: {
    ByPhone: "By Phone",
    Electronically: "Electronically",
  },
  usState: {
    AL: "AL",
    AK: "AK",
    AZ: "AZ",
    AR: "AR",
    CA: "CA",
    CO: "CO",
    CT: "CT",
    DE: "DE",
    DC: "DC",
    FL: "FL",
    GA: "GA",
    HI: "HI",
    ID: "ID",
    IL: "IL",
    IN: "IN",
    IA: "IA",
    KS: "KS",
    KY: "KY",
    LA: "LA",
    ME: "ME",
    MD: "MD",
    MA: "MA",
    MI: "MI",
    MN: "MN",
    MS: "MS",
    MO: "MO",
    MT: "MT",
    NE: "NE",
    NV: "NV",
    NH: "NH",
    NJ: "NJ",
    NM: "NM",
    NY: "NY",
    NC: "NC",
    ND: "ND",
    OH: "OH",
    OK: "OK",
    OR: "OR",
    PA: "PA",
    PR: "PR",
    RI: "RI",
    SC: "SC",
    SD: "SD",
    TN: "TN",
    TX: "TX",
    UT: "UT",
    VT: "VT",
    VA: "VA",
    VI: "VI",
    WA: "WA",
    WV: "WV",
    WI: "WI",
    WY: "WY",
  },
  paymentType: {
    CoPay: "CoPay",
    SelfPay: "Self Pay",
  },
  paymentForm: {
    Cash: "Cash",
    Check: "Check",
    CreditCard: "Credit Card",
    DebitCard: "Debit Card",
  },
  chargeStatus: {
    Accepted: "Accepted",
    BalanceDuePatient: "Blance due Patient",
    Collection: "Collection",
    RejectedAtClearingHouse: "Rejected at Clearing House",
    Delete: "Deleted",
    Incomplete: "Incomplete",
    OnHold: "On Hold",
    Paid: "Paid",
    PendingPatient: "Pending Patient",
    PendingPhysician: "Pending Physician",
    WaitingForReview: "Waiting for Review",
  },
  encounterStatus: {
    InProcess: "In Process",
    Discharged: "Discharged",
    Lobby: "Lobby",
    Observation: "Observation",
    Transfers: "Transfers",
    Draft: "Draft",
    ReceptionAudit: "Demographics Audit",
  },
  chartProgressStatus: {
    unchanged: "unchanged",
    improved: "improved",
    reExamined: "re-examined",
  },
  patientSearchEncounterStatus: {
    Closed: "Closed",
    Open: "Open",
  },
  encounterProfileStatus: {
    Completed: "Complete",
    Incompleted: "Incomplete",
  },
  insurancePaymentType: {
    CoPay: "Co-Pay",
    SelfPay: "Self Pay",
  },
  insuranceStatus: {
    Active: "Active",
    Inactive: "Inactive",
    UnableToVerify: "Unable To Verify",
  },
  relationToSubscriber: {
    Unknown: "Unknown",
    Spouse: "Spouse",
    Child: "Child",
    Self: "Self",
  },
  relationToPatient: {
    Associate: "Associate",
    Brother: "Brother",
    CareGiver: "Care Giver",
    Child: "Child",
    HandicappedDependent: "Handicapped Dependent",
    LifePartner: "Life Partner",
    EmergencyContact: "Emergency Contact",
    Employee: "Employee",
    Employer: "Employer",
    ExtendedFamily: "Extended Family",
    FosterChild: "Foster Child",
    Friend: "Friend",
    Grandchild: "Grandchild",
    Guardian: "Guardian",
    Grandparent: "Grandparent",
    Manager: "Manager",
    Mother: "Mother",
    NaturalChild: "Natural Child",
    None: "None",
    OtherAdult: "Other Adult",
    Other: "Other",
    Owner: "Owner",
    Parent: "Parent",
    StepChild: "Stepchild",
    Self: "Self",
    Sibling: "Sibling",
    Sister: "Sister",
    Spouse: "Spouse",
    Trainer: "Trainer",
    Unknown: "Unknown",
    WardOfCourt: "Ward of Court",
  },
  insuranceGuarantorSameAsFrom: {
    Manual: "Use Manual Info",
    Patient: "Use Patient Info",
    Subscriber: "Use Subscriber Info",
  },
  orderStatus: {
    Cancelled: "Cancelled",
    Completed: "Completed",
    InProcess: "In Process",
    Ordered: "Ordered",
    NeedsApproval: "Needs Approval",
    CancellationRequested: "Requested",
    Administered: "Administered",
  },
  ekgStatus: {
    Cancelled: "Cancelled",
    Completed: "Completed",
    InProcess: "In Review",
    Ordered: "Ordered",
    NeedsApproval: "Needs Approval",
    CancellationRequested: "Requested",
    Administered: "Administered",
  },
  labOrderType: {
    LabsOnSite: "Labs (On Site)",
    SendoutLabs: "Sendout Labs",
  },
  radiologyOrderType: {
    XRay: "X Ray",
    Ultrasound: "Ultrasound",
    CTScan: "CT Scan",
    CTScanContrast: "CT Scan Contrast",
  },
  orderType: {
    LabsOnSite: "Labs (On Site)",
    SendoutLabs: "Sendout Labs",
    XRay: "X Ray",
    Ultrasound: "Ultrasound",
    CTScan: "CT Scan",
    CTScanContrast: "CT Scan",
  },
  medicationType: {
    Medication: "Medication",
    Nebulizer: "Nebulizer",
    Nursing: "Nursing",
  },
  orderGroup: {
    Labs: "Labs",
    Radiology: "Radiology",
  },
  orderFormElementType: {
    Lab: "Labs",
    Medication: "Medications",
    Radiology: "Radiology",
    Treatment: "Treatments",
  },
  orderFormElementCategory: {
    CT_SCAN: "CT Scan",
    CT_SCAN_CONTRAST: "CT Scan Contrast",
    XRAY: "XRay",
    MRI: "MRI",
    Ultrasound: "Ultrasound",
  },
  contrastOption: {
    Iv: "IV",
    Oral: "Oral",
    NoContrast: "No Contrast",
    None: "None",
    DrinkAndScan: "Drink & Scan",
    NinetyMin: "90 minutes",
    Custom: "Custom",
  },
  userSignatureType: {
    ActingProvider: "Acting Provider",
    Nurse: "Nurse",
    Scribe: "Scribe",
    Supervisor: "Supervisor",
  },
  providersScope: {
    adddelete: "Add & Delete Charts",
    patientinfo: "Patient Info",
    mh: "Medical History",
    vitals: "Vitals",
    odh: "Order History",
    hpi: "HPI",
    ros: "ROS",
    pex: "Physical Exam",
    odf: "Orders",
    ivm: "Meds & IV",
    treatments: "Treatments",
    rad: "Radiology",
    labs: "Labs",
    ekg: "EKG",
    proc: "Procedures",
    prog: "Progress",
    mdm: "MDM",
    ci: "Clinical Impression",
    disp: "Disposition",
  },
  nursesScope: {
    adddelete: "Add & Delete Charts",
    patientinfo: "Patient Info",
    vitals: "Vitals",
    ai: "Arrival Info",
    cc: "Chief Complaint",
    mh: "Medical HX",
    assts: "Assessments",
    actions: "Actions",
    odf: "Orders",
    odh: "Order History",
    sup: "Supplies",
    ivm: "Meds & IV",
    rad: "Radiology",
    labs: "Labs",
    ekg: "EKG",
    proc: "Procedures",
    prog: "Progress",
    disp: "Disposition",
  },
  locations: {
    All: "All Locations",
    Galveston: "Galveston",
    Longview: "Longview",
    Tyler: "Tyler",
  },
  homeLocations: {
    Galveston: "Galveston",
    Longview: "Longview",
    Tyler: "Tyler",
  },
  userTypes: {
    Registrar: "Registrar",
    Nurse: "Nurse",
    Provider: "Provider",
    SupervisingProvider: "Supervising Provider",
    HUC: "HUC",
    Scribe: "Scribe",
    LabTechnician: "Lab Technician",
    RadiologyManager: "Radiology Manager",
    ITSupport: "IT Support",
    Auditor: "Auditor",
    Administrator: "Administrator",
    Other: "Other",
    MedicalDirector: "Medical Director",
    ERTech: "ER Tech",
    EVS: "EVS",
    FacilityMaintenance: "Facility Maintenance",
    Hospitality: "Hospitality",
    Kitchen: "Kitchen",
    MedSupply: "Med Supply",
    Myotherapy: "Myotherapy",
    Paramedic: "Paramedic",
    PatientFacilitator: "Patient Facilitator",
    Radiology: "Radiology",
    Valet: "Valet",
    Warehouse: "Warehouse",
  },
  encounterUserTypes: {
    Nurse: "Nurse",
    Provider: "Provider",
    Supervisor: "Supervisor",
    ChargeNurse: "Charge Nurse",
    AdmittingProvider: "Admitting Provider",
    ObservationProvider: "Observation Provider",
    HUC: "HUC",
    Scribe: "Scribe",
  },
  signatureType: {
    SupervisingProvider: "Supervising Provider",
    TreatingProvider: "Treating Provider",
    ChargeNurse: "Charge Nurse",
    Nurse: "Nurse",
    Scribe: "Scribe",
    HUC: "HUC",
  },
  orderFormElementRoute: {
    AD: "AD | Right Ear",
    AS: "AS | Left Ear",
    AU: "AU | Both Ears",
    HHN: "HHN | Handheld Nebulizer",
    ID: "ID | Intradermal Route",
    IM: "IM | Intramuscular Route",
    IT: "IT | Intrathecal Route",
    IV: "IV | IV Infusion",
    IVP: "IVP | Intravenous Push",
    IVPB: "IVPB | Intravenous Piggyback",
    NGT: "NGT | Nasogastric Tube",
    OD: "OD | Right Eye",
    OS: "OS | Left Eye",
    OU: "OU | Both Eyes",
    PO: "PO | Mouth",
    PR: "PR | Rectum",
    SQ: "SQ | Subcutaneous Route",
    SL: "SL | Sublingual Route",
    "S&S": "S&S | Swish and Swallow",
    PV: "PV | Vaginal Route",
  },
  roomTicketType: {
    Cleaning: "Cleaning",
    Maintenance: "Maintenance",
    Other: "Other",
  },
  spo2Type: {
    hf: "High Flow",
    m: "Mask",
    nc: "Nasal Canula",
    ra: "Room air",
  },
  encounterDischargedSubstatus: {
    LPT: "LPT",
    AMA: "AMA",
    Eloped: "Eloped",
    Transferred: "Transferred",
  },
};

function toDisplay<Enum extends keyof Enums, Value extends Enums[Enum]>(
  name: Enum,
  value: Value
) {
  const enumRecord = ENUMS_DISPLAY[name] as Record<Value, string>;
  return enumRecord[value];
}

function getOptions<Enum extends keyof Enums>(name: Enum) {
  return Object.entries(ENUMS_DISPLAY[name]).map(([k, v]) => ({
    value: k as Enums[Enum],
    label: v as string,
  }));
}

function valueToOption<Enum extends keyof Enums, Value extends Enums[Enum]>(
  name: Enum,
  value: Value
): SelectOption<Value> {
  return {
    value,
    label: toDisplay(name, value),
  };
}

export { toDisplay, getOptions, valueToOption };
