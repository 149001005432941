import { Box, BoxProps, Icon, IconButton } from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { AddRounded } from "icons";
import Konva from "konva";
import { formMessages } from "messages";
import * as React from "react";
import { extractApiErrorMessage } from "utils";
import { useDeleteDiagram, useSaveDiagrams } from "./api";
import { DiagramAction } from "./diagram-reducer";
import { Diagram } from "./types";

type DiagramCanvasPreviewProps = {
  diagram: Diagram;
  dispatch?: React.Dispatch<DiagramAction>;
  isSelected?: boolean;
  isActive?: boolean;
  encounterId: string;
  chartCode: string;
  addMode?: boolean;
  deleteMode?: boolean;
  onAddSuccess?: () => void;
} & BoxProps;

function DiagramCanvasPreview({
  isActive = false,
  diagram,
  dispatch,
  chartCode,
  encounterId,
  addMode,
  deleteMode,
  onAddSuccess,
  ...boxProps
}: DiagramCanvasPreviewProps) {
  const toast = useToast();

  const [previewImage, setPreviewImage] = React.useState("");

  const { thumbnailUrl, shapes, title } = diagram;

  const { mutateAsync: deleteDiagram, isLoading: isDeleteLoading } =
    useDeleteDiagram(encounterId, chartCode);

  const onDelete = async () => {
    try {
      if (diagram.id) {
        await deleteDiagram(diagram.id);
        toast({ description: formMessages.deleteSuccess("Diagram") });
      }
      dispatch?.({
        type: "DELETE_DIAGRAM",
        payload: diagram.id || diagram.imageCode,
      });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const { mutateAsync: saveDiagrams, isLoading: isSaveLoading } =
    useSaveDiagrams(encounterId, chartCode);

  const onAdd = async () => {
    try {
      const diagramPayload = {
        data: diagram.shapes,
        imageCode: diagram.imageCode,
        id: diagram.id,
      };

      await saveDiagrams(diagramPayload);
      onAddSuccess?.();
      toast({ description: formMessages.createSuccess("Diagram") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    const stage = new Konva.Stage({
      container: document.createElement("div"),
      width: 1000,
      height: 630,
    });
    const layer = new Konva.Layer();
    stage.add(layer);
    const rect = new Konva.Rect({ width: 1000, height: 630, fill: "white" });
    layer.add(rect);
    const imageObj = new Image();
    imageObj.onload = function () {
      const image = new Konva.Image({
        x: 200 / 2 - (imageObj?.width ?? 0) / 2,
        y: 30,
        image: imageObj,
        width: 1000,
        height: 600,
      });
      layer.add(image);

      // Insert shapes to layer after image is inserted to layer
      shapes.forEach((shape) => {
        const { config, type } = shape;

        if (type === "circle" || type === "curve_line") {
          const circle = new Konva.Circle(config);
          layer.add(circle);
        }

        if (type === "editable-text" || type === "text") {
          const text = new Konva.Text(config);
          layer.add(text);
        }

        if (type === "line") {
          const line = new Konva.Line(config);
          layer.add(line);
        }

        if (type === "arrow") {
          const arrow = new Konva.Arrow({
            ...config,
            points: config.points ?? [],
          });
          layer.add(arrow);
        }

        if (type === "ellipse") {
          const ellipse = new Konva.Ellipse({
            ...config,
            radiusX: config.radiusX ?? 100,
            radiusY: config.radiusY ?? 40,
          });
          layer.add(ellipse);
        }
      });

      const canvasPicture = stage.toDataURL({
        quality: 50,
        mimeType: "image/jpeg",
      });

      setPreviewImage(canvasPicture);
    };
    imageObj.src = thumbnailUrl;
  }, [thumbnailUrl, shapes]);

  if (previewImage) {
    return (
      <Box
        {...(addMode && {
          onClick: onAdd,
        })}
        {...boxProps}
      >
        <Box
          width="195px"
          height="130px"
          borderRadius="10px"
          overflow="hidden"
          border="1px solid"
          borderColor={isActive ? "blue" : "transparent"}
          position="relative"
        >
          <img
            src={previewImage}
            alt="canvas preview"
            style={{ width: "100%", height: "auto", objectFit: "fill" }}
          />
          {deleteMode && (
            <IconButton
              aria-label="delete diagram"
              variant="icon"
              icon={<Icon as={EmrRemove} color="red" />}
              position="absolute"
              top="11px"
              right="11px"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              isLoading={isDeleteLoading}
              isDisabled={isDeleteLoading}
            />
          )}
          {addMode && (
            <IconButton
              aria-label="add diagram"
              variant="icon"
              icon={<Icon as={AddRounded} color="blue" />}
              position="absolute"
              top="11px"
              right="11px"
              onClick={(e) => {
                e.stopPropagation();
                onAdd();
              }}
              isLoading={isSaveLoading}
              isDisabled={isSaveLoading}
            />
          )}
        </Box>
        <Box
          fontWeight="500"
          width="100%"
          textAlign="center"
          fontSize="0.9375rem"
        >
          {title}
        </Box>
      </Box>
    );
  }

  return null;
}

export { DiagramCanvasPreview };
