import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S009Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q009.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q009`}
          subsectionLabel="Feeding Tube Insertion"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Feeding Tube Insertion
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="Date & Time:"
              name={`${sectionDelimiter}Q009A001`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q009A002`}
              type="date"
              w="220px"
            />
            <ChartRowElement
              label="date & time OUT:"
              name={`${sectionDelimiter}Q009A003`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q009A004`}
              type="date"
              w="220px"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q009A005`}
              type="input"
            />
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Indication:"
                name={`${sectionDelimiter}Q009A006`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="dislodged"
                name={`${sectionDelimiter}Q009A007`}
                type="option"
              />
              <ChartRowElement
                label="malfunctioning"
                name={`${sectionDelimiter}Q009A008`}
                type="option"
              />
              <ChartRowElement
                label="G-tube"
                name={`${sectionDelimiter}Q009A009`}
                type="option"
              />
              <ChartRowElement
                label="J-tube"
                name={`${sectionDelimiter}Q009A010`}
                type="option"
              />
              <ChartRowElement
                label="nasal feeding tube"
                name={`${sectionDelimiter}Q009A011`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A012`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                type="option"
                name={`${sectionDelimiter}Q009A013`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="risks, benefits, alternatives explained to:"
                type="option"
                name={`${sectionDelimiter}Q009A014`}
              />
              <ChartRowElement
                label="patient"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q009A015`}
              />
              <ChartRowElement
                label="parent"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q009A016`}
              />
              <ChartRowElement
                label="guardian"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q009A017`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A018`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="topical anesthesia used:"
                name={`${sectionDelimiter}Q009A019`}
                type="option"
              />
              <ChartRowElement
                label="lidocaine gel"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A020`}
                type="option"
              />
              <ChartRowElement
                label="benzocaine spray"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A021`}
                type="option"
              />
              <ChartRowElement
                label="chlorhexidine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A022`}
                type="option"
              />
              <ChartRowElement
                label="tube size:"
                name={`${sectionDelimiter}Q009A023`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A024`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Procedure:"
                type="option"
                name={`${sectionDelimiter}Q009A025`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="successful"
                type="option"
                name={`${sectionDelimiter}Q009A026`}
              />
              <ChartRowElement
                label="unsuccessful"
                type="option"
                name={`${sectionDelimiter}Q009A027`}
              />
              <ChartRowElement
                label="performed by:"
                fontStyle="underline"
                type="option"
                name={`${sectionDelimiter}Q009A028`}
              />
              <ChartRowElement
                label="me"
                type="option"
                name={`${sectionDelimiter}Q009A029`}
              />
              <ChartRowElement
                label="ED physician"
                type="option"
                name={`${sectionDelimiter}Q009A030`}
              />
              <ChartRowElement
                label="PA"
                type="option"
                name={`${sectionDelimiter}Q009A031`}
              />
              <ChartRowElement
                label="nurse"
                type="option"
                name={`${sectionDelimiter}Q009A032`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A033`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="tube inserted to:"
                name={`${sectionDelimiter}Q009A034`}
                type="option"
              />
              <ChartRowElement
                label="abdominal stoma"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A035`}
                type="option"
              />
              <ChartRowElement
                label="oropharynx"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A036`}
                type="option"
              />
              <ChartRowElement
                label="L"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A037`}
                type="option"
              />
              <ChartRowElement
                label="R"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A038`}
                type="option"
              />
              <ChartRowElement
                label="nostril"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A039`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A040`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="no sig resistance met"
                name={`${sectionDelimiter}Q009A041`}
                type="option"
              />
              <ChartRowElement
                label="confirmed placement by:"
                name={`${sectionDelimiter}Q009A042`}
                type="option"
              />
              <ChartRowElement
                label="aspiration"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A043`}
                type="option"
              />
              <ChartRowElement
                label="Xray"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A044`}
                type="option"
              />
              <ChartRowElement
                label="auscilation"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A045`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A046`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="secured w/:"
                name={`${sectionDelimiter}Q009A047`}
                type="option"
              />
              <ChartRowElement
                label="tape"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A048`}
                type="option"
              />
              <ChartRowElement
                label="suture"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A049`}
                type="option"
              />
              <ChartRowElement
                label="dressing"
                fontStyle="italic"
                name={`${sectionDelimiter}Q009A050`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A051`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Complications:"
                type="option"
                name={`${sectionDelimiter}Q009A052`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="none"
                type="option"
                name={`${sectionDelimiter}Q009A053`}
              />
              <ChartRowElement
                label="bleeding"
                type="option"
                name={`${sectionDelimiter}Q009A054`}
              />
              <ChartRowElement
                label="vomiting"
                fontStyle="underline"
                type="option"
                name={`${sectionDelimiter}Q009A055`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q009A056`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q009A057`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S009Procedure };
