import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGetEnabledUsersMinResponse = {
  id: string;
  name: string;
  imageUrl: string;
  title: string;
};

function useGetEnabledUsersMin(searchString: string) {
  return useAxiosQuery(
    ["enabledUsersMin", { searchString }],
    () =>
      axiosIdentityInstance.get<UseGetEnabledUsersMinResponse[]>(
        "/v1/user/enabled-users/min",
        { params: { search: searchString } }
      ),
    { staleTime: 1000 * 60 * 4 }
  );
}

export type { UseGetEnabledUsersMinResponse };
export { useGetEnabledUsersMin };
