import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { Loading } from "shared/loading";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { useChartForm } from "modules";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SCHC";
const chartCode = "T040x";

function N40ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="started:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A059`}
                />
                <ChartRowElement
                  label="occurred:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A060`}
                />
                <ChartRowElement
                  label="just PTA"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  w="413px"
                  type="input"
                />
                <ChartRowElement
                  label="last known well date & time:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A061`}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="seizure"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="x1"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="x2"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="x3"
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="witnessed"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="post ictal on arrival"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="seizing on arrival"
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="lost consciousness"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="unresponsive motor activity"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tonic"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="clonic"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="focal"
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="precipitating factors:"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A062`}
                />
                <ChartRowElement
                  label="recent alcohol intake"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="recent drug intake"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sleep deprivation"
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="change in meds or dosage"
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="recent illness"
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="missed dose of seizure meds"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="headache"
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="numbness"
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dizziness"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="incontinence"
                  name={`Q001.${chartCode}${sectionId}Q001A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="nausea"
                  name={`Q001.${chartCode}${sectionId}Q001A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="vomiting x"
                  name={`Q001.${chartCode}${sectionId}Q001A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A039`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="mental status change"
                  name={`Q001.${chartCode}${sectionId}Q001A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A042`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="fatigue"
                  name={`Q001.${chartCode}${sectionId}Q001A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="weakness"
                  name={`Q001.${chartCode}${sectionId}Q001A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="visual change"
                  name={`Q001.${chartCode}${sectionId}Q001A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="visual disturbance"
                  name={`Q001.${chartCode}${sectionId}Q001A047`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A048`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="seizure activity"
                  name={`Q001.${chartCode}${sectionId}Q001A049`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="difficulty walking"
                  name={`Q001.${chartCode}${sectionId}Q001A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="difficulty swallowing"
                  name={`Q001.${chartCode}${sectionId}Q001A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A053`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chemical exposure"
                  name={`Q001.${chartCode}${sectionId}Q001A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="CO exposure"
                  name={`Q001.${chartCode}${sectionId}Q001A055`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A056`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q001.${chartCode}${sectionId}Q001A057`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A058`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="head"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neck"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="nose"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lip"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mouth"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bit tongue"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="chest"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="abdomen"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="back"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="LUE"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUE"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLE"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLE"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N40ChiefComplaint };
