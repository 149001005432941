import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";

type ChartTrackingItem = {
  encounterId: string;
  type: "application" | "shared";
  code: string;
  action: "updated" | "removed" | "added";
  previousValue: unknown;
  newValue: unknown;
  chartCode?: string;
  sectionId?: string;
  label: string;
};

function useTrackChartInteractions() {
  return useAxiosMutation(
    ["trackChartInteractions"],
    (payload: ChartTrackingItem[]) =>
      axiosChartInstance.post<null>(`v1/Chart/track-interactions`, payload)
  );
}

export { useTrackChartInteractions };
export type { ChartTrackingItem };
