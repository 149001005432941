import {
  mapFormObjectToAnswersList,
  mapAnswersListToFormObject,
} from "modules";
import {
  ActionsForm,
  ActionsPayload,
  ActionsRow,
  ActionsSection,
} from "../types";
import { ChartAnswer } from "types";

const filterSection: (
  actionRow: {
    index: number;
    date: Date | undefined;
    answers: ChartAnswer[];
  },
  index: number
) => boolean = ({ date, answers }) => !!date || !!answers.length;

const sectionToPayload = (
  {
    date,
    id: _id,
    createdById: _createdById,
    createdByFullName: _createdByFullName,
    createdByPictureUrl: _createdByPictureUrl,
    createdDate: _createdDate,
    ...answersForm
  }: ActionsRow,
  index: number
) => ({
  index,
  date,
  answers: mapFormObjectToAnswersList(answersForm),
});

function formToPayload(form: ActionsForm): ActionsPayload {
  const { initialActionsSections, actionsSections } = form;

  return {
    initialActionsSections: initialActionsSections
      .map(sectionToPayload)
      .filter(filterSection),
    actionsSections: actionsSections
      .map(sectionToPayload)
      .filter(filterSection),
  };
}

function mergeSections(sections: ActionsSection[]) {
  const maxIndex = Math.max(...sections.map(({ index }) => index));
  const filledSections = Array.from({ length: maxIndex + 1 }, (_, i) => {
    const found = sections.find((obj) => obj.index === i);
    return (
      found || {
        date: undefined,
        answers: [],
        createdBy: undefined,
      }
    );
  });

  return filledSections.map(({ date, answers, createdBy }) => ({
    date: date && new Date(date),
    createdByFullName:
      createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
    createdByPictureUrl: createdBy && createdBy.pictureUrl,
    ...mapAnswersListToFormObject(answers),
  }));
}

function responseToForm(response: ActionsPayload): ActionsForm {
  const { initialActionsSections, actionsSections } = response;

  const form = {
    initialActionsSections: mergeSections(initialActionsSections) || [],
    actionsSections: mergeSections(actionsSections) || [],
  };

  return form;
}

export { formToPayload, responseToForm };
