import axios, { AxiosRequestConfig } from "axios";
import config from "./config";

const baseConfig: AxiosRequestConfig = {
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
};

const axiosChartInstance = axios.create({
  baseURL: config.chartApiBaseUrl,
  ...baseConfig,
});

const axiosIdentityInstance = axios.create({
  baseURL: config.identityApiBaseUrl,
  ...baseConfig,
});

const axiosPatientInstance = axios.create({
  baseURL: config.patientApiBaseUrl,
  ...baseConfig,
});

const axiosFacilityInstance = axios.create({
  baseURL: config.facilityApiBaseUrl,
  ...baseConfig,
});

const axiosTimerInstance = axios.create({
  baseURL: config.timerApiBaseUrl,
  ...baseConfig,
});

const axiosOrderInstance = axios.create({
  baseURL: config.orderApiBaseUrl,
  ...baseConfig,
});

const axiosSLPatientInstance = axios.create({
  baseURL: config.SLPatientApiBaseUrl,
  withCredentials: false,
});

const axiosInstances = {
  axiosChartInstance,
  axiosIdentityInstance,
  axiosPatientInstance,
  axiosFacilityInstance,
  axiosTimerInstance,
  axiosOrderInstance,
  axiosSLPatientInstance,
};

export {
  axiosChartInstance,
  axiosIdentityInstance,
  axiosPatientInstance,
  axiosFacilityInstance,
  axiosTimerInstance,
  axiosOrderInstance,
  axiosSLPatientInstance,
};
export default axiosInstances;
