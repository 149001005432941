import { useAxiosMutation } from "hooks";
import { axiosChartInstance } from "config";
import { useQueryClient } from "react-query";

type SignedSection = "Discharge" | "Observation" | "Ama" | "Transfer";

type CreateSignedSectionPayload = { type: SignedSection; input: FormData };

function useCreateSignedSection(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createSignedSection", { encounterId }],
    ({ type, input }: CreateSignedSectionPayload) =>
      axiosChartInstance.post(
        `v1/sectionSummary/${encounterId}/signed-section/${type}`,
        input
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["signedSectionsFiles", { encounterId }]),
    }
  );
}

export type { SignedSection };
export { useCreateSignedSection };
