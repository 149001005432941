import * as React from "react";
import { useToast as useCkToast, UseToastOptions } from "@chakra-ui/react";

const toastInitialOptions: UseToastOptions = {
  variant: "solid",
  position: "bottom",
};

function useToast(props?: UseToastOptions) {
  const toastProps = React.useMemo(() => {
    if (!props) {
      return toastInitialOptions;
    }
    return { ...toastInitialOptions, ...props };
  }, [props]);

  return useCkToast(toastProps);
}

export { useToast };
