import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteEncounterPayment(encounterId: string, paymentId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteEncounterPayment", { encounterId, paymentId }],
    () =>
      axiosPatientInstance.delete<string>(
        `v1/encounter/${encounterId}/form/payment/${paymentId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterPayments", { encounterId }]);
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
        queryClient.invalidateQueries(["encounterOverview", { encounterId }]);
      },
    }
  );
}

export { useDeleteEncounterPayment };
