import { KonvaEventObject, Node, NodeConfig } from "konva/lib/Node";
import {
  TransformerConfig,
  Transformer as TransformerType,
} from "konva/lib/shapes/Transformer";
import * as React from "react";
import { KonvaNodeEvents, Transformer } from "react-konva";
import { DiagramAction } from "./diagram-reducer";

type WithTransformerProps = React.PropsWithChildren<{
  isSelected: boolean;
  shapeRef: React.RefObject<Node<NodeConfig>>;
  dispatch: React.Dispatch<DiagramAction>;
}> &
  TransformerConfig &
  KonvaNodeEvents;

function WithTransformer(props: WithTransformerProps) {
  const { isSelected, children, shapeRef, dispatch, ...transformerConfig } =
    props;
  const transformerRef = React.useRef<TransformerType>(null);
  const [startPosition, setStartPosition] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  const onTransformEnd = () => {
    const node = shapeRef.current;

    if (node) {
      const id = node.id();
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      node.scaleX(1);
      node.scaleY(1);
      dispatch({
        type: "EDIT_SHAPE",
        payload: {
          x: node.x(),
          y: node.y(),
          width: node.width() * scaleX,
          height: node.height() * scaleY,
          id,
        },
      });
    }
  };

  React.useEffect(() => {
    if (isSelected && shapeRef.current) {
      transformerRef.current?.nodes([shapeRef.current]);
      transformerRef.current?.getLayer()?.batchDraw();
    }
  }, [isSelected, shapeRef]);

  const handleDragStart = React.useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      setStartPosition({
        x: e.currentTarget.x(),
        y: e.currentTarget.y(),
      });
    },
    []
  );
  const handleDragEnd = React.useCallback(() => {
    const node = shapeRef.current;

    if (!node || !startPosition) {
      return;
    }
    const id = node.id();
    dispatch({
      type: "RECORD_DRAG",
      payload: {
        id: id,
        x: startPosition.x,
        y: startPosition.y,
      },
    });
    setStartPosition(null);
  }, [startPosition, dispatch, shapeRef]);

  return (
    <>
      {children}
      {isSelected && (
        <Transformer
          ref={transformerRef}
          onTransformEnd={onTransformEnd}
          {...transformerConfig}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      )}
    </>
  );
}

export { WithTransformer };
export type { WithTransformerProps };
