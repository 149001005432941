import * as React from "react";
import {
  Box,
  BoxProps,
  Button,
  Icon,
  IconButton,
  HStack,
  Flex,
  chakra,
} from "@chakra-ui/react";
import { usePagination, useToast } from "hooks";
import {
  Column,
  HeaderGroup,
  TableOptions,
  useSortBy,
  useTable,
} from "react-table";
import { OrderGroup } from "enums";
import { Card, InfiniteList } from "shared";
import { OrdersNavigationBar } from "modules/orders";
import { getValueByOrderGroup } from "modules/orders/helpers";
import { useNavigate } from "react-router-dom";
import { SiChevronLeft, SiSearch } from "@medstonetech/slate-icons";
import {
  LabOrderFilters,
  useSearchOrders,
  SearchOrdersListItem,
} from "modules/orders/api";
import { DownArrowFilled, UpArrowFilled } from "icons";
import { extractApiErrorMessage } from "utils";
import { AnyObject } from "types";
import { OrdersSearchCard } from "./OrdersSearchCard";
import { OrdersSearchItemCard } from "./OrdersSearchItemCard";

type OrdersSearchHeaderItemProps<T extends AnyObject> = BoxProps & {
  header: HeaderGroup<T>;
};

type StyledColumn = Column<SearchOrdersListItem> & {
  styles: BoxProps;
};

type OrdersSearchProps = {
  orderGroup: OrderGroup;
};

function OrdersSearchHeaderItem<T extends AnyObject>(
  props: OrdersSearchHeaderItemProps<T>
) {
  const { header, ...thProps } = props;
  const headerProps = header.getHeaderProps(header.getSortByToggleProps());
  return (
    <Flex
      fontSize="0.9375rem"
      minWidth="90px"
      padding="20px 0"
      justifyContent="start"
      alignItems="center"
      {...headerProps}
      {...thProps}
    >
      <>
        {header.render("Header")}
        {header.isSorted ? (
          <>
            {header.isSortedDesc ? (
              <Icon
                as={DownArrowFilled}
                fontSize="0.625rem"
                color="gray.450"
                marginLeft="14px"
              />
            ) : (
              <Icon
                as={UpArrowFilled}
                fontSize="0.625rem"
                color="gray.450"
                marginLeft="14px"
              />
            )}
          </>
        ) : null}
      </>
    </Flex>
  );
}

function OrdersSearch(props: OrdersSearchProps) {
  const { orderGroup } = props;
  const toast = useToast();
  const navigate = useNavigate();
  const routeTo = getValueByOrderGroup<string>(
    "lab-orders",
    "radiology-orders"
  )[orderGroup];

  const [search, setSearch] = React.useState<LabOrderFilters>({});
  const {
    setSort,
    state: { sort },
  } = usePagination();

  const { data, isLoading, fetchNextPage, hasNextPage, error } =
    useSearchOrders({
      descending: sort?.desc,
      sort: sort?.id,
      orderGroup,
      ...search,
    });

  const fetchMore = React.useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  const hasData = data && data.pages.length > 0;
  const showNoResults = !isLoading && !hasData;

  const onSearch = React.useCallback((newValues: LabOrderFilters) => {
    setSearch((prev) => ({
      ...prev,
      ...newValues,
    }));
  }, []);

  const COLUMNS: StyledColumn[] = React.useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "orderId",
        styles: { width: "100px", minWidth: "100px", paddingLeft: "30px" },
      },
      {
        Header: "Status",
        accessor: "status",
        styles: { width: "120px", minWidth: "120px" },
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        styles: { width: "180px", minWidth: "180px" },
      },
      {
        Header: "Patient",
        accessor: "patient",
        styles: { width: "320px", minWidth: "320px" },
      },
      {
        Header: getValueByOrderGroup<string>(
          "Order Description",
          "Description"
        )[orderGroup],
        accessor: "description",
        styles: { flex: "1", minWidth: "200px" },
      },
      ...getValueByOrderGroup<StyledColumn[]>(
        [],
        [
          {
            Header: "Contrast",
            accessor: "contrast",
            styles: { width: "120px", minWidth: "120px" },
          },
        ]
      )[orderGroup],
      {
        Header: "Ordered By",
        accessor: "orderedBy",
        styles: { width: "250px", minWidth: "250px", paddingRight: "30px" },
      },
    ],
    [orderGroup]
  );

  const tableOptions: TableOptions<SearchOrdersListItem> = React.useMemo(() => {
    return {
      columns: COLUMNS.map(
        ({ styles, ...rest }) => rest as Column<SearchOrdersListItem>
      ),
      data: [],
      manualSortBy: true,
    };
  }, [COLUMNS]);

  const {
    headerGroups,
    state: { sortBy },
  } = useTable(tableOptions, useSortBy);

  React.useEffect(() => {
    setSort(sortBy[0]);
  }, [sortBy, setSort]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <Box backgroundColor="#f2f2f7" height="100vh" overflow="hidden">
      <OrdersNavigationBar
        title={
          getValueByOrderGroup<string>("Lab Search", "Radiology Search")[
            orderGroup
          ]
        }
        leftActions={[
          <Button
            fontSize="1.125rem"
            key="backButton"
            size="iconSm"
            minHeight="42px"
            padding="10px"
            variant="ghost"
            color="blue"
            onClick={() => {
              navigate(routeTo);
            }}
            leftIcon={<Icon as={SiChevronLeft} fontSize="1.125rem" />}
          >
            Back
          </Button>,
        ]}
        rightActions={[
          <IconButton
            key="searchButton"
            size="iconSm"
            aria-label="Search"
            backgroundColor="blue"
            color="white"
            height="40px"
            width="40px"
            padding="10px"
            icon={
              <Icon as={SiSearch} color="white" height="20px" width="20px" />
            }
          />,
        ]}
      />
      <Box height="calc(100% - 80px)" overflow="auto">
        <Flex direction="column" height="100%">
          <OrdersSearchCard
            orderGroup={orderGroup}
            onSearch={onSearch}
            padding="30px 40px"
          />
          <Flex direction="column" flex="1" minHeight="400px" padding="0 24px">
            <chakra.h2
              fontWeight="500"
              fontSize="1.5rem"
              lineHeight="1.75rem"
              marginLeft="1rem"
            >
              Search Results
            </chakra.h2>
            <Card
              margin="8px 16px 10px 16px"
              backgroundColor="gray.50"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
              borderRadius="10px"
            >
              <Box overflowX="auto" overflowY="hidden">
                {headerGroups.map((headerGroup) => {
                  const { key: headerGroupKey, ...headerGroupProps } =
                    headerGroup.getHeaderGroupProps();

                  return (
                    <HStack
                      key={headerGroupKey}
                      {...headerGroupProps}
                      spacing="30px"
                    >
                      {headerGroup.headers.map((header, index) => {
                        const { key: headerKey } = header.getHeaderProps(
                          header.getSortByToggleProps()
                        );
                        return (
                          <OrdersSearchHeaderItem
                            {...COLUMNS[index].styles}
                            key={headerKey}
                            header={header}
                          />
                        );
                      })}
                    </HStack>
                  );
                })}
              </Box>
            </Card>
            {showNoResults ? (
              <Box width="100%">
                <Box
                  textAlign="center"
                  fontSize="1.65rem"
                  fontWeight="bold"
                  color="gray.650"
                >
                  No results
                </Box>
              </Box>
            ) : (
              <InfiniteList
                fetchMore={fetchMore}
                hasMore={!!hasNextPage}
                isLoading={isLoading}
                renderRow={(orderItem) => (
                  <OrdersSearchItemCard
                    orderGroup={orderGroup}
                    orderItem={orderItem}
                    onClick={() => {
                      navigate(
                        `/${orderGroup.toLowerCase()}/${routeTo}/${
                          orderItem.encounterId
                        }/order/${orderItem.id}`
                      );
                    }}
                  />
                )}
                rows={
                  data?.pages.reduce<SearchOrdersListItem[]>(
                    (accum, curr) => [...accum, ...curr.data.content],
                    []
                  ) || []
                }
                spacing="10px"
                padding="0 16px"
              />
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export { OrdersSearch };
