import * as React from "react";
import { AnyObject } from "types";

type UsePaginatedSelectedRowsProps<T> = {
  getRowId: (x: T) => string;
};

function usePaginatedSelectedRows<T extends AnyObject>(
  props: UsePaginatedSelectedRowsProps<T>
) {
  const { getRowId } = props;
  const [selectedRows, setSelectedRows] = React.useState<Map<string, T>>(
    new Map()
  );

  const toggleRowSelected = React.useCallback(
    (row: T, set: boolean) =>
      setSelectedRows((xs) => {
        const map = new Map(xs);

        if (set) {
          map.set(getRowId(row), row);
          return map;
        }

        map.delete(getRowId(row));
        return map;
      }),
    [getRowId]
  );

  const getToggleAllRowsSelectedProps = React.useCallback(
    (rows: T[]) => {
      const everyRowSelected = rows.every(
        (x) => !!selectedRows.has(getRowId(x))
      );

      return {
        indeterminate: !everyRowSelected && selectedRows.size > 0,
        checked: everyRowSelected && selectedRows.size > 0,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target;

          if (checked) {
            setSelectedRows((xs) => {
              const map = new Map(xs);
              rows?.forEach((x) => map.set(getRowId(x), x));
              return map;
            });
          } else {
            setSelectedRows((xs) => {
              const map = new Map(xs);
              rows?.forEach((x) => map.delete(getRowId(x)));
              return map;
            });
          }
        },
      };
    },
    [getRowId, selectedRows]
  );

  const getToggleRowSelectedProps = React.useCallback(
    (row: T) => {
      return {
        checked: !!selectedRows.get(getRowId(row)),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target;

          if (checked) {
            setSelectedRows((xs) => {
              const map = new Map(xs);
              map.set(getRowId(row), row);
              return map;
            });
          } else {
            setSelectedRows((xs) => {
              const map = new Map(xs);
              map.delete(getRowId(row));
              return map;
            });
          }
        },
      };
    },
    [getRowId, selectedRows]
  );

  const value = React.useMemo(
    () => ({
      toggleRowSelected,
      selectedRows,
      getToggleAllRowsSelectedProps,
      getToggleRowSelectedProps,
    }),
    [
      toggleRowSelected,
      selectedRows,
      getToggleAllRowsSelectedProps,
      getToggleRowSelectedProps,
    ]
  );

  return value;
}

export type { UsePaginatedSelectedRowsProps };
export { usePaginatedSelectedRows };
