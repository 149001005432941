import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteFaq(id: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteFaq", { id }],
    () => axiosFacilityInstance.delete(`/v1/faq/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("faqs");
      },
    }
  );
}

export { useDeleteFaq };
