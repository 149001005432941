import { UseGetUserResponse } from "api";
import { axiosIdentityInstance } from "config";
import { RoomTicketStatus, RoomTicketType } from "enums";
import { useAxiosQuery } from "hooks";

type TicketDto = {
  id: string;
  userId: string;
  roomId: string;
  number: number;
  description: string;
  reportedAt: Date;
  type: RoomTicketType;
  status: RoomTicketStatus;
  user?: UseGetUserResponse;
};

function useTicketsByRoom(roomId: string) {
  return useAxiosQuery(["ticketsByRoom", { roomId }], () => {
    return axiosIdentityInstance.get<TicketDto[]>("v1/ticket/by-room", {
      params: { roomId },
    });
  });
}

export { useTicketsByRoom };
export type { TicketDto };
