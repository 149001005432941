import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S003Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q003.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q003`}
          subsectionLabel="Burn Care"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Burn Care
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="cool soaks"
              name={`${sectionDelimiter}Q003A001`}
              type="option"
            />
            <ChartRowElement
              label="sterile towels"
              name={`${sectionDelimiter}Q003A002`}
              type="option"
            />
            <ChartRowElement
              label="debrided"
              name={`${sectionDelimiter}Q003A003`}
              type="option"
            />
            <ChartRowElement
              label="antibiotic ointment"
              name={`${sectionDelimiter}Q003A004`}
              type="option"
            />
            <ChartRowElement
              label="dressed"
              name={`${sectionDelimiter}Q003A005`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q003A006`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q003A007`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S003Procedure };
