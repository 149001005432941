import { useAxiosMutation } from "hooks";
import { axiosPatientInstance } from "config";
import { useQueryClient } from "react-query";

function useDeleteStpVipNote(
  noteId: string,
  encounterId: string,
  type: string
) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteStpVipNote", { noteId, encounterId }],
    () => axiosPatientInstance.delete(`v1/notes/${noteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "stpVipNotes",
          { patientId: encounterId, type },
        ]);
      },
    }
  );
}

export { useDeleteStpVipNote };
