import { AxiosResponse } from "axios";
import { EncounterStatus } from "enums";
import {
  IntegrationResponse,
  useUpdateEncounterIntegrations,
} from "modules/reception/api";
import { extractApiErrorMessage } from "utils";
import { useToast } from "hooks";

function useUpdateIntegrationsStatus(encounterId: string, integration: string) {
  const toast = useToast();
  const { mutateAsync, isLoading } =
    useUpdateEncounterIntegrations(encounterId);

  const updateIntegrationStatus = (newStatus: EncounterStatus) => {
    if (newStatus === "InProcess") {
      mutateAsync(encounterId)
        .then((integrationResponse: AxiosResponse<IntegrationResponse>) => {
          if (integration === "ayva") {
            if (
              !integrationResponse?.data?.ayvaResponse?.sentAYVAPatientInd ||
              !integrationResponse?.data?.ayvaResponse?.sentAYVAEncounterInd
            ) {
              const ayvaResponse = !(
                integrationResponse?.data?.ayvaResponse?.sentAYVAPatientInd &&
                integrationResponse?.data?.ayvaResponse?.sentAYVAEncounterInd
              )
                ? "AYVA Error - Error generating ayva connection"
                : "";
              toast({
                description: `Integrations Request Error: ${ayvaResponse}`,
              });
            }
          }
          if (integration === "ramsoft") {
            if (
              !integrationResponse?.data?.ramsoftResponse?.sentHL7ADTMessageInd
            ) {
              const ramsoftResponse = !integrationResponse?.data
                ?.ramsoftResponse?.sentHL7ADTMessageInd
                ? "Ramsoft Error - Error generating ramsoft connection"
                : "";
              toast({
                description: `Integrations Request Error: ${ramsoftResponse}`,
              });
            }
          } else {
            toast({
              description: "Integrations Request Success",
            });
          }
        })
        .catch((integrationsError: unknown | Error) => {
          toast({ description: extractApiErrorMessage(integrationsError) });
        });
    }
  };

  return {
    isLoading,
    updateIntegrationStatus,
  };
}

export { useUpdateIntegrationsStatus };
