import { axiosSLPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { StpVipCounters } from "modules/stp-vip/types";

function useStpVipCounters() {
  return useAxiosQuery(["stpVipCounters"], () =>
    axiosSLPatientInstance.get<StpVipCounters>(`v1/patient/counters`, {})
  );
}

export { useStpVipCounters };
