import * as React from "react";
import { AvatarProps, Avatar, Box } from "@chakra-ui/react";
import { useEncounterProfilePicture } from "modules/reception/api";
import { useFileUrl } from "hooks";

type UserAvatarProps = {
  encounterId: string;
  icon?: React.ReactElement;
  indicatorColor?: string;
  pictureIndicatorColor?: string;
  stp?: boolean;
} & AvatarProps;

function EncounterAvatar(props: UserAvatarProps) {
  const {
    encounterId,
    icon,
    indicatorColor,
    pictureIndicatorColor,
    stp,
    size,
    ...avatarProps
  } = props;
  const { data: file } = useEncounterProfilePicture(encounterId);

  const fileUrl = useFileUrl(file?.data);

  return (
    <Avatar
      position="relative"
      src={fileUrl}
      size={size}
      {...avatarProps}
      {...(!!pictureIndicatorColor && {
        borderWidth: size === "lg" ? "5px" : "3px",
        borderStyle: "solid",
        borderColor: `${pictureIndicatorColor}`,
      })}
    >
      {icon && (
        <Box
          position="absolute"
          borderRadius="5px"
          width={size === "lg" ? "1.75rem" : "1.35rem"}
          height={size === "lg" ? "1.75rem" : "1.35rem"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          top={0}
          right={size === "lg" ? 2 : -1}
          {...(!!indicatorColor && { bgColor: indicatorColor })}
        >
          {React.cloneElement(icon, {
            fontSize: size === "lg" ? "1.25rem" : "1rem",
            color: "white",
          })}
        </Box>
      )}
    </Avatar>
  );
}

export { EncounterAvatar };
