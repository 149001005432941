import * as React from "react";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useAuditCommentsSectionIndicator,
  useAuditCommentsSubsectionIndicator,
  useAuditCommentsSharedSectionIndicator,
  useAuditCommentsSharedSubsectionIndicator,
} from "modules/charts-shared/api/queries/useAuditCommentsIndicator";
import { AuditCommentsIndicator } from "modules/charts-shared/types/audit-comments";

import { EmrCheckCircle } from "@medstonetech/slate-icons";
import { AuditComment, AuditCommentAlt, AuditCommentCheck } from "icons";
import { UseMutateFunction } from "react-query";
import {
  AuditCommentsModal,
  AuditCommentsSharedModal,
} from "./AuditCommentsModal";
import { usePermissions } from "contexts";
import { useFormContext } from "react-hook-form";

type AuditCommentsSectionProps = {
  chartCode: string;
  sectionCode: string;
  encounterId: string;
  isFromSideBar: boolean;
  hasAuditsCheck?: boolean;
};

type AuditCommentsSharedSectionProps = {
  sectionCode: string;
  encounterId: string;
  isFromSideBar: boolean;
};

type AuditCommentsSubsectionProps = {
  chartCode: string;
  sectionCode: string;
  encounterId: string;
  subsectionCode: string;
  subsectionLabel: string;
  isFromSideBar: boolean;
};

type AuditCommentsSharedSubsectionProps = {
  chartCode: string;
  sectionCode: string;
  encounterId: string;
  subsectionCode: string;
  subsectionLabel: string;
  isFromSideBar: boolean;
};

type AuditCommentsIndicatorButtonProps = {
  data?: AuditCommentsIndicator;
  updateCheck?: UseMutateFunction<unknown, unknown, unknown>;
  isUpdateCheckLoading?: boolean;
  isFromSideBar: boolean;
  onClickCheckCallback?(): void;
  onClickCommentCallback?(): void;
  hasAuditsCheck?: boolean;
};

function AuditCommentsIndicatorButton(
  props: AuditCommentsIndicatorButtonProps
) {
  const {
    data,
    isFromSideBar,
    onClickCheckCallback,
    onClickCommentCallback,
    hasAuditsCheck = false,
  } = props;

  const { scope } = usePermissions();

  const isCommenter =
    !scope("audits").isEditable && scope("audits").isAccessible;

  const isAuditIndicatorAvailable = hasAuditsCheck;
  const isAuditCommentsAvailable = data?.hasComments;

  return isFromSideBar ? (
    <Box display="flex" justifyContent="space-between" padding="5px">
      {isCommenter && isAuditCommentsAvailable ? (
        <IconButton
          aria-label="audit-comment"
          icon={
            <Icon
              as={isAuditIndicatorAvailable ? AuditCommentCheck : AuditComment}
              color={isAuditIndicatorAvailable ? "green" : "orange"}
              fontSize="1.55rem"
            />
          }
          variant="ghost"
          minWidth="unset"
          position="relative"
          height="2rem"
          top="0rem"
          width="1rem"
        />
      ) : (
        <IconButton
          aria-label="audit-check"
          icon={
            isAuditCommentsAvailable ? (
              <Icon as={AuditComment} color={"orange"} fontSize="1.55rem" />
            ) : (
              <Icon
                as={EmrCheckCircle}
                color={isAuditIndicatorAvailable ? "green" : "gray.450"}
                fontSize="1.45rem"
              />
            )
          }
          variant="ghost"
          minWidth="unset"
          position="relative"
          height="2rem"
          top="0rem"
          width="1rem"
        />
      )}
    </Box>
  ) : (
    <HStack gap=".5rem">
      {isCommenter ? (
        <Box display="flex" justifyContent="space-between" padding="5px">
          <IconButton
            aria-label="notes"
            icon={
              isAuditIndicatorAvailable ? (
                <Icon
                  as={AuditCommentCheck}
                  color={"green"}
                  fontSize="1.75rem"
                />
              ) : (
                <Icon
                  as={isAuditCommentsAvailable ? AuditComment : AuditCommentAlt}
                  color={isAuditCommentsAvailable ? "orange" : "gray.450"}
                  fontSize="1.75rem"
                />
              )
            }
            variant="ghost"
            minWidth="unset"
            position="relative"
            height="2rem"
            top="0.1rem"
            width="2rem"
            onClick={() => onClickCommentCallback && onClickCommentCallback()}
          />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" padding="5px">
            <IconButton
              aria-label="audit-check"
              icon={
                <Icon
                  as={EmrCheckCircle}
                  color={isAuditIndicatorAvailable ? "green" : "gray.450"}
                  fontSize="1.65rem"
                />
              }
              variant="ghost"
              minWidth="unset"
              position="relative"
              height="2rem"
              top="0.05rem"
              width="2rem"
              isDisabled={isAuditCommentsAvailable}
              onClick={() => {
                if (onClickCheckCallback) onClickCheckCallback();
              }}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" padding="5px">
            <IconButton
              aria-label="audit-comment"
              icon={
                <Icon
                  as={isAuditCommentsAvailable ? AuditComment : AuditCommentAlt}
                  color={isAuditCommentsAvailable ? "orange" : "gray.450"}
                  fontSize="1.75rem"
                />
              }
              variant="ghost"
              minWidth="unset"
              position="relative"
              height="2rem"
              top="0.1rem"
              width="2rem"
              onClick={() => onClickCommentCallback && onClickCommentCallback()}
            />
          </Box>
        </>
      )}
    </HStack>
  );
}

function AuditCommentsSectionIndicator(props: AuditCommentsSectionProps) {
  const { encounterId, sectionCode, chartCode, isFromSideBar } = props;

  const { data } = useAuditCommentsSectionIndicator({
    chartCode,
    encounterId,
    sectionCode,
  });

  return (
    <Box>
      <AuditCommentsIndicatorButton
        data={data?.data}
        isFromSideBar={isFromSideBar}
        hasAuditsCheck={data?.data.hasCheck}
      />
    </Box>
  );
}

function AuditCommentsSubsectionIndicator(props: AuditCommentsSubsectionProps) {
  const {
    encounterId,
    sectionCode,
    subsectionCode,
    subsectionLabel,
    chartCode,
    isFromSideBar,
  } = props;

  const { watch, getValues, setValue } = useFormContext();
  const hasAuditsCheck = watch(subsectionCode)?.auditCheck as boolean;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isLoading } = useAuditCommentsSubsectionIndicator({
    chartCode,
    encounterId,
    sectionCode,
    subsectionCode,
  });

  const onCheck = () => {
    const currentValue = getValues()?.[subsectionCode];
    setValue(subsectionCode, { ...currentValue, auditCheck: !hasAuditsCheck });
  };

  return (
    <Box>
      <AuditCommentsIndicatorButton
        data={data?.data}
        isFromSideBar={isFromSideBar}
        onClickCheckCallback={onCheck}
        onClickCommentCallback={onOpen}
        hasAuditsCheck={hasAuditsCheck}
      />

      {isOpen && (
        <AuditCommentsModal
          isOpen={isOpen}
          onClose={onClose}
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={sectionCode}
          subsectionCode={subsectionCode}
          commentsIndicator={data?.data}
          commentsIndicatorLoading={isLoading}
          title={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Subsection: {subsectionLabel}
            </chakra.span>
          }
        />
      )}
    </Box>
  );
}

function AuditCommentsSharedSectionIndicator(
  props: AuditCommentsSharedSectionProps
) {
  const { encounterId, sectionCode, isFromSideBar } = props;

  const { data } = useAuditCommentsSharedSectionIndicator({
    encounterId,
    sectionCode,
  });

  return (
    <Box>
      <AuditCommentsIndicatorButton
        data={data?.data}
        isFromSideBar={isFromSideBar}
      />
    </Box>
  );
}

function AuditCommentsSharedSubsectionIndicator(
  props: AuditCommentsSharedSubsectionProps
) {
  const {
    chartCode,
    encounterId,
    sectionCode,
    subsectionCode,
    subsectionLabel,
    isFromSideBar,
  } = props;

  const { watch, getValues, setValue } = useFormContext();
  const hasAuditsCheck = watch(subsectionCode)?.auditCheck as boolean;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isLoading } = useAuditCommentsSharedSubsectionIndicator({
    encounterId,
    sectionCode,
    subsectionCode,
  });

  const onCheck = () => {
    const currentValue = getValues()?.[subsectionCode];
    setValue(subsectionCode, { ...currentValue, auditCheck: !hasAuditsCheck });
  };

  return (
    <Box>
      <AuditCommentsIndicatorButton
        data={data?.data}
        isFromSideBar={isFromSideBar}
        onClickCheckCallback={onCheck}
        onClickCommentCallback={onOpen}
        hasAuditsCheck={hasAuditsCheck}
      />

      {isOpen && (
        <AuditCommentsSharedModal
          isOpen={isOpen}
          onClose={onClose}
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={sectionCode}
          subsectionCode={subsectionCode}
          commentsIndicator={data?.data}
          commentsIndicatorLoading={isLoading}
          title={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Subsection: {subsectionLabel}
            </chakra.span>
          }
        />
      )}
    </Box>
  );
}

export {
  AuditCommentsSectionIndicator,
  AuditCommentsSubsectionIndicator,
  AuditCommentsSharedSectionIndicator,
  AuditCommentsSharedSubsectionIndicator,
};
