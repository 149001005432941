import {
  Box,
  chakra,
  Flex,
  Icon,
  IconButton,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  EmrProfileComplete,
  EmrRemove,
  EmrSignature,
} from "@medstonetech/slate-icons";
import { UseUserByScopeResponseItem } from "api";
import { useToast } from "hooks";
import { PersonAdd } from "icons";
import { formMessages } from "messages";
import { useDeleteProgressNote } from "modules/charts-shared/api";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Button, Card, CardProps, StaffAvatar, UserAvatar } from "shared";
import { ChartProgressItem, ChartType } from "types";
import { enumMapper, extractApiErrorMessage } from "utils";
import { ChartRowElement } from "../chart";
import { AddTransferCareProviderModal } from "./AddTransferCareProviderModal";
import { useGetUserSignature } from "modules/onboarding/api";
import { USERID_CLAIMS } from "system-constants";
import { useAuth0 } from "@auth0/auth0-react";

type ProgressCardProps = {
  name: string;
  chartType: ChartType;
  isReadOnly?: boolean;
  onDelete: () => void;
  progressNote: Omit<ChartProgressItem, "encounterId">;
} & CardProps;

const GRID_PROPS: CardProps = {
  display: "grid",
  gridTemplateColumns: "32px 200px 1fr 47px",
  gridColumnGap: "18px",
  gridRowGap: "8px",
  alignItems: "center",
};

function ProgressCardHeader(props: CardProps) {
  return (
    <Card
      padding="16px"
      bg="gray.250"
      color="gray.650"
      fontSize="0.9375rem"
      boxShadow="none"
      borderRadius="5px"
      fontWeight="600"
      {...GRID_PROPS}
      {...props}
    >
      <Icon
        as={EmrProfileComplete}
        w="1.25rem"
        h="1.25rem"
        color="gray.650"
        justifySelf="center"
      />
      <chakra.span textAlign="center">Date / Time</chakra.span>
      <chakra.span textAlign="left">Status</chakra.span>
      <chakra.span textAlign="center">Delete</chakra.span>
    </Card>
  );
}

const progressOptions = enumMapper.getOptions("chartProgressStatus");

function ProgressCard(props: ProgressCardProps) {
  const {
    name,
    chartType,
    isReadOnly = false,
    progressNote: { id, updatedByFullName, updatedByProfilePictureUrl, type },
    onDelete: onDeleteProp,
    ...cardProps
  } = props;
  const [addingProvider, setAddingProvider] = React.useState(false);
  const { setValue, watch } = useFormContext();
  const toast = useToast();
  const { mutateAsync: deleteNote, isLoading: isDeleteLoading } =
    useDeleteProgressNote();
  const onDelete = async () => {
    try {
      await deleteNote(id);
      onDeleteProp();
      toast({ description: formMessages.deleteSuccess("Progress Note") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const { user } = useAuth0();
  const { data: signatureData } = useGetUserSignature(user?.[USERID_CLAIMS]);

  const transferredTo = watch(`${name}.transferredTo`);
  const transferredToEntity = watch(`${name}.transferredToEntity`);

  const signedBy = watch(`${name}.signedBy`);
  const signatureEntity = watch(`${name}.signedByEntity`);

  const signature = React.useMemo(() => {
    if (!signedBy && !signatureEntity) {
      return null;
    }

    if (signedBy && !signatureEntity) {
      return signatureData?.data;
    }

    if (signedBy && signatureEntity) {
      return signatureEntity;
    }
  }, [signatureEntity, signatureData?.data, signedBy]);

  const handleSelectProvider = (provider: UseUserByScopeResponseItem) => {
    setValue(`${name}.transferredTo`, provider.id, { shouldDirty: true });
    setValue(`${name}.transferredToEntity`, provider, { shouldDirty: true });
  };

  const handleSignOrder = () => {
    setValue(`${name}.signedBy`, user?.[USERID_CLAIMS], { shouldDirty: true });
  };

  return (
    <Card
      padding="16px"
      boxShadow="none"
      border="none"
      {...GRID_PROPS}
      {...cardProps}
    >
      <StaffAvatar
        size="xs"
        fontSize="1rem"
        userName={updatedByFullName || ""}
        profileUrl={updatedByProfilePictureUrl || ""}
        justifySelf="center"
      />
      <ChartRowElement
        isDisabled={isReadOnly}
        type="date"
        name={`${name}.date`}
      />
      {type === "note" ? (
        <Flex columnGap="0.5rem">
          {progressOptions.map((option) => (
            <ChartRowElement
              type="option"
              name={`${name}.${option.value}`}
              label={option.label}
            />
          ))}
        </Flex>
      ) : (
        <Flex flex={10} gap={50}>
          <Flex flex={4} columnGap="30px" alignItems="center">
            <Text fontWeight="bold">Care transferred to:</Text>
            {transferredTo ? (
              <Flex alignItems="center">
                <UserAvatar
                  mr="11px"
                  w="40px"
                  h="40px"
                  picture={transferredToEntity.pictureUrl}
                  name={transferredTo.fullName}
                />
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize="1.063rem"
                    lineHeight="1.063rem"
                  >
                    {transferredToEntity.fullName}
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    lineHeight="0.875rem"
                    color="gray"
                    mt="2px"
                  >
                    {chartType === "Chart" ? "Provider" : "Nurse"}
                  </Text>
                </Box>
              </Flex>
            ) : (
              <Button
                variant="label"
                color="blue"
                onClick={() => setAddingProvider(true)}
              >
                <Icon as={PersonAdd} mr="12px" />
                Add {chartType === "Chart" ? "Provider" : "Nurse"}
              </Button>
            )}
          </Flex>
          <Flex flex={6} columnGap="30px" alignItems="center">
            <Text fontWeight="bold">From / e-signed by:</Text>

            {!!signature ? (
              <>
                <Flex flex={4} alignItems="center">
                  <UserAvatar
                    mr="11px"
                    w="36px"
                    h="36px"
                    picture={signature.pictureUrl}
                    name={signature.fullName}
                  />
                  <Box>
                    <Text
                      fontWeight="bold"
                      fontSize="1.063rem"
                      lineHeight="1.063rem"
                    >
                      {signature.fullName}
                    </Text>
                    <Text
                      fontSize="0.875rem"
                      lineHeight="0.875rem"
                      color="gray"
                      mt="2px"
                    >
                      {chartType === "Chart" ? "Provider" : "Nurse"}
                    </Text>
                  </Box>
                </Flex>
                <Flex flex={2}>
                  <Box minW={300} maxW={400} minH={50}>
                    <Image
                      src={signature?.signature}
                      height="100%"
                      width="100%"
                    />
                  </Box>
                </Flex>
              </>
            ) : (
              <Button variant="label" color="blue" onClick={handleSignOrder}>
                <Icon as={EmrSignature} fontSize="1.5rem" color="blue" m={2} />
                Add Signature
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <IconButton
        aria-label="delete progress note"
        icon={<EmrRemove color="red" />}
        variant="icon"
        justifySelf="center"
        disabled={isReadOnly}
        onClick={onDelete}
        isLoading={isDeleteLoading}
      />
      <ChartRowElement
        type="input"
        name={`${name}.notes`}
        gridColumn="1 / -1"
      />
      <AddTransferCareProviderModal
        chartType={chartType}
        onClose={() => setAddingProvider(false)}
        onSelectedProvider={handleSelectProvider}
        isOpen={addingProvider}
        progressNoteId={id}
      />
    </Card>
  );
}

export { ProgressCard, ProgressCardHeader };
