import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Dialog = createIcon({
  path: (
    <path
      d="M5.40858 19.07C5.77878 19.07 6.06772 18.8894 6.51016 18.474L9.83296 15.4763H15.7562C18.474 15.4853 20 13.9233 20 11.2415V4.23476C20 1.55305 18.474 0 15.7562 0H4.24379C1.52596 0 0 1.55305 0 4.23476V11.2415C0 13.9233 1.56208 15.4763 4.18059 15.4763H4.54176V18.0948C4.54176 18.6817 4.86682 19.07 5.40858 19.07ZM5.42664 6.77201C5.42664 5.62528 6.29345 4.81264 7.44018 4.81264C8.70429 4.81264 9.54402 5.84199 9.54402 7.15124C9.54402 9.4447 7.68397 10.6366 6.68172 10.6366C6.41084 10.6366 6.20316 10.447 6.20316 10.1941C6.20316 9.95937 6.31151 9.8149 6.6456 9.7246C7.33183 9.55305 8.04515 9.03837 8.35214 8.29797H8.24379C7.99097 8.60497 7.59368 8.68623 7.17833 8.68623C6.11287 8.68623 5.42664 7.85553 5.42664 6.77201ZM10.5192 6.77201C10.5192 5.62528 11.377 4.81264 12.5237 4.81264C13.7878 4.81264 14.6366 5.84199 14.6366 7.15124C14.6366 9.4447 12.7765 10.6366 11.7743 10.6366C11.4944 10.6366 11.2957 10.447 11.2957 10.1941C11.2957 9.95937 11.4041 9.8149 11.7291 9.7246C12.4244 9.55305 13.1377 9.03837 13.4357 8.29797H13.3363C13.0835 8.60497 12.6862 8.68623 12.2619 8.68623C11.2054 8.68623 10.5192 7.85553 10.5192 6.77201Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 21 20",
});

export { Dialog };
