import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrPerson } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SPEX";
const chartCode = "C046x";

function T46PhysicalExam() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
    watch,
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values, ["Q001"]);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Physical Exam") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "physical-exam",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject, ["Q001"]);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Physical Exam") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal = Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`));
  const q009AllNormal = Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`));
  const q010AllNormal = Boolean(watch(`Q010.${chartCode}${sectionId}Q010A001`));
  const q011AllNormal = Boolean(watch(`Q011.${chartCode}${sectionId}Q011A001`));
  const q012AllNormal = Boolean(watch(`Q012.${chartCode}${sectionId}Q012A001`));
  const q013AllNormal = Boolean(watch(`Q013.${chartCode}${sectionId}Q013A001`));

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    label="Nursing Assessment reviewed"
                    pr="0px"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    label="Vitals Reviewed"
                    pr="0px"
                    type="cross-option"
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="PTA"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="in ED"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no acute distress"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mild distress"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="moderate distress"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="severe distress"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears normal"
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="alert"
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="anxious"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="lethargic"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unconscious"
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unresponsive"
                    name={`Q002.${chartCode}${sectionId}Q002A023`}
                    type="cross-option"
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A024`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q002.${chartCode}${sectionId}Q002A026`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    type="input"
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x4"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A092`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cognition nml"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="speech abnormalities"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="cognition abnormalities"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="slow responsiveness"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of CVA"
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal response to commands:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A093`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="no response"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="eyes open"
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="slow"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="innapropriate"
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A019`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal response to pain:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A094`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="withdraws"
                    name={`Q003.${chartCode}${sectionId}Q003A020`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="flexor"
                    name={`Q003.${chartCode}${sectionId}Q003A021`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="enxtensor"
                    name={`Q003.${chartCode}${sectionId}Q003A022`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="none"
                    name={`Q003.${chartCode}${sectionId}Q003A023`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A024`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hearing deficit"
                    name={`Q003.${chartCode}${sectionId}Q003A025`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A026`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A027`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A028`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="alert"
                    name={`Q003.${chartCode}${sectionId}Q003A029`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="aphasic"
                    name={`Q003.${chartCode}${sectionId}Q003A030`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="expressive"
                    name={`Q003.${chartCode}${sectionId}Q003A031`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="receptive"
                    name={`Q003.${chartCode}${sectionId}Q003A032`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A033`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nystagmus"
                    name={`Q003.${chartCode}${sectionId}Q003A034`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="fast component to:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A095`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A035`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A036`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A037`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dysarthria"
                    name={`Q003.${chartCode}${sectionId}Q003A038`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A039`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CN's nml (2-12)"
                    name={`Q003.${chartCode}${sectionId}Q003A040`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial palsy"
                    name={`Q003.${chartCode}${sectionId}Q003A041`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A042`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A043`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="forehead:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A096`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="involved"
                    name={`Q003.${chartCode}${sectionId}Q003A044`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="spared"
                    name={`Q003.${chartCode}${sectionId}Q003A045`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A046`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial droop"
                    name={`Q003.${chartCode}${sectionId}Q003A047`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A048`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A049`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A050`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tongue deviation"
                    name={`Q003.${chartCode}${sectionId}Q003A051`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A052`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A053`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A054`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal Romberg test"
                    name={`Q003.${chartCode}${sectionId}Q003A055`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="abnormal gait"
                    name={`Q003.${chartCode}${sectionId}Q003A056`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="abnormal finger-nose-finger"
                    name={`Q003.${chartCode}${sectionId}Q003A057`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A058`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A059`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A060`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor nml"
                    name={`Q003.${chartCode}${sectionId}Q003A061`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="weakness"
                    name={`Q003.${chartCode}${sectionId}Q003A062`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="hemiparesis"
                    name={`Q003.${chartCode}${sectionId}Q003A063`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="hemiplegia"
                    name={`Q003.${chartCode}${sectionId}Q003A064`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A065`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A066`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A067`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="clonus"
                    name={`Q003.${chartCode}${sectionId}Q003A068`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A069`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A070`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A071`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="reflexes nml"
                    name={`Q003.${chartCode}${sectionId}Q003A072`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pronator drift"
                    name={`Q003.${chartCode}${sectionId}Q003A073`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A074`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A075`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A076`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="altered light-touch"
                    name={`Q003.${chartCode}${sectionId}Q003A077`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="pin-prick"
                    name={`Q003.${chartCode}${sectionId}Q003A078`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="2-pt discrimin"
                    name={`Q003.${chartCode}${sectionId}Q003A079`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A080`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tremor"
                    name={`Q003.${chartCode}${sectionId}Q003A081`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A082`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal movements"
                    name={`Q003.${chartCode}${sectionId}Q003A083`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A084`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Babinski reflex"
                    name={`Q003.${chartCode}${sectionId}Q003A085`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A086`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A087`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A088`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="asterixis"
                    name={`Q003.${chartCode}${sectionId}Q003A089`}
                    type="cross-option"
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A090`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q003.${chartCode}${sectionId}Q003A097`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A091`}
                    type="input"
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Glasgow Coma Score"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="input"
                    name={`Q004.${chartCode}${sectionId}Q004A001`}
                    w="50px"
                  />
                </>
              }
            >
              Glasgow Coma Score
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="eyes open:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A020`}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="spontaneously (4)"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="to voice (3)"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="to pain (2)"
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="none (1)"
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="input"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="speech:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A021`}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml (5)"
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="disoriented (4)"
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="inapprop (3)"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="incoherent (2)"
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="none (1)"
                    name={`Q004.${chartCode}${sectionId}Q004A011`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A012`}
                    type="input"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q004.${chartCode}${sectionId}Q004A022`}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml (6)"
                    name={`Q004.${chartCode}${sectionId}Q004A013`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="localizes (5)"
                    name={`Q004.${chartCode}${sectionId}Q004A014`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="withdraws (4)"
                    name={`Q004.${chartCode}${sectionId}Q004A015`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="flexor (3)"
                    name={`Q004.${chartCode}${sectionId}Q004A016`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="exten (2)"
                    name={`Q004.${chartCode}${sectionId}Q004A017`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    label="none (1)"
                    name={`Q004.${chartCode}${sectionId}Q004A018`}
                    type="cross-option"
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A019`}
                    type="input"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Psych"
            >
              Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mood / affect nml"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="depressed"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="tearful"
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="anxious"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="paranoid"
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labile"
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="flat"
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="agitated"
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thought content nml"
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-communicative"
                    name={`Q005.${chartCode}${sectionId}Q005A013`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="pressured"
                    name={`Q005.${chartCode}${sectionId}Q005A014`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="slow"
                    name={`Q005.${chartCode}${sectionId}Q005A015`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A016`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="rambling"
                    name={`Q005.${chartCode}${sectionId}Q005A017`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="tangenital"
                    name={`Q005.${chartCode}${sectionId}Q005A018`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A019`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thought process nml"
                    name={`Q005.${chartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="suicidal"
                    name={`Q005.${chartCode}${sectionId}Q005A021`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="homicidal"
                    name={`Q005.${chartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="ideation"
                    name={`Q005.${chartCode}${sectionId}Q005A023`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="plan"
                    name={`Q005.${chartCode}${sectionId}Q005A024`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A025`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="grandiosity"
                    name={`Q005.${chartCode}${sectionId}Q005A026`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="hallucination:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A038`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="visual"
                    name={`Q005.${chartCode}${sectionId}Q005A027`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="auditory"
                    name={`Q005.${chartCode}${sectionId}Q005A028`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A029`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disorganized"
                    name={`Q005.${chartCode}${sectionId}Q005A030`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="flight of ideas"
                    name={`Q005.${chartCode}${sectionId}Q005A031`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A032`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="judgement / insight nml"
                    name={`Q005.${chartCode}${sectionId}Q005A033`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="poor insight"
                    name={`Q005.${chartCode}${sectionId}Q005A034`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="poor judgement"
                    name={`Q005.${chartCode}${sectionId}Q005A035`}
                    type="cross-option"
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A036`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q005.${chartCode}${sectionId}Q005A039`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A037`}
                    type="input"
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Neck"
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cerv. lymphadenopathy"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neck supple"
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="stiff neck"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="meningismus"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no carotid bruit"
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="carotid bruit"
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Kernig's sign"
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="Brudzinski's sign"
                    name={`Q006.${chartCode}${sectionId}Q006A015`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A016`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q006.${chartCode}${sectionId}Q006A024`}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A017`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neck supple"
                    name={`Q006.${chartCode}${sectionId}Q006A018`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="stiff neck"
                    name={`Q006.${chartCode}${sectionId}Q006A019`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="meningismus"
                    name={`Q006.${chartCode}${sectionId}Q006A020`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A021`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thyromegaly"
                    name={`Q006.${chartCode}${sectionId}Q006A022`}
                    type="cross-option"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A023`}
                    type="input"
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="HEENT"
            >
              HEENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="unequal pupils"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    w="50px"
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    w="50px"
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scleral icterus"
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="pale conjunctivae"
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no nystagmus"
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nystagmus"
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no apparent trauma"
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="raccoon eyes"
                    name={`Q007.${chartCode}${sectionId}Q007A020`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="Battle's sign"
                    name={`Q007.${chartCode}${sectionId}Q007A021`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A022`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A023`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A024`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ENT inspection nml"
                    name={`Q007.${chartCode}${sectionId}Q007A025`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="TM erythema"
                    name={`Q007.${chartCode}${sectionId}Q007A026`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="dullness"
                    name={`Q007.${chartCode}${sectionId}Q007A027`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A028`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A029`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A030`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM's intact"
                    name={`Q007.${chartCode}${sectionId}Q007A031`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM palsy"
                    name={`Q007.${chartCode}${sectionId}Q007A032`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A033`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A034`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A035`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnml funduscopic"
                    name={`Q007.${chartCode}${sectionId}Q007A036`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="papilledema"
                    name={`Q007.${chartCode}${sectionId}Q007A037`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A038`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A039`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A040`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hemotympanum"
                    name={`Q007.${chartCode}${sectionId}Q007A041`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A042`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A043`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A044`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="gag reflex intact"
                    name={`Q007.${chartCode}${sectionId}Q007A045`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deprsd gag reflex"
                    name={`Q007.${chartCode}${sectionId}Q007A046`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="handles secretions poorly"
                    name={`Q007.${chartCode}${sectionId}Q007A047`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A048`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oropharynx nml"
                    name={`Q007.${chartCode}${sectionId}Q007A049`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharyngeal erythema"
                    name={`Q007.${chartCode}${sectionId}Q007A050`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="exudate"
                    name={`Q007.${chartCode}${sectionId}Q007A051`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A052`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="airway intact"
                    name={`Q007.${chartCode}${sectionId}Q007A053`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A054`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q007.${chartCode}${sectionId}Q007A055`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q007.${chartCode}${sectionId}Q007A056`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A057`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A058`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A059`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q007.${chartCode}${sectionId}Q007A060`}
                    type="cross-option"
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A061`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q007.${chartCode}${sectionId}Q007A063`}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A062`}
                    type="input"
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp distress"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="apneic"
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breath sounds nml"
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wheezes"
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q008.${chartCode}${sectionId}Q008A010`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q008.${chartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A012`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q008.${chartCode}${sectionId}Q008A014`}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A013`}
                    type="input"
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="reg. rate & rhythm"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="irregularly irregular rhythm"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="JVD"
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="extrasystoles"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="occasional"
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="frequent"
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="murmur"
                    name={`Q009.${chartCode}${sectionId}Q009A012`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="grade"
                    name={`Q009.${chartCode}${sectionId}Q009A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A014`}
                    w="50px"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="/6"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q009.${chartCode}${sectionId}Q009A032`}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="sys"
                    name={`Q009.${chartCode}${sectionId}Q009A015`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="dias"
                    name={`Q009.${chartCode}${sectionId}Q009A016`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A017`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="gallop"
                    name={`Q009.${chartCode}${sectionId}Q009A018`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="S3"
                    name={`Q009.${chartCode}${sectionId}Q009A019`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="S4"
                    name={`Q009.${chartCode}${sectionId}Q009A020`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A021`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased pulse"
                    name={`Q009.${chartCode}${sectionId}Q009A022`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q009.${chartCode}${sectionId}Q009A023`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q009.${chartCode}${sectionId}Q009A024`}
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="radial"
                    name={`Q009.${chartCode}${sectionId}Q009A025`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A026`}
                    w="249.6666717529297px"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="fem"
                    name={`Q009.${chartCode}${sectionId}Q009A027`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A028`}
                    w="249.6666717529297px"
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="dors ped"
                    name={`Q009.${chartCode}${sectionId}Q009A029`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A030`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q009.${chartCode}${sectionId}Q009A033`}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A031`}
                    type="input"
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q010.${chartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q010.${chartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q010.${chartCode}${sectionId}Q010A004`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="generalized"
                    name={`Q010.${chartCode}${sectionId}Q010A005`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="LUQ"
                    name={`Q010.${chartCode}${sectionId}Q010A006`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="RUQ"
                    name={`Q010.${chartCode}${sectionId}Q010A007`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="LLQ"
                    name={`Q010.${chartCode}${sectionId}Q010A008`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="RLQ"
                    name={`Q010.${chartCode}${sectionId}Q010A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A010`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no organomegaly"
                    name={`Q010.${chartCode}${sectionId}Q010A011`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hepatomegaly"
                    name={`Q010.${chartCode}${sectionId}Q010A012`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="splenomegaly"
                    name={`Q010.${chartCode}${sectionId}Q010A013`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="mass"
                    name={`Q010.${chartCode}${sectionId}Q010A014`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A015`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q010.${chartCode}${sectionId}Q010A016`}
                    type="cross-option"
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A017`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q010.${chartCode}${sectionId}Q010A019`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A018`}
                    type="input"
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Rectal"
            >
              Rectal
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q011.${chartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased rectal tone"
                    name={`Q011.${chartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A004`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heme neg stool"
                    name={`Q011.${chartCode}${sectionId}Q011A005`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="black stool"
                    name={`Q011.${chartCode}${sectionId}Q011A006`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="bloody stool"
                    name={`Q011.${chartCode}${sectionId}Q011A007`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="heme pos. stool"
                    name={`Q011.${chartCode}${sectionId}Q011A008`}
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="trace"
                    name={`Q011.${chartCode}${sectionId}Q011A009`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A010`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q011.${chartCode}${sectionId}Q011A012`}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A011`}
                    type="input"
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color, warm, dry"
                    name={`Q012.${chartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cyanosis"
                    name={`Q012.${chartCode}${sectionId}Q012A003`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoresis"
                    name={`Q012.${chartCode}${sectionId}Q012A004`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="pallor"
                    name={`Q012.${chartCode}${sectionId}Q012A005`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="warmth"
                    name={`Q012.${chartCode}${sectionId}Q012A006`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q012.${chartCode}${sectionId}Q012A007`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q012.${chartCode}${sectionId}Q012A008`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q012.${chartCode}${sectionId}Q012A009`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A010`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no rash"
                    name={`Q012.${chartCode}${sectionId}Q012A011`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q012.${chartCode}${sectionId}Q012A012`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="zoster-like"
                    name={`Q012.${chartCode}${sectionId}Q012A013`}
                    fontStyle="italic"
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="petechiae"
                    name={`Q012.${chartCode}${sectionId}Q012A014`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q012.${chartCode}${sectionId}Q012A015`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="lymphangitis"
                    name={`Q012.${chartCode}${sectionId}Q012A016`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A017`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="embolic lesions"
                    name={`Q012.${chartCode}${sectionId}Q012A018`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="signs of IVDA"
                    name={`Q012.${chartCode}${sectionId}Q012A019`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A020`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q012.${chartCode}${sectionId}Q012A021`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ucler"
                    name={`Q012.${chartCode}${sectionId}Q012A022`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="location:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q012.${chartCode}${sectionId}Q012A029`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A023`}
                    w="673px"
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="depth / stage:"
                    fontStyle="italic"
                    type="cross-option"
                    name={`Q012.${chartCode}${sectionId}Q012A030`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="1"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A024`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="2"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A025`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="3"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A026`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="4"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A027`}
                    type="cross-option"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q012.${chartCode}${sectionId}Q012A031`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A028`}
                    type="input"
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q013.${chartCode}${sectionId}Q013A002`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q013.${chartCode}${sectionId}Q013A003`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A004`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A005`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A006`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="calf tenderness"
                    name={`Q013.${chartCode}${sectionId}Q013A007`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A008`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A009`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A010`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml ROM"
                    name={`Q013.${chartCode}${sectionId}Q013A011`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Homan's sign"
                    name={`Q013.${chartCode}${sectionId}Q013A012`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A013`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A014`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="cords"
                    name={`Q013.${chartCode}${sectionId}Q013A015`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A016`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pedal edema"
                    name={`Q013.${chartCode}${sectionId}Q013A017`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pedal edema"
                    name={`Q013.${chartCode}${sectionId}Q013A018`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A019`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A020`}
                    type="cross-option"
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A021`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    name={`Q013.${chartCode}${sectionId}Q013A023`}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A022`}
                    type="input"
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q014"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q014.${chartCode}${sectionId}Q014A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q014.${chartCode}${sectionId}Q014A002`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T46PhysicalExam };
