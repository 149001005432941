import { axiosChartInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseDeleteChartFavoriteParams = {
  id: string;
  sectionCode: string;
};

function useDeleteChartFavorite({
  id,
  sectionCode,
}: UseDeleteChartFavoriteParams) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteChartFavorite", { id }],
    () => axiosChartInstance.delete(`v1/favorites/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartFavorites", { sectionCode }]);
      },
    }
  );
}

export { useDeleteChartFavorite };
