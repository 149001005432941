import { axiosIdentityInstance } from "config";
import { RoomStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { BedRequestDto } from "./useUpdateBeds";

type CreateRoomPayload = {
  room: {
    roomId?: string;
    name: string;
    status: RoomStatus;
  };
  beds: BedRequestDto[];
};

function useCreateRoom() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createRoom"],
    (input: CreateRoomPayload) => axiosIdentityInstance.post("/v1/room", input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
      },
    }
  );
}

export { useCreateRoom };
export type { CreateRoomPayload };
