import {
  chakra,
  Flex,
  FlexProps,
  Icon,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
} from "@chakra-ui/react";
import { EmrCheckCircle } from "@medstonetech/slate-icons";
import { WarningCircleOutlined } from "icons";
import * as React from "react";

type LeftInfoInputProps = InputGroupProps & {
  inputElement: React.ReactElement;
  leftTitle: string;
};

type LeftInfoSelectProps = FlexProps & {
  selectElement: React.ReactElement;
  leftTitle: string;
};

function LeftInfoInput(props: LeftInfoInputProps) {
  const { inputElement, leftTitle, ...groupProps } = props;
  return (
    <InputGroup {...groupProps}>
      <InputLeftElement
        pointerEvents="none"
        h="100%"
        w="180px"
        px="25px"
        justifyContent="start"
        children={
          <chakra.span fontSize="1.0625rem" fontWeight="400" color="gray.700">
            {leftTitle}
          </chakra.span>
        }
      />
      {React.cloneElement(inputElement, {
        bg: "white",
        textAlign: "right",
        h: "60px",
        paddingLeft: "150px",
        paddingRight: "25px",
        border: "none",
        fontSize: "1.0625rem",
        fontWeight: "600",
      })}
    </InputGroup>
  );
}

function LeftInfoSelect(props: LeftInfoSelectProps) {
  const { selectElement, leftTitle, children, ...groupProps } = props;
  return (
    <Flex
      w="100%"
      position="relative"
      bg="white"
      borderRadius="10px"
      {...groupProps}
    >
      {React.cloneElement(selectElement, {
        textAlign: "right",
        h: "60px",
        paddingRight: "25px",
        border: "none",
        fontSize: "1.0625rem",
        fontWeight: "600",
        variant: "unstyled",
      })}

      <Flex
        justify="center"
        alignItems="center"
        pointerEvents="none"
        position="absolute"
        h="100%"
        w="120px"
        px="25px"
        justifyContent="start"
        children={
          <chakra.span fontSize="1.0625rem" fontWeight="400" color="gray.700">
            {leftTitle}
          </chakra.span>
        }
      />
    </Flex>
  );
}

function PasswordValidationInput(
  props: LeftInfoInputProps & { isValid: boolean; showValidator?: boolean }
) {
  const {
    inputElement,
    leftTitle,
    isValid,
    showValidator = true,
    ...groupProps
  } = props;
  return (
    <InputGroup {...groupProps}>
      <InputLeftElement
        pointerEvents="none"
        h="100%"
        w="150px"
        px="25px"
        justifyContent="start"
        children={
          <>
            <chakra.span fontSize="1.0625rem" fontWeight="400" color="gray.700">
              {leftTitle}
            </chakra.span>
            {showValidator &&
              (isValid ? (
                <Icon
                  as={EmrCheckCircle}
                  color="green"
                  ml="12px"
                  h="20px"
                  w="20px"
                />
              ) : (
                <Icon
                  as={WarningCircleOutlined}
                  color="red"
                  ml="12px"
                  h="20px"
                  w="20px"
                />
              ))}
          </>
        }
      />
      {React.cloneElement(inputElement, {
        bg: "white",
        textAlign: "right",
        h: "60px",
        paddingLeft: "150px",
        paddingRight: "25px",
        border: "none",
        fontSize: "1.0625rem",
        fontWeight: "600",
      })}
    </InputGroup>
  );
}

export { LeftInfoInput, LeftInfoSelect, PasswordValidationInput };
