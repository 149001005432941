import { axiosPatientInstance } from "config";
import { SupervisingProvider } from "modules/reception/types";
import { PaginationPayload, PaginationResponse } from "types";
import { useQuery } from "react-query";

type SupervisingProviderListProps = PaginationPayload & {
  search?: string;
};

type SupervisingProviderItemResponse = SupervisingProvider & {
  actions: string;
};

type SupervisingProviderResponse =
  PaginationResponse<SupervisingProviderItemResponse>;

function useSupervisingProviderList(
  props: SupervisingProviderListProps,
  options?: { enabled: boolean }
) {
  const { sort, descending, page = 1, size = 10, search } = props;

  return useQuery(
    ["supervisingProviderList", props],
    () =>
      axiosPatientInstance.get<SupervisingProviderResponse>(
        "v1/supervising-provider",
        {
          params: {
            page,
            pageSize: size,
            sortDesc: descending || false,
            orderBy: sort || "createdAt",
            search,
          },
        }
      ),
    { keepPreviousData: true, ...options }
  );
}

export { useSupervisingProviderList };
export type { SupervisingProviderResponse, SupervisingProviderItemResponse };
