import { axiosSLPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useGetStpVipReportByRange(
  start: Date | null,
  end: Date | null,
  filter: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["stpVipReportByRange", { start, end }],
    () =>
      axiosSLPatientInstance.get<Blob>(`v1/patient/report/${filter}`, {
        responseType: "blob",
        params: { start, end },
      }),
    { ...options }
  );
}

export { useGetStpVipReportByRange };
