import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import {
  getSharedChartNoteApiURL,
  SharedChartNoteApiUrlParams,
} from "modules/charts-shared/utils";
import { ChartNote } from "types";

function useSharedChartNotes(params: SharedChartNoteApiUrlParams) {
  return useAxiosQuery(["sharedChartNotes", params], () =>
    axiosChartInstance.get<ChartNote[]>(getSharedChartNoteApiURL(params))
  );
}

export { useSharedChartNotes };
