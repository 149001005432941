import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T014UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T016UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound / FAST Exam done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="heart"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="pericardium"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="abdomen"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T017UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound / FAST Exam done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="heart"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="pericardium"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="abdomen"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A005`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T021UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            fontStyle="italic"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A006`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            fontStyle="italic"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            fontStyle="italic"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T024UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T031UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A006`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T035UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="LE Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T036UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound / FAST Exam done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="heart"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement
            label="pericardium"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="abdomen"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A006`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A013`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T039UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T040UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="pelvis"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T041UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Doppler Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A007`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T043UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A006`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T046UltrasoundChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Ultrasound done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A002`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A003`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by radiologist"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A006`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD:"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal::"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function UltrasoundChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C014x: <T014UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C016x: <T016UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C017x: <T017UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C021x: <T021UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C024x: <T024UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C031x: <T031UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C035x: <T035UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C036x: <T036UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C039x: <T039UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C040x: <T040UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C041x: <T041UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C043x: <T043UltrasoundChart sectionDelimiter={sectionDelimiter} />,
    C046x: <T046UltrasoundChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { UltrasoundChart };
