import { axiosIdentityInstance } from "config";
import { BedStatus } from "enums";
import { RoomDto } from "modules/room-list/api";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type RoomFilter = "Open" | "Occupied" | "Cleaning" | "Maintenance" | "Closed";

type UseRoomsDetailsProps = PaginationPayload & {
  search?: string;
  bedStatus?: BedStatus;
  assigned?: boolean;
  filter?: RoomFilter;
};

type UseRoomsDetailsResponse = PaginationResponse<RoomDto>;

function useRoomsDetails(props: UseRoomsDetailsProps) {
  const { size = 10, page = 1, search, filter, bedStatus, assigned } = props;

  return useInfiniteQuery(
    ["roomsDetails", { search, filter, bedStatus, assigned }],
    ({ pageParam = page }) =>
      axiosIdentityInstance.get<UseRoomsDetailsResponse>("v1/room/encounters", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
          filter,
          bedStatus,
          assigned,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export type { UseRoomsDetailsResponse, RoomFilter };
export { useRoomsDetails };
