const colors = {
  blue: "#007AFF",
  cyan: "#32ADE6",
  green: "#34C759",
  indigo: "#5856BE",
  mint: "#00C7BE",
  orange: "#FF9500",
  pink: "#DE5494",
  purple: "#AF52DE",
  red: "#FF3B30",
  "red-muted": "#EB5757",
  "red-sizzling": "#FF2D55",
  teal: "#30B0C7",
  yellow: "#FFCC00",
  gray: {
    50: "#F8F8F8",
    100: "#F7F7F7",
    150: "#F4F4F5",
    200: "#F2F2F7",
    250: "#EEEEEF",
    300: "#E9E8E7",
    350: "#E3E3E8",
    400: "#DADADA",
    450: "#D1D1D6",
    500: "#C4C4C4",
    550: "#A2A2A2",
    600: "#808080",
    650: "#595959",
    700: "#9A9EA7",
    750: "#50555C",
  },
};

export default colors;
