import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const AuditComment = createIcon({
  path: (
    <path
      d="M5.92922 20.7123C6.31851 20.7123 6.60799 20.5127 7.07713 20.0735L10.6806 16.7795H17.4283C20.4029 16.7795 22 15.1425 22 12.2178V4.57169C22 1.64701 20.4029 0 17.4283 0H4.57169C1.5971 0 0 1.63702 0 4.57169V12.2178C0 15.1524 1.5971 16.7795 4.57169 16.7795H5.04083V19.6842C5.04083 20.3031 5.36025 20.7123 5.92922 20.7123ZM11.01 10.2414C10.4909 10.2414 10.2015 9.95191 10.1915 9.42287L10.0617 3.99274C10.0517 3.4637 10.441 3.07441 11 3.07441C11.549 3.07441 11.9583 3.47368 11.9483 4.00272L11.7985 9.42287C11.7886 9.96189 11.4991 10.2414 11.01 10.2414ZM11.01 13.5853C10.4111 13.5853 9.89201 13.1162 9.89201 12.5172C9.89201 11.9283 10.4011 11.4492 11.01 11.4492C11.6089 11.4492 12.118 11.9183 12.118 12.5172C12.118 13.1261 11.5989 13.5853 11.01 13.5853Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 21",
});

export { AuditComment };
