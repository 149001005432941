import * as React from "react";

import { Heading, chakra } from "@chakra-ui/react";
import { Modal, ModalBody, ModalContent } from "@chakra-ui/react";

import { Lock } from "icons";

export function UnauthorizedPage() {
  React.useEffect(() => {
    const handleContextmenu: (this: Document, ev: MouseEvent) => void = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <Modal onClose={() => {}} isOpen isCentered size="full">
      <ModalContent backgroundColor="rgba(255, 255, 255, 0.4)">
        <ModalBody
          userSelect="none"
          cursor="not-allowed"
          display="flex"
          flexDir="column"
          gap="1.5rem"
          alignItems="center"
          justifyContent="center"
          color="gray.750"
        >
          <Lock fontSize={70} />
          <Heading>Not whitelisted</Heading>
          <chakra.span fontSize={24}>
            You do not have permission to access this page.
          </chakra.span>
          <chakra.span fontSize={24} mt={10}>
            Please ask your administrator for more information.
          </chakra.span>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
