import { CheckboxProps, useCheckbox, Box } from "@chakra-ui/react";
import * as React from "react";

type CheckboxListItemProps = {
  preventDefault?: boolean;
  passIsCheckedToChildren?: boolean;
} & CheckboxProps;

function CheckboxListItem(props: CheckboxListItemProps) {
  const {
    children,
    isChecked,
    isReadOnly,
    preventDefault = true,
    passIsCheckedToChildren = false,
    ...rest
  } = props;
  const {
    getInputProps,
    getCheckboxProps,
    state: { isChecked: isSelected },
  } = useCheckbox(props);

  const input = getInputProps();
  const { onMouseDown, ...restCheckbox } = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...restCheckbox}
        {...(preventDefault && { onMouseDown })}
        color="gray.600"
        height="48px"
        display="flex"
        alignItems="center"
        padding="14px 12px 14px 16px"
        _checked={{
          color: "blue",
        }}
        _disabled={{ opacity: 0.6 }}
        {...rest}
      >
        {passIsCheckedToChildren
          ? React.isValidElement(children) &&
            React.cloneElement(children, { isChecked: isSelected })
          : children}
      </Box>
    </Box>
  );
}

export { CheckboxListItem };
