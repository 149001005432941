import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const EllipseOutlined = createIcon({
  path: (
    <path
      d="M0 8.26962C0 3.40368 4.5107 0 10.9959 0C17.4893 0 22 3.40368 22 8.26962C22 13.1356 17.4893 16.5475 10.9959 16.5475C4.5107 16.5475 0 13.1356 0 8.26962ZM1.84228 8.26962C1.84228 12.0285 5.63425 14.7135 10.9959 14.7135C16.3658 14.7135 20.1577 12.0285 20.1577 8.26962C20.1577 4.51896 16.3658 1.83402 10.9959 1.83402C5.63425 1.83402 1.84228 4.51896 1.84228 8.26962Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 22 17",
});

export { EllipseOutlined };
