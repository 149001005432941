import {
  Box,
  Button,
  ButtonGroup,
  InputProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { Input } from "shared";
import { getOptions } from "utils/enum-mapper";
import { TEMPERATURE_TYPE_LABEL } from "../constants";
import { useVitalsContext } from "../contexts";
import { Spo2Type, WeightUnit } from "../types/types";
import { VitalsCell, VitalsCellProps } from "./VitalsCell";
import {
  HeightVitalsInputModal,
  Spo2VitalsInputModal,
  WeightVitalsInputModal,
} from "./modals";

type VitalsEditCellProps = {
  onOpen: () => void;
  value?: string | number;
  disabledBtn?: boolean;
} & VitalsCellProps;

type SpecializedVitalsEditCellProps = Omit<VitalsEditCellProps, "onOpen">;

type SlashVitalsEditCellProps = BaseVitalsEditCellProps & {
  leftSuffix?: string;
  rightSuffix?: string;
};

type Spo2TypeVitalsEditCellProps = Omit<BaseVitalsEditCellProps, "onSave"> & {
  onSave: (value: Spo2Type) => void;
};

type BaseVitalsEditCellProps = {
  onSave: (value: string) => void;
  onValidate?: (value: string) => string | boolean;
  modalTitle: string;
  inputProps?: Pick<InputProps, "type" | "max" | "min" | "width">;
  suffix?: string;
  name?: string;
} & SpecializedVitalsEditCellProps;

type WeightVitalsEditCellProps = {
  onSave: (value: number, unit: WeightUnit) => void;
} & SpecializedVitalsEditCellProps;

type HeightVitalsEditCellProps = {
  onSave: (value: string) => void;
} & SpecializedVitalsEditCellProps;

type Spo2VitalsEditCellProps = SpecializedVitalsEditCellProps & {
  onSave: (value: string, type: Spo2Type) => void;
  type?: Spo2Type;
};

type TemperatureVitalsEditCellProps = SpecializedVitalsEditCellProps & {
  onSave: (value: string) => void;
};

function VitalsEditCell(props: VitalsEditCellProps) {
  const { value, onOpen, disabledBtn, ...restProps } = props;
  const { isEditMode } = useVitalsContext();

  if (value && !isEditMode) {
    return <VitalsCell {...restProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell padding="7px 10px" {...restProps}>
      <Button
        position="unset"
        onClick={onOpen}
        height="30px"
        variant="action"
        borderRadius="20px"
        border="none"
        minWidth="unset"
        bgColor={isEditMode && value ? "transparent" : "gray.50"}
        width="100%"
        disabled={disabledBtn}
      >
        {value || "Add"}
      </Button>
    </VitalsCell>
  );
}

function BaseVitalsEditCell(props: BaseVitalsEditCellProps) {
  const {
    onSave,
    value,
    modalTitle,
    onValidate,
    disabledBtn,
    inputProps,
    suffix,
    name,
    ...vitalsEditCellProps
  } = props;
  const { isEditMode, isAddMode } = useVitalsContext();

  if (value && !isEditMode && !isAddMode) {
    return <VitalsCell {...vitalsEditCellProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell {...vitalsEditCellProps}>
      <Box flex={1} />
      <Input
        value={value}
        onChange={(e) => onSave(e.target.value)}
        {...inputProps}
      />
      <Text flex={1} color="gray.450">
        {suffix}
      </Text>
    </VitalsCell>
  );
}

function WeightVitalsEditCell(props: WeightVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <WeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
      />
    </>
  );
}

function HeightVitalsEditCell(props: HeightVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <HeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
      />
    </>
  );
}

function Spo2VitalsEditCell(props: Spo2VitalsEditCellProps) {
  const { onSave, value, type, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <Spo2VitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
        initialType={type}
      />
    </>
  );
}

function TemperatureVitalsEditCell(props: TemperatureVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;

  const { isEditMode, isAddMode } = useVitalsContext();

  const tempType = React.useMemo(() => {
    const stringValue = value?.toString() ?? "";
    if (stringValue.indexOf("(") > -1 && stringValue?.indexOf(")") > -1) {
      return stringValue.slice(
        stringValue.indexOf("(") + 1,
        stringValue.indexOf(")")
      );
    }
    return "";
  }, [value]);

  const tempValue = React.useMemo(() => {
    const stringValue = value?.toString() ?? "";
    if (stringValue.indexOf("(") > -1 && stringValue?.indexOf(")") > -1) {
      return stringValue.slice(0, stringValue.indexOf("(") - 1);
    }
    return stringValue;
  }, [value]);

  if (value && !isEditMode && !isAddMode) {
    return <VitalsCell {...vitalsEditCellProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell columnGap="0.5rem">
      <Input
        value={tempValue}
        onChange={(e) =>
          onSave(`${e.target.value}${tempType !== "" ? ` (${tempType})` : ""}`)
        }
        width="4.5rem"
      />
      <ButtonGroup>
        {Object.values(TEMPERATURE_TYPE_LABEL).map((val) => (
          <Button
            height="40px"
            width="40px"
            minW="unset"
            variant="outline"
            sx={{
              backgroundColor: "white",
              borderColor: val[0] === tempType[0] ? "blue" : "gray.450",
            }}
            color={val[0] === tempType[0] ? "blue" : "gray.450"}
            onClick={() =>
              onSave(`${tempValue} (${val === "TM" ? "TM" : val[0]})`)
            }
            _hover={{}}
          >
            {val === "TM" ? val : val[0]}
          </Button>
        ))}
      </ButtonGroup>
    </VitalsCell>
  );
}

function SpO2TypeVitalsEditCell(props: Spo2TypeVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isEditMode, isAddMode } = useVitalsContext();

  if (value && !isEditMode && !isAddMode) {
    return <VitalsCell {...vitalsEditCellProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell>
      <ButtonGroup>
        {getOptions("spo2Type").map(({ value: typeValue }) => (
          <Button
            height="40px"
            width="40px"
            minW="unset"
            variant="outline"
            onClick={() => onSave(typeValue)}
            color={value === typeValue ? "blue" : "gray.450"}
            bgColor="white"
            borderColor={value === typeValue ? "blue" : "gray.450"}
            _hover={{}}
          >
            {typeValue.toUpperCase()}
          </Button>
        ))}
      </ButtonGroup>
    </VitalsCell>
  );
}

function SlashVitalsEditCell(props: SlashVitalsEditCellProps) {
  const {
    onSave,
    value,
    modalTitle,
    leftSuffix,
    rightSuffix,
    inputProps,
    ...vitalsEditCellProps
  } = props;
  const { isEditMode, isAddMode } = useVitalsContext();

  const { leftValue, rightValue } = React.useMemo(() => {
    if (value) {
      const stringValues = value.toString().split("/");
      return {
        leftValue: stringValues[0].replace(leftSuffix ?? "", "").trim(),
        rightValue: stringValues[1].replace(rightSuffix ?? "", "").trim(),
      };
    }
    return { leftValue: "", rightValue: "" };
  }, [value, leftSuffix, rightSuffix]);

  if (value && !isEditMode && !isAddMode) {
    return <VitalsCell {...vitalsEditCellProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell columnGap="0.5rem">
      <Input
        {...inputProps}
        value={leftValue}
        onChange={(e) =>
          onSave(
            `${e.target.value}${
              leftSuffix ? ` ${leftSuffix}` : ""
            }/${rightValue}${rightSuffix ? ` ${rightSuffix}` : ""}`
          )
        }
      />
      {leftSuffix && <Text color="gray.450">{leftSuffix}</Text>}
      <Text color="gray.450">/</Text>
      <Input
        {...inputProps}
        value={rightValue}
        onChange={(e) =>
          onSave(
            `${leftValue}${leftSuffix ? ` ${leftSuffix}` : ""}/${
              e.target.value
            }${rightSuffix ? ` ${rightSuffix}` : ""}`
          )
        }
      />
      {rightSuffix && <Text color="gray.450">{rightSuffix}</Text>}
    </VitalsCell>
  );
}

export type {
  BaseVitalsEditCellProps,
  HeightVitalsEditCellProps,
  Spo2VitalsEditCellProps,
  VitalsEditCellProps,
  WeightVitalsEditCellProps,
};

export {
  BaseVitalsEditCell,
  HeightVitalsEditCell,
  SlashVitalsEditCell,
  SpO2TypeVitalsEditCell,
  Spo2VitalsEditCell,
  TemperatureVitalsEditCell,
  VitalsEditCell,
  WeightVitalsEditCell,
};
