import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Merge = createIcon({
  path: (
    <path
      d="M9.99756 15.3149C10.2832 15.3149 10.5396 15.0879 10.5396 14.8022V0.505371C10.5396 0.219727 10.2832 0 9.99756 0C9.71191 0 9.46289 0.219727 9.46289 0.505371V14.8022C9.46289 15.0879 9.71191 15.3149 9.99756 15.3149ZM18.5229 11.9165C19.27 12.3706 20.0024 12.041 20.0024 11.1475V4.16016C20.0024 3.28857 19.2847 2.93701 18.5229 3.39844L12.9712 6.79688C12.2388 7.24365 12.2534 8.07129 12.9712 8.51807L18.5229 11.9165ZM1.47217 11.9165L7.02393 8.51807C7.7417 8.07129 7.76367 7.24365 7.02393 6.79688L1.47217 3.39844C0.717773 2.93701 0 3.28857 0 4.16016V11.1475C0 12.041 0.732422 12.3706 1.47217 11.9165Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 21 16",
});

export { Merge };
