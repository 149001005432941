import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import {
  InsuranceCardPosition,
  InsuranceCardType,
} from "modules/reception/types";

type UseEncounterInsuranceCardFileProps = {
  encounterId: string;
  insuranceType: InsuranceCardType;
  position: InsuranceCardPosition;
};

function useEncounterInsuranceCardFile(
  props: UseEncounterInsuranceCardFileProps
) {
  const { encounterId, insuranceType, position } = props;

  return useAxiosQuery(["encounterInsuranceCardFile", props], () =>
    axiosPatientInstance.get<Blob>(
      `v1/encounter/${encounterId}/form/files/insurance-card/${insuranceType}/${position}`,
      { responseType: "blob" }
    )
  );
}

export type { UseEncounterInsuranceCardFileProps };
export { useEncounterInsuranceCardFile };
