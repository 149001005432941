import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Inbox = createIcon({
  path: (
    <path
      d="M9.99578 11.2875C10.2068 11.2875 10.3841 11.2199 10.5867 11.0173L13.4234 8.29042C13.5669 8.1469 13.6429 7.99494 13.6429 7.79232C13.6429 7.39553 13.3305 7.11693 12.9422 7.11693C12.7564 7.11693 12.5623 7.19291 12.4272 7.34487L11.2368 8.61123L10.7134 9.22752L10.7809 7.97805V0.759814C10.7809 0.346138 10.4263 0 9.99578 0C9.57366 0 9.21908 0.346138 9.21908 0.759814V7.97805L9.27818 9.22752L8.75475 8.61123L7.57282 7.34487C7.43774 7.19291 7.23512 7.11693 7.04095 7.11693C6.6526 7.11693 6.34867 7.39553 6.34867 7.79232C6.34867 7.99494 6.43309 8.1469 6.57661 8.29042L9.40481 11.0173C9.60743 11.2199 9.79316 11.2875 9.99578 11.2875ZM2.7691 19.5948H17.2309C19.0545 19.5948 20 18.6577 20 16.8594V11.9966C20 11.1524 19.8902 10.7387 19.5103 10.2068L16.6906 6.44998C15.7028 5.12453 15.0359 4.47446 13.4909 4.47446H12.3428V5.84213H13.5753C14.1663 5.84213 14.7657 6.33179 15.1456 6.83833L18.0245 10.8062C18.32 11.1946 18.2102 11.355 17.7797 11.355H12.7227C12.233 11.355 12.0135 11.7011 12.0135 12.0642V12.0979C12.0135 13.0941 11.2368 14.1579 9.99578 14.1579C8.75475 14.1579 7.98649 13.0941 7.98649 12.0979V12.0642C7.98649 11.7011 7.75855 11.355 7.26889 11.355H2.22879C1.78134 11.355 1.68848 11.1693 1.97552 10.8062L4.85437 6.83833C5.23428 6.33179 5.83368 5.84213 6.42465 5.84213H7.66568V4.47446H6.50063C4.96412 4.47446 4.28873 5.12453 3.30941 6.44998L0.489658 10.2068C0.101309 10.7387 0 11.1524 0 11.9966V16.8594C0 18.6577 0.945547 19.5948 2.7691 19.5948Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 19.59",
});

export { Inbox };
