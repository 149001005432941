import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C043x";

function T43ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Allergy"
            >
              Allergy
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Allergic reaction"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Anaphylaxis"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="localized"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                />
                <ChartRowElement
                  label="bee sting"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wasp"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fire ants"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="food:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  w="318.6666564941406px"
                  type="input"
                />
                <ChartRowElement
                  label="drug:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  w="318.6666564941406px"
                  type="input"
                />
                <ChartRowElement
                  label="substance:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                />
                <ChartRowElement
                  label="pruritus"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="rash"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hives"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bronchospasm"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="angioedema"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Angioendema:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                />
                <ChartRowElement
                  label="hereditary"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Contact dermatitis, allergic:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                />
                <ChartRowElement
                  label="latex"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="plants"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cosmetics"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="metal"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Soft Tissue Infection"
            >
              Soft Tissue Infection
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abscess - cutaneous:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cellulitis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Lymphangitis, acute:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Skin / Systemic / Other"
            >
              Skin / Systemic / Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Drug rash:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A113`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A114`}
                />
                <ChartRowElement
                  label="enteral"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="parental"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="topical"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Eczema:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A115`}
                />
                <ChartRowElement
                  label="intrinsic"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="flexural"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Erythema multiforme:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A116`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A117`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fifth disease"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gonococcemia"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A118`}
                />
                <ChartRowElement
                  label="arthritis"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="urethritis"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vaginitis"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PID"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hand, foot, mouth disease"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes genitalis:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A119`}
                />
                <ChartRowElement
                  label="penis"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="scrotum"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cervix"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vagina"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="vulva"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="perianal"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes zoster:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A120`}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A121`}
                />
                <ChartRowElement
                  label="keratitis"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="external"
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="otitis"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hidradenitis suppurativa"
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Impetigo:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A122`}
                />
                <ChartRowElement
                  label="bullous"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="non-bullous"
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Insect bite:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A123`}
                />
                <ChartRowElement
                  label="Insect sting:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A124`}
                />
                <ChartRowElement
                  label="ant"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fire ant"
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bee"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="wasp"
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hornet"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="spider"
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="tick"
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mosquito"
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="caterpillar"
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="centipede"
                  name={`Q004.${chartCode}${sectionId}Q004A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Lyme dz:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A125`}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A126`}
                />
                <ChartRowElement
                  label="rash"
                  name={`Q004.${chartCode}${sectionId}Q004A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="myalgias"
                  name={`Q004.${chartCode}${sectionId}Q004A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="arthralgias"
                  name={`Q004.${chartCode}${sectionId}Q004A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Measles"
                  name={`Q004.${chartCode}${sectionId}Q004A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Meningococcemia, acute"
                  name={`Q004.${chartCode}${sectionId}Q004A052`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ meningitis"
                  name={`Q004.${chartCode}${sectionId}Q004A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A054`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="MRSA:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A127`}
                />
                <ChartRowElement
                  label="cellulitis"
                  name={`Q004.${chartCode}${sectionId}Q004A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lymphangitis"
                  name={`Q004.${chartCode}${sectionId}Q004A056`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abscess:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A128`}
                />
                <ChartRowElement
                  label="location:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A129`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A057`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pityriasis rosea"
                  name={`Q004.${chartCode}${sectionId}Q004A058`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A059`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Poison Ivy"
                  name={`Q004.${chartCode}${sectionId}Q004A060`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Poison Oak"
                  name={`Q004.${chartCode}${sectionId}Q004A061`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A062`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pruritus:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A130`}
                />
                <ChartRowElement
                  label="local"
                  name={`Q004.${chartCode}${sectionId}Q004A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q004.${chartCode}${sectionId}Q004A064`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A065`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Psoriasis:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A131`}
                />
                <ChartRowElement
                  label="flexural"
                  name={`Q004.${chartCode}${sectionId}Q004A066`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="plaque"
                  name={`Q004.${chartCode}${sectionId}Q004A067`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized pustular"
                  name={`Q004.${chartCode}${sectionId}Q004A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A069`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rocky Mtn Spotted Fever"
                  name={`Q004.${chartCode}${sectionId}Q004A070`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A071`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rubella"
                  name={`Q004.${chartCode}${sectionId}Q004A072`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ encephalitis"
                  name={`Q004.${chartCode}${sectionId}Q004A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A074`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Scabies"
                  name={`Q004.${chartCode}${sectionId}Q004A075`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A076`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Scarlatina:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A132`}
                />
                <ChartRowElement
                  label="with rash"
                  name={`Q004.${chartCode}${sectionId}Q004A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="with pharyngitis"
                  name={`Q004.${chartCode}${sectionId}Q004A078`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A133`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A079`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q004.${chartCode}${sectionId}Q004A080`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Sepsis, severe"
                  name={`Q004.${chartCode}${sectionId}Q004A081`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A134`}
                />
                <ChartRowElement
                  label="hypotension"
                  name={`Q004.${chartCode}${sectionId}Q004A082`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q004.${chartCode}${sectionId}Q004A083`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q004.${chartCode}${sectionId}Q004A084`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alt. mental"
                  name={`Q004.${chartCode}${sectionId}Q004A085`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypoxia"
                  name={`Q004.${chartCode}${sectionId}Q004A086`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="resp. distress"
                  name={`Q004.${chartCode}${sectionId}Q004A087`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A088`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Syphilis:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A135`}
                />
                <ChartRowElement
                  label="primary:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A136`}
                />
                <ChartRowElement
                  label="genital"
                  name={`Q004.${chartCode}${sectionId}Q004A089`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anal"
                  name={`Q004.${chartCode}${sectionId}Q004A090`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="secondary:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A137`}
                />
                <ChartRowElement
                  label="rash"
                  name={`Q004.${chartCode}${sectionId}Q004A091`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="PID"
                  name={`Q004.${chartCode}${sectionId}Q004A092`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A093`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tinea:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A138`}
                />
                <ChartRowElement
                  label="capitis"
                  name={`Q004.${chartCode}${sectionId}Q004A094`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="corporis"
                  name={`Q004.${chartCode}${sectionId}Q004A095`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cruris"
                  name={`Q004.${chartCode}${sectionId}Q004A096`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="pedis"
                  name={`Q004.${chartCode}${sectionId}Q004A097`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="verisicolor"
                  name={`Q004.${chartCode}${sectionId}Q004A098`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A099`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Toxic shock syndrome"
                  name={`Q004.${chartCode}${sectionId}Q004A100`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A101`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ulceration:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A139`}
                />
                <ChartRowElement
                  label="pressure"
                  name={`Q004.${chartCode}${sectionId}Q004A102`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="non-pressure"
                  name={`Q004.${chartCode}${sectionId}Q004A103`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="location:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A140`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A104`}
                  w="484.5px"
                  type="input"
                />
                <ChartRowElement
                  label="depth / stage:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A141`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A105`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Urticaria:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A142`}
                />
                <ChartRowElement
                  label="allergic"
                  name={`Q004.${chartCode}${sectionId}Q004A106`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q004.${chartCode}${sectionId}Q004A107`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A108`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Varicella"
                  name={`Q004.${chartCode}${sectionId}Q004A109`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ pneumonia"
                  name={`Q004.${chartCode}${sectionId}Q004A110`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A111`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A112`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fever"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shortness of breath"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T43ClinicalImpression };
