import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Timer = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4804 0 10.0098 0C9.4902 0 9.23529 0.313725 9.23529 0.823529V4.61765C9.23529 5.04902 9.52941 5.38235 9.95098 5.38235C10.3824 5.38235 10.6765 5.04902 10.6765 4.61765V1.69608C14.9902 2.03922 18.3235 5.59804 18.3235 10C18.3235 14.6274 14.6274 18.3333 10 18.3333C5.37255 18.3333 1.65686 14.6274 1.66667 10C1.67647 7.93137 2.41176 6.05882 3.64706 4.61765C3.94118 4.23529 3.96078 3.77451 3.63725 3.43137C3.31373 3.08824 2.77451 3.11765 2.41176 3.54902C0.921569 5.29412 0 7.55882 0 10C0 15.4608 4.53922 20 10 20ZM11.5686 11.4118C12.3431 10.598 12.1863 9.5098 11.2549 8.86274L6.06863 5.23529C5.57843 4.89216 5.06863 5.41176 5.41176 5.89216L9.02941 11.0784C9.68627 12.0098 10.7745 12.1765 11.5686 11.4118Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Timer };
