import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import {
  SharedChartNoteApiUrlParams,
  getSharedChartAudioNoteApiURL,
} from "../../utils";

function useCreateSharedChartNoteAudio(params: SharedChartNoteApiUrlParams) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createSharedChartNoteAudio", params],
    (audio: FormData) =>
      axiosChartInstance.post(getSharedChartAudioNoteApiURL(params), audio),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sharedChartNotes", params]);
        queryClient.invalidateQueries(["sectionNotesCount", params]);
        queryClient.invalidateQueries(["subSectionNotesCount", params]);
      },
    }
  );
}

export { useCreateSharedChartNoteAudio };
