import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

function useEncounterProfilePicture(encounterId: string) {
  return useAxiosQuery(
    ["profilePicture", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(
        `v1/encounter/${encounterId}/form/files/profile-picture`,
        { responseType: "blob" }
      ),
    { staleTime: 1000 * 60 * 10 }
  );
}

export { useEncounterProfilePicture };
