import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ActionsPayload } from "../../types";

type UseTriageChartActionsProps = {
  encounterId: string;
  chartCode: string;
};

function useTriageChartActions(props: UseTriageChartActionsProps) {
  const { encounterId, chartCode } = props;
  return useAxiosQuery(["triageChartActions", props], () =>
    axiosChartInstance.get<ActionsPayload>(
      `v1/triage/${encounterId}/${chartCode}/actions`
    )
  );
}

export { useTriageChartActions };
