import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ProceduresResponse } from "../../types";

type UseTriageChartProceduresProps = {
  encounterId: string;
  chartCode: string;
};

function useTriageChartProcedures(props: UseTriageChartProceduresProps) {
  const { encounterId, chartCode } = props;
  return useAxiosQuery(["triageChartProcedures", props], () =>
    axiosChartInstance.get<ProceduresResponse>(
      `v1/triage/${encounterId}/${chartCode}/procedures`
    )
  );
}

export { useTriageChartProcedures };
