import { Box, Button, ButtonProps, HStack } from "@chakra-ui/react";
import * as React from "react";

type ActionButtonProps = ButtonProps & {
  icon: React.ReactNode;
  iconPosition?: "left" | "right";
  spacing?: string;
};

function ActionButton(props: ActionButtonProps) {
  const {
    children,
    icon,
    iconPosition = "left",
    spacing = "0.75rem",
    ...restProps
  } = props;

  return (
    <Button
      display="flex"
      alignItems="center"
      size="md"
      variant="action"
      height="40px"
      padding="0 8px"
      {...restProps}
    >
      <HStack spacing={spacing}>
        {iconPosition === "left" && icon}
        <Box>{children}</Box>
        {iconPosition === "right" && icon}
      </HStack>
    </Button>
  );
}

export { ActionButton };
