import { AxiosError } from "axios";
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { useHandleUnauthorizedError } from "./useHandleUnauthorizedError";

/**
 * --- IMPORTANT ---
 * Only use this custom mutation in components inside the
 * auth context, otherwise it won't work due to the useHandleUnauthorizedError
 * hook.
 */
function useAxiosQuery<Response>(
  queryKey: QueryKey,
  queryFn: QueryFunction<Response, QueryKey>,
  options?: UseQueryOptions<Response, AxiosError, Response, QueryKey>
) {
  const queryResult = useQuery(queryKey, queryFn, options);

  useHandleUnauthorizedError(queryResult.error?.response?.status);

  return queryResult;
}

export { useAxiosQuery };
