import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const FavoriteOff = createIcon({
  path: (
    <path
      d="M18.4105 19.5173L0.224093 1.3508C-0.0746977 1.05201 -0.0746977 0.534103 0.224093 0.235312C0.542803 -0.0833976 1.05075 -0.0734379 1.3595 0.235312L19.5359 18.3918C19.8447 18.7105 19.8347 19.2085 19.5359 19.5173C19.2371 19.826 18.7192 19.826 18.4105 19.5173ZM17.6834 14.8163L16.2393 13.3821C17.9125 11.4798 18.9981 9.45798 18.9981 7.54572C18.9981 5.01596 17.3348 3.31286 15.1039 3.31286C13.3111 3.31286 12.3351 4.41838 11.6678 5.36455C11.369 5.75298 11.1997 5.89241 10.9407 5.89241C10.6818 5.89241 10.4925 5.76294 10.2137 5.36455C9.59616 4.42834 8.59023 3.32282 6.85724 3.32282C6.65805 3.32282 6.45886 3.35269 6.27958 3.41245L4.5964 1.72927C5.24378 1.48027 5.94095 1.34084 6.66801 1.34084C8.56035 1.34084 10.0543 2.22725 10.9407 3.53197C11.8371 2.21729 13.3211 1.34084 15.2134 1.34084C18.4802 1.34084 21 3.89052 21 7.54572C21 10.0257 19.7849 12.4857 17.6834 14.8163ZM14.7055 17.5751C13.839 18.2623 12.8829 18.9396 11.867 19.5969C11.5682 19.7862 11.1997 19.9754 10.9407 19.9754C10.6917 19.9754 10.3133 19.7862 10.0145 19.5969C4.447 16.0115 0.881432 11.8085 0.881432 7.54572C0.881432 6.40036 1.14038 5.35459 1.60849 4.46818L3.19208 6.05177C2.99289 6.48004 2.88333 6.98798 2.88333 7.54572C2.88333 11.0715 6.55845 14.9657 10.7515 17.7544C10.8411 17.8142 10.9009 17.854 10.9407 17.854C10.9806 17.854 11.0403 17.8142 11.13 17.7544C11.8769 17.2564 12.6139 16.7186 13.2912 16.1609L14.7055 17.5751Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 21 20",
});

export { FavoriteOff };
