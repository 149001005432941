import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { SupervisingProvider } from "../../types";

type CreateSupervisingProviderPayload = {
  email: string;
  firstName: string;
  lastName: string;
  npiid: string;
};

function useCreateSupervisingProvider() {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createSupervisingProvider"],
    (payload: CreateSupervisingProviderPayload) => {
      return axiosPatientInstance.post<SupervisingProvider>(
        "v1/supervising-provider",
        payload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("supervisingProviderList");
        queryClient.invalidateQueries("activeSupervisingProvider");
      },
    }
  );
}

export { useCreateSupervisingProvider };
export type { CreateSupervisingProviderPayload };
