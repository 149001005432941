import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const AddRemove = createIcon({
  path: (
    <path
      d="M9.87097 9.37599L4.43575 17.4574C4.1597 17.8572 3.95029 18 3.58858 18C3.11264 18 2.72237 17.6954 2.72237 17.229C2.72237 16.9815 2.789 16.8006 2.93178 16.6007L8.37652 8.58593L13.7641 0.552089C14.0592 0.133263 14.2686 0 14.6684 0C15.1063 0 15.487 0.333157 15.487 0.771021C15.487 0.989952 15.4204 1.17081 15.2776 1.3707L9.87097 9.37599ZM0 4.78794C0 4.3596 0.342676 4.01692 0.771021 4.01692H2.60814V2.17028C2.60814 1.74194 2.96034 1.39926 3.37916 1.39926C3.80751 1.39926 4.1597 1.74194 4.1597 2.17028V4.01692H5.99683C6.42517 4.01692 6.76785 4.3596 6.76785 4.78794C6.76785 5.21629 6.42517 5.55896 5.99683 5.55896H4.1597V7.39609C4.1597 7.82443 3.81703 8.16711 3.37916 8.16711C2.96034 8.17663 2.60814 7.83395 2.60814 7.39609V5.55896H0.771021C0.342676 5.55896 0 5.21629 0 4.78794ZM11.4225 13.8498C11.4225 13.4215 11.7747 13.0788 12.2031 13.0788H17.4289C17.8572 13.0788 18.1999 13.4215 18.1999 13.8498C18.1999 14.2782 17.8572 14.6208 17.4289 14.6208H12.2031C11.7747 14.6208 11.4225 14.2782 11.4225 13.8498Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { AddRemove };
