import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { EmrSearch } from "@medstonetech/slate-icons";
import { MdFavorite } from "react-icons/md";
import { Button, Card } from "shared";

type OrderFormSearchBarProps = {
  search: string;
  onChangeSearch: (search: string) => void;
  toggleMyFavorites: () => void;
  toggleFacilityFavorites: () => void;
  facilityFavorites: boolean;
  myFavorites: boolean;
};

function OrderFormSearchBar({
  search,
  myFavorites,
  facilityFavorites,
  onChangeSearch,
  toggleMyFavorites,
  toggleFacilityFavorites,
}: OrderFormSearchBarProps) {
  return (
    <Card
      width="100%"
      display="flex"
      flexDirection="row"
      columnGap="20px"
      p="15px 20px"
      mb="20px"
    >
      <Button
        variant="outlineSquared"
        flex={2}
        height="40px"
        onClick={toggleMyFavorites}
        bgColor={myFavorites ? "blue" : "white"}
        color={myFavorites ? "white" : "blue"}
      >
        <Icon as={MdFavorite} mr="12px" />
        My Favorites
      </Button>
      <Button
        variant="outlineSquared"
        color={facilityFavorites ? "white" : "orange"}
        borderColor="orange"
        bgColor={facilityFavorites ? "orange" : "white"}
        flex={2}
        height="40px"
        onClick={toggleFacilityFavorites}
      >
        <Icon as={MdFavorite} mr="12px" />
        Facility Favorites
      </Button>
      <InputGroup flex={5}>
        <InputLeftElement pointerEvents="none">
          <Icon as={EmrSearch} />
        </InputLeftElement>
        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => onChangeSearch(e.target.value)}
        />
      </InputGroup>
      <Button
        variant="outlineSquared"
        colorScheme="gray"
        flex={2}
        height="40px"
        onClick={() => onChangeSearch("")}
      >
        Clear Search
      </Button>
    </Card>
  );
}

export { OrderFormSearchBar };
