import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C040x";

function T40ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="OB"
            >
              OB
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abruptio placenta:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A080`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A081`}
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypofibrinogenemia"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Active Labor:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A082`}
                />
                <ChartRowElement
                  label="term"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="<37wks"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="<27wks"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Braxton Hicks contractions:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A083`}
                />
                <ChartRowElement
                  label="at:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A084`}
                />
                <ChartRowElement
                  label="14-27 wks"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="28-36"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">37"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Eclampsia:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A085`}
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="labor"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post-partum"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fetal demise"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label=">20 wks"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Placenta Previa:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A086`}
                />
                <ChartRowElement
                  label="w/ hemorrhage"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pre-eclampsia:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A087`}
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mod"
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="HELLP syndrome"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pregnant:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A088`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="+preg test"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="IUP"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Prolapsed umbilical cord"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ruptured Membranes:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A089`}
                />
                <ChartRowElement
                  label="<14 wks"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="14-27"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="28-26"
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">37"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ onset labor <24hr ago"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Stillborn"
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vaginal bleeding:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A090`}
                />
                <ChartRowElement
                  label="spotting"
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="brisk"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="1st"
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Vaginal delivery:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A091`}
                />
                <ChartRowElement
                  label="term"
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="preterm:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A092`}
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q002.${chartCode}${sectionId}Q002A066`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cephalic"
                  name={`Q002.${chartCode}${sectionId}Q002A067`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="breech"
                  name={`Q002.${chartCode}${sectionId}Q002A068`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="footing"
                  name={`Q002.${chartCode}${sectionId}Q002A069`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A070`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A093`}
                />
                <ChartRowElement
                  label="nuchal cord"
                  name={`Q002.${chartCode}${sectionId}Q002A071`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cord compress"
                  name={`Q002.${chartCode}${sectionId}Q002A072`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="prolapsed cord"
                  name={`Q002.${chartCode}${sectionId}Q002A073`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="perineal lac:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A094`}
                />
                <ChartRowElement
                  label="degree:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A095`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q002.${chartCode}${sectionId}Q002A074`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2"
                  name={`Q002.${chartCode}${sectionId}Q002A075`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3"
                  name={`Q002.${chartCode}${sectionId}Q002A076`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="4"
                  name={`Q002.${chartCode}${sectionId}Q002A077`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A078`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A079`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abdominal pain:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute abd"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                />
                <ChartRowElement
                  label="rebound"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tenderness"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="NOTE:  For ICD-10:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  fontWeight="700"
                />
                <ChartRowElement
                  label=" • 1st trimester = < 14 weeks;  • 2nd  = 14-27 weeks;  • 3rd = 28 weeks - delivery"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  fontWeight="700"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T40ClinicalImpression };
