import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ExclamationCircleHardStops = createIcon({
  path: (
    <path
      d="M10 20C15.4706 20 20 15.4608 20 10C20 4.52941 15.4608 0 9.9902 0C4.52941 0 0 4.52941 0 10C0 15.4608 4.53922 20 10 20ZM10 18.3333C5.37255 18.3333 1.67647 14.6274 1.67647 10C1.67647 5.37255 5.36274 1.66667 9.9902 1.66667C14.6176 1.66667 18.3333 5.37255 18.3333 10C18.3333 14.6274 14.6274 18.3333 10 18.3333ZM9.9902 11.7647C10.451 11.7647 10.7255 11.5 10.7353 10.9902L10.8824 5.81373C10.8922 5.31373 10.5 4.94118 9.98039 4.94118C9.45098 4.94118 9.07843 5.30392 9.08823 5.80392L9.21569 10.9902C9.22549 11.4902 9.5 11.7647 9.9902 11.7647ZM9.9902 14.951C10.549 14.951 11.0392 14.5 11.0392 13.9314C11.0392 13.3627 10.5588 12.9118 9.9902 12.9118C9.41176 12.9118 8.93137 13.3725 8.93137 13.9314C8.93137 14.4902 9.42157 14.951 9.9902 14.951Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { ExclamationCircleHardStops };
