import { Icon, IconProps } from "@chakra-ui/react";
import { EmrBed, EmrVitals } from "@medstonetech/slate-icons";
import { NurseHat } from "icons";
import * as React from "react";
import { ChartType } from "types";

function getChartTypeIcon(chartType: ChartType, props: IconProps) {
  switch (chartType) {
    case "Chart":
      return <Icon as={EmrVitals} {...props} />;
    case "Triage":
      return <Icon as={NurseHat} {...props} />;
    case "Observation":
      return <Icon as={EmrBed} {...props} />;
    default:
      return <Icon as={NurseHat} {...props} />;
  }
}

export { getChartTypeIcon };
