import * as React from "react";

type Sort = {
  id: string;
  desc?: boolean;
};

type PaginationState = {
  page: number;
  totalPages: number;
  size: number;
  sort?: Sort;
  globalSearch?: string;
};

type PaginationActionSort = {
  type: "SET_SORT";
  payload?: Sort;
};

type PaginationActionSize = {
  type: "SET_SIZE";
  payload: number;
};

type PaginationActionSearch = {
  type: "SET_GLOBAL_SEARCH";
  payload: string;
};

type PaginationActionPageSize = {
  type: "SET_TOTAL_PAGES";
  payload: number;
};

type PaginationActionPage = {
  type: "NEXT_PAGE" | "PREV_PAGE";
  payload?: never;
};

type PaginationActionGoToPage = {
  type: "GOTO_PAGE";
  payload: number;
};

type PaginationAction =
  | PaginationActionSort
  | PaginationActionSize
  | PaginationActionSearch
  | PaginationActionPageSize
  | PaginationActionPage
  | PaginationActionGoToPage;

const PAGINATION_INITIAL_STATE = {
  page: 0,
  totalPages: 0,
  size: 10,
  descending: true,
  globalSearch: "",
};

function paginationReducer(
  state: PaginationState,
  action: PaginationAction
): PaginationState {
  const { payload, type } = action;

  switch (type) {
    case "SET_SORT": {
      return {
        ...state,
        sort: payload,
        page: 0,
      };
    }
    case "SET_GLOBAL_SEARCH": {
      return {
        ...state,
        globalSearch: payload,
        page: 0,
      };
    }
    case "SET_TOTAL_PAGES": {
      return {
        ...state,
        totalPages: payload,
        page: state.page,
      };
    }
    case "NEXT_PAGE": {
      if (state.page + 1 >= state.totalPages) {
        return {
          ...state,
          page: state.page,
        };
      }

      return {
        ...state,
        page: state.page + 1,
      };
    }
    case "PREV_PAGE": {
      if (state.page < 0) {
        return {
          ...state,
          page: state.page,
        };
      }

      return {
        ...state,
        page: state.page - 1,
      };
    }
    case "GOTO_PAGE": {
      return {
        ...state,
        page: payload,
      };
    }
    case "SET_SIZE": {
      return {
        ...state,
        size: payload,
        page: 0,
      };
    }
    default: {
      throw new Error("Unhandled state action");
    }
  }
}

type UsePaginationProps = {
  initialState?: PaginationState;
};

function usePagination(props: UsePaginationProps = {}) {
  const { initialState } = props;
  const [state, dispatch] = React.useReducer(
    paginationReducer,
    initialState || PAGINATION_INITIAL_STATE
  );

  const gotoPage = React.useCallback(
    (page: number) => dispatch({ type: "GOTO_PAGE", payload: page }),
    []
  );
  const nextPage = React.useCallback(() => dispatch({ type: "NEXT_PAGE" }), []);
  const previousPage = React.useCallback(
    () => dispatch({ type: "PREV_PAGE" }),
    []
  );
  const setGlobalFilter = React.useCallback(
    (globalFilter: string) =>
      dispatch({ type: "SET_GLOBAL_SEARCH", payload: globalFilter }),
    []
  );
  const setSize = React.useCallback(
    (size: number) => dispatch({ type: "SET_SIZE", payload: size }),
    []
  );
  const setSort = React.useCallback(
    (sort?: Sort) => dispatch({ type: "SET_SORT", payload: sort }),
    []
  );
  const setTotalPages = React.useCallback(
    (totalPages: number) =>
      dispatch({ type: "SET_TOTAL_PAGES", payload: totalPages }),
    []
  );

  return {
    state,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    setSize,
    setSort,
    setTotalPages,
  };
}

export type { PaginationAction, PaginationState, Sort, UsePaginationProps };
export { usePagination, PAGINATION_INITIAL_STATE };
