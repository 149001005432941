import { axiosOrderInstance } from "config/axios";
import {
  OrderStatus,
  OrderGroup,
  OrderType,
  OrderContrastTimerStatus,
  OrderFormElementType,
  OrderFormElementCategory,
} from "enums";
import { useAxiosQuery } from "hooks";
import { Contrast } from "modules/orders/types";

type UseEncounterOrdersProps = {
  encounterId: string;
  group: OrderGroup;
};

type UseEncounterOrdersResponseItem = {
  id: string;
  orderId: number;
  status: OrderStatus;
  type?: OrderType;
  contrastTimerStatus: OrderContrastTimerStatus;
  contrast?: Contrast;
  description: string;
  elementType: OrderFormElementType;
  elementCategory: OrderFormElementCategory;
};

function useEncounterOrders(props: UseEncounterOrdersProps) {
  const { encounterId, group } = props;
  return useAxiosQuery(["encounterOrders", props], () =>
    axiosOrderInstance.get<UseEncounterOrdersResponseItem[]>(
      `v1/${encounterId}`,
      { params: { group } }
    )
  );
}

export type { UseEncounterOrdersResponseItem };
export { useEncounterOrders };
