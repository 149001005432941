import {
  Avatar,
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
} from "@chakra-ui/react";
import { EmrBed, EmrRemove, EmrRoomCard } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { AddRounded, RightArrow } from "icons";
import { formMessages } from "messages";
import { useState } from "react";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { Button, DividerList, Select, ToolbarHeader } from "shared";
import { extractApiErrorMessage } from "utils";
import {
  BedRequestDto,
  RoomDto,
  UpdateRoomPayload,
  useDeleteRoom,
  useUpdateRoom,
} from "../api";

type BedItemProps = {
  index: number;
  bed: BedRequestDto;
  control: Control<UpdateRoomPayload>;
};

const BedItem = ({ bed, control, index }: BedItemProps) => {
  const { remove } = useFieldArray({
    control,
    name: "beds",
  });

  const handleRemoveBed = () => {
    remove(index);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" py={3} px={4}>
        <Box display="flex" justifyContent="start">
          <Icon as={EmrBed} mr={4} h="24px" w="24px" color="blue" />
          <chakra.span fontSize="17px" fontWeight="500">
            {bed.name}
          </chakra.span>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Button variant="label" onClick={handleRemoveBed}>
            <Icon as={EmrRemove} mr={4} h="24px" w="24px" color="red" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

type AddBedButtonProps = {
  roomId: string;
  control: Control<UpdateRoomPayload>;
  hideButton: boolean;
};

const AddBedButton = ({ roomId, control, hideButton }: AddBedButtonProps) => {
  const [active, setActive] = useState(false);
  const [selectedBed, setSelectedBed] = useState("");

  const { append } = useFieldArray({
    control,
    name: "beds",
  });

  const handleAddBed = () => {
    if (selectedBed) {
      append({
        name: selectedBed,
        status: "Active",
        roomId,
      });
      setActive(false);
      setSelectedBed("");
    }
  };

  return (
    <>
      {!active && !hideButton && (
        <Box pl={4} pt={4} display="flex" alignItems="center">
          <Button
            color="blue"
            variant="label"
            key="doneBtn"
            fontSize="15px"
            fontWeight="500"
            onClick={() => {
              setActive(true);
            }}
          >
            <Icon as={AddRounded} h="24px" w="24px" color="blue" mr={2} />
            add bed
          </Button>
        </Box>
      )}
      {active && (
        <Box w="100%" bgColor="white" p={4} mt={4} borderRadius="10px">
          <ToolbarHeader
            titleText={
              <chakra.span fontSize="15px" fontWeight="600">
                Add Bed
              </chakra.span>
            }
            leftButtons={[
              <Button
                key="cancelBtn"
                fontSize="16px"
                fontWeight="500"
                onClick={() => {
                  setActive(false);
                }}
              >
                Cancel
              </Button>,
            ]}
            rightButtons={[
              <Button
                key="doneBtn"
                fontSize="16px"
                fontWeight="500"
                onClick={handleAddBed}
                disabled={!selectedBed}
              >
                Done
              </Button>,
            ]}
          />
          <Box display="flex" justifyContent="space-between" pt={8}>
            <Box display="flex" justifyContent="start">
              <Icon as={EmrBed} mr={4} h="24px" w="24px" color="gray.700" />
              <chakra.span fontSize="17px" fontWeight="500" color="gray.700">
                Bed Name
              </chakra.span>
            </Box>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Select
                labelAccessor="value"
                valueAccessor="value"
                placeholder="Select"
                items={[{ value: "A" }, { value: "B" }, { value: "C" }]}
                showBlank={false}
                customOpenIcon={RightArrow}
                customCloseIcon={RightArrow}
                iconProps={{
                  fontSize: "1rem",
                  sx: { color: "gray.450 !important" },
                  marginLeft: 2,
                }}
                btnProps={{
                  variant: "label",
                  maxW: "300px",
                  width: "100px",
                  textAlign: "end",
                  justifyContent: "end",
                  fontSize: "17px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeHolderProps={{
                  color: "blue",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
                onSelectedItemChange={(item) => {
                  if (item.selectedItem) {
                    setSelectedBed(item.selectedItem?.value);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

type BedListProps = {
  roomId: string;
  control: Control<UpdateRoomPayload>;
  beds: BedRequestDto[];
};

const BedList = ({ beds, ...rest }: BedListProps) => {
  return (
    <Box w="100%">
      <chakra.span pl={4} fontSize="15px" fontWeight="500" color="gray.600">
        Beds
      </chakra.span>
      {!!beds.length && (
        <Box bgColor="white" my={2} pb={1} borderRadius="10px">
          <DividerList dividerLeftMargin="52px">
            {beds.map((bed, index) => (
              <BedItem key={index} bed={bed} index={index} {...rest} />
            ))}
          </DividerList>
        </Box>
      )}
      <AddBedButton hideButton={beds.length >= 3} {...rest} />
    </Box>
  );
};

type DeleteRoomProps = {
  roomId: string;
} & Pick<RoomDetailsModalProps, "onClose">;

const DeleteRoomButton = ({ roomId, onClose }: DeleteRoomProps) => {
  const toast = useToast();

  const { mutateAsync: deleteRoom, isLoading } = useDeleteRoom(roomId);

  const handleDeleteRoom = async () => {
    try {
      await deleteRoom({});

      toast({
        status: "success",
        description: formMessages.deleteSuccess("Room"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <Button
        variant="outlineSquared"
        borderRadius="25px"
        w="100%"
        sx={{ backgroundColor: "red", color: "white", borderColor: "red" }}
        isLoading={isLoading}
        onClick={handleDeleteRoom}
      >
        Delete Room
      </Button>
    </>
  );
};

type RoomDetailsModalProps = {
  room: RoomDto;
  isOpen: boolean;
  onClose: () => void;
};

const RoomDetailContent = ({ room, onClose }: RoomDetailsModalProps) => {
  const toast = useToast();

  const { watch, control, getValues } = useForm<UpdateRoomPayload>({
    defaultValues: {
      room: {
        roomId: room.id,
        name: room.name,
        status: room.status,
      },
      beds: room.beds.map((bed) => {
        return {
          bedId: bed.id,
          roomId: room.id,
          name: bed.name,
          status: bed.status,
        };
      }),
    },
  });

  const beds = watch("beds");

  const { mutateAsync: updateRoom, isLoading: isUpdateRoomLoading } =
    useUpdateRoom(room.id);
  const handleUpdateRoom = async () => {
    try {
      await updateRoom(getValues());

      toast({
        status: "success",
        description: formMessages.updateSuccess("Room"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="17px" fontWeight="600">
              Room
            </chakra.span>
          }
          leftButtons={[
            <Button
              key="cancelBtn"
              fontSize="16px"
              fontWeight="500"
              onClick={onClose}
            >
              Close
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              fontSize="16px"
              fontWeight="500"
              isLoading={isUpdateRoomLoading}
              onClick={handleUpdateRoom}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="15px">
        <VStack width="100%">
          <Avatar
            bg="blue"
            icon={<Icon as={EmrRoomCard} fontSize="3rem" color="white" />}
            margin="auto"
            h="100px"
            w="100px"
          />
          <chakra.span fontSize="26px" fontWeight="500">
            {room.name}
          </chakra.span>
          <BedList beds={beds} control={control} roomId={room.id} />
        </VStack>
      </ModalBody>
      <ModalFooter>
        <DeleteRoomButton roomId={room.id} onClose={onClose} />
      </ModalFooter>
    </>
  );
};

const RoomDetailsModal = (props: RoomDetailsModalProps) => {
  return (
    <Modal {...props}>
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        height="95%"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <RoomDetailContent {...props} />
      </ModalContent>
    </Modal>
  );
};

export { RoomDetailsModal };
