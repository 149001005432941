import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection } from "modules/charts-shared";

function useProviderMedicalHistory(encounterId: string) {
  return useAxiosQuery(["providerMedicalHistory", { encounterId }], () =>
    axiosChartInstance.get<ChartFormSubsection[]>(
      `v1/chart/${encounterId}/medical-history`
    )
  );
}

export { useProviderMedicalHistory };
