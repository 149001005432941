import { axiosFacilityInstance } from "config";
import { useAxiosQuery } from "hooks";
import { Pharmacy } from "modules/charts-shared/types";

type UsePharmacyResponse = Omit<Pharmacy, "actions">;

function usePharmacy(pharmacyId: string, options?: { enabled: boolean }) {
  return useAxiosQuery(
    ["pharmacy", { pharmacyId }],
    () =>
      axiosFacilityInstance.get<UsePharmacyResponse>(
        `/v1/pharmacy/${pharmacyId}`
      ),
    { ...options }
  );
}

export type { UsePharmacyResponse };
export { usePharmacy };
