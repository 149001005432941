import { AxiosResponse } from "axios";
import { UseInProcessEncounterResponse } from "modules/in-process/api";
import { EncounterListItem } from "modules/in-process/types";
import { InfiniteData, QueryClient } from "react-query";

function updateInProcessListItemCache(
  queryClient: QueryClient,
  encounterId: string,
  encounterChanges: Partial<EncounterListItem>
) {
  queryClient.setQueriesData<
    InfiniteData<AxiosResponse<UseInProcessEncounterResponse>> | undefined
  >("inProcessEncounterList", (data) => {
    const pages = data?.pages || [];
    for (let index = 0; index < pages.length; index += 1) {
      const page = pages[index];

      const updatedEncounterIndex = page.data.content.findIndex(
        (encounter) => encounter.encounterId === encounterId
      );
      if (updatedEncounterIndex >= 0) {
        const encounter = page.data.content[updatedEncounterIndex];
        page.data.content[updatedEncounterIndex] = {
          ...encounter,
          ...encounterChanges,
        };
        break;
      }
    }
    return data;
  });
}

export { updateInProcessListItemCache };
