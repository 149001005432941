const sectionCode = "SPRO2";
const chartCode = "C024x";

import { Procedures } from "modules/provider/shared/procedure-charts";

function T24Procedures() {
  return <Procedures chartCode={chartCode} sectionCode={sectionCode} />;
}

export { T24Procedures };
