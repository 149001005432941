import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const Bus = createIcon({
  path: (
    <path
      d="M3.88072 11.7682C7.13986 12.2806 11.8606 12.2722 15.1869 11.7682C15.7497 11.6758 16.0269 11.3566 16.0269 10.7434V3.09954C16.0269 2.41075 15.7245 2.05796 15.0945 1.98236C11.7766 1.60437 7.26585 1.58757 3.98152 1.98236C3.35153 2.05796 3.04914 2.41915 3.04914 3.09954V10.7434C3.04914 11.3482 3.31793 11.6758 3.88072 11.7682ZM2.51155 20C1.78916 20 1.29357 19.5044 1.29357 18.7904V8.40823H0.915582C0.377992 8.40823 0 8.06384 0 7.55985V4.09072C0 3.58673 0.377992 3.25074 0.915582 3.25074H1.29357V2.36035C1.29357 1.20118 1.96556 0.49559 3.18354 0.335993C4.9559 0.109198 7.25745 0 9.52541 0C11.8102 0 14.1201 0.117598 15.8757 0.335993C17.1021 0.49559 17.774 1.19278 17.774 2.36035V3.25074H18.152C18.6812 3.25074 19.0592 3.58673 19.0592 4.09072V7.55985C19.0592 8.06384 18.6812 8.40823 18.152 8.40823H17.774V18.7904C17.774 19.5044 17.2785 20 16.5561 20H15.5397C14.8257 20 14.3301 19.5044 14.3301 18.7904V17.4633C12.7089 17.5808 11.0794 17.6312 9.52541 17.6312C7.97984 17.6312 6.35027 17.5724 4.73751 17.4549V18.7904C4.73751 19.5044 4.24192 20 3.51953 20H2.51155ZM4.41831 15.5817C5.0399 15.5817 5.51869 15.1029 5.51869 14.4813C5.51869 13.8597 5.0399 13.3809 4.41831 13.3809C3.79672 13.3809 3.31793 13.8597 3.31793 14.4813C3.31793 15.1029 3.79672 15.5817 4.41831 15.5817ZM14.6409 15.5817C15.2709 15.5817 15.7413 15.1029 15.7413 14.4813C15.7413 13.8597 15.2709 13.3809 14.6409 13.3809C14.0193 13.3809 13.5489 13.8597 13.5489 14.4813C13.5489 15.1029 14.0193 15.5817 14.6409 15.5817ZM7.70265 15.2373H11.3482C11.8018 15.2373 12.121 14.9181 12.121 14.4645C12.121 14.0109 11.8018 13.7001 11.3482 13.7001H7.70265C7.24906 13.7001 6.92986 14.0109 6.92986 14.4645C6.92986 14.9181 7.24906 15.2373 7.70265 15.2373Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { Bus };
