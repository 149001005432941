import { BoxProps, Flex, HStack } from "@chakra-ui/react";
import * as React from "react";

type TableTopSectionProps = {
  leftItems?: React.ReactNode;
  rightItems?: React.ReactNode;
} & BoxProps;

function TableTopSection(props: TableTopSectionProps) {
  const { leftItems, rightItems, ...boxProps } = props;

  return (
    <Flex
      p="20px 30px"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <HStack spacing="1.25rem">{leftItems}</HStack>
      <HStack spacing="2.125rem" display="flex">
        {rightItems}
      </HStack>
    </Flex>
  );
}

export type { TableTopSectionProps };
export { TableTopSection };
