import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const DuplicatePatients = createIcon({
  path: (
    <path
      d="M5.97466 2.08277C6.06588 0.72973 6.87162 0 8.34628 0H18.6233C20.098 0 20.9037 0.72973 21.0101 2.08277H5.97466ZM3.3598 6.50676C3.57264 5.0625 4.30236 4.22635 5.92905 4.22635H21.0405C22.6672 4.22635 23.397 5.0625 23.6098 6.50676H3.3598ZM4.98649 36C1.7027 36 0 34.3125 0 31.0591V13.8497C0 10.5963 1.7027 8.92399 4.98649 8.92399H22.0895C25.3733 8.92399 27.076 10.6115 27.076 13.8497V31.0591C27.076 34.2973 25.3733 36 22.4696 36H4.98649ZM18.0152 24.5372C19.8243 24.5372 21.3446 22.9409 21.3446 20.8733C21.3446 18.8361 19.8243 17.3007 18.0304 17.3007C16.2365 17.3007 14.6706 18.8666 14.6858 20.8885C14.6858 22.9409 16.2061 24.5372 18.0152 24.5372ZM8.4527 24.7348C10.0338 24.7348 11.3564 23.3514 11.3564 21.527C11.3564 19.7635 10.0186 18.4257 8.4527 18.4257C6.88682 18.4257 5.53378 19.7787 5.54899 21.5422C5.54899 23.3514 6.88682 24.7348 8.4527 24.7348ZM5.03209 33.1115H9.50169C9.28885 32.7922 9.21284 32.3514 9.21284 31.8497C9.21284 30.2534 10.201 28.1098 11.6757 27C10.7483 26.4375 9.72973 26.1334 8.4527 26.1334C5.79223 26.1334 3.75507 27.5321 2.8277 29.1132V30.8767C2.8277 32.397 3.55743 33.1115 5.03209 33.1115ZM11.8125 33.1115H22.0743C23.3818 33.1115 24.2635 32.397 24.2635 30.8767V29.4324C23.1537 27.5473 20.7821 26.1639 18.0152 26.1639C13.8041 26.1639 11.1436 29.2196 11.1436 31.9105C11.1436 32.6402 11.5389 33.0659 11.8125 33.1115Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 28 36",
});

export { DuplicatePatients };
