import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { STPVipNote } from "modules/stp-vip/types";

type UseStpVipNotes = { patientId: string; type: "Stp" | "Vip" | "Other" };

function useStpVipNotes(props: UseStpVipNotes) {
  const { patientId, type } = props;
  return useAxiosQuery(["stpVipNotes", { patientId, type }], () =>
    axiosPatientInstance.get<STPVipNote[]>(`v1/notes/${patientId}/${type}`, {})
  );
}

export { useStpVipNotes };
