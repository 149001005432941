import * as React from "react";
import {
  Avatar as CkAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";

const omittedProps = ["icon", "name", "getInitials", "variant"] as const;
type CkAvatarProps = Omit<ChakraAvatarProps, typeof omittedProps[number]>;

type AvatarProps = CkAvatarProps;

export function Avatar(props: AvatarProps) {
  return <CkAvatar {...props} />;
}
