import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const CloseCircle = createIcon({
  path: (
    <path
      d="M12 24.2126C18.5647 24.2126 24 18.7656 24 12.2126C24 5.64794 18.5529 0.212646 11.9882 0.212646C5.43529 0.212646 0 5.64794 0 12.2126C0 18.7656 5.44706 24.2126 12 24.2126ZM8.4 17.7774C7.88235 17.7774 7.49412 17.4126 7.49412 16.9303C7.49412 16.6832 7.55294 16.5185 7.70588 16.295L10.7882 12.0362L7.88235 8.03618C7.68235 7.76559 7.62353 7.60088 7.62353 7.33029C7.62353 6.83618 8.05882 6.42441 8.57647 6.42441C8.98824 6.42441 9.22353 6.57735 9.50588 6.96559L12.0824 10.7068H12.1412L14.6824 6.97735C14.9412 6.57735 15.1882 6.42441 15.5647 6.42441C16.0824 6.42441 16.4824 6.80088 16.4824 7.28323C16.4824 7.50676 16.4235 7.70676 16.2471 7.93029L13.1882 12.095L16.2235 16.2479C16.3882 16.4597 16.4588 16.6362 16.4588 16.8832C16.4588 17.4126 16.0471 17.7774 15.4941 17.7774C15.1176 17.7774 14.8941 17.6362 14.5882 17.2126L11.9765 13.4832H11.9294L9.30588 17.2126C8.98824 17.6362 8.78823 17.7774 8.4 17.7774Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 25",
});

export { CloseCircle };
