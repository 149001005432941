import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Logs = createIcon({
  path: (
    <path
      d="M6.52632 2.20243C6.62348 0.777328 7.48178 0 9.02024 0H24.5182C26.0729 0 26.915 0.777328 27.0283 2.20243H6.52632ZM3.22267 6.88259C3.44939 5.34413 4.22672 4.46964 5.94332 4.46964H27.6113C29.3279 4.46964 30.1053 5.34413 30.332 6.88259H3.22267ZM5.1336 36C1.74899 36 0 34.251 0 30.915V14.6073C0 11.2551 1.74899 9.52227 5.1336 9.52227H28.4049C31.7733 9.52227 33.5385 11.2713 33.5385 14.6073V30.915C33.5385 34.251 31.7733 36 28.4049 36H5.1336Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 34 36",
});

export { Logs };
