import * as React from "react";

import { Audits, Bus, FootSteps, Hand, PersonRunning } from "icons";
import {
  EmrBed,
  EmrDischarged,
  EmrEditCircle,
  EmrInProcess,
  EmrStar,
  EmrTransportation,
} from "@medstonetech/slate-icons";
import { EncounterDischargedSubstatus, EncounterStatus } from "enums";

import { Icon } from "@chakra-ui/react";

const ICON_PROPS = { fontSize: "1.5rem" };

const STATUS_ICON: Record<EncounterStatus, JSX.Element> = {
  Discharged: <Icon as={EmrDischarged} color="green" {...ICON_PROPS} />,
  Draft: <Icon as={EmrEditCircle} color="cyan" {...ICON_PROPS} />,
  InProcess: <Icon as={EmrInProcess} color="blue" {...ICON_PROPS} />,
  Lobby: <Icon as={EmrStar} color="mint" {...ICON_PROPS} />,
  Observation: <Icon as={EmrBed} color="purple" {...ICON_PROPS} />,
  Transfers: <Icon as={EmrTransportation} color="yellow" {...ICON_PROPS} />,
  ReceptionAudit: <Icon as={Audits} color="yellow" {...ICON_PROPS} />,
};

const DISCHARGED_SUBSTATUS_ICON: Record<
  EncounterDischargedSubstatus,
  JSX.Element
> = {
  LPT: <Icon as={PersonRunning} color="orange" {...ICON_PROPS} />,
  AMA: <Icon as={Hand} color="red" {...ICON_PROPS} />,
  Eloped: <Icon as={FootSteps} color="gray.600" {...ICON_PROPS} />,
  Transferred: <Icon as={Bus} color="yellow" {...ICON_PROPS} />,
};

export { STATUS_ICON, DISCHARGED_SUBSTATUS_ICON };
