import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const EligibilityInfo = createIcon({
  path: (
    <path
      d="M17.2541 29.2954C17.0535 29.2954 16.7349 29.2128 16.4282 29.0476C9.65529 25.3426 7.29541 23.6553 7.29541 19.3603V10.4282C7.29541 9.03582 7.83818 8.52845 9.00632 8.04467C10.3161 7.5019 14.7408 5.86178 16.027 5.5078C16.4164 5.4016 16.8647 5.29541 17.2541 5.29541C17.6553 5.29541 18.1037 5.37801 18.4813 5.5078C19.7792 5.93258 24.204 7.4901 25.5137 8.04467C26.6818 8.54025 27.2246 9.03582 27.2246 10.4282V19.3603C27.2246 23.6553 24.8647 25.3544 18.0919 29.0476C17.7851 29.2128 17.4665 29.2954 17.2541 29.2954ZM17.2541 26.8175C17.4311 26.8175 17.5963 26.7585 17.9385 26.5461C23.2718 23.3367 25.0063 22.3928 25.0063 18.9119V10.8057C25.0063 10.3928 24.9237 10.2158 24.5933 10.0978C22.8234 9.4606 19.4606 8.26886 17.8677 7.64349C17.6081 7.5491 17.4311 7.5137 17.2541 7.5137C17.0889 7.5137 16.9001 7.5491 16.6405 7.64349C15.0594 8.26886 11.6612 9.38981 9.91488 10.0978C9.5963 10.2276 9.5019 10.3928 9.5019 10.8057V18.9119C9.5019 22.3692 11.1774 23.2659 16.5815 26.5461C16.9119 26.7585 17.0889 26.8175 17.2541 26.8175Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 35 35",
});

export { EligibilityInfo };
