import * as React from "react";

import { createIcon } from "@chakra-ui/react";

const Action = createIcon({
  path: (
    <path
      d="M19.2509 23.4917C16.3757 23.4917 14 21.1262 14 18.2407C14 15.3655 16.3757 13 19.2509 13C22.1262 13 24.4917 15.3655 24.4917 18.2407C24.4917 21.1058 22.0956 23.4917 19.2509 23.4917ZM16.8243 19.1074H19.3121C19.7098 19.1074 20.0156 18.8015 20.0156 18.3937V15.2941C20.0156 14.9067 19.6996 14.6008 19.3121 14.6008C18.9145 14.6008 18.5984 14.9067 18.5984 15.2941V17.6902H16.8243C16.4266 17.6902 16.1106 18.0062 16.1106 18.3937C16.1106 18.7913 16.4165 19.1074 16.8243 19.1074ZM25.287 15.4878C30.7214 15.8651 35.0853 20.4737 35.0853 25.9795C35.0853 26.6524 34.6163 27.0603 34.0963 27.0603C33.5457 27.0603 33.0155 26.6626 33.0155 25.9795C33.0155 21.7176 29.8854 18.2102 25.7968 17.629C25.7866 16.9153 25.5827 16.1302 25.287 15.4878ZM14.0204 25.9795C14.0204 24.9089 14.1937 23.8791 14.4996 22.9003C15.0298 23.4203 15.6518 23.8384 16.3349 24.1136C16.1921 24.705 16.1106 25.3372 16.1106 25.9795C16.1106 29.1607 17.8541 31.9238 20.3215 33.3104C21.647 34.0853 20.6172 35.8187 19.3325 35.0947C16.2941 33.4226 14.0204 29.9356 14.0204 25.9795ZM24.196 33.6979L24.3082 25.4799C24.3184 25.0517 24.767 24.8783 25.0627 25.174L30.7316 30.9654C31.0477 31.3018 30.8336 31.7504 30.385 31.7504L28.254 31.8116L30.0383 35.9512C30.1403 36.1653 30.0995 36.4508 29.8344 36.563L28.7944 36.9708C28.5395 37.0626 28.305 36.93 28.203 36.6853L26.5105 32.4948L25.0219 34.014C24.7262 34.3198 24.1858 34.1567 24.196 33.6979Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 50 50",
});

export { Action };
