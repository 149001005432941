import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const SquareSearch = createIcon({
  path: (
    <path
      d="M4.18161 11.8638C3.54824 11.8638 3.20091 11.5301 3.20091 10.8695V10.5698C3.916 10.9035 4.70602 11.0874 5.5437 11.0874C6.68105 11.0874 7.72304 10.7401 8.59478 10.1476L12.0681 13.6277C12.2724 13.8252 12.5244 13.9137 12.79 13.9137C13.3689 13.9137 13.7911 13.4779 13.7911 12.9058C13.7911 12.6334 13.689 12.3814 13.5051 12.1975L10.0522 8.73099C10.6992 7.83882 11.0874 6.73553 11.0874 5.5437C11.0874 4.11351 10.5426 2.8059 9.64359 1.81839H15.6845C16.311 1.81839 16.6652 2.14529 16.6652 2.8059V10.8695C16.6652 11.5301 16.311 11.8638 15.6845 11.8638H14.2202C14.4926 12.252 14.6016 12.7219 14.5267 13.1986H15.7662C17.2372 13.1986 18 12.4359 18 10.9852V2.69694C18 1.2395 17.2372 0.483541 15.7662 0.483541H7.80477C7.1101 0.177072 6.34733 0 5.5437 0C2.48581 0 0 2.48581 0 5.5437C0 7.19864 0.721907 8.6765 1.86606 9.69126V10.9852C1.86606 12.4427 2.63564 13.1986 4.09989 13.1986H10.5221L9.18048 11.8638H4.18161ZM1.44381 5.5437C1.44381 3.28263 3.27582 1.44381 5.5437 1.44381C7.80477 1.44381 9.64359 3.28263 9.64359 5.5437C9.64359 7.81158 7.80477 9.64359 5.5437 9.64359C3.27582 9.64359 1.44381 7.81158 1.44381 5.5437Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 14",
});

export { SquareSearch };
