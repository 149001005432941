import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import {
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules/charts-shared";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrPerson } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const SECTION_CODE = "SPEX";
const CHART_CODE = "C070a";

function T70PhysicalExam() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode: CHART_CODE,
    encounterId,
    sectionId: SECTION_CODE,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode: CHART_CODE,
      encounterId,
      sectionId: SECTION_CODE,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values, ["Q001"]);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode: CHART_CODE,
            sectionId: SECTION_CODE,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Supplies") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "physical-exam",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject, ["Q001"]);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode: CHART_CODE,
          sectionId: SECTION_CODE,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Supplies") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={SECTION_CODE}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={CHART_CODE}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        {/* header subsection Q001 */}
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name="Q001.C070aSPEXQ001A001"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C070aSPEXQ001A003"
                    label="Nursing Assessment reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name="Q001.C070aSPEXQ001A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.C070aSPEXQ001A004"
                    label="Vitals Reviewed"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* General Appearance Q002 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="enable"
                    name="Q002.C070aSPEXQ002A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="c-collar"
                    name="Q002.C070aSPEXQ002A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="backboard"
                    name="Q002.C070aSPEXQ002A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PTA"
                    name="Q002.C070aSPEXQ002A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="in ED"
                    name="Q002.C070aSPEXQ002A005"
                  />
                  <ChartRowElement type="input" name="Q002.C070aSPEXQ002A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no acute distress"
                    name="Q002.C070aSPEXQ002A007"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="mild"
                    name="Q002.C070aSPEXQ002A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="moderate"
                    name="Q002.C070aSPEXQ002A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="severe"
                    name="Q002.C070aSPEXQ002A010"
                  />
                  <ChartRowElement type="input" name="Q002.C070aSPEXQ002A011" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="appears normal"
                    name="Q002.C070aSPEXQ002A012"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="ill"
                    name="Q002.C070aSPEXQ002A013"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="uncomfortable"
                    name="Q002.C070aSPEXQ002A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="crying"
                    name="Q002.C070aSPEXQ002A015"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="inconsolable"
                    name="Q002.C070aSPEXQ002A016"
                  />
                  <ChartRowElement type="input" name="Q002.C070aSPEXQ002A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="alert"
                    name="Q002.C070aSPEXQ002A018"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="anxious"
                    name="Q002.C070aSPEXQ002A019"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="lethargic"
                    name="Q002.C070aSPEXQ002A020"
                  />
                  <ChartRowElement type="input" name="Q002.C070aSPEXQ002A021" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q002.C070aSPEXQ002A022" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* head subsection Q003 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="Head"
            >
              Head
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q003.C070aSPEXQ003A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q003.C070aSPEXQ003A002"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no swelling"
                    name="Q003.C070aSPEXQ003A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q003.C070aSPEXQ003A005"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q003.C070aSPEXQ003A007"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="raccoon eyes"
                    name="Q003.C070aSPEXQ003A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Battle's sign"
                    name="Q003.C070aSPEXQ003A009"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q003.C070aSPEXQ003A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q003.C070aSPEXQ003A011"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A012" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no obvious trauma"
                    name="Q003.C070aSPEXQ003A013"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q003.C070aSPEXQ003A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasion"
                    name="Q003.C070aSPEXQ003A015"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="swelling"
                    name="Q003.C070aSPEXQ003A016"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no visual disturbance"
                    name="Q003.C070aSPEXQ003A018"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="periorbital hematoma"
                    name="Q003.C070aSPEXQ003A019"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="raccoon eyes"
                    name="Q003.C070aSPEXQ003A021"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Battle's sign"
                    name="Q003.C070aSPEXQ003A022"
                  />
                  <ChartRowElement type="input" name="Q003.C070aSPEXQ003A023" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q003.C070aSPEXQ003A024" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* eyes subsection Q004 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="Eyes"
            >
              Eyes
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lids & conjunctivae nml"
                    name="Q004.C070aSPEXQ004A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="lids & conjunctivae abnormal"
                    name="Q004.C070aSPEXQ004A002"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="PERRL"
                    name="Q004.C070aSPEXQ004A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C070aSPEXQ004A044"
                    label="unequal pupils:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A005"
                  />
                  <ChartRowElement
                    type="input"
                    size="sm"
                    name="Q004.C070aSPEXQ004A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C070aSPEXQ004A045"
                    label="mm"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A007"
                  />
                  <ChartRowElement
                    type="input"
                    size="sm"
                    name="Q004.C070aSPEXQ004A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q004.C070aSPEXQ004A046"
                    label="mm"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="EOMI"
                    name="Q004.C070aSPEXQ004A010"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="EOM entrapment"
                    name="Q004.C070aSPEXQ004A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="palsy"
                    name="Q004.C070aSPEXQ004A012"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A013"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A014"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A015" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="hyphema"
                    name="Q004.C070aSPEXQ004A016"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="retinal detachment"
                    name="Q004.C070aSPEXQ004A017"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A018"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A019"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A020" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="subconctival hemmorhage"
                    name="Q004.C070aSPEXQ004A021"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A022"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A023"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="periorbital hematoma"
                    name="Q004.C070aSPEXQ004A025"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A026"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A027"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A028" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="subconctival hemmorhage"
                    name="Q004.C070aSPEXQ004A029"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A030"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A031"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A032" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="subconctival hemmorhage"
                    name="Q004.C070aSPEXQ004A033"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A034"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A035"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A036" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="foreign body"
                    name="Q004.C070aSPEXQ004A037"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q004.C070aSPEXQ004A038"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q004.C070aSPEXQ004A039"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A040" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="fluorescein uptake"
                    name="Q004.C070aSPEXQ004A041"
                  />
                  <ChartRowElement type="input" name="Q004.C070aSPEXQ004A042" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q004.C070aSPEXQ004A043" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Neck subsection Q005 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="Neck"
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q005.C070aSPEXQ005A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q005.C070aSPEXQ005A002"
                  />
                  <ChartRowElement type="input" name="Q005.C070aSPEXQ005A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nml ROM"
                    name="Q005.C070aSPEXQ005A004"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pain on neck movement"
                    name="Q005.C070aSPEXQ005A005"
                  />
                  <ChartRowElement type="input" name="Q005.C070aSPEXQ005A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nexus criteria negative"
                    name="Q005.C070aSPEXQ005A007"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="midline tenderness"
                    name="Q005.C070aSPEXQ005A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="distracting injury"
                    name="Q005.C070aSPEXQ005A009"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.C070aSPEXQ005A0010"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="altered mental status"
                    name="Q005.C070aSPEXQ005A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="recent ETOH"
                    name="Q005.C070aSPEXQ005A012"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.C070aSPEXQ005A0013"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="focal neuro deficit"
                    name="Q005.C070aSPEXQ005A014"
                  />
                  <ChartRowElement
                    type="input"
                    name="Q005.C070aSPEXQ005A0015"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q005.C070aSPEXQ005A0016" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* ENT subsection Q006 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q006"
              subsectionLabel="ENT"
            >
              ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pharynx"
                    name="Q006.C070aSPEXQ006A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="hemotympanum"
                    name="Q006.C070aSPEXQ006A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q006.C070aSPEXQ006A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q006.C070aSPEXQ006A004"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="TM obscured by wax"
                    name="Q006.C070aSPEXQ006A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q006.C070aSPEXQ006A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q006.C070aSPEXQ006A008"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="clotted nasal blood"
                    name="Q006.C070aSPEXQ006A010"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q006.C070aSPEXQ006A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q006.C070aSPEXQ006A012"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A013" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="nasal septal hematoma"
                    name="Q006.C070aSPEXQ006A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q006.C070aSPEXQ006A015"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q006.C070aSPEXQ006A016"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no injury to teeth, lips or gums"
                    name="Q006.C070aSPEXQ006A018"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dental injury"
                    name="Q006.C070aSPEXQ006A019"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="malocclusion"
                    name="Q006.C070aSPEXQ006A020"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A021" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="laceration"
                    name="Q006.C070aSPEXQ006A022"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="crosses vermillion border"
                    name="Q006.C070aSPEXQ006A023"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="dry mucous membranes"
                    name="Q006.C070aSPEXQ006A025"
                  />
                  <ChartRowElement type="input" name="Q006.C070aSPEXQ006A026" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q006.C070aSPEXQ006A027" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Neuro / Psych subsection Q007 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q007"
              subsectionLabel="Neuro / Psych"
            >
              Neuro / Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="oriented x4"
                    name="Q007.C070aSPEXQ007A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q007.C070aSPEXQ007A026"
                    label="disoriented to:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="person"
                    name="Q007.C070aSPEXQ007A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="place"
                    name="Q007.C070aSPEXQ007A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="time"
                    name="Q007.C070aSPEXQ007A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="situation"
                    name="Q007.C070aSPEXQ007A005"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="confused"
                    name="Q007.C070aSPEXQ007A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="obtunded"
                    name="Q007.C070aSPEXQ007A008"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="sensation nml"
                    name="Q007.C070aSPEXQ007A010"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="weakness"
                    name="Q007.C070aSPEXQ007A011"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="sensory loss"
                    name="Q007.C070aSPEXQ007A012"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A013" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="motor nml"
                    name="Q007.C070aSPEXQ007A014"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="abnormal gait"
                    name="Q007.C070aSPEXQ007A015"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A016" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="CN's nml (2-12)"
                    name="Q007.C070aSPEXQ007A017"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="facial droop"
                    name="Q007.C070aSPEXQ007A018"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q007.C070aSPEXQ007A019"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q007.C070aSPEXQ007A020"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A021" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="mood / affect nml"
                    name="Q007.C070aSPEXQ007A022"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="depressed mood / affect"
                    name="Q007.C070aSPEXQ007A023"
                  />
                  <ChartRowElement type="input" name="Q007.C070aSPEXQ007A024" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q007.C070aSPEXQ007A025" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Respiratory subsection Q008 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q008"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="chest non-tender"
                    name="Q008.C070aSPEXQ008A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q008.C070aSPEXQ008A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q008.C070aSPEXQ008A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="abrasions"
                    name="Q008.C070aSPEXQ008A004"
                  />
                  <ChartRowElement type="input" name="Q008.C070aSPEXQ008A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="breathing sounds nml"
                    name="Q008.C070aSPEXQ008A006"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="decreased breathing sounds"
                    name="Q008.C070aSPEXQ008A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q008.C070aSPEXQ008A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q008.C070aSPEXQ008A009"
                  />
                  <ChartRowElement type="input" name="Q008.C070aSPEXQ008A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no respiratory distress"
                    name="Q008.C070aSPEXQ008A011"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="wheezes"
                    name="Q008.C070aSPEXQ008A012"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rales"
                    name="Q008.C070aSPEXQ008A013"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="rhonchi"
                    name="Q008.C070aSPEXQ008A014"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q008.C070aSPEXQ008A015"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q008.C070aSPEXQ008A016"
                  />
                  <ChartRowElement type="input" name="Q008.C070aSPEXQ008A017" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q008.C070aSPEXQ008A018" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* CVS subsection Q009 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q009"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="heart sounds nml"
                    name="Q009.C070aSPEXQ009A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tachycardia"
                    name="Q009.C070aSPEXQ009A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="bradycardia"
                    name="Q009.C070aSPEXQ009A003"
                  />
                  <ChartRowElement type="input" name="Q009.C070aSPEXQ009A004" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="reg. rate & rythm"
                    name="Q009.C070aSPEXQ009A005"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement type="input" name="Q009.C070aSPEXQ009A006" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q009.C070aSPEXQ009A007" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Abdomen / GU Q010 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q010"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q010.C070aSPEXQ010A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    name="Q010.C070aSPEXQ010A014"
                    label="tenderness:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="generalized"
                    name="Q010.C070aSPEXQ010A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="LUQ"
                    name="Q010.C070aSPEXQ010A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RUQ"
                    name="Q010.C070aSPEXQ010A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="LLQ"
                    name="Q010.C070aSPEXQ010A005"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RLQ"
                    name="Q010.C070aSPEXQ010A006"
                  />
                  <ChartRowElement type="input" name="Q010.C070aSPEXQ010A007" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="no organomegaly"
                    name="Q010.C070aSPEXQ010A008"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q010.C070aSPEXQ010A009"
                  />
                  <ChartRowElement type="input" name="Q010.C070aSPEXQ010A010" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="catheter present"
                    name="Q010.C070aSPEXQ010A011"
                  />
                  <ChartRowElement type="input" name="Q010.C070aSPEXQ010A012" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q010.C070aSPEXQ010A013" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Skin subsection Q011 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q011"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="intact, nml palp."
                    name="Q011.C070aSPEXQ011A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="crepitus"
                    name="Q011.C070aSPEXQ011A002"
                  />
                  <ChartRowElement type="input" name="Q011.C070aSPEXQ011A003" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="pressure ulcer"
                    name="Q011.C070aSPEXQ011A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    name="Q011.C070aSPEXQ011A007"
                    label="location:"
                  />
                  <ChartRowElement type="input" name="Q011.C070aSPEXQ011A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q011.C070aSPEXQ011A006" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Extremities subsection Q012 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q012"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="non-tender"
                    name="Q012.C070aSPEXQ012A001"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="tenderness"
                    name="Q012.C070aSPEXQ012A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q012.C070aSPEXQ012A003"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q012.C070aSPEXQ012A004"
                  />
                  <ChartRowElement type="input" name="Q012.C070aSPEXQ012A005" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="cross-option"
                    label="ecchymosis"
                    name="Q012.C070aSPEXQ012A006"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name="Q012.C070aSPEXQ012A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name="Q012.C070aSPEXQ012A008"
                  />
                  <ChartRowElement type="input" name="Q012.C070aSPEXQ012A009" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow>
                <ChartRowElement type="input" name="Q012.C070aSPEXQ012A010" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        {/* Additional Findings subsection Q013 */}
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q013"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    name={`Q013.C070aSPEXQ013A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement type="input" name="Q013.C070aSPEXQ013A002" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T70PhysicalExam };
