import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import { OrderFormElementRoute } from "enums";
import { useAddCustomDoseFavorite } from "modules/charts-shared/api";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import { OrderElementResponse } from "modules/order-list/types";
import { useFormContext } from "react-hook-form";
import { IoHeart } from "react-icons/io5";
import { Button, Checkbox, FormCalendarInput, Label, Select } from "shared";
import { extractApiErrorMessage } from "utils";
import { getOptions, valueToOption } from "utils/enum-mapper";
import { MedicationOrderForm } from "./AddMedicationOrderModal";
import {
  VALID_CONCENTRATIONS_UNITS,
  VALID_UNITS,
  VALID_VOLUME_UNITS,
} from "./ValidCalculations";

const unitsArray = [
  { label: "mcg/kg", value: "mcg/kg" },
  { label: "mg/kg", value: "mg/kg" },
  { label: "g/kg", value: "g/kg" },
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "mcg", value: "mcg" },
  { label: "mL", value: "mL" },
  { label: "L", value: "L" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "oz", value: "oz" },
  { label: "drops", value: "drops" },
];

const concentrationDoseUnits = [
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "mcg", value: "mcg" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "L", value: "L" },
];

const volumeUnits = [
  { label: "mL", value: "mL" },
  { label: "L", value: "L" },
  { label: "mcg", value: "mcg" },
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "minutes", value: "minutes" },
];

type CustomDoseProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

type CustomDoseForm = MedicationOrderForm;

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function CustomDose({ item, showCalculation }: CustomDoseProps) {
  const { register, setValue, watch, getValues, control } =
    useFormContext<CustomDoseForm>();

  const { mutateAsync: addCustomFavorite, isLoading: isAddingCustomFavorite } =
    useAddCustomDoseFavorite();

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { doseAmount, doseUnit, weight, concentration } = getValues();
    if (
      checked &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit &&
      doseAmount &&
      doseUnit &&
      weight
    ) {
      const amount = calculateMedicationAmount(
        {
          doseAmount: concentration.doseAmount,
          doseUnit: concentration.doseUnit,
          volumeAmount: concentration.volumeAmount,
          volumeUnit: concentration.volumeUnit,
          id: "",
        },
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const doseUnit = watch("doseUnit");
  const doseAmount = watch("doseAmount");
  const concentrationDoseUnit = watch("concentration.doseUnit");
  const volumeUnit = watch("concentration.volumeUnit");

  const validCalculation =
    VALID_UNITS.includes(doseUnit) &&
    !!concentrationDoseUnit &&
    VALID_CONCENTRATIONS_UNITS.includes(concentrationDoseUnit) &&
    !!volumeUnit &&
    VALID_VOLUME_UNITS.includes(volumeUnit);

  const toast = useToast();

  const handleAddFavorite = async () => {
    try {
      await addCustomFavorite({
        orderFormElementId: item.id,
        dose: {
          doseAmount: getValues("doseAmount"),
          doseUnit: getValues("doseUnit"),
        },
        ...(concentrationDoseUnit && {
          concentration: {
            doseAmount: getValues("concentration.doseAmount"),
            doseUnit: getValues("concentration.doseUnit"),
            volumeAmount: getValues("concentration.volumeAmount"),
            volumeUnit: getValues("concentration.volumeUnit"),
          },
        }),
        route: getValues("route"),
      });
      toast({
        description: "Custom dose added to favorites",
      });
    } catch (error) {
      toast({
        description: extractApiErrorMessage(error),
      });
    }
  };

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>

      <Label {...fieldLabelStyle}>Custom Dosage</Label>
      <Flex columnGap="10px">
        <Input {...register("doseAmount")} type="number" step=".001" />
        <Select
          items={unitsArray}
          labelAccessor="label"
          valueAccessor="value"
          showBlank={false}
          onSelectedItemChange={(changes) =>
            setValue("doseUnit", changes.selectedItem?.value || "")
          }
        />
      </Flex>

      <Label {...fieldLabelStyle}>Custom Concentration</Label>
      <Flex columnGap="10px">
        <Input
          {...register("concentration.doseAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={concentrationDoseUnits}
          labelAccessor="label"
          valueAccessor="value"
          showBlank={false}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.doseUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
        <Input
          {...register("concentration.volumeAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={volumeUnits}
          labelAccessor="label"
          valueAccessor="value"
          showBlank={false}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.volumeUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
      </Flex>

      {watch("concentration.doseAmount") &&
        Boolean(concentrationDoseUnit) &&
        watch("concentration.volumeAmount") &&
        Boolean(volumeUnit) &&
        watch("doseAmount") &&
        Boolean(doseUnit) &&
        validCalculation && (
          <>
            <Label {...fieldLabelStyle}>Weight(kg)</Label>
            <Flex columnGap="10px">
              <Input
                type="number"
                step=".001"
                flex={1}
                {...register("weight")}
              />
              <Checkbox
                isDisabled={!Boolean(watch("weight"))}
                onChange={handleCalculate}
                flex={1}
              >
                Calculate from weight
              </Checkbox>
            </Flex>
          </>
        )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Route *</Label>
      <Select
        items={getOptions("orderFormElementRoute")}
        defaultSelectedItem={valueToOption(
          "orderFormElementRoute",
          getValues("route") as OrderFormElementRoute
        )}
        labelAccessor="label"
        valueAccessor="value"
        showBlank={false}
        onSelectedItemChange={(changes) =>
          setValue("route", changes.selectedItem?.value || "")
        }
      />

      <Button
        color="blue"
        _disabled={{
          opacity: 0.5,
        }}
        disabled={!doseUnit || !doseAmount}
        leftIcon={<Icon as={IoHeart} />}
        onClick={handleAddFavorite}
        aria-label="add favorite"
        variant="label"
        isLoading={isAddingCustomFavorite}
        mt="1rem"
      >
        Add to favorites
      </Button>

      <Label {...fieldLabelStyle}>Expected Administration Time *</Label>
      <Flex maxW="240px">
        <FormCalendarInput
          name="administrationTime"
          dateFormat={"LL/dd/yyyy \t HH:mm"}
          control={control}
          showIcon={false}
          showTimeInput
          shouldCloseOnSelect={false}
          onInputClick={() => {
            const date = getValues("administrationTime");
            if (!date) {
              setValue("administrationTime", new Date());
            }
          }}
        />
      </Flex>
      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { CustomDose };
