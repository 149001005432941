import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";
import { PaginationPayload, PaginationResponse } from "types";
import { OrderHistoryItem } from "./useOrderHistory";
import { useInfiniteQuery } from "react-query";

type OrderHistoryPaginationProps = PaginationPayload & {
  encounterId?: string;
  elementType?: string;
};

type OrderHistoryPaginationResponse = PaginationResponse<OrderHistoryItem>;

function useOrderHistoryPagination(
  props: OrderHistoryPaginationProps,
  options?: { enabled: boolean }
) {
  const { encounterId, page = 1, size = 10, elementType } = props;

  return useAxiosQuery(
    ["orderHistoryPagination", { encounterId, elementType }, props],
    () =>
      axiosOrderInstance.get<OrderHistoryPaginationResponse>(
        `v1/order-history/${encounterId}/all`,
        {
          params: {
            page,
            pageSize: size,
            elementType,
          },
        }
      ),
    { keepPreviousData: false, ...options }
  );
}

function useOrderHistoryInfinite(props: OrderHistoryPaginationProps) {
  const { size = 10, page = 1, elementType, encounterId } = props;

  return useInfiniteQuery(
    ["orderHistoryInfinite", { encounterId, elementType }, props],
    ({ pageParam = page }) =>
      axiosOrderInstance.get<OrderHistoryPaginationResponse>(
        `v1/order-history/${encounterId}/all`,
        {
          params: {
            pageSize: size,
            page: pageParam,
            elementType,
          },
        }
      ),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export { useOrderHistoryPagination, useOrderHistoryInfinite };
