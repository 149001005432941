import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C044x";

function T44HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="face swelling"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="throat swelling"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="skin rash"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="itching"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="trouble breathing"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dizziness"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="fainting"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="continues in ED"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="gone"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="now"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="better"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="worse"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="skin rash"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="itching"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="facial"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trunk"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="extremities"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="diffuse"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="redness"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hives"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="swelling"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="face"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lip(s)"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tongue"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="throat"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hands"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="feet"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="diffuse"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="trouble swallowing"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="trouble speaking"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="fainting"
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="dizziness"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Identified Cause"
            >
              Identified Cause
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="no"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="yes"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="possibly"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Exposure:"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Medication:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                />
                <ChartRowElement
                  label="antibiotic"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspirin"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NSAID"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ACE inhibitor"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Food:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                />
                <ChartRowElement
                  label="shellfish"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nuts"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="soybeans"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="eggs"
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Other:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                />
                <ChartRowElement
                  label="bee sting"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="wasp sting"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ant bite"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="spider bite"
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="insect bite"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="poison ivy"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="poison oak"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="infectious illness"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="soap"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="detergent"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="When"
            >
              When
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just prior to symptom onset"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="home"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="school"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="neighbor's"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="park"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="work"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="street"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="exact location:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  w="627.5px"
                  type="input"
                />
                <ChartRowElement
                  label="exact activity:"
                  type="cross-option"
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T44HPI };
