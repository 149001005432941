import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteSupervisingProvider(provider: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteSupervisingProvider", { provider }],
    () => axiosPatientInstance.delete(`v1/supervising-provider/${provider}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("supervisingProviderList");
        queryClient.invalidateQueries("activeSupervisingProvider");
      },
    }
  );
}

export { useDeleteSupervisingProvider };
