import {
  ArrowState,
  CircleState,
  CurveLineState,
  EllipseState,
  LineState,
  ShapeState,
  TextState,
} from "./types";

function isShapeCircle(shape: ShapeState): shape is CircleState {
  return shape.type === "circle";
}

function isShapeLine(shape: ShapeState): shape is LineState {
  return shape.type === "line";
}

function isShapeText(shape: ShapeState): shape is TextState {
  return shape.type === "text";
}

function isShapeArrow(shape: ShapeState): shape is ArrowState {
  return shape.type === "arrow";
}

function isShapeEllipse(shape: ShapeState): shape is EllipseState {
  return shape.type === "ellipse";
}

function isShapeCurveLine(shape: ShapeState): shape is CurveLineState {
  return shape.type === "curve_line";
}

export {
  isShapeArrow,
  isShapeCircle,
  isShapeLine,
  isShapeText,
  isShapeEllipse,
  isShapeCurveLine,
};
