import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const CurveLine = createIcon({
  path: (
    <path
      d="M2.42596 7.02829V6.63894C1.02829 6.22962 0 4.93178 0 3.38436C0 1.51747 1.51747 0 3.38436 0C5.26123 0 6.7787 1.51747 6.7787 3.38436C6.7787 4.93178 5.7604 6.22962 4.35275 6.63894V7.02829C4.35275 8.18636 5.18136 9.02496 6.75874 9.45424L11.8003 10.822C14.2762 11.4809 15.574 12.9484 15.574 15.0749V15.4642C16.9717 15.8735 18 17.1814 18 18.7188C18 20.5857 16.4825 22.1032 14.6156 22.1032C12.7388 22.1032 11.2113 20.5857 11.2113 18.7188C11.2113 17.1814 12.2396 15.8735 13.6473 15.4642V15.0749C13.6473 13.9168 12.8286 13.0882 11.2313 12.6489L6.19967 11.2812C3.72379 10.6323 2.42596 9.15474 2.42596 7.02829ZM3.38436 4.95175C4.27288 4.95175 4.95175 4.2629 4.95175 3.38436C4.95175 2.50582 4.27288 1.82696 3.38436 1.82696C2.51581 1.82696 1.83694 2.50582 1.83694 3.38436C1.83694 4.2629 2.51581 4.95175 3.38436 4.95175ZM14.6156 17.1614C13.7271 17.1614 13.0483 17.8403 13.0483 18.7188C13.0483 19.5973 13.7271 20.2762 14.6156 20.2762C15.4742 20.2762 16.1631 19.5973 16.1631 18.7188C16.1631 17.8503 15.4742 17.1614 14.6156 17.1614Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 23",
});

export { CurveLine };
