import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateFaqPayload = {
  question: string;
  answer: string;
};

function useCreateFaq() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createFaq"],
    (input: CreateFaqPayload) => axiosFacilityInstance.post("/v1/faq", input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("faqs");
      },
    }
  );
}

export { useCreateFaq };
export type { CreateFaqPayload };
