import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useMergeRefs } from "@chakra-ui/react";
import { CheckboxButton, CheckboxButtonProps } from "shared";
import { useDivideControlProps } from "./hooks";

const omittedProps = ["selected", "name", "onChange"] as const;

type FormCheckboxButtonProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<CheckboxButtonProps, typeof omittedProps[number]> & {
      onChange?: CheckboxButtonProps["onChange"];
    };

function FormCheckboxButtonInner<TFieldValues extends FieldValues>(
  props: FormCheckboxButtonProps<TFieldValues>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const { children, onChange: onChangeProp, ...restProps } = props;
  const [checkboxProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormCheckboxButtonProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, onChange, value, ...restField },
  } = useController(controllerProps);

  const checkboxRef = useMergeRefs(ref, fieldRef);

  const handleChange: CheckboxButtonProps["onChange"] = (event) => {
    onChangeProp?.(event);
    onChange(event);
  };

  return (
    <CheckboxButton
      ref={checkboxRef}
      isChecked={!!value}
      onChange={handleChange}
      {...checkboxProps}
      {...restField}
    >
      {children}
    </CheckboxButton>
  );
}

const FormCheckboxButton = React.forwardRef(FormCheckboxButtonInner) as <
  TFieldValues extends FieldValues
>(
  props: FormCheckboxButtonProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof FormCheckboxButtonInner>;

export { FormCheckboxButton };
export type { FormCheckboxButtonProps };
