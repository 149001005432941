import {
  Box,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { EmrClose } from "@medstonetech/slate-icons";
import { getAmountInMg, getVolumeInMl } from "modules/charts-shared/utils";
import { Concentration } from "modules/order-list/types";

type CalculationModalProps = {
  doseAmount: number;
  doseUnit: string;
  weight: number;
  concentration: Partial<Concentration>;
  isOpen: boolean;
  onClose: () => void;
};

function CalculationModal({
  doseAmount,
  doseUnit,
  concentration,
  weight,
  isOpen,
  onClose,
}: CalculationModalProps) {
  const doseAmountConverted = getAmountInMg(doseAmount, doseUnit);
  const concentrationDoseAmountConverted = getAmountInMg(
    concentration.doseAmount ?? 1,
    concentration.doseUnit ?? ""
  );
  const concentrationVolumeConverted = getVolumeInMl(
    concentration.volumeAmount ?? 1,
    concentration.volumeUnit ?? ""
  );

  const DW = doseAmountConverted * weight;
  const DW2 = DW / concentrationDoseAmountConverted;
  const amount = (DW2 * concentrationVolumeConverted).toFixed(3);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent zIndex={1000} borderRadius="md">
        <ModalHeader borderTopRadius="md" bgColor="gray.50">
          <Flex>
            <Box flex={1} />
            <Text>Calculation</Text>
            <Flex alignItems="center" justifyContent="flex-end" flex={1}>
              <IconButton variant="icon" onClick={onClose} aria-label="close">
                <EmrClose />
              </IconButton>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex>
            <Text fontWeight="500" flex={1}>
              (D) Dosage =
            </Text>
            <Text fontWeight="500">{`${doseAmount} ${doseUnit}`}</Text>
          </Flex>
          <Flex>
            <Text fontWeight="500" flex={1}>
              (C) Concentration =
            </Text>
            <Text fontWeight="500">{`${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit}`}</Text>
          </Flex>
          <Flex>
            <Text fontWeight="500" flex={1}>
              (W) Weight =
            </Text>
            <Text fontWeight="500">{`${weight} kg`}</Text>
          </Flex>
          <Divider />
          <Flex>
            <Text color="gray.700" fontWeight="500" flex={1}>
              {`${doseAmountConverted} mg (D) X ${weight} kg (W) =`}
            </Text>
            <Text color="gray.700" fontWeight="500">
              {DW}
            </Text>
          </Flex>
          <Flex>
            <Text
              flex={1}
              color="gray.700"
              fontWeight="500"
            >{`${DW} / ${concentrationDoseAmountConverted} mg (C1) =`}</Text>
            <Text color="gray.700" fontWeight="500">
              {DW2}
            </Text>
          </Flex>
          <Flex>
            <Text
              flex={1}
              color="gray.700"
              fontWeight="500"
            >{`${DW2} x ${concentrationVolumeConverted} mL`}</Text>
            <Text color="gray.700" fontWeight="500">
              {amount}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          borderBottomRadius="md"
          bgColor="gray.50"
        >
          <Text fontWeight="500">Amount =</Text>
          <Text fontWeight="500">{`${amount} mL`}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { CalculationModal };
