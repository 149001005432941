import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { VitalsResponse } from "modules";

function useVitals(encounterId: string) {
  return useAxiosQuery(["vitals", { encounterId }], () =>
    axiosChartInstance.get<VitalsResponse>(`v1/Vitals/${encounterId}`)
  );
}

export { useVitals };
