import React, { forwardRef } from "react";
import {
  Button as CkButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

const omittedProps = ["size", "colorScheme"] as const;
type CkButtonProps = Omit<ChakraButtonProps, typeof omittedProps[number]>;

type ButtonColorScheme = "blue" | "red" | "gray" | "green";

type BaseProps = CkButtonProps & {
  variant?: CkButtonProps["variant"];
  size?: never;
  colorScheme?: never;
};

type ButtonSolidProps = CkButtonProps & {
  variant?: ChakraButtonProps["variant"];
  size?: ChakraButtonProps["size"];
  colorScheme?: ButtonColorScheme;
};

type ButtonProps = BaseProps | ButtonSolidProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <CkButton ref={ref} {...props} />;
});

Button.displayName = "Button";

export type { ButtonProps };
export { Button };
