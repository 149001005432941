import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S010Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q010.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q010`}
          subsectionLabel="Fracture / Dislocation Care"
          onDeleteSubSection={() => onRemoveElement(sectionId)}
        >
          Fracture / Dislocation Care
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q010A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q010A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Procedural Sedation:"
                name={`${sectionDelimiter}Q010A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="local"
                name={`${sectionDelimiter}Q010A004`}
                type="option"
              />
              <ChartRowElement
                label="regional"
                name={`${sectionDelimiter}Q010A005`}
                type="option"
              />
              <ChartRowElement
                label="anesthesia"
                name={`${sectionDelimiter}Q010A006`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A007`}
                type="input"
                w="350px"
              />
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q010A008`}
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                name={`${sectionDelimiter}Q010A009`}
                type="option"
              />
              <ChartRowElement
                label="reduced with manipulation"
                name={`${sectionDelimiter}Q010A010`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A011`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Post-Reduction:"
                name={`${sectionDelimiter}Q010A011`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A012`}
                type="option"
              />
              <ChartRowElement
                label="vasc"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A013`}
                type="option"
              />
              <ChartRowElement
                label="intact"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A014`}
                type="option"
              />
              <ChartRowElement
                label="alignment:"
                name={`${sectionDelimiter}Q010A015`}
                type="option"
              />
              <ChartRowElement
                label="good"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A016`}
                type="option"
              />
              <ChartRowElement
                label="fair"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A017`}
                type="option"
              />
              <ChartRowElement
                label="poor"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A018`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="foreign body identified:"
                name={`${sectionDelimiter}Q010A020`}
                type="option"
              />
              <ChartRowElement
                label="wood"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A021`}
                type="option"
              />
              <ChartRowElement
                label="glass"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A022`}
                type="option"
              />
              <ChartRowElement
                label="metal"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A023`}
                type="option"
              />
              <ChartRowElement
                label="other"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A024`}
                type="option"
              />
              <ChartRowElement
                label="good"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A025`}
                type="option"
              />
              <ChartRowElement
                label="foreign material removed"
                name={`${sectionDelimiter}Q010A026`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A027`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Post-Reduction:"
                name={`${sectionDelimiter}Q010A028`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A029`}
                type="option"
              />
              <ChartRowElement
                label="vasc"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A030`}
                type="option"
              />
              <ChartRowElement
                label="intact"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A031`}
                type="option"
              />
              <ChartRowElement
                label="alignment:"
                name={`${sectionDelimiter}Q010A032`}
                type="option"
              />
              <ChartRowElement
                label="good"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A033`}
                type="option"
              />
              <ChartRowElement
                label="fair"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A034`}
                type="option"
              />
              <ChartRowElement
                label="poor"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A035`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Applied:"
                name={`${sectionDelimiter}Q010A037`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="sling"
                name={`${sectionDelimiter}Q010A038`}
                type="option"
              />
              <ChartRowElement
                label="splint:"
                name={`${sectionDelimiter}Q010A039`}
                type="option"
              />
              <ChartRowElement
                label="stirrup"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A040`}
                type="option"
              />
              <ChartRowElement
                label="posterior"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A041`}
                type="option"
              />
              <ChartRowElement
                label="OCL"
                name={`${sectionDelimiter}Q010A042`}
                type="option"
              />
              <ChartRowElement
                label="Ortho-glass"
                name={`${sectionDelimiter}Q010A043`}
                type="option"
              />
              <ChartRowElement
                label="Plaster"
                name={`${sectionDelimiter}Q010A044`}
                type="option"
              />
              <ChartRowElement
                label="neoprene"
                name={`${sectionDelimiter}Q010A045`}
                type="option"
              />
              <ChartRowElement
                label="Velcro"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A046`}
                type="option"
              />
              <ChartRowElement
                label="Aluminum-foam"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A047`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A048`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="Volar"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A049`}
                type="option"
              />
              <ChartRowElement
                label="Thumb spica"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A050`}
                type="option"
              />
              <ChartRowElement
                label="Ulnar"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A051`}
                type="option"
              />
              <ChartRowElement
                label="Wrist"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A052`}
                type="option"
              />
              <ChartRowElement
                label="Sugar-Tong"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A053`}
                type="option"
              />
              <ChartRowElement
                label="Cock-up"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A054`}
                type="option"
              />
              <ChartRowElement
                label="Colles"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A055`}
                type="option"
              />
              <ChartRowElement
                label="fingers buddy tapped"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A056`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A057`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="ace wrap"
                name={`${sectionDelimiter}Q010A058`}
                type="option"
              />
              <ChartRowElement
                label="ace tape"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A059`}
                type="option"
              />
              <ChartRowElement
                label="boot orthosis"
                name={`${sectionDelimiter}Q010A060`}
                type="option"
              />
              <ChartRowElement
                label="crutches"
                name={`${sectionDelimiter}Q010A061`}
                type="option"
              />
              <ChartRowElement
                label="post-op shoe"
                name={`${sectionDelimiter}Q010A062`}
                type="option"
              />
              <ChartRowElement
                label="air cast"
                name={`${sectionDelimiter}Q010A063`}
                type="option"
              />
              <ChartRowElement
                label="Digital Block:"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A064`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A065`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="toe"
                name={`${sectionDelimiter}Q010A066`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A067`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Applied by:"
                name={`${sectionDelimiter}Q010A068`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="ED Physician"
                name={`${sectionDelimiter}Q010A069`}
                type="option"
              />
              <ChartRowElement
                label="MLP"
                name={`${sectionDelimiter}Q010A070`}
                type="option"
              />
              <ChartRowElement
                label="Tech"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A071`}
                type="option"
              />
              <ChartRowElement
                label="Nurse"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A072`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A073`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Examined post splint application:"
                name={`${sectionDelimiter}Q010A074`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A075`}
                type="option"
              />
              <ChartRowElement
                label="vasc"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A076`}
                type="option"
              />
              <ChartRowElement
                label="intact"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A077`}
                type="option"
              />
              <ChartRowElement
                label="good alignment"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A078`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A002`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Foreign body removed:"
                name={`${sectionDelimiter}Q010A079`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="w/:"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A080`}
                type="option"
              />
              <ChartRowElement
                label="forceps"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A081`}
                type="option"
              />
              <ChartRowElement
                label="incision"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A082`}
                type="option"
              />
              <ChartRowElement
                label="exploration"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A083`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A084`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Subungual hematoma:"
                name={`${sectionDelimiter}Q010A085`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="drained using:"
                name={`${sectionDelimiter}Q010A086`}
                type="option"
              />
              <ChartRowElement
                label="electrocautery"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A087`}
                type="option"
              />
              <ChartRowElement
                label="burr"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A088`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A089`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Nail removed:"
                name={`${sectionDelimiter}Q010A090`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="completely"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A091`}
                type="option"
              />
              <ChartRowElement
                label="partially"
                fontStyle="italic"
                name={`${sectionDelimiter}Q010A092`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q010A093`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>

          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q010A094`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S010Procedure };
